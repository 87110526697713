var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from "react";
import { get } from "lodash";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TextField } from "../../components/fields";
import { useDeepCompareCallback, useDeepCompareMemoize } from "../UtilHooks";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const groupData = (data, field, expanded, allGroupsExpanded) => {
  const groupedData = data && data.reduce((aggregate, row) => {
    var _a;
    const rowGroupValue = get(row, field);
    const existingGroupIndex = aggregate.findIndex((group2) => group2.value === rowGroupValue);
    const newGroupIndex = existingGroupIndex >= 0 ? existingGroupIndex : aggregate.length;
    const group = existingGroupIndex >= 0 ? aggregate[existingGroupIndex] : {
      field,
      value: rowGroupValue,
      items: [],
      expanded: get(expanded, rowGroupValue, allGroupsExpanded || newGroupIndex === 0)
    };
    (_a = group.items) == null ? void 0 : _a.push(row);
    return [...aggregate.slice(0, newGroupIndex), group, ...aggregate.slice(newGroupIndex + 1)];
  }, []) || [];
  return groupedData.map((group) => {
    var _a;
    return __spreadProps(__spreadValues({}, group), {
      items: (_a = group.items) == null ? void 0 : _a.map((item) => {
        var _a2;
        return __spreadProps(__spreadValues({}, item), { groupTotal: (_a2 = group.items) == null ? void 0 : _a2.length });
      })
    });
  });
};
const styles = createStyles({
  expandRow: {
    cursor: "pointer"
  },
  expandGroupIcon: {
    position: "relative",
    top: 7,
    left: -4,
    transform: "rotate(-90deg)",
    "& > svg": {
      transform: "none"
    },
    height: 24,
    width: 24
  },
  shrinkGroupIcon: {
    position: "relative",
    top: 7,
    left: -4,
    height: 24,
    width: 24
  }
});
const useGroupsStyles = makeOverrideableStyles("DataTableGroups", styles);
const useDataTableGroups = (data, props) => {
  const { groupBy, groupField, allGroupsExpanded } = props;
  const classes = useGroupsStyles(props);
  const [expanded, setExpanded] = useState({});
  const onExpandChange = useCallback((event) => {
    const groupValue = get(event, "dataItem.value");
    if (groupValue) {
      setExpanded(__spreadProps(__spreadValues({}, expanded), { [groupValue]: event.value }));
    }
  }, []);
  const group = useMemo(() => [{ field: groupBy }], [groupBy]);
  const memoizedData = useDeepCompareMemoize(groupBy ? groupData(data, groupBy, expanded, allGroupsExpanded) : data);
  const cellRender = useDeepCompareCallback((cell, cellProps, additionalRender) => {
    const { columnIndex, dataIndex, dataItem } = cellProps;
    if (groupBy && dataIndex === -1) {
      const expandedState = get(dataItem, "expanded", true);
      return cell && React.cloneElement(
        cell,
        {
          onClick: (event) => {
            var _a, _b;
            const isGroup = (_b = (_a = event == null ? void 0 : event.target) == null ? void 0 : _a.closest) == null ? void 0 : _b.call(_a, ".k-grouping-row");
            isGroup && setExpanded(__spreadProps(__spreadValues({}, expanded), {
              [get(dataItem, "value")]: !expandedState
            }));
          },
          className: classes.expandRow
        },
        /* @ts-ignore more issues related to children of CellRender */
        /* @__PURE__ */ jsxs("span", { children: [
          /* @__PURE__ */ jsx(ArrowDropDownIcon, { className: expandedState ? classes.shrinkGroupIcon : classes.expandGroupIcon }),
          React.cloneElement(groupField || /* @__PURE__ */ jsx(TextField, {}), {
            data: dataItem,
            source: "value",
            value: dataItem.value
          })
        ] })
      );
    }
    if (groupBy && columnIndex === 0) {
      return cell;
    }
    return additionalRender ? additionalRender(cell, cellProps) : cell;
  }, [groupBy, groupField]);
  return {
    onExpandChange: groupBy ? onExpandChange : void 0,
    expandField: "expanded",
    group: groupBy ? group : void 0,
    data: memoizedData,
    cellRender
  };
};
export {
  useDataTableGroups
};
