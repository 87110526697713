import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import {
  ActionButtonsField,
  ChipArrayField,
  CustomField,
  DeleteDialog,
  DisableableButton,
  EmptyPageContent,
  getArrayDataContent,
  IconPreview,
  TablePage,
  TextField,
  useCrudProps,
  useMixpanel
} from "@cuda-react/core";
import { AppCatalogGraphic, GetStartedBackgroundGraphic } from "@cuda-react/icons";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import { Dialog } from "@barracuda-internal/bds-core";
import apiResources from "../../../apiResources";
import CreateEditApplication from "./CreateEditApplication";
import { User, UserGroup } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { categoryIcons } from "../../../components/iconMapping";
import { sortBy } from "lodash";
const AppCatalog = () => {
  const registerAction = useMixpanel("App Catalog");
  const tableRefreshRef = useRef(null);
  const [actionVisible, setActionVisible] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const imageFileTypes = getArrayDataContent(useCrudProps(apiResources.customResponseImageTypes)[0]);
  const ztnaBasedApps = getArrayDataContent(useCrudProps(apiResources.zeroTrustApplicationsZtnaBased, { pagination: { page: 0, perPage: 3e3 } })[0]);
  const visibilityChoices = sortBy(getArrayDataContent(useCrudProps(apiResources.visibility)[0]), "name");
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      TablePage,
      {
        title: "tesseract.endpoint.pageTitle",
        subtitle: "tesseract.endpoint.appCatalog.pageTitle",
        resource: apiResources.appCatalog,
        minCellWidth: 128,
        compact: true,
        refreshRef: tableRefreshRef,
        titleActions: actionVisible && /* @__PURE__ */ jsx(
          DisableableButton,
          {
            buttonText: "tesseract.endpoint.appCatalog.buttonText",
            onClick: () => setCreateOpen(true)
          },
          "create"
        ),
        noDataPage: /* @__PURE__ */ jsx(
          EmptyPageContent,
          {
            background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
            icon: /* @__PURE__ */ jsx(AppCatalogGraphic, {}),
            iconHeader: "tesseract.endpoint.appCatalog.startTextHeader",
            text: "tesseract.endpoint.appCatalog.startText",
            actions: /* @__PURE__ */ jsx(
              DisableableButton,
              {
                buttonText: "tesseract.endpoint.appCatalog.buttonText",
                onClick: () => setCreateOpen(true)
              },
              "create"
            )
          }
        ),
        onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table"),
        children: [
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "icon",
              label: "tesseract.endpoint.appCatalog.iconText",
              width: 64,
              render: (iconData, data) => /* @__PURE__ */ jsx(
                IconPreview,
                {
                  icon: iconData,
                  filename: data == null ? void 0 : data.iconFilename,
                  name: data == null ? void 0 : data.name,
                  supportedFiles: imageFileTypes
                }
              )
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "name",
              label: "tesseract.endpoint.appCatalog.name",
              filter: "text",
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "app",
              label: "tesseract.endpoint.appCatalog.application",
              filter: "selectarray",
              choices: ztnaBasedApps,
              filterProps: {
                resource: apiResources.zeroTrustApplicationsZtnaBased,
                optionIcon: "categoryId",
                optionValue: "id",
                iconMap: categoryIcons
              },
              iconMap: categoryIcons,
              optionValue: "id",
              optionIcon: "categoryId"
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "url",
              label: "tesseract.endpoint.appCatalog.launchURL",
              filter: "text",
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "visibility",
              label: "tesseract.endpoint.appCatalog.visibility",
              filter: "select",
              filterProps: {
                choices: visibilityChoices
              },
              render: (value, data) => {
                var _a, _b, _c, _d;
                if ((data == null ? void 0 : data.visibility) === "usersAndGroups") {
                  const resolvedUsers = ((_a = data == null ? void 0 : data.users) == null ? void 0 : _a.map((_user, index) => {
                    var _a2;
                    return (_a2 = data == null ? void 0 : data.resolvedUsers) == null ? void 0 : _a2[index];
                  })) || [];
                  const resolvedGroups = ((_b = data == null ? void 0 : data.groups) == null ? void 0 : _b.map((_user, index) => {
                    var _a2;
                    return (_a2 = data == null ? void 0 : data.resolvedGroups) == null ? void 0 : _a2[index];
                  })) || [];
                  const userGroups = [
                    ...resolvedGroups.map((name) => ({ name, type: "group" })),
                    ...resolvedUsers.map((name) => ({ name, type: "user" }))
                  ];
                  return /* @__PURE__ */ jsx(
                    ChipArrayField,
                    {
                      data: { userGroups },
                      source: "userGroups",
                      sourceValue: "name",
                      optionIcon: "type",
                      iconMap: {
                        user: /* @__PURE__ */ jsx(User, {}),
                        group: /* @__PURE__ */ jsx(UserGroup, {})
                      }
                    }
                  );
                } else if ((data == null ? void 0 : data.visibility) === "ztnaBased") {
                  if (!((_c = data == null ? void 0 : data.resolvedUsers) == null ? void 0 : _c.length) && !((_d = data == null ? void 0 : data.resolvedGroups) == null ? void 0 : _d.length)) {
                    return /* @__PURE__ */ jsx(
                      ChipArrayField,
                      {
                        data: { value: "all" },
                        source: "value",
                        choices: visibilityChoices,
                        optionText: "name"
                      }
                    );
                  }
                  const userGroups = [
                    ...((data == null ? void 0 : data.resolvedGroups) || []).map((name) => ({ name, type: "group" })),
                    ...((data == null ? void 0 : data.resolvedUsers) || []).map((name) => ({ name, type: "user" }))
                  ];
                  return /* @__PURE__ */ jsx(
                    ChipArrayField,
                    {
                      data: { userGroups },
                      source: "userGroups",
                      sourceValue: "name",
                      optionIcon: "type",
                      iconMap: {
                        user: /* @__PURE__ */ jsx(User, {}),
                        group: /* @__PURE__ */ jsx(UserGroup, {})
                      }
                    }
                  );
                } else {
                  return /* @__PURE__ */ jsx(
                    ChipArrayField,
                    {
                      data: { value },
                      source: "value",
                      choices: visibilityChoices,
                      optionText: "name"
                    }
                  );
                }
              }
            }
          ),
          /* @__PURE__ */ jsxs(
            ActionButtonsField,
            {
              source: "id",
              width: 96,
              alwaysVisible: true,
              children: [
                /* @__PURE__ */ jsx(
                  CreateEditButtonDialog,
                  {
                    component: CreateEditApplication,
                    onSuccess: () => {
                      var _a;
                      registerAction("Update");
                      (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                    }
                  }
                ),
                /* @__PURE__ */ jsx(
                  DeleteDialog,
                  {
                    resource: apiResources.appCatalog,
                    title: "tesseract.endpoint.appCatalog.delete.title",
                    message: "tesseract.endpoint.appCatalog.delete.body",
                    onSuccess: () => {
                      var _a;
                      registerAction("Delete");
                      (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                    }
                  }
                )
              ]
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Dialog,
      {
        open: createOpen,
        maxWidth: false,
        disableEnforceFocus: true,
        children: /* @__PURE__ */ jsx(
          CreateEditApplication,
          {
            onSuccess: () => {
              var _a;
              registerAction("Create");
              (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
            },
            create: true,
            onClose: () => setCreateOpen(false)
          }
        )
      }
    )
  ] });
};
var AppCatalog_default = AppCatalog;
export {
  AppCatalog_default as default
};
