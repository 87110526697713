var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { SAVE_ORIGIN, SAVE_LOGIN_URL } from "../../actions";
const AuthReducer = (previousState = {}, { type, origin, loginUrl }) => {
  if (type === SAVE_ORIGIN) {
    if (origin === null) {
      return { loginUrl: previousState.loginUrl };
    } else if (origin !== previousState.origin) {
      return __spreadProps(__spreadValues({}, previousState), { origin, previousOrigin: previousState.origin });
    }
  }
  if (type === SAVE_LOGIN_URL) {
    return __spreadProps(__spreadValues({}, previousState), { loginUrl: loginUrl || null });
  }
  return previousState;
};
var AuthReducer_default = AuthReducer;
export {
  AuthReducer_default as default
};
