import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const EventAllIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-event-all",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
          d: "M37.4,10.3v17.2H13.2l-2.9,2.9V10.3h27.1ZM39.9,5.4H7.8c-1.4,0-2.5,1.1-2.5,2.5v34.5l9.9-9.9h24.6c1.4,0,2.5-1.1,2.5-2.5V7.8c0-1.4-1.1-2.5-2.5-2.5ZM52.2,15.2h-4.9v22.2H15.2v4.9c0,1.4,1.1,2.5,2.5,2.5h27.1l9.9,9.9V17.7c0-1.4-1.1-2.5-2.5-2.5Z"
        }
      )
    }
  );
};
var EventAllIcon_default = EventAllIcon;
export {
  EventAllIcon_default as default
};
