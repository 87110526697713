import { jsx, jsxs } from "react/jsx-runtime";
import {
  ConnectedFormPage,
  getArrayDataContent,
  SelectInput,
  TextInput,
  useCrudProps,
  useMixpanel
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import { Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  message: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1)
  }
}));
const VmActivation = () => {
  var _a;
  const countries = getArrayDataContent((_a = useCrudProps(apiResources.vmActivationCountries, {}, {}, true)[0]) == null ? void 0 : _a.data);
  const [translate] = useTranslation();
  const classes = useStyles();
  const registerAction = useMixpanel("VM Activation");
  return /* @__PURE__ */ jsxs(
    ConnectedFormPage,
    {
      title: "tesseract.network.pageTitle",
      subtitle: "tesseract.network.vmActivation",
      resource: apiResources.vmActivation,
      onSubmitSuccess: () => {
        registerAction("Update");
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.message, children: translate("tesseract.network.vmActivationMessage") }),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "firstName",
            label: "tesseract.settings.tabs.vmActivation.firstName",
            description: "tesseract.settings.tabs.vmActivation.descriptions.firstName",
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "lastName",
            label: "tesseract.settings.tabs.vmActivation.lastName",
            description: "tesseract.settings.tabs.vmActivation.descriptions.lastName",
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "emailAddress",
            label: "tesseract.settings.tabs.vmActivation.emailAddress",
            description: "tesseract.settings.tabs.vmActivation.descriptions.emailAddress",
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "phoneNumber",
            label: "tesseract.settings.tabs.vmActivation.phoneNumber",
            description: "tesseract.settings.tabs.vmActivation.descriptions.phoneNumber",
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "companyName",
            label: "tesseract.settings.tabs.vmActivation.companyName",
            description: "tesseract.settings.tabs.vmActivation.descriptions.companyName",
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "address",
            label: "tesseract.settings.tabs.vmActivation.address",
            description: "tesseract.settings.tabs.vmActivation.descriptions.address",
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "address2",
            label: "tesseract.settings.tabs.vmActivation.address2",
            description: "tesseract.settings.tabs.vmActivation.descriptions.address2"
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "city",
            label: "tesseract.settings.tabs.vmActivation.city",
            description: "tesseract.settings.tabs.vmActivation.descriptions.city",
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "state",
            label: "tesseract.settings.tabs.vmActivation.state",
            description: "tesseract.settings.tabs.vmActivation.descriptions.state",
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          SelectInput,
          {
            source: "country",
            label: "tesseract.settings.tabs.vmActivation.country",
            description: "tesseract.settings.tabs.vmActivation.descriptions.country",
            choices: countries,
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "zip",
            label: "tesseract.settings.tabs.vmActivation.zip",
            description: "tesseract.settings.tabs.vmActivation.descriptions.zip",
            isRequired: true
          }
        )
      ]
    }
  );
};
var VmActivation_default = VmActivation;
export {
  VmActivation_default as default
};
