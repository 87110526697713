import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography } from "@barracuda-internal/bds-core";
import { getArrayDataContent, TextInput, useCrudProps } from "@cuda-react/core";
import { useSiteInputValidations } from "../../../../../hooks/createEditSiteHooks";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useWatch } from "react-hook-form";
import apiResources from "../../../../../apiResources";
const useStyles = makeStyles((theme) => ({
  message: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
const SelectLanWanLengthStep = ({ applianceType, type }) => {
  var _a;
  const inputValidations = useSiteInputValidations();
  const classes = useStyles();
  const [translate] = useTranslation();
  const [model, wanTotal = 1, wans = []] = useWatch({ name: ["serials.0.model", "wanTotal", "wans"] });
  const hasWanBridge = wans.slice(0, wanTotal).some((wan) => wan.mode === "bridge");
  const portsData = getArrayDataContent((_a = useCrudProps(apiResources.modelPorts, { id: model })[0]) == null ? void 0 : _a.data);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: translate(`tesseract.appliances.dialog.${type}.message`, {
      portsAvailable: portsData && portsData.length,
      wans: wanTotal
    }) }),
    /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: translate(`tesseract.appliances.dialog.${type}.message2`, { context: applianceType }) }),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        label: `tesseract.appliances.dialog.${type}.size`,
        source: type === "wans" ? "wanTotal" : "lanTotal",
        isRequired: true,
        validate: type === "wans" ? inputValidations.validateWansLength : inputValidations.validateLansLength,
        type: "integer",
        options: {
          type: "number",
          InputProps: {
            inputProps: {
              min: type === "lans" && hasWanBridge ? 0 : 1,
              max: 16,
              step: 1
            }
          }
        }
      }
    )
  ] });
};
var SelectLanWanLengthStep_default = SelectLanWanLengthStep;
export {
  SelectLanWanLengthStep_default as default
};
