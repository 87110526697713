var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import {
  ConnectedWizard,
  getArrayDataContent,
  InputStep,
  MultiStep,
  ResultStep,
  SubmitStep,
  useCrudProps,
  useGlobalParam,
  useMixpanel,
  validatePrivateIpv4
} from "@cuda-react/core";
import { get, set } from "lodash";
import { useTranslation } from "react-i18next";
import { validateNames, validateNetworks } from "../../../hooks/createEditSiteHooks";
import BasicStep from "./steps/common/BasicStep";
import SerialStep from "./steps/common/SerialStep";
import SelectLanWanLengthStep from "./steps/tvt/SelectLanWanLengthStep";
import WanStep from "./steps/tvt/WanStep";
import LanStep from "./steps/tvt/LanStep";
import AdvancedStep from "./steps/common/AdvancedStep";
import SiteSubmitStep from "./steps/common/SiteSubmitStep";
import SiteResultStep from "./steps/tvt/SiteResultStep";
import { makeStyles } from "@mui/styles";
import CreateTypeStep from "./steps/tvt/CreateTypeStep";
import SelectSiteStep from "./steps/promote/SelectSiteStep";
import PromoteSubmitStep from "./steps/promote/PromoteSubmitStep";
import PromoteResultStep from "./steps/promote/PromoteResultStep";
import apiResources from "../../../apiResources";
import { format } from "../edit/EditTVTContent";
import { SELECT_EMPTY_VALUE } from "../../../utils/constants";
const useStyles = makeStyles((theme) => ({
  root: {},
  dialogContent: {
    width: 850
  },
  standardInputStep: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(8)
  },
  submitStep: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8)
  }
}));
const CreateTVTContent = (_a) => {
  var _b = _a, {
    onSuccess,
    title,
    type
  } = _b, wizardProps = __objRest(_b, [
    "onSuccess",
    "title",
    "type"
  ]);
  var _a2;
  const registerAction = useMixpanel(
    type === "gateway" ? "Gateways Create Gateway" : "Sites Create Site",
    "Start",
    type === "site" ? { type: "T" } : void 0
  );
  const [translate] = useTranslation();
  const classes = useStyles();
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const [wanTotal, setWanTotal] = useState(2);
  const [lanTotal, setLanTotal] = useState(1);
  const [createMode, setCreateMode] = useState();
  const [siteId, setSiteId] = useState();
  const promoteChoices = getArrayDataContent((_a2 = useCrudProps(createMode === "promote" ? apiResources.sitePromotable : void 0)[0]) == null ? void 0 : _a2.data);
  const memoedWizardProps = useMemo(
    () => {
      const common = {
        onChange: (newValues) => {
          const wanTotal2 = parseInt(get(newValues, "wanTotal", 2)) || 2;
          const lanTotal2 = parseInt(get(newValues, "lanTotal", 1)) || 0;
          setWanTotal(wanTotal2 > 0 ? wanTotal2 : 1);
          setLanTotal(lanTotal2 > 0 ? lanTotal2 : 0);
          setCreateMode(get(newValues, "createMode"));
          setSiteId(get(newValues, "siteId"));
        },
        initialValues: {
          wans: [
            { port: "p4", wanPort: "p4", name: "ISP-Uplink-1" },
            { name: "ISP-Uplink-2" }
          ],
          lans: [],
          wanTotal: "2",
          lanTotal: "1"
        },
        onSubmitSuccess: (response) => {
          var _a3, _b2, _c;
          registerAction(
            "Success",
            createMode === "promote" ? {
              action: "promote"
            } : {
              model: response.model,
              hasUpstream: type === "gateway" ? !!response.gateway : "",
              type: type === "site" ? "T" : "on-prem",
              wansLength: (_a3 = response.wans) == null ? void 0 : _a3.length,
              lansLength: (_b2 = response.lans) == null ? void 0 : _b2.length,
              isHa: ((_c = response.serials) == null ? void 0 : _c.length) > 1,
              action: type === "gateway" ? "create" : ""
            }
          );
          onSuccess == null ? void 0 : onSuccess(response);
        }
      };
      return createMode === "promote" ? __spreadProps(__spreadValues({}, common), {
        resource: apiResources.sitePromote,
        submitParams: { id: siteId }
      }) : __spreadProps(__spreadValues({}, common), {
        resource: type === "site" ? apiResources.siteConfiguration : apiResources.gatewaysOnPrem,
        formatRequestData: (data) => Object.assign({}, data, {
          gateway: data.gateway === SELECT_EMPTY_VALUE ? null : data.gateway,
          upstream: data.upstream === SELECT_EMPTY_VALUE ? null : data.upstream,
          serials: data.serials.map((appliance) => appliance.serial),
          wans: data.wans.slice(0, data.wanTotal).map((wan) => __spreadProps(__spreadValues({}, wan), {
            virtualLanId: wan.virtualLanId ? parseInt(wan.virtualLanId) : null,
            port: wan.mode !== "wwan" ? wan.port : void 0
          })).filter((wan) => wan.mode !== "bridge"),
          lans: data.lans.slice(0, data.lanTotal).map((lan) => __spreadProps(__spreadValues({}, lan), {
            virtualLanId: lan.virtualLanId ? parseInt(lan.virtualLanId) : null,
            dhcp: get(lan, "dhcp.type") ? get(lan, "dhcp") : { type: "disabled" }
          })),
          bridges: data.wans.slice(0, data.wanTotal).filter((wan) => wan.mode === "bridge").map((bridge) => __spreadProps(__spreadValues({}, bridge), {
            name: bridge.name,
            type: "inline",
            lanPorts: bridge.lanPorts,
            wanPort: bridge.wanPort,
            address: bridge.address,
            netmask: bridge.netmask,
            gateway: bridge.gateway,
            provider: bridge.provider,
            dhcp: {
              type: "disabled"
            }
          })),
          virtualWan: virtualWanId
        }),
        validate: (values = {}) => {
          const errors = {};
          const totalWans = parseInt(get(values, "wanTotal")) || 1;
          const wanBridgeCount = get(values, "wans", []).slice(0, totalWans).filter((wan) => wan.mode === "bridge").length;
          const totalLans = parseInt(get(values, "lanTotal", "1")) || 0;
          const usedNetworks = validateNetworks(values, "wans", totalWans, translate, errors);
          validateNetworks(values, "lans", totalLans, translate, errors, usedNetworks);
          const usedNetworkNames = validateNames(values, "wans", totalWans, translate, errors);
          validateNames(values, "lans", totalLans, translate, errors, usedNetworkNames);
          validateNames(values, "routes", void 0, translate, errors);
          if (totalLans < 1 && wanBridgeCount === 0) {
            set(errors, "lanTotal", translate("tesseract.appliances.dialog.validation.noLans"));
          }
          get(values, "wans", []).map((wan, index) => __spreadProps(__spreadValues({}, wan), { index })).filter(({ mode }) => mode === "pppoe").slice(4).forEach(({ index }) => set(errors, `wans[${index}].mode`, translate("tesseract.appliances.dialog.validation.exceededPPoE")));
          if (wanBridgeCount > 1) {
            values.wans.forEach((wan, index) => {
              if (wan.mode === "bridge") {
                set(errors, `wans[${index}].mode`, translate("tesseract.appliances.dialog.validation.oneInlineBridge"));
              }
            });
          }
          values.secondaryDns && !values.primaryDns && set(errors, "secondaryDns", translate("tesseract.appliances.dialog.validation.secondaryDns"));
          if (get(values, "wans[0].address")) {
            const privateIp = !validatePrivateIpv4(get(values, "wans[0].address"), values, { t: translate });
            type === "gateway" && get(values, "wans[0].address") && privateIp && set(errors, "wans[0].address", translate("tesseract.appliances.dialog.validation.publicIp"));
          }
          return errors;
        },
        formatError: format.error
      });
    },
    [createMode, siteId]
  );
  return /* @__PURE__ */ jsxs(
    ConnectedWizard,
    __spreadProps(__spreadValues(__spreadValues({
      title,
      dialogBodyProps: { classes: { dialogContent: classes.dialogContent } }
    }, memoedWizardProps), wizardProps), {
      children: [
        type === "gateway" ? /* @__PURE__ */ jsx(InputStep, { label: "tesseract.appliances.dialog.createType.sectionTitle", children: /* @__PURE__ */ jsx(CreateTypeStep, {}) }) : null,
        createMode === "promote" ? /* @__PURE__ */ jsx(
          InputStep,
          {
            label: "tesseract.appliances.dialog.siteSelection.sectionTitle",
            classes: { content: classes.standardInputStep },
            children: /* @__PURE__ */ jsx(SelectSiteStep, { choices: promoteChoices })
          },
          "siteSelect"
        ) : null,
        createMode === "promote" ? /* @__PURE__ */ jsx(
          SubmitStep,
          {
            label: "tesseract.appliances.dialog.steps.submit",
            classes: { content: classes.submitStep },
            children: /* @__PURE__ */ jsx(PromoteSubmitStep, { choices: promoteChoices })
          },
          "promoteSubmit"
        ) : null,
        createMode === "promote" ? /* @__PURE__ */ jsx(
          ResultStep,
          {
            label: "tesseract.appliances.dialog.steps.result",
            onClose: wizardProps.onClose,
            children: /* @__PURE__ */ jsx(PromoteResultStep, {})
          },
          "promoteResult"
        ) : null,
        createMode !== "promote" ? /* @__PURE__ */ jsx(
          InputStep,
          {
            label: "tesseract.appliances.dialog.basic.sectionTitle",
            classes: { content: classes.standardInputStep },
            children: /* @__PURE__ */ jsx(BasicStep, { applianceType: type })
          },
          "basic"
        ) : null,
        createMode !== "promote" ? /* @__PURE__ */ jsx(
          InputStep,
          {
            label: translate(
              "tesseract.appliances.dialog.serials.sectionTitle",
              { context: type }
            ),
            children: /* @__PURE__ */ jsx(
              SerialStep,
              {
                allowHa: true,
                series: "T",
                mainMessage: type === "gateway" ? "tesseract.appliances.dialog.serials.gatewayMessage" : "tesseract.appliances.dialog.serials.TVTSiteMessage"
              }
            )
          },
          "serial"
        ) : null,
        createMode !== "promote" ? /* @__PURE__ */ jsx(
          MultiStep,
          {
            label: "tesseract.appliances.dialog.wans.sectionTitle",
            children: [
              /* @__PURE__ */ jsx(InputStep, { classes: { content: classes.standardInputStep }, children: /* @__PURE__ */ jsx(
                SelectLanWanLengthStep,
                {
                  type: "wans",
                  applianceType: type
                }
              ) }, "mainInput"),
              ...Array(Math.min(wanTotal, 16)).fill(null).map((value, index) => /* @__PURE__ */ jsx(
                InputStep,
                {
                  classes: { content: classes.standardInputStep },
                  children: /* @__PURE__ */ jsx(
                    WanStep,
                    {
                      stepIndex: index,
                      series: "T",
                      applianceType: type
                    }
                  )
                },
                index
              ))
            ]
          },
          "wan"
        ) : null,
        createMode !== "promote" ? /* @__PURE__ */ jsx(
          MultiStep,
          {
            label: "tesseract.appliances.dialog.lans.sectionTitle",
            children: [
              /* @__PURE__ */ jsx(InputStep, { classes: { content: classes.standardInputStep }, children: /* @__PURE__ */ jsx(
                SelectLanWanLengthStep,
                {
                  type: "lans",
                  applianceType: type
                }
              ) }, "mainInput"),
              ...Array(Math.min(lanTotal, 16)).fill(null).map((value, index) => /* @__PURE__ */ jsx(
                InputStep,
                {
                  classes: { content: classes.standardInputStep },
                  children: /* @__PURE__ */ jsx(LanStep, { stepIndex: index })
                },
                index
              ))
            ]
          },
          "lan"
        ) : null,
        createMode !== "promote" ? /* @__PURE__ */ jsx(
          InputStep,
          {
            label: "tesseract.appliances.dialog.advanced.sectionTitle",
            classes: { content: classes.standardInputStep },
            children: /* @__PURE__ */ jsx(AdvancedStep, { applianceType: type })
          },
          "advanced"
        ) : null,
        createMode !== "promote" ? /* @__PURE__ */ jsx(
          SubmitStep,
          {
            label: "tesseract.appliances.dialog.steps.submit",
            classes: { content: classes.submitStep },
            children: /* @__PURE__ */ jsx(SiteSubmitStep, { applianceType: type })
          },
          "submit"
        ) : null,
        createMode !== "promote" ? /* @__PURE__ */ jsx(
          ResultStep,
          {
            label: "tesseract.appliances.dialog.steps.result",
            onClose: wizardProps.onClose,
            children: /* @__PURE__ */ jsx(SiteResultStep, { applianceType: type })
          },
          "result"
        ) : null
      ]
    })
  );
};
var CreateTVTContent_default = CreateTVTContent;
export {
  CreateTVTContent_default as default
};
