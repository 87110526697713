var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
import { DateTimePicker } from "@mui/x-date-pickers";
import classNames from "classnames";
import { lightFormat, set } from "date-fns";
import { formatErrorMessage } from "../../../utils";
const styles = createStyles({
  root: {
    width: 256,
    "& label": {
      fontSize: 14,
      transform: "translate(0px, 0px) scale(1) !important"
    },
    "& fieldset > legend": {
      width: 0
    },
    "& input": {
      cursor: "pointer"
    }
  }
});
const useStyles = makeOverrideableStyles("DateTime", styles);
const DateTime = (props) => {
  const {
    className,
    disabled,
    error,
    format = "yyyy-MM-dd HH:mm",
    // the format which the user sees the date in
    id,
    label,
    onChange,
    pickerProps,
    value
  } = props;
  const classes = useStyles(props);
  const helperText = formatErrorMessage(error);
  const handleOnChange = (date) => {
    const roundedDate = set(date || Date.now(), { seconds: 0 });
    const dateString = lightFormat(roundedDate, "yyyy-MM-dd'T'HH:mm:ss");
    onChange == null ? void 0 : onChange(dateString);
  };
  return /* @__PURE__ */ jsx(
    DateTimePicker,
    __spreadValues({
      slotProps: {
        textField: {
          helperText,
          error: !!error,
          id: id && "datetime-input-" + id
        }
      },
      label,
      format,
      className: classNames(classes.root, className),
      value: (typeof value === "string" ? new Date(value) : value) || null,
      disabled,
      onChange: handleOnChange,
      ampm: false
    }, pickerProps)
  );
};
var DateTime_default = DateTime;
export {
  DateTime,
  DateTime_default as default
};
