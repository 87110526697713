import { jsx, jsxs } from "react/jsx-runtime";
import { ResourceStatisticsCard } from "../../baseCards";
import generatePreviewData from "../../../utils/previewDataGenerators/zeroTrustUsers";
import { useCardFetch } from "../../../hooks";
import { CustomField, StatusIconField, TextField } from "@cuda-react/core";
import { Help } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { Success } from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import apiResources from "../../../apiResources";
import { useTheme } from "@mui/material";
const UserStatusCard = (props) => {
  const { preview, size } = props;
  const { data = {} } = useCardFetch({ url: !preview ? apiResources.zeroTrustUsers : void 0 });
  const { total, enrolled, pending } = (preview ? generatePreviewData() : data) || {};
  const theme = useTheme();
  const overviewData = [
    { title: "stratosReporting.userStatusCard.enrolled", value: enrolled, color: theme.palette.success.main },
    { title: "stratosReporting.userStatusCard.pending", value: pending, color: theme.palette.info.main }
  ];
  return /* @__PURE__ */ jsxs(
    ResourceStatisticsCard,
    {
      overViewValue: total,
      overviewTitle: "stratosReporting.userStatusCard.title",
      overViewData: overviewData,
      preview,
      size,
      resource: apiResources.userStatus,
      tableTitle: "stratosReporting.userStatusCard.tableTitle",
      children: [
        /* @__PURE__ */ jsx(TextField, { source: "fullName", label: "stratosReporting.userStatusCard.name" }),
        /* @__PURE__ */ jsx(TextField, { source: "email", label: "stratosReporting.userStatusCard.email" }),
        /* @__PURE__ */ jsx(TextField, { source: "deviceCount", label: "stratosReporting.userStatusCard.deviceCount" }),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "deviceCount",
            label: "stratosReporting.userStatusCard.enrollmentCompleted.label",
            render: (value, data2) => data2 && ((data2 == null ? void 0 : data2.deviceCount) > 0 ? /* @__PURE__ */ jsx(StatusIconField, { icon: /* @__PURE__ */ jsx(Success, { style: { color: theme.palette.success.main } }), tooltip: "stratosReporting.userStatusCard.enrollmentCompleted.yes" }) : /* @__PURE__ */ jsx(StatusIconField, { icon: /* @__PURE__ */ jsx(Help, {}), tooltip: "stratosReporting.userStatusCard.enrollmentCompleted.no" })),
            columnProps: {
              field: "deviceCount"
            }
          }
        )
      ]
    }
  );
};
export {
  UserStatusCard
};
