import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import {
  Card,
  ChipArrayField,
  CustomField,
  DateField,
  getArrayDataContent,
  LinkField,
  ListCardContent,
  SelectedChoiceField,
  StatusIconField,
  TableCardContent,
  TextField,
  useCrudProps,
  useGlobalParam
} from "@cuda-react/core";
import { useTranslation } from "react-i18next";
import { StatusIcon } from "@cuda-react/icons";
import { makeStyles } from "@mui/styles";
import { getAzureHubLink, getAzureResourceLink } from "../../../../utils/azureUtils";
import { get } from "lodash";
import apiResources from "../../../../apiResources";
import { siteGatewayStatusIcons } from "../../../../components/iconMapping";
const useStyles = makeStyles((theme) => ({
  serialIconSpan: {
    lineHeight: 3,
    marginLeft: -7,
    "& svg": {
      position: "relative",
      top: 7,
      marginRight: theme.spacing(1)
    }
  }
}));
const SiteSummaryCard = ({ details }) => {
  var _a, _b, _c, _d;
  const classes = useStyles();
  const [translate] = useTranslation();
  const isSc = get(details, "modelSeries") === "S" || !get(details, "modelSeries");
  const isDemo = useGlobalParam("userData.mode")[0] === "demo";
  const regionChoices = getArrayDataContent((_a = useCrudProps(apiResources.gatewayRegions)[0]) == null ? void 0 : _a.data);
  const scaleUnitChoices = getArrayDataContent((_b = useCrudProps(apiResources.gatewayScaleUnits)[0]) == null ? void 0 : _b.data);
  const licenseStateChoices = getArrayDataContent((_c = useCrudProps(!isSc ? apiResources.licensesStates : void 0)[0]) == null ? void 0 : _c.data);
  const licenseTypeChoices = getArrayDataContent((_d = useCrudProps(!isSc ? apiResources.licensesTypes : void 0)[0]) == null ? void 0 : _d.data);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Card,
      {
        id: "SiteSummaryCard",
        title: "tesseract.appliances.dashboard.summary.siteStatus.title",
        children: /* @__PURE__ */ jsxs(
          ListCardContent,
          {
            getFrom: {
              resource: apiResources.siteDashboardStatus,
              params: { id: details == null ? void 0 : details.id }
            },
            children: [
              /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dashboard.summary.siteStatus.model", source: "model" }),
              /* @__PURE__ */ jsx(
                ChipArrayField,
                {
                  label: `tesseract.appliances.dashboard.summary.siteStatus.serial${isSc ? "" : "s"}`,
                  source: "connectionStatus.connectionStates",
                  render: (val) => /* @__PURE__ */ jsxs("span", { className: classes.serialIconSpan, children: [
                    /* @__PURE__ */ jsx(
                      StatusIcon,
                      {
                        iconMap: siteGatewayStatusIcons,
                        status: val.state
                      }
                    ),
                    val.id
                  ] })
                }
              ),
              /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dashboard.summary.siteStatus.release", source: "version" })
            ]
          }
        )
      }
    ),
    !isSc && /* @__PURE__ */ jsx(
      Card,
      {
        id: "SiteLicencesCard",
        title: "tesseract.appliances.dashboard.summary.licenses.title",
        children: /* @__PURE__ */ jsxs(
          TableCardContent,
          {
            resource: (details == null ? void 0 : details.id) ? apiResources.licensesState : void 0,
            params: { id: details == null ? void 0 : details.id },
            formatData: (data) => (data == null ? void 0 : data.licenseStates) || [],
            minCellWidth: 64,
            children: [
              /* @__PURE__ */ jsx(
                StatusIconField,
                {
                  label: "tesseract.appliances.dashboard.summary.licenses.status",
                  source: "state"
                }
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  label: "tesseract.appliances.dashboard.summary.licenses.serial",
                  source: "instanceId"
                }
              ),
              /* @__PURE__ */ jsx(
                SelectedChoiceField,
                {
                  label: "tesseract.appliances.dashboard.summary.licenses.state",
                  source: "state",
                  choices: licenseStateChoices
                },
                "textState"
              ),
              /* @__PURE__ */ jsx(
                SelectedChoiceField,
                {
                  source: "type",
                  label: "tesseract.appliances.dashboard.summary.licenses.type",
                  choices: licenseTypeChoices
                }
              ),
              /* @__PURE__ */ jsx(
                CustomField,
                {
                  source: "expiryDate",
                  label: "tesseract.appliances.dashboard.summary.licenses.expirationDate",
                  render: (expiryDate, data) => !(data.type === "payg" && data.state === "ok") ? /* @__PURE__ */ jsx(DateField, { data, source: "expiryDate" }) : /* @__PURE__ */ jsx(
                    TextField,
                    {
                      source: "data",
                      data: { data: translate("tesseract.appliances.dashboard.summary.licenses.noData") }
                    }
                  )
                }
              )
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      Card,
      {
        id: "SiteGatewayCard",
        title: "tesseract.appliances.dashboard.summary.gateway.tabTitle",
        children: /* @__PURE__ */ jsxs(
          ListCardContent,
          {
            resource: (details == null ? void 0 : details.gateway) ? apiResources.gateways : void 0,
            params: { id: details == null ? void 0 : details.gateway },
            noDataMessage: !(details == null ? void 0 : details.gateway) ? "tesseract.appliances.dashboard.summary.gateway.noGateway" : void 0,
            children: [
              /* @__PURE__ */ jsx(
                LinkField,
                {
                  source: "name",
                  label: "tesseract.appliances.dashboard.summary.gateway.name",
                  link: getAzureResourceLink("applicationId", isDemo),
                  sortable: true
                }
              ),
              /* @__PURE__ */ jsx(
                LinkField,
                {
                  source: "virtualWan.name",
                  label: "tesseract.appliances.dashboard.summary.gateway.vwan",
                  link: getAzureResourceLink("virtualWan.resourceId", isDemo),
                  sortable: true
                }
              ),
              /* @__PURE__ */ jsx(
                LinkField,
                {
                  source: "hubName",
                  label: "tesseract.appliances.dashboard.summary.gateway.hubName",
                  link: getAzureHubLink("hubId", isDemo),
                  sortable: true
                }
              ),
              /* @__PURE__ */ jsx(
                SelectedChoiceField,
                {
                  source: "region",
                  label: "tesseract.appliances.dashboard.summary.gateway.region",
                  choices: regionChoices,
                  sortable: true
                }
              ),
              /* @__PURE__ */ jsx(
                SelectedChoiceField,
                {
                  label: "tesseract.appliances.dashboard.summary.gateway.size",
                  source: "scaleUnit",
                  choices: scaleUnitChoices,
                  skip: (value) => value.scaleUnit === 0
                },
                "scaleUnit"
              )
            ]
          }
        )
      }
    )
  ] });
};
var SiteSummaryCard_default = SiteSummaryCard;
export {
  SiteSummaryCard_default as default
};
