import en from "./en";
import ja from "./ja";
import fr from "./fr";
import de from "./de";
var TesseractMessages_default = {
  de,
  en,
  ja,
  fr
};
export {
  TesseractMessages_default as default
};
