var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { BaseCard } from "../BaseCard";
import { Divider, Grid, Skeleton, Typography } from "@barracuda-internal/bds-core";
import { RiskGaugeIcon, RiskLegend } from "../../icons";
import { createStyles, makeStyles } from "@mui/styles";
import { CardSizeOptions, CardSizes, RiskLevels } from "../../../typesAndConstants";
import { BarGrid } from "../../statistics/BarGrid";
import { useTranslation } from "react-i18next";
import { WidgetInfoIcon } from "@cuda-react/icons";
const styles = (theme) => createStyles({
  container: {
    height: "calc(100% - 8px)"
  },
  overview: {
    height: ({ size }) => size === CardSizes.large ? "calc(50% - 5px)" : "100%"
  },
  breakdown: {
    height: "calc(50% - 5px)",
    paddingLeft: `${theme.spacing(2)} !important`
  },
  breakdownGrid: {
    paddingLeft: theme.spacing(1)
  },
  riskTitle: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.reporting.fontColor,
    fontSize: 16,
    fontWeight: "bold"
  },
  levelLabels: {
    height: 210,
    marginTop: -12,
    width: 150,
    color: theme.palette.reporting.fontColor
  },
  overallRiskLabel: {
    position: "relative",
    bottom: 90,
    textAlign: "center",
    marginBottom: -42,
    color: theme.palette.reporting.fontColor,
    fontWeight: "bold"
  },
  gaugeSkeleton: {
    width: 268,
    height: 192,
    transform: "none"
  },
  noDataContainer: {
    height: ({ size }) => {
      var _a;
      return `calc(${(_a = CardSizeOptions[size]) == null ? void 0 : _a.height}px - ${theme.spacing(10)})`;
    },
    width: "100%"
  },
  noDataIcon: {
    width: 64,
    height: 64
  },
  noDataMessage: {
    textAlign: "center",
    color: theme.palette.reporting.fontColor
  }
});
const useStyles = makeStyles(styles);
const RiskCard = (props) => {
  const { size, riskLevel, entries, loading } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx(
    BaseCard,
    __spreadProps(__spreadValues({}, props), {
      title: "stratosReporting.applicationRisk.title",
      children: !loading && !entries.length ? /* @__PURE__ */ jsxs(Grid, { container: true, justifyContent: "center", alignContent: "center", className: classes.noDataContainer, children: [
        /* @__PURE__ */ jsx(Grid, { xs: 12, style: { textAlign: "center" }, children: /* @__PURE__ */ jsx(WidgetInfoIcon, { className: classes.noDataIcon }) }),
        /* @__PURE__ */ jsx(Grid, { xs: 12, children: /* @__PURE__ */ jsx(Typography, { variant: "h6", className: classes.noDataMessage, children: translate("stratosReporting.barGrid.noData") }) })
      ] }) : /* @__PURE__ */ jsxs(Grid, { container: true, spacing: 1, className: classes.container, children: [
        /* @__PURE__ */ jsxs(
          Grid,
          {
            item: true,
            xs: 12,
            className: classes.overview,
            container: true,
            spacing: 1,
            justifyContent: "space-around",
            alignItems: "center",
            children: [
              loading ? /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(Skeleton, { className: classes.gaugeSkeleton }) }) : /* @__PURE__ */ jsxs(Grid, { item: true, children: [
                /* @__PURE__ */ jsx(RiskGaugeIcon, { riskLevel }),
                /* @__PURE__ */ jsx(Typography, { variant: "h4", className: classes.overallRiskLabel, children: translate(`stratosReporting.applicationRisk.${riskLevel}`) })
              ] }),
              size !== CardSizes.small ? /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsxs(Grid, { container: true, spacing: 1, wrap: "nowrap", justifyContent: "center", alignItems: "center", children: [
                /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(RiskLegend, {}) }),
                /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(
                  Grid,
                  {
                    container: true,
                    spacing: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    className: classes.levelLabels,
                    children: Object.values(RiskLevels).map((level) => /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, children: /* @__PURE__ */ jsx(Typography, { children: translate(`stratosReporting.applicationRisk.${level}`) }) }, level))
                  }
                ) })
              ] }) }) : null
            ]
          }
        ),
        size === CardSizes.large ? /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, children: /* @__PURE__ */ jsx(Divider, {}) }) : null,
        size === CardSizes.large ? /* @__PURE__ */ jsxs(Grid, { item: true, xs: 12, className: classes.breakdown, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "h5", className: classes.riskTitle, children: translate("stratosReporting.applicationRisk.risk") }),
          /* @__PURE__ */ jsx(
            BarGrid,
            {
              size: CardSizes.medium,
              entries,
              loading,
              classes: { container: classes.breakdownGrid }
            }
          )
        ] }) : null
      ] })
    })
  );
};
export {
  RiskCard
};
