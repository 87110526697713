const apiBase = "/api/v1/webui/{userData.currentAccount}";
var apiResources_default = {
  accountList: apiBase + "/user",
  action: apiBase + "/policies/action",
  applianceConfiguration: apiBase + "/dashboard/configurations/deployment/status",
  applianceDeploymentEvents: apiBase + "/appliances/deployment/events/{id}",
  applianceDeploymentLocations: apiBase + "/appliances/deployment/location/{id}",
  applianceDeploymentStatus: apiBase + "/appliances/deployment/status/{id}",
  applianceStates: apiBase + "/dashboard/configurations/deployment/status/states",
  applications: apiBase + "/applications",
  auditEntries: apiBase + "/audit/entries",
  auditEntriesEvents: apiBase + "/audit/entries/types/associated",
  auditEntriesExport: apiBase + "/audit/entries/export",
  auditEvents: apiBase + "/audit/entries/events",
  auditStatuses: apiBase + "/audit/entries/status",
  auditTypes: apiBase + "/audit/entries/types",
  applicationControl: apiBase + "/security/acls/{id}/{changeOrder}",
  applicationControlAll: apiBase + "/security/acls/all",
  applicationControlExport: apiBase + "/security/acls/export",
  applicationControlDefault: apiBase + "/security/acls/default/action/{action}",
  applicationControlActions: apiBase + "/security/acls/actions",
  applicationControlIcmp: apiBase + "/security/acls/icmp",
  azureMonitorRegions: apiBase + "/azuremonitor/regions",
  authenticationType: apiBase + "/ipsec/authentication/types",
  availableAsns: apiBase + "/asn/available",
  claimAppliance: apiBase + "/appliances/claim",
  cloudGateways: apiBase + "/gateways/cloud/{id}",
  cloudVwans: apiBase + "/cloudvirtualwan/all",
  configurationFirmware: apiBase + "/configurations/status/{siteId}/firmware/{id}",
  configurationHotfixes: apiBase + "/configurations/status/{siteId}/hotfixes/{id}",
  configurationInterfaces: apiBase + "/configurations/status/{siteId}/interfaces/{id}",
  configurationResources: apiBase + "/configurations/status/{siteId}/resources/{id}",
  configurationStatus: apiBase + "/configurations/status/{id}/servers",
  configurationServices: apiBase + "/configurations/status/{siteId}/services/{id}",
  configurationUpdate: apiBase + "/configurations/status/{siteId}/configurationupdate/{id}",
  configurationDhcpLeases: apiBase + "/configurations/status/{id}/dhcplease",
  connectionHistoryTypes: apiBase + "/configurations/status/history/types",
  connectionHistoryStates: apiBase + "/configurations/status/history/states",
  configurationOnPremFirmware: apiBase + "/configurations/status/onprem/{siteId}/firmware/{id}",
  configurationOnPremHotfixes: apiBase + "/configurations/status/onprem/{siteId}/hotfixes/{id}",
  configurationOnPremInterfaces: apiBase + "/configurations/status/onprem/{siteId}/interfaces/{id}",
  configurationOnPremResources: apiBase + "/configurations/status/onprem/{siteId}/resources/{id}",
  configurationOnPremStatus: apiBase + "/configurations/status/onprem/{id}/servers",
  configurationOnPremServices: apiBase + "/configurations/status/onprem/{siteId}/services/{id}",
  configurationOnPremUpdate: apiBase + "/configurations/status/onprem/{siteId}/configurationupdate/{id}",
  configurationOnPremDhcpLeases: apiBase + "/configurations/status/onprem/{id}/dhcplease",
  connectionOnPremHistoryTypes: apiBase + "/configurations/status/onprem/history/types",
  connectionOnPremHistoryStates: apiBase + "/configurations/status/onprem/history/states",
  connectionOnPremLiveStates: apiBase + "/configurations/status/onprem/live/connectionstates",
  customApps: apiBase + "/applications/custom/{id}",
  customAppTypes: apiBase + "/applications/custom/types",
  customCategories: apiBase + "/customcategory/{id}",
  customDashboard: apiBase + "/preferences/{id}",
  customResponse: apiBase + "/security/customresponse",
  customResponseImageTypes: apiBase + "/security/customresponse/image/types",
  directoryEnvironments: apiBase + "/directory/environments",
  endpointWebFilterModes: apiBase + "/zerotrust/options/endpointwebfilter/modes",
  downloadSiteBootstrapConfiguration: apiBase + "/sites/{serial}/configuration/bootstrap",
  downloadGatewayBootstrapConfiguration: apiBase + "/gateways/onprem/{serial}/configuration/bootstrap",
  enableSupport: apiBase + "/supportaccess/enable/{uuid}",
  enrollmentUrl: "/api/v1/external/zerotrust/hostname",
  fallback: apiBase + "/policies/fallback",
  firewall: apiBase + "/cgf/{id}",
  firewallNames: apiBase + "/cgf/ids/names",
  registerFirewall: apiBase + "/cgf/token",
  gatewayAuditLog: apiBase + "/gateways/audit/entries/{id}",
  gatewayFirmwareStatus: apiBase + "/configurations/status/gateways/{id}/firmware",
  gatewayCloudHistory: apiBase + "/configurations/status/gateways/{id}/history",
  gatewayCloudHistoryUpdated: apiBase + "/configurations/status/gateways/{id}/history/updated",
  gatewayCloudLive: apiBase + "/configurations/status/gateways/{id}/live",
  gatewayCloudLiveUpdated: apiBase + "/configurations/status/gateways/{id}/live/updated",
  gatewayOnPremHistory: apiBase + "/configurations/status/onprem/{id}/history/{serial}",
  gatewayOnPremHistoryUpdated: apiBase + "/configurations/status/onprem/{id}/history/updated",
  gatewayOnPremLive: apiBase + "/configurations/status/onprem/{id}/live/{serial}",
  gatewayOnPremLiveUpdated: apiBase + "/configurations/status/onprem/{id}/live/updated",
  gatewayNames: apiBase + "/gateways/ids/names",
  gatewayRegions: apiBase + "/gateways/regions",
  gateways: apiBase + "/gateways/{id}",
  gatewaysOnPrem: apiBase + "/gateways/onprem/{id}",
  gatewaysOnPremPorts: apiBase + "/gateways/onprem/{id}/ports",
  gatewaysOnPremUpstream: apiBase + "/gateways/onprem/upstreams/permitted",
  gatewaysOnPremNetworks: apiBase + "/gateways/onprem/networks",
  gatewayHA: apiBase + "/configurations/status/onprem/{id}/serials",
  gatewayScaleUnits: apiBase + "/gateways/scaleunits",
  gatewayStates: apiBase + "/gateways/states",
  gatewayStatus: apiBase + "/dashboard/configurations/gateway/status",
  gatewayTunnelLocation: apiBase + "/gateways/vpn/tunnels/locations/{id}",
  geoLocations: apiBase + "/configurations/status/statistics/countries",
  groups: apiBase + "/directory/groups",
  ingressNatRules: apiBase + "/natingressrules/{id}",
  ingressNatRulesAll: apiBase + "/natingressrules",
  ingressNatRulesExport: apiBase + "/natingressrules/export",
  ingressNatRulesUpdateOrder: apiBase + "/natingressrules/{id}/{changeOrder}",
  ips: apiBase + "/security/ips/{id}/{changeOrder}",
  ipsActions: apiBase + "/security/ips/action",
  ipsExplicit: apiBase + "/security/ips/explicit/{id}/{changeOrder}",
  ipsExplicitActions: apiBase + "/security/ips/explicit/action",
  ipsExplicitDefaultAction: apiBase + "/security/ips/explicit/default/action/{defaultAction}",
  ipsExplicitExport: apiBase + "/security/ips/explicit/export",
  ipsExploits: apiBase + "/security/ips/exploit",
  ipsExport: apiBase + "/security/ips/export",
  ipsMode: apiBase + "/security/ips/mode/{mode}",
  ipsModes: apiBase + "/security/ips/modes",
  ipsEnable: apiBase + "/security/ips/status/{enabled}",
  ipsecV2: apiBase + "/ipsec/{id}",
  restartIpsecV2Tunnel: apiBase + "/ipsec/{id}/restart",
  restartIpsecV2TunnelAvailable: apiBase + "/ipsec/{id}/restart/available",
  ipsecApplicableGatewaysWans: apiBase + "/ipsec/gateways/wans",
  ipsecApplicableSitesWans: apiBase + "/ipsec/sites/wans",
  licensesGateways: apiBase + "/licenses/gateways/onprem/{id}",
  licensesStates: apiBase + "/licenses/states",
  licensesState: apiBase + "/licenses/sites/{id}",
  licensesTypes: apiBase + "/licenses/types",
  loadBalancing: apiBase + "/policies/loadbalancing",
  malware: apiBase + "/security/malware/{id}/{changeOrder}",
  malwareActions: apiBase + "/security/malware/action",
  malwareDefault: apiBase + "/security/malware/default/action/{action}",
  malwareExport: apiBase + "/security/malware/export",
  malwareEnabled: apiBase + "/security/malware/status/{enabled}",
  managedBandwidthsUsage: apiBase + "/gateways/managed/bandwidths/usage",
  managedGatewaysBandwidths: apiBase + "/gateways/managed/bandwidths",
  managedGateways: apiBase + "/gateways/managed/{id}",
  managedGatewaysRegions: apiBase + "/gateways/managed/regions/available/{virtualWanId}",
  managedGatewayStatistics: apiBase + "/configurations/status/gateways/{id}/statistics",
  modelNames: apiBase + "/appliances/CGW/models",
  allModelNames: apiBase + "/appliances/BNG/models",
  modelPorts: apiBase + "/sites/configuration/ports/{id}",
  notification: apiBase + "/notifications/{id}",
  notificationExport: apiBase + "/notifications/export",
  onpremStatistics: apiBase + "/configurations/status/onprem/{id}/statistics",
  phasesEncryption: apiBase + "/ipsec/phase/encryptions",
  phasesDhGroup: apiBase + "/ipsec/phase/dhgroups/{phase}",
  phasesDpdActions: apiBase + "/ipsec/dpd/actions",
  phasesHashes: apiBase + "/ipsec/phase/hashes",
  phasesProposalHandling: apiBase + "/ipsec/phase/proposals",
  pointsOfEntry: apiBase + "/zerotrust/pointofentry/{id}",
  pointsOfEntryCreate: apiBase + "/zerotrust/pointofentry/create",
  pointsOfEntryExport: apiBase + "/zerotrust/pointofentry/export",
  pointsOfEntryConfigurationTypes: apiBase + "/zerotrust/pointofentry/configurationtypes",
  pointToSite: apiBase + "/pointtosite",
  pointToSiteGateway: apiBase + "/pointtosite/vpnprofile/gateway/{id}",
  pointToSiteSite: apiBase + "/pointtosite/vpnprofile/site/{id}",
  pointToSiteSpMetadata: apiBase + "/pointtosite/saml/spmetadata",
  pointToSiteIdpMetadataRefresh: apiBase + "/pointtosite/saml/idpmetadata/synchronize",
  priority: apiBase + "/policies/priority",
  protocol: apiBase + "/policies/protocol",
  registerGateway: apiBase + "/gateways/token",
  restartAvailable: apiBase + "/sites/{siteId}/restart/available/{id}",
  restartBox: apiBase + "/sites/{id}/restart/{serial}",
  restartFirmware: apiBase + "/sites/{id}/restart/firmware/{serial}",
  restartVpn: apiBase + "/sites/{id}/restart/vpn/{serial}",
  routingPolicy: apiBase + "/pointtosite/routingpolicy",
  sdwanExplicitPolicies: apiBase + "/policies/explicit/{id}/{changeOrder}",
  sdwanPolicies: apiBase + "/policies/{id}",
  sdwanAppCategories: apiBase + "/policies/ids",
  serials: apiBase + "/appliances/serials/available",
  securityEnabled: apiBase + "/security/status/{enabled}",
  sendPointToSiteEnrollEmail: apiBase + "/pointtosite/invite",
  sendZeroTrustEnrollEmail: apiBase + "/zerotrust/invite",
  resendZeroTrustEnrollEmail: apiBase + "/zerotrust/users/{data.id}/invite",
  sdwanConnector: apiBase + "/secureedgeconnector/{id}",
  sdwanConnectorNetwork: apiBase + "/secureedgeconnector/network",
  sdwanConnectorRegisterToken: apiBase + "/secureedgeconnector/{id}/token",
  settings: apiBase + "/settings",
  siteAuditLog: apiBase + "/sites/audit/entries/{id}",
  siteConfiguration: apiBase + "/sites/configuration/{id}",
  siteConfigurationTypes: apiBase + "/sites/configuration/dhcp/types",
  siteConnectionHistoryLog: apiBase + "/configurations/status/{id}/history/{serial}",
  siteConnectionHistoryUpdated: apiBase + "/configurations/status/{id}/history/updated",
  siteDashboardStatus: apiBase + "/sites/status/{id}",
  siteHA: apiBase + "/configurations/status/{id}/serials",
  siteScConfiguration: apiBase + "/sites/secureconnector/configuration/{id}",
  siteStatistics: apiBase + "/configurations/status/{id}/statistics",
  liveConnectionStates: apiBase + "/configurations/status/live/connectionstates",
  siteLiveLog: apiBase + "/configurations/status/{id}/live/{serial}",
  siteLiveUpdated: apiBase + "/configurations/status/{id}/live/updated",
  siteModes: apiBase + "/sites/configuration/modes",
  siteNetworks: apiBase + "/sites/configuration/networks",
  sitePorts: apiBase + "/sites/configuration/{id}/ports",
  siteProviders: apiBase + "/sites/configuration/providers",
  siteHealthCheckModes: apiBase + "/sites/configuration/wan/healthchecks",
  sites: apiBase + "/sites/{id}",
  siteNames: apiBase + "/sites/ids/names",
  siteStates: apiBase + "/sites/states",
  sitePromote: apiBase + "/sites/{id}/promote",
  sitePromotable: apiBase + "/sites/promotable",
  siteStatus: apiBase + "/dashboard/configurations/site/status",
  siteTimezones: apiBase + "/sites/configuration/timezones",
  siteTunnelLocations: apiBase + "/sites/vpn/tunnels/locations/{id}",
  sslCertificates: apiBase + "/security/ssl/{id}",
  sslCertificateAuthorities: apiBase + "/security/ssl/{id}",
  sslCertificatesActive: apiBase + "/security/ssl/active/{id}",
  sslCertificatesDownload: apiBase + "/security/ssl/download/{id}",
  sslExplicitActions: apiBase + "/security/ssl/explicit/action",
  sslExplicit: apiBase + "/security/ssl/explicit/{id}/{changeOrder}",
  sslExplicitDefault: apiBase + "/security/ssl/explicit/default/action/{action}",
  sslExplicitExport: apiBase + "/security/ssl/explicit/export",
  sslInspectionEnabled: apiBase + "/security/ssl/status/{enabled}",
  standaloneSitesAllLocations: apiBase + "/dashboard/sites/standalone/locations",
  standaloneSiteLocation: apiBase + "/sites/standalone/locations/{id}",
  subscriptions: apiBase + "/subscriptions",
  subscriptionsPam: apiBase + "/subscriptions/pam",
  subscriptionsPamProducts: apiBase + "/subscriptions/pam/products",
  subscriptionsPamUsagePlans: apiBase + "/subscriptions/pam/usageplans",
  supportAccessEnabled: apiBase + "/portal/accounts/supportaccess/enabled/{enabled}",
  supportedGatewayWans: apiBase + "/natingressrules/gateways/wans",
  supportedSiteWans: apiBase + "/natingressrules/sites/wans",
  syslogLogs: apiBase + "/syslog/logs",
  syslogProtocols: apiBase + "/syslog/protocols",
  syslogSecurityProtocols: apiBase + "/syslog/securityprotocols",
  trafficType: apiBase + "/policies/traffic",
  update: apiBase + "/system/update/{id}",
  updateDefault: apiBase + "/system/update/{id}",
  updatesAvailable: apiBase + "/system/update/available",
  updatesList: apiBase + "/system/update/all",
  updateNow: apiBase + "/system/update/install/now",
  urlExceptions: apiBase + "/security/urls/explicit/{id}/{changeOrder}",
  urlExceptionsExport: apiBase + "/security/urls/explicit/export",
  urlFilterActions: apiBase + "/security/urls/action",
  urlFilterCategories: apiBase + "/security/urls/category",
  urlFilterDefaultActions: apiBase + "/security/urls/default/actions",
  urlFilterGroups: apiBase + "/security/urls/group",
  urlFilterDefault: apiBase + "/security/urls/default/action/{action}",
  urlFilterRules: apiBase + "/security/urls/rules/{id}/{changeOrder}",
  urlFilterRulesets: apiBase + "/security/urls/{id}/{changeOrder}",
  urlFiltersExport: apiBase + "/security/urls/rules/export",
  urlFilterTypes: apiBase + "/security/urls/type",
  updatesUnacknowledged: apiBase + "/system/pending/update",
  updatesAcknowledge: apiBase + "/system/pending/update/acknowledge/{id}",
  users: apiBase + "/directory/users",
  userOrGroupEnabled: apiBase + "/directory/status",
  validTargets: apiBase + "/natingressrules/validtargets",
  VPNTunnelLocations: apiBase + "/dashboard/vpn/tunnels/locations",
  VPNTunnelOffline: apiBase + "/dashboard/vpn/tunnels/offline",
  VPNTunnelStatus: apiBase + "/dashboard/vpn/tunnels",
  configurationSiteVPNTunnels: apiBase + "/configurations/status/{id}/vpn/tunnels",
  configurationGatewayVPNTunnels: apiBase + "/configurations/status/gateways/{id}/vpn/tunnels",
  configurationOnPremVPNTunnels: apiBase + "/configurations/status/onprem/{id}/vpn/tunnels",
  virtualWans: apiBase + "/virtualwan",
  virtualWanSettings: apiBase + "/virtualwan/{id}",
  virtualWanDelete: apiBase + "/virtualwan/{id}",
  vmActivation: apiBase + "/licenses/activation",
  vmActivationCountries: apiBase + "/licenses/activation/countries",
  webMonitorPolicies: apiBase + "/security/urls/monitor/policies",
  wifiEncryptionMode: apiBase + "/sites/secureconnector/configuration/wifi/securitymodes",
  zeroTrustApplications: apiBase + "/zerotrust/policies/applications",
  zeroTrustCertificateDownload: apiBase + "/zerotrust/options/certificate",
  zeroTrustDevices: apiBase + "/zerotrust/devices/{id}",
  zeroTrustDevicesExport: apiBase + "/zerotrust/devices/export",
  zeroTrustPolicies: apiBase + "/zerotrust/policies/{id}/{changeOrder}",
  zeroTrustPoliciesExport: apiBase + "/zerotrust/policies/export",
  zeroTrustPoliciesOperatingSystem: apiBase + "/zerotrust/policies/operatingSystem",
  zeroTrustPoliciesSecurityPosture: apiBase + "/zerotrust/policies/securityPosture",
  zeroTrustSettings: apiBase + "/zerotrust/options",
  zeroTrustUsers: apiBase + "/zerotrust/users/{id}",
  zeroTrustUsersExport: apiBase + "/zerotrust/users/export",
  zeroTrustUsersRemainingSeats: apiBase + "/zerotrust/users/remaining",
  appCatalog: apiBase + "/zerotrust/appcatalog/{id}",
  visibility: apiBase + "/zerotrust/appcatalog/visibility",
  zeroTrustApplicationsZtnaBased: apiBase + "/zerotrust/policies/applications/inuse/",
  identityProvider: apiBase + "/identity/identityProviders/{id}",
  identityProvidersConfiguration: apiBase + "/identity/identityProviders/configuration",
  identityProviderTestURL: apiBase + "/identity/identityProviders/{id}/testUrl",
  identityProviderAuthURL: apiBase + "/identity/identityProviders/{id}/authorizationURL",
  directories: apiBase + "/identity/directories/{id}",
  directoriesSync: apiBase + "/identity/directories/{id}/sync",
  directoriesAuthorizationURL: apiBase + "/identity/directories/{id}/authorizationURL",
  directoriesConnectorsIps: "/identity/connectorips",
  identityUsers: apiBase + "/identity/users/{id}",
  identityGroups: apiBase + "/identity/groups/{id}",
  identityBCCEnvironments: apiBase + "/identity/bcc/environments",
  demoDirectories: "/demo/directories.json",
  demoIdentityProviders: "/demo/providers.json",
  demoUsers: "/demo/users.json",
  demoGroups: "/demo/groups.json",
  demoAuthorizationURL: "/demo/authorizationUrl.json",
  demoTestURL: "/demo/testUrl.json"
};
export {
  apiResources_default as default
};
