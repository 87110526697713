var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import {
  BooleanInput,
  ConnectedFormPage,
  FileInput,
  FormSection,
  getArrayDataContent,
  PropGateway,
  TextInput,
  useCrudProps,
  useMixpanel
} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import { Typography } from "@barracuda-internal/bds-core";
import { Trans } from "react-i18next";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  helpText: {
    padding: theme.spacing(0, 2, 2, 4),
    color: theme.palette.text.secondary
  },
  multiLineTextInput: {
    width: "50%",
    "& > div": {
      "& > div": {
        height: "auto",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }
    }
  }
}));
const CustomResponse = () => {
  const classes = useStyles();
  const registerAction = useMixpanel("Custom Response Page Settings");
  const imageFileTypes = getArrayDataContent(useCrudProps(apiResources.customResponseImageTypes)[0]);
  const imageAccept = imageFileTypes.map(({ mimeType }) => mimeType).join(",");
  return /* @__PURE__ */ jsx(
    ConnectedFormPage,
    {
      resource: apiResources.customResponse,
      title: "tesseract.securityAndAccess.pageTitle",
      subtitle: ["tesseract.securityAndAccess.settings", "tesseract.securityAndAccess.customResponse"],
      params: { filter: { type: "virtualWan" } },
      formatResourceData: (data) => {
        const _a = data || {}, { image, imageFilename } = _a, formData = __objRest(_a, ["image", "imageFilename"]);
        return __spreadProps(__spreadValues({}, formData), { image: { filename: imageFilename, data: image } });
      },
      formatRequestData: (data) => {
        var _a, _b;
        return __spreadProps(__spreadValues({}, data), {
          image: ((_a = data == null ? void 0 : data.image) == null ? void 0 : _a.data) === "" ? void 0 : data.image.data,
          imageFilename: (_b = data == null ? void 0 : data.image) == null ? void 0 : _b.filename
        });
      },
      formatError: (error) => __spreadProps(__spreadValues({}, error), { image: (error == null ? void 0 : error.imageFilename) || (error == null ? void 0 : error.image) }),
      onSubmitSuccess: () => {
        registerAction("Update");
      },
      children: /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.customResponse.setup", newStyle: true, hideBorder: true, children: [
        /* @__PURE__ */ jsx(PropGateway, { children: /* @__PURE__ */ jsx(Typography, { className: classes.helpText, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.customResponse.setupHelpText" }) }) }),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "message",
            label: "tesseract.customResponse.customText",
            classes: {
              field: classes.multiLineTextInput
            },
            options: {
              multiline: true
            },
            additionalInfoLabel: "tesseract.customResponse.descriptions.customText",
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          FileInput,
          {
            source: "image",
            label: "tesseract.customResponse.customLogo",
            additionalInfoLabel: "tesseract.customResponse.descriptions.customLogo",
            accept: imageAccept,
            newStyle: true,
            showDelete: true
          }
        ),
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "branding",
            label: "tesseract.customResponse.barracudaBranding",
            toggleEnabledText: "tesseract.customResponse.showBarracudaBranding",
            newStyle: true
          }
        )
      ] })
    }
  );
};
var CustomResponse_default = CustomResponse;
export {
  CustomResponse_default as default
};
