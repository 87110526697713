import { jsx } from "react/jsx-runtime";
import { BaseBarCard } from "../../baseCards";
import { colorFunctionError, numberFormatter } from "../../../utils";
import { CardSizes } from "../../../typesAndConstants";
import { useReportingData } from "../../../contexts";
import { UsersIcon } from "../../icons";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
const TopBlockedUsersCard = (props) => {
  const { preview, size } = props;
  const { data, loading, addFilter } = useReportingData("TopBlockedUsersCard", preview);
  const { blockedUserDataPoints = [] } = data || {};
  const [translate] = useTranslation();
  const theme = useTheme();
  const maxValue = Math.max(...blockedUserDataPoints.map((user) => user.numberOfSessions));
  const entries = blockedUserDataPoints.map((user) => ({
    title: user.label,
    value: props.size === CardSizes.small ? numberFormatter(user.numberOfSessions) : translate("stratosReporting.topBlockedUserCard.hits", {
      count: user.numberOfSessions,
      formattedCount: numberFormatter(user.numberOfSessions)
    }),
    barProgress: 100 / maxValue * user.numberOfSessions,
    color: colorFunctionError(100 / maxValue * user.numberOfSessions, theme),
    icon: /* @__PURE__ */ jsx(UsersIcon, { size: 20 })
    //Removing the filter completely until it is fixed on the platform
    // onClick: addFilter ? () => addFilter(user.id, "userDataPoints") : undefined,
    // tooltipMessage: addFilter ? "stratosReporting.filterTooltip" : undefined
  }));
  return /* @__PURE__ */ jsx(
    BaseBarCard,
    {
      entries,
      title: "stratosReporting.topBlockedUserCard.title",
      size,
      preview,
      loading: loading && !data.blockedUserDataPoints,
      noData: !loading && !data.blockedUserDataPoints,
      activeFilter: true
    }
  );
};
export {
  TopBlockedUsersCard
};
