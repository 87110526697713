import { jsx, jsxs } from "react/jsx-runtime";
import { TableInput, TextField, TextInput } from "@cuda-react/core";
import { useRouteFormValidation, useSiteInputValidations } from "../../../../hooks/createEditSiteHooks";
const RoutesTableInput = () => {
  const inputValidations = useSiteInputValidations();
  const routeFormValidation = useRouteFormValidation();
  return /* @__PURE__ */ jsxs(
    TableInput,
    {
      source: "routes",
      optionValue: "name",
      labels: {
        addTitle: "tesseract.appliances.settings.route.addTitle",
        addButtonText: "tesseract.appliances.settings.route.addButtonText",
        editTitle: "tesseract.appliances.settings.route.editTitle",
        deleteTitle: "tesseract.appliances.settings.route.deleteTitle",
        deleteMessage: "tesseract.appliances.settings.route.deleteMessage"
      },
      formValidate: routeFormValidation,
      orderable: true,
      children: [
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.appliances.settings.route.name",
            inputComponent: TextInput,
            inputProps: {
              validate: inputValidations.validateName,
              description: "tesseract.appliances.settings.route.descriptions.name",
              isRequired: true
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "address",
            label: "tesseract.appliances.settings.route.address",
            inputComponent: TextInput,
            inputProps: {
              validate: inputValidations.validateIp,
              description: "tesseract.appliances.settings.route.descriptions.address",
              isRequired: true
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "netmask",
            label: "tesseract.appliances.settings.route.netmask",
            inputComponent: TextInput,
            inputProps: {
              validate: inputValidations.validateNetmask,
              description: "tesseract.appliances.settings.route.descriptions.netmask",
              isRequired: true
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "gateway",
            label: "tesseract.appliances.settings.route.gateway",
            width: 164,
            inputComponent: TextInput,
            inputProps: {
              validate: inputValidations.validateIp,
              description: "tesseract.appliances.settings.route.descriptions.gateway",
              isRequired: true
            }
          }
        )
      ]
    }
  );
};
var RoutesTableInput_default = RoutesTableInput;
export {
  RoutesTableInput_default as default
};
