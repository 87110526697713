var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { get, isEqual } from "lodash";
import { handleLinkedPointClick } from "./handleLinkedPointClick";
import { isLinkSeries } from "./clearLinkedSeries";
const drawLinkedPoints = (chart, sourceType, points, setTooltip, translate, theme) => {
  var _a, _b, _c;
  const label = sourceType.linkedSeries && sourceType.linkedSeries.label;
  const seriesConfig = {
    id: "linked-points-point-series",
    type: "mappoint",
    cursor: "pointer",
    data: points,
    name: label ? translate(label) : void 0,
    visible: true,
    showInLegend: !!label,
    zIndex: sourceType.linkedSeries && sourceType.linkedSeries.zIndex || 9999,
    color: sourceType.linkedSeries && sourceType.linkedSeries.color || sourceType.color,
    borderColor: theme.palette.background.paper,
    marker: {
      symbol: "circle",
      lineWidth: 1,
      radius: 6
    },
    states: {
      hover: {
        enabled: false
      },
      inactive: {
        enabled: false
      }
    },
    allowPointSelect: false,
    point: {
      events: {
        click: handleLinkedPointClick(chart, setTooltip, translate, theme)
      }
    },
    events: {
      hide: () => {
        var _a2;
        const lineSeries = (_a2 = chart.current) == null ? void 0 : _a2.series.find((series) => get(series, "userOptions.id") === "linked-points-line-series");
        lineSeries && lineSeries.hide && lineSeries.hide();
      },
      show: () => {
        var _a2;
        const lineSeries = (_a2 = chart.current) == null ? void 0 : _a2.series.find((series) => get(series, "userOptions.id") === "linked-points-line-series");
        lineSeries && lineSeries.show && lineSeries.show();
      }
    }
  };
  const existingPointSeries = (_a = chart.current) == null ? void 0 : _a.series.find((series) => series.userOptions.id === seriesConfig.id);
  if (existingPointSeries) {
    existingPointSeries.update(seriesConfig, false);
  } else {
    (_b = chart.current) == null ? void 0 : _b.addSeries(seriesConfig, false);
  }
  (_c = chart.current) == null ? void 0 : _c.series.filter((series) => !isLinkSeries(series) && get(series, "userOptions.originalData")).forEach((series) => {
    const data = get(series, "userOptions.originalData").filter(
      (dataItem) => points.every(({ source }) => {
        const { pointData: _a2 } = dataItem, _b2 = _a2, { linkedSeriesParent, linkedSeriesSources, series: series2 } = _b2, values = __objRest(_b2, ["linkedSeriesParent", "linkedSeriesSources", "series"]);
        return !isEqual(source, values);
      })
    );
    series.setData(data, false);
  });
};
export {
  drawLinkedPoints
};
