const getStatusColorFromTheme = (status, theme) => {
  switch (status) {
    case true:
    case "ok":
      return theme.palette.success.main;
    case "warning":
      return theme.palette.warning.main;
    case false:
    case "error":
    case "errorWarning":
      return theme.palette.error.main;
    default:
      return theme.palette.info.main;
  }
};
export {
  getStatusColorFromTheme
};
