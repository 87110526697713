var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { makeStyles } from "@mui/styles";
import {
  ConnectedForm,
  DialogBody,
  FormSection,
  getArrayDataContent,
  ipv4CIDRRegex,
  ipv4Regex,
  MultiInput,
  SelectInput,
  TextArrayInput,
  TextInput,
  useCrudProps,
  useGlobalParam,
  useMixpanel,
  validateArray,
  validateInt,
  validateMaxLengthMemo,
  validateMaxValueMemo,
  validateMinValueMemo,
  validateNegativeRegex,
  validateRegexMemo,
  validateWildcardDomain
} from "@cuda-react/core";
import { categoryIcons } from "../../../../components/iconMapping";
import { get, set } from "lodash";
import apiResources from "../../../../apiResources";
const useStyles = makeStyles(() => ({
  readOnlyField: {
    width: 255
  }
}));
const CustomAppContent = (_a) => {
  var _b = _a, {
    create,
    id,
    title,
    type,
    onSuccess
  } = _b, props = __objRest(_b, [
    "create",
    "id",
    "title",
    "type",
    "onSuccess"
  ]);
  var _a2, _b2;
  const registerAction = useMixpanel("Apps", false);
  const choicesData = (_a2 = useCrudProps(apiResources.sdwanPolicies)[0]) == null ? void 0 : _a2.data;
  const protocolData = (_b2 = useCrudProps(apiResources.protocol, {}, { cache: true })[0]) == null ? void 0 : _b2.data;
  const validations = {
    port: [validateInt, validateMaxValueMemo(65535), validateMinValueMemo(1)],
    name: [validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")],
    description: [validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")],
    wildcardDomain: [
      validateArray([
        validateWildcardDomain,
        validateNegativeRegex(ipv4Regex, "cuda.validation.wildcardDomain"),
        validateNegativeRegex(ipv4CIDRRegex, "cuda.validation.wildcardDomain")
      ])
    ]
  };
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const classes = useStyles();
  return /* @__PURE__ */ jsx(
    DialogBody,
    __spreadProps(__spreadValues({}, props), {
      form: true,
      title,
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          create,
          flat: true,
          resource: apiResources.customApps,
          id,
          form: "custom-app",
          onSubmitSuccess: (response) => {
            onSuccess == null ? void 0 : onSuccess();
            props.onClose();
            registerAction(create ? "Create" : "Update", { type, category: response == null ? void 0 : response.categoryId });
          },
          onCancel: props.onClose,
          initialValues: type && { type } || void 0,
          formatRequestData: (data) => __spreadProps(__spreadValues({}, data), {
            destinations: data.type === "web" ? data.destination && data.destination.map((destination) => ({ destination })) : data.destinations,
            virtualWanId: create ? virtualWanId : data.virtualWanId
          }),
          formatResourceData: (data) => __spreadProps(__spreadValues({}, data), {
            destination: data.type === "web" ? data.destinations && data.destinations.map((destination) => destination.destination) : [],
            destinations: data.type === "web" ? [] : data.destinations
          }),
          formatError: (error, data) => {
            var _a3;
            set(error, "destination", data.type === "web" ? (_a3 = get(error, "destinations")) == null ? void 0 : _a3.map((val) => val.destination) : void 0);
            set(error, "destinations", data.type === "web" ? void 0 : get(error, "destinations"));
            return error;
          },
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.customApps.dialogs.general.sectionTitle", children: [
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "name",
                  label: "tesseract.customApps.dialogs.general.name",
                  description: "tesseract.customApps.dialogs.general.descriptions.name",
                  isRequired: true,
                  validate: validations.name
                }
              ),
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "description",
                  label: "tesseract.customApps.dialogs.general.description",
                  description: "tesseract.customApps.dialogs.general.descriptions.description",
                  validate: validations.description
                }
              )
            ] }),
            /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.customApps.dialogs.endpoints.sectionTitle", children: [
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "protocol",
                  label: "tesseract.customApps.dialogs.endpoints.protocol",
                  description: "tesseract.customApps.dialogs.endpoints.descriptions.protocol",
                  hide: (value, allValues) => !allValues || allValues.type !== "web",
                  value: "HTTP/HTTPS",
                  disabled: true,
                  className: classes.readOnlyField
                },
                "protocol"
              ),
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "port",
                  label: "tesseract.customApps.dialogs.endpoints.port",
                  description: "tesseract.customApps.dialogs.endpoints.descriptions.port",
                  hide: (value, allValues) => !allValues || allValues.type !== "web",
                  value: "80/443",
                  disabled: true,
                  className: classes.readOnlyField
                },
                "port"
              ),
              /* @__PURE__ */ jsx(
                TextArrayInput,
                {
                  source: "destination",
                  label: "tesseract.customApps.dialogs.endpoints.destination",
                  description: "tesseract.customApps.dialogs.endpoints.descriptions.webAppDestination",
                  isRequired: true,
                  hide: (value, allValues) => !allValues || allValues.type !== "web",
                  validate: validations.wildcardDomain
                },
                "destination"
              ),
              /* @__PURE__ */ jsxs(
                MultiInput,
                {
                  maxInputs: 250,
                  source: "destinations",
                  hide: (value, allValues) => allValues && allValues.type === "web",
                  children: [
                    /* @__PURE__ */ jsx(
                      SelectInput,
                      {
                        source: "protocol",
                        label: "tesseract.customApps.dialogs.endpoints.protocol",
                        description: "tesseract.customApps.dialogs.endpoints.descriptions.protocol",
                        choices: getArrayDataContent(protocolData),
                        isRequired: true
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      TextInput,
                      {
                        source: "destination",
                        label: "tesseract.customApps.dialogs.endpoints.destination",
                        description: "tesseract.customApps.dialogs.endpoints.descriptions.destination",
                        isRequired: true
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      TextInput,
                      {
                        source: "firstPort",
                        label: "tesseract.customApps.dialogs.endpoints.firstPort",
                        description: "tesseract.customApps.dialogs.endpoints.descriptions.firstPort",
                        validate: validations.port
                      }
                    ),
                    /* @__PURE__ */ jsx(
                      TextInput,
                      {
                        source: "lastPort",
                        label: "tesseract.customApps.dialogs.endpoints.lastPort",
                        description: "tesseract.customApps.dialogs.endpoints.descriptions.lastPort",
                        validate: validations.port,
                        disable: (fieldValue, data, sourcePrefix, siblingData) => !(siblingData && (siblingData.firstPort || fieldValue))
                      }
                    )
                  ]
                }
              )
            ] }),
            /* @__PURE__ */ jsx(FormSection, { title: "tesseract.customApps.dialogs.category", children: /* @__PURE__ */ jsx(
              SelectInput,
              {
                source: "categoryId",
                label: "tesseract.customApps.dialogs.category",
                description: "tesseract.customApps.dialogs.descriptions.category",
                choices: getArrayDataContent(choicesData),
                iconMap: categoryIcons,
                optionValue: "category",
                optionIcon: "category",
                defaultChoice: "saasAndBusiness",
                isRequired: true
              }
            ) })
          ]
        }
      )
    })
  );
};
var CustomAppContent_default = CustomAppContent;
export {
  CustomAppContent,
  CustomAppContent_default as default,
  useStyles
};
