import { jsx } from "react/jsx-runtime";
import { BaseBarCard } from "../../baseCards";
import { colorFunctionSuccess, numberFormatter } from "../../../utils";
import { CardSizes } from "../../../typesAndConstants";
import { useReportingData } from "../../../contexts";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "@barracuda-internal/bds-core";
import { sortBy } from "lodash";
import { UrlCategoriesIcon } from "@cuda-react/icons";
import { useTheme } from "@mui/material";
const TopAllowedUrlCategoriesPerSessionCard = (props) => {
  const { preview, size } = props;
  const [translate] = useTranslation();
  const { data, loading, addFilter } = useReportingData("TopAllowedUrlCategoriesPerSessionCard", preview);
  const { urlCategoryDataPoints = [] } = data || {};
  const theme = useTheme();
  const maxValue = Math.max(...urlCategoryDataPoints.map((category) => category.numberOfSessions));
  const entries = sortBy(urlCategoryDataPoints, "numberOfSessions").reverse().map((category) => ({
    title: category.label,
    value: size === CardSizes.small ? numberFormatter(category.numberOfSessions) : translate("stratosReporting.topAllowedUrlCategoriesCard.sessions", {
      count: category.numberOfSessions,
      formattedCount: numberFormatter(category.numberOfSessions)
    }),
    barProgress: 100 / maxValue * category.numberOfSessions,
    color: colorFunctionSuccess(100 / maxValue * category.numberOfSessions, theme),
    icon: /* @__PURE__ */ jsx(UrlCategoriesIcon, { size: 20 }),
    onClick: category.label && addFilter ? () => addFilter({ key: category.id, name: category.label }, "urlCategoryDataPoints") : void 0,
    tooltipMessage: category.label && addFilter ? "stratosReporting.filterTooltip" : void 0
  }));
  return /* @__PURE__ */ jsx(
    BaseBarCard,
    {
      entries,
      title: /* @__PURE__ */ jsx(Trans, { i18nKey: "stratosReporting.topAllowedUrlCategoriesCard.titlePerSession", children: /* @__PURE__ */ jsx(Typography, { variant: "body1", component: "span" }) }),
      size,
      preview,
      loading: loading && !data.urlCategoryDataPoints,
      noData: !loading && !data.urlCategoryDataPoints,
      activeFilter: true
    }
  );
};
export {
  TopAllowedUrlCategoriesPerSessionCard
};
