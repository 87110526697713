import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import {
  BooleanInput,
  getArrayDataContent,
  MultiInput,
  SelectInput,
  TextInput,
  useCrudProps,
  useDeepCompareMemo
} from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import apiResources from "../../../../../apiResources";
import { get } from "lodash";
import { useWatch } from "react-hook-form";
const useStyles = makeStyles({
  appMinimumVersion: {
    paddingLeft: 32
  },
  osPlatform: {
    paddingLeft: 32
  },
  osMinimumVersion: {
    paddingLeft: 0
  },
  input: {
    width: 128
  },
  multiInputGroup: {
    minHeight: 50
  }
});
const defaultDeviceAttributes = {
  deviceCriteria: {
    requireScreenLock: true,
    requireFirewall: true,
    requireNotJailbroken: true,
    requireDiskEncryption: false,
    requireAntivirus: true
  }
};
const validatePlatforms = (value, data, { t: translate }) => get(data, "deviceCriteria.osMinimumVersions", []).map(({ platform }) => platform).filter((platform) => value === platform).length > 1 ? translate("tesseract.endpoint.remoteAccessPolicies.validatePlatform") : void 0;
const validateDeviceAttributes = (translate) => (data) => {
  const error = get(data, "accessCriteria.securityPosture", "disable") !== "disable" && !(get(data, "deviceCriteria.requireScreenLock") || get(data, "deviceCriteria.requireFirewall") || get(data, "deviceCriteria.requireNotJailbroken") || get(data, "deviceCriteria.requireDiskEncryption") || get(data, "deviceCriteria.appUpdates") || get(data, "deviceCriteria.osUpdates") || get(data, "deviceCriteria.requireAntivirus")) ? translate("tesseract.endpoint.remoteAccessPolicies.validateDeviceCriteria") : void 0;
  return error ? {
    deviceCriteria: {
      requireScreenLock: error,
      requireFirewall: error,
      requireNotJailbroken: error,
      requireDiskEncryption: error,
      appUpdates: error,
      osUpdates: error,
      requireAntivirus: error
    }
  } : {};
};
const DeviceAttributesInputs = () => {
  const platformChoices = getArrayDataContent(useCrudProps(apiResources.zeroTrustPoliciesOperatingSystem)[0].data);
  const classes = useStyles();
  const activePlatformChoices = (useWatch({ name: "deviceCriteria.osMinimumVersions" }) || []).map(({ platform }) => platform);
  const nextPlatformDefault = useDeepCompareMemo(
    // I have no idea why the last entry needs to be sliced off. It seems like somehow the activePlatformChoices gets the selected defaultChoice prior to the Select
    // component stabilising, so you need to ignore that last value, otherwise it will skip it.
    () => {
      var _a;
      return (_a = platformChoices.find((choice) => !activePlatformChoices.slice(0, activePlatformChoices.length - 1).includes(choice.key))) == null ? void 0 : _a.key;
    },
    [activePlatformChoices, platformChoices]
  );
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      BooleanInput,
      {
        label: "tesseract.endpoint.remoteAccessPolicies.screenLock",
        description: "tesseract.endpoint.remoteAccessPolicies.descriptions.screenLock",
        source: "deviceCriteria.requireScreenLock"
      }
    ),
    /* @__PURE__ */ jsx(
      BooleanInput,
      {
        label: "tesseract.endpoint.remoteAccessPolicies.firewall",
        description: "tesseract.endpoint.remoteAccessPolicies.descriptions.firewall",
        source: "deviceCriteria.requireFirewall"
      }
    ),
    /* @__PURE__ */ jsx(
      BooleanInput,
      {
        label: "tesseract.endpoint.remoteAccessPolicies.antivirus",
        description: "tesseract.endpoint.remoteAccessPolicies.descriptions.antivirus",
        source: "deviceCriteria.requireAntivirus"
      }
    ),
    /* @__PURE__ */ jsx(
      BooleanInput,
      {
        label: "tesseract.endpoint.remoteAccessPolicies.jailbroken",
        description: "tesseract.endpoint.remoteAccessPolicies.descriptions.jailbroken",
        source: "deviceCriteria.requireNotJailbroken"
      }
    ),
    /* @__PURE__ */ jsx(
      BooleanInput,
      {
        label: "tesseract.endpoint.remoteAccessPolicies.encryption",
        description: "tesseract.endpoint.remoteAccessPolicies.descriptions.encryption",
        source: "deviceCriteria.requireDiskEncryption"
      }
    ),
    /* @__PURE__ */ jsx(
      BooleanInput,
      {
        label: "tesseract.endpoint.remoteAccessPolicies.appUpdates",
        description: "tesseract.endpoint.remoteAccessPolicies.descriptions.appUpdates",
        source: "deviceCriteria.appUpdates"
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        label: "tesseract.endpoint.remoteAccessPolicies.minimumVersion",
        source: "deviceCriteria.appMinimumVersion",
        hide: (value, data) => !get(data, "deviceCriteria.appUpdates"),
        inputLabelProps: { minimised: true, classes: { root: classes.appMinimumVersion } },
        inputClasses: { textField: classes.input },
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      BooleanInput,
      {
        label: "tesseract.endpoint.remoteAccessPolicies.osUpdates",
        description: "tesseract.endpoint.remoteAccessPolicies.descriptions.osUpdates",
        source: "deviceCriteria.osUpdates"
      }
    ),
    /* @__PURE__ */ jsxs(
      MultiInput,
      {
        source: "deviceCriteria.osMinimumVersions",
        hide: (value, data) => !get(data, "deviceCriteria.osUpdates"),
        classes: { inputGroup: classes.multiInputGroup },
        maxInputs: 4,
        isRequired: true,
        children: [
          /* @__PURE__ */ jsx(
            SelectInput,
            {
              label: "tesseract.endpoint.remoteAccessPolicies.platform",
              source: "platform",
              choices: platformChoices,
              inputLabelProps: { minimised: true, classes: { root: classes.osPlatform } },
              inputClasses: { select: classes.input, formControl: classes.input },
              inputProps: { defaultChoice: nextPlatformDefault },
              validate: validatePlatforms,
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              label: "tesseract.endpoint.remoteAccessPolicies.minimumVersion",
              source: "version",
              inputLabelProps: { minimised: true, classes: { root: classes.osMinimumVersion } },
              inputClasses: { textField: classes.input },
              isRequired: true
            }
          )
        ]
      }
    )
  ] });
};
var DeviceAttributesInputs_default = DeviceAttributesInputs;
export {
  DeviceAttributesInputs,
  DeviceAttributesInputs_default as default,
  defaultDeviceAttributes,
  validateDeviceAttributes
};
