var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { createElement } from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import ConnectedForm from "../../forms/ConnectedForm/ConnectedForm";
import BasicPage from "../BasicPage/BasicPage";
import FormButtonToolbar from "../../forms/FormButtonToolbar/FormButtonToolbar";
import { Card, Typography } from "@barracuda-internal/bds-core";
import { createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
const styles = (theme) => createStyles({
  toolbar: {
    marginBottom: -12,
    marginRight: -16
  },
  titleNewStyle: {
    padding: theme.spacing(1.5, 3.5, 1.5),
    marginBottom: theme.spacing(1),
    fontSize: 20,
    fontWeight: "bold",
    color: "#4E4D4D"
  }
});
const useStyles = makeOverrideableStyles("ConnectedFormPage", styles);
const ConnectedFormPage = (props) => {
  const classes = useStyles(props);
  const _a = props, {
    actions,
    children,
    title,
    subtitle,
    authenticated,
    authParams,
    showSeparateTitle,
    formButtonToolbarProps
  } = _a, connectedFormProps = __objRest(_a, [
    "actions",
    "children",
    "title",
    "subtitle",
    "authenticated",
    "authParams",
    "showSeparateTitle",
    "formButtonToolbarProps"
  ]);
  const arrayActions = (Array.isArray(actions) ? actions : [actions]).filter(Boolean);
  const [translate] = useTranslation();
  let pageTitle;
  if (typeof subtitle === "string" || Array.isArray(subtitle)) {
    pageTitle = showSeparateTitle && subtitle ? (!Array.isArray(subtitle) ? [subtitle] : subtitle)[subtitle.length - 1] : null;
  }
  return /* @__PURE__ */ jsx(ConnectedForm, __spreadProps(__spreadValues({ flat: true, noToolbar: true }, connectedFormProps), { children: /* @__PURE__ */ jsx(
    BasicPage,
    {
      actions: [
        ...arrayActions,
        /* @__PURE__ */ createElement(FormButtonToolbar, __spreadProps(__spreadValues({}, formButtonToolbarProps), { key: "toolbar", buttonsOnly: true, classes: { buttonContainer: classes.toolbar } }))
      ],
      authenticated,
      authParams,
      title,
      subtitle,
      children: /* @__PURE__ */ jsxs(Card, { children: [
        pageTitle ? /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "h6",
            className: classes.titleNewStyle,
            children: translate(typeof pageTitle !== "string" ? pageTitle.text : pageTitle)
          }
        ) : null,
        children
      ] })
    }
  ) }));
};
var ConnectedFormPage_default = ConnectedFormPage;
export {
  ConnectedFormPage_default as default,
  styles
};
