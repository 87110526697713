import { jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@barracuda-internal/bds-core";
import { Trans, useTranslation } from "react-i18next";
import { BooleanInput, FormSection, SelectArrayInput, TextInput, validateArray, validateEmail } from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import { User, UserGroup } from "@barracuda-internal/bds-core/dist/Icons/Core";
import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  inputLabelWidth: {
    width: 150
  },
  inputWidth: {
    width: 316
  },
  message: {
    margin: theme.spacing(1, 2, 2)
  },
  messageError: {
    color: theme.palette.error.main
  }
}));
const htmlTags = /<|>/;
const EnrollSelectionStep = ({ remainingSeats, create }) => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const validations = useMemo(() => ({
    validateEmailArray: validateArray(validateEmail),
    validateContent: (value) => value && value.match(htmlTags) ? translate("tesseract.endpoint.enrollEmailDialog.noHtml") : void 0,
    validateNoSeats: () => create && remainingSeats < 1 ? translate("tesseract.endpoint.enrollEmailDialog.noSeats") : void 0
  }), [remainingSeats]);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { className: classes.message, children: create && remainingSeats <= 99999 ? /* @__PURE__ */ jsx(
      Trans,
      {
        i18nKey: "tesseract.endpoint.enrollEmailDialog.enrollMessage",
        values: {
          count: remainingSeats,
          context: remainingSeats !== 1 ? "plural" : void 0
        },
        children: /* @__PURE__ */ jsx("p", { className: remainingSeats < 1 ? classes.messageError : void 0 })
      }
    ) : /* @__PURE__ */ jsx(Trans, { i18nKey: `tesseract.endpoint.enrollEmailDialog.${create ? "enrollMessageOld" : "reenrollMessage"}` }) }),
    /* @__PURE__ */ jsx(
      SelectArrayInput,
      {
        source: "users",
        label: "tesseract.endpoint.enrollEmailDialog.users",
        resource: apiResources.users,
        optionValue: "name",
        options: {
          label: "tesseract.endpoint.enrollEmailDialog.addUsers",
          dropdownAutoWidth: true
        },
        icon: /* @__PURE__ */ jsx(User, {}),
        validate: [validations.validateEmailArray, validations.validateNoSeats],
        disabled: !create,
        inputLabelProps: { classes: { inputLabel: classes.inputLabelWidth } },
        inputClasses: { selectArray: classes.inputWidth }
      }
    ),
    create ? /* @__PURE__ */ jsx(
      SelectArrayInput,
      {
        source: "groups",
        label: "tesseract.endpoint.enrollEmailDialog.groups",
        resource: apiResources.groups,
        optionValue: "id",
        options: {
          label: "tesseract.endpoint.enrollEmailDialog.addGroups",
          dropdownAutoWidth: true
        },
        icon: /* @__PURE__ */ jsx(UserGroup, {}),
        inputLabelProps: { classes: { inputLabel: classes.inputLabelWidth } },
        inputClasses: { selectArray: classes.inputWidth },
        validate: validations.validateNoSeats
      }
    ) : null,
    create ? /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.endpoint.enrollEmailDialog.userNotificationSectionTitle", children: [
      /* @__PURE__ */ jsx(
        BooleanInput,
        {
          source: "enrollmentEmailEnabled",
          label: "tesseract.endpoint.enrollEmailDialog.enrollmentEmail.label",
          toggleEnabledText: "tesseract.endpoint.enrollEmailDialog.enrollmentEmail.enabled",
          toggleDisabledText: "tesseract.endpoint.enrollEmailDialog.enrollmentEmail.disabled",
          inputLabelProps: { classes: { inputLabel: classes.inputLabelWidth } }
        }
      ),
      /* @__PURE__ */ jsx(
        TextInput,
        {
          source: "content",
          label: "tesseract.endpoint.enrollEmailDialog.customMessage",
          validate: validations.validateContent,
          inputLabelProps: { classes: { inputLabel: classes.inputLabelWidth } },
          inputClasses: { textField: classes.inputWidth },
          disable: (value, data) => !(data == null ? void 0 : data.enrollmentEmailEnabled)
        }
      )
    ] }) : /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "content",
        label: "tesseract.endpoint.enrollEmailDialog.customMessage",
        validate: validations.validateContent,
        inputLabelProps: { classes: { inputLabel: classes.inputLabelWidth } },
        inputClasses: { textField: classes.inputWidth },
        disable: (value, data) => !(data == null ? void 0 : data.enrollmentEmailEnabled)
      }
    )
  ] });
};
var EnrollSelectionStep_default = EnrollSelectionStep;
export {
  EnrollSelectionStep,
  EnrollSelectionStep_default as default,
  useStyles
};
