var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import { Button } from "@mui/material";
import { useCrudProps, useMixpanel } from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@barracuda-internal/bds-core";
import { StatusIcon } from "@cuda-react/icons";
import useDemoApi from "../../../../../hooks/useDemoApi";
const IDPAuthorizationStatusField = ({ status, id }) => {
  var _a;
  const registerAction = useMixpanel("Identity Providers", false);
  const [translate] = useTranslation("translation");
  const authUrl = useDemoApi(apiResources.identityProviderAuthURL, apiResources.demoAuthorizationURL);
  const authorizationURL = (_a = useCrudProps(id ? authUrl : void 0, { id })[0].data) == null ? void 0 : _a.authorizationURL;
  const handleAuthorize = useCallback(() => __async(void 0, null, function* () {
    registerAction("Authorize Provider");
    window.open(authorizationURL);
  }), [id, authorizationURL]);
  if (status === "needs_consent") {
    return /* @__PURE__ */ jsx(
      Button,
      {
        variant: "outlined",
        color: "warning",
        startIcon: /* @__PURE__ */ jsx(StatusIcon, { status: "warning" }),
        onClick: handleAuthorize,
        children: translate(`tesseract.identity.identityProviders.authorizationStatusField.${status}`)
      }
    );
  }
  return /* @__PURE__ */ jsxs(Box, { display: "flex", alignItems: "center", children: [
    /* @__PURE__ */ jsx(StatusIcon, { status: status === "active" ? "ok" : "warning" }),
    /* @__PURE__ */ jsx(Typography, { ml: 1, children: translate(`tesseract.identity.identityProviders.authorizationStatusField.${status}`) })
  ] });
};
export {
  IDPAuthorizationStatusField
};
