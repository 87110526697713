import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const LicenseIcon = ({ className }) => {
  var _a, _b;
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-license",
      viewBox: "0 0 60 60",
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
            d: "M49.3,22.2l-3-3.8.2-4.8c0-1.2-.7-2.2-1.8-2.5l-4.6-1.3-2.6-4c-.6-1-1.8-1.4-2.9-.9l-4.5,1.7-4.5-1.7c-1.1-.4-2.3,0-2.9.9l-2.6,4-4.6,1.3c-1.1.3-1.9,1.3-1.8,2.5l.2,4.8-3,3.8c-.7.9-.7,2.2,0,3.1l3,3.8-.2,4.8c0,1.2.7,2.2,1.8,2.5l4.6,1.3.5.8-1.3,14.3c0,.9.4,1.8,1.2,2.3.4.2.8.4,1.3.4s.9-.1,1.3-.4l7.2-4.7,7.3,4.7c.8.5,1.8.5,2.6,0,.8-.5,1.2-1.4,1.2-2.3l-1.5-14.7.2-.4,4.6-1.3c1.1-.3,1.9-1.3,1.8-2.5l-.2-4.8,3-3.8c.7-.9.7-2.2,0-3.1ZM31.5,45.2c-.8-.5-1.8-.5-2.6,0l-4.4,2.8.5-5.4c.2,0,.3,0,.5-.1l4.5-1.7,4.5,1.7c.3.1.6.2.9.2l.5,5.4-4.4-2.8ZM41.9,26.6c-.4.5-.6,1.1-.5,1.7l.2,3.8-3.6,1c-.6.2-1.1.5-1.4,1l-2.1,3.2-3.5-1.3c-.6-.2-1.2-.2-1.7,0l-3.5,1.3-2.1-3.2c-.3-.5-.8-.9-1.4-1l-3.6-1,.2-3.8c0-.6-.2-1.2-.5-1.7l-2.4-2.9,2.4-2.9c.4-.5.6-1.1.5-1.7l-.2-3.8,3.6-1c.6-.2,1.1-.5,1.4-1l2.1-3.2,3.5,1.3c.6.2,1.2.2,1.7,0l3.5-1.3,2.1,3.2c.3.5.8.9,1.4,1l3.6,1-.2,3.8c0,.6.2,1.2.5,1.7l2.4,2.9-2.4,2.9Z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 },
            d: "M38.4,21v-2.1c.1-.8-.4-1.6-1.2-1.8l-2-.5-1.1-1.8c-.5-.7-1.3-1-2.1-.7l-2,.7-2-.7c-.8-.3-1.6,0-2.1.7l-1.1,1.8-2,.5c-.8.2-1.3,1-1.3,1.8v2.1c.1,0-1.2,1.6-1.2,1.6-.5.6-.5,1.6,0,2.2l1.3,1.6v2.1c-.1.8.4,1.6,1.2,1.8l2,.5,1.1,1.8c.3.5.9.8,1.5.8s.4,0,.6-.1l2-.7,2,.7c.8.3,1.6,0,2.1-.7l1.1-1.8,2-.5c.8-.2,1.3-1,1.3-1.8v-2.1c-.1,0,1.2-1.6,1.2-1.6.5-.6.5-1.6,0-2.2l-1.3-1.6ZM35.3,24.8c-.3.3-.4.8-.4,1.2v1.4c0,0-1.2.4-1.2.4-.4.1-.8.4-1,.7l-.7,1.1-1.3-.5c-.4-.2-.8-.2-1.2,0l-1.3.5-.7-1.1c-.2-.4-.6-.6-1-.7l-1.3-.4v-1.4c0-.4,0-.9-.3-1.2l-.9-1.1.9-1.1c.3-.3.4-.8.4-1.2v-1.4c0,0,1.2-.4,1.2-.4.4-.1.8-.4,1-.7l.7-1.1,1.3.5c.4.2.8.2,1.2,0l1.3-.5.7,1.1c.2.4.6.6,1,.7l1.3.4v1.4c0,.4,0,.8.3,1.2l.9,1.1-.9,1.1Z"
          }
        )
      ]
    }
  );
};
var LicenseIcon_default = LicenseIcon;
export {
  LicenseIcon,
  LicenseIcon_default as default
};
