import {
  AccessDevicesAgentVersionCard,
  ApplianceMapCard,
  ApplianceStatusCard,
  ApplicationRiskCard,
  GeoSourceTopCountries,
  GeoDestinationTopCountries,
  IpsIncidentsCard,
  LicensingCard,
  MalwareCard,
  OsStatusCard,
  SdwanBandwidthCard,
  SdwanBandwidthChartCard,
  SdwanHeatMapCard,
  SdwanStatusCard,
  SdwanSummaryCard,
  StorageOverviewCard,
  TopAllowedApplicationsSessionsCard,
  TopAllowedApplicationsTrafficCard,
  TopAllowedCategoriesSessionsCard,
  TopAllowedCategoriesTrafficCard,
  TopAllowedDomainsPerSessionCard,
  TopAllowedDomainsPerTrafficCard,
  TopAllowedUrlCategoriesPerSessionCard,
  TopAllowedUrlCategoriesPerTrafficCard,
  TopApplicationCard,
  TopBlockedApplicationCategoriesCard,
  TopBlockedApplicationsCard,
  TopBlockedDomainsCard,
  TopBlockedUrlCategoriesCard,
  TopBlockedUsersCard,
  TopUsersCard,
  UserDevicesCard,
  UserEnrollmentCard,
  UserStatusCard,
  UserSummaryCard,
  WebRequestsCard,
  ZTNAResourcesCard,
  ZTNAAgentInUseCard,
  ZTNAUserGeoLocationCard
} from "./components";
import { ZTNADeviceLocationCard } from "./components/dashboardCards/ZTNADeviceLocationCard";
const StandaloneCards = {
  ApplianceMapCard: {
    component: ApplianceMapCard,
    name: "stratosReporting.applianceMapCard.title"
  },
  ApplianceStatusCard: {
    component: ApplianceStatusCard,
    name: "stratosReporting.applianceStatus.title"
  },
  LicensingCard: {
    component: LicensingCard,
    name: "stratosReporting.licensing.title"
  },
  OsStatusCard: {
    component: OsStatusCard,
    name: "stratosReporting.osStatusCard.title"
  },
  SdwanBandwidthCard: {
    component: SdwanBandwidthCard,
    name: "stratosReporting.sdwanBandwidthCard.title"
  },
  SdwanBandwidthChartCard: {
    component: SdwanBandwidthChartCard,
    name: "stratosReporting.sdwanBandwidthChartCard.title"
  },
  SdwanHeatMapCard: {
    component: SdwanHeatMapCard,
    name: "stratosReporting.sdwanHeatMap.title"
  },
  SdwanStatusCard: {
    component: SdwanStatusCard,
    name: "stratosReporting.sdwanStatusCard.title"
  },
  SdwanSummaryCard: {
    component: SdwanSummaryCard,
    name: "stratosReporting.sdwanSummaryCard.title"
  },
  StorageOverviewCard: {
    component: StorageOverviewCard,
    name: "stratosReporting.storageCard.title"
  },
  TopApplicationCard: {
    component: TopApplicationCard,
    name: "stratosReporting.topApplicationCard.title"
  },
  TopBlockedUserCard: {
    component: TopBlockedUsersCard,
    name: "stratosReporting.topBlockedUserCard.title"
  },
  UserEnrollmentCard: {
    component: UserEnrollmentCard,
    name: "stratosReporting.userEnrollment.title"
  },
  WebRequestsCard: {
    component: WebRequestsCard,
    name: "stratosReporting.topWebRequest.title"
  }
};
const ReportingDataCards = {
  GeoDestinationTopCountries: {
    component: GeoDestinationTopCountries,
    name: "stratosReporting.geoCard.destinationTopCountries.title",
    type: "stratosReporting.categories.security"
  },
  GeoSourceTopCountries: {
    component: GeoSourceTopCountries,
    name: "stratosReporting.geoCard.sourceTopCountries.title",
    type: "stratosReporting.categories.security"
  },
  TopUsersCard: {
    component: TopUsersCard,
    name: "stratosReporting.topUsersCard.title",
    type: "stratosReporting.categories.webFilter"
  },
  TopAllowedDomainsPerSessionCard: {
    component: TopAllowedDomainsPerSessionCard,
    name: "stratosReporting.topAllowedDomainsCard.menuEntryPerSession",
    type: "stratosReporting.categories.webFilter"
  },
  TopAllowedDomainsPerTrafficCard: {
    component: TopAllowedDomainsPerTrafficCard,
    name: "stratosReporting.topAllowedDomainsCard.menuEntryPerTraffic",
    type: "stratosReporting.categories.webFilter"
  },
  TopBlockedDomainsCard: {
    component: TopBlockedDomainsCard,
    name: "stratosReporting.topBlockedDomainsCard.title",
    type: "stratosReporting.categories.webFilter"
  },
  TopAllowedUrlCategoriesPerSessionCard: {
    component: TopAllowedUrlCategoriesPerSessionCard,
    name: "stratosReporting.topAllowedUrlCategoriesCard.menuEntryPerSession",
    type: "stratosReporting.categories.webFilter"
  },
  TopAllowedUrlCategoriesPerTrafficCard: {
    component: TopAllowedUrlCategoriesPerTrafficCard,
    name: "stratosReporting.topAllowedUrlCategoriesCard.menuEntryPerTraffic",
    type: "stratosReporting.categories.webFilter"
  },
  TopBlockedUrlCategoriesCard: {
    component: TopBlockedUrlCategoriesCard,
    name: "stratosReporting.topBlockedUrlCategoriesCard.title",
    type: "stratosReporting.categories.webFilter"
  },
  TopBlockedUsersCard: {
    component: TopBlockedUsersCard,
    name: "stratosReporting.topBlockedUserCard.title",
    type: "stratosReporting.categories.webFilter"
  },
  MalwareCard: {
    component: MalwareCard,
    name: "stratosReporting.malwareCard.title",
    type: "stratosReporting.categories.security"
  },
  IpsIncidentsCard: {
    component: IpsIncidentsCard,
    name: "stratosReporting.ipsIncidentCard.title",
    type: "stratosReporting.categories.security"
  },
  TopAllowedCategoriesSessionsCard: {
    component: TopAllowedCategoriesSessionsCard,
    name: "stratosReporting.topAllowedCategoriesSessions.menuEntry",
    type: "stratosReporting.categories.security"
  },
  TopAllowedCategoriesTrafficCard: {
    component: TopAllowedCategoriesTrafficCard,
    name: "stratosReporting.topAllowedCategoriesTraffic.menuEntry",
    type: "stratosReporting.categories.security"
  },
  BlockedApplicationCategoriesCard: {
    component: TopBlockedApplicationCategoriesCard,
    name: "stratosReporting.blockedApplicationCategories.title",
    type: "stratosReporting.categories.security"
  },
  TopAllowedApplicationsSessionsCard: {
    component: TopAllowedApplicationsSessionsCard,
    name: "stratosReporting.allowedApplicationsSessions.menuEntry",
    type: "stratosReporting.categories.security"
  },
  TopAllowedApplicationsTrafficCard: {
    component: TopAllowedApplicationsTrafficCard,
    name: "stratosReporting.allowedApplicationsTraffic.menuEntry",
    type: "stratosReporting.categories.security"
  },
  TopBlockedApplicationsCard: {
    component: TopBlockedApplicationsCard,
    name: "stratosReporting.blockedApplications.title",
    type: "stratosReporting.categories.security"
  },
  ApplicationRiskCard: {
    component: ApplicationRiskCard,
    name: "stratosReporting.applicationRisk.title",
    type: "stratosReporting.categories.security"
  }
};
const ZtnaDashboardDataCards = {
  AccessDevicesAgentVersionCard: {
    component: AccessDevicesAgentVersionCard,
    name: "stratosReporting.accessDevicesAgentVersionCard.title",
    type: "stratosReporting.categories.ztna"
  },
  UserStatusCard: {
    component: UserStatusCard,
    name: "stratosReporting.userStatusCard.title",
    type: "stratosReporting.categories.ztna"
  },
  ZTNAResourcesCard: {
    component: ZTNAResourcesCard,
    name: "stratosReporting.ZTNAResources.title",
    type: "stratosReporting.categories.ztna"
  },
  ZTNAAgentInUseCard: {
    component: ZTNAAgentInUseCard,
    name: "stratosReporting.ZTNAAgentInUse.title",
    type: "stratosReporting.categories.ztna"
  },
  ZTNAUserGeoLocationCard: {
    component: ZTNAUserGeoLocationCard,
    name: "stratosReporting.ZTNAUserGeoLocation.title",
    type: "stratosReporting.categories.ztna"
  },
  ZTNADeviceLocationCard: {
    component: ZTNADeviceLocationCard,
    name: "stratosReporting.ZTNADeviceLocation.title",
    type: "stratosReporting.categories.ztna"
  }
};
const UserDashboardDataCards = {
  UserSummaryCard: {
    component: UserSummaryCard,
    name: "stratosReporting.userSummaryCard.title"
  },
  UserDevicesCard: {
    component: UserDevicesCard,
    name: "stratosReporting.userDevicesCard.title"
  },
  ZTNADeviceLocationCard: {
    component: ZTNADeviceLocationCard,
    name: "stratosReporting.ZTNADeviceLocation.title"
  }
};
export {
  ReportingDataCards,
  StandaloneCards,
  UserDashboardDataCards,
  ZtnaDashboardDataCards
};
