import { jsx } from "react/jsx-runtime";
import get from "lodash/get";
import { formatDate, formatDateTime } from "../../../utils";
const DateField = (props) => {
  const { source = "", data = {}, dateOnly, format } = props;
  return /* @__PURE__ */ jsx("span", { children: dateOnly ? formatDate(get(data, source)) : formatDateTime(get(data, source), format) });
};
var DateField_default = DateField;
export {
  DateField,
  DateField_default as default
};
