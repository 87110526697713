var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { get } from "lodash";
import { handleLinkedPointClick } from "./handleLinkedPointClick";
const highlightSourcePoint = (chart, sourceType, point, setTooltip, translate, theme) => {
  var _a, _b, _c;
  const seriesConfig = {
    id: "linked-points-source-point-series",
    visible: true,
    cursor: "pointer",
    type: "mappoint",
    showInLegend: false,
    zIndex: sourceType.linkedSeries && sourceType.linkedSeries.selectedZIndex || 9999,
    color: sourceType.linkedSeries && sourceType.linkedSeries.selectedColor || sourceType.color,
    marker: {
      symbol: "circle",
      lineWidth: 2,
      radius: 7,
      lineColor: theme.palette.text.primary
    },
    states: {
      hover: {
        enabled: false
      },
      inactive: {
        enabled: false
      }
    },
    allowPointSelect: false,
    data: [__spreadProps(__spreadValues({}, (_a = chart.current) == null ? void 0 : _a.fromLatLonToPoint({
      lat: get(point.pointData, "latitude"),
      lon: get(point.pointData, "longitude")
    })), {
      source: point.pointData
    })],
    point: {
      events: {
        click: handleLinkedPointClick(chart, setTooltip, translate, theme)
      }
    }
  };
  const existingPointSeries = (_b = chart.current) == null ? void 0 : _b.series.find((series) => series.userOptions.id === seriesConfig.id);
  if (existingPointSeries) {
    existingPointSeries.update(seriesConfig, false);
  } else {
    (_c = chart.current) == null ? void 0 : _c.addSeries(seriesConfig, false);
  }
};
export {
  highlightSourcePoint
};
