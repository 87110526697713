import SiteStatusCard from "./SiteStatusCard/SiteStatusCard";
import IotStatusCard from "./IotStatusCard/IotStatusCard";
import ApplianceStatusCard from "./ApplianceStatusCard/ApplianceStatusCard";
import TunnelStatusCard from "./TunnelStatusCard/TunnelStatusCard";
import GatewayStatusCard from "./GatewayStatusCard/GatewayStatusCard";
import ActiveTunnelsCard from "./ActiveTunnelsCard/ActiveTunnelsCard";
import RecentAdminEvents from "./RecentAdminEvents";
import RecentSystemEvents from "./RecentSystemEvents";
const CgwanCards = {
  adminEventsCard: {
    component: RecentAdminEvents,
    name: "tesseract.dashboard.cards.recentEvents.adminTabTitle",
    type: "tesseract.dashboard.cards.categories.status"
  },
  systemEventsCard: {
    component: RecentSystemEvents,
    name: "tesseract.dashboard.cards.recentEvents.systemTabTitle",
    type: "tesseract.dashboard.cards.categories.status"
  },
  siteStatusCard: {
    component: SiteStatusCard,
    name: "tesseract.dashboard.cards.status.site.tabTitle",
    type: "tesseract.dashboard.cards.categories.status"
  },
  iotStatusCard: {
    component: IotStatusCard,
    name: "tesseract.dashboard.cards.status.iot.tabTitle",
    type: "tesseract.dashboard.cards.categories.status"
  },
  applianceStatusCard: {
    component: ApplianceStatusCard,
    name: "tesseract.dashboard.cards.status.applianceConfiguration.tabTitle",
    type: "tesseract.dashboard.cards.categories.status"
  },
  tunnelStatusCard: {
    component: TunnelStatusCard,
    name: "tesseract.dashboard.cards.vpn.status.tabTitle",
    type: "tesseract.dashboard.cards.categories.status"
  },
  gatewayStatusCard: {
    component: GatewayStatusCard,
    name: "tesseract.dashboard.cards.gateway.cardTitle",
    type: "tesseract.dashboard.cards.categories.status"
  },
  activeTunnelsCard: {
    component: ActiveTunnelsCard,
    name: "tesseract.dashboard.cards.vpn.locations.tabTitle",
    type: "tesseract.dashboard.cards.categories.status"
  }
};
export {
  CgwanCards
};
