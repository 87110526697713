import { useTheme } from "@mui/material";
import { useMemo } from "react";
import { get } from "lodash";
import { getStatusColorFromTheme } from "@cuda-react/theme";
const useStatisticsCardDetailsAndTotal = (statuses, linkCreator, getKeyColor) => {
  const theme = useTheme();
  const [siteStatusDetails, siteStatusTotal] = useMemo(() => [
    statuses.map((status) => ({
      title: status.name,
      value: status.key,
      color: getKeyColor ? getKeyColor(status.key) : getStatusColorFromTheme(status.key, theme),
      link: linkCreator ? linkCreator(status) : void 0
    })),
    (data) => statuses.reduce((total, status) => total + get(data, status.key, 0), 0)
  ], [statuses, theme]);
  return [siteStatusDetails, siteStatusTotal];
};
export {
  useStatisticsCardDetailsAndTotal
};
