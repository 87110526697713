import { jsx } from "react/jsx-runtime";
import { StatusIconField } from "@cuda-react/core";
import { get } from "lodash";
import { daysBetween } from "../../../../../../utils/dateTime";
const ExpiryStatus = ({ data, source }) => {
  const expireDate = get(data, source);
  const daysTilExpiry = expireDate && daysBetween(new Date(expireDate), /* @__PURE__ */ new Date());
  let status = expireDate ? "ok" : "unknown";
  status = expireDate && daysTilExpiry < 28 ? "warning" : status;
  status = expireDate && daysTilExpiry < 0 ? "error" : status;
  return /* @__PURE__ */ jsx(StatusIconField, { source: "status", data: { status } });
};
var ExpiryStatus_default = ExpiryStatus;
export {
  ExpiryStatus_default as default
};
