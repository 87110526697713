var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { SelectArray, Text } from "@cuda-react/core";
import { get } from "lodash";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
const useStyles = makeStyles((theme) => ({
  filter: {
    marginTop: theme.spacing(1)
  }
}));
const CustomAppDestinationFilter = ({ className, value, onChange, choices }) => {
  const classes = useStyles();
  const update = (field) => (eventOrValue) => onChange && onChange(__spreadProps(__spreadValues({}, value || {}), {
    [field]: eventOrValue.target ? eventOrValue.target.value : eventOrValue
  }));
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      Text,
      {
        source: "destination",
        id: "cuda-filter-input-destinations",
        label: "tesseract.customApps.table.destination",
        value: get(value, "destination", ""),
        onChange: update("destination"),
        className: classNames(className, classes.filter)
      }
    ),
    /* @__PURE__ */ jsx(
      SelectArray,
      {
        source: "protocol",
        id: "protocol",
        label: "tesseract.customApps.table.protocol",
        value: get(value, "protocol"),
        onChange: update("protocol"),
        choices,
        className: classNames(className, classes.filter)
      }
    )
  ] });
};
var CustomAppDestinationFilter_default = CustomAppDestinationFilter;
export {
  CustomAppDestinationFilter_default as default
};
