import { jsx, jsxs } from "react/jsx-runtime";
import { Route, Switch, withRouter } from "react-router";
import SiteDetails from "./SiteDetails";
import IotTable from "./IotTable";
const IoT = ({ match }) => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(
    Route,
    {
      exact: true,
      path: match.path,
      component: IotTable
    }
  ),
  /* @__PURE__ */ jsx(
    Route,
    {
      path: `${match.path}/:id`,
      component: SiteDetails
    }
  )
] });
var IoT_default = withRouter(IoT);
export {
  IoT,
  IoT_default as default
};
