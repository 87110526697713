import { jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@barracuda-internal/bds-core";
import { CustomField } from "@cuda-react/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import EmailTemplate from "./EmailTemplate";
const useStyles = makeStyles((theme) => ({
  preview: {
    height: 300,
    margin: theme.spacing(1, "auto"),
    width: 600,
    border: `${theme.palette.divider} solid 1px`,
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    overflowX: "hidden",
    overflowY: "auto",
    paddingTop: 16
  },
  emailPreview: {
    transform: "scale(0.7) translateY(-17%) translateX(-12%)",
    height: 580
  },
  message: {
    margin: theme.spacing(1, 2, 2)
  },
  customField: {
    width: `100%`
  }
}));
const EmailPreviewStep = ({ data }) => {
  const classes = useStyles();
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { className: classes.message, children: translate("tesseract.endpoint.enrollEmailDialog.sendMessage") }),
    /* @__PURE__ */ jsx(
      CustomField,
      {
        data,
        source: "content",
        classes: { root: classes.customField },
        render: (customMessage) => /* @__PURE__ */ jsx("div", { className: classes.preview, children: /* @__PURE__ */ jsx("div", { className: classes.emailPreview, children: /* @__PURE__ */ jsx(EmailTemplate, { content: customMessage, username: "John Doe" }) }) })
      }
    )
  ] });
};
var EmailPreviewStep_default = EmailPreviewStep;
export {
  EmailPreviewStep,
  EmailPreviewStep_default as default,
  useStyles
};
