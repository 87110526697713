import { jsx } from "react/jsx-runtime";
import { useDeepCompareEffect, usePrevious } from "@cuda-react/core";
import SCWanTableInput from "../../inputs/SCWanTableInput";
import { useFormContext, useWatch } from "react-hook-form";
const WansTab = ({ id }) => {
  const { setValue } = useFormContext();
  const [serials = [], wans = []] = useWatch({ name: ["serials", "wans"] });
  const oldSerials = usePrevious(serials);
  useDeepCompareEffect(() => {
    const removedSerial = oldSerials == null ? void 0 : oldSerials.filter((serial) => !serials.includes(serial));
    const newSerial = oldSerials && serials && serials.filter((serial) => !(oldSerials == null ? void 0 : oldSerials.includes(serial)));
    wans.forEach((wan, wanIndex) => {
      if (wan.mode === "wwan" && removedSerial && newSerial) {
        setValue(`wans[${wanIndex}].pin.serial`, newSerial[0]);
      }
    });
  }, [serials]);
  return /* @__PURE__ */ jsx(SCWanTableInput, {});
};
var WansTab_default = WansTab;
export {
  WansTab_default as default
};
