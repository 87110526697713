import React from "react";
import { makeStyles } from "@mui/styles";
import get from "lodash/get";
import { categoryIcons } from "../../../../components/iconMapping";
const styles = () => ({
  icon: {
    minWidth: 32,
    verticalAlign: "middle"
  }
});
const useStyles = makeStyles(styles);
const PolicyIconField = ({ source, data }) => {
  const icon = get(categoryIcons, get(data, source));
  const classes = useStyles();
  return icon ? React.cloneElement(icon, { classes }) : null;
};
var PolicyIconField_default = PolicyIconField;
export {
  PolicyIconField,
  PolicyIconField_default as default,
  styles
};
