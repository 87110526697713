var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import DialogBody from "../../dialog/DialogBody/DialogBody";
import Form from "../../forms/Form/Form";
import MultiStep from "../MultiStep/MultiStep";
import WizardStepper from "../WizardStepper/WizardStepper";
import { uniq } from "lodash";
import { useDeepCompareCallback } from "../../../hooks";
const toChildArray = (children) => children ? React.Children.toArray(children).filter((child) => !!child && React.isValidElement(child)) : [];
const generateSteps = (visitedSteps, stepsWithErrors, parentStep = "") => (child, index) => {
  const visited = visitedSteps.includes(parentStep + index);
  return {
    label: child.props.label,
    subSteps: child.type === MultiStep && child.props.children ? toChildArray(child.props.children).map(generateSteps(visitedSteps, stepsWithErrors, parentStep + index + ".")) : void 0,
    content: child.type !== MultiStep ? child : void 0,
    hideErrors: child.props.hideErrors,
    preventBackNavigation: child.props.preventBackNavigation,
    hideSubNavigation: child.props.hideSubNavigation,
    completed: visited && visitedSteps.some((visitedStep) => visitedStep > parentStep + index),
    error: stepsWithErrors.some((errorStep) => errorStep === parentStep + index || errorStep.startsWith(parentStep + index + ".")),
    hideWizardStepper: child.props.hideWizardStepper,
    visited,
    index
  };
};
const Wizard = (props) => {
  var _b;
  const _a = props, {
    asyncValidate,
    children,
    dialogBodyProps = {},
    disableDirectNavigation,
    onClose,
    onStepChange,
    onStepSuccess,
    preventBackNavigation,
    save,
    stepperProps,
    title,
    toolbarDisabled
  } = _a, formProps = __objRest(_a, [
    "asyncValidate",
    "children",
    "dialogBodyProps",
    "disableDirectNavigation",
    "onClose",
    "onStepChange",
    "onStepSuccess",
    "preventBackNavigation",
    "save",
    "stepperProps",
    "title",
    "toolbarDisabled"
  ]);
  const [result, setResult] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [activeSubStep, setActiveSubStep] = useState(0);
  const [visitedSteps, setVisitedSteps] = useState([]);
  const [stepsWithErrors, setStepsWithErrors] = useState([]);
  const childArray = toChildArray(children);
  const steps = childArray.map(generateSteps(visitedSteps, stepsWithErrors));
  const onActiveStepChange = useDeepCompareCallback((stepIndex) => {
    var _a2;
    const subSteps = (_a2 = steps[activeStep]) == null ? void 0 : _a2.subSteps;
    onStepChange == null ? void 0 : onStepChange(stepIndex, activeStep, subSteps && stepIndex < activeStep ? subSteps.length - 1 : 0, activeSubStep);
    setActiveSubStep(subSteps && stepIndex < activeStep ? subSteps.length - 1 : 0);
    setActiveStep(stepIndex);
  }, [steps, activeStep]);
  const nextStep = useDeepCompareCallback(() => {
    var _a2;
    const subSteps = (_a2 = steps[activeStep]) == null ? void 0 : _a2.subSteps;
    if (subSteps && subSteps.length > activeSubStep + 1) {
      onStepChange == null ? void 0 : onStepChange(activeStep, activeStep, activeSubStep + 1, activeSubStep);
      setActiveSubStep(activeSubStep + 1);
    } else {
      onStepChange == null ? void 0 : onStepChange(activeStep + 1, activeStep, activeSubStep, activeSubStep);
      setActiveSubStep(0);
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, activeSubStep, steps]);
  const previousStep = useDeepCompareCallback(() => {
    var _a2, _b2;
    if (((_a2 = steps[activeStep]) == null ? void 0 : _a2.subSteps) && activeSubStep > 0) {
      setActiveSubStep(activeSubStep - 1);
      onStepChange == null ? void 0 : onStepChange(activeStep, activeStep, activeSubStep - 1, activeSubStep);
    } else {
      setActiveStep(activeStep - 1);
      const subSteps = (_b2 = steps[activeStep - 1]) == null ? void 0 : _b2.subSteps;
      if (subSteps) {
        onStepChange == null ? void 0 : onStepChange(activeStep - 1, activeStep, subSteps.length - 1, activeSubStep);
        setActiveSubStep(subSteps.length - 1);
      } else {
        onStepChange == null ? void 0 : onStepChange(activeStep - 1, activeStep, 0, activeSubStep);
        setActiveSubStep(0);
      }
    }
  }, [activeStep, activeSubStep, steps]);
  const setStepError = useCallback((value, error) => {
    setStepsWithErrors((currentValues) => [...currentValues.filter((currentValue) => currentValue !== value), ...error ? [value] : []]);
  }, []);
  const showWizardStepper = !((_b = steps[activeStep]) == null ? void 0 : _b.hideWizardStepper);
  useEffect(() => {
    var _a2;
    if ((_a2 = steps[activeStep]) == null ? void 0 : _a2.subSteps) {
      setVisitedSteps(uniq([...visitedSteps, activeStep + "", activeStep + "." + activeSubStep]));
    } else {
      setVisitedSteps(uniq([...visitedSteps, activeStep + ""]));
    }
  }, [activeSubStep, activeStep]);
  return /* @__PURE__ */ jsxs(DialogBody, __spreadProps(__spreadValues({ title, onClose, form: true }, dialogBodyProps), { children: [
    showWizardStepper ? /* @__PURE__ */ jsx(
      WizardStepper,
      __spreadValues({
        steps,
        activeStep,
        activeSubStep,
        onStepChange: onActiveStepChange,
        onSubStepChange: setActiveSubStep,
        disableDirectNavigation
      }, stepperProps)
    ) : null,
    /* @__PURE__ */ jsx(
      Form,
      __spreadProps(__spreadValues({
        save: (values, fullSubmit) => {
          var _a2, _b2, _c, _d;
          if (fullSubmit && save) {
            return save(values);
          } else if (!fullSubmit && asyncValidate) {
            const subSteps = steps[activeStep].subSteps;
            return asyncValidate(
              values,
              subSteps ? (_b2 = (_a2 = subSteps[activeSubStep]) == null ? void 0 : _a2.content) == null ? void 0 : _b2.props : (_d = (_c = steps[activeStep]) == null ? void 0 : _c.content) == null ? void 0 : _d.props
            );
          } else {
            return Promise.resolve(values);
          }
        },
        onSubmitSuccess: (submitResult) => {
          setResult(submitResult);
          onStepSuccess == null ? void 0 : onStepSuccess(activeStep, activeSubStep);
          nextStep();
        },
        noToolbar: true
      }, formProps), {
        children: steps.slice(0, activeStep + 1).flatMap((step) => {
          if (step.subSteps) {
            const subSteps = step.index === activeStep ? step.subSteps.slice(0, activeSubStep + 1) : step.subSteps;
            return subSteps.map((subStep) => subStep.content ? React.cloneElement(
              subStep.content,
              {
                active: step.index === activeStep && subStep.index === activeSubStep,
                key: step.index + "-" + subStep.index,
                back: !(subStep.index === 0 && step.index === 0) && !preventBackNavigation ? previousStep : void 0,
                close: onClose,
                toolbarDisabled,
                result,
                formErrorReporterValue: step.index + "." + subStep.index,
                formErrorReporterSetError: setStepError
              }
            ) : null);
          }
          return step.content ? React.cloneElement(
            step.content,
            {
              active: step.index === activeStep,
              key: step.index,
              back: step.index !== 0 && !preventBackNavigation ? previousStep : void 0,
              close: onClose,
              toolbarDisabled,
              result,
              formErrorReporterValue: step.index + "",
              formErrorReporterSetError: setStepError
            }
          ) : null;
        })
      })
    )
  ] }));
};
var Wizard_default = Wizard;
export {
  Wizard,
  Wizard_default as default
};
