var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useCallback } from "react";
import classNames from "classnames";
import { makeOverrideableStyles } from "@cuda-react/theme";
const useDataTableRowHighlightingStyles = (theme) => ({
  highlightedRow: {
    boxShadow: theme.shadows[5]
  },
  dimmedRow: {
    opacity: 0.5,
    backgroundColor: theme.palette.action.disabledBackground + "!important"
  }
});
const useRowHighlightingStyles = makeOverrideableStyles("DataTableRowHighlighting", useDataTableRowHighlightingStyles);
const useDataTableRowHighlighting = (props) => {
  const { rowHighlight, rowDimmed } = props;
  const classes = useRowHighlightingStyles(props);
  return {
    rowRender: useCallback((row, rowProps) => React.cloneElement(row, __spreadProps(__spreadValues({}, row.props), {
      className: classNames(
        row.props && row.props.className,
        rowProps.className,
        rowHighlight && (rowHighlight(rowProps.dataItem) ? classes.highlightedRow : classes.dimmedRow),
        (rowDimmed == null ? void 0 : rowDimmed(rowProps.dataItem)) && classes.dimmedRow
      )
    })), [rowHighlight, rowDimmed])
  };
};
export {
  useDataTableRowHighlighting
};
