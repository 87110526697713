import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { ListCardContent, TextField } from "@cuda-react/core";
import apiResources from "../../../apiResources";
import { BaseCard } from "../../baseCards";
import { createStyles, makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => createStyles({
  userSummaryWrapper: {
    maxHeight: 280,
    overflow: "hidden"
  }
}));
const UserSummaryCard = (props) => {
  var _a;
  const classes = useStyles();
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(
    BaseCard,
    {
      id: "UserSummaryCard",
      title: "stratosReporting.userSummaryCard.title",
      size: props.size,
      classes: { cardContent: classes.userSummaryWrapper },
      children: /* @__PURE__ */ jsxs(
        ListCardContent,
        {
          getFrom: {
            resource: apiResources.userStatus,
            params: { id: (_a = props == null ? void 0 : props.additionalProps) == null ? void 0 : _a.id }
          },
          visible: true,
          children: [
            /* @__PURE__ */ jsx(TextField, { label: "stratosReporting.userSummaryCard.fullName", source: "fullName" }),
            /* @__PURE__ */ jsx(TextField, { label: "stratosReporting.userSummaryCard.email", source: "email" }),
            /* @__PURE__ */ jsx(TextField, { label: "stratosReporting.userSummaryCard.deviceCount", source: "deviceCount" }),
            /* @__PURE__ */ jsx(TextField, { label: "stratosReporting.userSummaryCard.deviceCount", source: "completed" })
          ]
        }
      )
    }
  ) });
};
export {
  UserSummaryCard
};
