var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import {
  ActionButtonsField,
  ChipArrayField,
  ConnectedTable,
  CrudTypes,
  DeleteDialog,
  getArrayDataContent,
  PropGateway,
  StatusIconField,
  TextField,
  useCrudFetch,
  useCrudProps,
  useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { makeStyles } from "@mui/styles";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import CreateEditUrlExceptions from "./createEdit/CreateEditUrlExceptions";
import { useSourceDestinationField } from "../../../../../hooks/sourceAndDestinationHooks";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import {
  formatSourceDestinationFilters,
  formatUserGroupInTableData
} from "../../../../../components/sourceAndDestination/tableUtils";
import SourceFilter from "../../../../../components/sourceAndDestination/SourceFilter";
import { settingsIcons } from "../../../../../components/iconMapping";
import apiResources from "../../../../../apiResources";
const styles = (theme) => ({
  cardActions: {
    paddingRight: theme.spacing(2),
    width: "calc(100% - " + theme.spacing(2) + ")"
  },
  orderCell: {
    minWidth: 70
  },
  actionCell: {
    minWidth: 70,
    paddingLeft: 0
  }
});
const useStyles = makeStyles(styles);
const ExplicitTable = ({ actionChoices, categoryChoices }) => {
  var _a;
  const registerAction = useMixpanel("Web Filter", false);
  const explicitTableRefreshRef = useRef(null);
  const [, , callUrlExceptions] = useCrudFetch(CrudTypes.CREATE, apiResources.urlExceptions, {});
  const classes = useStyles();
  const handleExceptionsOrderClick = (id, direction, data) => callUrlExceptions({
    id,
    changeOrder: direction,
    data
  }).then(explicitTableRefreshRef.current);
  const generateChipArrayProps = useSourceDestinationField(categoryChoices);
  const customCategories = getArrayDataContent((_a = useCrudProps(apiResources.customCategories)[0]) == null ? void 0 : _a.data);
  return /* @__PURE__ */ jsxs(
    ConnectedTable,
    {
      resource: apiResources.urlExceptions,
      exportResource: { resource: apiResources.urlExceptionsExport, filename: "urlExceptions" },
      actions: /* @__PURE__ */ jsx(
        CreateEditButtonDialog,
        {
          create: true,
          component: CreateEditUrlExceptions,
          onSuccess: () => {
            var _a2;
            return (_a2 = explicitTableRefreshRef.current) == null ? void 0 : _a2.call(explicitTableRefreshRef);
          }
        }
      ),
      params: (params) => {
        const formattedParams = formatSourceDestinationFilters(params);
        return __spreadProps(__spreadValues({}, formattedParams), {
          filter: __spreadProps(__spreadValues({}, formattedParams.filter || {}), {
            rules: void 0,
            rule: [...params.filter && params.filter.rules && params.filter.rules.category || []],
            category: params.filter && params.filter.rules && params.filter.rules.category || [],
            destinationType: params.filter && params.filter.rules && params.filter.rules.type || [],
            domain: params.filter && params.filter.rules && params.filter.rules.domain || []
          })
        });
      },
      classes: { cardActions: classes.cardActions },
      formatData: formatUserGroupInTableData,
      minCellWidth: 128,
      refreshRef: explicitTableRefreshRef,
      flat: true,
      children: [
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            left: true,
            label: "tesseract.security.urlFiltering.settings.order",
            cellClassName: classes.orderCell,
            width: 96,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(
                TextField,
                {
                  source: "order"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowUpwardIcon,
                {
                  onClick: (event, data) => handleExceptionsOrderClick(data.id, "increase", data),
                  disabled: (data) => data && data.index <= 0,
                  id: "cuda-icon-up"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowDownwardIcon,
                {
                  onClick: (event, data) => handleExceptionsOrderClick(data.id, "decrease", data),
                  disabled: (data) => data && data.index >= data.total - 1,
                  id: "cuda-icon-down"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.security.urlFiltering.exceptions.name",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.security.urlFiltering.exceptions.description",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "action",
            text: (action) => (actionChoices.find((item) => item.key === action) || {}).name,
            label: "tesseract.security.urlFiltering.exceptions.action",
            cellClassName: classes.actionCell,
            iconMap: settingsIcons,
            filter: "select",
            filterProps: {
              choices: actionChoices
            },
            width: 140
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "source",
            label: "tesseract.security.urlFiltering.exceptions.source",
            editProps: generateChipArrayProps("source"),
            filter: "custom",
            filterProps: {
              component: SourceFilter,
              filterSources: ["network", "site", "userOrGroup"]
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "source" })
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "destination",
            label: "tesseract.security.urlFiltering.exceptions.destinations",
            editProps: generateChipArrayProps("destination"),
            filter: "custom",
            filterProps: {
              source: "rules",
              component: DestinationFilter,
              filterSources: ["domain", "category", "customCategory"],
              table: "WebFilterExplicit",
              categoryChoices,
              customCategories
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "destination" })
          }
        ),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            width: 96,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(
                CreateEditButtonDialog,
                {
                  component: CreateEditUrlExceptions,
                  onSuccess: () => {
                    var _a2;
                    return (_a2 = explicitTableRefreshRef.current) == null ? void 0 : _a2.call(explicitTableRefreshRef);
                  }
                }
              ),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource: apiResources.urlExceptions,
                  title: "tesseract.security.urlFiltering.settings.delete.title",
                  message: "tesseract.security.urlFiltering.settings.delete.body",
                  onSuccess: () => {
                    var _a2;
                    registerAction("Explicit Delete");
                    (_a2 = explicitTableRefreshRef.current) == null ? void 0 : _a2.call(explicitTableRefreshRef);
                  }
                }
              )
            ]
          }
        )
      ]
    }
  );
};
var ExplicitTable_default = ExplicitTable;
export {
  ExplicitTable,
  ExplicitTable_default as default,
  styles
};
