var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { GridColumn } from "@progress/kendo-react-grid";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  cells: {
    padding: (props) => props.compact ? theme.spacing(1) + " !important" : void 0
  },
  headerCells: {
    padding: (props) => props.compact ? theme.spacing(1) + " !important" : void 0,
    // @ts-ignore important isn't recognised as part of a valid option
    whiteSpace: "normal !important",
    lineHeight: 1.3,
    verticalAlign: "middle !important",
    textTransform: "uppercase",
    "& a.k-link": {
      display: "flex !important",
      alignItems: "center",
      cursor: (props) => props.reorderable ? void 0 : "default",
      minHeight: "1rem"
    },
    "&:hover": {
      borderRightWidth: (props) => props.resizable ? 1 : void 0,
      borderLeftWidth: (props) => props.resizable ? 1 : void 0,
      borderLeftColor: (props) => props.resizable ? "inherit" : void 0
    }
  },
  sortableHeaderCells: {
    "& a.k-link": {
      cursor: "pointer"
    }
  }
});
const useDataTableFieldColumnsStyles = makeOverrideableStyles("DataTableFieldColumns", styles);
const generateUniqueKey = (child, index) => {
  const key = "" + child.key;
  return key.startsWith(".$") ? key.replace(".$", "") : child.props.source || index;
};
const useDataTableFieldColumns = (children, props) => {
  const { isDisabled, sortable } = props;
  const [translate] = useTranslation();
  const classes = useDataTableFieldColumnsStyles(props);
  return {
    children: children.map((child, childIndex) => {
      const childIsSortable = Boolean(sortable && child.props.sortable !== false || child.props.sortable);
      return /* @__PURE__ */ jsx(
        GridColumn,
        __spreadValues({
          field: child.props.source,
          title: child.props.label && translate(child.props.label) || " ",
          sortable: childIsSortable,
          className: classNames(classes.cells, child.props.cellClassName),
          headerClassName: classNames(classes.headerCells, child.props.cellClassName, childIsSortable && classes.sortableHeaderCells),
          width: child.props.width,
          minResizableWidth: child.props.minResizableWidth,
          editColumnLabel: child.props.editColumnLabel,
          hideByDefault: child.props.hideByDefault,
          alwaysVisible: child.props.alwaysVisible,
          format: "fieldColumn_" + childIndex
        }, child.props.columnProps),
        generateUniqueKey(child, childIndex)
      );
    }),
    cellRender: (cell, { className, dataIndex, dataItem, format }) => {
      if (format && format.startsWith("fieldColumn_")) {
        const childIndex = +format.split("_")[1];
        const child = children[childIndex];
        return /* @__PURE__ */ jsx("td", { className, children: child && React.cloneElement(child, {
          data: __spreadValues({
            index: dataIndex
          }, dataItem),
          disabled: isDisabled == null ? void 0 : isDisabled(dataItem)
        }) || null });
      }
      return cell;
    }
  };
};
export {
  useDataTableFieldColumns
};
