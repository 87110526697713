import { jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import {
  MenuButton
} from "@cuda-react/core";
import { useTranslation } from "react-i18next";
const i18nScope = "tesseract.identity.userDirectories";
const AddUserDirectoryButton = ({ onSelect, disabled = false }) => {
  const [translate] = useTranslation();
  const clickHandler = useCallback((type) => () => {
    onSelect(type);
  }, [onSelect]);
  const menuItems = useMemo(() => [
    {
      label: translate(`${i18nScope}.form.type.entraid`),
      onClick: clickHandler("entraid")
    },
    {
      label: translate(`${i18nScope}.form.type.google`),
      onClick: clickHandler("google")
    },
    {
      label: translate(`${i18nScope}.form.type.okta`),
      onClick: clickHandler("okta")
    },
    {
      label: translate(`${i18nScope}.form.type.bcc`),
      onClick: clickHandler("bcc")
    },
    {
      label: translate(`${i18nScope}.form.type.ldap`),
      onClick: clickHandler("ldap")
    }
  ], [translate, onSelect]);
  return /* @__PURE__ */ jsx(
    MenuButton,
    {
      label: "tesseract.identity.userDirectories.createDirectory",
      disabled,
      menuItems
    }
  );
};
export {
  AddUserDirectoryButton
};
