import { jsx, jsxs } from "react/jsx-runtime";
import { Route, Switch, withRouter } from "react-router";
import UsersTable from "./UsersTable";
import UserDetails from "./user/UserDetails";
const Users = ({ match }) => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(
    Route,
    {
      exact: true,
      path: match.path,
      component: UsersTable
    }
  ),
  /* @__PURE__ */ jsx(
    Route,
    {
      path: `${match.path}/:id`,
      component: UserDetails
    }
  )
] });
var Users_default = withRouter(Users);
export {
  Users,
  Users_default as default
};
