import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const SiteIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-site",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
          d: "M5,52.5V7.5h25v10h25v35H5ZM10,47.5h5v-5h-5v5ZM10,37.5h5v-5h-5v5ZM10,27.5h5v-5h-5v5ZM10,17.5h5v-5h-5v5ZM20,47.5h5v-5h-5v5ZM20,37.5h5v-5h-5v5ZM20,27.5h5v-5h-5v5ZM20,17.5h5v-5h-5v5ZM30,47.5h20v-25h-20v5h5v5h-5v5h5v5h-5v5ZM40,32.5v-5h5v5h-5ZM40,42.5v-5h5v5h-5Z"
        }
      )
    }
  );
};
var SiteIcon_default = SiteIcon;
export {
  SiteIcon,
  SiteIcon_default as default
};
