import { jsx } from "react/jsx-runtime";
import { DialogBody, LoadingMessage, useCrudProps } from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import { IdentityProviderForm } from "./IdentityProviderForm/IdentityProviderForm";
const EditIdentityProviderWrapper = ({ id, onClose, onSuccess }) => {
  var _a;
  const identityProvider = (_a = useCrudProps(apiResources.identityProvider, { id })[0]) == null ? void 0 : _a.data;
  if (!identityProvider) {
    return /* @__PURE__ */ jsx(LoadingMessage, {});
  }
  return /* @__PURE__ */ jsx(DialogBody, { form: true, children: /* @__PURE__ */ jsx(
    IdentityProviderForm,
    {
      create: false,
      id,
      identityProvider,
      onClose: (refresh) => {
        if (refresh) {
          onSuccess();
        }
        onClose();
      }
    }
  ) });
};
export {
  EditIdentityProviderWrapper
};
