import { jsx, jsxs } from "react/jsx-runtime";
import {
  ActionButtonsField,
  ConnectedAutocompleteSearch,
  CrudTypes,
  getArrayDataContent,
  SelectField,
  TablePage,
  TextField,
  useCrudFetch,
  useCrudProps,
  useMixpanel
} from "@cuda-react/core";
import { Restore } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { useRef, useState } from "react";
import { fallbackIcons, priorityIcons } from "../../../../components/iconMapping";
import PolicyIconField from "../fields/PolicyIconField";
import apiResources from "../../../../apiResources";
const SdWanPolicies = () => {
  var _a, _b, _c, _d;
  const registerAction = useMixpanel("SDWAN Categories");
  const resource = apiResources.sdwanPolicies;
  const [, , performDelete] = useCrudFetch(CrudTypes.DELETE, resource, {});
  const priorityData = (_a = useCrudProps(apiResources.priority, {}, {}, true)[0]) == null ? void 0 : _a.data;
  const actionData = (_b = useCrudProps(apiResources.action, {}, {}, true)[0]) == null ? void 0 : _b.data;
  const fallbackData = (_c = useCrudProps(apiResources.fallback, {}, {}, true)[0]) == null ? void 0 : _c.data;
  const loadBalancingData = (_d = useCrudProps(apiResources.loadBalancing, {}, {}, true)[0]) == null ? void 0 : _d.data;
  const [searchApplication, setSearchApplication] = useState();
  const rowHighlight = (row) => row && searchApplication && row.category === searchApplication.categoryId;
  const tableRefreshRef = useRef();
  const onSuccess = (field) => (response) => {
    var _a2;
    (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
    registerAction("Update", { category: response.category, field });
  };
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      title: "tesseract.securityAndAccess.pageTitle",
      subtitle: ["tesseract.securityAndAccess.sdwan", "tesseract.securityAndAccess.categories"],
      resource,
      titleActions: /* @__PURE__ */ jsx(
        ConnectedAutocompleteSearch,
        {
          value: searchApplication,
          onChange: setSearchApplication,
          resource: apiResources.applications,
          placeholder: "tesseract.policies.sdwan.search",
          fullWidth: true
        }
      ),
      rowHighlight: searchApplication && rowHighlight || void 0,
      minCellWidth: 64,
      compact: true,
      refreshRef: tableRefreshRef,
      children: [
        /* @__PURE__ */ jsx(
          PolicyIconField,
          {
            source: "category",
            label: " ",
            width: 40
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.policies.sdwan.table.category"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "applications",
            label: "tesseract.policies.sdwan.table.apps"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "customApplications",
            label: "tesseract.policies.sdwan.table.customApps"
          }
        ),
        /* @__PURE__ */ jsx(
          SelectField,
          {
            source: "priority",
            label: "tesseract.policies.sdwan.table.priority",
            resource,
            id: (data) => data.id,
            choices: getArrayDataContent(priorityData),
            iconMap: priorityIcons,
            width: 142,
            columnProps: {
              width: 156
            },
            onSuccess: onSuccess("priority")
          }
        ),
        /* @__PURE__ */ jsx(
          SelectField,
          {
            source: "action",
            label: "tesseract.policies.sdwan.table.action",
            resource,
            id: (data) => data.id,
            choices: getArrayDataContent(actionData),
            width: 144,
            columnProps: {
              width: 168
            },
            onSuccess: onSuccess("action")
          }
        ),
        /* @__PURE__ */ jsx(
          SelectField,
          {
            source: "fallback",
            label: "tesseract.policies.sdwan.table.fallback",
            resource,
            id: (data) => data.id,
            choices: getArrayDataContent(fallbackData),
            iconMap: fallbackIcons,
            width: 124,
            columnProps: {
              width: 148
            },
            onSuccess: onSuccess("fallback")
          }
        ),
        /* @__PURE__ */ jsx(
          SelectField,
          {
            source: "loadBalancing",
            label: "tesseract.policies.sdwan.table.loadBalancing",
            resource,
            id: (data) => data.id,
            choices: getArrayDataContent(loadBalancingData),
            width: 120,
            columnProps: {
              width: 144
            },
            onSuccess: onSuccess("loadBalancing")
          }
        ),
        /* @__PURE__ */ jsx(
          ActionButtonsField,
          {
            width: 64,
            source: "id",
            children: /* @__PURE__ */ jsx(
              Restore,
              {
                onClick: (event, data) => {
                  registerAction("Reset", { category: data.category });
                  performDelete({ id: data.id }).then(tableRefreshRef.current);
                },
                id: "cuda-icon-restore"
              }
            )
          }
        )
      ]
    }
  );
};
var SdWanPolicies_default = SdWanPolicies;
export {
  SdWanPolicies_default as default
};
