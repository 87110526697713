var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { useMixpanel } from "@cuda-react/core";
import {
  CardSizes,
  DashboardPage,
  ReportingDataCards,
  ReportingDataProvider,
  ZtnaDashboardDataCards
} from "@stratos/reporting";
import { useTranslation } from "react-i18next";
import { CgwanCards } from "./cards";
import useCustomDashboard from "../../hooks/useCustomDashboard";
import useReportingApiAndFilters from "../../hooks/reportingDashboard/useReportingApiAndFilters";
const statusDashboardCards = [
  { componentId: "gatewayStatusCard", size: CardSizes.small },
  { componentId: "iotStatusCard", size: CardSizes.small },
  { componentId: "activeTunnelsCard", size: CardSizes.large },
  { componentId: "siteStatusCard", size: CardSizes.medium },
  { componentId: "adminEventsCard", size: CardSizes.medium },
  { componentId: "systemEventsCard", size: CardSizes.medium }
];
const securityDashboardCards = [
  { componentId: "ApplicationRiskCard", size: CardSizes.large },
  { componentId: "IpsIncidentsCard", size: CardSizes.medium },
  { componentId: "MalwareCard", size: CardSizes.medium },
  { componentId: "TopBlockedApplicationsCard", size: CardSizes.large },
  { componentId: "TopAllowedApplicationsTrafficCard", size: CardSizes.large },
  { componentId: "GeoDestinationTopCountries", size: CardSizes.medium },
  { componentId: "TopAllowedCategoriesTrafficCard", size: CardSizes.large },
  { componentId: "GeoSourceTopCountries", size: CardSizes.medium },
  { componentId: "TopBlockedUsersCard", size: CardSizes.medium },
  { componentId: "TopUsersCard", size: CardSizes.medium }
];
const webFilterDashboardCards = [
  { componentId: "TopAllowedUrlCategoriesPerTrafficCard", size: CardSizes.medium },
  { componentId: "TopBlockedUrlCategoriesCard", size: CardSizes.medium },
  { componentId: "TopAllowedDomainsPerTrafficCard", size: CardSizes.large },
  { componentId: "TopBlockedDomainsCard", size: CardSizes.large },
  { componentId: "TopUsersCard", size: CardSizes.large },
  { componentId: "TopBlockedUsersCard", size: CardSizes.large }
];
const ztnaDashboardCards = [
  { componentId: "ZTNADeviceLocationCard", size: CardSizes.medium },
  { componentId: "UserStatusCard", size: CardSizes.small },
  { componentId: "ZTNAAgentInUseCard", size: CardSizes.small },
  { componentId: "ZTNAUserGeoLocationCard", size: CardSizes.small },
  { componentId: "ZTNAResourcesCard", size: CardSizes.small },
  { componentId: "TopAllowedApplicationsTrafficCard", size: CardSizes.medium },
  { componentId: "TopAllowedUrlCategoriesPerTrafficCard", size: CardSizes.medium },
  { componentId: "AccessDevicesAgentVersionCard", size: CardSizes.large },
  { componentId: "TopAllowedDomainsPerTrafficCard", size: CardSizes.medium }
];
const Dashboard = () => {
  useMixpanel("Dashboard");
  const [translate] = useTranslation();
  const templateDashboards = [{
    name: translate("tesseract.dashboard.status"),
    cards: statusDashboardCards,
    template: translate("tesseract.dashboard.status")
  }, {
    name: translate("tesseract.dashboard.security"),
    cards: securityDashboardCards,
    template: translate("tesseract.dashboard.security")
  }, {
    name: translate("tesseract.dashboard.webFilter"),
    cards: webFilterDashboardCards,
    template: translate("tesseract.dashboard.webFilter")
  }, {
    name: translate("tesseract.dashboard.ztnaDashboard"),
    cards: ztnaDashboardCards,
    template: translate("tesseract.dashboard.ztnaDashboard")
  }, {
    name: translate("tesseract.dashboard.emptyTemplate"),
    cards: []
  }];
  const defaultDash = templateDashboards.slice(0, 4);
  const availableCards = __spreadValues(__spreadValues(__spreadValues({}, CgwanCards), ReportingDataCards), ZtnaDashboardDataCards);
  const {
    remoteStoredCustomDashboards,
    handleDashboardFetch,
    loadingDashboards
  } = useCustomDashboard("mainDashboard");
  const reportingApi = useReportingApiAndFilters();
  return /* @__PURE__ */ jsx(
    ReportingDataProvider,
    {
      data: reportingApi.data,
      loading: reportingApi.loading,
      register: reportingApi.register,
      unregister: reportingApi.unregister,
      addFilter: reportingApi.updateFilter,
      children: /* @__PURE__ */ jsx(
        DashboardPage,
        {
          id: "mainDashboard",
          title: "tesseract.dashboard.pageTitle",
          availableCards,
          defaultDashboards: defaultDash,
          templates: templateDashboards,
          filters: { filterButtons: reportingApi.filterButtons, activeFilters: reportingApi.activeFilters },
          remoteStoredDashboards: remoteStoredCustomDashboards,
          loadingRemoteStoredDashboards: loadingDashboards,
          onDashboardsChange: handleDashboardFetch,
          maxDashboardsAmount: 15
        }
      )
    }
  );
};
var Dashboard_default = Dashboard;
export {
  Dashboard,
  Dashboard_default as default,
  securityDashboardCards,
  statusDashboardCards,
  webFilterDashboardCards,
  ztnaDashboardCards
};
