import { jsx, jsxs } from "react/jsx-runtime";
import { Card, ConnectedForm, TextInput, validateIpv4 } from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import apiResources from "../../../../apiResources";
import { useTranslation } from "react-i18next";
import { parseJson } from "../../../../utils/jsonUtils";
import { get } from "lodash";
const useStyles = makeStyles((theme) => ({
  message: {
    padding: theme.spacing(2, 2, 2, 4)
  }
}));
const DcAgent = () => {
  const [translate] = useTranslation();
  const classes = useStyles();
  return /* @__PURE__ */ jsx(Card, { title: "tesseract.settings.tabs.dcAgent.title", children: /* @__PURE__ */ jsxs(
    ConnectedForm,
    {
      formatRequestData: (data) => ({
        settings: {
          "dc_agent": JSON.stringify(data)
        }
      }),
      formatResourceData: (data) => {
        var _a, _b;
        return {
          ipAddress: ((_b = parseJson((_a = data == null ? void 0 : data.settings) == null ? void 0 : _a["dc_agent"])) == null ? void 0 : _b.ipAddress) || ""
        };
      },
      formatError: (errors) => get(errors, "settings.dc_agent"),
      resource: apiResources.settings,
      params: { filter: { type: "virtualWan" } },
      children: [
        /* @__PURE__ */ jsx(Typography, { className: classes.message, children: translate("tesseract.settings.tabs.dcAgent.description") }),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "ipAddress",
            label: "tesseract.settings.tabs.dcAgent.ipAddress",
            validate: validateIpv4
          }
        )
      ]
    }
  ) });
};
var DcAgent_default = DcAgent;
export {
  DcAgent,
  DcAgent_default as default,
  useStyles
};
