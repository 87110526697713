import { jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authLogin } from "../../../actions";
import { parse } from "query-string";
import { useLocation } from "react-router";
import AuthenticatingPage from "../AuthenticatingPage/AuthenticatingPage";
const BccRedirectLoginPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryString = parse(location.search);
  useEffect(() => {
    dispatch(authLogin(queryString));
  }, []);
  return /* @__PURE__ */ jsx(AuthenticatingPage, {});
};
var BccRedirectLoginPage_default = BccRedirectLoginPage;
export {
  BccRedirectLoginPage,
  BccRedirectLoginPage_default as default
};
