import { jsx, jsxs } from "react/jsx-runtime";
import { Grid, Link, Typography } from "@barracuda-internal/bds-core";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";
import { CustomField, getArrayDataContent, useCrudProps } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { useWatch } from "react-hook-form";
import apiResources from "../../../../../apiResources";
const useStyles = makeStyles((theme) => ({
  siteCreated: {
    textAlign: "center",
    marginBottom: theme.spacing(2)
  },
  message: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  serial: {
    textAlign: "center",
    paddingTop: 12
  },
  warning: {
    color: theme.palette.warning.main
  }
}));
const defaultPorts = ["p2", "p3", "p4", "p5"];
const SiteResultStep = ({ applianceType, data }) => {
  var _a;
  const [model, wans, lans] = useWatch({ name: ["serials.0.model", "wans", "lans"] });
  const portsData = getArrayDataContent((_a = useCrudProps(apiResources.modelPorts, { id: model })[0]) == null ? void 0 : _a.data);
  const nonDefaultPorts = [...wans, ...lans].map((link) => link && link.port).filter((port) => !defaultPorts.includes(port));
  const nonDefaultPortNames = portsData.filter((appliancePort) => nonDefaultPorts.includes(appliancePort.port)).map((port) => port.name);
  const classes = useStyles();
  const [translate] = useTranslation();
  const documentationLink = applianceType === "site" ? "https://campus.barracuda.com/doc/98223578/" : "https://campus.barracuda.com/doc/98223586/";
  return /* @__PURE__ */ jsx(
    CustomField,
    {
      source: "serials",
      data,
      render: (value) => {
        const valueAsArray = value && (Array.isArray(value) ? value : [value]);
        return /* @__PURE__ */ jsxs(Grid, { container: true, direction: "column", children: [
          /* @__PURE__ */ jsxs(Grid, { item: true, children: [
            /* @__PURE__ */ jsx(Typography, { className: classes.siteCreated, variant: "h5", children: translate("tesseract.appliances.dialog.result.success", { context: applianceType }) }),
            /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: /* @__PURE__ */ jsx(
              Trans,
              {
                i18nKey: "tesseract.appliances.dialog.result.connectPort",
                count: valueAsArray.length,
                values: model === "T93A" ? { port: "P2" } : { port: "P4" },
                children: /* @__PURE__ */ jsx("strong", {})
              }
            ) }),
            valueAsArray.length > 1 && /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.appliances.dialog.result.HAMessage", children: /* @__PURE__ */ jsx("strong", {}) }) }),
            /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.appliances.dialog.result.campus", children: /* @__PURE__ */ jsx(Link, { href: documentationLink, target: "_blank", rel: "noopener" }) }) })
          ] }),
          /* @__PURE__ */ jsx(Grid, { item: true, children: valueAsArray.map((appliance) => /* @__PURE__ */ jsx(Typography, { variant: "h6", className: classes.serial, children: translate("tesseract.appliances.dialog.result.serial", { serial: appliance.serial }) }, appliance.serial)) }),
          nonDefaultPortNames && nonDefaultPortNames.length ? /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(Typography, { className: classNames(classes.message, classes.warning), variant: "body1", children: /* @__PURE__ */ jsx(
            Trans,
            {
              i18nKey: "tesseract.appliances.dialog.result.nonstandardPort",
              count: nonDefaultPortNames.length,
              values: { ports: nonDefaultPortNames.join(", ") },
              children: /* @__PURE__ */ jsx("strong", {})
            }
          ) }) }) : null
        ] });
      }
    }
  );
};
var SiteResultStep_default = SiteResultStep;
export {
  SiteResultStep_default as default
};
