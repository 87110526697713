import { jsx, jsxs } from "react/jsx-runtime";
import { FormHelperText, ListItemText, MenuItem, Select, TextField, Typography } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import { clone, cloneDeep, get, isEmpty, set } from "lodash";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { formatErrorMessage } from "../../../utils";
import { createStyles } from "@mui/styles";
const baseDayOptions = [
  { key: "mon", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.mon" },
  { key: "tue", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.tue" },
  { key: "wed", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.wed" },
  { key: "thu", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.thu" },
  { key: "fri", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.fri" },
  { key: "sat", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.sat" },
  { key: "sun", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.sun" }
];
const styles = (theme) => createStyles({
  textField: {
    minWidth: 98,
    maxWidth: 140
    // Allow for AM/PM as set by locale
  },
  fromToText: {
    display: "inline-block",
    color: theme.palette.text.secondary,
    lineHeight: "19px",
    minWidth: 38
  },
  fromBlock: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: (props) => !props.isDaily ? theme.spacing(1.2) : 0
    }
  },
  fromText: {
    padding: "10px 8px 10px 0"
  },
  toText: {
    padding: "10px 4px",
    textAlign: "center"
  },
  listItemIcon: {
    display: "inline-flex",
    minWidth: "unset",
    marginRight: theme.spacing(1),
    verticalAlign: "middle"
  },
  inset: {
    paddingLeft: 32
  },
  listItemText: {
    display: "inline-flex",
    margin: 0
  },
  listItemTextDisabled: {
    color: "grey",
    display: "inline-flex",
    margin: 0
  },
  select: {
    marginRight: theme.spacing(1.5),
    width: 98
    // "&:before, &:hover:before": {
    //     borderBottomColor: theme.palette.divider
    // },
    // "&:after": {
    //     borderBottomColor: theme.palette.secondary.main
    // },
    // "& .MuiSelect-select": {
    //     minHeight: 25,
    //     paddingTop: 0,
    //     paddingBottom: 0,
    //     overflow: "visible",
    //     "&:focus": {
    //         backgroundColor: theme.palette.background.paper
    //     }
    // },
    // fontSize: 14
  },
  outerContainer: {
    display: "flow-root"
  },
  timePoint: {
    display: "inline-flex",
    justifyContent: "center"
  },
  inputHelp: {
    display: "inline-block",
    width: "1rem",
    height: "auto",
    padding: "1rem 0.5rem 1rem 0"
  }
});
const useStyles = makeOverrideableStyles("TimeWindow", styles);
const TimeWindow = (props) => {
  const {
    allowDaily,
    defaults,
    disabled,
    endDayKey = "to.day",
    endTimeKey = "to.time",
    error,
    isDaily,
    onChange,
    onFocus,
    onBlur,
    id,
    startDayKey = "from.day",
    startTimeKey = "from.time",
    value = {}
  } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const dayOptions = clone(baseDayOptions);
  const errorToDisplay = error && formatErrorMessage(error);
  if (allowDaily) {
    dayOptions.push({ key: "*", value: "cuda.inputs.timeWindow.daily" });
  }
  useEffect(() => {
    const initialState = {};
    if (!get(value, startTimeKey)) {
      set(initialState, startTimeKey, get(defaults, startTimeKey, "00:00"));
    }
    if (!get(value, endTimeKey)) {
      set(initialState, endTimeKey, get(defaults, endTimeKey, "00:00"));
    }
    if (isEmpty(value) && isDaily) {
      set(initialState, startDayKey, "*");
      set(initialState, endDayKey, "*");
    } else {
      if (!get(value, startDayKey)) {
        set(initialState, startDayKey, get(defaults, startDayKey, "sun"));
      }
      if (!get(value, endDayKey)) {
        set(initialState, endDayKey, get(defaults, endDayKey, "mon"));
      }
    }
    if (!isEmpty(initialState)) {
      onChange == null ? void 0 : onChange(initialState);
    }
  }, []);
  const updateWindow = (field, target, isBlur) => {
    if (target) {
      const newWindow = cloneDeep(value);
      if (field === startDayKey) {
        if (target.value === "*" && get(newWindow, startDayKey) !== "*") {
          set(newWindow, endDayKey, target.value);
        } else if (target.value !== "*" && get(newWindow, startDayKey) === "*") {
          set(newWindow, endDayKey, target.value);
        }
      }
      set(newWindow, field, target.value);
      isBlur ? onBlur == null ? void 0 : onBlur(newWindow) : onChange == null ? void 0 : onChange(newWindow);
    }
  };
  const renderMenuItem = (choice) => {
    const choiceName = get(choice, "value");
    const choiceKey = get(choice, "key");
    return /* @__PURE__ */ jsx(
      MenuItem,
      {
        value: choiceKey,
        children: /* @__PURE__ */ jsx(
          ListItemText,
          {
            primary: translate(choiceName),
            className: classes.listItemText,
            classes: { inset: classes.inset }
          }
        )
      },
      choiceKey
    );
  };
  return /* @__PURE__ */ jsxs("div", { className: classes.outerContainer, children: [
    /* @__PURE__ */ jsxs("div", { className: classNames(classes.timePoint, classes.fromBlock), children: [
      /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classNames(classes.fromToText, classes.fromText), children: translate("cuda.inputs.timeWindow.from") }),
      !isDaily ? /* @__PURE__ */ jsx(
        Select,
        {
          value: get(value, startDayKey, "mon"),
          onChange: (event) => updateWindow(startDayKey, event == null ? void 0 : event.target),
          error: !!errorToDisplay,
          disabled,
          id: "start-day-input-" + id,
          className: classes.select,
          variant: "outlined",
          children: dayOptions.map((day) => renderMenuItem(day))
        }
      ) : null,
      /* @__PURE__ */ jsx(
        TextField,
        {
          value: get(value, startTimeKey, ""),
          onBlur: (event) => updateWindow(startTimeKey, event == null ? void 0 : event.target, true),
          onFocus: (event) => onFocus == null ? void 0 : onFocus(event),
          onChange: (event) => updateWindow(startTimeKey, event == null ? void 0 : event.target),
          type: "time",
          error: !!errorToDisplay,
          disabled,
          id: "start-time-input-" + id,
          className: classes.textField,
          inputProps: {
            step: 900
            /* 15 minutes */
          },
          variant: "outlined"
        }
      )
    ] }),
    /* @__PURE__ */ jsxs("div", { className: classes.timePoint, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classNames(classes.fromToText, classes.toText), children: translate("cuda.inputs.timeWindow.to") }),
      !isDaily && get(value, startDayKey) !== "*" ? /* @__PURE__ */ jsx(
        Select,
        {
          value: get(value, endDayKey, ""),
          onChange: (event) => updateWindow(endDayKey, event == null ? void 0 : event.target),
          error: !!errorToDisplay,
          disabled,
          id: "end-day-input-" + id,
          className: classes.select,
          variant: "outlined",
          children: baseDayOptions.map((day) => renderMenuItem(day))
        }
      ) : null,
      /* @__PURE__ */ jsx(
        TextField,
        {
          value: get(value, endTimeKey, ""),
          onBlur: (event) => updateWindow(endTimeKey, event == null ? void 0 : event.target, true),
          onFocus: (event) => onFocus == null ? void 0 : onFocus(event),
          onChange: (event) => updateWindow(endTimeKey, event == null ? void 0 : event.target),
          type: "time",
          size: "small",
          error: !!errorToDisplay,
          disabled,
          id: "end-time-input-" + id,
          className: classes.textField,
          inputProps: {
            step: 900
            /* 15 minutes */
          },
          variant: "outlined"
        }
      )
    ] }),
    error ? /* @__PURE__ */ jsx(FormHelperText, { error: true, children: errorToDisplay }) : null
  ] });
};
var TimeWindow_default = TimeWindow;
export {
  TimeWindow,
  TimeWindow_default as default,
  styles
};
