import { jsx } from "react/jsx-runtime";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const defaultIconSize = 32;
const styles = createStyles({
  initialsIcon: {
    width: (props) => props.size || defaultIconSize,
    height: (props) => props.size || defaultIconSize,
    lineHeight: (props) => `${props.size || defaultIconSize}px`,
    borderRadius: "50%",
    textAlign: "center",
    color: "#ffffff",
    background: "#4DC6BB"
  },
  iconImage: {
    width: (props) => props.size || defaultIconSize,
    height: (props) => props.size || defaultIconSize,
    padding: "3px",
    borderRadius: "8px",
    objectFit: "contain"
  }
});
const useStyles = makeOverrideableStyles("IconPreview", styles);
const IconPreview = (props) => {
  var _a, _b;
  const classes = useStyles(props);
  const fileExtension = props.filename && props.filename.split(".").slice(-1)[0];
  const iconMimeType = fileExtension && ((_a = props.supportedFiles.find((fileType) => fileType.fileExtensions.includes(fileExtension.toLowerCase()))) == null ? void 0 : _a.mimeType);
  const imageSource = props.icon && iconMimeType && `data:${iconMimeType};base64,${props.icon}`;
  const initialsText = (((_b = (props.name || "").match(/[a-zA-Z0-9]/)) == null ? void 0 : _b[0]) || "").toUpperCase();
  return imageSource ? /* @__PURE__ */ jsx("img", { src: imageSource, className: classes.iconImage, alt: props.filename }) : /* @__PURE__ */ jsx("div", { className: classes.initialsIcon, children: initialsText });
};
var IconPreview_default = IconPreview;
export {
  IconPreview_default as default
};
