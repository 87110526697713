var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { get, set } from "lodash";
import ConnectedAutocompleteSearch from "../../search/ConnectedAutocompleteSearch/ConnectedAutocompleteSearch";
import AutocompleteSearch from "../../search/AutocompleteSearch/AutocompleteSearch";
import { FormControl } from "@barracuda-internal/bds-core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useChoices } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { createStyles } from "@mui/styles";
const styles = createStyles({
  selectArray: {
    width: 256,
    paddingTop: 4
  }
});
const useStyles = makeOverrideableStyles("SelectArrayInput", styles);
const SearchableSelect = (props) => {
  const {
    clearOnFocus,
    choices = [],
    disabled,
    filterKey,
    icon,
    iconMap,
    error,
    value = [],
    optionIcon,
    onChange,
    onBlur,
    options = {},
    optionText = "name",
    optionValue = "key",
    placeholder = "cuda.inputs.selectArray.typeToSearch",
    resource,
    selectAll,
    selectAllText = "cuda.inputs.selectArray.all",
    selectAllValue = "*",
    id,
    staticChoices = []
  } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const selectAllChoice = selectAll && set(set({}, optionValue, selectAllValue), optionText, translate(selectAllText));
  const getChoiceId = (choice) => choice && get(choice, optionValue) || "";
  const [chipsToShow, addChipToShow] = useChoices(
    value ? [value] : [],
    {
      choices: choices && [...selectAllChoice ? [selectAllChoice] : [], ...staticChoices, ...choices],
      filterKey: filterKey || optionValue,
      optionValue,
      onAdd: (choice) => {
        onChange == null ? void 0 : onChange(getChoiceId(choice));
        onBlur == null ? void 0 : onBlur(getChoiceId(choice));
      },
      resource,
      params: {
        pagination: {
          page: 1,
          perPage: 20
        }
      }
    }
  );
  const searchProps = __spreadValues({
    onChange: addChipToShow,
    optionText,
    disabled,
    iconMap,
    icon,
    optionIcon,
    noSearchIcon: true,
    placeholder,
    id: "select-input-" + id,
    error
  }, options);
  return /* @__PURE__ */ jsx(FormControl, { className: classes.selectArray, children: resource ? /* @__PURE__ */ jsx(
    ConnectedAutocompleteSearch,
    __spreadValues({
      value: chipsToShow[0],
      resource,
      filterChoices: (choice) => value !== getChoiceId(choice),
      staticChoices: selectAllChoice ? [selectAllChoice, ...staticChoices] : staticChoices,
      totalText: "cuda.inputs.selectArray.showing",
      clearOnFocus
    }, searchProps)
  ) : /* @__PURE__ */ jsx(
    AutocompleteSearch,
    __spreadValues({
      value: chipsToShow[0],
      filterSelectedOptions: true,
      choices: choices && [...selectAllChoice ? [selectAllChoice] : [], ...choices].filter((choice) => value !== getChoiceId(choice)),
      clearOnFocus,
      selectOnFocus: !clearOnFocus
    }, searchProps)
  ) });
};
var SearchableSelect_default = SearchableSelect;
export {
  SearchableSelect,
  SearchableSelect_default as default
};
