var CardSizes = /* @__PURE__ */ ((CardSizes2) => {
  CardSizes2["small"] = "small";
  CardSizes2["medium"] = "medium";
  CardSizes2["large"] = "large";
  return CardSizes2;
})(CardSizes || {});
var RiskLevels = /* @__PURE__ */ ((RiskLevels2) => {
  RiskLevels2["low"] = "low";
  RiskLevels2["lowMedium"] = "lowMedium";
  RiskLevels2["medium"] = "medium";
  RiskLevels2["mediumHigh"] = "mediumHigh";
  RiskLevels2["high"] = "high";
  return RiskLevels2;
})(RiskLevels || {});
const singleCardWidth = 380;
const singleCardHeight = 260;
const columnMargin = 16;
const CardSizeOptions = {
  small: {
    width: singleCardWidth,
    height: singleCardHeight,
    gridLayout: {
      width: 1,
      height: 1
    }
  },
  medium: {
    width: singleCardWidth * 2 + columnMargin,
    height: singleCardHeight,
    gridLayout: {
      width: 2,
      height: 1
    }
  },
  large: {
    width: singleCardWidth * 2 + columnMargin,
    height: singleCardHeight * 2 + columnMargin,
    gridLayout: {
      width: 2,
      height: 2
    }
  }
};
const GridSizeOptions = {
  rowHeight: singleCardHeight,
  columnWidth: singleCardWidth,
  columnMargin
};
export {
  CardSizeOptions,
  CardSizes,
  GridSizeOptions,
  RiskLevels
};
