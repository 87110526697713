import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import LoadingMessage from "../../loading/LoadingMessage/LoadingMessage";
import { useCardGetFrom } from "../../../hooks";
import { Button, Divider, Grid, Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useHistory } from "react-router";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  overviewContainer: {
    margin: theme.spacing(4, 0, 2)
  },
  overviewText: {
    marginRight: theme.spacing(1),
    fontSize: "40px"
  },
  overviewValue: {
    fontWeight: "bold",
    fontSize: "40px"
  },
  dividerRow: {
    width: "75%"
  },
  divider: {
    width: "100%"
  },
  detailsRow: {
    width: "100%"
  },
  detailsContainer: {
    margin: theme.spacing(2, 0)
  },
  detailLinkButton: {
    textTransform: "none",
    padding: theme.spacing(1)
  },
  detailContainer: {
    padding: theme.spacing(1)
  },
  detailsText: {
    margin: theme.spacing(0, 0.5),
    fontSize: "20px",
    color: "#4E4D4D"
  },
  detailsValue: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
    fontSize: "30px"
  }
});
const useStyles = makeOverrideableStyles("ResourceStatisticsCardContent", styles);
const getValue = (data, pathOrFunction) => {
  if (typeof pathOrFunction === "string") {
    return get(data, pathOrFunction);
  }
  return pathOrFunction && pathOrFunction(data);
};
const ResourceStatisticsCardContent = (props) => {
  const { visible, getFrom: getFrom2, overviewText, overviewValue, details, hideFalseyValues } = props;
  const { push } = useHistory();
  const [translate] = useTranslation();
  const classes = useStyles(props);
  const [getFromData, getFromStatus] = useCardGetFrom(getFrom2, props);
  const isLoading = getFromStatus;
  if (!visible) {
    return null;
  }
  if (!getFromData && isLoading) {
    return /* @__PURE__ */ jsx(LoadingMessage, {});
  }
  return /* @__PURE__ */ jsxs(Grid, { container: true, direction: "column", alignItems: "center", children: [
    /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, children: /* @__PURE__ */ jsxs(Grid, { container: true, alignItems: "center", className: classes.overviewContainer, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "h4", className: classes.overviewText, children: translate(overviewText) }),
      /* @__PURE__ */ jsx(Typography, { variant: "h4", className: classes.overviewValue, children: getValue(getFromData, overviewValue) })
    ] }) }),
    details && /* @__PURE__ */ jsxs(React.Fragment, { children: [
      /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.dividerRow, children: /* @__PURE__ */ jsx(Divider, { className: classes.divider }) }),
      /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.detailsRow, children: /* @__PURE__ */ jsx(Grid, { container: true, className: classes.detailsContainer, justifyContent: "space-around", children: details && details.map((detail, index) => {
        const value = getValue(getFromData, detail.value);
        if ((hideFalseyValues || detail.hideFalseyValues) && !value) {
          return null;
        }
        const idSuffix = `${detail.text || index}`.replace(/[^a-zA-Z0-9]/g, "-");
        return /* @__PURE__ */ jsx(
          Grid,
          {
            item: true,
            id: `resource-statistics-statistic-${idSuffix}`,
            onClick: () => detail.link && push(detail.link),
            component: detail.link ? Button : "div",
            className: detail.link ? classes.detailLinkButton : classes.detailContainer,
            children: /* @__PURE__ */ jsxs(Grid, { container: true, direction: "column", alignItems: "center", children: [
              /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(Typography, { className: classes.detailsText, children: translate(detail.text) }) }),
              /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(
                Typography,
                {
                  variant: "h5",
                  className: classes.detailsValue,
                  style: detail.color ? { color: detail.color } : void 0,
                  children: value
                }
              ) })
            ] })
          },
          detail.text || index
        );
      }) }) })
    ] })
  ] });
};
ResourceStatisticsCardContent.defaultProps = {
  overviewText: "cuda.cards.overview",
  overviewValue: "total",
  visible: true
};
var ResourceStatisticsCardContent_default = ResourceStatisticsCardContent;
export {
  ResourceStatisticsCardContent,
  ResourceStatisticsCardContent_default as default
};
