import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Button, CircularProgress, TextField, Typography } from "@barracuda-internal/bds-core";
import { InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CrudTypes, DialogBody, getDataContent, Toolbar, useCrudFetch, useGlobalParam } from "@cuda-react/core";
import hexGenerator from "hex-generator";
import { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import apiResources from "../../../apiResources";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    maxWidth: 400,
    padding: theme.spacing(2)
  },
  inputRoot: {
    width: `calc(100% - ${theme.spacing(8)})`,
    margin: theme.spacing(2, 4, 1),
    "& .MuiOutlinedInput-multiline": {
      height: "inherit"
    }
  },
  completeMessage: {
    margin: theme.spacing(1)
  }
}));
const CreateFirewallTokenContent = ({ onClose }) => {
  const [translate] = useTranslation();
  const classes = useStyles();
  const demo = useGlobalParam("userData.mode")[0] === "demo";
  const [data, , fetchToken] = useCrudFetch(CrudTypes.CREATE, apiResources.registerFirewall);
  const token = useMemo(() => demo ? hexGenerator(128) : (getDataContent(data) || {}).otp, [demo, data]);
  useEffect(() => {
    if (!demo) {
      fetchToken();
    }
  }, []);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      DialogBody,
      {
        classes: { dialogContent: classes.dialogContent },
        title: "tesseract.firewalls.dialog.create.dialogTitle",
        onClose,
        children: [
          /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.completeMessage, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.firewalls.dialog.result.message", children: /* @__PURE__ */ jsx(
            "a",
            {
              href: "https://campus.barracuda.com/doc/98223588/",
              rel: "noreferrer noopener",
              target: "_blank"
            }
          ) }) }),
          /* @__PURE__ */ jsx(
            TextField,
            {
              id: "gateway-otp",
              className: classes.inputRoot,
              InputProps: {
                endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: token ? /* @__PURE__ */ jsx(
                  CopyToClipboardButton,
                  {
                    value: token || "",
                    iconOnly: true
                  }
                ) : /* @__PURE__ */ jsx(CircularProgress, { size: 24 }) })
              },
              fullWidth: true,
              label: translate("tesseract.firewalls.dialog.result.firewallText"),
              value: token || "",
              disabled: !token,
              variant: "outlined"
            }
          ),
          /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.completeMessage, children: translate("tesseract.firewalls.dialog.result.firewallOtp") })
        ]
      }
    ),
    /* @__PURE__ */ jsx(Toolbar, { children: /* @__PURE__ */ jsx(Button, { variant: "contained", color: "primary", onClick: onClose, children: translate("cuda.notification.ok") }) })
  ] });
};
var CreateFirewallTokenContent_default = CreateFirewallTokenContent;
export {
  CreateFirewallTokenContent_default as default
};
