import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import {
  BooleanInput,
  FormSection,
  getArrayDataContent,
  HiddenInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  useCrudProps,
  validateMaxLengthMemo,
  validateRegexMemo
} from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import { User, UserGroup } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { useTranslation } from "react-i18next";
import { categoryIcons } from "../../../../../components/iconMapping";
import { CustomAppsIcon } from "@cuda-react/icons";
import { get } from "lodash";
import useDemoApi from "../../../../../hooks/useDemoApi";
const resourceTypeChoices = [
  { key: "public", name: "tesseract.endpoint.remoteAccessPolicies.resourcesSection.publicResource" },
  { key: "internal", name: "tesseract.endpoint.remoteAccessPolicies.resourcesSection.internalResource" }
];
const hideInput = (key, type) => (value, data) => !get(data, key) || get(data, key) !== type;
const defaultPolicy = {
  accessCriteria: {
    users: [],
    groups: []
  }
};
const BasicPolicyInputs = () => {
  const [translate] = useTranslation();
  const securityPostureChoices = getArrayDataContent(useCrudProps(apiResources.zeroTrustPoliciesSecurityPosture)[0].data);
  const directoriesApi = useDemoApi(apiResources.directories, apiResources.demoDirectories);
  const hasUserDirectory = getArrayDataContent(useCrudProps(directoriesApi)[0].data).length > 0;
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      TextInput,
      {
        label: "tesseract.endpoint.remoteAccessPolicies.name",
        description: "tesseract.endpoint.remoteAccessPolicies.descriptions.name",
        source: "name",
        validate: [validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")],
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        label: "tesseract.endpoint.remoteAccessPolicies.description",
        description: "tesseract.endpoint.remoteAccessPolicies.descriptions.description",
        source: "description",
        validate: [validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]
      }
    ),
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.endpoint.remoteAccessPolicies.resourcesSection.title", children: [
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.endpoint.remoteAccessPolicies.resourcesSection.resourceTypeLabel",
          description: "tesseract.endpoint.remoteAccessPolicies.resourcesSection.resourceTypeDescription",
          source: "resourceCriteria.type",
          choices: resourceTypeChoices,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        SelectArrayInput,
        {
          label: "tesseract.endpoint.remoteAccessPolicies.resourcesSection.resourcesLabel",
          description: "tesseract.endpoint.remoteAccessPolicies.descriptions.applications",
          source: "resourceCriteria.publicApps",
          resource: apiResources.zeroTrustApplications,
          optionValue: "id",
          filterKey: "applicationId",
          options: {
            dropdownAutoWidth: true
          },
          iconMap: categoryIcons,
          optionIcon: "categoryId",
          hide: hideInput("resourceCriteria.type", "public"),
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        SelectArrayInput,
        {
          source: "resourceCriteria.internalApps",
          label: "tesseract.endpoint.remoteAccessPolicies.resourcesSection.resourcesLabel",
          description: "tesseract.endpoint.remoteAccessPolicies.descriptions.applications",
          resource: apiResources.customApps,
          optionValue: "applicationId",
          options: {
            dropdownAutoWidth: true
          },
          hide: hideInput("resourceCriteria.type", "internal"),
          icon: /* @__PURE__ */ jsx(CustomAppsIcon, {}),
          isRequired: true
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.endpoint.remoteAccessPolicies.accessCriteriaSection", children: [
      /* @__PURE__ */ jsx(
        SelectArrayInput,
        {
          label: "tesseract.endpoint.remoteAccessPolicies.users",
          description: "tesseract.endpoint.remoteAccessPolicies.descriptions.users",
          source: "accessCriteria.users",
          resource: !!hasUserDirectory && apiResources.users,
          disabled: !hasUserDirectory,
          error: !hasUserDirectory ? translate("tesseract.security.sourceCriteria.userOrGroupDisabled") : void 0,
          optionValue: "name",
          options: { dropdownAutoWidth: true },
          icon: /* @__PURE__ */ jsx(User, {})
        }
      ),
      /* @__PURE__ */ jsx(
        SelectArrayInput,
        {
          label: "tesseract.endpoint.remoteAccessPolicies.groups",
          description: "tesseract.endpoint.remoteAccessPolicies.descriptions.groups",
          source: "accessCriteria.groups",
          resource: !!hasUserDirectory && apiResources.groups,
          disabled: !hasUserDirectory,
          error: !hasUserDirectory ? translate("tesseract.security.sourceCriteria.userOrGroupDisabled") : void 0,
          optionValue: "id",
          options: { dropdownAutoWidth: true },
          icon: /* @__PURE__ */ jsx(UserGroup, {})
        }
      ),
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.endpoint.remoteAccessPolicies.devicePosture",
          description: "tesseract.endpoint.remoteAccessPolicies.descriptions.securityPosture",
          source: "accessCriteria.securityPosture",
          choices: securityPostureChoices
        }
      ),
      /* @__PURE__ */ jsx(
        HiddenInput,
        {
          source: "accessCriteria.securityInspection",
          hiddenValue: true,
          hide: hideInput("resourceCriteria.type", "internal")
        }
      ),
      /* @__PURE__ */ jsx(
        BooleanInput,
        {
          description: "tesseract.endpoint.remoteAccessPolicies.descriptions.securityInspection",
          label: "tesseract.endpoint.remoteAccessPolicies.securityInspection",
          source: "accessCriteria.securityInspection",
          disable: hideInput("resourceCriteria.type", "public")
        }
      )
    ] })
  ] });
};
var BasicPolicyInputs_default = BasicPolicyInputs;
export {
  BasicPolicyInputs,
  BasicPolicyInputs_default as default,
  defaultPolicy
};
