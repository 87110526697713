const mockData = {
  "instanceId": "834873",
  "sourceDataPoints": [
    {
      "id": "20.213.242.28",
      "label": "20.213.242.28",
      "numberOfBytes": 16750372454,
      "numberOfSessions": 27826
    },
    {
      "id": "203.0.113.73",
      "label": "203.0.113.73",
      "numberOfBytes": 16320875725,
      "numberOfSessions": 2461
    },
    {
      "id": "172.16.10.240",
      "label": "172.16.10.240",
      "numberOfBytes": 8804682957,
      "numberOfSessions": 37301
    },
    {
      "id": "127.0.0.1",
      "label": "127.0.0.1",
      "numberOfBytes": 7945689498,
      "numberOfSessions": 229334
    },
    {
      "id": "169.254.0.3",
      "label": "169.254.0.3",
      "numberOfBytes": 3168480,
      "numberOfSessions": 26404
    },
    {
      "id": "198.51.100.36",
      "label": "198.51.100.36",
      "numberOfBytes": 889336,
      "numberOfSessions": 5
    },
    {
      "id": "192.168.73.101",
      "label": "192.168.73.101",
      "numberOfBytes": 603045,
      "numberOfSessions": 76
    },
    {
      "id": "169.254.128.2",
      "label": "169.254.128.2",
      "numberOfBytes": 538840,
      "numberOfSessions": 3412
    },
    {
      "id": "10.17.83.76",
      "label": "10.17.83.76",
      "numberOfBytes": 173184,
      "numberOfSessions": 460
    },
    {
      "id": "10.17.83.55",
      "label": "10.17.83.55",
      "numberOfBytes": 173184,
      "numberOfSessions": 460
    },
    {
      "id": "10.0.20.1",
      "label": "10.0.20.1",
      "numberOfBytes": 173184,
      "numberOfSessions": 460
    },
    {
      "id": "192.168.171.1",
      "label": "192.168.171.1",
      "numberOfBytes": 163672,
      "numberOfSessions": 452
    },
    {
      "id": "Consolidated",
      "label": "Consolidated",
      "numberOfBytes": 39250,
      "numberOfSessions": 127
    }
  ],
  "geoSourceDataPoints": [
    {
      "id": "AU",
      "label": "Australia",
      "numberOfBytes": 2106136183,
      "numberOfSessions": 654321
    },
    {
      "id": "AT",
      "label": "Austria",
      "numberOfBytes": 1806136183,
      "numberOfSessions": 643210
    },
    {
      "id": "DE",
      "label": "Germany",
      "numberOfBytes": 1706136542,
      "numberOfSessions": 555444
    },
    {
      "id": "NL",
      "label": "Netherlands",
      "numberOfBytes": 1536136895,
      "numberOfSessions": 498356
    },
    {
      "id": "GB",
      "label": "United Kingdom",
      "numberOfBytes": 1456136362,
      "numberOfSessions": 423654
    },
    {
      "id": "US",
      "label": "United States",
      "numberOfBytes": 1106136822,
      "numberOfSessions": 333666
    },
    {
      "id": "SK",
      "label": "Slovakia",
      "numberOfBytes": 1008456412,
      "numberOfSessions": 300221
    },
    {
      "id": "CZ",
      "label": "Czech Repbulic",
      "numberOfBytes": 741206423,
      "numberOfSessions": 258654
    },
    {
      "id": "HU",
      "label": "Hungary",
      "numberOfBytes": 341206974,
      "numberOfSessions": 298456
    },
    {
      "id": "TR",
      "label": "Turkey",
      "numberOfBytes": 170032423,
      "numberOfSessions": 124321
    },
    {
      "id": "ZZ",
      "label": "Non-routable or Private IP Addresses",
      "numberOfBytes": 5015067018,
      "numberOfSessions": 987123
    }
  ],
  "geoDestinationDataPoints": [
    {
      "id": "ZZ",
      "label": "Non-routable or Private IP Addresses",
      "numberOfBytes": 2143239120,
      "numberOfSessions": 35429
    },
    {
      "id": "NL",
      "label": "Netherlands",
      "numberOfBytes": 79626147,
      "numberOfSessions": 1383
    },
    {
      "id": "AU",
      "label": "Australia",
      "numberOfBytes": 58829726,
      "numberOfSessions": 21
    },
    {
      "id": "US",
      "label": "United States",
      "numberOfBytes": 37026202,
      "numberOfSessions": 1063
    },
    {
      "id": "DE",
      "label": "Germany",
      "numberOfBytes": 2019924,
      "numberOfSessions": 284
    },
    {
      "id": "AT",
      "label": "Austria",
      "numberOfBytes": 330344,
      "numberOfSessions": 1792
    },
    {
      "id": "UK",
      "label": "United Kingdom",
      "numberOfBytes": 125621,
      "numberOfSessions": 52
    },
    {
      "id": "IE",
      "label": "Ireland",
      "numberOfBytes": 6117,
      "numberOfSessions": 1
    }
  ],
  "riskDataPoints": [
    {
      "id": "1",
      "label": "1",
      "numberOfBytes": 2106754532,
      "numberOfSessions": 136
    },
    {
      "id": "2",
      "label": "2",
      "numberOfBytes": 1726754532,
      "numberOfSessions": 155
    },
    {
      "id": "3",
      "label": "3",
      "numberOfBytes": 1206754812,
      "numberOfSessions": 100
    },
    {
      "id": "4",
      "label": "4",
      "numberOfBytes": 226754987,
      "numberOfSessions": 77
    },
    {
      "id": "0",
      "label": "0",
      "numberOfBytes": 214448669,
      "numberOfSessions": 39889
    }
  ],
  "categoryDataPoints": [
    {
      "id": "0",
      "label": "Uncategorized",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 40025
    },
    {
      "id": "2",
      "label": "Standard Network",
      "numberOfBytes": 211203123,
      "numberOfSessions": 20354
    },
    {
      "id": "3",
      "label": "Web Browsing",
      "numberOfBytes": 1981200278,
      "numberOfSessions": 17852
    },
    {
      "id": "5",
      "label": "Remote Access",
      "numberOfBytes": 1231205412,
      "numberOfSessions": 22821
    },
    {
      "id": "7",
      "label": "Business",
      "numberOfBytes": 1111206543,
      "numberOfSessions": 36123
    },
    {
      "id": "11",
      "label": "Social Networking",
      "numberOfBytes": 961206395,
      "numberOfSessions": 33222
    },
    {
      "id": "13",
      "label": "Software Update",
      "numberOfBytes": 951203201,
      "numberOfSessions": 8256
    },
    {
      "id": "17",
      "label": "File Sharing P2P",
      "numberOfBytes": 631203248,
      "numberOfSessions": 3898
    },
    {
      "id": "19",
      "label": "Media Streaming",
      "numberOfBytes": 441202198,
      "numberOfSessions": 2923
    },
    {
      "id": "23",
      "label": "Conferencing",
      "numberOfBytes": 331203201,
      "numberOfSessions": 1987
    },
    {
      "id": "29",
      "label": "File Storage and Backup",
      "numberOfBytes": 231203201,
      "numberOfSessions": 873
    }
  ],
  "sdwanCategoryDataPoints": [
    {
      "id": "0",
      "label": "Uncategorized",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 40025
    },
    {
      "id": "2",
      "label": "Network Bulk",
      "numberOfBytes": 211203123,
      "numberOfSessions": 20354
    },
    {
      "id": "3",
      "label": "Web Traffic",
      "numberOfBytes": 1981200278,
      "numberOfSessions": 17852
    },
    {
      "id": "5",
      "label": "Remote Access",
      "numberOfBytes": 1231205412,
      "numberOfSessions": 22821
    },
    {
      "id": "7",
      "label": "SaaS & Business",
      "numberOfBytes": 1111206543,
      "numberOfSessions": 36123
    },
    {
      "id": "11",
      "label": "Office 365",
      "numberOfBytes": 961206395,
      "numberOfSessions": 33222
    },
    {
      "id": "13",
      "label": "Voice & Video",
      "numberOfBytes": 951203201,
      "numberOfSessions": 8256
    },
    {
      "id": "17",
      "label": "Network Services",
      "numberOfBytes": 631203248,
      "numberOfSessions": 3898
    }
  ],
  "blockedSdwanCategoryDataPoints": [
    {
      "id": "0",
      "label": "Uncategorized",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 40025
    },
    {
      "id": "2",
      "label": "Network Bulk",
      "numberOfBytes": 211203123,
      "numberOfSessions": 20354
    },
    {
      "id": "3",
      "label": "Web Traffic",
      "numberOfBytes": 1981200278,
      "numberOfSessions": 17852
    },
    {
      "id": "5",
      "label": "Remote Access",
      "numberOfBytes": 1231205412,
      "numberOfSessions": 22821
    },
    {
      "id": "7",
      "label": "SaaS & Business",
      "numberOfBytes": 1111206543,
      "numberOfSessions": 36123
    },
    {
      "id": "11",
      "label": "Office 365",
      "numberOfBytes": 961206395,
      "numberOfSessions": 33222
    },
    {
      "id": "13",
      "label": "Voice & Video",
      "numberOfBytes": 951203201,
      "numberOfSessions": 8256
    },
    {
      "id": "17",
      "label": "Network Services",
      "numberOfBytes": 631203248,
      "numberOfSessions": 3898
    }
  ],
  "blockedCategoryDataPoints": [
    {
      "id": "0",
      "label": "Uncategorized",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 40025
    },
    {
      "id": "2",
      "label": "Standard Network",
      "numberOfBytes": 211203123,
      "numberOfSessions": 20354
    },
    {
      "id": "3",
      "label": "Web Browsing",
      "numberOfBytes": 1981200278,
      "numberOfSessions": 17852
    },
    {
      "id": "5",
      "label": "Remote Access",
      "numberOfBytes": 1231205412,
      "numberOfSessions": 22821
    },
    {
      "id": "7",
      "label": "Business",
      "numberOfBytes": 1111206543,
      "numberOfSessions": 36123
    },
    {
      "id": "11",
      "label": "Social Networking",
      "numberOfBytes": 961206395,
      "numberOfSessions": 33222
    },
    {
      "id": "13",
      "label": "Software Update",
      "numberOfBytes": 951203201,
      "numberOfSessions": 8256
    },
    {
      "id": "17",
      "label": "File Sharing P2P",
      "numberOfBytes": 631203248,
      "numberOfSessions": 3898
    },
    {
      "id": "19",
      "label": "Media Streaming",
      "numberOfBytes": 441202198,
      "numberOfSessions": 2923
    },
    {
      "id": "23",
      "label": "Conferencing",
      "numberOfBytes": 331203201,
      "numberOfSessions": 1987
    },
    {
      "id": "29",
      "label": "File Storage and Backup",
      "numberOfBytes": 231203201,
      "numberOfSessions": 873
    }
  ],
  "protocolDataPoints": [
    {
      "id": "0",
      "label": "0",
      "numberOfBytes": 2320609901,
      "numberOfSessions": 40005
    },
    {
      "id": "1002",
      "label": "HTTP direct",
      "numberOfBytes": 559483,
      "numberOfSessions": 20
    },
    {
      "id": "1007",
      "label": "SSL",
      "numberOfBytes": 26591,
      "numberOfSessions": 0
    },
    {
      "id": "1001",
      "label": "HTTP",
      "numberOfBytes": 7226,
      "numberOfSessions": 0
    }
  ],
  "applicationDataPoints": [
    {
      "id": "123456",
      "label": "Web browsing",
      "numberOfBytes": 2106754532,
      "numberOfSessions": 40985
    },
    {
      "id": "258431",
      "label": "ESXi",
      "numberOfBytes": 1986754745,
      "numberOfSessions": 12687
    },
    {
      "id": "641456",
      "label": "Zoom Video",
      "numberOfBytes": 12767587456,
      "numberOfSessions": 19453
    },
    {
      "id": "153843",
      "label": "CentOS Update",
      "numberOfBytes": 12267584523,
      "numberOfSessions": 7563
    },
    {
      "id": "153847",
      "label": "Ubuntu Update",
      "numberOfBytes": 10567584980,
      "numberOfSessions": 6300
    },
    {
      "id": "100000",
      "label": "Barracuda Networks Online",
      "numberOfBytes": 9367584812,
      "numberOfSessions": 8663
    },
    {
      "id": "654893",
      "label": "Microsoft 365 Outlook",
      "numberOfBytes": 7467583245,
      "numberOfSessions": 16852
    },
    {
      "id": "546321",
      "label": "YouTube Watch Video",
      "numberOfBytes": 7267583326,
      "numberOfSessions": 13352
    },
    {
      "id": "893632",
      "label": "Slack",
      "numberOfBytes": 7267583326,
      "numberOfSessions": 12689
    },
    {
      "id": "546875",
      "label": "Microsoft Update",
      "numberOfBytes": 5067584568,
      "numberOfSessions": 3658
    },
    {
      "id": "0",
      "label": "0",
      "numberOfBytes": 214448669,
      "numberOfSessions": 39889
    }
  ],
  "blockedApplicationDataPoints": [
    {
      "id": "123456",
      "label": "Web browsing",
      "numberOfBytes": 2106754532,
      "numberOfSessions": 40985
    },
    {
      "id": "258431",
      "label": "ESXi",
      "numberOfBytes": 1986754745,
      "numberOfSessions": 12687
    },
    {
      "id": "641456",
      "label": "Zoom Video",
      "numberOfBytes": 12767587456,
      "numberOfSessions": 19453
    },
    {
      "id": "153843",
      "label": "CentOS Update",
      "numberOfBytes": 12267584523,
      "numberOfSessions": 7563
    },
    {
      "id": "153847",
      "label": "Ubuntu Update",
      "numberOfBytes": 10567584980,
      "numberOfSessions": 6300
    },
    {
      "id": "100000",
      "label": "Barracuda Networks Online",
      "numberOfBytes": 9367584812,
      "numberOfSessions": 8663
    },
    {
      "id": "654893",
      "label": "Microsoft 365 Outlook",
      "numberOfBytes": 7467583245,
      "numberOfSessions": 16852
    },
    {
      "id": "546321",
      "label": "YouTube Watch Video",
      "numberOfBytes": 7267583326,
      "numberOfSessions": 13352
    },
    {
      "id": "893632",
      "label": "Slack",
      "numberOfBytes": 7267583326,
      "numberOfSessions": 12689
    },
    {
      "id": "546875",
      "label": "Microsoft Update",
      "numberOfBytes": 5067584568,
      "numberOfSessions": 3658
    },
    {
      "id": "0",
      "label": "0",
      "numberOfBytes": 214448669,
      "numberOfSessions": 39889
    }
  ],
  "contentDataPoints": [
    {
      "id": "2",
      "label": "DEB",
      "numberOfBytes": 851203201,
      "numberOfSessions": 17352
    },
    {
      "id": "3",
      "label": "GZIP",
      "numberOfBytes": 771203201,
      "numberOfSessions": 16231
    },
    {
      "id": "5",
      "label": "XML",
      "numberOfBytes": 621202987,
      "numberOfSessions": 13212
    },
    {
      "id": "7",
      "label": "RPM",
      "numberOfBytes": 531201896,
      "numberOfSessions": 6325
    },
    {
      "id": "11",
      "label": "JPEG",
      "numberOfBytes": 461209685,
      "numberOfSessions": 13683
    },
    {
      "id": "13",
      "label": "ELF",
      "numberOfBytes": 221208416,
      "numberOfSessions": 3215
    },
    {
      "id": "17",
      "label": "ZIP",
      "numberOfBytes": 171206743,
      "numberOfSessions": 6780
    },
    {
      "id": "19",
      "label": "shell",
      "numberOfBytes": 131203269,
      "numberOfSessions": 8631
    },
    {
      "id": "23",
      "label": "EXE",
      "numberOfBytes": 111200212,
      "numberOfSessions": 2674
    },
    {
      "id": "29",
      "label": "PNG",
      "numberOfBytes": 101208430,
      "numberOfSessions": 6984
    },
    {
      "id": "0",
      "label": "0",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 40025
    }
  ],
  "domainDataPoints": [
    {
      "id": "cuda-inc.com",
      "label": "cuda-inc.com",
      "numberOfBytes": 511201896,
      "numberOfSessions": 12687
    },
    {
      "id": "ubuntu.com",
      "label": "ubuntu.com",
      "numberOfBytes": 361209875,
      "numberOfSessions": 5321
    },
    {
      "id": "localhost.localdomain",
      "label": "localhost.localdomain",
      "numberOfBytes": 281205462,
      "numberOfSessions": 1698
    },
    {
      "id": "microsoft.com",
      "label": "microsoft.com",
      "numberOfBytes": 271200398,
      "numberOfSessions": 9478
    },
    {
      "id": "office365.com",
      "label": "office365.com",
      "numberOfBytes": 231209465,
      "numberOfSessions": 6712
    },
    {
      "id": "pkgbuild.com",
      "label": "pkgbuild.com",
      "numberOfBytes": 191207412,
      "numberOfSessions": 2136
    },
    {
      "id": "googlevideo.com",
      "label": "googlevideo.com",
      "numberOfBytes": 181202689,
      "numberOfSessions": 963
    },
    {
      "id": "slack-edge.com",
      "label": "slack-edge.com",
      "numberOfBytes": 133202689,
      "numberOfSessions": 1456
    },
    {
      "id": "snapcraft.io",
      "label": "snapcraft.io",
      "numberOfBytes": 93202689,
      "numberOfSessions": 1736
    },
    {
      "id": "google.com",
      "label": "google.com",
      "numberOfBytes": 82202689,
      "numberOfSessions": 7543
    }
  ],
  "blockedDomainDataPoints": [
    {
      "id": "cuda-inc.com",
      "label": "cuda-inc.com",
      "numberOfBytes": 511201896,
      "numberOfSessions": 12687
    },
    {
      "id": "ubuntu.com",
      "label": "ubuntu.com",
      "numberOfBytes": 361209875,
      "numberOfSessions": 5321
    },
    {
      "id": "localhost.localdomain",
      "label": "localhost.localdomain",
      "numberOfBytes": 281205462,
      "numberOfSessions": 1698
    },
    {
      "id": "microsoft.com",
      "label": "microsoft.com",
      "numberOfBytes": 271200398,
      "numberOfSessions": 9478
    },
    {
      "id": "office365.com",
      "label": "office365.com",
      "numberOfBytes": 231209465,
      "numberOfSessions": 6712
    },
    {
      "id": "pkgbuild.com",
      "label": "pkgbuild.com",
      "numberOfBytes": 191207412,
      "numberOfSessions": 2136
    },
    {
      "id": "googlevideo.com",
      "label": "googlevideo.com",
      "numberOfBytes": 181202689,
      "numberOfSessions": 963
    },
    {
      "id": "slack-edge.com",
      "label": "slack-edge.com",
      "numberOfBytes": 133202689,
      "numberOfSessions": 1456
    },
    {
      "id": "snapcraft.io",
      "label": "snapcraft.io",
      "numberOfBytes": 93202689,
      "numberOfSessions": 1736
    },
    {
      "id": "google.com",
      "label": "google.com",
      "numberOfBytes": 82202689,
      "numberOfSessions": 7543
    }
  ],
  "targetDataPoints": [
    {
      "id": "",
      "label": "",
      "numberOfBytes": 2320609901,
      "numberOfSessions": 40005
    },
    {
      "id": "800018-archive.ubuntu.com",
      "label": "800018-archive.ubuntu.com",
      "numberOfBytes": 367940,
      "numberOfSessions": 12
    },
    {
      "id": "800018-security.ubuntu.com",
      "label": "800018-security.ubuntu.com",
      "numberOfBytes": 190649,
      "numberOfSessions": 4
    },
    {
      "id": "800018-api.snapcraft.io",
      "label": "800018-api.snapcraft.io",
      "numberOfBytes": 21015,
      "numberOfSessions": 0
    },
    {
      "id": "800018-dl.google.com",
      "label": "800018-dl.google.com",
      "numberOfBytes": 8120,
      "numberOfSessions": 4
    },
    {
      "id": "800018-motd.ubuntu.com",
      "label": "800018-motd.ubuntu.com",
      "numberOfBytes": 5576,
      "numberOfSessions": 0
    }
  ],
  "userDataPoints": [
    {
      "id": "#",
      "label": "#",
      "numberOfBytes": 42321203201,
      "numberOfSessions": 40025
    },
    {
      "id": "User-A",
      "label": "User-A",
      "numberOfBytes": 42321203201,
      "numberOfSessions": 17325
    },
    {
      "id": "User-B",
      "label": "User-B",
      "numberOfBytes": 1821203201,
      "numberOfSessions": 15312
    },
    {
      "id": "User-C",
      "label": "User-C",
      "numberOfBytes": 1621203201,
      "numberOfSessions": 8456
    },
    {
      "id": "User-D",
      "label": "User-D",
      "numberOfBytes": 1521203201,
      "numberOfSessions": 3214
    },
    {
      "id": "User-E",
      "label": "User-E",
      "numberOfBytes": 1421203201,
      "numberOfSessions": 8102
    },
    {
      "id": "User-F",
      "label": "User-F",
      "numberOfBytes": 1321203201,
      "numberOfSessions": 11369
    },
    {
      "id": "User-G",
      "label": "User-G",
      "numberOfBytes": 1121203201,
      "numberOfSessions": 7985
    },
    {
      "id": "User-H",
      "label": "User-H",
      "numberOfBytes": 921203201,
      "numberOfSessions": 6045
    },
    {
      "id": "User-J",
      "label": "User-J",
      "numberOfBytes": 821203201,
      "numberOfSessions": 8450
    }
  ],
  "blockedUserDataPoints": [
    {
      "id": "#",
      "label": "#",
      "numberOfBytes": 42321203201,
      "numberOfSessions": 40025
    },
    {
      "id": "User-A",
      "label": "User-A",
      "numberOfBytes": 42321203201,
      "numberOfSessions": 17325
    },
    {
      "id": "User-B",
      "label": "User-B",
      "numberOfBytes": 1821203201,
      "numberOfSessions": 15312
    },
    {
      "id": "User-C",
      "label": "User-C",
      "numberOfBytes": 1621203201,
      "numberOfSessions": 8456
    },
    {
      "id": "User-D",
      "label": "User-D",
      "numberOfBytes": 1521203201,
      "numberOfSessions": 3214
    },
    {
      "id": "User-E",
      "label": "User-E",
      "numberOfBytes": 1421203201,
      "numberOfSessions": 8102
    },
    {
      "id": "User-F",
      "label": "User-F",
      "numberOfBytes": 1321203201,
      "numberOfSessions": 11369
    },
    {
      "id": "User-G",
      "label": "User-G",
      "numberOfBytes": 1121203201,
      "numberOfSessions": 7985
    },
    {
      "id": "User-H",
      "label": "User-H",
      "numberOfBytes": 921203201,
      "numberOfSessions": 6045
    },
    {
      "id": "User-J",
      "label": "User-J",
      "numberOfBytes": 821203201,
      "numberOfSessions": 8450
    }
  ],
  "urlCategoryDataPoints": [
    {
      "id": "53",
      "label": "Business Commercial",
      "numberOfBytes": 1721203201,
      "numberOfSessions": 18975
    },
    {
      "id": "91",
      "label": "Computing & Internet",
      "numberOfBytes": 1521208415,
      "numberOfSessions": 23840
    },
    {
      "id": "50",
      "label": "Downloads",
      "numberOfBytes": 1421209988,
      "numberOfSessions": 5398
    },
    {
      "id": "41",
      "label": "Content Delivery",
      "numberOfBytes": 921203201,
      "numberOfSessions": 22475
    },
    {
      "id": "47",
      "label": "Hosting Sites",
      "numberOfBytes": 721208456,
      "numberOfSessions": 9620
    },
    {
      "id": "43",
      "label": "Web Based Mail",
      "numberOfBytes": 581208456,
      "numberOfSessions": 3511
    },
    {
      "id": "37",
      "label": "Parked Domains",
      "numberOfBytes": 331208456,
      "numberOfSessions": 1654
    },
    {
      "id": "31",
      "label": "YouTube",
      "numberOfBytes": 308208456,
      "numberOfSessions": 812
    },
    {
      "id": "29",
      "label": "Storage Services",
      "numberOfBytes": 11208456,
      "numberOfSessions": 1234
    },
    {
      "id": "51",
      "label": "Program Downloads",
      "numberOfBytes": 9208456,
      "numberOfSessions": 546
    }
  ],
  "blockedUrlCategoryDataPoints": [
    {
      "id": "53",
      "label": "Business Commercial",
      "numberOfBytes": 1721203201,
      "numberOfSessions": 18975
    },
    {
      "id": "91",
      "label": "Computing & Internet",
      "numberOfBytes": 1521208415,
      "numberOfSessions": 23840
    },
    {
      "id": "50",
      "label": "Downloads",
      "numberOfBytes": 1421209988,
      "numberOfSessions": 5398
    },
    {
      "id": "41",
      "label": "Content Delivery",
      "numberOfBytes": 921203201,
      "numberOfSessions": 22475
    },
    {
      "id": "47",
      "label": "Hosting Sites",
      "numberOfBytes": 721208456,
      "numberOfSessions": 9620
    },
    {
      "id": "43",
      "label": "Web Based Mail",
      "numberOfBytes": 581208456,
      "numberOfSessions": 3511
    },
    {
      "id": "37",
      "label": "Parked Domains",
      "numberOfBytes": 331208456,
      "numberOfSessions": 1654
    },
    {
      "id": "31",
      "label": "YouTube",
      "numberOfBytes": 308208456,
      "numberOfSessions": 812
    },
    {
      "id": "29",
      "label": "Storage Services",
      "numberOfBytes": 11208456,
      "numberOfSessions": 1234
    },
    {
      "id": "51",
      "label": "Program Downloads",
      "numberOfBytes": 9208456,
      "numberOfSessions": 546
    }
  ],
  "virusDataPoints": [
    {
      "id": "",
      "label": "",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 40025
    }
  ],
  "atdDataPoints": [
    {
      "id": "phishing.file",
      "label": "phishing.file",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 245
    },
    {
      "id": "invoice.pdf",
      "label": "invoice.pdf",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 15
    },
    {
      "id": "flash.exe",
      "label": "flash.exe",
      "numberOfBytes": 456879823102,
      "numberOfSessions": 11
    },
    {
      "id": "reports.xls",
      "label": "reports.xls",
      "numberOfBytes": 2354686,
      "numberOfSessions": 1
    }
  ],
  "ipsDataPoints": [
    {
      "id": "",
      "label": "",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 40025
    }
  ],
  "agentDataPoints": [
    {
      "id": "0",
      "label": "0",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 40025
    }
  ],
  "applicationPropertiesDataPoints": [
    {
      "id": "0",
      "label": "0",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 40025,
      "properties": []
    }
  ],
  "blockedApplicationPropertiesDataPoints": [
    {
      "id": "0",
      "label": "0",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 40025,
      "properties": []
    }
  ],
  "reputationDataPoints": [
    {
      "id": "",
      "label": "",
      "numberOfBytes": 2321203201,
      "numberOfSessions": 40025
    }
  ]
};
const dataGenerator = () => mockData;
var reportingData_default = dataGenerator;
export {
  reportingData_default as default
};
