import { jsx, jsxs } from "react/jsx-runtime";
import { BasicPage, Tab, Tabs, useMixpanel } from "@cuda-react/core";
import { Paper } from "@barracuda-internal/bds-core";
import IPSSettings from "./IPSSettings";
import IPSFalsePositives from "./IPSFalsePositives";
const IPS = () => {
  useMixpanel("IPS");
  return /* @__PURE__ */ jsx(
    BasicPage,
    {
      title: "tesseract.securityAndAccess.pageTitle",
      subtitle: ["tesseract.securityAndAccess.security", "tesseract.securityAndAccess.ips"],
      authenticated: true,
      children: /* @__PURE__ */ jsx(Paper, { children: /* @__PURE__ */ jsxs(Tabs, { children: [
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.security.ips.settings.tabTitle", children: /* @__PURE__ */ jsx(IPSSettings, {}) }),
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.security.ips.settings.falsePositivesTabTitle", children: /* @__PURE__ */ jsx(IPSFalsePositives, {}) })
      ] }) })
    }
  );
};
var IPS_default = IPS;
export {
  IPS,
  IPS_default as default
};
