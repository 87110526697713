var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  getArrayDataContent,
  useCrudProps,
  useDeepCompareMemo,
  validateArray,
  validateCIDR,
  validateInt,
  validateIpv4,
  validateIpv4CIDR,
  validateMaxLength,
  validateMaxValue,
  validateMinLength,
  validateMinValue,
  validateNegativeRegex,
  validatePrivateIpv4,
  validateRegex,
  validateRequired
} from "@cuda-react/core";
import { get, isArray, isEqual, set } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import apiResources from "../apiResources";
import { useWatch } from "react-hook-form";
const getNetworkId = (network = {}) => network.port + (network.virtualLanId ? "." + network.virtualLanId : "");
const getExpressRouteNetworkId = (network = {}, portIndex) => getNetworkId({
  port: get(network, `doublePort[${portIndex}]`),
  virtualLanId: get(network, `doubleVirtualLanId[${portIndex}]`)
});
const getNetworkPorts = (network) => {
  if (network.mode === "bridge") {
    return [...network.lanPorts, network.wanPort];
  }
  if (network.mode === "expressRoute") {
    return network == null ? void 0 : network.doublePort;
  }
  return [network.port];
};
const findNetworksMatching = (targetNetwork) => (network) => network.mode === "expressRoute" ? getExpressRouteNetworkId(network, 0) === getNetworkId(targetNetwork) || getExpressRouteNetworkId(network, 1) === getNetworkId(targetNetwork) : getNetworkId(network) === getNetworkId(targetNetwork);
const validateNetworks = (values, arrayKey, arrayLength, translate, errors, usedNetworks = []) => {
  get(values, arrayKey, []).slice(0, arrayLength || get(values, arrayKey, []).length).forEach((value, index) => {
    var _a, _b;
    switch (value && value.mode) {
      case "wwan":
        if (usedNetworks.includes("lte")) {
          set(errors, arrayKey + `[${index}].mode`, translate("tesseract.appliances.dialog.validation.duplicateWwan"));
        }
        usedNetworks.push("lte");
        break;
      case "expressRoute":
        if (usedNetworks.includes("expressRoute")) {
          set(errors, arrayKey + `[${index}].mode`, translate("tesseract.appliances.dialog.validation.duplicateExpressRoute"));
        }
        usedNetworks.push("expressRoute");
        if (usedNetworks.includes(getExpressRouteNetworkId(value, 0))) {
          set(errors, arrayKey + `[${index}].doublePort[0]`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
          set(errors, arrayKey + `[${index}].doubleVirtualLanId[0]`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
        }
        usedNetworks.push(getExpressRouteNetworkId(value, 0));
        if (usedNetworks.includes(getExpressRouteNetworkId(value, 1))) {
          set(errors, arrayKey + `[${index}].doublePort[1]`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
          set(errors, arrayKey + `[${index}].doubleVirtualLanId[1]`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
        }
        usedNetworks.push(getExpressRouteNetworkId(value, 1));
        break;
      case "bridge":
        if (value && value.wanPort && usedNetworks.some((networkId) => networkId.split(".")[0] === value.wanPort)) {
          set(errors, arrayKey + `[${index}]wanPort`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
        }
        value && value.wanPort && usedNetworks.push(value.wanPort);
        value && value.lanPorts && value.lanPorts.forEach((lanPort, lanIndex) => {
          if (usedNetworks.some((networkId) => networkId.split(".")[0] === lanPort)) {
            set(errors, arrayKey + `[${index}]lanPorts[${lanIndex}]`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
          }
          usedNetworks.push(lanPort);
        });
        break;
      default:
        if ((_a = values == null ? void 0 : values.wans) == null ? void 0 : _a.find((wan) => wan.mode === "bridge")) {
          const bridgeIndex = values.wans.findIndex((wan) => wan.mode === "bridge");
          const bridgeWanPort = values.wans[bridgeIndex].wanPort;
          const bridgeLanPorts = values.wans[bridgeIndex].lanPorts;
          if (bridgeWanPort === value.port || value.port && (bridgeLanPorts == null ? void 0 : bridgeLanPorts.includes(value.port))) {
            set(errors, arrayKey + `[${index}].port`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
          }
        }
        if ((_b = values == null ? void 0 : values.lans) == null ? void 0 : _b.find((lan) => lan.mode === "bridge")) {
          const bridgeIndex = values.lans.findIndex((lan) => lan.mode === "bridge");
          const bridgeLanPorts = values.lans[bridgeIndex].lanPorts;
          if (value.port && (bridgeLanPorts == null ? void 0 : bridgeLanPorts.includes(value.port))) {
            set(errors, arrayKey + `[${index}].port`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
          }
        }
        if (value && value.port && usedNetworks.includes(getNetworkId(value))) {
          set(errors, arrayKey + `[${index}].port`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
          set(errors, arrayKey + `[${index}].virtualLanId`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
        }
        value && usedNetworks.push(getNetworkId(value));
    }
  });
  return usedNetworks;
};
const validateNames = (values, arrayKey, arrayLength, translate, errors, usedNames = []) => {
  get(values, arrayKey, []).slice(0, arrayLength || get(values, arrayKey, []).length).forEach((value, index) => {
    if (value && usedNames.includes(get(value, "name", ""))) {
      set(errors, arrayKey + "[" + index + "].name", translate("tesseract.appliances.dialog.validation.duplicateName"));
    }
    value && usedNames.push(get(value, "name", ""));
  });
  return usedNames;
};
const networkFormValidation = (existingNetworks, asnData, translate) => (values = {}, initialValues = {}) => {
  var _a, _b;
  const errors = {};
  if (values.name !== initialValues.name && existingNetworks.find((network) => network.name === values.name)) {
    errors.name = translate("tesseract.appliances.dialog.validation.duplicateName");
  }
  if (values.mode === "pppoe" && initialValues.mode !== "pppoe" && existingNetworks.filter((wan, index) => wan.mode === "pppoe").length >= 4) {
    set(errors, "mode", translate("tesseract.appliances.dialog.validation.exceededPPoE"));
  }
  if (values.mode === "expressRoute" && (!isEqual(values.doublePort, initialValues.doublePort) || !isEqual(values.doubleVirtualLanId, initialValues.doubleVirtualLanId))) {
    if (existingNetworks.find((network) => getNetworkId(network) === getExpressRouteNetworkId(values, 0))) {
      set(errors, "doublePort[0]", translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
      set(errors, "doubleVirtualLanId[0]", translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
    }
    if (existingNetworks.find((network) => getNetworkId(network) === getExpressRouteNetworkId(values, 1)) || getExpressRouteNetworkId(values, 0) === getExpressRouteNetworkId(values, 1)) {
      set(errors, "doublePort[1]", translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
      set(errors, "doubleVirtualLanId[1]", translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
    }
  }
  if (values.mode === "bridge") {
    const usedPorts = existingNetworks.filter((network) => network.id !== values.id).flatMap(getNetworkPorts);
    const otherInlineBridges = values.wanPort && existingNetworks.some((network) => network.id !== values.id && network.mode === "bridge" && network.wanPort);
    if (otherInlineBridges) {
      set(errors, "mode", translate("tesseract.appliances.dialog.validation.oneInlineBridge"));
    }
    if (values.wanPort && (usedPorts.includes(values.wanPort) || ((_a = values.lanPorts) == null ? void 0 : _a.includes(values.wanPort)))) {
      set(errors, "wanPort", translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
    }
    (_b = values.lanPorts) == null ? void 0 : _b.some((port) => {
      var _a2;
      if (usedPorts.includes(port) || ((_a2 = values.lanPorts) == null ? void 0 : _a2.includes(values.wanPort))) {
        set(errors, "lanPorts", translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
      }
    });
  }
  if (values.mode !== "bridge" && existingNetworks.find((network, index) => network.mode === "bridge")) {
    const bridgeIndex = existingNetworks.findIndex((network) => network.mode === "bridge");
    if (existingNetworks[bridgeIndex].id !== values.id && existingNetworks[bridgeIndex].wanPort === values.port) {
      errors.port = translate("tesseract.appliances.dialog.validation.duplicateNetwork");
    }
    if (existingNetworks[bridgeIndex].id !== values.id && existingNetworks[bridgeIndex].lanPorts.includes(values.port)) {
      errors.port = translate("tesseract.appliances.dialog.validation.duplicateNetwork");
    }
  }
  if (values.mode !== "bridge" && values.mode !== "wwan" && values.mode !== "expressRoute" && getNetworkId(values) !== getNetworkId(initialValues) && existingNetworks.find(findNetworksMatching(values))) {
    errors.port = translate("tesseract.appliances.dialog.validation.duplicateNetwork");
    errors.virtualLanId = translate("tesseract.appliances.dialog.validation.duplicateNetwork");
  }
  if (values.mode !== initialValues.mode && values.mode === "wwan" && existingNetworks.find((network) => network.mode === values.mode)) {
    errors.mode = translate("tesseract.appliances.dialog.validation.duplicateWwan");
  }
  if (values.mode !== initialValues.mode && values.mode === "expressRoute" && existingNetworks.find((network) => network.mode === values.mode)) {
    errors.mode = translate("tesseract.appliances.dialog.validation.duplicateExpressRoute");
  }
  if (values.mode === "expressRoute" && `${values.asn}` !== `${initialValues.asn}` && !asnData.some((asn) => `${asn}` === `${values.asn}`)) {
    errors.asn = translate("tesseract.appliances.dialog.validation.availableAsn");
  }
  return errors;
};
const useNetworkFormValidation = () => {
  var _a;
  const [wans = [], lans = []] = useWatch({ name: ["wans", "lans"] });
  const existingNetworks = [...wans, ...lans];
  const [translate] = useTranslation();
  const asnData = getArrayDataContent((_a = useCrudProps(apiResources.availableAsns)[0]) == null ? void 0 : _a.data);
  return useDeepCompareMemo(() => networkFormValidation(existingNetworks, asnData, translate), [existingNetworks, asnData]);
};
const routeFormValidation = (existingRoutes, translate) => (values = {}, initialValues = {}) => {
  const errors = {};
  if (values.name !== initialValues.name && existingRoutes.find((route) => route.name === values.name)) {
    errors.name = translate("tesseract.appliances.dialog.validation.duplicateName");
  }
  return errors;
};
const useRouteFormValidation = () => {
  const existingRoutes = useWatch({ name: "routes" }) || [];
  const [translate] = useTranslation();
  return useDeepCompareMemo(() => routeFormValidation(existingRoutes, translate), [existingRoutes]);
};
const reservationFormValidation = (lans, translate) => (values = {}, initialValues = {}) => {
  const errors = {};
  if (values.networkId) {
    const lan = lans.find((lan2) => getNetworkId(lan2) === values.networkId);
    const reservations = get(lan, "dhcp.reservations", []);
    if (values.clientId && values.clientId !== initialValues.clientId && reservations.some((reservation) => reservation.clientId === values.clientId)) {
      set(errors, "clientId", translate("tesseract.appliances.settings.dhcp.reservations.duplicate"));
    }
    if (values.mac && values.mac !== initialValues.mac && reservations.some((reservation) => {
      var _a;
      return reservation.mac === ((_a = values.mac) == null ? void 0 : _a.replace(/-/g, ":"));
    })) {
      set(errors, "mac", translate("tesseract.appliances.settings.dhcp.reservations.duplicate"));
    }
    if (values.ipAddress && values.ipAddress !== initialValues.ipAddress && reservations.some((reservation) => reservation.ipAddress === values.ipAddress)) {
      set(errors, "ipAddress", translate("tesseract.appliances.settings.dhcp.reservations.duplicate"));
    }
    const allReservations = lans.flatMap((lan2) => get(lan2, "dhcp.reservations", []));
    if (values.name && values.name !== initialValues.name && allReservations.some((reservation) => reservation.name === values.name)) {
      set(errors, "name", translate("tesseract.appliances.settings.dhcp.reservations.duplicateName"));
    }
  }
  return errors;
};
const useReservationFormValidation = () => {
  const lans = useWatch({ name: "lans" }) || [];
  const [translate] = useTranslation();
  return useDeepCompareMemo(() => reservationFormValidation(lans, translate), [lans]);
};
const useSiteInputValidations = () => {
  const [translate] = useTranslation();
  return useMemo(() => ({
    validateIp: [validateIpv4],
    validateCidr: [validateCIDR],
    validateCidrArray: [validateArray(validateCIDR)],
    validatePrivateIp: [validatePrivateIpv4],
    validateIpArray: [validateArray(validateIpv4)],
    validateIpv4CidrArray: [validateArray(validateIpv4CIDR)],
    validateStaticAddress: [validateIpv4],
    validateRequiredPrivateIpArray: [validateArray([validateRequired, validatePrivateIpv4], true)],
    validateName: [validateMaxLength(64), validateRegex(/^[a-zA-Z0-9-]+$/, translate("tesseract.validation.alphaNumericDash"))],
    validateSiteName: [
      validateMaxLength(64),
      validateRegex(/^[a-zA-Z0-9-]+$/, translate("tesseract.validation.alphaNumericDash")),
      validateNegativeRegex(/^.*-ha$/i, translate("tesseract.validation.alphaNumericDashNoHaSuffix"))
    ],
    validateNetmask: [validateInt, validateMaxValue(32), validateMinValue(1)],
    validateRequiredNetmaskArray: [validateArray([validateRequired, validateInt, validateMaxValue(32), validateMinValue(1)])],
    validatePin: [validateRegex(/^[0-9]{4}$/, translate("tesseract.appliances.settings.wan.invalidPinFormat"))],
    validateDefaultLeaseTime: [validateInt, validateMinValue(0)],
    validateMaxLeaseTime: [
      validateInt,
      validateMinValue(0),
      (value, allValues) => parseInt(value) < parseInt(get(allValues, "dhcp.defaultLeaseTime")) ? translate("tesseract.appliances.settings.dhcp.networks.validateMaxLeaseTime") : void 0
    ],
    validatePassword: [validateMaxLength(255)],
    validateMac: [validateRegex(/^((([0-9A-Fa-f]{2}:){5})|(([0-9A-Fa-f]{2}-){5}))[0-9A-Fa-f]{2}$/, translate("tesseract.appliances.settings.dhcp.reservations.validateMac"))],
    validateHostname: [validateRegex(/^[a-zA-Z0-9-_]*$/, translate("tesseract.appliances.settings.dhcp.reservations.validateHostname"))],
    validateVlanId: [validateInt, validateMinValue(1), validateMaxValue(4094)],
    validateVlanIdArray: [validateArray([validateInt, validateMinValue(1), validateMaxValue(4094)])],
    validateWansLength: [validateInt, validateMinValue(1), validateMaxValue(16)],
    validateLansLength: [validateInt, validateMinValue(0), validateMaxValue(16)],
    validateSCWansLength: [validateInt, validateMinValue(1), validateMaxValue(2)],
    validateSCLansLength: (maxValue) => [validateInt, validateMinValue(1), validateMaxValue(maxValue)],
    validateAsn: [validateInt, validateMinValue(64512), validateMaxValue(65514)],
    validateAsnIpsec: [validateInt, validateMinValue(64512), validateMaxValue(65534)],
    validateAsnAny: [validateInt, validateMinValue(0)],
    validateSerials: [validateArray([validateRequired], true)],
    validateWifiPassphrase: [validateMinLength(8), validateMaxLength(63)],
    validateWifiSsid: [validateMaxLength(32), validateRegex(/^[a-zA-Z0-9-]+$/, translate("tesseract.appliances.settings.lan.wifi.validateSsid"))],
    validateAlphaNumericDotsColonsDash: (maxLenght) => [validateMaxLength(maxLenght), validateRegex(/^[a-zA-Z0-9.:-]+$/, translate("tesseract.validation.alphaNumericDotsColonsDash"))],
    validateTunnelLifetime: [validateInt, validateMinValue(0), validateMaxValue(2147483647)]
    //  0 < z < 2B
  }), []);
};
const getNetworkNameFromPorts = (portsData) => {
  const generateNetworkName = (port, virtualLanId) => ((portsData.find((portData) => portData.port === port) || {}).name || "") + (virtualLanId ? "." + virtualLanId : "");
  const getPortName = (portId) => (portsData.find((portData) => portData.port === portId) || {}).name || "";
  return (network) => {
    var _a;
    if (network && network.mode === "expressRoute") {
      return generateNetworkName(get(network, "doublePort[0]"), get(network, "doubleVirtualLanId[0]")) + ", " + generateNetworkName(get(network, "doublePort[1]"), get(network, "doubleVirtualLanId[1]"));
    } else if (network && network.mode === "bridge") {
      const lanPorts = ((_a = network.lanPorts) == null ? void 0 : _a.map(getPortName).join(", ")) || "";
      return network.wanPort ? `${network.wanPort && getPortName(network.wanPort)}, ${lanPorts}` : lanPorts;
    }
    return generateNetworkName(network.port, network.virtualLanId);
  };
};
const formatSCErrors = (errors, originalValues, formattedValues) => {
  const wifiValue = get(formattedValues, "wifi");
  const lanErrors = get(errors, "lan");
  const wifiErrors = get(errors, "wifi");
  if (wifiErrors || lanErrors && wifiValue) {
    const wifiIndex = get(originalValues, "lans", []).findIndex((lan) => lan.port === "wifi");
    const lans = get(errors, "lans", Array(get(formattedValues, "lans", []).length));
    isArray(lans) && lans.splice(wifiIndex, 0, wifiErrors);
    return __spreadProps(__spreadValues({}, errors), { lans });
  }
  return errors;
};
export {
  findNetworksMatching,
  formatSCErrors,
  getExpressRouteNetworkId,
  getNetworkId,
  getNetworkNameFromPorts,
  getNetworkPorts,
  useNetworkFormValidation,
  useReservationFormValidation,
  useRouteFormValidation,
  useSiteInputValidations,
  validateNames,
  validateNetworks
};
