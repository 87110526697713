var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import {
  ActionButtonsField,
  ChipArrayField,
  ConnectedTable,
  CrudTypes,
  CustomField,
  DeleteDialog,
  getArrayDataContent,
  getDataContent,
  InputLabel,
  Select,
  StatusIconField,
  TextField,
  useCrudFetch,
  useCrudProps,
  useCrudSubscription,
  useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { makeStyles } from "@mui/styles";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import CreateEditUrlFiltering from "./createEdit/CreateEditUrlFiltering";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import { settingsIcons } from "../../../../../components/iconMapping";
import apiResources from "../../../../../apiResources";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";
const styles = (theme) => ({
  cardActions: {
    paddingRight: theme.spacing(2),
    width: "calc(100% - " + theme.spacing(2) + ")"
  },
  orderCell: {
    minWidth: 70
  },
  actionCell: {
    minWidth: 70,
    paddingLeft: 0
  },
  actionsCell: {
    paddingRight: "0 !important"
  }
});
const useStyles = makeStyles(styles);
const SettingsTable = ({ actionChoices, categoryChoices }) => {
  var _a, _b, _c;
  const registerAction = useMixpanel("Web Filter", false);
  const settingsTableRefreshRef = useRef(null);
  const groupChoices = sortBy(getArrayDataContent((_a = useCrudProps(apiResources.urlFilterGroups)[0]) == null ? void 0 : _a.data), "key");
  const [rulesetsData, , refreshRulesets] = useCrudSubscription(CrudTypes.GET, apiResources.urlFilterRulesets);
  const rulesets = getArrayDataContent(rulesetsData);
  const defaultActionChoices = getArrayDataContent((_b = useCrudProps(apiResources.urlFilterDefaultActions, {}, {}, true)[0]) == null ? void 0 : _b.data);
  const [defaultActionData, defaultActionLoading, refreshDefaultAction] = useCrudSubscription(CrudTypes.GET, apiResources.urlFilterDefault);
  const [, defaultActionUpdating, updateDefaultAction] = useCrudFetch(CrudTypes.UPDATE, apiResources.urlFilterDefault);
  const defaultAction = getDataContent(defaultActionData);
  const [translate] = useTranslation();
  const classes = useStyles();
  const defaultLoading = typeof defaultAction !== "string" || defaultActionLoading || defaultActionUpdating;
  const customCategories = getArrayDataContent((_c = useCrudProps(apiResources.customCategories)[0]) == null ? void 0 : _c.data);
  const handleUpdateDefaultAction = (action) => {
    if (action && !defaultLoading && action !== defaultAction) {
      registerAction("Default Update", { action });
      return updateDefaultAction({ action }).then(refreshDefaultAction);
    }
  };
  const [, , callUrlFilters] = useCrudFetch(CrudTypes.CREATE, apiResources.urlFilterRules, {});
  const handleFiltersOrderClick = (id, direction, data) => callUrlFilters({
    id,
    changeOrder: direction,
    data
  }).then(settingsTableRefreshRef.current);
  const onRuleChange = () => {
    settingsTableRefreshRef.current && settingsTableRefreshRef.current();
    refreshRulesets();
  };
  return /* @__PURE__ */ jsxs(
    ConnectedTable,
    {
      resource: rulesets.length > 0 ? apiResources.urlFilterRules : void 0,
      exportResource: { resource: apiResources.urlFiltersExport, filename: "urlFilters" },
      actions: [
        /* @__PURE__ */ jsx(
          InputLabel,
          {
            label: "tesseract.security.urlFiltering.settings.defaultAction",
            minimised: true,
            left: true,
            children: /* @__PURE__ */ jsx(
              Select,
              {
                id: "defaultAction",
                value: !defaultLoading ? defaultAction : "",
                onChange: (eventOrValue) => {
                  var _a2;
                  return handleUpdateDefaultAction((_a2 = eventOrValue == null ? void 0 : eventOrValue.target) == null ? void 0 : _a2.value);
                },
                disabled: defaultLoading,
                choices: defaultActionChoices
              }
            )
          },
          "defaultAction"
        ),
        /* @__PURE__ */ jsx(
          CreateEditButtonDialog,
          {
            create: true,
            component: CreateEditUrlFiltering,
            onSuccess: onRuleChange
          },
          "create"
        )
      ],
      params: (params) => __spreadProps(__spreadValues({}, params), {
        filter: __spreadProps(__spreadValues({}, params.filter || {}), {
          type: params.filter && params.filter.rules && params.filter.rules.type || [],
          rulesetId: rulesets.map((ruleset) => ruleset.id),
          rules: void 0,
          rule: [...params.filter && params.filter.rules && params.filter.rules.category || [], params.filter && params.filter.rules && params.filter.rules.domain]
        })
      }),
      classes: { cardActions: classes.cardActions },
      minCellWidth: 128,
      refreshRef: settingsTableRefreshRef,
      expandableFields: [
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "domains",
            label: "tesseract.security.urlFiltering.settings.domains",
            skipMissing: true
          },
          "domains"
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            arraySource: "categories",
            source: "active",
            sourceValue: "name",
            skipMissing: true
          },
          "categories"
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "customCategories",
            sourceValue: "name",
            label: "tesseract.security.urlFiltering.settings.customCategories",
            skipMissing: true
          },
          "customCategories"
        )
      ],
      rowId: "id",
      formatData: (data) => getArrayDataContent(data).map((rowData) => {
        const activeCategories = rowData.type === "category" ? categoryChoices.filter((category) => rowData.rules.includes(category.key)) : rowData.type === "customCategory" ? customCategories.filter((category) => rowData.rules.includes(category.id.toString())) : [];
        return __spreadProps(__spreadValues({}, rowData), {
          activeCategories,
          categories: groupChoices.map((group) => __spreadProps(__spreadValues({}, group), {
            active: activeCategories.filter((category) => category.group === group.key)
          })).filter((group) => group.active.length > 0),
          domains: rowData.type === "domain" ? rowData.rules.sort() : [],
          customCategories: customCategories.map((cat) => __spreadProps(__spreadValues({}, cat), {
            category: activeCategories.filter((category) => category.id === cat.id)
          })).filter((group) => group.category.length > 0)
        });
      }),
      flat: true,
      children: [
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            left: true,
            label: "tesseract.security.urlFiltering.settings.order",
            cellClassName: classes.orderCell,
            width: 96,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(
                TextField,
                {
                  source: "order"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowUpwardIcon,
                {
                  onClick: (event, data) => handleFiltersOrderClick(data.id, "increase", data),
                  disabled: (data) => data.index <= 0,
                  id: "cuda-icon-up"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowDownwardIcon,
                {
                  onClick: (event, data) => handleFiltersOrderClick(data.id, "decrease", data),
                  disabled: (data) => data.index >= data.total - 1,
                  id: "cuda-icon-down"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.security.urlFiltering.settings.name",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.security.urlFiltering.settings.description",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "action",
            text: (action) => (actionChoices.find((item) => item.key === action) || {}).name,
            label: "tesseract.security.urlFiltering.settings.action",
            iconMap: settingsIcons,
            cellClassName: classes.actionCell,
            filter: "select",
            filterProps: {
              choices: actionChoices
            },
            width: 140
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "rules",
            label: "tesseract.security.urlFiltering.settings.rule",
            render: (rules, data) => translate(
              `tesseract.security.urlFiltering.settings.destination${data.type === "category" ? "Categories" : data.type === "customCategory" ? "CustomCategories" : "Domains"}`,
              __spreadProps(__spreadValues({}, data), { context: rules.length, additional: rules.length - 1 })
            ),
            filter: "custom",
            filterProps: {
              component: DestinationFilter,
              filterSources: ["domain", "category", "customCategory"],
              table: "WebFilter",
              categoryChoices,
              customCategories
            }
          }
        ),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            width: 96,
            source: "id",
            cellClassName: classes.actionsCell,
            children: [
              /* @__PURE__ */ jsx(CreateEditButtonDialog, { component: CreateEditUrlFiltering, onSuccess: onRuleChange }),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource: apiResources.urlFilterRules,
                  title: "tesseract.security.urlFiltering.settings.delete.title",
                  message: "tesseract.security.urlFiltering.settings.delete.body",
                  onSuccess: () => {
                    registerAction("Rule Delete");
                    onRuleChange();
                  }
                }
              )
            ]
          }
        )
      ]
    }
  );
};
var SettingsTable_default = SettingsTable;
export {
  SettingsTable,
  SettingsTable_default as default,
  styles
};
