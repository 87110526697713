import { jsx, jsxs } from "react/jsx-runtime";
import {
  BooleanInput,
  ConnectedForm,
  CrudTypes,
  getDataContent,
  SelectInput,
  useCrudSubscription,
  useMixpanel,
  usePreview
} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import { Typography } from "@barracuda-internal/bds-core";
import { Trans } from "react-i18next";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  introduction: {
    padding: theme.spacing(2, 2, 2, 4)
  },
  deviceLimitSelect: {
    width: 96
  },
  deviceLimitInfo: {
    marginLeft: 344,
    top: -30,
    position: "relative"
  }
}));
const UserSettings = ({ details }) => {
  const classes = useStyles();
  const id = details == null ? void 0 : details.id;
  const userDetails = getDataContent(useCrudSubscription(CrudTypes.GET, apiResources.zeroTrustUsers, { id })[0], {});
  const registerAction = useMixpanel("User Settings", void 0, userDetails == null ? void 0 : userDetails.fullName);
  const tpmEnforcement = usePreview("tpm");
  return /* @__PURE__ */ jsxs(
    ConnectedForm,
    {
      resource: apiResources.zeroTrustUsers,
      topToolbar: true,
      params: { id, filter: { type: "virtualWan" } },
      onSubmitSuccess: () => {
        registerAction("Update");
      },
      children: [
        /* @__PURE__ */ jsx(Typography, { className: classes.introduction, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.users.settings.introduction" }) }),
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "optionsOverride",
            label: "tesseract.users.settings.global",
            additionalInfoLabel: "tesseract.users.settings.descriptions.global",
            toggleEnabledText: "tesseract.users.settings.overrideEnabled",
            toggleDisabledText: "tesseract.users.settings.overrideDisabled",
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "tamperProof",
            label: "tesseract.users.settings.tamperProof",
            additionalInfoLabel: "tesseract.users.settings.descriptions.tamperProof",
            disable: (value, data) => !(data == null ? void 0 : data.optionsOverride),
            toggleEnabledText: "tesseract.users.settings.enabled",
            toggleDisabledText: "tesseract.users.settings.disabled",
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "devicePreConnect",
            label: "tesseract.users.settings.devicePreConnect",
            additionalInfoLabel: "tesseract.users.settings.descriptions.devicePreConnect",
            disable: (value, data) => !(data == null ? void 0 : data.optionsOverride),
            toggleEnabledText: "tesseract.users.settings.enabled",
            toggleDisabledText: "tesseract.users.settings.disabled",
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          SelectInput,
          {
            label: "tesseract.users.settings.deviceLimit",
            source: "deviceLimit",
            choices: new Array(10).fill(null).map((item, index) => ({
              key: index + 1,
              name: `${index + 1}`
            })),
            additionalInfoLabel: "tesseract.users.settings.descriptions.deviceLimit",
            inputLabelProps: {
              classes: {
                inputAdditionalInfo: classes.deviceLimitInfo
              }
            },
            inputProps: {
              classes: {
                select: classes.deviceLimitSelect
              }
            },
            disable: (value, data) => !(data == null ? void 0 : data.optionsOverride),
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "webFilter",
            label: "tesseract.users.settings.webFiltering",
            additionalInfoLabel: "tesseract.users.settings.descriptions.webFiltering",
            toggleEnabledText: "tesseract.users.settings.enabled",
            toggleDisabledText: "tesseract.users.settings.disabled",
            disable: (value, data) => !(data == null ? void 0 : data.optionsOverride),
            newStyle: true
          }
        ),
        tpmEnforcement ? /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "tpmEnforcement",
            label: "tesseract.users.settings.tpmEnforcement",
            additionalInfoLabel: "tesseract.users.settings.descriptions.tpmEnforcement",
            toggleEnabledText: "tesseract.users.settings.enabled",
            toggleDisabledText: "tesseract.users.settings.disabled",
            disable: (value, data) => !(data == null ? void 0 : data.optionsOverride),
            newStyle: true
          }
        ) : null
      ]
    }
  );
};
var UserSettings_default = UserSettings;
export {
  UserSettings_default as default
};
