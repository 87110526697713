var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { CardHeader, Divider } from "@barracuda-internal/bds-core";
import { createStyles, makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { TableCardContent } from "@cuda-react/core";
import { CardSizeOptions } from "../../../typesAndConstants";
const styles = (theme) => createStyles({
  dividerCards: {
    margin: theme.spacing(0.5, 5, 0),
    width: `calc(100% -${theme.spacing(10)})`
  },
  titleTypography: {
    fontSize: 16,
    fontWeight: "bold",
    overflowY: "visible",
    paddingLeft: theme.spacing(1),
    color: theme.palette.reporting.fontColor
  },
  table: {
    maxHeight: `calc(${CardSizeOptions.small.height}px - ${theme.spacing(6.5)}) !important`,
    minHeight: `calc(${CardSizeOptions.small.height}px - ${theme.spacing(6.5)}) !important`,
    margin: theme.spacing(0, 2.5),
    border: "none",
    "& div.k-grid-content": {
      overflowY: "auto !important"
    }
  }
});
const useStyles = makeStyles(styles);
const StatisticsTable = (props) => {
  const _a = props, { tableTitle, children } = _a, tableCardContentProps = __objRest(_a, ["tableTitle", "children"]);
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Divider, { className: classes.dividerCards }),
    /* @__PURE__ */ jsx(CardHeader, { classes: { title: classes.titleTypography }, title: translate(tableTitle) }),
    /* @__PURE__ */ jsx(TableCardContent, __spreadProps(__spreadValues({}, tableCardContentProps), { classes: { dataTable: classes.table }, children }))
  ] });
};
export {
  StatisticsTable
};
