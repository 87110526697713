import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { createStyles, makeStyles } from "@mui/styles";
import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
const styles = (theme) => createStyles({
  footerTextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 5,
    paddingTop: 10,
    color: theme.palette.reporting.fontColor
  },
  footerTextBold: {
    fontWeight: "bold",
    color: theme.palette.reporting.fontColor
  }
});
const useStyles = makeStyles(styles);
const CardFooter = (props) => {
  const { footerKey, length, total } = props;
  const [translate] = useTranslation();
  const classes = useStyles(props);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Divider, {}),
    /* @__PURE__ */ jsxs("div", { className: classes.footerTextContainer, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "caption", className: classes.footerTextBold, children: translate(footerKey, { length }) }),
      /* @__PURE__ */ jsx(Typography, { variant: "caption", children: translate("stratosReporting.baseBarCard.total", { total }) })
    ] })
  ] });
};
export {
  CardFooter
};
