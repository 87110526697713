import { jsx, jsxs } from "react/jsx-runtime";
import SdWanPolicies from "./tabs/SdWanPolicies";
import ExplicitPolicies from "./tabs/ExplicitPolicies";
import { Route, Switch } from "react-router";
const Sdwan = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(Route, { path: "/policies/sdwan/categories", children: /* @__PURE__ */ jsx(SdWanPolicies, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/sdwan/explicit", children: /* @__PURE__ */ jsx(ExplicitPolicies, {}) })
] });
var Sdwan_default = Sdwan;
export {
  Sdwan_default as default
};
