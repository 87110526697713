var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import {
  DialogBody,
  Form,
  TextInput,
  validateMaxLengthMemo,
  validateRegexMemo
} from "@cuda-react/core";
const RenameDashboardDialog = ({
  dashboard,
  addUpdateDashboard,
  onClose
}) => /* @__PURE__ */ jsx(
  DialogBody,
  {
    title: "stratosReporting.dashboardPage.rename",
    onClose,
    form: true,
    children: /* @__PURE__ */ jsx(
      Form,
      {
        initialValues: dashboard,
        onCancel: onClose,
        save: (_a) => {
          var newDashboard = __objRest(_a, []);
          return addUpdateDashboard(newDashboard, dashboard == null ? void 0 : dashboard.name).then((result) => {
            onClose == null ? void 0 : onClose();
            return result;
          });
        },
        children: /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "name",
            label: "stratosReporting.dashboardPage.name",
            isRequired: true,
            validate: [validateMaxLengthMemo(32), validateRegexMemo(/^[a-zA-Z0-9 ]+$/, "stratosReporting.dashboardPage.validateName")]
          }
        )
      }
    )
  }
);
export {
  RenameDashboardDialog
};
