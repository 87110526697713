import { useCrudFetch, useCrudSubscription } from "./CrudHooks";
import { CrudTypes } from "../clients";
import { getDataContent } from "../utils";
;
const useSupportAccess = (supportAccessResource) => {
  const [enabledData, enabledLoading, refreshEnabledData] = useCrudSubscription(CrudTypes.GET, supportAccessResource);
  const [, enabledUpdating, updateEnabled] = useCrudFetch(CrudTypes.CREATE, supportAccessResource);
  const supportAccessEnabled = getDataContent(enabledData.data, false);
  const loadingEnabled = typeof supportAccessEnabled !== "boolean" || enabledLoading || enabledUpdating;
  const onSupportAccessAccept = (value) => !loadingEnabled && updateEnabled({ enabled: value ? "true" : "false" }).then(refreshEnabledData);
  return { supportAccessEnabled, onSupportAccessAccept };
};
export {
  useSupportAccess
};
