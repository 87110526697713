import { jsx } from "react/jsx-runtime";
import { ButtonDialog } from "@cuda-react/core";
import ExplicitPolicyContent from "./ExplicitPolicyContent";
const CreateExplicitPolicyDialog = ({ label, onSuccess }) => /* @__PURE__ */ jsx(ButtonDialog, { buttonText: label || "tesseract.policies.dialogs.explicit.create.buttonText", children: /* @__PURE__ */ jsx(
  ExplicitPolicyContent,
  {
    create: true,
    title: "tesseract.policies.dialogs.explicit.create.dialogTitle",
    onSuccess
  }
) });
var CreateExplicitPolicyDialog_default = CreateExplicitPolicyDialog;
export {
  CreateExplicitPolicyDialog,
  CreateExplicitPolicyDialog_default as default
};
