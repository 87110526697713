import { jsx, jsxs } from "react/jsx-runtime";
import { StatusIcon } from "@cuda-react/icons";
import { Typography } from "@barracuda-internal/bds-core";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  successIcon: {
    margin: theme.spacing(2, "auto"),
    width: 64,
    height: 64,
    display: "inherit"
  },
  successHeader: {
    margin: theme.spacing(1, 2),
    textAlign: "center",
    width: `calc(100% - ${theme.spacing(4)})`
  },
  successMessage: {
    textAlign: "center",
    width: `calc(100% - ${theme.spacing(4)})`,
    margin: theme.spacing(1, 2, 2)
  }
}));
const EnrollSuccessStep = ({ data, result, create }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s;
  const classes = useStyles();
  const [translate] = useTranslation();
  return create ? /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(StatusIcon, { status: "ok", className: classes.successIcon }),
    /* @__PURE__ */ jsx(Typography, { variant: "h6", className: classes.successHeader, children: translate(
      ((_a = result == null ? void 0 : result.data) == null ? void 0 : _a.newlyEnrolled) === 0 ? "tesseract.endpoint.enrollEmailDialog.unSuccessHeaderAllUsersAlreadyEnrolled" : `tesseract.endpoint.enrollEmailDialog.${(data == null ? void 0 : data.enrollmentEmailEnabled) ? "successHeader" : "noEmailSuccessHeader"}`,
      {
        count: (_b = result == null ? void 0 : result.data) == null ? void 0 : _b.newlyEnrolled,
        context: ((_c = result == null ? void 0 : result.data) == null ? void 0 : _c.newlyEnrolled) === 0 && ((_d = result == null ? void 0 : result.data) == null ? void 0 : _d.alreadyEnrolled) + ((_e = result == null ? void 0 : result.data) == null ? void 0 : _e.unenrollable) > 1 ? "plural" : ((_f = result == null ? void 0 : result.data) == null ? void 0 : _f.newlyEnrolled) > 1 ? "plural" : void 0
      }
    ) }),
    ((_g = result == null ? void 0 : result.data) == null ? void 0 : _g.alreadyEnrolled) + ((_h = result == null ? void 0 : result.data) == null ? void 0 : _h.unenrollable) > 0 && /* @__PURE__ */ jsx(Typography, { className: classes.successMessage, children: translate(
      "tesseract.endpoint.enrollEmailDialog.alreadyEnrolledUsers",
      {
        context: `${(data == null ? void 0 : data.enrollmentEmailEnabled) ? "email" : ""}${((_i = result == null ? void 0 : result.data) == null ? void 0 : _i.unenrollable) > 0 ? "workspace" : ""}${((_j = result == null ? void 0 : result.data) == null ? void 0 : _j.alreadyEnrolled) + ((_k = result == null ? void 0 : result.data) == null ? void 0 : _k.unenrollable) > 1 ? "plural" : ""}`,
        totalCount: ((_l = result == null ? void 0 : result.data) == null ? void 0 : _l.alreadyEnrolled) + ((_m = result == null ? void 0 : result.data) == null ? void 0 : _m.unenrollable),
        unenrollableCount: (_n = result == null ? void 0 : result.data) == null ? void 0 : _n.unenrollable
      }
    ) }),
    /* @__PURE__ */ jsx(Typography, { className: classes.successMessage, children: ((_o = result == null ? void 0 : result.data) == null ? void 0 : _o.newlyEnrolled) > 0 ? translate("tesseract.endpoint.enrollEmailDialog.successMessage", { context: ((_p = result == null ? void 0 : result.data) == null ? void 0 : _p.newlyEnrolled) > 1 ? "plural" : void 0 }) : null })
  ] }) : /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(StatusIcon, { status: "ok", className: classes.successIcon }),
    /* @__PURE__ */ jsx(Typography, { variant: "h6", className: classes.successHeader, children: translate(
      "tesseract.endpoint.enrollEmailDialog.successHeaderOld",
      {
        count: (_q = data == null ? void 0 : data.users) == null ? void 0 : _q.length,
        context: ((_r = data == null ? void 0 : data.users) == null ? void 0 : _r.length) > 1 ? "plural" : void 0,
        users: data == null ? void 0 : data.users
      }
    ) }),
    /* @__PURE__ */ jsx(Typography, { className: classes.successMessage, children: translate("tesseract.endpoint.enrollEmailDialog.successMessage", { context: ((_s = data == null ? void 0 : data.users) == null ? void 0 : _s.length) > 1 ? "plural" : void 0 }) })
  ] });
};
var EnrollSuccessStep_default = EnrollSuccessStep;
export {
  EnrollSuccessStep,
  EnrollSuccessStep_default as default,
  useStyles
};
