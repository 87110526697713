var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnField } from "../../components/fields";
import classNames from "classnames";
import { useDeepCompareMemo } from "../UtilHooks";
import { Dialog } from "@barracuda-internal/bds-core";
import { DialogBody } from "../../components/dialog";
import { makeOverrideableStyles } from "@cuda-react/theme";
const rowDetailsStyles = {
  clickableRow: {
    cursor: "pointer"
  }
};
const useRowDetailsStyles = makeOverrideableStyles("DataTableRowDetails", rowDetailsStyles);
const useDataTableRowDetails = (props) => {
  const { rowDetails } = props;
  const classes = useRowDetailsStyles(props);
  const [clickedData, setClickedData] = useState();
  const [translate] = useTranslation();
  const rowRender = useCallback((row, rowProps) => rowDetails ? React.cloneElement(row, __spreadProps(__spreadValues({}, row.props), {
    className: classNames(
      row.props.className,
      classes.clickableRow
    ),
    onClick: () => {
      setClickedData(rowProps.dataItem);
    },
    title: translate("cuda.table.rowDetails")
  })) : row, [rowDetails]);
  const detailsDialog = useDeepCompareMemo(() => rowDetails ? /* @__PURE__ */ jsx(
    Dialog,
    {
      disableEnforceFocus: true,
      open: !!clickedData,
      maxWidth: "md",
      children: /* @__PURE__ */ jsx(
        DialogBody,
        {
          title: translate(rowDetails.title, clickedData),
          onClose: () => {
            setClickedData(null);
          },
          children: /* @__PURE__ */ jsx(ColumnField, { autoWidth: true, data: clickedData, children: rowDetails.fields })
        }
      )
    }
  ) : null, [clickedData, rowDetails]);
  return [
    { rowRender },
    detailsDialog
  ];
};
export {
  useDataTableRowDetails
};
