import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const PriorityMediumIcon = ({ className }) => {
  var _a, _b;
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-priority-medium",
      viewBox: "0 0 60 60",
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
            d: "M30,55c-13.8,0-25-11.2-25-25S16.2,5,30,5s25,11.2,25,25-11.2,25-25,25ZM30,9.9c-11.1,0-20.1,9-20.1,20.1s9,20.1,20.1,20.1,20.1-9,20.1-20.1-9-20.1-20.1-20.1Z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_b = theme.palette.warning) == null ? void 0 : _b.main, strokeWidth: 0 },
            d: "M34.3,32.2c.7-1.2.8-2.5.4-3.9s-1.2-2.4-2.4-3c-.5-.3-1-.4-1.5-.5-.5-.1-1.1-.1-1.6,0l-6-7.1c-.5-.6-1-.9-1.7-1s-1.4.1-1.9.6c-.6.4-.9,1-.9,1.7s.1,1.3.6,1.9l6,7.1c-.4,1.2-.4,2.4,0,3.6s1.2,2.1,2.3,2.7,2.5.8,3.9.4,2.4-1.2,3-2.4Z"
          }
        )
      ]
    }
  );
};
var PriorityMediumIcon_default = PriorityMediumIcon;
export {
  PriorityMediumIcon,
  PriorityMediumIcon_default as default
};
