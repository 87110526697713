var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import {
  ConnectedForm,
  ConnectedWizard,
  DialogBody,
  getArrayDataContent,
  InputStep,
  ResultStep,
  SubmitStep,
  useCrudProps,
  useGlobalParam,
  useMixpanel
} from "@cuda-react/core";
import React, { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import apiResources from "../../../../apiResources";
import SdwanConnectorGeneralStep from "../../../../components/appliances/create/steps/sdwanConnector/SdwanConnectorGeneralStep";
import SdwanConnectorServicesStep from "../../../../components/appliances/create/steps/sdwanConnector/SdwanConnectorServicesStep";
import SdwanConnectorSubmitStep from "../../../../components/appliances/create/steps/sdwanConnector/./SdwanConnectorSubmitStep";
import SdwanConnectorPoolNetworkStep from "../../../../components/appliances/create/steps/sdwanConnector/SdwanConnectorPoolNetworkStep";
import SdwanConnectorResultStep from "../../../../components/appliances/create/steps/sdwanConnector/SdwanConnectorResultStep";
import { Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: 850
  },
  completeMessage: {
    margin: theme.spacing(3)
  }
}));
const CreateSdwanConnector = ({ onClose, onSuccess }) => {
  var _a, _b;
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const registerAction = useMixpanel("SD-WAN Connector Create SD-WAN Connector", "Start", {});
  const classes = useStyles();
  const [{ data: connectedDataNetwork }, loadingNetwork, fetchDataNetwork] = getArrayDataContent(useCrudProps(apiResources.sdwanConnectorNetwork));
  const emptyPool = !((_b = (_a = connectedDataNetwork == null ? void 0 : connectedDataNetwork.content) == null ? void 0 : _a.clientNetworkConfiguration) == null ? void 0 : _b.range);
  const [translate] = useTranslation();
  const onSuccessHandler = useCallback((response) => {
    registerAction("Success");
    onSuccess == null ? void 0 : onSuccess(response);
  }, []);
  return /* @__PURE__ */ jsx(React.Fragment, { children: emptyPool && !loadingNetwork ? /* @__PURE__ */ jsxs(
    DialogBody,
    {
      onClose,
      form: true,
      title: "tesseract.network.sdwanConnector.dialog.poolNetwork.sectionTitle",
      classes: { dialogContent: classes.dialogContent },
      children: [
        /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.completeMessage, children: translate("tesseract.network.sdwanConnector.dialog.poolNetwork.descriptionMessage") }),
        /* @__PURE__ */ jsx(
          ConnectedForm,
          {
            resource: apiResources.sdwanConnectorNetwork,
            initialValues: { virtualWanId },
            onSubmitSuccess: fetchDataNetwork,
            children: /* @__PURE__ */ jsx(SdwanConnectorPoolNetworkStep, {})
          }
        )
      ]
    }
  ) : /* @__PURE__ */ jsxs(
    ConnectedWizard,
    {
      title: `tesseract.network.sdwanConnector.dialog.create.dialogTitle`,
      dialogBodyProps: { classes: { dialogContent: classes.dialogContent } },
      resource: apiResources.sdwanConnector,
      formatRequestData: (data) => __spreadProps(__spreadValues({}, data), {
        virtualWanId,
        configurationType: "gateway"
      }),
      onClose,
      onSubmitSuccess: onSuccessHandler,
      children: [
        /* @__PURE__ */ jsx(InputStep, { label: "tesseract.network.sdwanConnector.dialog.general.sectionTitle", children: /* @__PURE__ */ jsx(SdwanConnectorGeneralStep, {}) }),
        /* @__PURE__ */ jsx(InputStep, { label: "tesseract.network.sdwanConnector.dialog.servers.sectionTitle", children: /* @__PURE__ */ jsx(SdwanConnectorServicesStep, { create: true }) }),
        /* @__PURE__ */ jsx(SubmitStep, { label: "tesseract.network.sdwanConnector.dialog.summary.sectionTitle", children: /* @__PURE__ */ jsx(SdwanConnectorSubmitStep, {}) }, "submit"),
        /* @__PURE__ */ jsx(ResultStep, { label: "tesseract.appliances.dialog.steps.result", onClose, children: /* @__PURE__ */ jsx(SdwanConnectorResultStep, {}) })
      ]
    }
  ) });
};
var CreateSdwanConnector_default = CreateSdwanConnector;
export {
  CreateSdwanConnector,
  CreateSdwanConnector_default as default
};
