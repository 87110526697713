var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import BaseCard from "../BaseCard";
import StatisticsOverview from "../../statistics/StatisticsOverview";
import StatisticsDetail from "../../statistics/StatisticsDetail";
import { CardSizes } from "../../../typesAndConstants";
import { StatisticsTable } from "../../statistics";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  cardContent: {
    height: "calc(100% - 50px)",
    overflowY: "hidden"
  }
});
const ResourceStatisticsCard = (props) => {
  const _a = props, {
    overViewData,
    overViewValue,
    overviewTitle,
    customOverviewTitle,
    size,
    preview
  } = _a, tableProps = __objRest(_a, [
    "overViewData",
    "overViewValue",
    "overviewTitle",
    "customOverviewTitle",
    "size",
    "preview"
  ]);
  const showTable = (tableProps == null ? void 0 : tableProps.tableTitle) && size === CardSizes.large;
  const classes = useStyles(props);
  return /* @__PURE__ */ jsxs(BaseCard, { title: overviewTitle, size, preview, classes: { cardContent: classes.cardContent }, children: [
    /* @__PURE__ */ jsx(
      StatisticsOverview,
      {
        overviewTitle: customOverviewTitle ? customOverviewTitle : "stratosReporting.resourceStatisticsCard.overview",
        overviewValue: overViewValue,
        children: Array.isArray(overViewData) && overViewData.map((data) => /* @__PURE__ */ jsx(
          StatisticsDetail,
          {
            title: data.title,
            value: data == null ? void 0 : data.value,
            color: data == null ? void 0 : data.color,
            icon: data == null ? void 0 : data.icon,
            link: data == null ? void 0 : data.link
          },
          data.title
        ))
      }
    ),
    showTable && /* @__PURE__ */ jsx(StatisticsTable, __spreadProps(__spreadValues({}, tableProps), { children: tableProps.children }))
  ] });
};
ResourceStatisticsCard.defaultProps = {
  overviewText: "stratosReporting.resourceStatisticsCard.overview",
  overviewValue: "total"
};
export {
  ResourceStatisticsCard,
  useStyles
};
