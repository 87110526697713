import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Grid, Skeleton, Typography } from "@barracuda-internal/bds-core";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useTranslation } from "react-i18next";
import { CardSizes } from "../../../typesAndConstants";
import { createStyles, makeStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    width: "100%",
    margin: theme.spacing(0.25, 0)
  },
  expandColumn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 28
  },
  titleColumn: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    "& > svg": {
      marginRight: theme.spacing(1)
    },
    "& > p": {
      maxWidth: "calc(100% - 24)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    width: ({ size }) => size === CardSizes.small ? 142 : 192,
    height: 30,
    color: theme.palette.reporting.fontColor
  },
  contentColumn: {
    width: "100%",
    maxWidth: ({ size }) => `calc(100% - ${size === CardSizes.small ? 174 : 224}px)`
  },
  expandIcon: {
    transform: "rotate(90deg)",
    transition: "transform 250ms"
  },
  expanded: {
    transform: "rotate(180deg)"
  },
  skeleton: {
    width: `calc(100% - ${theme.spacing(2)})`,
    height: 30,
    margin: theme.spacing(0, 1)
  },
  text: {
    fontSize: 14,
    color: theme.palette.reporting.fontColor
  }
});
const useStyles = makeStyles(styles);
const OverviewCardRow = (props) => {
  const { children, icon, loading, title, expanded, onExpand, noContainer, noContentContainer, onClick } = props;
  const [translate] = useTranslation();
  const classes = useStyles(props);
  const content = /* @__PURE__ */ jsxs(Fragment, { children: [
    onExpand && !loading && /* @__PURE__ */ jsx(Grid, { item: true, className: classes.expandColumn, children: /* @__PURE__ */ jsx(
      ExpandLessIcon,
      {
        className: `${classes.expandIcon} ${expanded ? classes.expanded : ""}`,
        onClick: onExpand
      }
    ) }),
    /* @__PURE__ */ jsx(Grid, { item: true, className: classes.titleColumn, children: loading ? /* @__PURE__ */ jsx(Skeleton, { className: classes.skeleton }) : /* @__PURE__ */ jsxs(Fragment, { children: [
      icon,
      /* @__PURE__ */ jsx(
        Typography,
        {
          variant: "body2",
          children: translate(title)
        }
      )
    ] }) }),
    noContentContainer && !loading ? children : /* @__PURE__ */ jsx(Grid, { item: true, className: classes.contentColumn, children: loading ? /* @__PURE__ */ jsx(Skeleton, { className: classes.skeleton }) : children })
  ] });
  return noContainer ? content : /* @__PURE__ */ jsx(Grid, { container: true, spacing: 0, className: classes.root, children: content });
};
export {
  OverviewCardRow
};
