import { jsx, jsxs } from "react/jsx-runtime";
import UrlFilters from "./tabs/UrlFilters/UrlFilters";
import WebFilterSettings from "./settings/WebFilterSettings";
import { Route, Switch } from "react-router";
import CustomCategories from "./tabs/CustomCategories/CustomCategories";
import WebMonitorPolicies from "./webMonitorPolicies/WebMonitorPolicies";
const WebFilter = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(Route, { path: "/policies/webfilter/customcategories", children: /* @__PURE__ */ jsx(CustomCategories, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/webfilter/urlfilter", children: /* @__PURE__ */ jsx(UrlFilters, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/webfilter/settings", children: /* @__PURE__ */ jsx(WebFilterSettings, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/webfilter/policies", children: /* @__PURE__ */ jsx(WebMonitorPolicies, {}) })
] });
var WebFilter_default = WebFilter;
export {
  WebFilter_default as default
};
