import { useI18next } from "@cuda-react/core";
import countries from "i18n-iso-countries";
import { useTranslation } from "react-i18next";
import en from "i18n-iso-countries/langs/en.json";
import fr from "i18n-iso-countries/langs/fr.json";
import ja from "i18n-iso-countries/langs/ja.json";
import de from "i18n-iso-countries/langs/de.json";
countries.registerLocale(en);
countries.registerLocale(fr);
countries.registerLocale(ja);
countries.registerLocale(de);
const usei18nCountries = () => {
  const { activeLanguage } = useI18next();
  const [translate] = useTranslation();
  return (countryCode) => {
    if (!countryCode)
      return translate("stratosReporting.ZTNAUserGeoLocation.nonRoutableOrIP");
    return countries.getName(countryCode, activeLanguage, { select: "official" }) || translate("stratosReporting.ZTNAUserGeoLocation.nonRoutableOrIP");
  };
};
export {
  usei18nCountries
};
