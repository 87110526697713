import { jsx, jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import {
  ActionButtonsField,
  BooleanField,
  ChipArrayField,
  DeleteDialog,
  EmptyPageContent,
  StatusIconField,
  TablePage,
  TextField,
  useMixpanel
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import CreateFirewallTokenDialog from "./CreateFirewallTokenDialog";
import { FirewallGraphic, GetStartedBackgroundGraphic } from "@cuda-react/icons";
const FirewallsTable = () => {
  const registerAction = useMixpanel("Firewalls");
  const tableRefreshRef = useRef(null);
  const [actionVisible, setActionVisible] = useState(false);
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      titleActions: actionVisible && /* @__PURE__ */ jsx(CreateFirewallTokenDialog, {}),
      authenticated: true,
      resource: apiResources.firewall,
      title: "tesseract.integration.pageTitle",
      subtitle: "tesseract.integration.firewalls",
      minCellWidth: 90,
      tableName: "Firewalls",
      resetOnGlobalParamChange: true,
      refreshRef: tableRefreshRef,
      resizable: true,
      reorderable: true,
      editColumns: true,
      onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table"),
      noDataPage: /* @__PURE__ */ jsx(
        EmptyPageContent,
        {
          background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
          icon: /* @__PURE__ */ jsx(FirewallGraphic, {}),
          iconHeader: "tesseract.firewalls.noData.iconHeader",
          textHeader: "tesseract.firewalls.noData.textHeader",
          text: "tesseract.firewalls.noData.text",
          actions: /* @__PURE__ */ jsx(CreateFirewallTokenDialog, {})
        }
      ),
      children: [
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "aggregateState",
            alwaysVisible: true,
            width: 90
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.firewalls.table.name",
            filter: "text",
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "serials",
            label: "tesseract.firewalls.table.serials",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          BooleanField,
          {
            source: "ccManaged",
            label: "tesseract.firewalls.table.isCcManaged",
            width: 120,
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "model",
            label: "tesseract.firewalls.table.model",
            filter: "text",
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(ActionButtonsField, { width: 96, source: "id", alwaysVisible: true, children: /* @__PURE__ */ jsx(
          DeleteDialog,
          {
            disable: (data) => !data || (data == null ? void 0 : data.ztEnabled),
            resource: apiResources.firewall,
            title: "tesseract.firewalls.delete.title",
            message: "tesseract.firewalls.delete.body",
            disabledMessage: "tesseract.firewalls.delete.forbidden",
            onSuccess: () => {
              var _a;
              registerAction("Delete CloudGen Firewall");
              (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
            }
          }
        ) })
      ]
    }
  );
};
var FirewallsTable_default = FirewallsTable;
export {
  FirewallsTable_default as default
};
