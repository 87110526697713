var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import {
  BooleanInput,
  ConnectedForm,
  getArrayDataContent,
  PropGateway,
  ReadOnlyInput,
  SelectedChoiceField,
  SelectInput,
  TextInput,
  useCrudProps,
  useGlobalParam,
  useMixpanel
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import { useSiteInputValidations } from "../../../hooks/createEditSiteHooks";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  helpText: {
    margin: theme.spacing(2, 4, 0, 8)
  }
}));
const EditManagedGatewayContent = ({ id, details: { model, gatewayType } }) => {
  var _a;
  const registerAction = useMixpanel(
    "Gateways Settings",
    !!model,
    { model: model || "", type: gatewayType || "" }
  );
  const inputValidations = useSiteInputValidations();
  const bandwidthChoices = getArrayDataContent(useCrudProps(apiResources.managedGatewaysBandwidths)[0]);
  const regionChoices = getArrayDataContent(useCrudProps(apiResources.gatewayRegions)[0]);
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const classes = useStyles();
  const [translate] = useTranslation();
  const bandwidthData = (_a = useCrudProps(apiResources.managedBandwidthsUsage)[0]) == null ? void 0 : _a.data;
  const availableBandwidth = (bandwidthData == null ? void 0 : bandwidthData.available) > 0 ? bandwidthData.available : 0;
  return /* @__PURE__ */ jsxs(
    ConnectedForm,
    {
      resource: apiResources.managedGateways,
      onSubmitSuccess: (response) => {
        registerAction("Update", {
          model: response.model,
          type: "managed"
        });
      },
      id,
      formatRequestData: (data) => __spreadProps(__spreadValues({}, data), {
        id,
        virtualWanId
      }),
      canReset: true,
      topToolbar: true,
      children: [
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "name",
            label: "tesseract.gateways.dialog.create.managed.name",
            validate: inputValidations.validateSiteName
          }
        ),
        /* @__PURE__ */ jsx(
          ReadOnlyInput,
          {
            source: "region",
            label: "tesseract.gateways.dialog.create.managed.region",
            field: SelectedChoiceField,
            options: { choices: regionChoices }
          }
        ),
        /* @__PURE__ */ jsx(
          SelectInput,
          {
            source: "model",
            label: "tesseract.gateways.dialog.create.managed.bandwidth",
            choices: bandwidthChoices,
            additionalInfoLabel: availableBandwidth <= 99999 ? {
              i18nKey: "tesseract.gateways.dialog.create.managed.bandwidthAvailable",
              values: { availableBandwidth }
            } : void 0,
            validate: (value, data, formValues) => {
              var _a2;
              const totalBandwidth = parseInt((_a2 = formValues == null ? void 0 : formValues.initialValues) == null ? void 0 : _a2.model) + (bandwidthData == null ? void 0 : bandwidthData.available);
              const availableBandwidth2 = totalBandwidth > 0 ? totalBandwidth : 0;
              if (value && value > availableBandwidth2) {
                return translate("tesseract.gateways.settings.managed.bandwidth.invalidBandwidth", { availableBandwidth: availableBandwidth2 });
              } else {
                return void 0;
              }
            }
          }
        ),
        /* @__PURE__ */ jsx(PropGateway, { children: /* @__PURE__ */ jsx(Typography, { className: classes.helpText, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.gateways.settings.editBandwidthWarning" }) }) }),
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "disableSyslogStreaming",
            label: "tesseract.syslog.syslogOverride",
            description: "tesseract.syslog.descriptions.syslogOverride_gateway"
          }
        )
      ]
    }
  );
};
var EditManagedGatewayContent_default = EditManagedGatewayContent;
export {
  EditManagedGatewayContent_default as default
};
