var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Columns from "../../layout/Columns/Columns";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    paddingTop: theme.spacing(1)
  },
  labelCell: {
    fontSize: theme.typography.pxToRem(14),
    borderBottom: "none",
    padding: theme.spacing(0.5, 2),
    color: theme.palette.text.secondary
  },
  dataCell: {
    fontSize: theme.typography.pxToRem(14),
    borderBottom: "none",
    height: 24
  },
  borderContainer: {
    borderBottom: "solid 1px #DDDDDD"
  },
  columnStyleLabelCell: {
    borderBottom: "none",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: "bold",
    padding: theme.spacing(1, 2),
    textAlign: "right",
    width: "30%"
  }
});
const useStyles = makeOverrideableStyles("ColumnField", styles);
const ColumnField = (props) => {
  const _a = props, { children, columnCount = 2, columns, data, stratosSummaryStyle } = _a, columnProps = __objRest(_a, ["children", "columnCount", "columns", "data", "stratosSummaryStyle"]);
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const columnStructure = columns || Array(columnCount).fill({ xs: 12, md: 6 });
  const tabulateItems = (itemsArray) => /* @__PURE__ */ jsx(Table, { size: "small", children: /* @__PURE__ */ jsx(TableBody, { children: React.Children.map(itemsArray, (child) => child ? /* @__PURE__ */ jsxs(TableRow, { className: stratosSummaryStyle ? child.props.borderBottom ? classes.borderContainer : void 0 : void 0, children: [
    /* @__PURE__ */ jsx(TableCell, { className: stratosSummaryStyle ? classes.columnStyleLabelCell : classes.labelCell, children: translate(child.props.label) }),
    /* @__PURE__ */ jsx(TableCell, { className: classes.dataCell, children: React.cloneElement(child, { data }) })
  ] }) : null) }) });
  return /* @__PURE__ */ jsx(
    Columns,
    __spreadProps(__spreadValues({
      columns: columnStructure,
      render: tabulateItems,
      className: classes.root
    }, columnProps), {
      children
    })
  );
};
var ColumnField_default = ColumnField;
export {
  ColumnField,
  ColumnField_default as default
};
