import { jsx, jsxs } from "react/jsx-runtime";
import CreateGatewayContent from "./CreateGatewayContent";
import CreateTVTContent from "../../../../components/appliances/create/CreateTVTContent";
import CreateManagedGatewayContent from "./CreateManagedGatewayContent";
import { Dialog } from "@barracuda-internal/bds-core";
const CreateGatewayDialog = ({ currentDialog, onSuccess, onClose }) => /* @__PURE__ */ jsxs(
  Dialog,
  {
    open: !!currentDialog,
    maxWidth: false,
    disableEnforceFocus: true,
    children: [
      currentDialog === "managed" && /* @__PURE__ */ jsx(
        CreateManagedGatewayContent,
        {
          title: "tesseract.gateways.dialog.create.managed.dialogTitle",
          label: "tesseract.gateways.dialog.create.managed.menuLabel",
          onSuccess,
          onClose
        }
      ),
      currentDialog === "cloud" && /* @__PURE__ */ jsx(
        CreateGatewayContent,
        {
          create: true,
          title: "tesseract.gateways.dialog.create.cloud.dialogTitle",
          label: "tesseract.gateways.dialog.create.cloud.menuLabel",
          onClose
        }
      ),
      currentDialog === "on-prem" && /* @__PURE__ */ jsx(
        CreateTVTContent,
        {
          type: "gateway",
          title: "tesseract.gateways.dialog.create.onPrem.dialogTitle",
          label: "tesseract.gateways.dialog.create.onPrem.menuLabel",
          onSuccess,
          onClose
        }
      )
    ]
  }
);
var CreateGatewayDialog_default = CreateGatewayDialog;
export {
  CreateGatewayDialog_default as default
};
