import { merge } from "lodash";
import { SET_GLOBAL_PARAMS, UPDATE_GLOBAL_PARAMS } from "../../actions";
const GlobalParamsReducer = (previousState = {}, { type, params }) => {
  switch (type) {
    case SET_GLOBAL_PARAMS:
      return Object.assign(
        {},
        params
      );
    case UPDATE_GLOBAL_PARAMS:
      return merge(
        {},
        previousState,
        params
      );
    default:
      return previousState;
  }
};
var GlobalParamsReducer_default = GlobalParamsReducer;
export {
  GlobalParamsReducer_default as default
};
