var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import Tooltip from "@barracuda-internal/bds-core/dist/Tooltip";
import { useTranslation } from "react-i18next";
import ConditionalWrapper from "../../functional/ConditionalWrapper/ConditionalWrapper";
import ButtonDialog from "../ButtonDialog/ButtonDialog";
import { Delete } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
import DeleteDialogContent from "../DeleteDialogContent/DeleteDialogContent";
const styles = (theme) => createStyles({
  iconDisabled: {
    fill: theme.palette.text.disabled,
    color: theme.palette.text.disabled
  },
  tooltip: __spreadValues({}, theme.typography.body2)
});
const useStyles = makeOverrideableStyles("DeleteDialog", styles);
const DeleteDialog = (props) => {
  const _a = props, { disable, disabled, disabledMessage, classes: _overrides } = _a, deleteDialogContentProps = __objRest(_a, ["disable", "disabled", "disabledMessage", "classes"]);
  const [translate] = useTranslation();
  const classes = useStyles(props);
  const isDisabled = disabled || !!disable && disable(props.data);
  return /* @__PURE__ */ jsx(
    ConditionalWrapper,
    {
      condition: disabledMessage && isDisabled || false,
      wrapper: (children) => /* @__PURE__ */ jsx(
        Tooltip,
        {
          title: disabledMessage ? translate(disabledMessage) : "",
          placement: "top",
          classes: { tooltip: classes.tooltip },
          children: /* @__PURE__ */ jsx("span", { children })
        }
      ),
      children: /* @__PURE__ */ jsx(
        ButtonDialog,
        {
          buttonIcon: /* @__PURE__ */ jsx(
            Delete,
            {
              id: "cuda-icon-delete",
              className: isDisabled ? classes.iconDisabled : void 0
            }
          ),
          disabled: isDisabled,
          stopPropagation: props.stopPropagation,
          children: /* @__PURE__ */ jsx(DeleteDialogContent, __spreadValues({}, deleteDialogContentProps))
        }
      )
    }
  );
};
DeleteDialog.defaultProps = {
  optionValue: "id",
  optionText: "name",
  title: "cuda.delete.title",
  message: "cuda.delete.message"
};
var DeleteDialog_default = DeleteDialog;
export {
  DeleteDialog,
  DeleteDialog_default as default
};
