import { useDeepCompareCallback } from "./UtilHooks";
import { useEffect, useState } from "react";
const useInfiniteScroll = ({ canLoadMoreRows, pageMode, loading, loadMoreRows }) => {
  const [shouldLoadMoreRows, setShouldLoadMoreRows] = useState(false);
  useEffect(() => {
    if (shouldLoadMoreRows) {
      loadMoreRows();
    }
  }, [shouldLoadMoreRows]);
  useEffect(() => {
    if (!loading) {
      setShouldLoadMoreRows(false);
    }
  }, [loading]);
  const onScroll = useDeepCompareCallback((event) => {
    if (!canLoadMoreRows) {
      return;
    }
    const { clientHeight, scrollHeight, scrollTop } = event.nativeEvent.target;
    const initialBelowFoldHeight = scrollHeight - clientHeight;
    const currentBelowFoldHeight = initialBelowFoldHeight - scrollTop;
    if (!loading && pageMode === "dynamic" && currentBelowFoldHeight < clientHeight) {
      setShouldLoadMoreRows(true);
    }
  }, [canLoadMoreRows, pageMode, loading]);
  return {
    onScroll
  };
};
export {
  useInfiniteScroll
};
