import { get } from "lodash";
import PropTypes from "prop-types";
import { getDataContent } from "../utils";
import { useCrudSubscription } from "./index";
import { useEffect } from "react";
import { CrudTypes } from "../clients";
const resolveGetFromValue = (path, getFrom, defaults, props) => {
  const value = get(getFrom, path) || get(props, path) || get(defaults, path);
  return typeof value === "function" ? value(props) : value;
};
const cardGetFromPropTypes = PropTypes.shape({
  crudType: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  resource: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  params: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])), PropTypes.object]),
  formatData: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    debounceWait: PropTypes.number,
    crudOptions: PropTypes.shape({
      idKey: PropTypes.string,
      quietErrors: PropTypes.bool
    }),
    pollInterval: PropTypes.number
  })])
});
const useCardGetFrom = (getFrom, props, defaults) => {
  const formatData = get(getFrom, "formatData") || get(defaults, "formatData") || get(props, "formatData");
  const [data, loading] = useCrudSubscription(
    resolveGetFromValue("crudType", getFrom, defaults, props) || CrudTypes.GET,
    resolveGetFromValue("resource", getFrom, defaults, props),
    resolveGetFromValue("params", getFrom, defaults, props) || {},
    resolveGetFromValue("options", getFrom, defaults, props)
  );
  const retrievedData = getDataContent(data.data);
  const providedData = retrievedData ? retrievedData : resolveGetFromValue("data", getFrom, defaults, props);
  const content = formatData ? formatData(providedData) : providedData;
  const status = useStatusAvatar(
    data,
    content,
    loading,
    resolveGetFromValue("statusAvatarSource", getFrom, defaults, props),
    resolveGetFromValue("statusAvatarAggregateSource", getFrom, defaults, props),
    props && props.updateState
  );
  return [content, !!loading, data && data.error, status];
};
const useStatusAvatar = (rawData, arrayData, loading, statusAvatarSource, statusAvatarAggregateSource, updateState) => {
  let statusAvatarValue;
  if (statusAvatarSource || statusAvatarAggregateSource) {
    if (rawData && rawData.error) {
      statusAvatarValue = "error";
    } else if (statusAvatarSource) {
      statusAvatarValue = get(rawData && rawData.data, statusAvatarSource) || (loading ? "loading" : "unknown");
    } else if (statusAvatarAggregateSource && arrayData && Array.isArray(arrayData)) {
      statusAvatarValue = arrayData.some((item) => {
        const state = get(item, statusAvatarAggregateSource);
        return state !== "successful" && state !== "ok";
      }) ? "error" : "ok";
    } else {
      statusAvatarValue = loading ? "loading" : void 0;
    }
  }
  useEffect(() => {
    updateState && updateState(statusAvatarValue);
  }, [statusAvatarValue]);
  return statusAvatarValue;
};
export {
  cardGetFromPropTypes,
  useCardGetFrom,
  useStatusAvatar
};
