const dataGenerator = () => ({
  total: 165,
  operatingSystems: {
    "Mac OS": 28,
    "Android": 22,
    "iOS": 7,
    "Linux": 8,
    "Windows": 100,
    "Chrome OS": 100
  }
});
var ztnaAgentOperatingSystems_default = dataGenerator;
export {
  ztnaAgentOperatingSystems_default as default
};
