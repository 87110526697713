import { jsx, jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonGroup } from "@barracuda-internal/bds-core";
import { CardSizes } from "../../../typesAndConstants";
import { useEffect } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { GridSizeIcon } from "@cuda-react/icons";
import { GridSizes } from "@cuda-react/icons/lib/stratosIcons/GridSizeIcon/GridSizeIcon";
const styles = (theme) => createStyles({
  toggleButtonLarge: {
    textTransform: "none",
    height: 28,
    color: `${theme.palette.text.primary} !important`,
    fontSize: 16,
    width: "33%",
    "&:disabled": {
      color: "#DDDDDD !important",
      backgroundColor: "#AAAAAA"
    }
  },
  toggleGroup: {
    width: 320,
    paddingTop: theme.spacing(0.5)
  }
});
const useStyles = makeStyles(styles);
const SizeSelect = (props) => {
  const { value, onChange, forceSmall } = props;
  const [translate] = useTranslation();
  const classes = useStyles(props);
  useEffect(() => {
    if (value === void 0) {
      onChange == null ? void 0 : onChange(CardSizes.small);
    }
  }, [value, onChange]);
  return /* @__PURE__ */ jsxs(
    ToggleButtonGroup,
    {
      "aria-label": "outlined primary button group",
      exclusive: true,
      value,
      onChange: (event, value2) => value2 && (onChange == null ? void 0 : onChange(value2)),
      className: classes.toggleGroup,
      children: [
        /* @__PURE__ */ jsxs(
          ToggleButton,
          {
            classes: { sizeLarge: classes.toggleButtonLarge },
            value: CardSizes.small,
            size: "large",
            children: [
              /* @__PURE__ */ jsx(GridSizeIcon, { size: GridSizes.small }),
              " \xA0",
              translate("stratosReporting.previewDialog.small")
            ]
          }
        ),
        /* @__PURE__ */ jsxs(
          ToggleButton,
          {
            value: CardSizes.medium,
            classes: { sizeLarge: classes.toggleButtonLarge },
            disabled: forceSmall,
            size: "large",
            children: [
              /* @__PURE__ */ jsx(GridSizeIcon, { size: GridSizes.medium }),
              " \xA0",
              translate("stratosReporting.previewDialog.medium")
            ]
          }
        ),
        /* @__PURE__ */ jsxs(
          ToggleButton,
          {
            value: CardSizes.large,
            classes: { sizeLarge: classes.toggleButtonLarge },
            disabled: forceSmall,
            size: "large",
            children: [
              /* @__PURE__ */ jsx(GridSizeIcon, { size: GridSizes.large }),
              " \xA0",
              translate("stratosReporting.previewDialog.large")
            ]
          }
        )
      ]
    }
  );
};
export {
  SizeSelect
};
