var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import Input from "../Input/Input";
import { get } from "lodash";
import Password from "./Password";
const getConfirmName = (name) => name + "_confirm";
const confirmPasswordValidation = (confirmValue, allValues, props, confirmName) => {
  const name = (confirmName || "").substring(0, (confirmName || "").length - 8);
  const value = get(allValues, name, "");
  const initialValue = (props == null ? void 0 : props.initialValues) && name && get(props == null ? void 0 : props.initialValues, name);
  const valueIsIgnorable = initialValue === value && (confirmValue === "" || confirmValue === void 0 || confirmValue === null);
  const valueIsConfirmed = value === confirmValue;
  return !valueIsIgnorable && !valueIsConfirmed ? props.t("cuda.validation.passwordConfirm") : void 0;
};
const PasswordConfirmInput = (props) => {
  const { source, confirmLabel, clearOnFocus = false, viewable = false, validate, noConfirm } = props;
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      Input,
      __spreadProps(__spreadValues({}, props), {
        source,
        clearOnFocus,
        viewable,
        displayError: false,
        validate,
        component: Password
      })
    ),
    !noConfirm && /* @__PURE__ */ jsx(
      Input,
      __spreadProps(__spreadValues({}, props), {
        label: confirmLabel,
        clearOnFocus,
        description: "",
        viewable,
        displayError: false,
        isRequired: false,
        validate: [confirmPasswordValidation],
        source: getConfirmName(source),
        component: Password
      })
    )
  ] });
};
var PasswordConfirmInput_default = PasswordConfirmInput;
export {
  PasswordConfirmInput,
  confirmPasswordValidation,
  PasswordConfirmInput_default as default
};
