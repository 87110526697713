import { jsx } from "react/jsx-runtime";
import { Chip, IconButton } from "@barracuda-internal/bds-core";
import { PageNext, PagePrevious } from "@barracuda-internal/bds-core/dist/Icons/Core";
import get from "lodash/get";
import { useState } from "react";
import { useChoices } from "../../../hooks";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  iconButton: {
    zIndex: 2,
    display: "inline-block",
    marginRight: theme.spacing(2),
    marginTop: 5,
    height: 30,
    verticalAlign: "middle"
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: -theme.spacing(1)
  },
  chip: {
    margin: 4,
    maxWidth: "100%"
  },
  chipIcon: {
    marginLeft: 10,
    marginTop: 6
  },
  chipUnknown: {
    color: theme.palette.error.main
  }
});
const useStyles = makeOverrideableStyles("ActionButtonsField", styles);
const ChipArrayField = (props) => {
  const {
    choices,
    data = {},
    filterKey,
    icon,
    iconMap,
    maxChips = 5,
    maxChipLength = 30,
    onDelete,
    optionIcon,
    optionText = "name",
    optionValue = "key",
    render,
    resource,
    source = "",
    sourceValue = "",
    unknownChipContent = "cuda.inputs.selectArray.unknownChipText"
  } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const [translate] = useTranslation();
  const unknownChip = typeof unknownChipContent === "string" ? translate(unknownChipContent) : unknownChipContent;
  const [minimized, setMinimized] = useState(true);
  const field = get(data, source) || [];
  const fieldArray = Array.isArray(field) ? field : [field];
  const resolvedFieldArray = sourceValue ? fieldArray.map((value) => get(value, sourceValue)) : fieldArray;
  const [resourceData] = useChoices(
    resolvedFieldArray,
    {
      choices,
      filterKey: filterKey || optionValue,
      optionValue,
      resource,
      params: {
        pagination: {
          page: 1,
          perPage: resolvedFieldArray.length
        }
      }
    }
  );
  const dataToUse = resource || choices ? resourceData.map((chipData) => get(chipData, optionText)) : resolvedFieldArray;
  const slicedFields = minimized ? dataToUse.slice(0, maxChips) : dataToUse;
  const chipValues = slicedFields.map((val, index) => render ? render(val, data, fieldArray.find((field2) => get(field2, sourceValue, field2) === get(resourceData[index], optionValue)), resourceData[index]) : translate(val));
  const chipIcons = slicedFields.map((val, index) => {
    if (iconMap) {
      if (typeof optionIcon === "function") {
        const iconIndex = optionIcon(val, data);
        return iconIndex && iconMap[iconIndex] || icon || null;
      }
      return (optionIcon ? iconMap[get(resourceData[index], optionIcon) || get(fieldArray[index], optionIcon)] : iconMap[val]) || icon || null;
    }
    return icon || null;
  });
  const renderChips = (chips, rootIndex = -1) => chips.flatMap((val, index) => {
    const iconIndex = rootIndex > -1 ? rootIndex : index;
    if (Array.isArray(val)) {
      return renderChips(val, iconIndex);
    }
    return /* @__PURE__ */ jsx(
      Chip,
      {
        label: typeof val === "string" && val.length > maxChipLength ? val.substring(0, maxChipLength - 3) + "..." : val ? val : /* @__PURE__ */ jsx("span", { className: classes.chipUnknown, children: unknownChip }),
        className: classes.chip,
        icon: chipIcons[iconIndex] ? /* @__PURE__ */ jsx("span", { className: classes.chipIcon, children: chipIcons[iconIndex] }) : void 0,
        onDelete: onDelete ? () => onDelete(val) : void 0
      },
      "Chip-" + (rootIndex !== void 0 ? rootIndex + "-" : "") + index
    );
  });
  return /* @__PURE__ */ jsx("div", { id: "chip-array-field-" + source.replace(/[.]/g, "-"), className: classes.root, children: renderChips(chipValues).concat(
    dataToUse.length > maxChips ? [
      /* @__PURE__ */ jsx(
        IconButton,
        {
          className: classes.iconButton,
          onClick: () => setMinimized(!minimized),
          color: "primary",
          children: minimized ? /* @__PURE__ */ jsx(PageNext, { style: { color: theme.palette.primary.main } }) : /* @__PURE__ */ jsx(PagePrevious, { style: { color: theme.palette.primary.main } })
        },
        "button"
      )
    ] : []
  ) });
};
var ChipArrayField_default = ChipArrayField;
export {
  ChipArrayField,
  ChipArrayField_default as default,
  styles
};
