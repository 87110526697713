import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import {
  AutoSelectInput,
  BooleanInput,
  ChipArrayField,
  FormSection,
  getArrayDataContent,
  InputLabel,
  MultiInput,
  SelectInput,
  TextArrayInput,
  TextInput,
  useCrudProps,
  validateIpv4,
  validateIpv4CIDR
} from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../apiResources";
import { useSiteInputValidations } from "../../../../hooks/createEditSiteHooks";
import { useWatch } from "react-hook-form";
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    fontSize: 16
  },
  completeMessage: {
    margin: theme.spacing(3)
  }
}));
const SourceDestInput = ({ gateways, sites }) => {
  var _a, _b, _c, _d;
  const classes = useStyles();
  const [translate] = useTranslation();
  const [selectedUuid, selectedType = "gateway", selectedWanKey] = useWatch({ name: ["local.uuid", "local.type", "local.wan"] });
  const cloudGatewaySelected = (_a = gateways.find(({ uuid }) => selectedUuid === uuid)) == null ? void 0 : _a.cloud;
  const inputValidations = useSiteInputValidations();
  const sourceTypeData = [
    {
      key: "gateway",
      name: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.sourceTypeData.gateway"
    },
    {
      key: "site",
      name: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.sourceTypeData.site"
    }
  ];
  const wanChoices = ((_b = (selectedType === "gateway" ? gateways : sites).find((item) => (item == null ? void 0 : item.uuid) === selectedUuid)) == null ? void 0 : _b.wans) || [];
  const gatewayData = getArrayDataContent((_c = useCrudProps(cloudGatewaySelected && apiResources.gateways, { filter: { uuid: selectedUuid } })[0]) == null ? void 0 : _c.data)[0];
  const selectedWan = Array.isArray(wanChoices) && wanChoices.find((wan) => wan.key === selectedWanKey && wan.address);
  const wanIpChoices = selectedWan ? [
    { key: selectedWan == null ? void 0 : selectedWan.address, name: selectedWan == null ? void 0 : selectedWan.address, type: translate("tesseract.integration.ipSecV2.dialog.sourceDestSection.source.primaryAddress") },
    ...((selectedWan == null ? void 0 : selectedWan.addresses) || []).map((ip) => ({ key: ip, name: ip, type: translate("tesseract.integration.ipSecV2.dialog.sourceDestSection.source.additionalAddresses") }))
  ] : [];
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.completeMessage, children: translate("tesseract.integration.ipSecV2.dialog.sourceDestSection.descriptionMessage") }),
    /* @__PURE__ */ jsx(
      BooleanInput,
      {
        source: "bgp",
        label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.enableBgp",
        description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.enableBgp"
      }
    ),
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.title", children: [
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.type",
          source: "local.type",
          choices: sourceTypeData,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.peer",
          source: "local.uuid",
          choices: selectedType === "site" ? sites : gateways,
          optionValue: "uuid",
          isRequired: true
        }
      ),
      !cloudGatewaySelected && /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.wanInterface",
          source: "local.wan",
          choices: wanChoices
        }
      ),
      !cloudGatewaySelected && selectedWan && /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.localWans",
          source: "local.address",
          choices: wanIpChoices,
          optionCategory: ((_d = wanIpChoices[0]) == null ? void 0 : _d.type) ? "type" : void 0
        }
      ),
      cloudGatewaySelected && /* @__PURE__ */ jsx(
        InputLabel,
        {
          label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.ipAddresses",
          description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.ipAddresses",
          children: /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "connectionStatus.connectionStates",
              data: gatewayData,
              render: (value) => value == null ? void 0 : value.id
            }
          )
        }
      ),
      /* @__PURE__ */ jsx(
        TextInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.localId",
          description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.localId",
          source: "local.id",
          validate: inputValidations.validateAlphaNumericDotsColonsDash(255)
        }
      ),
      /* @__PURE__ */ jsx(
        TextArrayInput,
        {
          source: "local.networks",
          label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.networkAddresses",
          description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.networkAddresses",
          validate: inputValidations.validateCidrArray,
          isRequired: true,
          hide: (value, data) => data == null ? void 0 : data.bgp
        }
      ),
      /* @__PURE__ */ jsx(
        TextInput,
        {
          source: "local.bgpAddress",
          label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.networkAddressBgp",
          description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.networkAddressBgp",
          isRequired: true,
          validate: validateIpv4CIDR,
          hide: (value, data) => !(data == null ? void 0 : data.bgp)
        }
      ),
      /* @__PURE__ */ jsx(
        AutoSelectInput,
        {
          source: "local.asn",
          label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.asnBgp",
          description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.descriptions.asnBgp",
          resource: apiResources.availableAsns,
          validate: inputValidations.validateAsnIpsec,
          hide: (value, data) => !(data == null ? void 0 : data.bgp),
          allowShuffle: true,
          allowManualInput: true,
          isRequired: true
        },
        "local.asn"
      )
    ] }),
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.title", children: [
      /* @__PURE__ */ jsx(
        TextInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.remoteGateway",
          description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.remoteGateway",
          source: "remotes[0].gateway",
          hide: (value, data) => data == null ? void 0 : data.bgp,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.remoteId",
          description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.remoteId",
          source: "remotes[0].id",
          validate: inputValidations.validateAlphaNumericDotsColonsDash(255),
          hide: (value, data) => data == null ? void 0 : data.bgp
        }
      ),
      /* @__PURE__ */ jsx(
        TextArrayInput,
        {
          source: "remotes[0].networks",
          label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.networkAddresses",
          description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.networkAddresses",
          validate: inputValidations.validateCidrArray,
          isRequired: true,
          hide: (value, data) => data == null ? void 0 : data.bgp
        }
      ),
      /* @__PURE__ */ jsxs(
        MultiInput,
        {
          maxInputs: 2,
          source: "remotes",
          hide: (value, data) => !(data == null ? void 0 : data.bgp),
          columnStyle: true,
          columnStyleItemLabelSource: "gateway",
          columnStyleItemLabel: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.multiInputTitle",
          columnStyleTitleLabel: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.multiInputTitleLabel",
          children: [
            /* @__PURE__ */ jsx(
              TextInput,
              {
                label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.remoteGateway",
                description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.remoteGateway",
                source: "gateway",
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.remoteId",
                description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.remoteId",
                source: "id",
                validate: inputValidations.validateAlphaNumericDotsColonsDash(255)
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "bgpAddress",
                label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.networkAddressBgp",
                description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.networkAddressBgp",
                validate: validateIpv4,
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "asn",
                label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.asnBgp",
                description: "tesseract.integration.ipSecV2.dialog.sourceDestSection.destination.descriptions.asnBgp",
                inputProps: { type: "number" },
                validate: inputValidations.validateAsnAny,
                isRequired: true
              },
              "asn"
            )
          ]
        }
      )
    ] })
  ] });
};
var SourceDestInput_default = SourceDestInput;
export {
  SourceDestInput,
  SourceDestInput_default as default
};
