import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography } from "@barracuda-internal/bds-core";
import { getArrayDataContent, TextInput, useCrudProps } from "@cuda-react/core";
import { useSiteInputValidations } from "../../../../../hooks/createEditSiteHooks";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useWatch } from "react-hook-form";
import apiResources from "../../../../../apiResources";
const useStyles = makeStyles((theme) => ({
  message: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
const SelectLanLengthStep = () => {
  var _a;
  const inputValidations = useSiteInputValidations();
  const classes = useStyles();
  const [translate] = useTranslation();
  const model = useWatch({ name: "serials[0].model" });
  const portsData = getArrayDataContent((_a = useCrudProps(apiResources.modelPorts, { id: model })[0]) == null ? void 0 : _a.data);
  const availablePorts = portsData.filter((port) => port.port && !port.port.includes("wan"));
  const portsAvailable = availablePorts.length;
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: translate("tesseract.sites.dialog.lans.SCmessage", { portsAvailable }) }),
    /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: translate("tesseract.sites.dialog.lans.message2") }),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        label: "tesseract.sites.dialog.lans.size",
        source: "lanTotal",
        isRequired: true,
        validate: inputValidations.validateSCLansLength(portsAvailable || 3),
        type: "integer",
        options: {
          type: "number",
          InputProps: {
            inputProps: {
              min: 1,
              max: portsAvailable,
              step: 1
            }
          }
        }
      }
    )
  ] });
};
var SelectLanLengthStep_default = SelectLanLengthStep;
export {
  SelectLanLengthStep,
  SelectLanLengthStep_default as default
};
