var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import {
  ActionButtonsField,
  ChipArrayField,
  CustomField,
  DeleteDialog,
  getArrayDataContent,
  TablePage,
  TextField,
  useCrudProps,
  useMixpanel
} from "@cuda-react/core";
import { useRef, useState } from "react";
import apiResources from "../../../../../apiResources";
import { Dialog } from "@barracuda-internal/bds-core";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import { CreateEditCustomCategory } from "./createEdit/CreateEditCustomCategory";
import { useTranslation } from "react-i18next";
import { sortBy } from "lodash";
const CustomCategories = () => {
  var _a, _b;
  const registerAction = useMixpanel("Custom Categories");
  const tableRefreshRef = useRef(null);
  const [actionVisible, setActionVisible] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [translate] = useTranslation();
  const categoryChoices = sortBy(getArrayDataContent((_a = useCrudProps(apiResources.urlFilterCategories, {}, {}, true)[0]) == null ? void 0 : _a.data), "key");
  const groupChoices = sortBy(getArrayDataContent((_b = useCrudProps(apiResources.urlFilterGroups)[0]) == null ? void 0 : _b.data), "key");
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      TablePage,
      {
        titleActions: actionVisible && /* @__PURE__ */ jsx(
          CreateEditButtonDialog,
          {
            component: CreateEditCustomCategory,
            create: true,
            label: "tesseract.customCategories.dialog.create.buttonText",
            onClick: () => setCreateOpen(true),
            onSuccess: () => {
              var _a2;
              return (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
            }
          }
        ),
        authenticated: true,
        resource: apiResources.customCategories,
        title: "tesseract.securityAndAccess.webFilter",
        subtitle: "tesseract.network.customCategories",
        noDataMessage: "tesseract.customCategories.table.noData",
        minCellWidth: 160,
        tableName: "Custom Categories",
        refreshRef: tableRefreshRef,
        params: (params) => __spreadProps(__spreadValues({}, params), {
          filter: __spreadProps(__spreadValues({}, params.filter || {}), {
            category: [...params.filter && params.filter.categories && params.filter.categories.category || []],
            domain: [params.filter && params.filter.categories && params.filter.categories.domain || []]
          })
        }),
        expandableFields: [
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "domains",
              label: "tesseract.customCategories.table.domainsIncluded",
              skipMissing: true
            },
            "domains"
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              arraySource: "categoryValues",
              source: "active",
              sourceValue: "name",
              skipMissing: true
            },
            "categoryValues"
          )
        ],
        formatData: (data) => getArrayDataContent(data).map((rowData) => {
          const activeCategories = categoryChoices.filter((category) => rowData.categories.includes(category.key));
          return __spreadProps(__spreadValues({}, rowData), {
            activeCategories,
            categoryValues: groupChoices.map((group) => __spreadProps(__spreadValues({}, group), {
              active: activeCategories.filter((category) => category.group === group.key)
            })).filter((group) => group.active.length > 0)
          });
        }),
        onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table"),
        children: [
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "name",
              label: "tesseract.customCategories.table.name",
              filter: "text",
              width: 180,
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "description",
              label: "tesseract.customCategories.table.description",
              filter: "text",
              width: 480
            }
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "categories",
              label: "tesseract.customCategories.table.details",
              render: (rules, data) => {
                const categoriesString = data.categories.length >= 1 ? translate(
                  `tesseract.customCategories.table.detailCategories`,
                  __spreadProps(__spreadValues({}, data), { context: rules.length, additional: rules.length - 1 })
                ) : "";
                const domainsString = data.domains.length >= 1 ? translate(
                  `tesseract.customCategories.table.detailDomains`,
                  __spreadProps(__spreadValues({}, data), { context: data.domains.length, additionalDomains: data.domains.length - 1 })
                ) : "";
                return `${categoriesString}${domainsString}`;
              },
              filter: "custom",
              filterProps: {
                component: DestinationFilter,
                filterSources: ["domain", "category"],
                categoryChoices
              }
            }
          ),
          /* @__PURE__ */ jsxs(
            ActionButtonsField,
            {
              width: 128,
              source: "id",
              children: [
                /* @__PURE__ */ jsx(
                  CreateEditButtonDialog,
                  {
                    component: CreateEditCustomCategory,
                    onSuccess: () => {
                      var _a2;
                      return (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                    }
                  }
                ),
                /* @__PURE__ */ jsx(
                  DeleteDialog,
                  {
                    resource: apiResources.customCategories,
                    title: "tesseract.customCategories.dialog.delete.title",
                    message: "tesseract.customCategories.dialog.delete.body",
                    onSuccess: () => {
                      var _a2;
                      (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                      registerAction("Custom Category Delete");
                    }
                  }
                )
              ]
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Dialog,
      {
        open: createOpen,
        maxWidth: false,
        onClose: () => setCreateOpen(false),
        disableEnforceFocus: true,
        children: /* @__PURE__ */ jsx(
          CreateEditCustomCategory,
          {
            title: "tesseract.customCategories.dialog.dialogTitle",
            create: true,
            onSuccess: () => {
              var _a2;
              return (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
            },
            onClose: () => setCreateOpen(false),
            id: void 0
          }
        )
      }
    )
  ] });
};
var CustomCategories_default = CustomCategories;
export {
  CustomCategories,
  CustomCategories_default as default
};
