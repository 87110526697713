var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import apiResources from "../../../apiResources";
import { ConnectedForm, DialogBody, SelectArrayInput, useGlobalParam } from "@cuda-react/core";
import { useTranslation } from "react-i18next";
const AddPointOfEntryContent = ({ onClose, onSuccess }) => {
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx(
    DialogBody,
    {
      title: "tesseract.endpoint.pointsOfEntry.create.title",
      onClose,
      form: true,
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          resource: apiResources.pointsOfEntryCreate,
          create: true,
          onSubmitSuccess: () => {
            onSuccess == null ? void 0 : onSuccess();
            onClose == null ? void 0 : onClose();
          },
          onCancel: onClose,
          formatRequestData: (data) => __spreadProps(__spreadValues({}, data), {
            virtualWanId
          }),
          initialValues: { cgfs: [], sites: [], gateways: [] },
          validate: (data) => {
            if (data.cgfs.length < 1 && data.sites.length < 1 && data.gateways.length < 1) {
              return {
                cgfs: translate("tesseract.endpoint.pointsOfEntry.create.validateSelectOne"),
                gateways: translate("tesseract.endpoint.pointsOfEntry.create.validateSelectOne"),
                sites: translate("tesseract.endpoint.pointsOfEntry.create.validateSelectOne")
              };
            }
            return {};
          },
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsx(
              SelectArrayInput,
              {
                source: "cgfs",
                label: "tesseract.endpoint.pointsOfEntry.create.firewalls",
                description: "tesseract.endpoint.pointsOfEntry.create.descriptions.firewalls",
                resource: apiResources.firewallNames,
                optionValue: "uuid"
              }
            ),
            /* @__PURE__ */ jsx(
              SelectArrayInput,
              {
                source: "gateways",
                label: "tesseract.endpoint.pointsOfEntry.create.gateways",
                description: "tesseract.endpoint.pointsOfEntry.create.descriptions.gateways",
                resource: apiResources.gatewayNames,
                optionValue: "uuid"
              }
            ),
            /* @__PURE__ */ jsx(
              SelectArrayInput,
              {
                source: "sites",
                label: "tesseract.endpoint.pointsOfEntry.create.sites",
                description: "tesseract.endpoint.pointsOfEntry.create.descriptions.sites",
                resource: apiResources.siteNames,
                options: { params: { filter: { modelSeries: "T" } } },
                optionValue: "uuid"
              }
            )
          ]
        }
      )
    }
  );
};
var AddPointOfEntryContent_default = AddPointOfEntryContent;
export {
  AddPointOfEntryContent_default as default
};
