import en from "./en";
import fr from "./fr";
import ja from "./ja";
import de from "./de";
var StratosReportingMessages_default = {
  de,
  en,
  fr,
  ja
};
export {
  StratosReportingMessages_default as default
};
