import { jsx } from "react/jsx-runtime";
import { Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = createStyles({
  typography: {
    height: "fit-content",
    padding: "0.5rem 1rem",
    textAlign: "center"
  }
});
const useStyles = makeOverrideableStyles("NoDataMessage", styles);
const NoDataMessage = (props) => {
  const classes = useStyles(props);
  const { message = "cuda.cards.noData" } = props;
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx(Typography, { className: classes.typography, children: translate(typeof message === "string" ? message : message == null ? void 0 : message.message) });
};
var NoDataMessage_default = NoDataMessage;
export {
  NoDataMessage_default as default,
  styles
};
