import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { SearchableSelectInput } from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
const useStyles = makeStyles((theme) => ({
  haMessage: {
    color: theme.palette.warning.main
  },
  secondaryMessage: {
    marginTop: theme.spacing(1)
  }
}));
const SelectSiteStep = ({ choices }) => {
  var _a;
  const classes = useStyles();
  const [translate] = useTranslation();
  const siteId = useWatch({ name: "siteId" });
  const selectedSite = choices.find((site) => site.id === siteId);
  const siteIsHa = (((_a = selectedSite == null ? void 0 : selectedSite.serials) == null ? void 0 : _a.length) || 0) > 1;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.appliances.dialog.siteSelection.mainMessage") }),
    /* @__PURE__ */ jsx(Typography, { className: classes.secondaryMessage, children: translate("tesseract.appliances.dialog.siteSelection.secondaryMessage") }),
    /* @__PURE__ */ jsx(
      SearchableSelectInput,
      {
        isRequired: true,
        choices,
        optionValue: "id",
        label: "tesseract.appliances.dialog.siteSelection.site",
        source: "siteId",
        clearOnFocus: true
      }
    ),
    selectedSite && !siteIsHa ? /* @__PURE__ */ jsx(Typography, { className: classes.haMessage, children: translate("tesseract.appliances.dialog.siteSelection.haWarning") }) : null
  ] });
};
var SelectSiteStep_default = SelectSiteStep;
export {
  SelectSiteStep_default as default
};
