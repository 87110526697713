import { jsx, jsxs } from "react/jsx-runtime";
import { ConnectedForm, TextInput } from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";
const i18nScope = "tesseract.identity.userDirectories.form";
const UserDirectoryEntraIDForm = ({
  onClose,
  create,
  directory,
  id
}) => /* @__PURE__ */ jsxs(
  ConnectedForm,
  {
    create,
    resource: apiResources.directories,
    params: create ? void 0 : { id },
    onSubmitSuccess: () => {
      onClose(true);
    },
    onCancel: () => onClose(false),
    initialValues: directory,
    children: [
      /* @__PURE__ */ jsx(
        TextInput,
        {
          source: "displayName",
          label: `${i18nScope}.displayName`,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextInput,
        {
          source: "options.tenantId",
          label: `${i18nScope}.tenantId`,
          isRequired: true
        }
      )
    ]
  }
);
export {
  UserDirectoryEntraIDForm
};
