import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { useDeepCompareEffect, usePrevious } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import WanTableInput from "../../inputs/wanTableInput/WanTableInput";
import { Typography } from "@barracuda-internal/bds-core";
import { useFormContext, useWatch } from "react-hook-form";
const useStyles = makeStyles((theme) => ({
  tableWarning: {
    color: theme.palette.warning.main,
    marginLeft: theme.spacing(2)
  }
}));
const WansTab = ({ applianceType, portsData, series }) => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const { setValue } = useFormContext();
  const [serials = [], wans = []] = useWatch({ name: ["serials", "wans"] });
  const tableRowDimmed = (row) => {
    if (!(row.port || row.doublePort) || row.mode === "wwan") {
      return false;
    }
    let applianceAvailable = [];
    portsData.forEach((applianceInterface) => {
      if (row.mode === "expressRoute" ? row.doublePort.some((port) => port === applianceInterface.port) : applianceInterface.port === row.port) {
        applianceAvailable.push(applianceInterface.available);
      }
    });
    return !applianceAvailable.length || !applianceAvailable.every((available) => available);
  };
  const wanHasPortDisabled = wans.some(tableRowDimmed);
  const oldSerials = usePrevious(serials);
  useDeepCompareEffect(() => {
    const removedSerial = oldSerials == null ? void 0 : oldSerials.filter((serial) => !serials.includes(serial));
    const newSerial = oldSerials && serials && serials.filter((serial) => !oldSerials.includes(serial));
    wans.forEach((wan, wanIndex) => {
      if (wan.mode === "wwan" && removedSerial && newSerial) {
        if (removedSerial.length === newSerial.length) {
          wans[wanIndex].pins.forEach((pin, pinIndex) => {
            pin.serial === removedSerial[0] && setValue(`wans[${wanIndex}].pins[${pinIndex}].serial`, newSerial[0]);
          });
        } else if ((oldSerials == null ? void 0 : oldSerials.length) === 2 && newSerial.length < removedSerial.length) {
          wans[wanIndex].pins.forEach((pin, pinIndex) => {
            pin.serial === removedSerial[0] && wans[wanIndex].pins.splice(pinIndex, 1);
          });
        } else if ((oldSerials == null ? void 0 : oldSerials.length) === 1 && newSerial.length > removedSerial.length && newSerial.length === 1) {
          setValue(`wans[${wanIndex}].pins[1].serial`, newSerial[0]);
        }
      }
    });
  }, [serials]);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      WanTableInput,
      {
        serials,
        tableRowDimmed,
        portsData,
        applianceType,
        series
      }
    ),
    wanHasPortDisabled && /* @__PURE__ */ jsx(Typography, { className: classes.tableWarning, children: translate("tesseract.appliances.settings.wan.portWarning") })
  ] });
};
var WansTab_default = WansTab;
export {
  WansTab_default as default
};
