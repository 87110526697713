var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { parse } from "query-string";
import {
  AUTH_CHANGE_ACCOUNT,
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  authLogout,
  DIALOG_NOTIFICATION,
  ERROR_NOTIFICATION,
  notificationHide,
  notificationShowToast,
  redirect as urlRedirect,
  saveLoginUrl,
  saveOrigin as saveOriginAction,
  TOAST_NOTIFICATION,
  updateGlobalParams
} from "../actions";
import { call, put, takeEvery, takeLeading } from "redux-saga/effects";
const getOrigin = () => {
  const reduxRouterPath = window.location.href.substring(window.location.href.indexOf("#") + 1);
  if (reduxRouterPath.startsWith("/login")) {
    const existingOrigin = parse(reduxRouterPath.substring(reduxRouterPath.indexOf("?") + 1));
    return existingOrigin && existingOrigin.origin || "/";
  }
  return reduxRouterPath;
};
const parseException = (exception) => {
  if (typeof exception === "object") {
    const _a = exception, { message, redirect, loginUrl } = _a, newParams = __objRest(_a, ["message", "redirect", "loginUrl"]);
    return [
      message || "cuda.auth.signInError",
      redirect,
      loginUrl,
      Object.keys(newParams).length > 0 ? newParams : void 0
    ];
  }
  return [
    typeof exception === "string" && exception || "cuda.auth.signInError"
  ];
};
const parseResponse = (authClientResponse) => {
  let redirectUrl, loginUrl;
  const _a = authClientResponse || {}, { redirect, loginUrl: loginUrlToSave } = _a, newParams = __objRest(_a, ["redirect", "loginUrl"]);
  if (redirect) {
    redirectUrl = redirect;
  }
  loginUrl = loginUrlToSave;
  return [
    redirectUrl,
    loginUrl,
    Object.keys(newParams).length > 0 ? newParams : void 0
  ];
};
function* resolveFailure(errorMessage, redirect, loginUrl, newParams, saveOrigin) {
  if (newParams) {
    yield put(updateGlobalParams(newParams));
  }
  if (saveOrigin) {
    yield put(saveOriginAction(getOrigin()));
  }
  if (errorMessage) {
    yield put(notificationShowToast(errorMessage, ERROR_NOTIFICATION));
  }
  if (loginUrl || loginUrl === null) {
    yield put(saveLoginUrl(loginUrl));
  }
  if (redirect) {
    yield put(urlRedirect(redirect));
  }
}
function* handleLogin(authClient, { type, params, payload, error, globalParams }) {
  try {
    const authResponse = yield call(authClient, type, error || params || payload, globalParams);
    const [redirect, loginUrl, newParams] = parseResponse(authResponse);
    yield put(notificationHide(DIALOG_NOTIFICATION));
    yield put(notificationHide(TOAST_NOTIFICATION));
    if (newParams) {
      yield put(updateGlobalParams(newParams));
    }
    yield put(saveLoginUrl(loginUrl));
    yield put(saveOriginAction(null));
    yield put(urlRedirect(redirect || "/"));
  } catch (exception) {
    const [message, redirect, loginUrl, newParams] = parseException(exception);
    yield call(resolveFailure, message, redirect, loginUrl || null, newParams, false);
  }
}
function* handleLogout(authClient, { type, params, payload, error, globalParams }) {
  try {
    const authResponse = yield call(authClient, type, error || params || payload, globalParams);
    const [redirect, loginUrl, newParams] = parseResponse(authResponse);
    if (newParams) {
      yield put(updateGlobalParams(newParams));
    }
    if (loginUrl) {
      yield put(saveLoginUrl(loginUrl));
    }
    yield put(saveOriginAction(getOrigin()));
    yield put(urlRedirect(redirect || "/login"));
  } catch (exception) {
    const [, redirect, loginUrl, newParams] = parseException(exception);
    yield call(resolveFailure, void 0, redirect, loginUrl, newParams, true);
  }
}
function* handleChangeAccount(authClient, { type, params, payload, error, globalParams }) {
  try {
    const authResponse = yield call(authClient, type, error || params || payload, globalParams);
    if (!authResponse) {
      return;
    }
    yield put(notificationHide(DIALOG_NOTIFICATION));
    yield put(notificationHide(TOAST_NOTIFICATION));
    const [redirect, loginUrl, newParams] = parseResponse(authResponse);
    if (newParams) {
      yield put(updateGlobalParams(newParams));
    }
    if (loginUrl) {
      yield put(saveLoginUrl(loginUrl));
    }
    if (redirect) {
      yield put(saveOriginAction("/"));
      yield put(saveOriginAction(getOrigin()));
      yield put(urlRedirect(redirect || "/"));
    }
  } catch (exception) {
    const [message, redirect, loginUrl, newParams] = parseException(exception);
    yield call(resolveFailure, message, redirect, loginUrl, newParams, false);
  }
}
function* handleError(authClient, { params, payload, error, globalParams }) {
  try {
    const authResponse = yield call(authClient, AUTH_ERROR, error || params || payload, globalParams);
    const [redirect, loginUrl, newParams] = parseResponse(authResponse);
    if (newParams) {
      yield put(updateGlobalParams(newParams));
    }
    if (loginUrl) {
      yield put(saveLoginUrl(loginUrl));
    }
    if (redirect) {
      yield put(saveOriginAction(getOrigin()));
      yield put(urlRedirect(redirect));
    }
  } catch (exception) {
    const [, redirect, loginUrl, newParams] = parseException(exception);
    yield call(resolveFailure, void 0, redirect, loginUrl, newParams, true);
    if (!redirect) {
      yield put(authLogout());
    }
  }
}
function* handleCheck(authClient, { type, params, payload, error, globalParams }) {
  try {
    const authResponse = yield call(authClient, type, error || params || payload, globalParams);
    const [redirect, loginUrl, newParams] = parseResponse(authResponse);
    if (newParams) {
      yield put(updateGlobalParams(newParams));
    }
    if (loginUrl) {
      yield put(saveLoginUrl(loginUrl));
    }
    if (redirect) {
      yield put(saveOriginAction(getOrigin()));
      yield put(urlRedirect(redirect));
    }
  } catch (exception) {
    const [, redirect, loginUrl, newParams] = parseException(exception);
    yield call(resolveFailure, void 0, redirect || "/login", loginUrl, newParams, true);
  }
}
var AuthSagas_default = (authClient) => function* authSagas() {
  if (authClient) {
    yield takeEvery(AUTH_CHECK, handleCheck, authClient);
    yield takeEvery([AUTH_ERROR], handleError, authClient);
    yield takeLeading(AUTH_LOGOUT, handleLogout, authClient);
    yield takeLeading(AUTH_CHANGE_ACCOUNT, handleChangeAccount, authClient);
    yield takeLeading(AUTH_LOGIN, handleLogin, authClient);
  }
};
export {
  AuthSagas_default as default,
  handleChangeAccount,
  handleCheck,
  handleError,
  handleLogin,
  handleLogout,
  resolveFailure
};
