import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import {
  ActionButtonsField,
  ChipArrayField,
  CrudTypes,
  DeleteDialog,
  getArrayDataContent,
  getDataContent,
  InputLabel,
  PropGateway,
  Select,
  StatusIconField,
  TablePage,
  TextField,
  useCrudFetch,
  useCrudProps,
  useCrudSubscription,
  useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SingleSiteIcon from "@mui/icons-material/StoreMallDirectory";
import { SiteIcon } from "@cuda-react/icons";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import CreateEditApplicationControl from "./CreateEditApplicationControl";
import { useSourceDestinationField } from "../../../../../hooks/sourceAndDestinationHooks";
import {
  formatSourceDestinationFilters,
  formatUserGroupInTableData
} from "../../../../../components/sourceAndDestination/tableUtils";
import SourceFilter from "../../../../../components/sourceAndDestination/SourceFilter";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import { settingsIcons } from "../../../../../components/iconMapping";
import { get, merge } from "lodash";
import GroupingField from "../GroupingField";
import apiResources from "../../../../../apiResources";
const ApplicationControl = ({ type, actions }) => {
  var _a;
  const isGateway = type === "gateway";
  const tableRefreshRef = useRef();
  const actionChoices = getArrayDataContent((_a = useCrudProps(apiResources.applicationControlActions)[0]) == null ? void 0 : _a.data);
  const [defaultActionData, defaultActionLoading, refreshDefaultAction] = useCrudSubscription(CrudTypes.GET, apiResources.applicationControlDefault, { filter: { type } });
  const [, defaultActionUpdating, updateDefaultAction] = useCrudFetch(CrudTypes.UPDATE, apiResources.applicationControlDefault);
  const defaultAction = getDataContent(defaultActionData);
  const defaultLoading = typeof defaultAction !== "string" || defaultActionLoading || defaultActionUpdating;
  const handleUpdateDefaultAction = (action) => {
    if (action && !defaultLoading && action !== defaultAction) {
      registerAction("Default Update", { action });
      return updateDefaultAction({ action, filter: { type } }).then(refreshDefaultAction);
    }
  };
  const [, , callApplicationControl] = useCrudFetch(CrudTypes.CREATE, apiResources.applicationControl, {});
  const handleOrderClick = (id, direction, data) => callApplicationControl({
    id,
    changeOrder: direction,
    data
  }).then(tableRefreshRef.current);
  const generateChipArrayProps = useSourceDestinationField();
  const renderSiteGroupName = generateChipArrayProps("scope")({ data: { scope: { type: isGateway ? "gateway" : "site" } } }).render;
  const registerAction = useMixpanel(isGateway ? "Gateway ACL" : "Site ACL");
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      title: "tesseract.securityAndAccess.pageTitle",
      subtitle: [
        "tesseract.securityAndAccess.network",
        isGateway ? "tesseract.securityAndAccess.gateway" : "tesseract.securityAndAccess.site"
      ],
      titleActions: /* @__PURE__ */ jsx(
        CreateEditButtonDialog,
        {
          create: true,
          component: CreateEditApplicationControl,
          type,
          onSuccess: tableRefreshRef.current
        },
        "create"
      ),
      authenticated: true,
      resource: apiResources.applicationControlAll,
      exportResource: { resource: apiResources.applicationControlExport, filename: "applicationControl" },
      actions: [
        /* @__PURE__ */ jsx(
          InputLabel,
          {
            label: "tesseract.security.applicationControl.settings.defaultAction",
            minimised: true,
            left: true,
            children: /* @__PURE__ */ jsx(
              Select,
              {
                id: "defaultAction",
                source: "defaultAction",
                value: !defaultLoading ? defaultAction : "",
                onChange: (eventOrValue) => eventOrValue.target ? handleUpdateDefaultAction(eventOrValue.target.value) : handleUpdateDefaultAction(eventOrValue),
                onBlur: () => {
                },
                disabled: !!defaultLoading,
                meta: {},
                choices: actionChoices
              },
              "defaultAction"
            )
          },
          "defaultAction"
        )
      ],
      params: (data) => merge({}, formatSourceDestinationFilters(data), { filter: { type } }),
      formatData: formatUserGroupInTableData,
      minCellWidth: 128,
      groupBy: "scope",
      groupField: /* @__PURE__ */ jsx(
        GroupingField,
        {
          createEditComponent: CreateEditApplicationControl,
          render: renderSiteGroupName,
          type,
          onSuccess: tableRefreshRef.current
        }
      ),
      defaultItemsPerPage: 5e3,
      flat: true,
      refreshRef: tableRefreshRef,
      children: [
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            left: true,
            label: "tesseract.security.applicationControl.settings.order",
            width: 96,
            source: "id",
            filter: "selectarray",
            filterProps: {
              label: "tesseract.security.applicationControl.settings.scope",
              source: "scope",
              resource: isGateway ? apiResources.gateways : apiResources.siteNetworks,
              optionValue: "uuid",
              selectAll: true,
              selectAllValue: isGateway ? "allGateways" : "allSites",
              selectAllText: `tesseract.security.applicationControl.settings.${isGateway ? "allGateways" : "allSites"}`,
              iconMap: { allSites: /* @__PURE__ */ jsx(SiteIcon, {}) },
              icon: /* @__PURE__ */ jsx(SingleSiteIcon, {})
            },
            children: [
              /* @__PURE__ */ jsx(
                TextField,
                {
                  source: "order"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowUpwardIcon,
                {
                  onClick: (event, data) => handleOrderClick(data.id, "increase", data),
                  disabled: (data) => get(data, "order", 0) < 2,
                  id: "cuda-icon-up"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowDownwardIcon,
                {
                  onClick: (event, data) => handleOrderClick(data.id, "decrease", data),
                  disabled: (data) => get(data, "groupTotal", 0) - get(data, "order", 0) < 1,
                  id: "cuda-icon-down"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.security.applicationControl.settings.name",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.security.applicationControl.settings.description",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "action",
            text: (action) => (actionChoices.find((item) => item.key === action) || {}).name,
            label: "tesseract.security.applicationControl.settings.action",
            iconMap: settingsIcons,
            filter: "select",
            filterProps: {
              choices: actionChoices
            },
            width: 140
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "source",
            label: "tesseract.security.applicationControl.settings.source",
            editProps: generateChipArrayProps("source"),
            filter: "custom",
            filterProps: {
              component: SourceFilter,
              filterSources: [isGateway && "application", "onPremGateway", "network", "site", "userOrGroup"]
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "source" })
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "destination",
            label: "tesseract.security.applicationControl.settings.destination",
            editProps: generateChipArrayProps("destination"),
            filter: "custom",
            filterProps: {
              component: DestinationFilter,
              filterSources: ["network", "application", "onPremGatewayNetworks", "site"]
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "destination" })
          }
        ),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            width: 96,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(
                CreateEditButtonDialog,
                {
                  component: CreateEditApplicationControl,
                  type,
                  onSuccess: tableRefreshRef.current
                }
              ),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource: apiResources.applicationControl,
                  title: "tesseract.security.applicationControl.settings.delete.title",
                  message: "tesseract.security.applicationControl.settings.delete.body",
                  onSuccess: () => {
                    var _a2;
                    (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                    registerAction("Delete");
                  }
                }
              )
            ]
          }
        )
      ]
    }
  );
};
var ApplicationControl_default = ApplicationControl;
export {
  ApplicationControl_default as default
};
