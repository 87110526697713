import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { DialogBody, Form, HiddenInput, SelectInput } from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  resetToDefaultMessage: {
    textAlign: "center",
    color: theme.palette.reporting.fontColor,
    margin: theme.spacing(2)
  }
});
const useStyles = makeStyles(styles);
const ResetDashboardDialog = ({
  dashboard,
  addUpdateDashboard,
  onClose,
  templates,
  oneTabDashboard
}) => {
  var _a;
  const [translate] = useTranslation();
  const classes = useStyles();
  return /* @__PURE__ */ jsx(
    DialogBody,
    {
      title: "stratosReporting.dashboardPage.reset",
      onClose,
      form: true,
      children: /* @__PURE__ */ jsxs(
        Form,
        {
          initialValues: dashboard,
          onCancel: onClose,
          save: (newDashboard) => addUpdateDashboard(newDashboard, dashboard == null ? void 0 : dashboard.name, true).then((result) => {
            onClose == null ? void 0 : onClose();
            return result;
          }),
          children: [
            oneTabDashboard ? /* @__PURE__ */ jsx(Typography, { className: classes.resetToDefaultMessage, children: translate("stratosReporting.dashboardPage.resetToDefault") }) : null,
            oneTabDashboard ? /* @__PURE__ */ jsx(
              HiddenInput,
              {
                source: "template",
                hiddenValue: templates && ((_a = templates[0]) == null ? void 0 : _a.name)
              }
            ) : /* @__PURE__ */ jsxs(Fragment, { children: [
              /* @__PURE__ */ jsx(Typography, { className: classes.resetToDefaultMessage, children: translate("stratosReporting.dashboardPage.resetToTemplateDescription") }),
              /* @__PURE__ */ jsx(
                SelectInput,
                {
                  source: "template",
                  label: "stratosReporting.dashboardPage.resetToTemplate",
                  choices: templates,
                  defaultChoice: dashboard == null ? void 0 : dashboard.template,
                  optionValue: "name"
                }
              )
            ] })
          ]
        }
      )
    }
  );
};
export {
  ResetDashboardDialog
};
