var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { get, merge } from "lodash";
import { clearLinkedSeries, isLinkSeries } from "./clearLinkedSeries";
import { drawLinkedLines } from "./drawLinkedLines";
import { handlePointClick } from "./handlePointClick";
import mapDataWorld from "@highcharts/map-collection/custom/world-highres.geo.json";
const generateOptions = (chart, theme, legendEnabled, seriesData, setTooltip, tooltipRef, translate, chartOverrides) => {
  const series = [
    {
      mapData: mapDataWorld,
      name: "Countries",
      nullColor: "#D4D4D4",
      borderColor: theme.palette.background.paper,
      enableMouseTracking: false,
      showInLegend: false,
      type: "map"
    }
  ];
  seriesData.forEach((dataPoint) => {
    series.push({
      animation: false,
      cursor: "pointer",
      type: "mappoint",
      dataLabels: {
        enabled: true,
        format: "{point.capital}"
      },
      name: dataPoint.label,
      data: dataPoint.data,
      color: dataPoint.color,
      borderColor: theme.palette.background.paper,
      zIndex: dataPoint.zIndex,
      marker: {
        symbol: "circle",
        lineWidth: 1,
        radius: 6,
        states: {
          select: {
            fillColor: dataPoint.color
          }
        }
      },
      point: {
        events: {
          click: (event) => {
            const clickedTarget = "point" in event ? event : event.target;
            const clickedPoint = clickedTarget.point;
            if (!clickedPoint.graphic) {
              return false;
            }
            return handlePointClick(chart, clickedPoint, setTooltip, translate, theme);
          }
        }
      },
      allowPointSelect: true,
      states: {
        hover: {
          enabled: false
        },
        inactive: {
          enabled: false
        }
      },
      originalData: dataPoint.data
    });
  });
  return merge({
    chart: {
      animation: false,
      events: {
        click: () => {
          if (chart.current && chart.current.series.some((series2) => isLinkSeries(series2) && get(series2, "visible"))) {
            clearLinkedSeries(chart, setTooltip);
          }
        },
        render: (event) => {
          if (tooltipRef && tooltipRef.current) {
            const currentXaxis = get(event, "target.xAxis[0]");
            const currentYaxis = get(event, "target.yAxis[0]");
            const tooltipPoint = tooltipRef.current.point;
            const tooltipElement = tooltipRef.current.element;
            if (!tooltipPoint) {
              setTooltip(null);
            } else if (tooltipPoint.x > currentXaxis.max || tooltipPoint.x < currentXaxis.min || tooltipPoint.y > currentYaxis.max || tooltipPoint.y < currentYaxis.min) {
              tooltipElement && setTooltip({ element: null });
            } else {
              const pointSeries = get(event, "target.series", []).find((series2) => series2.userOptions.id === "linked-points-source-point-series");
              const tooltipElement2 = get(pointSeries, "points[0].graphic.element", null);
              setTooltip({ element: tooltipElement2 });
            }
          }
          drawLinkedLines(chart, theme, true);
        }
      },
      height: "56.25%"
    },
    plotOptions: {
      mappoint: {
        cluster: {
          enabled: true,
          animation: false,
          drillToCluster: false
        }
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: legendEnabled,
      itemStyle: __spreadProps(__spreadValues({}, theme.typography.body1), {
        fontSize: "12px",
        fontWeight: "normal"
      })
    },
    mapNavigation: {
      enabled: true
    },
    series,
    title: {
      //@ts-ignore TODO: Is this even needed to be set if its null?
      text: null
    },
    tooltip: {
      enabled: false
    }
  }, chartOverrides || {});
};
export {
  generateOptions
};
