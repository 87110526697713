const getStatusColorFromTheme = (status, theme) => theme.palette[getStatusColor(status)].main;
const getStatusColor = (status) => {
  switch (status) {
    case true:
    case "online":
    case "completed":
      return "success";
    case "connecting":
    case "degraded":
      return "warning";
    case false:
    case "offline":
    case "failed":
      return "error";
    case "pending":
    default:
      return "info";
  }
};
export {
  getStatusColorFromTheme
};
