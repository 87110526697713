var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ColumnSelectorDialog from "../../components/table/ColumnSelectorDialog/ColumnSelectorDialog";
import { debounce, isEqual } from "lodash";
import { useDeepCompareCallback, useDeepCompareMemo, usePersistedState } from "../UtilHooks";
const generateColumnsFromChildren = (childArray, translate) => childArray.map(({
  props: {
    alwaysVisible,
    field,
    title,
    hideByDefault,
    editColumnLabel,
    width,
    minResizableWidth
  }
}, index) => ({
  childIndex: index,
  orderIndex: index,
  show: !hideByDefault,
  title: translate(editColumnLabel || title || " "),
  alwaysVisible,
  field,
  width,
  minResizableWidth
}));
const useDataTablePersonalisedColumns = (children, props) => {
  const { editColumns, resizable, reorderable, minCellWidth, tableName } = props;
  const [persistentSavedColumns, setPersistentSavedColumns] = usePersistedState("cudaPersonalisedTableColumns-" + tableName);
  const [translate] = useTranslation();
  const [localSavedColumns, setLocalSavedColumns] = useState();
  const saveColumns = (columns) => tableName ? setPersistentSavedColumns(columns) : setLocalSavedColumns(columns);
  const savedColumns = (tableName ? persistentSavedColumns : localSavedColumns) || generateColumnsFromChildren(children, translate);
  useEffect(() => {
    if (persistentSavedColumns) {
      const inputColumns = generateColumnsFromChildren(children, translate);
      const comparableColumnFields = ({ childIndex, title, alwaysVisible, field }) => ({
        childIndex,
        title,
        alwaysVisible,
        field
      });
      if (!isEqual(inputColumns.map(comparableColumnFields), persistentSavedColumns.map(comparableColumnFields))) {
        const unresolvedColumns = [...persistentSavedColumns];
        const assignedOrderIndexes = [];
        const resolvedColumns = inputColumns.map((column) => {
          const existingColumnIndex = unresolvedColumns.findIndex(({ field }) => column.field === field);
          let resolvedColumn = column;
          if (existingColumnIndex >= 0) {
            const existingColumn = unresolvedColumns[existingColumnIndex];
            unresolvedColumns.splice(existingColumnIndex, 1);
            resolvedColumn = __spreadProps(__spreadValues({}, column), {
              orderIndex: existingColumn.orderIndex,
              show: column.alwaysVisible || existingColumn.show,
              width: existingColumn.width || column.width
            });
          }
          while (assignedOrderIndexes.includes(resolvedColumn.orderIndex)) {
            resolvedColumn.orderIndex++;
          }
          assignedOrderIndexes.push(resolvedColumn.orderIndex);
          return resolvedColumn;
        });
        saveColumns(resolvedColumns);
      }
    }
  }, []);
  const actions = /* @__PURE__ */ jsx(
    ColumnSelectorDialog,
    {
      columns: savedColumns,
      defaultColumns: generateColumnsFromChildren(children, translate),
      onSave: (columns) => saveColumns(columns.map((column) => __spreadProps(__spreadValues({}, column), {
        // If the column is being added, we need to ensure it has a width set, otherwise it can appear with 0 width
        width: column.width || column.minResizableWidth || minCellWidth
      })))
    }
  );
  const onColumnReorder = useDeepCompareCallback((event) => {
    const updatedColumns = savedColumns.map((column) => {
      var _a, _b;
      return __spreadProps(__spreadValues({}, column), {
        orderIndex: (_b = (_a = event.columns.find((col) => col.field === column.field)) == null ? void 0 : _a.orderIndex) != null ? _b : column.orderIndex
      });
    });
    saveColumns(updatedColumns);
  }, [savedColumns]);
  const onColumnResize = useDeepCompareCallback(debounce((event) => {
    const resizedColumn = event.columns.sort((column1, column2) => {
      var _a, _b;
      return ((_a = column1.orderIndex) != null ? _a : 0) - ((_b = column2.orderIndex) != null ? _b : 0);
    })[event.index];
    const columnMinWidth = resizedColumn.minResizableWidth || 0;
    const newWidth = event.oldWidth < columnMinWidth ? columnMinWidth : event.oldWidth;
    const updatedColumns = savedColumns.map((column) => __spreadProps(__spreadValues({}, column), {
      width: column.orderIndex === resizedColumn.orderIndex ? newWidth : column.width
    }));
    saveColumns(updatedColumns);
  }, 250), [minCellWidth, savedColumns]);
  const displayedColumns = savedColumns && savedColumns.filter((column) => column.show).map((column) => column.field) || [];
  const newChildren = useDeepCompareMemo(() => children.filter((child) => child.props.alwaysVisible || displayedColumns.includes(child.props.field)).map((child) => {
    if (reorderable || resizable || editColumns) {
      const column = savedColumns && savedColumns.find((col) => col.field === child.props.field);
      return React.cloneElement(child, {
        width: column ? column.width : child.props.width || child.props.minResizableWidth || minCellWidth,
        orderIndex: column == null ? void 0 : column.orderIndex,
        minResizableWidth: child.props.minResizableWidth || minCellWidth
      });
    }
    return child;
  }), [children, displayedColumns, reorderable, resizable, editColumns, savedColumns, minCellWidth]);
  return {
    actions: editColumns ? actions : void 0,
    children: newChildren,
    onColumnReorder: reorderable ? onColumnReorder : void 0,
    onColumnResize: resizable ? onColumnResize : void 0,
    headerCellRender: useCallback((cell, cellProps, additionalRender) => {
      const newCell = React.Children.map(cell, (cellChild) => React.isValidElement(cellChild) ? React.cloneElement(cellChild, { draggable: false }) : cellChild);
      return additionalRender ? additionalRender(newCell, cellProps) : newCell;
    }, []),
    resizable,
    reorderable
  };
};
export {
  useDataTablePersonalisedColumns
};
