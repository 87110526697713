import { jsx, jsxs } from "react/jsx-runtime";
import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material";
const UsersIcon = (props) => {
  var _a, _b;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(SvgIcon, { viewBox: "2 2 21 21", style: { width: (_a = props.size) != null ? _a : 50, height: (_b = props.size) != null ? _b : 50 }, children: /* @__PURE__ */ jsxs("g", { transform: "matrix(1,0,0,1,-5.42152,-5.995)", children: [
    /* @__PURE__ */ jsx("rect", { x: "5.422", y: "5.995", width: "24.026", height: "24.026", style: { fill: "none" } }),
    /* @__PURE__ */ jsx("g", { transform: "matrix(1,0,0,1,7.2213,8.64218)", children: /* @__PURE__ */ jsx(
      "path",
      {
        d: "M14.833,4.746C14.833,2.194 12.766,0.127 10.213,0.127C7.661,0.127 5.594,2.194 5.594,4.746C5.594,7.299 7.661,9.366 10.213,9.366C12.766,9.366 14.833,7.299 14.833,4.746ZM12.523,4.746C12.523,6.017 11.484,7.056 10.213,7.056C8.943,7.056 7.903,6.017 7.903,4.746C7.903,3.476 8.943,2.436 10.213,2.436C11.484,2.436 12.523,3.476 12.523,4.746ZM0.974,16.295L0.974,18.605L19.452,18.605L19.452,16.295C19.452,13.223 13.297,11.676 10.213,11.676C7.13,11.676 0.974,13.223 0.974,16.295ZM3.284,16.295C3.515,15.475 7.095,13.985 10.213,13.985C13.32,13.985 16.889,15.464 17.143,16.295L3.284,16.295Z",
        style: { fill: theme.palette.reporting.fontColor, fillRule: "nonzero" }
      }
    ) })
  ] }) });
};
export {
  UsersIcon
};
