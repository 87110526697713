var de_default = {
  translation: {
    tesseract: {
      productName: "Barracuda SecureEdge",
      dashboard: {
        menuTitle: "DASHBOARD",
        pageTitle: "Dashboard",
        emptyTemplate: "Leer",
        filters: {
          timeframe: "Zeitfenster",
          appliance: "Edge Service/Site",
          noAppliances: "Keine",
          timeframes: {
            lastDay: "Letzte 24 Stunden",
            lastMonth: "Letzter Monat",
            lastWeek: "Letzte Woche",
            lastQuarter: "Letzte 3 Monate"
          }
        },
        cards: {
          gateway: {
            cardTitle: "Edge Service Status",
            online: "Erreichbar",
            pending: "Ausstehend",
            degraded: "Eingeschr\xE4nkt",
            offline: "Nicht erreichbar"
          },
          iotStatusCard: {
            healthy: "Erreichbar",
            pending: "Ausstehend",
            connecting: "Verbindung herstellen",
            degraded: "Eingeschr\xE4nkt",
            offline: "Nicht verbunden",
            name: "Name",
            gateway: "Gateway",
            aggregateState: "Status",
            type: "Model"
          },
          status: {
            site: {
              tabTitle: "Site Status"
            },
            iot: {
              tabTitle: "IoT Status"
            },
            applianceConfiguration: {
              tabTitle: "Status der Ger\xE4tekonfiguration",
              completed: "Abgeschlossen",
              connecting: "Verbindung herstellen",
              failed: "Fehlgeschlagen",
              pending: "Ausstehend"
            }
          },
          vpn: {
            locations: {
              tabTitle: "Karte",
              gateways: {
                label: "Edge Services",
                linkedSites: "Verbundene Aussenstellen/IoT",
                fields: {
                  name: "Name",
                  sites: "Verbundene Aussenstellen",
                  iot: "Verbundene IoT Ger\xE4te",
                  vwan: "Arbeitsumgebung",
                  hub: "Hub",
                  region: "Region",
                  size: "Gr\xF6\xDFe"
                }
              },
              sites: {
                label: "Sites",
                linkedHubs: "Verbundener Edge Service",
                fields: {
                  name: "Name",
                  location: "Standort",
                  gateway: "Edge Service",
                  providers: "Anzahl der Dienstleister"
                }
              },
              iot: {
                label: "IoT",
                linkedHubs: "Verbundener Edge Service",
                fields: {
                  name: "Name",
                  location: "Standort",
                  gateway: "Edge Service",
                  providers: "Anzahl der Dienstleister"
                }
              },
              standaloneSites: {
                label: "Eigenst\xE4ndige Site"
              }
            },
            status: {
              tabTitle: "Verbindungsstatus",
              ok: "OK",
              warning: "Warnung",
              error: "Fehler",
              online: "Verbunden",
              pending: "Ausstehend",
              connecting: "Verbinden...",
              degraded: "Eingeschr\xE4nkt",
              offline: "Nicht verbunden"
            },
            offline: {
              tabTitle: "Nicht verbunden",
              gatewayName: "Edge Service Name",
              gatewayStatus: "Edge Service Status",
              siteName: "Aussenstelle / IoT Name",
              siteStatus: "Aussenstelle / IoT Status",
              siteLocation: "Aussenstelle / IoT Standort",
              noData: "Keine Offline-Tunnel gefunden."
            }
          },
          recentEvents: {
            adminTabTitle: "Stattgefundene administrative Ereignisse",
            systemTabTitle: "Stattgefundene Systemereignisse",
            latest: "Neueste {{length}}"
          },
          categories: {
            status: "Status"
          }
        },
        status: "Status",
        security: "Security",
        webFilter: "Web-Filter",
        ztnaDashboard: "Zero Trust Access",
        ztnaUserDashboard: "Benutzer Dashboard"
      },
      network: {
        pageTitle: "Infrastruktur",
        vmActivation: "VM Aktivierung",
        vmActivationMessage: "Bitte registrieren Sie Ihre Rechnungsdaten f\xFCr die automatische VM-Aktivierung in allen Arbeitsbereichen.",
        gateways: "Edge Services",
        sites: "Aussenstellen",
        iot: {
          menuTitle: "IoT",
          noData: {
            iconHeader: "Beginnen Sie mit dem Hinzuf\xFCgen eines IoT-Ger\xE4ts!",
            labelButton: "Hinzuf\xFCgen eines IoT-Ger\xE4ts",
            textHeader: "Was ist ein IoT-Ger\xE4t?",
            text: "Ein IoT-Ger\xE4t bezeichnet einen Internet-of-Things-Standort, der durch eine Barracuda Secure Connector Appliance verbunden (und gesch\xFCtzt) ist. Secure Connector Appliances k\xF6nnen als Hardware oder virtuelle Appliance bereitgestellt werden - je nachdem, was Ihren Anforderungen am besten entspricht."
          }
        },
        settings: "Einstellungen",
        firewalls: "CloudGen Firewall",
        sdwanConnector: {
          menuTitle: "CONNECTOR",
          pageTitle: "Connectors",
          table: {
            status: "Status",
            name: "Name",
            gateway: "Edge Service",
            hostnames: "Hostnamen",
            internalResources: "Internal Ressourcen",
            provisioningDate: "Bereitstellungsdatum",
            servers: "Server",
            ellipsisMenu: {
              downloadLinux: "Den Linux-Agenten herunterladen",
              downloadWindows: "Den Windows-Agenten herunterladen",
              token: "Registrierungs-Token generieren"
            }
          },
          delete: {
            body: "Sind Sie sicher, dass Sie diesen Connector l\xF6schen m\xF6chten?",
            title: "Connector l\xF6schen {{name}}"
          },
          dialog: {
            add: "Hinzuf\xFCgen",
            menuItemDisabled: "Connector ben\xF6tigt einen Edge Service",
            basic: {
              name: "Name",
              sectionTitle: "Grundlegende Informationen",
              tabTitle: "Grundlagen"
            },
            create: {
              dialogTitle: "Connector hinzuf\xFCgen"
            },
            edit: {
              dialogTitle: "Connector bearbeiten"
            },
            poolNetwork: {
              sectionTitle: "Geroutete IP-Adressen",
              descriptionMessage: "Bevor Sie Ihren ersten Connector hinzuf\xFCgen k\xF6nnen, m\xFCssen Sie einen Client-Netzwerkbereich festlegen. Das Client-Netzwerk wird verwendet, um den Ressourcen hinter den Connectors IPs zuzuweisen.",
              network: "Client-Netzwerk"
            },
            general: {
              sectionTitle: "Allgemein",
              name: "Name",
              description: "Beschreibung",
              appliance: "Edge Service"
            },
            servers: {
              sectionTitle: "Server",
              serviceName: "Server",
              itemName: "Server",
              hostname: "Hostname",
              internalIp: "Interne IP Adresse",
              category: "Kategorie"
            },
            summary: {
              sectionTitle: "Zusammenfassung",
              name: "Zusammenfassung der Konfiguration f\xFCr SD-WAN Connector {{connector}}",
              connectorName: "Name",
              serviceName: "Service Name",
              hostname: "Hostname"
            },
            completed: {
              sectionTitle: 'Connector "{{connector}}" erfolgreich erzeugt',
              sectionTitleNextSteps: "N\xE4chste Schritte",
              sectionMessage: "Bitte f\xFChren Sie die folgenden Schritte aus, um den SD-WAN Connector zu benutzen",
              sectionSteps: "Herunterladen und Installieren des SD-WAN Connector",
              sectionSteps2: "Den Registrierungs-Token eintragen:",
              sectionSteps3: "Pr\xFCfen Sie, ob die Verbindung hergestellt ist",
              downloadButtonLinux: "Herunterladen f\xFCr Linux",
              downloadButtonWindows: "Herunterladen f\xFCr Windows"
            },
            description: {
              poolNetwork: {
                network: "Cient-Netzwerkbereich in CIDR-Notation"
              },
              general: {
                sectionTitle: "Grundlegend",
                name: "Eindeutiger Name f\xFCr Ihren Connector.",
                description: "Kurze Beschreibung Ihres Connectors.",
                appliance: "W\xE4hlen Sie einen Edge Service oder Appliance aus, die Sie konfigurieren m\xF6chten."
              },
              servers: {
                sectionTitle: "Server",
                serviceName: "Ein eindeutiger Name f\xFCr den Server.",
                hostname: "Der Hostname, der sich auf den Server bezieht.",
                internalIp: "Die interne IP des Servers.",
                category: "Kategorie"
              }
            },
            token: {
              dialogTitle: "Registrierungs-Token generieren",
              message: 'Registrierungs-Token generieren f\xFCr "{{sec}}"',
              subLabel: "Connector Token"
            }
          },
          noData: {
            iconHeader: "Beginnen Sie mit dem Hinzuf\xFCgen eines SD-WAN Connectors!",
            labelButton: "SD-WAN Connector hinzuf\xFCgen",
            textHeader: "Was ist ein SD-WAN Connector?",
            text: "Die Bereitstellung von Anwendungen f\xFCr Ihre Mitarbeiter kann eine schwierige Aufgabe sein.<0 /> Mit einem SD-WAN-Connector k\xF6nnen Sie Windows- oder Linux- und Cloud-Dienste und -Server direkt in Ihr Netzwerk einbinden und sie Ihren Mitarbeitern zur Verf\xFCgung stellen, ohne dass ein dediziertes Standortger\xE4t erforderlich ist."
          }
        },
        customCategories: "Benutzerdefinierte Kategorien"
      },
      endpoint: {
        pageTitle: "Zero Trust Access",
        devices: "Ger\xE4te",
        settings: "Einstellungen",
        zeroTrustAccess: "Zero Trust Access",
        zeroTrustSettingsTitle: "Eintstellungen",
        pointToSite: "Point to Site",
        users: "Angemeldete Benutzer",
        appCatalog: {
          pageTitle: "App-Katalog",
          textHeader: "Was ist der App-Katalog?",
          text: "Mit dem App-Katalog k\xF6nnen Sie die Anwendungen festlegen, die in der SecureEdge Access Agent-App f\xFCr den schnellen Zugriff angezeigt werden.<0 />Diese Schnellzugriffsfunktion funktioniert sowohl f\xFCr externe als auch f\xFCr interne Anwendungen.<br/><br/>Diese Funktion wird in K\xFCrze verf\xFCgbar sein.",
          startText: "Mit dem App-Katalog k\xF6nnen Sie die Apps definieren, die in der SecureEdge Access Agent-App f\xFCr den Schnellzugriff angezeigt werden.<0 />Diese Schnellzugriffsfunktion funktioniert sowohl f\xFCr externe als auch f\xFCr interne Apps.",
          startTextHeader: "Beginnen Sie mit dem Hinzuf\xFCgen einer App!",
          buttonText: "App hinzuf\xFCgen",
          iconText: "Symbol",
          name: "Name",
          application: "App",
          launchURL: "URL starten",
          visibility: "Visibilit\xE4t",
          create: {
            title: "App hinzuf\xFCgen",
            displayName: "Namen anzeigen",
            displayIcon: "Symbol anzeigen",
            associatedApplication: "Zugeh\xF6rige App",
            users: "Benutzer",
            groups: "Gruppen",
            descriptions: {
              icon: "App-Symbol hochladen.",
              iconInfo: "Unterst\xFCtzte Dateiformate: JPEG, PNG, GIF und SVG.",
              name: "Eindeutiger Name f\xFCr die App.",
              application: "Zugeh\xF6rige App ausw\xE4hlen",
              launchUrl: "Start-URL angeben",
              visibility: "Sichtbarkeit des Links ausw\xE4hlen",
              users: "W\xE4hlen Sie die Benutzer aus, denen der Zugriff auf die ausgew\xE4hlten Apps gestattet werden soll. Wenn keine Benutzer oder Gruppen ausgew\xE4hlt sind, sind die ausgew\xE4hlten Apps f\xFCr alle zug\xE4nglich.",
              groups: "W\xE4hlen Sie die Gruppen aus, denen der Zugriff auf die ausgew\xE4hlten Apps gestattet werden soll. Wenn keine Benutzer oder Gruppen ausgew\xE4hlt sind, sind die ausgew\xE4hlten Apps f\xFCr alle zug\xE4nglich."
            }
          },
          edit: {
            title: "App bearbeiten"
          },
          delete: {
            body: "Sind Sie sicher, dass Sie diese App l\xF6schen m\xF6chten?",
            title: "L\xF6schen {{name}}"
          },
          validate: {
            userGroupRequired: "Mindestens ein Benutzer oder eine Gruppe erforderlich",
            tenantIdRequired: "Benutzer und Gruppen nicht verf\xFCgbar. Sie m\xFCssen zun\xE4chst ein Benutzer-Verzeichnis auf der Seite Identit\xE4t > Einstellungen hinzuf\xFCgen."
          }
        },
        enrollEmailDialog: {
          addEmail: "Benutzer oder eMail hinzuf\xFCgen",
          body: "Einrichtung",
          customMessage: "Einladungs-Mail",
          defaultContent: 'Griass Di, \n\nSie wurden von Ihrer Organisation zur sicheren Fernzugriffsfunktion mit Barracuda SecureEdge hinzugef\xFCgt. \n\nUm Zugang zu erhalten, folgen Sie bitte diesen Anweisungen: \n\n1. Gehen Sie auf {{link}}\n2. Laden Sie den Barracuda VPN-Client herunter und installieren Sie ihn.\n3. Dr\xFCcken Sie auf "Enroll now" um den Vorgang abzuschlie\xDFen.\n\nSie sind nun in der Lage, eine Verbindung zu Barracuda SecureEdge von Ihrem Windows- oder macOS-Ger\xE4t aus herzustellen.',
          defaultSubject: "Sie haben sich bei Barracuda SecureEdge angemeldet",
          zeroTrustSubject: "Einladung zur Registrierung Ihres Barracuda SecureEdge Access Agent",
          emailTitle: "Benutzer registrieren",
          resendEmailTitle: "Einladung zur erneuten Einschreibung senden",
          noHtml: "die folgenden Sonderzeichen sind nicht zul\xE4ssig: <>",
          recipients: "An",
          users: "Benutzer",
          send: "Senden",
          subject: "Betreff",
          enrollMessage: "W\xE4hlen Sie den Benutzer oder die Gruppe aus, der/die f\xFCr SecureEdge Access registriert werden sollen. Benutzer m\xFCssen sich beim ersten Start des Agents registrieren. Sie k\xF6nnen diesen Fortschritt auf der Seite Zugang > Benutzer verfolgen.<0>Sie haben noch {{count}} Seats frei.</0>",
          sendMessage: "Folgende Nachricht wird an die ausgew\xE4hlten Benutzer und Gruppen gesendet. Sind Sie sicher, dass Sie Ihre Einladung senden m\xF6chten?",
          successHeader_plural: "Ihre Einladung zur Registrierung wird an {{count}} Benutzer gesendet!",
          successHeader: "Ihre Einladung zur Registrierung wird an {{count}} Benutzer gesendet!",
          successMessage_plural: "Sie k\xF6nnen den Anmeldestatus f\xFCr jeden Benutzer in der Benutzertabelle \xFCberpr\xFCfen.",
          successMessage: "Sie k\xF6nnen den Anmeldestatus des Benutzers in der Benutzertabelle \xFCberpr\xFCfen",
          emailTemplate: {
            hello: "Hallo {{username}}!",
            message: "Sie sind eingeladen, Ihren Barracuda SecureEdge Access Agent zu registrieren. Dieser Agent sichert Ihren Zugang zu Unternehmensressourcen und sch\xFCtzt Ihre Ger\xE4te vor Cyber-Bedrohungen.",
            clickStart: 'Bitte klicken Sie auf"Start" um den Selbstregistrierungsprozess zu starten.',
            start: "Start",
            copyLink: "Alternativ k\xF6nnen Sie auch den unten stehenden Link direkt in Ihren SecureEdge Access Agent kopieren, um sich anzumelden.",
            exampleLink: "https://se.barracudanetworks.com/zerotrust/enroll/?uuid=22fc1190-8d5a-4b8e-803f-fd8819408305&endpoint=https://se.barracudanetworks.com"
          },
          addUsers: "Benutzer hinzuf\xFCgen",
          addGroups: "Gruppen hinzuf\xFCgen",
          userNotificationSectionTitle: "Benutzerbenachrichtigung (Optional)",
          enrollmentEmail: {
            label: "Einladungs-E-Mail",
            enabled: "Ja - beinhaltet Agent Download Link",
            disabled: "Nein - unbeaufsichtigte Installation"
          },
          groups: "Gruppen",
          enroll: "Registrierung",
          enrollMessageOld: "W\xE4hlen Sie die Benutzer oder Gruppen aus, die f\xFCr SecureEdge Access eingeladen werden sollen. Der Benutzer muss sich beim ersten Start des Agents anmelden. Sie k\xF6nnen diesen Fortschritt auf der Seite Access > Benutzer verfolgen.",
          enrollMessage_plural: "W\xE4hlen Sie die Benutzer oder Gruppen aus, die f\xFCr SecureEdge Access registriert werden sollen. Benutzer muss sich beim ersten Start des Agents registrieren. Sie k\xF6nnen diesen Fortschritt auf der Seite Zugang > Benutzer verfolgen.<0>Sie haben noch {{count}} Seats frei.</0>",
          resolvedUsers: "Die von Ihnen ausgew\xE4hlten Benutzer/Gruppen haben mehr Benutzer als Seats zur Verf\xFCgung stehen. Bitte verwenden Sie das nachstehende Men\xFC, um die Benutzer zu entfernen, die Sie jetzt nicht registrieren m\xF6chten, oder gehen Sie zur\xFCck, um andere Benutzer/Gruppen auszuw\xE4hlen.",
          successHeaderOld: "Ihre Einladung zur Registrierung wird an '{{users}}' gesendet!",
          successHeaderOld_plural: "Ihre Einladung zur Registrierung wird an {{count}} Benutzer gesendet!",
          noEmailSuccessHeader: "{{count}} Benutzer werden registriert!",
          noEmailSuccessHeader_plural: "{{count}} Benutzer werden registriert!",
          validate: {
            userGroupRequired: "Mindestens ein Benutzer oder eine Gruppe erforderlich",
            userRequired: "Mindestens ein Benutzer erforderlich",
            seatLimit: "Registrieren Sie {{currentCount}} Benutzer ({{maxCount}} Seats verf\xFCgbar)"
          },
          reenrollMessage: "Die Einladung zur Registrierung erneut an den ausgew\xE4hlten Benutzer senden.",
          noSeats: "Es sind keine Seats mehr frei.",
          seats: "Sie haben noch {{count}} Seat.",
          seats_plural: "Sie haben noch {{count}} Seats.",
          unSuccessHeaderAllUsersAlreadyEnrolled: "Der gew\xE4hlte Benutzer ist bereits registriert.",
          unSuccessHeaderAllUsersAlreadyEnrolled_plural: "Alle gew\xE4hlten Benutzer sind bereits registriert.",
          alreadyEnrolledUsersUnsuccessful_emailplural: "Es werden keine E-Mails verschickt.",
          alreadyEnrolledUsers: "{{totalCount}} der gew\xE4hlten Benutzer ist bereits registriert.",
          alreadyEnrolledUsers_workspace: "{{totalCount}} der gew\xE4hlten Benutzer ist bereits ({{unenrollableCount}} \xFCber ein anderes Konto bzw. Workspace) registriert.",
          alreadyEnrolledUsers_email: "{{totalCount}} der gew\xE4hlten Benutzer ist bereits registriert. An diesen Benutzer werden keine E-Mails verschickt.",
          alreadyEnrolledUsers_emailworkspace: "{{totalCount}} der gew\xE4hlten Benutzer ist bereits ({{unenrollableCount}} \xFCber ein anderes Konto bzw. Workspace) registriert. An diesen Benutzer werden keine E-Mails verschickt.",
          alreadyEnrolledUsers_plural: "{{totalCount}} der gew\xE4hlten Benutzer sind bereits registriert.",
          alreadyEnrolledUsers_workspaceplural: "{{totalCount}} der gew\xE4hlten Benutzer sind bereits ({{unenrollableCount}} \xFCber ein anderes Konto bzw. Workspace) registriert.",
          alreadyEnrolledUsers_emailplural: "{{totalCount}} der gew\xE4hlten Benutzer sind bereits registriert. An diese Benutzer werden keine E-Mails verschickt.",
          alreadyEnrolledUsers_emailworkspaceplural: "{{totalCount}} der gew\xE4hlten Benutzer sind bereits ({{unenrollableCount}} \xFCber ein anderes Konto bzw. Workspace) registriert. An diese Benutzer werden keine E-Mails verschickt."
        },
        remoteAccessPolicies: {
          delete: {
            title: "Zero Trust-Zugangsrichtlinie l\xF6schen {{name}}",
            body: "Sind Sie sicher, dass Sie diese Zero Trust-Zugangsrichtlinie l\xF6schen m\xF6chten?"
          },
          order: "Rangfolge",
          name: "Name",
          description: "Beschreibung",
          groupsGrantedAccess: "Gruppen die Zugriff haben",
          resources: "Resourcen",
          devicePosture: "Ger\xE4tezustand",
          usersGrantedAccess: "Benutzer, denen Zugang gew\xE4hrt wurde",
          zeroTrustAccess: "Zero Trust Access",
          policy: "Richtlinie",
          create: "Zero Trust-Zugangsrichtlinie erstellen",
          edit: "Zero Trust-Zugangsrichtlinie bearbeiten",
          success: "Erfolg",
          successMessage: "Eine neue Zero Trust-Zugangsrichtlinie wurde erfolgreich erstellt.",
          createButton: "Richtlinie hinzuf\xFCgen",
          deviceAttributes: "Ger\xE4tezustand",
          resourcesSection: {
            title: "Ressourcen",
            internalResource: "Interne Ressources (custom apps)",
            publicResource: "\xD6ffentliche Ressourcen (SaaS)",
            resourceTypeLabel: "Ressourcentyp",
            resourceTypeDescription: 'W\xE4hlen Sie "Intern" f\xFCr Ressourcen, die nur intern \xFCber einen Einstiegspunkt zug\xE4nglich sind, und "\xD6ffentlich" f\xFCr \xF6ffentlich zug\xE4ngliche Ressourcen.',
            resourcesLabel: "Ressourcen"
          },
          applications: "Anwendungen",
          accessCriteriaSection: "Zugangskriterien",
          users: "Benutzer",
          groups: "Gruppen",
          securityInspection: "Sicherheits\xFCberpr\xFCfung",
          screenLock: "Bildschirmsperre aktiviert",
          firewall: "Firewall aktiviert",
          jailbroken: "Blockieren von Jailbreak-Ger\xE4ten",
          encryption: "Festplattenverschl\xFCsselung erzwingen",
          appUpdates: "Aktualisierungen des Barracuda SecureEdge Access Agent",
          minimumVersion: "Minimale Version",
          platform: "Betriebssystem",
          osUpdates: "Betriebssystem updates",
          antivirus: "Antivirus aktiviert",
          descriptions: {
            name: "Eindeutiger Name f\xFCr die Richtlinie.",
            description: "Kurze Beschreibung der Richtlinie.",
            resourceType: "Konfigurieren Sie entweder benutzerdefinierte Anwendungen oder externe Anwendungen von Drittanbietern.",
            applications: "Die Anwendung(en), f\xFCr die diese Richtlinie gilt.",
            users: "W\xE4hlen Sie die Benutzer aus, denen der Zugriff auf die ausgew\xE4hlte(n) Anwendung(en) gestattet werden soll. Wenn keine Benutzer oder Gruppen ausgew\xE4hlt sind, sind diese f\xFCr alle zug\xE4nglich.",
            groups: "W\xE4hlen Sie Gruppen aus, denen der Zugriff auf die ausgew\xE4hlte(n) Anwendung(en) gestattet werden soll. Wenn keine Benutzer oder Gruppen ausgew\xE4hlt sind, sind diese f\xFCr alle zug\xE4nglich.",
            securityPosture: "W\xE4hlen Sie, ob die konfigurierten Ger\xE4teattribute erzwungen werden sollen oder ob Verst\xF6\xDFe nur protokolliert werden sollen.",
            securityInspection: "Wenn diese Option aktiviert ist, wird der Datenverkehr zu den ausgew\xE4hlten Anwendungen durch einen Tunnel an den SecureEdge Service geleitet um eine eingehendere Sicherheitspr\xFCfung zu erm\xF6glichen. Die Sicherheitsrichtlinien f\xFCr Einstiegspunkt werden angewendet. Ist die Option deaktiviert, wird der Datenverkehr normal \xFCber das Internet geleitet.",
            screenLock: "Benutzer m\xFCssen eine Bildschirmsperre f\xFCr ihre Ger\xE4te einrichten. Unterst\xFCtzte Plattformen: Android, iOS.",
            firewall: "Die Benutzer m\xFCssen eine Firewall auf ihren Ger\xE4ten konfiguriert und aktiviert haben. Unterst\xFCtzte Plattformen: Mac OS, Windows.",
            jailbroken: "Verhindern Sie, dass kompromittierte Ger\xE4te Zugriff auf Ressourcen erhalten. Unterst\xFCtzte Plattformen: Android, iOS.",
            encryption: "Benutzer m\xFCssen die Festplattenverschl\xFCsselung aktivert haben. Unterst\xFCtzte Plattformen: Android, iOS, Mac OS, Windows.",
            appUpdates: "Erfordert von Benutzern, den Barracuda SecureEdge Access Agent auf die neueste Version aktualisiert zu haben. Unterst\xFCtzte Plattformen: Android, iOS, Linux, Mac OS, Windows.",
            osUpdates: "Anforderung das Betriebssystem (OS) auf die eingestellte Mindestversion aktualisiert zu haben. Unterst\xFCtzte Plattformen: Android, iOS, Mac OS, Windows.",
            antivirus: "Benutzer m\xFCssen einen Antiviren-Dienst auf ihrem Ger\xE4t konfiguriert haben. Plattformen: Windows."
          },
          validatePlatform: "Betriebssystem bereits ausgew\xE4hlt",
          validateDeviceCriteria: "mindestens ein Sicherheitsmerkmal muss aktiviert sein",
          lastMileOptimization: "Optimierung der letzten Meile",
          noData: {
            iconHeader: "Beginnen Sie mit dem Hinzuf\xFCgen einer Zero Trust-Zugangsrichtlinie!",
            labelButton: "Zero Trust-Zugangsrichtlinie hinzuf\xFCgen",
            textHeader: "Was ist eine Zero Trust-Zugangsrichtlinie?",
            text: "In diesem Abschnitt k\xF6nnen Sie die Anforderungen definieren, die Endger\xE4te erf\xFCllen m\xFCssen, um Zugang zum Netzwerk zu erhalten.<0 /> Sie k\xF6nnen zum Beispiel verlangen, dass auf den Ger\xE4ten die neuesten Betriebssystem-Updates installiert und die integrierten Sicherheitsfunktionen aktiviert sind, oder Sie k\xF6nnen sogar gehackte ( jailbreak) Ger\xE4te sperren."
          }
        },
        pointsOfEntry: {
          title: "Zugangspunkte",
          table: {
            name: "Name",
            type: "Typ",
            size: "GR\xD6\xDFE",
            location: "Ort",
            publicIp: "\xD6ffentliche IP Adressen",
            noData: "Es wurden keine Zugangspunkte konfiguriert. F\xFCr die Verwendung von Zero Trust Access muss mindestens ein Zugangspunkt konfiguriert sein."
          },
          createButton: "Zugangspunkte hinzuf\xFCgen",
          create: {
            title: "Zugangspunkte hinzuf\xFCgen",
            configurationType: "Typ",
            site: "Aussenstelle",
            gateway: "Edge Service",
            firewall: "Firewall",
            ipAddress: "\xD6ffentliche IP Adressen",
            sites: "Aussenstellen",
            gateways: "Edge Services",
            firewalls: "Firewalls",
            descriptions: {
              sites: "Aussenstellen, die als Zugangspunkte hinzugef\xFCgt werden k\xF6nnen.",
              gateways: "Edge Services, die als Zugangspunkte hinzugef\xFCgt werden k\xF6nnen..",
              firewalls: "Firewalls, die als Zugangspunkte hinzugef\xFCgt werden k\xF6nnen."
            },
            validateSelectOne: "Es muss mindestens ein Firewall, Aussenstelle oder Edge Service ausgew\xE4hlt werden."
          },
          edit: {
            title: "Zugangspunkte \xE4ndern",
            name: "Name",
            ipAddress: "\xD6ffentliche IP Adressen",
            descriptions: {
              ipAddress: "Die \xF6ffentlichen IP-Adresse, \xFCber die diese Firewall erreichbar ist."
            }
          },
          delete: {
            title: "Zugangspunkt {{name}} l\xF6schen",
            body: "Sind Sie sicher, dass Sie diesen Zugangspunkt l\xF6schen m\xF6chten?"
          },
          noData: {
            iconHeader: "Beginnen Sie mit dem Hinzuf\xFCgen eines Zugangspunktes!",
            labelButton: "Zugangspunkt hinzuf\xFCgen",
            textHeader: "Was ist ein Zugangspunkt?",
            text: "Ein Point of Entry (PoE) dient als Verbindungspunkt f\xFCr Ihre SecureEdge Access Agents.<0 /> Die Agents w\xE4hlen automatisch den n\xE4chstgelegenen verf\xFCgbaren PoE, um eine optimale Servicequalit\xE4t zu gew\xE4hrleisten."
          }
        },
        zeroTrustSettings: {
          agentWebFiltering: "Web-Filterung mit SecureEdge Access Agent",
          dnsSuffix: "DNS-Zusatz",
          downloadCertificateHelpText: "Laden Sie dieses Stammzertifikat herunter und verteilen Sie es an alle Clients, um einen parallelen Betrieb mit CloudGen Firewall-Client-to-Site-Setups und SecureEdge Access Agent zu gew\xE4hrleisten.",
          downloadCertificateLabel: "Zertifikat herunterladen",
          networkSectionTitle: "Netzwerkkonfiguration des Agenten",
          clientNetworkDescription: "Das Client-Netzwerk wird verwendet, um den Clients, die sich \xFCber SecureEdge Access Agent verbinden, IPs zuzuweisen. Es ist in Pools unterteilt, die dann auf die einzelnen Zugangspunkte verteilt werden.",
          manualConfigurationEnabled: "Manuelle Konfiguration",
          clientNetworkRange: "Kunden-Netzwerk",
          clientNetworkSize: "Dieses Client-Netzwerk bietet mindestens {{connections}} Client-Verbindungen pro Zugangspunkt.",
          clientNetworkPoolSize: "Pool Bitmaske",
          validatePoolSize: "muss kleiner sein als das Client-Netzwerk",
          enforce: "Erzwingen",
          disable: "Deaktivieren",
          globalZtnaSettings: "Access-Einstellungen"
        },
        ztnaUsers: {
          dashboardTabTitle: "Dashboard",
          settingsTabTitle: "Einstellungen",
          userNotFound: "Benutzer nicht gefunden"
        }
      },
      securityAndAccess: {
        pageTitle: "Security Richtlinie",
        apps: "Anwendungen und Ressourcen",
        network: "Netzwerk ACL",
        gateway: "Edge Service ACL",
        site: "Aussenstellen ACL",
        sdwan: "SD-WAN",
        categories: "App-Kategorien",
        explicit: "Benutzerdefinierte Kategorien",
        security: "Security",
        urlFilter: "Richtlinien",
        webFilter: "Web-Filter",
        malware: "Advanced Threat Protection",
        ssl: "SSL Inspektion",
        ips: "IPS",
        ingressNatRules: "Zugangs NAT",
        webFilterSettings: "Einstellungen",
        webMonitorPolicies: "Web-Monitoring",
        settings: "Einstellungen",
        customResponse: "Benutzerdefinierte Antworten"
      },
      subscription: {
        pam: {
          menuTitle: "Subscriptions",
          pageTitle: "Profil",
          pageSubTitle: "Subscriptions",
          table: {
            subscription: "Subscriptions",
            serial: "Lizenz-Seriennummer",
            amount: "Menge",
            startDate: "Startdatum",
            expirationDate: "Ablaufdatum",
            noData: {
              iconHeader: "Beginnen Sie mit dem Hinzuf\xFCgen einer Subscription!",
              textHeader: "Wie erhalte ich eine Subscription-Lizenz?",
              text: "Subscriptions erm\xF6glichen zus\xE4tzliche Funktionalit\xE4ten in Ihrer SecureEdge-Installation.<0/> Wenn Sie zum Beispiel zus\xE4tzliche SecureEdge Access Seats f\xFCr Ihre Remote-Mitarbeiter hinzuf\xFCgen m\xF6chten, verwenden Sie einfach diese Schnittstelle, um die erforderliche Anzahl von Seats zu Ihrer Bereitstellung hinzuzuf\xFCgen.<0/> Um die beste Subscription f\xFCr Ihre Bed\xFCrfnisse zu erhalten, wenden Sie sich einfach an Barracuda Sales (barracuda.com/contact-sales)."
            },
            information: "Information",
            value: "{{value}}",
            value_34: "{{value}} Benutzer",
            value_24: "{{value}} Mbps",
            msp: "MSP in Rechnung gestellt"
          }
        }
      },
      appliances: {
        dashboard: {
          deployment: {
            status: {
              title: "Status der Bereitstellung {{serial}}",
              status: "Status",
              action: "Aktion",
              message: "Meldung",
              registered: "Registriert",
              lastSeen: "Zuletzt gesehen",
              completed: "Fertiggestellt",
              localIp: "Lokale IP-Adresse",
              remoteIp: "\xD6ffentliche IP-Adresse"
            },
            events: {
              title: "Ereignisse bei der Bereitstellung",
              state: "Stand",
              status: "Status",
              created: "Datum",
              siteDeploying: "Bereitstellung in Arbeit"
            },
            location: {
              title: "Bereitstellungsort"
            }
          },
          details: {
            servicesCardTitle: "Services",
            interfaces: {
              title: "Schnittstellen",
              status: "Status",
              name: "Name",
              speed: "Geschwindigkeit",
              duplex: "Duplex",
              errors: "Fehler",
              state: {
                ok: "OK",
                warning: "Warnung",
                error: "Fehler",
                unknown: "Unbekannt",
                loading: " "
              }
            },
            boxResources: {
              title: "Ressourcen",
              cpuLoad: "CPU",
              cpuLoadError: "Die CPU hat einen Fehler erkannt. Bitte starten Sie die Appliance neu. Wenn dieser Fehler anh\xE4lt, wenden Sie sich an den Barracuda Support.",
              memory: "Speicher",
              diskInformation: "Plattenplatz",
              diskInformationError: "Auf einer der Festplattenpartitionen wurde ein Fehler erkannt. Bitte starten Sie die Appliance neu. Wenn dieser Fehler anh\xE4lt, wenden Sie sich an den Barracuda Support."
            },
            vpnTunnels: {
              title: "VPN-Tunnel",
              instanceId: "Instanz-Serie",
              status: "Status",
              name: "Name",
              peer: "Peer",
              local: "Lokal",
              type: "Typ",
              state: {
                UP: "Up",
                DOWN: "Down",
                INACTIVE: "Inaktiv",
                FAIL: "Down - Fehlgeschlagen",
                STANDBY: "Down - Standby",
                "DOWN (PASSIVE)": "Down - Passiv",
                "DOWN (FAIL)": "Down - Fehlgeschlagen",
                "DOWN (STANDBY)": "Down - Standby",
                "DOWN (DISABLED)": "Deaktiviert",
                PENDING: "Ausstehend"
              }
            }
          },
          firmware: {
            details: {
              tabTitle: "Firmware",
              version: "Version",
              hotfixes: "Installierte Hotfixe",
              window: "N\xE4chstes Update",
              nextUpdate: "Zwischen {{from}} und {{to}}"
            },
            hotfixes: {
              tabTitle: "Hotfixe",
              status: "Status",
              name: "Name"
            },
            updates: {
              tabTitle: "Konfigurations-Updates",
              lastUpdate: "Letztes Update",
              lastSeen: "Zuletzt gesehen",
              remoteIp: "Remote IP"
            },
            state: {
              error: "Fehler",
              ok: "OK",
              unknown: "Unbekannt",
              warning: "Ausstehend",
              loading: " "
            }
          },
          siteStatus: {
            tabTitle: "Verbundene Standorte"
          },
          iotStatus: {
            tabTitle: "Verbundene IoT-Ger\xE4te"
          },
          summary: {
            siteStatus: {
              title: "Status",
              model: "Modell",
              serials: "Seriennummer(n)",
              serial: "Seriennummer",
              release: "Release"
            },
            licenses: {
              title: "Lizenzen",
              type: "Typ",
              state: "Stand",
              status: "Status",
              expirationDate: "Ablaufdatum",
              serial: "Seriennummer",
              noData: "N/A"
            },
            gateway: {
              tabTitle: "Edge Service",
              noGateway: "F\xFCr diesen Standort wurde kein Edge Service konfiguriert",
              name: "Name",
              region: "Region",
              hubName: "Hub",
              vwan: "Cloud VWAN",
              size: "Gr\xF6\xDFe",
              publicIP: "\xD6ffentl. IP Adresse",
              publicIPs: "\xD6ffentl. IP Adresse(n)",
              serial: "Seriennummer(n)",
              instanceNotFound: "Instance not found"
            },
            status: {
              tabTitle: "Status",
              hub: "Hub",
              region: "Region",
              vwan: "Cloud VWAN",
              connectedSites: "Verbundene Standorte"
            }
          },
          tunnelLocations: {
            title: "Karte",
            gateways: {
              label: "Edge Services",
              fields: {
                name: "Name",
                sites: "Verbundene Standorte",
                iot: "Verbundene IoT Ger\xE4te",
                vwan: "Arbeitsbereich",
                hub: "Hub",
                region: "Region",
                size: "Gr\xF6\xDFe"
              }
            },
            iot: {
              label: "IoT"
            },
            sites: {
              label: "Standorte",
              fields: {
                name: "Name",
                location: "Standort",
                gateway: "Edge Service",
                providers: "Anzahl der Provider "
              }
            },
            standaloneSites: {
              title: "Karte",
              label: "Eigenst\xE4ndige Site"
            }
          }
        },
        dialog: {
          createType: {
            sectionTitle: "Edge Service Typ",
            new: "Neuer Private Edge Service",
            promote: "Bestehendes Site Device zu Private Edge Service upgraden"
          },
          siteSelection: {
            sectionTitle: "Auswahl des Standortes",
            mainMessage: "W\xE4hlen Sie den Standort aus, den Sie zu einem Private Edge Service machen m\xF6chten.",
            secondaryMessage: "Hinweis: Alle zugeh\xF6rigen Richtlinien (z. B. ACLs, URL-Filter usw.) werden bei dem Vorgang gel\xF6scht.",
            haWarning: "Dieser Standort ist nicht hochverf\xFCgbar. Es wird empfohlen, f\xFCr Private Edge Services hochverf\xFCgbare Umgebungen zu verwenden.",
            site: "Standort"
          },
          basic: {
            gateway: "Edge Service",
            upstream_gateway: "Vorgelagert Edge Service",
            noGateway: "(Keiner)",
            name: "Name",
            sectionTitle: "Generelle Informationen",
            tabTitle: "Generell",
            rootPassword: "Root Passwort",
            rootPasswordConfirmation: "Bitte best\xE4tigen Sie das Root Passwort",
            descriptions: {
              gateway_site: "Der Barracuda SecureEdge Edge Service, mit dem sich dieser Standort verbinden soll.",
              gateway_gateway: "Der Barracuda SecureEdge Edge Service, mit dem sich dieser Edge Service verbinden soll.",
              name_site: "Ein eindeutiger Name f\xFCr den Standort.",
              name_gateway: "Ein eindeutiger Name f\xFCr den Edge Service.",
              rootPassword_site: "Das Passwort des Root-Benutzers f\xFCr den Standort.",
              rootPassword_gateway: "Das Passwort des Root-Benutzers f\xFCr den Edge Service",
              upstream_gateway: "Der Barracuda SecureEdge Edge Service, mit dem sich dieser Edge Service upstream verbinden soll."
            }
          },
          serials: {
            gatewayMessage: "W\xE4hlen Sie die Edge Service-Appliances aus, die Sie konfigurieren m\xF6chten. Wir empfehlen, zwei Appliances auszuw\xE4hlen, um einen Hochverf\xFCgbarkeits-Cluster zu erstellen.",
            TVTSiteMessage: "W\xE4hlen Sie die Site Appliances aus, die Sie konfigurieren m\xF6chten. Sie k\xF6nnen entweder eine einzelne Appliance oder zwei Appliances ausw\xE4hlen, wenn Sie einen Hochverf\xFCgbarkeits-Cluster einrichten m\xF6chten.",
            SCSiteMessage: "W\xE4hlen Sie die Secure Connector-Appliance aus, die Sie konfigurieren m\xF6chten.",
            haMessage: "<1>Achtung:</1> Beide Appliances in einem HA-Cluster m\xFCssen das gleiche Modell und die gleiche Firmware-Version haben.",
            sectionTitle_site: "Standort Appliances",
            sectionTitle_gateway: "Edge Service Appliances",
            addAppliance: "F\xFCgen Sie fehlende Ger\xE4te nach Seriennummer und linking code oder Lizen-Token hinzu",
            linkingCode: "Code/Token",
            table: {
              serial: "Seriennummer",
              model: "Modell",
              location: "Standort",
              orderId: "Auftragsnummer",
              orderDate: "Auftragsdatum"
            }
          },
          wans: {
            sectionTitle: "WANs",
            message: "Ihr Ger\xE4t hat gesamt {{portsAvailable}} Ports f\xFCr WAN and LAN Aufteilung.",
            message2_site: "Wie viele WAN-Verbindungen m\xF6chten Sie f\xFCr diesen Standort konfigurieren?",
            message2_gateway: "Wie viele WAN-Verbindungen m\xF6chten Sie f\xFCr diesen Edge-Service konfigurieren?",
            size: "Anzahl WAN Verbindungen",
            setup: "WAN Verbindung konfigurieren {{step}}",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtual LAN ID",
            type: "Typ",
            providerPinning: "Provider Pinning",
            address: "IP Addresse",
            netmask: "Netzmaske",
            gateway: "Gateway",
            pin: "SIM Pin f\xFCr {{serial}}",
            invalidPinFormat: "SIM Pin sollte 4 stellig sein",
            SCmessage: "Ihr Ger\xE4t verf\xFCgt \xFCber einen einzigen zu konfigurierenden WAN-Port.",
            SCmessage_failover: "Ihr Ger\xE4t kann bis zu 2 WAN-Verbindungen haben (eine prim\xE4re und eine Failover-Verbindung).",
            SCconfigureFailover: "M\xF6chten Sie eine Ausfallschutz f\xFCr diesen Standort konfigurieren?",
            failover: "WAN-Ausfallschutz",
            setupSC: "Prim\xE4re WAN-Verbindung konfigurieren",
            setupSC_0: "Prim\xE4re WAN-Verbindung konfigurieren",
            setupSC_1: "Failover-WAN-Verbindung konfigurieren",
            descriptions: {
              name: "Ein eindeutiger Name f\xFCr diese WAN-Verbindung.",
              port: "Der Port, der f\xFCr diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual-LAN-ID f\xFCr das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit Virtual-LAN-Unterst\xFCtzung besteht).",
              type: "Der Netztyp, der f\xFCr diese Verbindung verwendet werden soll.",
              providerPinning: "Die Anbietergruppe, der dieser Link zugeordnet werden soll. Anbietergruppen werden in den SD-WAN-Richtlinien verwendet, um den Verkehrsfluss und das Verhalten zu definieren.",
              address: "Die IP-Adresse, die dem Anschluss zugewiesen werden soll.",
              netmask: "CIDR-Netzmaskensuffix f\xFCr diese Verbindung.",
              gateway: "Die mit dieser Verbindung verbundene Gateway-IP-Adresse.",
              pin: "Die pers\xF6nliche Identifikationsnummer (PIN) f\xFCr die in diesem Ger\xE4t installierte SIM-Karte."
            },
            bridgeType: {
              name: "Bridge",
              address: "IP-Adresse",
              gateway: "Gateway",
              lanPorts: "LAN Ports",
              netmask: "Netzmaske",
              wanPort: "WAN Port",
              descriptions: {
                address: "Die dem Port zugewiesene IP-Adresse.",
                gateway: "Die mit diesem Link verkn\xFCpfte Gateway IP-Adresse.",
                lanPorts: "Der zu verwendende LAN Port",
                netmask: "Das Network CIDR Netmask Suffix",
                wanPort: "Der zu verwendende WAN Port"
              }
            },
            pppoeType: {
              name: "Benutzername",
              password: "Passwort",
              description: {
                name: "Eindeutiger Name f\xFCr dieses PPPoE.",
                password: "Eindeutiges Passwort"
              }
            },
            validation: {
              mode: "Wenn ein Edge Service ausgew\xE4hlt ist, ist keine Bridging-Funktion m\xF6glich."
            }
          },
          lans: {
            sectionTitle: "LANs",
            message: "Ihr Ger\xE4t verf\xFCgt \xFCber insgesamt {{portsAvailable}} Ports, die sowohl f\xFCr WAN als auch f\xFCr LAN verf\xFCgbar sind, und Sie haben {{wans}} dem WAN zugewiesen.",
            message2_site: "Wie viele LAN-Verbindungen m\xF6chten Sie f\xFCr diesen Standort konfigurieren?",
            message2_gateway: "Wie viele LAN-Verbindungen m\xF6chten Sie f\xFCr diesen Edge-Service konfigurieren?",
            size: "Anzahl LAN Verbindungen",
            setup: "LAN Verbindung konfigurieren {{step}}",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtuelle LAN ID",
            address: "IP Addresse",
            netmask: "Netzmaske",
            dhcpEnable: "DHCP Server",
            dhcpDescription: "Bechreibung",
            dhcpFirstIp: "Erste IP-Adresse",
            dhcpLastIp: "Letzte IP-Adresse",
            dhcpDomain: "DHCP-Dom\xE4ne",
            descriptions: {
              dhcpEnable: "Aktiviert den DHCP-Server f\xFCr diese LAN-Verbindung.",
              name: "Ein eindeutiger Name f\xFCr diese LAN-Verbindung.",
              port: "Der Ger\xE4teanschluss, der f\xFCr diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual-LAN-ID f\xFCr das Netzwerk (zur Verwendung, wenn eine Verbindung zu einem Switch mit Virtual-LAN-Unterst\xFCtzung besteht).",
              address: "Die IP-Adresse, die dem Port zugewiesen werden soll.",
              netmask: "Der CIDR-Netzmaskensuffix, das f\xFCr das erstellte Netzwerk verwendet werden soll.",
              dhcpDescription: "Benutzerdefinierte Beschreibung f\xFCr dieses dhcp-Netzwerk.",
              dhcpFirstIp: "Erste IP-Adresse, die in diesem dhcp-Netzwerk zugewiesen werden kann.",
              dhcpLastIp: "Letzte IP-Adresse, die in diesem dhcp-Netzwerk zugewiesen werden kann.",
              dhcpDomain: "Domain name for this dhcp service.",
              wifi: {
                ssid: "Die SSID des WiFi-Netzwerkes.",
                securityMode: "Der Wi-Fi-Sicherheitsmodus, den Sie verwenden m\xF6chten.",
                passphrase: "Passphrase f\xFCr das Wi-Fi Netzwerk."
              },
              type: "Der Schnittstellentyp. Kann als standardm\xE4\xDFiges LAN Interface oder Bridge konfiguriert sein."
            },
            type: "Typ",
            bridgeType: {
              default: "Single Port",
              name: "Bridge",
              address: "IP-Adresse",
              lanPorts: "Ports",
              netmask: "Netzmaske",
              description: {
                address: "Die dem Port zugewiesene IP-Adresse.",
                lanPorts: "Die zu verwendenden LAN Ports",
                netmask: "Das Network CIDR Netmask Suffix"
              }
            },
            dhcpRelayType: "DHCP",
            validation: {
              dhcpRelayType: "kann nicht aktiviert werden, wenn SecureEdge eingestellt ist"
            }
          },
          advanced: {
            sectionTitle: "Erweiterte Einstellungen",
            updateWindowTZ: "Zeitzone des Updatefensters",
            primaryDns: "Prim\xE4re DNS Server",
            secondaryDns: "Sekund\xE4rer DNS Server",
            descriptions: {
              updateWindowTZ_site: "Die Zeitzone f\xFCr den Standort, die zur Bestimmung des Updatefensters f\xFCr diesen Standort verwendet wird.",
              updateWindowTZ_gateway: "Die Zeitzone f\xFCr den Edge Service, die zur Bestimmung des Updatefensters f\xFCr diesen Edge Service verwendet wird.",
              primaryDns: "Legen Sie die prim\xE4re DNS-Adresse manuell fest.",
              secondaryDns: "Legen Sie die sekund\xE4re DNS-Adresse manuell fest."
            }
          },
          submit: {
            message_site: "Bitte \xFCberpr\xFCfen Sie die Details f\xFCr den Standort und klicken Sie auf Speichern",
            message_gateway: "Bitte \xFCberpr\xFCfen Sie die Details f\xFCr den Edge Service und klicken Sie auf Speichern",
            name: "Name",
            model: "Modell",
            description: "Beschreibung",
            serial: "Seriennumer",
            gateway: "Edge Service",
            noGateway: "(keiner)",
            upstream_gateway: "Edge Service upstream",
            timezone: "Zeitzone",
            status: "Verbindungsstatus",
            peeringAddress: "Peering IP",
            wan: "WAN",
            lan: "LAN",
            primaryDns: "Prim\xE4rer DNS Server",
            secondaryDns: "Sekund\xE4rer DNS Server",
            haWarning: "Dieser Standort ist nicht hochverf\xFCgbar. Es wird empfohlen, f\xFCr Private Edge Services hochverf\xFCgbare Umgebungen zu verwenden."
          },
          result: {
            success_site: "Standort wurde erfolgreich erstellt",
            success_gateway: "Edge Service wurde erfolgreich erstellt",
            connectPort: "Bitte stellen Sie sicher, dass Sie den Port <0>{{port}}</0> der Appliance an eine DHCP-f\xE4hige Internetverbindung anschlie\xDFen, um die automatische Konfiguration Ihrer Appliance zu erm\xF6glichen. Wenn Sie einen anderen Port verwenden m\xF6chten, kann dieser direkt auf der Appliance konfiguriert werden.",
            connectPort_plural: "Bitte stellen Sie sicher, dass Sie den Port <0>{{port}}</0> der Appliances an eine DHCP-f\xE4hige Internetverbindung anschlie\xDFen, um die automatische Konfiguration Ihrer Appliances zu erm\xF6glichen. Wenn Sie einen anderen Port verwenden m\xF6chten, kann dieser direkt auf den Appliances konfiguriert werden.",
            HAMessage: "Verbinden Sie Port <0>P1</0> der beiden Appliances direkt mit einem Netzwerkkabel. Die HA-Synchronisierung wird automatisch gestartet.",
            campus: "F\xFCr weitere Informationen siehe <0>Barracuda Campus</0>",
            serial: "Seriennummer: {{serial}}",
            nonstandardPort: "Warning: Warnung: Diese Konfiguration verwendet einen nicht standardisierten Port:<0>{{ports}}</0>. Bitte stellen Sie sicher, dass er auf Ihrer virtuellen Appliance aktiviert ist.",
            nonstandardPort_plural: "Diese Konfiguration verwendet nicht standardisierte Ports:<0>{{ports}}</0>. Bitte stellen Sie sicher, dass diese auf den virtuellen Appliances aktiviert sind."
          },
          promoteResult: {
            primaryMessage: "Standortger\xE4t erfolgreich zum Edge Service bef\xF6rdert.",
            secondaryMessage: "Sie k\xF6nnen nun damit beginnen, neue oder bestehende Standorte anzubinden."
          },
          steps: {
            result: "Fertig",
            submit: "Zusammenfassung"
          },
          types: {
            dynamic: "Dynamisch (DHCP)",
            configure_on_branch: "In der Zweigstelle konfiguriert"
          },
          validation: {
            duplicateNetwork: "ein Netzwerk, das mit diesem Port/virtuellen LAN-ID \xFCbereinstimmt, wurde bereits konfiguriert",
            duplicateWwan: "es kann nur ein WWAN konfiguriert werden",
            duplicateExpressRoute: "es kann nur eine Expressroute konfiguriert werden",
            duplicateName: "der Name wird bereits f\xFCr eine andere Verbindung verwendet",
            secondaryDns: "sollte nicht gesetzt werden, wenn es keinen prim\xE4ren DNS gibt",
            availableAsn: "dieser asn-Wert ist nicht verf\xFCgbar",
            publicIp: "Die IP-Adresse muss im \xF6ffentlichen Bereich liegen.",
            noLans: "Muss mindestens ein LAN haben",
            exceededPPoE: "Es k\xF6nnen nur bis zu vier WAN PPPoEs konfiguriert werden",
            oneInlineBridge: "Es kann nur eine WAN Bridge konfiguriert werden"
          }
        },
        settings: {
          tabTitle: "Einstellungen",
          basic: {
            tabTitle: "Grundlegende Informationen",
            name: "Name",
            model: "Modell",
            gateway: "Edge Service",
            serials: "Seriennummer(n)",
            descriptions: {
              gateway_site: "Der Barracuda SecureEdge Edge Service, mit dem sich dieser Standort verbinden soll.",
              gateway_gateway: "Der upstream Barracuda SecureEdge Edge Service, mit dem sich dieser Edge Service verbinden soll.",
              name_site: "Ein eindeutiger Name f\xFCr Ihren Standort.",
              name_gateway: "Ein eindeutiger Name f\xFCr Ihren Edge Service.",
              model_site: "Das Ger\xE4temodell f\xFCr diesen Standort.",
              model_gateway: "Das Ger\xE4temodell f\xFCr diesen Edge Service.",
              serials_site: "Die Seriennummer(n) der Ger\xE4te f\xFCr diesen Standort",
              serials_gateway: "Die Seriennummer(n) der Ger\xE4te f\xFCr diesen Edge Service."
            },
            serialEdit: {
              table: {
                status: "Status",
                serial: "Seriennummer",
                add: "Zweite hinzuf\xFCgen"
              },
              addTitle: "Seriennummer hinzuf\xFCgen",
              editTitle: "Seriennummer tauschen",
              deleteTitle: "Seriennummer l\xF6schen",
              edit_site: "W\xE4hlen Sie unten die Seriennummer des Ersatzger\xE4tes aus, das Sie an diesen Standort anschlie\xDFen m\xF6chten. Die Ger\xE4te k\xF6nnen nur durch ein anderes Ger\xE4t desselben Modells ersetzt werden.",
              edit_gateway: "W\xE4hlen Sie unten die Seriennummer des Ersatzger\xE4ts aus, das Sie mit diesem Edge Service verbinden m\xF6chten. Ger\xE4te k\xF6nnen nur durch ein anderes Ger\xE4t desselben Modells ersetzt werden.",
              edit: "W\xE4hlen Sie unten die Seriennummer des Ersatzger\xE4ts aus, das Sie an diesen Standort anschlie\xDFen m\xF6chten. Die Ger\xE4te k\xF6nnen nur durch ein anderes Ger\xE4t desselben Modells ersetzt werden.",
              add_site: "Wenn Sie eine zweite Appliance an Ihren Standort anschlie\xDFen, wird die Hochverf\xFCgbarkeitsfunktion aktiviert. Bitte w\xE4hlen Sie unten die Seriennummer Ihrer Appliance aus.",
              add_gateway: "Wenn Sie eine zweite Appliance zu Ihrem Edge Service hinzuf\xFCgen, wird die Hochverf\xFCgbarkeitsfunktion aktiviert. Bitte w\xE4hlen Sie unten die Seriennummer Ihrer Appliance aus.",
              add: "Wenn Sie eine zweite Appliance zu Ihrem Standort hinzuf\xFCgen, wird die Hochverf\xFCgbarkeitsfunktion aktiviert. Bitte w\xE4hlen Sie unten die Seriennummer der Appliance aus.",
              delete1_site: "Sind Sie sicher, dass Sie Seriennummer {{serial}} von diesem Standort entfernen wollen?",
              delete1_gateway: "Sind Sie sicher, dass Sie Seriennummer {{serial}} von diesem Edge Service entfernen wollen?",
              delete1: "Sind Sie sicher, dass Sie Seriennummer {{serial}} von diesem Standort entfernen wollen?",
              delete2: "Dadurch wird die Hochverf\xFCgbarkeitsfunktionalit\xE4t entfernt und die entsprechende Appliance wird deaktiviert.",
              save: "Die \xC4nderungen werden erst nach dem Speichern wirksam.",
              saveDelete: "Nach dem Speichern k\xF6nnen Sie das Ger\xE4t vom Netz trennen.",
              saveAdd: "Nach dem Speichern k\xF6nnen Sie das neue Ger\xE4t anschlie\xDFen. Verbinden Sie zun\xE4chst Port <0>P4</0> des Ger\xE4tes mit einer DHCP-f\xE4higen Internetverbindung, um die automatische Konfiguration Ihres Ger\xE4ts zu erm\xF6glichen. Wenn Sie einen anderen Port verwenden m\xF6chten, kann dieser direkt auf der Appliance konfiguriert werden.",
              saveSCAdd: "Nach dem Speichern k\xF6nnen Sie die neue Appliance anschlie\xDFen, indem Sie den WAN-Port mit einer DHCP-f\xE4higen Internetverbindung verbinden, um die automatische Konfiguration Ihrer Appliance zu erm\xF6glichen.",
              saveEdit: "Nach dem Speichern k\xF6nnen Sie die alte Appliance trennen. Schlie\xDFen Sie dann das neue Ger\xE4t an. Verbinden Sie zun\xE4chst Port <0>P4</0> des Ger\xE4ts mit einer DHCP-f\xE4higen Internetverbindung, um die automatische Konfiguration Ihres Ger\xE4ts zu erm\xF6glichen. Wenn Sie einen anderen Port verwenden m\xF6chten, k\xF6nnen Sie diesen direkt auf dem Ger\xE4t konfigurieren.",
              saveSCEdit: "Nach dem Speichern k\xF6nnen Sie die alte Appliance trennen. Schlie\xDFen Sie dann die neue Appliance an und verbinden Sie den WAN-Port mit einer DHCP-f\xE4higen Internetverbindung, um die automatische Konfiguration Ihrer Appliance zu erm\xF6glichen.",
              saveHA: "Verbinden Sie au\xDFerdem Port <0>P1</0> der neuen Appliance und der vorhandenen Appliance direkt mit einem Netzwerkkabel. Die HA-Synchronisierung wird automatisch gestartet.",
              saveEditSecondary: "Die alte Appliance wurde f\xFCr HA konfiguriert. Bevor sie wieder verwendet werden kann, muss sie neu installiert werden.",
              saveDeleteSecondary: "Diese Appliance wurde f\xFCr HA konfiguriert. Bevor sie wieder verwendet werden kann, muss sie neu installiert werden.",
              saveDeletePrimary_site: "Die andere Appliance, die an diesen Standort angeschlossen ist, wurde f\xFCr HA konfiguriert. Sie muss nach dem Speichern neu installiert werden, damit sie sich als Nicht-HA-Appliance neu konfigurieren kann. Dies bedeutet eine gewisse Ausfallzeit f\xFCr Ihren Standort, bis dieser Prozess abgeschlossen ist.",
              saveDeletePrimary_gateway: "Die andere Appliance, die an diesen Edge Service angeschlossen ist, wurde f\xFCr HA konfiguriert. Sie muss nach dem Speichern neu installiert werden, damit sie sich als Nicht-HA-Appliance neu konfigurieren kann. Dies bedeutet eine gewisse Ausfallzeit f\xFCr Ihren Edge Service, bis dieser Prozess abgeschlossen ist.",
              saveDeletePrimary: "Die andere Appliance, die an diesen Standort angeschlossen ist, wurde f\xFCr HA konfiguriert. Sie muss nach dem Speichern neu installiert werden, damit sie sich als Nicht-HA-Appliance neu konfigurieren kann. Dies bedeutet eine gewisse Ausfallzeit f\xFCr Ihren Standort, bis dieser Prozess abgeschlossen ist."
            }
          },
          wan: {
            tabTitle: "WAN",
            addTitle: "Neue WAN-Schnittstelle hinzuf\xFCgen",
            addButtonText: "WAN-Schnittstelle hinzuf\xFCgen",
            addSCButtonText: "WAN-Fallback hinzuf\xFCgen",
            editTitle: "WAN-Schnittstelle bearbeiten",
            deleteTitle: "WAN-Schnittstelle l\xF6schen",
            deleteMessage: "Sind Sie sicher, dass Sie dieses WAN Interface l\xF6schen wollen?<0/>Alle mit diesem WAN verbundenen Verbindungen und Richtlinien (z.B. IPsec-Tunnel) werden automatisch entfernt.<0/>Die L\xF6schung wird erst beim Speichern wirksam.",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtuelle LAN ID",
            type: "Typ",
            providerPinning: "Provider Pinning",
            fallback: "Fallback",
            address: "IP Addresse",
            netmask: "Netzmaske",
            gateway: "Gateway",
            pin: "SIM Pin f\xFCr {{serial}}",
            scPin: "SIM Pin",
            invalidPinFormat: "SIM Pin muss aus 4 Nummern bestehen",
            portWarning: "Die ausgegrauten Zeilen oben zeigen an, dass der ausgew\xE4hlte Anschluss auf der Appliance nicht aktiviert wurde.",
            failover: "HA-Ausfallsicherung",
            network: "Netzwer",
            doublePort: "Port",
            doubleVirtualLanId: "Virtuelle LAN ID",
            doubleAddress: "IP Addresse",
            doubleNetmask: "Netzmaske",
            virtualTransferNetwork: "Virtuelles Transfernetz",
            asn: "Express Route ASN",
            expressRoute: "Express Route",
            primary: "Prim\xE4r",
            secondary: "Sekund\xE4r",
            validatePublicIp: "muss eine \xF6ffentliche IP f\xFCr User Connectivity sein",
            validateOnePort: "Sie k\xF6nnen nur einen dynamischen/statischen WAN-Anschluss und einen WWAN-Anschluss konfigurieren.",
            descriptions: {
              name: "Ein eindeutiger Name f\xFCr diese WAN-Verbindung.",
              port: "Der Ger\xE4teanschluss, der f\xFCr diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual LAN ID f\xFCr das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit VLAN-Unterst\xFCtzung besteht).",
              type: "Der Netztyp, der f\xFCr diese Verbindung verwendet werden soll.",
              providerPinning: "Die Anbietergruppe, der dieser Link zugeordnet werden soll. Anbietergruppen werden in den SD-WAN-Richtlinien verwendet, um den Verkehrsfluss und das SD-WAN Verhalten zu definieren.",
              address: "Die IP-Adresse, die dem Anschluss zugewiesen werden soll.",
              netmask: "CIDR-Netzmaskensuffix f\xFCr diese Verbindung.",
              gateway: "Die mit dieser Verbindung verbundene Gateway-IP-Adresse.",
              pin: "Die pers\xF6nliche Identifikationsnummer f\xFCr die in diesem Ger\xE4t installierte SIM-Karte.",
              failover: "Wenn diese Option aktiviert ist, wird der Anschluss \xFCberwacht und ein HA-Failover durchgef\xFChrt, wenn die Verbindung unterbrochen wird. Nur verf\xFCgbar, wenn HA aktiviert ist.",
              doublePort: "Die Ger\xE4teanschl\xFCsse, die f\xFCr die prim\xE4ren und sekund\xE4ren Verbindungen verwendet werden sollen.",
              doubleVirtualLanId: "Optionale Virtuelle LAN ID f\xFCr die prim\xE4ren und sekund\xE4ren Links (wird verwendet, wenn eine Verbindung zu einem Switch mit VLAN-Unterst\xFCtzung besteht).",
              doubleAddress: "Die IP-Adresse, die den prim\xE4ren und sekund\xE4ren Verbindungen zugewiesen werden soll. Dies sollte die erste nutzbare IP-Adresse der in Azure f\xFCr jede Verbindung konfigurierten Bereiche sein (z. B. wenn 172.16.0.4/30 in Azure konfiguriert ist, ist 172.16.0.5 die einzugebende IP-Adresse).",
              doubleNetmask: "CIDR-Netzmaskensuffix f\xFCr die prim\xE4ren und sekund\xE4ren Verbindungen.",
              asn: "Automatisch generierte BGP-ASN f\xFCr diese Verbindung. Dies f\xFChrt zu keinen Konflikten mit anderen von SecureEdge verwalteten ASNs.",
              virtualTransferNetwork: "Netzwerk, das zur Reservierung von IPs f\xFCr die virtuellen \xDCbertragungsschnittstellen verwendet wird.",
              healthCheck: {
                enabled: "Wenn diese Funktion aktiviert ist, wird die Schnittstelle in regelm\xE4\xDFigen Abst\xE4nden auf ihren Status hin \xFCberpr\xFCft.",
                mode: "Der Health Check-Modus, den Sie verwenden wollen.",
                targets: "IP-Adressen, die mit dem ICMP Health Check gepr\xFCft werden sollen."
              },
              userNamePassword: "Muss weniger als 256 Zeichen haben",
              mtu: "Sollte eine Zahl bis (einschlie\xDFlich) 1492 sein.",
              additionalAddresses: "Die dem Port zugewiesene zus\xE4tzliche IP-Adresse."
            },
            bridge: "Bridge",
            advanced: "Erweitert",
            basic: "Basic",
            userName: "Benutzername",
            password: "Passwort",
            mtu: "MTU-Gr\xF6\xDFe",
            healthCheck: {
              enabled: "Health Check aktivieren",
              mode: "Health Check",
              targets: "Health Check Target"
            },
            warning: "Wenn Sie den WAN-Typ \xE4ndern, werden die zugeh\xF6rigen IPsec-Tunnel oder NAT-Ingress-Regeln gel\xF6scht.",
            additionalAddresses: "Zus\xE4tzliche IP-Adressen",
            addressWarning: "Achtung: Wenn eine der zus\xE4tzlichen WAN-IP-Adressen, die Sie entfernt haben, in einer IPSec-Tunnel- oder NAT-Ingress-Regel verwendet wurde, werden die zugeh\xF6rigen Regeln beim Speichern dieser \xC4nderungen gel\xF6scht."
          },
          lan: {
            tabTitle: "LAN",
            sectionTitle: "LAN Anschl\xFCsse",
            addTitle: "Neue LAN-Schnittstelle hinzuf\xFCgen",
            addButtonText: "LAN-Schnittstelle hinzuf\xFCgen",
            editTitle: "LAN-Schnittstelle bearbeiten",
            deleteTitle: "LAN-Schnittstelle l\xF6schen",
            deleteMessage1: "Sind Sie sicher, dass Sie diese LAN-Schnittstelle l\xF6schen m\xF6chten? Dies hat die folgenden Auswirkungen:",
            deleteMessage2: "Alle zugeh\xF6rigen DHCP-Konfigurationen und Reservierungen werden entfernt.",
            deleteMessage3: "Alle Sicherheitsregeln, die diese LAN-Schnittstelle verwenden, werden aktualisiert oder entfernt.",
            deleteMessage4: "Das L\xF6schen wird erst beim Speichern dauerhaft.",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtuelle LAN ID",
            address: "IP Addresse",
            netmask: "Netzmaske",
            dhcpEnable: "DHCP Server",
            dhcpFirst: "Erste IP-Adresse",
            dhcpLast: "Letzte IP-Adresse",
            dhcpDomain: "DHCP-Dom\xE4ne",
            dhcpRange: "DHCP-Bereich",
            portWarning: "Die ausgegrauten Zeilen oben zeigen an, dass der ausgew\xE4hlte Anschluss auf der Appliance nicht aktiviert wurde.",
            failover: "HA-Ausfallsicherung",
            descriptions: {
              name: "Ein eindeutiger Name f\xFCr diese LAN-Verbindung.",
              port: "Der Ger\xE4teanschluss, der f\xFCr diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual-LAN-ID f\xFCr das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit VLAN-Unterst\xFCtzung besteht).",
              address: "Die IP-Adresse, die dem Anschluss zugewiesen werden soll.",
              netmask: "CIDR-Netzmaskensuffix, das f\xFCr das erstellte Netzwerk verwendet werden soll.",
              dhcpEnable: "Aktivieren Sie den DHCP-Server auf dem Ger\xE4t f\xFCr diese Verbindung.",
              dhcpFirst: "Die erste f\xFCr die DHCP-Zuweisung verf\xFCgbare IP. Sie muss innerhalb des durch die Port-IP und Netzmaske definierten Netzwerks liegen.",
              dhcpLast: "Die letzte f\xFCr die DHCP-Zuweisung verf\xFCgbare IP. Sie muss innerhalb des durch die Port-IP und Netzmaske definierten Netzes liegen.",
              dhcpDomain: "Der Dom\xE4nenname, der dem DHCP-Server zugewiesen werden soll.",
              failover: "Wenn diese Option aktiviert ist, wird der Anschluss \xFCberwacht und ein HA-Failover durchgef\xFChrt, wenn die Verbindung unterbrochen wird. Nur verf\xFCgbar, wenn HA aktiviert ist."
            },
            wifi: {
              validateSsid: "darf nur alphanumerische oder '-' Zeichen enthalten"
            }
          },
          route: {
            tabTitle: "Routen",
            sectionTitle: "Statische Routen",
            addTitle: "Neue Statische Route",
            addButtonText: "Statische Route hinzuf\xFCgen",
            editTitle: "Statische Route \xE4ndern",
            deleteTitle: "Statische Route l\xF6schen",
            deleteMessage: "Sind Sie sicher, dass Sie diese statische Route l\xF6schen m\xF6chten? Das L\xF6schen wird erst beim Speichern dauerhaft.",
            name: "Name",
            address: "Zielnetz",
            netmask: "Netzmaske",
            gateway: "Gateway",
            descriptions: {
              name: "Ein eindeutiger Name f\xFCr diese Route.",
              address: "Die Zielnetzadresse f\xFCr diese Route.",
              netmask: "Das CIDR-Netzmaskensuffix f\xFCr das Zielnetz.",
              gateway: "Das f\xFCr diese Route zu verwendende Gateway."
            }
          },
          dhcp: {
            tabTitle: "DHCP",
            networksSectionTitle: "DHCP Netze",
            leasesSectionTitle: "DHCP Leases",
            reservationsSectionTitle: "DHCP Reservierungen",
            dhcpEnable: "DHCP Service aktivieren",
            addNetworkTitle: "Neues DHCP Netz",
            addNetworkButtonText: "Neues Netz",
            editNetworkTitle: "DHCP-Netzwerk bearbeiten",
            deleteNetworkTitle: "DHCP-Netzwerk l\xF6schen",
            deleteNetworkMessage: "Sind Sie sicher, dass Sie dieses DHCP-Netzwerk l\xF6schen wollen? Das L\xF6schen wird erst beim Speichern dauerhaft.",
            networks: {
              networkSectionTitle: "Netzwerk",
              basicSectionTitle: "Grundlegend",
              basicTabTitle: "Grundlegend",
              advancedSectionTitle: "Erweitert",
              advancedTabTitle: "Erweitert",
              enable: "DHCP-Status",
              port: "LAN Name",
              description: "Beschreibung",
              firstIp: "Erste IP-Adresse",
              lastIp: "Letzte IP-Adresse",
              domain: "Dom\xE4nenname",
              dnsServers: "DNS Server",
              bootFileName: "Name der Boot-Datei",
              defaultLeaseTime: "Standard Ausgabezeit",
              maxLeaseTime: "Maximum Ausgabezeit",
              winsServers: "WINS Server",
              tftpHostname: "TFTP Host Name",
              tftpIpAddress: "TFTP IP Addresse",
              vendorOptions: "Vendor Options",
              validateMaxLeaseTime: "darf nicht kleiner sein als die Standard Ausgabezeit",
              dhcpRelayType: "DHCP",
              disabled: "Deaktiviert",
              dhcpRelay: "DHCP Relay",
              dhcpService: "DHCP Service"
            },
            blockUnknownMac: "Nur bekannte MAC-Adressen zulassen",
            addReservationTitle: "Neue DHCP-Reservierung hinzuf\xFCgen",
            addReservationButtonText: "Reservierung hinzuf\xFCgen",
            editReservationTitle: "DHCP-Reservierung bearbeiten",
            deleteReservationTitle: "DHCP-Reservierung l\xF6schen",
            deleteReservationMessage: "Sind Sie sicher, dass Sie diese DHCP-Reservierung l\xF6schen wollen? Das L\xF6schen wird erst beim Speichern dauerhaft.",
            leases: {
              reservationSectionTitle: "Leases",
              port: "LAN Name",
              ip: "IP Addresse",
              mac: "MAC Addresse",
              reserve: "Reserve IP",
              noLeases: "Derzeit sind keine DHCP-Leases aktiv. Es kann bis zu 2 Minuten dauern, bis neue DHCP-Leases erscheinen."
            },
            reservations: {
              reservationSectionTitle: "Reservierung",
              name: "Name",
              description: "Beschreibung",
              port: "LAN Name",
              ip: "IP Addresse",
              mac: "MAC Addresse",
              clientId: "Client ID",
              hostname: "Hostname",
              validateMac: "muss eine g\xFCltige MAC-Adresse sein",
              validateHostname: "darf nur alphanumerische, '-' oder '_' Zeichen enthalten",
              duplicate: "muss innerhalb des LAN eindeutig sein",
              duplicateName: "muss eindeutig sein"
            },
            descriptions: {
              dhcpEnable_site: "Aktivieren Sie DHCP f\xFCr diesen Standort.",
              dhcpEnable_gateway: "Aktivieren Sie DHCP f\xFCr diesen Edge Service.",
              blockUnknownMac: "Wenn diese Option aktiviert ist, k\xF6nnen nur die in der folgenden Tabelle aufgef\xFChrten MAC-Adressen den DHCP-Dienst nutzen.",
              networks: {
                enable: "Aktivieren Sie den DHCP-Server auf dem Ger\xE4t f\xFCr diese Verbindung.",
                description: "Geben Sie eine optionale Beschreibung f\xFCr diese DHCP-Serverkonfiguration an",
                port: "Die LAN-Schnittstelle, \xFCber die das DHCP-Netzwerk konfiguriert werden soll.",
                firstIp: "Die erste f\xFCr die DHCP-Zuweisung verf\xFCgbare IP. Sie muss innerhalb des durch die Port-IP und Netzmaske definierten Netzwerks liegen.",
                lastIp: "Die letzte f\xFCr die DHCP-Zuweisung verf\xFCgbare IP. Sie muss innerhalb des durch die Port-IP und Netzmaske definierten Netzes liegen.",
                domain: "Legen Sie optional einen Dom\xE4nennamen fest, der dem DHCP-Server zugewiesen werden soll.",
                dnsServers: "Legen Sie optional eine Liste der zu verwendenden DNS-Server fest.",
                bootFileName: "Legen Sie optional den Namen einer anf\xE4nglichen Boot-Datei fest, die von einem Client geladen werden soll. Der Name sollte f\xFCr jedes Datei\xFCbertragungsprotokoll erkennbar sein, das der Client zum Laden der Datei verwenden kann.",
                defaultLeaseTime: "Die Standardl\xE4nge in Sekunden, die einem Lease zugewiesen wird.",
                maxLeaseTime: "Die maximale L\xE4nge in Sekunden, die einem Lease zugewiesen wird.",
                winsServers: "F\xFCgen Sie optional eine oder mehrere WINS-Server-IP-Adressen hinzu.",
                tftpHostname: "Optional kann der Hostname eines TFTP-Servers angegeben werden.",
                tftpIpAddress: "Optional k\xF6nnen Sie die IP-Adresse eines TFTP-Servers angeben.",
                vendorOptions: "Optional k\xF6nnen Sie eine oder mehrere durch ';' getrennte Vendor-Pptionen festlegen."
              },
              reservations: {
                name: "Ein Name, der zur Identifizierung dieser Reservierung verwendet wird.",
                description: "Optionale Beschreibung f\xFCr diese Reservierung.",
                port: "Die LAN-Schnittstelle, f\xFCr die diese Reservierung vorgenommen werden soll.",
                ip: "Die f\xFCr den Client zu reservierende IP.",
                mac: "Die MAC-Adresse zur Identifizierung des Clients.",
                clientId: "Client-ID, die den Host-Client identifiziert, wenn eine IP-Adresse angefordert wird.",
                hostname: "Hostname, der dieser IP zugewiesen werden soll."
              },
              dhcpRelayType: "W\xE4hlen Sie den DHCP-Typ aus."
            },
            validation: {
              dhcpEnable: "Kann nicht aktiviert werden, wenn eine WAN Bridge konfiguriert ist"
            }
          },
          advanced: {
            tabTitle: "Erweiterte Einstellungen",
            primaryDns: "Prim\xE4rer DNS Server",
            secondaryDns: "Sekund\xE4rer DNS Server",
            rootPassword: "Root Password",
            rootPasswordConfirmation: "Root Password best\xE4tigen",
            updateWindowTZ: "Zeitzone des Updatefensters",
            disableOMS: "Log Analytics deaktivieren",
            disableSecurityPolicy: "Security deaktivieren",
            disableIpsPolicy: "IPS deaktivieren",
            omsNote: "Damit werden die Standardeinstellungen f\xFCr die Log Analytics Anbindung au\xDFer Kraft gesetzt.",
            descriptions: {
              updateWindowTZ_site: "Die Zeitzone f\xFCr den Standort, die zur Bestimmung des Updatefensters f\xFCr diesen Standort verwendet wird.",
              updateWindowTZ_gateway: "Die Zeitzone f\xFCr den Edge Service, die zur Bestimmung des Updatefensters f\xFCr diesen Edge Service verwendet wird.",
              primaryDns: "Legen Sie die prim\xE4re DNS-Adresse manuell fest.",
              secondaryDns: "Legen Sie die sekund\xE4re DNS-Adresse manuell fest.",
              rootPassword_site: "Das Passwort des Root-Benutzers f\xFCr diesen Standort.",
              rootPassword_gateway: "Das Passwort des Root-Benutzers f\xFCr diesen Edge Service.",
              disableOMS_site: "Deaktivieren Sie das Streaming von Protokollen an Azure Log Analytics f\xFCr diesen Standort.",
              disableOMS_gateway: "Deaktivieren Sie das Streaming von Protokollen an Azure Log Analytics f\xFCr diesen Edge Service.",
              disableSecurityPolicy_site: "Deaktivieren Sie alle Sicherheitsrichtlinien f\xFCr diesen Standort.",
              disableSecurityPolicy_gateway: "Deaktivieren Sie alle Sicherheitsrichtlinien f\xFCr diesen Edge Service.",
              disableIpsPolicy_site: "Deaktivieren Sie die IPS-Richtlinien f\xFCr diesen Standort.",
              disableIpsPolicy_gateway: "Deaktivieren Sie die IPS-Richtlinien f\xFCr diesen Edge Service."
            }
          },
          confirmPortNotActivated: "Einige der ausgew\xE4hlten Ports sind derzeit nicht f\xFCr dieses Ger\xE4t aktiviert.",
          confirmPortNotActivated_site: "Einige der ausgew\xE4hlten Ports sind derzeit nicht f\xFCr diesen Standort aktiviert.",
          confirmPortNotActivated_gateway: "Einige der ausgew\xE4hlten Ports sind derzeit nicht f\xFCr diesen Edge Service aktiviert.",
          confirmPointOfEntryBroken: "Dieses Ger\xE4t ist als Einstiegspunkt konfiguriert und ben\xF6tigt ein WAN mit einer statischen \xF6ffentlichen IP. Wenn Sie das Ger\xE4t speichern, ohne eine statische \xF6ffentliche IP zu konfigurieren, wird die Einstiegspunkt-Funktionalit\xE4t entfernt.",
          confirmPointOfEntryBroken_site: "Dieser Standort ist als Einstiegspunkt konfiguriert und erfordert ein WAN mit einer statischen \xF6ffentlichen IP. Wenn Sie ohne statische \xF6ffentliche IP speichern, wird die Einstiegspunkt-Funktionalit\xE4t entfernt.",
          confirmPointOfEntryBroken_gateway: "Dieser Edge Service ist als Einstiegspunkt konfiguriert und erfordert ein WAN mit einer statischen \xF6ffentlichen IP. Wenn Sie ohne statische \xF6ffentliche IP speichern, wird die Einstiegspunkt-Funktionalit\xE4t entfernt.",
          confirm: "Sind Sie sicher, dass Sie fortfahren wollen?"
        },
        support: {
          buttonText: "Support Zugang",
          dialogTitle: "Support Zugang",
          message_site: "Mit der hier angegebenen Support-ACL und dem SSH-Schl\xFCssel wird der SSH-Zugang zu diesem Standort f\xFCr 24 Stunden gew\xE4hrt.",
          message_gateway: "Mit der hier angegebenen Support-ACL und dem SSH-Schl\xFCssel wird der SSH-Zugang zu diesem Edge Service f\xFCr 24 Stunden gew\xE4hrt.",
          cloudGatewayMessage: "Um auf einen Edge Service f\xFCr Virtual WAN zugreifen zu k\xF6nnen, m\xFCssen Sie auch Support-Zugriff in der <0>Azure Managed Application</0> aktivieren.",
          networks: "Support ACL",
          publicKey: "SSH Public Key",
          success_site: "Support-Zugang f\xFCr Standort '{{Name}}' f\xFCr 24 Stunden aktiviert.",
          success_gateway: "Support-Zugang f\xFCr Edge Service '{{Name}}' f\xFCr 24 Stunden aktiviert.",
          description: {
            networks_site: "Durch Kommata getrennte Liste der Netze, denen SSH-Zugang zu dem Standort gew\xE4hrt werden soll.",
            networks_gateway: "Durch Kommata getrennte Liste der Netze, denen SSH-Zugang zu dem Edge Service gew\xE4hrt werden soll.",
            publicKey_site: "Der \xF6ffentliche OpenSSH-Schl\xFCssel, der f\xFCr den Zugriff auf den Standort erforderlich ist.",
            publicKey_gateway: "Der \xF6ffentliche OpenSSH-Schl\xFCssel, der f\xFCr den Zugriff auf den Edge Service erforderlich ist."
          }
        },
        restart: {
          buttonLabel: "Neustart",
          box: {
            menuChoice: "Standort neu starten",
            dialogTitle: "Standort neu starten",
            confirmMessage: "Wenn Sie auf BEST\xC4TIGEN klicken, wird dieser Standort neu gestartet. Dies kann bis zu 5 Minuten dauern. <0/><0/> Sind Sie sicher, dass Sie fortfahren m\xF6chten?",
            confirmMessage_plural: "Wenn Sie auf BEST\xC4TIGEN klicken, werden beide Appliances in Ihrem HA-Paar neu gestartet. Dies kann bis zu 5 Minuten dauern. <0/> Dies betrifft nur Appliances, die als online gemeldet sind. <0/><0/> Sind Sie sicher, dass Sie fortfahren m\xF6chten?"
          },
          vpn: {
            menuChoice: "VPN Service neu starten",
            dialogTitle: "VPN Service neu starten",
            confirmMessage: "Wenn Sie auf BEST\xC4TIGEN klicken, wird der VPN-Service f\xFCr diese Appliance neu gestartet. Dies kann bis zu 5 Minuten dauern. <0/><0/> Sind Sie sicher, dass Sie fortfahren m\xF6chten?",
            confirmMessage_plural: "Wenn Sie auf BEST\xC4TIGEN klicken, wird der VPN Service f\xFCr beide Appliances in Ihrem HA-Paar neu gestartet; dies kann bis zu 5 Minuten dauern. <0/> Dies betrifft nur Appliances, die als online gemeldet sind. <0/><0/> Sind Sie sicher, dass Sie fortfahren m\xF6chten?"
          },
          firmware: {
            menuChoice: "Firmware durchstarten",
            dialogTitle: "Firmware durchstarten",
            confirmMessage: "Wenn Sie auf BEST\xC4TIGEN klicken, wird die Firmware f\xFCr diese Appliance neu gestartet. Dies kann bis zu 5 Minuten dauern. <0/><0/> Sind Sie sicher, dass Sie fortfahren m\xF6chten?",
            confirmMessage_plural: "Wenn Sie auf BEST\xC4TIGEN klicken, wird die Firmware f\xFCr beide Appliances in Ihrem HA-Paar neu gestartet. Dies kann bis zu 5 Minuten dauern. <0/> Dies betrifft nur Appliances, die als online gemeldet sind. <0/><0/> Sind Sie sicher, dass Sie fortfahren m\xF6chten?"
          }
        }
      },
      security: {
        securityEnabled: "Sicherheitsfunktionen eingeschaltet",
        menuTitle: "Sicherheitsfunktionen",
        pageTitle: "Sicherheitsfunktionen",
        comingSoon: "Bald verf\xFCgbar",
        protection: {
          tabTitle: "Schutz vor Schadsoftware",
          settings: {
            tabTitle: "Einstellungen",
            protection: "Schutz vor Schadsoftware",
            name: "Name",
            action: "Aktion",
            description: "Beschreibung",
            order: "Rangfolge",
            source: "Quelle",
            destination: "Ziel",
            defaultAction: "Standardma\xDFnahme",
            inputs: {
              enabled: "Eingeschaltet"
            },
            descriptions: {
              name: "Ein eindeutiger Name f\xFCr Ihre Richtlinie.",
              description: "Eine kurze Beschreibung Ihrer Richtlinie.",
              action: "Die zu ergreifende Ma\xDFnahme f\xFCr den von dieser Richtlinie erfassten Verkehr."
            },
            create: {
              buttonText: "Neue Richtlinie",
              dialogTitle: "Neue Richtlinie hinzuf\xFCgen"
            },
            edit: {
              dialogTitle: "Richtlinie bearbeiten"
            },
            delete: {
              title: "Richtlinie l\xF6schen",
              body: "Sind Sie sicher, dass Sie diese Richtlinie l\xF6schen wollen?"
            }
          }
        },
        addRuleButtonText: "Richtlinie hinzuf\xFCgen",
        sourceCriteria: {
          any: "Any",
          section: "Quellen",
          type: "Typ",
          site: "Standort",
          internet: "Internet",
          siteNetwork: "Netzwerke",
          source: "Quelle",
          application: "Benutzerdefinierte Netzwerkanwendung",
          applications: "Benutzerdefinierte Netzwerkanwendungen",
          allSites: "Alle Standorte",
          allNetworks: "Alle Netze",
          addSite: "Standort hinzuf\xFCgen",
          editSite: "Standort besrbeiten",
          network: "IP/Netzwerk",
          userOrGroup: "Benutzer/Gruppe",
          users: "Benutzer",
          groups: "Gruppen",
          userOrGroupRequired: "mindestens ein Benutzer oder eine Gruppe ist erforderlich",
          userOrGroupDisabled: "Kein Benutzer-Verzeichnis konfiguriert. Um ein Benutzer-Verzeichnis hinzuzuf\xFCgen, gehen Sie bitte zu 'Identit\xE4t > Einstellungen'.",
          userOrGroupDisabledShort: "Kein Benutzer-Verzeichnis konfiguriert.",
          description: "W\xE4hlen Sie den Quellentyp aus.",
          applicationDescription: "W\xE4hlen Sie die Quellanwendung aus.",
          networkDescription: "F\xFCgen Sie Ihr Quellnetz ein.",
          siteDescription: "W\xE4hlen Sie den Quell-Standort.",
          userDescription: "W\xE4hlen Sie den/die Quell-Benutzer.",
          groupDescription: "W\xE4hlen Sie den/die Quell-Benutzergruppe(n)).",
          gatewayNetwork: "Netzwerke",
          allOnpremGateways: "Alle Private Edge Services",
          addOnpremGateway: "Private Edge Service hinzuf\xFCgen",
          editOnpremGateway: "Private Edge Service bearbeiten",
          onpremGateway: "Private Edge Service",
          onpremGatewayDescription: "W\xE4hlen Sie den Private Edge Service f\xFCr Ihre Source aus."
        },
        destinationCriteria: {
          section: "Zielkriterien",
          type: "Typ",
          destination: "Ziel",
          site: "Standort",
          siteNetwork: "Netzwerke",
          allSites: "Alle Standorte",
          allNetworks: "Alle Netzwerke",
          addSite: "Standort hinzuf\xFCgen",
          editSite: "Edit Site",
          network: "IP/Network",
          application: "Application",
          domain: "Domain",
          category: "URL Category",
          onPremGateway: "Private Edge Service",
          description: "W\xE4hlen Sie Ihren Zieltyp.",
          applicationDescription: "W\xE4hlen Sie Ihre Zielanwendung aus.",
          siteDescription: "W\xE4hlen Sie Ihren Ziel-Standort.",
          gatewayDescription: "W\xE4hlen Sie den Private Edge Service f\xFCr die Destination aus.",
          networkDescription: "W\xE4hlen Sie Ihren Zielnetz.",
          gateway: "Edge Service",
          ingressSite: "Standort",
          customCategory: "Benutzerdefinierte Kategorien",
          gatewayNetwork: "Netzwerke",
          onpremGateway: "Private Edge Service",
          allOnpremGateways: "Alle Private Edge Services",
          addOnpremGateway: "Private Edge Service hinzuf\xFCgen",
          editOnpremGateway: "Private Edge Service bearbeiten",
          onpremGatewayDescription: "W\xE4hlen Sie den Private Edge Service f\xFCr Ihre Destination aus."
        },
        targetCriteria: {
          section: "Ziel-Kriterien"
        },
        tables: {
          allSites: "Alle Standorte",
          allGateways: "Alle Edge Services",
          source: "Quelle",
          sourceNetwork: "IP/Netz",
          sourceApplication: "Benutzerdefinierte Netzwerkanwendung",
          sourceSite: "Standort",
          sourceType: "Quell-Typ",
          destination: "Destination",
          destinationSite: "Standort",
          destinationApplication: "Anwendung",
          destinationCategories: "URL-Kategorie",
          destinationNetwork: "IP/Netz",
          destinationDomain: "Dom\xE4ne",
          destinationType: "Ziel-Typ",
          destinationGateway: "Ziel Edge Service",
          destinationWan: "Ziel WAN",
          user: "Benutzer",
          group: "Gruppe",
          destinationCustomCategories: "Benutzerdefinierte Kategorie",
          allOnpremGateways: "Alle Private Edge Services",
          sourceOnpremGateway: "Private Edge Service",
          destinationOnpremGateway: "Private Edge Service"
        },
        applicationControl: {
          siteTabTitle: "Standort ACL",
          gatewayTabTitle: "Edge Service ACL",
          settings: {
            tabTitle: "Einstellungen",
            siteApplicationControl: "Standort ACL",
            gatewayApplicationControl: "Edge Service ACL",
            order: "Rangfolge",
            name: "Name",
            action: "Aktion",
            description: "Beschreibung",
            defaultAction: "Standard-Aktion",
            source: "Quelle",
            destination: "Ziel",
            scope: "Anwendungsbereich",
            allSites: "Alle Standorte",
            allGateways: "Alle Edge Services",
            icmp: "ICMP",
            descriptions: {
              name: "Ein eindeutiger Name f\xFCr Ihre Richtlinie.",
              description: "Eine kurze Beschreibung Ihrer Richtlinie.",
              action: "Die zu ergreifende Ma\xDFnahme (blockieren oder zulassen) f\xFCr den von dieser Richtlinie erfassten Verkehr.",
              scope: "Die Standort(e), auf die diese Richtlinie anzuwenden ist.",
              icmp: "Zulassen, dass Ger\xE4te \xFCber ICMP kommunizieren."
            },
            create: {
              buttonText: "Richtlinie hinzuf\xFCgen",
              dialogTitle: "Neue Richtlinie hinzuf\xFCgen"
            },
            edit: {
              dialogTitle: "Richtlinie \xE4ndern"
            },
            delete: {
              title: "Richtlinie l\xF6schen",
              body: "Sind Sie sicher, dass Sie diese Richtlinie l\xF6schen wollen?"
            }
          },
          noData: {
            edgeServiceAcl: {
              iconHeader: "Beginnen Sie mit dem Hinzuf\xFCgen einer ACL f\xFCr den Edge Service",
              labelButton: "Edge Service ACL hinzuf\xFCgen",
              textHeader: "Was ist eine ACL f\xFCr den Edge Service?",
              text: "Aus Sicherheitsgr\xFCnden sollten Sie den Zugang zu Ihrem(n) Service(s) beschr\xE4nken.<0 /> Mit dieser Access Control List (ACL) k\xF6nnen Sie konfigurieren, wer auf den/die Services zugreifen darf und wie"
            },
            siteAcl: {
              iconHeader: "Beginnen Sie mit dem Hinzuf\xFCgen einer Standort ACL!",
              labelButton: "Hinzuf\xFCgen Standort ACL",
              textHeader: "Was ist eine Standort ACL?",
              text: "Aus Sicherheitsgr\xFCnden sollten Sie den Zugriff auf Ihren/Ihre Standort(e) einr\xE4nken.<0 /> Mit dieser Access Control List (ACL) k\xF6nnen Sie konfigurieren, wer auf Ihren/Ihre Standort(e) zugreifen darf und wie"
            },
            appResource: {
              iconHeader: "Beginnen Sie mit dem Hinzuf\xFCgen von Apps und Ressourcen!",
              labelButton: "Benutzerdefinierte App hinzuf\xFCgen",
              textHeader: "Was ist eine benutzerdefinierte App?",
              text: "F\xFCr eine funktionierende SD-WAN-Umgebung muss Ihre Installation die verwendeten Apps kennen.<0/> W\xE4hrend eine gro\xDFe Anzahl von Apps von Anfang an unterst\xFCtzt wird, geh\xF6ren bestimmte Apps m\xF6glicherweise nicht zu dieser ersten Gruppe.<0/>Sie k\xF6nnen hier Netzwerk- und Web-Apps hinzuf\xFCgen, die nicht unterst\xFCtzt werden."
            }
          }
        },
        urlFiltering: {
          tabTitle: "URL-Filtern",
          settings: {
            tabTitle: "Einstellungen",
            order: "Rangfolge",
            name: "Name",
            description: "Beschreibung",
            appliesTo: "Betrifft",
            action: "Aktion",
            type: "Typ",
            rule: "Ziel",
            domains: "Dom\xE4ne",
            categories: "URL-Kategorie",
            customCategories: "Benutzerdefinierte Kategorien",
            defaultAction: "Standard-Aktion",
            destinationCriteria: "Zielkriterien",
            destinationDomains: "{{domains.0}}{{additional}}",
            destinationDomains_1: "{{domains.0}}",
            destinationCategories: "Kategorien ({{activeCategories.0.name}}, +{{additional}})",
            destinationCategories_1: "Kategorien ({{activeCategories.0.name}})",
            destinationCustomCategories: "Benutzerdefinierte Kategorien ({{activeCategories.0.name}}, +{{additional}})",
            destinationCustomCategories_1: "Benutzerdefinierte Kategorien ({{activeCategories.0.name}})",
            descriptions: {
              name: "Ein eindeutiger Name f\xFCr Ihre Richtlinie.",
              description: "Eine kurze Beschreibung Ihrer Richtlinie.",
              action: "Die zu ergreifende Ma\xDFnahme (blockieren, zulassen...) f\xFCr den von dieser Richtlinie erfassten Verkehr.",
              type: "Erstellen Sie diese Richtlinie entweder auf URL-Kategorie oder auf der \xDCbereinstimmung mit Dom\xE4nennamen.",
              domains: "Die Dom\xE4nen, die f\xFCr diese Richtlinie verwendet werden.",
              customCategories: "Die benutzerdefinierten Kategorien, die f\xFCr diese Richtlinie verwendet werden.",
              silent: "Stummes Sperren der Regel."
            },
            create: {
              buttonText: "Richtlinie hinzuf\xFCgen",
              dialogTitle: "Neue Richtlinie hinzuf\xFCgen"
            },
            edit: {
              dialogTitle: "Richtlinie bearbeiten"
            },
            validation: {
              emptyCategories: "es muss mindestens eine Kategorie ausgew\xE4hlt sein."
            },
            delete: {
              title: "Richtlinie l\xF6schen",
              body: "Sind Sie sicher, dass Sie diese Richtlinie l\xF6schen wollen?"
            },
            silent: "Stumm"
          },
          exceptions: {
            tabTitle: "Explizit",
            order: "Rangfolge",
            name: "Name",
            description: "Beschreibung",
            action: "Aktion",
            type: "Typ",
            rule: "Richtlinie",
            domain: "Dom\xE4ne",
            category: "URL-Kategorie",
            customCategories: "Benutzerdefinierte Kategorien",
            domains: "Ziel-Hostname",
            categories: "URL-Kategorie",
            source: "Quelle",
            sourceNetwork: "Quell IP/Netz",
            sourceSite: "Quell Standort",
            allSites: "Alle Standorte",
            allNetworks: "Alle Netze",
            sourceSiteNetwork: "Netze",
            sourceType: "Typ",
            network: "IP/Netz",
            site: "Standort",
            sourceSites: "Standort",
            addSite: "Standort hinzuf\xFCgen",
            editSite: "Standort editieren",
            destinations: "Ziel",
            destination: "Ziel IP/Netz",
            destinationType: "Typ",
            destinationCategories: "Ziel URL-Kategorie",
            destinationDomains: "Ziel Dom\xE4ne",
            sectionTitle: "Kriterien",
            sourceCriteria: "Quell-Kriterien",
            destinationCriteria: "Ziel-Kriterien",
            descriptions: {
              name: "Ein eindeutiger Name f\xFCr diese explizite Richtlinie.",
              description: "Eine kurze Beschreibung dieser Richtlinie.",
              action: "Die zu ergreifende Aktion (blockieren, zulassen...) f\xFCr den von dieser Richtlinie erfassten Verkehr.",
              categories: "Die Kategorien, die verwendet werden, um dieser Richtlinie zu entsprechen.",
              customCategories: "Die benutzerdefinierten Kategorien, die f\xFCr diese Richtlinie verwendet werden.",
              domains: "Die Dom\xE4nen, die f\xFCr diese Richtlinie verwendet werden.",
              silent: "Stummes Sperren der Regel."
            },
            validation: {
              sourceDest: "es muss mindestens eine Quelle oder ein Ziel angeben werden"
            },
            create: {
              buttonText: "Hinzuf\xFCgen",
              dialogTitle: "Explizite Richtlinie hinzuf\xFCgen"
            },
            edit: {
              dialogTitle: "Explizit Richtlinie bearbeiten"
            },
            delete: {
              title: "Explizite Richtlinie l\xF6schen",
              body: "Sind Sie sicher, dass Sie diese Ausnahme l\xF6schen m\xF6chten??"
            },
            silent: "Stumm"
          }
        },
        urlCategories: {
          tabTitle: "URL-Kategorien",
          settings: {
            tabTitle: "Einstellungen",
            urlCategories: "URL-Kategorien",
            description: "Markieren Sie eine URL-Oberkategorie oder URL-Kategorie, um sie zu blockieren"
          },
          blocklists: {
            tabTitle: "Erlaubt/Blockieren Listen",
            description: "Definieren Sie URLs, IPs oder Netzwerke, die dauerhaft gesperrt oder zugelassen werden sollen.",
            inputs: {
              blacklist: "Immer blockieren",
              whitelist: "Immer zulassen"
            }
          },
          exceptions: {
            tabTitle: "Explizit",
            categories: "Oberkategorie",
            subCategories: "Kategorien",
            mode: "Erlauben/Blockieren",
            allow: "Erlauben",
            block: "Blockieren",
            users: "Benutzer",
            groups: "Benutzergruppen",
            source: "Quell IP/Netz",
            time: "Zeit",
            validateCategory: "mindestens eine URL-Oberkategorie oder URL-Kategorie muss angegeben werden",
            validateSource: "mindestens eine Quelle, ein Benutzer oder eine Gruppe muss angegeben werden",
            delete: {
              title: "Explizite l\xF6schen",
              body: "Sind Sie sicher, dass Sie diese Ausnahme l\xF6schen wollen? Das L\xF6schen wird erst beim Speichern dauerhaft."
            },
            create: {
              title: "Neu hinzuf\xFCgen Explizit",
              button: "Hinzuf\xFCgen"
            },
            edit: {
              title: "Explizit bearbeiten"
            }
          }
        },
        ips: {
          tabTitle: "IPS",
          settings: {
            tabTitle: "Einstellungen",
            falsePositivesTabTitle: "Positive Fehlmeldungen",
            ips: "Intrusion Prevention",
            name: "Name",
            action: "Aktion",
            description: "Beschreibung",
            block: "Blockieren",
            mode: "Modus",
            log: "Protokollierung",
            order: "Rangfolge",
            source: "Quelle",
            exploits: "Exploit",
            destination: "Ziel",
            defaultAction: "Standard-Aktion",
            descriptions: {
              name: "Ein eindeutiger Name f\xFCr die Richtlinie",
              description: "Eine kurze Beschreibung der Richtlinie.",
              action: "Die zu ergreifende Ma\xDFnahme f\xFCr den von dieser Richtlinie erfassten Verkehr."
            },
            inputs: {
              enabled: "Eingeschaltet"
            },
            create: {
              buttonText: "Richtlinie hinzuf\xFCgen",
              dialogTitle: "Neue Richtlinie hinzuf\xFCgen"
            },
            edit: {
              dialogTitle: "Richtlinie bearbeiten"
            },
            delete: {
              title: "Richtlinie l\xF6schen",
              body: "Sind Sie sicher, dass Sie diese Richtlinie l\xF6schen wollen?"
            }
          }
        },
        sslInterception: {
          tabTitle: "SSL Inspektion",
          settings: {
            tabTitle: "Zertifikate",
            sslInterception: "SSL Inspektion",
            name: "Name",
            action: "Aktion",
            description: "Beschreibung",
            order: "Rangfolge",
            source: "Quelle",
            destination: "Ziel",
            defaultAction: "Standard-Aktion",
            explicitTabTitle: "Einstellungen",
            status: "Status",
            issuer: "Herausgeber",
            startDate: "Startdatum",
            endDate: "Enddatum",
            makeActive: "Auf aktiv setzen",
            active: "Activ",
            activeCertificate: "Active Zertifikate",
            makeCertificateActive: "Aktivieren",
            sslCertificates: "SSL Inspektion Zertifikate",
            sslCertificateAuthorities: "Vertrauensw\xFCrdige Zertifikatsstellen",
            uploadP12: "PKCS12 hochladen",
            uploadPEM: "PEM oder CRT hochladen",
            passphrase: "Passphrase",
            downloadCertificate: "Client-Zertifikat herunterladen",
            certificates: {
              create: {
                label: "Zertifikat hinzuf\xFCgen",
                dialogTitle: "Neues Zertifikat hinzuf\xFCgen"
              },
              edit: {
                dialogTitle: "Zertifikatdetails bearbeiten"
              },
              delete: {
                title: "Zertifikat l\xF6schen",
                body: "Sind Sie sicher, dass Sie dieses Zertifikat l\xF6schen m\xF6chten?",
                disabledMessage: "Aktives Zertifikat kann nicht gel\xF6scht werden."
              }
            },
            descriptions: {
              name: "Ein eindeutiger Name f\xFCr Ihre Richtlinie.",
              certificateName: "Ein eindeutiger Name f\xFCr Ihr Zertifikat.",
              description: "Eine kurze Beschreibung Ihrer Richtlinie.",
              certificateDescription: "Eine kurze Beschreibung Ihres Zertifikats.",
              action: "Die zu ergreifende Ma\xDFnahme f\xFCr den von dieser Richtlinie erfassten Verkehr.",
              uploadP12: "Laden Sie Ihr PKS12-Zertifikat f\xFCr hoch. Es muss den privaten Schl\xFCssel enthalten.",
              uploadPEM: "Laden Sie ein PEM- oder CRT-Zertifikat hoch.",
              passphrase: "Die Passphrase zum Entsperren des PKS12-Zertifikats (falls erforderlich)."
            },
            inputs: {
              enabled: "Eingeschaltet"
            },
            create: {
              buttonText: "Richtlinie hinzuf\xFCgen",
              dialogTitle: "Neue Richtlinie hinzuf\xFCgen"
            },
            edit: {
              dialogTitle: "Richtlinie bearbeiten"
            },
            delete: {
              title: "Richtlinie l\xF6schen",
              body: "Sind Sie sicher, dass Sie diese Richtlinie l\xF6schen wollen?"
            }
          }
        },
        ingressNatRules: {
          settings: {
            order: "Auftrag",
            name: "Name",
            target: "Ziel",
            description: "Beschreibung",
            source: "Quelle",
            destination: "Ziel",
            gateway: "Edge Service",
            wan: "WAN",
            targetApplicationResource: "Anwendung/Ressource",
            descriptions: {
              name: "Eindeutiger Name f\xFCr Ihre Regel.",
              description: "Kurze Beschreibung Ihrer Regel.",
              target: "Ziel f\xFCr den Traffic, auf den diese Regel zutrifft.",
              wan: "W\xE4hlen Sie Ihr WAN Interface je nach der ben\xF6tigten \xF6ffentlichen IP.",
              patPublicPort: "Ihr Ziel-PAT Public Port. Dies ist ein Pflichtfeld, wenn das Ziel eine Netzwerkanwendung ohne Ports ist. Wenn kein Port angegeben wird, wird der konfigurierte Port der Zielanwendung (oder der erste Port im konfigurierten Portbereich) verwendet."
            },
            create: {
              buttonText: "Regel hinzuf\xFCgen",
              dialogTitle: "Neue Regel hinzuf\xFCgen"
            },
            edit: {
              dialogTitle: "Regel bearbeiten"
            },
            delete: {
              title: "Regel l\xF6schen",
              body: "Sind Sie sicher, dass Sie diese Regel l\xF6schen m\xF6chten?"
            },
            IPAddress: "Adresse"
          },
          noData: {
            iconHeader: "Beginnen Sie mit dem Hinzuf\xFCgen einer Ingress NAT-Regel!",
            labelButton: " Ingress NAT-Regel hinzuf\xFCgen",
            textHeader: "Was ist eine Ingress NAT?",
            text: "Falls Sie interne Netzwerkressourcen f\xFCr die \xD6ffentlichkeit zug\xE4nglich machen wollen, verwenden Sie diesen Abschnitt, um die Parameter f\xFCr solche Verbindungen zu definieren.<0/><0/>Bitte beachten Sie, dass der interne Dienst unter einer statischen \xF6ffentlichen IP-Adresse laufen muss."
          }
        }
      },
      customApps: {
        menuTitle: "BENUTZERDEFINIERTE APPS",
        pageTitle: "Benutzerdefinierte Anwendungen",
        table: {
          name: "Name",
          description: "Beschreibung",
          protocol: "Protokoll",
          destination: "Ziele",
          category: "Kategorie",
          type: "Typ"
        },
        dialogs: {
          create: {
            buttonText: "Neue benutzerdefinierte Anwendung",
            dialogTitleWeb: "Neue benutzerdefinierte Web-Anwendung",
            dialogTitleNetwork: "Neue benutzerdefinierte Netzwerk-Anwendung",
            menuLabelWeb: "Neue benutzerdefinierte Web-Anwendung",
            menuLabelNetwork: "Neue benutzerdefinierte Netzwerk-Anwendung"
          },
          edit: {
            dialogTitle: "Benutzerdefinierte Anwendung bearbeiten"
          },
          general: {
            sectionTitle: "Allgemein",
            name: "Name",
            description: "Beschreibung",
            descriptions: {
              name: "Eindeutiger Name f\xFCr diese benutzerdefinierte Anwendung.",
              description: "Kurze Beschreibung dieser benutzerdefinierten Anwendung."
            }
          },
          endpoints: {
            protocol: "Protokoll",
            sectionTitle: "App-Endpunkte",
            destination: "Ziel",
            port: "Port",
            firstPort: "Erster Port",
            lastPort: "Letzter Port",
            descriptions: {
              protocol: "Das von dieser benutzerdefinierten Anwendung verwendete Protokoll.",
              destination: "Die Ziel-IP, Domain oder das Netzwerk, an das diese benutzerdefinierte Anwendung Traffic sendet.",
              webAppDestination: "Der Hostname des Ziels, an das diese benutzerdefinierte Anwendung Traffic sendet.",
              port: "Der von dieser benutzerdefinierten Anwendung verwendete Port.",
              firstPort: "OPTIONAL: Der erste Port im Bereich der von dieser benutzerdefinierten Anwendung verwendeten Ports.",
              lastPort: "OPTIONAL: Der letzte Port im Bereich der von dieser benutzerdefinierten Anwendung verwendeten Ports."
            }
          },
          category: "Kategorie",
          descriptions: {
            category: "Die Kategorie, der diese benutzerdefinierte Anwendung zuzuordnen ist."
          }
        },
        delete: {
          title: "L\xF6schen {{name}}",
          body: "Sind Sie sicher, dass Sie diese benutzerdefinierte Anwendung l\xF6schen m\xF6chten?"
        }
      },
      sites: {
        auditLog: {
          tabTitle: "Audit-Protokoll",
          pageTitle: "Audit-Protokoll"
        },
        connectionHistory: {
          tabTitle: "Letzte Verbindungen",
          pageTitle: "Letzte Verbindungen",
          waitingForConnections: "Verbinden mit der Site. Es kann bis zu 2 Minuten dauern, bis eine Verbindung zustande kommt. Bitte warten...",
          emptyData: "Keine letzten Verbindungen...",
          pauseRequestsButton: "Pause",
          resumeRequestsButton: "Wiederaufnahme"
        },
        dashboard: {
          tabTitle: "Dashboard",
          statusCardTitle: "Status",
          servicesCardTitle: "Dienste",
          licensesCardTitle: "Lizenzen",
          locationsCardTitle: "Karte",
          gateway: {
            tabTitle: "Edge Service",
            name: "Name",
            region: "Region",
            hubName: "Hub",
            vwan: "VWAN",
            size: "Gr\xF6\xDFe"
          },
          siteStatus: {
            title: "Information",
            model: "Modell",
            licenses: "Lizenzen",
            serials: "Seriennummer(n)",
            uptime: "Aktiv seit",
            time: "Aktuelle Zeit",
            release: "Release",
            hostname: "Hostname"
          },
          licenses: {
            type: "Typ",
            state: "Zustand",
            status: "Status",
            expirationDate: "Ablaufdatum",
            serial: "Serie",
            noData: "k. A."
          },
          boxResources: {
            title: "Ressourcen",
            cpuLoad: "CPU",
            cpuLoadError: "Die CPU hat einen Fehler erkannt. Bitte starten Sie die Appliance neu. Wenn dieser Fehler anh\xE4lt, wenden Sie sich an den Support.",
            memory: "Speicher",
            diskInformation: "Festplatte",
            diskInformationError: "Auf einer der Festplattenpartitionen wurde ein Fehler erkannt. Bitte starten Sie die Appliance neu. Wenn dieser Fehler anh\xE4lt, wenden Sie sich an den Support."
          },
          interfaces: {
            title: "Schnittstellen",
            status: "Status",
            name: "Name",
            speed: "Geschwindigkeit",
            duplex: "Duplex",
            errors: "Fehler",
            state: {
              ok: "OK",
              warning: "Warnung",
              error: "Fehler",
              unknown: "Unbekannt",
              loading: " "
            }
          },
          deploymentEvents: {
            title: "Einsatz-Ereignisse",
            state: "Zustand",
            status: "Status",
            created: "Datum"
          },
          deploymentStatus: {
            title: "Einsatz-Status {{serial}}",
            status: "Status",
            action: "Aktion",
            message: "Nachricht",
            registered: "Registriert",
            lastSeen: "Zuletzt angesehen",
            completed: "Abgeschlossen",
            localIp: "Lokale IP",
            remoteIp: "\xD6ffentliche IP",
            siteDeploying: "Laufender Einsatz"
          },
          deploymentLocation: {
            title: "Einsatzort"
          },
          firmware: {
            tabTitle: "Firmware",
            version: "Version",
            hotfixes: "Installierte Hotfixes",
            window: "N\xE4chstes Update",
            nextUpdate: "Von {{from}} bis {{to}}"
          },
          hotfixes: {
            tabTitle: "Hotfixes",
            status: "Status",
            name: "Name",
            state: {
              error: "Fehler",
              ok: "OK",
              unknown: "Unbekannt",
              warning: "Ausstehend",
              loading: " "
            }
          },
          updates: {
            tabTitle: "Konfigurations-Updates",
            lastUpdate: "Letztes Update",
            lastSeen: "Zuletzt angesehen",
            remoteIp: "Remote IP"
          },
          restart: {
            buttonLabel: "Neustart",
            box: {
              menuChoice: "Reboot Site",
              dialogTitle: "Reboot Site",
              confirmMessage: "Zum Neustart der Site auf BEST\xC4TIGEN klicken, dies kann bis zu 5 Minuten dauern. <0/><0/> Sind Sie sicher, dass Sie fortfahren wollen?",
              confirmMessage_plural: "Zum Neustart beider Appliances in Ihrem HA-Paar auf BEST\xC4TIGEN klicken, dies kann bis zu 5 Minuten dauern. <0/> Dies wirkt sich nur auf Anwendungen aus, die als online gemeldet sind. <0/><0/> Sind Sie sicher, dass Sie fortfahren wollen?"
            },
            vpn: {
              menuChoice: "Neustart VPN Service",
              dialogTitle: "Neustart VPN Service",
              confirmMessage: "Zum Neustart des VPN Service f\xFCr dieser Appliance auf BEST\xC4TIGEN klicken, dies kann bis zu 5 Minuten dauern. <0/><0/> Sind Sie sicher, dass Sie fortfahren wollen?",
              confirmMessage_plural: "Zum Neustart des VPN Service beider Appliances in Ihrem HA-Paar auf BEST\xC4TIGEN klicken, dies kann bis zu 5 Minuten dauern. <0/> Dies wirkt sich nur auf Appliances aus, die als online gemeldet sind. <0/><0/> Sind Sie sicher, dass Sie fortfahren wollen?"
            },
            firmware: {
              menuChoice: "Neustart Firmware",
              dialogTitle: "Neustart Firewall Firmware",
              confirmMessage: "Zum Neustart der Firmware f\xFCr diese Appliance auf BEST\xC4TIGEN klicken, dies kann bis zu 5 Minuten dauern. <0/><0/> Sind Sie sicher, dass Sie fortfahren wollen?",
              confirmMessage_plural: "Zum Neustart der Firmware f\xFCr beide Appliances in Ihrem HA-Paar auf BEST\xC4TIGEN klicken, dies kann bis zu 5 Minuten dauern. <0/> Dies wirkt sich nur auf Appliances aus, die als online gemeldet sind. <0/><0/> Sind Sie sicher, dass Sie fortfahren wollen?"
            }
          }
        },
        delete: {
          body: "Sind Sie sicher, dass Sie dieses Site Device l\xF6schen m\xF6chten?",
          title: "Site Device l\xF6schen {{name}}"
        },
        dialog: {
          create: {
            buttonText: "Neue Site",
            sc: {
              createSC: "Neue IoT-Appliance",
              dialogTitle: "Neue IoT-Appliance",
              menuItemDisabled: "IoT-Appliance ben\xF6tigt einen Edge Service"
            },
            tvt: {
              createTVT: "Neue T/VT-Appliance",
              dialogTitle: "Neue Site"
            }
          },
          wans: {
            sectionTitle: "WANs",
            message: "Ihre Appliance verf\xFCgt \xFCber insgesamt {{portsAvailable}} Ports f\xFCr WAN- und LAN-Zuweisungen.",
            message2_site: "Wie viele WAN-Verbindungen m\xF6chten Sie f\xFCr diese Site konfigurieren?",
            message2_gateway: "Wie viele WAN-Verbindungen m\xF6chten Sie f\xFCr diesen Edge Service konfigurieren?",
            size: "Anzahl der WANs",
            setup: "WAN-Link konfigurieren {{step}}",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtual LAN ID",
            type: "Typ",
            providerPinning: "Provider Pinning",
            address: "IP-Adresse",
            netmask: "Netzmaske",
            gateway: "Gateway",
            pin: "SIM Pin f\xFCr {{serial}}",
            invalidPinFormat: "SIM Pin sollte 4 Ziffern haben",
            SCmessage: "Ihre Appliance verf\xFCgt \xFCber einen einzigen zu konfigurierenden WAN Port.",
            SCmessage_failover: "Ihre Appliance kann bis zu 2 WAN-Verbindungen haben (eine prim\xE4re und eine Failover-Verbindung).",
            SCconfigureFailover: "M\xF6chten Sie einen Failover f\xFCr diese Site konfigurieren?",
            failover: "WAN Failover",
            setupSCPrimary: "Prim\xE4re WAN-Verbindung konfigurieren",
            setupSCFailover: "Failover WAN-Verbindung konfigurieren",
            descriptions: {
              name: "Eindeutiger Name f\xFCr diese WAN-Verbindung",
              port: "Appliance-Port, der f\xFCr diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual-LAN-ID f\xFCr das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit Virtual-LAN-Unterst\xFCtzung besteht).",
              type: "Netzwerk, das f\xFCr diese Verbindung verwendet soll.",
              providerPinning: "Providergruppe, mit der diese Verbindung verkn\xFCpft werden soll. Providergruppen werden in den SD-WAN-Richtlinien verwendet, um den Traffic-Fluss und das Verhalten zu definieren.",
              address: "Die dem Port zugewiesene IP-Adresse.",
              netmask: "Network CIDR Netmask Suffix f\xFCr diese Verbindung.",
              gateway: "Mit dieser Verbindung verkn\xFCpfte Gateway IP-Adresse.",
              pin: "Pers\xF6nliche Identifikationsnummer f\xFCr die in dieser Appliance installierte SIM-Karte."
            }
          },
          lans: {
            sectionTitle: "LANs",
            message: "Ihre Appliance verf\xFCgt \xFCber insgesamt {{portsAvailable}} Ports f\xFCr WAN- und LAN-Zuweisungen, von denen Sie {{wans}} an WAN zugewiesen haben.",
            SCmessage: "Ihre Appliance verf\xFCgt \xFCber insgesamt {{portsAvailable}} LAN Ports.",
            message2: "Wie viele LAN-Verbindungen m\xF6chten Sie f\xFCr diese Site konfigurieren?",
            size: "Anzahl der LANs",
            setup: "LAN-Verbindung konfigurieren {{step}}",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtual LAN ID",
            address: "IP-Adresse",
            netmask: "Netzmaske",
            dhcpEnable: "DHCP Server",
            dhcpDescription: "Beschreibung",
            dhcpFirstIp: "Erste IP-Adresse",
            dhcpLastIp: "Letzte IP-Adresse",
            dhcpDomain: "DHCP Domain",
            dhcpSectionTitle: "DHCP Konfiguration",
            wifiSectionTitle: "WiFi Konfiguration",
            wifi: {
              ssid: "SSID",
              securityMode: "Sicherheits-Modus",
              passphrase: "Passphrase",
              passphraseConfirm: "Passphrase best\xE4tigen"
            },
            descriptions: {
              dhcpEnable: "Aktiviert den DHCP-Server f\xFCr diese LAN-Verbindung.",
              name: "Eindeutiger Name f\xFCr diese LAN-Verbindung",
              port: "Appliance-Port, der f\xFCr diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual-LAN-ID f\xFCr das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit Virtual-LAN-Unterst\xFCtzung besteht).",
              address: "Die dem Port zugewiesene IP-Adresse.",
              netmask: "Network CIDR Netmask Suffix, das f\xFCr das erstellte Netzwerk verwendet werden soll.",
              dhcpDescription: "Benutzerdefinierte Beschreibung f\xFCr dieses DHCP Network.",
              dhcpFirstIp: "Erste IP-Adresse, die diesem DHCP Network zugewiesen werden kann.",
              dhcpLastIp: "Letzte IP-Adresse, die diesem DHCP Network zugewiesen werden kann.",
              dhcpDomain: "Domain Name f\xFCr diesen DHCP Service.",
              wifi: {
                ssid: "SSID des WLAN-Netzwerks.",
                securityMode: "WLAN Sicherheits-Modus, den Sie verwenden wollen.",
                passphrase: "Passwort f\xFCr das Netzwerk."
              }
            }
          },
          advanced: {
            sectionTitle: "Erweiterte Einstellungen",
            updateWindowTZ: "Zeitzone Update-Fenster",
            primaryDns: "Prim\xE4rer DNS",
            secondaryDns: "Sekund\xE4rer DNS",
            descriptions: {
              updateWindowTZ: "Die Zeitzone des Standorts, die f\xFCr die Bestimmung des Update-Fensters f\xFCr dieses Site Device verwendet wird.",
              primaryDns: "Prim\xE4re DNS-Adresse manuell einstellen.",
              secondaryDns: "Sekund\xE4re DNS-Adresse manuell einstellen."
            }
          },
          submit: {
            message: "Bitte \xFCberpr\xFCfen Sie die Angaben zu Ihrer Site und klicken Sie auf Speichern",
            name: "Name",
            description: "Beschreibung",
            serial: "Serie",
            gateway: "Edge Service",
            noGateway: "(Keiner)",
            timezone: "Zeitzone",
            status: "Verbindungs-Status",
            peeringAddress: "Peering Adresse",
            wan: "WAN",
            lan: "LAN",
            primaryDns: "Prim\xE4rer DNS",
            secondaryDns: "Sekund\xE4rer DNS"
          },
          result: {
            siteCreated: "Site wurde erfolgreich erstellt",
            connectPort: "Bitte stellen Sie sicher, dass Port <0>{{port}}</0> der Appliance unten an eine DHCP-f\xE4hige Internetverbindung angeschlossen ist, damit die Appliance automatisch konfiguriert werden kann. Wenn Sie einen anderen Port verwenden m\xF6chten, kann dieser direkt auf der Appliances konfiguriert werden.",
            connectPortSc: "Bitte stellen Sie sicher, dass der WAN Port der Appliance unten an eine DHCP-f\xE4hige Internetverbindung angeschlossen ist, damit die Appliance automatisch konfiguriert werden kann.",
            connectPort_plural: "Bitte stellen Sie sicher, dass Port <0>{{port}}</0> der Appliance unten an eine DHCP-f\xE4hige Internetverbindung angeschlossen ist, damit Ihre Appliances automatisch konfiguriert werden k\xF6nnen. Wenn Sie einen anderen Port verwenden m\xF6chten, kann dieser direkt auf den Appliances konfiguriert werden.",
            HAMessage: "Schlie\xDFen Sie Port <0>P1</0> beider Appliances direkt mit einem Netzwerkkabel an. HA Sync startet automatisch.",
            campus: "Weitere Informationen finden Sie im <0>Barracuda Campus</0>",
            serial: "Serie: {{serial}}",
            nonstandardPort: "Warnung: Diese Konfiguration verwendet einen nicht-standardm\xE4\xDFigen Port<0>{{ports}}</0> Bitte stellen Sie sicher, dass dies auf Threr virtuellen Appliance aktiviert ist.",
            nonstandardPort_plural: "Warnung: Diese Konfiguration verwendet nicht-standardm\xE4\xDFigen Ports<0>{{ports}}</0> Bitte stellen Sie sicher, dass diese auf Threr virtuellen Appliance aktiviert sind."
          },
          steps: {
            result: "Abgeschlossen",
            submit: "Zusammenfassung"
          },
          types: {
            dynamic: "Dynamisch (DHCP)",
            configure_on_branch: "Im Branch konfiguriert"
          },
          validation: {
            duplicateNetwork: "Ein Netzwerk, das mit dieser Port/Virtual LAN ID \xFCbereinstimmt, wurde bereits konfiguriert.",
            duplicateWwan: "Es kann nur ein WWAN konfiguriert werden",
            duplicateSCwan: "Es kann nur ein dynamisches oder statisches WAN konfiguriert werden",
            duplicateExpressRoute: "Es kann nur eine Express Route konfiguriert werden",
            duplicateName: "Name wird bereits f\xFCr eine andere Verbindung verwendet",
            secondaryDns: "Sollte nicht eingestellt werden, wenn kein prim\xE4rer DNS vorhanden",
            availableAsn: "ASN-Wert nicht verf\xFCgbar"
          }
        },
        liveLog: {
          tabTitle: "Live-Verbindungen",
          pageTitle: "Live-Verbindungen",
          waitingForConnections: "Verbinden mit der Site. Es kann bis zu 2 Minuten dauern, bis eine Verbindung zustande kommt. Bitte warten...",
          emptyData: "Derzeit keine Live-Verbindungen aktiv...",
          pauseRequestsButton: "Pause",
          resumeRequestsButton: "Wiederaufnahme"
        },
        menuTitle: "SITES",
        pageTitle: "Sites",
        settings: {
          tabTitle: "Einstellungen",
          basic: {
            tabTitle: "Grundlegende Informationen",
            name: "Name",
            model: "Modell",
            gateway: "Edge Service",
            serials: "Seriennummer(n)",
            descriptions: {
              gateway: "Barracuda SecureEdge Edge Service, mit dem diese Site verbunden ist.",
              name: "Eindeutiger Name f\xFCr Ihre Site.",
              model: "Appliance-Modell f\xFCr diese Site.",
              serials: "Appliance-Seriennummer(n) f\xFCr diese Site."
            },
            serialEdit: {
              table: {
                status: "Status",
                serial: "Seriennummer",
                add: "Zweite hinzuf\xFCgen"
              },
              addTitle: "Seriennummer hinzuf\xFCgen",
              editTitle: "Seriennummer tauschen",
              deleteTitle: "Seriennummer entfernen",
              edit: "W\xE4hlen Sie unten die Seriennummer des Ersatz-Appliance aus, das Sie an diese Site anschlie\xDFen m\xF6chten. Eine Appliance kann nur durch ein andere Appliance desselben Modells ausgetauscht werden.",
              add: "Wenn Sie eine zweite Appliance an Ihre Site anschlie\xDFen, wird die Hochverf\xFCgbarkeitsfunktion aktiviert. Bitte w\xE4hlen Sie unten die Seriennummer Ihrer Appliance aus.",
              delete1: "Sind Sie sicher, dass Sie die Seriennummer {{serial}} von dieser Site l\xF6schen wollen?",
              delete2: "Dadurch wird die Hochverf\xFCgbarkeitsfunktion entfernt und die betreffende Appliance deaktiviert.",
              save: "Die \xC4nderungen werden erst nach dem Speichern wirksam.",
              saveDelete: "Nach dem Speichern k\xF6nnen Sie die Appliance entfernen.",
              saveAdd: "Nach dem Speichern k\xF6nnen Sie die neue Appliance anschlie\xDFen, indem Sie zun\xE4chst Port <0>P4</0> der Appliance an eine DHCP-f\xE4hige Internetverbindung anschlie\xDFen, damit die Appliance automatisch konfiguriert werden kann. Wenn Sie einen anderen Port verwenden m\xF6chten, kann dieser direkt auf der Appliance konfiguriert werden.",
              saveEdit: "Nach dem Speichern k\xF6nnen Sie die alte Appliance entfernen. Dann k\xF6nnen Sie die neue Appliance anschlie\xDFen, indem Sie zun\xE4chst Port <0>P4</0> der Appliance an eine DHCP-f\xE4hige Internetverbindung anschlie\xDFen, damit die Appliance automatisch konfiguriert werden kann. Wenn Sie einen anderen Port verwenden m\xF6chten, kann dieser direkt auf der Appliance konfiguriert werden.",
              saveHA: "Verbinden Sie au\xDFerdem Port <0>P1</0> der neuen Appliance und der vorhandenen Appliance direkt mit einem Netzwerkkabel. HA Sync startet automatisch.",
              saveEditSecondary: "Die alte Appliance wurde f\xFCr HA konfiguriert. Bevor es wieder verwendet werden kann, muss ein Re-Imaging erfolgen.",
              saveDeleteSecondary: "Die Appliance wurde f\xFCr HA konfiguriert. Bevor es wieder verwendet werden kann, muss ein Re-Imaging erfolgen.",
              saveDeletePrimary: "Die andere mit dieser Site verbundene Appliance wurde f\xFCr HA konfiguriert. Nach dem Speichern muss ein Re-Imaging erfolgen, damit es sich selbst als Nicht-HA-Appliance rekonfigurieren kann. Dies bedeutet eine gewisse Ausfallzeit f\xFCr Ihre Site, bis dieser Prozess abgeschlossen ist."
            }
          },
          wan: {
            tabTitle: "WAN",
            addTitle: "Neues WAN Interface hinzuf\xFCgen",
            addButtonText: "WAN Interface hinzuf\xFCgen",
            editTitle: "WAN Interface bearbeiten",
            deleteTitle: "WAN Interface l\xF6schen",
            deleteMessage: "Sind Sie sicher, dass Sie dieses WAN Interface l\xF6schen wollen? <0> Die L\xF6schung wird erst beim Speichern wirksam.",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtual LAN ID",
            type: "Typ",
            providerPinning: "Provider Pinning",
            address: "IP-Adresse",
            netmask: "Netzmaske",
            gateway: "Gateway",
            pin: "SIM Pin f\xFCr {{serial}}",
            scPin: "SIM Pin",
            invalidPinFormat: "SIM Pin sollte 4 Ziffern haben",
            portWarning: "Die ausgegrauten Zeilen oben zeigen an, dass der ausgew\xE4hlte Port nicht auf der Appliance aktiviert wurde.",
            failover: "HA Failover",
            network: "Netzwerk",
            doublePort: "Port",
            doubleVirtualLanId: "Virtual LAN ID",
            doubleAddress: "IP-Adresse",
            doubleNetmask: "Netzmaske",
            virtualTransferNetwork: "Virtual Transfer Network",
            asn: "Express Route ASN",
            expressRoute: "Express Route",
            primary: "Prim\xE4r",
            secondary: "Sekund\xE4r",
            validatePublicIp: "Muss f\xFCr Benutzer-Konnektivit\xE4t eine \xF6ffentliche IP sein",
            descriptions: {
              name: "Eindeutiger Name f\xFCr diese WAN-Verbindung",
              port: "Ger\xE4te-Port, der f\xFCr diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual LAN ID f\xFCr das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit Virtual-LAN-Unterst\xFCtzung besteht).",
              type: "Netzwerk, das f\xFCr diese Verbindung verwendet soll.",
              providerPinning: "Providergruppe, mit der diese Verbindung verkn\xFCpft werden soll. Providergruppen werden in den SD-WAN-Richtlinien verwendet, um den Traffic-Fluss und das Verhalten zu definieren.",
              address: "Die dem Port zugewiesene IP-Adresse.",
              netmask: "Network CIDR Netmask Suffix f\xFCr diese Verbindung.",
              gateway: "Mit dieser Verbindung verkn\xFCpfte Gateway IP-Adresse.",
              pin: "Pers\xF6nliche Identifikationsnummer f\xFCr die in diesem Ger\xE4t installierte SIM-Karte.",
              failover: "Falls aktiviert, wird der Port \xFCberwacht und ein HA-Failover-Ereignis durchgef\xFChrt, wenn die Verbindung unterbrochen wird. Nur auf einer HA-f\xE4higen Website verf\xFCgbar.",
              doublePort: "Die f\xFCr die prim\xE4ren und sekund\xE4ren Verbindungen zu verwendenden Appliance-Ports.",
              doubleVirtualLanId: "Optionale Virtual LAN ID f\xFCr die prim\xE4ren und sekund\xE4ren Verbindungen (wird verwendet, wenn eine Verbindung zu einem Switch mit Virtual LAN-Unterst\xFCtzung besteht).",
              doubleAddress: "Die den prim\xE4ren und sekund\xE4ren Verbindungen zuzuweisende IP-Adresse. Dabei sollte es sich um die erste nutzbare IP-Adresse der in Azure f\xFCr jede Verbindung konfigurierten Bereiche handeln (wenn z. B. 172.16.0.4/30 in Azure konfiguriert ist, ist 172.16.0.5 die einzugebende IP-Adresse).",
              doubleNetmask: "Network CIDR Netmask Suffix f\xFCr die prim\xE4ren und sekund\xE4ren Verbindungen.",
              asn: "Automatisch generierte BGP-ASN f\xFCr diese Verbindung. Dies f\xFChrt nicht zu Konflikten mit anderen ASNs, die innerhalb des Cloud Gen WAN-Service verwaltet werden.",
              virtualTransferNetwork: "Netzwerk, das zur Reservierung von IPs f\xFCr die Virtual Transfer Interfaces verwendet wird."
            }
          },
          lan: {
            tabTitle: "LAN",
            sectionTitle: "LAN Interfaces",
            addTitle: "Neues LAN Interface hinzuf\xFCgen",
            addButtonText: "LAN Interface hinzuf\xFCgen",
            editTitle: "LAN Interface bearbeiten",
            deleteTitle: "LAN Interface l\xF6schen",
            deleteMessage1: "Sind Sie sicher, dass Sie dieses LAN Interface l\xF6schen wollen? Dies hat folgenden Effekt:",
            deleteMessage2: "Alle dazugeh\xF6rigen DHCP-Konfigurationen und Reservierungen werden entfernt.",
            deleteMessage3: "Alle Sicherheitsregeln, die dieses LAN Interface verwenden, werden aktualisiert oder entfernt.",
            deleteMessage4: "Die L\xF6schung wird erst beim Speichern wirksam.",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtual LAN ID",
            address: "IP-Adresse",
            netmask: "Netzmaske",
            dhcpEnable: "DHCP Server",
            dhcpFirst: "Erste IP-Adresse",
            dhcpLast: "Letzte IP-Adresse",
            dhcpDomain: "DHCP Domain",
            dhcpRange: "DHCP-Bereich",
            portWarning: "Die ausgegrauten Zeilen oben zeigen an, dass der ausgew\xE4hlte Port auf der Appliance nicht aktiviert wurde.",
            failover: "HA Failover",
            descriptions: {
              name: "Eindeutiger Name f\xFCr diese LAN-Verbindung",
              port: "Ger\xE4te-Port, der f\xFCr diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual LAN ID f\xFCr das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit Virtual-LAN-Unterst\xFCtzung besteht).",
              address: "Die dem Port zugewiesene IP-Adresse.",
              netmask: "Network CIDR Netmask Suffix, das f\xFCr das erstellte Netzwerk verwendet werden soll.",
              dhcpEnable: "DHCP-Server auf der Appliance f\xFCr diese Verbindung aktivieren.",
              dhcpFirst: "Die erste f\xFCr die DHCP-Zuweisung verf\xFCgbare IP. Muss sich innerhalb des durch die Port-IP und Netzmaske definierten Netzwerks befinden.",
              dhcpLast: "Die letzte f\xFCr die DHCP-Zuweisung verf\xFCgbare IP. Muss sich innerhalb des durch die Port-IP und Netzmaske definierten Netzwerks befinden.",
              dhcpDomain: "Der dem DHCP Server zugewiesene Domain Name.",
              failover: "Falls aktiviert, wird der Port \xFCberwacht und ein HA-Failover-Ereignis durchgef\xFChrt, wenn die Verbindung unterbrochen wird. Nur auf einer HA-f\xE4higen Website verf\xFCgbar."
            }
          },
          route: {
            tabTitle: "Route",
            sectionTitle: "Statische Routen",
            addTitle: "Neue statische Route hinzuf\xFCgen",
            addButtonText: "Statische Route hinzuf\xFCgen",
            editTitle: "Statische Route bearbeiten",
            deleteTitle: "Statische Route l\xF6schen",
            deleteMessage: "Sind Sie sicher, dass Sie diese statische Route l\xF6schen m\xF6chten? Die L\xF6schung wird erst beim Speichern wirksam.",
            name: "Name",
            address: "Ziel-Netzwerk",
            netmask: "Netzmaske",
            gateway: "Gateway",
            descriptions: {
              name: "Eindeutiger Name f\xFCr diese Route.",
              address: "Ziel-Netzwerk-Adresse f\xFCr diese Route.",
              netmask: "CIDR Netmask Suffix f\xFCr das Ziel-Netzwerk.",
              gateway: "F\xFCr diese Route zu verwendendes Gateway."
            }
          },
          dhcp: {
            tabTitle: "DHCP",
            networksSectionTitle: "DHCP-Netzwerke",
            leasesSectionTitle: "DHCP Leases",
            reservationsSectionTitle: "DHCP-Reservierungen",
            dhcpEnable: "DHCP Service aktivieren",
            addNetworkTitle: "Neues DHCP-Netzwerk hinzuf\xFCgen",
            addNetworkButtonText: "Netzwerk hinzuf\xFCgen",
            editNetworkTitle: "DHCP-Netzwerk bearbeiten",
            deleteNetworkTitle: "DHCP-Netzwerk l\xF6schen",
            deleteNetworkMessage: "Sind Sie sicher, dass Sie dieses DHCP-Netzwerk l\xF6schen wollen? Die L\xF6schung wird erst beim Speichern wirksam.",
            networks: {
              networkSectionTitle: "Netzwerk",
              basicSectionTitle: "Basic",
              basicTabTitle: "Basic",
              advancedSectionTitle: "Erweitert",
              advancedTabTitle: "Erweitert",
              enable: "Aktiver DHCP-Bereich",
              port: "LAN Name",
              description: "Beschreibung",
              firstIp: "Erste IP-Adresse",
              lastIp: "Letzte IP-Adresse",
              domain: "Domain Name",
              dnsServers: "DNS Servers",
              bootFileName: "Boot File Name",
              defaultLeaseTime: "Standard-Leasingdauer",
              maxLeaseTime: "Maximale Leasingdauer",
              winsServers: "WINS Servers",
              tftpHostname: "TFTP Host Name",
              tftpIpAddress: "TFTP IP-Adresse",
              vendorOptions: "Anbieter-Optionen",
              validateMaxLeaseTime: "Darf nicht k\xFCrzer als die Standard-Leasingdauer sein"
            },
            blockUnknownMac: "Nur bekannte MAC-Adressen zulassen",
            addReservationTitle: "Neue DHCP-Reservierung hinzuf\xFCgen",
            addReservationButtonText: "Reservierung hinzuf\xFCgen",
            editReservationTitle: "DHCP-Reservierung bearbeiten",
            deleteReservationTitle: "DHCP-Reservierung l\xF6schen",
            deleteReservationMessage: "Sind Sie sicher, dass Sie diese DHCP-Reservierung l\xF6schen wollen? Die L\xF6schung wird erst beim Speichern wirksam.",
            leases: {
              reservationSectionTitle: "Leases",
              port: "LAN Name",
              ip: "IP-Adresse",
              mac: "MAC-Adresse",
              reserve: "Reserve IP",
              noLeases: "Derzeit keine Leases aktiv. Es kann bis zu 2 Minuten dauern, bis neue Leases angezeigt werden."
            },
            reservations: {
              reservationSectionTitle: "Reservierung",
              name: "Name",
              description: "Beschreibung",
              port: "LAN Name",
              ip: "IP-Adresse",
              mac: "MAC-Adresse",
              clientId: "Client ID",
              hostname: "Hostname",
              validateMac: "Muss eine g\xFCltige MAC-Adresse sein",
              validateHostname: "Darf nur alphanumerischen Zeichen, '-' oder '_\u2018 Zeichen enthalten.",
              duplicate: "Muss innerhalb des LAN eindeutig sein",
              duplicateName: "Muss eindeutig sein"
            },
            descriptions: {
              dhcpEnable: "DHC Service Provider f\xFCr diese Site aktivieren.",
              blockUnknownMac: "Wenn diese Option aktiviert ist, k\xF6nnen nur die in der folgenden Tabelle aufgef\xFChrten MAC-Adressen den DHCP-Dienst nutzen.",
              networks: {
                enable: "DHCP-Server auf der Appliance f\xFCr diese Verbindung aktivieren.",
                description: "Optionale Beschreibung f\xFCr diese DHCP-Serverkonfiguration angeben.",
                port: "LAN Interface, auf dem dieses DHCP-Netzwerk konfiguriert werden soll.",
                firstIp: "Die erste f\xFCr die DHCP-Zuweisung verf\xFCgbare IP. Muss sich innerhalb des durch die Port-IP und Netzmaske definierten Netzwerks befinden.",
                lastIp: "Die letzte f\xFCr die DHCP-Zuweisung verf\xFCgbare IP. Muss sich innerhalb des durch die Port-IP und Netzmaske definierten Netzwerks befinden.",
                domain: "Dem DHCP Server zugewiesenen Domain Name optional festlegen.",
                dnsServers: "Liste von zu verwendenden DNS-Servern optional festlegen.",
                bootFileName: "Den Namen einer vom Client geladenen Boot-Datei optional festlegen. Er sollte f\xFCr jedes Datei\xFCbertragungsprotokoll, das der Client zum Laden der Datei verwenden kann, erkennbar sein.",
                defaultLeaseTime: "Standardl\xE4nge in Sekunden, die einem Lease zugewiesen wird.",
                maxLeaseTime: "Maximale L\xE4nge in Sekunden, die einem Lease zugewiesen wird.",
                winsServers: "Eine oder mehr WINS Server IP-Adressen optional hinzuf\xFCgen.",
                tftpHostname: "Den Hostname eines TFTP-Servers optional festlegen.",
                tftpIpAddress: "Die IP-Adresse eines TFTP-Servers optional festlegen.",
                vendorOptions: "Eine oder mehrere durch ';' getrennte Anbieter-Optionen optional festlegen."
              },
              reservations: {
                name: "Zur Identifizierung dieser Reservierung verwendeter Name.",
                description: "Optionale Beschreibung f\xFCr diese Reservierung.",
                port: "LAN Interface, auf dem diese Reservierung durchgef\xFChrt werden soll.",
                ip: "F\xFCr den Client zu reservierende IP.",
                mac: "MAC-Adresse zur Identifizierung des Client.",
                clientId: "Client Identifier, der den Host-Client bei der Anfrage nach einer IP-Adresse identifiziert.",
                hostname: "Dieser IP zuzuweisender Hostname."
              }
            }
          },
          advanced: {
            tabTitle: "Erweiterte Einstellungen",
            primaryDns: "Prim\xE4rer DNS",
            secondaryDns: "Sekund\xE4rer DNS",
            rootPassword: "Root-Passwort",
            rootPasswordConfirmation: "Root-Passwort best\xE4tigen",
            updateWindowTZ: "Zeitzone Update-Fenster",
            disableOMS: "Log Analytics deaktivieren",
            disableSecurityPolicy: "Security deaktivieren",
            disableIpsPolicy: "IPS deaktivieren",
            omsNote: "Damit werden die Standardeinstellungen von Log Analytics \xFCberschrieben.",
            descriptions: {
              updateWindowTZ: "Die Zeitzone f\xFCr die Site, die f\xFCr die Bestimmung des Update-Fensters f\xFCr diese Site verwendet wird.",
              primaryDns: "Prim\xE4re DNS-Adresse manuell einstellen.",
              secondaryDns: "Sekund\xE4re DNS-Adresse manuell einstellen.",
              rootPassword: "F\xFCr diese Site verwendetes Root-Benutzer-Passwort.",
              disableOMS: "Streaming von Protokollen an Azure Log Analytics f\xFCr diese Site deaktivieren.",
              disableSecurityPolicy: "Alle Sicherheitsrichtlinien f\xFCr diese Site deaktivieren.",
              disableIpsPolicy: "IPS Policy f\xFCr diese Site deaktivieren."
            },
            downloadBootstrapConfigDialog: {
              button: "Schaltfl\xE4che",
              buttons: "Schaltfl\xE4chen",
              buttonLabel: "Bootstrap-Konfiguration",
              title: "Bootstrap-Konfiguration downloaden",
              dialogMessage: 'Die Appliances sollten sich automatisch mit SecureEdge verbinden und ihre Konfiguration herunterladen, vorausgesetzt, sie verf\xFCgen \xFCber eine DHCP-unterst\xFCtzte Internetverbindung.\n\nWenn keine Internetverbindung zur Verf\xFCgung steht, k\xF6nnen Sie die Appliances mit dem Tool "Barracuda Firewall Install" und einem USB-Flashspeicher konfigurieren. Eine Dokumentation f\xFCr dieses Tool finden Sie <0>hier</0>.\n\nVerwenden Sie die Schaltfl\xE4che {{button}} weiter unten, um die Konfigurationsdateien herunterzuladen. Diese Dateien sollten w\xE4hrend der Installation der Barracuda Firewall als "CGW-Konfigurationen" bereitgestellt werden.',
              dialogMessageSc: "Die Appliances sollten sich automatisch mit SecureEdge verbinden und ihre Konfiguration herunterladen, vorausgesetzt, sie verf\xFCgen \xFCber eine DHCP-unterst\xFCtzte Internetverbindung.\n\nVerwenden Sie die Schaltfl\xE4che {{button}} weiter unten, um die Konfigurationsdatei herunterzuladen.\nDiese Datei sollte in das Root-Verzeichnis eines USB-Flashspeichers kopiert werden.\nWenn der USB-Speicher beim Booten aktiv ist, wird die Konfiguration \xFCbernommen.",
              downloadButtonLabel: "Download",
              serial: "Seriennummer {{serial}}"
            }
          },
          confirm: "Einige der ausgew\xE4hlten Ports sind derzeit nicht f\xFCr diese Site aktiviert. Sind Sie sicher, dass Sie fortfahren m\xF6chten?"
        },
        table: {
          name: "Name",
          description: "Beschreibung",
          serial: "Seriennummer",
          model: "Modell",
          gateway: "Edge Service",
          cloudVirtualWan: "Cloud VWAN",
          privateCloud: "Keine",
          status: "Verbindungs-Status",
          peeringAddress: "Peering Address",
          asn: "AS-Nummer",
          lan: "LANs",
          wan: "WANs",
          linkAddresses: "Adressen",
          noSites: "Keine Sites gefunden.",
          noIot: "Keine IoT-Ger\xE4te gefunden.",
          dynamic: "Dynamisch",
          wwan: "WWAN",
          bridge: "Bridge",
          vpnSite: "Client VPN-Konfiguration downloaden.",
          vpnSiteDisabled: "Um diese Aktion durchf\xFChren zu k\xF6nnen, muss die Funktion f\xFCr Benutzerverbindungen aktiviert und ein statisches WAN f\xFCr Benutzerverbindungen zugewiesen sein.",
          noGateway: "(Keiner)",
          licenseType: "Lizenztyp",
          licenseExpiration: "Lizenzablauf"
        },
        siteNotFound: "Die von Ihnen gesuchte Site wurde nicht gefunden.",
        noData: {
          iconHeader: "Beginnen Sie mit dem Hinzuf\xFCgen einer Site!",
          textHeader: "Was ist eine Site?",
          text: "Eine Site bezeichnet einen Ort, der durch ein Site Device verbunden (und gesch\xFCtzt) ist.<0/>Ein Site Device kann als Hardware oder als virtuelle Appliance implementiert werden - je nachdem, was Ihren Anforderungen am besten entspricht.<0/>Das Hinzuf\xFCgen eines Site Device zu Ihrer Netzwerkinstallation ist ein einfacher und unkomplizierter Prozess.<0/><0/>Sie haben noch keine Site?",
          demoLicenseLink: "<0>Demo-Lizenz anfordern</0>"
        }
      },
      gateways: {
        menuTitle: "EDGE SERVICES",
        pageTitle: "Edge Services",
        noData: {
          iconHeader: "Beginnen Sie mit dem Hinzuf\xFCgen eines Edge Service!",
          textHeader: "Was ist ein Edge Service?",
          text: "Ein Edge Service fungiert als Verbindungspunkt f\xFCr Ihre Sites und Remote Access Agents.<0/> Sowohl die Sites als auch die Agents w\xE4hlen automatisch den n\xE4chstgelegenen verf\xFCgbaren Edge Service, um eine optimale Servicequalit\xE4t zu gew\xE4hrleisten."
        },
        table: {
          asn: "AS-Nummer",
          name: "Name",
          type: "Typ",
          upstream: "Upstream",
          region: "Ort",
          instances: "Instanzen",
          size: "Gr\xF6\xDFe",
          HA: "(HA)",
          upstream_gateway: "Upstream Edge Service",
          scaleUnit: "Skalierungseinheit",
          sites: "Sites",
          status: "Verbindungs-Status",
          vpnGateway: "Client VPN-Konfiguration downloaden.",
          vpnGatewayDisabled: "Um diese Aktion durchf\xFChren zu k\xF6nnen, muss die Funktion f\xFCr Benutzerverbindungen aktiviert sein.",
          privateCloud: "Private Edge Service",
          managedCloud: "Edge Service",
          noGateway: "(Keiner)",
          mbit: "MBit",
          filters: {
            sizeSelector: "Gr\xF6\xDFentyp",
            scaleUnit: "Skalierungseinheit",
            model: "Modell",
            bandwidth: "Bandbreite"
          }
        },
        dashboard: {
          locationsCardTitle: "Karte",
          tabTitle: "Dashboard",
          connectedSites: {
            tabTitle: "Verbundene Site."
          },
          firmware: {
            tabTitle: "Firmware",
            version: "Version",
            hotfixes: "Installierte Hotfixes",
            window: "N\xE4chstes Update",
            nextUpdate: "Von {{from}} bis {{to}}"
          }
        },
        liveLog: {
          tabTitle: "Live-Verbindungen",
          pageTitle: "Live-Verbindungen",
          waitingForConnections: "Verbindung mit Edge Service wird aufgebaut. Es kann bis zu 2 Minuten dauern, bis eine Verbindung zustande kommt. Bitte warten...",
          emptyData: "Derzeit keine Live-Verbindungen aktiv...",
          pauseRequestsButton: "Pause",
          resumeRequestsButton: "Wiederaufnahme"
        },
        connectionHistory: {
          pageTitle: "Letzte Verbindungen",
          tabTitle: "Letzte Verbindungen",
          waitingForConnections: "Verbindung mit Edge Service wird aufgebaut. Es kann bis zu 2 Minuten dauern, bis eine Verbindung zustande kommt. Bitte warten...",
          emptyData: "Keine letzten Verbindungen...",
          pauseRequestsButton: "Pause",
          resumeRequestsButton: "Wiederaufnahme"
        },
        delete: {
          body: "Sind Sie sicher, dass Sie diesen Edge Service l\xF6schen m\xF6chten?",
          title: "Edge Service l\xF6schen {{name}}",
          forbidden: "Um diesen Edge Service zu l\xF6schen, l\xF6schen Sie bitte zuerst alle zugeh\xF6rigen Edge Services und Sites."
        },
        dialog: {
          basic: {
            name: "Name",
            sectionTitle: "Grundlegende Informationen",
            tabTitle: "Basic"
          },
          create: {
            buttonText: "Neuer Edge Service",
            cloud: {
              menuLabel: "Edge Service f\xFCr Virtual WAN",
              dialogTitle: "Edge Service Token erstellen"
            },
            onPrem: {
              menuLabel: "Private Edge Service",
              dialogTitle: "Neuer Private Edge Service",
              serials: {
                sectionTitle: "Private Edge Service-Apps"
              }
            },
            managed: {
              menuLabel: "Edge Service",
              dialogTitle: "Neuer Edge Service",
              name: "Name",
              region: "Region",
              bandwidth: "Bandbreite",
              bandwidthAvailable: "{{availableBandwidth}} Verf\xFCgbare Mbps",
              invalidBandwidth: "Max w\xE4hlbare Bandbreite: {{availableBandwidth}} Mbit. Verringern Sie die Bandbreitenauswahl oder erwerben Sie zus\xE4tzliche Kapazit\xE4t, um diesen Edge Service zu erstellen."
            }
          },
          edit: {
            dialogTitle: "Edge Service bearbeiten"
          },
          result: {
            managedAppText: "Managed App OTP",
            message: "Kopieren Sie das folgende Token in Ihre <0>Edge Service f\xFCr Virtual WAN</0>-Installation.",
            managedAppOtp: "Dieses Token ist nur f\xFCr eine einzige Bereitstellung g\xFCltig und l\xE4uft in 30 Minuten ab."
          },
          steps: {
            result: "Abgeschlossen",
            submit: "Konfigurieren"
          },
          validation: {
            duplicateName: "Darf kein Duplikat des Edge-Service-Namens sein"
          }
        },
        settings: {
          tabTitle: "Einstellungen",
          managed: {
            bandwidth: {
              invalidBandwidth: "Max w\xE4hlbare Bandbreite: {{availableBandwidth}} Mbit. Verringern Sie die Bandbreitenauswahl oder erwerben Sie zus\xE4tzliche Kapazit\xE4t, um diesen Edge Service zu aktualisieren."
            }
          },
          labels: {
            secureInternetTraffic: "Internet Traffic aus VNETs sichern"
          },
          descriptions: {
            secureInternetTraffic: "Pr\xFCfung des Internet Traffic aus diesem virtuellen Netzwerk durch diesen Edge Service zulassen."
          },
          editBandwidthWarning: "Eine \xC4nderung der Bandbreite f\xFChrt dazu, dass der Edge Service vor\xFCbergehend nicht verf\xFCgbar ist, da er neu gestartet werden muss."
        },
        auditLog: {
          tabTitle: "Audit-Protokoll",
          pageTitle: "Audit-Protokoll"
        },
        gatewayNotFound: "Der von Ihnen gesuchte Edge Service wurde nicht gefunden."
      },
      logs: {
        pageTitle: "Logs und Berichte",
        audit: "Audit-Protokoll",
        notifications: "Benachrichtigungen",
        notificationsMessage: "Benachrichtigungen f\xFCr administrative und Systemereignisse f\xFCr alle Workspaces konfigurieren.",
        auditLog: {
          message: "Audit Logs zeigen administrative und Systemereignisse f\xFCr alle Workspaces.",
          labels: {
            status: "Status",
            created: "Erstellt",
            type: "Typ",
            event: "Name",
            serial: "Seriennummer",
            description: "Beschreibung",
            remoteAddress: "\xD6ffentliche IP",
            createdUsername: "Administrator"
          }
        },
        connectionHistory: {
          detailsTitle: "Verbindungsdetails",
          labels: {
            application: "Anwendung",
            count: "Z\xE4hler",
            destinationIP: "Ziel-IP",
            destinationPort: "Ziel-Port",
            information: "Information",
            ipProtocol: "IP-Protokoll",
            lastTime: "Letzter Zeitpunkt",
            nextHop: "NextHop",
            sourceIP: "Quell-IP",
            sourceMac: "Quell-MAC",
            state: "Zustand",
            urlCategory: "URL-Kategorie",
            user: "Benutzer"
          }
        },
        liveLog: {
          detailsTitle: "Verbindungsdetails",
          labels: {
            agentId: "Agent-ID",
            appName: "App-Kontext",
            application: "Anwendung",
            connectionTrafficState: "Verbindungsstatus",
            destinationBytesDescription: "Bytes Out",
            destinationIp: "Ziel-IP",
            destinationInterface: "Ziel-Interface",
            destinationPort: "Ziel-Port",
            idleTime: "Inaktiv seit",
            ipProtocol: "IP-Protokoll",
            protocol: "Protokoll",
            protocolId: "Protokoll-ID",
            serial: "Seriennummer",
            sourceBytesDescription: "Bytes In",
            sourceIp: "Quell-IP",
            sourcePort: "Quell-Port",
            sourceNat: "Quell-NAT",
            speedDescription: "Geschwindigkeit",
            startTime: "Startzeit",
            state: "Zustand",
            target: "Ziel",
            type: "Typ",
            totalBytesDescription: "Bytes insgesamt",
            urlCategories: "URL-Kategorien",
            user: "Benutzer",
            vpnRoute: "VPN Route"
          },
          connectionState: {
            initiate: "Aktiver One-Way Traffic",
            fail: "Inaktiver One-Way Traffic",
            sync: "Two-Way Traffic",
            closing: "Verbindung wird geschlossen",
            established: "Verbindung hergestellt",
            httpDeny: "Verbindung nicht hergestellt"
          }
        }
      },
      customCategories: {
        table: {
          name: "Name",
          description: "Beschreibung",
          details: "Details",
          detailCategories: "Kategorien ({{activeCategories.0.name}}, +{{additional}}) ",
          detailCategories_1: "Kategorien ({{activeCategories.0.name}}) ",
          detailDomains: "Eingeschl. Domains ({{domains.0}}, +{{additionalDomains}})",
          detailDomains_1: "Eingeschl. Domain ({{domains.0}})",
          domainsExcluded: "Exkludierte Domains",
          domainsIncluded: "Inkludierte Domain",
          noData: "Keine benutzerdefinierten Kategorieregeln gefunden."
        },
        dialog: {
          create: {
            buttonText: "Benutzerdefinierte Kategorie hinzuf\xFCgen",
            dialogTitle: "Neu Benutzerdefinierte Kategorie"
          },
          edit: {
            dialogTitle: "Benutzerdefinierte Kategorie bearbeiten"
          },
          name: "Name",
          description: "Beschreibung",
          details: "Details",
          includedDomains: "Inkludierte Domain",
          excludedDomains: "Exkludierte Domains",
          descriptions: {
            name: "Eindeutiger Name f\xFCr diese Regel.",
            description: "Kurze Beschreibung dieser Regel.",
            includedDomains: "Liste der von dieser Regel betroffenen Domains",
            excludedDomains: "Liste aller nicht von dieser Regel betroffenen Domains"
          },
          validation: {
            emptyCategories: "Muss mindestens eine Kategorie oder Domain enthalten"
          },
          delete: {
            title: "L\xF6schen {{name}}",
            body: "Sind Sie sicher, dass Sie diese Regel l\xF6schen m\xF6chten? Alle Sicherheitsregeln, die diese benutzerdefinierte Kategorie verwenden, werden aktualisiert oder entfernt."
          }
        }
      },
      policies: {
        menuTitle: "SD-WAN",
        sdwan: {
          tabTitle: "App-Kategorien",
          search: "App suchen",
          table: {
            category: "Kategorie",
            apps: "App",
            customApps: "Benutzerdefinierte Apps",
            priority: "Priorit\xE4t",
            action: "Aktion",
            fallback: "Fallback",
            loadBalancing: "Load Balancing",
            reset: "Reset"
          }
        },
        explicit: {
          delete: {
            title: "L\xF6schen {{name}}",
            body: "Sind Sie sicher, dass Sie diese Richtlinie l\xF6schen m\xF6chten?"
          },
          tabTitle: "\xDCberschreibungs-Kategorien",
          table: {
            order: "Auftrag",
            name: "Name",
            apps: "Apps",
            sources: "Quelle",
            user: "Benutzer",
            traffic: "Traffic-Typ",
            priority: "Priorit\xE4t",
            action: "Aktion",
            fallback: "Fallback",
            loadBalancing: "Load Balancing"
          },
          noData: {
            iconHeader: "Beginnen Sie hier mit dem Hinzuf\xFCgen einer SD-WAN-Richtlinien\xFCberschreibung!",
            labelButton: "\xDCberschreibungsrichtlinie hinzuf\xFCgen",
            text: "Ihr SecureEdge-Setup bietet einen Satz von allgemeinen SD-WAN-Richtlinien, die sofort einsatzbereit sind. <0/>Wenn Ihr Setup jedoch ein anderes SD-WAN-Profil f\xFCr bestimmte Apps erfordert, k\xF6nnen Sie hier Ihre eigenen Richtlinien definieren."
          }
        },
        dialogs: {
          explicit: {
            create: {
              buttonText: "Richtlinie hinzuf\xFCgen",
              dialogTitle: "Neue Richtlinie"
            },
            edit: {
              dialogTitle: "Richtlinie bearbeiten"
            },
            general: {
              sectionTitle: "Allgemein",
              name: "Name",
              description: "Beschreibung",
              descriptions: {
                name: "Eindeutiger Name f\xFCr diese Richtlinie.",
                description: "Kurze Beschreibung dieser Richtlinie."
              }
            },
            criteria: {
              sectionTitle: "Kriterien",
              applications: "Apps",
              sources: "Quell-IP/Netzwerk",
              trafficType: "Traffic-Typ",
              descriptions: {
                applications: "Spezifische Apps, auf die diese Richtlinie angewandt werden soll. Es muss mindestens eine Anwendung oder Quelle angegeben werden.",
                sources: "Spezifische Quell-IPs/Netzwerke, auf die diese Richtlinie angewandt werden soll. Es muss mindestens eine Anwendung oder Quelle angegeben werden.",
                trafficType: "Definieren Sie optional den spezifischen Traffic-Typ, auf den diese Richtlinie angewandt werden soll."
              }
            },
            action: {
              sectionTitle: "Aktion",
              priority: "Priorit\xE4t",
              action: "Aktion",
              fallback: "Fallback",
              loadBalancing: "Load Balancing",
              forwardErrorCorrection: {
                label: "Letzte-Meile-Optimierung",
                options: {
                  on: "An",
                  off: "Aus"
                }
              },
              descriptions: {
                priority: "Priorit\xE4tsstufe, die f\xFCr den von dieser Richtlinie erfassten Traffic gelten soll.",
                action: "Aktion, die f\xFCr den von dieser Richtlinie erfassten Traffic durchgef\xFChrt werden soll.",
                fallback: "Fallback Behavior, das f\xFCr den von dieser Richtlinie erfassten Traffic angewandt werden soll.",
                loadBalancing: "Load Balancing Behavior, das f\xFCr den von dieser Richtlinie erfassten Traffic angewandt werden soll.",
                forwardErrorCorrection: "Schaltet die Letzte-Meile-Optimierung f\xFCr den von dieser Richtlinie abgedeckten Traffic ein. Dies kann bei verlustbehafteten Netzwerken helfen, erh\xF6ht aber die Bandbreitennutzung durch die Clients."
              }
            },
            validation: {
              required: "Eine App oder Quelle ist erforderlich"
            }
          }
        }
      },
      sdwanExplicitPolicies: {
        delete: {
          title: "Delete Policy {{name}}",
          body: "Sind Sie sicher, dass Sie diese Richtlinie l\xF6schen m\xF6chten?"
        }
      },
      audit: {
        menuTitle: "AUDIT LOG",
        pageTitle: "Audit Log",
        labels: {
          created: "Erstellt",
          type: "Typ",
          event: "Name",
          serial: "Seriennummer",
          description: "Beschreibung",
          status: "Status",
          createdUsername: "Administrator",
          remoteAddress: "\xD6ffentliche IP"
        },
        filter: {
          created: {
            days1: "1 Tag",
            days3: "3 Tage",
            weeks1: "1 Woche",
            weeks2: "2 Wochen",
            months1: "1 Monat",
            months2: "2 Monate",
            months3: "3 Monate",
            months6: "6 Monate",
            months9: "9 Monate",
            years1: "1 Jahr"
          }
        }
      },
      settings: {
        menuTitle: "EINSTELLUNGEN",
        pageTitle: "Einstellungen",
        tabs: {
          workspaces: {
            title: "Workspaces",
            pageTitle: "Workspaces verwalten",
            fields: {
              name: "Name",
              description: "Beschreibung",
              associations: "Sites/Edge Services"
            },
            dialog: {
              create: {
                buttonLabel: "Neuer Workspace",
                dialogTitle: "Neuer Workspace"
              },
              edit: "Workspace bearbeiten"
            },
            delete: {
              body: "Sind Sie sicher, dass Sie diesen Workspace l\xF6schen m\xF6chten?",
              title: "L\xF6schen {{name}}",
              disabled: {
                notEmpty: "Workspaces k\xF6nnen nicht gel\xF6scht werden, solange ihnen eine Site oder ein Edge Service zugeordnet ist.",
                lastWorkspace: "Sie m\xFCssen mindestens einen Workspace haben."
              }
            },
            none: "Keine Workspaces verf\xFCgbar"
          },
          workspaceSettings: {
            title: "Workspace-Einstellungen"
          },
          updates: {
            title: "Update-Fenster",
            firmware: {
              title: "Firmware-Update-Fenster",
              description: "Firmware-Update-Fenster m\xFCssen mindestens 3 Stunden lang sein und werden in der lokalen Zeitzone der Appliance ausgef\xFChrt.",
              noWindowsMessage: "Es wurden keine Update-Fenster festgelegt",
              noUpdatesMessage: "Es stehen keine Updates zur Installation bereit.",
              noAppliancesMessage: "Derzeit erfordert keine Appliance ein Update.",
              default: {
                label: "Standardm\xE4\xDFiges Update-Fenster:",
                to: "bis",
                from: "von",
                setting: "{{from}} <0>bis</0> {{to}}"
              },
              create: {
                buttonText: "Neues Update-Fenster",
                dialogTitle: "Neues Update-Fenster hinzuf\xFCgen"
              },
              edit: {
                dialogTitle: "Update-Fenster bearbeiten"
              },
              delete: {
                title: "Update-Fenster l\xF6schen",
                message: "Nach dem L\xF6schen wird f\xFCr die Site oder den Edge Service, die mit diesem Update-Fenster verbunden sind, das Standard-Update-Fenster verwendet."
              },
              fields: {
                appliance: "Site/Edge Service",
                applianceDescription: "Edge Service oder Standalone Site, der/die w\xE4hrend des Update-Fensters aktualisiert werden soll",
                from: "Von",
                fromDescription: "Beginn des Zeitraums, in dem Firmware-Updates automatisch installiert werden k\xF6nnen. Das Zeitfenster bezieht sich auf die automatisch ermittelte lokale Zeitzone des jeweiligen Edge Service oder Standalone Site.",
                to: "Bis",
                toDescription: "Ende des Zeitraums, in dem Firmware-Updates automatisch installiert werden k\xF6nnen. Das Zeitfenster bezieht sich auf die automatisch ermittelte lokale Zeitzone des jeweiligen Edge Service oder Standalone Site.",
                windowLength: "Liefert ein Firmware-Update-Fenster von {{duration}}."
              },
              updateNow: {
                buttonLabel: "Jetzt installieren",
                disabledMessage: "Keine Updates verf\xFCgbar.",
                dialogTitle: "Jetzt Updates installieren",
                dialogMessage: "Wenn Sie jetzt ein Update durchf\xFChren, werden alle geplanten Update-Fenster entfernt und alle Edge Services und Standalone Sites sofort aktualisiert. Sites mit einem Edge Service werden aktualisiert, sobald das Update ihres Edge Service abgeschlossen ist. Dies kann zu Ausfallzeiten f\xFChren."
              },
              validation: {
                insufficientWindow: "Das Update-Fenster muss mindesten 3 Stunden lang sein"
              },
              editDefault: {
                dialogTitle: "Standard-Update-Fenster bearbeiten"
              }
            },
            security: {
              title: "Sicherheits-Update-Fenster",
              window: "Hotfix-Update-Fenster",
              windowDescription: "Zeitraum, in dem Hotfix-Updates automatisch installiert werden k\xF6nnen. Das Zeitfenster bezieht sich auf die automatisch ermittelte lokale Zeitzone des jeweiligen Edge Service oder der Site, die in der Site-Konfiguration \xFCberschrieben werden kann."
            },
            hotfixUpdateWindow: "Hotfix-Update-Fenster",
            validation: {
              invalidStartTime: "Ung\xFCltige Startzeit",
              invalidEndTime: "Ung\xFCltige Endzeit"
            }
          },
          oms: {
            title: "Azure Monitor",
            enabled: "Aktiviert",
            id: "Workspace ID",
            key: "Workspace Key",
            descriptions: {
              oms: "Nur dann aktivieren, wenn Sie Microsoft Operations Management Suite (OMS) verwenden. OMS wird nicht mehr weiterentwickelt.",
              azureMonitorAgent: "Der neue Azure Monitor Agent ist der bevorzugte Agent f\xFCr Sites und Private Edge Services.<0 />(F\xFCr Log Analytics Support auf Edge Services und Edge Services f\xFCr Azure verwenden Sie bitte die OMS-L\xF6sung weiter unten)."
            },
            clientId: "Client ID",
            clientSecret: "Client Secret",
            correlationId: "Correlation ID",
            region: "Region",
            resourceGroup: "Ressourcen-Gruppe",
            subscriptionId: "Abonnement ID",
            tenantId: "Tenant ID",
            oms: "Azure Log Analytics (OMS)",
            azureMonitorAgent: "Azure Monitor Agent",
            disabled: "Deaktiviert",
            introduction: "Mit Azure Monitor k\xF6nnen Sie die von Ihren IT-Systemen und Ihrer technologischen Infrastruktur erzeugten Maschinendaten durchsuchen, analysieren und visualisieren, um mehr \xFCber Ihr Netzwerk zu erfahren. Streamen Sie Logs in Ihr Azure Monitor-Konto, um die Logdaten Ihrer Installation weiter zu verarbeiten."
          },
          global: {
            title: "Globale Einstellungen"
          },
          forwardedDomains: {
            title: "Weitergeleitete Domains",
            domains: "Domains",
            descriptions: {
              domains: "DNS Domains, die zur Aufl\xF6sung an Azure weitergeleitet werden sollten"
            },
            domain: "Domain",
            forwarders: "Server",
            button: "Hinzuf\xFCgen",
            duplicateName: "Bereits verwendeter Domain Name",
            addDomain: "Domain hinzuf\xFCgen",
            editDomain: "Domain bearbeiten",
            deleteDomain: "Domain l\xF6schen"
          },
          reportCreatorAcl: {
            title: "Report Creator",
            subMenu: "Zul\xE4ssige Netzwerke",
            description: "Geben Sie eine Liste mit privaten Netzwerken und IP-Adressen ein, in denen das Report Creator-Tool die Site Devices und Edge Services abfragen soll."
          },
          vmActivation: {
            title: "VM-Aktivierung",
            firstName: "Vorname",
            lastName: "Nachname",
            companyName: "Firmenname",
            phoneNumber: "Telefonnummer",
            address: "Adresszeile 1",
            address2: "Adresszeile 2",
            city: "Stadt",
            country: "Land",
            emailAddress: "E-Mail-Adresse",
            state: "Zustand",
            zip: "PLZ",
            descriptions: {
              firstName: "Ihr Vorname.",
              lastName: "Ihr Nachname.",
              companyName: "Ihr Firmenname.",
              phoneNumber: "Telefonnummer Ihrer Firma.",
              address: "Erste Zeile Ihrer Gesch\xE4ftsadresse.",
              address2: "Ggf. zweite Zeile Ihrer Gesch\xE4ftsadresse.",
              city: "Stadt, in der sich Ihr B\xFCro befindet.",
              country: "Land, in dem sich Ihr B\xFCro befindet.",
              emailAddress: "E-Mail-Adresse Ihrer Firma.",
              state: "Staat oder Land, in dem sich Ihr B\xFCro befindet.",
              zip: "PLZ f\xFCr Ihr B\xFCro."
            }
          },
          notification: {
            title: "Benachrichtigungen",
            delete: "Benachrichtigung l\xF6schen ",
            labels: {
              name: "Name",
              events: "Ereignisse",
              status: "Status",
              administrators: "Administratoren",
              actions: "Aktionen"
            },
            descriptions: {
              name: "Eindeutiger Name f\xFCr diese Benachrichtigung.",
              events: "Ereignisse, f\xFCr die Benachrichtigungen verschickt werden sollen.",
              status: "Statustypen, f\xFCr die Benachrichtigungen verschickt werden sollen.",
              administrators: "E-Mail-Adressen der Administratoren, an die Benachrichtigungen verschickt werden sollen.",
              actions: "Aktionen, f\xFCr die Benachrichtigungen verschickt werden sollen."
            },
            components: {
              button: {
                add: "Hinzuf\xFCgen",
                edit: "Bearbeiten",
                delete: "L\xF6schen",
                save: "Speichern",
                cancel: "Stornieren"
              },
              title: {
                editNotification: "Benachrichtigung bearbeiten",
                createNotification: "Benachrichtigung erstellen"
              }
            },
            action: {
              deleteNotification: "Sind Sie sicher, dass Sie diese Benachrichtigung l\xF6schen m\xF6chten?"
            }
          },
          sdwanConnectorNetwork: {
            title: "Connector",
            subMenu: "Client-Netzwerkbereich",
            description: "Das Client-Netzwerk wird verwendet, um den Ressourcen hinter den Connectors IPs zuzuweisen."
          },
          dcAgent: {
            title: "DC Agent",
            description: "Geben Sie die IP-Adresse des DC Agent ein, den Sie konfiguriert haben.",
            ipAddress: "IP-Adresse"
          },
          dhcpServer: {
            title: "DHCP Relay Server",
            description: "Geben Sie die IP-Adresse des DHCP-Servers ein, den Sie verwenden m\xF6chten.",
            ipAddress: "IP-Adresse"
          }
        }
      },
      statusBanner: {
        subscriptionExpiresSoon: "Ihre Subscription l\xE4uft in {{days}} Tagen ab",
        subscriptionExpiresOneDay: "Ihre Subscription l\xE4uft in 1 Tag ab",
        subscriptionExpiresToday: "Ihre Subscription l\xE4uft in weniger als 1 Tag ab",
        deprecatedExpiresSoon: "Das von Ihnen verwendete Azure-Subscriptionmodell wird nicht mehr unterst\xFCtzt. Dieser Service l\xE4uft in {{days}} Tage ab, wenn Sie nicht auf ein neues Modell umsteigen. \xC4ndern Sie Ihr Modell bitte {{linkStart}}hier{{linkEnd}}.",
        deprecatedExpiresOneDay: "Das von Ihnen verwendete Azure-Subscriptionmodell wird nicht mehr unterst\xFCtzt. Dieser Service l\xE4uft in 1 Tag ab, wenn Sie nicht auf ein neues Modell umsteigen. {{linkStart}}{{linkEnd}}",
        deprecatedExpiresToday: "Das von Ihnen verwendete Azure-Subscriptionmodell wird nicht mehr unterst\xFCtzt. Dieser Service l\xE4uft in weniger als einem Tag ab, wenn Sie nicht auf ein neues Modell umsteigen. \xC4ndern Sie Ihr Modell bitte {{linkStart}}hier{{linkEnd}}",
        updateAvailable: "Es steht ein neues Firmware Update zur Verf\xFCgung! Vereinbaren Sie vor {{date}} ein neues Wartungsfenster, um den Update-Prozess zu starten.",
        pamBandwidthCapacity: "Ihre aktuelle Edge-Service-Bandbreite \xFCbersteigt die lizenzierte Menge. Bitte kontaktieren Sie den Vertrieb, um mehr Bandbreite zu erwerben."
      },
      states: {
        pending: "Ausstehend",
        completed: "Abgeschlossen",
        online: "Online",
        offline: "Offline",
        failed: "Fehlgeschlagen",
        error: "Fehler",
        degraded: "Herabgestuft",
        connecting: "Verbindung wird aufgebaut",
        unknown: "Unbekannt",
        loading: " "
      },
      validation: {
        alphaNumericDash: "Darf nur Zahlen, Buchstaben und Bindestriche enthalten",
        alphaNumericDotsDash: "Darf nur Zahlen, Buchstaben, Punkte und Bindestriche enthalten",
        alphaNumericDotsColonsDash: "Darf nur Zahlen, Buchstaben, Punkte, Doppelpunkte und Bindestriche enthalten",
        alphaNumericDashNoHaSuffix: "Darf kein '-ha'-Suffix enthalten",
        alphaNumericDashSpace: "Darf nur Zahlen, Buchstaben, Bindestriche und Leerzeichen enthalten",
        customKeyword: "Darf nur maximal 128 Zahlen, Buchstaben, Bindestriche, Punkte, Apostrophe oder Leerzeichen enthalten",
        uuid: "muss eine g\xFCltige UUID-/ GUID sein"
      },
      footer: {
        serial: " Abonnement-Seriennummer: {{serial}}"
      },
      users: {
        table: {
          fullName: "Vollst\xE4ndiger Name",
          email: "E-Mail",
          deviceCount: "Anzahl der Ger\xE4te",
          enrollmentCompleted: {
            label: "Anmeldung abgeschlossen",
            yes: "Anmeldung abgeschlossen",
            no: "Anmeldung ausstehend"
          },
          enrollmentLink: "Anmeldungs-Link kopieren",
          enrollmentLinkCopied: "Anmeldungs-Link in Clipboard kopieren",
          resendInvite: "Einladung erneut versenden",
          tamperProof: "Manipulationssicher",
          devicePreConnect: "Endger\xE4te Pre-Logon",
          webFiltering: "Web-Filterung",
          optionsOverride: "Benutzer-Override",
          on: "An",
          off: "Aus"
        },
        delete: {
          title: "Benutzer abmelden",
          body: "Sind Sie sicher, dass Sie diesen Benutzer entfernen m\xF6chten?"
        },
        dialog: {
          buttonText: "Benutzer anmelden",
          disabledButton: "Sie m\xFCssen zun\xE4chst ein Benutzer-Verzeichnis und einen Identity Provider konfigurieren, indem Sie auf die Seite Identit\xE4t > Einstellungen gehen."
        },
        noData: {
          iconHeader: "Beginnnen Sie mit dem Hinzuf\xFCgen eines Benutzers!",
          labelButton: "Benutzer hinzuf\xFCgen und einladen",
          textHeader: "Warum sind Benutzer f\xFCr SecureEdge Access so wichtig?",
          text: "SecureEdge Access kontrolliert die Zugangsrechte und Berechtigungen der Benutzer in Ihrem Netzwerk. Hier k\xF6nnen Sie also festlegen, wer zugreifen darf, und den Anmeldeprozess f\xFCr die SecureEdge Access Agents starten.",
          firstTitle: "1",
          firstStep: "Anmelde-Einladung versenden",
          secondTitle: "2",
          secondStep: "Lassen Sie den Benutzer den Agent herunterladen, installieren und sich selbst anmelden.",
          textFooter: "Das war\u2018s. Sie k\xF6nnen loslegen!"
        },
        settings: {
          global: "Benutzer-Override",
          introduction: "\xDCberschreiben Sie die globalen ZTNA-Einstellungen f\xFCr diesen Benutzer.",
          tamperProof: "Manipulationssicher",
          devicePreConnect: "Endger\xE4te Pre-Logon",
          deviceLimit: "Benutzer-Ger\xE4telimit",
          webFiltering: "Web-Filterung",
          enabled: "aktiviert",
          disabled: "deaktiviert",
          overrideEnabled: "unter Verwendung der unten angegebenen Einstellungen",
          overrideDisabled: "unter Verwendung der globalen ZTNA-Einstellungen",
          descriptions: {
            tamperProof: "Verhindert, dass der Benutzer den SecureEdge Access Agent deaktiviert. F\xFCr Endger\xE4te mit iOS, iPadOS oder Android ist eine MDM-L\xF6sung erforderlich.",
            devicePreConnect: "Erm\xF6glicht die Verwaltung von Endger\xE4ten unter Windows, ohne dass der Benutzer angemeldet ist.",
            webFiltering: "Erzwingt die \xDCberpr\xFCfung des gesamten Webverkehrs anhand der definierten Webfilterrichtlinie.",
            deviceLimit: "Ender\xE4te pro Benutzer"
          }
        }
      },
      devices: {
        table: {
          userName: "Benutzer",
          email: "E-Mail",
          brand: "Ger\xE4temarke",
          model: "Ger\xE4temodell",
          hardwareModel: "Hardwaremodell",
          os: "Betriebssystem",
          osFlavour: "OS Flavor",
          osVersion: "OS Version",
          enrollmentDate: "Anmeldedatum"
        },
        delete: {
          body: "Sind Sie sicher, dass Sie dieses Ger\xE4t l\xF6schen m\xF6chten?",
          title: "Ger\xE4t l\xF6schen"
        },
        noData: {
          iconHeader: "Keine angemeldeten Ger\xE4te gefunden!",
          action: "Benutzer hinzuf\xFCgen und einladen",
          textHeader: "Was ist ein angemeldetes Ger\xE4t?",
          text: "Sobald Sie einen Benutzer eingeladen haben, den Selbstanmeldungsprozess zu starten, und der Benutzer die Einf\xFChrungsschritte abgeschlossen hat, werden hier alle Endger\xE4te dieses Benutzers aufgelistet, auf denen SecureEdge Access Agents installiert sind."
        }
      },
      firewalls: {
        table: {
          name: "Name",
          serials: "Seriennummer",
          isCcManaged: "CC gemanaged",
          model: "Modell"
        },
        delete: {
          title: "CloudGen Firewall trennen {{name}}",
          body: "Sind Sie sicher, dass Sie diese CloudGen Firewall von Ihrer SecureEdge-Umgebung trennen m\xF6chten?",
          forbidden: "Sie k\xF6nnen CloudGen Firewall-Integrationen nicht entfernen, wenn sie als Endpunkt konfiguriert sind."
        },
        dialog: {
          create: {
            buttonText: "Registrierungs-Token",
            dialogTitle: "Registrierungs-Token erstellen"
          },
          result: {
            message: "Kopieren Sie das folgende Token in Ihr <0>Firewall Admin-Aktivierungsformular</0>.",
            firewallText: "Firewall Registrierungs-Token",
            firewallOtp: "Dieses Token ist nur f\xFCr eine einzige Firewall g\xFCltig und l\xE4uft in 30 Minuten ab."
          }
        },
        noData: {
          iconHeader: "Richten Sie eine CloudGen Firewall ein!",
          labelButton: "Holen Sie sich ein Registrierungs-Token",
          textHeader: "Sie wollen Ihrer Installation eine CloudGen Firewall hinzuf\xFCgen?",
          text: "Wenn Sie bereits eine Barracuda CloudGen Firewall einsetzen, k\xF6nnen Sie diese auch als Point-of-Entry f\xFCr Ihre Access Agents nutzen. Das Verbinden einer CloudGen Firewall ist so einfach wie das Kopieren des Registrierungs-Tokens in der Management-Konsole f\xFCr CloudGen Firewall.<0 /><0 />Beachten Sie, dass nach der Anforderung der CloudGen Firewall die Remote Access-Komponente nicht mehr durch das Firewall Admin-Tool gesteuert wird."
        }
      },
      integration: {
        pageTitle: "Integration",
        firewalls: "CloudGen Firewall",
        ipSecV2: {
          create: "IPsec Tunnel erstellen",
          createButton: "IPsec Tunnel hinzuf\xFCgen",
          edit: "IPsec Tunnel bearbeiten",
          general: "Allgemein",
          network: "Netzwerk",
          phases: "Phasen",
          sourceDest: "Quelle/Ziel",
          success: "Erfolg",
          successMessage: "Neuer IPsec Tunnel erfolgreich erzeugt",
          pageTitle: "IPsec VPN",
          table: {
            status: "Status",
            name: "Name",
            description: "Beschreibung",
            enabled: "Aktiviert",
            gateway: "Remote Gateway",
            localNetworks: "Lokale Netzwerke",
            localBgp: "Lokale BGP",
            remoteNetworks: "Remote-Netzwerke",
            remoteBgp: "Remote BGP",
            type: "Typ",
            siteWanInterface: "SecureEdge Peer",
            peerIp: "Peer IP",
            duration: "Dauer",
            encryption: "Verschl\xFCsselung",
            filters: {
              enabled: {
                enabled: "Aktiviert",
                disabled: "Deaktiviert"
              },
              secureEdgePeer: {
                peerType: "Peer-Typ",
                edgeService: "Edge Service",
                site: "Site"
              }
            },
            ellipsisMenu: {
              restart: "IPsec Tunnel neu starten"
            }
          },
          cloudCantInitiate: "Nur Sites und Private Edge Services k\xF6nnen so eingestellt werden, dass sie Tunnel initiieren",
          dialog: {
            create: {
              buttonText: "Tunnel hinzuf\xFCgen"
            },
            delete: {
              body: "Sind Sie sicher, dass Sie diesen IPsec Tunnel l\xF6schen m\xF6chten?",
              title: "IPsec Tunnel l\xF6schen {{name}}"
            },
            restart: {
              ok: "Ok",
              cancel: "Stornieren",
              body: "Sind Sie sicher, dass Sie diesen IPsec Tunnel neu starten m\xF6chten?",
              title: "IPsec Tunnel neu starten {{name}}",
              message: "Bitte warten, der Tunnel wird neu gestartet"
            },
            generalSection: {
              descriptionMessage: "Um einen neuen Tunnel zu erstellen, nehmen Sie zur Konfiguration des Tunnels die folgenden Einstellungen vor.",
              tunnelStatus: "Aktivieren",
              general: {
                title: "Allgemeine Informationen",
                name: "Name",
                description: "Beschreibung",
                initiatesTunnel: "Tunnel initiieren",
                restartSa: "SA neu starten",
                descriptions: {
                  title: "Beschreibung allgemeine Informationen",
                  name: "Eindeutiger Name f\xFCr diesen IPsec Tunnel.",
                  description: "Optionale Beschreibung des Tunnels.",
                  tunnelStatus: "Ausschalten, um den Tunnel vor\xFCbergehend zu deaktivieren.",
                  initiatesTunnel: "Aktiviert, damit die SecureEdge-Appliance den IPsec-Tunnel initiiert."
                }
              },
              authentication: {
                title: "Authentifizierung",
                authentication: "Authentifizierung",
                sharedSecret: "Shared Secret",
                serverCert: "Server-Zertifikat",
                caCert: "CA-Zertifikat",
                x509Condition: "X509-Bedingung",
                exploit509: "Exploit 509",
                descriptions: {
                  authentication: "Methode, die der IPsec-Tunnel zur Authentifizierung verwenden sollte.",
                  sharedSecret: "Pre-Shared Key, der f\xFCr die Authentifizierung verwendet werden sollte.",
                  serverCert: "Beschreibung Server-Zertifikat",
                  caCert: "Beschreibung CA-Zertifikat",
                  x509Condition: "Beschreibung X509-Bedingung",
                  exploit509: "Beschreibung Exploit 509"
                }
              }
            },
            sourceDestSection: {
              descriptionMessage: "Konfigurieren Sie die Quelle und das Ziel des Tunnels, den Sie hinzuf\xFCgen m\xF6chten.",
              enableBgp: "BGP aktivieren",
              descriptions: {
                enableBgp: "BGP aktivieren"
              },
              source: {
                title: "Quelle",
                type: "Typ",
                peer: "Peer",
                ipAddresses: "Peer Gateways",
                initiatesTunnel: "Initiieren",
                wanInterface: "WAN Interface",
                localId: "Local ID",
                networkAddresses: "Netzwerk-Adressen",
                networkAddressBgp: "Peering Address",
                asnBgp: "ASN",
                descriptions: {
                  ipAddresses: "\xD6ffentliche IP-Adresse des gew\xE4hlten Edge Service. F\xFCr die Konfiguration Ihres Remote-Ger\xE4ts erforderlich.",
                  localId: "IPsec ID der lokalen Seite des Tunnels.",
                  networkAddresses: "Die auf der lokalen Seite des Tunnels verf\xFCgbaren Netzwerke.",
                  networkAddressBgp: "Die auf der lokalen Seite zur BGP-Kommunikation verwendete IP-Adresse.",
                  asnBgp: "Die Autonome Systemnummer der lokalen Seite.",
                  enableBgp: "Aktivieren Sie diese Option, damit die an den lokalen und Remote-Standorten erreichbaren Netze dynamisch \xFCber BGP ausgetauscht werden."
                },
                sourceTypeData: {
                  gateway: "Edge Service",
                  site: "Site"
                },
                localWans: "Adresse",
                primaryAddress: "Prim\xE4re Adresse",
                additionalAddresses: "Zus\xE4tzliche Adressen"
              },
              destination: {
                title: "Ziel",
                remoteGatewayName: "Name",
                remoteGateway: "Remote Gateway",
                remoteId: "Remote ID",
                networkAddresses: "Netzwerk-Adressen",
                networkAddressBgp: "Peering Address",
                asnBgp: "ASN",
                multiInputTitle: "Remote Gateway",
                multiInputTitleLabel: "Ziele",
                descriptions: {
                  remoteId: "IPsec ID der Remote-Seite des Tunnels.",
                  networkAddresses: "Die auf der Remote-Seite des Tunnels verf\xFCgbaren Netzwerke.",
                  networkAddressBgp: "Die auf der Remote-Seite zur BGP-Kommunikation verwendete IP-Adresse.",
                  asnBgp: "Die Autonome Systemnummer der Remote-Seite.",
                  remoteGateway: "FQDN- oder IP der Remote-Seite des Tunnels. Wenn die lokale Seite den Tunnel nicht initiiert, kann dies auf 0.0.0.0/0 gesetzt werden."
                }
              }
            },
            phasesSection: {
              descriptionMessage: "Der Tunnel bietet 2 Phasen. Richten Sie Ihre bevorzugten Sicherheitseinstellungen f\xFCr jede dieser Phasen ein.",
              phase1: {
                title: "Phase 1",
                encryption: "Verschl\xFCsselung",
                hash: "Hash",
                dhGroup: "DH-Gruppe",
                proposalHandling: "Angebotsbearbeitung",
                lifetime: "Lebensdauer",
                descriptions: {
                  proposalHandling: 'Bei der Einstellung "strict" kann nur die ausgew\xE4hlte Verschl\xFCsselung, der Hash-Algorithmus und die Diffie-Hellman-Gruppe verwendet werden, bei der Einstellung "negotiate" kann der Initiator einen anderen Satz von Algorithmen und eine andere DH-Gruppe ausw\xE4hlen.',
                  lifetime: "Die Anzahl der Sekunden, bis die IPsec (SA) neu eingegeben wird."
                }
              },
              phase2: {
                title: "Phase 2",
                lifetime: "Lebensdauer",
                trafficVolume: "Aktiviertes Traffic-Volumen",
                trafficVolumeKb: "Traffic-Volumen KB",
                encryption: "Verschl\xFCsselung",
                hash: "Hash",
                dhGroup: "DH-Gruppe",
                proposalHandling: "Angebotsbearbeitung",
                descriptions: {
                  proposalHandling: 'Bei der Einstellung "strict" kann nur die ausgew\xE4hlte Verschl\xFCsselung, der Hash-Algorithmus und die Diffie-Hellman-Gruppe verwendet werden, bei der Einstellung "negotiate" kann der Initiator einen anderen Satz von Algorithmen und eine andere DH-Gruppe ausw\xE4hlen.',
                  lifetime: "Die Anzahl der Sekunden, bis die IPsec (SA) neu eingegeben wird.",
                  trafficVolume: "Wenn diese Funktion aktiviert ist, kann eine erneute Eingabe auf der Grundlage des Traffic-Volumens ausgel\xF6st werden.",
                  trafficVolumeKb: "Die Anzahl der Sekunden, bis die SA neu eingegeben wird."
                }
              }
            },
            networkSection: {
              descriptionMessage: "Netzwerkeinstellungen konfigurieren. Dies sind erweiterte Optionen, die f\xFCr einen allgemeinen Tunnel nicht zwingend erforderlich sind.",
              networkSettings: {
                title: "Netzwerkeinstellungen",
                oneTunnelSubnet: "EIn VPN Tunnel pro Subnetz-Paar",
                universalTrafficSelectors: "Universal Traffic Selectors",
                forceUdpEncapsulation: "UDP-Kapselung erzwingen",
                ikeReauthentication: "IKE-Reauthentifizierung",
                descriptions: {
                  oneTunnelSubnet: "Erstellen Sie eine Security Association, SA, pro Paar von lokalen und Remote-Netzwerken.",
                  universalTrafficSelectors: "Ist diese Funktion aktiviert, kann der gesamte Traffic durch den Tunnel gehen.",
                  forceUdpEncapsulation: 'Verwenden Sie UDP Port 4500 f\xFCr den "IPsec Tunnel Envelope", auch wenn kein Natting erkannt wurde.',
                  ikeReauthentication: "Nehmen Sie jedes Mal, wenn eine SA neu eingegeben wird, eine Authentifizierung vor."
                }
              },
              deadPeerDetection: {
                title: "Dead Peer Detection",
                actionDetected: "Aktion bei Erkennung",
                delay: "Verz\xF6gerung",
                descriptions: {
                  actionDetected: 'Aktion, wenn die Remote-Seite nicht auf die DPD-Meldungen antwortet. "L\xF6schen" beendet den Tunnel, "Neustart" versucht, den Tunnel wiederherzustellen und "Keine" deaktiviert DPD.',
                  delay: "Zeit in Sekunden, nach der eine DPD Keep Alive-Meldung gesendet wird, wenn kein anderer Traffic empfangen wird."
                }
              }
            }
          },
          noData: {
            buttonText: "IPsec-Verbindung hinzuf\xFCgen",
            iconHeader: "Beginnen Sie mit dem Hinzuf\xFCgen einer IPsec-Verbindung!",
            labelButton: "IPsec-Verbindung hinzuf\xFCgen",
            textHeader: "Warum sollte eine IPsec-Verbindung integriert werden?",
            text: "SecureEdge-Installation verwenden die propriet\xE4re Barracuda TINA VPN f\xFCr sichere und zuverl\xE4ssige Konnektivit\xE4t. Verwenden Sie diese Konfigurationsseite, um Drittanbieter-Implementierungen zu verbinden, die IPsec IKEv2 verwenden k\xF6nnen. Sobald die Konfiguration abgeschlossen ist, k\xF6nnen Sie auf dieser Seite den Status IhrerIPsec-VPN-Verbindung \xFCberpr\xFCfen."
          }
        },
        azureMonitor: "Azure Monitor"
      },
      syslog: {
        pageTitle: "Syslog Streaming",
        certificateCName: "Allgemeiner Zertifikatsname",
        certificateExpiryDate: "Zertifikat-Ablaufdatum",
        enable: "Syslog aktivieren",
        logs: "Logs",
        port: "Port",
        protocol: "Protokoll",
        securityProtocol: "Sicherheitsprotokoll",
        server: "Syslog Server",
        syslogOverride: "Syslog Streaming deaktivieren",
        uploadSslCert: "SSL-Zertifikat hochladen",
        descriptions: {
          port: "Ziel-Port Ihres Syslog Servers.",
          protocol: "Das f\xFCr das Syslog-Streaming zu verwendende Daten\xFCbertragungsprotokoll.",
          uploadSslCert: "Das \xF6ffentliche Zertfikat f\xFCr Ihren Syslog Server.",
          logs: "Die zu streamenden Appliance-Logs.",
          server: "Hostname oder IP Ihres Syslog Servers.",
          securityProtocol: "Das f\xFCr das Syslog-Streaming zu verwendende Sicherheitsprotokoll.",
          syslogOverride_site: "Syslog Streaming f\xFCr diese Site deaktivieren.",
          syslogOverride_gateway: "Syslog Streaming f\xFCr diesen Edge Service deaktivieren."
        }
      },
      xdr: {
        pageTitle: "Barracuda XDR",
        enable: "Streaming aktivieren",
        sites: "Sites",
        gateways: "Edge Services",
        hostname: "Hostname",
        port: "Port",
        descriptions: {
          sites: "Die Sites, die Logs an Barracuda XDR streamen sollen.",
          gateways: "Die Edge Services, die Logs an Barracuda XDR streamen sollen.",
          hostname: "Barracuda XDR Hostname, an den gestreamt werden soll.",
          port: "Barracuda XDR Port, an den gestreamt werden soll."
        }
      },
      webFilterSettings: {
        safeSearch: "SafeSearch",
        silentAdBlocking: "Blockieren von Werbung",
        descriptions: {
          safeSearch: 'Erzwingt den "SafeSearch"-Modus bei Internet-Suchmaschinen. Voraussetzung ist, dass die SSL-Sicherheitspr\xFCfung aktiviert und konfiguriert wurde, um SafeSearch zu erzwingen.',
          silentAdBlocking: 'Sperrt den Zugriff auf Seiten der URL-Kategorie "Werbung oder Banner".'
        }
      },
      webMonitorPolicies: {
        webConfigHelpText: "Aktivieren oder deaktivieren Sie die \xDCberwachung der Websuche Ihrer Nutzer nach bestimmten Schl\xFCsselw\xF6rtern. Wenn diese Funktion aktiviert ist, k\xF6nnen Sie die Schl\xFCsselwort\xFCbereinstimmungen (die alle im Web Monitor Alert Log gesammelt werden, das Teil der Service Logs-Sammlung ist) an den gew\xFCnschten Syslog Server zur weiteren Verarbeitung weiterleiten.<0/> W\xE4hlen Sie aus einer Reihe von vordefinierten Stichw\xF6rtern aus und/oder f\xFCgen Sie benutzerdefinierte Stichw\xF6rter hinzu, die genau Ihren Anforderungen entsprechen.",
        enabled: "Web Monitoring aktivieren",
        webMonitoringTitle: "Schl\xFCsselwort-Kategorien",
        customKeywordTitle: "Benutzerdefiniertes Keyword Set",
        customKeywordHelpText: 'Geben Sie benutzerdefinierte Schl\xFCsselw\xF6rter in das Textfeld ein und klicken Sie auf die Schaltfl\xE4che "+", um sie der \xDCberwachungsliste hinzuzuf\xFCgen.',
        customKeywords: "Benutzerdefinierte Schl\xFCsselw\xF6rter",
        monitored: "\xFCberwacht"
      },
      customResponse: {
        setup: "Benutzerdefinierte Antwortseite einrichten",
        setupHelpText: "Hier k\xF6nnen Sie Ihre benutzerdefinierte Antwortseite einrichten. Nach dem Speichern wird diese Antwortseite allen aktiven Benutzern angezeigt.",
        barracudaBranding: "Barracuda Branding",
        customLogo: "Benutzerdefiniertes Logo",
        customText: "Benutzerdefinierter Text",
        showBarracudaBranding: "Branding anzeigen",
        descriptions: {
          customText: "Wird unter dem benutzerdefinierten Logo angezeigt.<0/> Lassen Sie diesen Platz frei, wenn Sie keinen benutzerdefinierten Text verwenden.",
          customLogo: "Wird oben angezeigt. Unterst\xFCtzte Dateiformate: JPEG, PNG, GIF und SVG."
        }
      },
      copy: {
        label: "In Clipboard kopieren"
      },
      identity: {
        menuTitle: "IDENTIT\xC4T",
        pageTitle: "Identit\xE4t",
        pageSubtitle: "Einstellungen",
        identityProviders: {
          title: "Identity Providers",
          addNotification: "Identity Provider erfolgreich hinzugef\xFCgt",
          noDataMessage: "Anscheinend haben Sie noch keinen Identity Provider konfiguriert.",
          clickBelow: "Klicken Sie auf die Schaltfl\xE4che unten, um einen Identity Provider hinzuzuf\xFCgen.",
          createIdentityProvider: "Identity Provider hinzuf\xFCgen",
          saml: {
            informationNeeded: "Von Ihrem Identity Provider ben\xF6tigte Information",
            entityId: "Entity ID",
            acsURL: "ACS URL"
          },
          add: {
            title: "Identity Provider hinzuf\xFCgen",
            createStep: "SAML Sign In-Einstellungen",
            setupStep: "SAML einrichten",
            button: "Hinzuf\xFCgen",
            buttonDisabled: "Sie k\xF6nnen immer nur einen Identity Provider haben.",
            types: {
              email: "E-Mail",
              entraid: "Microsoft Entra ID",
              bcc: "Barracuda Cloud Control",
              google: "Google Workspace",
              oidc: "OpenID Connect",
              saml: "SAML 2.0"
            },
            options: {
              provider: "Provider",
              displayName: "Namen anzeigen",
              tenantId: "Tenant ID",
              clientId: "Client ID",
              clientSecret: "Client Secret",
              discoveryURL: "Discovery URL",
              scope: "Scope",
              googleWorkspaceDomain: "Google Workspace Domain",
              signInEndpoint: "Sign In-Endger\xE4t",
              signingCert: "Signing Cert"
            },
            redirectUri: "URI weiterleiten"
          },
          table: {
            name: "Name",
            type: "Typ",
            status: "Status",
            noData: "Keine Identity Providers gefunden."
          },
          authorizationStatusField: {
            needs_consent: "Autorisierung erforderlich",
            active: "Aktiv",
            error: "Fehler"
          },
          menu: {
            test: "Identity Provider testen",
            setupSaml: "SAML einrichten"
          },
          delete: {
            title: "Identity Provider entfernen",
            body: "Sind Sie sicher, dass Sie diesen Identity Provider entfernen m\xF6chten?"
          }
        },
        userDirectories: {
          title: "Identit\xE4t",
          subtitle: "Benutzer-Verzeichnisse",
          tableName: "Benutzer-Verzeichnisse",
          noDataMessage: "Anscheinend haben Sie noch kein Benutzer-Verzeichnis konfiguriert.",
          clickBelow: "Klicken Sie auf die Schaltfl\xE4che unten, um eines hinzuzuf\xFCgen.",
          createDirectory: "Benutzer-Verzeichnis hinzuf\xFCgen",
          createDirectoryDialogTitle: "Benutzer-Verzeichnis hinzuf\xFCgen",
          editDirectory: "Benutzer-Verzeichnis bearbeiten",
          displayName: "Name",
          type: "Typ",
          status: "Status",
          statusField: {
            running: "L\xE4uft",
            cancelled: "Abgebrochen",
            errored: "Fehler",
            completed: "Abgeschlossen",
            pending: "Ausstehend",
            unknown: "Unbekannt",
            needs_consent: "Autorisierung erforderlich"
          },
          sync: "Sync",
          syncResult: "Sync-Ergebnis",
          syncedNotification: "Sync Benutzer-Verzeichnis gestartet",
          syncErrors: "Sync-Fehler",
          users_one: "{{count}} Benutzer",
          users_other: "{{count}} Benutzer",
          groups_one: "{{count}} Gruppe",
          groups_other: "{{count}} Gruppen",
          syncStatus: "{{users}}, {{groups}}, Synchronisiert",
          lastSuccessfulSync: "Letzte Sync",
          lastSyncAt: "Letzte Sync am {{date}}",
          neverSynced: "Noch keine Sync",
          delete: {
            body: "Sind Sie sicher, dass Sie dieses Benutzer-Verzeichnis l\xF6schen m\xF6chten?",
            body2: "Eine \xC4nderung oder L\xF6schung des Benutzer-Verzeichnisses f\xFCr Ihr Konto kann dazu f\xFChren, dass bestehende Sicherheits- und Zugriffsregeln nicht mehr wie erwartet funktionieren. Bitte \xFCberpr\xFCfen Sie diese und korrigieren Sie sie gegebenenfalls. Bereits registrierte Benutzer haben weiterhin Zugang. Bitte verwenden Sie die Benutzer-Seite, um festzulegen, wer gel\xF6scht werden soll.",
            title: "Benutzer-Verzeichnis l\xF6schen {{name}}"
          },
          form: {
            title: "Verzeichnis",
            displayName: "Namen anzeigen",
            tenantId: "Tenant ID",
            type: {
              label: "Verzeichnis-Typ",
              bcc: "Barracuda Cloud Control",
              entraid: "Microsoft Entra ID",
              okta: "Okta",
              ldap: "LDAP",
              google: "Google Workspace"
            },
            ldap: {
              steps: {
                userDirectory: "Info Benutzer-Verzeichnis",
                auth: "Authentifizierung",
                tls: "TLS-Einstellungen"
              },
              host: {
                label: "Host",
                placeholder: "z.B.: ldap.barracuda.com"
              },
              port: {
                label: "Port",
                placeholder: "z.B.: 389"
              },
              profile: {
                label: "Profil",
                placeholder: "Profil ausw\xE4hlen",
                ad: "Ad",
                custom: "Benutzerdefiniert"
              },
              userSearchBase: {
                label: "Benutzer-Suchbasis",
                placeholder: "z.B.: dc=mydomain.com,dc=com"
              },
              groupSearchBase: {
                label: "Gruppen-Suchbasis",
                placeholder: "z.B.: dc=mydomain.com,dc=com"
              },
              authenticationMethod: {
                label: "Authentifizierungs-Methode",
                placeholder: "Authentifizierungs-Methode ausw\xE4hlen",
                anon: "Anon",
                simple: "Einfach",
                saslExternal: "SASL Extern",
                saslKerberos: "SASL Kerberos",
                ntlm: "NTLM"
              },
              username: "Benutzername",
              password: "Passwort",
              saslCredentials: "SASL-Anmeldedaten",
              useTls: {
                tlsSettings: "TLS-Einstellungen",
                none: "Keine",
                tls: "TLS",
                startTls: "TLS starten",
                encryptionMethod: "Verschl\xFCsselungsmethode",
                ldapSni: {
                  label: "SNI Hostname",
                  placeholder: "z.B.: example.org"
                },
                ldapPrivkey: {
                  label: "Privater Schl\xFCssel",
                  placeholder: "F\xFCgen Sie hier Ihren privaten Schl\xFCssel ein"
                },
                ldapPrivkeyPassword: "Passwort",
                ldapPubkey: {
                  label: "\xD6ffentlicher Schl\xFCssel",
                  placeholder: "F\xFCgen Sie hier Ihren \xF6ffentlichen Schl\xFCssel ein"
                },
                ldapCacerts: {
                  label: "CA-Zertifikate",
                  placeholder: "F\xFCgen Sie hier Ihre CA-Zertifikate ein"
                },
                ldapCheckCerts: {
                  label: "Zertifikate pr\xFCfen",
                  description: "Pr\xFCfen, ob empfangene Serverzertifikate vertrauensw\xFCrdig sind"
                },
                ldapCheckHostname: {
                  label: "Hostnamen pr\xFCfen",
                  description: "Hostname des Zertifikats \xFCberpr\xFCfen"
                },
                ldapCertAdditionalNames: {
                  label: "Weitere Zertifikatnamen",
                  placeholder: "z.B.: example.org,company.com,dc1.example.org"
                }
              }
            },
            okta: {
              domainname: "Okta Domain Name",
              authToken: "Okta Auth Token"
            },
            google: {
              googleWorkspaceDomain: "Google Workspace Domain"
            },
            bcc: {
              environment: "Umgebung"
            }
          }
        },
        users: {
          title: "Benutzer",
          subtitle: "Benutzer",
          tableName: "Benutzer",
          name: "Name",
          email: "E-Mail",
          noDataMessage: "Keine Benutzer gefunden.",
          nameOrEmail: "Benutzer"
        },
        groups: {
          title: "Gruppen",
          subtitle: "Gruppen",
          tableName: "Gruppen",
          name: "Name",
          email: "E-Mail",
          noDataMessage: "Keine Gruppen gefunden."
        }
      }
    }
  }
};
export {
  de_default as default
};
