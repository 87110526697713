import { jsx } from "react/jsx-runtime";
import { BaseBarCard } from "../../baseCards";
import { bytesFormatter, colorFunctionSuccess } from "../../../utils";
import { useReportingData } from "../../../contexts";
import { Trans } from "react-i18next";
import { Typography } from "@barracuda-internal/bds-core";
import { sortBy } from "lodash";
import { UrlCategoriesIcon } from "@cuda-react/icons";
import { useTheme } from "@mui/material";
const TopAllowedUrlCategoriesPerTrafficCard = (props) => {
  const { preview, size } = props;
  const { data, loading, addFilter } = useReportingData("TopAllowedUrlCategoriesPerTrafficCard", preview);
  const { urlCategoryDataPoints = [] } = data || {};
  const theme = useTheme();
  const maxValue = Math.max(...urlCategoryDataPoints.map((category) => category.numberOfBytes));
  const entries = sortBy(urlCategoryDataPoints, "numberOfBytes").reverse().map((category) => ({
    title: category.label,
    value: bytesFormatter(category.numberOfBytes),
    barProgress: 100 / maxValue * category.numberOfBytes,
    color: colorFunctionSuccess(100 / maxValue * category.numberOfBytes, theme),
    icon: /* @__PURE__ */ jsx(UrlCategoriesIcon, { size: 20 }),
    onClick: category.label && addFilter ? () => addFilter({ key: category.id, name: category.label }, "urlCategoryDataPoints") : void 0,
    tooltipMessage: category.label && addFilter ? "stratosReporting.filterTooltip" : void 0
  }));
  return /* @__PURE__ */ jsx(
    BaseBarCard,
    {
      entries,
      title: /* @__PURE__ */ jsx(Trans, { i18nKey: "stratosReporting.topAllowedUrlCategoriesCard.titlePerTraffic", children: /* @__PURE__ */ jsx(Typography, { variant: "body1", component: "span" }) }),
      size,
      preview,
      loading: loading && !data.urlCategoryDataPoints,
      noData: !loading && !data.urlCategoryDataPoints,
      activeFilter: true
    }
  );
};
export {
  TopAllowedUrlCategoriesPerTrafficCard
};
