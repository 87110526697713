import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const AzureIcon = (props) => {
  var _a, _b;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      viewBox: "0 0 60 60",
      className: classNames(props.className, (_a = props.classes) == null ? void 0 : _a.icon, "cuda-react-icon"),
      id: "cuda-icon-azure",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 },
          d: "M56,48.6l-13.4-40.3c-.3-1-1-1.8-1.8-2.4-.8-.6-1.9-.9-2.8-.8h-15.9c-1,0-1.9.3-2.7.9-.8.6-1.4,1.4-1.7,2.3L4,48.6c-.2.7-.3,1.5-.2,2.3.1.8.4,1.5.9,2.1.5.6,1.1,1.1,1.8,1.4.6.3,1.3.4,1.9.4s.1,0,.2,0h9.9c1,0,1.9-.3,2.7-.9.8-.6,1.4-1.4,1.7-2.3l1.4-4.2,8.6,6.5c.8.6,1.8.9,2.8.9h15.7c.8,0,1.5-.1,2.2-.5.7-.3,1.3-.8,1.7-1.4.5-.6.8-1.3.9-2.1.1-.7,0-1.5-.2-2.2ZM26.9,38.1l2.6-6.6,6.2,18.3-15.7-11.6h6.9ZM27.3,10.1h10.5l13.4,39.8h-10.6c0-.4-.1-.9-.3-1.3L27.3,10.1Z"
        }
      )
    }
  );
};
var AzureIcon_default = AzureIcon;
export {
  AzureIcon,
  AzureIcon_default as default
};
