var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import React from "react";
import DialogBody from "../../dialog/DialogBody/DialogBody";
import { Form, TabbedForm } from "../../forms";
const TableInputAddEditForm = (props) => {
  const {
    children,
    title,
    initialErrors,
    initialValue,
    onSubmit,
    formValidate,
    formatFormData,
    onClose,
    rowIndex,
    tabbed,
    formProps
  } = props;
  return /* @__PURE__ */ jsx(
    DialogBody,
    {
      title,
      form: true,
      children: React.createElement(
        // @ts-ignore Not sure what this is complaining about...
        tabbed ? TabbedForm : Form,
        __spreadValues({
          validate: (values) => {
            const errors = formValidate ? formValidate(values, initialValue) || {} : {};
            return errors;
          },
          initialValues: initialValue,
          initialErrors,
          save: (values) => {
            onSubmit == null ? void 0 : onSubmit(formatFormData ? formatFormData(values) : values, initialValue);
            onClose && onClose();
            return Promise.resolve(values);
          },
          onCancel: onClose,
          formButtonToolbarProps: {
            label: "cuda.state.ok"
          }
        }, formProps),
        React.Children.toArray(children).filter((child) => !!child).map((child) => React.cloneElement(child, { rowIndex }))
      )
    }
  );
};
var TableInputAddEditForm_default = TableInputAddEditForm;
export {
  TableInputAddEditForm,
  TableInputAddEditForm_default as default
};
