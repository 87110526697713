import { CrudTypes, useCrudFetch, useCrudProps } from "@cuda-react/core";
import apiResources from "../apiResources";
import { parseJson } from "../utils/jsonUtils";
var useCustomDashboard_default = (dashboardName) => {
  var _a;
  const [{ data: remoteStoredData }, , refreshDashboard] = useCrudProps(apiResources.customDashboard, { filter: { name: dashboardName } });
  const selectedDashboard = (_a = remoteStoredData == null ? void 0 : remoteStoredData.content) == null ? void 0 : _a[0];
  const remoteStoredCustomDashboards = parseJson(selectedDashboard == null ? void 0 : selectedDashboard.value, []);
  const idDashboard = selectedDashboard == null ? void 0 : selectedDashboard.id;
  const [, , performCreate] = useCrudFetch(CrudTypes.CREATE, apiResources.customDashboard);
  const [, , performUpdate] = useCrudFetch(CrudTypes.UPDATE, apiResources.customDashboard, { id: idDashboard });
  const handleDashboardFetch = (dashboards) => (idDashboard ? performUpdate({
    data: {
      "id": idDashboard,
      "name": dashboardName,
      "value": JSON.stringify(dashboards)
    }
  }) : performCreate({
    data: {
      "name": dashboardName,
      "value": JSON.stringify(dashboards)
    }
  })).then(() => refreshDashboard());
  return {
    remoteStoredCustomDashboards,
    handleDashboardFetch,
    loadingDashboards: !remoteStoredData
  };
};
export {
  useCustomDashboard_default as default
};
