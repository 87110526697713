import { flatten, get, isEqual, uniq } from "lodash";
const drawLinkedLines = (chart, theme, redraw = true) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const spokeSeries = chart.current && chart.current.series.find((series) => series.userOptions.id === "linked-points-point-series" && series.visible);
  const spokeClusters = (_a = spokeSeries == null ? void 0 : spokeSeries.markerClusterInfo) == null ? void 0 : _a.clusters;
  const spokeData = (_b = spokeSeries == null ? void 0 : spokeSeries.options) == null ? void 0 : _b.data;
  const hubData = get((_e = (_d = (_c = chart.current) == null ? void 0 : _c.series) == null ? void 0 : _d.find) == null ? void 0 : _e.call(_d, (series) => series.userOptions.id === "linked-points-source-point-series" && series.visible), "options.data");
  if (!hubData || hubData.length === 0 || !spokeData || spokeData.length === 0) {
    return;
  }
  const lineTargets = uniq(
    spokeData.map(
      (spoke) => spokeClusters && spokeClusters.find(
        (cluster) => cluster.data && cluster.data.some(
          (clusterData) => spoke.x === clusterData.x && spoke.y === clusterData.y
        )
      ) || spoke
    )
  );
  const data = lineTargets.map((spoke) => ({
    path: ["M", hubData[0].x, hubData[0].y, "L", spoke.x, spoke.y, "Z"]
  }));
  const existingLineSeries = chart.current && chart.current.series.find((series) => series.userOptions.id === "linked-points-line-series");
  const existingData = get(existingLineSeries, "options.data", []);
  if (existingLineSeries && existingLineSeries.visible && (existingData == null ? void 0 : existingData.length) === data.length && (existingData == null ? void 0 : existingData.every(({ path }, index) => isEqual(flatten(path), data[index].path)))) {
    return;
  }
  if (existingLineSeries) {
    existingLineSeries.update({ type: "mapline", data, visible: true }, redraw);
  } else {
    (_g = (_f = chart.current) == null ? void 0 : _f.addSeries) == null ? void 0 : _g.call(_f, {
      type: "mapline",
      id: "linked-points-line-series",
      color: theme.palette.info.main,
      lineWidth: 2,
      enableMouseTracking: false,
      showInLegend: false,
      data
    }, redraw);
  }
};
export {
  drawLinkedLines
};
