var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import {
  CrudTypes,
  getArrayDataContent,
  getDataContent,
  NoResourcePage,
  Tab,
  TabbedPage,
  useCrudProps,
  useCrudSubscription,
  useGlobalParam
} from "@cuda-react/core";
import EditTVTContent from "../../../components/appliances/edit/EditTVTContent";
import ApplianceAuditLog from "../appliances/logs/ApplianceAuditLog";
import LiveLog from "../appliances/logs/LiveLog";
import HistoryLog from "../appliances/logs/HistoryLog";
import { get } from "lodash";
import ApplianceDashboard from "../appliances/ApplianceDashboard";
import apiResources from "../../../apiResources";
import EditSCContent from "../../../components/appliances/edit/EditSCContent";
const SiteDetails = ({ match }) => {
  var _a, _b, _c;
  const id = match.params.id;
  const [siteDetailsData, loading] = useCrudSubscription(CrudTypes.GET, apiResources.siteDashboardStatus, { id });
  const configStatus = getArrayDataContent((_a = useCrudProps(id ? apiResources.configurationStatus : void 0, { id })[0]) == null ? void 0 : _a.data);
  const activeAppliance = configStatus.find((appliance) => appliance.activeHA) || configStatus[0];
  const activeSerial = activeAppliance == null ? void 0 : activeAppliance.instanceId;
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const siteDetails = getDataContent(siteDetailsData, {});
  const siteVirtualWanId = (_b = siteDetails == null ? void 0 : siteDetails.virtualWan) == null ? void 0 : _b.id;
  const siteOnline = get(siteDetails, "connectionStatus.aggregateConnectionState") === "online";
  const siteNotFound = !!(siteDetailsData == null ? void 0 : siteDetailsData.error) || siteVirtualWanId && virtualWanId !== siteVirtualWanId;
  const siteModel = get(siteDetails, "modelSeries");
  const isSc = siteModel === "S" || ((_c = match == null ? void 0 : match.path) == null ? void 0 : _c.startsWith("/infrastructure/iot"));
  return !siteNotFound ? /* @__PURE__ */ jsxs(
    TabbedPage,
    {
      defaultTab: 0,
      title: "tesseract.network.pageTitle",
      subtitle: [
        {
          text: `tesseract.network.${isSc ? "iot.menuTitle" : "sites"}`,
          path: `/infrastructure/${isSc ? "iot" : "sites"}`
        },
        siteDetails.name || " "
      ],
      children: [
        /* @__PURE__ */ jsx(
          Tab,
          {
            value: "#dashboard",
            label: "tesseract.sites.dashboard.tabTitle",
            children: /* @__PURE__ */ jsx(
              ApplianceDashboard,
              {
                details: __spreadProps(__spreadValues({ id, activeSerial }, siteDetails), { loading }),
                type: "sites"
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          Tab,
          {
            value: "#settings",
            label: "tesseract.sites.settings.tabTitle",
            children: siteModel ? isSc ? /* @__PURE__ */ jsx(EditSCContent, { id, details: siteDetails }) : /* @__PURE__ */ jsx(EditTVTContent, { id, type: "site", details: siteDetails }) : null
          }
        ),
        siteModel && !isSc ? /* @__PURE__ */ jsx(
          Tab,
          {
            value: "#connectionHistory",
            label: "tesseract.sites.connectionHistory.tabTitle",
            disabled: !siteOnline,
            children: /* @__PURE__ */ jsx(
              HistoryLog,
              {
                id,
                messagesType: "sites",
                resource: apiResources.siteConnectionHistoryLog,
                serial: activeSerial,
                tableName: "siteHistory",
                details: siteDetails
              }
            )
          }
        ) : null,
        siteModel && !isSc ? /* @__PURE__ */ jsx(
          Tab,
          {
            value: "#live",
            label: "tesseract.sites.liveLog.tabTitle",
            disabled: !siteOnline,
            children: /* @__PURE__ */ jsx(
              LiveLog,
              {
                id,
                messagesType: "sites",
                resource: apiResources.siteLiveLog,
                serial: activeSerial,
                tableName: "siteLive",
                details: siteDetails
              }
            )
          }
        ) : null,
        /* @__PURE__ */ jsx(
          Tab,
          {
            value: "#auditLog",
            label: "tesseract.sites.auditLog.tabTitle",
            children: /* @__PURE__ */ jsx(
              ApplianceAuditLog,
              {
                id,
                messagesType: "sites",
                resource: apiResources.siteAuditLog,
                details: siteDetails
              }
            )
          }
        )
      ]
    }
  ) : /* @__PURE__ */ jsx(NoResourcePage, { content: "tesseract.sites.siteNotFound" });
};
var SiteDetails_default = SiteDetails;
export {
  SiteDetails_default as default
};
