import { validateDateOnAfter, validateDateOnBefore } from "@cuda-react/core";
import { differenceInHours, subHours } from "date-fns";
const updateWindowValidation = (mustInstallBy, translate) => (values) => {
  const minDate = subHours(/* @__PURE__ */ new Date(), 26);
  const mustInstallByDate = new Date(mustInstallBy);
  const start = new Date(values.from);
  const end = new Date(values.to);
  const windowWholeHours = values.from && values.to && differenceInHours(end, start);
  const lengthError = windowWholeHours < 3 ? translate("tesseract.settings.tabs.updates.firmware.validation.insufficientWindow") : void 0;
  return {
    from: lengthError || validateDateOnAfter(minDate)(values.from, values, { t: translate }),
    to: lengthError || validateDateOnBefore(mustInstallByDate)(values.to, values, { t: translate })
  };
};
export {
  updateWindowValidation
};
