var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { get, set } from "lodash";
import { useTranslation } from "react-i18next";
import { useChoices, useDeepCompareMemo } from "../../../hooks";
import AutocompleteSearch from "../../search/AutocompleteSearch/AutocompleteSearch";
import ConnectedAutocompleteSearch from "../../search/ConnectedAutocompleteSearch/ConnectedAutocompleteSearch";
import classNames from "classnames";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
import { Chip, CircularProgress, FormControl } from "@barracuda-internal/bds-core";
const styles = (theme) => createStyles({
  selectArray: {
    width: (props) => get(props, "options.fullWidth") ? "100%" : 256
  },
  chip: {
    margin: theme.spacing(0.25)
  },
  chipIcon: {
    marginLeft: 10
  },
  chipContainer: {
    maxWidth: "100%",
    marginBottom: theme.spacing(0.5)
  },
  chipError: {
    backgroundColor: theme.palette.error.main
  },
  chipUnknown: {
    color: theme.palette.error.main,
    marginLeft: "12px"
  }
});
const useStyles = makeOverrideableStyles("SelectArray", styles);
const SelectArray = (props) => {
  const {
    choices = [],
    disabled,
    error,
    filterKey,
    icon,
    iconMap,
    label,
    maxChipLength = 30,
    maxInputs,
    onChange,
    optionIcon,
    optionText = "name",
    optionValue = "key",
    options,
    placeholder = "cuda.inputs.selectArray.typeToSearch",
    resource,
    selectAll,
    selectAllText = "cuda.inputs.selectArray.all",
    selectAllValue = "*",
    id,
    value = [],
    unknownChipContent = "cuda.inputs.selectArray.unknownChipText"
  } = props;
  const [translate] = useTranslation();
  const getChoiceName = (choice) => {
    const name = choice && get(choice, optionText) || "";
    return translate(name);
  };
  const classes = useStyles(props);
  const getChoiceId = (choice) => choice && get(choice, optionValue) || "";
  const selectAllChoice = selectAll && set(set({}, optionValue, selectAllValue), optionText, selectAllText);
  const unknownChipValue = typeof unknownChipContent === "string" ? translate(unknownChipContent) : unknownChipContent;
  const emptyValue = useDeepCompareMemo(() => ({}), [value]);
  const currentValue = Array.isArray(value) ? value : value && [value] || [];
  const addChip = (choice) => {
    if (!choice) {
      return;
    }
    const choiceId = getChoiceId(choice);
    const newChips = currentValue ? [...currentValue].concat([choiceId]) : [choiceId];
    if (selectAll && newChips.includes(selectAllValue)) {
      onChange && onChange([choiceId]);
    } else {
      const uniqueChips = [...new Set(newChips)];
      onChange && onChange(uniqueChips);
    }
  };
  const [chipsToShow, addChipToShow, loading] = useChoices(
    currentValue,
    {
      choices: choices && [...selectAllChoice ? [selectAllChoice] : [], ...choices],
      filterKey: filterKey || optionValue,
      optionValue,
      onAdd: addChip,
      resource,
      params: {
        pagination: {
          page: 1,
          perPage: currentValue.length
        }
      }
    }
  );
  const handleRequestDelete = (choiceId) => {
    const newValue = currentValue ? currentValue.filter((val) => val !== choiceId) : [];
    onChange && onChange(newValue);
  };
  const searchProps = __spreadValues({
    onChange: addChipToShow,
    optionText,
    id: id && "select-array-input-" + id,
    disabled: disabled || !!(currentValue && maxInputs && currentValue.length >= maxInputs),
    iconMap,
    icon,
    optionIcon,
    noSearchIcon: true,
    placeholder,
    label
  }, options);
  return /* @__PURE__ */ jsxs(FormControl, { className: classes.selectArray, id: "select-array-root", children: [
    /* @__PURE__ */ jsx("div", { className: classes.chipContainer, children: chipsToShow.map((choice, index) => {
      const choiceId = getChoiceId(choice);
      const chipIcon = iconMap && optionIcon && iconMap[get(choice, optionIcon)] || icon;
      const specificError = error && Array.isArray(error) && error[index];
      const val = getChoiceName(choice);
      const valueLabel = val || (loading ? /* @__PURE__ */ jsx(CircularProgress, { size: 16 }) : /* @__PURE__ */ jsx("span", { className: classes.chipUnknown, children: unknownChipValue }));
      return /* @__PURE__ */ jsx(
        Chip,
        {
          onDelete: disabled ? void 0 : () => handleRequestDelete(choiceId),
          className: classNames(classes.chip, specificError && classes.chipError),
          disabled,
          label: typeof val === "string" && val.length > maxChipLength ? val.substring(0, maxChipLength - 3) + "..." : valueLabel,
          icon: chipIcon ? /* @__PURE__ */ jsx("span", { className: classes.chipIcon, children: chipIcon }) : void 0
        },
        choiceId
      );
    }) }),
    resource ? /* @__PURE__ */ jsx(
      ConnectedAutocompleteSearch,
      __spreadValues({
        resource,
        error: error || void 0,
        filterChoices: (choice) => !currentValue.includes(getChoiceId(choice)),
        staticChoices: selectAllChoice ? [selectAllChoice] : [],
        totalText: "cuda.inputs.selectArray.showing",
        clearOnFocus: true
      }, searchProps)
    ) : /* @__PURE__ */ jsx(
      AutocompleteSearch,
      __spreadValues({
        value: emptyValue,
        filterSelectedOptions: true,
        choices: choices && [...selectAllChoice ? [selectAllChoice] : [], ...choices].filter((choice) => !currentValue.includes(getChoiceId(choice))),
        error: error || void 0
      }, searchProps)
    )
  ] });
};
var SelectArray_default = SelectArray;
export {
  SelectArray,
  SelectArray_default as default,
  styles
};
