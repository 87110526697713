import { jsx, jsxs } from "react/jsx-runtime";
import { Route, Switch } from "react-router";
import Users from "./users/Users";
import DevicesTable from "./devices/DevicesTable";
import ZeroTrustSettings from "./zeroTrustSettings/ZeroTrustSettings";
import PointsOfEntry from "./pointsOfEntry/PointsOfEntry";
import AppCatalog from "./appCatalog/AppCatalog";
const Endpoint = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(Route, { path: "/ztna/users", children: /* @__PURE__ */ jsx(Users, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/ztna/devices", children: /* @__PURE__ */ jsx(DevicesTable, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/ztna/pointsofentry", children: /* @__PURE__ */ jsx(PointsOfEntry, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/ztna/appcatalog", children: /* @__PURE__ */ jsx(AppCatalog, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/ztna/settings", children: /* @__PURE__ */ jsx(ZeroTrustSettings, {}) })
] });
var Endpoint_default = Endpoint;
export {
  Endpoint,
  Endpoint_default as default
};
