var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { FormControlLabel, IconButton, Radio, RadioGroup, Tooltip } from "@barracuda-internal/bds-core";
import { Info } from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import { get, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { createStyles } from "@mui/styles";
import { makeOverrideableStyles } from "@cuda-react/theme";
const styles = (theme) => createStyles({
  root: {},
  radioGroup: {
    float: "left",
    marginLeft: theme.spacing(1.5),
    maxWidth: "100%"
  },
  inputHelp: {
    display: "inline-block",
    float: "left",
    height: "auto",
    margin: "5px 12px 5px 0",
    width: 20
  },
  infoIcon: {
    fill: theme.palette.text.secondary
  },
  tooltip: __spreadProps(__spreadValues({}, theme.typography.body2), {
    // @ts-ignore Added by Cuda-react. Default (undefined), is fine
    color: theme.palette.text.tooltip
  })
});
const useStyles = makeOverrideableStyles("RadioGroup", styles);
const BasicRadioButtonGroup = (props) => {
  const {
    value = "",
    onChange,
    onBlur,
    choices = [],
    id,
    allowEmpty,
    optionText = "name",
    defaultChoice,
    options = {},
    optionHelp,
    optionValue = "key",
    disabled
  } = props;
  useEffect(() => {
    if (!isEmpty(choices) && !allowEmpty && !choices.find((choice) => get(choice, optionValue) === value)) {
      onChange == null ? void 0 : onChange(defaultChoice || get(choices[0], optionValue));
      onBlur == null ? void 0 : onBlur();
    }
  }, [choices, value, onChange]);
  const renderRadioButton = (choice) => {
    let choiceName = "";
    const [translate] = useTranslation();
    if (React.isValidElement(optionText)) {
      choiceName = React.cloneElement(optionText, { data: choice });
    } else if (typeof optionText === "function") {
      choiceName = optionText(choice);
    } else if (typeof optionText === "string") {
      choiceName = get(choice, optionText);
    }
    return /* @__PURE__ */ jsx(
      FormControlLabel,
      {
        control: /* @__PURE__ */ jsx(Radio, { color: "primary" }),
        label: typeof choiceName === "string" ? translate(choiceName) : choiceName,
        value: get(choice, optionValue),
        disabled
      },
      get(choice, optionValue)
    );
  };
  const getCurrentChoice = () => {
    if (value) {
      for (let index = 0; index < choices.length; index++) {
        if (get(choices[index], optionValue) === value) {
          return choices[index];
        }
      }
    }
    return {};
  };
  const classes = useStyles(props);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      RadioGroup,
      __spreadProps(__spreadValues({
        name: id,
        value
      }, options), {
        className: classes.radioGroup,
        onChange: (event, index) => {
          onChange && onChange(index);
          onBlur && onBlur();
        },
        id: id && "radio-input-" + id,
        children: choices.map(renderRadioButton)
      })
    ),
    /* @__PURE__ */ jsx("div", { className: classes.inputHelp, children: optionHelp && getCurrentChoice()[optionHelp] && /* @__PURE__ */ jsx(
      Tooltip,
      {
        placement: "top-end",
        title: getCurrentChoice()[optionHelp],
        classes: { tooltip: classes.tooltip },
        children: /* @__PURE__ */ jsx(IconButton, { size: "small", children: /* @__PURE__ */ jsx(Info, { className: classes.infoIcon }) })
      }
    ) })
  ] });
};
var BasicRadioButtonGroup_default = BasicRadioButtonGroup;
export {
  BasicRadioButtonGroup,
  BasicRadioButtonGroup_default as default
};
