import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const NetworkBulkIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-network-bulk",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
          d: "M32.4,54.5h19.6v-17.1h-7.3v-9.8h-12.2v-4.9h7.3V5.5h-19.6v17.1h7.3v4.9h-12.2v9.8h-7.3v17.1h19.6v-17.1h-7.3v-4.9h19.6v4.9h-7.3v17.1ZM25.1,17.8v-7.3h9.8v7.3h-9.8ZM22.7,42.2v7.3h-9.8v-7.3h9.8ZM47.1,42.2v7.3h-9.8v-7.3h9.8Z"
        }
      )
    }
  );
};
var NetworkBulkIcon_default = NetworkBulkIcon;
export {
  NetworkBulkIcon,
  NetworkBulkIcon_default as default
};
