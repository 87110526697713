import { jsx } from "react/jsx-runtime";
import EventSuccessIcon from "./EventSuccessIcon";
import EventFailedIcon from "./EventFailedIcon";
import EventAllIcon from "./EventAllIcon";
import { Critical, Success } from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import { Help } from "@barracuda-internal/bds-core/dist/Icons/Core";
const NotificationStatusIconMap = {
  successful: /* @__PURE__ */ jsx(EventSuccessIcon, {}),
  failed: /* @__PURE__ */ jsx(EventFailedIcon, {}),
  all: /* @__PURE__ */ jsx(EventAllIcon, {}),
  default: /* @__PURE__ */ jsx(Help, {})
};
const getNotificationStatusColorFromTheme = (status, theme) => {
  if (status === "successful") {
    return theme.palette.success.main;
  }
  if (status === "failed") {
    return theme.palette.error.main;
  }
  if (status === "all") {
    return theme.palette.success.main;
  }
  return theme.palette.info.main;
};
const AuditStatusIconMap = {
  successful: /* @__PURE__ */ jsx(Success, { sx: { color: "success.main" } }),
  failed: /* @__PURE__ */ jsx(Critical, { sx: { color: "error.main" } }),
  default: /* @__PURE__ */ jsx(Help, { sx: { color: "info.main" } })
};
const getAuditStatusColorFromTheme = (status, theme) => {
  if (status === "successful") {
    return theme.palette.success.main;
  }
  if (status === "failed") {
    return theme.palette.error.main;
  }
  return theme.palette.info.main;
};
export {
  AuditStatusIconMap,
  NotificationStatusIconMap,
  getAuditStatusColorFromTheme,
  getNotificationStatusColorFromTheme
};
