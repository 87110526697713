var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  icon: {
    width: 14,
    height: 181
  }
});
const RiskLegend = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(SvgIcon, __spreadProps(__spreadValues({ className: classes.icon, viewBox: "0 0 14 181" }, props), { children: [
    /* @__PURE__ */ jsx("rect", { width: "14", height: "181", fill: "#E5E5E5" }),
    /* @__PURE__ */ jsx(
      "path",
      {
        d: "M283.983 220.398H-541.799C-545.697 220.398 -548.908 216.745 -548.908 212.148V-94.5522C-548.908 -99.1492 -545.697 -102.802 -541.799 -102.802H283.983C287.881 -102.802 291.092 -99.1492 291.092 -94.5522V212.148C291.092 216.745 287.881 220.398 283.983 220.398Z",
        fill: "white",
        stroke: "#CCCBCB",
        strokeWidth: "0.5"
      }
    ),
    /* @__PURE__ */ jsx(
      "path",
      {
        d: "M7.33447 173.647L7.33447 6.80609",
        stroke: "#EDEDED",
        strokeWidth: "13.3076",
        strokeMiterlimit: "1.5",
        strokeLinecap: "round",
        strokeLinejoin: "round"
      }
    ),
    /* @__PURE__ */ jsx(
      "path",
      {
        d: "M7.3335 12.8057C10.6472 12.8057 13.3335 10.1194 13.3335 6.80566C13.3335 3.49196 10.6472 0.805664 7.3335 0.805664C4.01979 0.805664 1.3335 3.49196 1.3335 6.80566C1.3335 10.1194 4.01979 12.8057 7.3335 12.8057Z",
        fill: theme.palette.risk.low
      }
    ),
    /* @__PURE__ */ jsx(
      "path",
      {
        d: "M7.3335 53.4058C10.6472 53.4058 13.3335 50.7195 13.3335 47.4058C13.3335 44.0921 10.6472 41.4058 7.3335 41.4058C4.01979 41.4058 1.3335 44.0921 1.3335 47.4058C1.3335 50.7195 4.01979 53.4058 7.3335 53.4058Z",
        fill: theme.palette.risk.lowMedium
      }
    ),
    /* @__PURE__ */ jsx(
      "path",
      {
        d: "M7.3335 94.8557C10.6472 94.8557 13.3335 92.1694 13.3335 88.8557C13.3335 85.542 10.6472 82.8557 7.3335 82.8557C4.01979 82.8557 1.3335 85.542 1.3335 88.8557C1.3335 92.1694 4.01979 94.8557 7.3335 94.8557Z",
        fill: theme.palette.risk.medium
      }
    ),
    /* @__PURE__ */ jsx(
      "path",
      {
        d: "M7.3335 137.376C10.6472 137.376 13.3335 134.689 13.3335 131.376C13.3335 128.062 10.6472 125.376 7.3335 125.376C4.01979 125.376 1.3335 128.062 1.3335 131.376C1.3335 134.689 4.01979 137.376 7.3335 137.376Z",
        fill: theme.palette.risk.mediumHigh
      }
    ),
    /* @__PURE__ */ jsx(
      "path",
      {
        d: "M7.3335 179.646C10.6472 179.646 13.3335 176.959 13.3335 173.646C13.3335 170.332 10.6472 167.646 7.3335 167.646C4.01979 167.646 1.3335 170.332 1.3335 173.646C1.3335 176.959 4.01979 179.646 7.3335 179.646Z",
        fill: theme.palette.risk.high
      }
    )
  ] }));
};
export {
  RiskLegend
};
