import { jsx, jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  interfacesLabels: {
    marginTop: 8,
    marginBottom: -4,
    paddingLeft: 275,
    width: "calc(100% - 320px)",
    display: "inline-flex",
    "&> div": {
      minWidth: 132,
      paddingLeft: 12
    }
  }
});
const ExpressRouteHeader = () => {
  const [translate] = useTranslation();
  const classes = useStyles();
  return /* @__PURE__ */ jsxs(
    Typography,
    {
      component: "div",
      className: classes.interfacesLabels,
      children: [
        /* @__PURE__ */ jsx("div", { children: translate("tesseract.appliances.settings.wan.primary") }),
        /* @__PURE__ */ jsx("div", { children: translate("tesseract.appliances.settings.wan.secondary") })
      ]
    }
  );
};
var ExpressRouteHeader_default = ExpressRouteHeader;
export {
  ExpressRouteHeader_default as default
};
