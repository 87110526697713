var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { useDeepCompareEffect, usePersistedState } from "../../../hooks";
import { createContext } from "react";
import { useHistory, useLocation } from "react-router";
import { get, uniq } from "lodash";
const PreviewContext = createContext([]);
const matcher = /(?:\?|&)preview=([^&|?\s]*)/;
const clearValues = ["none", "clear", "0"];
const PreviewProvider = ({ children }) => {
  const [activePreviews = [], setActivePreviews] = usePersistedState("activePreviews", []);
  const location = useLocation();
  const { push } = useHistory();
  useDeepCompareEffect(() => {
    const search = get(location, "search", "");
    const hash = get(location, "hash", "");
    const match = search.match(matcher) || hash.match(matcher);
    if (match && match[1]) {
      push(__spreadProps(__spreadValues({}, location), {
        search: search.replace(match[0], ""),
        hash: hash.replace(match[0], "")
      }));
      if (clearValues.includes(match[1])) {
        setActivePreviews([]);
      } else {
        const previews = match[1].split(",");
        setActivePreviews(uniq([...activePreviews, ...previews]));
      }
    }
  }, [location]);
  return /* @__PURE__ */ jsx(PreviewContext.Provider, { value: activePreviews, children });
};
var PreviewProvider_default = PreviewProvider;
export {
  PreviewContext,
  PreviewProvider,
  PreviewProvider_default as default
};
