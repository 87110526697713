import { createContext, useContext, useEffect } from "react";
const MixpanelContext = createContext(false);
const MixpanelPrefixContext = createContext([]);
const PAGE_VIEW_EVENT = "Pageview";
const useMixpanel = (pageName, onLoadAction = PAGE_VIEW_EVENT, additionalDetails = {}) => {
  const mixpanel = useContext(MixpanelContext);
  const [prefix] = useContext(MixpanelPrefixContext);
  const getTrackingEvent = (action) => (prefix ? prefix + " " : "") + `${pageName} ${action}`;
  const registerAction = (action, customAdditionalDetails = {}) => {
    const trackingEvent = getTrackingEvent(action);
    if (process.env.NODE_ENV === "development") {
      console.info("[mixpanel]", trackingEvent, customAdditionalDetails);
    }
    mixpanel && mixpanel.track(
      trackingEvent,
      customAdditionalDetails
    );
  };
  useEffect(() => {
    if (onLoadAction && !!mixpanel && prefix) {
      registerAction(
        onLoadAction === true ? PAGE_VIEW_EVENT : onLoadAction,
        additionalDetails
      );
    }
  }, [!!mixpanel, prefix, onLoadAction]);
  return registerAction;
};
const useMixpanelPrefix = (prefix) => {
  const [, setPrefix] = useContext(MixpanelPrefixContext);
  useEffect(() => {
    prefix && (setPrefix == null ? void 0 : setPrefix(prefix));
  }, [prefix]);
};
export {
  MixpanelContext,
  MixpanelPrefixContext,
  useMixpanel,
  useMixpanelPrefix
};
