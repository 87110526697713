var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { BaseCard } from "../index";
import { createStyles, makeStyles } from "@mui/styles";
import { CardFooter } from "../CardFooter";
import { CardSizes } from "../../../typesAndConstants";
import { BarGrid } from "../../statistics/BarGrid";
import { colorFunction, progressFunction } from "../../../utils";
import { useTheme } from "@mui/material";
const styles = createStyles({
  hideBoxShadow: {
    boxShadow: "none"
  },
  footerBarGrid: {
    minWidth: 580,
    maxWidth: 580
  }
});
const useStyles = makeStyles(styles);
const BaseBarCard = (props) => {
  const { activeFilter, title, entries, total, loading, noData, footerKey, size, preview, rowIcon, rowColorFunction } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const large = size === CardSizes.large;
  const usedColorFunction = rowColorFunction || colorFunction;
  const entriesToDisplay = entries.map((entry, index) => {
    var _a, _b, _c;
    return __spreadProps(__spreadValues({}, entry), {
      icon: (_a = entry.icon) != null ? _a : rowIcon,
      barProgress: (_b = entry.barProgress) != null ? _b : progressFunction(index + 1),
      color: (_c = entry.color) != null ? _c : usedColorFunction(index + 1, theme)
    });
  });
  const showFooter = !loading && large && footerKey && total;
  return /* @__PURE__ */ jsxs(
    BaseCard,
    {
      title,
      className: props.hideBoxShadow ? classes.hideBoxShadow : void 0,
      size,
      preview,
      noData,
      activeFilter,
      children: [
        /* @__PURE__ */ jsx(
          BarGrid,
          {
            size,
            entries: entriesToDisplay,
            loading,
            className: showFooter ? classes.footerBarGrid : void 0
          }
        ),
        showFooter && /* @__PURE__ */ jsx(
          CardFooter,
          {
            footerKey,
            length: entries.length,
            total
          }
        )
      ]
    }
  );
};
export {
  BaseBarCard
};
