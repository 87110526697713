import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const SdWanIcon = ({ className }) => {
  var _a, _b;
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-sdwan",
      viewBox: "0 0 60 60",
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
            d: "M22.6,32.5l2.8-.9c.4.3.7.5,1,.7.3.2.7.3,1.1.5l.6,2.9h3.9l.6-2.9c.4-.2.7-.3,1.1-.5.3-.2.7-.4,1-.7l2.8.9,1.9-3.3-2.2-1.9c0-.4,0-.8,0-1.2s0-.8,0-1.2l2.2-1.9-1.9-3.4-2.8,1c-.4-.3-.7-.5-1-.7-.3-.2-.7-.4-1.1-.5l-.6-2.9h-3.9l-.6,2.9c-.4.2-.7.3-1.1.5s-.7.4-1,.7l-2.8-.9-1.9,3.3,2.2,1.9c0,.4,0,.9,0,1.3s0,.8,0,1.3l-2.2,1.9,1.9,3.3ZM27.3,23.4c.8-.8,1.7-1.1,2.7-1.1s2,.4,2.7,1.1c.8.8,1.1,1.7,1.1,2.7s-.4,2-1.1,2.7c-.8.8-1.7,1.1-2.7,1.1s-2-.4-2.7-1.1c-.8-.8-1.1-1.7-1.1-2.7s.4-2,1.1-2.7Z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 },
            d: "M53.9,40.1c2.2-2.2,3.2-4.8,3.2-7.8s-.9-5.3-2.8-7.4c-1.9-2.1-4.2-3.3-7-3.7,0-4.8-1.7-8.9-5-12.2-3.3-3.3-7.4-5-12.2-5s-7.4,1.2-10.5,3.5c-3.1,2.3-5.1,5.4-6.2,9.2-3.1.7-5.6,2.3-7.6,4.8-1.9,2.5-2.9,5.4-2.9,8.6s1.3,6.9,4,9.5c2.5,2.5,5.6,3.7,9.1,3.9l-3.2,4.6c-1.4.3-2.7,1.3-3.1,2.8h0c-.3,1.1-.2,2.2.3,3.1.5,1,1.4,1.7,2.4,2,.4.1.8.2,1.2.2,1.8,0,3.4-1.1,3.9-2.9.3-1,.2-2.1-.3-3.1l4.7-6.8h5.5v5.4c-.6.5-1.1,1.2-1.4,2h0c-.7,2.2.5,4.5,2.7,5.2.4.1.8.2,1.2.2,1.8,0,3.4-1.1,3.9-2.9.6-1.8-.1-3.6-1.6-4.6v-5.3h5.5l5,7.2c0,0,0,.1,0,.2h0c-.3,1.1-.2,2.2.3,3.1.5,1,1.4,1.7,2.4,2,.4.1.8.2,1.2.2,1.8,0,3.4-1.1,3.9-2.9.3-1.1.2-2.2-.3-3.1-.5-1-1.4-1.7-2.4-2-.3-.1-.7-.2-1-.2l-3.1-4.5h2.2c3.1,0,5.7-1.1,7.8-3.2ZM30.7,52.2l-1.5-.5,1.5.5c-.1.4-.6.6-1,.5-.2,0-.4-.2-.5-.4,0-.2-.1-.4,0-.6,0-.2.2-.4.4-.5.2,0,.4-.1.6,0,.4.1.6.6.5,1ZM47.4,52.2l-1.5-.5,1.5.5c-.1.4-.6.6-1,.5-.2,0-.4-.2-.5-.4,0-.2-.1-.4,0-.6,0-.2.2-.4.4-.5.2,0,.4-.1.6,0,.2,0,.4.2.5.4,0,.2.1.4,0,.6ZM7.8,29.8c0-2.4.8-4.4,2.5-6.1,1.7-1.7,3.7-2.5,6.1-2.5h1.2c0-3.4,1.2-6.3,3.6-8.7,2.4-2.4,5.3-3.6,8.7-3.6s6.3,1.2,8.7,3.6c2.4,2.4,3.6,5.3,3.6,8.7v4.9h3.7c1.7,0,3.2.6,4.4,1.8,1.2,1.2,1.8,2.6,1.8,4.4s-.6,3.2-1.8,4.4c-1.2,1.2-2.6,1.8-4.4,1.8h-29.5c-2.4,0-4.4-.8-6.1-2.5-1.7-1.7-2.5-3.7-2.5-6.1ZM14.3,52.2l-1.5-.5,1.5.5c0,.2-.2.4-.4.5-.2,0-.4.1-.6,0-.2,0-.4-.2-.5-.4,0-.2-.1-.4,0-.6,0-.2.2-.4.4-.5.2,0,.4-.1.6,0,.2,0,.4.2.5.4,0,.2.1.4,0,.6Z"
          }
        )
      ]
    }
  );
};
var SdWanIcon_default = SdWanIcon;
export {
  SdWanIcon,
  SdWanIcon_default as default
};
