var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { CardSizeOptions, CardSizes, GridSizeOptions } from "../../../typesAndConstants";
import { Close, Edit } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { IconButton } from "@barracuda-internal/bds-core";
import Draggable from "react-draggable";
import classNames from "classnames";
import useEditableGridLayout from "../../../hooks/useEditableGridLayout";
import { AddWidgetCard } from "../AddWidgetCard";
import { ButtonDialog, useWidthTracking } from "@cuda-react/core";
import { PreviewDialogContent } from "../PreviewDialogContent";
const styles = (theme) => createStyles({
  layout: {
    transition: "height 200ms ease"
  },
  deleteButton: {
    border: "none"
  },
  editModeButtons: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    position: "absolute",
    width: 64,
    zIndex: 100,
    backgroundColor: theme.palette.background.default
  },
  editButtonIcon: {
    height: 20,
    width: 20
  },
  editButton: {
    border: "none"
  },
  responsive: {
    display: "grid",
    gap: GridSizeOptions.columnMargin,
    gridTemplateColumns: GridSizeOptions.columnWidth,
    gridTemplateRows: GridSizeOptions.rowHeight,
    paddingBottom: theme.spacing(2)
  },
  insertCard: {
    borderLeft: `2px solid #067CC1 !important`
  },
  dragged: {
    zIndex: 25
  },
  cardSlot: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f0f0f0",
    "& > div > :not(:first-child)": {
      opacity: 0.5
    },
    cursor: "pointer"
  },
  fillerCard: {
    width: GridSizeOptions.columnWidth
  },
  clickBarrier: {
    zIndex: 50,
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0
  }
});
const useStyles = makeStyles(styles);
const Grid = (props) => {
  const classes = useStyles(props);
  const [ref, width] = useWidthTracking(0);
  const { cardComponents, onChange, editMode, cards, allCards, onAdd } = props;
  const layout = useEditableGridLayout(cardComponents, onChange, editMode, cards, width);
  const addSelectedCardToFilteredCards = (card, filteredCards) => __spreadValues({
    [card.componentId]: allCards[card.componentId]
  }, filteredCards);
  const columnWidth = GridSizeOptions.columnWidth + GridSizeOptions.columnMargin;
  const gridWidth = Math.floor((width + GridSizeOptions.columnMargin) / columnWidth) * columnWidth - GridSizeOptions.columnMargin;
  return /* @__PURE__ */ jsx("div", { ref, children: /* @__PURE__ */ jsx("div", { className: classes.responsive, style: { maxWidth: gridWidth }, children: layout.map((item) => {
    var _a, _b;
    return /* @__PURE__ */ jsx(
      "div",
      {
        className: classNames(
          !item.card && classes.fillerCard,
          editMode && classes.cardSlot,
          item.hasInsert && classes.insertCard,
          item.isMoving && classes.dragged
        ),
        style: {
          gridColumnStart: item.x + 1,
          gridColumnEnd: item.x + item.w + 1,
          gridRowStart: item.y + 1,
          gridRowEnd: item.y + item.h + 1,
          width: CardSizeOptions[((_b = (_a = item == null ? void 0 : item.card) == null ? void 0 : _a.props) == null ? void 0 : _b.size) || "small"].width
        },
        children: item.card ? (
          // @ts-ignore
          /* @__PURE__ */ jsx(
            Draggable,
            {
              disabled: !editMode,
              onStart: item.onDragEvent,
              onStop: item.onDragFinish,
              onDrag: item.onDragEvent,
              position: { x: 0, y: 0 },
              children: /* @__PURE__ */ jsxs("div", { children: [
                editMode ? /* @__PURE__ */ jsxs("div", { className: classes.editModeButtons, children: [
                  /* @__PURE__ */ jsx(
                    ButtonDialog,
                    {
                      buttonIcon: /* @__PURE__ */ jsx(Edit, { id: "edit-widget-icon", className: classes.editButtonIcon }),
                      stopPropagation: true,
                      buttonClasses: { buttonIcon: classes.editButton },
                      children: /* @__PURE__ */ jsx(
                        PreviewDialogContent,
                        {
                          cards: addSelectedCardToFilteredCards(item.card, cards),
                          selectedCard: {
                            componentId: item.card.componentId,
                            size: item.card.props.size || CardSizes.small
                          },
                          onAdd: (selectedCardId, selectedSize) => {
                            const newCardComponents = cardComponents.map((card) => {
                              if (selectedCardId && card.componentId === item.id) {
                                return {
                                  componentId: selectedCardId,
                                  component: allCards[selectedCardId].component,
                                  props: {
                                    size: selectedSize
                                  }
                                };
                              }
                              return card;
                            });
                            onChange(newCardComponents);
                          }
                        }
                      )
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    IconButton,
                    {
                      size: "small",
                      onClick: (event) => {
                        var _a2;
                        onChange == null ? void 0 : onChange(cardComponents.filter((card) => card.componentId !== item.id));
                        (_a2 = event == null ? void 0 : event.stopPropagation) == null ? void 0 : _a2.call(event);
                      },
                      className: classes.deleteButton,
                      children: /* @__PURE__ */ jsx(Close, { id: "stratos-remove-card" })
                    }
                  )
                ] }) : null,
                React.createElement(
                  item.card.component,
                  __spreadProps(__spreadValues({}, item.card.props), {
                    size: item.forceSmall ? CardSizes.small : item.card.props.size
                  })
                ),
                editMode ? /* @__PURE__ */ jsx(
                  "div",
                  {
                    onClick: (event) => event.stopPropagation(),
                    className: classes.clickBarrier
                  }
                ) : null
              ] })
            }
          )
        ) : editMode && item.addWidget ? /* @__PURE__ */ jsx(AddWidgetCard, { cards, onAdd: item.onAdd }) : /* @__PURE__ */ jsx("div", {})
      },
      item.id
    );
  }) }) });
};
export {
  Grid
};
