var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import Dialog from "@mui/material/Dialog";
import { createStyles } from "@mui/styles";
import React, { useState } from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import { pick } from "lodash";
import DisableableButton from "../../buttons/DisableableButton/DisableableButton";
const styles = (theme) => createStyles({
  buttonIcon: {},
  button: {},
  dialog: {},
  dialogPaper: {
    minWidth: "fit-content"
  },
  tooltip: __spreadValues({}, theme.typography.body2)
});
const useStyles = makeOverrideableStyles("ButtonDialog", styles);
const ButtonDialog = (props) => {
  const {
    children,
    onClose,
    useMenu,
    onOpen,
    disabled,
    disabledMessage,
    buttonText,
    buttonProps,
    buttonClasses,
    fullWidth,
    buttonIcon,
    buttonEndIcon,
    stopPropagation
  } = props;
  const classes = useStyles(props);
  const childArray = React.Children.toArray(children).filter((child) => !!child);
  const [open, setOpen] = useState(false);
  const [activeChild, setActiveChild] = useState(null);
  const resolvedActiveChild = activeChild && childArray.find((child, index) => child.props && child.props.label ? child.props.label === activeChild : index === activeChild);
  const handleOpen = (event, activeChild2) => {
    stopPropagation && event.stopPropagation();
    setOpen(true);
    setActiveChild(activeChild2);
    onOpen && onOpen();
  };
  const handleClose = () => {
    setOpen(false);
    setActiveChild(null);
    onClose && onClose();
  };
  const menuItems = !useMenu ? [] : childArray.map((child, index) => __spreadProps(__spreadValues({}, pick(child.props, ["disabled", "disabledTooltipText", "label", "icon"])), {
    onClick: (event) => handleOpen(event, child.props.label || index)
  }));
  return (
    // @ts-ignore as its showing this is not a valid JSX element
    /* @__PURE__ */ jsxs(ErrorBoundary, { children: [
      /* @__PURE__ */ jsx(
        DisableableButton,
        {
          buttonIcon,
          buttonEndIcon,
          buttonText,
          menuItems: useMenu ? menuItems : void 0,
          disabledMessage,
          disabled,
          onClick: handleOpen,
          buttonProps,
          classes: buttonClasses
        }
      ),
      /* @__PURE__ */ jsx(
        Dialog,
        {
          open,
          className: classes.dialog,
          fullWidth,
          maxWidth: false,
          classes: { paper: classes.dialogPaper },
          disableEnforceFocus: true,
          children: (useMenu ? resolvedActiveChild && React.cloneElement(
            resolvedActiveChild,
            { onClose: handleClose }
          ) : childArray.map((child) => React.cloneElement(
            child,
            { onClose: handleClose }
          ))) || /* @__PURE__ */ jsx("div", {})
        }
      )
    ] })
  );
};
var ButtonDialog_default = ButtonDialog;
export {
  ButtonDialog_default as default
};
