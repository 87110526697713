var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Button, Collapse, FormHelperText, Grid, TextField, Typography } from "@barracuda-internal/bds-core";
import { Search } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { get, isEqual, merge } from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrudTypes } from "../../../clients";
import { useCrudFetch, useDeepCompareEffect, useDeepCompareMemo } from "../../../hooks";
import { formatErrorMessage } from "../../../utils";
import ChipArrayField from "../../fields/ChipArrayField/ChipArrayField";
import ConnectedTable from "../../table/ConnectedTable/ConnectedTable";
import InputLabel from "../InputLabel/InputLabel";
import Text from "../TextInput/Text";
import { InputAdornment } from "@mui/material";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {},
  searchField: {
    marginTop: theme.spacing(2)
  },
  searchFieldButtonbar: {
    margin: theme.spacing(1, 2, 0, 2),
    width: `calc(100% + ${theme.spacing(1)})`
  },
  searchFieldCardActions: {
    padding: 0
  },
  connectedTable: {
    borderColor: (props) => props.error ? theme.palette.error.main : theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    borderStyle: "solid",
    borderWidth: 1
  },
  tableError: {
    marginLeft: theme.spacing(1)
  },
  addSerial: {
    marginLeft: theme.spacing(2)
  },
  collapseButton: {
    textTransform: "none"
  },
  collapse: {
    marginLeft: theme.spacing(1)
  },
  infoText: {
    marginLeft: theme.spacing(3)
  },
  submitButton: {
    marginBottom: 10,
    left: -65
  }
});
const useStyles = makeOverrideableStyles("SelectSerial", styles);
const SelectSerial = (props) => {
  var _a, _b;
  const {
    children,
    claimResource,
    defaultSort = {
      field: "serial",
      order: "asc"
    },
    dynamicFilter,
    filter,
    expandSectionButtonText = "cuda.inputs.selectSerial.expandSectionButtonText",
    helpText = "cuda.inputs.selectSerial.helpText",
    id,
    linkingCodeInputLabel = "cuda.inputs.selectSerial.linkingCode",
    maxSelectable,
    error,
    onChange,
    onBlur,
    value = "",
    optionIdentifier = "serial",
    optionText = "serial",
    optionValue,
    params,
    searchField,
    searchPlaceholder = "cuda.inputs.selectSerial.searchPlaceholder",
    selectedSerialsLabel = "cuda.inputs.selectSerial.selectedSerials",
    serialInputLabel = "cuda.inputs.selectSerial.serial",
    tableResource
  } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const tableRefreshRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showClaimForm, setShowClaimForm] = useState(false);
  const [values, setValues] = useState({});
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [claimedAppliances, setClaimedAppliances] = useState([]);
  const [changedSinceSubmit, setChangedSinceSubmit] = useState({});
  const [saveData, saving, performSave] = useCrudFetch(CrudTypes.CREATE, claimResource, { data: values }, { quietErrors: true });
  const serverErrors = ((_b = (_a = saveData == null ? void 0 : saveData.error) == null ? void 0 : _a.body) == null ? void 0 : _b.errors) || {};
  const formContainsErrors = Object.keys(errors).length > 0;
  const fieldIsTouched = (source) => touched === "all" || touched[source];
  const getFieldErrors = (source) => {
    if (!get(changedSinceSubmit, source) && claimedAppliances.length > 0) {
      return get(serverErrors, source);
    }
    return get(errors, source);
  };
  const claimAppliance = () => {
    setTouched("all");
    if (formContainsErrors) {
      return;
    }
    performSave().then(() => {
      var _a2;
      return (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
    });
    setClaimedAppliances((currentVal) => [...new Set(currentVal.concat([(values.serial || "").trim()]))]);
    setChangedSinceSubmit({});
  };
  const [tableFilter, setTableFilter] = useState(merge({}, filter, searchField && { [searchField]: searchQuery }));
  let selectedRows = value || [];
  if (!Array.isArray(selectedRows)) {
    selectedRows = [selectedRows];
  }
  const {
    isDisabled,
    isSelected,
    onSelect,
    onSelectAll,
    selectAllState
  } = useDeepCompareMemo(() => {
    const getRowValue = (row) => get(row, optionValue || "", row);
    const getRowIdentifier = (row) => optionValue ? getRowValue(row) : get(row, optionIdentifier, row);
    const isSelected2 = (row) => selectedRows.some((selectedRow) => isEqual(getRowIdentifier(selectedRow), getRowIdentifier(row)));
    const isDisabled2 = (row) => !!(maxSelectable && maxSelectable > 1 && selectedRows.length >= maxSelectable && !isSelected2(row));
    const onSelect2 = (row) => {
      onBlur && onBlur();
      if (isDisabled2(row)) {
        return;
      }
      const newSelectedRows = maxSelectable === 1 ? [] : selectedRows.slice();
      if (isSelected2(row)) {
        const itemIndex = newSelectedRows.map(getRowIdentifier).indexOf(getRowIdentifier(row));
        newSelectedRows.splice(itemIndex, 1);
      } else {
        newSelectedRows.push(getRowValue(row));
      }
      onChange && onChange(newSelectedRows);
    };
    const onSelectAll2 = (data) => {
      onBlur == null ? void 0 : onBlur();
      if (selectedRows.length) {
        onChange && onChange([]);
        return;
      }
      onChange && onChange(data.map(getRowValue));
    };
    const selectAllState2 = (data) => selectedRows.length > 0 && selectedRows.length === data.length;
    return {
      isSelected: isSelected2,
      onSelect: onSelect2,
      isDisabled: isDisabled2,
      onSelectAll: onSelectAll2,
      selectAllState: selectAllState2
    };
  }, [optionValue, selectedRows, maxSelectable]);
  const getSerialText = (appliance) => typeof appliance === "object" ? get(appliance, optionText) : appliance;
  const onDelete = (value2) => {
    const newSelectedRows = selectedRows.slice();
    const itemIndex = newSelectedRows.map(getSerialText).indexOf(value2);
    newSelectedRows.splice(itemIndex, 1);
    onChange && onChange(newSelectedRows);
  };
  useDeepCompareEffect(() => {
    setTableFilter(merge({}, filter, dynamicFilter && dynamicFilter(selectedRows), searchField && { [searchField]: searchQuery }));
  }, [selectedRows, searchField, searchQuery]);
  useDeepCompareEffect(() => {
    const errors2 = {};
    if (!values.serial) {
      errors2.serial = translate("cuda.validation.required");
    }
    if (!values.linkingCode) {
      errors2.linkingCode = translate("cuda.validation.required");
    }
    setErrors(errors2);
  }, [values]);
  useDeepCompareEffect(() => {
    if (!saving && saveData && saveData.data && !saveData.error && claimedAppliances.includes(saveData.data && saveData.data.serial)) {
      setValues({});
      setTouched({});
      setShowClaimForm(false);
      onSelect(saveData.data);
    }
  }, [saving]);
  return /* @__PURE__ */ jsxs("div", { id: "serial-input-" + id, className: classes.root, children: [
    /* @__PURE__ */ jsx(
      ConnectedTable,
      {
        actions: searchField && /* @__PURE__ */ jsx(
          TextField,
          {
            className: classes.searchField,
            value: searchQuery,
            onChange: (eventOrValue) => setSearchQuery(eventOrValue.target.value),
            placeholder: translate(searchPlaceholder),
            variant: "outlined",
            fullWidth: true,
            InputProps: {
              startAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: /* @__PURE__ */ jsx(Search, {}) })
            }
          }
        ),
        classes: { dataTable: classes.connectedTable, cardActions: classes.searchFieldCardActions },
        tableActionsAndFiltersProps: { classes: { buttonBar: classes.searchFieldButtonbar } },
        isDisabled,
        isSelected,
        onSelect,
        onSelectAll: maxSelectable ? void 0 : onSelectAll,
        selectAllState,
        flat: true,
        noRouter: true,
        refreshRef: tableRefreshRef,
        resource: tableResource,
        defaultSort,
        params: __spreadProps(__spreadValues({}, params), { filter: tableFilter }),
        disablePageSizes: true,
        children
      }
    ),
    /* @__PURE__ */ jsx(FormHelperText, { error: true, className: classes.tableError, children: formatErrorMessage(error) }),
    (Array.isArray(value) ? value.length > 0 : value) && /* @__PURE__ */ jsx(InputLabel, { label: selectedSerialsLabel, children: /* @__PURE__ */ jsx(
      ChipArrayField,
      {
        data: { serials: Array.isArray(value) ? value.map(getSerialText) : getSerialText(value) },
        source: "serials",
        onDelete
      }
    ) }),
    /* @__PURE__ */ jsxs("div", { className: classes.addSerial, children: [
      /* @__PURE__ */ jsx(
        Button,
        {
          className: classes.collapseButton,
          onClick: () => setShowClaimForm((prevState) => !prevState),
          startIcon: showClaimForm ? /* @__PURE__ */ jsx(ExpandLessIcon, {}) : /* @__PURE__ */ jsx(ExpandMoreIcon, {}),
          variant: "text",
          size: "small",
          children: translate(expandSectionButtonText)
        }
      ),
      /* @__PURE__ */ jsxs(
        Collapse,
        {
          className: classes.collapse,
          in: showClaimForm,
          children: [
            /* @__PURE__ */ jsx(
              Typography,
              {
                className: classes.infoText,
                color: "textSecondary",
                children: translate(helpText)
              }
            ),
            /* @__PURE__ */ jsxs(
              Grid,
              {
                alignItems: "flex-end",
                container: true,
                direction: "row",
                wrap: "nowrap",
                children: [
                  /* @__PURE__ */ jsxs(Grid, { item: true, children: [
                    saveData && saveData.error && claimedAppliances.length > 0 && /* @__PURE__ */ jsx(FormHelperText, { error: true, children: saveData.error.message }),
                    /* @__PURE__ */ jsx(InputLabel, { isRequired: true, label: serialInputLabel, children: /* @__PURE__ */ jsx(
                      Text,
                      {
                        disabled: saving,
                        onBlur: () => !fieldIsTouched("serial") && setTouched((prevState) => prevState === "all" ? "all" : __spreadProps(__spreadValues({}, prevState), { serial: true })),
                        onChange: (event) => {
                          const serial = event && event.target && event.target.value || "";
                          setValues((prevState) => __spreadProps(__spreadValues({}, prevState), { serial }));
                          !changedSinceSubmit.serial && setChangedSinceSubmit((prevState) => __spreadProps(__spreadValues({}, prevState), { serial: true }));
                        },
                        value: values.serial,
                        error: fieldIsTouched("serial") && getFieldErrors("serial"),
                        id: "serial"
                      }
                    ) }),
                    /* @__PURE__ */ jsx(InputLabel, { isRequired: true, label: linkingCodeInputLabel, children: /* @__PURE__ */ jsx(
                      Text,
                      {
                        disabled: saving,
                        id: "linkingcode",
                        onBlur: () => !fieldIsTouched("linkingCode") && setTouched((prevState) => prevState === "all" ? "all" : __spreadProps(__spreadValues({}, prevState), { linkingCode: true })),
                        onChange: (event) => {
                          const linkingCode = event && event.target && event.target.value || "";
                          setValues((prevState) => __spreadProps(__spreadValues({}, prevState), { linkingCode }));
                          !changedSinceSubmit.linkingCode && setChangedSinceSubmit((prevState) => __spreadProps(__spreadValues({}, prevState), { linkingCode: true }));
                        },
                        value: values.linkingCode,
                        error: fieldIsTouched("linkingCode") && getFieldErrors("linkingCode")
                      }
                    ) })
                  ] }),
                  /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(
                    Button,
                    {
                      className: classes.submitButton,
                      color: "primary",
                      size: "small",
                      disabled: formContainsErrors || saving,
                      onClick: claimAppliance,
                      variant: "contained",
                      children: translate("cuda.buttons.add")
                    }
                  ) })
                ]
              }
            )
          ]
        }
      )
    ] })
  ] });
};
var SelectSerial_default = SelectSerial;
export {
  SelectSerial,
  SelectSerial_default as default
};
