import { jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@barracuda-internal/bds-core";
import { CustomField, TableInput, TextField } from "@cuda-react/core";
import { useTranslation } from "react-i18next";
import {
  getNetworkNameFromPorts,
  useNetworkFormValidation,
  useSiteInputValidations
} from "../../../../hooks/createEditSiteHooks";
import SCLanTableInputForm from "./SCLanTableInputForm";
const LanTableInput = ({ portsData }) => {
  const [translate] = useTranslation();
  const networkFormValidation = useNetworkFormValidation();
  const getNetworkName = getNetworkNameFromPorts(portsData);
  const inputValidations = useSiteInputValidations();
  return /* @__PURE__ */ jsxs(
    TableInput,
    {
      source: "lans",
      optionValue: "name",
      labels: {
        addTitle: "tesseract.sites.settings.lan.addTitle",
        addButtonText: "tesseract.sites.settings.lan.addButtonText",
        editTitle: "tesseract.sites.settings.lan.editTitle",
        deleteTitle: "tesseract.sites.settings.lan.deleteTitle",
        deleteMessage: /* @__PURE__ */ jsxs(Typography, { children: [
          /* @__PURE__ */ jsx("div", { children: translate("tesseract.sites.settings.lan.deleteMessage1") }),
          /* @__PURE__ */ jsx("div", { children: translate("tesseract.sites.settings.lan.deleteMessage2") }),
          /* @__PURE__ */ jsx("div", { children: translate("tesseract.sites.settings.lan.deleteMessage3") }),
          /* @__PURE__ */ jsx("p", { children: translate("tesseract.sites.settings.lan.deleteMessage4") })
        ] })
      },
      formValidate: networkFormValidation,
      minimumItems: 1,
      defaultFormValues: { dhcp: { type: "disabled" } },
      minCellWidth: 128,
      formChildren: /* @__PURE__ */ jsx(SCLanTableInputForm, { portsData }),
      children: [
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.sites.settings.lan.name"
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "port",
            label: "tesseract.sites.settings.lan.port",
            render: (value, data) => getNetworkName(data)
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "address",
            label: "tesseract.sites.settings.lan.address"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "netmask",
            label: "tesseract.sites.settings.lan.netmask"
          }
        )
      ]
    }
  );
};
var SCLanTableInput_default = LanTableInput;
export {
  SCLanTableInput_default as default
};
