import { jsx, jsxs } from "react/jsx-runtime";
import { ButtonDialog, formatDateTime, InputLabel } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { Trans } from "react-i18next";
import { Typography } from "@barracuda-internal/bds-core";
import { Edit } from "@barracuda-internal/bds-core/dist/Icons/Core";
import UpdateDialogContent from "./dialog/UpdateDialogContent";
const useStyles = makeStyles({
  dateTimeInput: {
    width: 140
  },
  inputLabel: {
    marginTop: 16,
    paddingRight: 8
  },
  inputRoot: {
    width: 580
  },
  value: {
    paddingTop: 6,
    marginTop: 16,
    "& > *": {
      display: "inline-block"
    },
    "& p": {
      paddingTop: 2
    }
  },
  midText: {
    display: "inline-block",
    color: "grey"
  }
});
const DefaultUpdateWindow = ({ data, onSuccess, availableUpdates }) => {
  const classes = useStyles();
  return /* @__PURE__ */ jsx(
    InputLabel,
    {
      label: "tesseract.settings.tabs.updates.firmware.default.label",
      classes: { inputLabel: classes.inputLabel, root: classes.inputRoot },
      minimised: true,
      children: /* @__PURE__ */ jsxs("div", { className: classes.value, children: [
        /* @__PURE__ */ jsx(Typography, { variant: "body1", children: /* @__PURE__ */ jsx(
          Trans,
          {
            i18nKey: data ? "tesseract.settings.tabs.updates.firmware.default.setting" : "tesseract.settings.tabs.updates.firmware.noUpdatesMessage",
            values: { from: formatDateTime(data == null ? void 0 : data.from), to: formatDateTime(data == null ? void 0 : data.to) },
            children: /* @__PURE__ */ jsx("div", { className: classes.midText })
          }
        ) }),
        data && /* @__PURE__ */ jsx(
          ButtonDialog,
          {
            buttonIcon: /* @__PURE__ */ jsx(Edit, { id: "edit-dialog-icon" }),
            children: /* @__PURE__ */ jsx(
              UpdateDialogContent,
              {
                data,
                title: "tesseract.settings.tabs.updates.firmware.editDefault.dialogTitle",
                defaultUpdateWindow: true,
                availableUpdates,
                onSuccess
              }
            )
          }
        )
      ] })
    }
  );
};
var DefaultUpdateWindow_default = DefaultUpdateWindow;
export {
  DefaultUpdateWindow_default as default
};
