import { Fragment, jsx } from "react/jsx-runtime";
import { useI18next } from "./i18n";
import { useTranslation } from "react-i18next";
const LanguageFullReset = ({ children }) => {
  const { activeLanguage } = useI18next();
  const [, { language }] = useTranslation();
  return /* @__PURE__ */ jsx(Fragment, { children: activeLanguage === language ? children : null });
};
export {
  LanguageFullReset
};
