import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const SitesGraphic = ({ className }) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-graphic"),
      id: "cuda-graphic-sites",
      viewBox: "0 0 96 96",
      children: /* @__PURE__ */ jsx("g", { id: "ArtBoard11", children: /* @__PURE__ */ jsxs("g", { id: "Ebene-1", children: [
        /* @__PURE__ */ jsx("path", { style: { fill: (_a = theme.palette.general) == null ? void 0 : _a.white, strokeWidth: 0 }, d: "M68.7,57h-3.4v6.8h-6.8v3.4h6.8v6.8h3.4v-6.8h6.8v-3.4h-6.8v-6.8Z" }),
        /* @__PURE__ */ jsx("rect", { style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 }, x: "42.5", y: "25.7", width: "12.2", height: "9.2" }),
        /* @__PURE__ */ jsx("path", { style: { fill: (_c = theme.palette.icons) == null ? void 0 : _c.graphics, strokeWidth: 0 }, d: "M42.5,56.2h6.9c1.3-2.5,3.1-4.6,5.3-6.3v-12h-12.2v18.3Z" }),
        /* @__PURE__ */ jsx("rect", { style: { fill: (_d = theme.palette.icons) == null ? void 0 : _d.graphics, strokeWidth: 0 }, x: "24.2", y: "44", width: "15.3", height: "12.2" }),
        /* @__PURE__ */ jsx("rect", { style: { fill: (_e = theme.palette.icons) == null ? void 0 : _e.graphics, strokeWidth: 0 }, x: "24.2", y: "25.7", width: "15.3", height: "15.3" }),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_f = theme.palette.icons) == null ? void 0 : _f.graphics, strokeWidth: 0 },
            d: "M47.1,65.5c0-.8,0-1.6.2-2.4h-30V18.8h44.4v27.6c1.7-.5,3.5-.7,5.3-.7v-26.1c0-3.4-2.8-6.1-6.1-6.1H18.1c-3.4,0-6.1,2.8-6.1,6.1v42.8c0,3.4,2.8,6.1,6.1,6.1h29.3c-.1-1-.2-1.9-.2-2.9"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_g = theme.palette.success) == null ? void 0 : _g.main, strokeWidth: 0 },
            d: "M67,48.5c-9.4,0-17,7.6-17,17s7.6,17,17,17,17-7.6,17-17-7.6-17-17-17ZM75.5,67.2h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"
          }
        )
      ] }) })
    }
  );
};
var SitesGraphic_default = SitesGraphic;
export {
  SitesGraphic,
  SitesGraphic_default as default
};
