var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import EnableSupportDialog from "./support/EnableSupportDialog";
import RestartSiteDialog from "./restart/RestartSiteDialog";
import { Dashboard, useMixpanel } from "@cuda-react/core";
import SiteSummaryCard from "./cards/SiteSummaryCard";
import IoTStatusCard from "./cards/IoTStatusCard";
import DetailsCard from "./cards/DetailsCard";
import FirmwareCard from "./cards/FirmwareCard";
import SiteStatusCard from "./cards/SiteStatusCard";
import DeploymentCard from "./cards/DeploymentCard";
import TunnelLocationsCard from "./cards/TunnelLocationsCard";
import { useMediaQuery, useTheme } from "@mui/material";
import GatewaySummaryCard from "./cards/GatewaySummaryCard";
import VPNTunnelsCard from "./cards/VPNTunnelsCard";
const ApplianceDashboard = (props) => {
  const { type, details: { gatewayType, id, model, modelSeries, name, serials, uuid, applicationId } } = props;
  const theme = useTheme();
  const smallMedium = useMediaQuery(theme.breakpoints.down(1130));
  const isSc = modelSeries === "S" || !modelSeries;
  let actions;
  if (type === "sites" && !isSc) {
    actions = [
      /* @__PURE__ */ jsx(EnableSupportDialog, { uuid, name, applianceType: "site" }, 0),
      /* @__PURE__ */ jsx(RestartSiteDialog, { id, serials }, 1)
    ];
  } else if (type !== "sites") {
    actions = [
      /* @__PURE__ */ jsx(
        EnableSupportDialog,
        {
          uuid,
          name,
          applianceType: "gateway",
          gatewayType,
          applicationId
        },
        0
      )
    ];
  }
  useMixpanel(
    gatewayType ? "Gateways Dashboard" : "Sites Dashboard",
    !!model,
    { model: model || "", type: gatewayType || modelSeries || "" }
  );
  return /* @__PURE__ */ jsxs(
    Dashboard,
    {
      authenticated: true,
      columns: type === "sites" ? [{ xs: 12, md: 6, lg: 4 }, { xs: 12, md: 6, lg: 8 }] : [
        { xs: 12, md: smallMedium ? 12 : 6, lg: 5, xl: 4 },
        { xs: 12, md: smallMedium ? 12 : 6, lg: 7, xl: 8 }
      ],
      actions,
      children: [
        type === "sites" ? /* @__PURE__ */ jsx(SiteSummaryCard, __spreadValues({ column: 0 }, props)) : /* @__PURE__ */ jsx(GatewaySummaryCard, __spreadValues({ column: 0 }, props)),
        /* @__PURE__ */ jsx(FirmwareCard, __spreadValues({ column: 0 }, props)),
        /* @__PURE__ */ jsx(SiteStatusCard, __spreadValues({ column: 0 }, props)),
        /* @__PURE__ */ jsx(TunnelLocationsCard, __spreadValues({ column: 1 }, props)),
        /* @__PURE__ */ jsx(IoTStatusCard, __spreadValues({ column: 1 }, props)),
        /* @__PURE__ */ jsx(VPNTunnelsCard, __spreadValues({ column: 1 }, props)),
        /* @__PURE__ */ jsx(DetailsCard, __spreadValues({ column: 1 }, props)),
        /* @__PURE__ */ jsx(DeploymentCard, __spreadValues({ column: 1 }, props))
      ]
    }
  );
};
var ApplianceDashboard_default = ApplianceDashboard;
export {
  ApplianceDashboard_default as default
};
