import { jsx } from "react/jsx-runtime";
import { formatErrorMessage } from "../../../utils";
import { Typography } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
import { useFormState } from "react-hook-form";
import { get } from "lodash";
const styles = (theme) => createStyles({
  error: {
    color: theme.palette.error.main,
    padding: theme.spacing(1, 1, 2, 6)
  }
});
const useStyles = makeOverrideableStyles("ErrorDisplay", styles);
const unpackErrors = (errors, source) => {
  if (!source) {
    return errors;
  }
  if (Array.isArray(source)) {
    return source.map((subsource) => get(errors, subsource)).filter((error) => !!error);
  }
  return get(errors, source);
};
const ErrorDisplay = (props) => {
  const { source, render, className } = props;
  const classes = useStyles(props);
  const { errors: allErrors } = useFormState({ name: source });
  const errors = unpackErrors(allErrors, source);
  if (!errors || Array.isArray(errors) && errors.length === 0) {
    return null;
  }
  return render ? render(errors) : /* @__PURE__ */ jsx(Typography, { className: classNames(className, classes.error), id: "cuda-form-error", children: formatErrorMessage(errors) });
};
var ErrorDisplay_default = ErrorDisplay;
export {
  ErrorDisplay,
  ErrorDisplay_default as default
};
