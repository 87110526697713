import { jsx, jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@barracuda-internal/bds-core";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Success } from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import { StepIcon } from "@mui/material";
import { CrudTypes, getDataContent, useCrudFetch, useGlobalParam } from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import hexGenerator from "hex-generator";
const useStyles = makeStyles((theme) => ({
  imageContainer: {
    textAlign: "center"
  },
  icon: {
    color: theme.palette.success.main,
    height: 80,
    width: 80
  },
  divider: {
    marginTop: 18,
    marginBottom: 18,
    width: "100%"
  },
  secCreated: {
    textAlign: "center",
    marginBottom: theme.spacing(2)
  },
  message: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center"
  },
  sectionNextSteps: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  sectionInfoSteps: {
    display: "flex"
  },
  stepText: {
    marginLeft: theme.spacing(1)
  },
  sectionActionSteps: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1)
  },
  sectionActionStepsButtons: {
    marginRight: theme.spacing(1)
  }
}));
const SdwanConnectorResultStep = ({ data, result }) => {
  var _a;
  const classes = useStyles();
  const [translate] = useTranslation();
  const demo = useGlobalParam("userData.mode")[0] === "demo";
  const [tokenData, , fetchToken] = useCrudFetch(CrudTypes.CREATE, apiResources.sdwanConnectorRegisterToken, { id: (_a = result == null ? void 0 : result.data) == null ? void 0 : _a.id });
  const tokenValue = React.useMemo(() => demo ? hexGenerator(128) : (getDataContent(tokenData) || {}).otp, [demo, tokenData]);
  useEffect(() => {
    demo || fetchToken();
  }, []);
  return /* @__PURE__ */ jsx(Grid, { container: true, direction: "column", children: /* @__PURE__ */ jsxs(Grid, { item: true, children: [
    /* @__PURE__ */ jsx("div", { className: classes.imageContainer, children: /* @__PURE__ */ jsx(Success, { className: classes.icon }) }),
    /* @__PURE__ */ jsx(Divider, { className: classes.divider }),
    /* @__PURE__ */ jsx(Typography, { className: classes.secCreated, variant: "h6", children: translate("tesseract.network.sdwanConnector.dialog.completed.sectionTitle", { connector: data == null ? void 0 : data.name }) }),
    /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.network.sdwanConnector.dialog.completed.sectionMessage", children: /* @__PURE__ */ jsx("strong", {}) }) }),
    /* @__PURE__ */ jsx(Divider, { className: classes.divider }),
    /* @__PURE__ */ jsx(Typography, { variant: "h6", children: translate("tesseract.network.sdwanConnector.dialog.completed.sectionTitleNextSteps", { connector: data == null ? void 0 : data.name }) }),
    /* @__PURE__ */ jsxs("div", { className: classes.sectionNextSteps, children: [
      /* @__PURE__ */ jsxs("div", { className: classes.sectionInfoSteps, children: [
        /* @__PURE__ */ jsx(StepIcon, { icon: 1, active: true }),
        /* @__PURE__ */ jsx(Typography, { className: classes.stepText, variant: "body1", children: translate("tesseract.network.sdwanConnector.dialog.completed.sectionSteps") })
      ] }),
      /* @__PURE__ */ jsxs("div", { className: classes.sectionActionSteps, children: [
        /* @__PURE__ */ jsx(
          Button,
          {
            className: classes.sectionActionStepsButtons,
            variant: "contained",
            color: "primary",
            href: "https://d.barracudanetworks.com/SDWC/SDWANConnector_1.0.0-2_x64.exe",
            target: "_blank",
            children: translate("tesseract.network.sdwanConnector.dialog.completed.downloadButtonWindows")
          }
        ),
        /* @__PURE__ */ jsx(
          Button,
          {
            className: classes.sectionActionStepsButtons,
            variant: "contained",
            color: "primary",
            href: "https://d.barracudanetworks.com/SDWC/SDWANConnector_1.0.0-2_Linux.zip",
            target: "_blank",
            children: translate("tesseract.network.sdwanConnector.dialog.completed.downloadButtonLinux")
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx("div", { className: classes.sectionNextSteps, children: /* @__PURE__ */ jsxs("div", { className: classes.sectionInfoSteps, children: [
      /* @__PURE__ */ jsx(StepIcon, { icon: 2, active: true }),
      /* @__PURE__ */ jsxs(Typography, { className: classes.stepText, variant: "body1", children: [
        translate("tesseract.network.sdwanConnector.dialog.completed.sectionSteps2"),
        /* @__PURE__ */ jsx("div", { children: tokenValue })
      ] })
    ] }) }),
    /* @__PURE__ */ jsx("div", { className: classes.sectionNextSteps, children: /* @__PURE__ */ jsxs("div", { className: classes.sectionInfoSteps, children: [
      /* @__PURE__ */ jsx(StepIcon, { icon: 3, active: true }),
      /* @__PURE__ */ jsx(Typography, { className: classes.stepText, variant: "body1", children: translate("tesseract.network.sdwanConnector.dialog.completed.sectionSteps3") })
    ] }) })
  ] }) });
};
var SdwanConnectorResultStep_default = SdwanConnectorResultStep;
export {
  SdwanConnectorResultStep,
  SdwanConnectorResultStep_default as default
};
