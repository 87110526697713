import { jsx } from "react/jsx-runtime";
import {
  CustomField,
  getArrayDataContent,
  LinkField,
  SelectedChoiceField,
  useCrudProps,
  useGlobalParam
} from "@cuda-react/core";
import { getAzureHubLink, getAzureResourceLink } from "../../../../utils/azureUtils";
import apiResources from "../../../../apiResources";
const useGatewayDetails = () => {
  var _a, _b;
  const isDemo = useGlobalParam("userData.mode")[0] === "demo";
  const regionChoices = (_a = useCrudProps(apiResources.gatewayRegions)[0]) == null ? void 0 : _a.data;
  const scaleUnitChoices = (_b = useCrudProps(apiResources.gatewayScaleUnits)[0]) == null ? void 0 : _b.data;
  return [
    /* @__PURE__ */ jsx(
      LinkField,
      {
        label: "tesseract.dashboard.cards.vpn.locations.gateways.fields.name",
        source: "name",
        link: getAzureResourceLink("applicationId", isDemo)
      },
      "name"
    ),
    /* @__PURE__ */ jsx(
      CustomField,
      {
        label: "tesseract.dashboard.cards.vpn.locations.gateways.fields.sites",
        source: "tseries",
        render: (sites, data) => /* @__PURE__ */ jsx(
          LinkField,
          {
            defaultText: sites && sites.length || sites,
            link: `/infrastructure/sites?filter={"upstream":[${data.id}]}`
          }
        )
      },
      "tseries"
    ),
    /* @__PURE__ */ jsx(
      CustomField,
      {
        label: "tesseract.dashboard.cards.vpn.locations.gateways.fields.iot",
        source: "iot",
        render: (sites, data) => /* @__PURE__ */ jsx(
          LinkField,
          {
            defaultText: sites && sites.length || sites,
            link: `/infrastructure/iot?filter={"upstream":[${data.id}]}`
          }
        )
      },
      "iot"
    ),
    /* @__PURE__ */ jsx(
      LinkField,
      {
        label: "tesseract.dashboard.cards.vpn.locations.gateways.fields.vwan",
        source: "virtualWan.name",
        link: getAzureResourceLink("virtualWan.resourceId", isDemo)
      },
      "vwanName"
    ),
    /* @__PURE__ */ jsx(
      LinkField,
      {
        label: "tesseract.dashboard.cards.vpn.locations.gateways.fields.hub",
        source: "hubName",
        link: getAzureHubLink("hubId", isDemo)
      },
      "hubName"
    ),
    /* @__PURE__ */ jsx(
      SelectedChoiceField,
      {
        label: "tesseract.dashboard.cards.vpn.locations.gateways.fields.region",
        source: "region",
        choices: getArrayDataContent(regionChoices)
      },
      "region"
    ),
    /* @__PURE__ */ jsx(
      SelectedChoiceField,
      {
        label: "tesseract.dashboard.cards.vpn.locations.gateways.fields.size",
        source: "scaleUnit",
        choices: getArrayDataContent(scaleUnitChoices)
      },
      "scaleUnit"
    )
  ];
};
var useGatewayDetails_default = useGatewayDetails;
export {
  useGatewayDetails_default as default,
  useGatewayDetails
};
