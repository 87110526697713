var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { Button, IconButton, TextField } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { Assignment } from "@barracuda-internal/bds-core/dist/Icons/Core";
const CopyToClipboardButton = (_a) => {
  var _b = _a, {
    value,
    label = "tesseract.copy.label",
    iconOnly,
    onClick,
    hideButton,
    onClickRef
  } = _b, props = __objRest(_b, [
    "value",
    "label",
    "iconOnly",
    "onClick",
    "hideButton",
    "onClickRef"
  ]);
  const textRef = useRef(null);
  const [translate] = useTranslation();
  const onClickHandler = () => {
    var _a2, _b2;
    onClick == null ? void 0 : onClick();
    if (!(navigator == null ? void 0 : navigator.clipboard)) {
      (_a2 = textRef == null ? void 0 : textRef.current) == null ? void 0 : _a2.select();
      document.execCommand("copy");
      (_b2 = textRef == null ? void 0 : textRef.current) == null ? void 0 : _b2.setSelectionRange(0, 0);
    } else {
      navigator.clipboard.writeText(value);
    }
  };
  if (onClickRef) {
    onClickRef.current = onClickHandler;
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    !hideButton ? iconOnly ? /* @__PURE__ */ jsx(IconButton, __spreadProps(__spreadValues({ onClick: onClickHandler }, props), { children: /* @__PURE__ */ jsx(Assignment, {}) })) : /* @__PURE__ */ jsx(
      Button,
      __spreadProps(__spreadValues({
        onClick: onClickHandler,
        variant: "contained",
        color: "primary",
        startIcon: /* @__PURE__ */ jsx(Assignment, {})
      }, props), {
        children: translate(label)
      })
    ) : null,
    /* @__PURE__ */ jsx(TextField, { inputRef: textRef, value, style: { display: "none" } })
  ] });
};
var CopyToClipboardButton_default = CopyToClipboardButton;
export {
  CopyToClipboardButton_default as default
};
