var de_default = {
  translation: {
    stratosReporting: {
      resourceStatisticsCard: {
        overview: "Gesamt"
      },
      domainCard: {
        title: "Domains"
      },
      dualTopBarBaseCard: {
        title: "Domains",
        overview: "Insgesamt",
        totalAllowedSessions: "Zul\xE4ssige Sitzungen ",
        totalBlockedSessions: "Gesperrte Sitzungen ",
        allowed: "Zul\xE4ssig",
        blocked: "Gesperrt"
      },
      topUrlCard: {
        title: "URL-Kategorien"
      },
      malwareCard: {
        title: "Erweiterter Bedrohungs-Schutz",
        hits: "{{formattedCount}} Treffer",
        hits_one: "{{formattedCount}} Treffer",
        hits_other: "{{formattedCount}} Treffer"
      },
      ipsIncidentCard: {
        title: "IPS-Vorf\xE4lle",
        sessions: "{{sessions}} Treffer",
        sessions_one: "{{sessions}} Treffer",
        sessions_other: "{{sessions}} Treffer"
      },
      affectedUsers: {
        title: "Betroffene Benutzer"
      },
      sdwanStatusCard: {
        title: "SD-WAN Status",
        overviewTitle: "Gesamt",
        healthy: "Gesund",
        degraded: "Eingeschr\xE4nkt",
        down: "Unterbrochen",
        tableTitle: "SD-WAN Status Top 10 Ger\xE4te",
        name: "Name",
        location: "Standort",
        type: "Typ"
      },
      totalChart: {
        total: "Insgesamt"
      },
      sdwanBandwidthCard: {
        tableTitle: "SD-WAN Bandbreite Top 5",
        title: "SD-WAN Bandbreite Zusammenfassung"
      },
      sdwanBandwidthChartCard: {
        title: "SD-WAN Bandbreite",
        topTunnels: "Top {{length}} Tunnels"
      },
      osStatusCard: {
        title: "Betriebssysteme",
        others: "Andere",
        name: "Name",
        location: "Standort",
        type: "Typ",
        ip: "IP-Adresse",
        linux: "Linux",
        mac: "Mac OSX",
        windows: "Windows",
        other: "anderes",
        android: "Android",
        ios: "iOS"
      },
      applianceMapCard: {
        title: "\xDCbersicht der Appliances"
      },
      baseBarCard: {
        total: "von {{total}}"
      },
      topApplicationCard: {
        title: "Top Anwendungen",
        topApplications: "Top {{length}} Anwendungen"
      },
      sdwanSummaryCard: {
        title: "SD-WAN Zusammenfassung",
        topAppliances: "Top {{length}} Appliances",
        inbound: "eingehend",
        outbound: "ausgehend"
      },
      topAllowedCategoriesSessions: {
        title: "Top zul\xE4ssige App-Kategorien <0>(pro Sitzung)</0>",
        menuEntry: "Top zul\xE4ssige App-Kategorien (pro Sitzung)",
        footerKey: "Top {{length}}",
        sessions: "{{formattedCount}} Sitzungen",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      topAllowedCategoriesTraffic: {
        title: "Top zul\xE4ssige App-Kategorien <0>(pro Traffic)</0>",
        menuEntry: "Top zul\xE4ssige App-Kategorien (pro Traffic)",
        footerKey: "Top {{length}}"
      },
      blockedApplicationCategories: {
        title: "Top gesperrte App-Kategorien",
        footerKey: "Top {{length}}"
      },
      allowedApplicationsSessions: {
        title: "Top zul\xE4ssige Apps <0>(pro Sitzung)</0>",
        menuEntry: "Top zul\xE4ssige Apps (pro Sitzung)",
        sessions: "{{formattedCount}} Sitzungen",
        footerKey: "Top {{length}}",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      allowedApplicationsTraffic: {
        title: "Top zul\xE4ssige Apps <0>(pro Traffic)</0>",
        menuEntry: "Top zul\xE4ssige Apps (pro Traffic)",
        footerKey: "Top {{length}}"
      },
      blockedApplications: {
        title: "Top gesperrte Apps",
        sessions: "{{formattedCount}} Sitzungen",
        footerKey: "Top {{length}}",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      applicationRisk: {
        mediumHigh: "Mittel -\nHoch",
        medium: "Mittel",
        lowMedium: "Gering - Mittel",
        low: "Gering",
        high: "Hoch",
        title: "App-Risiko",
        risk: "Risiko"
      },
      highRiskApplicationsCard: {
        title: "Apps mit hohem Risiko",
        top: "Top {{length}}"
      },
      topWebRequest: {
        title: "Web Aufrufe",
        tableTitle: "Top Web Aufrufe",
        total: "Gesamt",
        allowed: "erlaubt",
        blocked: "blockiert",
        type: "Typ",
        location: "Standort",
        name: "Name"
      },
      storageCard: {
        title: "Speicher\xFCberblick",
        use: "<0>{{spaceUsed}}GB</0> belegt von {{totalSpace}}GB",
        retention: "Vorratsdatensspeicherung f. ca. 3 Tage",
        name: "Name",
        lastChange: "Zuletzt ge\xE4ndert",
        fileSize: "Detaigr\xF6sse",
        storageLow: "Der Speicherplatz wird knapp!",
        upgradeStorageNow: "Bitte Speicherplatz erweitern",
        tableTitle: "Top Appliances die Speicher belegen"
      },
      topBlockedUserCard: {
        title: "Top gesperrte Benutzer",
        topUsers: "Top {{length}} Benutzer",
        total: "out of {{total}}",
        hits: "{{formattedCount}} Treffer",
        hits_one: "{{formattedCount}} Treffer",
        hits_other: "{{formattedCount}} Treffer"
      },
      topBlockedUrlCategoriesCard: {
        title: "Top gesperrte URL-Kategorien",
        topUrlCategories: "Top {{length}} gesperrte URL-Kategorien",
        total: "von {{total}}",
        sessions: "{{formattedCount}} Sitzungen",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      applicationCategories: {
        title: "App-Kategorien"
      },
      topApplications: {
        title: "Apps"
      },
      topAllowedUrlCategoriesCard: {
        titlePerSession: "Top zul\xE4ssige URL-Kategorien <0>(pro Sitzung)</0>",
        titlePerTraffic: "Top zul\xE4ssige URL-Kategorien <0>(pro Traffic)</0>",
        menuEntryPerSession: "Top zul\xE4ssige URL-Kategorien (pro Sitzung)",
        menuEntryPerTraffic: "Top zul\xE4ssige URL-Kategorien (pro Traffic)",
        topAllowedUrlCategories: "Top {{length}} zul\xE4ssige URL-Kategorien",
        total: "von {{total}}",
        sessions: "{{formattedCount}} Sitzungen",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      topAllowedDomainsCard: {
        titlePerSession: "Top zul\xE4ssige Domains <0>(pro Sitzung)</0>",
        titlePerTraffic: "Top zul\xE4ssige Domains <0>(pro Traffic)</0>",
        menuEntryPerSession: "Top zul\xE4ssige Domains (pro Sitzung)",
        menuEntryPerTraffic: "Top zul\xE4ssige Domains (pro Traffic)",
        topAllowedDomains: "Top {{length}} zul\xE4ssige Domains",
        total: "von {{total}}",
        sessions: "{{formattedCount}} Sitzungen",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      topBlockedDomainsCard: {
        title: "Top gesperrte Domains",
        topBlockedDomains: "Top {{length}} gesperrte Domains",
        total: "von {{total}}",
        sessions: "{{formattedCount}} Sitzungen",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      topUsersCard: {
        title: "Top Benutzer",
        topUsers: "Top {{length}} Benutzer",
        total: "von {{total}}"
      },
      sdwanHeatMap: {
        title: "SD-WAN \xDCbersichtskarte"
      },
      legendFooter: {
        online: "erreichbar",
        offline: "nicht erreichbar",
        degraded: "degradiert"
      },
      applianceStatus: {
        title: "Appliance Status",
        topUsers: "Top {{length}} Benutzer",
        total: "von insgesamt {{total}}"
      },
      previewDialog: {
        addWidgetButton: "Widget hinzuf\xFCgen",
        title: "Anzeigekomponente hinzuf\xFCgen",
        card: "Anzeigekomponente",
        size: "Gr\xF6\xDFe",
        cancel: "Abbrechen",
        add: "Hinzuf\xFCgen",
        small: "Klein",
        medium: "Mittel",
        large: "Gross",
        addWidgetCard: "Klicken, um Widget hinzuzuf\xFCgen!",
        ok: "OK",
        editTitle: "Widget bearbeiten"
      },
      reportTable: {
        title: "Alle Reports",
        type: "Typ",
        name: "Name",
        period: "Zeitperiode",
        devices: "Ger\xE4te",
        schedule: "Zeitplan",
        owner: "Eigent\xFCmer"
      },
      reportDialog: {
        create: {
          title: "Report erstellen",
          button: "Erstellen"
        },
        edit: {
          title: "Report \xE4ndern",
          button: "\xC4ndern"
        },
        reportData: {
          label: "Report Daten",
          application: {
            name: "Anwendung"
          },
          protocol: {
            name: "Protokoll"
          },
          applicationRule: {
            name: "Anwendungs Regel"
          },
          contentType: {
            name: "Art des Inhalts"
          },
          outputInterface: {
            name: "Ausgabe Schnittstelle"
          },
          destination: {
            name: "Ziel"
          }
        },
        datasource: {
          label: "Datenherkunft",
          activity: {
            name: "Aktivit\xE4ten"
          },
          threat: {
            name: "Bedrohungen"
          },
          webFilter: {
            name: "Web-Filter"
          },
          sdwanTunnels: {
            name: "SD-WAN Verbindungen"
          },
          sdwanTunnelsAppProtocol: {
            name: "SD-WAN Verbindungen, Anwendungen und Protokolle"
          }
        },
        output: {
          label: "Ausgabe",
          outputFormat: {
            label: "Ausgabeformat"
          },
          deliveryOptions: {
            label: "Ausgabeoptionen",
            email: "E-Mail",
            fileDownload: "Datei Download"
          },
          recipients: {
            label: "Empf\xE4nger"
          },
          sizeLimit: {
            label: "Gr\xF6ssenlimitierung"
          },
          frequency: {
            label: "H\xE4ufigkeit",
            once: "Einmalig",
            weekly: "Jede Woche",
            monthly: "Jeden Monat"
          },
          status: {
            label: "Status",
            enabled: "Aktiviert",
            disabled: "Deaktiviert"
          }
        },
        advanced: {
          label: "Erweitert",
          destination: {
            label: "Ziel",
            domain: "Dom\xE4nen",
            category: "Kategorie"
          },
          ipAnonymize: {
            label: "IP Anonymisieren"
          },
          serialNumberBucketing: {
            label: "Seriennummern zusammenfassen"
          },
          domains: {
            label: "Dom\xE4nen"
          },
          exludeTimeframe: {
            label: "Ausgeschlossene Zeitspanne"
          },
          action: {
            label: "Aktion"
          },
          chartType: {
            label: "Grafiktyp",
            horizontalBar: "Horizontales Balkendiagramm",
            verticalBar: "Vertikales Balkendiagramm",
            pieChart: "Tortendiagramm"
          },
          ddLimitLevels: {
            label: "Drill-Down Limitierungen"
          }
        },
        result: {
          label: "Ergebnis"
        },
        success: "Erfolgreich erstellt"
      },
      userEnrollment: {
        title: "Benutzer",
        enrolledUsers: "Benutzer",
        enrolledDevices: "Ger\xE4te",
        pendingEnrollment: "Ausstehend",
        name: "Name",
        location: "Standort",
        device: "Ger\xE4tetyp"
      },
      licensing: {
        title: "Lizensierung",
        bandwidth: "Bandbreite",
        bandwidthUsageMedium: "{{remain}} Gbit/s verf\xFCgbar (von {{total}} Gbit/s)",
        bandwidthUsageLarge: "{{used}} verbraucht (von {{total}} GB/s)",
        bandwidthExpire: "L\xE4uft ab in {{daysLeft}} Tagen",
        users: "Benutzer",
        usersUsageMedium: "{{remain}} Benutzer verf\xFCgbar (von {{total}} Benutzern)",
        usersUsageLarge: "{{used}} Benutzer vergeben (von {{total}} Benutzern)",
        usersExpire: "{{remain}} Benutzer verf\xFCgbar",
        storage: "Speicher",
        storageUsageMedium: "{{remain}} GB verf\xFCgbar (von {{total}} GB)",
        storageUsageLarge: "{{used}} GB/s benutzt (von {{total}} GB/s)",
        storageRetention: "Max. Datenspeicherung: ca. {{remain}} Tage",
        storageExpire: "L\xE4uft aus in {{daysLeft}} Tagen",
        appliances: "Appliances",
        appliancesUsageMedium: "Noch {{remain}} Tage verf\xFCgbar (von {{total}} Tagen)",
        appliancesUsageLarge: "{{used}} Tage benutze (von {{total}} Tagen)",
        appliancesExpire: "L\xE4uft ab in {{daysLeft}} Tagen",
        percentage: "{{percentage}}"
      },
      dashboardPage: {
        done: "Erledigt",
        cancel: "Abbruch",
        editDashboard: "Dashboard \xE4ndern",
        emptyMessage: "Dieses Dashboard enth\xE4lt noch keine Komponenten.",
        addMessage: "Komponenten hinzuf\xFCgen indem Sie Zuf\xFCgen clicken.",
        lastDay: "Letzte 24 Stunden",
        lastWeek: "Letzte Woche",
        lastMonth: "Letzter Monat",
        configureDashboard: "Dieses Dashboard einrichten!",
        addDashboard: "Dashboard erstellen",
        name: "Name",
        template: "Vorlage",
        reset: "Dashboard zur\xFCcksetzen",
        rename: "Dashboard umbenennen",
        delete: "Dashboard l\xF6schen",
        confirmDeletion: "M\xF6chten Sie wirklich das Dashboard '{{name}}' l\xF6schen?",
        actions: "Optionen",
        add: "Dashboard hinzuf\xFCgen",
        resetToTemplate: "Auf Template zur\xFCcksetzen",
        validateName: "Darf nur alphanumerische Zeichen oder Leerzeichen enthalten.",
        changeDashboardMessage: "Sind Sie sicher, dass Sie Ihre \xC4nderungen nicht speichern wollen?",
        uniqueNameError: "Muss eindeutig sein",
        resetToDefault: "M\xF6chten Sie das Dashboard auf seinen Standardzustand zur\xFCcksetzen?",
        resetToTemplateDescription: "Ihr Dashboard wieder auf die urspr\xFCnglichen Widgets und das Layout der gew\xE4hlten Vorlage zur\xFCcksetzen?",
        options: "Optionen",
        maxNumberOfDashboardReached: "Es k\xF6nnen keine weiteren Dashboards erzeugt werden, Maximum von {{maxDashboardsAmount}} erreicht"
      },
      noData: {
        message: "Keine Daten f\xFCr dieses Widget!"
      },
      barGrid: {
        noData: "Keine passenden Eintr\xE4ge gefunden!"
      },
      geoCard: {
        sourceTopCountries: {
          title: "Top Geo Quell-L\xE4nder"
        },
        destinationTopCountries: {
          title: "Top Geo Ziel-L\xE4nder"
        }
      },
      ZTNAResources: {
        title: "Ressourcen",
        others: "Sonstige",
        total: "Insgesamt verf\xFCgbar",
        custom: "Benutzerdefiniert",
        inUse: "Wird verwendet",
        tableName: "Access-Richtlinien",
        table: {
          name: "Name",
          description: "Beschreibung",
          users: "Benutzer",
          groups: "Gruppen",
          resources: "Ressourcen",
          devicePosture: "Device Posture"
        }
      },
      ZTNAAgentInUse: {
        title: "Meistverwendete Betriebssysteme f\xFCr Agents",
        total: "Insgesamt",
        windows: "Windows",
        macos: "macOS",
        android: "Android",
        ios: "iOS",
        linux: "Linux",
        tableName: "Meistverwendete Betriebssysteme",
        devices: "{{value}} Endger\xE4t",
        devices_plural: "{{value}} Endger\xE4te"
      },
      ZTNADeviceLocation: {
        title: "Endger\xE4te-Karte",
        devices: "Endger\xE4te",
        noData: "Kein Ger\xE4t gefunden.",
        user: "Benutzer",
        brand: "Endger\xE4te-Marke",
        model: "Endger\xE4te-Modell",
        hardwareModel: "Hardwaremodell",
        operatingSystem: "Betriebssystem",
        operatingSystemVersion: "Betriebssystem Version",
        agentVersion: "Agent Version",
        enrollmentDate: "Anmeldedatum",
        country: "Land",
        noCountry: "Kein Standort"
      },
      ZTNAUserGeoLocation: {
        title: "Geo-Standort des Benutzers",
        nonRoutableOrIP: "Nicht routingf\xE4hige oder private IP-Adressen",
        devices: "{{value}} Endger\xE4t",
        devices_plural: "{{value}} Endger\xE4te"
      },
      userStatusCard: {
        title: "Benutzer",
        total: "Insgesamt",
        enrolled: "Registriert",
        pending: "Ausstehend",
        tableTitle: "Top-Benutzer (nach Anzahl der Ger\xE4te)",
        name: "Name",
        email: "E-Mail",
        deviceCount: "Anzahl der Endger\xE4te",
        enrollmentCompleted: {
          label: "Registrierung abgeschlossen",
          yes: "Registrierung abgeschlossen",
          no: "Registrierung ausstehend"
        }
      },
      userSummaryCard: {
        title: "Zusammenfassung",
        fullName: "Vollst\xE4ndiger Name",
        email: "E-Mail",
        deviceCount: "Anzahl der Endger\xE4te",
        enrollmentCompleted: "Anmeldung abgeschlossen"
      },
      userDevicesCard: {
        title: "Endger\xE4te",
        table: {
          userFullName: "Vollst\xE4ndiger Name",
          userEmail: "E-Mail",
          brand: "Endger\xE4te-Marke",
          hardwareModel: "Endger\xE4te-Modell",
          operatingSystem: "Betriebssystem",
          operatingSystemVersion: "Betriebssystem Version",
          enrollmentDate: "Anmeldungsdatum"
        }
      },
      accessDevicesAgentVersionCard: {
        title: "Verwendete Agent Version",
        total: "Insgesamt",
        version: "Version {{version}}",
        older: "\xC4lter",
        tableTitle: "Verwendete Top-Versionen",
        devices: "{{value}} Endger\xE4t",
        devices_plural: "{{value}} Endger\xE4te"
      },
      categories: {
        ztna: "Zero Trust Access",
        security: "Sicherheit",
        webFilter: "Web-Filter"
      }
    }
  }
};
export {
  de_default as default
};
