import { jsx, jsxs } from "react/jsx-runtime";
import { List, ListItem, ListItemIcon, ListItemText } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import { get } from "lodash";
import React from "react";
import { StatusIcon } from "@cuda-react/icons";
import { formatDateTime } from "../../../utils";
import LoadingMessage from "../../loading/LoadingMessage/LoadingMessage";
import NoDataMessage from "../../loading/NoDataMessage/NoDataMessage";
import { useCardGetFrom } from "../../../hooks";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  secondaryText: {
    color: theme.palette.text.secondary,
    // @ts-ignore complains the value isnt valid for this, but it is.
    wordWrap: "break-word !important",
    fontSize: 14,
    lineHeight: "16px",
    margin: theme.spacing(0.5, 0, 0)
  },
  secondaryTextDate: {
    float: "right",
    marginLeft: theme.spacing(4)
  },
  listItem: (props) => ({
    cursor: props.onClick ? "pointer" : "default",
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      // @ts-ignore hover is a property added by CudaTheme
      backgroundColor: theme.palette.background.hover
    },
    "&:nth-child(odd)": {
      backgroundColor: "transparent"
    },
    "&:nth-child(odd):hover": {
      // @ts-ignore hover is a property added by CudaTheme
      backgroundColor: theme.palette.background.hover
    },
    padding: theme.spacing(0, 2)
  }),
  listItemIcon: {
    width: 40,
    float: "left",
    display: "inline-block",
    position: "relative",
    paddingLeft: theme.spacing(1)
  },
  listItemText: {
    width: "calc(100% - 56px)",
    minWidth: 160,
    display: "inline-block"
  },
  list: {
    padding: theme.spacing(0),
    margin: 0,
    maxHeight: 490,
    overflowX: "hidden"
  },
  message: {
    display: "flex",
    justifyContent: "center"
  }
});
const useStyles = makeOverrideableStyles("EventCardContent", styles);
const EventCardContent = (props) => {
  const {
    getFrom: getFrom2,
    filter,
    noDataMessage,
    visible,
    itemClassName,
    titleKey,
    textKey,
    serialKey,
    dateKey,
    statusKey,
    onClick,
    itemIcon,
    itemIconMap
  } = props;
  const classes = useStyles(props);
  const [getFromData, getFromStatus, getFromError] = useCardGetFrom(getFrom2, props);
  const content = Array.isArray(getFromData) ? getFromData : [];
  const filteredContent = content.filter(filter ? filter : () => true);
  const isLoading = !!getFromStatus;
  const hasData = filteredContent.length > 0;
  if (!visible) {
    return null;
  }
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    !getFromError && hasData && /* @__PURE__ */ jsx(List, { className: classes.list, children: filteredContent.map((item, index) => {
      const serialText = get(item, serialKey);
      const secondaryText = get(item, textKey);
      const primaryText = serialText && !secondaryText.includes(serialText) ? `${get(item, titleKey)} (${serialText})` : get(item, titleKey);
      const dateTime = dateKey && formatDateTime(get(item, dateKey));
      const LeftIcon = itemIcon || StatusIcon;
      return /* @__PURE__ */ jsxs(
        ListItem,
        {
          className: classNames(classes.listItem, itemClassName),
          onClick: onClick ? () => onClick(item) : void 0,
          children: [
            statusKey && /* @__PURE__ */ jsx(ListItemIcon, { className: classes.listItemIcon, children: /* @__PURE__ */ jsx(
              LeftIcon,
              {
                status: get(item, statusKey),
                iconMap: itemIconMap
              }
            ) }),
            /* @__PURE__ */ jsx(
              ListItemText,
              {
                className: classes.listItemText,
                primary: primaryText,
                secondary: /* @__PURE__ */ jsxs(React.Fragment, { children: [
                  secondaryText,
                  dateKey ? /* @__PURE__ */ jsx("span", { className: classes.secondaryTextDate, children: dateTime }) : null
                ] })
              }
            )
          ]
        },
        "copy-" + index
      );
    }) }),
    (getFromError || !hasData) && [
      isLoading && !getFromError && /* @__PURE__ */ jsx(LoadingMessage, {}, "loading"),
      getFromError && /* @__PURE__ */ jsx(NoDataMessage, { message: getFromError }, "error"),
      !isLoading && !getFromError && /* @__PURE__ */ jsx(NoDataMessage, { message: noDataMessage }, "noData")
    ]
  ] });
};
EventCardContent.defaultProps = {
  textKey: "text",
  titleKey: "title",
  serialKey: "serial",
  noDataMessage: "cuda.cards.noEventData",
  visible: true
};
var EventCardContent_default = EventCardContent;
export {
  EventCardContent,
  EventCardContent_default as default,
  styles
};
