var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { useMixpanel } from "@cuda-react/core";
import { CardSizes, DashboardPage, UserDashboardDataCards } from "@stratos/reporting";
import { useTranslation } from "react-i18next";
import useCustomDashboard from "../../../../hooks/useCustomDashboard";
const UserDashboard = ({ details }) => {
  useMixpanel("User Dashboard");
  const [translate] = useTranslation();
  const templateDashboards = [{
    name: translate("tesseract.dashboard.ztnaUserDashboard"),
    cards: [
      { componentId: "ZTNADeviceLocationCard", size: CardSizes.large },
      { componentId: "UserSummaryCard", size: CardSizes.small },
      { componentId: "UserDevicesCard", size: CardSizes.small }
    ],
    template: translate("tesseract.dashboard.ztnaUserDashboard")
  }];
  const {
    remoteStoredCustomDashboards,
    handleDashboardFetch,
    loadingDashboards
  } = useCustomDashboard("userDashboard");
  return /* @__PURE__ */ jsx(
    DashboardPage,
    {
      id: "userDashboard",
      availableCards: __spreadValues({}, UserDashboardDataCards),
      defaultDashboards: templateDashboards,
      templates: templateDashboards,
      additionalProps: details,
      oneTabDashboard: true,
      remoteStoredDashboards: remoteStoredCustomDashboards,
      onDashboardsChange: handleDashboardFetch,
      loadingRemoteStoredDashboards: loadingDashboards
    }
  );
};
var UserDashboard_default = UserDashboard;
export {
  UserDashboard,
  UserDashboard_default as default
};
