var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Switch, useHistory, useLocation } from "react-router";
import NotificationDialog from "../../dialog/NotificationDialog/NotificationDialog";
import Footer from "../Footer/Footer";
import { NavigationBar } from "@barracuda-internal/bds-core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import { createStyles } from "@mui/styles";
import { StratosBarracudaIcon } from "@cuda-react/icons";
import { useTranslation } from "react-i18next";
import UserAvatar from "../../authentication/UserAvatar/UserAvatar";
const styles = (theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  main: {
    display: "flex",
    flexDirection: "row",
    height: "100vh",
    minWidth: "600px"
  },
  body: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flex: "1 1 auto",
    scroll: "auto",
    height: "100vh"
  },
  content: {
    flex: "1 1 auto",
    overflowX: "auto"
  },
  barracudaIcon: {
    width: 24,
    marginRight: 32
  },
  navSubMenu: {
    "& > :nth-child(2)": {
      height: "100%"
    },
    "& > :nth-child(2) > div > :nth-child(2)": {
      height: "calc(100% - 62px)"
    }
  },
  topBar: {
    zIndex: 5
  }
});
const useStyles = makeOverrideableStyles("AppLayout", styles);
const translateRoutes = (routes, translate) => routes.map((route) => __spreadProps(__spreadValues({}, route), {
  name: translate(route.name),
  children: route.children ? translateRoutes(route.children, translate) : route.children
}));
const translateOptions = (options, translate) => options.map((option) => __spreadProps(__spreadValues({}, option), {
  name: translate(option.name)
}));
const AppLayout = (props) => {
  const { children, additionalOptions = [], footerItems, logout, navigation, title } = props;
  const classes = useStyles(props);
  const location = useLocation();
  const [translate] = useTranslation();
  const { push } = useHistory();
  return /* @__PURE__ */ jsx("div", { className: classes.root, children: /* @__PURE__ */ jsxs("div", { className: classes.main, children: [
    /* @__PURE__ */ jsx(ErrorBoundary, { children: /* @__PURE__ */ jsx(
      NavigationBar,
      {
        classes: { topBar: classes.topBar, navigationSubMenu: classes.navSubMenu },
        currentPath: location.pathname,
        title: translate(title),
        logoOnClick: () => push("/"),
        logo: /* @__PURE__ */ jsx(StratosBarracudaIcon, { className: classes.barracudaIcon }),
        routes: translateRoutes(navigation, translate),
        options: translateOptions([...additionalOptions, {
          name: "cuda.auth.profile",
          icon: /* @__PURE__ */ jsx(UserAvatar, { size: 28 }),
          menu: logout
        }], translate),
        onNavigate: (path) => push(path)
      }
    ) }),
    /* @__PURE__ */ jsxs("div", { className: classes.body, children: [
      /* @__PURE__ */ jsxs("div", { className: classes.content, children: [
        /* @__PURE__ */ jsx(ErrorBoundary, { children: /* @__PURE__ */ jsx(NotificationDialog, {}) }),
        /* @__PURE__ */ jsx(ErrorBoundary, { children: /* @__PURE__ */ jsx(Switch, { children }) })
      ] }),
      /* @__PURE__ */ jsx(ErrorBoundary, { children: /* @__PURE__ */ jsx(Footer, { footerItems }) })
    ] })
  ] }) });
};
var AppLayout_default = AppLayout;
export {
  AppLayout,
  AppLayout_default as default
};
