import { jsx, jsxs } from "react/jsx-runtime";
import { Grid } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { BaseCard, CardFooter } from "../../baseCards";
import { CardSizeOptions, CardSizes } from "../../../typesAndConstants";
import { RecentEvent } from "./RecentEvent";
const styles = (theme) => createStyles({
  container: {
    color: theme.palette.reporting.fontColor,
    maxHeight: ({ size }) => {
      var _a;
      return `calc(${(_a = CardSizeOptions[size]) == null ? void 0 : _a.height}px - ${size === CardSizes.large ? 82 : 58}px)`;
    },
    minHeight: ({ size }) => {
      var _a;
      return `calc(${(_a = CardSizeOptions[size]) == null ? void 0 : _a.height}px - ${size === CardSizes.large ? 82 : 58}px)`;
    },
    margin: theme.spacing(0, 2),
    width: `calc(100% - ${theme.spacing(4)})`,
    overflowY: "auto"
  }
});
const useStyles = makeStyles(styles);
const RecentEventsCard = (props) => {
  const { title, preview, entries, total, size, footerKey, loading, onEventClick } = props;
  const length = entries.length;
  const classes = useStyles(props);
  return /* @__PURE__ */ jsxs(BaseCard, { title, size, preview, children: [
    /* @__PURE__ */ jsxs(Grid, { container: true, className: classes.container, direction: "column", wrap: "nowrap", children: [
      loading && new Array(5).fill(null).map((entry, index) => /* @__PURE__ */ jsx(
        RecentEvent,
        {
          size,
          loading: true
        },
        index
      )),
      !loading && entries.map((entry) => /* @__PURE__ */ jsx(
        RecentEvent,
        {
          size,
          eventName: entry.eventName,
          description: entry.description,
          status: entry.status,
          created: entry.created,
          onClick: () => onEventClick == null ? void 0 : onEventClick(entry)
        },
        entry.id
      ))
    ] }),
    size === CardSizes.large && /* @__PURE__ */ jsx(
      CardFooter,
      {
        footerKey,
        length,
        total
      }
    )
  ] });
};
export {
  RecentEventsCard
};
