var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from "react";
import ChipArrayField from "../../fields/ChipArrayField/ChipArrayField";
import ActionButtonsField from "../../fields/ActionButtonsField/ActionButtonsField";
import { Add, Delete, Edit } from "@barracuda-internal/bds-core/dist/Icons/Core";
import SelectArray from "../SelectArrayInput/SelectArray";
import { get, isEqual, merge, set, uniq } from "lodash";
import ConnectedAutocompleteSearch from "../../search/ConnectedAutocompleteSearch/ConnectedAutocompleteSearch";
import AutocompleteSearch from "../../search/AutocompleteSearch/AutocompleteSearch";
import { useChoices } from "../../../hooks";
import { useTranslation } from "react-i18next";
import Toolbar from "../../layout/Toolbar/Toolbar";
import { Button, FormHelperText, IconButton, Popover } from "@barracuda-internal/bds-core";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { DialogBody } from "../../dialog";
import { formatErrorMessage } from "../../../utils";
import classNames from "classnames";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    textAlign: "left",
    display: "inline-block",
    maxWidth: "100%",
    padding: theme.spacing(1, 2),
    width: "calc(100% - " + theme.spacing(4) + ")"
  },
  dataTable: {
    "& .k-grid-content": {
      overflowY: "auto"
    },
    "& th": {
      textTransform: "uppercase"
    }
  },
  tableContainerError: {
    border: "solid 1px " + theme.palette.error.main
  },
  primaryColumn: {
    minWidth: "40%"
  },
  popover: {
    border: theme.palette.primary.main + " solid 1px",
    width: 512
  },
  popoverTitle: {
    paddingBottom: 0
  },
  popoverContent: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "flex-end",
    "& > *": {
      width: "50%",
      margin: theme.spacing(0, 1, 1)
    }
  },
  secondarySelectChips: {
    marginLeft: "calc(-100% - 8px)",
    maxWidth: "calc(200% + 8px)",
    width: "calc(200% + 8px)"
  },
  error: {
    marginLeft: theme.spacing(1)
  },
  rowError: {
    borderTop: "solid 1px " + theme.palette.error.main + "!important",
    borderBottom: "solid 1px " + theme.palette.error.main + "!important",
    "&:first-child": {
      borderLeft: "solid 1px " + theme.palette.error.main + "!important"
    },
    "&:last-child": {
      borderRight: "solid 1px " + theme.palette.error.main + "!important"
    }
  },
  cellError: {
    border: "solid 1px " + theme.palette.error.main + "!important"
  },
  actionButtons: {
    padding: 2
  }
});
const useStyles = makeOverrideableStyles("SelectPairArray", styles);
const SelectPairArray = (props) => {
  const {
    addTitle = "cuda.buttons.add",
    disabled,
    editTitle = "cuda.buttons.edit",
    error,
    filterKey,
    choices,
    onBlur,
    onChange,
    onFocus,
    primaryLabel,
    primaryOptionValue = "key",
    primaryOptions,
    id,
    primarySource,
    noDataMessage = "cuda.inputs.table.noDataMessage",
    secondaryChoices,
    secondaryLabel,
    secondaryOptions,
    secondaryOptionText = "name",
    secondaryOptionValue = "key",
    secondaryResource,
    secondaryResourceParams,
    secondarySource,
    secondarySelectAll,
    secondarySelectAllText = "cuda.inputs.selectArray.all",
    secondarySelectAllValue = "*",
    resource,
    value
  } = props;
  const tableRef = useRef(null);
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const [popover, setPopover] = useState(null);
  const [pageState, setPageState] = useState({ skip: 0, take: 5 });
  const selectAllChoice = secondarySelectAll && set(set({}, secondaryOptionValue, secondarySelectAllValue), secondaryOptionText, secondarySelectAllText);
  const tableData = Array.isArray(value) ? value : value ? [value] : [];
  const onSelectChange = (newValue) => {
    setPopover(__spreadProps(__spreadValues({}, popover), {
      primaryValue: get(newValue, primaryOptionValue),
      secondaryValues: []
    }));
  };
  const selectedValues = [
    ...tableData.map((item) => get(item, primarySource)),
    ...popover && popover.primaryValue && [popover.primaryValue] || []
  ];
  const [primarySelectedItems, addSelection] = useChoices(
    selectedValues,
    {
      choices,
      onAdd: onSelectChange,
      filterKey: filterKey || primaryOptionValue,
      optionValue: primaryOptionValue,
      resource,
      params: {
        pagination: {
          page: 1,
          perPage: tableData.length
        }
      }
    }
  );
  let allSecondaryChoices = Array.isArray(secondaryChoices) ? secondaryChoices : [];
  let activeSecondaryChoices = Array.isArray(secondaryChoices) ? secondaryChoices : [];
  let activePrimaryChoice = popover && primarySelectedItems.find((item) => isEqual(get(item, primaryOptionValue), popover.primaryValue));
  if (typeof secondaryChoices === "string") {
    allSecondaryChoices = uniq(primarySelectedItems.flatMap((item) => get(item, secondaryChoices)));
    activeSecondaryChoices = activePrimaryChoice && get(activePrimaryChoice, secondaryChoices) || [];
  }
  if (typeof secondaryChoices === "function") {
    allSecondaryChoices = uniq(primarySelectedItems.flatMap(secondaryChoices));
    activeSecondaryChoices = activePrimaryChoice && secondaryChoices(activePrimaryChoice) || [];
  }
  const fields = [
    /* @__PURE__ */ jsx(
      ChipArrayField,
      __spreadValues({
        source: primarySource,
        label: primaryLabel,
        choices: primarySelectedItems,
        optionValue: primaryOptionValue,
        resource,
        filterKey,
        cellClassName: classes.primaryColumn
      }, primaryOptions),
      primarySource
    ),
    /* @__PURE__ */ jsx(
      ChipArrayField,
      __spreadValues({
        source: secondarySource,
        label: secondaryLabel,
        choices: [...secondarySelectAll ? [selectAllChoice] : [], ...allSecondaryChoices],
        resource: secondaryResource,
        optionValue: secondaryOptionValue,
        optionText: secondaryOptionText
      }, secondaryOptions),
      secondarySource
    ),
    /* @__PURE__ */ jsxs(
      ActionButtonsField,
      {
        source: "actions",
        width: 96,
        children: [
          /* @__PURE__ */ jsx(
            IconButton,
            {
              size: "small",
              onClick: (event, data) => {
                setPopover({
                  anchorEl: tableRef.current,
                  primaryValue: get(data, primarySource),
                  secondaryValues: get(data, secondarySource),
                  title: editTitle,
                  editIndex: data.index
                });
                onFocus == null ? void 0 : onFocus();
              },
              disabled,
              className: classes.actionButtons,
              children: /* @__PURE__ */ jsx(Edit, {})
            }
          ),
          /* @__PURE__ */ jsx(
            IconButton,
            {
              size: "small",
              onClick: (event, data) => {
                onChange == null ? void 0 : onChange(tableData.filter((item, index) => index !== data.index));
                onBlur == null ? void 0 : onBlur();
              },
              disabled,
              className: classes.actionButtons,
              children: /* @__PURE__ */ jsx(Delete, {})
            }
          )
        ]
      },
      "actions"
    )
  ];
  return /* @__PURE__ */ jsx("div", { className: classes.root, id: "select-pair-array-input-" + id, children: /* @__PURE__ */ jsxs("div", { ref: tableRef, children: [
    /* @__PURE__ */ jsxs(
      Grid,
      __spreadProps(__spreadValues({
        data: tableData.slice(pageState.skip || 0, (pageState.skip || 0) + 5),
        className: classNames(classes.dataTable, error && classes.tableContainerError),
        pageable: tableData.length ? {
          buttonCount: 5,
          info: true,
          previousNext: true,
          pageSizes: [5]
        } : void 0,
        onPageChange: (event) => setPageState(event.page),
        total: tableData.length
      }, pageState), {
        headerCellRender: (header, { field }) => {
          if (field === "actions" && header) {
            return React.cloneElement(header, {}, /* @__PURE__ */ jsx(
              IconButton,
              {
                size: "small",
                onClick: () => {
                  setPopover({
                    anchorEl: tableRef.current,
                    primaryValue: "",
                    secondaryValues: [],
                    title: addTitle
                  });
                  onFocus == null ? void 0 : onFocus();
                },
                disabled,
                children: /* @__PURE__ */ jsx(Add, { id: "cuda-icon-add" })
              }
            ));
          }
          return header;
        },
        cellRender: (cell, { className, columnIndex, dataIndex, dataItem }) => {
          const rowError = error && typeof error[dataIndex] === "string";
          const field = get(fields, `[${columnIndex}]`);
          const cellError = get(error, `[${dataIndex}].${get(field, `props.source`)}`);
          return /* @__PURE__ */ jsx("td", { className: classNames(className, cellError && classes.cellError, rowError && classes.rowError), children: field && React.cloneElement(field, {
            data: __spreadProps(__spreadValues({}, dataItem || {}), {
              index: dataIndex
            }),
            total: tableData.length,
            disabled
          }) || null });
        },
        children: [
          /* @__PURE__ */ jsx(GridNoRecords, { children: translate(noDataMessage) }),
          fields.map((field) => /* @__PURE__ */ jsx(
            GridColumn,
            __spreadValues({
              field: field.props.source,
              title: field.props.label && translate(field.props.label) || " ",
              className: field.props.cellClassName,
              headerClassName: field.props.cellClassName,
              width: field.props.width
            }, field.props.columnProps),
            field.props.source
          ))
        ]
      })
    ),
    /* @__PURE__ */ jsx(
      Popover,
      {
        onClose: () => {
          setPopover(null);
          onBlur == null ? void 0 : onBlur();
        },
        transformOrigin: { horizontal: "center", vertical: "center" },
        anchorOrigin: { horizontal: "center", vertical: "center" },
        open: !!(popover && popover.anchorEl),
        anchorEl: popover && popover.anchorEl || null,
        classes: { paper: classes.popover },
        children: /* @__PURE__ */ jsxs(
          DialogBody,
          {
            title: popover ? popover.title : void 0,
            onClose: () => {
              setPopover(null);
              onBlur == null ? void 0 : onBlur();
            },
            children: [
              /* @__PURE__ */ jsxs("div", { className: classes.popoverContent, children: [
                resource ? /* @__PURE__ */ jsx(
                  ConnectedAutocompleteSearch,
                  __spreadValues({
                    value: activePrimaryChoice || void 0,
                    onChange: addSelection,
                    label: primaryLabel,
                    resource,
                    disabled: !!popover && popover.editIndex !== void 0,
                    id: "select-input-" + primarySource.replace(/[.]/g, "-")
                  }, primaryOptions)
                ) : /* @__PURE__ */ jsx(
                  AutocompleteSearch,
                  __spreadValues({
                    value: activePrimaryChoice || void 0,
                    onChange: addSelection,
                    label: primaryLabel,
                    disabled: !!popover && popover.editIndex !== void 0,
                    choices,
                    id: "select-input-" + primarySource.replace(/[.]/g, "-")
                  }, primaryOptions)
                ),
                /* @__PURE__ */ jsx(
                  SelectArray,
                  __spreadValues({
                    value: popover && popover.secondaryValues || [],
                    onChange: (newValue) => setPopover(__spreadProps(__spreadValues({}, popover), { secondaryValues: newValue })),
                    choices: activeSecondaryChoices,
                    resource: secondaryResource,
                    options: {
                      label: secondaryLabel,
                      params: secondaryResourceParams ? secondaryResourceParams(activePrimaryChoice) : void 0
                    },
                    classes: { chipContainer: classes.secondarySelectChips },
                    disabled: !activeSecondaryChoices || activeSecondaryChoices.length < 1,
                    id: secondarySource.replace(/[.]/g, "-"),
                    optionValue: secondaryOptionValue,
                    optionText: secondaryOptionText,
                    selectAll: secondarySelectAll,
                    selectAllText: secondarySelectAllText,
                    selectAllValue: secondarySelectAllValue
                  }, secondaryOptions)
                )
              ] }),
              /* @__PURE__ */ jsxs(Toolbar, { children: [
                /* @__PURE__ */ jsx(
                  Button,
                  {
                    variant: "contained",
                    color: "primary",
                    onClick: () => {
                      const newArrayValue = [...tableData];
                      const existingIndex = (popover == null ? void 0 : popover.editIndex) !== void 0 ? popover == null ? void 0 : popover.editIndex : newArrayValue.findIndex((value2) => get(value2, primarySource) === (popover == null ? void 0 : popover.primaryValue));
                      const secondaryValues = [...(popover == null ? void 0 : popover.editIndex) === void 0 && get(tableData[existingIndex], secondarySource) || [], ...(popover == null ? void 0 : popover.secondaryValues) || []];
                      newArrayValue.splice(
                        existingIndex >= 0 ? existingIndex : newArrayValue.length,
                        1,
                        set(
                          set(merge({}, tableData[existingIndex]), primarySource, popover == null ? void 0 : popover.primaryValue),
                          secondarySource,
                          secondaryValues.includes(secondarySelectAllValue) ? [secondarySelectAllValue] : uniq(secondaryValues)
                        )
                      );
                      onChange == null ? void 0 : onChange(newArrayValue);
                      setPopover(null);
                      onBlur == null ? void 0 : onBlur();
                    },
                    disabled: !popover || !popover.primaryValue || !popover.secondaryValues || popover.secondaryValues.length < 1,
                    children: translate("cuda.state.ok")
                  }
                ),
                /* @__PURE__ */ jsx(
                  Button,
                  {
                    variant: "contained",
                    color: "secondary",
                    size: "small",
                    onClick: () => {
                      setPopover(null);
                      onBlur == null ? void 0 : onBlur();
                    },
                    children: translate("cuda.buttons.cancel")
                  }
                )
              ] })
            ]
          }
        )
      }
    ),
    error && /* @__PURE__ */ jsx(FormHelperText, { error: true, className: classes.error, children: formatErrorMessage(error) })
  ] }) });
};
var SelectPairArray_default = SelectPairArray;
export {
  SelectPairArray,
  SelectPairArray_default as default
};
