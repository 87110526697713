var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import {
  BooleanInput,
  ConnectedWizard,
  FileInput,
  InputStep,
  SelectInput,
  SubmitStep,
  TextInput,
  usePreview
} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";
import { get, unset } from "lodash";
import { ConnectorIPsInfo } from "./components/ConnectorIPsInfo";
const i18nScope = "tesseract.identity.userDirectories.form";
const isNotLDAPSimpleAuth = (value, { options: { ldapAuthMethod } }) => ldapAuthMethod !== "simple";
const isNotSasl = (value, { options: { ldapAuthMethod } }) => !["saslExternal", "saslKerberos"].includes(ldapAuthMethod);
const isNotTls = (value, { options: { encryptionMethod } }) => !["tls", "startTls"].includes(encryptionMethod);
const isNotTlsChoice = (value, { options: { encryptionMethod } }) => !["tls"].includes(encryptionMethod);
const formatRequestData = (data) => {
  const encryptionMethod = data.options.encryptionMethod;
  const ldapUseTls = encryptionMethod === "tls";
  const ldapUseStarttls = encryptionMethod === "startTls";
  const dataToSend = __spreadValues({}, data);
  unset(dataToSend, "options.encryptionMethod");
  return __spreadProps(__spreadValues({}, dataToSend), {
    options: __spreadProps(__spreadValues({}, dataToSend.options), {
      ldapUseTls,
      ldapUseStarttls,
      ldapPrivkey: get(dataToSend.options, "ldapPrivkey.data", void 0),
      ldapPubkey: get(dataToSend.options, "ldapPubkey.data", void 0),
      ldapCacerts: get(dataToSend.options, "ldapCacerts.data", void 0)
    })
  });
};
const UserDirectoryLdapForm = ({
  onClose,
  create,
  directory,
  id
}) => {
  const initialValue = useMemo(() => __spreadProps(__spreadValues({}, directory), {
    options: __spreadProps(__spreadValues({}, directory.options), {
      encryptionMethod: directory.options.ldapUseTls ? "tls" : directory.options.ldapUseStarttls ? "startTls" : "none"
    })
  }), [directory]);
  const ldapAuthExtended = usePreview("ldapextended");
  return /* @__PURE__ */ jsxs(
    ConnectedWizard,
    {
      resource: apiResources.directories,
      onSubmitSuccess: () => onClose(true),
      onCancel: () => onClose(false),
      initialValues: initialValue,
      formatRequestData,
      update: !create,
      submitParams: create ? void 0 : { id },
      children: [
        /* @__PURE__ */ jsxs(InputStep, { label: `${i18nScope}.ldap.steps.userDirectory`, children: [
          /* @__PURE__ */ jsx(ConnectorIPsInfo, {}),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "displayName",
              label: `${i18nScope}.displayName`,
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "options.ldapHost",
              label: `${i18nScope}.ldap.host.label`,
              placeholder: `${i18nScope}.ldap.host.placeholder`,
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "options.ldapPort",
              label: `${i18nScope}.ldap.port.label`,
              placeholder: `${i18nScope}.ldap.port.placeholder`,
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "options.ldapUserSearchBase",
              label: `${i18nScope}.ldap.userSearchBase.label`,
              placeholder: `${i18nScope}.ldap.userSearchBase.placeholder`,
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "options.ldapGroupSearchBase",
              label: `${i18nScope}.ldap.groupSearchBase.label`,
              placeholder: `${i18nScope}.ldap.groupSearchBase.placeholder`,
              isRequired: true
            }
          )
        ] }),
        /* @__PURE__ */ jsxs(InputStep, { label: `${i18nScope}.ldap.steps.auth`, children: [
          /* @__PURE__ */ jsx(
            SelectInput,
            {
              source: "options.ldapAuthMethod",
              label: `${i18nScope}.ldap.authenticationMethod.label`,
              choices: ldapAuthExtended ? [
                {
                  name: `${i18nScope}.ldap.authenticationMethod.simple`,
                  key: "simple"
                },
                {
                  name: `${i18nScope}.ldap.authenticationMethod.anon`,
                  key: "anon"
                },
                {
                  name: `${i18nScope}.ldap.authenticationMethod.ntlm`,
                  key: "ntlm"
                },
                {
                  name: `${i18nScope}.ldap.authenticationMethod.saslExternal`,
                  key: "saslExternal"
                },
                {
                  name: `${i18nScope}.ldap.authenticationMethod.saslKerberos`,
                  key: "saslKerberos"
                }
              ] : [
                {
                  name: `${i18nScope}.ldap.authenticationMethod.simple`,
                  key: "simple"
                }
              ]
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "options.ldapAuthUsername",
              label: `${i18nScope}.ldap.username`,
              isRequired: true,
              hide: isNotLDAPSimpleAuth
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "options.ldapAuthPassword",
              type: "password",
              isRequired: true,
              label: `${i18nScope}.ldap.password`,
              hide: isNotLDAPSimpleAuth
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "options.ldapAuthSaslCredentials",
              label: `${i18nScope}.ldap.saslCredentials`,
              hide: isNotSasl,
              isRequired: true
            }
          )
        ] }),
        /* @__PURE__ */ jsxs(SubmitStep, { label: `${i18nScope}.ldap.steps.tls`, children: [
          /* @__PURE__ */ jsx(
            SelectInput,
            {
              source: "options.encryptionMethod",
              label: `${i18nScope}.ldap.useTls.encryptionMethod`,
              choices: [
                {
                  name: `${i18nScope}.ldap.useTls.none`,
                  key: "none"
                },
                {
                  name: `${i18nScope}.ldap.useTls.startTls`,
                  key: "startTls"
                },
                {
                  name: `${i18nScope}.ldap.useTls.tls`,
                  key: "tls"
                }
              ]
            }
          ),
          ldapAuthExtended ? /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "options.ldapSni",
              label: `${i18nScope}.ldap.useTls.ldapSni.label`,
              placeholder: `${i18nScope}.ldap.useTls.ldapSni.placeholder`,
              hide: isNotTls
            }
          ) : null,
          ldapAuthExtended ? /* @__PURE__ */ jsx(
            FileInput,
            {
              source: "options.ldapPrivkey",
              label: `${i18nScope}.ldap.useTls.ldapPrivkey.label`,
              hide: isNotTls,
              readType: "string"
            }
          ) : null,
          ldapAuthExtended ? /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "options.ldapPrivkeyPassword",
              label: `${i18nScope}.ldap.useTls.ldapPrivkeyPassword`,
              hide: isNotTls
            }
          ) : null,
          ldapAuthExtended ? /* @__PURE__ */ jsx(
            FileInput,
            {
              source: "options.ldapPubkey",
              label: `${i18nScope}.ldap.useTls.ldapPubkey.label`,
              hide: isNotTls,
              readType: "string"
            }
          ) : null,
          ldapAuthExtended ? /* @__PURE__ */ jsx(
            FileInput,
            {
              source: "options.ldapCacerts",
              label: `${i18nScope}.ldap.useTls.ldapCacerts.label`,
              hide: isNotTls,
              readType: "string"
            }
          ) : /* @__PURE__ */ jsx(
            FileInput,
            {
              source: "options.ldapCacerts",
              label: `${i18nScope}.ldap.useTls.ldapCacerts.label`,
              hide: isNotTlsChoice,
              readType: "string"
            }
          ),
          ldapAuthExtended ? /* @__PURE__ */ jsx(
            BooleanInput,
            {
              source: "options.ldapCheckCerts",
              label: `${i18nScope}.ldap.useTls.ldapCheckCerts.label`,
              hide: isNotTls
            }
          ) : null,
          ldapAuthExtended ? /* @__PURE__ */ jsx(
            BooleanInput,
            {
              source: "options.ldapCheckHostname",
              label: `${i18nScope}.ldap.useTls.ldapCheckHostname.label`,
              hide: isNotTls
            }
          ) : null,
          ldapAuthExtended ? /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "options.ldapCertAdditionalNames",
              label: `${i18nScope}.ldap.useTls.ldapCertAdditionalNames.label`,
              placeholder: `${i18nScope}.ldap.useTls.ldapCertAdditionalNames.placeholder`,
              hide: isNotTls
            }
          ) : null
        ] })
      ]
    }
  );
};
export {
  UserDirectoryLdapForm
};
