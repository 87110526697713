import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const ProcessorIcon = ({ className }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-processor",
      viewBox: "0 0 60 60",
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
            d: "M42.1,44.6h-24.3c-1.4,0-2.5-1.1-2.5-2.5v-24.3c0-1.4,1.1-2.5,2.5-2.5h24.3c1.4,0,2.5,1.1,2.5,2.5v24.3c0,1.4-1.1,2.5-2.5,2.5ZM20.3,39.7h19.4v-19.4h-19.4v19.4Z"
          }
        ),
        /* @__PURE__ */ jsxs("g", { children: [
          /* @__PURE__ */ jsxs("g", { children: [
            /* @__PURE__ */ jsx("rect", { style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 }, x: "19.9", y: "5.2", width: "3.5", height: "6.5" }),
            /* @__PURE__ */ jsx("rect", { style: { fill: (_c = theme.palette.icons) == null ? void 0 : _c.graphics, strokeWidth: 0 }, x: "28.2", y: "5.2", width: "3.5", height: "6.5" }),
            /* @__PURE__ */ jsx("rect", { style: { fill: (_d = theme.palette.icons) == null ? void 0 : _d.graphics, strokeWidth: 0 }, x: "36.6", y: "5.2", width: "3.5", height: "6.5" })
          ] }),
          /* @__PURE__ */ jsxs("g", { children: [
            /* @__PURE__ */ jsx("rect", { style: { fill: (_e = theme.palette.icons) == null ? void 0 : _e.graphics, strokeWidth: 0 }, x: "19.9", y: "48.4", width: "3.5", height: "6.5" }),
            /* @__PURE__ */ jsx("rect", { style: { fill: (_f = theme.palette.icons) == null ? void 0 : _f.graphics, strokeWidth: 0 }, x: "28.2", y: "48.4", width: "3.5", height: "6.5" }),
            /* @__PURE__ */ jsx("rect", { style: { fill: (_g = theme.palette.icons) == null ? void 0 : _g.graphics, strokeWidth: 0 }, x: "36.6", y: "48.4", width: "3.5", height: "6.5" })
          ] })
        ] }),
        /* @__PURE__ */ jsxs("g", { children: [
          /* @__PURE__ */ jsxs("g", { children: [
            /* @__PURE__ */ jsx("rect", { style: { fill: (_h = theme.palette.icons) == null ? void 0 : _h.graphics, strokeWidth: 0 }, x: "48.4", y: "19.9", width: "6.5", height: "3.5" }),
            /* @__PURE__ */ jsx("rect", { style: { fill: (_i = theme.palette.icons) == null ? void 0 : _i.graphics, strokeWidth: 0 }, x: "48.4", y: "28.2", width: "6.5", height: "3.5" }),
            /* @__PURE__ */ jsx("rect", { style: { fill: (_j = theme.palette.icons) == null ? void 0 : _j.graphics, strokeWidth: 0 }, x: "48.4", y: "36.6", width: "6.5", height: "3.5" })
          ] }),
          /* @__PURE__ */ jsxs("g", { children: [
            /* @__PURE__ */ jsx("rect", { style: { fill: (_k = theme.palette.icons) == null ? void 0 : _k.graphics, strokeWidth: 0 }, x: "5.2", y: "19.9", width: "6.5", height: "3.5" }),
            /* @__PURE__ */ jsx("rect", { style: { fill: (_l = theme.palette.icons) == null ? void 0 : _l.graphics, strokeWidth: 0 }, x: "5.2", y: "28.2", width: "6.5", height: "3.5" }),
            /* @__PURE__ */ jsx("rect", { style: { fill: (_m = theme.palette.icons) == null ? void 0 : _m.graphics, strokeWidth: 0 }, x: "5.2", y: "36.6", width: "6.5", height: "3.5" })
          ] })
        ] })
      ]
    }
  );
};
var ProcessorIcon_default = ProcessorIcon;
export {
  ProcessorIcon_default as default
};
