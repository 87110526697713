import { jsx } from "react/jsx-runtime";
import get from "lodash/get";
import { Checkmark, Close } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = createStyles({
  root: {
    height: "1rem",
    position: "relative",
    top: "calc(0.5rem - 12px)"
  }
});
const useStyles = makeOverrideableStyles("BooleanField", styles);
const BooleanField = (props) => {
  const { data = {}, source = "" } = props;
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx("div", { className: classes.root, children: get(data, source) ? /* @__PURE__ */ jsx(Checkmark, {}) : /* @__PURE__ */ jsx(Close, {}) });
};
var BooleanField_default = BooleanField;
export {
  BooleanField,
  BooleanField_default as default,
  styles
};
