import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import {
  ActionButtonsField,
  Boolean,
  ChipArrayField,
  CrudTypes,
  DeleteDialog,
  getArrayDataContent,
  getDataContent,
  InputLabel,
  PropGateway,
  Select,
  StatusIconField,
  TableAction,
  TablePage,
  TextField,
  useCrudFetch,
  useCrudProps,
  useCrudSubscription,
  useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { makeStyles } from "@mui/styles";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import CreateEditMalwareRule from "./CreateEditMalwareRule";
import { useSourceDestinationField } from "../../../../../hooks/sourceAndDestinationHooks";
import SourceFilter from "../../../../../components/sourceAndDestination/SourceFilter";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import {
  formatSourceDestinationFilters,
  formatUserGroupInTableData
} from "../../../../../components/sourceAndDestination/tableUtils";
import { scanIcons } from "../../../../../components/iconMapping";
import apiResources from "../../../../../apiResources";
const styles = (theme) => ({
  cardActions: {
    paddingRight: theme.spacing(2),
    width: "calc(100% - " + theme.spacing(2) + ")"
  },
  orderCell: {
    minWidth: 70
  },
  actionCell: {
    minWidth: 70,
    paddingLeft: 0
  },
  defaultActionContainer: {
    display: "inline-flex"
  },
  defaultAction: {
    display: "inline-flex",
    paddingTop: theme.spacing(1)
  }
});
const useStyles = makeStyles(styles);
const MalwareProtection = () => {
  var _a;
  const registerAction = useMixpanel("Malware");
  const tableRefreshRef = useRef();
  const actionChoices = getArrayDataContent((_a = useCrudProps(apiResources.malwareActions, {}, { cache: true })[0]) == null ? void 0 : _a.data);
  const [enabledData, enabledLoading, refreshEnabled] = useCrudSubscription(CrudTypes.GET, apiResources.malwareEnabled);
  const [, enabledUpdating, updateEnabled] = useCrudFetch(CrudTypes.UPDATE, apiResources.malwareEnabled);
  const [defaultActionData, defaultActionLoading, refreshDefaultAction] = useCrudSubscription(CrudTypes.GET, apiResources.malwareDefault);
  const [, defaultActionUpdating, updateDefaultAction] = useCrudFetch(CrudTypes.UPDATE, apiResources.malwareDefault);
  const defaultAction = getDataContent(defaultActionData);
  const defaultLoading = typeof defaultAction !== "string" || defaultActionLoading || defaultActionUpdating;
  const handleUpdateDefaultAction = (action) => {
    if (action && !defaultLoading && action !== defaultAction) {
      registerAction("Default Update", { action });
      return updateDefaultAction({ action }).then(refreshDefaultAction);
    }
  };
  const enabled = getDataContent(enabledData);
  const loadingEnabled = typeof enabled !== "boolean" || enabledLoading || enabledUpdating;
  const handleUpdateEnabled = (newValue) => {
    if (!loadingEnabled && newValue !== enabled) {
      registerAction("Default Update", { enabled: newValue });
      return updateEnabled({ enabled: newValue ? "enable" : "disable" }).then(refreshEnabled);
    }
  };
  const [, , callMalwareUpdate] = useCrudFetch(CrudTypes.CREATE, apiResources.malware, {});
  const handleOrderClick = (id, direction, data) => callMalwareUpdate({
    id,
    changeOrder: direction,
    data
  }).then(tableRefreshRef.current);
  const generateChipArrayProps = useSourceDestinationField();
  const classes = useStyles();
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      title: "tesseract.securityAndAccess.pageTitle",
      subtitle: ["tesseract.securityAndAccess.security", "tesseract.securityAndAccess.malware"],
      authenticated: true,
      resource: apiResources.malware,
      exportResource: { resource: apiResources.malwareExport, filename: "malware" },
      actions: [
        /* @__PURE__ */ jsx(TableAction, { left: true, children: /* @__PURE__ */ jsxs("div", { className: classes.defaultActionContainer, children: [
          /* @__PURE__ */ jsx(InputLabel, { label: "tesseract.security.protection.settings.inputs.enabled", minimised: true, children: /* @__PURE__ */ jsx(
            Boolean,
            {
              id: "enabled",
              onChange: handleUpdateEnabled,
              options: { disabled: !!loadingEnabled },
              value: typeof enabled !== "boolean" ? false : enabled
            }
          ) }),
          /* @__PURE__ */ jsx(
            InputLabel,
            {
              label: "tesseract.security.protection.settings.defaultAction",
              minimised: true,
              left: true,
              children: /* @__PURE__ */ jsx(
                Select,
                {
                  id: "defaultAction",
                  source: "defaultAction",
                  value: !defaultLoading ? defaultAction : "",
                  onChange: (eventOrValue) => eventOrValue.target ? handleUpdateDefaultAction(eventOrValue.target.value) : handleUpdateDefaultAction(eventOrValue),
                  onBlur: () => {
                  },
                  disabled: !!defaultLoading,
                  choices: actionChoices
                },
                "defaultAction"
              )
            }
          )
        ] }) }, "defaultAction"),
        /* @__PURE__ */ jsx(
          CreateEditButtonDialog,
          {
            create: true,
            component: CreateEditMalwareRule,
            onSuccess: tableRefreshRef.current
          },
          "create"
        )
      ],
      params: formatSourceDestinationFilters,
      classes: { cardActions: classes.cardActions },
      minCellWidth: 128,
      formatData: formatUserGroupInTableData,
      refreshRef: tableRefreshRef,
      flat: true,
      children: [
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            left: true,
            label: "tesseract.security.protection.settings.order",
            cellClassName: classes.orderCell,
            width: 96,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(
                TextField,
                {
                  source: "order"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowUpwardIcon,
                {
                  onClick: (event, data) => handleOrderClick(data.id, "increase", data),
                  disabled: (data) => data.index <= 0,
                  id: "cuda-icon-up"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowDownwardIcon,
                {
                  onClick: (event, data) => handleOrderClick(data.id, "decrease", data),
                  disabled: (data) => data.index >= data.total - 1,
                  id: "cuda-icon-down"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.security.protection.settings.name",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.security.protection.settings.description",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "action",
            text: (action) => (actionChoices.find((item) => item.key === action) || {}).name,
            label: "tesseract.security.protection.settings.action",
            iconMap: scanIcons,
            cellClassName: classes.actionCell,
            filter: "select",
            filterProps: {
              choices: actionChoices
            }
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "source",
            label: "tesseract.security.protection.settings.source",
            editProps: generateChipArrayProps("source"),
            filter: "custom",
            filterProps: {
              component: SourceFilter,
              filterSources: ["application", "network", "userOrGroup"]
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "source" })
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "destination",
            label: "tesseract.security.protection.settings.destination",
            editProps: generateChipArrayProps("destination"),
            filter: "custom",
            filterProps: {
              component: DestinationFilter,
              filterSources: ["application", "network", "domain", "site"],
              table: "MalwareProtection"
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "destination" })
          }
        ),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            source: "id",
            width: 96,
            children: [
              /* @__PURE__ */ jsx(CreateEditButtonDialog, { component: CreateEditMalwareRule, onSuccess: tableRefreshRef.current }),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource: apiResources.malware,
                  title: "tesseract.security.protection.settings.delete.title",
                  message: "tesseract.security.protection.settings.delete.body",
                  onSuccess: () => {
                    var _a2;
                    registerAction("Delete");
                    (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                  }
                }
              )
            ]
          }
        )
      ]
    }
  );
};
var MalwareProtection_default = MalwareProtection;
export {
  MalwareProtection,
  MalwareProtection_default as default,
  styles
};
