import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const SortIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-sort",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx("g", { children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
          d: "M25.1,44.8h9.9v-4.9h-9.9v4.9ZM7.8,15.2v4.9h44.4v-4.9H7.8ZM15.2,32.5h29.6v-4.9H15.2v4.9Z"
        }
      ) })
    }
  );
};
var SortIcon_default = SortIcon;
export {
  SortIcon,
  SortIcon_default as default
};
