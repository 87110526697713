import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import { useTheme } from "@mui/material";
const OffIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-off",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
          d: "M30,5.4c-13.6,0-24.6,11-24.6,24.6s11,24.6,24.6,24.6,24.6-11,24.6-24.6S43.6,5.4,30,5.4ZM32.5,42.3h-4.9v-24.6h4.9v24.6Z"
        }
      )
    }
  );
};
var OffIcon_default = OffIcon;
export {
  OffIcon_default as default
};
