var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { getDataContent } from "../../utils";
import { get } from "lodash";
const parseData = (series, data, translate, noRandom) => series.map((seriesEntry) => {
  const resourceData = getDataContent(seriesEntry.data || data);
  const dataAsArray = Array.isArray(resourceData) ? resourceData : [resourceData];
  const mergedData = seriesEntry.source ? dataAsArray.flatMap(
    (parent) => {
      const values = seriesEntry.source && get(parent, seriesEntry.source) || [];
      return (Array.isArray(values) ? values : [values]).map((item) => __spreadProps(__spreadValues({}, item), {
        linkedSeriesParent: seriesEntry.linkedSeries ? parent : void 0,
        series: seriesEntry
      }));
    }
  ) : dataAsArray.map((item) => __spreadProps(__spreadValues({}, item), {
    series: seriesEntry,
    linkedSeriesSources: seriesEntry.linkedSeries ? series.map((series2) => series2.source).filter((source) => source) : void 0
  }));
  const filteredLocations = mergedData.filter((location) => location && location.latitude && location.longitude).map((location) => ({
    lat: noRandom ? location.latitude : location.latitude + Math.random() / 1e3,
    lon: noRandom ? location.longitude : location.longitude + Math.random() / 1e3,
    pointData: location
  }));
  return {
    label: seriesEntry.label && translate(seriesEntry.label),
    color: seriesEntry.color,
    data: seriesEntry.filter ? filteredLocations.filter(seriesEntry.filter) : filteredLocations,
    zIndex: seriesEntry.zIndex
  };
});
export {
  parseData
};
