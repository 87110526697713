const numberFormatter = (number, units = ["", "K", "M", "B", "T"], showDecimal = false) => {
  let value = number || 0;
  let unitIndex = 0;
  while (value >= 1e3 && unitIndex < units.length) {
    value = value / 1e3;
    unitIndex++;
  }
  const formattedValue = (showDecimal || number >= 10 || number <= -10) && value < 100 && value > 0 ? Number(value.toPrecision(2)) : value.toFixed(0);
  return formattedValue + units[unitIndex];
};
const byteUnits = [" B", " KB", " MB", " GB", " TB", " PB"];
const bytesFormatter = (bytes) => numberFormatter(bytes, byteUnits);
export {
  bytesFormatter,
  numberFormatter
};
