import { RiskLevels } from "../typesAndConstants";
const getRiskLevel = (riskNumber) => {
  const level = typeof riskNumber === "string" ? parseInt(riskNumber) : riskNumber;
  if (Math.round(level) >= 4)
    return RiskLevels.high;
  if (Math.round(level) >= 3)
    return RiskLevels.mediumHigh;
  if (Math.round(level) >= 2)
    return RiskLevels.medium;
  if (Math.round(level) >= 1)
    return RiskLevels.lowMedium;
  return RiskLevels.low;
};
const getRiskColor = (riskNumber, theme) => theme.palette.risk[getRiskLevel(riskNumber)];
export {
  getRiskColor,
  getRiskLevel
};
