var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import {
  ConnectedForm,
  DialogBody,
  OK_NOTIFICATION,
  TextInput,
  TOAST_NOTIFICATION,
  useGlobalParam,
  useNotifications
} from "@cuda-react/core";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import { get } from "lodash";
import apiResources from "../../../../apiResources";
import classNames from "classnames";
import { getAzureResourceLinkFromResourceID } from "../../../../utils/azureUtils";
const useStyles = makeStyles((theme) => ({
  fullHeight: {
    "& > div": {
      height: "auto",
      minHeight: theme.spacing(20),
      alignItems: "flex-start"
    }
  },
  fullWidth: {
    "& > div": {
      width: 410
    }
  },
  narrowLabel: {
    width: 120
  },
  dialogContent: {
    width: 610
  },
  message: {
    padding: theme.spacing(2)
  }
}));
const EnableSupportContent = ({ applianceType, uuid, name, onClose, gatewayType, applicationId }) => {
  const [translate] = useTranslation();
  const classes = useStyles();
  const [show] = useNotifications(
    TOAST_NOTIFICATION,
    "tesseract.appliances.support.success",
    OK_NOTIFICATION,
    { translateParams: { name, context: applianceType } }
  );
  const isDemo = useGlobalParam("userData.mode")[0] === "demo";
  return /* @__PURE__ */ jsx(
    DialogBody,
    {
      title: "tesseract.appliances.support.dialogTitle",
      classes: { dialogContent: classes.dialogContent },
      onClose,
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          resource: apiResources.enableSupport,
          params: { uuid },
          onSubmitSuccess: () => {
            show();
            onClose == null ? void 0 : onClose();
          },
          onCancel: onClose,
          submitOnEnter: false,
          formatRequestData: (data) => __spreadProps(__spreadValues({}, data), {
            networks: get(data, "networks", "").split(",").map((network) => network.trim())
          }),
          create: true,
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsx(Typography, { className: classes.message, children: translate("tesseract.appliances.support.message", { context: applianceType }) }),
            gatewayType === "cloud" ? /* @__PURE__ */ jsx(Typography, { className: classes.message, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.appliances.support.cloudGatewayMessage", children: /* @__PURE__ */ jsx(
              "a",
              {
                href: getAzureResourceLinkFromResourceID(applicationId, isDemo),
                rel: "noreferrer noopener",
                target: "_blank"
              }
            ) }) }) : null,
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "networks",
                label: "tesseract.appliances.support.networks",
                description: translate("tesseract.appliances.support.description.networks", { context: applianceType }),
                inputClasses: {
                  textField: classes.fullWidth
                },
                inputLabelProps: {
                  classes: {
                    inputLabel: classes.narrowLabel
                  }
                },
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "publicKey",
                label: "tesseract.appliances.support.publicKey",
                description: translate("tesseract.appliances.support.description.publicKey", { context: applianceType }),
                inputClasses: {
                  textField: classNames(classes.fullWidth, classes.fullHeight)
                },
                inputLabelProps: {
                  classes: {
                    inputLabel: classes.narrowLabel
                  }
                },
                options: {
                  multiline: true
                }
              }
            )
          ]
        }
      )
    }
  );
};
var EnableSupportContent_default = EnableSupportContent;
export {
  EnableSupportContent,
  EnableSupportContent_default as default
};
