import { jsx } from "react/jsx-runtime";
import { ButtonDialog } from "@cuda-react/core";
import { Edit } from "@barracuda-internal/bds-core/dist/Icons/Core";
import ExplicitPolicyContent from "./ExplicitPolicyContent";
const EditExplicitPolicyDialog = ({ id, data, onSuccess }) => {
  const explicitPolicyId = data && typeof id === "function" ? id(data) : id;
  return /* @__PURE__ */ jsx(ButtonDialog, { buttonIcon: /* @__PURE__ */ jsx(Edit, { id: "edit-explicit-policy-icon" }), children: /* @__PURE__ */ jsx(
    ExplicitPolicyContent,
    {
      title: "tesseract.policies.dialogs.explicit.edit.dialogTitle",
      id: explicitPolicyId,
      onSuccess
    }
  ) });
};
var EditExplicitPolicyDialog_default = EditExplicitPolicyDialog;
export {
  EditExplicitPolicyDialog,
  EditExplicitPolicyDialog_default as default
};
