import { jsx, jsxs } from "react/jsx-runtime";
import { get } from "lodash";
import { Select, SelectArray } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Grid } from "@barracuda-internal/bds-core";
const PEER_TYPE_FILTER = "peer-type";
const useStyles = makeStyles((theme) => ({
  container: {
    width: 256
  },
  selectFilter: {
    marginTop: theme.spacing(1),
    width: "100%",
    maxWidth: 256
  },
  typeSelect: {
    width: "100%",
    maxWidth: 256
  }
}));
const peerTypeChoices = [
  { key: "gateway", name: "tesseract.integration.ipSecV2.table.filters.secureEdgePeer.edgeService" },
  { key: "site", name: "tesseract.integration.ipSecV2.table.filters.secureEdgePeer.site" }
];
const IpsecLocalPeerFilter = (props) => {
  const { onChange, ipsecSiteWans, ipsecGatewayWans, value } = props;
  const classes = useStyles();
  const [translate] = useTranslation();
  const update = (field) => (eventOrValue) => onChange({
    [PEER_TYPE_FILTER]: get(value, PEER_TYPE_FILTER, ""),
    [field]: (eventOrValue == null ? void 0 : eventOrValue.target) ? eventOrValue.target.value : eventOrValue
  });
  const filter = get(value, PEER_TYPE_FILTER) === "gateway" ? /* @__PURE__ */ jsx(
    SelectArray,
    {
      label: "tesseract.integration.ipSecV2.table.filters.secureEdgePeer.edgeService",
      id: "edgeService",
      optionValue: "uuid",
      choices: ipsecGatewayWans,
      value: get(value, "uuid"),
      onChange: update("uuid"),
      className: classes.selectFilter
    },
    "uuid"
  ) : get(value, PEER_TYPE_FILTER) === "site" ? /* @__PURE__ */ jsx(
    SelectArray,
    {
      id: "site",
      optionValue: "uuid",
      label: "tesseract.integration.ipSecV2.table.filters.secureEdgePeer.site",
      choices: ipsecSiteWans,
      value: get(value, "uuid"),
      onChange: update("uuid"),
      className: classes.selectFilter
    },
    "uuid"
  ) : null;
  return /* @__PURE__ */ jsxs(Grid, { container: true, direction: "column", className: classes.container, children: [
    /* @__PURE__ */ jsx(
      Select,
      {
        id: "cuda-filter-input-type",
        value: get(value, PEER_TYPE_FILTER, ""),
        onChange: (eventOrValue) => onChange({ [PEER_TYPE_FILTER]: eventOrValue.target ? eventOrValue.target.value : eventOrValue }),
        label: translate("tesseract.integration.ipSecV2.table.filters.secureEdgePeer.peerType"),
        choices: peerTypeChoices,
        classes: { select: classes.typeSelect, formControl: classes.typeSelect }
      }
    ),
    filter
  ] });
};
var IpsecLocalPeerFilter_default = IpsecLocalPeerFilter;
export {
  PEER_TYPE_FILTER,
  IpsecLocalPeerFilter_default as default
};
