var fr_default = {
  translation: {
    stratosReporting: {
      resourceStatisticsCard: {
        overview: "Total"
      },
      sdwanStatusCard: {
        title: "Statut SD-WAN",
        overviewTitle: "Total",
        healthy: "Sain",
        degraded: "D\xE9grad\xE9",
        down: "Arr\xEAt\xE9",
        tableTitle: "Top 10 appareils pour statut SD-WAN",
        name: "Nom",
        location: "Emplacement",
        type: "Type"
      },
      sdwanBandwidthCard: {
        tableTitle: "Top 5 bande passante SD-WAN",
        title: "Synth\xE8se de bande passante SD-WAN"
      },
      sdwanBandwidthChartCard: {
        title: "Bande passante SD-WAN",
        topTunnels: "Top {{length}} tunnels"
      },
      osStatusCard: {
        title: "Syst\xE8mes d'exploitation",
        others: "Autres",
        name: "Nom",
        location: "Emplacement",
        type: "Type",
        ip: "IP",
        linux: "Linux",
        mac: "Mac OSX",
        windows: "Windows",
        other: "Autres",
        android: "Android",
        ios: "iOS"
      },
      applianceMapCard: {
        title: "Plan d'appareil"
      },
      baseBarCard: {
        total: "sur {{total}}"
      },
      topApplicationCard: {
        title: "Top applications",
        topApplications: "Top {{length}} applications"
      },
      sdwanSummaryCard: {
        title: "Synth\xE8se SD-WAN",
        topAppliances: "Top {{length}} appareils",
        inbound: "entrant",
        outbound: "sortant"
      },
      topWebRequest: {
        title: "Requ\xEAtes Web",
        tableTitle: "Top Requ\xEAtes Web",
        total: "Total",
        allowed: "autoris\xE9",
        blocked: "bloqu\xE9",
        type: "Type",
        location: "Emplacement",
        name: "Nom"
      },
      storageCard: {
        title: "Aper\xE7u de stockage",
        use: "<0>{{spaceUsed}}GB</0> utilis\xE9s sur {{totalSpace}}GB",
        retention: "Fid\xE9lisation maxi. env. 3 jours",
        name: "Nom",
        lastChange: "Derni\xE8re modification",
        fileSize: "Taille de fichier",
        storageLow: "Espace de stockage bient\xF4t \xE9puis\xE9 !",
        upgradeStorageNow: "Mettez \xE0 niveau le stockage maintenant !",
        tableTitle: "Top appareils pour la consommation de stockage"
      },
      topBlockedUserCard: {
        title: "Top des utilisateurs bloqu\xE9s",
        topUsers: "Top {{length}} utilisateurs",
        total: "sur {{total}}",
        hits: "{{formattedCount}} R\xE9sultats",
        hits_one: "{{formattedCount}} R\xE9sultat",
        hits_other: "{{formattedCount}} R\xE9sultats"
      },
      sdwanHeatMap: {
        title: "Carte de densit\xE9 SD-WAN"
      },
      legendFooter: {
        online: "en ligne",
        offline: "hors ligne",
        degraded: "d\xE9grad\xE9"
      },
      applianceStatus: {
        title: "Statut d'appareil",
        topUsers: "Top {{length}} utilisateurs",
        total: "sur {{total}}"
      },
      previewDialog: {
        addWidgetButton: "Ajouter widget",
        title: "Ajouter widget",
        card: "Widget",
        size: "Taille",
        cancel: "Annuler",
        add: "Ajouter",
        small: "Petit",
        medium: "Moyen",
        large: "Grand",
        addWidgetCard: "Cliquez pour ajouter un widget !",
        ok: "OK",
        editTitle: "Modifier Widget"
      },
      reportTable: {
        title: "Tous les rapports",
        type: "Type",
        name: "Nom",
        period: "P\xE9riode de temps",
        devices: "Dispositifs",
        schedule: "Planification",
        owner: "Propri\xE9taire"
      },
      reportDialog: {
        create: {
          title: "Cr\xE9er un rapport",
          button: "Cr\xE9er"
        },
        edit: {
          title: "Modifier un rapport",
          button: "Modifier"
        },
        reportData: {
          label: "Donn\xE9es de rapport",
          application: {
            name: "Application"
          },
          protocol: {
            name: "Protocole"
          },
          applicationRule: {
            name: "R\xE8gle d'application"
          },
          contentType: {
            name: "Type de contenu"
          },
          outputInterface: {
            name: "Interface de sortie"
          },
          destination: {
            name: "Destination"
          }
        },
        datasource: {
          label: "Source de donn\xE9es",
          activity: {
            name: "Activit\xE9"
          },
          threat: {
            name: "Menace"
          },
          webFilter: {
            name: "Filtre Web"
          },
          sdwanTunnels: {
            name: "Tunnels SD-WAN"
          },
          sdwanTunnelsAppProtocol: {
            name: "Applications et protocoles de tunnels SD-WAN"
          }
        },
        output: {
          label: "Sortie",
          outputFormat: {
            label: "Format de sortie"
          },
          deliveryOptions: {
            label: "Options de livraison",
            email: "E-mail",
            fileDownload: "T\xE9l\xE9chargement de fichier"
          },
          recipients: {
            label: "Destinataires"
          },
          sizeLimit: {
            label: "Limite de taille"
          },
          frequency: {
            label: "Fr\xE9quence",
            once: "Une fois",
            weekly: "Hebdomadaire",
            monthly: "Mensuel"
          },
          status: {
            label: "Statut",
            enabled: "Activ\xE9",
            disabled: "D\xE9sactiv\xE9"
          }
        },
        advanced: {
          label: "Avanc\xE9",
          destination: {
            label: "Destination",
            domain: "Domaine",
            category: "Cat\xE9gorie"
          },
          ipAnonymize: {
            label: "Anonymiser IP"
          },
          serialNumberBucketing: {
            label: "Bucketing de num\xE9ro de s\xE9rie"
          },
          domains: {
            label: "Domaines"
          },
          exludeTimeframe: {
            label: "Exclure \xE9ch\xE9ancier"
          },
          action: {
            label: "Action"
          },
          chartType: {
            label: "Type de tableau",
            horizontalBar: "Barre horizontale",
            verticalBar: "Barre verticale",
            pieChart: "Graphique en fromage"
          },
          ddLimitLevels: {
            label: "Niveaux limites d'approfondissement"
          }
        },
        result: {
          label: "\xC9tape de r\xE9sultat"
        },
        success: "Correctement cr\xE9\xE9"
      },
      userEnrollment: {
        title: "Inscription d'utilisateur",
        enrolledUsers: "Utilisateurs",
        enrolledDevices: "Dispositifs",
        pendingEnrollment: "En cours",
        name: "Nom",
        location: "Emplacement",
        device: "Dispositif"
      },
      licensing: {
        title: "Licences",
        bandwidth: "Bande passante",
        bandwidthUsageMedium: "{{remain}} Gbit/s restants (sur {{total}} Gbit/s)",
        bandwidthUsageLarge: "{{used}} utilis\xE9s (sur {{total}} GB/s)",
        bandwidthExpire: "Expire dans {{daysLeft}} jours",
        users: "Utilisateurs",
        usersUsageMedium: "{{remain}} Utilisateurs restants (sur {{total}} utilisateurs)",
        usersUsageLarge: "{{used}} Utilisateurs utilis\xE9s (sur {{total}} utilisateurs)",
        usersExpire: "{{remain}} Utilisateurs restants",
        storage: "Stockage",
        storageUsageMedium: "{{remain}}GB restants (sur {{total}}GB)",
        storageUsageLarge: "{{used}} GB/sB utilis\xE9s (sur {{total}} GB/s)",
        storageRetention: "Fid\xE9lisation maxi. env. {{remain}} jours",
        storageExpire: "Expire dans {{daysLeft}} jours",
        appliances: "Appareils",
        appliancesUsageMedium: "{{remain}} jours restants (sur {{total}} jours)",
        appliancesUsageLarge: "{{used}} jours utilis\xE9s (sur {{total}} jours)",
        appliancesExpire: "Expire dans {{daysLeft}} jours",
        percentage: "{{percentage}}"
      },
      dashboardPage: {
        done: "Termin\xE9",
        cancel: "Annuler",
        editDashboard: "Modifier un tableau de bord",
        emptyMessage: "Ce tableau de bord ne contient pour le moment aucun widget.",
        addMessage: "Ajouter des widgets en cliquant sur le bouton Ajouter.",
        lastDay: "Derni\xE8res 24 heures",
        lastWeek: "Derni\xE8re semaine",
        lastMonth: "Dernier mois",
        configureDashboard: "Configurez ce tableau de bord !",
        addDashboard: "Cr\xE9er un tableau de bord",
        name: "Nom",
        template: "Mod\xE8le",
        reset: "R\xE9initialiser tableau de bord",
        rename: "Renommer un tableau de bord",
        delete: "Supprimer un tableau de bord",
        confirmDeletion: "Voulez-vous vraiment supprimer le tableau de bord \xAB\xA0{{name}}\xA0\xBB ?",
        actions: "Options",
        add: "Ajouter tableau de bord",
        resetToTemplate: "R\xE9initialiser vers mod\xE8le",
        validateName: "peut uniquement contenir des caract\xE8res alphanum\xE9riques ou des espaces.",
        changeDashboardMessage: "\xCAtes-vous certain de vouloir modifier les tableaux de bord sans enregistrer vos modifications ?",
        uniqueNameError: "doit \xEAtre unique",
        resetToDefault: "Voulez-vous r\xE9initialiser le tableau de bord par d\xE9faut ?",
        resetToTemplateDescription: "R\xE9initialisez votre tableau de bord sur les widgets et la mise en page d'origine pour le mod\xE8le choisi.",
        options: "Options",
        maxNumberOfDashboardReached: "Impossible de cr\xE9er plus de tableaux de bord, maximum de {{maxDashboardsAmount}} atteint"
      },
      domainCard: {
        title: "Domaines"
      },
      dualTopBarBaseCard: {
        title: "Domaines",
        overview: "Total",
        totalAllowedSessions: "Sessions autoris\xE9es ",
        totalBlockedSessions: "Sessions bloqu\xE9es ",
        allowed: "Autoris\xE9",
        blocked: "Bloqu\xE9"
      },
      topUrlCard: {
        title: "Cat\xE9gories d'URL"
      },
      malwareCard: {
        title: "Advanced Threat Protection",
        hits: "{{formattedCount}} R\xE9sultats",
        hits_one: "{{formattedCount}} R\xE9sultat",
        hits_other: "{{formattedCount}} R\xE9sultats"
      },
      ipsIncidentCard: {
        title: "Incidents IPS",
        sessions: "{{sessions}} R\xE9sultats",
        sessions_one: "{{sessions}} R\xE9sultat",
        sessions_other: "{{sessions}} R\xE9sultats"
      },
      affectedUsers: {
        title: "Utilisateurs affect\xE9s"
      },
      totalChart: {
        total: "Total"
      },
      geoCard: {
        sourceTopCountries: {
          title: "Top des pays G\xE9o-Source"
        },
        destinationTopCountries: {
          title: "Top des pays G\xE9o-Destination"
        }
      },
      topAllowedCategoriesSessions: {
        title: "Top des cat\xE9gories d'application autoris\xE9es <0>(par Session)</0>",
        menuEntry: "Top des cat\xE9gories d'application autoris\xE9es (par Session)",
        footerKey: "Top {{length}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topAllowedCategoriesTraffic: {
        title: "Top des cat\xE9gories d'application autoris\xE9es <0>(par Trafic)</0>",
        menuEntry: "Top des cat\xE9gories d'application autoris\xE9es (par Trafic)",
        footerKey: "Top {{length}}"
      },
      blockedApplicationCategories: {
        title: "Top des cat\xE9gories d'application bloqu\xE9es",
        footerKey: "Top {{length}}"
      },
      allowedApplicationsSessions: {
        title: "Top des applications autoris\xE9es <0>(par Session)</0>",
        menuEntry: "Top des applications autoris\xE9es (par Session)",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions",
        footerKey: "Top {{length}}"
      },
      allowedApplicationsTraffic: {
        title: "Top des applications autoris\xE9es <0>(par Trafic)</0>",
        menuEntry: "Top des applications autoris\xE9es (par Trafic)",
        footerKey: "Top {{length}}"
      },
      blockedApplications: {
        title: "Top des applications bloqu\xE9es",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions",
        footerKey: "Top {{length}}"
      },
      applicationRisk: {
        mediumHigh: "Moyen -\nHaut",
        medium: "Moyen",
        lowMedium: "Bas - Moyen",
        low: "Bas",
        high: "Haut",
        title: "Risque d'application",
        risk: "Risque"
      },
      highRiskApplicationsCard: {
        title: "Applications \xE0 haut risque",
        top: "Top {{length}}"
      },
      topBlockedUrlCategoriesCard: {
        title: "Top des cat\xE9gories d'URL bloqu\xE9es",
        topUrlCategories: "Top {{length}} cat\xE9gories d'URL bloqu\xE9es",
        total: "sur {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      applicationCategories: {
        title: "Cat\xE9gories d'application"
      },
      topApplications: {
        title: "Applications"
      },
      topAllowedUrlCategoriesCard: {
        titlePerSession: "Top des cat\xE9gories d'URL autoris\xE9es <0>(par Session)</0>",
        titlePerTraffic: "Top des cat\xE9gories d'URL autoris\xE9es <0>(par Trafic)</0>",
        menuEntryPerSession: "Top des cat\xE9gories d'URL autoris\xE9es (par Session)",
        menuEntryPerTraffic: "Top des cat\xE9gories d'URL autoris\xE9es (par Trafic)",
        topAllowedUrlCategories: "Top {{length}} cat\xE9gories d'URL autoris\xE9es",
        total: "sur {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topAllowedDomainsCard: {
        titlePerSession: "Top des domaines autoris\xE9s <0>(par Session)</0>",
        titlePerTraffic: "Top des domaines autoris\xE9s <0>(par Trafic)</0>",
        menuEntryPerSession: "Top des domaines autoris\xE9s (par Session)",
        menuEntryPerTraffic: "Top des domaines autoris\xE9s (par Trafic)",
        topAllowedDomains: "Top {{length}} domaines autoris\xE9s",
        total: "sur {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topBlockedDomainsCard: {
        title: "Top des domaines bloqu\xE9s",
        topBlockedDomains: "Top {{length}} domaines bloqu\xE9s",
        total: "sur {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topUsersCard: {
        title: "Top des utilisateurs",
        topUsers: "Top {{length}} utilisateurs",
        total: "sur {{total}}"
      },
      noData: {
        message: "Aucune donn\xE9e pour ce widget !"
      },
      barGrid: {
        noData: "Aucune entr\xE9e correspondante trouv\xE9e !"
      },
      ZTNAResources: {
        title: "Ressources",
        others: "Autres",
        total: "Total disponible",
        custom: "Personnalis\xE9",
        inUse: "En cours d'utilisation",
        tableName: "Strat\xE9gies d'acc\xE8s",
        table: {
          name: "Nom",
          description: "Description",
          users: "Utilisateurs",
          groups: "Groupes",
          resources: "Ressources",
          devicePosture: "Posture de s\xE9curit\xE9"
        }
      },
      ZTNAAgentInUse: {
        title: "SE d'agent en usage",
        total: "Total",
        windows: "Win",
        macos: "macOS",
        android: "Android",
        ios: "iOS",
        linux: "Linux",
        tableName: "Top SE en usage",
        devices: "{{value}} Dispositif",
        devices_plural: "{{value}} Dispositifs"
      },
      ZTNADeviceLocation: {
        title: "Carte de dispositifs",
        devices: "Dispositifs",
        noData: "Aucun dispositif trouv\xE9.",
        user: "Utilisateur",
        brand: "Marque de dispositif",
        model: "Mod\xE8le de dispositif",
        hardwareModel: "Mod\xE8le de mat\xE9riel",
        operatingSystem: "Syst\xE8me d'exploitation",
        operatingSystemVersion: "Version OS",
        agentVersion: "Version d'agent",
        enrollmentDate: "Date d'inscription",
        country: "Pays",
        noCountry: "Aucun emplacement"
      },
      ZTNAUserGeoLocation: {
        title: "G\xE9olocalisation d'utilisateur",
        nonRoutableOrIP: "Adresses IP priv\xE9es ou non-routables",
        devices: "{{value}} Dispositif",
        devices_plural: "{{value}} Dispositifs"
      },
      userStatusCard: {
        title: "Utilisateurs",
        total: "Total",
        enrolled: "Inscrit",
        pending: "En cours",
        tableTitle: "Top utilisateurs (par compte de dispositifs)",
        name: "Nom",
        email: "E-mail",
        deviceCount: "Nombre de dispositifs",
        enrollmentCompleted: {
          label: "Inscription termin\xE9e",
          yes: "Inscription termin\xE9e",
          no: "Inscription en cours"
        }
      },
      userSummaryCard: {
        title: "Synth\xE8se",
        fullName: "Nom complet",
        email: "E-mail",
        deviceCount: "Nombre de dispositifs",
        enrollmentCompleted: "Inscription termin\xE9e"
      },
      userDevicesCard: {
        title: "Dispositifs",
        table: {
          userFullName: "Nom complet",
          userEmail: "E-mail",
          brand: "Marque de dispositif",
          hardwareModel: "Mod\xE8le de dispositif",
          operatingSystem: "Syst\xE8me d'exploitation",
          operatingSystemVersion: "Version OS",
          enrollmentDate: "Date d'inscription"
        }
      },
      accessDevicesAgentVersionCard: {
        title: "Version d'agent en usage",
        total: "Total",
        version: "Version {{version}}",
        older: "Ancien",
        tableTitle: "Top Versions en usage",
        devices: "{{value}} Dispositif",
        devices_plural: "{{value}} Dispositifs"
      },
      categories: {
        ztna: "Zero Trust Access",
        security: "S\xE9curit\xE9",
        webFilter: "Filtre Web"
      }
    }
  }
};
export {
  fr_default as default
};
