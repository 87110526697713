var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import {
  ActionButtonsField,
  ActionDialog,
  Card,
  ConnectedTable,
  CrudTypes,
  DateField,
  DeleteDialog,
  getArrayDataContent,
  getDataContent,
  PropGateway,
  SelectedChoiceField,
  useCrudSubscription,
  useDeepCompareMemo,
  useMixpanel
} from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { useRef } from "react";
import DefaultUpdateWindow from "./DefaultUpdateWindow";
import CreateUpdateDialog from "./dialog/CreateUpdateDialog";
import EditUpdateDialog from "./dialog/EditUpdateDialog";
import apiResources from "../../../../../apiResources";
import { useTranslation } from "react-i18next";
import { Typography } from "@barracuda-internal/bds-core";
import { GatewayIcon, SiteIcon } from "@cuda-react/icons";
const useStyles = makeStyles((theme) => ({
  message: {
    margin: theme.spacing(2, 4, 0)
  },
  tableContainer: {
    padding: theme.spacing(0, 6, 2)
  }
}));
const convertApplianceId = (type) => (appliance) => __spreadProps(__spreadValues({}, appliance), {
  key: `${type}:${appliance.key}`,
  type
});
const getAvailableAppliances = (appliances, type, updateWindowsContent, availableUpdates) => {
  const appliancesWithUpdateWindows = updateWindowsContent.filter((window) => window.configurationType === type).map((window) => window.configurationId);
  return appliances.filter((appliance) => {
    var _a;
    if (appliancesWithUpdateWindows.includes(appliance.key)) {
      return false;
    }
    return (_a = availableUpdates == null ? void 0 : availableUpdates.configurationUpdates) == null ? void 0 : _a.some((item) => item.type === type && item.id === appliance.key);
  }).map(convertApplianceId(type));
};
const FirmwareUpdates = () => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const registerAction = useMixpanel("Network Settings Firmware Updates", false);
  const tableRefreshRef = useRef();
  const [updateNowData, , refreshUpdateNow] = useCrudSubscription(CrudTypes.GET, apiResources.updateNow, {}, { pollInterval: 3e4 });
  const updateNow = getDataContent(updateNowData == null ? void 0 : updateNowData.data, false);
  const [availableUpdatesData, , refreshAvailableUpdates] = useCrudSubscription(CrudTypes.GET, apiResources.updatesAvailable, {}, { pollInterval: 3e4 });
  const availableUpdates = getDataContent(availableUpdatesData, {});
  const [updateWindowsData, , refreshUpdatesList] = useCrudSubscription(CrudTypes.GET, apiResources.updatesList);
  const updateWindowsContent = getArrayDataContent(updateWindowsData);
  const [currentUpdateDefaultData, , refreshDefault] = useCrudSubscription(CrudTypes.GET, apiResources.updateDefault, { filter: { allConfigurations: true } });
  const currentUpdateDefault = getArrayDataContent(currentUpdateDefaultData)[0];
  const [gatewaysData, , refreshGateways] = useCrudSubscription(CrudTypes.GET, apiResources.gatewayNames, {}, { pollInterval: 3e4 });
  const gateways = getArrayDataContent(gatewaysData, []);
  const [sitesData, , refreshSites] = useCrudSubscription(CrudTypes.GET, apiResources.siteNames, {}, { pollInterval: 3e4 });
  const sites = getArrayDataContent(sitesData, []);
  const onUpdateNow = () => {
    refreshUpdateNow();
    refreshGateways();
    refreshSites();
    refreshAvailableUpdates();
    refreshDefault();
    registerAction("Install Now");
  };
  const onUpdatesChange = () => {
    refreshUpdatesList();
    refreshDefault();
    tableRefreshRef.current && tableRefreshRef.current();
  };
  const availableAppliances = useDeepCompareMemo(() => {
    const availableGateways = getAvailableAppliances(gateways, "gateway", updateWindowsContent, availableUpdates);
    const availableSites = getAvailableAppliances(sites, "site", updateWindowsContent, availableUpdates);
    return [...availableGateways, ...availableSites];
  }, [gateways, sites, updateWindowsContent, availableUpdates]);
  return /* @__PURE__ */ jsxs(Card, { title: "tesseract.settings.tabs.updates.firmware.title", children: [
    /* @__PURE__ */ jsx(Typography, { className: classes.message, children: translate("tesseract.settings.tabs.updates.firmware.description") }),
    /* @__PURE__ */ jsx("div", { className: classes.tableContainer, id: "FirmwareUpdateWindows", children: /* @__PURE__ */ jsxs(
      ConnectedTable,
      {
        actions: [
          /* @__PURE__ */ jsx(
            CreateUpdateDialog,
            {
              disabled: !updateNow || availableAppliances.length === 0,
              disabledMessage: "tesseract.settings.tabs.updates.firmware.noAppliancesMessage",
              appliances: availableAppliances,
              availableUpdates,
              onSuccess: () => {
                onUpdatesChange();
                registerAction("Create");
              }
            },
            0
          ),
          /* @__PURE__ */ jsx(
            ActionDialog,
            {
              crudType: CrudTypes.CREATE,
              resource: apiResources.updateNow,
              buttonText: "tesseract.settings.tabs.updates.firmware.updateNow.buttonLabel",
              disabledMessage: "tesseract.settings.tabs.updates.firmware.updateNow.disabledMessage",
              title: "tesseract.settings.tabs.updates.firmware.updateNow.dialogTitle",
              message: "tesseract.settings.tabs.updates.firmware.updateNow.dialogMessage",
              disabled: !updateNow,
              onSuccess: onUpdateNow
            },
            1
          ),
          /* @__PURE__ */ jsx(
            DefaultUpdateWindow,
            {
              left: true,
              onSuccess: () => {
                onUpdatesChange();
                registerAction("Default Update");
              },
              data: currentUpdateDefault,
              availableUpdates,
              disabled: !updateNow
            },
            2
          )
        ],
        resource: updateNow && apiResources.update,
        defaultSort: { field: "from", order: "ASC" },
        defaultFilter: { allConfigurations: false, installNow: false },
        noDataWithFilterMessage: (availableUpdates == null ? void 0 : availableUpdates.configurationUpdates) ? "tesseract.settings.tabs.updates.firmware.noWindowsMessage" : "tesseract.settings.tabs.updates.firmware.noUpdatesMessage",
        flat: true,
        noRouter: true,
        refreshRef: tableRefreshRef,
        children: [
          /* @__PURE__ */ jsx(
            PropGateway,
            {
              source: "configurationId",
              label: "tesseract.settings.tabs.updates.firmware.fields.appliance",
              editProps: (props) => {
                var _a;
                const isGateway = ((_a = props == null ? void 0 : props.data) == null ? void 0 : _a.configurationType) === "gateway";
                return __spreadProps(__spreadValues({}, props), {
                  choices: isGateway ? gateways : sites,
                  icon: isGateway ? /* @__PURE__ */ jsx(GatewayIcon, {}) : /* @__PURE__ */ jsx(SiteIcon, {})
                });
              },
              children: /* @__PURE__ */ jsx(SelectedChoiceField, { source: "configurationId" })
            }
          ),
          /* @__PURE__ */ jsx(
            DateField,
            {
              source: "from",
              label: "tesseract.settings.tabs.updates.firmware.fields.from"
            }
          ),
          /* @__PURE__ */ jsx(
            DateField,
            {
              source: "to",
              label: "tesseract.settings.tabs.updates.firmware.fields.to"
            }
          ),
          /* @__PURE__ */ jsxs(
            ActionButtonsField,
            {
              source: "gatewayId",
              width: 128,
              children: [
                /* @__PURE__ */ jsx(
                  DeleteDialog,
                  {
                    resource: apiResources.update,
                    title: "tesseract.settings.tabs.updates.firmware.delete.title",
                    message: "tesseract.settings.tabs.updates.firmware.delete.message",
                    onSuccess: () => {
                      onUpdatesChange();
                      registerAction("Delete");
                    }
                  }
                ),
                /* @__PURE__ */ jsx(
                  EditUpdateDialog,
                  {
                    appliances: availableAppliances,
                    availableUpdates,
                    onSuccess: () => {
                      onUpdatesChange();
                      registerAction("Update");
                    }
                  }
                )
              ]
            }
          )
        ]
      }
    ) })
  ] });
};
var FirmwareUpdates_default = FirmwareUpdates;
export {
  convertApplianceId,
  FirmwareUpdates_default as default
};
