var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import {
  ConnectedForm,
  DialogBody,
  FileInput,
  PasswordInput,
  TextInput,
  useGlobalParam,
  validateMaxLengthMemo,
  validateRegexMemo
} from "@cuda-react/core";
import { get } from "lodash";
import apiResources from "../../../../../../apiResources";
const CreateEditSslCertificates = (_a) => {
  var _b = _a, { create, id, type, onSuccess } = _b, props = __objRest(_b, ["create", "id", "type", "onSuccess"]);
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  return /* @__PURE__ */ jsx(
    DialogBody,
    __spreadProps(__spreadValues({}, props), {
      form: true,
      title: create ? "tesseract.security.sslInterception.settings.certificates.create.dialogTitle" : "tesseract.security.sslInterception.settings.certificates.edit.dialogTitle",
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          resource: type === "interception" ? apiResources.sslCertificates : apiResources.sslCertificateAuthorities,
          form: "sslCertificates-" + type,
          create,
          params: create ? void 0 : { id },
          onSubmitSuccess: () => {
            onSuccess && onSuccess();
            props.onClose();
          },
          onCancel: props.onClose,
          formatRequestData: (_a2) => {
            var _b2 = _a2, { contents } = _b2, data = __objRest(_b2, ["contents"]);
            return __spreadProps(__spreadValues({}, data), {
              type,
              contents: get(contents, "data"),
              virtualWanId: data.virtualWanId || virtualWanId
            });
          },
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "name",
                label: "Name",
                description: "tesseract.security.sslInterception.settings.descriptions.certificateName",
                validate: [validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")],
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "description",
                label: "tesseract.security.sslInterception.settings.description",
                description: "tesseract.security.sslInterception.settings.descriptions.certificateDescription",
                validate: [validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]
              }
            ),
            create ? /* @__PURE__ */ jsx(
              FileInput,
              {
                source: "contents",
                label: type === "interception" ? "tesseract.security.sslInterception.settings.uploadP12" : "tesseract.security.sslInterception.settings.uploadPEM",
                description: type === "interception" ? "tesseract.security.sslInterception.settings.descriptions.uploadP12" : "tesseract.security.sslInterception.settings.descriptions.uploadPEM",
                isRequired: true,
                accept: "*",
                readType: type === "interception" ? "base64" : "string"
              }
            ) : null,
            type === "interception" && create ? /* @__PURE__ */ jsx(
              PasswordInput,
              {
                source: "passphrase",
                label: "tesseract.security.sslInterception.settings.passphrase",
                description: "tesseract.security.sslInterception.settings.descriptions.passphrase"
              }
            ) : null
          ]
        }
      )
    })
  );
};
var CreateEditSslCertificates_default = CreateEditSslCertificates;
export {
  CreateEditSslCertificates_default as default
};
