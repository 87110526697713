import { jsx, jsxs } from "react/jsx-runtime";
import { LoadingMessage, useCrudProps } from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";
import { Box, Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
const RedirectUriInfo = () => {
  const [translate] = useTranslation();
  const [{ data }, loading] = useCrudProps(apiResources.identityProvidersConfiguration);
  return /* @__PURE__ */ jsxs(Box, { pl: 8, mt: 2, mb: 4, children: [
    /* @__PURE__ */ jsx(Typography, { color: "secondary", mb: 1, fontWeight: "bold", children: translate("tesseract.identity.identityProviders.add.redirectUri") }),
    loading ? /* @__PURE__ */ jsx(LoadingMessage, {}) : /* @__PURE__ */ jsx(Typography, { color: "secondary", children: data == null ? void 0 : data.oidcRedirectURL })
  ] });
};
export {
  RedirectUriInfo
};
