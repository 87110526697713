var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useRef, useState } from "react";
import {
  ActionButtonsField,
  Card,
  ConnectedTable,
  CustomField,
  DeleteDialog,
  DialogBody,
  TextField,
  useMixpanel
} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import { useTranslation } from "react-i18next";
import { UserDirectorySyncResultField } from "./components/UserDirectorySyncResultField";
import { UserDirectoryStatusField } from "./components/UserDirectoryStatusField";
import CreateEditButtonDialog from "../../../../components/CreateEditButtonDialog";
import { Dialog } from "@barracuda-internal/bds-core";
import { UserDirectoryForm } from "./components/UserDirectoryForm/UserDirectoryForm";
import { EditUserDirectoryWrapper } from "./components/EditUserDirectoryWrapper";
import { UserDirectoryMenu } from "./components/UserDirectoryMenu";
import { LastSyncField } from "./components/LastSyncField";
import { styled } from "@mui/material";
import { UserDirectoriesNoData } from "./components/UserDirectoriesNoData";
import useDemoApi from "../../../../hooks/useDemoApi";
import { AddUserDirectoryButton } from "./components/AddUserDirectoryButton";
import { DeleteContent } from "../../components/DeleteContent";
const initialValue = {
  displayName: "",
  type: "bcc",
  accountName: ""
};
const initialOptions = {
  ldap: {
    ldapAuthMethod: "simple",
    ldapAuthPassword: "",
    ldapAuthUsername: "",
    ldapCacerts: "",
    ldapCertAdditionalNames: "",
    ldapCheckCerts: false,
    ldapCheckHostname: false,
    ldapGroupSearchBase: "",
    ldapHost: "",
    ldapPort: "",
    ldapPrivkey: "",
    ldapPrivkeyPassword: "",
    ldapProfile: "ad",
    ldapPubkey: "",
    ldapSni: "",
    ldapUseStarttls: false,
    ldapUseTls: false,
    ldapUserSearchBase: ""
  },
  okta: {
    oktaAuthToken: "",
    oktaDomainName: ""
  },
  google: {
    googleWorkspaceDomain: ""
  },
  entraid: {
    tenantId: ""
  },
  bcc: {
    environmentId: ""
  }
};
const i18nScope = "tesseract.identity.userDirectories";
const MinHeightCard = styled(Card)({
  height: "calc(50vh - 90px)"
});
const UserDirectories = () => {
  const directoryApi = useDemoApi(apiResources.directories, apiResources.demoDirectories);
  const registerAction = useMixpanel("User Directories");
  const tableRefreshRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [initialFormValue, setInitialFormValue] = useState(
    __spreadProps(__spreadValues({}, initialValue), {
      type: "bcc",
      options: {
        environmentId: ""
      }
    })
  );
  const [translate] = useTranslation();
  const handleCreateClose = (wasCreated) => {
    var _a;
    if (wasCreated) {
      registerAction("created");
      (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
    }
    setDialogOpen(false);
  };
  const handleOpenForm = (type) => {
    const newUserDirectory = __spreadProps(__spreadValues({}, initialValue), {
      type,
      options: __spreadValues({}, initialOptions[type])
    });
    setInitialFormValue(newUserDirectory);
    setDialogOpen(true);
  };
  const handleDelete = useCallback(() => {
    var _a;
    registerAction("deleted");
    (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
  }, []);
  return /* @__PURE__ */ jsxs(MinHeightCard, { title: `${i18nScope}.subtitle`, children: [
    /* @__PURE__ */ jsxs(
      ConnectedTable,
      {
        hasTitle: true,
        resource: directoryApi,
        noDataPage: /* @__PURE__ */ jsx(UserDirectoriesNoData, { onSelect: handleOpenForm }),
        tableName: `${i18nScope}.tableName`,
        minCellWidth: 142,
        pollInterval: 3e5,
        refreshRef: tableRefreshRef,
        actions: [
          /* @__PURE__ */ jsx(
            AddUserDirectoryButton,
            {
              onSelect: handleOpenForm
            },
            0
          )
        ],
        children: [
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "displayName",
              label: `${i18nScope}.displayName`,
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "type",
              label: `${i18nScope}.type`,
              render: (value, directory) => translate(`${i18nScope}.form.type.${directory.type}`)
            }
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "connectionStatus",
              label: `${i18nScope}.status`,
              render: (value, directory) => /* @__PURE__ */ jsx(UserDirectoryStatusField, { directory })
            }
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "lastSuccessfulSync",
              label: `${i18nScope}.lastSuccessfulSync`,
              render: (value, directory) => /* @__PURE__ */ jsx(LastSyncField, { directory })
            }
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              label: `${i18nScope}.syncResult`,
              source: "totalUsers",
              render: (value, directory) => /* @__PURE__ */ jsx(UserDirectorySyncResultField, { directory })
            }
          ),
          /* @__PURE__ */ jsxs(
            ActionButtonsField,
            {
              width: 142,
              source: "id",
              alwaysVisible: true,
              children: [
                /* @__PURE__ */ jsx(UserDirectoryMenu, {}),
                /* @__PURE__ */ jsx(
                  CreateEditButtonDialog,
                  {
                    create: false,
                    component: EditUserDirectoryWrapper,
                    onSuccess: () => {
                      var _a;
                      (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                    }
                  }
                ),
                /* @__PURE__ */ jsx(
                  DeleteDialog,
                  {
                    resource: apiResources.directories,
                    title: "tesseract.identity.userDirectories.delete.title",
                    message: /* @__PURE__ */ jsxs(DeleteContent, { children: [
                      /* @__PURE__ */ jsx("p", { children: translate("tesseract.identity.userDirectories.delete.body") }),
                      /* @__PURE__ */ jsx("p", { children: translate("tesseract.identity.userDirectories.delete.body2") })
                    ] }),
                    onSuccess: handleDelete
                  }
                )
              ]
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Dialog,
      {
        open: dialogOpen,
        maxWidth: false,
        children: /* @__PURE__ */ jsx(
          DialogBody,
          {
            form: true,
            title: `${i18nScope}.createDirectoryDialogTitle`,
            onClose: () => handleCreateClose(false),
            children: /* @__PURE__ */ jsx(
              UserDirectoryForm,
              {
                create: true,
                directory: initialFormValue,
                onClose: handleCreateClose
              }
            )
          }
        )
      }
    )
  ] });
};
export {
  UserDirectories
};
