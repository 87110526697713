var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import {
  ConnectedForm,
  getArrayDataContent,
  Tab,
  useCrudProps,
  useDeepCompareMemo,
  useMixpanel,
  validatePrivateIpv4
} from "@cuda-react/core";
import { get, set } from "lodash";
import { useState } from "react";
import { getNetworkId, validateNames, validateNetworks } from "../../../hooks/createEditSiteHooks";
import { useTranslation } from "react-i18next";
import BasicTab from "./tabs/common/BasicTab";
import WansTab from "./tabs/tvt/WansTab";
import LansTab from "./tabs/tvt/LansTab";
import AdvancedSettingsTab from "./tabs/common/AdvancedSettingsTab";
import DhcpTab from "./tabs/tvt/DhcpTab";
import apiResources from "../../../apiResources";
import { DEFAULT_MTU } from "./inputs/wanTableInput/WanTableInput";
const SELECT_EMPTY_VALUE = "__SELECT_EMPTY_VALUE";
const parseVirtualLanId = (virtualLanId) => virtualLanId ? parseInt(virtualLanId) : null;
const format = {
  error: (error, data) => {
    var _a, _b, _c, _d;
    const wanErrors = [];
    const lanErrors = [];
    let indexes = { wans: 0, lans: 0, expressRoutes: 0, bridges: 0 };
    (_b = (_a = data.wans) == null ? void 0 : _a.forEach) == null ? void 0 : _b.call(_a, (wan, index) => {
      if (wan.mode === "expressRoute") {
        if (get(error, "expressRoutes")) {
          if (Array.isArray(get(error, "expressRoutes"))) {
            if (get(error, `expressRoutes[${indexes.expressRoutes}]`)) {
              const expressError = get(error, `expressRoutes[${indexes.expressRoutes}]`);
              set(wanErrors, `[${index}]`, expressError);
              if (get(expressError, "interfaces")) {
                get(expressError, "interfaces", []).forEach((interfaceErrors, interfaceIndex) => {
                  set(wanErrors, `[${index}].doublePort[${interfaceIndex}]`, get(interfaceErrors, "port"));
                  set(wanErrors, `[${index}].doubleVirtualLanId[${interfaceIndex}]`, get(interfaceErrors, "virtualLanId"));
                  set(wanErrors, `[${index}].doubleAddress[${interfaceIndex}]`, get(interfaceErrors, "address"));
                  set(wanErrors, `[${index}].doubleNetmask[${interfaceIndex}]`, get(interfaceErrors, "netmask"));
                });
              }
            }
          } else {
            set(wanErrors, `[${index}]`, get(error, "expressRoutes"));
          }
        }
        indexes.expressRoutes++;
      } else if (wan.mode === "bridge") {
        if (get(error, `bridges[${indexes.bridges}]`)) {
          set(wanErrors, `[${index}]`, get(error, `bridges[${indexes.bridges}]`));
          if (get(error, `bridges[${indexes.bridges}].type`)) {
            set(wanErrors, `[${index}].mode`, get(error, `bridges[${indexes.bridges}].type`));
          }
        } else if (get(error, `bridges`) && !Array.isArray(get(error, "bridges"))) {
          set(wanErrors, `[${index}]`, get(error, `bridges[${indexes.bridges}]`));
        }
        indexes.bridges++;
      } else {
        if (get(error, `wans[${indexes.wans}]`)) {
          set(wanErrors, `[${index}]`, get(error, `wans[${indexes.wans}]`));
        }
        indexes.wans++;
      }
    });
    (_d = (_c = data.lans) == null ? void 0 : _c.forEach) == null ? void 0 : _d.call(_c, (lan, index) => {
      if (lan.mode === "bridge") {
        if (get(error, `bridges[${indexes.bridges}]`)) {
          set(lanErrors, `[${index}]`, get(error, `bridges[${indexes.bridges}]`));
          if (get(error, `bridges[${indexes.bridges}].type`)) {
            set(lanErrors, `[${index}].mode`, get(error, `bridges[${indexes.bridges}].type`));
          }
        } else if (get(error, `bridges`) && !Array.isArray(get(error, "bridges"))) {
          set(lanErrors, `[${index}]`, get(error, `bridges[${indexes.bridges}]`));
        }
        indexes.bridges++;
      } else {
        if (get(error, `lans[${indexes.lans}]`)) {
          set(lanErrors, `[${index}]`, get(error, `lans[${indexes.lans}]`));
        }
        indexes.lans++;
      }
    });
    if (wanErrors.length > 0)
      set(error, "wans", wanErrors);
    if (lanErrors.length > 0)
      set(error, "lans", lanErrors);
    return error;
  },
  requestData: (_a) => {
    var _b = _a, { gateway, upstream } = _b, data = __objRest(_b, ["gateway", "upstream"]);
    const buildBridgeJson = (data2) => {
      const inlineBridge = data2.wans.filter((wan) => wan.mode === "bridge").map((bridge) => __spreadProps(__spreadValues({}, bridge), {
        type: "inline",
        dhcp: {
          type: "disabled"
        }
      }));
      const switchBridge = data2.lans.filter((lan) => lan.mode === "bridge").map((bridge) => __spreadProps(__spreadValues({}, bridge), {
        type: "switch"
      }));
      return inlineBridge.concat(switchBridge);
    };
    return __spreadProps(__spreadValues({}, data), {
      wans: data.wans.filter((wan) => wan.mode !== "expressRoute").filter((wan) => wan.mode !== "bridge").map((wan) => __spreadProps(__spreadValues({}, wan), { virtualLanId: parseVirtualLanId(wan.virtualLanId) })),
      lans: data.lans.filter((wan) => wan.mode !== "bridge").map((lan) => __spreadProps(__spreadValues({}, lan), { virtualLanId: parseVirtualLanId(lan.virtualLanId) })),
      expressRoutes: data.wans.filter((wan) => wan.mode === "expressRoute").map((expressRoute) => __spreadProps(__spreadValues({}, expressRoute), {
        interfaces: [{
          port: get(expressRoute, "doublePort[0]"),
          virtualLanId: parseVirtualLanId(get(expressRoute, "doubleVirtualLanId[0]")),
          address: get(expressRoute, "doubleAddress[0]"),
          netmask: get(expressRoute, "doubleNetmask[0]"),
          primary: true
        }, {
          port: get(expressRoute, "doublePort[1]"),
          virtualLanId: parseVirtualLanId(get(expressRoute, "doubleVirtualLanId[1]")),
          address: get(expressRoute, "doubleAddress[1]"),
          netmask: get(expressRoute, "doubleNetmask[1]"),
          primary: false
        }],
        transferNetwork: expressRoute.transferNetwork + "/28"
      })),
      bridges: buildBridgeJson(data),
      gateway: gateway === SELECT_EMPTY_VALUE ? null : gateway,
      upstream: upstream === SELECT_EMPTY_VALUE ? null : upstream
    });
  },
  resourceData: (_c) => {
    var _d = _c, { gateway, upstream } = _d, data = __objRest(_d, ["gateway", "upstream"]);
    return __spreadProps(__spreadValues({}, data), {
      wans: data.wans && data.wans.concat((data.expressRoutes || []).map((expressRoute) => {
        const interfaces = get(expressRoute, "interfaces", []).sort((routeA, routeB) => routeA.primary ? -1 : routeB.primary ? 1 : 0);
        return __spreadProps(__spreadValues({}, expressRoute), {
          mode: "expressRoute",
          doublePort: interfaces.map(({ port }) => port),
          doubleVirtualLanId: interfaces.map(({ virtualLanId }) => virtualLanId),
          doubleAddress: interfaces.map(({ address }) => address),
          doubleNetmask: interfaces.map(({ netmask }) => netmask),
          transferNetwork: expressRoute.transferNetwork && expressRoute.transferNetwork.split("/")[0],
          mtu: DEFAULT_MTU
        });
      }), (data.bridges || []).filter((bridge) => bridge.type === "inline").map((bridge) => __spreadProps(__spreadValues({}, bridge), {
        mode: "bridge",
        port: bridge.wanPort,
        mtu: DEFAULT_MTU
      }))),
      lans: data.lans && data.lans.concat((data.bridges || []).filter((bridge) => bridge.type === "switch").map((bridge) => __spreadProps(__spreadValues({}, bridge), {
        mode: "bridge",
        port: bridge.lanPorts[0]
      }))),
      gateway: gateway === null ? SELECT_EMPTY_VALUE : gateway,
      upstream: upstream === null ? SELECT_EMPTY_VALUE : upstream
    });
  }
};
const EditTVTContent = ({ id, type, details: { model, gatewayType, modelSeries, serial, serials, uuid } }) => {
  var _a, _b, _c;
  const [translate] = useTranslation();
  const [{ activeLans = [], wans = [] }, setFormValues] = useState({});
  const portsData = getArrayDataContent((_a = useCrudProps(
    type === "site" ? apiResources.sitePorts : apiResources.gatewaysOnPremPorts,
    { id }
  )[0]) == null ? void 0 : _a.data);
  const pointOfEntryApplicable = type === "gateway" && gatewayType === "on-prem" || type === "site" && modelSeries === "T";
  const isPointOfEntry = (_c = (_b = useCrudProps(pointOfEntryApplicable ? apiResources.pointsOfEntry : void 0, { filter: { configurationUuid: uuid } })[0].data) == null ? void 0 : _b.content) == null ? void 0 : _c[0];
  const gatewaySerials = gatewayType === "on-prem" ? serial == null ? void 0 : serial.split(",") : void 0;
  const [disabledPorts, breaksPointOfEntry] = useDeepCompareMemo(() => {
    const tableRowDimmed = (row) => {
      if (!row.port || row.mode === "wwan") {
        return false;
      }
      return portsData && !portsData.some((applianceInterface) => {
        if (row.mode === "expressRoute" ? row.doublePort.some((port) => port === applianceInterface.port) : applianceInterface.port === row.port) {
          return applianceInterface.available;
        }
      });
    };
    const wanHasPortDisabled = wans.some(tableRowDimmed);
    const lanHasPortDisabled = activeLans.some(tableRowDimmed);
    const disabledPorts2 = wanHasPortDisabled || lanHasPortDisabled;
    const breaksPointOfEntry2 = isPointOfEntry && !wans.some((wan) => wan.mode === "static" && validatePrivateIpv4(wan == null ? void 0 : wan.address, {}, { t: translate }));
    return [disabledPorts2, breaksPointOfEntry2];
  }, [activeLans, wans, portsData, isPointOfEntry]);
  const registerAction = useMixpanel(
    gatewayType ? "Gateways Settings" : "Sites Settings",
    !!model,
    gatewayType ? { model: model || "", type: gatewayType || "" } : { model: model || "", type: modelSeries || "" }
  );
  let confirmMessage;
  if (disabledPorts) {
    confirmMessage = [translate("tesseract.appliances.settings.confirmPortNotActivated", { context: type })];
  }
  if (breaksPointOfEntry) {
    confirmMessage = confirmMessage ? [
      ...confirmMessage,
      translate("tesseract.appliances.settings.confirmPointOfEntryBroken", { context: type })
    ] : [
      translate("tesseract.appliances.settings.confirmPointOfEntryBroken", { context: type })
    ];
  }
  if (confirmMessage) {
    confirmMessage = [...confirmMessage, translate("tesseract.appliances.settings.confirm")];
  }
  return /* @__PURE__ */ jsxs(
    ConnectedForm,
    {
      resource: type === "site" ? apiResources.siteConfiguration : apiResources.gatewaysOnPrem,
      formButtonToolbarProps: confirmMessage ? { confirmMessage } : void 0,
      id,
      canReset: true,
      tabbed: true,
      topToolbar: true,
      validate: (values = {}) => {
        const errors = {};
        const usedNetworks = validateNetworks(values, "wans", void 0, translate, errors);
        validateNetworks(values, "lans", void 0, translate, errors, usedNetworks);
        const usedNetworkNames = validateNames(values, "wans", void 0, translate, errors);
        validateNames(values, "lans", void 0, translate, errors, usedNetworkNames);
        validateNames(values, "routes", void 0, translate, errors);
        const wanBridgeCount = get(values, "wans", []).filter((wan) => wan.mode === "bridge").length;
        if (get(values, "lans", []).length < 1 && wanBridgeCount === 0) {
          set(errors, "lans", translate("tesseract.appliances.dialog.validation.noLans"));
        }
        if (wanBridgeCount > 1) {
          values.wans.forEach((wan, index) => {
            if (wan.mode === "bridge") {
              set(errors, `wans[${index}].mode`, translate("tesseract.appliances.dialog.validation.oneInlineBridge"));
            }
          });
        }
        values.secondaryDns && !values.primaryDns && set(errors, "secondaryDns", translate("tesseract.appliances.dialog.validation.secondaryDns"));
        return errors;
      },
      formatRequestData: format.requestData,
      formatResourceData: format.resourceData,
      formatError: format.error,
      onChange: (newValues) => {
        setFormValues({
          wans: get(newValues, "wans"),
          activeLans: get(newValues, "lans", []).map((lan) => __spreadProps(__spreadValues({}, lan), { networkId: getNetworkId(lan) }))
        });
      },
      onSubmitSuccess: (response) => {
        registerAction("Update", {
          model: response.model,
          hasUpstream: type === "gateway" ? !!response.gateway : "",
          type: type === "site" ? "T" : "on-prem",
          wansLength: response.wans.length,
          lansLength: response.lans.length,
          isHa: response.serials.length > 1
        });
      },
      children: [
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.appliances.settings.basic.tabTitle", children: /* @__PURE__ */ jsx(BasicTab, { id, applianceType: type, series: "T" }) }),
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.appliances.settings.wan.tabTitle", children: /* @__PURE__ */ jsx(WansTab, { applianceType: type, portsData, series: "T" }) }),
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.appliances.settings.lan.tabTitle", children: /* @__PURE__ */ jsx(LansTab, { portsData }) }),
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.appliances.settings.dhcp.tabTitle", children: /* @__PURE__ */ jsx(DhcpTab, { id, applianceType: type }) }),
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.appliances.settings.advanced.tabTitle", children: /* @__PURE__ */ jsx(
          AdvancedSettingsTab,
          {
            applianceType: type,
            serials: gatewayType ? gatewaySerials : serials,
            gatewayType
          }
        ) })
      ]
    }
  );
};
var EditTVTContent_default = EditTVTContent;
export {
  SELECT_EMPTY_VALUE,
  EditTVTContent_default as default,
  format
};
