import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Fragment as Fragment2 } from "react";
import {
  Card,
  DateField,
  EventCardContent,
  ListCardContent,
  LocationsCardContent,
  TextField,
  usePreview
} from "@cuda-react/core";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../apiResources";
import { useTheme } from "@mui/material";
const DeploymentCard = ({ type, details }) => {
  var _a;
  const [translate] = useTranslation();
  const expertMode = usePreview("expert");
  const theme = useTheme();
  const renderDeploymentCard = (serial, multiple) => /* @__PURE__ */ jsxs(Fragment2, { children: [
    /* @__PURE__ */ jsx(
      Card,
      {
        id: "DeploymentStatusCard",
        title: translate(
          "tesseract.appliances.dashboard.deployment.status.title",
          multiple ? { serial } : { serial: "" }
        ),
        children: /* @__PURE__ */ jsxs(
          ListCardContent,
          {
            getFrom: {
              resource: apiResources.applianceDeploymentStatus,
              params: { id: serial }
            },
            children: [
              /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dashboard.deployment.status.status", source: "statusName" }),
              /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dashboard.deployment.status.action", source: "actionName" }),
              /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dashboard.deployment.status.message", source: "message" }),
              /* @__PURE__ */ jsx(DateField, { label: "tesseract.appliances.dashboard.deployment.status.registered", source: "registered" }),
              /* @__PURE__ */ jsx(DateField, { label: "tesseract.appliances.dashboard.deployment.status.lastSeen", source: "lastSeen" }),
              /* @__PURE__ */ jsx(DateField, { label: "tesseract.appliances.dashboard.deployment.status.completed", source: "completed" }),
              /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dashboard.deployment.status.localIp", source: "localIp" }),
              /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dashboard.deployment.status.remoteIp", source: "remoteIp" })
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(Card, { id: "DeploymentEventsCard", title: "tesseract.appliances.dashboard.deployment.events.title", children: /* @__PURE__ */ jsx(
      EventCardContent,
      {
        getFrom: {
          resource: apiResources.applianceDeploymentEvents,
          params: { id: serial },
          options: {
            pollInterval: 3e5
          }
        },
        titleKey: "status",
        statusKey: "state",
        dateKey: "created",
        textKey: "description",
        noDataMessage: "tesseract.appliances.dashboard.deployment.events.siteDeploying"
      }
    ) }),
    /* @__PURE__ */ jsx(Card, { id: "DeploymentLocationsCard", title: "tesseract.appliances.dashboard.deployment.location.title", children: /* @__PURE__ */ jsx(
      LocationsCardContent,
      {
        params: { id: serial },
        legendEnabled: false,
        resource: apiResources.applianceDeploymentLocations,
        series: [{ label: "Location", color: theme.palette.neutral.main }]
      }
    ) })
  ] }, serial);
  return /* @__PURE__ */ jsx(Fragment, { children: type === "sites" && expertMode && ((_a = details == null ? void 0 : details.serials) == null ? void 0 : _a.map((serial) => {
    var _a2;
    return renderDeploymentCard(serial, (((_a2 = details == null ? void 0 : details.serials) == null ? void 0 : _a2.length) || 0) > 1);
  })) || null });
};
var DeploymentCard_default = DeploymentCard;
export {
  DeploymentCard_default as default
};
