var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import {
  Card,
  CrudTypes,
  CustomField,
  DateField,
  formatDateTime,
  getArrayDataContent,
  getDataContent,
  ListCardContent,
  StatusIconField,
  TableCardContent,
  TextField,
  useCrudSubscription
} from "@cuda-react/core";
import { hotfixUpdateStatusIcons } from "../../../../components/iconMapping";
import { get } from "lodash";
import apiResources from "../../../../apiResources";
const FirmwareCard = ({ type, details }) => {
  var _a;
  const [translate] = useTranslation();
  const gatewayType = details == null ? void 0 : details.gatewayType;
  const isCloud = !(type === "sites" || gatewayType === "on-prem");
  const [applianceFirmwareData] = useCrudSubscription(
    CrudTypes.GET,
    isCloud ? apiResources.gatewayFirmwareStatus : details.activeSerial && (gatewayType === "on-prem" ? apiResources.configurationOnPremFirmware : apiResources.configurationFirmware),
    isCloud ? { id: details.id } : { id: details.activeSerial, siteId: details.id },
    { pollInterval: 3e4 }
  );
  const applianceFirmwareStatus = getDataContent(applianceFirmwareData);
  const [updateWindowsData] = useCrudSubscription(CrudTypes.GET, apiResources.update, {}, { pollInterval: 3e4 });
  const updateWindows = getArrayDataContent(updateWindowsData);
  const currentApplianceUpdateWindow = (_a = updateWindows.find((update) => update.configurationId === details.id && update.configurationType === (type === "sites" ? "site" : "gateway"))) != null ? _a : updateWindows.find((update) => update.allConfigurations === true);
  const isSc = get(details, "modelSeries") === "S" || !get(details, "modelSeries");
  const detailsParams = {
    firmwareStatus: applianceFirmwareStatus,
    firmwareUpdate: currentApplianceUpdateWindow
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Card,
      {
        id: "FirmwareDetailsCard",
        title: "tesseract.appliances.dashboard.firmware.details.tabTitle",
        children: /* @__PURE__ */ jsxs(ListCardContent, { data: detailsParams, children: [
          /* @__PURE__ */ jsx(
            TextField,
            {
              label: "tesseract.appliances.dashboard.firmware.details.version",
              source: "firmwareStatus.firmwareVersion"
            }
          ),
          type === "gateways" || !isSc ? /* @__PURE__ */ jsx(
            TextField,
            {
              label: "tesseract.appliances.dashboard.firmware.details.hotfixes",
              source: "firmwareStatus.hotfixes"
            }
          ) : null,
          currentApplianceUpdateWindow ? /* @__PURE__ */ jsx(
            CustomField,
            {
              label: "tesseract.appliances.dashboard.firmware.details.window",
              source: "firmwareUpdate",
              render: (value) => translate(
                "tesseract.appliances.dashboard.firmware.details.nextUpdate",
                __spreadProps(__spreadValues({}, value), {
                  from: formatDateTime(value.from),
                  to: formatDateTime(value.to)
                })
              )
            }
          ) : null
        ] })
      }
    ),
    type === "sites" && !isSc || gatewayType === "on-prem" ? /* @__PURE__ */ jsx(
      Card,
      {
        id: "FirmwareHotfixesCard",
        title: "tesseract.appliances.dashboard.firmware.hotfixes.tabTitle",
        children: /* @__PURE__ */ jsxs(
          TableCardContent,
          {
            resource: details.activeSerial ? gatewayType === "on-prem" ? apiResources.configurationOnPremHotfixes : apiResources.configurationHotfixes : void 0,
            pollInterval: 3e5,
            params: { id: details.activeSerial, siteId: details.id },
            formatData: (data) => (data == null ? void 0 : data.hotfixes) || [],
            children: [
              /* @__PURE__ */ jsx(
                StatusIconField,
                {
                  label: "tesseract.appliances.dashboard.firmware.hotfixes.status",
                  source: "state",
                  tooltipSource: "state",
                  tooltipPrefix: "tesseract.appliances.dashboard.firmware.state.",
                  iconMap: hotfixUpdateStatusIcons,
                  width: 72
                }
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  label: "tesseract.appliances.dashboard.firmware.hotfixes.name",
                  source: "name"
                }
              )
            ]
          }
        )
      }
    ) : null,
    type === "sites" || gatewayType === "on-prem" ? /* @__PURE__ */ jsx(
      Card,
      {
        id: "ConfigurationUpdatesCard",
        title: "tesseract.appliances.dashboard.firmware.updates.tabTitle",
        children: /* @__PURE__ */ jsxs(
          ListCardContent,
          {
            getFrom: {
              resource: details.activeSerial ? gatewayType === "on-prem" ? apiResources.configurationOnPremUpdate : apiResources.configurationUpdate : void 0,
              options: { pollInterval: 3e5 },
              params: { id: details.activeSerial, siteId: details.id }
            },
            children: [
              /* @__PURE__ */ jsx(
                DateField,
                {
                  label: "tesseract.appliances.dashboard.firmware.updates.lastUpdate",
                  source: "lastUpdate"
                }
              ),
              /* @__PURE__ */ jsx(DateField, { label: "tesseract.appliances.dashboard.firmware.updates.lastSeen", source: "lastSeen" }),
              /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dashboard.firmware.updates.remoteIp", source: "remoteIp" })
            ]
          }
        )
      }
    ) : null
  ] });
};
var FirmwareCard_default = FirmwareCard;
export {
  FirmwareCard_default as default
};
