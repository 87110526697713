import { jsx } from "react/jsx-runtime";
import { BaseBarCard } from "../../baseCards";
import { colorFunction, numberFormatter } from "../../../utils";
import { sortBy } from "lodash";
import { NetworkIcon, StratosSupportIcon } from "@cuda-react/icons";
import Flag from "react-world-flags";
import { useCardFetch } from "../../../hooks";
import apiResources from "../../../apiResources";
import generatePreviewData from "../../../utils/previewDataGenerators/ztnaUserCountries";
import { usei18nCountries } from "../../../utils/countries";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
const ZTNAUserGeoLocationCard = (props) => {
  const { preview, size } = props;
  const { data = {}, loading } = useCardFetch({ url: !preview ? apiResources.ZTNAUserCountries : void 0 });
  const { countries = {} } = (preview ? generatePreviewData() : data) || {};
  const mappedCountries = Object.entries(countries);
  const sortedMappedCountries = sortBy(mappedCountries, 1).reverse();
  const maxValue = Math.max(...mappedCountries.map(([__, value]) => value));
  const getCountryNameByCode = usei18nCountries();
  const [translate] = useTranslation();
  const theme = useTheme();
  const entries = sortedMappedCountries.map(([key, value]) => ({
    title: getCountryNameByCode(key),
    value: size === "small" ? numberFormatter(value) : translate("stratosReporting.ZTNAUserGeoLocation.devices", { value: numberFormatter(value), context: value === 1 ? void 0 : "plural" }),
    barProgress: 100 / maxValue * value,
    icon: key ? key === "ZZ" ? /* @__PURE__ */ jsx(NetworkIcon, {}) : /* @__PURE__ */ jsx(
      Flag,
      {
        code: key,
        fallback: /* @__PURE__ */ jsx(StratosSupportIcon, {}),
        style: {
          width: 40,
          height: 20,
          borderRadius: "50%",
          marginRight: 0,
          marginLeft: -6,
          overflow: "hidden",
          objectFit: "cover",
          transform: "scaleX(0.50)",
          flexShrink: 0
        }
      }
    ) : /* @__PURE__ */ jsx(StratosSupportIcon, {}),
    color: colorFunction(100 / maxValue * value, theme)
  }));
  return /* @__PURE__ */ jsx(
    BaseBarCard,
    {
      entries,
      title: "stratosReporting.ZTNAUserGeoLocation.title",
      size,
      preview,
      loading: loading && !countries,
      noData: !loading && !countries
    }
  );
};
export {
  ZTNAUserGeoLocationCard
};
