import {
  BANNER_NOTIFICATION,
  ERROR_NOTIFICATION,
  useCrudProps,
  useDeepCompareEffect,
  useNotifications
} from "@cuda-react/core";
import apiResources from "../apiResources";
var useLicensesBannerNotification_default = () => {
  var _a;
  const [notification] = useNotifications(BANNER_NOTIFICATION, "tesseract.statusBanner.pamBandwidthCapacity", ERROR_NOTIFICATION);
  const bandwidthData = (_a = useCrudProps(apiResources.managedBandwidthsUsage)[0]) == null ? void 0 : _a.data;
  useDeepCompareEffect(() => {
    (bandwidthData == null ? void 0 : bandwidthData.available) < 0 && notification();
  }, [bandwidthData]);
};
export {
  useLicensesBannerNotification_default as default
};
