var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { Button, IconButton, Tooltip } from "@barracuda-internal/bds-core";
import MenuButton from "../MenuButton/MenuButton";
import { createStyles } from "@mui/styles";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useTranslation } from "react-i18next";
import { ConditionalWrapper } from "../../functional";
const styles = (theme) => createStyles({
  buttonIcon: {},
  button: {},
  tooltip: __spreadValues({}, theme.typography.body2)
});
const useStyles = makeOverrideableStyles("DiseableableButton", styles);
const DisableableButton = (props) => {
  const {
    buttonIcon,
    buttonEndIcon,
    buttonText,
    disabledMessage,
    disabled,
    onClick,
    menuItems,
    buttonProps
  } = props;
  const [translate] = useTranslation();
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx(
    ConditionalWrapper,
    {
      condition: disabledMessage && disabled || false,
      wrapper: (children) => /* @__PURE__ */ jsx(
        Tooltip,
        {
          title: disabledMessage ? translate(disabledMessage) : "",
          placement: "top",
          classes: { tooltip: classes.tooltip },
          children: /* @__PURE__ */ jsx("span", { children })
        }
      ),
      children: menuItems && /* @__PURE__ */ jsx(
        MenuButton,
        __spreadValues({
          menuItems,
          label: buttonText || "",
          icon: buttonIcon,
          endIcon: buttonEndIcon,
          disabled
        }, buttonProps)
      ) || (buttonIcon && !buttonText ? /* @__PURE__ */ jsx(
        IconButton,
        __spreadProps(__spreadValues({
          size: "small"
        }, buttonProps), {
          disabled,
          onClick,
          className: classes.buttonIcon,
          children: buttonIcon
        })
      ) : /* @__PURE__ */ jsx(
        Button,
        __spreadProps(__spreadValues({
          variant: "contained",
          color: "primary",
          size: "small"
        }, buttonProps), {
          disabled,
          onClick,
          className: classes.button,
          startIcon: buttonIcon,
          children: translate(buttonText || "")
        })
      ))
    }
  );
};
var DisableableButton_default = DisableableButton;
export {
  DisableableButton_default as default
};
