var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import {
  Card,
  CustomField,
  getDataContent,
  ListCardContent,
  PercentageBarChartField,
  StatusIconField,
  TableCardContent,
  TextField,
  useCrudProps
} from "@cuda-react/core";
import {
  AuditLogIcon,
  ConfigUpdatesIcon,
  FirewallIcon,
  GatewayIcon,
  ProcessorIcon,
  ResourceIcon,
  SdWanIcon,
  SecurityIcon,
  UrlCategoriesIcon
} from "@cuda-react/icons";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../apiResources";
import { get } from "lodash";
const serviceIcons = {
  "SD WAN": SdWanIcon,
  "Routing": GatewayIcon,
  "Firewall": FirewallIcon,
  "Configuration": ConfigUpdatesIcon,
  "IPS": SecurityIcon,
  "Log Analytics": AuditLogIcon,
  "URL Filtering": UrlCategoriesIcon
};
const colorScheme = (state) => (val, data, theme) => {
  let color;
  if (state === "warning") {
    color = theme.palette.warning.main;
  } else if (state === "error") {
    color = theme.palette.error.main;
  } else {
    if (val < 75) {
      color = theme.palette.success.main;
    } else if (val >= 75 && val < 90) {
      color = theme.palette.warning.main;
    } else {
      color = theme.palette.error.main;
    }
  }
  return color;
};
const useStyles = makeStyles((theme) => ({
  resourceErrorRow: {
    height: "auto"
  },
  resourceErrorCell: {
    height: "auto",
    padding: theme.spacing(0, 1)
  },
  resourceErrorField: {
    color: theme.palette.error.main
  },
  serviceIcon: {
    height: "1.5rem",
    width: "1.5rem",
    marginBottom: theme.spacing(-0.8),
    paddingRight: theme.spacing(1),
    position: "relative"
  }
}));
const DetailsCard = ({ type, details }) => {
  var _a, _d;
  const classes = useStyles();
  const [translate] = useTranslation();
  const gatewayType = details == null ? void 0 : details.gatewayType;
  const diskPartitions = getDataContent((_a = useCrudProps(
    details.activeSerial ? gatewayType === "on-prem" ? apiResources.configurationOnPremResources : apiResources.configurationResources : void 0,
    {
      id: details.activeSerial,
      siteId: details.id
    }
  )[0]) == null ? void 0 : _a.data);
  const isSc = get(details, "modelSeries") === "S" || !get(details, "modelSeries");
  return type === "sites" && !isSc || gatewayType === "on-prem" ? /* @__PURE__ */ jsxs(Fragment, { children: [
    (!isSc || gatewayType === "on-prem") && /* @__PURE__ */ jsx(
      Card,
      {
        id: "ApplianceServicesCard",
        title: "tesseract.appliances.dashboard.details.servicesCardTitle",
        children: /* @__PURE__ */ jsx(
          ListCardContent,
          {
            getFrom: {
              resource: details.activeSerial ? gatewayType === "on-prem" ? apiResources.configurationOnPremServices : apiResources.configurationServices : void 0,
              params: { id: details.activeSerial, siteId: details.id }
            },
            children: /* @__PURE__ */ jsx(
              StatusIconField,
              {
                source: "state",
                arraySource: "services",
                textSource: "state",
                textPrefix: "cuda.state.",
                labelIcon: (_b) => {
                  var _c = _b, { data } = _c, props = __objRest(_c, ["data"]);
                  const LabelIcon = serviceIcons[data.name];
                  return LabelIcon ? /* @__PURE__ */ jsx(LabelIcon, __spreadValues({ className: classes.serviceIcon }, props)) : null;
                }
              }
            )
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      Card,
      {
        id: "ApplianceInterfacesCard",
        title: "tesseract.appliances.dashboard.details.interfaces.title",
        children: /* @__PURE__ */ jsxs(
          TableCardContent,
          {
            resource: details.activeSerial ? gatewayType === "on-prem" ? apiResources.configurationOnPremInterfaces : apiResources.configurationInterfaces : void 0,
            pollInterval: 3e5,
            params: { id: details.activeSerial, siteId: details.id },
            formatData: (data) => (data == null ? void 0 : data.interfaces) || [],
            children: [
              /* @__PURE__ */ jsx(
                StatusIconField,
                {
                  label: "tesseract.appliances.dashboard.details.interfaces.status",
                  source: "state",
                  tooltipSource: "state",
                  tooltipPrefix: "tesseract.appliances.dashboard.details.interfaces.state."
                }
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  label: "tesseract.appliances.dashboard.details.interfaces.name",
                  source: "name"
                }
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  label: "tesseract.appliances.dashboard.details.interfaces.speed",
                  source: "speedDescription"
                }
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  label: "tesseract.appliances.dashboard.details.interfaces.duplex",
                  source: "duplex"
                }
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  label: "tesseract.appliances.dashboard.details.interfaces.errors",
                  source: "errors"
                }
              )
            ]
          }
        )
      }
    ),
    /* @__PURE__ */ jsx(
      Card,
      {
        id: "ApplianceResourcesCard",
        title: "tesseract.appliances.dashboard.details.boxResources.title",
        children: /* @__PURE__ */ jsxs(
          ListCardContent,
          {
            getFrom: {
              resource: details.activeSerial ? gatewayType === "on-prem" ? apiResources.configurationOnPremResources : apiResources.configurationResources : void 0,
              options: { pollInterval: 6e4 },
              params: { id: details.activeSerial, siteId: details.id }
            },
            children: [
              /* @__PURE__ */ jsx(
                PercentageBarChartField,
                {
                  label: "tesseract.appliances.dashboard.details.boxResources.cpuLoad",
                  source: "cpuLoad.minute1",
                  tooltipSource: "cpuLoad.description",
                  icon: /* @__PURE__ */ jsx(ProcessorIcon, {})
                }
              ),
              /* @__PURE__ */ jsx(
                PercentageBarChartField,
                {
                  label: "tesseract.appliances.dashboard.details.boxResources.memory",
                  source: "memory.usage",
                  tooltipSource: "memory.description",
                  icon: /* @__PURE__ */ jsx(ResourceIcon, {})
                }
              ),
              /* @__PURE__ */ jsx(
                PercentageBarChartField,
                {
                  label: "tesseract.appliances.dashboard.details.boxResources.diskInformation",
                  source: "totalDiskUsage.usage",
                  tooltipSource: "totalDiskUsage.description",
                  icon: /* @__PURE__ */ jsx(ResourceIcon, {})
                }
              ),
              (_d = diskPartitions == null ? void 0 : diskPartitions.diskPartitionsUsage) == null ? void 0 : _d.map(
                (value, key) => /* @__PURE__ */ jsx(
                  PercentageBarChartField,
                  {
                    label: value.mountpoint,
                    source: `diskPartitionsUsage[${key}].usage`,
                    tooltipSource: `diskPartitionsUsage[${key}].description`,
                    icon: /* @__PURE__ */ jsx(ResourceIcon, {}),
                    customColorScheme: colorScheme(value && value.state)
                  },
                  key
                )
              ),
              /* @__PURE__ */ jsx(
                CustomField,
                {
                  noLabel: true,
                  source: "cpuUsageState",
                  classes: { root: classes.resourceErrorField },
                  cellClassName: classes.resourceErrorCell,
                  rowClassName: classes.resourceErrorRow,
                  render: (value) => value === "error" ? translate("tesseract.appliances.dashboard.details.boxResources.cpuLoadError") : null
                }
              ),
              /* @__PURE__ */ jsx(
                CustomField,
                {
                  noLabel: true,
                  source: "diskUsageState",
                  classes: { root: classes.resourceErrorField },
                  cellClassName: classes.resourceErrorCell,
                  rowClassName: classes.resourceErrorRow,
                  render: (value) => value === "error" ? translate("tesseract.appliances.dashboard.details.boxResources.diskInformationError") : null
                }
              )
            ]
          }
        )
      }
    )
  ] }) : null;
};
var DetailsCard_default = DetailsCard;
export {
  DetailsCard_default as default
};
