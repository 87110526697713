var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { BaseBarCard } from "../../baseCards";
import { bytesFormatter, colorFunctionSuccess } from "../../../utils";
import { AppsIcon } from "../../icons";
import { useReportingData } from "../../../contexts";
import { sortBy } from "lodash";
import { Trans } from "react-i18next";
import { Typography } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
const TopAllowedApplicationsTrafficCard = (props) => {
  const { preview } = props;
  const { data, loading, addFilter } = useReportingData("TopAllowedApplicationsTrafficCard", preview);
  const { applicationDataPoints = [] } = data || {};
  const theme = useTheme();
  const maxValue = Math.max(...applicationDataPoints.map((category) => category.numberOfBytes));
  const entries = sortBy(applicationDataPoints, "numberOfBytes").reverse().map((category) => ({
    title: category.label,
    value: bytesFormatter(category.numberOfBytes),
    barProgress: 100 / maxValue * category.numberOfBytes,
    color: colorFunctionSuccess(100 / maxValue * category.numberOfBytes, theme),
    onClick: category.label && addFilter ? () => addFilter({ key: category.id, name: category.label }, "applicationDataPoints") : void 0,
    tooltipMessage: category.label && addFilter ? "stratosReporting.filterTooltip" : void 0
  }));
  return /* @__PURE__ */ jsx(
    BaseBarCard,
    __spreadProps(__spreadValues({}, props), {
      entries,
      title: /* @__PURE__ */ jsx(Trans, { i18nKey: "stratosReporting.allowedApplicationsTraffic.title", children: /* @__PURE__ */ jsx(Typography, { variant: "body1", component: "span" }) }),
      rowIcon: /* @__PURE__ */ jsx(AppsIcon, { size: 20 }),
      loading: loading && !data.applicationDataPoints,
      noData: !loading && !data.applicationDataPoints,
      activeFilter: true
    })
  );
};
export {
  TopAllowedApplicationsTrafficCard
};
