var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { FormSection, SelectedChoiceField, SelectInput, TableInput, TextField, TextInput } from "@cuda-react/core";
import { get, merge, set } from "lodash";
import { getNetworkId, useSiteInputValidations } from "../../../../hooks/createEditSiteHooks";
const getReservationsFormProps = ({
  reservableLans,
  reservationFormValidation,
  inputValidations
}) => ({
  formValidate: reservationFormValidation,
  formatInput: (lans) => lans && lans.flatMap(
    (lan) => (get(lan, "dhcp.reservations") || []).map((reservation) => __spreadProps(__spreadValues({}, reservation), {
      networkId: getNetworkId(lan)
    }))
  ) || [],
  formatOutput: (reservations, lans) => lans.map(
    (lan) => set(
      merge({}, lan),
      "dhcp.reservations",
      reservations.filter((reservation) => reservation.networkId === getNetworkId(lan)).map((reservation) => __spreadProps(__spreadValues({}, reservation), {
        mac: reservation.mac && reservation.mac.replace(/-/g, ":")
      }))
    )
  ),
  formatError: (error, lans) => Array.isArray(error) ? lans.flatMap(
    (lan, index) => {
      const lanError = get(error, `[${index}]dhcp.reservations`) || [];
      const reservations = (get(lan, "dhcp.reservations") || []).map(() => void 0);
      if (Array.isArray(lanError)) {
        return [...lanError, ...reservations.slice(lanError.length)];
      }
      return reservations.map(() => lanError);
    }
  ) : error,
  formChildren: [
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "name",
        label: "tesseract.appliances.settings.dhcp.reservations.name",
        description: "tesseract.appliances.settings.dhcp.descriptions.reservations.name",
        isRequired: true
      },
      "name"
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "description",
        label: "tesseract.appliances.settings.dhcp.reservations.description",
        description: "tesseract.appliances.settings.dhcp.descriptions.reservations.description"
      },
      "description"
    ),
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: "networkId",
        label: "tesseract.appliances.settings.dhcp.reservations.port",
        choices: reservableLans,
        optionValue: "networkId",
        description: "tesseract.appliances.settings.dhcp.descriptions.reservations.port",
        isRequired: true
      },
      "networkId"
    ),
    /* @__PURE__ */ jsxs(
      FormSection,
      {
        title: "tesseract.appliances.settings.dhcp.reservations.reservationSectionTitle",
        children: [
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "ipAddress",
              label: "tesseract.appliances.settings.dhcp.reservations.ip",
              description: "tesseract.appliances.settings.dhcp.descriptions.reservations.ip",
              validate: inputValidations.validateIp,
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "mac",
              label: "tesseract.appliances.settings.dhcp.reservations.mac",
              description: "tesseract.appliances.settings.dhcp.descriptions.reservations.mac",
              validate: inputValidations.validateMac,
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "clientId",
              label: "tesseract.appliances.settings.dhcp.reservations.clientId",
              description: "tesseract.appliances.settings.dhcp.descriptions.reservations.clientId"
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "hostname",
              label: "tesseract.appliances.settings.dhcp.reservations.hostname",
              description: "tesseract.appliances.settings.dhcp.descriptions.reservations.hostname",
              validate: inputValidations.validateHostname
            }
          )
        ]
      },
      "reservations"
    )
  ]
  // Rest of the object properties remain unchanged
  // ...
});
const DhcpReservationsTableInput = (_a) => {
  var _b = _a, {
    activeLans,
    reservableLans,
    reservationFormValidation
  } = _b, tableInputProps = __objRest(_b, [
    "activeLans",
    "reservableLans",
    "reservationFormValidation"
  ]);
  const inputValidations = useSiteInputValidations();
  return /* @__PURE__ */ jsxs(
    TableInput,
    __spreadProps(__spreadValues(__spreadValues({
      source: "lans",
      id: "dhcp-reservations",
      optionValue: "name",
      labels: {
        addTitle: "tesseract.appliances.settings.dhcp.addReservationTitle",
        addButtonText: "tesseract.appliances.settings.dhcp.addReservationButtonText",
        editTitle: "tesseract.appliances.settings.dhcp.editReservationTitle",
        deleteTitle: "tesseract.appliances.settings.dhcp.deleteReservationTitle",
        deleteMessage: "tesseract.appliances.settings.dhcp.deleteReservationMessage"
      }
    }, getReservationsFormProps({ reservableLans, reservationFormValidation, inputValidations })), tableInputProps), {
      addButtonProps: {
        disabled: reservableLans.length < 1
      },
      editButtonProps: {
        disabled: reservableLans.length < 1
      },
      minCellWidth: 164,
      children: [
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.appliances.settings.dhcp.reservations.name"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.appliances.settings.dhcp.reservations.description"
          }
        ),
        /* @__PURE__ */ jsx(
          SelectedChoiceField,
          {
            source: "networkId",
            label: "tesseract.appliances.settings.dhcp.reservations.port",
            choices: activeLans,
            optionValue: "networkId"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "ipAddress",
            label: "tesseract.appliances.settings.dhcp.reservations.ip"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "mac",
            label: "tesseract.appliances.settings.dhcp.reservations.mac"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "hostname",
            label: "tesseract.appliances.settings.dhcp.reservations.hostname"
          }
        )
      ]
    })
  );
};
var DhcpReservationsTableInput_default = DhcpReservationsTableInput;
export {
  DhcpReservationsTableInput_default as default,
  getReservationsFormProps
};
