import { get } from "lodash";
const getAzureResourceLink = (key, demoMode) => (data) => {
  if (demoMode) {
    return "https://portal.azure.com";
  }
  const resourceId = get(data, key);
  return resourceId ? "https://portal.azure.com/#@/resource" + resourceId : "";
};
const getAzureHubLink = (key, demoMode) => (data) => {
  if (demoMode) {
    return "https://portal.azure.com";
  }
  const resourceId = get(data, key);
  return resourceId ? "https://portal.azure.com/#blade/Microsoft_Azure_HybridNetworking/VirtualHubManagementViewModel/overview/virtualHubId/" + encodeURIComponent(resourceId) : "";
};
const getAzureResourceLinkFromResourceID = (resourceId, demoMode) => {
  if (demoMode) {
    return "https://portal.azure.com";
  }
  return "https://portal.azure.com/#@/resource" + resourceId;
};
export {
  getAzureHubLink,
  getAzureResourceLink,
  getAzureResourceLinkFromResourceID
};
