var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import {
  ConnectedForm,
  DialogBody,
  FormSection,
  getArrayDataContent,
  HiddenInput,
  SelectArrayInput,
  SelectInput,
  TextArrayInput,
  TextInput,
  useCrudProps,
  useGlobalParam,
  useMixpanel,
  validateMaxLengthMemo,
  validateRegexMemo
} from "@cuda-react/core";
import { useTranslation } from "react-i18next";
import { categoryIcons, fallbackIcons, priorityIcons } from "../../../../components/iconMapping";
import apiResources from "../../../../apiResources";
const ExplicitPolicyContent = (_a) => {
  var _b = _a, { title, create, id, onSuccess } = _b, props = __objRest(_b, ["title", "create", "id", "onSuccess"]);
  var _a2, _b2, _c, _d;
  const registerAction = useMixpanel("SDWAN Explicit", false);
  const priorityData = (_a2 = useCrudProps(apiResources.priority, {}, { cache: true })[0]) == null ? void 0 : _a2.data;
  const actionData = (_b2 = useCrudProps(apiResources.action, {}, { cache: true })[0]) == null ? void 0 : _b2.data;
  const fallbackData = (_c = useCrudProps(apiResources.fallback, {}, { cache: true })[0]) == null ? void 0 : _c.data;
  const loadBalancingData = (_d = useCrudProps(apiResources.loadBalancing, {}, { cache: true })[0]) == null ? void 0 : _d.data;
  const forwardErrorCorrectionData = [
    { "key": "true", "name": "tesseract.policies.dialogs.explicit.action.forwardErrorCorrection.options.on" },
    { "key": "false", "name": "tesseract.policies.dialogs.explicit.action.forwardErrorCorrection.options.off" }
  ];
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx(
    DialogBody,
    __spreadProps(__spreadValues({}, props), {
      form: true,
      title,
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          create,
          flat: true,
          resource: apiResources.sdwanExplicitPolicies,
          id,
          form: "explicit-policies",
          onSubmitSuccess: (response) => {
            var _a3, _b3;
            registerAction(create ? "Create" : "Update", {
              applications: (_a3 = response.applications) == null ? void 0 : _a3.length,
              sources: (_b3 = response.sources) == null ? void 0 : _b3.length
            });
            onSuccess == null ? void 0 : onSuccess();
            props.onClose();
          },
          onCancel: props.onClose,
          validate: (values) => {
            if ((!values.sources || values.sources.length === 0) && (!values.applications || values.applications.length === 0)) {
              return {
                applications: translate("tesseract.policies.dialogs.explicit.validation.required"),
                sources: translate("tesseract.policies.dialogs.explicit.validation.required")
              };
            }
            return {};
          },
          formatRequestData: create ? (data) => __spreadProps(__spreadValues({}, data), {
            virtualWanId
          }) : void 0,
          formatResourceData: (data) => __spreadProps(__spreadValues({}, data), {
            forwardErrorCorrection: data.forwardErrorCorrection ? "true" : "false"
          }),
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.policies.dialogs.explicit.general.sectionTitle", children: [
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "name",
                  label: "tesseract.policies.dialogs.explicit.general.name",
                  description: "tesseract.policies.dialogs.explicit.general.descriptions.name",
                  validate: [validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")],
                  isRequired: true
                }
              ),
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "description",
                  label: "tesseract.policies.dialogs.explicit.general.description",
                  description: "tesseract.policies.dialogs.explicit.general.descriptions.description",
                  validate: [validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]
                }
              )
            ] }),
            /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.policies.dialogs.explicit.criteria.sectionTitle", children: [
              /* @__PURE__ */ jsx(
                SelectArrayInput,
                {
                  source: "applications",
                  label: "tesseract.policies.dialogs.explicit.criteria.applications",
                  description: "tesseract.policies.dialogs.explicit.criteria.descriptions.applications",
                  resource: apiResources.applications,
                  filterKey: "applicationId",
                  iconMap: categoryIcons,
                  optionIcon: "categoryId",
                  optionValue: "id",
                  options: { dropdownAutoWidth: true }
                }
              ),
              /* @__PURE__ */ jsx(
                TextArrayInput,
                {
                  source: "sources",
                  label: "tesseract.policies.dialogs.explicit.criteria.sources",
                  description: "tesseract.policies.dialogs.explicit.criteria.descriptions.sources"
                }
              ),
              /* @__PURE__ */ jsx(
                HiddenInput,
                {
                  source: "trafficType",
                  hiddenValue: "auto"
                }
              )
            ] }),
            /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.policies.dialogs.explicit.action.sectionTitle", children: [
              /* @__PURE__ */ jsx(
                SelectInput,
                {
                  source: "priority",
                  label: "tesseract.policies.dialogs.explicit.action.priority",
                  description: "tesseract.policies.dialogs.explicit.action.descriptions.priority",
                  choices: getArrayDataContent(priorityData),
                  iconMap: priorityIcons,
                  isRequired: true
                }
              ),
              /* @__PURE__ */ jsx(
                SelectInput,
                {
                  source: "action",
                  label: "tesseract.policies.dialogs.explicit.action.action",
                  description: "tesseract.policies.dialogs.explicit.action.descriptions.action",
                  choices: getArrayDataContent(actionData),
                  isRequired: true
                }
              ),
              /* @__PURE__ */ jsx(
                SelectInput,
                {
                  source: "fallback",
                  label: "tesseract.policies.dialogs.explicit.action.fallback",
                  description: "tesseract.policies.dialogs.explicit.action.descriptions.fallback",
                  choices: getArrayDataContent(fallbackData),
                  iconMap: fallbackIcons,
                  isRequired: true
                }
              ),
              /* @__PURE__ */ jsx(
                SelectInput,
                {
                  source: "loadBalancing",
                  label: "tesseract.policies.dialogs.explicit.action.loadBalancing",
                  description: "tesseract.policies.dialogs.explicit.action.descriptions.loadBalancing",
                  choices: getArrayDataContent(loadBalancingData),
                  isRequired: true
                }
              ),
              /* @__PURE__ */ jsx(
                SelectInput,
                {
                  source: "forwardErrorCorrection",
                  defaultChoice: "false",
                  label: "tesseract.policies.dialogs.explicit.action.forwardErrorCorrection.label",
                  description: "tesseract.policies.dialogs.explicit.action.descriptions.forwardErrorCorrection",
                  choices: forwardErrorCorrectionData,
                  isRequired: true
                }
              )
            ] })
          ]
        }
      )
    })
  );
};
ExplicitPolicyContent.defaultProps = {
  onClose: () => {
  }
};
var ExplicitPolicyContent_default = ExplicitPolicyContent;
export {
  ExplicitPolicyContent,
  ExplicitPolicyContent_default as default
};
