import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const VpnIcon = ({ className }) => {
  var _a, _b, _c, _d;
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-vpn",
      viewBox: "0 0 60 60",
      children: [
        /* @__PURE__ */ jsxs("g", { children: [
          /* @__PURE__ */ jsx(
            "path",
            {
              style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0, fillRule: "evenodd" },
              d: "M21.4,40.8c0-4.7-.3-9.3.1-13.8.4-4.1,4.4-6.8,8.6-6.8,4.2,0,8,3,8.4,7.1.4,4.4,0,9,0,13.5h-17.2Z"
            }
          ),
          /* @__PURE__ */ jsx(
            "path",
            {
              style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 },
              d: "M11.1,52.5h-4.9v-25.6c.6-12.8,11-22.8,23.7-22.8s23.8,10.7,23.8,23.8v24.3h-4.9v-24.3c0-10.4-8.5-18.9-18.9-18.9s-18.4,7.9-18.9,18v25.5Z"
            }
          )
        ] }),
        /* @__PURE__ */ jsxs("g", { children: [
          /* @__PURE__ */ jsx("polygon", { style: { fill: (_c = theme.palette.icons) == null ? void 0 : _c.graphics, strokeWidth: 0 }, points: "32.6 48.3 31.6 44 28.3 44 27.4 48.3 32.6 48.3" }),
          /* @__PURE__ */ jsx("polygon", { style: { fill: (_d = theme.palette.icons) == null ? void 0 : _d.graphics, strokeWidth: 0 }, points: "27 51.6 26.1 55.8 34 55.8 33.1 51.6 27 51.6" })
        ] })
      ]
    }
  );
};
var VpnIcon_default = VpnIcon;
export {
  VpnIcon,
  VpnIcon_default as default
};
