import { jsx, jsxs } from "react/jsx-runtime";
import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material";
const IpsIcon = (props) => {
  var _a, _b;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(SvgIcon, { viewBox: "2 2 21 21", style: { width: (_a = props.size) != null ? _a : 50, height: (_b = props.size) != null ? _b : 50 }, children: /* @__PURE__ */ jsxs("g", { transform: "matrix(1,0,0,1,-5.42152,-5.995)", children: [
    /* @__PURE__ */ jsx("rect", { x: "5.422", y: "5.995", width: "24.026", height: "24.026", style: { fill: "none" } }),
    /* @__PURE__ */ jsx("g", { transform: "matrix(1,0,0,1,8.42856,7.96584)", children: /* @__PURE__ */ jsx(
      "path",
      {
        d: "M9.006,0.025L0.796,3.668L0.796,9.132C0.796,14.185 4.299,18.912 9.006,20.059C13.713,18.912 17.216,14.185 17.216,9.132L17.216,3.668L9.006,0.025ZM9.006,10.033L15.392,10.033C14.908,13.785 12.4,17.127 9.006,18.174L9.006,10.042L2.62,10.042L2.62,4.852L9.006,2.02L9.006,10.033Z",
        style: { fill: theme.palette.reporting.fontColor, fillRule: "nonzero" }
      }
    ) })
  ] }) });
};
export {
  IpsIcon
};
