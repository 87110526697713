var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import {
  ActionButtonsField,
  Boolean,
  ChipArrayField,
  CrudTypes,
  DeleteDialog,
  DisableableButton,
  EmptyPageContent,
  getArrayDataContent,
  getDataContent,
  InputLabel,
  TableAction,
  TablePage,
  TextField,
  useCrudFetch,
  useCrudProps,
  useCrudSubscription,
  useMixpanel,
  useTableChoices
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { makeStyles } from "@mui/styles";
import apiResources from "../../../../apiResources";
import CreateEditButtonDialog from "../../../../components/CreateEditButtonDialog";
import CreatePolicy from "./createEdit/CreatePolicy";
import EditPolicy from "./createEdit/EditPolicy";
import { GetStartedBackgroundGraphic, IpsGraphic } from "@cuda-react/icons";
import { Dialog } from "@barracuda-internal/bds-core";
import { categoryIcons } from "../../../../components/iconMapping";
const useStyles = makeStyles(() => ({
  orderCell: {
    minWidth: 70
  },
  actionCell: {
    minWidth: 70,
    paddingLeft: 0
  },
  lastMileOptimization: {
    display: "inline-flex"
  }
}));
const ZeroTrustAccess = () => {
  var _a, _b;
  const registerAction = useMixpanel("Zero Trust Access");
  const classes = useStyles();
  const tableRefreshRef = useRef();
  const [, , updateOrder] = useCrudFetch(CrudTypes.CREATE, apiResources.zeroTrustPolicies);
  const handleOrderClick = (id, direction, data) => updateOrder({
    id,
    changeOrder: direction,
    data
  }).then(tableRefreshRef.current);
  const securityPostureChoices = (_a = useCrudProps(apiResources.zeroTrustPoliciesSecurityPosture)[0]) == null ? void 0 : _a.data;
  const [renderApplication, getApplicationIconId] = useTableChoices(apiResources.applications, {
    optionValue: "id",
    filterId: "applicationId"
  }, "categoryId");
  const renderGroups = useTableChoices(apiResources.groups, { optionValue: "id", filterId: "id" });
  const renderUsers = useTableChoices(apiResources.users, { optionValue: "id", filterId: "id" });
  const [settingsData, settingsLoading, refreshSettings] = useCrudSubscription(CrudTypes.GET, apiResources.zeroTrustSettings);
  const [, settingsUpdating, updateSettings] = useCrudFetch(CrudTypes.UPDATE, apiResources.zeroTrustSettings);
  const stringFecLevel = (_b = getDataContent(settingsData)) == null ? void 0 : _b.fecLevel;
  const fecLevel = stringFecLevel === "medium";
  const loadingSettings = typeof stringFecLevel !== "string" || settingsLoading || settingsUpdating;
  const handleUpdateFecLevel = (newValue) => {
    if (!loadingSettings && newValue !== fecLevel) {
      registerAction("Last Mile Optimization Update", { fecLevel: newValue ? "medium" : "off" });
      return updateSettings({
        data: __spreadProps(__spreadValues({}, getDataContent(settingsData)), {
          fecLevel: newValue ? "medium" : "off"
        })
      }).then(refreshSettings);
    }
  };
  const [actionVisible, setActionVisible] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      TablePage,
      {
        title: "tesseract.securityAndAccess.pageTitle",
        subtitle: ["tesseract.endpoint.pageTitle", "tesseract.endpoint.remoteAccessPolicies.zeroTrustAccess"],
        resource: apiResources.zeroTrustPolicies,
        minCellWidth: 128,
        compact: true,
        refreshRef: tableRefreshRef,
        exportResource: { resource: apiResources.zeroTrustPoliciesExport, filename: "zeroTrustPolicies" },
        titleActions: actionVisible && /* @__PURE__ */ jsx(
          DisableableButton,
          {
            buttonText: "tesseract.endpoint.remoteAccessPolicies.createButton",
            onClick: () => setCreateOpen(true)
          },
          "create"
        ),
        actions: /* @__PURE__ */ jsx(TableAction, { left: true, children: /* @__PURE__ */ jsx("div", { className: classes.lastMileOptimization, children: /* @__PURE__ */ jsx(InputLabel, { label: "tesseract.endpoint.remoteAccessPolicies.lastMileOptimization", minimised: true, children: /* @__PURE__ */ jsx(
          Boolean,
          {
            id: "lastMileOptimization",
            value: fecLevel,
            onChange: handleUpdateFecLevel,
            options: { disabled: !!loadingSettings }
          }
        ) }) }) }, "lastMileOptimization"),
        noDataPage: /* @__PURE__ */ jsx(
          EmptyPageContent,
          {
            background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
            icon: /* @__PURE__ */ jsx(IpsGraphic, {}),
            iconHeader: "tesseract.endpoint.remoteAccessPolicies.noData.iconHeader",
            text: "tesseract.endpoint.remoteAccessPolicies.noData.text",
            actions: /* @__PURE__ */ jsx(
              DisableableButton,
              {
                buttonText: "tesseract.endpoint.remoteAccessPolicies.noData.labelButton",
                onClick: () => setCreateOpen(true)
              },
              "create"
            )
          }
        ),
        onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table"),
        children: [
          /* @__PURE__ */ jsxs(
            ActionButtonsField,
            {
              left: true,
              label: "tesseract.endpoint.remoteAccessPolicies.order",
              cellClassName: classes.orderCell,
              width: 96,
              source: "id",
              children: [
                /* @__PURE__ */ jsx(
                  TextField,
                  {
                    source: "order"
                  }
                ),
                /* @__PURE__ */ jsx(
                  ArrowUpwardIcon,
                  {
                    onClick: (event, data) => handleOrderClick(data.id, "increase", data),
                    disabled: (data) => data.index <= 0,
                    id: "cuda-icon-up"
                  }
                ),
                /* @__PURE__ */ jsx(
                  ArrowDownwardIcon,
                  {
                    onClick: (event, data) => handleOrderClick(data.id, "decrease", data),
                    disabled: (data) => data.index >= data.total - 1,
                    id: "cuda-icon-down"
                  }
                )
              ]
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "name",
              label: "tesseract.endpoint.remoteAccessPolicies.name",
              filter: "text"
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "description",
              label: "tesseract.endpoint.remoteAccessPolicies.description",
              filter: "text"
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "accessCriteria.users",
              label: "tesseract.endpoint.remoteAccessPolicies.users",
              filter: "selectarray",
              filterProps: {
                resource: apiResources.users,
                optionValue: "id"
              },
              render: renderUsers,
              columnProps: {
                field: "user"
              }
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "accessCriteria.groups",
              label: "tesseract.endpoint.remoteAccessPolicies.groups",
              filter: "selectarray",
              filterProps: {
                resource: apiResources.groups,
                optionValue: "id"
              },
              render: renderGroups,
              columnProps: {
                field: "group"
              }
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "resourceCriteria.applications",
              label: "tesseract.endpoint.remoteAccessPolicies.resources",
              filter: "selectarray",
              filterProps: {
                resource: apiResources.applications,
                optionValue: "id",
                optionIcon: "categoryId",
                iconMap: categoryIcons
              },
              render: renderApplication,
              iconMap: categoryIcons,
              optionIcon: getApplicationIconId,
              columnProps: {
                field: "application"
              }
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "accessCriteria.securityPosture",
              label: "tesseract.endpoint.remoteAccessPolicies.devicePosture",
              filter: "selectarray",
              choices: getArrayDataContent(securityPostureChoices),
              filterProps: {
                id: "security-posture",
                choices: getArrayDataContent(securityPostureChoices)
              },
              columnProps: {
                field: "securityPosture"
              }
            }
          ),
          /* @__PURE__ */ jsxs(
            ActionButtonsField,
            {
              width: 80,
              source: "id",
              children: [
                /* @__PURE__ */ jsx(
                  CreateEditButtonDialog,
                  {
                    component: EditPolicy,
                    onSuccess: () => {
                      var _a2;
                      registerAction("Update");
                      (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                    }
                  }
                ),
                /* @__PURE__ */ jsx(
                  DeleteDialog,
                  {
                    resource: apiResources.zeroTrustPolicies,
                    title: "tesseract.endpoint.remoteAccessPolicies.delete.title",
                    message: "tesseract.endpoint.remoteAccessPolicies.delete.body",
                    onSuccess: () => {
                      var _a2;
                      registerAction("Delete");
                      (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                    }
                  }
                )
              ]
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Dialog,
      {
        open: createOpen,
        maxWidth: false,
        disableEnforceFocus: true,
        onClose: () => setCreateOpen(false),
        children: /* @__PURE__ */ jsx(
          CreatePolicy,
          {
            onSuccess: () => {
              var _a2;
              registerAction("Create");
              (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
            },
            onClose: () => setCreateOpen(false)
          }
        )
      }
    )
  ] });
};
var ZeroTrustAccess_default = ZeroTrustAccess;
export {
  ZeroTrustAccess_default as default,
  useStyles
};
