var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  ActionButtonsField,
  Card,
  ConnectedTable,
  CrudTypes,
  CustomField,
  DeleteDialog,
  OK_NOTIFICATION,
  TOAST_NOTIFICATION,
  useCrudFetch,
  useGlobalParam,
  useMixpanel,
  useNotifications
} from "@cuda-react/core";
import { useLocation } from "react-router";
import { parse } from "query-string";
import { IdentityProviderMenu } from "./components/IdentityProviderMenu";
import apiResources from "../../../../apiResources";
import CreateEditButtonDialog from "../../../../components/CreateEditButtonDialog";
import { Dialog } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { IdentityProviderForm } from "./components/IdentityProviderForm/IdentityProviderForm";
import { IDPAuthorizationStatusField } from "./components/IDPAuthorizationStatusField";
import { EditIdentityProviderWrapper } from "./components/EditIdentityProviderWrapper";
import { IdentityProvidersNoData } from "./components/IdentityProvidersNoData";
import { styled } from "@mui/styles";
import useDemoApi from "../../../../hooks/useDemoApi";
import { AddIdentityProviderButton } from "./components/AddIdentityProviderButton";
const MinHeightCard = styled(Card)({
  height: "calc(50vh - 90px)"
});
const i18nScope = "tesseract.identity.identityProviders";
const initialValue = {
  displayName: "",
  type: "email",
  accountName: ""
};
const initialOptions = {
  oidc: {
    discoveryEndpoint: "",
    clientId: "",
    clientSecret: "",
    scope: ""
  },
  entraid: {
    tenantId: ""
  },
  email: {},
  bcc: {},
  saml: {
    signInEndpoint: "",
    signingCert: ""
  },
  google: {}
};
const IdentityProviders = () => {
  const identityApi = useDemoApi(apiResources.identityProvider, apiResources.demoIdentityProviders);
  const registerAction = useMixpanel("Identity Providers");
  const tableRefreshRef = useRef(null);
  const addButtonRefreshRef = useRef(null);
  const location = useLocation();
  const [showConsentToast] = useNotifications(
    TOAST_NOTIFICATION,
    "tesseract.identityProviders.addNotification",
    OK_NOTIFICATION
  );
  const [translate] = useTranslation();
  const [account] = useGlobalParam("pageTitle.account");
  const accountName = useMemo(() => {
    var _a;
    return (_a = account == null ? void 0 : account.join) == null ? void 0 : _a.call(account, " - ");
  }, [account]);
  const [, , performCreate] = useCrudFetch(CrudTypes.CREATE, apiResources.identityProvider);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [initialFormValue, setInitialFormValue] = useState(__spreadProps(__spreadValues({}, initialValue), {
    type: "bcc",
    options: {}
  }));
  const [isCreating, setIsCreating] = useState(false);
  useEffect(() => {
    const queryString = parse(location.search);
    if ((queryString == null ? void 0 : queryString.consent) === "true") {
      showConsentToast();
    }
  }, []);
  const handleCreateClose = (wasCreated) => {
    var _a, _b;
    if (wasCreated) {
      registerAction("created");
      (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
      (_b = addButtonRefreshRef.current) == null ? void 0 : _b.call(addButtonRefreshRef);
    }
    setDialogOpen(false);
  };
  const handleIDPCreation = useCallback((type) => () => __async(void 0, null, function* () {
    var _a, _b;
    const newIdentityProvider = __spreadProps(__spreadValues({}, initialValue), {
      accountName,
      type,
      options: __spreadValues({}, initialOptions[type])
    });
    if (type === "bcc" || type === "email") {
      let displayName = {
        "bcc": "Barracuda Cloud Control",
        "email": "Email"
      }[type];
      setIsCreating(true);
      yield performCreate({
        data: __spreadProps(__spreadValues({}, newIdentityProvider), {
          displayName
        })
      });
      (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
      (_b = addButtonRefreshRef.current) == null ? void 0 : _b.call(addButtonRefreshRef);
      return;
    }
    setInitialFormValue(newIdentityProvider);
    setDialogOpen(true);
  }), [initialValue, setInitialFormValue, setDialogOpen, performCreate, tableRefreshRef, accountName, setIsCreating]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(MinHeightCard, { id: "identity-providers", title: `${i18nScope}.title`, children: /* @__PURE__ */ jsxs(
      ConnectedTable,
      {
        hasTitle: true,
        refreshRef: tableRefreshRef,
        resource: identityApi,
        pollInterval: 3e5,
        noDataPage: /* @__PURE__ */ jsx(IdentityProvidersNoData, { onSelect: handleIDPCreation, showLoading: isCreating }),
        actions: [
          /* @__PURE__ */ jsx(
            AddIdentityProviderButton,
            {
              onSelect: handleIDPCreation
            },
            0
          )
        ],
        children: [
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "name",
              label: `${i18nScope}.table.name`,
              render: (value, data) => (data == null ? void 0 : data.displayName) || (data == null ? void 0 : data.name)
            }
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "type",
              label: `${i18nScope}.table.type`,
              render: (value, data) => translate(`${i18nScope}.add.types.${data.type}`)
            }
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "status",
              label: `${i18nScope}.table.status`,
              render: (status, identityProvider) => /* @__PURE__ */ jsx(IDPAuthorizationStatusField, { status, id: identityProvider.id })
            }
          ),
          /* @__PURE__ */ jsxs(ActionButtonsField, { source: "id", alwaysVisible: true, children: [
            /* @__PURE__ */ jsx(IdentityProviderMenu, {}),
            /* @__PURE__ */ jsx(
              CreateEditButtonDialog,
              {
                create: false,
                component: EditIdentityProviderWrapper,
                onSuccess: () => {
                  var _a, _b;
                  (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                  (_b = addButtonRefreshRef.current) == null ? void 0 : _b.call(addButtonRefreshRef);
                }
              }
            ),
            /* @__PURE__ */ jsx(
              DeleteDialog,
              {
                resource: apiResources.identityProvider,
                title: `${i18nScope}.delete.title`,
                message: `${i18nScope}.delete.body`,
                onSuccess: () => {
                  var _a, _b;
                  setIsCreating(false);
                  registerAction("deleted");
                  (_a = tableRefreshRef == null ? void 0 : tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                  (_b = addButtonRefreshRef == null ? void 0 : addButtonRefreshRef.current) == null ? void 0 : _b.call(addButtonRefreshRef);
                }
              }
            )
          ] })
        ]
      }
    ) }),
    /* @__PURE__ */ jsx(
      Dialog,
      {
        open: dialogOpen,
        onClose: handleCreateClose,
        maxWidth: false,
        disableEnforceFocus: true,
        children: /* @__PURE__ */ jsx(
          IdentityProviderForm,
          {
            create: true,
            identityProvider: initialFormValue,
            onClose: handleCreateClose
          }
        )
      }
    )
  ] });
};
export {
  IdentityProviders
};
