import { jsx, jsxs } from "react/jsx-runtime";
import { Table, TableBody, TableCell, TableRow, useTheme } from "@mui/material";
import classNames from "classnames";
import { get, merge } from "lodash";
import React from "react";
import LoadingMessage from "../../loading/LoadingMessage/LoadingMessage";
import NoDataMessage from "../../loading/NoDataMessage/NoDataMessage";
import { useCardGetFrom } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { createStyles } from "@mui/styles";
import { makeOverrideableStyles } from "@cuda-react/theme";
const styles = (theme) => createStyles({
  tableRow: {
    height: 34,
    overflow: "visible"
  },
  tableCells: {
    fontSize: 13,
    border: "none",
    height: 16,
    lineHeight: "15px",
    padding: theme.spacing(0.5, 1),
    overflow: "visible",
    whiteSpace: "normal"
  },
  tableLabelCell: {
    paddingLeft: theme.spacing(4),
    width: 120
  },
  tableLabelIconCell: {
    paddingLeft: theme.spacing(3),
    width: `calc(120px + ${theme.spacing(3)})`
  },
  table: {
    tableLayout: "auto",
    height: "100%",
    overflowY: "visible",
    overflowX: "hidden",
    marginTop: 0,
    marginBottom: theme.spacing(1)
  },
  message: {
    display: "flex",
    justifyContent: "center"
  },
  labelIcon: {
    height: "16px !important",
    marginBottom: -3,
    width: 24,
    padding: theme.spacing(0, 1, 0, 0)
  }
});
const useStyles = makeOverrideableStyles("ListCardContent", styles);
const ListCardContent = (props) => {
  const {
    data,
    noData,
    noDataMessage,
    visible,
    getFrom: getFrom2,
    children,
    skipMissing,
    cellClassName
  } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const theme = useTheme();
  const [getFromData, getFromStatus, error] = useCardGetFrom(getFrom2, props);
  const content = getFromData || data || {};
  const isLoading = !!getFromStatus;
  const childrenArray = React.Children.toArray(children);
  const renderRow = (key, child, data2, label) => {
    const labelIcon = child.props.labelIcon && React.createElement(
      child.props.labelIcon,
      merge(
        { classes: { icon: classes.labelIcon }, data: data2 },
        child.props.iconProps
      )
    );
    const labelText = typeof label === "string" ? translate(label) : label;
    const cellValue = React.cloneElement(child, { data: data2 });
    return /* @__PURE__ */ jsxs(TableRow, { className: classNames(classes.tableRow, child.props.rowClassName), children: [
      !child.props.noLabel ? /* @__PURE__ */ jsxs(
        TableCell,
        {
          className: classNames(classes.tableCells, classes.tableLabelCell, child.props.labelIcon && classes.tableLabelIconCell, cellClassName, child.props.cellClassName),
          style: { color: theme.palette.text.secondary },
          children: [
            labelIcon || null,
            labelText || null
          ]
        }
      ) : null,
      /* @__PURE__ */ jsx(
        TableCell,
        {
          className: classNames(classes.tableCells, cellClassName, child.props.cellClassName),
          colSpan: child.props.noLabel ? 2 : void 0,
          children: cellValue
        }
      )
    ] }, key);
  };
  const tableContent = childrenArray.filter((child) => !!child).map((child) => {
    const childValue = get(content, child.props.arraySource || child.props.source);
    const skipIfMissing = child.props.skipMissing !== void 0 ? child.props.skipMissing : skipMissing;
    const skip = child.props.skip !== void 0 ? child.props.skip(content) : false;
    const childHasValue = Array.isArray(childValue) ? childValue.length > 0 : !!childValue || childValue === false || childValue === 0;
    if (!skip && (!skipIfMissing || childHasValue)) {
      if (child.props.isMap) {
        const dataKeys = Object.keys(childValue);
        return dataKeys.map((item) => {
          const data2 = { [child.props.source]: get(content, child.props.source)[item] };
          return renderRow(
            child.props.source + "-" + item,
            child,
            data2,
            item
          );
        });
      }
      if (child.props.arraySource) {
        const labelSource = child.props.labelSource || "name";
        return childValue.map((value) => value ? renderRow(
          child.props.source + "-" + get(value, labelSource),
          child,
          value,
          child.props.label || get(value, labelSource)
        ) : null);
      }
      return renderRow(
        child.props.source,
        child,
        content,
        child.props.label
      );
    }
  }).filter((row) => !!row);
  const hasData = !noData && Object.keys(tableContent).length > 0;
  if (!visible) {
    return null;
  }
  return /* @__PURE__ */ jsx(React.Fragment, { children: !error && hasData && /* @__PURE__ */ jsx(Table, { className: classes.table, children: /* @__PURE__ */ jsx(TableBody, { children: tableContent }) }) || /* @__PURE__ */ jsxs("div", { className: classes.message, children: [
    isLoading && !error && /* @__PURE__ */ jsx(LoadingMessage, {}),
    error && /* @__PURE__ */ jsx(NoDataMessage, { message: error }),
    !isLoading && !error && /* @__PURE__ */ jsx(NoDataMessage, { message: noDataMessage })
  ] }) });
};
ListCardContent.defaultProps = {
  noDataMessage: "cuda.cards.noListData",
  skipMissing: true,
  visible: true
};
var ListCardContent_default = ListCardContent;
export {
  ListCardContent,
  ListCardContent_default as default,
  styles
};
