import { jsx } from "react/jsx-runtime";
import classNames from "classnames";
import get from "lodash/get";
import { Typography } from "@barracuda-internal/bds-core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  text: {
    lineHeight: "24px",
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14)
  }
});
const useStyles = makeOverrideableStyles("TextField", styles);
const TextField = (props) => {
  var _a;
  const { data, defaultText, className, source = "" } = props;
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx(Typography, { variant: "body2", component: "span", className: classNames(className, classes.text), children: (_a = get(data, source)) != null ? _a : defaultText });
};
var TextField_default = TextField;
export {
  TextField,
  TextField_default as default
};
