import { get } from "lodash";
const isLinkSeries = (series) => ["linked-points-line-series", "linked-points-point-series", "linked-points-source-point-series"].includes(get(series, "userOptions.id"));
const clearLinkedSeries = (chart, setTooltip, redraw = true) => {
  var _a;
  setTooltip(null);
  if ((_a = chart.current) == null ? void 0 : _a.series) {
    chart.current.series.filter(isLinkSeries).forEach((series) => {
      series.update({ type: "mappoint", showInLegend: false, visible: false }, false);
    });
    chart.current.series.filter((series) => get(series, "userOptions.originalData")).forEach((series) => {
      const data = get(series, "userOptions.originalData");
      series.setData(data, false);
    });
    if (redraw) {
      chart.current.redraw();
    }
  }
};
export {
  clearLinkedSeries,
  isLinkSeries
};
