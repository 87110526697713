var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { CrudTypes } from "../../../clients";
import Wizard from "../Wizard/Wizard";
import { useCrudFetch } from "../../../hooks";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
const ConnectedWizard = (props) => {
  const _a = props, { update, children, defaultResource, defaultParams, formatError, formatRequestData, resource, validateResource, submitParams, validateParams, onSubmitSuccess } = _a, wizardProps = __objRest(_a, ["update", "children", "defaultResource", "defaultParams", "formatError", "formatRequestData", "resource", "validateResource", "submitParams", "validateParams", "onSubmitSuccess"]);
  const [, saving, performSubmit] = useCrudFetch(props.update ? CrudTypes.UPDATE : CrudTypes.CREATE, resource, submitParams);
  const [, validating, performValidate] = useCrudFetch(props.update ? CrudTypes.UPDATE : CrudTypes.CREATE, validateResource, validateParams);
  const [defaultData, defaultLoading, performFetch] = useCrudFetch(CrudTypes.GET, defaultResource, defaultParams);
  const initialValues = defaultData && defaultData.data || {};
  useEffect(() => {
    if (defaultResource) {
      performFetch();
    }
  }, []);
  const submitData = (values) => {
    const formattedData = formatRequestData && formatRequestData(values) || values;
    return performSubmit(
      { data: formattedData },
      { formPromise: true }
    ).then((response) => {
      onSubmitSuccess == null ? void 0 : onSubmitSuccess(response == null ? void 0 : response.data);
      return response;
    }).catch((error) => {
      throw formatError ? formatError(error, values, formattedData) : error;
    });
  };
  const validateData = (values, stepProps) => {
    const resourceToUse = stepProps && stepProps.validateResource || validateResource;
    const formatFunction = stepProps && stepProps.formatRequestData || formatRequestData;
    if (!resourceToUse || stepProps && stepProps.noAsyncValidation) {
      return Promise.resolve(values);
    }
    const formattedData = (formatFunction == null ? void 0 : formatFunction(values)) || values;
    return performValidate(
      { data: formattedData },
      { formPromise: true },
      resourceToUse
    ).catch((error) => {
      throw formatError ? formatError(error, values, formattedData) : error;
    });
  };
  return /* @__PURE__ */ jsx(ErrorBoundary, { children: /* @__PURE__ */ jsx(
    Wizard,
    __spreadProps(__spreadValues({
      save: submitData,
      asyncValidate: validateData,
      toolbarDisabled: validating || saving || defaultLoading,
      disabled: validating || saving || defaultLoading,
      initialValues
    }, wizardProps), {
      children
    })
  ) });
};
var ConnectedWizard_default = ConnectedWizard;
export {
  ConnectedWizard,
  ConnectedWizard_default as default
};
