var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import apiResources from "../../../apiResources";
import {
  BooleanInput,
  ConnectedFormPage,
  DownloadButton,
  FormSection,
  getArrayDataContent,
  PropGateway,
  SelectInput,
  TextInput,
  useCrudProps,
  useGlobalParam,
  useMixpanel,
  usePreview,
  validateInt,
  validateMaxValueMemo,
  validatePrivateIpv4CIDR
} from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { get, isString } from "lodash";
const useStyles = makeStyles((theme) => ({
  helpText: {
    padding: theme.spacing(0, 2, 2, 4)
  },
  downloadButton: {
    margin: theme.spacing(0, 2, 2, 4)
  },
  deviceLimitSelect: {
    width: 96
  },
  deviceLimitInfo: {
    marginLeft: 344,
    top: -30,
    position: "relative"
  }
}));
const webFilterChoices = [
  { name: "tesseract.endpoint.zeroTrustSettings.enforce", key: true },
  { name: "tesseract.endpoint.zeroTrustSettings.disable", key: false }
];
const getNetworkMask = (network) => isString(network) && parseInt(network.substring(network.indexOf("/") + 1));
const getMask = (value) => value && parseInt(value) > 0 && parseInt(value) < 32 ? parseInt(value) : void 0;
const ZeroTrustSettings = () => {
  const registerAction = useMixpanel("Endpoint Zero Trust Settings");
  const [translate] = useTranslation();
  const isCgfRegistered = getArrayDataContent(useCrudProps(apiResources.firewall)[0]).length > 0;
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const [{ networkSize = 0, networkPoolSize, clientNetworkSize }, setFormValues] = useState({});
  const validateMinPoolSize = useCallback((value) => value && parseInt(value) <= networkSize ? translate("tesseract.endpoint.zeroTrustSettings.validatePoolSize") : void 0, [networkSize]);
  const classes = useStyles();
  const tpmEnforcement = usePreview("tpm");
  return /* @__PURE__ */ jsxs(
    ConnectedFormPage,
    {
      resource: apiResources.zeroTrustSettings,
      title: "tesseract.endpoint.pageTitle",
      subtitle: "tesseract.endpoint.zeroTrustSettingsTitle",
      onSubmitSuccess: () => {
        registerAction("Update");
      },
      formatResourceData: (data) => {
        var _a, _b;
        return __spreadProps(__spreadValues({}, data), {
          dnsSuffix: (data == null ? void 0 : data.dnsSuffix) ? data == null ? void 0 : data.dnsSuffix.toString() : "",
          fecLevel: (data == null ? void 0 : data.fecLevel) === "medium",
          manualConfigurationEnabled: !!(data == null ? void 0 : data.clientNetworkConfiguration),
          clientNetworkConfiguration: (data == null ? void 0 : data.clientNetworkConfiguration) ? __spreadProps(__spreadValues({}, data == null ? void 0 : data.clientNetworkConfiguration), {
            poolSize: (_b = (_a = data == null ? void 0 : data.clientNetworkConfiguration) == null ? void 0 : _a.poolSize) == null ? void 0 : _b.toString()
          }) : { range: "", poolSize: "" }
        });
      },
      formatRequestData: (data) => __spreadProps(__spreadValues({}, data), {
        fecLevel: (data == null ? void 0 : data.fecLevel) ? "medium" : "off",
        clientNetworkConfiguration: (data == null ? void 0 : data.manualConfigurationEnabled) ? data.clientNetworkConfiguration : null,
        virtualWanId
      }),
      onChange: (newValues) => {
        var _a, _b;
        const networkSize2 = getMask(getNetworkMask((_a = newValues == null ? void 0 : newValues.clientNetworkConfiguration) == null ? void 0 : _a.range));
        const networkPoolSize2 = getMask((_b = newValues == null ? void 0 : newValues.clientNetworkConfiguration) == null ? void 0 : _b.poolSize);
        const clientNetworkSize2 = Math.pow(2, 32 - (networkPoolSize2 || 0)) - 2;
        setFormValues({
          networkSize: networkSize2,
          networkPoolSize: networkPoolSize2,
          clientNetworkSize: clientNetworkSize2
        });
      },
      children: [
        /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.endpoint.zeroTrustSettings.globalZtnaSettings", children: [
          /* @__PURE__ */ jsx(
            BooleanInput,
            {
              source: "fecLevel",
              label: "tesseract.endpoint.zeroTrustSettings.fecLevel",
              hideInput: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "dnsSuffix",
              label: "tesseract.endpoint.zeroTrustSettings.dnsSuffix",
              additionalInfoLabel: "tesseract.endpoint.zeroTrustSettings.descriptions.dnsSuffix"
            }
          ),
          /* @__PURE__ */ jsx(
            BooleanInput,
            {
              source: "tamperProof",
              label: "tesseract.users.settings.tamperProof",
              additionalInfoLabel: "tesseract.users.settings.descriptions.tamperProof",
              toggleEnabledText: "tesseract.users.settings.enabled",
              toggleDisabledText: "tesseract.users.settings.disabled"
            }
          ),
          /* @__PURE__ */ jsx(
            BooleanInput,
            {
              source: "devicePreConnect",
              label: "tesseract.users.settings.devicePreConnect",
              additionalInfoLabel: "tesseract.users.settings.descriptions.devicePreConnect",
              toggleEnabledText: "tesseract.users.settings.enabled",
              toggleDisabledText: "tesseract.users.settings.disabled"
            }
          ),
          /* @__PURE__ */ jsx(
            SelectInput,
            {
              label: "tesseract.users.settings.deviceLimit",
              source: "deviceLimit",
              choices: new Array(10).fill(null).map((item, index) => ({
                key: index + 1,
                name: `${index + 1}`
              })),
              additionalInfoLabel: "tesseract.users.settings.descriptions.deviceLimit",
              inputLabelProps: {
                classes: {
                  inputAdditionalInfo: classes.deviceLimitInfo
                }
              },
              inputProps: {
                classes: {
                  select: classes.deviceLimitSelect
                }
              }
            }
          ),
          /* @__PURE__ */ jsx(
            BooleanInput,
            {
              source: "webFilter",
              label: "tesseract.users.settings.webFiltering",
              additionalInfoLabel: "tesseract.users.settings.descriptions.webFiltering",
              toggleEnabledText: "tesseract.users.settings.enabled",
              toggleDisabledText: "tesseract.users.settings.disabled"
            }
          ),
          tpmEnforcement ? /* @__PURE__ */ jsx(
            BooleanInput,
            {
              source: "tpmEnforcement",
              label: "tesseract.users.settings.tpmEnforcement",
              additionalInfoLabel: "tesseract.users.settings.descriptions.tpmEnforcement",
              toggleEnabledText: "tesseract.users.settings.enabled",
              toggleDisabledText: "tesseract.users.settings.disabled"
            }
          ) : null
        ] }),
        /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.endpoint.zeroTrustSettings.networkSectionTitle", children: [
          /* @__PURE__ */ jsx(PropGateway, { children: /* @__PURE__ */ jsx(Typography, { className: classes.helpText, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.endpoint.zeroTrustSettings.clientNetworkDescription" }) }) }),
          /* @__PURE__ */ jsx(
            BooleanInput,
            {
              source: "manualConfigurationEnabled",
              label: "tesseract.endpoint.zeroTrustSettings.manualConfigurationEnabled"
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "clientNetworkConfiguration.range",
              label: "tesseract.endpoint.zeroTrustSettings.clientNetworkRange",
              additionalInfoLabel: "tesseract.endpoint.zeroTrustSettings.descriptions.clientNetworkRange",
              disable: (value, data) => !get(data, "manualConfigurationEnabled"),
              isRequired: true,
              validate: [validatePrivateIpv4CIDR]
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "clientNetworkConfiguration.poolSize",
              label: "tesseract.endpoint.zeroTrustSettings.clientNetworkPoolSize",
              additionalInfoLabel: "tesseract.endpoint.zeroTrustSettings.descriptions.clientNetworkPoolSize",
              disable: (value, data) => !get(data, "manualConfigurationEnabled"),
              isRequired: true,
              inputProps: { type: "number" },
              validate: [validateInt, validateMinPoolSize, validateMaxValueMemo(29)]
            }
          ),
          /* @__PURE__ */ jsx(PropGateway, { children: /* @__PURE__ */ jsx(Typography, { className: classes.helpText, children: /* @__PURE__ */ jsx(
            Trans,
            {
              i18nKey: "tesseract.endpoint.zeroTrustSettings.clientNetworkSize",
              values: { connections: networkPoolSize ? clientNetworkSize : "?" }
            }
          ) }) })
        ] }),
        isCgfRegistered ? /* @__PURE__ */ jsxs(FormSection, { title: "CloudGen Firewall Client Certificate", children: [
          /* @__PURE__ */ jsx(PropGateway, { children: /* @__PURE__ */ jsx(Typography, { className: classes.helpText, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.endpoint.zeroTrustSettings.downloadCertificateHelpText" }) }) }),
          /* @__PURE__ */ jsx(
            DownloadButton,
            {
              label: "tesseract.endpoint.zeroTrustSettings.downloadCertificateLabel",
              filename: "certificate",
              filetype: "crt",
              resource: apiResources.zeroTrustCertificateDownload,
              params: { filter: virtualWanId, options: { headers: { "Accept": "application/x-x509-ca-cert" } } },
              classes: { downloadButton: classes.downloadButton },
              color: "primary",
              requestMethod: "POST"
            }
          )
        ] }) : null
      ]
    }
  );
};
var ZeroTrustSettings_default = ZeroTrustSettings;
export {
  ZeroTrustSettings_default as default,
  webFilterChoices
};
