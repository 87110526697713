import { jsx, jsxs } from "react/jsx-runtime";
import { ConnectedForm, DialogBody, Tab } from "@cuda-react/core";
import SdwanConnectorGeneralStep from "../../../../components/appliances/create/steps/sdwanConnector/SdwanConnectorGeneralStep";
import SdwanConnectorServicesStep from "../../../../components/appliances/create/steps/sdwanConnector/SdwanConnectorServicesStep";
import { makeStyles } from "@mui/styles";
import apiResources from "../../../../apiResources";
const useStyles = makeStyles({
  dialogContent: {
    width: 850
  }
});
const EditSdwanConnector = ({ onClose, onSuccess, id }) => {
  const classes = useStyles();
  return /* @__PURE__ */ jsx(
    DialogBody,
    {
      title: "tesseract.network.sdwanConnector.dialog.edit.dialogTitle",
      onClose,
      classes,
      form: true,
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          tabbed: true,
          resource: apiResources.sdwanConnector,
          onSubmitSuccess: () => {
            onSuccess == null ? void 0 : onSuccess();
            onClose == null ? void 0 : onClose();
          },
          params: { id },
          onCancel: onClose,
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsx(Tab, { label: "tesseract.network.sdwanConnector.dialog.general.sectionTitle", children: /* @__PURE__ */ jsx(SdwanConnectorGeneralStep, {}) }),
            /* @__PURE__ */ jsx(Tab, { label: "tesseract.network.sdwanConnector.dialog.servers.sectionTitle", children: /* @__PURE__ */ jsx(SdwanConnectorServicesStep, {}) })
          ]
        }
      )
    }
  );
};
var EditSdwanConnector_default = EditSdwanConnector;
export {
  EditSdwanConnector,
  EditSdwanConnector_default as default
};
