var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useTranslation } from "react-i18next";
import StepIcon from "../StepIcon/StepIcon";
import { Divider, Step, StepLabel, Stepper } from "@barracuda-internal/bds-core";
import { StepButton } from "@mui/material";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: 12
  },
  stepLabel: {
    marginTop: "6px !important"
  },
  subStepper: {
    paddingTop: 8,
    paddingBottom: 12,
    marginTop: -6
  },
  stepperButton: {
    marginBottom: 0,
    paddingBottom: 0
  },
  subStepperButton: {
    marginTop: 0,
    paddingTop: 0
  },
  stepLabelCompleted: {
    // @ts-ignore it complains about the !important flag, but we need it here to properly override material-ui
    fontWeight: "400 !important"
  },
  divider: {
    margin: theme.spacing(0, 2)
  }
});
const useStyles = makeOverrideableStyles("WizardStepper", styles);
const WizardStepper = (props) => {
  const _a = props, { activeStep, activeSubStep, disableDirectNavigation, steps, onStepChange, onSubStepChange } = _a, stepperProps = __objRest(_a, ["activeStep", "activeSubStep", "disableDirectNavigation", "steps", "onStepChange", "onSubStepChange"]);
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const subSteps = steps[activeStep] && steps[activeStep].subSteps;
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      Stepper,
      __spreadProps(__spreadValues({
        className: classes.root,
        activeStep,
        nonLinear: true,
        alternativeLabel: true,
        id: "cuda-wizard-stepper"
      }, stepperProps), {
        children: steps.map((step) => {
          const canDirectNavigate = step.visited && !disableDirectNavigation && !steps.slice(step.index + 1, activeStep + 1).some((step2) => step2.preventBackNavigation);
          return /* @__PURE__ */ jsx(Step, { completed: step.completed && step.index !== activeStep, children: canDirectNavigate ? /* @__PURE__ */ jsx(
            StepButton,
            {
              onClick: () => onStepChange == null ? void 0 : onStepChange(step.index),
              classes: { root: classes.stepperButton },
              children: /* @__PURE__ */ jsx(
                StepLabel,
                {
                  StepIconComponent: StepIcon,
                  classes: {
                    label: classes.stepLabel,
                    completed: classes.stepLabelCompleted
                  },
                  error: step.error && !step.hideErrors,
                  children: step.label && translate(step.label)
                }
              )
            }
          ) : /* @__PURE__ */ jsx(
            StepLabel,
            {
              StepIconComponent: StepIcon,
              classes: {
                label: classes.stepLabel,
                completed: classes.stepLabelCompleted
              },
              error: step.error && !step.hideErrors,
              active: step.visited,
              children: step.label && translate(step.label)
            }
          ) }, step.label);
        })
      })
    ),
    subSteps && !steps[activeStep].hideSubNavigation && /* @__PURE__ */ jsx(
      Stepper,
      __spreadProps(__spreadValues({
        nonLinear: true,
        activeStep: activeSubStep,
        alternativeLabel: true,
        id: "cuda-wizard-sub-stepper",
        classes: { root: classes.subStepper }
      }, stepperProps), {
        children: subSteps.map((step) => {
          const stepError = !steps[activeStep].hideErrors && !step.hideErrors && step.error;
          const canDirectNavigate = !disableDirectNavigation && step.visited;
          return /* @__PURE__ */ jsx(Step, { completed: step.completed && step.index !== activeSubStep, children: canDirectNavigate ? /* @__PURE__ */ jsx(
            StepButton,
            {
              onClick: () => onSubStepChange == null ? void 0 : onSubStepChange(step.index),
              classes: { root: classes.stepperButton },
              children: /* @__PURE__ */ jsx(
                StepLabel,
                {
                  StepIconComponent: StepIcon,
                  classes: {
                    label: classes.stepLabel,
                    completed: classes.stepLabelCompleted
                  },
                  error: stepError,
                  children: step.label && translate(step.label)
                }
              )
            }
          ) : /* @__PURE__ */ jsx(
            StepLabel,
            {
              StepIconComponent: StepIcon,
              classes: {
                label: classes.stepLabel,
                completed: classes.stepLabelCompleted
              },
              error: stepError,
              active: step.visited,
              children: step.label && translate(step.label)
            }
          ) }, step.index);
        }) || null
      })
    ),
    /* @__PURE__ */ jsx(Divider, { className: classes.divider })
  ] });
};
WizardStepper.defaultProps = {
  activeSubStep: 0
};
var WizardStepper_default = WizardStepper;
export {
  WizardStepper,
  WizardStepper_default as default,
  styles
};
