import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import apiResources from "../../../apiResources";
import {
  ActionButtonsField,
  DateField,
  DeleteDialog,
  EmptyPageContent,
  TablePage,
  TextField,
  useMixpanel
} from "@cuda-react/core";
import { createdRanges as enrolledRanges } from "../../logsAndReporting/auditLog/AuditLog";
import { EnrolledDevicesGraphic, NothingFoundBackgroundGraphic } from "@cuda-react/icons";
const DevicesTable = () => {
  const registerAction = useMixpanel("Devices");
  const tableRefreshRef = useRef(null);
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      authenticated: true,
      title: "tesseract.endpoint.pageTitle",
      subtitle: "tesseract.endpoint.devices",
      resource: apiResources.zeroTrustDevices,
      minCellWidth: 144,
      tableName: "EndpointDevices",
      pageMode: "paginate",
      resetOnGlobalParamChange: true,
      resizable: true,
      reorderable: true,
      editColumns: true,
      refreshRef: tableRefreshRef,
      exportResource: { resource: apiResources.zeroTrustDevicesExport, filename: "devices" },
      noDataPage: /* @__PURE__ */ jsx(
        EmptyPageContent,
        {
          background: /* @__PURE__ */ jsx(NothingFoundBackgroundGraphic, {}),
          icon: /* @__PURE__ */ jsx(EnrolledDevicesGraphic, {}),
          iconHeader: "tesseract.devices.noData.iconHeader",
          textHeader: "tesseract.devices.noData.textHeader",
          text: "tesseract.devices.noData.text"
        }
      ),
      children: [
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "userFullName",
            label: "tesseract.devices.table.userName",
            filter: "text",
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "userEmail",
            label: "tesseract.devices.table.email",
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "brand",
            label: "tesseract.devices.table.brand"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "model",
            label: "tesseract.devices.table.model"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "hardwareModel",
            label: "tesseract.devices.table.hardwareModel",
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "operatingSystem",
            label: "tesseract.devices.table.os",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "operatingSystemFlavor",
            label: "tesseract.devices.table.osFlavour",
            filter: "text",
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "operatingSystemVersion",
            label: "tesseract.devices.table.osVersion"
          }
        ),
        /* @__PURE__ */ jsx(
          DateField,
          {
            source: "enrollmentDate",
            label: "tesseract.devices.table.enrollmentDate",
            filter: "select",
            filterProps: {
              choices: enrolledRanges
            },
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          ActionButtonsField,
          {
            source: "id",
            width: 48,
            alwaysVisible: true,
            children: /* @__PURE__ */ jsx(
              DeleteDialog,
              {
                resource: apiResources.zeroTrustDevices,
                title: "tesseract.devices.delete.title",
                message: "tesseract.devices.delete.body",
                onSuccess: () => {
                  var _a;
                  registerAction("Delete Device");
                  (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                }
              }
            )
          }
        )
      ]
    }
  );
};
var DevicesTable_default = DevicesTable;
export {
  DevicesTable_default as default
};
