var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { ConnectedForm, SelectInput, getArrayDataContent, useCrudProps } from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";
const UserDirectoryBCCForm = ({
  onClose,
  create,
  directory,
  id
}) => {
  var _a, _b;
  const directoryEnvironmentsData = getArrayDataContent(useCrudProps(apiResources.identityBCCEnvironments));
  const directoryEnvironments = (_b = (_a = directoryEnvironmentsData[0]) == null ? void 0 : _a.data) == null ? void 0 : _b.content;
  return /* @__PURE__ */ jsx(
    ConnectedForm,
    {
      create,
      resource: apiResources.directories,
      params: create ? void 0 : { id },
      onSubmitSuccess: () => onClose(true),
      onCancel: () => onClose(false),
      initialValues: directory,
      formatRequestData: (data) => {
        var _a2;
        return __spreadProps(__spreadValues({}, data), {
          displayName: (_a2 = directoryEnvironments.find((directoryEnvironment) => directoryEnvironment.id === data.options.environmentId)) == null ? void 0 : _a2.name
        });
      },
      children: /* @__PURE__ */ jsx(
        SelectInput,
        {
          source: "options.environmentId",
          label: "tesseract.identity.userDirectories.form.bcc.environment",
          optionValue: "id",
          choices: directoryEnvironments,
          emptyLabel: "tesseract.identity.userDirectories.form.bcc.environment"
        }
      )
    }
  );
};
export {
  UserDirectoryBCCForm
};
