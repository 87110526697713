import { jsx, jsxs } from "react/jsx-runtime";
import { CustomField, DateField, getArrayDataContent, StatusIconField, TablePage, TextField, useCrudProps, useMixpanel } from "@cuda-react/core";
import apiResources from "../../../../apiResources";
const createdRanges = [
  { key: "1", name: "tesseract.audit.filter.created.days1" },
  { key: "3", name: "tesseract.audit.filter.created.days3" },
  { key: "7", name: "tesseract.audit.filter.created.weeks1" },
  { key: "14", name: "tesseract.audit.filter.created.weeks2" },
  { key: "30", name: "tesseract.audit.filter.created.months1" },
  { key: "60", name: "tesseract.audit.filter.created.months2" },
  { key: "90", name: "tesseract.audit.filter.created.months3" },
  { key: "180", name: "tesseract.audit.filter.created.months6" },
  { key: "270", name: "tesseract.audit.filter.created.months9" },
  { key: "365", name: "tesseract.audit.filter.created.years1" }
];
const ApplianceAuditLog = (props) => {
  var _a, _b, _c;
  const { id, resource, details: { model, gatewayType, modelSeries } } = props;
  const auditStatuses = (_a = useCrudProps(apiResources.auditStatuses, {}, {}, true)[0]) == null ? void 0 : _a.data;
  const auditTypes = (_b = useCrudProps(apiResources.auditTypes, {}, {}, true)[0]) == null ? void 0 : _b.data;
  const auditEvents = (_c = useCrudProps(apiResources.auditEvents, {}, {}, true)[0]) == null ? void 0 : _c.data;
  useMixpanel(
    gatewayType ? "Gateways Audit" : "Sites Audit",
    !!model,
    { model: model || "", type: gatewayType || modelSeries || "" }
  );
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      authenticated: true,
      resource: id ? resource : void 0,
      params: { id },
      minCellWidth: 140,
      pageMode: "paginate",
      hasTitle: true,
      sortable: true,
      children: [
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            isAudit: true,
            source: "status",
            label: "tesseract.logs.auditLog.labels.status",
            width: 96,
            filter: "selectarray",
            filterProps: {
              choices: getArrayDataContent(auditStatuses)
            }
          }
        ),
        /* @__PURE__ */ jsx(
          DateField,
          {
            source: "created",
            label: "tesseract.logs.auditLog.labels.created",
            dateOnly: false,
            width: 164,
            filter: "select",
            filterProps: {
              choices: createdRanges
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "type",
            label: "tesseract.logs.auditLog.labels.type",
            render: (value, record) => record.typeName,
            width: 160,
            filter: "selectarray",
            filterProps: {
              choices: getArrayDataContent(auditTypes)
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "event",
            label: "tesseract.logs.auditLog.labels.event",
            render: (value, record) => record.eventName,
            width: 160,
            filter: "selectarray",
            filterProps: {
              choices: getArrayDataContent(auditEvents)
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.logs.auditLog.labels.description",
            sortable: false
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "remoteAddress",
            label: "tesseract.logs.auditLog.labels.remoteAddress",
            width: 142,
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "createdUsername",
            label: "tesseract.logs.auditLog.labels.createdUsername",
            width: 200,
            filter: "text"
          }
        )
      ]
    }
  );
};
var ApplianceAuditLog_default = ApplianceAuditLog;
export {
  createdRanges,
  ApplianceAuditLog_default as default
};
