import { jsx, jsxs } from "react/jsx-runtime";
import {
  BooleanInput,
  Card,
  ConnectedForm,
  TextInput,
  validateInt,
  validateMaxValueMemo,
  validateMinValueMemo
} from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import apiResources from "../../../../apiResources";
import { get } from "lodash";
const useStyles = makeStyles((theme) => ({
  message: {
    padding: theme.spacing(2, 2, 2, 4)
  }
}));
const SIPProxy = () => /* @__PURE__ */ jsx(Card, { title: "tesseract.settings.tabs.SIPProxy.title", children: /* @__PURE__ */ jsxs(
  ConnectedForm,
  {
    formatRequestData: (data) => {
      var _a;
      return {
        settings: {
          sip: JSON.stringify({
            enabled: (_a = data == null ? void 0 : data.enabled) != null ? _a : true,
            udpTimeout: parseInt(data == null ? void 0 : data.udpTimeout, 10) || 60
          })
        }
      };
    },
    formatResourceData: (data) => {
      var _a, _b, _c;
      const sipData = JSON.parse(((_a = data == null ? void 0 : data.settings) == null ? void 0 : _a.sip) || "{}");
      return {
        enabled: (_b = sipData.enabled) != null ? _b : true,
        udpTimeout: (_c = sipData.udpTimeout) != null ? _c : 60
      };
    },
    formatError: (errors) => get(errors, "sip"),
    resource: apiResources.settings,
    params: { filter: { type: "virtualWan" } },
    children: [
      /* @__PURE__ */ jsx(
        BooleanInput,
        {
          source: "enabled",
          label: "tesseract.settings.tabs.SIPProxy.sipProxySetting",
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextInput,
        {
          source: "udpTimeout",
          label: "tesseract.settings.tabs.SIPProxy.UdpTimeout",
          validate: [validateInt, validateMaxValueMemo(300), validateMinValueMemo(30)],
          isRequired: true
        }
      )
    ]
  }
) });
var SIPProxy_default = SIPProxy;
export {
  SIPProxy,
  SIPProxy_default as default,
  useStyles
};
