import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { InputAdornment, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, TextField } from "@barracuda-internal/bds-core";
import CopyToClipboardButton from "../../../../../components/CopyToClipboardButton";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  inputRoot: {
    width: `calc(100% - ${theme.spacing(8)})`,
    margin: theme.spacing(2, 4, 1),
    "& .MuiOutlinedInput-multiline": {
      height: "inherit"
    }
  }
}));
const IdentityProviderSAMLSetup = ({ identityProvider }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const [translate] = useTranslation();
  const classes = useStyles();
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(Box, { display: "flex", flexDirection: "column", gap: 1, children: [
    /* @__PURE__ */ jsx(Typography, { mb: 2, children: translate("tesseract.identity.identityProviders.saml.informationNeeded") }),
    /* @__PURE__ */ jsx(
      TextField,
      {
        id: "entity-id",
        className: classes.inputRoot,
        InputProps: {
          endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: ((_a = identityProvider.saml) == null ? void 0 : _a.entityId) ? /* @__PURE__ */ jsx(
            CopyToClipboardButton,
            {
              value: ((_b = identityProvider.saml) == null ? void 0 : _b.entityId) || "",
              iconOnly: true
            }
          ) : /* @__PURE__ */ jsx(CircularProgress, { size: 24 }) })
        },
        fullWidth: true,
        label: translate("tesseract.identity.identityProviders.saml.entityId"),
        value: ((_c = identityProvider.saml) == null ? void 0 : _c.entityId) || "",
        disabled: !((_d = identityProvider.saml) == null ? void 0 : _d.entityId),
        variant: "outlined"
      }
    ),
    /* @__PURE__ */ jsx(
      TextField,
      {
        id: "acs-url",
        className: classes.inputRoot,
        InputProps: {
          endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: ((_e = identityProvider.saml) == null ? void 0 : _e.acsURL) ? /* @__PURE__ */ jsx(
            CopyToClipboardButton,
            {
              value: ((_f = identityProvider.saml) == null ? void 0 : _f.acsURL) || "",
              iconOnly: true
            }
          ) : /* @__PURE__ */ jsx(CircularProgress, { size: 24 }) })
        },
        fullWidth: true,
        label: translate("tesseract.identity.identityProviders.saml.acsURL"),
        value: ((_g = identityProvider.saml) == null ? void 0 : _g.acsURL) || "",
        disabled: !((_h = identityProvider.saml) == null ? void 0 : _h.acsURL),
        variant: "outlined"
      }
    )
  ] }) });
};
export {
  IdentityProviderSAMLSetup
};
