import { Fragment, jsx } from "react/jsx-runtime";
import { differenceInDays, format, formatDistanceToNow } from "date-fns";
import { useTranslation } from "react-i18next";
const i18nScope = "tesseract.identity.userDirectories";
const LastSyncField = ({ directory }) => {
  const [translate] = useTranslation();
  if (directory.lastSuccessfulSync) {
    if (differenceInDays(/* @__PURE__ */ new Date(), new Date(directory.lastSuccessfulSync)) > 7) {
      return /* @__PURE__ */ jsx(Fragment, { children: translate(`${i18nScope}.lastSyncAt`, { date: format(new Date(directory.lastSuccessfulSync), "LLLL d, yyyy") }) });
    } else {
      return /* @__PURE__ */ jsx(Fragment, { children: formatDistanceToNow(new Date(directory.lastSuccessfulSync), { addSuffix: true }) });
    }
  } else {
    return /* @__PURE__ */ jsx(Fragment, { children: translate(`${i18nScope}.neverSynced`) });
  }
};
export {
  LastSyncField
};
