import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  mainMessage: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2)
  },
  messages: {
    marginLeft: theme.spacing(8)
  }
}));
const PromoteResultStep = () => {
  const classes = useStyles();
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { className: classNames(classes.messages, classes.mainMessage), children: translate("tesseract.appliances.dialog.promoteResult.primaryMessage") }),
    /* @__PURE__ */ jsx(Typography, { className: classes.messages, children: translate("tesseract.appliances.dialog.promoteResult.secondaryMessage") })
  ] });
};
var PromoteResultStep_default = PromoteResultStep;
export {
  PromoteResultStep_default as default
};
