import { jsx } from "react/jsx-runtime";
import { LinearProgress } from "@barracuda-internal/bds-core";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
const useStyles = makeStyles((theme) => createStyles({
  root: {
    height: 15,
    borderRadius: ({ borderRadius }) => borderRadius || 7.5,
    width: "100%"
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 700]
  },
  bar: {
    borderRadius: ({ borderRadius }) => borderRadius || 5,
    backgroundColor: ({ barColor }) => barColor || "#1a90ff",
    animation: "translateX 500ms"
  }
}));
const BorderLinearProgress = (props) => {
  const [shownValue, setShownValue] = useState(0);
  const classes = useStyles(props);
  useEffect(() => {
    setShownValue(props.value || 0);
  }, [props.value]);
  return /* @__PURE__ */ jsx(LinearProgress, { value: shownValue, variant: "determinate", className: props.className, classes });
};
export {
  BorderLinearProgress
};
