var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import { Box, Button } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { StatusIconField, useCrudProps, useMixpanel } from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import { StatusIcon } from "@cuda-react/icons";
import SyncIcon from "@mui/icons-material/Sync";
import { ErrorDialog } from "./ErrorDialog";
import BlockIcon from "@mui/icons-material/Block";
import useDemoApi from "../../../../../hooks/useDemoApi";
const statusToIconMap = {
  running: /* @__PURE__ */ jsx(SyncIcon, {}),
  pending: /* @__PURE__ */ jsx(SyncIcon, {}),
  cancelled: /* @__PURE__ */ jsx(BlockIcon, { sx: { color: "error.main" } }),
  errored: /* @__PURE__ */ jsx(StatusIcon, { status: "error" }),
  completed: /* @__PURE__ */ jsx(StatusIcon, { status: "ok" }),
  default: /* @__PURE__ */ jsx(StatusIcon, { status: "error" })
};
const UserDirectoryStatusField = ({ directory }) => {
  var _a, _b, _c;
  const registerAction = useMixpanel("User Directories", false);
  const authUrl = useDemoApi(apiResources.directoriesAuthorizationURL, apiResources.demoAuthorizationURL);
  const [translate] = useTranslation("translation");
  const authorizationURL = (_a = useCrudProps(directory ? authUrl : void 0, { id: directory.id })[0].data) == null ? void 0 : _a.authorizationURL;
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const handleAuthorize = useCallback(() => __async(void 0, null, function* () {
    registerAction("Authorize Directory");
    window.open(authorizationURL);
  }), [authorizationURL]);
  const openErrorsDialog = useCallback(() => {
    setErrorDialogOpen(true);
  }, [setErrorDialogOpen]);
  if (directory.connectionStatus === "needs_consent") {
    return /* @__PURE__ */ jsx(
      Button,
      {
        size: "small",
        variant: "outlined",
        color: "warning",
        startIcon: /* @__PURE__ */ jsx(StatusIcon, { status: "warning" }),
        onClick: handleAuthorize,
        children: translate(`tesseract.identity.userDirectories.statusField.${directory.connectionStatus}`)
      }
    );
  }
  if (directory.syncStatus === "errored" && ((_b = directory.syncErrors) == null ? void 0 : _b.length) > 0) {
    return /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(
        Button,
        {
          size: "small",
          variant: "outlined",
          color: "error",
          startIcon: /* @__PURE__ */ jsx(StatusIcon, { status: "error" }),
          onClick: openErrorsDialog,
          children: translate(`tesseract.identity.userDirectories.statusField.${directory.syncStatus}`)
        }
      ),
      /* @__PURE__ */ jsx(ErrorDialog, { open: errorDialogOpen, onClose: useCallback(() => setErrorDialogOpen(false), []), directory })
    ] });
  }
  return /* @__PURE__ */ jsx(Box, { display: "flex", alignItems: "center", justifyContent: "center", children: /* @__PURE__ */ jsx(
    StatusIconField,
    {
      data: directory,
      source: "syncStatus",
      iconMap: statusToIconMap,
      text: (_c = translate(`tesseract.identity.userDirectories.statusField.${directory.syncStatus}`)) != null ? _c : ""
    }
  ) });
};
export {
  UserDirectoryStatusField,
  statusToIconMap
};
