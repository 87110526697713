import { jsx } from "react/jsx-runtime";
import { MenuButton } from "@cuda-react/core";
const i18nScope = "tesseract.identity.identityProviders";
const AddIdentityProviderButton = ({ onSelect, disabled = false }) => {
  const menuItems = [
    {
      label: `${i18nScope}.add.types.entraid`,
      onClick: onSelect("entraid")
    },
    {
      label: `${i18nScope}.add.types.google`,
      onClick: onSelect("google")
    },
    {
      label: `${i18nScope}.add.types.bcc`,
      onClick: onSelect("bcc")
    },
    {
      label: `${i18nScope}.add.types.oidc`,
      onClick: onSelect("oidc")
    },
    {
      label: `${i18nScope}.add.types.saml`,
      onClick: onSelect("saml")
    },
    {
      label: `${i18nScope}.add.types.email`,
      onClick: onSelect("email")
    }
  ];
  return /* @__PURE__ */ jsx(
    MenuButton,
    {
      disabled,
      label: "tesseract.identity.identityProviders.createIdentityProvider",
      menuItems
    }
  );
};
export {
  AddIdentityProviderButton
};
