var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { AUTH_CHANGE_ACCOUNT, AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT } from "../actions";
import { AUTHENTICATION_FAILED } from "./crudClient";
import { get, merge } from "lodash";
const AUTHENTICATE_URL = "/api/v1/webui/cloud/authenticate";
const EXPIRE_URL = "https://" + location.hostname + ":" + location.port + "/api/v1/webui/cloud/authenticate/expire";
const DEFAULT_LOGIN_URL = "https://login.barracudanetworks.com";
const logout = (redirectTarget) => fetch(EXPIRE_URL, { mode: "no-cors", credentials: "include" }).catch(() => {
}).then(() => getLoginRedirect(redirectTarget));
const getLoginRedirect = (target) => Promise.reject({
  redirect: (target || DEFAULT_LOGIN_URL) + "/auth/login?service=" + location.href,
  userData: null,
  loginUrl: null
});
const performLogin = (params, loginUrl, noRedirect = false) => fetch(loginUrl || AUTHENTICATE_URL, { credentials: "include" }).then(
  (response) => response.json().then(
    (json) => {
      if (response.status === 403) {
        return Promise.reject({
          redirect: "/unauthorized",
          userData: json,
          loginUrl: null
        });
      } else if (response.status >= 300) {
        return logout(json.portalUrl || response.headers.get("Portal-Location"));
      }
      return {
        redirect: noRedirect ? void 0 : get(params, "previousOrigin", get(params, "origin")) || "/",
        userData: json,
        loginUrl: null
      };
    },
    () => logout(response.headers.get("Portal-Location"))
  ),
  () => logout()
);
const sessionStatuses = {
  checkedSessionAuth: false,
  authenticating: false
};
const authLogin = (params, globalParams) => {
  const _a = globalParams || {}, { loginUrl } = _a, resolvedGlobalParams = __objRest(_a, ["loginUrl"]);
  if (sessionStatuses.authenticating) {
    return Promise.reject();
  }
  sessionStatuses.authenticating = true;
  sessionStatuses.checkedSessionAuth = true;
  return performLogin(merge({}, resolvedGlobalParams, params), loginUrl).finally(() => {
    sessionStatuses.authenticating = false;
  });
};
const authChangeAccount = (params, globalParams) => {
  const accountId = get(globalParams, "userData.currentAccount");
  const newAccountId = get(params, "accountId");
  const changeAccountUrl = `/api/v1/webui/${accountId}/accounts/switch/${newAccountId}`;
  const updateInPlace = get(params, "updateInPlace");
  if (accountId === newAccountId) {
    return Promise.resolve();
  }
  if (updateInPlace) {
    return performLogin(merge({}, globalParams, params), changeAccountUrl, true);
  } else {
    return Promise.resolve({ redirect: "/login", loginUrl: changeAccountUrl });
  }
};
const authLogout = (globalParams) => logout(get(globalParams, "userData.portalUrl"));
const authError = (params) => {
  if ((params == null ? void 0 : params.status) === 308 || (params == null ? void 0 : params.status) === 403) {
    return Promise.reject();
  } else if (params === AUTHENTICATION_FAILED || params.status === 401) {
    return Promise.reject({ redirect: "/login" });
  }
  return Promise.resolve();
};
const authCheck = (authenticated, globalParams) => {
  if (!get(globalParams, "userData.currentAccount") || authenticated && typeof authenticated === "function" && !authenticated(globalParams)) {
    return Promise.reject();
  }
  if (!sessionStatuses.checkedSessionAuth) {
    sessionStatuses.checkedSessionAuth = true;
    return performLogin(globalParams).then((_a) => {
      var _b = _a, { redirect } = _b, response = __objRest(_b, ["redirect"]);
      return response;
    });
  }
  return Promise.resolve();
};
var bccRedirectAuthClient_default = (type, params, globalParams) => {
  switch (type) {
    case AUTH_LOGIN:
      return authLogin(params, globalParams);
    case AUTH_CHANGE_ACCOUNT:
      return authChangeAccount(params, globalParams);
    case AUTH_LOGOUT:
      return authLogout(globalParams);
    case AUTH_ERROR:
      return authError(params);
    case AUTH_CHECK:
      return authCheck(params, globalParams);
    default:
      return Promise.reject("Unknown Method");
  }
};
export {
  AUTHENTICATE_URL,
  DEFAULT_LOGIN_URL,
  EXPIRE_URL,
  bccRedirectAuthClient_default as default,
  sessionStatuses
};
