var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Chip, FormControl, IconButton, TextField } from "@barracuda-internal/bds-core";
import { Expand } from "@barracuda-internal/bds-core/dist/Icons/Core";
import classNames from "classnames";
import { isArray, isEmpty } from "lodash";
import { useState } from "react";
import { formatErrorMessage } from "../../../utils";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { InputAdornment } from "@mui/material";
import { createStyles } from "@mui/styles";
const DELIMITER_REGEX = RegExp("[;,\\s]+");
const styles = (theme) => createStyles({
  formControl: {},
  textField: {
    width: 256,
    "& input": {
      fontSize: 16
    }
  },
  chip: {
    margin: "0.1rem"
  },
  chipError: {
    border: `1px solid ${theme.palette.error.main}`
  },
  addButton: {
    padding: 0
  },
  chipContainer: {
    maxWidth: 256,
    marginBottom: "0.1rem"
  }
});
const useStyles = makeOverrideableStyles("TextArray", styles);
const TextArray = (props) => {
  const {
    options = {},
    id,
    value,
    onChange,
    onBlur,
    error,
    type = "text",
    disabled,
    maxInputs,
    maxChipLength = 30
  } = props;
  const classes = useStyles(props);
  const [currentString, setCurrentString] = useState("");
  if (value && !isArray(value)) {
    return null;
  }
  const addChips = (chips, currentString2) => {
    const currString = currentString2 ? currentString2 : "";
    let newChips = value ? [...value].concat(chips) : chips;
    newChips = newChips.map((chip) => chip.trim()).filter((chip) => !!chip);
    newChips = [...new Set(newChips)];
    onChange == null ? void 0 : onChange(newChips);
    setCurrentString(currString);
  };
  const onEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      addChips([currentString]);
    }
  };
  const onTextFieldChange = (event) => {
    const newValue = event.target.value;
    if (checkPaste(newValue) && hasDelimiter(newValue)) {
      handlePaste(newValue);
    } else {
      setCurrentString(newValue);
    }
  };
  const checkPaste = (value2) => value2.trim().length - currentString.length > 5;
  const hasDelimiter = (value2) => value2.search(DELIMITER_REGEX) > 0;
  const handlePaste = (value2) => {
    const valueSplit = value2.split(DELIMITER_REGEX);
    const chips = valueSplit.slice(0, valueSplit.length - 1);
    addChips(chips, valueSplit[valueSplit.length - 1]);
  };
  const handleRequestDelete = (index) => {
    const newValue = (value || []).filter((val, itt) => itt !== index);
    onChange && onChange(newValue);
  };
  const inputLimitReached = maxInputs && value && value.length >= maxInputs || false;
  const addButtonDisabled = !(currentString.length > 0) || inputLimitReached;
  return /* @__PURE__ */ jsxs(FormControl, { className: classes.formControl, children: [
    /* @__PURE__ */ jsx("div", { className: classes.chipContainer, children: value && value.map((val, index) => /* @__PURE__ */ jsx(
      Chip,
      {
        onDelete: disabled ? void 0 : () => handleRequestDelete(index),
        className: classNames(
          classes.chip,
          !disabled && isArray(error) && !isEmpty(error[index]) && classes.chipError
        ),
        disabled,
        label: typeof val === "string" && val.length > maxChipLength ? val.substring(0, maxChipLength - 3) + "..." : val
      },
      index
    )) }),
    /* @__PURE__ */ jsx(
      TextField,
      __spreadProps(__spreadValues({
        value: currentString,
        onKeyDown: onEnter,
        onChange: onTextFieldChange,
        onBlur: () => {
          onBlur == null ? void 0 : onBlur(value || []);
          currentString && addChips([currentString]);
        },
        type,
        helperText: !disabled && formatErrorMessage(error),
        error: !!(!disabled && error)
      }, options), {
        id: "text-array-input-" + id,
        disabled: disabled || inputLimitReached,
        className: classes.textField,
        variant: "outlined",
        InputProps: {
          endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: /* @__PURE__ */ jsx(
            IconButton,
            {
              id: "add-to-text-array-button",
              className: classes.addButton,
              onClick: () => addChips([currentString]),
              disabled: addButtonDisabled,
              color: "primary",
              children: /* @__PURE__ */ jsx(Expand, {})
            }
          ) })
        }
      })
    )
  ] });
};
var TextArray_default = TextArray;
export {
  TextArray,
  TextArray_default as default,
  useStyles
};
