import { jsx, jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import Dashboard from "./pages/dashboard/Dashboard";
import GlobalStatusController from "./GlobalStatusController";
import TesseractMessages from "./i18n/TesseractMessages";
import { StratosReportingMessages } from "@stratos/reporting";
import { useGlobalParam, useI18next, useMixpanelPrefix } from "@cuda-react/core";
import Network from "./pages/network/Network";
import Endpoint from "./pages/endpoint/Endpoint";
import { Identity } from "./pages/identity/Identity";
import { LogsAndReporting } from "./pages/logsAndReporting/LogsAndReporting";
import SecurityAndAccess from "./pages/securityAndAccess/SecurityAndAccess";
import { Integration } from "./pages/integration/Integration";
import Subscription from "./pages/subscription/Subscription";
const CgwanModule = () => {
  const isDemo = useGlobalParam("userData.mode")[0] === "demo";
  useMixpanelPrefix(isDemo ? "CGW-Demo" : "CGW");
  const { addToMessages } = useI18next();
  useEffect(() => {
    addToMessages(TesseractMessages);
    addToMessages(StratosReportingMessages);
  }, []);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(GlobalStatusController, {}),
    /* @__PURE__ */ jsxs(Switch, { children: [
      /* @__PURE__ */ jsx(
        Route,
        {
          exact: true,
          path: "/",
          component: Dashboard
        }
      ),
      /* @__PURE__ */ jsx(
        Route,
        {
          path: "/infrastructure/*",
          component: Network
        }
      ),
      /* @__PURE__ */ jsx(
        Route,
        {
          path: "/ztna/*",
          component: Endpoint
        }
      ),
      /* @__PURE__ */ jsx(
        Route,
        {
          path: "/policies/*",
          component: SecurityAndAccess
        }
      ),
      /* @__PURE__ */ jsx(
        Route,
        {
          exact: true,
          path: "/logs/*",
          component: LogsAndReporting
        }
      ),
      /* @__PURE__ */ jsx(
        Route,
        {
          path: "/integration/*",
          component: Integration
        }
      ),
      /* @__PURE__ */ jsx(
        Route,
        {
          path: "/subscription/*",
          component: Subscription
        }
      ),
      /* @__PURE__ */ jsx(
        Route,
        {
          path: "/identity/*",
          component: Identity
        }
      ),
      /* @__PURE__ */ jsx(Redirect, { to: "/" })
    ] })
  ] });
};
var Module_default = CgwanModule;
export {
  Module_default as default
};
