import { jsx, jsxs } from "react/jsx-runtime";
import { getArrayDataContent, Select, SelectArray, useCrudProps } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Grid } from "@barracuda-internal/bds-core";
import apiResources from "../../../apiResources";
const SIZE_FILTER_CHOICE_KEY = "size-type";
const useStyles = makeStyles((theme) => ({
  container: {
    width: 256
  },
  selectFilter: {
    marginTop: theme.spacing(1),
    width: "100%",
    maxWidth: 256
  },
  typeSelect: {
    width: "100%",
    maxWidth: 256
  }
}));
const sizeTypeChoices = [
  { key: "scaleUnit", name: "tesseract.gateways.table.filters.scaleUnit" },
  { key: "model", name: "tesseract.gateways.table.filters.model" },
  { key: "bandwidth", name: "tesseract.gateways.table.filters.bandwidth" }
];
const SizeFilter = ({
  onChange,
  scaleUnitChoices = [],
  value = { [SIZE_FILTER_CHOICE_KEY]: "" }
}) => {
  var _a;
  const classes = useStyles();
  const [translate] = useTranslation();
  const update = (field) => (eventOrValue) => onChange({
    [SIZE_FILTER_CHOICE_KEY]: value[SIZE_FILTER_CHOICE_KEY] || "",
    [field]: (eventOrValue == null ? void 0 : eventOrValue.target) ? eventOrValue.target.value : eventOrValue
  });
  const modelChoices = getArrayDataContent((_a = useCrudProps(apiResources.modelNames)[0]) == null ? void 0 : _a.data).map((model) => ({ key: model, name: model }));
  const filter = value[SIZE_FILTER_CHOICE_KEY] === "model" ? /* @__PURE__ */ jsx(
    SelectArray,
    {
      label: "tesseract.gateways.table.filters.model",
      id: "model",
      choices: modelChoices,
      value: value.model,
      onChange: update("model"),
      className: classes.selectFilter
    },
    "gateway"
  ) : value[SIZE_FILTER_CHOICE_KEY] === "bandwidth" ? /* @__PURE__ */ jsx(
    SelectArray,
    {
      id: "bandwidth",
      label: "tesseract.gateways.table.filters.bandwidth",
      choices: scaleUnitChoices,
      value: value.bandwidth,
      onChange: update("bandwidth"),
      className: classes.selectFilter
    },
    "gateway"
  ) : /* @__PURE__ */ jsx(
    SelectArray,
    {
      id: "scaleUnit",
      label: "tesseract.gateways.table.filters.scaleUnit",
      choices: scaleUnitChoices,
      value: value.scaleUnit,
      onChange: update("scaleUnit"),
      className: classes.selectFilter
    }
  );
  return /* @__PURE__ */ jsxs(Grid, { container: true, direction: "column", className: classes.container, children: [
    /* @__PURE__ */ jsx(
      Select,
      {
        id: "cuda-filter-input-type",
        value: value[SIZE_FILTER_CHOICE_KEY],
        onChange: (eventOrValue) => onChange({ [SIZE_FILTER_CHOICE_KEY]: eventOrValue.target ? eventOrValue.target.value : eventOrValue }),
        label: translate("tesseract.gateways.table.filters.sizeSelector"),
        choices: sizeTypeChoices,
        classes: { select: classes.typeSelect, formControl: classes.typeSelect }
      }
    ),
    filter
  ] });
};
var SizeFilter_default = SizeFilter;
export {
  SIZE_FILTER_CHOICE_KEY,
  SizeFilter_default as default
};
