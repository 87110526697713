import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const NetworkServicesIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-network-services",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
          d: "M27.5,42.4h5l.7-3.7c.5-.2,1-.4,1.4-.7s.9-.5,1.3-.8l3.6,1.1,2.5-4.2-2.9-2.5c0-.6.1-1.1.1-1.6s0-1-.1-1.6l2.9-2.5-2.5-4.2-3.6,1.1c-.5-.3-.9-.6-1.3-.8s-.9-.4-1.4-.7l-.7-3.7h-5l-.7,3.7c-.5.2-1,.4-1.4.7s-.9.5-1.3.8l-3.6-1.1-2.5,4.2,2.9,2.5c0,.6-.1,1.1-.1,1.6s0,1,.1,1.6l-2.9,2.5,2.5,4.2,3.6-1.1c.5.3.9.6,1.3.8s.9.4,1.4.7l.7,3.7ZM30,35c-1.4,0-2.5-.5-3.5-1.5s-1.5-2.1-1.5-3.5.5-2.5,1.5-3.5,2.1-1.5,3.5-1.5,2.5.5,3.5,1.5c1,1,1.5,2.1,1.5,3.5s-.5,2.5-1.5,3.5c-1,1-2.1,1.5-3.5,1.5ZM12.6,52.3c-1.4,0-2.5-.5-3.5-1.5-1-1-1.5-2.1-1.5-3.5V12.6c0-1.4.5-2.5,1.5-3.5,1-1,2.1-1.5,3.5-1.5h34.7c1.4,0,2.5.5,3.5,1.5s1.5,2.1,1.5,3.5v34.7c0,1.4-.5,2.5-1.5,3.5-1,1-2.1,1.5-3.5,1.5H12.6ZM12.6,47.4h34.7V12.6H12.6v34.7ZM12.6,12.6v34.7V12.6Z"
        }
      )
    }
  );
};
var NetworkServicesIcon_default = NetworkServicesIcon;
export {
  NetworkServicesIcon,
  NetworkServicesIcon_default as default
};
