var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { IncidentBaseCard } from "../../baseCards";
import { VirusIcon } from "@cuda-react/icons";
import { useReportingData } from "../../../contexts";
import { CardSizes } from "../../../typesAndConstants";
import { colorFunctionError, numberFormatter } from "../../../utils";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
const MalwareCard = (props) => {
  const { preview } = props;
  const { data, loading, addFilter } = useReportingData("MalwareCard", preview);
  const { atdDataPoints = [] } = data || {};
  const [translate] = useTranslation();
  const theme = useTheme();
  const maxValue = Math.max(...atdDataPoints.map((atd) => atd.numberOfSessions));
  const total = numberFormatter(atdDataPoints.reduce((atdTotal, atd) => atdTotal + atd.numberOfSessions, 0));
  return /* @__PURE__ */ jsx(
    IncidentBaseCard,
    __spreadValues({
      icon: /* @__PURE__ */ jsx(VirusIcon, {}),
      title: "stratosReporting.malwareCard.title",
      entries: sortBy(atdDataPoints, "numberOfSessions").reverse().map((atd) => ({
        title: atd.label,
        value: props.size === CardSizes.small ? numberFormatter(atd.numberOfSessions) : translate("stratosReporting.malwareCard.hits", {
          count: atd.numberOfSessions,
          formattedCount: numberFormatter(atd.numberOfSessions)
        }),
        icon: /* @__PURE__ */ jsx(VirusIcon, { size: 20 }),
        barProgress: 100 / maxValue * atd.numberOfSessions,
        color: colorFunctionError(100 / maxValue * atd.numberOfSessions, theme),
        onClick: atd.label && addFilter ? () => addFilter({ key: atd.id, name: atd.label }, "atdDataPoints") : void 0,
        tooltipMessage: atd.label && addFilter ? "stratosReporting.filterTooltip" : void 0
      })),
      total,
      loading: loading && !data.atdDataPoints,
      noData: !loading && !data.atdDataPoints,
      activeFilter: true
    }, props)
  );
};
export {
  MalwareCard
};
