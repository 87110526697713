import { jsx, jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
const EmailTemplate = ({ content, username }) => {
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsxs(
    "div",
    {
      style: {
        width: 728,
        background: "white",
        border: "solid 1px rgba(0,0,0,0.12)",
        borderRadius: 8,
        padding: "36px 48px 0px",
        textAlign: "left"
      },
      children: [
        /* @__PURE__ */ jsx("div", { style: { marginBottom: 32 }, children: /* @__PURE__ */ jsx("p", { style: { lineHeight: "22px" }, children: /* @__PURE__ */ jsx(
          "span",
          {
            style: {
              fontSize: 22,
              fontFamily: '"Helvetica",sans-serif',
              color: "#373B45",
              letterSpacing: -0.05,
              fontWeight: "bold"
            },
            children: translate("tesseract.endpoint.enrollEmailDialog.emailTemplate.hello", { username })
          }
        ) }) }),
        /* @__PURE__ */ jsx("p", { style: { marginRight: 0, paddingBottom: 8, marginLeft: 0, lineHeight: "15px" }, children: /* @__PURE__ */ jsx(
          "span",
          {
            style: {
              fontSize: 16,
              fontFamily: '"Helvetica",sans-serif',
              color: "#383C45",
              letterSpacing: -0.05
            },
            children: translate("tesseract.endpoint.enrollEmailDialog.emailTemplate.message")
          }
        ) }),
        content ? /* @__PURE__ */ jsx("p", { style: { marginRight: 0, paddingBottom: 8, marginLeft: 0, lineHeight: "15px" }, children: /* @__PURE__ */ jsx(
          "span",
          {
            style: {
              fontSize: 16,
              fontFamily: '"Helvetica",sans-serif',
              color: "#383C45",
              letterSpacing: -0.05
            },
            children: content
          }
        ) }) : null,
        /* @__PURE__ */ jsx("p", { style: { lineHeight: "13.5px" }, children: /* @__PURE__ */ jsx(
          "span",
          {
            style: {
              fontSize: 16,
              fontFamily: '"Helvetica",sans-serif',
              color: "rgb(6,124,193)",
              letterSpacing: -0.05
            },
            children: translate("tesseract.endpoint.enrollEmailDialog.emailTemplate.clickStart")
          }
        ) }),
        /* @__PURE__ */ jsx(
          "div",
          {
            style: {
              border: "none",
              marginTop: 16,
              borderBottom: "solid #D7D8DA 1.0px",
              marginRight: 32,
              height: 460,
              width: 612,
              backgroundImage: "url(/images/email_getting_started.svg)",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right"
            },
            children: /* @__PURE__ */ jsxs("div", { style: { width: "100%", height: "100%", backgroundColor: "rgba(255,255,255,0.4)" }, children: [
              /* @__PURE__ */ jsx("p", { style: { lineHeight: "13.5px", paddingTop: 48 }, children: /* @__PURE__ */ jsx(
                "a",
                {
                  target: "_blank",
                  rel: "noopener noreferrer",
                  title: "Start",
                  "data-safelink": "true",
                  "data-linkindex": "0",
                  style: { textDecoration: "none", zIndex: 5 },
                  children: /* @__PURE__ */ jsx(
                    "span",
                    {
                      style: {
                        fontSize: 16,
                        fontFamily: '"Helvetica",sans-serif',
                        color: "#FFFFFF",
                        letterSpacing: -0.05,
                        backgroundColor: "rgb(6,124,193)",
                        borderRadius: 4,
                        padding: "8px 80px"
                      },
                      children: translate("tesseract.endpoint.enrollEmailDialog.emailTemplate.start")
                    }
                  )
                }
              ) }),
              /* @__PURE__ */ jsx("p", { style: { marginRight: 0, paddingTop: "16px", marginLeft: 0, lineHeight: "13px" }, children: /* @__PURE__ */ jsx("span", { style: {
                fontSize: 12,
                fontFamily: '"Helvetica",sans-serif',
                color: "#383C45",
                letterSpacing: -0.05
              }, children: translate("tesseract.endpoint.enrollEmailDialog.emailTemplate.copyLink") }) }),
              /* @__PURE__ */ jsx("p", { style: { lineHeight: "13.5px", marginTop: "-8px" }, children: /* @__PURE__ */ jsx(
                "a",
                {
                  target: "_blank",
                  rel: "noopener noreferrer",
                  title: "Start",
                  "data-safelink": "true",
                  "data-linkindex": "0",
                  style: { zIndex: 5 },
                  children: /* @__PURE__ */ jsx("span", { style: {
                    fontSize: 12,
                    fontFamily: '"Helvetica",sans-serif',
                    color: "#383C45",
                    letterSpacing: -0.05
                  }, children: translate("tesseract.endpoint.enrollEmailDialog.emailTemplate.exampleLink") })
                }
              ) })
            ] })
          }
        ),
        /* @__PURE__ */ jsxs("div", { style: { width: "100%", display: "flex", alignItems: "center" }, children: [
          /* @__PURE__ */ jsx("div", { style: { width: "70%" }, children: /* @__PURE__ */ jsx("p", { children: /* @__PURE__ */ jsx(
            "a",
            {
              target: "_blank",
              rel: "noopener noreferrer",
              "data-auth": "NotApplicable",
              title: "Barracuda SecureEdge",
              "data-safelink": "true",
              "data-linkindex": "1",
              children: /* @__PURE__ */ jsx(
                "span",
                {
                  style: { border: "none", windowtext: 1, padding: 0, textDecoration: "none" },
                  children: /* @__PURE__ */ jsx(
                    "img",
                    {
                      "data-imagetype": "External",
                      src: "/images/SecureEdge_Logo.svg",
                      border: "0",
                      width: "105",
                      height: "28.8",
                      id: "x__x0000_i1025",
                      style: { width: "1.875in", height: ".3541in" }
                    }
                  )
                }
              )
            }
          ) }) }),
          /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx("p", { children: /* @__PURE__ */ jsx(
            "a",
            {
              target: "_blank",
              rel: "noopener noreferrer",
              "data-auth": "NotApplicable",
              "data-safelink": "true",
              "data-linkindex": "2",
              style: { textDecoration: "none" },
              children: /* @__PURE__ */ jsx("b", { children: /* @__PURE__ */ jsx(
                "span",
                {
                  style: {
                    fontSize: 12,
                    color: "#888A8F",
                    letterSpacing: -0.05,
                    border: "none",
                    // @ts-ignore
                    windowtext: 1
                  },
                  children: "barracuda.com"
                }
              ) })
            }
          ) }) })
        ] })
      ]
    }
  ) });
};
var EmailTemplate_default = EmailTemplate;
export {
  EmailTemplate_default as default
};
