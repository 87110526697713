import { jsx } from "react/jsx-runtime";
import { ButtonDialog } from "@cuda-react/core";
import { Edit } from "@barracuda-internal/bds-core/dist/Icons/Core";
import NotificationContent from "./NotificationContent";
const EditNotificationDialog = ({ id, data, onSuccess }) => {
  const appId = typeof id === "function" ? id(data) : id;
  return /* @__PURE__ */ jsx(ButtonDialog, { buttonIcon: /* @__PURE__ */ jsx(Edit, { id: "edit-notification-icon" }), children: /* @__PURE__ */ jsx(
    NotificationContent,
    {
      title: "tesseract.settings.tabs.notification.components.title.editNotification",
      id: appId,
      onSuccess
    }
  ) });
};
var EditNotificationDialog_default = EditNotificationDialog;
export {
  EditNotificationDialog_default as default
};
