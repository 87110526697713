import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { ChipArrayField, CustomField, TablePage, TextField, useMixpanel, DeleteDialog } from "@cuda-react/core";
import apiResources from "../../../apiResources";
import useDemoApi from "../../../hooks/useDemoApi";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import { CreateEditUser } from "./components/CreateEditUser";
import { capitalize } from "lodash";
import { DeleteContent } from "../components/DeleteContent";
import { useTranslation } from "react-i18next";
const Users = () => {
  const [translate] = useTranslation();
  const usersApi = useDemoApi(apiResources.identityUsers, apiResources.demoUsers);
  const registerAction = useMixpanel("Users");
  const tableRefreshRef = useRef(null);
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsxs(
    TablePage,
    {
      authenticated: true,
      pageMode: "paginate",
      title: "tesseract.identity.pageTitle",
      subtitle: "tesseract.identity.users.title",
      resource: usersApi,
      noDataMessage: "tesseract.identity.users.noDataMessage",
      tableName: "tesseract.identity.users.tableName",
      refreshRef: tableRefreshRef,
      actions: [
        /* @__PURE__ */ jsx(
          CreateEditButtonDialog,
          {
            create: true,
            component: CreateEditUser,
            label: "tesseract.identity.users.create",
            onSuccess: () => {
              var _a;
              registerAction("Create");
              (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
            }
          },
          "create"
        )
      ],
      children: [
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.identity.users.name",
            filter: "text",
            filterProps: {
              label: "tesseract.identity.users.nameOrEmail"
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "email",
            label: "tesseract.identity.users.email"
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "groups",
            label: "tesseract.identity.users.groups",
            render: (group) => group && group.name,
            filter: "selectarray",
            filterProps: {
              resource: apiResources.identityGroups,
              optionValue: "id"
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "type",
            label: "tesseract.identity.users.type",
            render: (type) => capitalize(type),
            filter: "select",
            filterProps: {
              choices: [{ key: "local", name: "Local" }, { key: "external", name: "External" }]
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            width: 142,
            source: "id",
            alwaysVisible: true,
            render: (id, user) => {
              if (user.type === "local") {
                return /* @__PURE__ */ jsxs(Fragment, { children: [
                  /* @__PURE__ */ jsx(
                    CreateEditButtonDialog,
                    {
                      component: ({ onClose, onSuccess }) => /* @__PURE__ */ jsx(CreateEditUser, { id, onClose, onSuccess }),
                      label: "tesseract.identity.users.edit",
                      disabled: ({ type }) => type === "external",
                      onSuccess: () => {
                        var _a;
                        registerAction("edit");
                        (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                      }
                    },
                    "edit"
                  ),
                  /* @__PURE__ */ jsx(
                    DeleteDialog,
                    {
                      data: user,
                      resource: apiResources.identityUsers,
                      title: "tesseract.identity.users.delete.title",
                      message: /* @__PURE__ */ jsx(DeleteContent, { children: /* @__PURE__ */ jsx("p", { children: translate("tesseract.identity.users.delete.body") }) }),
                      onSuccess: () => {
                        var _a;
                        registerAction("deleted");
                        (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                      }
                    }
                  )
                ] });
              }
            }
          }
        )
      ]
    }
  ) });
};
export {
  Users
};
