var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import {
  ConnectedForm,
  DialogBody,
  getArrayDataContent,
  SelectInput,
  TextInput,
  useCrudProps,
  useGlobalParam,
  useMixpanel
} from "@cuda-react/core";
import { useSiteInputValidations } from "../../../../hooks/createEditSiteHooks";
import apiResources from "../../../../apiResources";
import { useTranslation } from "react-i18next";
const CreateManagedGatewayContent = ({ onSuccess, onClose, title }) => {
  var _a;
  const registerAction = useMixpanel("Gateways", false);
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const inputValidations = useSiteInputValidations();
  const regionChoices = getArrayDataContent(useCrudProps(apiResources.managedGatewaysRegions, { virtualWanId })[0]);
  const bandwidthChoices = getArrayDataContent(useCrudProps(apiResources.managedGatewaysBandwidths)[0]);
  const [translate] = useTranslation();
  const bandwidthData = (_a = useCrudProps(apiResources.managedBandwidthsUsage)[0]) == null ? void 0 : _a.data;
  const availableBandwidth = (bandwidthData == null ? void 0 : bandwidthData.available) > 0 ? bandwidthData.available : 0;
  regionChoices.sort((region1, region2) => region1.key.localeCompare(region2.key));
  return /* @__PURE__ */ jsx(
    DialogBody,
    {
      title,
      onClose,
      form: true,
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          create: true,
          resource: apiResources.managedGateways,
          onSubmitSuccess: (response) => {
            registerAction("Create", {
              model: response.model,
              region: response.region
            });
            onSuccess == null ? void 0 : onSuccess();
            onClose == null ? void 0 : onClose();
          },
          formatRequestData: (data) => __spreadProps(__spreadValues({}, data), {
            virtualWanId
          }),
          onCancel: onClose,
          children: [
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "name",
                label: "tesseract.gateways.dialog.create.managed.name",
                isRequired: true,
                validate: inputValidations.validateSiteName
              }
            ),
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                source: "region",
                label: "tesseract.gateways.dialog.create.managed.region",
                choices: regionChoices
              }
            ),
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                source: "model",
                label: "tesseract.gateways.dialog.create.managed.bandwidth",
                choices: bandwidthChoices,
                additionalInfoLabel: availableBandwidth <= 99999 ? { i18nKey: "tesseract.gateways.dialog.create.managed.bandwidthAvailable", values: { availableBandwidth } } : void 0,
                validate: (value) => {
                  if (value && value > availableBandwidth) {
                    return translate("tesseract.gateways.dialog.create.managed.invalidBandwidth", { availableBandwidth });
                  } else {
                    return void 0;
                  }
                }
              }
            )
          ]
        }
      )
    }
  );
};
var CreateManagedGatewayContent_default = CreateManagedGatewayContent;
export {
  CreateManagedGatewayContent_default as default
};
