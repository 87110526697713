import { jsx, jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { CircularProgress, Typography } from "@barracuda-internal/bds-core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useTranslation } from "react-i18next";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    textAlign: "center"
  },
  container: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(1)
  },
  spinner: {
    marginTop: 1
  }
});
const useStyles = makeOverrideableStyles("TableFooterRow", styles);
const TableFooterRow = (props) => {
  const { colSpan, state } = props;
  const styles2 = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx("tr", { children: /* @__PURE__ */ jsx("td", { colSpan, className: styles2.root, children: /* @__PURE__ */ jsxs("span", { className: styles2.container, children: [
    state === "loading" && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsxs(Typography, { children: [
        translate("cuda.table.footer.loading"),
        "\u2026"
      ] }),
      /* @__PURE__ */ jsx(CircularProgress, { id: "cuda-loading-spinner", size: 16, thickness: 2, className: styles2.spinner })
    ] }),
    state === "end" && /* @__PURE__ */ jsx(Typography, { children: translate("cuda.table.footer.end") })
  ] }) }) });
};
var TableFooterRow_default = TableFooterRow;
export {
  TableFooterRow_default as default,
  styles
};
