var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Divider, IconButton, Typography } from "@barracuda-internal/bds-core";
import { Add, Delete, Subtract } from "@barracuda-internal/bds-core/dist/Icons/Core";
import React, { Fragment, useEffect, useState } from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { get } from "lodash";
import { useFieldArray, useFormState, useWatch } from "react-hook-form";
import { createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
const styles = (theme) => createStyles({
  componentHelperText: {
    marginLeft: theme.spacing(7)
  },
  elementHelperText: {
    marginLeft: theme.spacing(7)
  },
  inputGroup: {
    overflow: "hidden"
  },
  inputGroupHidden: {
    display: "none"
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginLeft: 32,
    marginRight: 32
  },
  buttons: {
    marginLeft: 32,
    marginRight: 32,
    float: "right"
  },
  multiInputMainColumnStyle: {},
  topBarColumnStyle: {
    borderTop: "solid 1px #DDDDDD",
    borderBottom: "solid 1px #DDDDDD",
    marginTop: 30
  },
  buttonsColumnStyle: {
    paddingLeft: 40
  },
  mainContentColumnStyle: {
    float: "left"
  },
  leftColumnColumnStyle: {
    float: "left",
    width: "20%",
    overflow: "hidden"
  },
  centerContainerColumnStyle: {
    float: "left",
    borderLeft: "solid 1px #DDDDDD"
  },
  titleColumnStyleActive: {
    color: "#0A5D91",
    backgroundColor: "#E4F4FF",
    padding: 10
  },
  titleColumnStyle: { padding: 10 }
});
const useStyles = makeOverrideableStyles("MultiInput", styles);
const MultiInput = (props) => {
  const { children, disabled, maxInputs, hide, isRequired, disable, require: require2, source, columnStyle, columnStyleItemLabel, columnStyleItemLabelSource, columnStyleTitleLabel } = props;
  const classes = useStyles(props);
  const { append, fields, insert, remove } = useFieldArray({ name: source });
  const formData = useWatch();
  const fieldValue = get(formData, source);
  const fieldIsRequired = isRequired || !!(require2 == null ? void 0 : require2(fieldValue, formData));
  const fieldIsDisabled = disabled || !!(disable == null ? void 0 : disable(fieldValue, formData));
  const fieldIsHidden = hide == null ? void 0 : hide(fieldValue, formData);
  const childArray = React.Children.toArray(children).filter((child) => !!child);
  const defaultValues = React.useMemo(() => childArray.reduce((acc, child) => __spreadValues({ [child.props.source]: "" }, acc), {}), [childArray]);
  const [translate] = useTranslation();
  const [indexData, setIndexData] = useState({ activeIndex: 0, totalItems: 1 });
  const { errors } = useFormState();
  const sourceError = get(errors, source);
  useEffect(() => {
    if (fields.length === 0 && !fieldIsHidden) {
      append(__spreadValues({}, defaultValues), { shouldFocus: false });
    }
  }, [fields, fieldIsHidden]);
  return columnStyle && !fieldIsHidden ? /* @__PURE__ */ jsxs("div", { className: classes.multiInputMainColumnStyle, children: [
    /* @__PURE__ */ jsxs("div", { className: classes.topBarColumnStyle, children: [
      /* @__PURE__ */ jsx(Typography, { component: "span", children: typeof columnStyleTitleLabel === "string" ? translate(columnStyleTitleLabel) : columnStyleTitleLabel }),
      /* @__PURE__ */ jsxs("span", { className: classes.buttonsColumnStyle, children: [
        /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            onClick: () => {
              insert(indexData.activeIndex + 1, defaultValues);
              setIndexData({ activeIndex: indexData.activeIndex + 1, totalItems: indexData.totalItems + 1 });
            },
            disabled: fieldIsDisabled || fields.length >= maxInputs,
            children: /* @__PURE__ */ jsx(Add, { id: "cuda-multi-input-add" })
          }
        ),
        /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            onClick: () => {
              remove(indexData.activeIndex);
              setIndexData({ activeIndex: indexData.activeIndex !== 0 ? indexData.activeIndex - 1 : 0, totalItems: indexData.totalItems - 1 });
            },
            disabled: fieldIsDisabled || fields.length === 1,
            children: /* @__PURE__ */ jsx(Delete, { id: "cuda-multi-input-remove" })
          }
        )
      ] })
    ] }),
    /* @__PURE__ */ jsx("div", { className: classes.leftColumnColumnStyle, children: fields.map(
      (value, index) => /* @__PURE__ */ jsx(
        "div",
        {
          className: indexData.activeIndex === index ? classes.titleColumnStyleActive : classes.titleColumnStyle,
          id: value.id,
          children: /* @__PURE__ */ jsx(
            Typography,
            {
              color: sourceError && sourceError[index] ? "error" : void 0,
              component: "span",
              onClick: () => {
                setIndexData({ activeIndex: index, totalItems: indexData.totalItems });
              },
              children: columnStyleItemLabelSource && get(fieldValue == null ? void 0 : fieldValue[index], columnStyleItemLabelSource) || columnStyleItemLabel && translate(columnStyleItemLabel) || "Item"
            },
            index
          )
        },
        value.id
      )
    ) }),
    fields.map(
      ({ id }, index) => indexData.activeIndex === index ? /* @__PURE__ */ jsx("div", { className: classes.inputGroup, children: /* @__PURE__ */ jsx("div", { className: classes.mainContentColumnStyle, children: /* @__PURE__ */ jsx("div", { className: classes.centerContainerColumnStyle, children: childArray.map(
        (child) => React.cloneElement(
          child,
          {
            key: child.props.source,
            disabled: fieldIsDisabled || child.props.disabled,
            isRequired: fieldIsRequired || child.props.isRequired,
            sourcePrefix: `${source}.${index}`
          }
        )
      ) }) }) }, id) : /* @__PURE__ */ jsx("div", { className: classes.inputGroupHidden, children: /* @__PURE__ */ jsx("div", { className: classes.mainContentColumnStyle, children: /* @__PURE__ */ jsx("div", { className: classes.centerContainerColumnStyle, children: childArray.map(
        (child) => React.cloneElement(
          child,
          {
            key: child.props.source,
            disabled: fieldIsDisabled || child.props.disabled,
            isRequired: fieldIsRequired || child.props.isRequired,
            sourcePrefix: `${source}.${index}`
          }
        )
      ) }) }) }, id)
    )
  ] }) : /* @__PURE__ */ jsx(Fragment, { children: !fieldIsHidden ? fields.map(
    ({ id }, index, fields2) => /* @__PURE__ */ jsxs("div", { className: classes.inputGroup, children: [
      index !== 0 && /* @__PURE__ */ jsx(Divider, { className: classes.divider }),
      childArray.map(
        (child) => React.cloneElement(
          child,
          {
            key: child.props.source,
            disabled: fieldIsDisabled || child.props.disabled,
            isRequired: fieldIsRequired || child.props.isRequired,
            sourcePrefix: `${source}.${index}`
          }
        )
      ),
      /* @__PURE__ */ jsxs("span", { className: classes.buttons, children: [
        /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            onClick: () => insert(index + 1, defaultValues),
            disabled: fieldIsDisabled || fields2.length >= maxInputs,
            children: /* @__PURE__ */ jsx(Add, { id: "cuda-multi-input-add" })
          }
        ),
        /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            onClick: () => remove(index),
            disabled: fieldIsDisabled || fields2.length === 1,
            children: /* @__PURE__ */ jsx(Subtract, { id: "cuda-multi-input-remove" })
          }
        )
      ] })
    ] }, id)
  ) : null });
};
var MultiInput_default = MultiInput;
export {
  MultiInput_default as default
};
