const DAY_AS_MILLISECONDS = 24 * 60 * 60 * 1e3;
const daysBetweenAbs = (date1, date2) => Math.abs(daysBetween(date1, date2));
const daysBetween = (date1, date2) => {
  const diffInMilliseconds = date1.getTime() - date2.getTime();
  return diffInMilliseconds / DAY_AS_MILLISECONDS;
};
export {
  DAY_AS_MILLISECONDS,
  daysBetween,
  daysBetweenAbs
};
