import { usePersistedState } from "@cuda-react/core";
import { useCallback } from "react";
var useDashboards_default = (pageId, defaultDashboards, remoteStoredDashboards, onDashboardsChange) => {
  const [localStoredDashboards = [], setLocalDashboards] = usePersistedState(pageId, defaultDashboards);
  const [activeDashboardName = "", setActiveDashboardName] = usePersistedState(pageId + "_active", "");
  const useLocal = !remoteStoredDashboards;
  const savedDashboards = useLocal ? localStoredDashboards : remoteStoredDashboards;
  const resolvedDashboards = !Array.isArray(savedDashboards) || (savedDashboards == null ? void 0 : savedDashboards.length) === 0 ? defaultDashboards : savedDashboards;
  const activeDashboard = (resolvedDashboards == null ? void 0 : resolvedDashboards.find((dashboard) => (dashboard == null ? void 0 : dashboard.name) === activeDashboardName)) || (resolvedDashboards == null ? void 0 : resolvedDashboards[0]) || {};
  const addUpdateDashboard = useCallback((newDashboardValue, originalDashboardName) => {
    const dashboardToUpdateIndex = resolvedDashboards.findIndex((dashboard) => dashboard.name === originalDashboardName);
    let newDashboards;
    if (dashboardToUpdateIndex >= 0) {
      newDashboards = [
        ...resolvedDashboards.slice(0, dashboardToUpdateIndex),
        newDashboardValue,
        ...resolvedDashboards.slice(dashboardToUpdateIndex + 1)
      ];
    } else {
      newDashboards = [...resolvedDashboards, newDashboardValue];
    }
    if (useLocal) {
      setLocalDashboards(newDashboards);
      setActiveDashboardName(newDashboardValue == null ? void 0 : newDashboardValue.name);
    } else if (onDashboardsChange) {
      onDashboardsChange(newDashboards).then(() => setActiveDashboardName(newDashboardValue == null ? void 0 : newDashboardValue.name));
    }
  }, [pageId, onDashboardsChange, useLocal, resolvedDashboards]);
  const deleteDashboard = useCallback((dashboardName) => {
    var _a, _b;
    const dashboardToRemoveIndex = resolvedDashboards.findIndex((dashboard) => dashboard.name === dashboardName);
    if (dashboardToRemoveIndex >= 0) {
      const newDashboards = [
        ...resolvedDashboards.slice(0, dashboardToRemoveIndex),
        ...resolvedDashboards.slice(dashboardToRemoveIndex + 1)
      ];
      if (useLocal) {
        setLocalDashboards(newDashboards);
        setActiveDashboardName(((_a = newDashboards[dashboardToRemoveIndex]) == null ? void 0 : _a.name) || ((_b = newDashboards[dashboardToRemoveIndex - 1]) == null ? void 0 : _b.name));
      } else if (onDashboardsChange) {
        onDashboardsChange(newDashboards).then(() => {
          var _a2, _b2;
          setActiveDashboardName(((_a2 = newDashboards[dashboardToRemoveIndex]) == null ? void 0 : _a2.name) || ((_b2 = newDashboards[dashboardToRemoveIndex - 1]) == null ? void 0 : _b2.name));
        });
      }
    }
  }, [pageId, onDashboardsChange, useLocal, resolvedDashboards]);
  return {
    activeDashboard,
    dashboards: resolvedDashboards,
    setActiveDashboard: setActiveDashboardName,
    addUpdateDashboard,
    deleteDashboard
  };
};
export {
  useDashboards_default as default
};
