var ja_default = {
  translation: {
    tesseract: {
      productName: "Barracuda SecureEdge",
      dashboard: {
        menuTitle: "DASHBOARD",
        pageTitle: "Dashboard",
        emptyTemplate: "\u7A7A",
        cards: {
          gateway: {
            cardTitle: "Edge Service\u30B9\u30C6\u30FC\u30BF\u30B9",
            online: "\u30AA\u30F3\u30E9\u30A4\u30F3",
            pending: "\u4FDD\u7559\u4E2D",
            degraded: "\u30C0\u30A6\u30F3\u30B0\u30EC\u30FC\u30C9\u6E08\u307F",
            offline: "\u30AA\u30D5\u30E9\u30A4\u30F3"
          },
          iotStatusCard: {
            healthy: "\u30AA\u30F3\u30E9\u30A4\u30F3",
            pending: "\u4FDD\u7559\u4E2D",
            connecting: "\u63A5\u7D9A\u4E2D",
            degraded: "\u30C0\u30A6\u30F3\u30B0\u30EC\u30FC\u30C9\u6E08\u307F",
            offline: "\u30AA\u30D5\u30E9\u30A4\u30F3",
            name: "\u540D\u524D",
            gateway: "\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4",
            aggregateState: "\u30B9\u30C6\u30FC\u30BF\u30B9",
            type: "\u30E2\u30C7\u30EB"
          },
          status: {
            site: {
              tabTitle: "\u30B5\u30A4\u30C8\u30B9\u30C6\u30FC\u30BF\u30B9"
            },
            iot: {
              tabTitle: "IoT \u30B9\u30C6\u30FC\u30BF\u30B9"
            },
            applianceConfiguration: {
              tabTitle: "\u6A5F\u5668\u8A2D\u5B9A \u30B9\u30C6\u30FC\u30BF\u30B9",
              completed: "\u5B8C\u4E86",
              connecting: "\u63A5\u7D9A\u4E2D",
              failed: "\u5931\u6557",
              pending: "\u4FDD\u7559\u4E2D"
            }
          },
          vpn: {
            locations: {
              tabTitle: "\u30DE\u30C3\u30D7",
              gateways: {
                label: "Edge Services",
                linkedSites: "\u63A5\u7D9A\u3055\u308C\u305F\u30B5\u30A4\u30C8/IoT",
                fields: {
                  name: "\u540D\u524D",
                  sites: "\u63A5\u7D9A\u3055\u308C\u305F\u30B5\u30A4\u30C8",
                  iot: "\u63A5\u7D9A\u3055\u308C\u305F IoT",
                  vwan: "\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9",
                  hub: "\u30CF\u30D6",
                  region: "\u5730\u57DF",
                  size: "\u30B5\u30A4\u30BA"
                }
              },
              sites: {
                label: "\u30B5\u30A4\u30C8",
                linkedHubs: "\u63A5\u7D9A\u3055\u308C\u305F\u30A8\u30C3\u30B8Service",
                fields: {
                  name: "\u540D\u524D",
                  location: "\u5834\u6240",
                  gateway: "Edge Service",
                  providers: "\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u6570"
                }
              },
              iot: {
                label: "IoT",
                linkedHubs: "\u63A5\u7D9A\u3055\u308C\u305F\u30A8\u30C3\u30B8Service",
                fields: {
                  name: "\u540D\u524D",
                  location: "\u5834\u6240",
                  gateway: "Edge Service",
                  providers: "\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u6570"
                }
              },
              standaloneSites: {
                label: "\u30B9\u30BF\u30F3\u30C9\u30A2\u30ED\u30F3\u30B5\u30A4\u30C8"
              }
            },
            status: {
              tabTitle: "\u30C8\u30F3\u30CD\u30EB\u30B9\u30C6\u30FC\u30BF\u30B9",
              ok: "OK",
              warning: "\u8B66\u544A",
              error: "\u30A8\u30E9\u30FC",
              online: "\u30AA\u30F3\u30E9\u30A4\u30F3",
              pending: "\u4FDD\u7559\u4E2D",
              connecting: "\u63A5\u7D9A\u4E2D",
              degraded: "\u30C0\u30A6\u30F3\u30B0\u30EC\u30FC\u30C9\u6E08\u307F",
              offline: "\u30AA\u30D5\u30E9\u30A4\u30F3"
            },
            offline: {
              tabTitle: "\u30AA\u30D5\u30E9\u30A4\u30F3\u30C8\u30F3\u30CD\u30EB",
              gatewayName: "\u30A8\u30C3\u30B8Service\u540D",
              gatewayStatus: "Edge Service\u30B9\u30C6\u30FC\u30BF\u30B9",
              siteName: "\u30B5\u30A4\u30C8/IoT\u540D",
              siteStatus: "\u30B5\u30A4\u30C8/IoT \u30B9\u30C6\u30FC\u30BF\u30B9\u30B9\u30C6\u30FC\u30BF\u30B9",
              siteLocation: "\u30B5\u30A4\u30C8/IoT\u306E\u5834\u6240",
              noData: "\u30AA\u30D5\u30E9\u30A4\u30F3\u306E\u30C8\u30F3\u30CD\u30EB\u306F\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002"
            }
          },
          recentEvents: {
            adminTabTitle: "\u6700\u8FD1\u306E\u7BA1\u7406\u30A4\u30D9\u30F3\u30C8",
            systemTabTitle: "\u6700\u8FD1\u306E\u30B7\u30B9\u30C6\u30E0\u30A4\u30D9\u30F3\u30C8",
            latest: "\u6700\u65B0 {{length}}"
          },
          categories: {
            status: "\u30B9\u30C6\u30FC\u30BF\u30B9"
          }
        },
        status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
        security: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3",
        webFilter: "\u30A6\u30A7\u30D6\u30D5\u30A3\u30EB\u30BF\u30FC",
        filters: {
          timeframe: "\u6642\u9593\u67A0",
          appliance: "Edge Service/\u30B5\u30A4\u30C8",
          noAppliances: "\u306A\u3057",
          timeframes: {
            lastDay: "\u904E\u53BB24\u6642\u9593",
            lastMonth: "\u5148\u6708",
            lastWeek: "\u5148\u9031",
            lastQuarter: "\u76F4\u524D3\u30F5\u6708\u9593"
          }
        },
        ztnaDashboard: "\u30BC\u30ED\u30C8\u30E9\u30B9\u30C8\u30A2\u30AF\u30BB\u30B9",
        ztnaUserDashboard: "\u30E6\u30FC\u30B6\u30FCDashboard"
      },
      network: {
        pageTitle: "\u30A4\u30F3\u30D5\u30E9\u30B9\u30C8\u30E9\u30AF\u30C1\u30E3",
        vmActivation: "Vm\u30A2\u30AF\u30C6\u30A3\u30D9\u30FC\u30B7\u30E7\u30F3",
        vmActivationMessage: "\u3059\u3079\u3066\u306E\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u3067\u306E\u81EA\u52D5 VM \u30A2\u30AF\u30C6\u30A3\u30D9\u30FC\u30B7\u30E7\u30F3\u306E\u8ACB\u6C42\u660E\u7D30\u3092\u767B\u9332\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
        gateways: "Edge Services",
        sites: "\u30B5\u30A4\u30C8",
        iot: {
          menuTitle: "IoT",
          noData: {
            iconHeader: "IoT \u88C5\u7F6E\u3092\u8FFD\u52A0\u3057\u3066\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
            labelButton: "IoT \u88C5\u7F6E\u3092\u8FFD\u52A0\u3057\u3066\u304F\u3060\u3055\u3044",
            textHeader: "IoT \u88C5\u7F6E\u3068\u306F\u4F55\u3067\u3059\u304B",
            text: "IoT \u88C5\u7F6E\u3068\u306FBarracuda Secure Connector \u6A5F\u5668\u306B\u3088\u3063\u3066\u63A5\u7D9A\u3055\u308C\u305F (\u3055\u3089\u306B\u4FDD\u8B77\u3055\u308C\u305F)\u30E2\u30CE\u306E\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u306E\u5834\u6240\u306E\u3053\u3068\u3067\u3059\u3002Secure Connector \u6A5F\u5668\u306F\u30CF\u30FC\u30C9\u30A6\u30A7\u30A2\u3068\u3057\u3066\u307E\u305F\u306F\u4EEE\u60F3\u6A5F\u5668\u306E\u3044\u305A\u308C\u304B\u30CB\u30FC\u30BA\u3092\u6700\u9069\u306B\u6E80\u305F\u3059\u65B9\u3068\u3057\u3066\u914D\u5099\u53EF\u80FD\u3067\u3059\u3002"
          }
        },
        settings: "\u8A2D\u5B9A",
        firewalls: "CloudGen Firewalls",
        sdwanConnector: {
          menuTitle: "\u30B3\u30CD\u30AF\u30BF\u30FC",
          pageTitle: "\u30B3\u30CD\u30AF\u30BF",
          table: {
            status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
            name: "\u540D\u524D",
            gateway: "Edge Service",
            internalResources: "\u5185\u90E8\u30EA\u30BD\u30FC\u30B9",
            provisioningDate: "\u30D7\u30ED\u30D3\u30B8\u30E7\u30CB\u30F3\u30B0\u65E5",
            servers: "\u30B5\u30FC\u30D0\u30FC",
            ellipsisMenu: {
              downloadLinux: "Linux \u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9",
              downloadWindows: "Windows \u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9",
              token: "\u767B\u9332\u30C8\u30FC\u30AF\u30F3\u3092\u751F\u6210"
            },
            hostnames: "\u30DB\u30B9\u30C8\u540D"
          },
          delete: {
            body: "\u3053\u306E\u30B3\u30CD\u30AF\u30BF\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u3044\u306E\u3067\u3059\u304B\u3002",
            title: "\u30B3\u30CD\u30AF\u30BF\u3092\u524A\u9664 {{name}}"
          },
          dialog: {
            add: "\u8FFD\u52A0",
            menuItemDisabled: "\u30B3\u30CD\u30AF\u30BF\u306BEdge Service\u304C\u5FC5\u8981\u3067\u3059",
            basic: {
              name: "\u540D\u524D",
              sectionTitle: "\u57FA\u672C\u60C5\u5831",
              tabTitle: "\u57FA\u672C"
            },
            create: {
              dialogTitle: "\u30B3\u30CD\u30AF\u30BF\u3092\u8FFD\u52A0"
            },
            edit: {
              dialogTitle: "\u30B3\u30CD\u30AF\u30BF\u3092\u7DE8\u96C6"
            },
            poolNetwork: {
              sectionTitle: "\u30EB\u30FC\u30C6\u30A3\u30F3\u30B0\u3055\u308C\u305FIP",
              descriptionMessage: "\u6700\u521D\u306E\u30B3\u30CD\u30AF\u30BF\u3092\u8FFD\u52A0\u3059\u308B\u524D\u306B\u3001\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u7BC4\u56F2\u3092\u8A2D\u5B9A\u3057\u3066\u304A\u304F\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u4F7F\u7528\u3057\u3066\u30B3\u30CD\u30AF\u30BF\u306E\u80CC\u5F8C\u306B\u3042\u308B\u30EA\u30BD\u30FC\u30B9\u306BIP\u3092\u5272\u308A\u5F53\u3066\u307E\u3059\u3002",
              network: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8 \u30CD\u30C3\u30C8\u30EF\u30FC\u30AF"
            },
            general: {
              sectionTitle: "\u5168\u822C",
              name: "\u540D\u524D",
              description: "\u5185\u5BB9",
              appliance: "Edge Service"
            },
            servers: {
              sectionTitle: "\u30B5\u30FC\u30D0\u30FC",
              serviceName: "\u30B5\u30FC\u30D0\u30FC",
              itemName: "\u30B5\u30FC\u30D0\u30FC",
              hostname: "\u30DB\u30B9\u30C8\u540D",
              internalIp: "\u5185\u90E8 IP",
              category: "\u30AB\u30C6\u30B4\u30EA\u30FC"
            },
            summary: {
              sectionTitle: "\u8981\u7D04",
              name: "\u30B3\u30CD\u30AF\u30BF\u30FC {{connector}}\u306E\u8981\u7D04",
              connectorName: "\u30B3\u30CD\u30AF\u30BF\u30FC\u540D",
              serviceName: "Services\u540D",
              hostname: "\u30DB\u30B9\u30C8\u540D"
            },
            completed: {
              sectionTitle: "\u30B3\u30CD\u30AF\u30BF\u300C{{connector}}\u300D\u306F\u6B63\u5E38\u306B\u4F5C\u6210\u3055\u308C\u307E\u3057\u305F",
              sectionTitleNextSteps: "\u6B21\u306E\u624B\u9806",
              sectionMessage: "\u4EE5\u4E0B\u306E\u624B\u9806\u306B\u5F93\u3063\u3066\u4F7F\u7528\u3092\u59CB\u3081\u307E\u3059",
              sectionSteps: "\u30B3\u30CD\u30AF\u30BF\u30FC\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u3057\u3066\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u3057\u3066\u304F\u3060\u3055\u3044",
              sectionSteps2: "OTP\u30C8\u30FC\u30AF\u30F3\u3092\u30B3\u30CD\u30AF\u30BF\u30FC\u306B\u4ED8\u4E0E\u3057\u307E\u3059:",
              sectionSteps3: "\u63A5\u7D9A\u304C\u78BA\u7ACB\u3055\u308C\u305F\u3053\u3068\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044",
              downloadButtonLinux: "Linux\u7248\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9",
              downloadButtonWindows: "Windows\u7248\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9"
            },
            description: {
              poolNetwork: {
                network: "CIDR\u30CE\u30FC\u30C6\u30FC\u30B7\u30E7\u30F3\u3067\u306E\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u7BC4\u56F2"
              },
              general: {
                sectionTitle: "\u5168\u822C",
                name: "\u304A\u4F7F\u3044\u306E\u30B3\u30CD\u30AF\u30BF\u306E\u56FA\u6709\u540D\u3002",
                description: "\u30B3\u30CD\u30AF\u30BF\u306E\u7C21\u7565\u306A\u8AAC\u660E\u3002",
                appliance: "\u8A2D\u5B9A\u3057\u305F\u3044Edge Service\u6A5F\u5668\u3092\u9078\u629E\u3057\u307E\u3059\u3002"
              },
              servers: {
                sectionTitle: "\u30B5\u30FC\u30D0\u30FC",
                serviceName: "\u30B5\u30FC\u30D0\u30FC\u306E\u56FA\u6709\u540D\u3002",
                hostname: "\u30B5\u30FC\u30D0\u30FC\u95A2\u9023\u306E\u30DB\u30B9\u30C8\u540D\u3002",
                internalIp: "\u30B5\u30FC\u30D0\u30FC\u95A2\u9023\u306E\u5185\u90E8 IP\u3002",
                category: "\u30AB\u30C6\u30B4\u30EA\u30FC"
              }
            },
            token: {
              dialogTitle: "\u767B\u9332\u30C8\u30FC\u30AF\u30F3\u3092\u751F\u6210",
              message: '\u7528\u306B\u65B0\u3057\u3044\u30C8\u30FC\u30AF\u30F3\u3092\u751F\u6210"{{sec}}"',
              subLabel: "\u30B3\u30CD\u30AF\u30BF\u30C8\u30FC\u30AF\u30F3"
            }
          },
          noData: {
            iconHeader: "\u30B3\u30CD\u30AF\u30BF\u30FC\u3092\u8FFD\u52A0\u3057\u3066\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
            labelButton: "\u30B3\u30CD\u30AF\u30BF\u30FC\u3092\u8FFD\u52A0",
            textHeader: "\u30B3\u30CD\u30AF\u30BF\u30FC\u3068\u306F\u4F55\u304B",
            text: "\u30B9\u30BF\u30C3\u30D5\u3088\u3046\u306B\u7279\u5B9A\u306Eservices\u3092\u5229\u7528\u53EF\u80FD\u306B\u3059\u308B\u306E\u306F\u30DF\u30B9\u3092\u72AF\u3057\u3084\u3059\u3044\u4F5C\u696D\u3067\u3059\u3002<0 /> With a connector, you can easily connect Windows services and servers right to your network and make them available to your workforce without any need for a dedicated site device."
          }
        },
        customCategories: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC"
      },
      endpoint: {
        pageTitle: "\u30BC\u30ED\u30C8\u30E9\u30B9\u30C8\u30A2\u30AF\u30BB\u30B9",
        devices: "\u88C5\u7F6E",
        settings: "\u8A2D\u5B9A",
        zeroTrustAccess: "\u30BC\u30ED\u30C8\u30E9\u30B9\u30C8\u30A2\u30AF\u30BB\u30B9",
        zeroTrustSettingsTitle: "\u8A2D\u5B9A",
        pointToSite: "\u30B5\u30A4\u30C8\u3092\u6307\u3057\u793A\u3059",
        users: "\u767B\u9332\u6E08\u307F\u30E6\u30FC\u30B6\u30FC",
        appCatalog: {
          pageTitle: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u30AB\u30BF\u30ED\u30B0",
          textHeader: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u30AB\u30BF\u30ED\u30B0\u3068\u306F\u4F55\u304B",
          text: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u30AB\u30BF\u30ED\u30B0\u3067\u306F\u76F4\u3050\u30A2\u30AF\u30BB\u30B9\u3067\u304D\u308B\u3088\u3046\u306BSecureEdge \u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u30A2\u30D7\u30EA\u306B\u8868\u793A\u3055\u308C\u308B\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u5B9A\u7FA9\u3067\u304D\u307E\u3059\u3002<0 />\u3053\u306E\u30A2\u30AF\u30BB\u30B9\u6A5F\u80FD\u306F\u5916\u90E8\u3068\u5185\u90E8\u306E\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3068\u3082\u306B\u6A5F\u80FD\u3057\u307E\u3059\u3002<br/><br/>\u3053\u306E\u30BB\u30AF\u30B7\u30E7\u30F3\u306F\u9593\u3082\u306A\u304F\u5229\u7528\u53EF\u80FD\u306B\u306A\u308A\u307E\u3059\u3002",
          startText: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u30AB\u30BF\u30ED\u30B0\u3067\u306F\u30AF\u30A4\u30C3\u30AF\u30A2\u30AF\u30BB\u30B9\u7528\u306ESecureEdge\u30A2\u30AF\u30BB\u30B9 \u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u30A2\u30D7\u30EA\u306B\u8868\u793A\u3055\u305B\u308B\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u6C7A\u3081\u3066\u304A\u304F\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002<0 />\u3053\u306E\u30AF\u30A4\u30C3\u30AF\u30A2\u30AF\u30BB\u30B9\u6A5F\u80FD\u306F\u5916\u90E8\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306B\u3082\u5185\u90E8\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306B\u3082\u6709\u52B9\u3067\u3059\u3002",
          startTextHeader: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u8FFD\u52A0\u3057\u3066\u958B\u59CB\u3057\u307E\u3057\u3087\u3046\uFF01",
          buttonText: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u8FFD\u52A0",
          iconText: "\u30A2\u30A4\u30B3\u30F3",
          name: "\u540D\u524D",
          application: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
          launchURL: "URL\u516C\u958B",
          visibility: "\u53EF\u8996\u6027",
          create: {
            title: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u8FFD\u52A0",
            displayName: "\u30C7\u30A3\u30B9\u30D7\u30EC\u30A4\u540D",
            displayIcon: "\u30C7\u30A3\u30B9\u30D7\u30EC\u30A4 \u30A2\u30A4\u30B3\u30F3",
            associatedApplication: "\u95A2\u9023\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
            users: "\u30E6\u30FC\u30B6\u30FC",
            groups: "\u30B0\u30EB\u30FC\u30D7",
            descriptions: {
              icon: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u30A2\u30A4\u30B3\u30F3\u3092\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u3057\u307E\u3059\u3002",
              iconInfo: "\u5BFE\u5FDC\u30D5\u30A1\u30A4\u30EB\u5F62\u5F0F\uFF1AJPEG\u3001PNG\u3001GIF\u3001SVG\u3002",
              name: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306E\u56FA\u6709\u540D",
              application: "\u95A2\u9023\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u9078\u3073\u307E\u3059",
              launchUrl: "\u7ACB\u3061\u4E0A\u3052\u7528URL\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044",
              visibility: "\u30EA\u30F3\u30AF\u306E\u53EF\u8996\u6027\u3092\u9078\u3073\u307E\u3059",
              users: "\u9078\u629E\u3055\u308C\u305F\u30A2\u30D7\u30EA\u3078\u306E\u30A2\u30AF\u30BB\u30B9\u304C\u8A8D\u3081\u3089\u308C\u308B\u3079\u304D\u30E6\u30FC\u30B6\u30FC\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u3084\u30B0\u30EB\u30FC\u30D7\u304C\u306A\u3051\u308C\u3070\u3001\u9078\u629E\u3055\u308C\u305F\u30A2\u30D7\u30EA\u306F\u5168\u54E1\u304C\u30A2\u30AF\u30BB\u30B9\u3067\u304D\u307E\u3059\u3002",
              groups: "\u9078\u629E\u3055\u308C\u305F\u30A2\u30D7\u30EA\u3078\u306E\u30A2\u30AF\u30BB\u30B9\u304C\u8A8D\u3081\u3089\u308C\u308B\u3079\u304D\u30B0\u30EB\u30FC\u30D7\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u3084\u30B0\u30EB\u30FC\u30D7\u304C\u306A\u3051\u308C\u3070\u3001\u9078\u629E\u3055\u308C\u305F\u30A2\u30D7\u30EA\u306F\u5168\u54E1\u304C\u30A2\u30AF\u30BB\u30B9\u3067\u304D\u307E\u3059\u3002"
            }
          },
          edit: {
            title: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306E\u7DE8\u96C6"
          },
          delete: {
            body: "\u3053\u306E\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u672C\u5F53\u306B\u524A\u9664\u3057\u3066\u304B\u307E\u3044\u307E\u305B\u3093\u304B",
            title: "\u524A\u9664 {{name}}"
          },
          validate: {
            userGroupRequired: "\u5C11\u306A\u304F\u3068\u30821\u4EBA\u306E\u30E6\u30FC\u30B6\u30FC\u307E\u305F\u306F\u30B0\u30EB\u30FC\u30D7\u304C\u5FC5\u8981\u3067\u3059",
            tenantIdRequired: "\u30E6\u30FC\u30B6\u30FC\u3068\u30B0\u30EB\u30FC\u30D7\u304C\u5229\u7528\u3067\u304D\u307E\u305B\u3093\u3002\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3 > \u8A2D\u5B9A\u30DA\u30FC\u30B8\u3067\u6700\u521D\u306B\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u3092\u8FFD\u52A0\u3057\u3066\u304F\u3060\u3055\u3044\u3002"
          }
        },
        enrollEmailDialog: {
          addEmail: "\u30E6\u30FC\u30B6\u30FC\u307E\u305F\u306FE\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u8FFD\u52A0",
          body: "\u672C\u6587",
          customMessage: "\u62DB\u5F85\u30E1\u30C3\u30BB\u30FC\u30B8",
          defaultContent: '\u62DD\u5553 \n\nYou have been added by your organisation to Barracuda SecureEdge User Connectivity. \n\nTo gain access, follow these instructions: \n\n1. Go to {{link}}\n2. Download and install the Barracuda VPN client.\n3. Click "Enroll now" to complete the process.\n\nYou are now able to connect to Barracuda SecureEdge from your Windows or Mac OS clients.',
          defaultSubject: "Barracuda SecureEdge\u306B\u767B\u9332\u3055\u308C\u307E\u3057\u305F\u3002",
          zeroTrustSubject: "Barracuda SecureEdge \u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3092\u767B\u9332\u3057\u3066\u3044\u305F\u3060\u304F\u305F\u3081\u306E\u3054\u62DB\u5F85\u3067\u3059",
          emailTitle: "\u30E6\u30FC\u30B6\u30FC\u3092\u767B\u9332",
          resendEmailTitle: "\u767B\u9332\u6848\u5185\u3092\u518D\u9001",
          noHtml: "\u4EE5\u4E0B\u306E\u7279\u6B8A\u6587\u5B57\u306F\u4F7F\u3046\u3053\u3068\u304C\u3067\u304D\u307E\u305B\u3093: <>",
          recipients: "\u9001\u4FE1\u5148",
          users: "\u30E6\u30FC\u30B6\u30FC",
          send: "\u9001\u4FE1",
          subject: "\u4EF6\u540D",
          enrollMessage: "SecureEdge\u30A2\u30AF\u30BB\u30B9\u306B\u767B\u9332\u3059\u308B\u30E6\u30FC\u30B6\u30FC\u307E\u305F\u306F\u30B0\u30EB\u30FC\u30D7\u3092\u9078\u3073\u307E\u3059\u3002\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3092\u521D\u3081\u3066\u7ACB\u3061\u4E0A\u3052\u305F\u3068\u304D\u306B\u30E6\u30FC\u30B6\u30FC\u306F\u30B5\u30A4\u30F3\u30A4\u30F3\u306E\u5FC5\u8981\u304C\u3042\u308A\u307E\u305B\u3093\u3002\u3053\u306E\u9032\u6357\u306F\u30A2\u30AF\u30BB\u30B9 > \u30E6\u30FC\u30B6\u30FC\u30DA\u30FC\u30B8\u3067\u8FFD\u8DE1\u3067\u304D\u307E\u3059\u3002<0>\u3042\u3068{{count}}\u5E2D\u6B8B\u3063\u3066\u3044\u307E\u3059\u3002</0>",
          sendMessage: "\u6B21\u306E\u30E1\u30C3\u30BB\u30FC\u30B8\u306F\u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u3068\u30B0\u30EB\u30FC\u30D7\u306B\u9001\u3089\u308C\u307E\u3059\u3002\u62DB\u5F85\u72B6\u3092\u672C\u5F53\u306B\u767A\u9001\u3057\u3066\u3082\u3088\u3044\u3067\u3059\u304B\u3002",
          successHeader_plural: "\u3054\u767B\u9332\u306E\u62DB\u5F85\u72B6\u306F{{count}}\u4EBA\u306E\u30E6\u30FC\u30B6\u30FC\u306B\u9001\u4FE1\u4E2D\u3067\u3059\uFF01",
          successHeader: "\u3054\u767B\u9332\u306E\u62DB\u5F85\u72B6\u306F{{count}}\u4EBA\u306E\u30E6\u30FC\u30B6\u30FC\u306B\u9001\u4FE1\u4E2D\u3067\u3059\uFF01",
          successMessage_plural: "\u30E6\u30FC\u30B6\u30FC\u30C6\u30FC\u30D6\u30EB\u3067\u5404\u30E6\u30FC\u30B6\u30FC\u306E\u767B\u9332\u72B6\u6CC1\u3092\u78BA\u8A8D\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
          successMessage: "\u30E6\u30FC\u30B6\u30FC\u30C6\u30FC\u30D6\u30EB\u3067\u3053\u306E\u30E6\u30FC\u30B6\u30FC\u306E\u767B\u9332\u72B6\u6CC1\u3092\u78BA\u8A8D\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
          emailTemplate: {
            hello: "{{username}}\u6BBF\uFF01",
            message: "Barracuda SecureEdge \u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3092\u767B\u9332\u3057\u3066\u3044\u305F\u3060\u304F\u305F\u3081\u306E\u3054\u62DB\u5F85\u3067\u3059\u3002\u3053\u306E\u3048\u30FC\u3058\u3087\u3093\u3067\u30A8\u30F3\u30BF\u30FC\u30D7\u30E9\u30A4\u30BA\u30EA\u30BD\u30FC\u30B9\u3078\u306E\u30A2\u30AF\u30BB\u30B9\u306E\u5B89\u5168\u304C\u78BA\u4FDD\u3055\u308C\u3001\u304A\u4F7F\u3044\u306E\u88C5\u7F6E\u306F\u30B5\u30A4\u30D0\u30FC\u8105\u5A01\u304B\u3089\u4FDD\u8B77\u3055\u308C\u307E\u3059\u3002",
            clickStart: '\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u3066\u304F\u3060\u3055\u3044"Start" to start the self-enrollment process.',
            start: "\u30B9\u30BF\u30FC\u30C8",
            copyLink: "\u307E\u305F\u306F\u3001SecureEdge \u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u306B\u4E0B\u8A18\u306E\u30EA\u30F3\u30AF\u3092\u76F4\u63A5\u30B3\u30D4\u30FC\u3057\u3066\u3082\u767B\u9332\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
            exampleLink: "https://se.barracudanetworks.com/zerotrust/enroll/?uuid=22fc1190-8d5a-4b8e-803f-fd8819408305&endpoint=https://se.barracudanetworks.com"
          },
          addUsers: "\u30E6\u30FC\u30B6\u30FC\u8FFD\u52A0",
          addGroups: "\u30B0\u30EB\u30FC\u30D7\u8FFD\u52A0",
          userNotificationSectionTitle: "\u30E6\u30FC\u30B6\u30FC\u901A\u77E5 (\u30AA\u30D7\u30B7\u30E7\u30F3)",
          enrollmentEmail: {
            label: "\u767B\u9332\u7528\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
            enabled: "\u306F\u3044 - \u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u30EA\u30F3\u30AF\u3092\u542B\u3080",
            disabled: "\u3044\u3044\u3048 - \u30B5\u30A4\u30EC\u30F3\u30C8\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB"
          },
          groups: "\u30B0\u30EB\u30FC\u30D7",
          enroll: "\u767B\u9332",
          enrollMessageOld: "SecureEdge\u30A2\u30AF\u30BB\u30B9\u306B\u767B\u9332\u3059\u308B\u30E6\u30FC\u30B6\u30FC\u307E\u305F\u306F\u30B0\u30EB\u30FC\u30D7\u3092\u9078\u3073\u307E\u3059\u3002\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3092\u521D\u3081\u3066\u7ACB\u3061\u4E0A\u3052\u305F\u3068\u304D\u306B\u30E6\u30FC\u30B6\u30FC\u306F\u30B5\u30A4\u30F3\u30A4\u30F3\u306E\u5FC5\u8981\u304C\u3042\u308A\u307E\u305B\u3093\u3002\u3053\u306E\u9032\u6357\u306F\u30A2\u30AF\u30BB\u30B9 > \u30E6\u30FC\u30B6\u30FC\u30DA\u30FC\u30B8\u3067\u8FFD\u8DE1\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
          enrollMessage_plural: "SecureEdge\u30A2\u30AF\u30BB\u30B9\u306B\u767B\u9332\u3059\u308B\u30E6\u30FC\u30B6\u30FC\u307E\u305F\u306F\u30B0\u30EB\u30FC\u30D7\u3092\u9078\u3073\u307E\u3059\u3002\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3092\u521D\u3081\u3066\u7ACB\u3061\u4E0A\u3052\u305F\u3068\u304D\u306B\u30E6\u30FC\u30B6\u30FC\u306F\u30B5\u30A4\u30F3\u30A4\u30F3\u306E\u5FC5\u8981\u304C\u3042\u308A\u307E\u305B\u3093\u3002\u3053\u306E\u9032\u6357\u306F\u30A2\u30AF\u30BB\u30B9 > \u30E6\u30FC\u30B6\u30FC\u30DA\u30FC\u30B8\u3067\u8FFD\u8DE1\u3067\u304D\u307E\u3059\u3002<0>\u3042\u3068{{count}}\u5E2D\u6B8B\u3063\u3066\u3044\u307E\u3059\u3002</0>",
          resolvedUsers: "\u3042\u306A\u305F\u304C\u9078\u3093\u3060\u30E6\u30FC\u30B6\u30FC\uFF0F\u30B0\u30EB\u30FC\u30D7\u306B\u306F\u6B8B\u308A\u5E2D\u6570\u3088\u308A\u591A\u3044\u30E6\u30FC\u30B6\u30FC\u304C\u3044\u307E\u3059\u3002\u4E0B\u306E\u30E1\u30CB\u30E5\u30FC\u3067\u4ECA\u767B\u9332\u3055\u305B\u305F\u304F\u306A\u3044\u30E6\u30FC\u30B6\u30FC\u3092\u524A\u9664\u3059\u308B\u304B\u3001\u5143\u306B\u623B\u3063\u3066\u5225\u306E\u30E6\u30FC\u30B6\u30FC\uFF0F\u30B0\u30EB\u30FC\u30D7\u3092\u9078\u3093\u3067\u304F\u3060\u3055\u3044\u3002",
          successHeaderOld: "\u3054\u767B\u9332\u306E\u62DB\u5F85\u72B6\u306F'{{users}}'\u3055\u3093\u5B9B\u306B\u9001\u4FE1\u4E2D\u3067\u3059\uFF01",
          successHeaderOld_plural: "\u3054\u767B\u9332\u306E\u62DB\u5F85\u72B6\u306F{{count}}\u4EBA\u306E\u30E6\u30FC\u30B6\u30FC\u306B\u9001\u4FE1\u4E2D\u3067\u3059\uFF01",
          noEmailSuccessHeader: "{{count}}\u4EBA\u306E\u30E6\u30FC\u30B6\u30FC\u3092\u767B\u9332\u4E2D\u3067\u3059\uFF01",
          noEmailSuccessHeader_plural: "{{count}}\u4EBA\u306E\u30E6\u30FC\u30B6\u30FC\u3092\u767B\u9332\u4E2D\u3067\u3059\uFF01",
          validate: {
            userGroupRequired: "\u5C11\u306A\u304F\u3068\u30821\u4EBA\u306E\u30E6\u30FC\u30B6\u30FC\u307E\u305F\u306F\u30B0\u30EB\u30FC\u30D7\u304C\u5FC5\u8981\u3067\u3059",
            userRequired: "\u5C11\u306A\u304F\u3068\u3082\u30E6\u30FC\u30B6\u30FC\u4E00\u540D\u304C\u5FC5\u8981\u3067\u3059",
            seatLimit: "{{currentCount}}\u30E6\u30FC\u30B6\u30FC\u3092\u767B\u9332\u3057\u3066\u304F\u3060\u3055\u3044(\u3042\u3068{{maxCount}}\u5E2D\u7A7A\u304D\u304C\u3042\u308A\u307E\u3059)"
          },
          reenrollMessage: "\u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u306B\u767B\u9332\u62DB\u5F85\u30E1\u30FC\u30EB\u3092\u518D\u9001\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          noSeats: "\u6B8B\u308A\u306E\u5E2D\u306F\u3042\u308A\u307E\u305B\u3093\u3002",
          seats: "\u3042\u3068{{count}}\u5E2D\u6B8B\u3063\u3066\u3044\u307E\u3059\u3002",
          seats_plural: "\u3042\u3068{{count}}\u5E2D\u6B8B\u3063\u3066\u3044\u307E\u3059\u3002",
          unSuccessHeaderAllUsersAlreadyEnrolled: "\u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u306F\u3059\u3067\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059\u3002",
          unSuccessHeaderAllUsersAlreadyEnrolled_plural: "\u9078\u629E\u3055\u308C\u305F\u3059\u3079\u3066\u306E\u30E6\u30FC\u30B6\u30FC\u306F\u3059\u3067\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059\u3002",
          alreadyEnrolledUsersUnsuccessful_emailplural: "E\u30E1\u30FC\u30EB\u306F\u9001\u4FE1\u3055\u308C\u307E\u305B\u3093\u3002",
          alreadyEnrolledUsers: "{{totalCount}} \u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u306E\u3046\u3061\u3000\u3000\u3000\u306F\u3059\u3067\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059\u3002",
          alreadyEnrolledUsers_workspace: "{{totalCount}} \u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u306E\u3046\u3061\u3000\u3000\u3000\u306F\u3059\u3067\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059 (\u4ED6\u306E\u30A2\u30AB\u30A6\u30F3\u30C8\u304B\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u5185\u306E{{unenrollableCount}})\u3002",
          alreadyEnrolledUsers_email: "{{totalCount}} \u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u306E\u3046\u3061\u3000\u3000\u3000\u306F\u3059\u3067\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059\u3002\u3053\u306E\u305F\u3081\u3053\u306E\u30E6\u30FC\u30B6\u30FC\u306BE\u30E1\u30FC\u30EB\u306F\u9001\u4FE1\u3055\u308C\u307E\u305B\u3093\u3002",
          alreadyEnrolledUsers_emailworkspace: "{{totalCount}} \u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u306E\u3046\u3061\u3000\u3000\u3000\u306F\u3059\u3067\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059(\u4ED6\u306E\u30A2\u30AB\u30A6\u30F3\u30C8\u304B\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u5185\u306E{{unenrollableCount}})\u3002\u3053\u306E\u305F\u3081E\u30E1\u30FC\u30EB\u306F\u9001\u4FE1\u3055\u308C\u307E\u305B\u3093\u3002",
          alreadyEnrolledUsers_plural: "{{totalCount}} \u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u306E\u3046\u3061\u3000\u3000\u3000\u306F\u3059\u3067\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059\u3002",
          alreadyEnrolledUsers_workspaceplural: "{{totalCount}} \u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u306E\u3046\u3061\u3000\u3000\u3000\u306F\u3059\u3067\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059(\u4ED6\u306E\u30A2\u30AB\u30A6\u30F3\u30C8\u304B\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u5185\u306E{{unenrollableCount}})\u3002",
          alreadyEnrolledUsers_emailplural: "{{totalCount}} \u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u306E\u3046\u3061\u3000\u3000\u3000\u306F\u3059\u3067\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059\u3002\u3053\u306E\u305F\u3081\u3053\u308C\u3089\u306E\u30E6\u30FC\u30B6\u30FC\u306BE\u30E1\u30FC\u30EB\u306F\u9001\u4FE1\u3055\u308C\u307E\u305B\u3093\u3002",
          alreadyEnrolledUsers_emailworkspaceplural: "{{totalCount}} \u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u306E\u3046\u3061\u3000\u3000\u3000\u306F\u3059\u3067\u306B\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u3059(\u4ED6\u306E\u30A2\u30AB\u30A6\u30F3\u30C8\u304B\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u5185\u306E{{unenrollableCount}})\u3002\u3053\u306E\u305F\u3081E\u30E1\u30FC\u30EB\u306F\u9001\u4FE1\u3055\u308C\u307E\u305B\u3093\u3002"
        },
        remoteAccessPolicies: {
          delete: {
            title: "Zero Trust\u30A2\u30AF\u30BB\u30B9\u30DD\u30EA\u30B7\u30FC\u3092\u524A\u9664 {{name}}",
            body: "\u3053\u306EZero Trust\u30A2\u30AF\u30BB\u30B9 \u30DD\u30EA\u30B7\u30FC\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002"
          },
          order: "\u9806\u756A",
          name: "\u540D\u524D",
          description: "\u5185\u5BB9",
          groupsGrantedAccess: "\u30A2\u30AF\u30BB\u30B9\u3092\u8A31\u53EF\u3055\u308C\u305F\u30B0\u30EB\u30FC\u30D7",
          resources: "\u30EA\u30BD\u30FC\u30B9",
          devicePosture: "\u88C5\u7F6E\u30DD\u30B9\u30C1\u30E3\u30FC",
          usersGrantedAccess: "\u30A2\u30AF\u30BB\u30B9\u3092\u8A31\u53EF\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC",
          zeroTrustAccess: "\u30BC\u30ED\u30C8\u30E9\u30B9\u30C8\u30A2\u30AF\u30BB\u30B9",
          policy: "\u30DD\u30EA\u30B7\u30FC",
          create: "Zero Trust Access\u30DD\u30EA\u30B7\u30FC\u3092\u4F5C\u6210",
          edit: "Zero Trust\u30A2\u30AF\u30BB\u30B9\u30DD\u30EA\u30B7\u30FC\u3092\u7DE8\u96C6",
          success: "\u6210\u529F",
          successMessage: "\u65B0\u898FZero Trust Access\u30DD\u30EA\u30B7\u30FC\u3092\u4F5C\u6210\u3057\u307E\u3057\u305F\u3002",
          createButton: "\u30DD\u30EA\u30B7\u30FC\u3092\u8FFD\u52A0",
          deviceAttributes: "\u88C5\u7F6E\u30DD\u30B9\u30C1\u30E3\u30FC",
          resourcesSection: {
            title: "\u30EA\u30BD\u30FC\u30B9",
            internalResource: "\u793E\u5185\u30EA\u30BD\u30FC\u30B9 (\u30AB\u30B9\u30BF\u30E0\u30A2\u30D7\u30EA)",
            publicResource: "\u30D1\u30D6\u30EA\u30C3\u30AF\u30A8\u30F3\u30C9\u30DD\u30A4\u30F3\u30C8(SaaS)",
            resourceTypeLabel: "\u30EA\u30BD\u30FC\u30B9 \u30BF\u30A4\u30D7",
            resourceTypeDescription: "\u30A2\u30AF\u30BB\u30B9\u30DD\u30A4\u30F3\u30C8\u304B\u3089\u5185\u90E8\u3067\u3057\u304B\u30A2\u30AF\u30BB\u30B9\u3067\u304D\u306A\u3044\u30EA\u30BD\u30FC\u30B9\u306B\u306F\u300C\u5185\u90E8\u300D\u3092\u3001\u307E\u305F\u516C\u958B\u30A2\u30AF\u30BB\u30B9\u306E\u30EA\u30BD\u30FC\u30B9\u306B\u306F\u300C\u516C\u958B\u300D\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
            resourcesLabel: "\u30EA\u30BD\u30FC\u30B9"
          },
          applications: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
          accessCriteriaSection: "\u30A2\u30AF\u30BB\u30B9\u306E\u5224\u65AD\u57FA\u6E96",
          users: "\u30E6\u30FC\u30B6\u30FC",
          groups: "\u30B0\u30EB\u30FC\u30D7",
          securityInspection: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u691C\u67FB",
          screenLock: "\u753B\u9762\u30ED\u30C3\u30AF\u6709\u52B9",
          firewall: "Firewall\u6709\u52B9",
          jailbroken: "\u30B8\u30A7\u30A4\u30EB\u30D6\u30EC\u30A4\u30AF\u88C5\u7F6E\u3092\u30D6\u30ED\u30C3\u30AF",
          encryption: "\u30C7\u30A3\u30B9\u30AF\u6697\u53F7\u5316\u3092\u5F37\u5236",
          appUpdates: "Barracuda SecureEdge Agent\u306E\u66F4\u65B0",
          minimumVersion: "\u6700\u5C0F\u30D0\u30FC\u30B8\u30E7\u30F3",
          platform: "\u30D7\u30E9\u30C3\u30C8\u30D5\u30A9\u30FC\u30E0",
          osUpdates: "OS\u66F4\u65B0",
          antivirus: "\u30A2\u30F3\u30C1\u30A6\u30A3\u30EB\u30B9\u6709\u52B9",
          descriptions: {
            name: "\u30DD\u30EA\u30B7\u30FC\u306E\u4E00\u610F\u306E\u540D\u79F0",
            description: "\u30DD\u30EA\u30B7\u30FC\u306E\u6982\u7565",
            resourceType: "\u30AB\u30B9\u30BF\u30E0\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u307E\u305F\u306F\u5916\u90E8\u30B5\u30FC\u30C9\u30D1\u30FC\u30C6\u30A3\u30FC\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306E\u3044\u305A\u308C\u304B\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u3002",
            applications: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306E\u5BFE\u8C61\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3002",
            users: "\u9078\u629E\u3055\u308C\u305F\u30A2\u30D7\u30EA\u3078\u306E\u30A2\u30AF\u30BB\u30B9\u304C\u8A8D\u3081\u3089\u308C\u308B\u3079\u304D\u30E6\u30FC\u30B6\u30FC\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u3084\u30B0\u30EB\u30FC\u30D7\u304C\u306A\u3051\u308C\u3070\u3001\u9078\u629E\u3055\u308C\u305F\u30A2\u30D7\u30EA\u306F\u5168\u54E1\u304C\u30A2\u30AF\u30BB\u30B9\u3067\u304D\u307E\u3059\u3002",
            groups: "\u9078\u629E\u3055\u308C\u305F\u30A2\u30D7\u30EA\u3078\u306E\u30A2\u30AF\u30BB\u30B9\u304C\u8A8D\u3081\u3089\u308C\u308B\u3079\u304D\u30B0\u30EB\u30FC\u30D7\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u9078\u629E\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC\u3084\u30B0\u30EB\u30FC\u30D7\u304C\u306A\u3051\u308C\u3070\u3001\u9078\u629E\u3055\u308C\u305F\u30A2\u30D7\u30EA\u306F\u5168\u54E1\u304C\u30A2\u30AF\u30BB\u30B9\u3067\u304D\u307E\u3059\u3002",
            securityPosture: "\u8A2D\u5B9A\u6E08\u307F\u88C5\u7F6E\u5C5E\u6027\u3092\u5F37\u5236\u3059\u308B\u304B\u3001\u9055\u53CD\u3092Log\u306B\u8A18\u9332\u3059\u308B\u306E\u307F\u304B\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
            securityInspection: "\u6709\u52B9\u306B\u3059\u308B\u3068\u3001\u9078\u629E\u3057\u305F\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3078\u306E\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u304C\u30C8\u30F3\u30CD\u30EB\u7D4C\u7531\u3067\u30EB\u30FC\u30C6\u30A3\u30F3\u30B0\u3055\u308C\u3001\u3088\u308A\u8A73\u7D30\u306A\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u691C\u67FB\u304C\u53EF\u80FD\u306B\u306A\u308A\u3001\u5165\u53E3\u306E\u30DD\u30EA\u30B7\u30FC\u304C\u9069\u7528\u3055\u308C\u307E\u3059\u3002\u7121\u52B9\u306B\u3059\u308B\u3068\u3001\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306F\u901A\u5E38\u3069\u304A\u308A\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u7D4C\u7531\u3067\u30EB\u30FC\u30C6\u30A3\u30F3\u30B0\u3055\u308C\u307E\u3059\u3002",
            screenLock: "\u30E6\u30FC\u30B6\u30FC\u304C\u88C5\u7F6E\u3067\u753B\u9762\u30ED\u30C3\u30AF\u3092\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u3092\u5F37\u5236\u3057\u307E\u3059\u3002\u5BFE\u5FDC\u30D7\u30E9\u30C3\u30C8\u30D5\u30A9\u30FC\u30E0: Android\u3001iOS\u3002",
            firewall: "\u30E6\u30FC\u30B6\u30FC\u304C\u88C5\u7F6E\u306BFirewall\u3092\u6709\u52B9\u306B\u3057\u3066\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u3092\u5F37\u5236\u3057\u307E\u3059\u3002\u5BFE\u5FDC\u30D7\u30E9\u30C3\u30C8\u30D5\u30A9\u30FC\u30E0: mac OS\u3001Windows\u3002",
            jailbroken: "\u653B\u6483\u3092\u53D7\u3051\u8106\u5F31\u306B\u306A\u3063\u305F\u88C5\u7F6E\u304C\u30EA\u30BD\u30FC\u30B9\u306B\u30A2\u30AF\u30BB\u30B9\u3067\u304D\u306A\u304F\u3057\u307E\u3059\u3002\u5BFE\u5FDC\u30D7\u30E9\u30C3\u30C8\u30D5\u30A9\u30FC\u30E0: Android\u3001iOS\u3002",
            encryption: "\u30E6\u30FC\u30B6\u30FC\u304C\u30C7\u30A3\u30B9\u30AF\u6697\u53F7\u5316\u3092\u8A2D\u5B9A\u3059\u308B\u3088\u3046\u306B\u5F37\u5236\u3057\u307E\u3059\u3002\u5BFE\u5FDC\u30D7\u30E9\u30C3\u30C8\u30D5\u30A9\u30FC\u30E0: Android\u3001iOS\u3001mac OS\u3001Windows\u3002",
            appUpdates: "Barracuda SecureEdge \u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3092\u6700\u65B0\u30D0\u30FC\u30B8\u30E7\u30F3\u306B\u66F4\u65B0\u3059\u308B\u3053\u3068\u3092\u5F37\u5236\u3057\u307E\u3059\u3002\u5BFE\u5FDC\u30D7\u30E9\u30C3\u30C8\u30D5\u30A9\u30FC\u30E0: Android\u3001iOS\u3001 Linux\u3001mac OS\u3001Windows\u3002",
            osUpdates: "\u88C5\u7F6E\u306E\u30AA\u30DA\u30EC\u30FC\u30C6\u30A3\u30F3\u30B0\u30B7\u30B9\u30C6\u30E0(OS)\u3092\u8A2D\u5B9A\u3055\u308C\u305F\u6700\u5C0F\u30D0\u30FC\u30B8\u30E7\u30F3\u306B\u66F4\u65B0\u3059\u308B\u3088\u3046\u30E6\u30FC\u30B6\u306B\u5F37\u5236\u3057\u307E\u3059\u5BFE\u5FDC\u30D7\u30E9\u30C3\u30C8\u30D5\u30A9\u30FC\u30E0: Android\u3001iOS\u3001mac OS\u3001Windows\u3002",
            antivirus: "\u30E6\u30FC\u30B6\u30FC\u304C\u88C5\u7F6E\u306B\u30A2\u30F3\u30C1\u30A6\u30A3\u30EB\u30B9service\u3092\u8A2D\u5B9A\u3059\u308B\u3088\u3046\u306B\u5F37\u5236\u3057\u307E\u3059\u3002\u30D7\u30E9\u30C3\u30C8\u30D5\u30A9\u30FC\u30E0: Windows\u3002"
          },
          validatePlatform: "OS\u306F\u9078\u629E\u6E08\u307F\u3067\u3059\u3002",
          validateDeviceCriteria: "\u5C11\u306A\u304F\u3068\u30821\u3064\u306E\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u6A5F\u80FD\u304C\u6709\u52B9\u306B\u306A\u3063\u3066\u3044\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
          lastMileOptimization: "\u30E9\u30B9\u30C8\u30DE\u30A4\u30EB\u6700\u9069\u5316",
          noData: {
            iconHeader: "Zero Trust Access\u30DD\u30EA\u30B7\u30FC\u306E\u8FFD\u52A0\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
            labelButton: "Zero Trust Access\u30DD\u30EA\u30B7\u30FC\u3092\u8FFD\u52A0",
            textHeader: "Zero Trust Access\u30DD\u30EA\u30B7\u30FC\u3068\u306F\u4F55\u304B",
            text: "\u3053\u306E\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3067\u306F\u30E6\u30FC\u30B6\u30FC\u306E\u88C5\u7F6E\u304C\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306B\u30A2\u30AF\u30BB\u30B9\u3059\uFF52\u305F\u3081\u306B\u6E96\u62E0\u3059\u308B\u5FC5\u8981\u304C\u3042\u308B\u8981\u4EF6\u3092\u5B9A\u7FA9\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002<0 /> For example, you can require devices to have the latest OS updates applied and onboard security enabled, or you can even block jailbroken devices."
          }
        },
        pointsOfEntry: {
          title: "\u5165\u53E3",
          table: {
            name: "\u540D\u524D",
            type: "\u30BF\u30A4\u30D7",
            size: "\u30B5\u30A4\u30BA",
            location: "\u5834\u6240",
            publicIp: "\u30D1\u30D6\u30EA\u30C3\u30AFIP",
            noData: "\u5165\u53E3\u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u307E\u305B\u3093\u3002\u5C11\u306A\u304F\u3068\u30821\u3064\u306E\u5165\u53E3\u306F\u30BC\u30ED\u30C8\u30E9\u30B9\u30C8\u30A2\u30AF\u30BB\u30B9\u3092\u4F7F\u7528\u3059\u308B\u305F\u3081\u306B\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002"
          },
          createButton: "\u5165\u53E3\u306E\u8FFD\u52A0",
          create: {
            title: "\u5165\u53E3\u306E\u8FFD\u52A0",
            configurationType: "\u30BF\u30A4\u30D7",
            site: "\u30B5\u30A4\u30C8",
            gateway: "Edge Service",
            firewall: "Firewall",
            ipAddress: "\u30D1\u30D6\u30EA\u30C3\u30AFIP",
            sites: "\u30B5\u30A4\u30C8",
            gateways: "Edge Services",
            firewalls: "Firewalls",
            descriptions: {
              sites: "\u5165\u53E3\u3068\u3057\u3066\u8FFD\u52A0\u3059\u308B\u30B5\u30A4\u30C8\u3002",
              gateways: "\u5165\u53E3\u3068\u3057\u3066\u8FFD\u52A0\u3059\u308B\u30A8\u30C3\u30B8Services\u3002",
              firewalls: "\u5165\u53E3\u3068\u3057\u3066\u8FFD\u52A0\u3059\u308BFirewall\u3002"
            },
            validateSelectOne: "\u5C11\u306A\u304F\u3068\u3082 1\u3064\u306EFirewall\u3001\u30B5\u30A4\u30C8\u3001\u307E\u305F\u306F\u30A8\u30C3\u30B8Service\u3092\u9078\u629E\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002"
          },
          edit: {
            title: "\u5165\u53E3\u306E\u7DE8\u96C6",
            name: "\u540D\u524D",
            ipAddress: "\u30D1\u30D6\u30EA\u30C3\u30AFIP",
            descriptions: {
              ipAddress: "\u3053\u306EFirewall\u306B\u30A2\u30AF\u30BB\u30B9\u3067\u304D\u308B\u30D1\u30D6\u30EA\u30C3\u30AFIP\u30A2\u30C9\u30EC\u30B9\u3002"
            }
          },
          delete: {
            title: "\u5165\u53E3 {{name}}\u3092\u524A\u9664",
            body: "\u3053\u306E\u5165\u53E3\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u3044\u3067\u3059\u304B"
          },
          noData: {
            iconHeader: "\u5165\u53E3\u3092\u8FFD\u52A0\u3057\u3066\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
            labelButton: "\u5165\u53E3\u306E\u8FFD\u52A0",
            textHeader: "\u5165\u53E3\u3068\u306F\u4F55\u304B",
            text: "\u5165\u53E3(PoE)\u306F\u304A\u4F7F\u3044\u306ESecureEdge\u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u306E\u305F\u3081\u306EConnection\u30DD\u30A4\u30F3\u30C8\u3068\u3057\u3066\u6A5F\u80FD\u3057\u307E\u3059\u3002<0 />\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u304C\u76F4\u8FD1\u306E\u5229\u7528\u3067\u304D\u308BPoE\u3092\u81EA\u52D5\u9078\u629E\u3059\u308B\u306E\u3067Service\u54C1\u8CEA\u306F\u6700\u9069\u306B\u306A\u308A\u307E\u3059\u3002"
          }
        },
        zeroTrustSettings: {
          agentWebFiltering: "\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u30A6\u30A7\u30D6\u30D5\u30A3\u30EB\u30BF\u30EA\u30F3\u30B0",
          dnsSuffix: "DNS \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9",
          downloadCertificateHelpText: "\u3059\u3079\u3066\u306E\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u306B\u3053\u306Eroot\u8A3C\u660E\u66F8\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u3057\u3066\u914D\u5099\u3057\u3066CloudGen Firewall\u306E\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u304B\u3089\u30B5\u30A4\u30C8\u3078\u306E\u30BB\u30C3\u30C8\u30A2\u30C3\u30D7\u304A\u3088\u3073SecureEdge \u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3068\u306E\u5E73\u884C\u904B\u7528\u3092\u78BA\u5B9F\u306B\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          downloadCertificateLabel: "\u8A3C\u660E\u66F8\u306E\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9",
          networkSectionTitle: "\u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8 \u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u8A2D\u5B9A",
          clientNetworkDescription: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306F SecureEdge \u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3092\u4ECB\u3057\u3066\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u306B IP \u3092\u5272\u308A\u5F53\u3066\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u307E\u3059\u3002Pool\u306B\u5206\u5272\u3055\u308C\u3066\u304B\u3089Pool\u304C\u5404\u5165\u53E3\u306B\u914D\u5206\u3055\u308C\u307E\u3059\u3002",
          manualConfigurationEnabled: "\u624B\u52D5\u8A2D\u5B9A",
          clientNetworkRange: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8 \u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
          clientNetworkSize: "\u3053\u306E\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306F\u5165\u53E3\u5F53\u305F\u308A\u5C11\u306A\u304F\u3068\u3082{{connections}}\u500B\u306E\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u63A5\u7D9A\u3092\u63D0\u4F9B\u3057\u307E\u3059\u3002",
          clientNetworkPoolSize: "Pool\u30D3\u30C3\u30C8\u30DE\u30B9\u30AF",
          validatePoolSize: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8 \u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3088\u308A\u5C0F\u3055\u304F\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
          enforce: "\u5F37\u5236",
          disable: "\u7121\u52B9\u306B\u3059\u308B",
          globalZtnaSettings: "\u30A2\u30AF\u30BB\u30B9\u8A2D\u5B9A"
        },
        ztnaUsers: {
          dashboardTabTitle: "Dashboard",
          settingsTabTitle: "\u8A2D\u5B9A",
          userNotFound: "\u30E6\u30FC\u30B6\u30FC\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093"
        }
      },
      securityAndAccess: {
        pageTitle: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3 \u30DD\u30EA\u30B7\u30FC",
        apps: "\u30A2\u30D7\u30EA\u3068\u30EA\u30BD\u30FC\u30B9",
        network: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AFACL",
        gateway: "Edge Service ACL",
        site: "\u30B5\u30A4\u30C8ACL",
        sdwan: "SD-WAN",
        categories: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u30AB\u30C6\u30B4\u30EA\u30FC",
        explicit: "\u30AA\u30FC\u30D0\u30FC\u30E9\u30A4\u30C9\u30AB\u30C6\u30B4\u30EA\u30FC",
        security: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3",
        malware: "Advanced Threat Protection",
        ssl: "SSL Inspection",
        ips: "IPS",
        ingressNatRules: "\u30A4\u30F3\u30B0\u30EC\u30B9NAT",
        urlFilter: "\u30DD\u30EA\u30B7\u30FC",
        webFilter: "\u30A6\u30A7\u30D6\u30D5\u30A3\u30EB\u30BF\u30FC",
        webFilterSettings: "\u8A2D\u5B9A",
        webMonitorPolicies: "\u30A6\u30A7\u30D6\u30FB\u30E2\u30CB\u30BF\u30EA\u30F3\u30B0",
        settings: "\u8A2D\u5B9A",
        customResponse: "\u30EC\u30B9\u30DD\u30F3\u30B9\u30FB\u30E1\u30C3\u30BB\u30FC\u30B8"
      },
      appliances: {
        dashboard: {
          deployment: {
            status: {
              title: "\u914D\u5099\u30B9\u30C6\u30FC\u30BF\u30B9 {{serial}}",
              status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
              action: "\u64CD\u4F5C",
              message: "\u30E1\u30C3\u30BB\u30FC\u30B8",
              registered: "\u767B\u9332\u6E08\u307F",
              lastSeen: "\u6700\u5F8C\u306B\u78BA\u8A8D\u3055\u308C\u305F",
              completed: "\u5B8C\u4E86",
              localIp: "\u30ED\u30FC\u30AB\u30EBIP",
              remoteIp: "\u30D1\u30D6\u30EA\u30C3\u30AFIP"
            },
            events: {
              title: "\u914D\u5099\u30A4\u30D9\u30F3\u30C8",
              state: "\u90FD\u9053\u5E9C\u770C",
              status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
              created: "\u65E5\u4ED8",
              siteDeploying: "\u914D\u5099\u4E2D\u3067\u3059"
            },
            location: {
              title: "\u30C7\u30D7\u30ED\u30A4\u30E1\u30F3\u30C8\u306E\u5834\u6240"
            }
          },
          details: {
            servicesCardTitle: "Services",
            interfaces: {
              title: "\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9",
              status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
              name: "\u540D\u524D",
              speed: "\u30B9\u30D4\u30FC\u30C9",
              duplex: "\u5168\u4E8C\u91CD",
              errors: "\u30A8\u30E9\u30FC",
              state: {
                ok: "OK",
                warning: "\u8B66\u544A",
                error: "\u30A8\u30E9\u30FC",
                unknown: "\u4E0D\u660E",
                loading: " "
              }
            },
            boxResources: {
              title: "\u30EA\u30BD\u30FC\u30B9",
              cpuLoad: "CPU",
              cpuLoadError: "CPU\u306B\u30A8\u30E9\u30FC\u304C\u691C\u51FA\u3055\u308C\u307E\u3057\u305F\u3002\u88C5\u7F6E\u3092\u518D\u8D77\u52D5\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u3053\u306E\u30A8\u30E9\u30FC\u304C\u518D\u767A\u3059\u308C\u3070Barracuda Support\u306B\u3054\u9023\u7D61\u304F\u3060\u3055\u3044\u3002",
              memory: "\u30E1\u30E2\u30EA\u30FC",
              diskInformation: "\u30CF\u30FC\u30C9\u30C7\u30A3\u30B9\u30AF",
              diskInformationError: "\u30C7\u30A3\u30B9\u30AF\u30D1\u30FC\u30C6\u30A3\u30B7\u30E7\u30F3\u306E1\u3064\u3067\u30A8\u30E9\u30FC\u304C\u691C\u51FA\u3055\u308C\u307E\u3057\u305F\u3002\u88C5\u7F6E\u3092\u518D\u8D77\u52D5\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u3053\u306E\u30A8\u30E9\u30FC\u304C\u518D\u767A\u3059\u308C\u3070Barracuda Support\u306B\u3054\u9023\u7D61\u304F\u3060\u3055\u3044\u3002"
            },
            vpnTunnels: {
              title: "VPN\u30C8\u30F3\u30CD\u30EB",
              instanceId: "\u30A4\u30F3\u30B9\u30BF\u30F3\u30B9\u30FB\u30B7\u30EA\u30A2\u30EB",
              status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
              name: "\u540D\u524D",
              peer: "\u30D4\u30A2\u30FC",
              local: "\u30ED\u30FC\u30AB\u30EB",
              type: "\u30BF\u30A4\u30D7",
              state: {
                UP: "\u7A3C\u50CD\u4E2D",
                DOWN: "\u505C\u6B62\u4E2D",
                INACTIVE: "\u52D5\u4F5C\u3057\u3066\u3044\u306A\u3044",
                FAIL: "\u30C0\u30A6\u30F3 - \u5931\u6557",
                STANDBY: "\u30C0\u30A6\u30F3 - \u30B9\u30BF\u30F3\u30D0\u30A4",
                "DOWN (PASSIVE)": "\u30C0\u30A6\u30F3 - \u30D1\u30C3\u30B7\u30D6",
                "DOWN (FAIL)": "\u30C0\u30A6\u30F3 - \u5931\u6557\u3057\u305F",
                "DOWN (STANDBY)": "\u30C0\u30A6\u30F3 - \u30B9\u30BF\u30F3\u30D0\u30A4",
                "DOWN (DISABLED)": "\u7121\u52B9",
                PENDING: "\u4FDD\u7559\u4E2D"
              }
            }
          },
          firmware: {
            details: {
              tabTitle: "\u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2",
              version: "\u30D0\u30FC\u30B8\u30E7\u30F3",
              hotfixes: "\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u6E08\u307F\u30DB\u30C3\u30C8\u30D5\u30A3\u30C3\u30AF\u30B9",
              window: "\u6B21\u306E\u66F4\u65B0",
              nextUpdate: "{{from}}\u3068{{to}}\u306E\u9593"
            },
            hotfixes: {
              tabTitle: "\u30DB\u30C3\u30C8\u30D5\u30A3\u30C3\u30AF\u30B9",
              status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
              name: "\u540D\u524D"
            },
            updates: {
              tabTitle: "\u8A2D\u5B9A\u306E\u66F4\u65B0",
              lastUpdate: "\u524D\u56DE\u306E\u66F4\u65B0",
              lastSeen: "\u6700\u5F8C\u306B\u78BA\u8A8D\u3055\u308C\u305F",
              remoteIp: "\u30EA\u30E2\u30FC\u30C8IP"
            },
            state: {
              error: "\u30A8\u30E9\u30FC",
              ok: "OK",
              unknown: "\u4E0D\u660E",
              warning: "\u4FDD\u7559\u4E2D",
              loading: " "
            }
          },
          siteStatus: {
            tabTitle: "\u63A5\u7D9A\u3055\u308C\u305F\u30B5\u30A4\u30C8"
          },
          iotStatus: {
            tabTitle: "\u63A5\u7D9A\u3055\u308C\u305F IoT"
          },
          summary: {
            siteStatus: {
              title: "\u30B9\u30C6\u30FC\u30BF\u30B9",
              model: "\u30E2\u30C7\u30EB",
              serials: "\u30B7\u30EA\u30A2\u30EB",
              serial: "\u30B7\u30EA\u30A2\u30EB",
              release: "\u30EA\u30EA\u30FC\u30B9"
            },
            licenses: {
              title: "\u30E9\u30A4\u30BB\u30F3\u30B9",
              type: "\u30BF\u30A4\u30D7",
              state: "\u90FD\u9053\u5E9C\u770C",
              status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
              expirationDate: "\u6E80\u4E86\u65E5",
              serial: "\u30B7\u30EA\u30A2\u30EB",
              noData: "N/A"
            },
            gateway: {
              tabTitle: "Edge Service",
              noGateway: "\u3053\u306E\u30B5\u30A4\u30C8\u306B\u306F\u30A8\u30C3\u30B8Service\u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u307E\u305B\u3093\u3002",
              name: "\u540D\u524D",
              region: "\u5730\u57DF",
              hubName: "\u30CF\u30D6",
              vwan: "Cloud VWAN",
              size: "\u30B5\u30A4\u30BA",
              publicIP: "\u30D1\u30D6\u30EA\u30C3\u30AFIP",
              publicIPs: "\u30D1\u30D6\u30EA\u30C3\u30AFIP",
              serial: "\u30B7\u30EA\u30A2\u30EB",
              instanceNotFound: "\u30A4\u30F3\u30B9\u30BF\u30F3\u30B9\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093"
            },
            status: {
              tabTitle: "\u30B9\u30C6\u30FC\u30BF\u30B9",
              hub: "\u30CF\u30D6",
              region: "\u5730\u57DF",
              vwan: "Cloud VWAN",
              connectedSites: "\u63A5\u7D9A\u30B5\u30A4\u30C8"
            }
          },
          tunnelLocations: {
            title: "\u30DE\u30C3\u30D7",
            gateways: {
              label: "Edge Services",
              fields: {
                name: "\u540D\u524D",
                sites: "\u63A5\u7D9A\u3055\u308C\u305F\u30B5\u30A4\u30C8",
                iot: "\u63A5\u7D9A\u3055\u308C\u305F IoT",
                vwan: "\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9",
                hub: "\u30CF\u30D6",
                region: "\u5730\u57DF",
                size: "\u30B5\u30A4\u30BA"
              }
            },
            iot: {
              label: "IoT"
            },
            sites: {
              label: "\u30B5\u30A4\u30C8",
              fields: {
                name: "\u540D\u524D",
                location: "\u5834\u6240",
                gateway: "Edge Service",
                providers: "\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u6570"
              }
            },
            standaloneSites: {
              title: "\u30DE\u30C3\u30D7",
              label: "\u30B9\u30BF\u30F3\u30C9\u30A2\u30ED\u30F3\u30B5\u30A4\u30C8"
            }
          }
        },
        dialog: {
          createType: {
            sectionTitle: "\u30A8\u30C3\u30B8Service\u306E\u7A2E\u985E",
            new: "\u30BC\u30ED\u304B\u3089\u306E\u65B0\u3057\u3044Edge Service",
            promote: "\u65E2\u5B58\u306E\u30B5\u30A4\u30C8\u3092Edge Service\u306B\u6607\u683C"
          },
          siteSelection: {
            sectionTitle: "\u30B5\u30A4\u30C8\u306E\u9078\u629E",
            mainMessage: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service\u306B\u6607\u683C\u3055\u305B\u305F\u3044\u30B5\u30A4\u30C8\u3092\u9078\u3073\u307E\u3059\u3002",
            secondaryMessage: "\u6CE8\u8A18\uFF1A\u95A2\u9023\u3059\u308B\u3059\u3079\u3066\u306E\u30DD\u30EA\u30B7\u30FC (ACL\u3001URL filter\u306A\u3069) \u306F\u6607\u683C\u6642\u306B\u524A\u9664\u3055\u308C\u307E\u3059\u3002",
            haWarning: "\u3053\u306E\u30B5\u30A4\u30C8\u306FHA\u3067\u306F\u306A\u304F\u3001HA\u306F\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Services\u7528\u306B\u4F7F\u7528\u3059\u308B\u3053\u3068\u3092\u304A\u52E7\u3081\u3057\u307E\u3059\u3002",
            site: "\u30B5\u30A4\u30C8"
          },
          basic: {
            gateway: "Edge Service",
            upstream_gateway: "\u30A2\u30C3\u30D7\u30EA\u30F3\u30AF Edge Service",
            noGateway: "(\u306A\u3057)",
            name: "\u540D\u524D",
            sectionTitle: "\u57FA\u672C\u60C5\u5831",
            tabTitle: "\u57FA\u672C",
            rootPassword: "Root\u306E\u30D1\u30B9\u30EF\u30FC\u30C9",
            rootPasswordConfirmation: "Root\u306E\u30D1\u30B9\u30EF\u30FC\u30C9\u78BA\u8A8D\u5165\u529B",
            descriptions: {
              gateway_site: "\u3053\u306E\u30B5\u30A4\u30C8\u3092\u63A5\u7D9A\u3057\u305F\u3044 Barracuda SecureEdge Edge Service\u3002",
              gateway_gateway: "\u3053\u306EEdge Service\u3068\u63A5\u7D9A\u3057\u305F\u3044 Barracuda SecureEdge Edge Service\u3002",
              name_site: "\u30B5\u30A4\u30C8\u306E\u56FA\u6709\u540D\u3002",
              name_gateway: "Edge Service\u306E\u56FA\u6709\u540D\u3002",
              rootPassword_site: "\u30B5\u30A4\u30C8\u306E root\u30E6\u30FC\u30B6\u30FC\u30D1\u30B9\u30EF\u30FC\u30C9\u3002",
              rootPassword_gateway: "Edge Service\u306E root\u30E6\u30FC\u30B6\u30FC\u30D1\u30B9\u30EF\u30FC\u30C9\u3002",
              upstream_gateway: "\u3053\u306EEdge Service\u306B\u63A5\u7D9A\u3057\u305F\u3044Barracuda SecureEdge\u306E\u4E0A\u6D41\u306E Edge Service\u3002"
            }
          },
          serials: {
            gatewayMessage: "\u8A2D\u5B9A\u3057\u305F\u3044Edge Service\u6A5F\u5668\u3092\u9078\u629E\u3057\u307E\u3059\u3002\u9AD8\u53EF\u7528\u6027\u30AF\u30E9\u30B9\u30BF\u30FC\u3092\u4F5C\u6210\u3057\u30662\u3064\u306E\u6A5F\u5668\u3092\u9078\u629E\u3059\u308B\u3088\u3046\u304A\u52E7\u3081\u3057\u307E\u3059\u3002",
            TVTSiteMessage: "\u8A2D\u5B9A\u3057\u305F\u3044\u30B5\u30A4\u30C8\u6A5F\u5668\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u4E00\u3064\u306E\u6A5F\u5668\u307E\u305F\u306F\u9AD8\u53EF\u7528\u6027\u30AF\u30E9\u30B9\u30BF\u30FC\u3092\u30BB\u30C3\u30C8\u30A2\u30C3\u30D7\u3057\u305F\u3044\u5834\u5408\u306F2\u3064\u306E\u6A5F\u5668\u3092\u9078\u629E\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
            SCSiteMessage: "\u8A2D\u5B9A\u3057\u305F\u3044Secure Connector\u6A5F\u5668\u3092\u9078\u629E\u3057\u307E\u3059\u3002",
            haMessage: "<1>\u6CE8\u8A18:</1> HA\u30AF\u30E9\u30B9\u30BF\u30FC\u306E\u4E21\u65B9\u306E\u6A5F\u5668\u306F\u3001\u540C\u3058\u30E2\u30C7\u30EB\u3067\u3042\u308A\u3001\u540C\u3058\u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2\u30D0\u30FC\u30B8\u30E7\u30F3\u3067\u3042\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
            sectionTitle_site: "\u30B5\u30A4\u30C8\u6A5F\u5668",
            sectionTitle_gateway: "Edge Service\u306E\u6A5F\u5668",
            addAppliance: "\u30B7\u30EA\u30A2\u30EB\u304A\u3088\u3073\u30EA\u30F3\u30AF\u7528\u30B3\u30FC\u30C9/\u30E9\u30A4\u30BB\u30F3\u30B9\u30B3\u30FC\u30C9\u306B\u3088\u3063\u3066\u6B20\u843D\u3057\u3066\u3044\u308B\u6A5F\u5668\u3092\u8FFD\u52A0\u3057\u307E\u3059\u3002",
            linkingCode: "\u30B3\u30FC\u30C9/\u30C8\u30FC\u30AF\u30F3",
            table: {
              serial: "\u30B7\u30EA\u30A2\u30EB",
              model: "\u30E2\u30C7\u30EB",
              location: "\u5834\u6240",
              orderId: "\u6CE8\u6587ID",
              orderDate: "\u6CE8\u6587\u65E5"
            }
          },
          wans: {
            sectionTitle: "WAN",
            message: "\u304A\u4F7F\u3044\u306E\u88C5\u7F6E\u306B\u306F WAN\u3068 LAN\u306B\u5272\u5F53\u53EF\u80FD\u306A\u5408\u8A08{{portsAvailable}}\u500B\u306E\u30DD\u30FC\u30C8\u304C\u3042\u308A\u307E\u3059\u3002",
            message2_site: "\u3053\u306E\u30B5\u30A4\u30C8\u306B\u3044\u304F\u3064\u306E WAN\u63A5\u7D9A\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u304B\u3002",
            message2_gateway: "\u3053\u306EEdge Service\u3067\u306F\u3044\u304F\u3064\u306EWAN\u63A5\u7D9A\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u304B\u3002",
            size: "WAN\u672C\u6570",
            setup: "WAN\u30EA\u30F3\u30AF{{step}}\u3092\u8A2D\u5B9A",
            name: "\u540D\u524D",
            port: "\u30DD\u30FC\u30C8",
            virtualLanId: "\u4EEE\u60F3 LAN ID",
            type: "\u30BF\u30A4\u30D7",
            providerPinning: "\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u306E\u30D4\u30F3\u30CB\u30F3\u30B0",
            address: "IP\u30A2\u30C9\u30EC\u30B9",
            netmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
            gateway: "\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4",
            pin: "{{serial}}\u7528 SIM\u306E PIN",
            invalidPinFormat: "SIM\u306E Pin\u306F 4\u3064\u306E\u6570\u5B57\u3067\u3059\u3002",
            SCmessage: "\u88C5\u7F6E\u306B\u306F\u8A2D\u5B9A\u3059\u3079\u304D\u5358\u4E00\u306E WAN\u30DD\u30FC\u30C8\u304C\u3042\u308A\u307E\u3059\u3002",
            SCmessage_failover: "\u88C5\u7F6E\u306B\u306F 2\u3064\u306E WAN(\u30D7\u30E9\u30A4\u30DE\u30EA\u3068\u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC)\u307E\u3067\u304C\u63A5\u7D9A\u53EF\u80FD\u3067\u3059\u3002",
            SCconfigureFailover: "\u3053\u306E\u30B5\u30A4\u30C8\u306E\u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u304B\u3002",
            failover: "WAN \u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC",
            setupSC: "\u30D7\u30E9\u30A4\u30DE\u30EA WAN\u30EA\u30F3\u30AF\u306E\u8A2D\u5B9A",
            setupSC_0: "\u30D7\u30E9\u30A4\u30DE\u30EA WAN\u30EA\u30F3\u30AF\u306E\u8A2D\u5B9A",
            setupSC_1: "\u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC WAN\u30EA\u30F3\u30AF\u306E\u8A2D\u5B9A",
            descriptions: {
              name: "\u3053\u306E WAN\u30EA\u30F3\u30AF\u306E\u305F\u3081\u306E\u56FA\u6709\u540D\u3002",
              port: "\u3053\u306E\u30EA\u30F3\u30AF\u306B\u4F7F\u7528\u3059\u308B\u88C5\u7F6E\u30DD\u30FC\u30C8\u3002",
              virtualLanId: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u30AA\u30D7\u30B7\u30E7\u30F3\u4EEE\u60F3 LAN ID \uFF08\u4EEE\u60F3 LAN \u30B5\u30DD\u30FC\u30C8\u306E\u3042\u308B\u30B9\u30A4\u30C3\u30C1\u306B\u63A5\u7D9A\u3059\u308B\u5834\u5408\u306B\u4F7F\u7528\u3055\u308C\u307E\u3059\uFF09\u3002",
              type: "\u3053\u306E\u30EA\u30F3\u30AF\u7528\u306B\u4F7F\u7528\u3059\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30BF\u30A4\u30D7\u3002",
              providerPinning: "\u3053\u306E\u30EA\u30F3\u30AF\u306E\u30D4\u30F3\u30CB\u30F3\u30B0\u5148\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u30B0\u30EB\u30FC\u30D7\u3002\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u30B0\u30EB\u30FC\u30D7\u306F\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u30D5\u30ED\u30FC\u3068\u52D5\u4F5C\u3092\u5B9A\u7FA9\u3059\u308B\u305F\u3081\u306B SD-WAN \u30DD\u30EA\u30B7\u30FC\u3067\u4F7F\u7528\u3055\u308C\u307E\u3059\u3002",
              address: "\u30DD\u30FC\u30C8\u306B\u5272\u308A\u5F53\u3066\u308B Ip\u30A2\u30C9\u30EC\u30B9\u3002",
              netmask: "\u3053\u306E\u30EA\u30F3\u30AF\u306E\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF CIDR \u30CD\u30C3\u30C8\u30DE\u30B9\u30AF \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u3002",
              gateway: "\u3053\u306E\u30EA\u30F3\u30AF\u306B\u95A2\u9023\u4ED8\u3051\u3089\u308C\u3066\u3044\u308B\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4 IP\u30A2\u30C9\u30EC\u30B9\u3002",
              pin: "\u3053\u306E\u88C5\u7F6E\u306B\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u3055\u308C\u3066\u3044\u308B SIM\u30AB\u30FC\u30C9\u7528\u306E\u500B\u4EBA\u8B58\u5225\u756A\u53F7\u3002"
            },
            bridgeType: {
              name: "Bridge",
              address: "IP\u30A2\u30C9\u30EC\u30B9",
              gateway: "\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4",
              lanPorts: "LAN\u30DD\u30FC\u30C8",
              netmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
              wanPort: "WAN\u30DD\u30FC\u30C8",
              descriptions: {
                address: "\u30DD\u30FC\u30C8\u306B\u5272\u308A\u5F53\u3066\u308B IP\u30A2\u30C9\u30EC\u30B9\u3002",
                gateway: "\u3053\u306E\u30EA\u30F3\u30AF\u3068\u95A2\u9023\u3057\u3066\u3044\u308B\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4IP\u30A2\u30C9\u30EC\u30B9",
                lanPorts: "\u4F7F\u7528\u3059\u308BLAN\u30DD\u30FC\u30C8",
                netmask: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AFCIDR\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF\u306E\u30B5\u30D5\u30A3\u30C3\u30AF\u30B9",
                wanPort: "\u4F7F\u7528\u3059\u308BWAN\u30DD\u30FC\u30C8"
              }
            },
            pppoeType: {
              name: "\u30E6\u30FC\u30B6\u30FC\u540D",
              password: "\u30D1\u30B9\u30EF\u30FC\u30C9",
              description: {
                name: "\u3053\u306EPPPoE\u306E\u56FA\u6709\u540D\u3002",
                password: "\u4E00\u610F\u306E\u30D1\u30B9\u30EF\u30FC\u30C9"
              }
            },
            validation: {
              mode: "Edge Service\u304C\u9078\u629E\u3055\u308C\u305F\u5834\u5408\u306FBridge\u3067\u3042\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093\u3002"
            }
          },
          lans: {
            sectionTitle: "LAN",
            message: "\u304A\u4F7F\u3044\u306E\u88C5\u7F6E\u306B\u306F\u3001WAN\u3068 LAN\u306B\u5272\u5F53\u53EF\u80FD\u306A\u5408\u8A08{{portsAvailable}}\u30DD\u30FC\u30C8\u304C\u3042\u308A\u3001WAN\u306B{{wans}}\u304C\u5272\u308A\u5F53\u3066\u3089\u308C\u3066\u3044\u307E\u3059\u3002",
            message2_site: "\u3053\u306E\u30B5\u30A4\u30C8\u306B\u3044\u304F\u3064\u306E LAN\u63A5\u7D9A\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u304B\u3002",
            message2_gateway: "\u3053\u306EEdge Service\u3067\u306F\u3044\u304F\u3064\u306ELAN\u63A5\u7D9A\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u304B\u3002",
            size: "LAN\u672C\u6570",
            setup: "LAN\u30EA\u30F3\u30AF{{step}}\u3092\u8A2D\u5B9A",
            name: "\u540D\u524D",
            port: "\u30DD\u30FC\u30C8",
            virtualLanId: "\u4EEE\u60F3 LAN ID",
            address: "IP\u30A2\u30C9\u30EC\u30B9",
            netmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
            dhcpEnable: "DHCP \u30B5\u30FC\u30D0\u30FC",
            dhcpDescription: "\u5185\u5BB9",
            dhcpFirstIp: "\u5148\u982D\u306E IP\u30A2\u30C9\u30EC\u30B9",
            dhcpLastIp: "\u6700\u5F8C\u306E IP\u30A2\u30C9\u30EC\u30B9",
            dhcpDomain: "DHCP\u30C9\u30E1\u30A4\u30F3",
            descriptions: {
              dhcpEnable: "\u3053\u306E LAN\u30EA\u30F3\u30AF\u306B\u3064\u3044\u3066 DHCP \u30B5\u30FC\u30D0\u30FC\u3092\u6709\u52B9\u306B\u3057\u307E\u3059\u3002",
              name: "\u3053\u306E LAN\u30EA\u30F3\u30AF\u306E\u305F\u3081\u306E\u56FA\u6709\u540D\u3002",
              port: "\u3053\u306E\u30EA\u30F3\u30AF\u306B\u4F7F\u7528\u3059\u308B\u88C5\u7F6E\u30DD\u30FC\u30C8\u3002",
              virtualLanId: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u30AA\u30D7\u30B7\u30E7\u30F3\u4EEE\u60F3 LAN ID \uFF08\u4EEE\u60F3 LAN \u30B5\u30DD\u30FC\u30C8\u306E\u3042\u308B\u30B9\u30A4\u30C3\u30C1\u306B\u63A5\u7D9A\u3059\u308B\u5834\u5408\u306B\u4F7F\u7528\u3055\u308C\u307E\u3059\uFF09\u3002",
              address: "\u30DD\u30FC\u30C8\u306B\u5272\u308A\u5F53\u3066\u308B Ip\u30A2\u30C9\u30EC\u30B9\u3002",
              netmask: "\u4F5C\u6210\u3057\u305F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306B\u4F7F\u7528\u3059\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF CIDR\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u3002",
              dhcpDescription: "\u3053\u306E DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u7528\u306E\u30AB\u30B9\u30BF\u30E0\u8A73\u7D30\u3002",
              dhcpFirstIp: "\u3053\u306E DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u5185\u3067\u5272\u5F53\u53EF\u80FD\u306B\u3059\u308B\u5148\u982D\u306E IP\u30A2\u30C9\u30EC\u30B9\u3002",
              dhcpLastIp: "\u3053\u306E DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u5185\u3067\u5272\u5F53\u53EF\u80FD\u306B\u3059\u308B\u6700\u5F8C\u306E IP\u30A2\u30C9\u30EC\u30B9\u3002",
              dhcpDomain: "\u3053\u306E DHCPService\u306E\u30C9\u30E1\u30A4\u30F3\u540D\u3002",
              wifi: {
                ssid: "WiFi\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E SSID\u3002",
                securityMode: "\u4F7F\u7528\u3057\u305F\u3044 Wi-Fi\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30E2\u30FC\u30C9\u3002",
                passphrase: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u7528\u30D1\u30B9\u30D5\u30EC\u30FC\u30BA\u3002"
              },
              type: "\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u30FB\u30BF\u30A4\u30D7\u3002\u3053\u308C\u306F\u30C7\u30D5\u30A9\u30EB\u30C8\u306ELAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u307E\u305F\u306F\u4E00\u3064\u306EBridge\u3068\u3057\u3066\u69CB\u6210\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059"
            },
            type: "\u30BF\u30A4\u30D7",
            bridgeType: {
              default: "\u30B7\u30F3\u30B0\u30EB\u30FB\u30DD\u30FC\u30C8",
              name: "Bridge",
              address: "IP\u30A2\u30C9\u30EC\u30B9",
              lanPorts: "\u30DD\u30FC\u30C8",
              netmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
              description: {
                address: "\u30DD\u30FC\u30C8\u306B\u5272\u308A\u5F53\u3066\u308B IP\u30A2\u30C9\u30EC\u30B9\u3002",
                lanPorts: "\u4F7F\u7528\u3059\u308BLAN\u30DD\u30FC\u30C8",
                netmask: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AFCIDR\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF\u306E\u30B5\u30D5\u30A3\u30C3\u30AF\u30B9"
              }
            },
            dhcpRelayType: "DHCP",
            validation: {
              dhcpRelayType: "SecureEdge\u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u308B\u3068\u304D\u306F\u6709\u52B9\u306B\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u305B\u3093"
            }
          },
          advanced: {
            sectionTitle: "\u8A73\u7D30\u8A2D\u5B9A",
            updateWindowTZ: "\u66F4\u65B0\u6642\u9593\u67A0\u306E\u6642\u9593\u5E2F",
            primaryDns: "\u30D7\u30E9\u30A4\u30DE\u30EA DNS",
            secondaryDns: "\u30BB\u30AB\u30F3\u30C0\u30EA DNS",
            descriptions: {
              updateWindowTZ_site: "\u3053\u306E\u30B5\u30A4\u30C8\u306E\u66F4\u65B0\u6642\u9593\u67A0\u3092\u6C7A\u5B9A\u3059\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u308B\u30B5\u30A4\u30C8\u306E\u6642\u9593\u5E2F\u3002",
              updateWindowTZ_gateway: "\u3053\u306EEdge Service\u306E\u66F4\u65B0\u6642\u9593\u67A0\u3092\u6C7A\u5B9A\u3059\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u308B\u3001Edge Service\u306E\u6642\u9593\u5E2F\u3002",
              primaryDns: "\u30D7\u30E9\u30A4\u30DE\u30EADNS\u30A2\u30C9\u30EC\u30B9\u3092\u624B\u52D5\u3067\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              secondaryDns: "\u30BB\u30AB\u30F3\u30C0\u30EADNS\u30A2\u30C9\u30EC\u30B9\u3092\u624B\u52D5\u3067\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002"
            }
          },
          submit: {
            message_site: "\u30B5\u30A4\u30C8\u306E\u8A73\u7D30\u3092\u78BA\u8A8D\u3057\u3066\u300C\u4FDD\u5B58\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u307E\u3059",
            message_gateway: "Edge Service\u306E\u8A73\u7D30\u3092\u78BA\u8A8D\u3057\u3066\u300C\u4FDD\u5B58\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u3066\u304F\u3060\u3055\u3044",
            name: "\u540D\u524D",
            model: "\u30E2\u30C7\u30EB",
            description: "\u5185\u5BB9",
            serial: "\u30B7\u30EA\u30A2\u30EB",
            gateway: "Edge Service",
            noGateway: "(\u306A\u3057)",
            upstream_gateway: "\u30A2\u30C3\u30D7\u30EA\u30F3\u30AF Edge Service",
            timezone: "\u6642\u9593\u5E2F",
            status: "\u63A5\u7D9A\u30B9\u30C6\u30FC\u30BF\u30B9",
            peeringAddress: "\u30D4\u30A2\u30EA\u30F3\u30B0\u30A2\u30C9\u30EC\u30B9",
            wan: "WAN",
            lan: "LAN",
            primaryDns: "\u30D7\u30E9\u30A4\u30DE\u30EA DNS",
            secondaryDns: "\u30BB\u30AB\u30F3\u30C0\u30EA DNS",
            haWarning: "\u3053\u306E\u30B5\u30A4\u30C8\u306FHA\u3067\u306F\u306A\u304F\u3001HA\u306F\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Services\u7528\u306B\u4F7F\u7528\u3059\u308B\u3053\u3068\u3092\u304A\u52E7\u3081\u3057\u307E\u3059\u3002"
          },
          result: {
            success_site: "\u30B5\u30A4\u30C8\u306F\u6B63\u5E38\u306B\u4F5C\u6210\u3055\u308C\u307E\u3057\u305F\u3002",
            success_gateway: "Edge Service\u306F\u6B63\u5E38\u306B\u4F5C\u6210\u3055\u308C\u307E\u3057\u305F\u3002",
            connectPort: "\u6A5F\u5668\u306E\u81EA\u52D5\u69CB\u6210\u3092\u53EF\u80FD\u306B\u3059\u308B\u305F\u3081\u306B\u3001\u4E0B\u8A18\u306E\u6A5F\u5668\u306E\u30DD\u30FC\u30C8<0>{{port}}</0>\u3092 DHCP\u5BFE\u5FDC\u306E\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u63A5\u7D9A\u3057\u3066\u3044\u308B\u3053\u3068\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u5225\u306E\u30DD\u30FC\u30C8\u3092\u4F7F\u7528\u3057\u305F\u3044\u5834\u5408\u306F\u6A5F\u5668\u3067\u76F4\u63A5\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
            connectPort_plural: "\u6A5F\u5668\u306E\u81EA\u52D5\u69CB\u6210\u3092\u53EF\u80FD\u306B\u3059\u308B\u305F\u3081\u306B\u3001\u4E0B\u8A18\u306E\u6A5F\u5668\u306E\u30DD\u30FC\u30C8<0>{{port}}</0>\u3092 DHCP\u5BFE\u5FDC\u306E\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u63A5\u7D9A\u3057\u3066\u3044\u308B\u3053\u3068\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u5225\u306E\u30DD\u30FC\u30C8\u3092\u4F7F\u7528\u3057\u305F\u3044\u5834\u5408\u306F\u6A5F\u5668\u3067\u76F4\u63A5\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
            HAMessage: "\u4E21\u65B9\u306E\u6A5F\u5668\u306E\u30DD\u30FC\u30C8<0>P1</0> \u3092\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF \u30B1\u30FC\u30D6\u30EB\u3067\u76F4\u7D50\u3057\u307E\u3059\u3002HA \u540C\u671F\u304C\u81EA\u52D5\u7684\u306B\u958B\u59CB\u3055\u308C\u307E\u3059\u3002",
            campus: "\u8A73\u7D30\u306F<0>Barracuda Campus</0>\u3092\u3054\u89A7\u304F\u3060\u3055\u3044",
            serial: "\u30B7\u30EA\u30A2\u30EB: {{serial}}",
            nonstandardPort: "\u8B66\u544A: \u3053\u306E\u8A2D\u5B9A\u306F\u6A19\u6E96\u4EE5\u5916\u306E\u30DD\u30FC\u30C8\u3092\u4F7F\u7528\u3057\u307E\u3059: <0>{{ports}}</0>\u3002\u3053\u308C\u304C\u4EEE\u60F3\u6A5F\u5668\u3067\u6709\u52B9\u306A\u3053\u3068\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
            nonstandardPort_plural: "\u8B66\u544A: \u3053\u306E\u8A2D\u5B9A\u306F\u6A19\u6E96\u4EE5\u5916\u306E\u30DD\u30FC\u30C8\u3092\u4F7F\u7528\u3057\u307E\u3059: <0>{{ports}}</0>\u3002\u3053\u308C\u3089\u304C\u4EEE\u60F3\u6A5F\u5668\u3067\u6709\u52B9\u306A\u3053\u3068\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002"
          },
          promoteResult: {
            primaryMessage: "\u30B5\u30A4\u30C8\u306FEdge Service\u306B\u6B63\u5E38\u306B\u6607\u683C\u3057\u307E\u3057\u305F\u3002",
            secondaryMessage: "\u3053\u308C\u3067\u3001\u65B0\u898F\u307E\u305F\u306F\u65E2\u5B58\u306E\u30B5\u30A4\u30C8\u306E\u63A5\u7D9A\u3092\u958B\u59CB\u3067\u304D\u307E\u3059\u3002"
          },
          steps: {
            result: "\u5B8C\u4E86",
            submit: "\u8981\u7D04"
          },
          types: {
            dynamic: "\u52D5\u7684 (DHCP)",
            configure_on_branch: "\u30D6\u30E9\u30F3\u30C1\u3067\u8A2D\u5B9A\u6E08\u307F"
          },
          validation: {
            duplicateNetwork: "\u3053\u306E\u30DD\u30FC\u30C8/\u4EEE\u60F3 LAN ID\u3068\u5408\u81F4\u3059\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306F\u3059\u3067\u306B\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u307E\u3059",
            duplicateWwan: "1\u3064\u306E WWAN \u306E\u307F\u8A2D\u5B9A\u53EF\u80FD\u3067\u3059",
            duplicateExpressRoute: "1\u3064\u306E\u30A8\u30AF\u30B9\u30D7\u30EC\u30B9 \u30EB\u30FC\u30C8\u306E\u307F\u8A2D\u5B9A\u53EF\u80FD\u3067\u3059",
            duplicateName: "\u4ED6\u306E\u63A5\u7D9A\u3067\u540D\u524D\u304C\u3059\u3067\u306B\u4F7F\u7528\u3055\u308C\u3066\u3044\u307E\u3059",
            secondaryDns: "\u30D7\u30E9\u30A4\u30DE\u30EA DNS\u304C\u306A\u3044\u9650\u308A\u8A2D\u5B9A\u3059\u3079\u304D\u3067\u306F\u3042\u308A\u307E\u305B\u3093",
            availableAsn: "\u3053\u306E ASN\u5024\u306F\u5229\u7528\u3067\u304D\u307E\u305B\u3093",
            publicIp: "IP\u30A2\u30C9\u30EC\u30B9\u306F\u516C\u958B\u7BC4\u56F2\u306B\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093",
            noLans: "\u5C11\u306A\u304F\u3068\u3082\u4E00\u3064\u306ELAN\u3092\u6709\u3059\u308B\u3053\u3068\u304C\u6761\u4EF6\u3067\u3059",
            exceededPPoE: "\u6700\u5927\u56DB\u672C\u306EWAN PPPoE\u3057\u304B\u8A2D\u5B9A\u3067\u304D\u307E\u305B\u3093",
            oneInlineBridge: "\u4E00\u3064\u306EWAN Bridge\u3057\u304B\u8A2D\u5B9A\u3067\u304D\u307E\u305B\u3093"
          }
        },
        settings: {
          tabTitle: "\u8A2D\u5B9A",
          basic: {
            tabTitle: "\u57FA\u672C\u60C5\u5831",
            name: "\u540D\u524D",
            model: "\u30E2\u30C7\u30EB",
            gateway: "Edge Service",
            serials: "\u30B7\u30EA\u30A2\u30EB",
            descriptions: {
              gateway_site: "\u3053\u306E\u30B5\u30A4\u30C8\u3092\u63A5\u7D9A\u3057\u305F\u3044 Barracuda SecureEdge Edge Service\u3002",
              gateway_gateway: "\u3053\u306EEdge Service\u3068\u63A5\u7D9A\u3057\u305F\u3044 Barracuda SecureEdge Edge Service\u3002",
              name_site: "\u30B5\u30A4\u30C8\u306E\u56FA\u6709\u540D\u3002",
              name_gateway: "Edge Service\u306E\u56FA\u6709\u540D\u3002",
              model_site: "\u3053\u306E\u30B5\u30A4\u30C8\u7528\u306E\u88C5\u7F6E\u30E2\u30C7\u30EB\u3002",
              model_gateway: "\u3053\u306EEdge Service\u7528\u306E\u88C5\u7F6E\u30E2\u30C7\u30EB\u3002",
              serials_site: "\u3053\u306E\u30B5\u30A4\u30C8\u7528\u306E\u88C5\u7F6E\u30B7\u30EA\u30A2\u30EB\u3002",
              serials_gateway: "\u3053\u306EEdge Service\u7528\u306E\u88C5\u7F6E\u30B7\u30EA\u30A2\u30EB\u3002"
            },
            serialEdit: {
              table: {
                status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
                serial: "\u30B7\u30EA\u30A2\u30EB",
                add: "\u4E8C\u756A\u76EE\u3092\u8FFD\u52A0"
              },
              addTitle: "\u30B7\u30EA\u30A2\u30EB\u3092\u8FFD\u52A0",
              editTitle: "\u30B7\u30EA\u30A2\u30EB\u3092\u30B9\u30EF\u30C3\u30D7",
              deleteTitle: "\u30B7\u30EA\u30A2\u30EB\u3092\u524A\u9664",
              edit_site: "\u3053\u306E\u30B5\u30A4\u30C8\u306B\u63A5\u7D9A\u3057\u305F\u3044\u4EA4\u63DB\u7528\u6A5F\u5668\u7528\u306E\u30B7\u30EA\u30A2\u30EB\u3092\u4E0B\u304B\u3089\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u6A5F\u5668\u306F\u540C\u30E2\u30C7\u30EB\u306E\u4ED6\u306E\u6A5F\u5668\u3068\u3057\u304B\u4EA4\u63DB\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u305B\u3093\u3002",
              edit_gateway: "\u3053\u306EEdge Service\u306B\u63A5\u7D9A\u3057\u305F\u3044\u4EA4\u63DB\u7528\u6A5F\u5668\u306E\u30B7\u30EA\u30A2\u30EB\u3092\u4E0B\u304B\u3089\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u6A5F\u5668\u306F\u540C\u30E2\u30C7\u30EB\u306E\u4ED6\u306E\u6A5F\u5668\u3068\u3057\u304B\u4EA4\u63DB\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u305B\u3093\u3002",
              edit: "\u3053\u306E\u30B5\u30A4\u30C8\u306B\u63A5\u7D9A\u3057\u305F\u3044\u4EA4\u63DB\u7528\u6A5F\u5668\u7528\u306E\u30B7\u30EA\u30A2\u30EB\u3092\u4E0B\u304B\u3089\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u6A5F\u5668\u306F\u540C\u30E2\u30C7\u30EB\u306E\u4ED6\u306E\u6A5F\u5668\u3068\u3057\u304B\u4EA4\u63DB\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u305B\u3093\u3002",
              add_site: "\u30B5\u30A4\u30C8\u306B\u7B2C\u4E8C\u306E\u6A5F\u5668\u3092\u63A5\u7D9A\u3059\u308B\u3068\u9AD8\u53EF\u7528\u6027\u6A5F\u80FD\u304C\u958B\u59CB\u3055\u308C\u307E\u3059\u3002\u6A5F\u5668\u306E\u30B7\u30EA\u30A2\u30EB\u3092\u4E0B\u304B\u3089\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              add_gateway: "Edge Service \u306B\u7B2C\u4E8C\u306E\u6A5F\u5668\u3092\u63A5\u7D9A\u3059\u308B\u3068\u9AD8\u53EF\u7528\u6027\u6A5F\u80FD\u304C\u958B\u59CB\u3055\u308C\u307E\u3059\u3002\u6A5F\u5668\u306E\u30B7\u30EA\u30A2\u30EB\u3092\u4E0B\u304B\u3089\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              add: "\u30B5\u30A4\u30C8\u306B\u7B2C\u4E8C\u306E\u6A5F\u5668\u3092\u63A5\u7D9A\u3059\u308B\u3068\u9AD8\u53EF\u7528\u6027\u6A5F\u80FD\u304C\u958B\u59CB\u3055\u308C\u307E\u3059\u3002\u6A5F\u5668\u306E\u30B7\u30EA\u30A2\u30EB\u3092\u4E0B\u304B\u3089\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              delete1_site: "\u3053\u306E\u30B5\u30A4\u30C8\u304B\u3089\u30B7\u30EA\u30A2\u30EB {{serial}} \u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B",
              delete1_gateway: "\u3053\u306EEdge Service\u304B\u3089\u30B7\u30EA\u30A2\u30EB {{serial}} \u3092\u524A\u9664\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B",
              delete1: "\u3053\u306E\u30B5\u30A4\u30C8\u304B\u3089\u30B7\u30EA\u30A2\u30EB {{serial}} \u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B",
              delete2: "\u3053\u308C\u306B\u3088\u308A\u9AD8\u53EF\u7528\u6027\u6A5F\u80FD\u304C\u306A\u304F\u306A\u308A\u3001\u95A2\u9023\u3059\u308B\u6A5F\u5668\u306F\u7121\u52B9\u306B\u306A\u308A\u307E\u3059\u3002",
              save: "\u5909\u66F4\u306F\u4FDD\u5B58\u5F8C\u6709\u52B9\u306B\u306A\u308A\u307E\u3059\u3002",
              saveDelete: "\u4FDD\u5B58\u5F8C\u3001\u6A5F\u5668\u3092\u5207\u65AD\u3067\u304D\u307E\u3059\u3002",
              saveAdd: "\u4FDD\u5B58\u3057\u305F\u3089\u3001\u6A5F\u5668\u306E\u30DD\u30FC\u30C8<0>P4</0>\u3092 DHCP \u304C\u6709\u52B9\u306A\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u6700\u521D\u306B\u63A5\u7D9A\u3057\u6A5F\u5668\u306E\u81EA\u52D5\u8A2D\u5B9A\u3092\u6709\u52B9\u306B\u3057\u3066\u65B0\u3057\u3044\u6A5F\u5668\u3092\u63A5\u7D9A\u3057\u307E\u3059\u3002\u5225\u306E\u30DD\u30FC\u30C8\u3092\u4F7F\u7528\u3057\u305F\u3044\u5834\u5408\u306F\u6A5F\u5668\u3067\u76F4\u63A5\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
              saveSCAdd: "\u4FDD\u5B58\u3057\u305F\u3089\u3001\u65B0\u3057\u3044\u6A5F\u5668\u3092\u63A5\u7D9A\u3057\u3001WAN\u30DD\u30FC\u30C8\u3092 DHCP\u5BFE\u5FDC\u306E\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u63A5\u7D9A\u3057\u3066\u3001\u6A5F\u5668\u306E\u81EA\u52D5\u69CB\u6210\u3092\u53EF\u80FD\u306B\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
              saveEdit: "\u4FDD\u5B58\u3057\u305F\u3089\u53E4\u3044\u6A5F\u5668\u306F\u5207\u65AD\u3057\u3066\u3082\u304B\u307E\u3044\u307E\u305B\u3093\u3002\u6B21\u306B\u3001\u65B0\u3057\u3044\u6A5F\u5668\u3092\u63A5\u7D9A\u3057\u3001 \u6A5F\u5668\u306E\u30DD\u30FC\u30C8<0>P4</0>\u3092 DHCP \u304C\u6709\u52B9\u306A\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u6700\u521D\u306B\u63A5\u7D9A\u3057\u3066\u6A5F\u5668\u306E\u81EA\u52D5\u8A2D\u5B9A\u3092\u6709\u52B9\u306B\u3057\u307E\u3059\u3002\u5225\u306E\u30DD\u30FC\u30C8\u3092\u4F7F\u7528\u3057\u305F\u3044\u5834\u5408\u306F\u6A5F\u5668\u3067\u76F4\u63A5\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
              saveSCEdit: "\u4FDD\u5B58\u3057\u305F\u3089\u53E4\u3044\u6A5F\u5668\u306F\u5207\u65AD\u3057\u3066\u3082\u304B\u307E\u3044\u307E\u305B\u3093\u3002\u6B21\u306B\u65B0\u3057\u3044\u30A2\u30D7\u30E9\u30A4\u30A2\u30F3\u30B9\u3092\u63A5\u7D9A\u3057\u3001WAN\u30DD\u30FC\u30C8\u3092 DHCP\u5BFE\u5FDC\u306E\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u63A5\u7D9A\u3057\u3066\u3001\u30A2\u30D7\u30E9\u30A4\u30A2\u30F3\u30B9\u306E\u81EA\u52D5\u69CB\u6210\u3092\u53EF\u80FD\u306B\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
              saveHA: "\u3055\u3089\u306B\u3001\u65B0\u3057\u3044\u6A5F\u5668\u3068\u65E2\u5B58\u306E\u6A5F\u5668\u306E\u30DD\u30FC\u30C8<0>P1</0>\u3092\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30B1\u30FC\u30D6\u30EB\u3067\u76F4\u7D50\u3057\u307E\u3059\u3002HA \u540C\u671F\u304C\u81EA\u52D5\u7684\u306B\u958B\u59CB\u3055\u308C\u307E\u3059\u3002",
              saveEditSecondary: "\u53E4\u3044\u6A5F\u5668\u306FHA\u7528\u306B\u8A2D\u5B9A\u6E08\u307F\u3067\u3059\u3002\u3053\u308C\u3092\u518D\u5EA6\u4F7F\u7528\u3067\u304D\u308B\u3088\u3046\u306B\u3059\u308B\u305F\u3081\u518D\u5EA6\u30A4\u30E1\u30FC\u30B8\u3092\u69CB\u6210\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
              saveDeleteSecondary: "\u3053\u306E\u6A5F\u5668\u306F HA\u7528\u306B\u8A2D\u5B9A\u6E08\u307F\u3067\u3059\u3002\u3053\u308C\u3092\u518D\u5EA6\u4F7F\u7528\u3067\u304D\u308B\u3088\u3046\u306B\u3059\u308B\u305F\u3081\u518D\u5EA6\u30A4\u30E1\u30FC\u30B8\u3092\u69CB\u6210\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
              saveDeletePrimary_site: "\u3053\u306E\u30B5\u30A4\u30C8\u306B\u63A5\u7D9A\u3055\u308C\u3066\u3044\u308B\u4ED6\u306E\u6A5F\u5668\u306FHA\u7528\u306B\u8A2D\u5B9A\u6E08\u307F\u3067\u3059\u3002HA\u6A5F\u5668\u3068\u3057\u3066\u518D\u8A2D\u5B9A\u3055\u308C\u308B\u305F\u3081\u306B\u306F\u4FDD\u5B58\u5F8C\u518D\u5EA6\u30A4\u30E1\u30FC\u30B8\u5316\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002\u3053\u306E\u9593\u30D7\u30ED\u30BB\u30B9\u306E\u7D42\u4E86\u307E\u3067\u30B5\u30A4\u30C8\u304C\u82E5\u5E72\u505C\u6B62\u3059\u308B\u3053\u3068\u306B\u306A\u308A\u307E\u3059\u3002",
              saveDeletePrimary_gateway: "\u3053\u306E Edge Service \u306B\u63A5\u7D9A\u3055\u308C\u3066\u3044\u308B\u4ED6\u306E\u6A5F\u5668\u306F HA\u7528\u306B\u8A2D\u5B9A\u6E08\u307F\u3067\u3059\u3002HA\u6A5F\u5668\u3068\u3057\u3066\u518D\u8A2D\u5B9A\u3055\u308C\u308B\u305F\u3081\u306B\u306F\u4FDD\u5B58\u5F8C\u518D\u5EA6\u30A4\u30E1\u30FC\u30B8\u5316\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002\u3053\u306E\u305F\u3081\u30D7\u30ED\u30BB\u30B9\u306E\u5B8C\u4E86\u307E\u3067Edge Service\u304C\u82E5\u5E72\u306E\u6642\u9593\u505C\u6B62\u3059\u308B\u3053\u3068\u306B\u306A\u308A\u307E\u3059\u3002",
              saveDeletePrimary: "\u3053\u306E\u30B5\u30A4\u30C8\u306B\u63A5\u7D9A\u3055\u308C\u3066\u3044\u308B\u4ED6\u306E\u6A5F\u5668\u306FHA\u7528\u306B\u8A2D\u5B9A\u6E08\u307F\u3067\u3059\u3002HA\u6A5F\u5668\u3068\u3057\u3066\u518D\u8A2D\u5B9A\u3055\u308C\u308B\u305F\u3081\u306B\u306F\u4FDD\u5B58\u5F8C\u518D\u5EA6\u30A4\u30E1\u30FC\u30B8\u5316\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002\u3053\u306E\u9593\u30D7\u30ED\u30BB\u30B9\u306E\u7D42\u4E86\u307E\u3067\u30B5\u30A4\u30C8\u304C\u82E5\u5E72\u505C\u6B62\u3059\u308B\u3053\u3068\u306B\u306A\u308A\u307E\u3059\u3002"
            }
          },
          wan: {
            tabTitle: "WAN",
            addTitle: "\u65B0\u898F WAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u8FFD\u52A0",
            addButtonText: "WAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u8FFD\u52A0",
            addSCButtonText: "WAN\u30D5\u30A9\u30FC\u30EB\u30D0\u30C3\u30AF\u3092\u8FFD\u52A0",
            editTitle: "WAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u7DE8\u96C6",
            deleteTitle: "WAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u524A\u9664",
            deleteMessage: "\u3053\u306E WAN \u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u672C\u5F53\u306B\u524A\u9664\u3057\u3066\u3082\u3088\u3044\u306E\u3067\u3059\u304B<0/>\u3053\u306EWAN\u3068\u95A2\u9023\u3057\u3066\u3044\u308B\u63A5\u7D9A\u304A\u3088\u3073\u30DD\u30EA\u30B7\u30FC(IPsec \u30C8\u30F3\u30CD\u30EB\u306A\u3069)\u306F\u81EA\u52D5\u7684\u306B\u524A\u9664\u3055\u308C\u307E\u3059\u3002<0/>\u524A\u9664\u306F\u4FDD\u5B58\u6642\u306B\u6052\u4E45\u7684\u306B\u53CD\u6620\u3055\u308C\u307E\u3059\u3002",
            name: "\u540D\u524D",
            port: "\u30DD\u30FC\u30C8",
            virtualLanId: "\u4EEE\u60F3 LAN ID",
            type: "\u30BF\u30A4\u30D7",
            providerPinning: "\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u306E\u30D4\u30F3\u30CB\u30F3\u30B0",
            fallback: "\u30D5\u30A9\u30FC\u30EB\u30D0\u30C3\u30AF",
            address: "IP\u30A2\u30C9\u30EC\u30B9",
            netmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
            gateway: "\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4",
            pin: "{{serial}}\u7528 SIM\u306E PIN",
            scPin: "SIM Pin",
            invalidPinFormat: "SIM\u306E Pin\u306F 4\u3064\u306E\u6570\u5B57\u3067\u3059\u3002",
            portWarning: "\u4E0A\u8A18\u306E\u30B0\u30EC\u30FC\u30A2\u30A6\u30C8\u3055\u308C\u305F\u884C\u306F\u9078\u629E\u3055\u308C\u305F\u30DD\u30FC\u30C8\u304C\u6A5F\u5668\u3067\u6709\u52B9\u3067\u306A\u3044\u3053\u3068\u3092\u793A\u3057\u307E\u3059\u3002",
            failover: "HA \u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC",
            network: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            doublePort: "\u30DD\u30FC\u30C8",
            doubleVirtualLanId: "\u4EEE\u60F3 LAN ID",
            doubleAddress: "IP\u30A2\u30C9\u30EC\u30B9",
            doubleNetmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
            virtualTransferNetwork: "\u4EEE\u60F3\u8EE2\u9001\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            asn: "\u30A8\u30AF\u30B9\u30D7\u30EC\u30B9\u30EB\u30FC\u30C8 ASN",
            expressRoute: "\u30A8\u30AF\u30B9\u30D7\u30EC\u30B9\u30EB\u30FC\u30C8",
            primary: "\u30D7\u30E9\u30A4\u30DE\u30EA",
            secondary: "\u30BB\u30AB\u30F3\u30C0\u30EA",
            validatePublicIp: "\u306F\u30E6\u30FC\u30B6\u30FC\u304C\u63A5\u7D9A\u3067\u304D\u308B\u3088\u3046\u306B\u30D1\u30D6\u30EA\u30C3\u30AFIP\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093",
            validateOnePort: "\u52D5\u7684/\u56FA\u5B9AWAN\u30DD\u30FC\u30C8\u3068WWAN\u30921\u3064\u305A\u3064\u3057\u304B\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u305B\u3093",
            descriptions: {
              name: "\u3053\u306E WAN\u30EA\u30F3\u30AF\u306E\u305F\u3081\u306E\u56FA\u6709\u540D\u3002",
              port: "\u3053\u306E\u30EA\u30F3\u30AF\u306B\u4F7F\u7528\u3059\u308B\u88C5\u7F6E\u30DD\u30FC\u30C8\u3002",
              virtualLanId: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u30AA\u30D7\u30B7\u30E7\u30F3\u4EEE\u60F3 LAN ID \uFF08\u4EEE\u60F3 LAN \u30B5\u30DD\u30FC\u30C8\u306E\u3042\u308B\u30B9\u30A4\u30C3\u30C1\u306B\u63A5\u7D9A\u3059\u308B\u5834\u5408\u306B\u4F7F\u7528\u3055\u308C\u307E\u3059\uFF09\u3002",
              type: "\u3053\u306E\u30EA\u30F3\u30AF\u7528\u306B\u4F7F\u7528\u3059\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30BF\u30A4\u30D7\u3002",
              providerPinning: "\u3053\u306E\u30EA\u30F3\u30AF\u306E\u30D4\u30F3\u30CB\u30F3\u30B0\u5148\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u30B0\u30EB\u30FC\u30D7\u3002\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u30B0\u30EB\u30FC\u30D7\u306F\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u30D5\u30ED\u30FC\u3068\u52D5\u4F5C\u3092\u5B9A\u7FA9\u3059\u308B\u305F\u3081\u306B SD-WAN \u30DD\u30EA\u30B7\u30FC\u3067\u4F7F\u7528\u3055\u308C\u307E\u3059\u3002",
              address: "\u30DD\u30FC\u30C8\u306B\u5272\u308A\u5F53\u3066\u308B Ip\u30A2\u30C9\u30EC\u30B9\u3002",
              netmask: "\u3053\u306E\u30EA\u30F3\u30AF\u306E\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF CIDR \u30CD\u30C3\u30C8\u30DE\u30B9\u30AF \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u3002",
              gateway: "\u3053\u306E\u30EA\u30F3\u30AF\u306B\u95A2\u9023\u4ED8\u3051\u3089\u308C\u3066\u3044\u308B\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4 IP\u30A2\u30C9\u30EC\u30B9\u3002",
              pin: "\u3053\u306E\u88C5\u7F6E\u306B\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u3055\u308C\u3066\u3044\u308B SIM\u30AB\u30FC\u30C9\u7528\u306E\u500B\u4EBA\u8B58\u5225\u756A\u53F7\u3002",
              failover: "\u6709\u52B9\u306B\u3059\u308B\u3068\u3001\u30DD\u30FC\u30C8\u306F\u30E2\u30CB\u30BF\u30FC\u3055\u308C\u3001\u63A5\u7D9A\u304C\u5931\u308F\u308C\u305F\u5834\u5408\u306B HA\u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC\u304C\u5B9F\u884C\u3055\u308C\u307E\u3059\u3002HA\u304C\u6709\u52B9\u306A\u3068\u304D\u306E\u307F\u5229\u7528\u53EF\u80FD\u3067\u3059\u3002",
              doublePort: "\u30D7\u30E9\u30A4\u30DE\u30EA\u30EA\u30F3\u30AF\u3068\u30BB\u30AB\u30F3\u30C0\u30EA\u30EA\u30F3\u30AF\u7528\u306B\u4F7F\u7528\u3055\u308C\u308B\u88C5\u7F6E\u306E\u30DD\u30FC\u30C8\u3002",
              doubleVirtualLanId: "\u30D7\u30E9\u30A4\u30DE\u30EA\u30EA\u30F3\u30AF\u3068\u30BB\u30AB\u30F3\u30C0\u30EA\u30EA\u30F3\u30AF\u7528\u306E\u30AA\u30D7\u30B7\u30E7\u30F3\u4EEE\u60F3 LAN ID(\u4EEE\u60F3 LAN \u5BFE\u5FDC\u30B9\u30A4\u30C3\u30C1\u306B\u63A5\u7D9A\u3059\u308B\u3068\u304D\u4F7F\u7528)\u3002",
              doubleAddress: "\u30D7\u30E9\u30A4\u30DE\u30EA\u30EA\u30F3\u30AF\u3068\u30BB\u30AB\u30F3\u30C0\u30EA\u30EA\u30F3\u30AF\u306B\u5272\u308A\u5F53\u3066\u308B IP \u30A2\u30C9\u30EC\u30B9\u3002\u3053\u308C\u3089\u306F\u3001Azure\u3067\u69CB\u6210\u3055\u308C\u305F\u5404\u63A5\u7D9A\u306E\u7BC4\u56F2\u306E\u6700\u521D\u306E\u4F7F\u7528\u53EF\u80FD\u306A IP\u3067\u3042\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059(\u305F\u3068\u3048\u3070\u3001Azure\u3067 172.16\u30020.4/30 \u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u308B\u5834\u5408\u3001172.16\u30020.5 \u304C\u5165\u529B\u3059\u308B IP\u30A2\u30C9\u30EC\u30B9\u3068\u306A\u308A\u307E\u3059\uFF09\u3002",
              doubleNetmask: "\u30D7\u30E9\u30A4\u30DE\u30EA\u30EA\u30F3\u30AF\u3068\u30BB\u30AB\u30F3\u30C0\u30EA\u30EA\u30F3\u30AF\u7528\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF CIDR \u30CD\u30C3\u30C8\u30DE\u30B9\u30AF \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u3002",
              asn: "\u3053\u306E\u30EA\u30F3\u30AF\u7528\u306B\u81EA\u52D5\u751F\u6210\u3055\u308C\u305F BGP ASN\u3002\u3053\u308C\u3067SecureEdge\u306B\u7BA1\u7406\u3055\u308C\u308B\u4ED6\u306EASN\u3068\u7AF6\u5408\u3057\u306A\u304F\u306A\u308A\u307E\u3059\u3002",
              virtualTransferNetwork: "\u4EEE\u60F3\u8EE2\u9001\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u7528\u306BIP\u3092\u4E88\u7D04\u3059\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3002",
              healthCheck: {
                enabled: "\u6709\u52B9\u306B\u3059\u308B\u3068\u3001\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u304C\u305D\u306E\u5065\u5168\u6027\u3092\u5B9A\u671F\u7684\u306B\u70B9\u691C\u3057\u307E\u3059\u3002",
                mode: "\u4F7F\u7528\u3057\u305F\u3044Health Check\u30E2\u30FC\u30C9\u3002",
                targets: "ICMP Health Check\u3067\u30BF\u30FC\u30B2\u30C3\u30C8\u3059\u308B\u969B\u306EIP\u30A2\u30C9\u30EC\u30B9\u3002"
              },
              userNamePassword: "256\u6587\u5B57\u672A\u6E80\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093",
              mtu: "1492 (\u542B\u3080)\u500B\u307E\u3067\u3068\u3057\u307E\u3059\u3002",
              additionalAddresses: "\u30DD\u30FC\u30C8\u306B\u5272\u5F53\u3066\u308B\u8FFD\u52A0\u306EIP\u30A2\u30C9\u30EC\u30B9\u3002"
            },
            bridge: "Bridge",
            advanced: "\u8A73\u7D30\u8A2D\u5B9A",
            basic: "\u57FA\u672C",
            userName: "\u30E6\u30FC\u30B6\u30FC\u540D",
            password: "\u30D1\u30B9\u30EF\u30FC\u30C9",
            mtu: "MTU\u30B5\u30A4\u30BA",
            healthCheck: {
              enabled: "Health Check\u3092\u6709\u52B9\u5316",
              mode: "Health Check",
              targets: "Health Check\u30BF\u30FC\u30B2\u30C3\u30C8"
            },
            warning: "WAN\u30BF\u30A4\u30D7\u3092\u5909\u66F4\u3059\u308B\u3068\u95A2\u9023\u3057\u3066\u3044\u308BIPSec\u30C8\u30F3\u30CD\u30EB\u307E\u305F\u306FNAT\u30A4\u30F3\u30B0\u30EC\u30B9\u306E\u30EB\u30FC\u30EB\u304C\u524A\u9664\u3055\u308C\u307E\u3059\u3002",
            additionalAddresses: "\u8FFD\u52A0\u306EIP\u30A2\u30C9\u30EC\u30B9",
            addressWarning: "\u8B66\u544A\uFF1A\u524A\u9664\u3057\u305F\u8FFD\u52A0\u306EWAN IP\u30A2\u30C9\u30EC\u30B9\u304C\u4E00\u3064\u306EIPSec\u30C8\u30F3\u30CD\u30EB\u307E\u305F\u306FNAT\u9032\u5165\u30EB\u30FC\u30EB\u3067\u4F7F\u7528\u4E2D\u3067\u3042\u3063\u305F\u5834\u5408\u3001\u3053\u308C\u3089\u306E\u5909\u66F4\u3092\u4FDD\u5B58\u3059\u308B\u3068\u95A2\u9023\u4ED8\u3051\u3089\u308C\u3066\u3044\u308B\u30EB\u30FC\u30EB\u304C\u524A\u9664\u3055\u308C\u308B\u3053\u3068\u306B\u306A\u308A\u307E\u3059\u3002"
          },
          lan: {
            tabTitle: "LAN",
            sectionTitle: "LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9",
            addTitle: "\u65B0\u898F LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u8FFD\u52A0",
            addButtonText: "LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u8FFD\u52A0",
            editTitle: "LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u7DE8\u96C6",
            deleteTitle: "LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u524A\u9664",
            deleteMessage1: "\u3053\u306E LAN \u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u672C\u5F53\u306B\u524A\u9664\u3057\u3066\u3082\u3088\u3044\u306E\u3067\u3059\u304B\u3053\u308C\u3067\u4EE5\u4E0B\u306E\u52B9\u679C\u304C\u5B9F\u73FE\u3057\u307E\u3059:",
            deleteMessage2: "\u4E00\u81F4\u3059\u308B DHCP\u8A2D\u5B9A\u304A\u3088\u3073\u4E88\u7D04\u306F\u524A\u9664\u3055\u308C\u307E\u3059\u3002",
            deleteMessage3: "\u3053\u306E LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u4F7F\u7528\u3059\u308B \u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30EB\u30FC\u30EB\u306F\u3059\u3079\u3066\u66F4\u65B0\u307E\u305F\u306F\u524A\u9664\u3055\u308C\u307E\u3059\u3002",
            deleteMessage4: "\u524A\u9664\u306F\u4FDD\u5B58\u6642\u306B\u6052\u4E45\u7684\u306B\u53CD\u6620\u3055\u308C\u307E\u3059\u3002",
            name: "\u540D\u524D",
            port: "\u30DD\u30FC\u30C8",
            virtualLanId: "\u4EEE\u60F3 LAN ID",
            address: "IP\u30A2\u30C9\u30EC\u30B9",
            netmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
            dhcpEnable: "DHCP \u30B5\u30FC\u30D0\u30FC",
            dhcpFirst: "\u5148\u982D\u306E IP\u30A2\u30C9\u30EC\u30B9",
            dhcpLast: "\u6700\u5F8C\u306E IP\u30A2\u30C9\u30EC\u30B9",
            dhcpDomain: "DHCP\u30C9\u30E1\u30A4\u30F3",
            dhcpRange: "DHCP\u7BC4\u56F2",
            portWarning: "\u4E0A\u8A18\u306E\u30B0\u30EC\u30FC\u30A2\u30A6\u30C8\u3055\u308C\u305F\u884C\u306F\u9078\u629E\u3055\u308C\u305F\u30DD\u30FC\u30C8\u304C\u6A5F\u5668\u3067\u6709\u52B9\u3067\u306A\u3044\u3053\u3068\u3092\u793A\u3057\u307E\u3059\u3002",
            failover: "HA \u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC",
            descriptions: {
              name: "\u3053\u306E LAN\u30EA\u30F3\u30AF\u306E\u305F\u3081\u306E\u56FA\u6709\u540D\u3002",
              port: "\u3053\u306E\u30EA\u30F3\u30AF\u306B\u4F7F\u7528\u3059\u308B\u88C5\u7F6E\u30DD\u30FC\u30C8\u3002",
              virtualLanId: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u30AA\u30D7\u30B7\u30E7\u30F3\u4EEE\u60F3 LAN ID \uFF08\u4EEE\u60F3 LAN \u30B5\u30DD\u30FC\u30C8\u306E\u3042\u308B\u30B9\u30A4\u30C3\u30C1\u306B\u63A5\u7D9A\u3059\u308B\u5834\u5408\u306B\u4F7F\u7528\u3055\u308C\u307E\u3059\uFF09\u3002",
              address: "\u30DD\u30FC\u30C8\u306B\u5272\u308A\u5F53\u3066\u308B Ip\u30A2\u30C9\u30EC\u30B9\u3002",
              netmask: "\u4F5C\u6210\u3057\u305F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306B\u4F7F\u7528\u3059\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF CIDR\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u3002",
              dhcpEnable: "\u3053\u306E\u30EA\u30F3\u30AF\u7528\u306B\u88C5\u7F6E\u3067 DHCP \u30B5\u30FC\u30D0\u30FC\u3092\u6709\u52B9\u306B\u3057\u307E\u3059\u3002",
              dhcpFirst: "DHCP\u5272\u308A\u5F53\u3066\u7528\u306B\u6700\u521D\u306B\u5229\u7528\u53EF\u80FD\u306AIP\u3002\u3053\u308C\u306F\u30DD\u30FC\u30C8\u306E IP \u3068\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF\u3067\u5B9A\u7FA9\u3055\u308C\u305F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u5185\u306B\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
              dhcpLast: "DHCP \u5272\u308A\u5F53\u3066\u306B\u6700\u5F8C\u306B\u5229\u7528\u53EF\u80FD\u306A IP\u3002\u3053\u308C\u306F\u30DD\u30FC\u30C8\u306E IP \u3068\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF\u3067\u5B9A\u7FA9\u3055\u308C\u305F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u5185\u306B\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
              dhcpDomain: "DHCP \u30B5\u30FC\u30D0\u30FC\u306B\u5272\u308A\u5F53\u3066\u308B\u30C9\u30E1\u30A4\u30F3\u540D\u3002",
              failover: "\u6709\u52B9\u306B\u3059\u308B\u3068\u3001\u30DD\u30FC\u30C8\u306F\u30E2\u30CB\u30BF\u30FC\u3055\u308C\u3001\u63A5\u7D9A\u304C\u5931\u308F\u308C\u305F\u5834\u5408\u306B HA\u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC\u304C\u5B9F\u884C\u3055\u308C\u307E\u3059\u3002HA\u304C\u6709\u52B9\u306A\u3068\u304D\u306E\u307F\u5229\u7528\u53EF\u80FD\u3067\u3059\u3002"
            },
            wifi: {
              validateSsid: "\u82F1\u6570\u5B57\u307E\u305F\u306F\u6587\u5B57\u300C-\u300D\u306E\u307F\u3092\u542B\u3080\u3053\u3068"
            }
          },
          route: {
            tabTitle: "\u30EB\u30FC\u30C8",
            sectionTitle: "\u30B9\u30BF\u30C6\u30A3\u30C3\u30AF\u30EB\u30FC\u30C8",
            addTitle: "\u65B0\u898F\u30B9\u30BF\u30C6\u30A3\u30C3\u30AF\u30EB\u30FC\u30C8\u3092\u8FFD\u52A0",
            addButtonText: "\u30B9\u30BF\u30C6\u30A3\u30C3\u30AF\u30EB\u30FC\u30C8\u3092\u8FFD\u52A0",
            editTitle: "\u30B9\u30BF\u30C6\u30A3\u30C3\u30AF\u30EB\u30FC\u30C8\u3092\u7DE8\u96C6",
            deleteTitle: "\u30B9\u30BF\u30C6\u30A3\u30C3\u30AF\u30EB\u30FC\u30C8\u3092\u524A\u9664",
            deleteMessage: "\u3053\u306E\u30B9\u30BF\u30C6\u30A3\u30C3\u30AF\u30EB\u30FC\u30C8\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u3044\u306E\u3067\u3059\u304B\u3002\u524A\u9664\u306F\u4FDD\u5B58\u6642\u306B\u6052\u4E45\u7684\u306B\u53CD\u6620\u3055\u308C\u307E\u3059\u3002",
            name: "\u540D\u524D",
            address: "\u30BF\u30FC\u30B2\u30C3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            netmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
            gateway: "\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4",
            descriptions: {
              name: "\u3053\u306E\u30EB\u30FC\u30C8\u306E\u56FA\u6709\u540D\u3002",
              address: "\u3053\u306E\u30EB\u30FC\u30C8\u306E\u30BF\u30FC\u30B2\u30C3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30A2\u30C9\u30EC\u30B9\u3002",
              netmask: "\u30BF\u30FC\u30B2\u30C3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E CIDR\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u3002",
              gateway: "\u3053\u306E\u30EB\u30FC\u30C8\u306B\u4F7F\u7528\u3059\u308B\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4\u3002"
            }
          },
          dhcp: {
            tabTitle: "DHCP",
            networksSectionTitle: "DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            leasesSectionTitle: "DHCP\u30EA\u30FC\u30B9",
            reservationsSectionTitle: "DHCP\u4E88\u7D04",
            dhcpEnable: "DHCP Service\u3092\u6709\u52B9\u306B\u3059\u308B",
            addNetworkTitle: "\u65B0\u898F DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u8FFD\u52A0",
            addNetworkButtonText: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u8FFD\u52A0",
            editNetworkTitle: "DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u7DE8\u96C6",
            deleteNetworkTitle: "DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u524A\u9664",
            deleteNetworkMessage: "\u3053\u306E DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u524A\u9664\u3057\u3066\u3082\u304B\u307E\u3044\u307E\u305B\u3093\u304B\u3002\u524A\u9664\u306F\u4FDD\u5B58\u6642\u306B\u6052\u4E45\u7684\u306B\u53CD\u6620\u3055\u308C\u307E\u3059\u3002",
            networks: {
              networkSectionTitle: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
              basicSectionTitle: "\u57FA\u672C",
              basicTabTitle: "\u57FA\u672C",
              advancedSectionTitle: "\u8A73\u7D30\u8A2D\u5B9A",
              advancedTabTitle: "\u8A73\u7D30\u8A2D\u5B9A",
              enable: "DHCP\u30B9\u30C6\u30FC\u30BF\u30B9",
              port: "LAN \u540D",
              description: "\u5185\u5BB9",
              firstIp: "\u5148\u982D\u306E IP\u30A2\u30C9\u30EC\u30B9",
              lastIp: "\u6700\u5F8C\u306E IP\u30A2\u30C9\u30EC\u30B9",
              domain: "\u30C9\u30E1\u30A4\u30F3\u540D",
              dnsServers: "DNS\u30B5\u30FC\u30D0\u30FC",
              bootFileName: "Boot\u30D5\u30A1\u30A4\u30EB\u540D",
              defaultLeaseTime: "\u65E2\u5B9A\u30EA\u30FC\u30B9\u6642\u9593",
              maxLeaseTime: "\u6700\u5927\u30EA\u30FC\u30B9\u6642\u9593",
              winsServers: "WINS\u30B5\u30FC\u30D0\u30FC",
              tftpHostname: "TFTP \u30DB\u30B9\u30C8\u540D",
              tftpIpAddress: "TFTP IP\u30A2\u30C9\u30EC\u30B9",
              vendorOptions: "\u30D9\u30F3\u30C0\u30FC\u30AA\u30D7\u30B7\u30E7\u30F3",
              validateMaxLeaseTime: "\u65E2\u5B9A\u30EA\u30FC\u30B9\u6642\u9593\u3088\u308A\u77ED\u304F\u3059\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093",
              dhcpRelayType: "DHCP",
              disabled: "\u7121\u52B9",
              dhcpRelay: "DHCP\u30EA\u30EC\u30FC",
              dhcpService: "DHCP Service"
            },
            blockUnknownMac: "\u65E2\u77E5\u306E MAC\u30A2\u30C9\u30EC\u30B9\u306E\u307F\u8A8D\u3081\u308B",
            addReservationTitle: "\u65B0\u898F DHCP\u4E88\u7D04\u3092\u8FFD\u52A0",
            addReservationButtonText: "\u4E88\u7D04\u3092\u8FFD\u52A0",
            editReservationTitle: "DHCP\u4E88\u7D04\u3092\u7DE8\u96C6",
            deleteReservationTitle: "DHCP\u4E88\u7D04\u3092\u524A\u9664",
            deleteReservationMessage: "\u3053\u306EDHCP\u4E88\u7D04\u3092\u524A\u9664\u3057\u3066\u3082\u304B\u307E\u3044\u307E\u305B\u3093\u304B\u3002\u524A\u9664\u306F\u4FDD\u5B58\u6642\u306B\u6052\u4E45\u7684\u306B\u53CD\u6620\u3055\u308C\u307E\u3059\u3002",
            leases: {
              reservationSectionTitle: "\u30EA\u30FC\u30B9",
              port: "LAN \u540D",
              ip: "IP\u30A2\u30C9\u30EC\u30B9",
              mac: "MAC \u30A2\u30C9\u30EC\u30B9",
              reserve: "\u4E88\u7D04IP",
              noLeases: "\u73FE\u5728\u6709\u52B9\u306A\u30EA\u30FC\u30B9\u306F\u3042\u308A\u307E\u305B\u3093\u3002\u65B0\u898F\u30EA\u30FC\u30B9\u304C\u8868\u793A\u3055\u308C\u308B\u307E\u3067\u6700\u5927 2 \u5206\u304B\u304B\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002"
            },
            reservations: {
              reservationSectionTitle: "\u4E88\u7D04",
              name: "\u540D\u524D",
              description: "\u5185\u5BB9",
              port: "LAN \u540D",
              ip: "IP\u30A2\u30C9\u30EC\u30B9",
              mac: "MAC \u30A2\u30C9\u30EC\u30B9",
              clientId: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8ID",
              hostname: "\u30DB\u30B9\u30C8\u540D",
              validateMac: "\u6709\u52B9\u306AMAC\u30A2\u30C9\u30EC\u30B9\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093",
              validateHostname: "\u82F1\u6570\u5B57\u3001\u300C-\u300D\u307E\u305F\u306F\u300C_\u300D\u306E\u6587\u5B57\u306E\u307F\u542B\u3081\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059",
              duplicate: "LAN\u5185\u3067\u56FA\u6709\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093",
              duplicateName: "\u56FA\u6709\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093"
            },
            descriptions: {
              dhcpEnable_site: "\u3053\u306E\u30B5\u30A4\u30C8\u306E DHCP Service\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u3092\u6709\u52B9\u306B\u3057\u307E\u3059\u3002",
              dhcpEnable_gateway: "\u3053\u306EEdge Service\u306E DHCP Service\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u3092\u6709\u52B9\u306B\u3057\u307E\u3059\u3002",
              blockUnknownMac: "\u6709\u52B9\u306B\u3059\u308B\u3068\u3001\u4E0B\u306E\u8868\u306B\u8A18\u8F09\u3055\u308C\u3066\u3044\u308B MAC\u30A2\u30C9\u30EC\u30B9\u306E\u307F\u304C DHCP Service\u3092\u4F7F\u7528\u3067\u304D\u308B\u3088\u3046\u306B\u306A\u308A\u307E\u3059\u3002",
              networks: {
                enable: "\u3053\u306E\u30EA\u30F3\u30AF\u7528\u306B\u88C5\u7F6E\u3067 DHCP \u30B5\u30FC\u30D0\u30FC\u3092\u6709\u52B9\u306B\u3057\u307E\u3059\u3002",
                description: "\u3053\u306EDHCP \u30B5\u30FC\u30D0\u30FC\u8A2D\u5B9A\u306B\u30AA\u30D7\u30B7\u30E7\u30F3\u306E\u8A73\u7D30\u3092\u8A18\u5165\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
                port: "\u3053\u306E DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u8A2D\u5B9A\u306B\u4F7F\u7528\u3059\u308BLAN \u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3002",
                firstIp: "DHCP\u5272\u308A\u5F53\u3066\u7528\u306B\u6700\u521D\u306B\u5229\u7528\u53EF\u80FD\u306AIP\u3002\u3053\u308C\u306F\u30DD\u30FC\u30C8\u306E IP \u3068\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF\u3067\u5B9A\u7FA9\u3055\u308C\u305F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u5185\u306B\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
                lastIp: "DHCP \u5272\u308A\u5F53\u3066\u306B\u6700\u5F8C\u306B\u5229\u7528\u53EF\u80FD\u306A IP\u3002\u3053\u308C\u306F\u30DD\u30FC\u30C8\u306E IP \u3068\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF\u3067\u5B9A\u7FA9\u3055\u308C\u305F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u5185\u306B\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
                domain: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u3001DHCP \u30B5\u30FC\u30D0\u30FC\u306B\u5272\u308A\u5F53\u3066\u308B\u30C9\u30E1\u30A4\u30F3\u540D\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u3002",
                dnsServers: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u3001\u4F7F\u7528\u3059\u308B DNS\u30B5\u30FC\u30D0\u30FC\u30EA\u30B9\u30C8\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u3002",
                bootFileName: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u3001\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u304C\u8AAD\u307F\u8FBC\u3080\u3079\u304D\u521D\u671Fboot\u30D5\u30A1\u30A4\u30EB\u540D\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u3002\u3053\u308C\u306F\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u304C\u30D5\u30A1\u30A4\u30EB\u3092\u8AAD\u307F\u8FBC\u3080\u306E\u306B\u4F7F\u7528\u3059\u308B\u3068\u898B\u8FBC\u307E\u308C\u308B\u30D5\u30A1\u30A4\u30EB\u8EE2\u9001\u30D7\u30ED\u30C8\u30B3\u30EB\u3092\u554F\u308F\u305A\u8A8D\u8B58\u53EF\u80FD\u3067\u3042\u308B\u3079\u304D\u3067\u3059\u3002",
                defaultLeaseTime: "\u30EA\u30FC\u30B9\u306B\u5272\u308A\u5F53\u3066\u308B\u65E2\u5B9A\u79D2\u6570\u3002",
                maxLeaseTime: "\u30EA\u30FC\u30B9\u306B\u5272\u308A\u5F53\u3066\u308B\u6700\u5927\u79D2\u6570\u3002",
                winsServers: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u30011\u3064\u307E\u305F\u306F\u8907\u6570\u306E WINS\u30B5\u30FC\u30D0\u30FC IP\u30A2\u30C9\u30EC\u30B9\u3092\u8FFD\u52A0\u3057\u307E\u3059\u3002",
                tftpHostname: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u3001TFTP\u30B5\u30FC\u30D0\u30FC\u306B\u30DB\u30B9\u30C8\u540D\u3092\u4ED8\u3051\u307E\u3059\u3002",
                tftpIpAddress: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u3001TFTP\u30B5\u30FC\u30D0\u30FC\u306E IP\u30A2\u30C9\u30EC\u30B9\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u3002",
                vendorOptions: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u30011\u3064\u307E\u305F\u306F\u8907\u6570\u306E\u300C;\u300D\uFF08\u30BB\u30DF\u30B3\u30ED\u30F3\uFF09\u533A\u5207\u308A\u3067\u30D9\u30F3\u30C0\u30FC\u30AA\u30D7\u30B7\u30E7\u30F3\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u3002"
              },
              reservations: {
                name: "\u3053\u306E\u4E88\u7D04\u3092\u8B58\u5225\u3059\u308B\u305F\u3081\u306B\u4F7F\u7528\u3059\u308B\u540D\u524D\u3002",
                description: "\u3053\u306E\u4E88\u7D04\u306B\u95A2\u3059\u308B\u4EFB\u610F\u306E\u8A73\u7D30\u3002",
                port: "\u3053\u306E\u4E88\u7D04\u3092\u884C\u3046\u305F\u3081\u306E LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3002",
                ip: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u7528\u306B\u4E88\u7D04\u3059\u308BIP\u3002",
                mac: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u306E\u8B58\u5225\u7528 MAC\u30A2\u30C9\u30EC\u30B9\u3002",
                clientId: "IP\u30A2\u30C9\u30EC\u30B9\u3092\u8981\u8ACB\u3059\u308B\u969B\u306B\u30DB\u30B9\u30C8\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u3092\u8B58\u5225\u3059\u308B\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u8B58\u5225\u5B50\u3002",
                hostname: "\u3053\u306E IP\u306B\u5272\u308A\u5F53\u3066\u308B\u30DB\u30B9\u30C8\u540D\u3002"
              },
              dhcpRelayType: "DHCP\u30BF\u30A4\u30D7\u3092\u9078\u3093\u3067\u304F\u3060\u3055\u3044\u3002"
            },
            validation: {
              dhcpEnable: "WAN Bridge\u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u308B\u3068\u304D\u306F\u6709\u52B9\u5316\u3059\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093"
            }
          },
          advanced: {
            tabTitle: "\u8A73\u7D30\u8A2D\u5B9A",
            primaryDns: "\u30D7\u30E9\u30A4\u30DE\u30EA DNS",
            secondaryDns: "\u30BB\u30AB\u30F3\u30C0\u30EA DNS",
            rootPassword: "Root\u306E\u30D1\u30B9\u30EF\u30FC\u30C9",
            rootPasswordConfirmation: "Root\u306E\u30D1\u30B9\u30EF\u30FC\u30C9\u78BA\u8A8D\u5165\u529B",
            updateWindowTZ: "\u66F4\u65B0\u6642\u9593\u67A0\u306E\u6642\u9593\u5E2F",
            disableOMS: "Log\u5206\u6790\u3092\u7121\u52B9\u306B\u3059\u308B",
            disableSecurityPolicy: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u3092\u7121\u52B9\u306B\u3059\u308B",
            disableIpsPolicy: "IPS\u3092\u7121\u52B9\u306B\u3059\u308B",
            omsNote: "\u3053\u308C\u306B\u3088\u308A\u65E2\u5B9A\u306ELog\u5206\u6790\u8A2D\u5B9A\u306F\u30AA\u30FC\u30D0\u30FC\u30E9\u30A4\u30C9\u3055\u308C\u307E\u3059\u3002",
            descriptions: {
              updateWindowTZ_site: "\u3053\u306E\u30B5\u30A4\u30C8\u306E\u66F4\u65B0\u6642\u9593\u67A0\u3092\u6C7A\u5B9A\u3059\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u308B\u30B5\u30A4\u30C8\u306E\u6642\u9593\u5E2F\u3002",
              updateWindowTZ_gateway: "\u3053\u306EEdge Service\u306E\u66F4\u65B0\u6642\u9593\u67A0\u3092\u6C7A\u5B9A\u3059\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u308B\u3001Edge Service\u306E\u6642\u9593\u5E2F\u3002",
              primaryDns: "\u30D7\u30E9\u30A4\u30DE\u30EADNS\u30A2\u30C9\u30EC\u30B9\u3092\u624B\u52D5\u3067\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              secondaryDns: "\u30BB\u30AB\u30F3\u30C0\u30EADNS\u30A2\u30C9\u30EC\u30B9\u3092\u624B\u52D5\u3067\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              rootPassword_site: "\u30B5\u30A4\u30C8\u306E root\u30E6\u30FC\u30B6\u30FC\u30D1\u30B9\u30EF\u30FC\u30C9\u3002",
              rootPassword_gateway: "Edge Service\u306E root\u30E6\u30FC\u30B6\u30FC\u30D1\u30B9\u30EF\u30FC\u30C9\u3002",
              disableOMS_site: "\u3053\u306E\u30B5\u30A4\u30C8\u306E Azure Log Analytics \u3078\u306ELog\u306E\u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0\u3092\u7121\u52B9\u306B\u3057\u307E\u3059\u3002",
              disableOMS_gateway: "\u3053\u306EEdge Service\u306E Azure Log Analytics\u3078\u306ELog\u306E\u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0\u3092\u7121\u52B9\u306B\u3057\u307E\u3059\u3002",
              disableSecurityPolicy_site: "\u3053\u306E\u30B5\u30A4\u30C8\u306E\u3059\u3079\u3066\u306E\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30DD\u30EA\u30B7\u30FC\u3092\u7121\u52B9\u306B\u3059\u308B\u3002",
              disableSecurityPolicy_gateway: "\u3053\u306EEdge Service\u306E\u3059\u3079\u3066\u306E\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30DD\u30EA\u30B7\u30FC\u3092\u7121\u52B9\u306B\u3059\u308B\u3002",
              disableIpsPolicy_site: "\u3053\u306E\u30B5\u30A4\u30C8\u306E IPS\u30DD\u30EA\u30B7\u30FC\u3092\u7121\u52B9\u306B\u3059\u308B\u3002",
              disableIpsPolicy_gateway: "\u3053\u306EEdge Service\u306E IPS\u30DD\u30EA\u30B7\u30FC\u3092\u7121\u52B9\u306B\u3059\u308B\u3002"
            }
          },
          confirmPortNotActivated: "\u9078\u629E\u3055\u308C\u305F\u30DD\u30FC\u30C8\u306E\u4E00\u90E8\u306F\u3053\u306E\u88C5\u7F6E\u3067\u306F\u73FE\u5728\u6709\u52B9\u306B\u306A\u3063\u3066\u3044\u307E\u305B\u3093\u3002",
          confirmPortNotActivated_site: "\u9078\u629E\u3055\u308C\u305F\u30DD\u30FC\u30C8\u306E\u4E00\u90E8\u306F\u3053\u306E\u30B5\u30A4\u30C8\u3067\u306F\u73FE\u5728\u6709\u52B9\u306B\u306A\u3063\u3066\u3044\u307E\u305B\u3093\u3002",
          confirmPortNotActivated_gateway: "\u9078\u629E\u3055\u308C\u305F\u30DD\u30FC\u30C8\u306E\u4E00\u90E8\u306F\u3001\u73FE\u5728\u3053\u306EEdge Service\u306B\u3064\u3044\u3066\u306F\u6709\u52B9\u306B\u306A\u3063\u3066\u3044\u307E\u305B\u3093\u3002",
          confirmPointOfEntryBroken: "\u3053\u306E\u88C5\u7F6E\u306F\u5165\u53E3\u3068\u3057\u3066\u8A2D\u5B9A\u3055\u308C\u3066\u304A\u308A\u3001\u56FA\u5B9A\u30D1\u30D6\u30EA\u30C3\u30AFIP\u304C\u5272\u308A\u5F53\u3066\u3089\u308C\u305FWAN\u304C\u5FC5\u8981\u3067\u3059\u3002\u56FA\u5B9A\u30D1\u30D6\u30EA\u30C3\u30AFIP\u7121\u3057\u3067\u4FDD\u5B58\u3059\u308B\u3068\u5165\u53E3\u6A5F\u80FD\u304C\u7121\u304F\u306A\u308A\u307E\u3059\u3002",
          confirmPointOfEntryBroken_site: "\u3053\u306E\u30B5\u30A4\u30C8\u306F\u5165\u53E3\u3068\u3057\u3066\u8A2D\u5B9A\u3055\u308C\u3066\u304A\u308A\u3001 \u56FA\u5B9A\u30D1\u30D6\u30EA\u30C3\u30AFIP\u304C\u5272\u308A\u5F53\u3066\u3089\u308C\u305FWAN\u304C\u5FC5\u8981\u3067\u3059\u3002\u56FA\u5B9A\u30D1\u30D6\u30EA\u30C3\u30AFIP\u7121\u3057\u3067\u4FDD\u5B58\u3059\u308B\u3068\u5165\u53E3\u6A5F\u80FD\u304C\u7121\u304F\u306A\u308A\u307E\u3059\u3002",
          confirmPointOfEntryBroken_gateway: "\u3053\u306EEdge Service\u306F\u5165\u53E3\u3068\u3057\u3066\u8A2D\u5B9A\u3055\u308C\u3066\u304A\u308A\u3001\u56FA\u5B9A\u30D1\u30D6\u30EA\u30C3\u30AFIP\u304C\u5272\u308A\u5F53\u3066\u3089\u308C\u305FWAN\u304C\u5FC5\u8981\u3067\u3059\u3002\u56FA\u5B9A\u30D1\u30D6\u30EA\u30C3\u30AFIP\u7121\u3057\u3067\u4FDD\u5B58\u3059\u308B\u3068\u5165\u53E3\u6A5F\u80FD\u304C\u7121\u304F\u306A\u308A\u307E\u3059\u3002",
          confirm: "\u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002"
        },
        support: {
          buttonText: "\u30B5\u30DD\u30FC\u30C8\u30A2\u30AF\u30BB\u30B9",
          dialogTitle: "\u30B5\u30DD\u30FC\u30C8\u30A2\u30AF\u30BB\u30B9",
          message_site: "\u3053\u3053\u3067\u8A18\u5165\u3059\u308B\u30B5\u30DD\u30FC\u30C8 ACL\u3068 SSH\u30AD\u30FC\u306B\u3088\u308A 24\u6642\u9593\u3053\u306E\u30B5\u30A4\u30C8\u3078\u306E SSH\u30A2\u30AF\u30BB\u30B9\u304C\u8A31\u53EF\u3055\u308C\u307E\u3059\u3002",
          message_gateway: "\u3053\u3053\u3067\u8A18\u5165\u3059\u308B\u30B5\u30DD\u30FC\u30C8 ACL\u3068 SSH\u30AD\u30FC\u306B\u3088\u308A 24\u6642\u9593\u3053\u306EEdge Service\u3078\u306E SSH\u30A2\u30AF\u30BB\u30B9\u304C\u8A31\u53EF\u3055\u308C\u307E\u3059\u3002",
          networks: "\u30B5\u30DD\u30FC\u30C8 ACL",
          publicKey: "SSH\u516C\u958B\u9375",
          success_site: "\u30B5\u30A4\u30C8\u300C{{name}}\u300D\u306E\u30B5\u30DD\u30FC\u30C8\u30A2\u30AF\u30BB\u30B9\u306F 24\u6642\u9593\u6709\u52B9\u3067\u3059\u3002",
          success_gateway: "Edge Service\u300C{{name}}\u300D\u30B5\u30DD\u30FC\u30C8\u30A2\u30AF\u30BB\u30B9\u306F 24\u6642\u9593\u6709\u52B9\u3067\u3059\u3002",
          description: {
            networks_site: "\u5FA1\u793E\u306E\u8CB4\u30B5\u30A4\u30C8\u3078\u306E SSH\u30A2\u30AF\u30BB\u30B9\u304C\u8A31\u53EF\u3055\u308C\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u30B3\u30F3\u30DE\u533A\u5207\u308A\u30EA\u30B9\u30C8\u3002",
            networks_gateway: "\u5FA1\u793E\u306EEdge Service\u3078\u306E SSH\u30A2\u30AF\u30BB\u30B9\u304C\u8A31\u53EF\u3055\u308C\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u30B3\u30F3\u30DE\u533A\u5207\u308A\u30EA\u30B9\u30C8\u3002",
            publicKey_site: "\u5FA1\u793E\u306E\u8CB4\u30B5\u30A4\u30C8\u3078\u306E\u30A2\u30AF\u30BB\u30B9\u306B\u8A31\u53EF\u3055\u308C\u308B OpenSSH\u516C\u958B\u9375\u3002",
            publicKey_gateway: "\u5FA1\u793E\u306EEdge Service\u3078\u306E\u30A2\u30AF\u30BB\u30B9\u306B\u8A31\u53EF\u3055\u308C\u308B OpenSSH\u516C\u958B\u9375\u3002"
          },
          cloudGatewayMessage: "\u4EEE\u60F3WAN\u306E\u305F\u3081\u306BEdge Service\u306B\u30A2\u30AF\u30BB\u30B9\u3059\u308B\u306B\u306F\u3001<0>Azure\u30DE\u30CD\u30FC\u30B8\u30C9\u30FB\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3</0>\u3067\u30B5\u30DD\u30FC\u30C8\u30FB\u30A2\u30AF\u30BB\u30B9\u3082\u6709\u52B9\u306B\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002"
        },
        restart: {
          buttonLabel: "\u518D\u8D77\u52D5",
          box: {
            menuChoice: "\u30B5\u30A4\u30C8\u306E\u518D\u8D77\u52D5",
            dialogTitle: "\u30B5\u30A4\u30C8\u306E\u518D\u8D77\u52D5",
            confirmMessage: "\u300C\u78BA\u5B9A\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3059\u308B\u3068\u3053\u306E\u30B5\u30A4\u30C8\u304C\u518D\u8D77\u52D5\u3057\u3001\u3053\u308C\u306B\u306F\u6700\u59275\u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002<0/><0/> \u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002",
            confirmMessage_plural: "\u300C\u78BA\u5B9A\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3059\u308B\u3068\u5FA1\u793E\u306EHA\u30DA\u30A2\u5185\u306E\u4E21\u65B9\u306E\u6A5F\u5668\u304C\u518D\u8D77\u52D5\u3057\u3001\u3053\u308C\u306B\u306F\u6700\u59275\u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002<0/> \u3053\u308C\u306F\u30AA\u30F3\u30E9\u30A4\u30F3\u3068\u3057\u3066\u5831\u544A\u6E08\u307F\u6A5F\u5668\u306B\u3057\u304B\u5F71\u97FF\u3057\u307E\u305B\u3093\u3002<0/><0/> \u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002"
          },
          vpn: {
            menuChoice: "VPN Service\u306E\u518D\u8D77\u52D5",
            dialogTitle: "VPN Service\u306E\u518D\u8D77\u52D5",
            confirmMessage: "\u300C\u78BA\u5B9A\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3059\u308B\u3068\u3053\u306E\u6A5F\u5668\u306E\u305F\u3081\u306EVPN service\u304C\u518D\u8D77\u52D5\u3057\u3001\u3053\u308C\u306B\u306F\u6700\u59275\u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002<0/><0/> \u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002",
            confirmMessage_plural: "\u300C\u78BA\u5B9A\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3059\u308B\u3068\u5FA1\u793E\u306EHA\u30DA\u30A2\u5185\u306E\u4E21\u65B9\u306E\u6A5F\u5668\u3067 VPN service\u304C\u518D\u8D77\u52D5\u3057\u3001\u3053\u308C\u306B\u306F\u6700\u59275\u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002<0/> \u3053\u308C\u306F\u30AA\u30F3\u30E9\u30A4\u30F3\u3068\u3057\u3066\u5831\u544A\u6E08\u307F\u6A5F\u5668\u306B\u3057\u304B\u5F71\u97FF\u3057\u307E\u305B\u3093\u3002<0/><0/> \u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002"
          },
          firmware: {
            menuChoice: "\u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2\u306E\u518D\u8D77\u52D5",
            dialogTitle: "Firewall \u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2\u306E\u518D\u8D77\u52D5",
            confirmMessage: "\u300C\u78BA\u5B9A\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3059\u308B\u3068\u3053\u306E\u6A5F\u5668\u306Efirmware\u304C\u518D\u8D77\u52D5\u3057\u3001\u3053\u308C\u306B\u306F\u6700\u59275\u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002<0/><0/> \u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002",
            confirmMessage_plural: "\u300C\u78BA\u5B9A\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3059\u308B\u3068\u5FA1\u793E\u306EHA\u30DA\u30A2\u5185\u306E\u4E21\u65B9\u306E\u6A5F\u5668\u3067 firmware\u304C\u518D\u8D77\u52D5\u3057\u3001\u3053\u308C\u306B\u306F\u6700\u59275\u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002<0/> \u3053\u308C\u306F\u30AA\u30F3\u30E9\u30A4\u30F3\u3068\u3057\u3066\u5831\u544A\u6E08\u307F\u6A5F\u5668\u306B\u3057\u304B\u5F71\u97FF\u3057\u307E\u305B\u3093\u3002<0/><0/> \u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002"
          }
        }
      },
      security: {
        securityEnabled: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u6709\u52B9",
        menuTitle: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3",
        pageTitle: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3",
        comingSoon: "\u9593\u3082\u306A\u304F\u4E88\u5B9A",
        protection: {
          tabTitle: "Malware Protection",
          settings: {
            tabTitle: "\u8A2D\u5B9A",
            protection: "Malware Protection",
            name: "\u540D\u524D",
            action: "\u64CD\u4F5C",
            description: "\u5185\u5BB9",
            order: "\u9806\u756A",
            source: "\u30BD\u30FC\u30B9",
            destination: "\u9001\u308A\u5148",
            defaultAction: "\u65E2\u5B9A\u306E\u64CD\u4F5C",
            inputs: {
              enabled: "\u6709\u52B9"
            },
            descriptions: {
              name: "\u30EB\u30FC\u30EB\u306E\u56FA\u6709\u540D\u3002",
              description: "\u30EB\u30FC\u30EB\u306E\u6982\u7565\u3002",
              action: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u4E00\u81F4\u3059\u308B\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306B\u5BFE\u3057\u3066\u884C\u3046\u64CD\u4F5C\u3002"
            },
            create: {
              buttonText: "\u30EB\u30FC\u30EB\u8FFD\u52A0",
              dialogTitle: "\u65B0\u3057\u3044\u30EB\u30FC\u30EB\u306E\u8FFD\u52A0"
            },
            edit: {
              dialogTitle: "\u30EB\u30FC\u30EB\u306E\u7DE8\u96C6"
            },
            delete: {
              title: "\u30EB\u30FC\u30EB\u306E\u524A\u9664",
              body: "\u3053\u306E\u30EB\u30FC\u30EB\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B"
            }
          }
        },
        addRuleButtonText: "\u30EB\u30FC\u30EB\u8FFD\u52A0",
        sourceCriteria: {
          any: "\u4EFB\u610F",
          section: "\u30BD\u30FC\u30B9\u306E\u5224\u65AD\u57FA\u6E96",
          type: "\u30BF\u30A4\u30D7",
          site: "\u30B5\u30A4\u30C8",
          internet: "\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8",
          siteNetwork: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
          source: "\u30BD\u30FC\u30B9",
          application: "\u30AB\u30B9\u30BF\u30E0 \u30CD\u30C3\u30C8\u30EF\u30FC\u30AF \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
          applications: "\u30AB\u30B9\u30BF\u30E0 \u30CD\u30C3\u30C8\u30EF\u30FC\u30AF \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
          allSites: "\u5168\u3066\u306E\u30B5\u30A4\u30C8",
          allNetworks: "\u5168\u3066\u306E\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
          addSite: "\u30B5\u30A4\u30C8\u8FFD\u52A0",
          editSite: "\u30B5\u30A4\u30C8\u7DE8\u96C6",
          network: "IP/\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
          userOrGroup: "\u30E6\u30FC\u30B6\u30FC/\u30B0\u30EB\u30FC\u30D7",
          users: "\u30E6\u30FC\u30B6\u30FC",
          groups: "\u30B0\u30EB\u30FC\u30D7",
          userOrGroupRequired: "\u5C11\u306A\u304F\u3068\u30821\u3064\u306E\u30E6\u30FC\u30B6\u30FC\u307E\u305F\u306F\u30B0\u30EB\u30FC\u30D7\u304C\u5FC5\u8981\u3067\u3059",
          userOrGroupDisabled: "\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u307E\u305B\u3093\u3002\u300C\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3 > \u8A2D\u5B9A\u300D\u3067\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u3092\u8FFD\u52A0\u3057\u307E\u3059\u3002",
          userOrGroupDisabledShort: "\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u307E\u305B\u3093\u3002",
          description: "\u30BD\u30FC\u30B9\u306E\u7A2E\u985E\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          applicationDescription: "\u30BD\u30FC\u30B9\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          networkDescription: "\u30BD\u30FC\u30B9\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u633F\u5165\u3057\u307E\u3059\u3002",
          siteDescription: "\u30BD\u30FC\u30B9\u30B5\u30A4\u30C8\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          userDescription: "\u30BD\u30FC\u30B9\u30E6\u30FC\u30B6\u30FC\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          groupDescription: "\u30BD\u30FC\u30B9\u30B0\u30EB\u30FC\u30D7\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          gatewayNetwork: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
          allOnpremGateways: "\u5168\u3066\u306E\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Services",
          addOnpremGateway: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service\u306E\u8FFD\u52A0",
          editOnpremGateway: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service\u306E\u7DE8\u96C6",
          onpremGateway: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service",
          onpremGatewayDescription: "\u30BD\u30FC\u30B9\u306E\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service\u3092\u9078\u3073\u307E\u3059"
        },
        destinationCriteria: {
          section: "\u9001\u308A\u5148\u306E\u5224\u65AD\u57FA\u6E96",
          type: "\u30BF\u30A4\u30D7",
          destination: "\u9001\u308A\u5148",
          site: "\u30B5\u30A4\u30C8",
          siteNetwork: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
          allSites: "\u5168\u3066\u306E\u30B5\u30A4\u30C8",
          allNetworks: "\u5168\u3066\u306E\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
          addSite: "\u30B5\u30A4\u30C8\u8FFD\u52A0",
          editSite: "\u30B5\u30A4\u30C8\u7DE8\u96C6",
          network: "IP/\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
          application: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
          domain: "\u30C9\u30E1\u30A4\u30F3",
          category: "URL\u30AB\u30C6\u30B4\u30EA\u30FC",
          onPremGateway: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service",
          description: "\u9001\u308A\u5148\u30BF\u30A4\u30D7\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          applicationDescription: "\u9001\u308A\u5148\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          siteDescription: "\u9001\u308A\u5148\u30B5\u30A4\u30C8\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          gatewayDescription: "\u30BF\u30FC\u30B2\u30C3\u30C8\u306E\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service\u3092\u9078\u3073\u307E\u3059\u3002",
          networkDescription: "\u9001\u308A\u5148\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          gateway: "Edge Service",
          ingressSite: "\u30B5\u30A4\u30C8",
          customCategory: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC",
          gatewayNetwork: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
          onpremGateway: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service",
          allOnpremGateways: "\u5168\u3066\u306E\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Services",
          addOnpremGateway: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service\u306E\u8FFD\u52A0",
          editOnpremGateway: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service\u306E\u7DE8\u96C6",
          onpremGatewayDescription: "\u30BF\u30FC\u30B2\u30C3\u30C8\u306E\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service\u3092\u9078\u3073\u307E\u3059"
        },
        targetCriteria: {
          section: "\u5BFE\u8C61\u306E\u5224\u65AD\u57FA\u6E96"
        },
        tables: {
          allSites: "\u5168\u3066\u306E\u30B5\u30A4\u30C8",
          allGateways: "\u5168\u3066\u306EEdge Service",
          source: "\u30BD\u30FC\u30B9",
          sourceNetwork: "IP/\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
          sourceApplication: "\u30AB\u30B9\u30BF\u30E0 \u30CD\u30C3\u30C8\u30EF\u30FC\u30AF \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
          sourceSite: "\u30B5\u30A4\u30C8",
          sourceType: "\u30BD\u30FC\u30B9\u30BF\u30A4\u30D7",
          destination: "\u9001\u308A\u5148",
          destinationSite: "\u30B5\u30A4\u30C8",
          destinationApplication: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
          destinationCategories: "URL\u30AB\u30C6\u30B4\u30EA\u30FC",
          destinationNetwork: "IP/\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
          destinationDomain: "\u30C9\u30E1\u30A4\u30F3",
          destinationType: "\u9001\u308A\u5148\u30BF\u30A4\u30D7",
          destinationGateway: "\u9001\u308A\u5148Edge Service",
          destinationWan: "\u9001\u308A\u5148 WAN",
          user: "\u30E6\u30FC\u30B6\u30FC",
          group: "\u30B0\u30EB\u30FC\u30D7",
          destinationCustomCategories: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC",
          allOnpremGateways: "\u5168\u3066\u306E\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Services",
          sourceOnpremGateway: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service",
          destinationOnpremGateway: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service"
        },
        applicationControl: {
          siteTabTitle: "\u30B5\u30A4\u30C8ACL",
          gatewayTabTitle: "Edge Service ACL",
          settings: {
            tabTitle: "\u8A2D\u5B9A",
            siteApplicationControl: "\u30B5\u30A4\u30C8ACL",
            gatewayApplicationControl: "Edge Service ACL",
            order: "\u9806\u756A",
            name: "\u540D\u524D",
            action: "\u64CD\u4F5C",
            description: "\u5185\u5BB9",
            defaultAction: "\u65E2\u5B9A\u306E\u64CD\u4F5C",
            source: "\u30BD\u30FC\u30B9",
            destination: "\u9001\u308A\u5148",
            scope: "\u30B9\u30B3\u30FC\u30D7",
            allSites: "\u5168\u3066\u306E\u30B5\u30A4\u30C8",
            allGateways: "\u5168\u3066\u306EEdge Service",
            descriptions: {
              name: "\u30EB\u30FC\u30EB\u306E\u56FA\u6709\u540D\u3002",
              description: "\u30EB\u30FC\u30EB\u306E\u6982\u7565\u3002",
              action: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u3088\u3063\u3066\u4E00\u81F4\u3059\u308B\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306E\u305F\u3081\u306B\u884C\u3046\u64CD\u4F5C\uFF08\u30D6\u30ED\u30C3\u30AF\u307E\u305F\u306F\u8A31\u53EF\uFF09",
              scope: "\u3053\u306E\u30EB\u30FC\u30EB\u3092\u9069\u7528\u3059\u308B\u30B5\u30A4\u30C8\u3002",
              icmp: "\u88C5\u7F6E\u306BICMP\u3092\u4ECB\u3059\u308B\u901A\u4FE1\u3092\u8A31\u53EF\u3057\u307E\u3059\u3002"
            },
            create: {
              buttonText: "\u30EB\u30FC\u30EB\u8FFD\u52A0",
              dialogTitle: "\u65B0\u3057\u3044\u30EB\u30FC\u30EB\u306E\u8FFD\u52A0"
            },
            edit: {
              dialogTitle: "\u30EB\u30FC\u30EB\u306E\u7DE8\u96C6"
            },
            delete: {
              title: "\u30EB\u30FC\u30EB\u306E\u524A\u9664",
              body: "\u3053\u306E\u30EB\u30FC\u30EB\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B"
            },
            icmp: "ICMP"
          },
          noData: {
            edgeServiceAcl: {
              iconHeader: "Edge Service ACL\u3092\u8FFD\u52A0\u3057\u3066\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
              labelButton: "Edge Service ACL\u3092\u8FFD\u52A0",
              textHeader: "Edge Service ACL\u3068\u306F\u4F55\u304B",
              text: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u306E\u7406\u7531\u304B\u3089\u3001service\u30A2\u30AF\u30BB\u30B9\u3092\u5236\u9650\u3057\u305F\u3044\u3053\u3068\u306F\u3088\u304F\u3042\u308A\u307E\u3059\u3002<0 /> This Access Control List lets you configure who is allowed to access the service(s) and how"
            },
            siteAcl: {
              iconHeader: "\u30B5\u30A4\u30C8 ACL\u3092\u8FFD\u52A0\u3057\u3066\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
              labelButton: "\u30B5\u30A4\u30C8 ACL\u3092\u8FFD\u52A0",
              textHeader: "\u30B5\u30A4\u30C8 ACL\u3068\u306F\u4F55\u304B",
              text: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u306E\u89B3\u70B9\u304B\u3089\u30B5\u30A4\u30C8\u30A2\u30AF\u30BB\u30B9\u3092\u5236\u9650\u3057\u305F\u3044\u3053\u3068\u306F\u3088\u304F\u3042\u308A\u307E\u3059\u3002<0 /> This Access Control List lets you configure who is allowed to access the site(s) and how"
            },
            appResource: {
              iconHeader: "\u30A2\u30D7\u30EA\u3068\u30EA\u30BD\u30FC\u30B9\u3092\u8FFD\u52A0\u3057\u3066\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
              labelButton: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u8FFD\u52A0\u3059\u308B",
              textHeader: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3068\u306F\u4F55\u304B",
              text: "SD-WAN\u4F5C\u696D\u74B0\u5883\u306E\u305F\u3081\u306B\u306F\u30BB\u30C3\u30C8\u30A2\u30C3\u30D7\u304C\u3069\u306E\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u4F7F\u7528\u3059\u308B\u304B\u3092\u628A\u63E1\u3057\u3066\u304A\u304F\u5FC5\u8981\u304C\u3042\u3042\u308A\u307E\u3059\u3002<0 /> \u6700\u521D\u304B\u3089\u591A\u304F\u306E\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306B\u5BFE\u5FDC\u3057\u3066\u3044\u3066\u3082\u3001\u4E00\u90E8\u306E\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u304C\u521D\u671F\u96C6\u5408\u306B\u542B\u307E\u308C\u306A\u3044\u3053\u3068\u3082\u3042\u308A\u3048\u307E\u3059\u3002<0 />\u672A\u5BFE\u5FDC\u306E\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30FB\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3068\u30A6\u30A7\u30D6\u30FB\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u3053\u3061\u3089\u304B\u3089\u8FFD\u52A0\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002"
            }
          }
        },
        urlFiltering: {
          tabTitle: "URL Filtering",
          settings: {
            tabTitle: "\u8A2D\u5B9A",
            order: "\u9806\u756A",
            name: "\u540D\u524D",
            description: "\u5185\u5BB9",
            appliesTo: "\u9069\u7528\u5148",
            action: "\u64CD\u4F5C",
            type: "\u30BF\u30A4\u30D7",
            rule: "\u9001\u308A\u5148",
            domains: "\u30C9\u30E1\u30A4\u30F3",
            categories: "URL\u30AB\u30C6\u30B4\u30EA\u30FC",
            defaultAction: "\u65E2\u5B9A\u306E\u64CD\u4F5C",
            destinationCriteria: "\u9001\u308A\u5148\u306E\u5224\u65AD\u57FA\u6E96",
            descriptions: {
              name: "\u30EB\u30FC\u30EB\u306E\u56FA\u6709\u540D\u3002",
              description: "\u30EB\u30FC\u30EB\u306E\u6982\u7565\u3002",
              action: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u3088\u3063\u3066\u4E00\u81F4\u3059\u308B\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306E\u305F\u3081\u306B\u884C\u3046\u64CD\u4F5C\uFF08\u30D6\u30ED\u30C3\u30AF\u307E\u305F\u306F\u8A31\u53EF\uFF09",
              type: "\u30DD\u30EA\u30B7\u30FC\u304C URL\u30AB\u30C6\u30B4\u30EA\u30FC\u3082\u3057\u304F\u306F\u30C9\u30E1\u30A4\u30F3\u540D\u306E\u4E00\u81F4\u306E\u3069\u3061\u3089\u306B\u57FA\u3065\u304F\u304B\u9078\u629E\u3057\u307E\u3059\u3002",
              domains: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u4E00\u81F4\u3059\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u308B\u30C9\u30E1\u30A4\u30F3\u540D\u3002",
              silent: "\u30B5\u30A4\u30EC\u30F3\u30C8\u306B\u30EB\u30FC\u30EB\u3092\u30D6\u30ED\u30C3\u30AF\u3059\u308B\u3002",
              customCategories: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u30DE\u30C3\u30C1\u30F3\u30B0\u3055\u305B\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u3066\u3044\u308B\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC\u3002"
            },
            create: {
              buttonText: "\u30EB\u30FC\u30EB\u8FFD\u52A0",
              dialogTitle: "\u65B0\u3057\u3044\u30EB\u30FC\u30EB\u306E\u8FFD\u52A0"
            },
            edit: {
              dialogTitle: "\u30EB\u30FC\u30EB\u306E\u7DE8\u96C6"
            },
            validation: {
              emptyCategories: "\u5C11\u306A\u304F\u3068\u30821\u3064\u306E\u30AB\u30C6\u30B4\u30EA\u30FC\u3092\u9078\u629E\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002"
            },
            delete: {
              title: "\u30EB\u30FC\u30EB\u306E\u524A\u9664",
              body: "\u3053\u306E\u30EB\u30FC\u30EB\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B"
            },
            silent: "\u30B5\u30A4\u30EC\u30F3\u30C8",
            customCategories: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC",
            destinationDomains: "\u30C9\u30E1\u30A4\u30F3({{domains.0}}\u3001+{{additional}})",
            destinationDomains_1: "\u30C9\u30E1\u30A4\u30F3 ({{domains.0}})",
            destinationCategories: "\u30AB\u30C6\u30B4\u30EA\u30FC ({{activeCategories.0.name}}, +{{additional}})",
            destinationCategories_1: "\u30AB\u30C6\u30B4\u30EA\u30FC ({{activeCategories.0.name}})",
            destinationCustomCategories: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC({{activeCategories.0.name}}, +{{additional}})",
            destinationCustomCategories_1: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC({{activeCategories.0.name}})"
          },
          exceptions: {
            tabTitle: "\u660E\u793A\u7684",
            order: "\u9806\u756A",
            name: "\u540D\u524D",
            description: "\u5185\u5BB9",
            action: "\u64CD\u4F5C",
            type: "\u30BF\u30A4\u30D7",
            rule: "\u30EB\u30FC\u30EB",
            domain: "\u30C9\u30E1\u30A4\u30F3",
            category: "URL\u30AB\u30C6\u30B4\u30EA\u30FC",
            domains: "\u9001\u308A\u5148\u30DB\u30B9\u30C8\u540D",
            categories: "URL\u30AB\u30C6\u30B4\u30EA\u30FC",
            source: "\u30BD\u30FC\u30B9",
            sourceNetwork: "\u30BD\u30FC\u30B9IP/\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            sourceSite: "\u30BD\u30FC\u30B9\u30B5\u30A4\u30C8",
            allSites: "\u5168\u3066\u306E\u30B5\u30A4\u30C8",
            allNetworks: "\u5168\u3066\u306E\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            sourceSiteNetwork: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            sourceType: "\u30BF\u30A4\u30D7",
            network: "IP/\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            site: "\u30B5\u30A4\u30C8",
            sourceSites: "\u30B5\u30A4\u30C8",
            addSite: "\u30B5\u30A4\u30C8\u8FFD\u52A0",
            editSite: "\u30B5\u30A4\u30C8\u7DE8\u96C6",
            destinations: "\u9001\u308A\u5148",
            destination: "\u9001\u308A\u5148IP/\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            destinationType: "\u30BF\u30A4\u30D7",
            destinationCategories: "\u9001\u308A\u5148 URL\u30AB\u30C6\u30B4\u30EA\u30FC",
            destinationDomains: "\u9001\u308A\u5148\u30C9\u30E1\u30A4\u30F3",
            sectionTitle: "\u5224\u65AD\u57FA\u6E96",
            sourceCriteria: "\u30BD\u30FC\u30B9\u306E\u5224\u65AD\u57FA\u6E96",
            destinationCriteria: "\u9001\u308A\u5148\u306E\u5224\u65AD\u57FA\u6E96",
            descriptions: {
              name: "\u3053\u306E\u660E\u793A\u7684\u30DD\u30EA\u30B7\u30FC\u306E\u56FA\u6709\u540D\u3002",
              description: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306E\u6982\u7565\u3002",
              action: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u3088\u3063\u3066\u4E00\u81F4\u3059\u308B\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306E\u305F\u3081\u306B\u884C\u3046\u64CD\u4F5C\uFF08\u30D6\u30ED\u30C3\u30AF\u307E\u305F\u306F\u8A31\u53EF\uFF09",
              categories: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306E\u30DE\u30C3\u30C1\u30F3\u30B0\u306B\u4F7F\u7528\u3059\u308B\u30AB\u30C6\u30B4\u30EA\u30FC\u3002",
              domains: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u4E00\u81F4\u3059\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u308B\u30C9\u30E1\u30A4\u30F3\u540D\u3002",
              customCategories: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u30DE\u30C3\u30C1\u30F3\u30B0\u3055\u305B\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u3066\u3044\u308B\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC\u3002",
              silent: "\u30B5\u30A4\u30EC\u30F3\u30C8\u306B\u30EB\u30FC\u30EB\u3092\u30D6\u30ED\u30C3\u30AF\u3059\u308B\u3002"
            },
            validation: {
              sourceDest: "\u5C11\u306A\u304F\u3068\u30821\u3064\u306E\u30BD\u30FC\u30B9\u307E\u305F\u306F\u9001\u308A\u5148\u3092\u6307\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044"
            },
            create: {
              buttonText: "\u8FFD\u52A0",
              dialogTitle: "\u660E\u793A\u7684\u30DD\u30EA\u30B7\u30FC\u3092\u8FFD\u52A0"
            },
            edit: {
              dialogTitle: "\u660E\u793A\u7684\u30DD\u30EA\u30B7\u30FC\u3092\u7DE8\u96C6"
            },
            delete: {
              title: "\u660E\u793A\u7684\u30DD\u30EA\u30B7\u30FC\u3092\u524A\u9664",
              body: "\u3053\u306E\u4F8B\u5916\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B"
            },
            silent: "\u30B5\u30A4\u30EC\u30F3\u30C8",
            customCategories: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC"
          }
        },
        urlCategories: {
          tabTitle: "URL\u30AB\u30C6\u30B4\u30EA\u30FC",
          settings: {
            tabTitle: "\u8A2D\u5B9A",
            urlCategories: "URL\u30AB\u30C6\u30B4\u30EA\u30FC",
            description: "\u30D6\u30ED\u30C3\u30AF\u3059\u308B\u305F\u3081\u306B\u306FURL\u30B9\u30FC\u30D1\u30FC\u30AB\u30C6\u30B4\u30EA\u30FC\u307E\u305F\u306F URL\u30AB\u30C6\u30B4\u30EA\u30FC\u306B\u30C1\u30A7\u30C3\u30AF\u30DE\u30FC\u30AF\u3092\u5165\u308C\u307E\u3059"
          },
          blocklists: {
            tabTitle: "\u8A31\u53EF/\u30D6\u30ED\u30C3\u30AF\u30EA\u30B9\u30C8",
            description: "\u6052\u4E45\u7684\u306B\u30D6\u30ED\u30C3\u30AF\u307E\u305F\u306F\u8A31\u53EF\u3059\u308BURL\u3001IP\u307E\u305F\u306F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u5B9A\u7FA9\u3057\u307E\u3059\u3002",
            inputs: {
              blacklist: "\u5E38\u306B\u30D6\u30ED\u30C3\u30AF",
              whitelist: "\u5E38\u306B\u8A31\u53EF"
            }
          },
          exceptions: {
            tabTitle: "\u660E\u793A\u7684",
            categories: "\u30B9\u30FC\u30D1\u30FC\u30AB\u30C6\u30B4\u30EA\u30FC",
            subCategories: "\u30AB\u30C6\u30B4\u30EA\u30FC",
            mode: "\u8A31\u53EF/\u30D6\u30ED\u30C3\u30AF",
            allow: "\u8A31\u53EF",
            block: "\u30D6\u30ED\u30C3\u30AF",
            users: "\u30E6\u30FC\u30B6\u30FC",
            groups: "\u30B0\u30EB\u30FC\u30D7",
            source: "\u30BD\u30FC\u30B9IP/\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            time: "\u6642\u9593",
            validateCategory: "\u5C11\u306A\u304F\u3068\u30821\u3064\u306EURL\u30B9\u30FC\u30D1\u30FC\u30AB\u30C6\u30B4\u30EA\u30FC\u307E\u305F\u306F URL\u30AB\u30C6\u30B4\u30EA\u30FC\u3092\u6307\u5B9A\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059",
            validateSource: "\u5C11\u306A\u304F\u3068\u30821\u3064\u306E\u30BD\u30FC\u30B9\u307E\u305F\u306F\u30E6\u30FC\u30B6\u30FC\u307E\u305F\u306F\u30B0\u30EB\u30FC\u30D7\u3092\u6307\u5B9A\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059",
            delete: {
              title: "\u660E\u793A\u7684\u30DD\u30EA\u30B7\u30FC\u3092\u524A\u9664",
              body: "\u3053\u306E\u4F8B\u5916\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u524A\u9664\u306F\u4FDD\u5B58\u6642\u306B\u6052\u4E45\u7684\u306B\u53CD\u6620\u3055\u308C\u307E\u3059\u3002"
            },
            create: {
              title: "\u65B0\u898F\u660E\u793A\u7684\u30DD\u30EA\u30B7\u30FC\u3092\u8FFD\u52A0",
              button: "\u8FFD\u52A0"
            },
            edit: {
              title: "\u660E\u793A\u7684\u30DD\u30EA\u30B7\u30FC\u3092\u7DE8\u96C6"
            }
          }
        },
        ips: {
          tabTitle: "IPS",
          settings: {
            tabTitle: "\u8A2D\u5B9A",
            falsePositivesTabTitle: "\u8AA4\u691C\u77E5",
            ips: "Intrusion Prevention",
            name: "\u540D\u524D",
            action: "\u64CD\u4F5C",
            description: "\u5185\u5BB9",
            block: "\u30D6\u30ED\u30C3\u30AF",
            mode: "\u30E2\u30FC\u30C9",
            log: "Log",
            order: "\u9806\u756A",
            source: "\u30BD\u30FC\u30B9",
            exploits: "\u30A8\u30AF\u30B9\u30D7\u30ED\u30A4\u30C8",
            destination: "\u9001\u308A\u5148",
            defaultAction: "\u65E2\u5B9A\u306E\u64CD\u4F5C",
            descriptions: {
              name: "\u30EB\u30FC\u30EB\u306E\u56FA\u6709\u540D\u3002",
              description: "\u30EB\u30FC\u30EB\u306E\u6982\u7565\u3002",
              action: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u4E00\u81F4\u3059\u308B\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306B\u5BFE\u3057\u3066\u884C\u3046\u64CD\u4F5C\u3002"
            },
            inputs: {
              enabled: "\u6709\u52B9"
            },
            create: {
              buttonText: "\u30EB\u30FC\u30EB\u8FFD\u52A0",
              dialogTitle: "\u65B0\u3057\u3044\u30EB\u30FC\u30EB\u306E\u8FFD\u52A0"
            },
            edit: {
              dialogTitle: "\u30EB\u30FC\u30EB\u306E\u7DE8\u96C6"
            },
            delete: {
              title: "\u30EB\u30FC\u30EB\u306E\u524A\u9664",
              body: "\u3053\u306E\u30EB\u30FC\u30EB\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B"
            }
          }
        },
        sslInterception: {
          tabTitle: "SSL Inspection",
          settings: {
            tabTitle: "\u8A3C\u660E\u66F8",
            sslInterception: "SSL Inspection",
            name: "\u540D\u524D",
            action: "\u64CD\u4F5C",
            description: "\u5185\u5BB9",
            order: "\u9806\u756A",
            source: "\u30BD\u30FC\u30B9",
            destination: "\u9001\u308A\u5148",
            defaultAction: "\u65E2\u5B9A\u306E\u64CD\u4F5C",
            explicitTabTitle: "\u8A2D\u5B9A",
            status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
            issuer: "\u767A\u884C\u8005",
            startDate: "\u958B\u59CB\u65E5",
            endDate: "\u7D42\u4E86\u65E5",
            makeActive: "\u6709\u52B9\u306B\u3059\u308B",
            active: "\u30A2\u30AF\u30C6\u30A3\u30D6",
            activeCertificate: "\u6709\u52B9\u306A\u8A3C\u660E\u66F8",
            makeCertificateActive: "\u6709\u52B9\u306B\u3059\u308B",
            sslCertificates: "SSL Inspection\u8A3C\u660E\u66F8",
            sslCertificateAuthorities: "\u4FE1\u983C\u6E08\u307F\u8A3C\u660E\u66F8\u767A\u884C\u6A5F\u95A2",
            uploadP12: "PKCS12\u3092\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9",
            uploadPEM: "PEM\u307E\u305F\u306F CRT\u3092\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9",
            passphrase: "\u30D1\u30B9\u30D5\u30EC\u30FC\u30BA",
            downloadCertificate: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u8A3C\u660E\u66F8\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9",
            certificates: {
              create: {
                label: "\u8A3C\u660E\u66F8\u3092\u8FFD\u52A0",
                dialogTitle: "\u65B0\u898F\u8A3C\u660E\u66F8\u3092\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9"
              },
              edit: {
                dialogTitle: "\u8A3C\u660E\u66F8\u306E\u660E\u7D30\u3092\u7DE8\u96C6"
              },
              delete: {
                title: "\u8A3C\u660E\u66F8\u3092\u524A\u9664",
                body: "\u8A3C\u660E\u66F8\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B",
                disabledMessage: "\u6709\u52B9\u306A\u8A3C\u660E\u66F8\u306F\u524A\u9664\u3067\u304D\u307E\u305B\u3093\u3002"
              }
            },
            descriptions: {
              name: "\u30EB\u30FC\u30EB\u306E\u56FA\u6709\u540D\u3002",
              certificateName: "\u8A3C\u660E\u66F8\u306E\u56FA\u6709\u540D\u3002",
              description: "\u30EB\u30FC\u30EB\u306E\u6982\u7565\u3002",
              certificateDescription: "\u8A3C\u660E\u66F8\u306E\u6982\u7565\u3002",
              action: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u4E00\u81F4\u3059\u308B\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306B\u5BFE\u3057\u3066\u884C\u3046\u64CD\u4F5C\u3002",
              uploadP12: "\u7528\u306BPKS12 \u8A3C\u660E\u66F8\u3092\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u3053\u308C\u306B\u306F\u79D8\u5BC6\u9375\u304C\u542B\u307E\u308C\u3066\u3044\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
              uploadPEM: "PEM\u307E\u305F\u306F CRT\u8A3C\u660E\u66F8\u3092\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              passphrase: "PKS12\u8A3C\u660E\u66F8\u3092\u30ED\u30C3\u30AF\u89E3\u9664\u3059\u308B\u305F\u3081\u306E\u30D1\u30B9\u30D5\u30EC\u30FC\u30BA(\u5FC5\u8981\u306A\u5834\u5408)\u3002"
            },
            inputs: {
              enabled: "\u6709\u52B9"
            },
            create: {
              buttonText: "\u30EB\u30FC\u30EB\u8FFD\u52A0",
              dialogTitle: "\u65B0\u3057\u3044\u30EB\u30FC\u30EB\u306E\u8FFD\u52A0"
            },
            edit: {
              dialogTitle: "\u30EB\u30FC\u30EB\u306E\u7DE8\u96C6"
            },
            delete: {
              title: "\u30EB\u30FC\u30EB\u306E\u524A\u9664",
              body: "\u3053\u306E\u30EB\u30FC\u30EB\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B"
            }
          }
        },
        ingressNatRules: {
          settings: {
            order: "\u9806\u756A",
            name: "\u540D\u524D",
            target: "\u30BF\u30FC\u30B2\u30C3\u30C8",
            description: "\u5185\u5BB9",
            source: "\u30BD\u30FC\u30B9",
            destination: "\u9001\u308A\u5148",
            gateway: "Edge Service",
            wan: "WAN",
            targetApplicationResource: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3/\u30EA\u30BD\u30FC\u30B9",
            descriptions: {
              name: "\u30EB\u30FC\u30EB\u306E\u56FA\u6709\u540D\u3002",
              description: "\u30EB\u30FC\u30EB\u306E\u6982\u7565\u3002",
              target: "\u3053\u306E\u30EB\u30FC\u30EB\u3067\u4E00\u81F4\u3059\u308B\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306E\u30BF\u30FC\u30B2\u30C3\u30C8\u3002",
              wan: "\u5FC5\u8981\u306A\u30D1\u30D6\u30EA\u30C3\u30AFIP\u306B\u5FDC\u3058\u3066 WAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u9078\u629E\u3057\u307E\u3059\u3002",
              patPublicPort: "\u9001\u308A\u5148\u306E PAT \u30D1\u30D6\u30EA\u30C3\u30AF\u30DD\u30FC\u30C8\u3002\u9001\u308A\u5148\u304C\u30DD\u30FC\u30C8\u304C\u7121\u3044\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3067\u3042\u308C\u3070\u3053\u3053\u306F\u5FC5\u9808\u6B04\u3067\u3059\u3002\u30DD\u30FC\u30C8\u304C\u7121\u3051\u308C\u3070\u9001\u308A\u5148\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306E\u8A2D\u5B9A\u6E08\u307F\u30DD\u30FC\u30C8\uFF08\u307E\u305F\u306F\u8A2D\u5B9A\u6E08\u307F\u30DD\u30FC\u30C8\u7BC4\u56F2\u306E\u6700\u521D\u306E\u30DD\u30FC\u30C8\uFF09\u304C\u4F7F\u7528\u3055\u308C\u307E\u3059\u3002"
            },
            create: {
              buttonText: "\u30EB\u30FC\u30EB\u8FFD\u52A0",
              dialogTitle: "\u65B0\u3057\u3044\u30EB\u30FC\u30EB\u306E\u8FFD\u52A0"
            },
            edit: {
              dialogTitle: "\u30EB\u30FC\u30EB\u306E\u7DE8\u96C6"
            },
            delete: {
              title: "\u30EB\u30FC\u30EB\u306E\u524A\u9664",
              body: "\u3053\u306E\u30EB\u30FC\u30EB\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B"
            },
            IPAddress: "\u4F4F\u6240"
          },
          noData: {
            iconHeader: "\u30A4\u30F3\u30B0\u30EC\u30B9 NAT \u30EB\u30FC\u30EB\u3092\u8FFD\u52A0\u3057\u3066\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
            labelButton: "\u30A4\u30F3\u30B0\u30EC\u30B9 NAT \u30EB\u30FC\u30EB\u3092\u8FFD\u52A0",
            textHeader: "\u30A4\u30F3\u30B0\u30EC\u30B9 NAT\u3068\u306F\u4F55\u304B",
            text: "\u5185\u90E8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u30EA\u30BD\u30FC\u30B9\u3092\u30D1\u30D6\u30EA\u30C3\u30AF\u306B\u5229\u7528\u53EF\u80FD\u306B\u3059\u308B\u5834\u5408\u3001\u3053\u306E\u30BB\u30AF\u30B7\u30E7\u30F3\u3092\u4F7F\u3044\u3001\u30D1\u30D6\u30EA\u30C3\u30AF\u306B\u3059\u308B\u63A5\u7D9A\u7528\u306E\u30D1\u30E9\u30E1\u30FC\u30BF\u3092\u5B9A\u7FA9\u3057\u3066\u304F\u3060\u3055\u3044\u3002<0 /><0 />Please note that the internal service needs to run on a static public IP address."
          }
        }
      },
      customApps: {
        menuTitle: "\u30AB\u30B9\u30BF\u30E0\u30A2\u30D7\u30EA",
        pageTitle: "\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
        table: {
          name: "\u540D\u524D",
          description: "\u5185\u5BB9",
          protocol: "\u30D7\u30ED\u30C8\u30B3\u30EB",
          destination: "\u9001\u308A\u5148",
          category: "\u30AB\u30C6\u30B4\u30EA\u30FC",
          type: "\u30BF\u30A4\u30D7"
        },
        dialogs: {
          create: {
            buttonText: "\u65B0\u898F\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
            dialogTitleWeb: "\u65B0\u898F\u30AB\u30B9\u30BF\u30E0 \u30A6\u30A7\u30D6\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
            dialogTitleNetwork: "\u65B0\u898F\u30AB\u30B9\u30BF\u30E0 \u30CD\u30C3\u30C8\u30EF\u30FC\u30AF \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
            menuLabelWeb: "\u65B0\u898F\u30AB\u30B9\u30BF\u30E0 \u30A6\u30A7\u30D6\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
            menuLabelNetwork: "\u65B0\u898F\u30AB\u30B9\u30BF\u30E0 \u30CD\u30C3\u30C8\u30EF\u30FC\u30AF \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3"
          },
          edit: {
            dialogTitle: "\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306E\u7DE8\u96C6"
          },
          general: {
            sectionTitle: "\u5168\u822C",
            name: "\u540D\u524D",
            description: "\u5185\u5BB9",
            descriptions: {
              name: "\u3053\u306E\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306E\u56FA\u6709\u540D\u3002",
              description: "\u3053\u306E\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306E\u6982\u7565\u3002"
            }
          },
          endpoints: {
            protocol: "\u30D7\u30ED\u30C8\u30B3\u30EB",
            sectionTitle: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u30A8\u30F3\u30C9\u30DD\u30A4\u30F3\u30C8",
            destination: "\u9001\u308A\u5148",
            port: "\u30DD\u30FC\u30C8",
            firstPort: "\u6700\u521D\u306E\u30DD\u30FC\u30C8",
            lastPort: "\u6700\u5F8C\u306E\u30DD\u30FC\u30C8",
            descriptions: {
              protocol: "\u3053\u306E\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306B\u3088\u3063\u3066\u4F7F\u7528\u3055\u308C\u308B\u30D7\u30ED\u30C8\u30B3\u30EB\u3002",
              destination: "\u3053\u306E\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u304C\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u3092\u9001\u308B\u5148\u306E IP\u3001\u30C9\u30E1\u30A4\u30F3\u307E\u305F\u306F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3002",
              webAppDestination: "\u3053\u306E\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u304C\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u3092\u9001\u308B\u5148\u306E\u30DB\u30B9\u30C8\u540D\u3002",
              port: "\u3053\u306E\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306B\u3088\u3063\u3066\u4F7F\u7528\u3055\u308C\u308B\u30DD\u30FC\u30C8\u3002",
              firstPort: "\u30AA\u30D7\u30B7\u30E7\u30F3: \u3053\u306E\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306B\u3088\u3063\u3066\u4F7F\u7528\u3055\u308C\u308B\u30DD\u30FC\u30C8\u7BC4\u56F2\u306E\u6700\u521D\u306E\u30DD\u30FC\u30C8\u3002",
              lastPort: "\u30AA\u30D7\u30B7\u30E7\u30F3: \u3053\u306E\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306B\u3088\u3063\u3066\u4F7F\u7528\u3055\u308C\u308B\u30DD\u30FC\u30C8\u7BC4\u56F2\u306E\u6700\u5F8C\u306E\u30DD\u30FC\u30C8\u3002"
            }
          },
          category: "\u30AB\u30C6\u30B4\u30EA\u30FC",
          descriptions: {
            category: "\u3053\u306E\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u5272\u5F53\u308B\u30AB\u30C6\u30B4\u30EA\u30FC\u3002"
          }
        },
        delete: {
          title: "{{name}}\u3092\u524A\u9664",
          body: "\u3053\u306E\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B"
        }
      },
      sites: {
        auditLog: {
          tabTitle: "\u76E3\u67FBLog",
          pageTitle: "\u76E3\u67FBLog"
        },
        connectionHistory: {
          tabTitle: "\u6700\u8FD1\u306E\u63A5\u7D9A",
          pageTitle: "\u6700\u8FD1\u306E\u63A5\u7D9A",
          waitingForConnections: "\u30B5\u30A4\u30C8\u306B\u63A5\u7D9A\u3057\u3066\u3044\u307E\u3059\u3002\u63A5\u7D9A\u304C\u78BA\u7ACB\u3055\u308C\u308B\u307E\u3067\u6700\u5927 2 \u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002\u304A\u5F85\u3061\u304F\u3060\u3055\u3044...",
          emptyData: "\u6700\u8FD1\u306E\u63A5\u7D9A\u306F\u3042\u308A\u307E\u305B\u3093...",
          pauseRequestsButton: "\u4E00\u6642\u505C\u6B62",
          resumeRequestsButton: "\u518D\u958B"
        },
        dashboard: {
          tabTitle: "Dashboard",
          statusCardTitle: "\u30B9\u30C6\u30FC\u30BF\u30B9",
          servicesCardTitle: "Services",
          licensesCardTitle: "\u30E9\u30A4\u30BB\u30F3\u30B9",
          locationsCardTitle: "\u30DE\u30C3\u30D7",
          gateway: {
            tabTitle: "Edge Service",
            name: "\u540D\u524D",
            region: "\u5730\u57DF",
            hubName: "\u30CF\u30D6",
            vwan: "VWAN",
            size: "\u30B5\u30A4\u30BA"
          },
          siteStatus: {
            title: "\u60C5\u5831",
            model: "\u30E2\u30C7\u30EB",
            licenses: "\u30E9\u30A4\u30BB\u30F3\u30B9",
            serials: "\u30B7\u30EA\u30A2\u30EB",
            uptime: "\u7A3C\u50CD\u6642\u9593",
            time: "\u73FE\u5728\u6642\u523B",
            release: "\u30EA\u30EA\u30FC\u30B9",
            hostname: "\u30DB\u30B9\u30C8\u540D"
          },
          licenses: {
            type: "\u30BF\u30A4\u30D7",
            state: "\u90FD\u9053\u5E9C\u770C",
            status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
            expirationDate: "\u6E80\u4E86\u65E5",
            serial: "\u30B7\u30EA\u30A2\u30EB",
            noData: "N/A"
          },
          boxResources: {
            title: "\u30EA\u30BD\u30FC\u30B9",
            cpuLoad: "CPU",
            cpuLoadError: "CPU\u306B\u30A8\u30E9\u30FC\u304C\u691C\u51FA\u3055\u308C\u307E\u3057\u305F\u3002\u88C5\u7F6E\u3092\u518D\u8D77\u52D5\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u30A8\u30E9\u30FC\u304C\u518D\u767A\u3059\u308C\u3070\u30B5\u30DD\u30FC\u30C8\u306B\u304A\u554F\u3044\u5408\u308F\u305B\u304F\u3060\u3055\u3044\u3002",
            memory: "\u30E1\u30E2\u30EA\u30FC",
            diskInformation: "\u30CF\u30FC\u30C9\u30C7\u30A3\u30B9\u30AF",
            diskInformationError: "\u30C7\u30A3\u30B9\u30AF\u30D1\u30FC\u30C6\u30A3\u30B7\u30E7\u30F3\u306E1\u3064\u3067\u30A8\u30E9\u30FC\u304C\u691C\u51FA\u3055\u308C\u307E\u3057\u305F\u3002\u88C5\u7F6E\u3092\u518D\u8D77\u52D5\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u30A8\u30E9\u30FC\u304C\u518D\u767A\u3059\u308C\u3070\u30B5\u30DD\u30FC\u30C8\u306B\u304A\u554F\u3044\u5408\u308F\u305B\u304F\u3060\u3055\u3044\u3002"
          },
          interfaces: {
            title: "\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9",
            status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
            name: "\u540D\u524D",
            speed: "\u30B9\u30D4\u30FC\u30C9",
            duplex: "\u5168\u4E8C\u91CD",
            errors: "\u30A8\u30E9\u30FC",
            state: {
              ok: "OK",
              warning: "\u8B66\u544A",
              error: "\u30A8\u30E9\u30FC",
              unknown: "\u4E0D\u660E",
              loading: " "
            }
          },
          deploymentEvents: {
            title: "\u914D\u5099\u30A4\u30D9\u30F3\u30C8",
            state: "\u90FD\u9053\u5E9C\u770C",
            status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
            created: "\u65E5\u4ED8"
          },
          deploymentStatus: {
            title: "\u914D\u5099\u30B9\u30C6\u30FC\u30BF\u30B9 {{serial}}",
            status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
            action: "\u64CD\u4F5C",
            message: "\u30E1\u30C3\u30BB\u30FC\u30B8",
            registered: "\u767B\u9332\u6E08\u307F",
            lastSeen: "\u6700\u5F8C\u306B\u78BA\u8A8D\u3055\u308C\u305F",
            completed: "\u5B8C\u4E86",
            localIp: "\u30ED\u30FC\u30AB\u30EBIP",
            remoteIp: "\u30D1\u30D6\u30EA\u30C3\u30AFIP",
            siteDeploying: "\u914D\u5099\u4E2D\u3067\u3059"
          },
          deploymentLocation: {
            title: "\u30C7\u30D7\u30ED\u30A4\u30E1\u30F3\u30C8\u306E\u5834\u6240"
          },
          firmware: {
            tabTitle: "\u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2",
            version: "\u30D0\u30FC\u30B8\u30E7\u30F3",
            hotfixes: "\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u6E08\u307F\u30DB\u30C3\u30C8\u30D5\u30A3\u30C3\u30AF\u30B9",
            window: "\u6B21\u306E\u66F4\u65B0",
            nextUpdate: "{{from}}\u3068{{to}}\u306E\u9593"
          },
          hotfixes: {
            tabTitle: "\u30DB\u30C3\u30C8\u30D5\u30A3\u30C3\u30AF\u30B9",
            status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
            name: "\u540D\u524D",
            state: {
              error: "\u30A8\u30E9\u30FC",
              ok: "OK",
              unknown: "\u4E0D\u660E",
              warning: "\u4FDD\u7559\u4E2D",
              loading: " "
            }
          },
          updates: {
            tabTitle: "\u8A2D\u5B9A\u306E\u66F4\u65B0",
            lastUpdate: "\u524D\u56DE\u306E\u66F4\u65B0",
            lastSeen: "\u6700\u5F8C\u306B\u78BA\u8A8D\u3055\u308C\u305F",
            remoteIp: "\u30EA\u30E2\u30FC\u30C8IP"
          },
          restart: {
            buttonLabel: "\u518D\u8D77\u52D5",
            box: {
              menuChoice: "\u30B5\u30A4\u30C8\u306E\u518D\u8D77\u52D5",
              dialogTitle: "\u30B5\u30A4\u30C8\u306E\u518D\u8D77\u52D5",
              confirmMessage: "\u300C\u78BA\u5B9A\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3059\u308B\u3068\u3053\u306E\u30B5\u30A4\u30C8\u304C\u518D\u8D77\u52D5\u3057\u3001\u3053\u308C\u306B\u306F\u6700\u59275\u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002<0/><0/> \u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002",
              confirmMessage_plural: "\u300C\u78BA\u5B9A\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3059\u308B\u3068\u5FA1\u793E\u306EHA\u30DA\u30A2\u5185\u306E\u4E21\u65B9\u306E\u6A5F\u5668\u304C\u518D\u8D77\u52D5\u3057\u3001\u3053\u308C\u306B\u306F\u6700\u59275\u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002<0/> \u3053\u308C\u306F\u30AA\u30F3\u30E9\u30A4\u30F3\u3068\u3057\u3066\u5831\u544A\u6E08\u307F\u6A5F\u5668\u306B\u3057\u304B\u5F71\u97FF\u3057\u307E\u305B\u3093\u3002<0/><0/> \u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002"
            },
            vpn: {
              menuChoice: "VPN Service\u306E\u518D\u8D77\u52D5",
              dialogTitle: "VPN Service\u306E\u518D\u8D77\u52D5",
              confirmMessage: "\u300C\u78BA\u5B9A\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3059\u308B\u3068\u3053\u306E\u6A5F\u5668\u306E\u305F\u3081\u306EVPN service\u304C\u518D\u8D77\u52D5\u3057\u3001\u3053\u308C\u306B\u306F\u6700\u59275\u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002<0/><0/> \u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002",
              confirmMessage_plural: "\u300C\u78BA\u5B9A\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3059\u308B\u3068\u5FA1\u793E\u306EHA\u30DA\u30A2\u5185\u306E\u4E21\u65B9\u306E\u6A5F\u5668\u3067 VPN service\u304C\u518D\u8D77\u52D5\u3057\u3001\u3053\u308C\u306B\u306F\u6700\u59275\u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002<0/> \u3053\u308C\u306F\u30AA\u30F3\u30E9\u30A4\u30F3\u3068\u3057\u3066\u5831\u544A\u6E08\u307F\u6A5F\u5668\u306B\u3057\u304B\u5F71\u97FF\u3057\u307E\u305B\u3093\u3002<0/><0/> \u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002"
            },
            firmware: {
              menuChoice: "\u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2\u306E\u518D\u8D77\u52D5",
              dialogTitle: "Firewall \u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2\u306E\u518D\u8D77\u52D5",
              confirmMessage: "\u300C\u78BA\u5B9A\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3059\u308B\u3068\u3053\u306E\u6A5F\u5668\u306Efirmware\u304C\u518D\u8D77\u52D5\u3057\u3001\u3053\u308C\u306B\u306F\u6700\u59275\u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002<0/><0/> \u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002",
              confirmMessage_plural: "\u300C\u78BA\u5B9A\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3059\u308B\u3068\u5FA1\u793E\u306EHA\u30DA\u30A2\u5185\u306E\u4E21\u65B9\u306E\u6A5F\u5668\u3067 firmware\u304C\u518D\u8D77\u52D5\u3057\u3001\u3053\u308C\u306B\u306F\u6700\u59275\u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002<0/> \u3053\u308C\u306F\u30AA\u30F3\u30E9\u30A4\u30F3\u3068\u3057\u3066\u5831\u544A\u6E08\u307F\u6A5F\u5668\u306B\u3057\u304B\u5F71\u97FF\u3057\u307E\u305B\u3093\u3002<0/><0/> \u7D9A\u884C\u3057\u3066\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002"
            }
          }
        },
        delete: {
          body: "\u3053\u306E\u30B5\u30A4\u30C8\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B",
          title: "\u30B5\u30A4\u30C8{{name}}\u3092\u524A\u9664"
        },
        dialog: {
          create: {
            buttonText: "\u65B0\u3057\u3044\u30B5\u30A4\u30C8",
            sc: {
              createSC: "\u65B0\u898F IoT\u88C5\u7F6E",
              dialogTitle: "\u65B0\u898F IoT\u88C5\u7F6E",
              menuItemDisabled: "\u65B0\u898F IoT\u88C5\u7F6E\u306B\u306FEdge Service\u304C\u5FC5\u8981\u3067\u3059"
            },
            tvt: {
              createTVT: "\u65B0\u898F T/VT\u88C5\u7F6E",
              dialogTitle: "\u65B0\u3057\u3044\u30B5\u30A4\u30C8"
            }
          },
          wans: {
            sectionTitle: "WAN",
            message: "\u304A\u4F7F\u3044\u306E\u88C5\u7F6E\u306B\u306F WAN\u3068 LAN\u306B\u5272\u5F53\u53EF\u80FD\u306A\u5408\u8A08{{portsAvailable}}\u500B\u306E\u30DD\u30FC\u30C8\u304C\u3042\u308A\u307E\u3059\u3002",
            message2_site: "\u3053\u306E\u30B5\u30A4\u30C8\u306B\u3044\u304F\u3064\u306E WAN\u63A5\u7D9A\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u304B\u3002",
            message2_gateway: "\u3053\u306EEdge Service\u3067\u306F\u3044\u304F\u3064\u306EWAN\u63A5\u7D9A\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u304B\u3002",
            size: "WAN\u672C\u6570",
            setup: "WAN\u30EA\u30F3\u30AF{{step}}\u3092\u8A2D\u5B9A",
            name: "\u540D\u524D",
            port: "\u30DD\u30FC\u30C8",
            virtualLanId: "\u4EEE\u60F3 LAN ID",
            type: "\u30BF\u30A4\u30D7",
            providerPinning: "\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u306E\u30D4\u30F3\u30CB\u30F3\u30B0",
            address: "IP\u30A2\u30C9\u30EC\u30B9",
            netmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
            gateway: "\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4",
            pin: "{{serial}}\u7528 SIM\u306E PIN",
            invalidPinFormat: "SIM\u306E Pin\u306F 4\u3064\u306E\u6570\u5B57\u3067\u3059\u3002",
            SCmessage: "\u88C5\u7F6E\u306B\u306F\u8A2D\u5B9A\u3059\u3079\u304D\u5358\u4E00\u306E WAN\u30DD\u30FC\u30C8\u304C\u3042\u308A\u307E\u3059\u3002",
            SCmessage_failover: "\u88C5\u7F6E\u306B\u306F 2\u3064\u306E WAN(\u30D7\u30E9\u30A4\u30DE\u30EA\u3068\u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC)\u307E\u3067\u304C\u63A5\u7D9A\u53EF\u80FD\u3067\u3059\u3002",
            SCconfigureFailover: "\u3053\u306E\u30B5\u30A4\u30C8\u306E\u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u304B\u3002",
            failover: "WAN \u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC",
            setupSCPrimary: "\u30D7\u30E9\u30A4\u30DE\u30EA WAN\u30EA\u30F3\u30AF\u306E\u8A2D\u5B9A",
            setupSCFailover: "\u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC WAN\u30EA\u30F3\u30AF\u306E\u8A2D\u5B9A",
            descriptions: {
              name: "\u3053\u306E WAN\u30EA\u30F3\u30AF\u306E\u305F\u3081\u306E\u56FA\u6709\u540D\u3002",
              port: "\u3053\u306E\u30EA\u30F3\u30AF\u306B\u4F7F\u7528\u3059\u308B\u88C5\u7F6E\u30DD\u30FC\u30C8\u3002",
              virtualLanId: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u30AA\u30D7\u30B7\u30E7\u30F3\u4EEE\u60F3 LAN ID \uFF08\u4EEE\u60F3 LAN \u30B5\u30DD\u30FC\u30C8\u306E\u3042\u308B\u30B9\u30A4\u30C3\u30C1\u306B\u63A5\u7D9A\u3059\u308B\u5834\u5408\u306B\u4F7F\u7528\u3055\u308C\u307E\u3059\uFF09\u3002",
              type: "\u3053\u306E\u30EA\u30F3\u30AF\u7528\u306B\u4F7F\u7528\u3059\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30BF\u30A4\u30D7\u3002",
              providerPinning: "\u3053\u306E\u30EA\u30F3\u30AF\u306E\u30D4\u30F3\u30CB\u30F3\u30B0\u5148\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u30B0\u30EB\u30FC\u30D7\u3002\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u30B0\u30EB\u30FC\u30D7\u306F\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u30D5\u30ED\u30FC\u3068\u52D5\u4F5C\u3092\u5B9A\u7FA9\u3059\u308B\u305F\u3081\u306B SD-WAN \u30DD\u30EA\u30B7\u30FC\u3067\u4F7F\u7528\u3055\u308C\u307E\u3059\u3002",
              address: "\u30DD\u30FC\u30C8\u306B\u5272\u308A\u5F53\u3066\u308B Ip\u30A2\u30C9\u30EC\u30B9\u3002",
              netmask: "\u3053\u306E\u30EA\u30F3\u30AF\u306E\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF CIDR \u30CD\u30C3\u30C8\u30DE\u30B9\u30AF \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u3002",
              gateway: "\u3053\u306E\u30EA\u30F3\u30AF\u306B\u95A2\u9023\u4ED8\u3051\u3089\u308C\u3066\u3044\u308B\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4 IP\u30A2\u30C9\u30EC\u30B9\u3002",
              pin: "\u3053\u306E\u88C5\u7F6E\u306B\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u3055\u308C\u3066\u3044\u308B SIM\u30AB\u30FC\u30C9\u7528\u306E\u500B\u4EBA\u8B58\u5225\u756A\u53F7\u3002"
            }
          },
          lans: {
            sectionTitle: "LAN",
            message: "\u304A\u4F7F\u3044\u306E\u88C5\u7F6E\u306B\u306F\u3001WAN\u3068 LAN\u306B\u5272\u5F53\u53EF\u80FD\u306A\u5408\u8A08{{portsAvailable}}\u30DD\u30FC\u30C8\u304C\u3042\u308A\u3001WAN\u306B{{wans}}\u304C\u5272\u308A\u5F53\u3066\u3089\u308C\u3066\u3044\u307E\u3059\u3002",
            SCmessage: "\u3053\u306E\u88C5\u7F6E\u306B\u306F\u5408\u8A08{{portsAvailable}}\u500B\u306E LAN \u30DD\u30FC\u30C8\u304C\u5229\u7528\u53EF\u80FD\u3067\u3059\u3002",
            message2: "\u3053\u306E\u30B5\u30A4\u30C8\u306B\u3044\u304F\u3064\u306E LAN\u63A5\u7D9A\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u304B\u3002",
            size: "LAN\u672C\u6570",
            setup: "LAN\u30EA\u30F3\u30AF{{step}}\u3092\u8A2D\u5B9A",
            name: "\u540D\u524D",
            port: "\u30DD\u30FC\u30C8",
            virtualLanId: "\u4EEE\u60F3 LAN ID",
            address: "IP\u30A2\u30C9\u30EC\u30B9",
            netmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
            dhcpEnable: "DHCP \u30B5\u30FC\u30D0\u30FC",
            dhcpDescription: "\u5185\u5BB9",
            dhcpFirstIp: "\u5148\u982D\u306E IP\u30A2\u30C9\u30EC\u30B9",
            dhcpLastIp: "\u6700\u5F8C\u306E IP\u30A2\u30C9\u30EC\u30B9",
            dhcpDomain: "DHCP\u30C9\u30E1\u30A4\u30F3",
            dhcpSectionTitle: "DHCP\u8A2D\u5B9A",
            wifiSectionTitle: "WiFi\u8A2D\u5B9A",
            wifi: {
              ssid: "SSID",
              securityMode: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30E2\u30FC\u30C9",
              passphrase: "\u30D1\u30B9\u30D5\u30EC\u30FC\u30BA",
              passphraseConfirm: "\u30D1\u30B9\u30D5\u30EC\u30FC\u30BA\u3092\u78BA\u8A8D"
            },
            descriptions: {
              dhcpEnable: "\u3053\u306E LAN\u30EA\u30F3\u30AF\u306B\u3064\u3044\u3066 DHCP \u30B5\u30FC\u30D0\u30FC\u3092\u6709\u52B9\u306B\u3057\u307E\u3059\u3002",
              name: "\u3053\u306E LAN\u30EA\u30F3\u30AF\u306E\u305F\u3081\u306E\u56FA\u6709\u540D\u3002",
              port: "\u3053\u306E\u30EA\u30F3\u30AF\u306B\u4F7F\u7528\u3059\u308B\u88C5\u7F6E\u30DD\u30FC\u30C8\u3002",
              virtualLanId: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u30AA\u30D7\u30B7\u30E7\u30F3\u4EEE\u60F3 LAN ID \uFF08\u4EEE\u60F3 LAN \u30B5\u30DD\u30FC\u30C8\u306E\u3042\u308B\u30B9\u30A4\u30C3\u30C1\u306B\u63A5\u7D9A\u3059\u308B\u5834\u5408\u306B\u4F7F\u7528\u3055\u308C\u307E\u3059\uFF09\u3002",
              address: "\u30DD\u30FC\u30C8\u306B\u5272\u308A\u5F53\u3066\u308B Ip\u30A2\u30C9\u30EC\u30B9\u3002",
              netmask: "\u4F5C\u6210\u3057\u305F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306B\u4F7F\u7528\u3059\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF CIDR\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u3002",
              dhcpDescription: "\u3053\u306E DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u7528\u306E\u30AB\u30B9\u30BF\u30E0\u8A73\u7D30\u3002",
              dhcpFirstIp: "\u3053\u306E DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u5185\u3067\u5272\u5F53\u53EF\u80FD\u306B\u3059\u308B\u5148\u982D\u306E IP\u30A2\u30C9\u30EC\u30B9\u3002",
              dhcpLastIp: "\u3053\u306E DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u5185\u3067\u5272\u5F53\u53EF\u80FD\u306B\u3059\u308B\u6700\u5F8C\u306E IP\u30A2\u30C9\u30EC\u30B9\u3002",
              dhcpDomain: "\u3053\u306E DHCPService\u306E\u30C9\u30E1\u30A4\u30F3\u540D\u3002",
              wifi: {
                ssid: "WiFi\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E SSID\u3002",
                securityMode: "\u4F7F\u7528\u3057\u305F\u3044 Wi-Fi\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30E2\u30FC\u30C9\u3002",
                passphrase: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u7528\u30D1\u30B9\u30D5\u30EC\u30FC\u30BA\u3002"
              }
            }
          },
          advanced: {
            sectionTitle: "\u8A73\u7D30\u8A2D\u5B9A",
            updateWindowTZ: "\u66F4\u65B0\u6642\u9593\u67A0\u306E\u6642\u9593\u5E2F",
            primaryDns: "\u30D7\u30E9\u30A4\u30DE\u30EA DNS",
            secondaryDns: "\u30BB\u30AB\u30F3\u30C0\u30EA DNS",
            descriptions: {
              updateWindowTZ: "\u3053\u306E\u30B5\u30A4\u30C8\u306E\u66F4\u65B0\u6642\u9593\u67A0\u3092\u6C7A\u5B9A\u3059\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u308B\u30B5\u30A4\u30C8\u306E\u6642\u9593\u5E2F\u3002",
              primaryDns: "\u30D7\u30E9\u30A4\u30DE\u30EADNS\u30A2\u30C9\u30EC\u30B9\u3092\u624B\u52D5\u3067\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              secondaryDns: "\u30BB\u30AB\u30F3\u30C0\u30EADNS\u30A2\u30C9\u30EC\u30B9\u3092\u624B\u52D5\u3067\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002"
            }
          },
          submit: {
            message: "\u30B5\u30A4\u30C8\u306E\u8A73\u7D30\u3092\u78BA\u8A8D\u3057\u3066\u300C\u4FDD\u5B58\u300D\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u307E\u3059",
            name: "\u540D\u524D",
            description: "\u5185\u5BB9",
            serial: "\u30B7\u30EA\u30A2\u30EB",
            gateway: "Edge Service",
            noGateway: "(\u306A\u3057)",
            timezone: "\u6642\u9593\u5E2F",
            status: "\u63A5\u7D9A\u30B9\u30C6\u30FC\u30BF\u30B9",
            peeringAddress: "\u30D4\u30A2\u30EA\u30F3\u30B0\u30A2\u30C9\u30EC\u30B9",
            wan: "WAN",
            lan: "LAN",
            primaryDns: "\u30D7\u30E9\u30A4\u30DE\u30EA DNS",
            secondaryDns: "\u30BB\u30AB\u30F3\u30C0\u30EA DNS"
          },
          result: {
            siteCreated: "\u30B5\u30A4\u30C8\u306F\u6B63\u5E38\u306B\u4F5C\u6210\u3055\u308C\u307E\u3057\u305F\u3002",
            connectPort: "\u6A5F\u5668\u306E\u81EA\u52D5\u69CB\u6210\u3092\u53EF\u80FD\u306B\u3059\u308B\u305F\u3081\u306B\u3001\u4E0B\u8A18\u306E\u6A5F\u5668\u306E\u30DD\u30FC\u30C8<0>{{port}}</0>\u3092 DHCP\u5BFE\u5FDC\u306E\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u63A5\u7D9A\u3057\u3066\u3044\u308B\u3053\u3068\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u5225\u306E\u30DD\u30FC\u30C8\u3092\u4F7F\u7528\u3057\u305F\u3044\u5834\u5408\u306F\u6A5F\u5668\u3067\u76F4\u63A5\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
            connectPortSc: "\u6A5F\u5668\u306E\u81EA\u52D5\u69CB\u6210\u3092\u53EF\u80FD\u306B\u3059\u308B\u305F\u3081\u306B\u3001\u4EE5\u4E0B\u306E\u6A5F\u5668\u306E WAN\u30DD\u30FC\u30C8\u3092 DHCP\u5BFE\u5FDC\u306E\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u63A5\u7D9A\u3057\u3066\u3044\u308B\u3053\u3068\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
            connectPort_plural: "\u6A5F\u5668\u306E\u81EA\u52D5\u69CB\u6210\u3092\u53EF\u80FD\u306B\u3059\u308B\u305F\u3081\u306B\u3001\u4E0B\u8A18\u306E\u6A5F\u5668\u306E\u30DD\u30FC\u30C8<0>{{port}}</0>\u3092 DHCP\u5BFE\u5FDC\u306E\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u63A5\u7D9A\u3057\u3066\u3044\u308B\u3053\u3068\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u5225\u306E\u30DD\u30FC\u30C8\u3092\u4F7F\u7528\u3057\u305F\u3044\u5834\u5408\u306F\u6A5F\u5668\u3067\u76F4\u63A5\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
            HAMessage: "\u4E21\u65B9\u306E\u6A5F\u5668\u306E\u30DD\u30FC\u30C8<0>P1</0> \u3092\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF \u30B1\u30FC\u30D6\u30EB\u3067\u76F4\u7D50\u3057\u307E\u3059\u3002HA \u540C\u671F\u304C\u81EA\u52D5\u7684\u306B\u958B\u59CB\u3055\u308C\u307E\u3059\u3002",
            campus: "\u8A73\u7D30\u306F<0>Barracuda Campus</0>\u3092\u3054\u89A7\u304F\u3060\u3055\u3044",
            serial: "\u30B7\u30EA\u30A2\u30EB: {{serial}}",
            nonstandardPort: "\u8B66\u544A: \u3053\u306E\u8A2D\u5B9A\u306F\u6A19\u6E96\u4EE5\u5916\u306E\u30DD\u30FC\u30C8\u3092\u4F7F\u7528\u3057\u307E\u3059: <0>{{ports}}</0>\u3002\u3053\u308C\u304C\u4EEE\u60F3\u6A5F\u5668\u3067\u6709\u52B9\u306A\u3053\u3068\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
            nonstandardPort_plural: "\u8B66\u544A: \u3053\u306E\u8A2D\u5B9A\u306F\u6A19\u6E96\u4EE5\u5916\u306E\u30DD\u30FC\u30C8\u3092\u4F7F\u7528\u3057\u307E\u3059: <0>{{ports}}</0>\u3002\u3053\u308C\u3089\u304C\u4EEE\u60F3\u6A5F\u5668\u3067\u6709\u52B9\u306A\u3053\u3068\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002"
          },
          steps: {
            result: "\u5B8C\u4E86",
            submit: "\u8981\u7D04"
          },
          types: {
            dynamic: "\u52D5\u7684 (DHCP)",
            configure_on_branch: "\u30D6\u30E9\u30F3\u30C1\u3067\u8A2D\u5B9A\u6E08\u307F"
          },
          validation: {
            duplicateNetwork: "\u3053\u306E\u30DD\u30FC\u30C8/\u4EEE\u60F3 LAN ID\u3068\u5408\u81F4\u3059\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306F\u3059\u3067\u306B\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u307E\u3059",
            duplicateWwan: "1\u3064\u306E WWAN \u306E\u307F\u8A2D\u5B9A\u53EF\u80FD\u3067\u3059",
            duplicateSCwan: "1\u3064\u306E\u52D5\u7684\u307E\u305F\u306F\u56FA\u5B9AWAN\u306E\u307F\u8A2D\u5B9A\u53EF\u80FD\u3067\u3059",
            duplicateExpressRoute: "1\u3064\u306E\u30A8\u30AF\u30B9\u30D7\u30EC\u30B9 \u30EB\u30FC\u30C8\u306E\u307F\u8A2D\u5B9A\u53EF\u80FD\u3067\u3059",
            duplicateName: "\u4ED6\u306E\u63A5\u7D9A\u3067\u540D\u524D\u304C\u3059\u3067\u306B\u4F7F\u7528\u3055\u308C\u3066\u3044\u307E\u3059",
            secondaryDns: "\u30D7\u30E9\u30A4\u30DE\u30EA DNS\u304C\u306A\u3044\u9650\u308A\u8A2D\u5B9A\u3059\u3079\u304D\u3067\u306F\u3042\u308A\u307E\u305B\u3093",
            availableAsn: "\u3053\u306E ASN\u5024\u306F\u5229\u7528\u3067\u304D\u307E\u305B\u3093"
          }
        },
        liveLog: {
          tabTitle: "\u30E9\u30A4\u30D6\u63A5\u7D9A",
          pageTitle: "\u30E9\u30A4\u30D6\u63A5\u7D9A",
          waitingForConnections: "\u30B5\u30A4\u30C8\u306B\u63A5\u7D9A\u3057\u3066\u3044\u307E\u3059\u3002\u63A5\u7D9A\u304C\u78BA\u7ACB\u3055\u308C\u308B\u307E\u3067\u6700\u5927 2 \u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002\u304A\u5F85\u3061\u304F\u3060\u3055\u3044...",
          emptyData: "\u73FE\u5728\u30A2\u30AF\u30C6\u30A3\u30D6\u306A\u30E9\u30A4\u30D6\u63A5\u7D9A\u306F\u3042\u308A\u307E\u305B\u3093...",
          pauseRequestsButton: "\u4E00\u6642\u505C\u6B62",
          resumeRequestsButton: "\u518D\u958B"
        },
        menuTitle: "\u30B5\u30A4\u30C8",
        pageTitle: "\u30B5\u30A4\u30C8",
        settings: {
          tabTitle: "\u8A2D\u5B9A",
          basic: {
            tabTitle: "\u57FA\u672C\u60C5\u5831",
            name: "\u540D\u524D",
            model: "\u30E2\u30C7\u30EB",
            gateway: "Edge Service",
            serials: "\u30B7\u30EA\u30A2\u30EB",
            descriptions: {
              gateway: "\u3053\u306E\u30B5\u30A4\u30C8\u304C\u63A5\u7D9A\u3055\u308C\u3066\u3044\u308B Barracuda SecureEdge Edge Service\u3002",
              name: "\u30B5\u30A4\u30C8\u306E\u56FA\u6709\u540D\u3002",
              model: "\u3053\u306E\u30B5\u30A4\u30C8\u7528\u306E\u88C5\u7F6E\u30E2\u30C7\u30EB\u3002",
              serials: "\u3053\u306E\u30B5\u30A4\u30C8\u7528\u306E\u88C5\u7F6E\u30B7\u30EA\u30A2\u30EB\u3002"
            },
            serialEdit: {
              table: {
                status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
                serial: "\u30B7\u30EA\u30A2\u30EB",
                add: "\u4E8C\u756A\u76EE\u3092\u8FFD\u52A0"
              },
              addTitle: "\u30B7\u30EA\u30A2\u30EB\u3092\u8FFD\u52A0",
              editTitle: "\u30B7\u30EA\u30A2\u30EB\u3092\u30B9\u30EF\u30C3\u30D7",
              deleteTitle: "\u30B7\u30EA\u30A2\u30EB\u3092\u524A\u9664",
              edit: "\u3053\u306E\u30B5\u30A4\u30C8\u306B\u63A5\u7D9A\u3057\u305F\u3044\u4EA4\u63DB\u7528\u6A5F\u5668\u7528\u306E\u30B7\u30EA\u30A2\u30EB\u3092\u4E0B\u304B\u3089\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u6A5F\u5668\u306F\u540C\u30E2\u30C7\u30EB\u306E\u4ED6\u306E\u6A5F\u5668\u3068\u3057\u304B\u4EA4\u63DB\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u305B\u3093\u3002",
              add: "\u30B5\u30A4\u30C8\u306B\u7B2C\u4E8C\u306E\u6A5F\u5668\u3092\u63A5\u7D9A\u3059\u308B\u3068\u9AD8\u53EF\u7528\u6027\u6A5F\u80FD\u304C\u958B\u59CB\u3055\u308C\u307E\u3059\u3002\u304A\u4F7F\u3044\u306E\u6A5F\u5668\u306E\u30B7\u30EA\u30A2\u30EB\u3092\u4E0B\u8A18\u304B\u3089\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              delete1: "\u3053\u306E\u30B5\u30A4\u30C8\u304B\u3089\u30B7\u30EA\u30A2\u30EB {{serial}} \u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B",
              delete2: "\u3053\u308C\u306B\u3088\u308A\u9AD8\u53EF\u7528\u6027\u6A5F\u80FD\u304C\u306A\u304F\u306A\u308A\u3001\u95A2\u9023\u3059\u308B\u6A5F\u5668\u306F\u7121\u52B9\u306B\u306A\u308A\u307E\u3059\u3002",
              save: "\u5909\u66F4\u306F\u4FDD\u5B58\u5F8C\u6709\u52B9\u306B\u306A\u308A\u307E\u3059\u3002",
              saveDelete: "\u4FDD\u5B58\u5F8C\u3001\u6A5F\u5668\u3092\u5207\u65AD\u3067\u304D\u307E\u3059\u3002",
              saveAdd: "\u4FDD\u5B58\u3057\u305F\u3089\u3001\u6A5F\u5668\u306E\u30DD\u30FC\u30C8<0>P4</0>\u3092 DHCP \u304C\u6709\u52B9\u306A\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u6700\u521D\u306B\u63A5\u7D9A\u3057\u6A5F\u5668\u306E\u81EA\u52D5\u8A2D\u5B9A\u3092\u6709\u52B9\u306B\u3057\u3066\u65B0\u3057\u3044\u6A5F\u5668\u3092\u63A5\u7D9A\u3057\u307E\u3059\u3002\u5225\u306E\u30DD\u30FC\u30C8\u3092\u4F7F\u7528\u3057\u305F\u3044\u5834\u5408\u306F\u6A5F\u5668\u3067\u76F4\u63A5\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
              saveEdit: "\u4FDD\u5B58\u3057\u305F\u3089\u53E4\u3044\u6A5F\u5668\u306F\u5207\u65AD\u3057\u3066\u3082\u304B\u307E\u3044\u307E\u305B\u3093\u3002\u6B21\u306B\u3001\u65B0\u3057\u3044\u6A5F\u5668\u3092\u63A5\u7D9A\u3057\u3001 \u6A5F\u5668\u306E\u30DD\u30FC\u30C8<0>P4</0>\u3092 DHCP \u304C\u6709\u52B9\u306A\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u6700\u521D\u306B\u63A5\u7D9A\u3057\u3066\u6A5F\u5668\u306E\u81EA\u52D5\u8A2D\u5B9A\u3092\u6709\u52B9\u306B\u3057\u307E\u3059\u3002\u5225\u306E\u30DD\u30FC\u30C8\u3092\u4F7F\u7528\u3057\u305F\u3044\u5834\u5408\u306F\u6A5F\u5668\u3067\u76F4\u63A5\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
              saveHA: "\u3055\u3089\u306B\u3001\u65B0\u3057\u3044\u6A5F\u5668\u3068\u65E2\u5B58\u306E\u6A5F\u5668\u306E\u30DD\u30FC\u30C8<0>P1</0>\u3092\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30B1\u30FC\u30D6\u30EB\u3067\u76F4\u7D50\u3057\u307E\u3059\u3002HA \u540C\u671F\u304C\u81EA\u52D5\u7684\u306B\u958B\u59CB\u3055\u308C\u307E\u3059\u3002",
              saveEditSecondary: "\u53E4\u3044\u6A5F\u5668\u306FHA\u7528\u306B\u8A2D\u5B9A\u6E08\u307F\u3067\u3059\u3002\u3053\u308C\u3092\u518D\u5EA6\u4F7F\u7528\u3067\u304D\u308B\u3088\u3046\u306B\u3059\u308B\u305F\u3081\u518D\u5EA6\u30A4\u30E1\u30FC\u30B8\u3092\u69CB\u6210\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
              saveDeleteSecondary: "\u3053\u306E\u6A5F\u5668\u306F HA\u7528\u306B\u8A2D\u5B9A\u6E08\u307F\u3067\u3059\u3002\u3053\u308C\u3092\u518D\u5EA6\u4F7F\u7528\u3067\u304D\u308B\u3088\u3046\u306B\u3059\u308B\u305F\u3081\u518D\u5EA6\u30A4\u30E1\u30FC\u30B8\u3092\u69CB\u6210\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
              saveDeletePrimary: "\u3053\u306E\u30B5\u30A4\u30C8\u306B\u63A5\u7D9A\u3055\u308C\u3066\u3044\u308B\u4ED6\u306E\u6A5F\u5668\u306FHA\u7528\u306B\u8A2D\u5B9A\u6E08\u307F\u3067\u3059\u3002HA\u6A5F\u5668\u3068\u3057\u3066\u518D\u8A2D\u5B9A\u3055\u308C\u308B\u305F\u3081\u306B\u306F\u4FDD\u5B58\u5F8C\u518D\u5EA6\u30A4\u30E1\u30FC\u30B8\u5316\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002\u3053\u306E\u9593\u30D7\u30ED\u30BB\u30B9\u306E\u7D42\u4E86\u307E\u3067\u30B5\u30A4\u30C8\u304C\u82E5\u5E72\u505C\u6B62\u3059\u308B\u3053\u3068\u306B\u306A\u308A\u307E\u3059\u3002"
            }
          },
          wan: {
            tabTitle: "WAN",
            addTitle: "\u65B0\u898F WAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u8FFD\u52A0",
            addButtonText: "WAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u8FFD\u52A0",
            editTitle: "WAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u7DE8\u96C6",
            deleteTitle: "WAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u524A\u9664",
            deleteMessage: "\u3053\u306E WAN \u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u672C\u5F53\u306B\u524A\u9664\u3057\u3066\u3082\u3088\u3044\u306E\u3067\u3059\u304B <0> \u524A\u9664\u306F\u4FDD\u5B58\u6642\u306B\u6052\u4E45\u7684\u306B\u53CD\u6620\u3055\u308C\u307E\u3059\u3002",
            name: "\u540D\u524D",
            port: "\u30DD\u30FC\u30C8",
            virtualLanId: "\u4EEE\u60F3 LAN ID",
            type: "\u30BF\u30A4\u30D7",
            providerPinning: "\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u306E\u30D4\u30F3\u30CB\u30F3\u30B0",
            address: "IP\u30A2\u30C9\u30EC\u30B9",
            netmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
            gateway: "\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4",
            pin: "{{serial}}\u7528 SIM\u306E PIN",
            scPin: "SIM Pin",
            invalidPinFormat: "SIM\u306E Pin\u306F 4\u3064\u306E\u6570\u5B57\u3067\u3059\u3002",
            portWarning: "\u4E0A\u8A18\u306E\u30B0\u30EC\u30FC\u30A2\u30A6\u30C8\u3055\u308C\u305F\u884C\u306F\u9078\u629E\u3055\u308C\u305F\u30DD\u30FC\u30C8\u304C\u6A5F\u5668\u3067\u6709\u52B9\u3067\u306A\u3044\u3053\u3068\u3092\u793A\u3057\u307E\u3059\u3002",
            failover: "HA \u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC",
            network: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            doublePort: "\u30DD\u30FC\u30C8",
            doubleVirtualLanId: "\u4EEE\u60F3 LAN ID",
            doubleAddress: "IP\u30A2\u30C9\u30EC\u30B9",
            doubleNetmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
            virtualTransferNetwork: "\u4EEE\u60F3\u8EE2\u9001\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            asn: "\u30A8\u30AF\u30B9\u30D7\u30EC\u30B9\u30EB\u30FC\u30C8 ASN",
            expressRoute: "\u30A8\u30AF\u30B9\u30D7\u30EC\u30B9\u30EB\u30FC\u30C8",
            primary: "\u30D7\u30E9\u30A4\u30DE\u30EA",
            secondary: "\u30BB\u30AB\u30F3\u30C0\u30EA",
            validatePublicIp: "\u306F\u30E6\u30FC\u30B6\u30FC\u304C\u63A5\u7D9A\u3067\u304D\u308B\u3088\u3046\u306B\u30D1\u30D6\u30EA\u30C3\u30AFIP\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093",
            descriptions: {
              name: "\u3053\u306E WAN\u30EA\u30F3\u30AF\u306E\u305F\u3081\u306E\u56FA\u6709\u540D\u3002",
              port: "\u3053\u306E\u30EA\u30F3\u30AF\u306B\u4F7F\u7528\u3059\u308B\u88C5\u7F6E\u30DD\u30FC\u30C8\u3002",
              virtualLanId: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u30AA\u30D7\u30B7\u30E7\u30F3\u4EEE\u60F3 LAN ID \uFF08\u4EEE\u60F3 LAN \u30B5\u30DD\u30FC\u30C8\u306E\u3042\u308B\u30B9\u30A4\u30C3\u30C1\u306B\u63A5\u7D9A\u3059\u308B\u5834\u5408\u306B\u4F7F\u7528\u3055\u308C\u307E\u3059\uFF09\u3002",
              type: "\u3053\u306E\u30EA\u30F3\u30AF\u7528\u306B\u4F7F\u7528\u3059\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30BF\u30A4\u30D7\u3002",
              providerPinning: "\u3053\u306E\u30EA\u30F3\u30AF\u306E\u30D4\u30F3\u30CB\u30F3\u30B0\u5148\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u30B0\u30EB\u30FC\u30D7\u3002\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u30B0\u30EB\u30FC\u30D7\u306F\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u30D5\u30ED\u30FC\u3068\u52D5\u4F5C\u3092\u5B9A\u7FA9\u3059\u308B\u305F\u3081\u306B SD-WAN \u30DD\u30EA\u30B7\u30FC\u3067\u4F7F\u7528\u3055\u308C\u307E\u3059\u3002",
              address: "\u30DD\u30FC\u30C8\u306B\u5272\u308A\u5F53\u3066\u308B Ip\u30A2\u30C9\u30EC\u30B9\u3002",
              netmask: "\u3053\u306E\u30EA\u30F3\u30AF\u306E\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF CIDR \u30CD\u30C3\u30C8\u30DE\u30B9\u30AF \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u3002",
              gateway: "\u3053\u306E\u30EA\u30F3\u30AF\u306B\u95A2\u9023\u4ED8\u3051\u3089\u308C\u3066\u3044\u308B\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4 IP\u30A2\u30C9\u30EC\u30B9\u3002",
              pin: "\u3053\u306E\u88C5\u7F6E\u306B\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u3055\u308C\u3066\u3044\u308B SIM\u30AB\u30FC\u30C9\u7528\u306E\u500B\u4EBA\u8B58\u5225\u756A\u53F7\u3002",
              failover: "\u6709\u52B9\u306B\u3059\u308B\u3068\u3001\u30DD\u30FC\u30C8\u306F\u30E2\u30CB\u30BF\u30FC\u3055\u308C\u3001\u63A5\u7D9A\u304C\u5931\u308F\u308C\u305F\u5834\u5408\u306B HA\u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC\u304C\u5B9F\u884C\u3055\u308C\u307E\u3059\u3002HA \u304C\u6709\u52B9\u306A\u30B5\u30A4\u30C8\u3067\u306E\u307F\u5229\u7528\u3067\u304D\u307E\u3059\u3002",
              doublePort: "\u30D7\u30E9\u30A4\u30DE\u30EA\u30EA\u30F3\u30AF\u3068\u30BB\u30AB\u30F3\u30C0\u30EA\u30EA\u30F3\u30AF\u7528\u306B\u4F7F\u7528\u3055\u308C\u308B\u88C5\u7F6E\u306E\u30DD\u30FC\u30C8\u3002",
              doubleVirtualLanId: "\u30D7\u30E9\u30A4\u30DE\u30EA\u30EA\u30F3\u30AF\u3068\u30BB\u30AB\u30F3\u30C0\u30EA\u30EA\u30F3\u30AF\u7528\u306E\u30AA\u30D7\u30B7\u30E7\u30F3\u4EEE\u60F3 LAN ID(\u4EEE\u60F3 LAN \u5BFE\u5FDC\u30B9\u30A4\u30C3\u30C1\u306B\u63A5\u7D9A\u3059\u308B\u3068\u304D\u4F7F\u7528)\u3002",
              doubleAddress: "\u30D7\u30E9\u30A4\u30DE\u30EA\u30EA\u30F3\u30AF\u3068\u30BB\u30AB\u30F3\u30C0\u30EA\u30EA\u30F3\u30AF\u306B\u5272\u308A\u5F53\u3066\u308B IP \u30A2\u30C9\u30EC\u30B9\u3002\u3053\u308C\u3089\u306F\u3001Azure\u3067\u69CB\u6210\u3055\u308C\u305F\u5404\u63A5\u7D9A\u306E\u7BC4\u56F2\u306E\u6700\u521D\u306E\u4F7F\u7528\u53EF\u80FD\u306A IP\u3067\u3042\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059(\u305F\u3068\u3048\u3070\u3001Azure\u3067 172.16\u30020.4/30 \u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u308B\u5834\u5408\u3001172.16\u30020.5 \u304C\u5165\u529B\u3059\u308B IP\u30A2\u30C9\u30EC\u30B9\u3068\u306A\u308A\u307E\u3059\uFF09\u3002",
              doubleNetmask: "\u30D7\u30E9\u30A4\u30DE\u30EA\u30EA\u30F3\u30AF\u3068\u30BB\u30AB\u30F3\u30C0\u30EA\u30EA\u30F3\u30AF\u7528\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF CIDR \u30CD\u30C3\u30C8\u30DE\u30B9\u30AF \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u3002",
              asn: "\u3053\u306E\u30EA\u30F3\u30AF\u7528\u306B\u81EA\u52D5\u751F\u6210\u3055\u308C\u305F BGP ASN\u3002\u3053\u308C\u306FCloud Gen WAN service\u4EE5\u5185\u3067\u7BA1\u7406\u3055\u308C\u3066\u3044\u308B\u4ED6\u306E ASN\u3068\u306F\u7AF6\u5408\u3057\u307E\u305B\u3093\u3002",
              virtualTransferNetwork: "\u4EEE\u60F3\u8EE2\u9001\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u7528\u306BIP\u3092\u4E88\u7D04\u3059\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3002"
            }
          },
          lan: {
            tabTitle: "LAN",
            sectionTitle: "LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9",
            addTitle: "\u65B0\u898F LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u8FFD\u52A0",
            addButtonText: "LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u8FFD\u52A0",
            editTitle: "LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u7DE8\u96C6",
            deleteTitle: "LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u524A\u9664",
            deleteMessage1: "\u3053\u306E LAN \u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u672C\u5F53\u306B\u524A\u9664\u3057\u3066\u3082\u3088\u3044\u306E\u3067\u3059\u304B\u3053\u308C\u3067\u4EE5\u4E0B\u306E\u52B9\u679C\u304C\u5B9F\u73FE\u3057\u307E\u3059:",
            deleteMessage2: "\u4E00\u81F4\u3059\u308B DHCP\u8A2D\u5B9A\u304A\u3088\u3073\u4E88\u7D04\u306F\u524A\u9664\u3055\u308C\u307E\u3059\u3002",
            deleteMessage3: "\u3053\u306E LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u4F7F\u7528\u3059\u308B \u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30EB\u30FC\u30EB\u306F\u3059\u3079\u3066\u66F4\u65B0\u307E\u305F\u306F\u524A\u9664\u3055\u308C\u307E\u3059\u3002",
            deleteMessage4: "\u524A\u9664\u306F\u4FDD\u5B58\u6642\u306B\u6052\u4E45\u7684\u306B\u53CD\u6620\u3055\u308C\u307E\u3059\u3002",
            name: "\u540D\u524D",
            port: "\u30DD\u30FC\u30C8",
            virtualLanId: "\u4EEE\u60F3 LAN ID",
            address: "IP\u30A2\u30C9\u30EC\u30B9",
            netmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
            dhcpEnable: "DHCP \u30B5\u30FC\u30D0\u30FC",
            dhcpFirst: "\u5148\u982D\u306E IP\u30A2\u30C9\u30EC\u30B9",
            dhcpLast: "\u6700\u5F8C\u306E IP\u30A2\u30C9\u30EC\u30B9",
            dhcpDomain: "DHCP\u30C9\u30E1\u30A4\u30F3",
            dhcpRange: "DHCP\u7BC4\u56F2",
            portWarning: "\u4E0A\u8A18\u306E\u30B0\u30EC\u30FC\u30A2\u30A6\u30C8\u3055\u308C\u305F\u884C\u306F\u9078\u629E\u3055\u308C\u305F\u30DD\u30FC\u30C8\u304C\u6A5F\u5668\u3067\u6709\u52B9\u3067\u306A\u3044\u3053\u3068\u3092\u793A\u3057\u307E\u3059\u3002",
            failover: "HA \u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC",
            descriptions: {
              name: "\u3053\u306E LAN\u30EA\u30F3\u30AF\u306E\u305F\u3081\u306E\u56FA\u6709\u540D\u3002",
              port: "\u3053\u306E\u30EA\u30F3\u30AF\u306B\u4F7F\u7528\u3059\u308B\u88C5\u7F6E\u30DD\u30FC\u30C8\u3002",
              virtualLanId: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u30AA\u30D7\u30B7\u30E7\u30F3\u4EEE\u60F3 LAN ID \uFF08\u4EEE\u60F3 LAN \u30B5\u30DD\u30FC\u30C8\u306E\u3042\u308B\u30B9\u30A4\u30C3\u30C1\u306B\u63A5\u7D9A\u3059\u308B\u5834\u5408\u306B\u4F7F\u7528\u3055\u308C\u307E\u3059\uFF09\u3002",
              address: "\u30DD\u30FC\u30C8\u306B\u5272\u308A\u5F53\u3066\u308B Ip\u30A2\u30C9\u30EC\u30B9\u3002",
              netmask: "\u4F5C\u6210\u3057\u305F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306B\u4F7F\u7528\u3059\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF CIDR\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u3002",
              dhcpEnable: "\u3053\u306E\u30EA\u30F3\u30AF\u7528\u306B\u88C5\u7F6E\u3067 DHCP \u30B5\u30FC\u30D0\u30FC\u3092\u6709\u52B9\u306B\u3057\u307E\u3059\u3002",
              dhcpFirst: "DHCP\u5272\u308A\u5F53\u3066\u7528\u306B\u6700\u521D\u306B\u5229\u7528\u53EF\u80FD\u306AIP\u3002\u3053\u308C\u306F\u30DD\u30FC\u30C8\u306E IP \u3068\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF\u3067\u5B9A\u7FA9\u3055\u308C\u305F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u5185\u306B\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
              dhcpLast: "DHCP \u5272\u308A\u5F53\u3066\u306B\u6700\u5F8C\u306B\u5229\u7528\u53EF\u80FD\u306A IP\u3002\u3053\u308C\u306F\u30DD\u30FC\u30C8\u306E IP \u3068\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF\u3067\u5B9A\u7FA9\u3055\u308C\u305F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u5185\u306B\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
              dhcpDomain: "DHCP \u30B5\u30FC\u30D0\u30FC\u306B\u5272\u308A\u5F53\u3066\u308B\u30C9\u30E1\u30A4\u30F3\u540D\u3002",
              failover: "\u6709\u52B9\u306B\u3059\u308B\u3068\u3001\u30DD\u30FC\u30C8\u306F\u30E2\u30CB\u30BF\u30FC\u3055\u308C\u3001\u63A5\u7D9A\u304C\u5931\u308F\u308C\u305F\u5834\u5408\u306B HA\u30D5\u30A7\u30A4\u30EB\u30AA\u30FC\u30D0\u30FC\u304C\u5B9F\u884C\u3055\u308C\u307E\u3059\u3002HA \u304C\u6709\u52B9\u306A\u30B5\u30A4\u30C8\u3067\u306E\u307F\u5229\u7528\u3067\u304D\u307E\u3059\u3002"
            }
          },
          route: {
            tabTitle: "\u30EB\u30FC\u30C8",
            sectionTitle: "\u30B9\u30BF\u30C6\u30A3\u30C3\u30AF\u30EB\u30FC\u30C8",
            addTitle: "\u65B0\u898F\u30B9\u30BF\u30C6\u30A3\u30C3\u30AF\u30EB\u30FC\u30C8\u3092\u8FFD\u52A0",
            addButtonText: "\u30B9\u30BF\u30C6\u30A3\u30C3\u30AF\u30EB\u30FC\u30C8\u3092\u8FFD\u52A0",
            editTitle: "\u30B9\u30BF\u30C6\u30A3\u30C3\u30AF\u30EB\u30FC\u30C8\u3092\u7DE8\u96C6",
            deleteTitle: "\u30B9\u30BF\u30C6\u30A3\u30C3\u30AF\u30EB\u30FC\u30C8\u3092\u524A\u9664",
            deleteMessage: "\u3053\u306E\u30B9\u30BF\u30C6\u30A3\u30C3\u30AF\u30EB\u30FC\u30C8\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u3044\u306E\u3067\u3059\u304B\u3002\u524A\u9664\u306F\u4FDD\u5B58\u6642\u306B\u6052\u4E45\u7684\u306B\u53CD\u6620\u3055\u308C\u307E\u3059\u3002",
            name: "\u540D\u524D",
            address: "\u30BF\u30FC\u30B2\u30C3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            netmask: "\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF",
            gateway: "\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4",
            descriptions: {
              name: "\u3053\u306E\u30EB\u30FC\u30C8\u306E\u56FA\u6709\u540D\u3002",
              address: "\u3053\u306E\u30EB\u30FC\u30C8\u306E\u30BF\u30FC\u30B2\u30C3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30A2\u30C9\u30EC\u30B9\u3002",
              netmask: "\u30BF\u30FC\u30B2\u30C3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E CIDR\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF \u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u3002",
              gateway: "\u3053\u306E\u30EB\u30FC\u30C8\u306B\u4F7F\u7528\u3059\u308B\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4\u3002"
            }
          },
          dhcp: {
            tabTitle: "DHCP",
            networksSectionTitle: "DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            leasesSectionTitle: "DHCP\u30EA\u30FC\u30B9",
            reservationsSectionTitle: "DHCP\u4E88\u7D04",
            dhcpEnable: "DHCP Service\u3092\u6709\u52B9\u306B\u3059\u308B",
            addNetworkTitle: "\u65B0\u898F DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u8FFD\u52A0",
            addNetworkButtonText: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u8FFD\u52A0",
            editNetworkTitle: "DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u7DE8\u96C6",
            deleteNetworkTitle: "DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u524A\u9664",
            deleteNetworkMessage: "\u3053\u306E DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u524A\u9664\u3057\u3066\u3082\u304B\u307E\u3044\u307E\u305B\u3093\u304B\u3002\u524A\u9664\u306F\u4FDD\u5B58\u6642\u306B\u6052\u4E45\u7684\u306B\u53CD\u6620\u3055\u308C\u307E\u3059\u3002",
            networks: {
              networkSectionTitle: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
              basicSectionTitle: "\u57FA\u672C",
              basicTabTitle: "\u57FA\u672C",
              advancedSectionTitle: "\u8A73\u7D30\u8A2D\u5B9A",
              advancedTabTitle: "\u8A73\u7D30\u8A2D\u5B9A",
              enable: "DHCP\u7BC4\u56F2\u304C\u6709\u52B9",
              port: "LAN \u540D",
              description: "\u5185\u5BB9",
              firstIp: "\u5148\u982D\u306E IP\u30A2\u30C9\u30EC\u30B9",
              lastIp: "\u6700\u5F8C\u306E IP\u30A2\u30C9\u30EC\u30B9",
              domain: "\u30C9\u30E1\u30A4\u30F3\u540D",
              dnsServers: "DNS\u30B5\u30FC\u30D0\u30FC",
              bootFileName: "Boot\u30D5\u30A1\u30A4\u30EB\u540D",
              defaultLeaseTime: "\u65E2\u5B9A\u30EA\u30FC\u30B9\u6642\u9593",
              maxLeaseTime: "\u6700\u5927\u30EA\u30FC\u30B9\u6642\u9593",
              winsServers: "WINS\u30B5\u30FC\u30D0\u30FC",
              tftpHostname: "TFTP \u30DB\u30B9\u30C8\u540D",
              tftpIpAddress: "TFTP IP\u30A2\u30C9\u30EC\u30B9",
              vendorOptions: "\u30D9\u30F3\u30C0\u30FC\u30AA\u30D7\u30B7\u30E7\u30F3",
              validateMaxLeaseTime: "\u65E2\u5B9A\u30EA\u30FC\u30B9\u6642\u9593\u3088\u308A\u77ED\u304F\u3059\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093"
            },
            blockUnknownMac: "\u65E2\u77E5\u306E MAC\u30A2\u30C9\u30EC\u30B9\u306E\u307F\u8A8D\u3081\u308B",
            addReservationTitle: "\u65B0\u898F DHCP\u4E88\u7D04\u3092\u8FFD\u52A0",
            addReservationButtonText: "\u4E88\u7D04\u3092\u8FFD\u52A0",
            editReservationTitle: "DHCP\u4E88\u7D04\u3092\u7DE8\u96C6",
            deleteReservationTitle: "DHCP\u4E88\u7D04\u3092\u524A\u9664",
            deleteReservationMessage: "\u3053\u306EDHCP\u4E88\u7D04\u3092\u524A\u9664\u3057\u3066\u3082\u304B\u307E\u3044\u307E\u305B\u3093\u304B\u3002\u524A\u9664\u306F\u4FDD\u5B58\u6642\u306B\u6052\u4E45\u7684\u306B\u53CD\u6620\u3055\u308C\u307E\u3059\u3002",
            leases: {
              reservationSectionTitle: "\u30EA\u30FC\u30B9",
              port: "LAN \u540D",
              ip: "IP\u30A2\u30C9\u30EC\u30B9",
              mac: "MAC \u30A2\u30C9\u30EC\u30B9",
              reserve: "\u4E88\u7D04IP",
              noLeases: "\u73FE\u5728\u6709\u52B9\u306A\u30EA\u30FC\u30B9\u306F\u3042\u308A\u307E\u305B\u3093\u3002\u65B0\u898F\u30EA\u30FC\u30B9\u304C\u8868\u793A\u3055\u308C\u308B\u307E\u3067\u6700\u5927 2 \u5206\u304B\u304B\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002"
            },
            reservations: {
              reservationSectionTitle: "\u4E88\u7D04",
              name: "\u540D\u524D",
              description: "\u5185\u5BB9",
              port: "LAN \u540D",
              ip: "IP\u30A2\u30C9\u30EC\u30B9",
              mac: "MAC \u30A2\u30C9\u30EC\u30B9",
              clientId: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8ID",
              hostname: "\u30DB\u30B9\u30C8\u540D",
              validateMac: "\u6709\u52B9\u306AMAC\u30A2\u30C9\u30EC\u30B9\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093",
              validateHostname: "\u82F1\u6570\u5B57\u3001\u300C-\u300D\u307E\u305F\u306F\u300C_\u300D\u306E\u6587\u5B57\u306E\u307F\u542B\u3081\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059",
              duplicate: "LAN\u5185\u3067\u56FA\u6709\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093",
              duplicateName: "\u56FA\u6709\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093"
            },
            descriptions: {
              dhcpEnable: "\u3053\u306E\u30B5\u30A4\u30C8\u306E DHCP Service\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u3092\u6709\u52B9\u306B\u3057\u307E\u3059\u3002",
              blockUnknownMac: "\u6709\u52B9\u306B\u3059\u308B\u3068\u3001\u4E0B\u306E\u8868\u306B\u8A18\u8F09\u3055\u308C\u3066\u3044\u308B MAC\u30A2\u30C9\u30EC\u30B9\u306E\u307F\u304C DHCP Service\u3092\u4F7F\u7528\u3067\u304D\u308B\u3088\u3046\u306B\u306A\u308A\u307E\u3059\u3002",
              networks: {
                enable: "\u3053\u306E\u30EA\u30F3\u30AF\u7528\u306B\u88C5\u7F6E\u3067 DHCP \u30B5\u30FC\u30D0\u30FC\u3092\u6709\u52B9\u306B\u3057\u307E\u3059\u3002",
                description: "\u3053\u306EDHCP \u30B5\u30FC\u30D0\u30FC\u8A2D\u5B9A\u306B\u30AA\u30D7\u30B7\u30E7\u30F3\u306E\u8A73\u7D30\u3092\u8A18\u5165\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
                port: "\u3053\u306E DHCP\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u8A2D\u5B9A\u306B\u4F7F\u7528\u3059\u308BLAN \u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3002",
                firstIp: "DHCP\u5272\u308A\u5F53\u3066\u7528\u306B\u6700\u521D\u306B\u5229\u7528\u53EF\u80FD\u306AIP\u3002\u3053\u308C\u306F\u30DD\u30FC\u30C8\u306E IP \u3068\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF\u3067\u5B9A\u7FA9\u3055\u308C\u305F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u5185\u306B\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
                lastIp: "DHCP \u5272\u308A\u5F53\u3066\u306B\u6700\u5F8C\u306B\u5229\u7528\u53EF\u80FD\u306A IP\u3002\u3053\u308C\u306F\u30DD\u30FC\u30C8\u306E IP \u3068\u30CD\u30C3\u30C8\u30DE\u30B9\u30AF\u3067\u5B9A\u7FA9\u3055\u308C\u305F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u5185\u306B\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093\u3002",
                domain: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u3001DHCP \u30B5\u30FC\u30D0\u30FC\u306B\u5272\u308A\u5F53\u3066\u308B\u30C9\u30E1\u30A4\u30F3\u540D\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u3002",
                dnsServers: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u3001\u4F7F\u7528\u3059\u308B DNS\u30B5\u30FC\u30D0\u30FC\u30EA\u30B9\u30C8\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u3002",
                bootFileName: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u3001\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u304C\u8AAD\u307F\u8FBC\u3080\u3079\u304D\u521D\u671Fboot\u30D5\u30A1\u30A4\u30EB\u540D\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u3002\u3053\u308C\u306F\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u304C\u30D5\u30A1\u30A4\u30EB\u3092\u8AAD\u307F\u8FBC\u3080\u306E\u306B\u4F7F\u7528\u3059\u308B\u3068\u898B\u8FBC\u307E\u308C\u308B\u30D5\u30A1\u30A4\u30EB\u8EE2\u9001\u30D7\u30ED\u30C8\u30B3\u30EB\u3092\u554F\u308F\u305A\u8A8D\u8B58\u53EF\u80FD\u3067\u3042\u308B\u3079\u304D\u3067\u3059\u3002",
                defaultLeaseTime: "\u30EA\u30FC\u30B9\u306B\u5272\u308A\u5F53\u3066\u308B\u65E2\u5B9A\u79D2\u6570\u3002",
                maxLeaseTime: "\u30EA\u30FC\u30B9\u306B\u5272\u308A\u5F53\u3066\u308B\u6700\u5927\u79D2\u6570\u3002",
                winsServers: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u30011\u3064\u307E\u305F\u306F\u8907\u6570\u306E WINS\u30B5\u30FC\u30D0\u30FC IP\u30A2\u30C9\u30EC\u30B9\u3092\u8FFD\u52A0\u3057\u307E\u3059\u3002",
                tftpHostname: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u3001TFTP\u30B5\u30FC\u30D0\u30FC\u306B\u30DB\u30B9\u30C8\u540D\u3092\u4ED8\u3051\u307E\u3059\u3002",
                tftpIpAddress: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u3001TFTP\u30B5\u30FC\u30D0\u30FC\u306E IP\u30A2\u30C9\u30EC\u30B9\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u3002",
                vendorOptions: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u30011\u3064\u307E\u305F\u306F\u8907\u6570\u306E\u300C;\u300D\uFF08\u30BB\u30DF\u30B3\u30ED\u30F3\uFF09\u533A\u5207\u308A\u3067\u30D9\u30F3\u30C0\u30FC\u30AA\u30D7\u30B7\u30E7\u30F3\u3092\u8A2D\u5B9A\u3057\u307E\u3059\u3002"
              },
              reservations: {
                name: "\u3053\u306E\u4E88\u7D04\u3092\u8B58\u5225\u3059\u308B\u305F\u3081\u306B\u4F7F\u7528\u3059\u308B\u540D\u524D\u3002",
                description: "\u3053\u306E\u4E88\u7D04\u306B\u95A2\u3059\u308B\u4EFB\u610F\u306E\u8A73\u7D30\u3002",
                port: "\u3053\u306E\u4E88\u7D04\u3092\u884C\u3046\u305F\u3081\u306E LAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3002",
                ip: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u7528\u306B\u4E88\u7D04\u3059\u308BIP\u3002",
                mac: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u306E\u8B58\u5225\u7528 MAC\u30A2\u30C9\u30EC\u30B9\u3002",
                clientId: "IP\u30A2\u30C9\u30EC\u30B9\u3092\u8981\u8ACB\u3059\u308B\u969B\u306B\u30DB\u30B9\u30C8\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u3092\u8B58\u5225\u3059\u308B\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u8B58\u5225\u5B50\u3002",
                hostname: "\u3053\u306E IP\u306B\u5272\u308A\u5F53\u3066\u308B\u30DB\u30B9\u30C8\u540D\u3002"
              }
            }
          },
          advanced: {
            tabTitle: "\u8A73\u7D30\u8A2D\u5B9A",
            primaryDns: "\u30D7\u30E9\u30A4\u30DE\u30EA DNS",
            secondaryDns: "\u30BB\u30AB\u30F3\u30C0\u30EA DNS",
            rootPassword: "Root\u306E\u30D1\u30B9\u30EF\u30FC\u30C9",
            rootPasswordConfirmation: "Root\u306E\u30D1\u30B9\u30EF\u30FC\u30C9\u78BA\u8A8D\u5165\u529B",
            updateWindowTZ: "\u66F4\u65B0\u6642\u9593\u67A0\u306E\u6642\u9593\u5E2F",
            disableOMS: "Log\u5206\u6790\u3092\u7121\u52B9\u306B\u3059\u308B",
            disableSecurityPolicy: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u3092\u7121\u52B9\u306B\u3059\u308B",
            disableIpsPolicy: "IPS\u3092\u7121\u52B9\u306B\u3059\u308B",
            omsNote: "\u3053\u308C\u306B\u3088\u308A\u65E2\u5B9A\u306ELog\u5206\u6790\u8A2D\u5B9A\u306F\u30AA\u30FC\u30D0\u30FC\u30E9\u30A4\u30C9\u3055\u308C\u307E\u3059\u3002",
            descriptions: {
              updateWindowTZ: "\u3053\u306E\u30B5\u30A4\u30C8\u306E\u66F4\u65B0\u6642\u9593\u67A0\u3092\u6C7A\u5B9A\u3059\u308B\u305F\u3081\u306B\u4F7F\u7528\u3055\u308C\u308B\u30B5\u30A4\u30C8\u306E\u6642\u9593\u5E2F\u3002",
              primaryDns: "\u30D7\u30E9\u30A4\u30DE\u30EADNS\u30A2\u30C9\u30EC\u30B9\u3092\u624B\u52D5\u3067\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              secondaryDns: "\u30BB\u30AB\u30F3\u30C0\u30EADNS\u30A2\u30C9\u30EC\u30B9\u3092\u624B\u52D5\u3067\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              rootPassword: "\u30B5\u30A4\u30C8\u306E root\u30E6\u30FC\u30B6\u30FC\u30D1\u30B9\u30EF\u30FC\u30C9\u3002",
              disableOMS: "\u3053\u306E\u30B5\u30A4\u30C8\u306E Azure Log Analytics \u3078\u306ELog\u306E\u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0\u3092\u7121\u52B9\u306B\u3057\u307E\u3059\u3002",
              disableSecurityPolicy: "\u3053\u306E\u30B5\u30A4\u30C8\u306E\u3059\u3079\u3066\u306E\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30DD\u30EA\u30B7\u30FC\u3092\u7121\u52B9\u306B\u3059\u308B\u3002",
              disableIpsPolicy: "\u3053\u306E\u30B5\u30A4\u30C8\u306E IPS\u30DD\u30EA\u30B7\u30FC\u3092\u7121\u52B9\u306B\u3059\u308B\u3002"
            },
            downloadBootstrapConfigDialog: {
              button: "\u30DC\u30BF\u30F3",
              buttons: "\u30DC\u30BF\u30F3",
              buttonLabel: "Bootstrap\u8A2D\u5B9A",
              title: "Bootstrap\u8A2D\u5B9A\u306E\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9",
              dialogMessage: '\u6A5F\u5668\u306FDHCP\u5BFE\u5FDC\u306E\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u63A5\u7D9A\u3055\u308C\u3066\u3044\u308B\u9650\u308A\u81EA\u52D5\u7684\u306B SecureEdge\u306B\u63A5\u7D9A\u3057\u3001\u6A5F\u5668\u8A2D\u5B9A\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u3057\u307E\u3059\u3002\n\nIf an internet connection cannot be easily provided, you can configure the appliance using the "Barracuda Firewall Install" tool and a USB flash drive. See <0>here</0> for documentation on this tool.\n\nUse the {{button}} below to download the configuration files. These files should be provided as "CGW Configurations" during the Barracuda Firewall Install setup.',
              dialogMessageSc: "\u6A5F\u5668\u306FDHCP\u5BFE\u5FDC\u306E\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u63A5\u7D9A\u306B\u63A5\u7D9A\u3055\u308C\u3066\u3044\u308B\u9650\u308A\u81EA\u52D5\u7684\u306B SecureEdge\u306B\u63A5\u7D9A\u3057\u3001\u6A5F\u5668\u8A2D\u5B9A\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u3057\u307E\u3059\u3002\n\nUse the {{button}} below to download the configuration file.\nThis file should be copied to the root directory of a USB Flash drive.\nIf the USB storage is present during boot the configuration will be applied.",
              downloadButtonLabel: "\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9",
              serial: "\u30B7\u30EA\u30A2\u30EB {{serial}}"
            }
          },
          confirm: "\u9078\u629E\u3055\u308C\u305F\u30DD\u30FC\u30C8\u306E\u4E00\u90E8\u306F\u3053\u306E\u30B5\u30A4\u30C8\u3067\u306F\u73FE\u5728\u6709\u52B9\u306B\u306A\u3063\u3066\u3044\u307E\u305B\u3093\u3001\u6B21\u306B\u9032\u3093\u3067\u3082\u3088\u308D\u3057\u3044\u3067\u3059\u304B"
        },
        table: {
          name: "\u540D\u524D",
          description: "\u5185\u5BB9",
          serial: "\u30B7\u30EA\u30A2\u30EB",
          model: "\u30E2\u30C7\u30EB",
          gateway: "Edge Service",
          cloudVirtualWan: "Cloud VWAN",
          privateCloud: "\u306A\u3057",
          status: "\u63A5\u7D9A\u30B9\u30C6\u30FC\u30BF\u30B9",
          peeringAddress: "\u30D4\u30A2\u30EA\u30F3\u30B0\u30A2\u30C9\u30EC\u30B9",
          asn: "AS \u756A\u53F7",
          lan: "LAN",
          wan: "WAN",
          linkAddresses: "\u30A2\u30C9\u30EC\u30B9",
          noSites: "\u30B5\u30A4\u30C8\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002",
          noIot: "IoT \u88C5\u7F6E\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002",
          dynamic: "\u52D5\u7684",
          wwan: "WWAN",
          vpnSite: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u306E VPN \u8A2D\u5B9A\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          vpnSiteDisabled: "\u3053\u306E\u64CD\u4F5C\u3092\u5B9F\u884C\u3059\u308B\u306B\u306F\u3001\u30E6\u30FC\u30B6\u30FC\u63A5\u7D9A\u6027\u3092\u6709\u52B9\u306B\u3057\u3001\u30E6\u30FC\u30B6\u30FC\u63A5\u7D9A\u306B\u56FA\u5B9A WAN \u3092\u5272\u308A\u5F53\u3066\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
          noGateway: "(\u306A\u3057)",
          licenseType: "\u30E9\u30A4\u30BB\u30F3\u30B9\u30BF\u30A4\u30D7",
          licenseExpiration: "\u30E9\u30A4\u30BB\u30F3\u30B9\u6709\u52B9\u671F\u9650",
          bridge: "Bridge"
        },
        siteNotFound: "\u304A\u63A2\u3057\u306E\u30B5\u30A4\u30C8\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002",
        noData: {
          iconHeader: "\u30B5\u30A4\u30C8\u3092\u8FFD\u52A0\u3057\u3066\u304B\u3089\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
          textHeader: "\u30B5\u30A4\u30C8\u3068\u306F\u4F55\u304B",
          text: "\u30B5\u30A4\u30C8\u3068\u306F\u3001\u30B5\u30A4\u30C8\u88C5\u7F6E\u306B\u3088\u3063\u3066\u63A5\u7D9A\u3055\u308C\u305F (\u304A\u3088\u3073\u4FDD\u8B77\u3055\u308C\u305F)\u5834\u6240\u3092\u6307\u3057\u307E\u3059\u3002<0 />A site device can be deployed as a hardware or virtual appliance - whatever best meets your needs.<0 />Adding a site device to your network setup is a simple and straight-forward process.<0 /><0 />Don\u2019t have a site yet?",
          demoLicenseLink: "<0>\u8A66\u7528\u30E9\u30A4\u30BB\u30F3\u30B9\u3092GET</0>"
        }
      },
      gateways: {
        menuTitle: "EDGE SERVICES",
        pageTitle: "Edge Services",
        noData: {
          iconHeader: "Edge Service\u3092\u3069\u308C\u304B\u8FFD\u52A0\u3057\u3066\u304B\u3089\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
          textHeader: "Edge Service\u3068\u306F\u4F55\u304B",
          text: "Edge Service\u306F\u5FA1\u793E\u306E\u30B5\u30A4\u30C8\u3068\u30EA\u30E2\u30FC\u30C8\u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u306E\u305F\u3081\u306E\u63A5\u7D9A\u70B9\u3068\u3057\u3066\u6A5F\u80FD\u3057\u307E\u3059\u3002<0 />\u4E21\u65B9\u306E\u30B5\u30A4\u30C8\u3068\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u306F\u6700\u9069\u306Aservice\u54C1\u8CEA\u306E\u305F\u3081\u306B\u6700\u3082\u8FD1\u3044Edge Service\u3092\u81EA\u52D5\u7684\u306B\u53D6\u5F97\u3057\u307E\u3059\u3002"
        },
        table: {
          asn: "AS \u756A\u53F7",
          name: "\u540D\u524D",
          type: "\u30BF\u30A4\u30D7",
          upstream: "\u30A2\u30C3\u30D7\u30EA\u30F3\u30AF",
          region: "\u5834\u6240",
          instances: "\u30A4\u30F3\u30B9\u30BF\u30F3\u30B9",
          size: "\u30B5\u30A4\u30BA",
          HA: "(HA)",
          upstream_gateway: "\u30A2\u30C3\u30D7\u30EA\u30F3\u30AF Edge Service",
          scaleUnit: "\u30B9\u30B1\u30FC\u30EB\u30E6\u30CB\u30C3\u30C8",
          sites: "\u30B5\u30A4\u30C8",
          status: "\u63A5\u7D9A\u30B9\u30C6\u30FC\u30BF\u30B9",
          vpnGateway: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u306E VPN \u8A2D\u5B9A\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          vpnGatewayDisabled: "\u3053\u306E\u64CD\u4F5C\u3092\u5B9F\u884C\u3059\u308B\u306B\u306F\u3001\u30E6\u30FC\u30B6\u30FC\u63A5\u7D9A\u6027\u3092\u6709\u52B9\u306B\u3059\u308B\u5FC5\u8981\u304C\u3042\u308A\u307E\u3059\u3002",
          privateCloud: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service",
          managedCloud: "Edge Service",
          noGateway: "(\u306A\u3057)",
          mbit: "MBit",
          filters: {
            sizeSelector: "\u30B5\u30A4\u30BA\u30BF\u30A4\u30D7",
            scaleUnit: "\u30B9\u30B1\u30FC\u30EB\u30E6\u30CB\u30C3\u30C8",
            model: "\u30E2\u30C7\u30EB",
            bandwidth: "\u5E2F\u57DF\u5E45"
          }
        },
        dashboard: {
          locationsCardTitle: "\u30DE\u30C3\u30D7",
          tabTitle: "Dashboard",
          connectedSites: {
            tabTitle: "\u63A5\u7D9A\u3055\u308C\u305F\u30B5\u30A4\u30C8"
          },
          firmware: {
            tabTitle: "\u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2",
            version: "\u30D0\u30FC\u30B8\u30E7\u30F3",
            hotfixes: "\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u6E08\u307F\u30DB\u30C3\u30C8\u30D5\u30A3\u30C3\u30AF\u30B9",
            window: "\u6B21\u306E\u66F4\u65B0",
            nextUpdate: "{{from}}\u3068{{to}}\u306E\u9593"
          }
        },
        liveLog: {
          tabTitle: "\u30E9\u30A4\u30D6\u63A5\u7D9A",
          pageTitle: "\u30E9\u30A4\u30D6\u63A5\u7D9A",
          waitingForConnections: "Edge Service\u306B\u63A5\u7D9A\u3057\u3066\u3044\u307E\u3059\u3002\u63A5\u7D9A\u304C\u78BA\u7ACB\u3055\u308C\u308B\u307E\u3067\u6700\u5927 2 \u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002\u304A\u5F85\u3061\u304F\u3060\u3055\u3044...",
          emptyData: "\u73FE\u5728\u30A2\u30AF\u30C6\u30A3\u30D6\u306A\u30E9\u30A4\u30D6\u63A5\u7D9A\u306F\u3042\u308A\u307E\u305B\u3093...",
          pauseRequestsButton: "\u4E00\u6642\u505C\u6B62",
          resumeRequestsButton: "\u518D\u958B"
        },
        connectionHistory: {
          pageTitle: "\u6700\u8FD1\u306E\u63A5\u7D9A",
          tabTitle: "\u6700\u8FD1\u306E\u63A5\u7D9A",
          waitingForConnections: "Edge Service\u306B\u63A5\u7D9A\u3057\u3066\u3044\u307E\u3059\u3002\u63A5\u7D9A\u304C\u78BA\u7ACB\u3055\u308C\u308B\u307E\u3067\u6700\u5927 2 \u5206\u3092\u8981\u3059\u308B\u3053\u3068\u3082\u3042\u308A\u307E\u3059\u3002\u304A\u5F85\u3061\u304F\u3060\u3055\u3044...",
          emptyData: "\u6700\u8FD1\u306E\u63A5\u7D9A\u306F\u3042\u308A\u307E\u305B\u3093...",
          pauseRequestsButton: "\u4E00\u6642\u505C\u6B62",
          resumeRequestsButton: "\u518D\u958B"
        },
        delete: {
          body: "\u3053\u306EEdge Service\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B",
          title: "Edge Service {{name}}\u3092\u524A\u9664",
          forbidden: "\u3053\u306EEdge Service\u3092\u524A\u9664\u3059\u308B\u306B\u306F\u3001\u63A5\u7D9A\u3055\u308C\u3066\u3044\u308B\u3059\u3079\u3066\u306EEdge Service\u3068\u30B5\u30A4\u30C8\u3092\u6700\u521D\u306B\u524A\u9664\u3057\u3066\u304F\u3060\u3055\u3044\u3002"
        },
        dialog: {
          basic: {
            name: "\u540D\u524D",
            sectionTitle: "\u57FA\u672C\u60C5\u5831",
            tabTitle: "\u57FA\u672C"
          },
          create: {
            buttonText: "\u65B0\u898FEdge Service",
            cloud: {
              menuLabel: "\u4EEE\u60F3WAN\u7528Edge Service",
              dialogTitle: "Edge Service \u30C8\u30FC\u30AF\u30F3\u3092\u751F\u6210"
            },
            onPrem: {
              menuLabel: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service",
              dialogTitle: "\u65B0\u898F\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service",
              serials: {
                sectionTitle: "\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service\u5BFE\u8C61\u306E\u6A5F\u5668"
              }
            },
            managed: {
              menuLabel: "Edge Service",
              dialogTitle: "\u65B0\u898FEdge Service",
              name: "\u540D\u524D",
              region: "\u5730\u57DF",
              bandwidth: "\u5E2F\u57DF\u5E45",
              bandwidthAvailable: "{{availableBandwidth}} Mbps\u304C\u5229\u7528\u53EF\u80FD",
              invalidBandwidth: "\u9078\u629E\u53EF\u80FD\u306A\u6700\u5927\u5E2F\u57DF\u5E45\uFF1A{{availableBandwidth}} \u30E1\u30AC\u30D3\u30C3\u30C8\u3002\u5C0F\u3055\u3044\u5E2F\u57DF\u5E45\u3092\u9078\u629E\u3059\u308B\u304BEdgeService\u3092\u4F5C\u6210\u3059\u308B\u305F\u3081\u306E\u8FFD\u52A0\u5BB9\u91CF\u3092\u8CFC\u5165\u3057\u3066\u304F\u3060\u3055\u3044\u3002"
            }
          },
          edit: {
            dialogTitle: "Edge Service\u306E\u7DE8\u96C6"
          },
          result: {
            managedAppText: "\u30DE\u30CD\u30FC\u30B8\u30C9\u30A2\u30D7\u30EA\u306E OTP",
            message: "\u6B21\u306E\u30C8\u30FC\u30AF\u30F3\u3092\u30B3\u30D4\u30FC\u3057\u3066<0>\u4EEE\u60F3WAN\u7528Edge Service</0>\u306E\u914D\u5099\u306B\u8CBC\u308A\u4ED8\u3051\u3066\u304F\u3060\u3055\u3044\u3002",
            managedAppOtp: "\u3053\u306E\u30C8\u30FC\u30AF\u30F3\u306F\u4E00\u56DE\u914D\u5099\u306E\u307F\u306E\u305F\u3081\u306B\u6709\u52B9\u3067 30\u5206\u5F8C\u306B\u7121\u52B9\u306B\u306A\u308A\u307E\u3059\u3002"
          },
          steps: {
            result: "\u5B8C\u4E86",
            submit: "\u8A2D\u5B9A"
          },
          validation: {
            duplicateName: "\u91CD\u8907\u3059\u308BEdge Service\u540D\u3067\u3042\u3063\u3066\u306F\u306A\u308A\u307E\u305B\u3093"
          }
        },
        settings: {
          tabTitle: "\u8A2D\u5B9A",
          labels: {
            secureInternetTraffic: "VNET\u304B\u3089\u306E\u30BB\u30AD\u30E5\u30A2\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF"
          },
          descriptions: {
            secureInternetTraffic: "\u3053\u306EEdge Service\u306B\u3088\u308B\u3053\u306E\u4EEE\u60F3 \u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3067\u306E\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306E\u691C\u67FB\u3092\u6709\u52B9\u306B\u3057\u307E\u3059\u3002"
          },
          editBandwidthWarning: "\u5E2F\u57DF\u5E45\u3092\u5909\u66F4\u3059\u308B\u3068 Edge Service\u304C\u518D\u8D77\u52D5\u3059\u308B\u5FC5\u8981\u304C\u3042\u308B\u305F\u3081\u4E00\u6642\u5229\u7528\u3067\u304D\u306A\u304F\u306A\u308A\u307E\u3059\u3002",
          managed: {
            bandwidth: {
              invalidBandwidth: "\u9078\u629E\u53EF\u80FD\u306A\u6700\u5927\u5E2F\u57DF\u5E45\uFF1A{{availableBandwidth}} \u30E1\u30AC\u30D3\u30C3\u30C8\u3002\u9078\u629E\u3059\u308B\u5E2F\u57DF\u5E45\u3092\u6E1B\u3089\u3059\u304B\u8FFD\u52A0\u5BB9\u91CF\u3092\u3054\u8CFC\u5165\u3044\u305F\u3060\u304D\u3053\u306EEdgeService\u3092\u66F4\u65B0\u3057\u3066\u304F\u3060\u3055\u3044\u3002"
            }
          }
        },
        auditLog: {
          tabTitle: "\u76E3\u67FBLog",
          pageTitle: "\u76E3\u67FBLog"
        },
        gatewayNotFound: "\u691C\u7D22\u3057\u3066\u3044\u308BEdge Service\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002"
      },
      logs: {
        pageTitle: "Log\u3068\u30EC\u30DD\u30FC\u30C8",
        audit: "\u76E3\u67FBLog",
        notifications: "\u901A\u77E5",
        notificationsMessage: "\u3059\u3079\u3066\u306E\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u3067\u7BA1\u7406\u30A4\u30D9\u30F3\u30C8\u3068\u30B7\u30B9\u30C6\u30E0 \u30A4\u30D9\u30F3\u30C8\u306E\u901A\u77E5\u3092\u69CB\u6210\u3057\u307E\u3059\u3002",
        auditLog: {
          message: "\u3059\u3079\u3066\u306E\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u306E\u7BA1\u7406\u304A\u3088\u3073\u30B7\u30B9\u30C6\u30E0 \u30A4\u30D9\u30F3\u30C8\u3092\u793A\u3059\u76E3\u67FBLog",
          labels: {
            status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
            created: "\u4F5C\u6210\u8005",
            type: "\u30BF\u30A4\u30D7",
            event: "\u540D\u524D",
            serial: "\u30B7\u30EA\u30A2\u30EB",
            description: "\u5185\u5BB9",
            remoteAddress: "\u30D1\u30D6\u30EA\u30C3\u30AFIP",
            createdUsername: "\u7BA1\u7406\u8005"
          }
        },
        connectionHistory: {
          detailsTitle: "\u63A5\u7D9A\u306E\u8A73\u7D30",
          labels: {
            application: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
            count: "\u30AB\u30A6\u30F3\u30C8",
            destinationIP: "\u9001\u308A\u5148 IP",
            destinationPort: "\u9001\u308A\u5148\u30DD\u30FC\u30C8",
            information: "\u60C5\u5831",
            ipProtocol: "IP \u30D7\u30ED\u30C8\u30B3\u30EB",
            lastTime: "\u524D\u56DE",
            nextHop: "NextHop",
            sourceIP: "\u30BD\u30FC\u30B9IP",
            sourceMac: "\u30BD\u30FC\u30B9MAC",
            state: "\u90FD\u9053\u5E9C\u770C",
            urlCategory: "URL\u30AB\u30C6\u30B4\u30EA\u30FC",
            user: "\u30E6\u30FC\u30B6\u30FC"
          }
        },
        liveLog: {
          detailsTitle: "\u63A5\u7D9A\u306E\u8A73\u7D30",
          labels: {
            agentId: "\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8ID",
            appName: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u30B3\u30F3\u30C6\u30AD\u30B9\u30C8",
            application: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
            connectionTrafficState: "\u63A5\u7D9A\u72B6\u614B",
            destinationBytesDescription: "\u9001\u4FE1\u30D0\u30A4\u30C8\u6570",
            destinationIp: "\u9001\u308A\u5148 IP",
            destinationInterface: "\u9001\u308A\u5148\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9",
            destinationPort: "\u9001\u308A\u5148\u30DD\u30FC\u30C8",
            idleTime: "\u30A2\u30A4\u30C9\u30EB\u30BF\u30A4\u30E0",
            ipProtocol: "IP \u30D7\u30ED\u30C8\u30B3\u30EB",
            protocol: "\u30D7\u30ED\u30C8\u30B3\u30EB",
            protocolId: "\u30D7\u30ED\u30C8\u30B3\u30EBID",
            serial: "\u30B7\u30EA\u30A2\u30EB",
            sourceBytesDescription: "\u7740\u4FE1\u30D0\u30A4\u30C8\u6570",
            sourceIp: "\u30BD\u30FC\u30B9IP",
            sourcePort: "\u30BD\u30FC\u30B9\u30DD\u30FC\u30C8",
            sourceNat: "\u30BD\u30FC\u30B9NAT",
            speedDescription: "\u30B9\u30D4\u30FC\u30C9",
            startTime: "\u958B\u59CB\u6642\u523B",
            state: "\u90FD\u9053\u5E9C\u770C",
            target: "\u30BF\u30FC\u30B2\u30C3\u30C8",
            type: "\u30BF\u30A4\u30D7",
            totalBytesDescription: "\u5408\u8A08\u30D0\u30A4\u30C8\u6570",
            urlCategories: "URL\u30AB\u30C6\u30B4\u30EA\u30FC",
            user: "\u30E6\u30FC\u30B6\u30FC",
            vpnRoute: "VPN \u30EB\u30FC\u30C8"
          },
          connectionState: {
            initiate: "\u534A\u4E8C\u91CD\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u6709\u52B9",
            fail: "\u534A\u4E8C\u91CD\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u7121\u52B9",
            sync: "\u5168\u4E8C\u91CD\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF",
            closing: "\u63A5\u7D9A\u3092\u7D42\u4E86\u3057\u3066\u3044\u307E\u3059",
            established: "\u63A5\u7D9A\u304C\u78BA\u7ACB\u3055\u308C\u307E\u3057\u305F",
            httpDeny: "\u63A5\u7D9A\u306F\u78BA\u7ACB\u3055\u308C\u307E\u305B\u3093\u3067\u3057\u305F"
          }
        }
      },
      policies: {
        menuTitle: "SD-WAN",
        sdwan: {
          tabTitle: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u30AB\u30C6\u30B4\u30EA\u30FC",
          search: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306E\u691C\u7D22",
          table: {
            category: "\u30AB\u30C6\u30B4\u30EA\u30FC",
            apps: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
            customApps: "\u30AB\u30B9\u30BF\u30E0 \u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
            priority: "\u512A\u5148\u5EA6",
            action: "\u64CD\u4F5C",
            fallback: "\u30D5\u30A9\u30FC\u30EB\u30D0\u30C3\u30AF",
            loadBalancing: "Load Balancing",
            reset: "\u30EA\u30BB\u30C3\u30C8"
          }
        },
        explicit: {
          delete: {
            title: "{{name}}\u3092\u524A\u9664",
            body: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B"
          },
          tabTitle: "\u30AA\u30FC\u30D0\u30FC\u30E9\u30A4\u30C9\u30AB\u30C6\u30B4\u30EA\u30FC",
          table: {
            order: "\u9806\u756A",
            name: "\u540D\u524D",
            apps: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
            sources: "\u30BD\u30FC\u30B9",
            user: "\u30E6\u30FC\u30B6\u30FC",
            traffic: "\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u30BF\u30A4\u30D7",
            priority: "\u512A\u5148\u5EA6",
            action: "\u64CD\u4F5C",
            fallback: "\u30D5\u30A9\u30FC\u30EB\u30D0\u30C3\u30AF",
            loadBalancing: "Load Balancing"
          },
          noData: {
            iconHeader: "SD-WAN\u30DD\u30EA\u30B7\u30FC\u306E\u30AA\u30FC\u30D0\u30FC\u30E9\u30A4\u30C9\u3092\u3053\u3053\u306B\u8FFD\u52A0\u3057\u3066\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
            labelButton: "\u30AA\u30FC\u30D0\u30FC\u30E9\u30A4\u30C9\u30DD\u30EA\u30B7\u30FC\u3092\u8FFD\u52A0",
            text: "SecureEdge\u306E\u69CB\u6210\u306F\u3059\u3050\u306B\u4F7F\u3048\u308BSD-WAN\u30DD\u30EA\u30B7\u30FC\u306E\u5171\u901A\u30BB\u30C3\u30C8\u3092\u63D0\u4F9B\u3057\u307E\u3059\u3002<0 />But if your setup requires a different SD-WAN profile for certain applications, you can define your custom policies here."
          }
        },
        dialogs: {
          explicit: {
            create: {
              buttonText: "\u30DD\u30EA\u30B7\u30FC\u3092\u8FFD\u52A0",
              dialogTitle: "\u65B0\u898F\u30DD\u30EA\u30B7\u30FC"
            },
            edit: {
              dialogTitle: "\u30DD\u30EA\u30B7\u30FC\u306E\u7DE8\u96C6"
            },
            general: {
              sectionTitle: "\u5168\u822C",
              name: "\u540D\u524D",
              description: "\u5185\u5BB9",
              descriptions: {
                name: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306E\u56FA\u6709\u540D\u3002",
                description: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306E\u6982\u7565\u3002"
              }
            },
            criteria: {
              sectionTitle: "\u5224\u65AD\u57FA\u6E96",
              applications: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
              sources: "\u30BD\u30FC\u30B9IP/\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
              trafficType: "\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u30BF\u30A4\u30D7",
              descriptions: {
                applications: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u3092\u9069\u7528\u3059\u308B\u7279\u5B9A\u306E\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3002\u5C11\u306A\u304F\u3068\u30821\u3064\u306E\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u307E\u305F\u306F\u30BD\u30FC\u30B9\u304C\u63D0\u4F9B\u3055\u308C\u3066\u3044\u308B\u3053\u3068\u304C\u524D\u63D0\u3067\u3059\u3002",
                sources: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u3092\u9069\u7528\u3059\u308B\u7279\u5B9A\u306EIP/\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3002\u5C11\u306A\u304F\u3068\u30821\u3064\u306E\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u307E\u305F\u306F\u30BD\u30FC\u30B9\u304C\u63D0\u4F9B\u3055\u308C\u3066\u3044\u308B\u3053\u3068\u304C\u524D\u63D0\u3067\u3059\u3002",
                trafficType: "\u30AA\u30D7\u30B7\u30E7\u30F3\u3068\u3057\u3066\u3001\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u3092\u9069\u7528\u3059\u308B\u7279\u5B9A\u306E\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u30BF\u30A4\u30D7\u3092\u5B9A\u7FA9\u3057\u307E\u3059\u3002"
              }
            },
            action: {
              sectionTitle: "\u64CD\u4F5C",
              priority: "\u512A\u5148\u5EA6",
              action: "\u64CD\u4F5C",
              fallback: "\u30D5\u30A9\u30FC\u30EB\u30D0\u30C3\u30AF",
              loadBalancing: "Load Balancing",
              forwardErrorCorrection: {
                label: "\u30E9\u30B9\u30C8\u30DE\u30A4\u30EB\u6700\u9069\u5316",
                options: {
                  on: "\u30AA\u30F3",
                  off: "\u30AA\u30D5"
                }
              },
              descriptions: {
                priority: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u4E00\u81F4\u3059\u308B\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306B\u9069\u7528\u3059\u3079\u304D\u512A\u5148\u5EA6",
                action: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u4E00\u81F4\u3059\u308B\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u3067\u884C\u3046\u64CD\u4F5C\u3002",
                fallback: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u4E00\u81F4\u3059\u308B\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306B\u9069\u7528\u3059\u308B\u30D5\u30A9\u30FC\u30EB\u30D0\u30C3\u30AF\u52D5\u4F5C\u3002",
                loadBalancing: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u4E00\u81F4\u3059\u308B\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306B\u9069\u7528\u3059\u308Bload balancing\u52D5\u4F5C",
                forwardErrorCorrection: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u306B\u4E00\u81F4\u3059\u308B\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u306B\u3064\u3044\u3066\u30E9\u30B9\u30C8\u30DE\u30A4\u30EB\u6700\u9069\u5316\u3092\u6709\u52B9\u306B\u3059\u308B\u3053\u308C\u3067\u640D\u5931\u304C\u591A\u3044\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306F\u4FEE\u5FA9\u3055\u308C\u307E\u3059\u304C\u3001\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u306E\u5E2F\u57DF\u5E45\u4F7F\u7528\u5EA6\u304C\u5897\u3048\u307E\u3059\u3002"
              }
            },
            validation: {
              required: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u307E\u305F\u306F\u30BD\u30FC\u30B9\u304C\u5FC5\u8981\u3067\u3059"
            }
          }
        }
      },
      sdwanExplicitPolicies: {
        delete: {
          title: "\u30DD\u30EA\u30B7\u30FC {{name}}\u3092\u524A\u9664",
          body: "\u3053\u306E\u30DD\u30EA\u30B7\u30FC\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B"
        }
      },
      audit: {
        menuTitle: "\u76E3\u67FBLog",
        pageTitle: "\u76E3\u67FBLog",
        labels: {
          created: "\u4F5C\u6210\u8005",
          type: "\u30BF\u30A4\u30D7",
          event: "\u540D\u524D",
          serial: "\u30B7\u30EA\u30A2\u30EB",
          description: "\u5185\u5BB9",
          status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
          createdUsername: "\u7BA1\u7406\u8005",
          remoteAddress: "\u30D1\u30D6\u30EA\u30C3\u30AFIP"
        },
        filter: {
          created: {
            days1: "1\u65E5",
            days3: "3\u65E5",
            weeks1: "1\u9031\u9593",
            weeks2: "2\u9031\u9593",
            months1: "1\u30F6\u6708",
            months2: "2\u30F6\u6708",
            months3: "3\u30F6\u6708",
            months6: "6\u30F6\u6708",
            months9: "9\u30F6\u6708",
            years1: "1\u5E74"
          }
        }
      },
      settings: {
        menuTitle: "\u8A2D\u5B9A",
        pageTitle: "\u8A2D\u5B9A",
        tabs: {
          workspaces: {
            title: "\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9",
            pageTitle: "\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u3092\u7BA1\u7406",
            fields: {
              name: "\u540D\u524D",
              description: "\u5185\u5BB9",
              associations: "\u30B5\u30A4\u30C8/Edge Services"
            },
            dialog: {
              create: {
                buttonLabel: "\u65B0\u898F\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9",
                dialogTitle: "\u65B0\u898F\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9"
              },
              edit: "\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u3092\u7DE8\u96C6"
            },
            delete: {
              body: "\u3053\u306E\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B",
              title: "{{name}}\u3092\u524A\u9664",
              disabled: {
                notEmpty: "\u95A2\u9023\u4ED8\u3051\u3089\u308C\u305F\u30B5\u30A4\u30C8\u307E\u305F\u306FEdge Service\u304C\u3042\u308B\u9650\u308A\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u3092\u524A\u9664\u3059\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093\u3002",
                lastWorkspace: "\u5C11\u306A\u304F\u3068\u3082 1 \u3064\u306E\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u304C\u5FC5\u8981\u3067\u3059\u3002"
              }
            },
            none: "\u5229\u7528\u53EF\u80FD\u306A\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u306F\u3042\u308A\u307E\u305B\u3093"
          },
          workspaceSettings: {
            title: "\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u8A2D\u5B9A"
          },
          updates: {
            title: "\u66F4\u65B0\u6642\u9593\u67A0",
            firmware: {
              title: "\u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2\u66F4\u65B0\u6642\u9593\u67A0",
              description: "\u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2\u66F4\u65B0\u6642\u9593\u67A0\u306F\u5C11\u306A\u304F\u3068\u3082 3\u6642\u9593\u4EE5\u4E0A\u5FC5\u8981\u3067\u3042\u308A\u3001\u6A5F\u5668\u306E\u5834\u6240\u306E\u73FE\u5730\u6642\u9593\u5E2F\u3067\u5B9F\u884C\u3055\u308C\u307E\u3059\u3002",
              noWindowsMessage: "\u66F4\u65B0\u6642\u9593\u67A0\u306F\u672A\u8A2D\u5B9A\u3067\u3059",
              noUpdatesMessage: "\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u53EF\u80FD\u306A\u66F4\u65B0\u306F\u3042\u308A\u307E\u305B\u3093\u3002",
              noAppliancesMessage: "\u73FE\u5728\u3001\u66F4\u65B0\u3092\u8981\u3059\u308B\u6A5F\u5668\u306F\u3042\u308A\u307E\u305B\u3093\u3002",
              default: {
                label: "\u65E2\u5B9A\u306E\u66F4\u65B0\u6642\u9593\u67A0:",
                to: "\u7D42\u4E86\u6642\u9593",
                from: "\u958B\u59CB\u6642\u9593",
                setting: "{{from}} <0>\uFF5E</0> {{to}}"
              },
              create: {
                buttonText: "\u65B0\u898F\u66F4\u65B0\u6642\u9593\u67A0:",
                dialogTitle: "\u65B0\u898F\u66F4\u65B0\u6642\u9593\u67A0\u3092\u8FFD\u52A0"
              },
              edit: {
                dialogTitle: "\u66F4\u65B0\u6642\u9593\u67A0\u3092\u7DE8\u96C6"
              },
              delete: {
                title: "\u66F4\u65B0\u6642\u9593\u67A0\u3092\u524A\u9664",
                message: "\u524A\u9664\u3059\u308B\u3068\u3001\u3053\u306E\u66F4\u65B0\u6642\u9593\u67A0\u306B\u95A2\u9023\u4ED8\u3051\u3089\u308C\u3066\u3044\u308B\u30B5\u30A4\u30C8\u307E\u305F\u306FEdge Service\u306F\u65E2\u5B9A\u306E\u66F4\u65B0\u6642\u9593\u67A0\u3092\u4F7F\u7528\u3057\u307E\u3059\u3002"
              },
              fields: {
                appliance: "\u30B5\u30A4\u30C8/Edge Service",
                applianceDescription: "\u66F4\u65B0\u67A0\u306E\u9593\u306B\u66F4\u65B0\u3059\u3079\u304DEdge Service\u307E\u305F\u306F\u30B9\u30BF\u30F3\u30C9\u30A2\u30ED\u30F3\u30B5\u30A4\u30C8",
                from: "\u8D77\u70B9",
                fromDescription: "\u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2\u306E\u66F4\u65B0\u304C\u81EA\u52D5\u7684\u306B\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u3055\u308C\u308B\u6642\u9593\u67A0\u306E\u958B\u59CB\u6642\u523B\u3002\u6642\u9593\u67A0\u306F\u5404Edge Service\u307E\u305F\u306F\u30B9\u30BF\u30F3\u30C9\u30A2\u30ED\u30F3\u30B5\u30A4\u30C8\u306E\u81EA\u52D5\u691C\u51FA\u3055\u308C\u305F\u73FE\u5730\u6642\u9593\u5E2F\u306B\u3088\u308B\u3082\u306E\u3067\u3059\u3002",
                to: "\u9001\u4FE1\u5148",
                toDescription: "\u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2\u66F4\u65B0\u304C\u81EA\u52D5\u7684\u306B\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u3055\u308C\u308B\u6642\u9593\u67A0\u306E\u7D42\u4E86\u6642\u523B\u3002\u6642\u9593\u67A0\u306F\u5404Edge Service\u307E\u305F\u306F\u30B9\u30BF\u30F3\u30C9\u30A2\u30ED\u30F3\u30B5\u30A4\u30C8\u306E\u81EA\u52D5\u691C\u51FA\u3055\u308C\u305F\u73FE\u5730\u6642\u9593\u5E2F\u306B\u3088\u308B\u3082\u306E\u3067\u3059\u3002",
                windowLength: "\u3053\u308C\u306F\u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2\u66F4\u65B0\u67A0 {{duration}}\u3092\u63D0\u4F9B\u3057\u307E\u3059\u3002"
              },
              updateNow: {
                buttonLabel: "\u4ECA\u3059\u3050\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB",
                disabledMessage: "\u5229\u7528\u53EF\u80FD\u306A\u30A2\u30C3\u30D7\u30C7\u30FC\u30C8\u306F\u3042\u308A\u307E\u305B\u3093\u3002",
                dialogTitle: "\u4ECA\u3059\u3050\u30A2\u30C3\u30D7\u30C7\u30FC\u30C8\u3092\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB",
                dialogMessage: "\u4ECA\u3059\u3050\u66F4\u65B0\u3059\u308B\u3068\u3001\u4E88\u5B9A\u3092\u7D44\u3093\u3060\u66F4\u65B0\u6642\u9593\u67A0\u304C\u524A\u9664\u3055\u308C\u3001\u3059\u3079\u3066\u306EEdge Services \u3068\u30B9\u30BF\u30F3\u30C9\u30A2\u30ED\u30F3 \u30B5\u30A4\u30C8\u304C\u76F4\u3061\u306B\u66F4\u65B0\u3055\u308C\u307E\u3059\u3002Edge Service\u306E\u3042\u308B\u30B5\u30A4\u30C8\u306F\u3001Edge Services\u306E\u66F4\u65B0\u304C\u5B8C\u4E86\u3059\u308B\u3068\u66F4\u65B0\u304C\u958B\u59CB\u3055\u308C\u307E\u3059\u3002\u3053\u308C\u306B\u3088\u308A\u3001\u30C0\u30A6\u30F3\u30BF\u30A4\u30E0\u304C\u767A\u751F\u3059\u308B\u53EF\u80FD\u6027\u304C\u3042\u308A\u307E\u3059\u3002"
              },
              validation: {
                insufficientWindow: "\u66F4\u65B0\u6642\u9593\u67A0\u306F\u5C11\u306A\u304F\u3068\u3082 3\u6642\u9593\u4EE5\u4E0A\u5FC5\u8981\u3067\u3059\u3002"
              },
              editDefault: {
                dialogTitle: "\u65E2\u5B9A\u66F4\u65B0\u6642\u9593\u67A0\u3092\u7DE8\u96C6"
              }
            },
            security: {
              title: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u66F4\u65B0\u6642\u9593\u67A0",
              window: "\u30DB\u30C3\u30C8\u30D5\u30A3\u30C3\u30AF\u30B9\u66F4\u65B0\u6642\u9593\u67A0",
              windowDescription: "\u30DB\u30C3\u30C8\u30D5\u30A3\u30C3\u30AF\u30B9\u306E\u66F4\u65B0\u304C\u81EA\u52D5\u3067\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u3055\u308C\u308B\u3053\u3068\u304C\u53EF\u80FD\u306A\u671F\u9593\u3002\u3053\u306E\u6642\u9593\u67A0\u306F\u5404Edge Service \u307E\u305F\u306F\u30B5\u30A4\u30C8\u306E\u81EA\u52D5\u7684\u306B\u691C\u51FA\u3055\u308C\u305F\u73FE\u5730\u306E\u6642\u9593\u5E2F\u306B\u95A2\u9023\u3057\u3066\u304A\u308A\u3001\u30B5\u30A4\u30C8\u69CB\u6210\u306B\u304A\u3044\u3066\u30AA\u30FC\u30D0\u30FC\u30E9\u30A4\u30C9\u53EF\u80FD\u3067\u3059\u3002"
            },
            hotfixUpdateWindow: "\u30DB\u30C3\u30C8\u30D5\u30A3\u30C3\u30AF\u30B9\u66F4\u65B0\u6642\u9593\u67A0",
            validation: {
              invalidStartTime: "\u7121\u52B9\u306A\u958B\u59CB\u6642\u523B",
              invalidEndTime: "\u7121\u52B9\u306A\u7D42\u4E86\u6642\u523B"
            }
          },
          oms: {
            title: "Azure\u30E2\u30CB\u30BF\u30FC",
            enabled: "\u6709\u52B9",
            id: "\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9 ID",
            key: "\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u306E\u9375",
            descriptions: {
              oms: "Microsoft Operations Management Suite (OMS)\u3092\u4F7F\u7528\u3059\u308B\u5834\u5408\u306E\u307F\u6709\u52B9\u5316\u3057\u307E\u3059\u3002\u3053\u308C\u306F\u5EC3\u6B62\u7528\u30D1\u30B9\u306B\u5B58\u5728\u3057\u307E\u3059\u3002",
              azureMonitorAgent: "\u65B0\u3057\u3044Azure\u30E2\u30CB\u30BF\u30FC\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u306F\u30B5\u30A4\u30C8\u304A\u3088\u3073\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Service\u3067\u306E\u512A\u5148\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3067\u3059\u3002<0 />\uFF08Edge Services\u304A\u3088\u3073Edge Services for Azure\u306B\u304A\u3051\u308BLog\u30A2\u30CA\u30EA\u30C6\u30A3\u30AF\u30B9\u306E\u30B5\u30DD\u30FC\u30C8\u7528\u3001\u4E0B\u8A18\u306EOMS\u30BD\u30EA\u30E5\u30FC\u30B7\u30E7\u30F3\u3092\u3054\u4F7F\u7528\u304F\u3060\u3055\u3044\uFF09"
            },
            clientId: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8ID",
            clientSecret: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u30B7\u30FC\u30AF\u30EC\u30C3\u30C8",
            correlationId: "\u76F8\u95A2\u6027ID",
            region: "\u5730\u57DF",
            resourceGroup: "\u30EA\u30BD\u30FC\u30B9\u30B0\u30EB\u30FC\u30D7",
            subscriptionId: "\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3ID",
            tenantId: "\u30C6\u30CA\u30F3\u30C8ID",
            oms: "Azure\u30ED\u30B0\u5206\u6790(OMS)",
            azureMonitorAgent: "Azure\u30E2\u30CB\u30BF\u30FC\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8",
            disabled: "\u7121\u52B9",
            introduction: "Azure\u30E2\u30CB\u30BF\u30FC\u3067\u306FIT\u30B7\u30B9\u30C6\u30E0\u304A\u3088\u3073\u6280\u8853\u57FA\u76E4\u306B\u3088\u3063\u3066\u751F\u6210\u3055\u308C\u308B\u30DE\u30B7\u30F3\u30C7\u30FC\u30BF\u306E\u691C\u7D22\u3001\u89E3\u6790\u3001\u8996\u899A\u5316\u304C\u53EF\u80FD\u3067\u3042\u308A\u3001\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306B\u95A2\u3059\u308B\u77E5\u898B\u304C\u6DF1\u307E\u308A\u307E\u3059\u3002Logs\u3092Azure\u30E2\u30CB\u30BF\u30FC\u30A2\u30AB\u30A6\u30F3\u30C8\u306B\u6D41\u3057\u8FBC\u3093\u3067\u74B0\u5883\u306ELogs\u30C7\u30FC\u30BF\u3092\u3055\u3089\u306B\u51E6\u7406\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002"
          },
          global: {
            title: "\u5168\u4F53\u8A2D\u5B9A"
          },
          forwardedDomains: {
            title: "\u8EE2\u9001\u3055\u308C\u305F\u30C9\u30E1\u30A4\u30F3",
            domains: "\u30C9\u30E1\u30A4\u30F3",
            descriptions: {
              domains: "Azure\u3078\u89E3\u6C7A\u306E\u305F\u3081\u306B\u8EE2\u9001\u3055\u308C\u308B\u3079\u304D DNS\u30C9\u30E1\u30A4\u30F3"
            },
            domain: "\u30C9\u30E1\u30A4\u30F3",
            forwarders: "\u30B5\u30FC\u30D0\u30FC",
            button: "\u8FFD\u52A0",
            duplicateName: "\u30C9\u30E1\u30A4\u30F3\u540D\u306F\u3059\u3067\u306B\u4F7F\u7528\u3055\u308C\u3066\u3044\u307E\u3059",
            addDomain: "\u30C9\u30E1\u30A4\u30F3\u3092\u8FFD\u52A0",
            editDomain: "\u30C9\u30E1\u30A4\u30F3\u3092\u7DE8\u96C6",
            deleteDomain: "\u30C9\u30E1\u30A4\u30F3\u3092\u524A\u9664"
          },
          vmActivation: {
            title: "VM\u30A2\u30AF\u30C6\u30A3\u30D9\u30FC\u30B7\u30E7\u30F3",
            firstName: "\u540D\u524D",
            lastName: "\u82D7\u5B57",
            companyName: "\u4F1A\u793E\u540D",
            phoneNumber: "\u96FB\u8A71\u756A\u53F7",
            address: "\u4F4F\u6240 1",
            address2: "\u4F4F\u6240 2",
            city: "\u90FD\u5E02",
            country: "\u56FD",
            emailAddress: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
            state: "\u90FD\u9053\u5E9C\u770C",
            zip: "\u90F5\u4FBF\u756A\u53F7",
            descriptions: {
              firstName: "\u3054\u81EA\u5206\u306E\u540D\u524D\u3002",
              lastName: "\u82D7\u5B57",
              companyName: "\u4F1A\u793E\u540D",
              phoneNumber: "\u52E4\u52D9\u5148\u96FB\u8A71\u756A\u53F7",
              address: "\u30AA\u30D5\u30A3\u30B9\u4F4F\u62401",
              address2: "\u30AA\u30D5\u30A3\u30B9\u4F4F\u62402\u884C\u76EE (\u5FC5\u8981\u306A\u5834\u5408)",
              city: "\u30AA\u30D5\u30A3\u30B9\u306E\u6240\u5728\u90FD\u5E02",
              country: "\u30AA\u30D5\u30A3\u30B9\u306E\u6240\u5728\u56FD",
              emailAddress: "\u4F1A\u793E\u306E\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
              state: "\u30AA\u30D5\u30A3\u30B9\u306E\u6240\u5728\u90FD\u9053\u5E9C\u770C",
              zip: "\u30AA\u30D5\u30A3\u30B9\u306E\u90F5\u4FBF\u756A\u53F7"
            }
          },
          notification: {
            title: "\u901A\u77E5",
            delete: "\u901A\u77E5\u3092\u524A\u9664 ",
            labels: {
              name: "\u540D\u524D",
              events: "\u30A4\u30D9\u30F3\u30C8",
              status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
              administrators: "\u7BA1\u7406\u8005",
              actions: "\u64CD\u4F5C"
            },
            descriptions: {
              name: "\u3053\u306E\u901A\u77E5\u306E\u56FA\u6709\u540D\u3002",
              events: "\u901A\u77E5\u3059\u3079\u304D\u30A4\u30D9\u30F3\u30C8\u3002",
              status: "\u901A\u77E5\u3059\u3079\u304D\u30B9\u30C6\u30FC\u30BF\u30B9 \u30BF\u30A4\u30D7\u3002",
              administrators: "\u901A\u77E5\u306E\u9001\u308A\u5148\u304D\u7BA1\u7406\u8005\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3002",
              actions: "\u901A\u77E5\u3059\u3079\u304D\u64CD\u4F5C\u3002"
            },
            components: {
              button: {
                add: "\u8FFD\u52A0",
                edit: "\u7DE8\u96C6",
                delete: "\u524A\u9664",
                save: "\u4FDD\u5B58",
                cancel: "\u30AD\u30E3\u30F3\u30BB\u30EB"
              },
              title: {
                editNotification: "\u901A\u77E5\u3092\u7DE8\u96C6",
                createNotification: "\u901A\u77E5\u3092\u4F5C\u6210"
              }
            },
            action: {
              deleteNotification: "\u3053\u306E\u901A\u77E5\u3092\u672C\u5F53\u306B\u524A\u9664\u3057\u3066\u304B\u307E\u3044\u307E\u305B\u3093\u304B"
            }
          },
          sdwanConnectorNetwork: {
            title: "\u30B3\u30CD\u30AF\u30BF",
            subMenu: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u7BC4\u56F2",
            description: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092\u4F7F\u7528\u3057\u3066\u30B3\u30CD\u30AF\u30BF\u306E\u80CC\u5F8C\u306B\u3042\u308B\u30EA\u30BD\u30FC\u30B9\u306BIP\u3092\u5272\u308A\u5F53\u3066\u307E\u3059\u3002"
          },
          reportCreatorAcl: {
            title: "Report Creator",
            subMenu: "\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            description: "Report Creator\u30C4\u30FC\u30EB\u304C\u30B5\u30A4\u30C8\u306E\u30C7\u30D0\u30A4\u30B9\u3068Edge Services\u3092\u554F\u3044\u5408\u308F\u305B\u308B\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8\u30FB\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u304A\u3088\u3073 IP \u30A2\u30C9\u30EC\u30B9\u306E\u30EA\u30B9\u30C8\u3092\u5165\u529B\u3057\u307E\u3059\u3002"
          },
          dcAgent: {
            title: "DC\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8",
            description: "\u8A2D\u5B9A\u3057\u305FDC\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u306EIP\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B\u3057\u307E\u3059\u3002",
            ipAddress: "IP\u30A2\u30C9\u30EC\u30B9"
          },
          dhcpServer: {
            title: "DHCP\u30EA\u30EC\u30FC\u30B5\u30FC\u30D0\u30FC",
            description: "\u4F7F\u7528\u3057\u305F\u3044DHCP\u30B5\u30FC\u30D0\u30FC\u306EIP\u30A2\u30C9\u30EC\u30B9\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
            ipAddress: "IP\u30A2\u30C9\u30EC\u30B9"
          }
        }
      },
      statusBanner: {
        subscriptionExpiresSoon: "\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3\u306F{{days}} \u65E5\u5F8C\u306B\u6E80\u4E86\u3068\u306A\u308A\u307E\u3059",
        subscriptionExpiresOneDay: "\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3\u306F\u3042\u30681 \u65E5\u3067\u6E80\u4E86\u3068\u306A\u308A\u307E\u3059",
        subscriptionExpiresToday: "\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3\u306F\u6E80\u4E86\u307E\u30671 \u65E5\u3092\u5207\u308A\u307E\u3057\u305F",
        deprecatedExpiresSoon: "\u3054\u5229\u7528\u4E2D\u306EAzure\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3\u30D7\u30E9\u30F3\u306F\u3082\u306F\u3084\u30B5\u30DD\u30FC\u30C8\u3055\u308C\u306A\u304F\u306A\u3063\u3066\u3044\u307E\u3059\u3002\u65B0\u3057\u3044\u30D7\u30E9\u30F3\u306B\u5207\u308A\u66FF\u3048\u306A\u3044\u9650\u308A\u3053\u306Eservice\u306F\u3042\u3068 {{days}} \u65E5\u3067\u6E80\u4E86\u3068\u306A\u308A\u307E\u3059\u3002\u30D7\u30E9\u30F3\u3092{{linkStart}}\u3053\u3053\u3067{{linkEnd}}\u5909\u66F4\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
        deprecatedExpiresOneDay: "\u3054\u5229\u7528\u4E2D\u306EAzure\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3\u30D7\u30E9\u30F3\u306F\u3082\u306F\u3084\u30B5\u30DD\u30FC\u30C8\u3055\u308C\u306A\u304F\u306A\u3063\u3066\u3044\u307E\u3059\u3002\u3053\u306Eservice\u306F\u65B0\u3057\u3044\u30D7\u30E9\u30F3\u306B\u5207\u308A\u66FF\u3048\u306A\u3044\u3068\u3042\u30681\u65E5\u3067\u6E80\u4E86\u3068\u306A\u308A\u307E\u3059\u3002\u30D7\u30E9\u30F3\u3092{{linkStart}}\u3053\u3053\u3067{{linkEnd}}\u5909\u66F4\u3057\u3066\u304F\u3060\u3055\u3044",
        deprecatedExpiresToday: "\u3054\u5229\u7528\u4E2D\u306EAzure\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3\u30D7\u30E9\u30F3\u306F\u3082\u306F\u3084\u30B5\u30DD\u30FC\u30C8\u3055\u308C\u306A\u304F\u306A\u3063\u3066\u3044\u307E\u3059\u3002\u3053\u306Eservice\u306F\u65B0\u3057\u3044\u30D7\u30E9\u30F3\u306B\u5207\u308A\u66FF\u3048\u306A\u3044\u3068\u6E80\u4E86\u307E\u3067\u3082\u30461\u65E5\u3082\u3042\u308A\u307E\u305B\u3093\u3002\u30D7\u30E9\u30F3\u3092{{linkStart}}\u3053\u3053\u3067{{linkEnd}}\u5909\u66F4\u3057\u3066\u304F\u3060\u3055\u3044",
        updateAvailable: "\u65B0\u3057\u3044\u30D5\u30A1\u30FC\u30E0\u30A6\u30A7\u30A2\u66F4\u65B0\u304C\u3042\u308A\u307E\u3059\uFF01{{date}}\u307E\u3067\u306B\u30E1\u30F3\u30C6\u30CA\u30F3\u30B9\u6642\u9593\u67A0\u3092\u6C7A\u3081\u3066\u66F4\u65B0\u3092\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
        pamBandwidthCapacity: "\u73FE\u5728\u4F7F\u7528\u3057\u3066\u3044\u308BEdge Service\u306E\u5E2F\u57DF\u5E45\u306F\u30E9\u30A4\u30BB\u30F3\u30B9\u3055\u308C\u3066\u3044\u308B\u5E2F\u57DF\u5E45\u3092\u8D85\u904E\u3057\u3066\u3044\u307E\u3059\u3002\u3055\u3089\u306B\u5E2F\u57DF\u5E45\u3092\u3054\u8CFC\u5165\u3044\u305F\u3060\u304F\u305F\u3081\u55B6\u696D\u90E8\u306B\u3054\u9023\u7D61\u304F\u3060\u3055\u3044\u3002"
      },
      states: {
        pending: "\u4FDD\u7559\u4E2D",
        completed: "\u5B8C\u4E86",
        online: "\u30AA\u30F3\u30E9\u30A4\u30F3",
        offline: "\u30AA\u30D5\u30E9\u30A4\u30F3",
        failed: "\u5931\u6557",
        error: "\u30A8\u30E9\u30FC",
        degraded: "\u30C0\u30A6\u30F3\u30B0\u30EC\u30FC\u30C9\u6E08\u307F",
        connecting: "\u63A5\u7D9A\u4E2D",
        unknown: "\u4E0D\u660E",
        loading: " "
      },
      validation: {
        alphaNumericDash: "\u6570\u5B57\u3068\u6587\u5B57\u304A\u3088\u3073\u30CF\u30A4\u30D5\u30F3\u306E\u307F\u4F7F\u3048\u307E\u3059",
        alphaNumericDashNoHaSuffix: "\u300C-ha\u300D\u306E\u30B5\u30D5\u30A3\u30C3\u30AF\u30B9\u306F\u4F7F\u3048\u307E\u305B\u3093",
        alphaNumericDashSpace: "\u6570\u5B57\u3068\u6587\u5B57\u304A\u3088\u3073\u30CF\u30A4\u30D5\u30F3\u304A\u3088\u3073\u7A7A\u767D\u306E\u307F\u4F7F\u3048\u307E\u3059",
        alphaNumericDotsDash: "\u6570\u5B57\u3001\u30A2\u30EB\u30D5\u30A1\u30D9\u30C3\u30C8\u3001\u30C9\u30C3\u30C8\u3001\u30CF\u30A4\u30D5\u30F3\u3057\u304B\u542B\u3080\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093",
        alphaNumericDotsColonsDash: "\u6570\u5B57\u3001\u30A2\u30EB\u30D5\u30A1\u30D9\u30C3\u30C8\u3001\u30C9\u30C3\u30C8\u3001\u30B3\u30ED\u30F3\u3001\u30CF\u30A4\u30D5\u30F3\u3057\u304B\u542B\u3080\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093",
        customKeyword: "\u6700\u5927\u3067128\u306E\u6570\u5B57\u3001\u6587\u5B57\u3001\u30CF\u30A4\u30D5\u30F3\u3001\u30D4\u30EA\u30AA\u30C9\u3001\u5F15\u7528\u7B26\u3001\u7A7A\u767D\u307E\u3067\u3057\u304B\u542B\u3080\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093",
        uuid: "\u306F\u6709\u52B9\u306AUUID/GUID\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093"
      },
      footer: {
        serial: " | \u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3\u30B7\u30EA\u30A2\u30EB\u756A\u53F7: {{serial}}"
      },
      users: {
        table: {
          fullName: "\u6C0F\u540D",
          email: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
          deviceCount: "\u88C5\u7F6E\u53F0\u6570",
          enrollmentCompleted: {
            label: "\u767B\u9332\u5B8C\u4E86",
            yes: "\u767B\u9332\u5B8C\u4E86",
            no: "\u767B\u9332\u4FDD\u7559\u4E2D"
          },
          enrollmentLink: "\u767B\u9332\u7528\u30EA\u30F3\u30AF\u3092\u30B3\u30D4\u30FC",
          enrollmentLinkCopied: "\u767B\u9332\u7528\u30EA\u30F3\u30AF\u306F\u30AF\u30EA\u30C3\u30D7\u30DC\u30FC\u30C9\u306B\u30B3\u30D4\u30FC\u3055\u308C\u307E\u3057\u305F",
          resendInvite: "\u62DB\u5F85\u3092\u518D\u9001",
          tamperProof: "\u30BF\u30F3\u30D1\u30FC\u30D7\u30EB\u30FC\u30D5",
          devicePreConnect: "\u30C7\u30D0\u30A4\u30B9\u30D7\u30EC\u30ED\u30B0\u30AA\u30F3",
          webFiltering: "\u30A6\u30A7\u30D6\u30D5\u30A3\u30EB\u30BF\u30FC",
          optionsOverride: "\u30E6\u30FC\u30B6\u30FC\u30AA\u30FC\u30D0\u30FC\u30E9\u30A4\u30C9",
          on: "\u30AA\u30F3",
          off: "\u30AA\u30D5"
        },
        delete: {
          title: "\u30E6\u30FC\u30B6\u30FC\u306E\u767B\u9332\u89E3\u9664",
          body: "\u3053\u306E\u30E6\u30FC\u30B6\u30FC\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B"
        },
        dialog: {
          buttonText: "\u30E6\u30FC\u30B6\u30FC\u3092\u767B\u9332",
          disabledButton: "\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3 > \u8A2D\u5B9A\u30DA\u30FC\u30B8\u3067\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30BF\u30FC\u3068\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u3092\u6700\u521D\u306B\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002"
        },
        noData: {
          iconHeader: "\u30E6\u30FC\u30B6\u30FC\u3092\u8FFD\u52A0\u3057\u3066\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
          labelButton: "\u30E6\u30FC\u30B6\u30FC\u3092\u8FFD\u52A0\u3057\u3066\u62DB\u5F85",
          textHeader: "SecureEdge \u60AA\u3063\u3059\u306E\u305F\u3081\u306B\u30E6\u30FC\u30B6\u30FC\u304C\u3068\u3066\u3082\u91CD\u8981\u306A\u7406\u7531",
          text: "SecureEdge\u30A2\u30AF\u30BB\u30B9\u3067\u306F\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3067\u306E\u30E6\u30FC\u30B6\u30FC\u306E\u30A2\u30AF\u30BB\u30B9\u3068\u7279\u6A29\u3092\u5236\u5FA1\u3057\u307E\u3059\u3002\u5F93\u3063\u3066\u3001\u3053\u308C\u306F\u30A2\u30AF\u30BB\u30B9\u53EF\u80FD\u3067\u3042\u3063\u3066SecureEdge\u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u306E\u305F\u3081\u306E\u767B\u9332\u30D7\u30ED\u30BB\u30B9\u3092\u958B\u59CB\u53EF\u80FD\u306A\u4EBA\u3092\u8E74\u5B9A\u3067\u304D\u308B\u5834\u6240\u3068\u306A\u308A\u307E\u3059\u3002",
          firstTitle: "1",
          firstStep: "\u767B\u9332\u62DB\u5F85\u3092\u9001\u308A\u307E\u3059",
          secondTitle: "2",
          secondStep: "\u30E6\u30FC\u30B6\u30FC\u306B\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9\u3057\u3066\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u3055\u3089\u306B\u81EA\u5DF1\u767B\u9332\u3059\u308B\u3088\u3046\u3054\u6848\u5185\u3057\u307E\u3059\u3002",
          textFooter: "\u4EE5\u4E0A\u3067\u5168\u3066\u3067\u3059\u3002\u3053\u308C\u3067\u59CB\u3081\u308B\u7528\u610F\u304C\u3067\u304D\u307E\u3057\u305F\uFF01"
        },
        settings: {
          global: "\u30E6\u30FC\u30B6\u30FC\u30AA\u30FC\u30D0\u30FC\u30E9\u30A4\u30C9",
          introduction: "\u3053\u306E\u30E6\u30FC\u30B6\u30FC\u5411\u3051\u306E\u30B0\u30ED\u30FC\u30D0\u30EBZTNA\u8A2D\u5B9A\u3092\u30AA\u30FC\u30D0\u30FC\u30E9\u30A4\u30C9\u3057\u307E\u3059\u3002",
          tamperProof: "\u30BF\u30F3\u30D1\u30FC\u30D7\u30EB\u30FC\u30D5",
          devicePreConnect: "\u30C7\u30D0\u30A4\u30B9\u30D7\u30EC\u30ED\u30B0\u30AA\u30F3",
          deviceLimit: "\u30E6\u30FC\u30B6\u30FC\u30C7\u30D0\u30A4\u30B9\u5236\u9650",
          webFiltering: "\u30A6\u30A7\u30D6\u30D5\u30A3\u30EB\u30BF\u30FC",
          enabled: "\u6709\u52B9",
          disabled: "\u7121\u52B9",
          overrideEnabled: "\u4E0B\u8A18\u306E\u6307\u5B9A\u8A2D\u5B9A\u3092\u4F7F\u7528",
          overrideDisabled: "\u30B0\u30ED\u30FC\u30D0\u30EBZTNA\u8A2D\u5B9A\u3092\u4F7F\u7528",
          descriptions: {
            tamperProof: "\u3053\u308C\u3067\u30E6\u30FC\u30B6\u30FC\u306B\u3088\u308BSecureEdge\u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u306E\u7121\u52B9\u5316\u304C\u963B\u6B62\u3055\u308C\u307E\u3059\u3002iOS\u3001iPadOS\u3001\u307E\u305F\u306FAndroid\u3092\u5B9F\u884C\u3059\u308B\u30C7\u30D0\u30A4\u30B9\u306B\u3064\u3044\u3066\u306FMDM\u30BD\u30EA\u30E5\u30FC\u30B7\u30E7\u30F3\u304C\u5FC5\u9808\u3067\u3059\u3002",
            devicePreConnect: "\u30E6\u30FC\u30B6\u30FC\u304C\u30ED\u30B0\u30A4\u30F3\u3057\u3066\u3044\u306A\u3044Windows\u3092\u5B9F\u884C\u3057\u3066\u3044\u308B\u30E6\u30FC\u30B6\u30FC\u30C7\u30D0\u30A4\u30B9\u306E\u7BA1\u7406\u304C\u53EF\u80FD\u306B\u306A\u308A\u307E\u3059\u3002",
            webFiltering: "\u5168\u3066\u306E\u30A6\u30A7\u30D6\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u3092\u5B9A\u7FA9\u6E08\u307F\u30A6\u30A7\u30D6\u30D5\u30A3\u30EB\u30BF\u30FC\u30DD\u30EA\u30B7\u30FC\u306B\u57FA\u3065\u3044\u3066\u78BA\u8A8D\u3059\u308B\u3053\u3068\u3092\u5F37\u5236\u3057\u307E\u3059\u3002",
            deviceLimit: "\u30E6\u30FC\u30B6\u30FC\u4E00\u4EBA\u5F53\u305F\u308A\u306E\u30C7\u30D0\u30A4\u30B9\u6570"
          }
        }
      },
      devices: {
        table: {
          userName: "\u30E6\u30FC\u30B6\u30FC",
          email: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
          brand: "\u88C5\u7F6E\u306E\u30D6\u30E9\u30F3\u30C9",
          model: "\u88C5\u7F6E\u30E2\u30C7\u30EB",
          hardwareModel: "\u30CF\u30FC\u30C9\u30A6\u30A7\u30A2\u30E2\u30C7\u30EB",
          os: "\u30AA\u30DA\u30EC\u30FC\u30C6\u30A3\u30F3\u30B0\u30B7\u30B9\u30C6\u30E0",
          osFlavour: "OS\u30D5\u30EC\u30FC\u30D0\u30FC",
          osVersion: "Os\u30D0\u30FC\u30B8\u30E7\u30F3",
          enrollmentDate: "\u767B\u9332\u65E5"
        },
        delete: {
          body: "\u3053\u306E\u88C5\u7F6E\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F",
          title: "\u88C5\u7F6E\u3092\u524A\u9664"
        },
        noData: {
          iconHeader: "\u767B\u9332\u3055\u308C\u305F\u88C5\u7F6E\u304C\u307F\u3064\u304B\u308A\u307E\u305B\u3093\uFF01",
          action: "\u30E6\u30FC\u30B6\u30FC\u3092\u8FFD\u52A0\u3057\u3066\u62DB\u5F85",
          textHeader: "\u767B\u9332\u3055\u308C\u305F\u88C5\u7F6E\u3068\u306F\u4F55\u304B",
          text: "\u81EA\u5DF1\u767B\u9332\u30D7\u30ED\u30BB\u30B9\u3092\u958B\u59CB\u3059\u308B\u3088\u3046\u306B\u30E6\u30FC\u30B6\u30FC\u3092\u62DB\u5F85\u3057\u3001\u305D\u306E\u30E6\u30FC\u30B6\u30FC\u304C\u521D\u671F\u767B\u9332\u624B\u9806\u3092\u5B8C\u4E86\u3059\u308B\u3068\u76F4\u3061\u306B\u3001\u30A4\u30F3\u30B9\u30C8\u30FC\u30EB\u6E08\u307F\u306ESecureEdge\u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u3092\u6301\u3063\u3066\u3044\u308B\u3053\u306E\u30E6\u30FC\u30B6\u30FC\u7528\u306E\u3059\u3079\u3066\u306E\u88C5\u7F6E\u304C\u3053\u3053\u306B\u4E00\u89A7\u3055\u308C\u307E\u3059\u3002"
        }
      },
      firewalls: {
        table: {
          name: "\u540D\u524D",
          serials: "\u30B7\u30EA\u30A2\u30EB",
          isCcManaged: "CC\u7BA1\u7406",
          model: "\u30E2\u30C7\u30EB"
        },
        delete: {
          title: "CloudGen Firewall\u3092\u5207\u65AD {{name}}",
          body: "\u3053\u306ECloudGen Firewall\u3092SecureEdge Service\u304B\u3089\u672C\u5F53\u306B\u5207\u65AD\u3057\u3066\u3082\u3088\u3044\u306E\u3067\u3059\u304B\u3002",
          forbidden: "CloudGen Firewall\u306E\u7D71\u5408\u306F\u3053\u308C\u3089\u304C\u30A8\u30F3\u30C9\u30DD\u30A4\u30F3\u30C8\u3068\u3057\u3066\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u308B\u3068\u304D\u306F\u524A\u9664\u3067\u304D\u307E\u305B\u3093\u3002"
        },
        dialog: {
          create: {
            buttonText: "\u767B\u9332\u30C8\u30FC\u30AF\u30F3",
            dialogTitle: "\u767B\u9332\u30C8\u30FC\u30AF\u30F3\u3092\u751F\u6210"
          },
          result: {
            message: "\u6B21\u306E\u30C8\u30FC\u30AF\u30F3\u3092\u30B3\u30D4\u30FC\u3057\u3066<0>Firewall\u7BA1\u7406\u8005\u30A2\u30AF\u30C6\u30A3\u30D9\u30FC\u30B7\u30E7\u30F3\u30D5\u30A9\u30FC\u30E0</0>\u306B\u8CBC\u308A\u4ED8\u3051\u3066\u304F\u3060\u3055\u3044\u3002",
            firewallText: "Firewall\u767B\u9332\u30C8\u30FC\u30AF\u30F3",
            firewallOtp: "\u3053\u306E\u30C8\u30FC\u30AF\u30F3\u306F\u5358\u4E00\u306EFirewall\u306B\u3064\u3044\u3066\u306E\u307F\u6709\u52B9\u3067\u300130\u5206\u3067\u7121\u52B9\u306B\u306A\u308A\u307E\u3059\u3002"
          }
        },
        noData: {
          iconHeader: "CloudGen Firewall \u306E\u914D\u5099\u306B\u63A5\u7D9A\u3057\u307E\u3059\uFF01",
          labelButton: "\u767B\u9332\u30C8\u30FC\u30AF\u30F3\u3092\u53D6\u5F97\u3059\u308B",
          textHeader: "CloudGen Firewall\u3092\u73FE\u5728\u306E\u69CB\u6210\u306B\u8FFD\u52A0\u3057\u307E\u3059\u304B",
          text: "\u3059\u3067\u306B\u5B9F\u884C\u4E2D\u306EBarracuda CloudGen Firewall\u304C\u3042\u308B\u5834\u5408\u3001\u3053\u306E\u914D\u5099\u3092\u3054\u5229\u7528\u306E\u30A2\u30AF\u30BB\u30B9\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u7528\u5165\u53E3\u306E\u305F\u3081\u306E\u914D\u5099\u3092\u8ACB\u6C42\u3059\u308B\u3053\u3068\u3082\u3067\u304D\u307E\u3059\u3002CloudGen Firewall\u914D\u5099\u306E\u8ACB\u6C42\u306F\u767B\u9332\u30C8\u30FC\u30AF\u30F3\u3092CloudGen Firewall\u7528\u7BA1\u7406\u30B3\u30F3\u30BD\u30FC\u30EB\u306B\u30B3\u30D4\u30FC\u3059\u308B\u3060\u3051\u3068\u540C\u69D8\u306B\u7C21\u5358\u306B\u884C\u3046\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002<0 /><0 />\u6CE8\u610F\u3057\u3066\u3044\u305F\u3060\u304D\u305F\u3044\u306E\u306F\u3001CloudGen Firewall\u306E\u8ACB\u6C42\u5F8C\u3001\u30EA\u30E2\u30FC\u30C8\u30A2\u30AF\u30BB\u30B9\u30B3\u30F3\u30DD\u30FC\u30CD\u30F3\u30C8\u304CFirewall Admin\u30C4\u30FC\u30EB\u306B\u3088\u308B\u30B3\u30F3\u30C8\u30ED\u30FC\u30E9\u3067\u306F\u306A\u304F\u306A\u308A\u307E\u3059\u3002"
        }
      },
      integration: {
        pageTitle: "\u7D71\u5408",
        firewalls: "CloudGen\u30D5\u30A1\u30A4\u30A2\u30A6\u30A9\u30FC\u30EB (CloudGen Firewall)",
        ipSecV2: {
          create: "IPsec\u30C8\u30F3\u30CD\u30EB\u3092\u4F5C\u6210",
          createButton: "IPsec\u30C8\u30F3\u30CD\u30EB\u3092\u8FFD\u52A0",
          edit: "IPsec\u30C8\u30F3\u30CD\u30EB\u3092\u7DE8\u96C6",
          general: "\u5168\u822C",
          network: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
          phases: "\u6BB5\u968E",
          sourceDest: "\u30BD\u30FC\u30B9\uFF0F\u76EE\u7684\u5730",
          success: "\u6210\u529F",
          successMessage: "\u65B0\u898FIPsec\u30C8\u30F3\u30CD\u30EB\u306F\u6B63\u5E38\u306B\u4F5C\u6210\u3055\u308C\u307E\u3057\u305F",
          pageTitle: "IPsec VPN",
          table: {
            status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
            name: "\u540D\u524D",
            description: "\u5185\u5BB9",
            enabled: "\u6709\u52B9",
            gateway: "\u30EA\u30E2\u30FC\u30C8\u30FB\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4",
            localNetworks: "\u30ED\u30FC\u30AB\u30EB\u30FB\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            localBgp: "\u30ED\u30FC\u30AB\u30EBBGP",
            remoteNetworks: "\u30EA\u30E2\u30FC\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
            remoteBgp: "\u30EA\u30E2\u30FC\u30C8BGP",
            type: "\u30BF\u30A4\u30D7",
            siteWanInterface: "SecureEdge Peer",
            peerIp: "\u30D4\u30A2\u30FCIP",
            duration: "\u7D99\u7D9A\u6642\u9593",
            encryption: "\u6697\u53F7\u5316",
            filters: {
              enabled: {
                enabled: "\u6709\u52B9",
                disabled: "\u7121\u52B9"
              },
              secureEdgePeer: {
                peerType: "\u30D4\u30A2\u30FC\u30FB\u30BF\u30A4\u30D7",
                edgeService: "Edge Service",
                site: "\u30B5\u30A4\u30C8"
              }
            },
            ellipsisMenu: {
              restart: "IPsec\u30C8\u30F3\u30CD\u30EB\u3092\u518D\u8D77\u52D5"
            }
          },
          cloudCantInitiate: "\u30B5\u30A4\u30C8\u304A\u3088\u3073\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8Edge Services\u306B\u30C8\u30F3\u30CD\u30EB\u3092\u958B\u59CB\u3055\u305B\u308B\u3088\u3046\u306B\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059",
          dialog: {
            create: {
              buttonText: "\u30C8\u30F3\u30CD\u30EB\u3092\u8FFD\u52A0"
            },
            delete: {
              body: "\u3053\u306EIPsec\u30C8\u30F3\u30CD\u30EB\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u69CB\u3044\u307E\u305B\u3093\u304B\u3002",
              title: "IPsec\u30C8\u30F3\u30CD\u30EB\u3092\u524A\u9664 {{name}}"
            },
            restart: {
              ok: "OK",
              cancel: "\u30AD\u30E3\u30F3\u30BB\u30EB",
              body: "\u3053\u306EIPsec\u30C8\u30F3\u30CD\u30EB\u3092\u518D\u8D77\u52D5\u3057\u3066\u3082\u672C\u5F53\u306B\u69CB\u3044\u307E\u305B\u3093\u304B\u3002",
              title: "IPsec\u30C8\u30F3\u30CD\u30EB\u3092\u518D\u8D77\u52D5 {{name}}",
              message: "\u30C8\u30F3\u30CD\u30EB\u3092\u518D\u8D77\u52D5\u3057\u3066\u3044\u307E\u3059\u3002\u304A\u5F85\u3061\u304F\u3060\u3055\u3044"
            },
            generalSection: {
              descriptionMessage: "\u65B0\u898F\u30C8\u30F3\u30CD\u30EB\u3092\u4F5C\u6210\u3059\u308B\u306B\u306F\u4EE5\u4E0B\u306E\u8A2D\u5B9A\u3092\u884C\u3063\u3066\u69CB\u6210\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              tunnelStatus: "\u6709\u52B9",
              general: {
                title: "\u4E00\u822C\u60C5\u5831",
                name: "\u540D\u524D",
                description: "\u5185\u5BB9",
                initiatesTunnel: "\u30C8\u30F3\u30CD\u30EB\u3092\u958B\u59CB",
                restartSa: "SA\u3092\u518D\u8D77\u52D5",
                descriptions: {
                  title: "\u4E00\u822C\u60C5\u5831\u306E\u5185\u5BB9",
                  name: "\u3053\u306EIPsec\u30C8\u30F3\u30CD\u30EB\u306E\u305F\u3081\u306E\u56FA\u6709\u540D\u3002",
                  description: "\u30C8\u30F3\u30CD\u30EB\u30AA\u30D7\u30B7\u30E7\u30F3\u306E\u5185\u5BB9\u3002",
                  tunnelStatus: "\u4E00\u6642\u7684\u306B\u30C8\u30F3\u30CD\u30EB\u3092\u7121\u52B9\u5316\u3059\u308B\u3068\u304D\u30AA\u30D5\u306B\u3057\u307E\u3059\u3002",
                  initiatesTunnel: "\u6709\u52B9\u5316\u6570r\u3068SecureEdge\u30C7\u30D0\u30A4\u30B9\u304CIPsec\u30C8\u30F3\u30CD\u30EB\u3092\u958B\u59CB\u3057\u307E\u3059\u3002"
                }
              },
              authentication: {
                title: "\u8A8D\u8A3C",
                authentication: "\u8A8D\u8A3C",
                sharedSecret: "\u5171\u6709\u306E\u79D8\u5BC6",
                serverCert: "\u30B5\u30FC\u30D0\u30FC\u8A3C\u660E\u66F8",
                caCert: "CA\u8A3C\u660E\u66F8",
                x509Condition: "X509\u6761\u4EF6",
                exploit509: "\u30A8\u30AF\u30B9\u30D7\u30ED\u30A4\u30C8509",
                descriptions: {
                  authentication: "IPsec\u30C8\u30F3\u30CD\u30EB\u304C\u8A8D\u8A3C\u306E\u305F\u3081\u306B\u4F7F\u7528\u3059\u3079\u304D\u30E1\u30BD\u30C3\u30C9\u3002",
                  sharedSecret: "\u8A8D\u8A3C\u306E\u305F\u3081\u306E\u4F7F\u7528\u3059\u308B\u30D7\u30EC\u30B7\u30A7\u30FC\u30AF\u30AD\u30FC\u3002",
                  serverCert: "\u30B5\u30FC\u30D0\u30FC\u8A3C\u660E\u66F8\u306E\u5185\u5BB9",
                  caCert: "CA\u8A3C\u660E\u66F8\u306E\u5185\u5BB9",
                  x509Condition: "X509\u6761\u4EF6\u306E\u5185\u5BB9",
                  exploit509: "\u30A8\u30AF\u30B9\u30D7\u30ED\u30A4\u30C8509\u306E\u5185\u5BB9"
                }
              }
            },
            sourceDestSection: {
              descriptionMessage: "\u8FFD\u52A0\u3057\u305F\u3044\u30C8\u30F3\u30CD\u30EB\u306E\u30BD\u30FC\u30B9\u3068\u76EE\u7684\u5730\u3092\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              enableBgp: "BGP\u3092\u6709\u52B9\u5316",
              descriptions: {
                enableBgp: "BGP\u3092\u6709\u52B9\u5316"
              },
              source: {
                title: "\u30BD\u30FC\u30B9",
                type: "\u30BF\u30A4\u30D7",
                peer: "\u30D4\u30A2\u30FC",
                ipAddresses: "\u30D4\u30A2\u30FC\u30FB\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4",
                initiatesTunnel: "\u958B\u59CB\u3059\u308B",
                wanInterface: "WAN\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9",
                localId: "\u30ED\u30FC\u30AB\u30EBID",
                networkAddresses: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30A2\u30C9\u30EC\u30B9",
                networkAddressBgp: "\u30D4\u30A2\u30EA\u30F3\u30B0\u30A2\u30C9\u30EC\u30B9",
                asnBgp: "ASN",
                descriptions: {
                  ipAddresses: "\u9078\u629E\u3055\u308C\u305FEdge Service\u306E\u516C\u958BIP\u30A2\u30C9\u30EC\u30B9\u3002\u30EA\u30E2\u30FC\u30C8\u30C7\u30D0\u30A4\u30B9\u306E\u8A2D\u5B9A\u306E\u305F\u3081\u306B\u5FC5\u9808\u3067\u3059\u3002",
                  localId: "\u30C8\u30F3\u30CD\u30EB\u306E\u30ED\u30FC\u30AB\u30EB\u30B5\u30A4\u30C9\u306EIPsec ID\u3002",
                  networkAddresses: "\u30C8\u30F3\u30CD\u30EB\u306E\u30ED\u30FC\u30AB\u30EB\u30B5\u30A4\u30C9\u3067\u5230\u9054\u53EF\u80FD\u306A\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF",
                  networkAddressBgp: "\u30ED\u30FC\u30AB\u30EB\u30B5\u30A4\u30C9\u3067\u306EBGP\u901A\u4FE1\u7528\u306B\u4F7F\u7528\u3055\u308C\u3066\u3044\u308BIP\u30A2\u30C9\u30EC\u30B9\u3002",
                  asnBgp: "\u30ED\u30FC\u30AB\u30EB\u30B5\u30A4\u30C9\u306E\u81EA\u5F8B\u30B7\u30B9\u30C6\u30E0\u306E\u756A\u53F7",
                  enableBgp: "\u30ED\u30FC\u30AB\u30EB\u30B5\u30A4\u30C8\u3068\u30EA\u30E2\u30FC\u30C8\u30B5\u30A4\u30C8\u3067\u5230\u9054\u53EF\u80FD\u306A\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3092BGP\u7D4C\u7531\u3067\u52D5\u7684\u306B\u4EA4\u63DB\u3055\u305B\u308B\u3053\u3068\u3092\u6709\u52B9\u306B\u3057\u3066\u304F\u3060\u3055\u3044\u3002"
                },
                sourceTypeData: {
                  gateway: "Edge Service",
                  site: "\u30B5\u30A4\u30C8"
                },
                localWans: "\u4F4F\u6240",
                primaryAddress: "\u30D7\u30E9\u30A4\u30DE\u30EA\u30A2\u30C9\u30EC\u30B9",
                additionalAddresses: "\u8FFD\u52A0\u306E\u30A2\u30C9\u30EC\u30B9"
              },
              destination: {
                title: "\u9001\u308A\u5148",
                remoteGatewayName: "\u540D\u524D",
                remoteGateway: "\u30EA\u30E2\u30FC\u30C8\u30FB\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4",
                remoteId: "\u30EA\u30E2\u30FC\u30C8ID",
                networkAddresses: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30A2\u30C9\u30EC\u30B9",
                networkAddressBgp: "\u30D4\u30A2\u30EA\u30F3\u30B0\u30A2\u30C9\u30EC\u30B9",
                asnBgp: "ASN",
                multiInputTitle: "\u30EA\u30E2\u30FC\u30C8\u30FB\u30B2\u30FC\u30C8\u30A6\u30A7\u30A4",
                multiInputTitleLabel: "\u9001\u308A\u5148",
                descriptions: {
                  remoteId: "\u30C8\u30F3\u30CD\u30EB\u306E\u30EA\u30E2\u30FC\u30C8\u30B5\u30A4\u30C9\u306EIPsec ID\u3002",
                  networkAddresses: "\u30C8\u30F3\u30CD\u30EB\u306E\u30EA\u30E2\u30FC\u30C8\u30B5\u30A4\u30C9\u3067\u5230\u9054\u53EF\u80FD\u306A\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u3002",
                  networkAddressBgp: "\u30EA\u30E2\u30FC\u30C8\u30B5\u30A4\u30C9\u3067BGP\u901A\u4FE1\u306B\u4F7F\u7528\u3055\u308C\u3066\u3044\u308BIP\u30A2\u30C9\u30EC\u30B9\u3002",
                  asnBgp: "\u30EA\u30E2\u30FC\u30C8\u30B5\u30A4\u30C9\u306E\u81EA\u5F8B\u30B7\u30B9\u30C6\u30E0\u306E\u756A\u53F7\u3002",
                  remoteGateway: "\u30C8\u30F3\u30CD\u30EB\u306E\u30EA\u30E2\u30FC\u30C8\u30B5\u30A4\u30C9\u306EFQDN\u307E\u305F\u306FIP\u3002\u30ED\u30FC\u30AB\u30EB\u30B5\u30A4\u30C9\u304C\u30C8\u30F3\u30CD\u30EB\u3092\u958B\u59CB\u3057\u306A\u3044\u5834\u5408\u3001\u3053\u3053\u30920.0.0.0/0\u306B\u8A2D\u5B9A\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002"
                }
              }
            },
            phasesSection: {
              descriptionMessage: "\u30C8\u30F3\u30CD\u30EB\u306B\u306F2\u6BB5\u968E\u3042\u308A\u307E\u3059\u3002\u6BB5\u968E\u5225\u306B\u512A\u5148\u3057\u305F\u3044\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3 \u8A2D\u5B9A\u3092\u30BB\u30C3\u30C8\u30A2\u30C3\u30D7\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
              phase1: {
                title: "1\u6BB5\u968E",
                encryption: "\u6697\u53F7\u5316",
                hash: "\u30CF\u30C3\u30B7\u30E5",
                dhGroup: "DH\u30B0\u30EB\u30FC\u30D7",
                proposalHandling: "\u63D0\u6848\u306E\u30CF\u30F3\u30C9\u30EA\u30F3\u30B0",
                lifetime: "\u30E9\u30A4\u30D5\u30BF\u30A4\u30E0",
                descriptions: {
                  proposalHandling: "\u9078\u629E\u3055\u308C\u305F\u6697\u53F7\u5316\u306E\u307F\u306B\u5236\u9650\u3059\u308B\u8A2D\u5B9A\u3067\u3042\u308C\u3070\u3001\u30CF\u30C3\u30B7\u30E5\u30FB\u30A2\u30EB\u30B4\u30EA\u30BA\u30E0\u304A\u3088\u3073Diffie Hellman\u30B0\u30EB\u30FC\u30D7\u306E\u307F\u4F7F\u7528\u53EF\u80FD\u3068\u306A\u308A\u3001\u305D\u308C\u4EE5\u5916\u306E\u5834\u5408\u306B\u30CD\u30B4\u30B7\u30A8\u30FC\u30C8\u3059\u308B\u3088\u3046\u306B\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u308B\u3068\u3001\u30A4\u30CB\u30B7\u30A8\u30FC\u30BF\u30FC\u306F\u7570\u306A\u308B\u30A2\u30EB\u30B4\u30EA\u30BA\u30E0\u3068DH\u30B0\u30EB\u30FC\u30D7\u306E\u96C6\u5408\u3092\u9078\u629E\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
                  lifetime: "IPsec (SA)\u304C\u518D\u30AD\u30FC\u3055\u308C\u308B\u307E\u3067\u306E\u79D2\u6570\u3002"
                }
              },
              phase2: {
                title: "2\u6BB5\u968E",
                lifetime: "\u30E9\u30A4\u30D5\u30BF\u30A4\u30E0",
                trafficVolume: "\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u30FB\u30DC\u30EA\u30E5\u30FC\u30E0\u6709\u52B9",
                trafficVolumeKb: "\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u30FB\u30DC\u30EA\u30E5\u30FC\u30E0KB",
                encryption: "\u6697\u53F7\u5316",
                hash: "\u30CF\u30C3\u30B7\u30E5",
                dhGroup: "DH\u30B0\u30EB\u30FC\u30D7",
                proposalHandling: "\u63D0\u6848\u306E\u30CF\u30F3\u30C9\u30EA\u30F3\u30B0",
                descriptions: {
                  proposalHandling: "\u9078\u629E\u3055\u308C\u305F\u6697\u53F7\u5316\u306E\u307F\u306B\u5236\u9650\u3059\u308B\u8A2D\u5B9A\u3067\u3042\u308C\u3070\u3001\u30CF\u30C3\u30B7\u30E5\u30FB\u30A2\u30EB\u30B4\u30EA\u30BA\u30E0\u304A\u3088\u3073Diffie Hellman\u30B0\u30EB\u30FC\u30D7\u306E\u307F\u4F7F\u7528\u53EF\u80FD\u3068\u306A\u308A\u3001\u305D\u308C\u4EE5\u5916\u306E\u5834\u5408\u306B\u30CD\u30B4\u30B7\u30A8\u30FC\u30C8\u3059\u308B\u3088\u3046\u306B\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u308B\u3068\u3001\u30A4\u30CB\u30B7\u30A8\u30FC\u30BF\u30FC\u306F\u7570\u306A\u308B\u30A2\u30EB\u30B4\u30EA\u30BA\u30E0\u3068DH\u30B0\u30EB\u30FC\u30D7\u306E\u96C6\u5408\u3092\u9078\u629E\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
                  lifetime: "IPsec (SA)\u304C\u518D\u30AD\u30FC\u3055\u308C\u308B\u307E\u3067\u306E\u79D2\u6570\u3002",
                  trafficVolume: "\u6709\u52B9\u306A\u5834\u5408\u306F\u518D\u30AD\u30FC\u304C\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u30FB\u30DC\u30EA\u30E5\u30FC\u30E0\u306B\u57FA\u3065\u3044\u3066\u30C8\u30EA\u30AC\u30FC\u3055\u308C\u308B\u3053\u3068\u304C\u53EF\u80FD\u306B\u306A\u308A\u307E\u3059\u3002",
                  trafficVolumeKb: "SA\u304C\u518D\u30AD\u30FC\u3055\u308C\u308B\u307E\u3067\u306E\u30AD\u30ED\u30D3\u30C3\u30C8(Kb)\u6570\u3002"
                }
              }
            },
            networkSection: {
              descriptionMessage: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u8A2D\u5B9A\u3092\u884C\u3063\u3066\u304F\u3060\u3055\u3044\u3002\u8A73\u7D30\u30AA\u30D7\u30B7\u30E7\u30F3\u304C\u3042\u308A\u3001\u4E00\u822C\u30C8\u30F3\u30CD\u30EB\u306B\u3064\u3044\u3066\u306F\u5FC5\u9808\u3067\u3042\u308A\u307E\u305B\u3093\u3002",
              networkSettings: {
                title: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u8A2D\u5B9A",
                oneTunnelSubnet: "\u30B5\u30D6\u30CD\u30C3\u30C8\u30FB\u30DA\u30A2\u5F53\u305F\u308A\u4E00\u3064\u306EVPN\u30C8\u30F3\u30CD\u30EB",
                universalTrafficSelectors: "\u30E6\u30CB\u30D0\u30FC\u30B5\u30EB\u30FB\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u30FB\u30BB\u30EC\u30AF\u30BF\u30FC",
                forceUdpEncapsulation: "UDP\u30AB\u30D7\u30BB\u30EB\u5316\u3092\u5F37\u5236",
                ikeReauthentication: "IKE\u518D\u8A8D\u8A3C",
                descriptions: {
                  oneTunnelSubnet: "\u30ED\u30FC\u30AB\u30EB\u3068\u30EA\u30E2\u30FC\u30C8\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u306E\u4E00\u30DA\u30A2\u5F53\u305F\u308A\u4E00\u3064\u306ESA\u3092\u4F5C\u6210\u3057\u307E\u3059\u3002",
                  universalTrafficSelectors: "\u6709\u52B9\u306A\u5834\u5408\u306F\u3059\u3079\u3066\u306E\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u304C\u30C8\u30F3\u30CD\u30EB\u3092\u901A\u308C\u308B\u3088\u3046\u306B\u306A\u308A\u307E\u3059\u3002",
                  forceUdpEncapsulation: "\u30CD\u30C3\u30C8\u30EF\u30FC\u30AF\u30A2\u30C9\u30EC\u30B9\u5909\u63DB\u304C\u691C\u51FA\u3055\u308C\u3066\u3044\u306A\u304F\u3066\u3082\u3001IPsec\u30C8\u30F3\u30CD\u30EB\u30FB\u30A8\u30F3\u30D9\u30ED\u30FC\u30D7\u306E\u305F\u3081\u306B\u306FUDP\u30DD\u30FC\u30C8 4500\u3092\u4F7F\u7528\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
                  ikeReauthentication: "SA\u304C\u518D\u30AD\u30FC\u3055\u308C\u308B\u305F\u3073\u306B\u8A8D\u8A3C\u3057\u3066\u304F\u3060\u3055\u3044\u3002"
                }
              },
              deadPeerDetection: {
                title: "\u505C\u6B62\u30D4\u30A2\u30FC\u306E\u691C\u51FA",
                actionDetected: "\u691C\u51FA\u6642\u306E\u52D5\u4F5C",
                delay: "\u9045\u5EF6",
                descriptions: {
                  actionDetected: "\u30EA\u30E2\u30FC\u30C8\u30B5\u30A4\u30C9\u304CDPD\u30E1\u30C3\u30BB\u30FC\u30B8\u306B\u5FDC\u7B54\u3057\u306A\u3044\u5834\u5408\u306B\u5B9F\u884C\u3055\u308C\u308B\u52D5\u4F5C\u3002\u30AF\u30EA\u30A2\u3067\u30C8\u30F3\u30CD\u30EB\u3092\u7D42\u4E86\u3057\u3001\u518D\u8D77\u52D5\u3067\u30C8\u30F3\u30CD\u30EB\u518D\u78BA\u7ACB\u304C\u8A66\u884C\u3055\u308C\u3001\u7121\u3057\u3067DPD\u304C\u7121\u52B9\u306B\u306A\u308A\u307E\u3059\u3002",
                  delay: "\u4ED6\u306E\u30C8\u30E9\u30D5\u30A3\u30C3\u30AF\u3092\u53D7\u4FE1\u3057\u3066\u3044\u306A\u3044\u5834\u5408\u306BDPD\u306E\u300Ckeep alive\u300D\u30E1\u30C3\u30BB\u30FC\u30B8\u304C\u9001\u3089\u308C\u308B\u307E\u3067\u306E\u7D4C\u904E\u79D2\u6570\u3002"
                }
              }
            }
          },
          noData: {
            buttonText: "IPsec\u63A5\u7D9A\u3092\u8FFD\u52A0",
            iconHeader: "IPsec\u63A5\u7D9A\u306E\u8FFD\u52A0\u3092\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
            labelButton: "IPsec\u63A5\u7D9A\u3092\u8FFD\u52A0",
            textHeader: "IPsec\u63A5\u7D9A\u3092\u7D71\u5408\u3059\u3079\u304D\u7406\u7531",
            text: "SecureEdge\u30C7\u30A3\u30D7\u30ED\u30A4\u30E1\u30F3\u30C8\u3067\u306F\u5F0A\u793E\u958B\u767A\u306B\u3088\u308BBarracuda TINA VPN\u3092\u4F7F\u7528\u3059\u308B\u306E\u3067\u63A5\u7D9A\u304C\u5B89\u5168\u304B\u3064\u4FE1\u983C\u3067\u304D\u307E\u3059\u3002IPsec IKEv2\u3092\u5229\u7528\u53EF\u80FD\u306A\u30B5\u30FC\u30C9\u30D1\u30FC\u30C6\u30A3\u30FC\u306E\u30C7\u30A3\u30D7\u30ED\u30A4\u30E1\u30F3\u30C8\u3092\u63A5\u7D9A\u3059\u308B\u306B\u306F\u3053\u306E\u8A2D\u5B9A\u30DA\u30FC\u30B8\u3092\u3054\u89A7\u304F\u3060\u3055\u3044\u3002\u8A2D\u5B9A\u304C\u5B8C\u4E86\u3059\u308B\u3068\u76F4\u3061\u306B\u3001\u3053\u306E\u30DA\u30FC\u30B8\u3067IPsec VPN\u63A5\u7D9A\u306E\u30B9\u30C6\u30FC\u30BF\u30B9\u3092\u78BA\u8A8D\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002"
          }
        },
        azureMonitor: "Azure\u30E2\u30CB\u30BF\u30FC (Azure Monitor)"
      },
      copy: {
        label: "\u30AF\u30EA\u30C3\u30D7\u30DC\u30FC\u30C9\u306B\u30B3\u30D4\u30FC"
      },
      subscription: {
        pam: {
          menuTitle: "\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3",
          pageTitle: "\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB",
          pageSubTitle: "\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3",
          table: {
            subscription: "\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3",
            serial: "\u30E9\u30A4\u30BB\u30F3\u30B9\u306E\u30B7\u30EA\u30A2\u30EB\u756A\u53F7",
            amount: "\u91D1\u984D",
            startDate: "\u958B\u59CB\u65E5",
            expirationDate: "\u6E80\u4E86\u65E5",
            noData: {
              iconHeader: "\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3\u3092\u8FFD\u52A0\u3057\u3066\u958B\u59CB\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
              textHeader: "\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3\u30FB\u30E9\u30A4\u30BB\u30F3\u30B9\u306F\u3044\u304B\u306BGET\u3067\u304D\u307E\u3059\u304B\u3002",
              text: "\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3\u3067SecureEdge\u30C7\u30A3\u30D7\u30ED\u30A4\u30E1\u30F3\u30C8\u306B\u8FFD\u52A0\u6A5F\u80FD\u3092\u6709\u52B9\u5316\u3057\u307E\u3059\u3002<0/>\u4F8B\u3048\u3070\u3001\u30EA\u30E2\u30FC\u30C8\u30FB\u30EF\u30FC\u30AF\u30D5\u30A9\u30FC\u30B9\u306E\u305F\u3081\u306E\u8FFD\u52A0SecureEdge Access\u30B7\u30FC\u30C8\u3092\u8FFD\u52A0\u3059\u308B\u5834\u5408\u3001\u3053\u3053\u306E\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9\u3092\u4F7F\u3063\u3066\u5FC5\u8981\u306A\u30B7\u30FC\u30C8\u6570\u3092\u30C7\u30A3\u30D7\u30ED\u30A4\u30E1\u30F3\u30C8\u306B\u8FFD\u52A0\u3059\u308B\u3060\u3051\u3067\u3059\u3002<0/>\u30CB\u30FC\u30BA\u306B\u5408\u3046\u6700\u9069\u306A\u30B5\u30D6\u30B9\u30AF\u30EA\u30D7\u30B7\u30E7\u30F3\u3092GET\u3059\u308B\u306B\u306F\u3001Barracuda Sales (barracuda.com/contact-sales)\u306B\u304A\u554F\u3044\u5408\u308F\u305B\u304F\u3060\u3055\u3044\u3002"
            },
            information: "\u60C5\u5831",
            value: "{{value}}",
            value_34: "{{value}} \u30E6\u30FC\u30B6\u30FC",
            value_24: "{{value}} Mbps\uFF08\u6BCE\u79D2\u30E1\u30AC\u30D3\u30C3\u30C8\uFF09",
            msp: "MSP\u8ACB\u6C42\u66F8\u306F\u767A\u884C\u6E08\u307F\u3067\u3059"
          }
        }
      },
      customCategories: {
        table: {
          name: "\u540D\u524D",
          description: "\u5185\u5BB9",
          details: "\u8A73\u7D30",
          detailCategories: "\u30AB\u30C6\u30B4\u30EA\u30FC ({{activeCategories.0.name}}, +{{additional}}) ",
          detailCategories_1: "\u30AB\u30C6\u30B4\u30EA\u30FC ({{activeCategories.0.name}}) ",
          detailDomains: "\u30C9\u30E1\u30A4\u30F3({{domains.0}}, +{{additionalDomains}})\u3092\u542B\u3080",
          detailDomains_1: "\u30C9\u30E1\u30A4\u30F3({{domains.0}})\u3092\u542B\u3080",
          domainsExcluded: "\u9664\u5916\u30C9\u30E1\u30A4\u30F3",
          domainsIncluded: "\u5305\u542B\u3055\u308C\u3066\u3044\u308B\u30C9\u30E1\u30A4\u30F3",
          noData: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC\u306E\u30EB\u30FC\u30EB\u304C\u4F55\u3082\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002"
        },
        dialog: {
          create: {
            buttonText: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC\u3092\u8FFD\u52A0",
            dialogTitle: "\u65B0\u898F\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC"
          },
          edit: {
            dialogTitle: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC\u3092\u7DE8\u96C6"
          },
          name: "\u540D\u524D",
          description: "\u5185\u5BB9",
          details: "\u8A73\u7D30",
          includedDomains: "\u5305\u542B\u3055\u308C\u3066\u3044\u308B\u30C9\u30E1\u30A4\u30F3",
          excludedDomains: "\u9664\u5916\u30C9\u30E1\u30A4\u30F3",
          descriptions: {
            name: "\u3053\u306E\u30EB\u30FC\u30EB\u306E\u305F\u3081\u306E\u56FA\u6709\u540D\u3002",
            description: "\u3053\u306E\u30EB\u30FC\u30EB\u306E\u5185\u5BB9\u6982\u7565\u3002",
            includedDomains: "\u3053\u306E\u30EB\u30FC\u30EB\u3067\u5F71\u97FF\u3092\u53D7\u3051\u308B\u30C9\u30E1\u30A4\u30F3\u306E\u30EA\u30B9\u30C8",
            excludedDomains: "\u3053\u306E\u30EB\u30FC\u30EB\u3067\u5F71\u97FF\u3092\u53D7\u3051\u306A\u3044\u5168\u30C9\u30E1\u30A4\u30F3\u306E\u30EA\u30B9\u30C8"
          },
          validation: {
            emptyCategories: "\u5C11\u306A\u304F\u3068\u3082\u4E00\u3064\u306E\u30AB\u30C6\u30B4\u30EA\u307E\u305F\u306F\u30C9\u30E1\u30A4\u30F3\u3092\u6307\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044"
          },
          delete: {
            title: "\u524A\u9664 {{name}}",
            body: "\u3053\u306E\u30EB\u30FC\u30EB\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3053\u306E\u30AB\u30B9\u30BF\u30E0\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC\u3092\u4F7F\u7528\u3059\u308B\u3069\u306E\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30EB\u30FC\u30EB\u3082\u66F4\u65B0\u307E\u305F\u306F\u524A\u9664\u3055\u308C\u307E\u3059\u3002"
          }
        }
      },
      syslog: {
        pageTitle: "Syslog\u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0",
        certificateCName: "\u8A3C\u660E\u66F8\u306E\u5171\u901A\u540D",
        certificateExpiryDate: "\u8A3C\u660E\u66F8\u306E\u6709\u52B9\u671F\u9650\u65E5",
        enable: "Syslog\u3092\u6709\u52B9\u5316",
        logs: "Logs",
        port: "\u30DD\u30FC\u30C8",
        protocol: "\u30D7\u30ED\u30C8\u30B3\u30EB",
        securityProtocol: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30FB\u30D7\u30ED\u30C8\u30B3\u30EB",
        server: "Syslog \u30B5\u30FC\u30D0\u30FC",
        syslogOverride: "Syslog \u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0\u3092\u7121\u52B9\u5316",
        uploadSslCert: "SSL\u8A3C\u660E\u66F8\u3092\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9",
        descriptions: {
          port: "Syslog\u30B5\u30FC\u30D0\u30FC\u306E\u30BF\u30FC\u30B2\u30C3\u30C8\u30DD\u30FC\u30C8",
          protocol: "Syslog\u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0\u7528\u306B\u4F7F\u7528\u3059\u308B\u30C7\u30FC\u30BF\u8EE2\u9001\u30D7\u30ED\u30C8\u30B3\u30EB\u3002",
          uploadSslCert: "Syslog\u30B5\u30FC\u30D0\u30FC\u7528\u306E\u516C\u958B\u8A3C\u660E\u66F8\u3002",
          logs: "\u30B9\u30C8\u30EA\u30FC\u30E0\u3059\u308B\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3Logs\u3002",
          server: "Syslog\u30B5\u30FC\u30D0\u30FC\u306E\u30DB\u30B9\u30C8\u540D\u307E\u305F\u306FIP\u3002",
          securityProtocol: "Syslog\u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0\u7528\u306B\u4F7F\u7528\u3059\u308B\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u30FB\u30D7\u30ED\u30C8\u30B3\u30EB\u3002",
          syslogOverride_site: "\u3053\u306E\u30B5\u30A4\u30C8\u3067\u306FSyslog\u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0\u3092\u7121\u52B9\u306B\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          syslogOverride_gateway: "\u3053\u306EEdge Service\u306B\u3064\u3044\u3066\u306FSyslog\u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0\u3092\u7121\u52B9\u306B\u3057\u3066\u304F\u3060\u3055\u3044\u3002"
        }
      },
      xdr: {
        pageTitle: "Barracuda XDR",
        enable: "\u30B9\u30C8\u30EA\u30FC\u30DF\u30F3\u30B0\u3092\u6709\u52B9\u5316",
        sites: "\u30B5\u30A4\u30C8",
        gateways: "Edge Services",
        hostname: "\u30DB\u30B9\u30C8\u540D",
        port: "\u30DD\u30FC\u30C8",
        descriptions: {
          sites: "Barracuda XDR\u3078Logs\u3092\u30B9\u30C8\u30EA\u30FC\u30E0\u3059\u308B\u3053\u3068\u306B\u306A\u3063\u3066\u3044\u308B\u30B5\u30A4\u30C8\u3002",
          gateways: "Logs\u3092Barracuda XDR\u306B\u30B9\u30C8\u30EA\u30FC\u30E0\u4E88\u5B9A\u306EEdge Services\u3002",
          hostname: "\u30B9\u30C8\u30EA\u30FC\u30E0\u5148\u306EBarracuda XDR\u30DB\u30B9\u30C8\u540D\u3002",
          port: "\u30B9\u30C8\u30EA\u30FC\u30E0\u5148\u306EBarracuda XDR\u30DD\u30FC\u30C8\u3002"
        }
      },
      webFilterSettings: {
        safeSearch: "Safe Search",
        silentAdBlocking: "\u30B5\u30A4\u30EC\u30F3\u30C8\u5BA3\u4F1D\u30D6\u30ED\u30C3\u30AF",
        descriptions: {
          safeSearch: "\u30A4\u30F3\u30BF\u30FC\u30CD\u30C3\u30C8\u30FB\u30B5\u30FC\u30C1\u30A8\u30F3\u30B8\u30F3\u3067\u300CSafeSearch\u300D\u30E2\u30FC\u30C9\u3092\u5F37\u5236\u3057\u307E\u3059\u3002SafeSearch\u3092\u5F37\u5236\u3059\u308B\u306B\u306F\u3001\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3 SSL Inspection\u304C\u6709\u52B9\u306B\u306A\u3063\u3066\u304A\u308A\u3001\u8A2D\u5B9A\u6E08\u307F\u3067\u3042\u308B\u3053\u3068\u304C\u524D\u63D0\u3067\u3059\u3002",
          silentAdBlocking: "URL \u30AB\u30C6\u30B4\u30EA\u30FC\u300C\u5BA3\u4F1D\u307E\u305F\u306F\u30D0\u30CA\u30FC\u300D\u3092\u30B5\u30A4\u30EC\u30F3\u30C8\u306B\u963B\u6B62\u3057\u307E\u3059\u3002"
        }
      },
      webMonitorPolicies: {
        webConfigHelpText: "\u30E6\u30FC\u30B6\u30FC\u306E\u30A6\u30A7\u30D6\u30FB\u30B5\u30FC\u30C1\u3067\u7279\u5B9A\u306E\u30AD\u30FC\u30EF\u30FC\u30C9\u306E\u30E2\u30CB\u30BF\u30EA\u30F3\u30B0\u3092\u6709\u52B9\u5316\u307E\u305F\u306F\u7121\u52B9\u5316\u3057\u307E\u3059\u3002\u6709\u52B9\u306A\u5834\u5408\u3001Syslog\u30B5\u30FC\u30D0\u30FC\u3078\u4E00\u81F4\u30AD\u30FC\u30EF\u30FC\u30C9(Service Logs\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u306E\u4E00\u90E8\u3092\u6210\u3059\u30A6\u30A7\u30D6\u30FB\u30E2\u30CB\u30BF\u30FC\u30FB\u30A2\u30E9\u30FC\u30C8Log\u306B\u5168\u9805\u76EE\u304C\u56DE\u53CE\u3055\u308C\u308B)\u3092\u30B9\u30C8\u30EA\u30FC\u30E0\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002<0/>\u5229\u4FBF\u6027\u306E\u305F\u3081\u3001\u5B9A\u7FA9\u6E08\u307F\u306E\u30AD\u30FC\u30EF\u30FC\u30C9\u30AB\u30C6\u30B4\u30EA\u30FC\u304B\u3089\u9078\u629E\u3057\u305F\u308A\u3001\u660E\u793A\u7684\u306B\u8981\u4EF6\u3068\u4E00\u81F4\u3059\u308B\u30AB\u30B9\u30BF\u30E0\u30FB\u30AD\u30FC\u30EF\u30FC\u30C9\u3092\u8FFD\u52A0\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
        enabled: "\u30A6\u30A7\u30D6\u30FB\u30E2\u30CB\u30BF\u30EA\u30F3\u30B0\u3092\u6709\u52B9\u5316",
        webMonitoringTitle: "\u30AD\u30FC\u30EF\u30FC\u30C9\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC",
        customKeywordTitle: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30AD\u30FC\u30EF\u30FC\u30C9\u30FB\u30BB\u30C3\u30C8",
        customKeywordHelpText: "\u30C6\u30AD\u30B9\u30C8\u30D5\u30A3\u30FC\u30EB\u30C9\u306B\u30AB\u30B9\u30BF\u30E0\u30FB\u30AD\u30FC\u30EF\u30FC\u30C9\u3092\u5165\u529B\u3057\u3066\u300C+\u300D\u30DC\u30BF\u30F3\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u3001\u3053\u308C\u3092\u30E2\u30CB\u30BF\u30EA\u30F3\u30B0\u30FB\u30EA\u30B9\u30C8\u306B\u8FFD\u52A0\u3057\u307E\u3059\u3002",
        customKeywords: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30AD\u30FC\u30EF\u30FC\u30C9",
        monitored: "\u30E2\u30CB\u30BF\u30FC\u3055\u308C\u3066\u3044\u308B"
      },
      customResponse: {
        setup: "\u30BB\u30C3\u30C8\u30A2\u30C3\u30D7\u30FB\u30AB\u30B9\u30BF\u30E0\u30FB\u30EC\u30B9\u30DD\u30F3\u30B9\u306E\u30DA\u30FC\u30B8",
        setupHelpText: "\u3053\u3053\u3067\u30AB\u30B9\u30BF\u30E0\u30FB\u30EC\u30B9\u30DD\u30F3\u30B9\u30DA\u30FC\u30B8\u306E\u30BB\u30C3\u30C8\u30A2\u30C3\u30D7\u304C\u3067\u304D\u307E\u3059\u3002\u4FDD\u5B58\u3059\u308B\u3068\u3053\u306E\u30EC\u30B9\u30DD\u30F3\u30B9\u30DA\u30FC\u30B8\u304C\u3059\u3079\u3066\u306E\u30A2\u30AF\u30BB\u30B9\u3057\u3066\u3044\u308B\u30E6\u30FC\u30B6\u30FC\u306B\u898B\u3048\u307E\u3059\u3002",
        barracudaBranding: "Barracuda\u30D6\u30E9\u30F3\u30C7\u30A3\u30F3\u30B0",
        customLogo: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30ED\u30B4",
        customText: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30C6\u30AD\u30B9\u30C8",
        showBarracudaBranding: "\u30D6\u30E9\u30F3\u30C7\u30A3\u30F3\u30B0\u3092\u8868\u793A",
        descriptions: {
          customText: "\u30AB\u30B9\u30BF\u30E0\u30FB\u30ED\u30B4\u306E\u4E0B\u306B\u8868\u793A\u3055\u308C\u307E\u3059\u3002<0/>\u30AB\u30B9\u30BF\u30E0\u30FB\u30C6\u30AD\u30B9\u30C8\u3092\u4F7F\u7528\u3057\u306A\u3044\u5834\u5408\u306F\u3053\u3053\u3092\u7A7A\u306B\u3057\u3066\u304A\u3044\u3066\u304F\u3060\u3055\u3044\u3002",
          customLogo: "\u6700\u4E0A\u90E8\u306B\u51FA\u307E\u3059\u3002\u5BFE\u5FDC\u30D5\u30A1\u30A4\u30EB\u5F62\u5F0F\uFF1AJPEG\u3001PNG\u3001GIF\u3001SVG\u3002"
        }
      },
      identity: {
        menuTitle: "\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3",
        pageTitle: "\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3",
        pageSubtitle: "\u8A2D\u5B9A",
        identityProviders: {
          title: "\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC",
          addNotification: "\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u306F\u6B63\u5E38\u306B\u8FFD\u52A0\u3055\u308C\u307E\u3057\u305F",
          noDataMessage: "\u307E\u3060\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u306A\u3044\u3088\u3046\u3067\u3059\u306D\u3002",
          clickBelow: "\u4E0B\u306E\u30DC\u30BF\u30F3\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u3066\u8FFD\u52A0\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          createIdentityProvider: "\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u8FFD\u52A0",
          saml: {
            informationNeeded: "\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u304C\u5FC5\u8981\u3068\u3057\u3066\u3044\u308B\u60C5\u5831",
            entityId: "\u30A8\u30F3\u30C6\u30A3\u30C6\u30A3\u30FCID",
            acsURL: "ACS URL"
          },
          add: {
            title: "\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u8FFD\u52A0",
            createStep: "SAML\u30B5\u30A4\u30F3\u30A4\u30F3\u8A2D\u5B9A",
            setupStep: "\u30BB\u30C3\u30C8\u30A2\u30C3\u30D7SAML",
            button: "\u8FFD\u52A0",
            buttonDisabled: "\u4E00\u5EA6\u306B\u4E00\u3064\u306E\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u306E\u307F\u4FDD\u6709\u3059\u308B\u3053\u3068\u304C\u3067\u304D\u307E\u3059\u3002",
            types: {
              email: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
              entraid: "Microsoft Entra ID",
              bcc: "Barracuda Cloud Control",
              google: "Google\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9",
              oidc: "OpenID Connect",
              saml: "SAML 2.0"
            },
            options: {
              provider: "\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC",
              displayName: "\u30C7\u30A3\u30B9\u30D7\u30EC\u30A4\u540D",
              tenantId: "\u30C6\u30CA\u30F3\u30C8ID",
              clientId: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8ID",
              clientSecret: "\u30AF\u30E9\u30A4\u30A2\u30F3\u30C8\u30B7\u30FC\u30AF\u30EC\u30C3\u30C8",
              discoveryURL: "\u30C7\u30A3\u30B9\u30AB\u30D0\u30EA\u30FCURL",
              scope: "\u30B9\u30B3\u30FC\u30D7",
              googleWorkspaceDomain: "Google\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u30C9\u30E1\u30A4\u30F3",
              signInEndpoint: "\u30B5\u30A4\u30F3\u30A4\u30F3\u30A8\u30F3\u30C9\u30DD\u30A4\u30F3\u30C8",
              signingCert: "\u8A3C\u660E\u66F8\u306B\u7F72\u540D"
            },
            redirectUri: "\u30EA\u30C0\u30A4\u30EC\u30AF\u30C8URI"
          },
          table: {
            name: "\u540D\u524D",
            type: "\u30BF\u30A4\u30D7",
            status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
            noData: "\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002"
          },
          authorizationStatusField: {
            needs_consent: "\u8A8D\u8A3C\u304C\u5FC5\u8981\u3067\u3059",
            active: "\u30A2\u30AF\u30C6\u30A3\u30D6",
            error: "\u30A8\u30E9\u30FC"
          },
          menu: {
            test: "\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u3092\u30C6\u30B9\u30C8",
            setupSaml: "\u30BB\u30C3\u30C8\u30A2\u30C3\u30D7SAML"
          },
          delete: {
            title: "\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u3092\u524A\u9664",
            body: "\u3053\u306E\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3\u30D7\u30ED\u30D0\u30A4\u30C0\u30FC\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u308D\u3057\u3044\u3067\u3059\u304B\u3002"
          }
        },
        userDirectories: {
          title: "\u30A2\u30A4\u30C7\u30F3\u30C6\u30A3\u30C6\u30A3",
          subtitle: "\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA",
          tableName: "\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA",
          noDataMessage: "\u307E\u3060\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u304C\u8A2D\u5B9A\u3055\u308C\u3066\u3044\u306A\u3044\u3088\u3046\u3067\u3059\u306D\u3002",
          clickBelow: "\u4E0B\u306E\u30DC\u30BF\u30F3\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u3066\u8FFD\u52A0\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
          createDirectory: "\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u3092\u8FFD\u52A0",
          createDirectoryDialogTitle: "\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u3092\u8FFD\u52A0",
          editDirectory: "\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u3092\u7DE8\u96C6",
          displayName: "\u540D\u524D",
          type: "\u30BF\u30A4\u30D7",
          status: "\u30B9\u30C6\u30FC\u30BF\u30B9",
          statusField: {
            running: "\u5B9F\u884C\u4E2D",
            cancelled: "\u30AD\u30E3\u30F3\u30BB\u30EB",
            errored: "\u30A8\u30E9\u30FC\u767A\u751F",
            completed: "\u5B8C\u4E86",
            pending: "\u51E6\u7406\u4E2D",
            unknown: "\u4E0D\u660E",
            needs_consent: "\u8A8D\u8A3C\u304C\u5FC5\u8981\u3067\u3059"
          },
          sync: "\u540C\u671F",
          syncResult: "\u540C\u671F\u7D50\u679C",
          syncedNotification: "\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u306E\u540C\u671F\u304C\u958B\u59CB\u3055\u308C\u307E\u3057\u305F",
          syncErrors: "\u540C\u671F\u30A8\u30E9\u30FC",
          users_one: "{{count}} \u30E6\u30FC\u30B6\u30FC",
          users_other: "{{count}} \u30E6\u30FC\u30B6\u30FC",
          groups_one: "{{count}} \u30B0\u30EB\u30FC\u30D7",
          groups_other: "{{count}} \u30B0\u30EB\u30FC\u30D7",
          syncStatus: "{{users}}\u3001{{groups}}\u3001\u540C\u671F\u6E08\u307F",
          lastSuccessfulSync: "\u524D\u56DE\u306E\u540C\u671F",
          lastSyncAt: "\u524D\u56DE\u540C\u671F\u65E5 {{date}}",
          neverSynced: "\u540C\u671F\u3055\u308C\u305F\u3053\u3068\u306F\u306A\u3044",
          delete: {
            body: "\u3053\u306E\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u3092\u524A\u9664\u3057\u3066\u3082\u672C\u5F53\u306B\u3088\u3044\u306E\u3067\u3059\u304B\u3002",
            body2: "\u304A\u4F7F\u3044\u306E\u30A2\u30AB\u30A6\u30F3\u30C8\u3067\u306E\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u306E\u5909\u66F4\u3084\u524A\u9664\u306B\u3088\u3063\u3066\u65E2\u5B58\u306E\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3\u304A\u3088\u3073\u30A2\u30AF\u30BB\u30B9\u30DD\u30EA\u30B7\u30FC\u304C\u671F\u5F85\u901A\u308A\u306B\u6A5F\u80FD\u3057\u306A\u304F\u306A\u308B\u3053\u3068\u304C\u3042\u308A\u307E\u3059\u3002\u3053\u308C\u3089\u3092\u898B\u76F4\u3057\u3066\u5FC5\u8981\u306A\u3068\u3053\u308D\u306F\u88DC\u6B63\u3057\u3066\u304F\u3060\u3055\u3044\u3002\u767B\u9332\u6E08\u307F\u30E6\u30FC\u30B6\u30FC\u306F\u307E\u3060\u30A2\u30AF\u30BB\u30B9\u3067\u304D\u307E\u3059\u3002\u767B\u9332\u6E08\u307F\u30E6\u30FC\u30B6\u30FC\u30DA\u30FC\u30B8\u3092\u4F7F\u7528\u3057\u3066\u524A\u9664\u3059\u3079\u304D\u30E6\u30FC\u30B6\u30FC\u3092\u7BA1\u7406\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
            title: "\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u3092\u524A\u9664 {{name}}"
          },
          form: {
            title: "\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA",
            displayName: "\u30C7\u30A3\u30B9\u30D7\u30EC\u30A4\u540D",
            tenantId: "\u30C6\u30CA\u30F3\u30C8ID",
            type: {
              label: "\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u30BF\u30A4\u30D7",
              bcc: "Barracuda Cloud Control",
              entraid: "Microsoft Entra ID",
              okta: "Okta",
              ldap: "LDAP",
              google: "Google\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9"
            },
            ldap: {
              steps: {
                userDirectory: "\u30E6\u30FC\u30B6\u30FC\u30C7\u30A3\u30EC\u30AF\u30C8\u30EA\u60C5\u5831",
                auth: "\u8A8D\u8A3C",
                tls: "TLS\u8A2D\u5B9A"
              },
              host: {
                label: "\u30DB\u30B9\u30C8",
                placeholder: "\u4F8B\uFF1Aldap.barracuda.com"
              },
              port: {
                label: "\u30DD\u30FC\u30C8",
                placeholder: "\u4F8B\uFF1A389"
              },
              profile: {
                label: "\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB",
                placeholder: "\u30D7\u30ED\u30D5\u30A3\u30FC\u30EB\u9078\u629E",
                ad: "\u5E83\u544A",
                custom: "\u30AB\u30B9\u30BF\u30E0"
              },
              userSearchBase: {
                label: "\u30E6\u30FC\u30B6\u30FC\u691C\u7D22\u30D9\u30FC\u30B9",
                placeholder: "\u4F8B\uFF1Adc=mydomain.com,dc=com"
              },
              groupSearchBase: {
                label: "\u30B0\u30EB\u30FC\u30D7\u691C\u7D22\u30D9\u30FC\u30B9",
                placeholder: "\u4F8B\uFF1Adc=mydomain.com,dc=com"
              },
              authenticationMethod: {
                label: "\u8A8D\u8A3C\u65B9\u6CD5",
                placeholder: "\u8A8D\u8A3C\u65B9\u6CD5\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044",
                anon: "Anon",
                simple: "\u30B7\u30F3\u30D7\u30EB",
                saslExternal: "SASL\u5916\u90E8",
                saslKerberos: "SASL Kerberos",
                ntlm: "NTLM"
              },
              username: "\u30E6\u30FC\u30B6\u30FC\u540D",
              password: "\u30D1\u30B9\u30EF\u30FC\u30C9",
              saslCredentials: "SASL\u8CC7\u683C\u60C5\u5831",
              useTls: {
                tlsSettings: "TLS\u8A2D\u5B9A",
                none: "\u306A\u3057",
                tls: "TLS",
                startTls: "StartTLS",
                encryptionMethod: "\u6697\u53F7\u5316\u65B9\u6CD5",
                ldapSni: {
                  label: "SNI\u30DB\u30B9\u30C8\u540D",
                  placeholder: "\u4F8B\uFF1Aexample.org"
                },
                ldapPrivkey: {
                  label: "\u79D8\u5BC6\u9375",
                  placeholder: "\u3053\u3053\u306B\u79D8\u5BC6\u9375\u3092\u8CBC\u308A\u4ED8\u3051\u307E\u3059"
                },
                ldapPrivkeyPassword: "\u30D1\u30B9\u30EF\u30FC\u30C9",
                ldapPubkey: {
                  label: "\u516C\u958B\u9375",
                  placeholder: "\u3053\u3053\u306B\u516C\u958B\u9375\u3092\u8CBC\u308A\u4ED8\u3051\u307E\u3059"
                },
                ldapCacerts: {
                  label: "CA\u8A3C\u660E\u66F8",
                  placeholder: "\u3053\u3053\u306BCA\u8A3C\u660E\u66F8\u3092\u8CBC\u308A\u4ED8\u3051\u307E\u3059"
                },
                ldapCheckCerts: {
                  label: "\u8A3C\u660E\u66F8\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044",
                  description: "\u53D7\u4FE1\u3057\u305F\u30B5\u30FC\u30D0\u30FC\u8A3C\u660E\u66F8\u306E\u4FE1\u983C\u6027\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044"
                },
                ldapCheckHostname: {
                  label: "\u30DB\u30B9\u30C8\u540D\u3092\u78BA\u8A8D",
                  description: "\u8A3C\u660E\u66F8\u306E\u30DB\u30B9\u30C8\u540D\u306E\u6709\u52B9\u6027\u3092\u78BA\u8A8D"
                },
                ldapCertAdditionalNames: {
                  label: "\u8A3C\u660E\u66F8\u306E\u8FFD\u52A0\u540D",
                  placeholder: "\u4F8B\uFF1Aexample.org,company.com,dc1.example.org"
                }
              }
            },
            okta: {
              domainname: "Okta\u30C9\u30E1\u30A4\u30F3\u540D",
              authToken: "Okta\u8A8D\u8A3C\u30C8\u30FC\u30AF\u30F3"
            },
            google: {
              googleWorkspaceDomain: "Google\u30EF\u30FC\u30AF\u30B9\u30DA\u30FC\u30B9\u30C9\u30E1\u30A4\u30F3"
            },
            bcc: {
              environment: "\u74B0\u5883"
            }
          }
        },
        users: {
          title: "\u30E6\u30FC\u30B6\u30FC",
          subtitle: "\u30E6\u30FC\u30B6\u30FC",
          tableName: "\u30E6\u30FC\u30B6\u30FC",
          name: "\u540D\u524D",
          email: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
          noDataMessage: "\u30E6\u30FC\u30B6\u30FC\u306F\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002",
          nameOrEmail: "\u30E6\u30FC\u30B6\u30FC"
        },
        groups: {
          title: "\u30B0\u30EB\u30FC\u30D7",
          subtitle: "\u30B0\u30EB\u30FC\u30D7",
          tableName: "\u30B0\u30EB\u30FC\u30D7",
          name: "\u540D\u524D",
          email: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
          noDataMessage: "\u30B0\u30EB\u30FC\u30D7\u306F\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002"
        }
      }
    }
  }
};
export {
  ja_default as default
};
