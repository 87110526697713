import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { LoadingMessage } from "@cuda-react/core";
import { Box, Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { AddIdentityProviderButton } from "./AddIdentityProviderButton";
const i18nScope = "tesseract.identity.identityProviders";
const IdentityProvidersNoData = ({ onSelect, showLoading }) => {
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx(
    Box,
    {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "300px",
      children: showLoading ? /* @__PURE__ */ jsx(LoadingMessage, {}) : /* @__PURE__ */ jsxs(Fragment, { children: [
        /* @__PURE__ */ jsx(Typography, { children: translate(`${i18nScope}.noDataMessage`) }),
        /* @__PURE__ */ jsx(Typography, { mt: 1, mb: 2, children: translate(`${i18nScope}.clickBelow`) }),
        /* @__PURE__ */ jsx(AddIdentityProviderButton, { onSelect })
      ] })
    }
  );
};
export {
  IdentityProvidersNoData
};
