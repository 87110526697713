var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx } from "react/jsx-runtime";
import { useState } from "react";
import { BasicPage, Tab } from "@cuda-react/core";
import useDashboards from "../../../hooks/useDashboards";
import DashboardMenu from "../DashboardMenu";
import DashboardContent from "../DashboardContent";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  loading: {
    height: "75vh",
    transform: "none",
    width: "100%"
  }
});
const DashboardPage = (props) => {
  var _a;
  const {
    availableCards,
    title,
    remoteStoredDashboards,
    onDashboardsChange,
    defaultDashboards,
    filters,
    id,
    templates,
    additionalProps,
    oneTabDashboard,
    loadingRemoteStoredDashboards,
    maxDashboardsAmount
  } = props;
  const {
    activeDashboard = { name: "", cards: [], template: "" },
    addUpdateDashboard,
    setActiveDashboard,
    dashboards = [],
    deleteDashboard
  } = useDashboards(id, defaultDashboards, remoteStoredDashboards, onDashboardsChange);
  const [editMode, setEditMode] = useState(false);
  const [translate] = useTranslation();
  const activeCards = Array.isArray(activeDashboard == null ? void 0 : activeDashboard.cards) ? (_a = activeDashboard == null ? void 0 : activeDashboard.cards) == null ? void 0 : _a.filter((card) => card.componentId in availableCards) : [];
  const [cardEditBuffer, setCardEditBuffer] = useState(activeCards || []);
  const handleDashboardUpdate = (newDashboardSettings, originalDashboardName, reset) => {
    if (newDashboardSettings.name !== originalDashboardName && dashboards.find(({ name }) => name.toLowerCase() === newDashboardSettings.name.toLowerCase())) {
      return Promise.reject({ name: translate("stratosReporting.dashboardPage.uniqueNameError") });
    }
    if (reset) {
      setCardEditBuffer([]);
    }
    if (originalDashboardName && !reset) {
      addUpdateDashboard(newDashboardSettings, originalDashboardName);
    } else {
      const matchingTemplate = (templates || defaultDashboards).find(({ name }) => newDashboardSettings.template === name);
      addUpdateDashboard(__spreadProps(__spreadValues({}, newDashboardSettings), {
        cards: (matchingTemplate == null ? void 0 : matchingTemplate.cards) || []
      }), originalDashboardName);
    }
    return Promise.resolve({});
  };
  const classes = useStyles(props);
  const maxDashboardsAmountReached = !!maxDashboardsAmount && (dashboards == null ? void 0 : dashboards.length) >= maxDashboardsAmount;
  return !oneTabDashboard ? /* @__PURE__ */ jsx(BasicPage, { title, subtitle: loadingRemoteStoredDashboards ? "" : activeDashboard.name, children: loadingRemoteStoredDashboards ? /* @__PURE__ */ jsx(Skeleton, { className: classes.loading }) : /* @__PURE__ */ jsx(
    DashboardMenu,
    {
      onChange: (dashboardName) => {
        if (!editMode || confirm(translate("stratosReporting.dashboardPage.changeDashboardMessage"))) {
          setActiveDashboard(dashboardName);
          setEditMode(false);
        }
      },
      addUpdateDashboard: handleDashboardUpdate,
      deleteDashboard: (dashboard) => deleteDashboard(dashboard.name),
      activeDashboard,
      disabled: loadingRemoteStoredDashboards || editMode,
      templates: templates || defaultDashboards,
      maxDashboardsReached: maxDashboardsAmountReached,
      maxDashboardsReachedMessage: maxDashboardsAmountReached ? translate("stratosReporting.dashboardPage.maxNumberOfDashboardReached", { maxDashboardsAmount }) : void 0,
      children: dashboards.map((board) => /* @__PURE__ */ jsx(Tab, { label: board.name, value: board.name, children: /* @__PURE__ */ jsx(
        DashboardContent,
        {
          editMode,
          onChange: (cards) => {
            board = { name: board.name, cards };
            addUpdateDashboard(board, board.name);
          },
          setEditMode,
          cardEditBuffer: activeDashboard.name === board.name ? cardEditBuffer : [],
          setCardEditBuffer,
          activeDashboard: board,
          availableCards,
          filters,
          additionalProps,
          disabled: editMode,
          templates: templates || defaultDashboards,
          oneTabDashboard,
          addUpdateDashboard: handleDashboardUpdate
        },
        board.name
      ) }, board.name))
    }
  ) }) : /* @__PURE__ */ jsx(Fragment, { children: loadingRemoteStoredDashboards ? /* @__PURE__ */ jsx(Skeleton, { className: classes.loading }) : dashboards.map((board) => /* @__PURE__ */ jsx(
    DashboardContent,
    {
      editMode,
      onChange: (cards) => {
        board = { name: board.name, cards };
        addUpdateDashboard(board, board.name);
      },
      setEditMode,
      cardEditBuffer: activeDashboard.name === board.name ? cardEditBuffer : [],
      setCardEditBuffer,
      activeDashboard: board,
      availableCards,
      filters,
      additionalProps,
      disabled: editMode,
      templates: templates || defaultDashboards,
      oneTabDashboard,
      addUpdateDashboard: handleDashboardUpdate
    },
    board.name
  )) });
};
export {
  DashboardPage
};
