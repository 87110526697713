import { jsx, jsxs } from "react/jsx-runtime";
import { ColumnField, CustomField, DialogBody, DownloadButton } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import apiResources from "../../../../../apiResources";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "512px"
  },
  dialogContent: {
    whiteSpace: "pre-line",
    margin: theme.spacing(0, 2)
  },
  downloadButtonDiv: {
    margin: theme.spacing(0.5, 0, 1, 0)
  }
}));
const DownloadBootstrapConfigDialog = ({ onClose, serials, applianceType, isSc }) => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const renderDownloadButton = (value) => /* @__PURE__ */ jsx("div", { className: classes.downloadButtonDiv, children: /* @__PURE__ */ jsx(
    DownloadButton,
    {
      label: "tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.downloadButtonLabel",
      filename: "cloudgenwan-" + value,
      filetype: "gz",
      resource: applianceType === "site" ? apiResources.downloadSiteBootstrapConfiguration : apiResources.downloadGatewayBootstrapConfiguration,
      params: { serial: value },
      color: "primary",
      directDownload: true
    }
  ) });
  return /* @__PURE__ */ jsx("div", { className: classes.root, children: /* @__PURE__ */ jsxs(
    DialogBody,
    {
      onClose,
      title: "tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.title",
      children: [
        /* @__PURE__ */ jsx(Typography, { className: classes.dialogContent, children: /* @__PURE__ */ jsx(
          Trans,
          {
            i18nKey: isSc ? "tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.dialogMessageSc" : "tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.dialogMessage",
            values: { button: translate(serials[1] ? "tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.buttons" : "tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.button") },
            children: /* @__PURE__ */ jsx(
              "a",
              {
                target: "_blank",
                rel: "noopener noreferrer",
                href: "https://campus.barracuda.com/to/secureedge-bootstrap"
              }
            )
          }
        ) }),
        /* @__PURE__ */ jsxs(
          ColumnField,
          {
            columnCount: 1,
            columns: [{ xs: 10, md: 8 }],
            data: { serials },
            children: [
              /* @__PURE__ */ jsx(
                CustomField,
                {
                  label: translate("tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.serial", { serial: serials[0] }),
                  source: "serials[0]",
                  render: renderDownloadButton
                }
              ),
              serials[1] ? /* @__PURE__ */ jsx(
                CustomField,
                {
                  label: translate("tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.serial", { serial: serials[1] }),
                  source: "serials[1]",
                  render: renderDownloadButton
                }
              ) : null
            ]
          }
        )
      ]
    }
  ) });
};
var DownloadBootstrapConfigDialog_default = DownloadBootstrapConfigDialog;
export {
  DownloadBootstrapConfigDialog_default as default,
  useStyles
};
