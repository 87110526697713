var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { makeStyles } from "@mui/styles";
import {
  ConnectedForm,
  DialogBody,
  DualListInput,
  FormSection,
  getArrayDataContent,
  SelectInput,
  TextArrayInput,
  TextInput,
  useCrudProps
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
const styles = {
  dialogContent: {
    width: 750
  }
};
const useStyles = makeStyles(styles);
const NotificationContent = (_a) => {
  var _b = _a, {
    create,
    id,
    title,
    onSuccess
  } = _b, props = __objRest(_b, [
    "create",
    "id",
    "title",
    "onSuccess"
  ]);
  var _a2, _b2;
  const notificationStatus = (_a2 = useCrudProps(apiResources.auditStatuses, {}, {}, true)[0]) == null ? void 0 : _a2.data;
  const notificationEvents = (_b2 = useCrudProps(apiResources.auditEntriesEvents, {}, {}, true)[0]) == null ? void 0 : _b2.data;
  const classes = useStyles();
  return /* @__PURE__ */ jsx(
    DialogBody,
    __spreadProps(__spreadValues({}, props), {
      form: true,
      title,
      classes: { dialogContent: classes.dialogContent },
      children: /* @__PURE__ */ jsx(
        ConnectedForm,
        {
          create,
          flat: true,
          resource: apiResources.notification,
          id,
          onSubmitSuccess: () => {
            var _a3;
            onSuccess && onSuccess();
            (_a3 = props.onClose) == null ? void 0 : _a3.call(props);
          },
          onCancel: props.onClose,
          allowDirtyNavigation: true,
          children: /* @__PURE__ */ jsxs(FormSection, { children: [
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "name",
                label: "tesseract.settings.tabs.notification.labels.name",
                description: "tesseract.settings.tabs.notification.descriptions.name",
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                label: "tesseract.settings.tabs.notification.labels.status",
                description: "tesseract.settings.tabs.notification.descriptions.status",
                source: "status",
                choices: getArrayDataContent(notificationStatus),
                optionValue: "key",
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              DualListInput,
              {
                label: "tesseract.settings.tabs.notification.labels.events",
                description: "tesseract.settings.tabs.notification.descriptions.events",
                source: "events",
                choices: getArrayDataContent(notificationEvents),
                translateChoice: true,
                optionValue: "key",
                inputLabelProps: {
                  fullWidth: true
                },
                isRequired: true,
                optionBlockValue: "type",
                optionBlockData: "events"
              }
            ),
            /* @__PURE__ */ jsx(
              TextArrayInput,
              {
                label: "tesseract.settings.tabs.notification.labels.administrators",
                description: "tesseract.settings.tabs.notification.descriptions.administrators",
                source: "administrators",
                isRequired: true
              }
            )
          ] })
        }
      )
    })
  );
};
var NotificationContent_default = NotificationContent;
export {
  NotificationContent_default as default,
  styles
};
