import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@barracuda-internal/bds-core";
import { StatusIcon } from "@cuda-react/icons";
import { styled } from "@mui/styles";
import apiResources from "../../../../../../../apiResources";
import { getArrayDataContent, useCrudProps } from "@cuda-react/core";
import { useTranslation } from "react-i18next";
const Wrapper = styled(Box)(({ theme }) => {
  var _a;
  return {
    backgroundColor: (_a = theme.palette.warning.light) != null ? _a : ""
  };
});
const ConnectorIPsInfo = () => {
  var _a, _b, _c;
  const [translate] = useTranslation();
  const ips = (_c = (_b = (_a = getArrayDataContent(useCrudProps(apiResources.directoriesConnectorsIps))) == null ? void 0 : _a[0]) == null ? void 0 : _b.data) == null ? void 0 : _c.content;
  if (!ips || ips.length === 0) {
    return null;
  }
  return /* @__PURE__ */ jsx(Wrapper, { p: 2, children: /* @__PURE__ */ jsxs(Box, { display: "flex", flexDirection: "row", alignItems: "start", children: [
    /* @__PURE__ */ jsx(StatusIcon, { status: "warning" }),
    /* @__PURE__ */ jsxs(Box, { pl: 2, children: [
      /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.identity.userDirectories.form.ldap.connectorIps.warning") }),
      /* @__PURE__ */ jsx(Typography, { fontWeight: "bold", children: ips.join(", ") })
    ] })
  ] }) });
};
export {
  ConnectorIPsInfo
};
