var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { get } from "lodash";
const convertFromSimpleFilters = (filters) => ({
  filters: [{
    filters: filters && Object.keys(filters).map((filter) => ({
      field: filter,
      value: filters[filter],
      operator: "eq"
    })) || [],
    logic: "and"
  }],
  logic: "and"
});
const convertToSimpleFilters = (filters) => get(filters, "filters", []).flatMap((filter) => filter.filters || filter).reduce((filters2, filter) => __spreadProps(__spreadValues({}, filters2), {
  [filter.field]: filter.value
}), {});
export {
  convertFromSimpleFilters,
  convertToSimpleFilters
};
