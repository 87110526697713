import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import {
  ActionButtonsField,
  Boolean,
  ChipArrayField,
  ConnectedTable,
  CrudTypes,
  DeleteDialog,
  getArrayDataContent,
  getDataContent,
  InputLabel,
  PropGateway,
  Select,
  StatusIconField,
  TextField,
  useCrudFetch,
  useCrudProps,
  useCrudSubscription,
  useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { makeStyles } from "@mui/styles";
import CreateEditIpsRules from "./CreateEditIpsRules";
import SourceFilter from "../../../../../components/sourceAndDestination/SourceFilter";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import {
  formatSourceDestinationFilters,
  formatUserGroupInTableData
} from "../../../../../components/sourceAndDestination/tableUtils";
import { ipsIcons } from "../../../../../components/iconMapping";
import { useSourceDestinationField } from "../../../../../hooks/sourceAndDestinationHooks";
import apiResources from "../../../../../apiResources";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
const useStyles = makeStyles((theme) => ({
  cardActions: {
    paddingRight: theme.spacing(2),
    width: "calc(100% - " + theme.spacing(2) + ")"
  },
  orderCell: {
    minWidth: 70
  },
  actionCell: {
    minWidth: 70,
    paddingLeft: 0
  }
}));
const IPSSettings = () => {
  var _a;
  const registerAction = useMixpanel("IPS Rule", false);
  const classes = useStyles();
  const tableRefreshRef = useRef();
  const explicitActionChoices = getArrayDataContent((_a = useCrudProps(apiResources.ipsExplicitActions)[0]) == null ? void 0 : _a.data);
  const [ipsDefaultData, ipsDefaultLoading, refreshIpsDefault] = useCrudSubscription(CrudTypes.GET, apiResources.ipsExplicitDefaultAction);
  const [, ipsDefaultUpdating, updateIpsDefault] = useCrudFetch(CrudTypes.UPDATE, apiResources.ipsExplicitDefaultAction);
  const ipsDefault = getDataContent(ipsDefaultData);
  const defaultLoading = typeof ipsDefault !== "string" || ipsDefaultLoading || ipsDefaultUpdating;
  const handleUpdateIpsDefault = (defaultAction) => {
    if (defaultAction && !defaultLoading && defaultAction !== ipsDefault) {
      registerAction("Default Update", { action: defaultAction });
      return updateIpsDefault({ defaultAction }).then(refreshIpsDefault);
    }
  };
  const [enabledData, enabledLoading, refreshEnabled] = useCrudSubscription(CrudTypes.GET, apiResources.ipsEnable);
  const [, enabledUpdating, updateEnabled] = useCrudFetch(CrudTypes.UPDATE, apiResources.ipsEnable);
  const enabled = getDataContent(enabledData);
  const loadingEnabled = typeof enabled !== "boolean" || enabledLoading || enabledUpdating;
  const handleUpdateIpsEnable = (newValue) => {
    if (!loadingEnabled && newValue !== enabled) {
      registerAction("Default Update", { enabled: newValue });
      return updateEnabled({ enabled: newValue ? "enable" : "disable" }).then(refreshEnabled);
    }
  };
  const [, , callIpsExplicitUpdate] = useCrudFetch(CrudTypes.CREATE, apiResources.ipsExplicit);
  const handleOrderClickIpsExplicit = (id, direction, data) => callIpsExplicitUpdate({
    id,
    changeOrder: direction,
    data
  }).then(tableRefreshRef.current);
  const generateChipArrayPropsExplicit = useSourceDestinationField();
  return /* @__PURE__ */ jsxs(
    ConnectedTable,
    {
      resource: apiResources.ipsExplicit,
      exportResource: { resource: apiResources.ipsExplicitExport, filename: "ipsExplicit" },
      actions: [
        /* @__PURE__ */ jsx(
          InputLabel,
          {
            label: "tesseract.security.ips.settings.defaultAction",
            minimised: true,
            left: true,
            children: /* @__PURE__ */ jsx(
              Select,
              {
                id: "defaultAction",
                value: !defaultLoading ? ipsDefault : "",
                onChange: (eventOrValue) => (eventOrValue == null ? void 0 : eventOrValue.target) ? handleUpdateIpsDefault(eventOrValue.target.value) : handleUpdateIpsDefault(eventOrValue),
                onBlur: () => {
                },
                disabled: !!defaultLoading,
                choices: explicitActionChoices
              },
              "defaultAction"
            )
          },
          "defaultAction"
        ),
        /* @__PURE__ */ jsx(
          InputLabel,
          {
            label: "tesseract.security.ips.settings.inputs.enabled",
            minimised: true,
            left: true,
            children: /* @__PURE__ */ jsx(
              Boolean,
              {
                id: "enabled",
                value: typeof enabled !== "boolean" ? false : enabled,
                onChange: (eventOrValue) => (eventOrValue == null ? void 0 : eventOrValue.target) ? handleUpdateIpsEnable(eventOrValue.target.value) : handleUpdateIpsEnable(eventOrValue),
                options: { disabled: !!defaultLoading }
              }
            )
          },
          "enabled"
        ),
        /* @__PURE__ */ jsx(
          CreateEditButtonDialog,
          {
            create: true,
            component: CreateEditIpsRules,
            resource: apiResources.ipsExplicit,
            onSuccess: tableRefreshRef.current
          },
          "create"
        )
      ],
      params: formatSourceDestinationFilters,
      classes: { cardActions: classes.cardActions },
      formatData: formatUserGroupInTableData,
      minCellWidth: 128,
      flat: true,
      refreshRef: tableRefreshRef,
      children: [
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            left: true,
            label: "tesseract.security.ips.settings.order",
            cellClassName: classes.orderCell,
            width: 96,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(
                TextField,
                {
                  source: "order"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowUpwardIcon,
                {
                  onClick: (event, data) => handleOrderClickIpsExplicit(data.id, "increase", data),
                  disabled: (data) => data.index <= 0,
                  id: "cuda-icon-up"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowDownwardIcon,
                {
                  onClick: (event, data) => handleOrderClickIpsExplicit(data.id, "decrease", data),
                  disabled: (data) => data.index >= data.total - 1,
                  id: "cuda-icon-down"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.security.ips.settings.name",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.security.ips.settings.description",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "action",
            text: (action) => (explicitActionChoices.find((item) => item.key === action) || {}).name,
            label: "tesseract.security.ips.settings.action",
            iconMap: ipsIcons,
            cellClassName: classes.actionCell,
            filter: "select",
            filterProps: {
              choices: explicitActionChoices
            },
            width: 200
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "source",
            label: "tesseract.security.ips.settings.source",
            editProps: generateChipArrayPropsExplicit("source"),
            filter: "custom",
            filterProps: {
              component: SourceFilter,
              filterSources: ["application", "network", "domain", "site"]
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "source" })
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "destination",
            label: "tesseract.security.ips.settings.destination",
            editProps: generateChipArrayPropsExplicit("destination"),
            filter: "custom",
            filterProps: {
              component: DestinationFilter,
              filterSources: ["network", "site", "application", "domain"],
              table: "IpsSettings"
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "destination" })
          }
        ),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            width: 96,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(
                CreateEditButtonDialog,
                {
                  component: CreateEditIpsRules,
                  resource: apiResources.ipsExplicit,
                  onSuccess: tableRefreshRef.current
                }
              ),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource: apiResources.ipsExplicit,
                  title: "tesseract.security.ips.settings.delete.title",
                  message: "tesseract.security.ips.settings.delete.body",
                  onSuccess: () => {
                    var _a2;
                    registerAction("Delete");
                    (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                  }
                }
              )
            ]
          }
        )
      ]
    }
  );
};
var IPSSettings_default = IPSSettings;
export {
  IPSSettings,
  IPSSettings_default as default,
  useStyles
};
