import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon, useTheme } from "@mui/material";
import classNames from "classnames";
const Medium = ({ className }) => {
  var _a, _b, _c;
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-graphic"),
      id: "cuda-gridsize-medium",
      viewBox: "0 0 60 60",
      children: [
        /* @__PURE__ */ jsx("rect", { style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 }, x: "7.7", y: "7.7", width: "44.5", height: "19.8" }),
        /* @__PURE__ */ jsx("path", { style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 }, d: "M7.7,52.3h19.8v-19.8H7.7v19.8ZM12.7,37.4h9.9v9.9h-9.9v-9.9Z" }),
        /* @__PURE__ */ jsx("path", { style: { fill: (_c = theme.palette.icons) == null ? void 0 : _c.graphics, strokeWidth: 0 }, d: "M32.5,52.3h19.8v-19.8h-19.8v19.8ZM37.4,37.4h9.9v9.9h-9.9v-9.9Z" })
      ]
    }
  );
};
export {
  Medium
};
