var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { CardSizeOptions, CardSizes } from "../../../typesAndConstants";
import { BaseCard } from "../BaseCard";
import { DualContainer } from "../../statistics/DualContainer";
import { TotalChart } from "../../charts";
import { BarGrid } from "../../statistics/BarGrid";
import { Divider, Grid } from "@barracuda-internal/bds-core";
import { createStyles, makeStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  container: {
    width: "100%",
    height: "100%"
  },
  barGridLarge: {
    minHeight: CardSizeOptions[CardSizes.large].height - 180,
    maxHeight: CardSizeOptions[CardSizes.large].height - 180
  },
  divider: {
    margin: theme.spacing(3, 2, 2),
    width: `calc(100% - ${theme.spacing(4)})`
  }
});
const useStyles = makeStyles(styles);
const IncidentBaseCard = (props) => {
  const { size, icon, loading, total, entries } = props;
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx(BaseCard, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsxs(Grid, { container: true, className: classes.container, children: [
    /* @__PURE__ */ jsxs(Grid, { item: true, xs: 12, children: [
      size !== CardSizes.medium && /* @__PURE__ */ jsx(
        TotalChart,
        {
          total: total || 0,
          icon,
          size,
          loading
        }
      ),
      size === CardSizes.medium && /* @__PURE__ */ jsxs(DualContainer, { children: [
        /* @__PURE__ */ jsx(
          TotalChart,
          {
            total: total || 0,
            icon,
            size: CardSizes.small,
            loading
          }
        ),
        /* @__PURE__ */ jsx(
          BarGrid,
          {
            size: CardSizes.small,
            entries,
            loading
          }
        )
      ] })
    ] }),
    size === CardSizes.large && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, children: /* @__PURE__ */ jsx(Divider, { className: classes.divider }) }),
      /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, children: /* @__PURE__ */ jsx(
        BarGrid,
        {
          className: classes.barGridLarge,
          classes: { container: classes.barGridLarge },
          size,
          entries,
          loading
        }
      ) })
    ] })
  ] }) }));
};
export {
  IncidentBaseCard
};
