import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import classNames from "classnames";
const IntegrationsIcon = ({ className }) => /* @__PURE__ */ jsxs(
  SvgIcon,
  {
    className: classNames(className, "cuda-react-icon"),
    id: "cuda-icon-integrations",
    viewBox: "0 0 60 60",
    children: [
      /* @__PURE__ */ jsx("polygon", { style: { fill: "currentColor", strokeWidth: 0 }, points: "29 30 15.9 17 12.5 20.4 19.6 27.5 4.5 27.5 4.5 32.5 19.6 32.5 12.5 39.6 15.9 43.1 29 30" }),
      /* @__PURE__ */ jsx("path", { style: { fill: "currentColor", strokeWidth: 0 }, d: "M41.5,30c0-5.3-4.3-9.6-9.6-9.6h-.2c-1.1,0-2.3.3-3.4.7l3.8,3.7c2.7,0,4.8,2.2,4.9,4.9v.2s0,.2,0,.2c0,2.7-2.2,4.8-4.9,4.9l-3.8,3.8c1.2.5,2.4.7,3.6.7,5.3,0,9.6-4.3,9.6-9.6Z" }),
      /* @__PURE__ */ jsx("path", { style: { fill: "currentColor", strokeWidth: 0 }, d: "M50.5,46.4l4.9-8.5c.2-.4.1-1-.2-1.3l-5.2-4.1h0c0-.7.2-1.6.2-2.5s0-1.7-.2-2.4h0c0-.1,0-.2,0-.2l5.2-4c.4-.3.5-.9.2-1.3l-4.9-8.5c-.2-.3-.5-.5-.9-.5s-.2,0-.3,0l-6.2,2.5h0c-1.3-1.1-2.7-1.9-4.1-2.4h-.1s-.9-6.6-.9-6.6c0-.5-.5-.9-1-.9h-9.8c-.5,0-.9.4-1,.9l-.9,6.6h-.1c-.9.4-1.9,1-2.9,1.6l3.2,3.2c.6-.3,1-.5,1.4-.7l2.5-1,.9-6.1h3.7l.9,6.1,2.5,1c1.1.5,2.1,1,3.1,1.8l2.1,1.6,5.8-2.3,1.9,3.3-4.8,3.8.3,2.7c0,.7.1,1.2.1,1.8s0,1.1-.1,1.8l-.3,2.7,4.9,3.8-1.9,3.3-5.7-2.3-2.1,1.6c-1.1.8-2.1,1.4-3.1,1.8l-2.5,1-.9,6.1h-3.8l-.9-6.1-2.5-1c-.5-.2-1-.4-1.4-.7l-3.2,3.2c1,.7,2,1.2,2.9,1.6h.1s.9,6.6.9,6.6c0,.5.5.9,1,.9h9.8c.5,0,.9-.4,1-.9l.9-6.6h.1c1.4-.6,2.7-1.4,4.1-2.4h0c0,0,6.2,2.4,6.2,2.4.5.2,1,0,1.2-.5Z" })
    ]
  }
);
var IntegrationsIcon_default = IntegrationsIcon;
export {
  IntegrationsIcon,
  IntegrationsIcon_default as default
};
