import { jsx, jsxs } from "react/jsx-runtime";
import {
  ChipArrayField,
  CrudTypes,
  DateField,
  getArrayDataContent,
  getDataContent,
  StatusIconField,
  TablePage,
  TextField,
  useCrudProps,
  useCrudSubscription,
  useMixpanel
} from "@cuda-react/core";
import { memo, useEffect, useState } from "react";
import { logsIconMap, logsTrafficStatesIconMap } from "../../../../components/iconMapping";
import apiResources from "../../../../apiResources";
import { isString } from "lodash";
const createdRanges = [
  { key: "1", name: "tesseract.audit.filter.created.days1" },
  { key: "3", name: "tesseract.audit.filter.created.days3" },
  { key: "7", name: "tesseract.audit.filter.created.weeks1" },
  { key: "14", name: "tesseract.audit.filter.created.weeks2" },
  { key: "30", name: "tesseract.audit.filter.created.months1" },
  { key: "60", name: "tesseract.audit.filter.created.months2" },
  { key: "90", name: "tesseract.audit.filter.created.months3" },
  { key: "180", name: "tesseract.audit.filter.created.months6" },
  { key: "270", name: "tesseract.audit.filter.created.months9" },
  { key: "365", name: "tesseract.audit.filter.created.years1" }
];
const liveConnectionTrafficStates = [
  { key: "initiate", name: "tesseract.logs.liveLog.connectionState.initiate" },
  { key: "fail", name: "tesseract.logs.liveLog.connectionState.fail" },
  { key: "sync", name: "tesseract.logs.liveLog.connectionState.sync" },
  { key: "closing", name: "tesseract.logs.liveLog.connectionState.closing" },
  { key: "established", name: "tesseract.logs.liveLog.connectionState.established" },
  { key: "httpDeny", name: "tesseract.logs.liveLog.connectionState.httpDeny" }
];
const LiveLog = (props) => {
  var _a;
  const { id, messagesType, resource, serial, tableName, details: { model, gatewayType, modelSeries } } = props;
  const [pollInterval, setPollInterval] = useState(5e3);
  const liveState = getArrayDataContent((_a = useCrudProps(apiResources.connectionHistoryStates, {}, {}, true)[0]) == null ? void 0 : _a.data);
  const detailsLoaded = id && (resource === apiResources.gatewayCloudLive || serial);
  let liveUpdatedUrl = apiResources.siteLiveUpdated;
  if (gatewayType === "on-prem")
    liveUpdatedUrl = apiResources.gatewayOnPremLiveUpdated;
  else if (resource === apiResources.gatewayCloudLive)
    liveUpdatedUrl = apiResources.gatewayCloudLiveUpdated;
  const liveUpdated = getDataContent(useCrudSubscription(
    CrudTypes.GET,
    liveUpdatedUrl,
    { id },
    {
      pollInterval,
      crudOptions: { quietErrors: true },
      debounceWait: 500
    }
  )[0]);
  const emptyDataMessage = `tesseract.${messagesType}.liveLog.${(liveUpdated == null ? void 0 : liveUpdated.content) === null ? "waitingForConnections" : "emptyData"}`;
  useMixpanel(
    gatewayType ? "Gateways Live" : "Sites Live",
    !!model,
    { model: model || "", type: gatewayType || modelSeries || "" }
  );
  useEffect(() => {
    if (isString(liveUpdated))
      setPollInterval(6e5);
  }, [liveUpdated]);
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      authenticated: true,
      tableName,
      resource: detailsLoaded ? resource : void 0,
      params: { id, serial },
      noDataMessage: emptyDataMessage,
      pageMode: "paginate",
      pollInterval: 5e3,
      resizable: true,
      reorderable: true,
      editColumns: true,
      sortable: true,
      compact: true,
      noStaleData: true,
      hasTitle: true,
      rowDetails: {
        title: "tesseract.logs.liveLog.detailsTitle",
        fields: [
          /* @__PURE__ */ jsx(
            StatusIconField,
            {
              source: "state",
              tooltipSource: "connectionStateDescription",
              label: "tesseract.logs.liveLog.labels.state",
              iconMap: logsIconMap,
              column: 0
            },
            "state"
          ),
          /* @__PURE__ */ jsx(
            StatusIconField,
            {
              source: "connectionState",
              tooltipSource: "connectionStateDescription",
              label: "tesseract.logs.liveLog.labels.connectionTrafficState",
              iconMap: logsTrafficStatesIconMap,
              column: 0
            },
            "connectionState"
          ),
          /* @__PURE__ */ jsx(
            DateField,
            {
              source: "startTime",
              label: "tesseract.logs.liveLog.labels.startTime",
              dateOnly: false,
              column: 0
            },
            "startTime"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "type",
              label: "tesseract.logs.liveLog.labels.type",
              column: 0
            },
            "type"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "ipProtocol",
              label: "tesseract.logs.liveLog.labels.ipProtocol",
              column: 0
            },
            "ipProtocol"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "speedDescription",
              label: "tesseract.logs.liveLog.labels.speedDescription",
              column: 0
            },
            "speedDescription"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "sourceIp",
              label: "tesseract.logs.liveLog.labels.sourceIp",
              column: 0
            },
            "sourceIp"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "sourcePort",
              label: "tesseract.logs.liveLog.labels.sourcePort",
              column: 0
            },
            "sourcePort"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "sourceNat",
              label: "tesseract.logs.liveLog.labels.sourceNat",
              column: 0
            },
            "sourceNat"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "sourceBytesDescription",
              label: "tesseract.logs.liveLog.labels.sourceBytesDescription",
              column: 0
            },
            "sourceBytesDescription"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "destinationIp",
              label: "tesseract.logs.liveLog.labels.destinationIp",
              column: 0
            },
            "destinationIp"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "destinationPort",
              label: "tesseract.logs.liveLog.labels.destinationPort"
            },
            "destinationPort"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "destinationInterface",
              label: "tesseract.logs.liveLog.labels.destinationInterface"
            },
            "destinationInterface"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "destinationBytesDescription",
              label: "tesseract.logs.liveLog.labels.destinationBytesDescription"
            },
            "destinationBytesDescription"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "totalBytesDescription",
              label: "tesseract.logs.liveLog.labels.totalBytesDescription"
            },
            "totalBytesDescription"
          ),
          /* @__PURE__ */ jsx(
            DateField,
            {
              source: "idleTime",
              label: "tesseract.logs.liveLog.labels.idleTime",
              dateOnly: false
            },
            "idleTime"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "user",
              label: "tesseract.logs.liveLog.labels.user"
            },
            "user"
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "urlCategories",
              label: "tesseract.logs.liveLog.labels.urlCategories"
            },
            "urlCategories"
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "application",
              label: "tesseract.logs.liveLog.labels.application"
            },
            "application"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "appName",
              label: "tesseract.logs.liveLog.labels.appName"
            },
            "appName"
          )
        ]
      },
      children: [
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "state",
            tooltipSource: "connectionStateDescription",
            label: "tesseract.logs.liveLog.labels.state",
            width: 96,
            filter: "selectarray",
            iconMap: logsIconMap,
            filterProps: {
              choices: liveState
            },
            alwaysVisible: true
          }
        ),
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "connectionState",
            tooltipSource: "connectionStateDescription",
            label: "tesseract.logs.liveLog.labels.connectionTrafficState",
            width: 96,
            filter: "selectarray",
            iconMap: logsTrafficStatesIconMap,
            filterProps: {
              choices: liveConnectionTrafficStates
            },
            alwaysVisible: true
          }
        ),
        /* @__PURE__ */ jsx(
          DateField,
          {
            source: "startTime",
            label: "tesseract.logs.liveLog.labels.startTime",
            dateOnly: false,
            width: 164,
            filter: "select",
            filterProps: {
              choices: createdRanges
            },
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "type",
            label: "tesseract.logs.liveLog.labels.type",
            filter: "text",
            width: 150,
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            label: "tesseract.logs.liveLog.labels.ipProtocol",
            source: "ipProtocol",
            filter: "text",
            width: 130
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "speedDescription",
            label: "tesseract.logs.liveLog.labels.speedDescription",
            columnProps: {
              field: "speed"
            },
            width: 136,
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "sourceIp",
            label: "tesseract.logs.liveLog.labels.sourceIp",
            width: 150,
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "sourcePort",
            label: "tesseract.logs.liveLog.labels.sourcePort",
            width: 120,
            filter: "text",
            filterProps: {
              type: "number"
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "sourceNat",
            label: "tesseract.logs.liveLog.labels.sourceNat",
            width: 150,
            filter: "text",
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            label: "tesseract.logs.liveLog.labels.sourceBytesDescription",
            source: "sourceBytesDescription",
            columnProps: {
              field: "sourceBytes"
            },
            width: 140,
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "destinationIp",
            label: "tesseract.logs.liveLog.labels.destinationIp",
            width: 150,
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "destinationPort",
            label: "tesseract.logs.liveLog.labels.destinationPort",
            width: 150,
            filter: "text",
            filterProps: {
              type: "integer"
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "destinationInterface",
            label: "tesseract.logs.liveLog.labels.destinationInterface",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            label: "tesseract.logs.liveLog.labels.destinationBytesDescription",
            source: "destinationBytesDescription",
            columnProps: {
              field: "destinationBytes"
            },
            width: 140,
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            label: "tesseract.logs.liveLog.labels.totalBytesDescription",
            source: "totalBytesDescription",
            columnProps: {
              field: "totalBytes"
            },
            width: 140
          }
        ),
        /* @__PURE__ */ jsx(
          DateField,
          {
            source: "idleTime",
            label: "tesseract.logs.liveLog.labels.idleTime",
            dateOnly: false,
            width: 164,
            filter: "text",
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            label: "tesseract.logs.liveLog.labels.user",
            source: "user",
            filter: "text",
            width: 130
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "urlCategories",
            label: "tesseract.logs.liveLog.labels.urlCategories",
            filter: "text",
            width: 150
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "application",
            label: "tesseract.logs.liveLog.labels.application",
            width: 130,
            filter: "selectarray",
            filterProps: {
              resource: apiResources.applications,
              source: "applicationId",
              optionValue: "id"
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            label: "tesseract.logs.liveLog.labels.appName",
            source: "appName",
            width: 130,
            filter: "text",
            hideByDefault: true
          }
        )
      ]
    }
  );
};
var LiveLog_default = memo(LiveLog);
export {
  LiveLog,
  createdRanges,
  LiveLog_default as default
};
