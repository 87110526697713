var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { ConnectedTable } from "../../table";
import { getArrayDataContent } from "../../../utils";
import { CrudTypes } from "../../../clients/crudClient";
import { useCrudFetch, useStatusAvatar } from "../../../hooks";
const TableCardContent = (props) => {
  const _a = props, { children, statusAvatarSource, statusAvatarAggregateSource, resource, params, updateState, visible = true } = _a, tableProps = __objRest(_a, ["children", "statusAvatarSource", "statusAvatarAggregateSource", "resource", "params", "updateState", "visible"]);
  const [data, status, performFetch] = useCrudFetch(CrudTypes.GET, resource, params, { quietErrors: true });
  useStatusAvatar(
    data,
    props.formatData ? props.formatData(getArrayDataContent(data)) : getArrayDataContent(data),
    status,
    statusAvatarSource,
    statusAvatarAggregateSource,
    updateState
  );
  useEffect(() => {
    !visible && resource && performFetch();
  }, [resource]);
  return visible ? /* @__PURE__ */ jsx(
    ConnectedTable,
    __spreadProps(__spreadValues({
      resource,
      params,
      flat: true,
      compact: true,
      noRouter: true
    }, tableProps), {
      children
    })
  ) : null;
};
var TableCardContent_default = TableCardContent;
export {
  TableCardContent,
  TableCardContent_default as default
};
