import { jsx, jsxs } from "react/jsx-runtime";
import AccountTitle from "../AccountTitle/AccountTitle";
import BreadcrumbsTitle from "../BreadcrumbsTitle/BreadcrumbsTitle";
import Authenticated from "../../authentication/Authenticated/Authenticated";
import { makeOverrideableStyles } from "@cuda-react/theme";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import { useGlobalParam } from "../../../hooks";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    width: "100%",
    margin: 0,
    height: (props) => props.title ? void 0 : 0
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    height: 44
  },
  actions: {
    margin: (props) => props.title ? theme.spacing(0, 1.5, 0, 0) : theme.spacing(0),
    height: (props) => props.title ? "100%" : 0,
    display: "inline-flex",
    alignItems: "center",
    float: "right"
  },
  action: {
    marginLeft: theme.spacing(1)
  },
  content: {
    margin: (props) => props.title ? `0 ${theme.spacing(1.5)} ${theme.spacing(1.5)}` : 0
  },
  titleBar: (props) => props.title ? {
    margin: `0 ${theme.spacing(1.5)}`,
    padding: 0,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  } : {
    margin: `0 ${theme.spacing(1.5)}`,
    padding: 0,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
});
const useStyles = makeOverrideableStyles("BasicPage", styles);
const BasicPage = (props) => {
  const { actions, children, title, subtitle, authenticated, authParams } = props;
  const classes = useStyles(props);
  const arrayActions = actions && (Array.isArray(actions) ? actions : [actions]);
  const accountTitle = useGlobalParam("pageTitle.account")[0];
  return /* @__PURE__ */ jsx(ErrorBoundary, { children: /* @__PURE__ */ jsxs("div", { className: classes.root, id: "cuda-react-page", children: [
    /* @__PURE__ */ jsxs("div", { className: classes.titleBar, children: [
      /* @__PURE__ */ jsxs("div", { className: classes.titleContainer, children: [
        title && accountTitle && /* @__PURE__ */ jsx(
          AccountTitle,
          {
            title: accountTitle
          }
        ),
        title && /* @__PURE__ */ jsx(
          BreadcrumbsTitle,
          {
            title,
            subtitle
          }
        )
      ] }),
      arrayActions && /* @__PURE__ */ jsx("span", { className: classes.actions, children: arrayActions.map((action, index) => /* @__PURE__ */ jsx("span", { className: classes.action, children: action }, index)) }) || null
    ] }),
    authenticated ? /* @__PURE__ */ jsx(Authenticated, { authParams, children: /* @__PURE__ */ jsx("div", { className: classes.content, children }) }) : /* @__PURE__ */ jsx("div", { className: classes.content, children })
  ] }) });
};
var BasicPage_default = BasicPage;
export {
  BasicPage,
  BasicPage_default as default,
  styles
};
