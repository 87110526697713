import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const HelpIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-help-icon",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.neutral) == null ? void 0 : _a.main, strokeWidth: 0 },
          d: "M29.9,44.7c.9,0,1.6-.3,2.2-.9.6-.6.9-1.3.9-2.2s-.3-1.6-.9-2.2c-.6-.6-1.3-.9-2.2-.9s-1.6.3-2.2.9-.9,1.3-.9,2.2.3,1.6.9,2.2c.6.6,1.3.9,2.2.9ZM27.7,35.3h4.5c0-1.3.2-2.4.5-3.2s1.2-1.8,2.6-3.2c1.1-1.1,1.9-2.1,2.5-3s.9-2.1.9-3.5c0-2.3-.8-4-2.5-5.3s-3.7-1.8-5.9-1.8-4.2.6-5.7,1.8c-1.4,1.2-2.5,2.7-3,4.4l4,1.6c.2-.7.7-1.5,1.4-2.4s1.8-1.3,3.3-1.3,2.3.4,2.9,1.1,1,1.5,1,2.4-.2,1.6-.7,2.3-1.1,1.4-1.8,2c-1.8,1.6-2.9,2.8-3.3,3.6s-.6,2.3-.6,4.5ZM30,54.5c-3.4,0-6.6-.6-9.6-1.9-3-1.3-5.6-3-7.8-5.2s-4-4.8-5.2-7.8c-1.3-3-1.9-6.2-1.9-9.6s.6-6.6,1.9-9.6c1.3-3,3-5.6,5.2-7.8s4.8-4,7.8-5.2c3-1.3,6.2-1.9,9.6-1.9s6.6.6,9.6,1.9c3,1.3,5.6,3,7.8,5.2,2.2,2.2,4,4.8,5.2,7.8s1.9,6.2,1.9,9.6-.6,6.6-1.9,9.6-3,5.6-5.2,7.8c-2.2,2.2-4.8,4-7.8,5.2-3,1.3-6.2,1.9-9.6,1.9Z"
        }
      )
    }
  );
};
var HelpIcon_default = HelpIcon;
export {
  HelpIcon,
  HelpIcon_default as default
};
