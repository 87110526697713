import { jsx, jsxs } from "react/jsx-runtime";
import { Route, Switch, withRouter } from "react-router";
import GatewayTable from "./GatewayTable";
import GatewayDetails from "./GatewayDetails";
const Gateways = ({ match }) => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(
    Route,
    {
      exact: true,
      path: match.path,
      component: GatewayTable
    }
  ),
  /* @__PURE__ */ jsx(
    Route,
    {
      path: `${match.path}/:id`,
      component: GatewayDetails
    }
  )
] });
var Gateways_default = withRouter(Gateways);
export {
  Gateways,
  Gateways_default as default
};
