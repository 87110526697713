import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { BooleanInput, FormSection, getArrayDataContent, SelectInput, TextInput, useCrudProps } from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../apiResources";
import { useSiteInputValidations } from "../../../../hooks/createEditSiteHooks";
import { useWatch } from "react-hook-form";
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    fontSize: 16
  },
  completeMessage: {
    margin: theme.spacing(3)
  }
}));
const PhasesInput = () => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const phase2Encryption = useWatch({ name: "phase2.encryptionMethod" });
  const phasesEncryption = getArrayDataContent(useCrudProps(apiResources.phasesEncryption)[0]);
  const phasesHashes1 = getArrayDataContent(useCrudProps(apiResources.phasesHashes, { filter: { phase: "ONE" } })[0]);
  const phasesHashes2 = getArrayDataContent(useCrudProps(apiResources.phasesHashes, {
    filter: {
      phase: "TWO",
      encryption: phase2Encryption
    }
  })[0]);
  const phasesDhGroup1 = getArrayDataContent(useCrudProps(apiResources.phasesDhGroup, { filter: { phase: "ONE" } })[0]);
  const phasesDhGroup2 = getArrayDataContent(useCrudProps(apiResources.phasesDhGroup, { filter: { phase: "TWO" } })[0]);
  const phasesProposalHandling = getArrayDataContent(useCrudProps(apiResources.phasesProposalHandling)[0]);
  const inputValidations = useSiteInputValidations();
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.completeMessage, children: translate("tesseract.integration.ipSecV2.dialog.phasesSection.descriptionMessage") }),
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.integration.ipSecV2.dialog.phasesSection.phase1.title", children: [
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.phasesSection.phase1.encryption",
          source: "phase1.encryptionMethod",
          choices: phasesEncryption,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.phasesSection.phase1.hash",
          source: "phase1.hashMethod",
          choices: phasesHashes1,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.phasesSection.phase1.dhGroup",
          source: "phase1.dhGroup",
          optionValue: "key",
          choices: phasesDhGroup1,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.phasesSection.phase1.proposalHandling",
          description: "tesseract.integration.ipSecV2.dialog.phasesSection.phase1.descriptions.proposalHandling",
          source: "phase1.proposalHandling",
          choices: phasesProposalHandling,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextInput,
        {
          source: "phase1.lifetime",
          label: "tesseract.integration.ipSecV2.dialog.phasesSection.phase1.lifetime",
          description: "tesseract.integration.ipSecV2.dialog.phasesSection.phase1.descriptions.lifetime",
          inputProps: { type: "number" },
          validate: inputValidations.validateTunnelLifetime,
          isRequired: true
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.integration.ipSecV2.dialog.phasesSection.phase2.title", children: [
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.phasesSection.phase2.encryption",
          source: "phase2.encryptionMethod",
          choices: phasesEncryption,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.phasesSection.phase2.hash",
          source: "phase2.hashMethod",
          choices: phasesHashes2,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.phasesSection.phase2.dhGroup",
          source: "phase2.dhGroup",
          optionValue: "key",
          choices: phasesDhGroup2,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.phasesSection.phase2.proposalHandling",
          description: "tesseract.integration.ipSecV2.dialog.phasesSection.phase2.descriptions.proposalHandling",
          source: "phase2.proposalHandling",
          choices: phasesProposalHandling,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextInput,
        {
          source: "phase2.lifetime",
          label: "tesseract.integration.ipSecV2.dialog.phasesSection.phase2.lifetime",
          description: "tesseract.integration.ipSecV2.dialog.phasesSection.phase2.descriptions.lifetime",
          inputProps: { type: "number" },
          validate: inputValidations.validateTunnelLifetime,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        BooleanInput,
        {
          source: "phase2.trafficEnabled",
          label: "tesseract.integration.ipSecV2.dialog.phasesSection.phase2.trafficVolume",
          description: "tesseract.integration.ipSecV2.dialog.phasesSection.phase2.descriptions.trafficVolume"
        }
      ),
      /* @__PURE__ */ jsx(
        TextInput,
        {
          source: "phase2.traffic",
          label: "tesseract.integration.ipSecV2.dialog.phasesSection.phase2.trafficVolumeKb",
          description: "tesseract.integration.ipSecV2.dialog.phasesSection.phase2.descriptions.trafficVolumeKb",
          inputProps: { type: "number" },
          validate: inputValidations.validateTunnelLifetime,
          hide: (values, data) => {
            var _a;
            return !((_a = data == null ? void 0 : data.phase2) == null ? void 0 : _a.trafficEnabled);
          },
          isRequired: true
        }
      )
    ] })
  ] });
};
var PhasesInput_default = PhasesInput;
export {
  PhasesInput,
  PhasesInput_default as default
};
