var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { IncidentBaseCard } from "../../baseCards";
import { CardSizes } from "../../../typesAndConstants";
import { useTranslation } from "react-i18next";
import { useReportingData } from "../../../contexts";
import { colorFunctionError, numberFormatter } from "../../../utils";
import { sortBy } from "lodash";
import { IpsIcon } from "../../icons";
import { useTheme } from "@mui/material";
const IpsIncidentsCard = (props) => {
  const { preview, size } = props;
  const [translate] = useTranslation();
  const { data, loading, addFilter } = useReportingData("IpsIncidentsCard", preview);
  const { ipsDataPoints = [] } = data || {};
  const theme = useTheme();
  const maxEntry = Math.max(...ipsDataPoints.map((ipsDataPoint) => ipsDataPoint.numberOfSessions));
  const total = numberFormatter(ipsDataPoints.reduce((ipsTotal, ipsDataPoint) => ipsTotal + ipsDataPoint.numberOfSessions, 0));
  const entries = sortBy(ipsDataPoints, "numberOfSessions").reverse().map((ipsDataPoint) => ({
    title: ipsDataPoint.label,
    value: size === CardSizes.small ? numberFormatter(ipsDataPoint.numberOfSessions) : translate("stratosReporting.ipsIncidentCard.sessions", {
      count: ipsDataPoint.numberOfSessions,
      sessions: numberFormatter(ipsDataPoint.numberOfSessions)
    }),
    barProgress: 100 / maxEntry * ipsDataPoint.numberOfSessions,
    color: colorFunctionError(100 / maxEntry * ipsDataPoint.numberOfSessions, theme),
    icon: /* @__PURE__ */ jsx(IpsIcon, { size: 20 }),
    onClick: addFilter ? () => addFilter({ key: ipsDataPoint.id, name: ipsDataPoint.label }, "ipsDataPoints") : void 0,
    tooltipMessage: addFilter ? "stratosReporting.filterTooltip" : void 0
  }));
  return /* @__PURE__ */ jsx(
    IncidentBaseCard,
    __spreadValues({
      icon: /* @__PURE__ */ jsx(IpsIcon, {}),
      title: "stratosReporting.ipsIncidentCard.title",
      entries,
      loading: loading && !data.ipsDataPoints,
      noData: !loading && !data.ipsDataPoints,
      total,
      activeFilter: true
    }, props)
  );
};
export {
  IpsIncidentsCard
};
