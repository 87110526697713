import { pick } from "lodash";
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("appState");
    return serializedState ? JSON.parse(serializedState) : void 0;
  } catch (err) {
    return void 0;
  }
};
const saveState = (state) => {
  try {
    localStorage.setItem("appState", JSON.stringify(state));
  } catch (err) {
  }
};
const persistStore = (store, reducerNames = []) => {
  store.subscribe(() => {
    const currentState = store.getState();
    saveState(pick(currentState, reducerNames));
  });
};
export {
  loadState,
  persistStore,
  saveState
};
