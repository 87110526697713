var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import {
  ConnectedForm,
  DateTimeInput,
  DialogBody,
  ErrorDisplay,
  getArrayDataContent,
  SearchableSelectInput,
  useCrudProps,
  useGlobalParam
} from "@cuda-react/core";
import { FormHelperText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { addHours, formatDuration, intervalToDuration, isBefore, parseISO, subHours } from "date-fns";
import { concat } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { updateWindowValidation } from "../updateWindowFormValidation";
import { GatewayIcon, SiteIcon } from "@cuda-react/icons";
import { convertApplianceId } from "../FirmwareUpdates";
import apiResources from "../../../../../../apiResources";
const useStyles = makeStyles((theme) => ({
  durationText: {
    padding: theme.spacing(0, 4, 1)
  }
}));
const UpdateDialogContent = ({
  data,
  appliances,
  onClose,
  title,
  defaultUpdateWindow,
  onSuccess,
  availableUpdates
}) => {
  var _a, _b;
  const classes = useStyles();
  const [translate] = useTranslation();
  const [vwanId] = useGlobalParam("filter.virtualWanId");
  const gatewayNames = getArrayDataContent((_a = useCrudProps(apiResources.gatewayNames)[0]) == null ? void 0 : _a.data);
  const siteNames = getArrayDataContent((_b = useCrudProps(apiResources.siteNames)[0]) == null ? void 0 : _b.data);
  const [timeWindow, setTimeWindow] = useState();
  const [currentValues, setCurrentValues] = useState({});
  const [minDate] = useState(subHours(/* @__PURE__ */ new Date(), 26));
  const currentAppliance = data && (data.configurationType === "gateway" ? gatewayNames.find((gateway) => gateway.key === data.configurationId) : siteNames.find((site) => site.key === data.configurationId));
  const availableAppliances = currentAppliance ? concat(convertApplianceId(data.configurationType)(currentAppliance), appliances) : appliances;
  return /* @__PURE__ */ jsx(DialogBody, { form: true, onClose, title, children: /* @__PURE__ */ jsxs(
    ConnectedForm,
    {
      id: data == null ? void 0 : data.id,
      resource: apiResources.update,
      params: defaultUpdateWindow ? { filter: { allConfigurations: true } } : void 0,
      initialValues: (data == null ? void 0 : data.id) ? data : void 0,
      create: !(data == null ? void 0 : data.id),
      formatRequestData: (_c) => {
        var _d = _c, { applianceId } = _d, values = __objRest(_d, ["applianceId"]);
        const [configurationType, configurationId] = (applianceId == null ? void 0 : applianceId.split(":")) || [null, null];
        return __spreadProps(__spreadValues({}, values), {
          configurationType,
          configurationId,
          virtualWanId: vwanId
        });
      },
      formatResourceData: (_e) => {
        var _f = _e, { configurationType, configurationId } = _f, values = __objRest(_f, ["configurationType", "configurationId"]);
        return __spreadProps(__spreadValues({}, values), {
          applianceId: configurationType ? `${configurationType}:${configurationId}` : void 0
        });
      },
      onChange: (values) => {
        setCurrentValues(values);
        const start = new Date(values.from);
        const end = new Date(values.to);
        const interval = values.from && values.to && isBefore(start, end) && intervalToDuration({
          start,
          end
        });
        setTimeWindow(interval);
      },
      validate: updateWindowValidation(availableUpdates.mustInstallBy, translate),
      onSubmitSuccess: () => {
        onSuccess && onSuccess();
        onClose();
      },
      onCancel: onClose,
      children: [
        appliances ? /* @__PURE__ */ jsx(
          SearchableSelectInput,
          {
            source: "applianceId",
            label: "tesseract.settings.tabs.updates.firmware.fields.appliance",
            description: "tesseract.settings.tabs.updates.firmware.fields.applianceDescription",
            choices: availableAppliances,
            iconMap: {
              gateway: /* @__PURE__ */ jsx(GatewayIcon, {}),
              site: /* @__PURE__ */ jsx(SiteIcon, {})
            },
            optionIcon: "type",
            clearOnFocus: true
          }
        ) : null,
        /* @__PURE__ */ jsx(
          DateTimeInput,
          {
            source: "from",
            label: "tesseract.settings.tabs.updates.firmware.fields.from",
            description: "tesseract.settings.tabs.updates.firmware.fields.fromDescription",
            isRequired: true,
            pickerProps: {
              minDate,
              maxDate: availableUpdates.mustInstallBy,
              initialFocusedDate: currentValues.to && subHours(parseISO(currentValues.to), 3)
            }
          }
        ),
        /* @__PURE__ */ jsx(
          DateTimeInput,
          {
            source: "to",
            label: "tesseract.settings.tabs.updates.firmware.fields.to",
            description: "tesseract.settings.tabs.updates.firmware.fields.toDescription",
            isRequired: true,
            pickerProps: {
              minDate,
              maxDate: availableUpdates.mustInstallBy,
              initialFocusedDate: currentValues.from && addHours(parseISO(currentValues.from), 3)
            }
          }
        ),
        /* @__PURE__ */ jsx(ErrorDisplay, { source: "virtualWan" }),
        timeWindow ? /* @__PURE__ */ jsx(FormHelperText, { className: classes.durationText, children: translate("tesseract.settings.tabs.updates.firmware.fields.windowLength", { duration: formatDuration(timeWindow) }) }) : null
      ]
    }
  ) });
};
var UpdateDialogContent_default = UpdateDialogContent;
export {
  UpdateDialogContent_default as default,
  useStyles
};
