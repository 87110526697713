var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import {
  BooleanInput,
  ConnectedFormPage,
  CrudTypes,
  getArrayDataContent,
  SelectArrayInput,
  TextInput,
  useCrudSubscription,
  useMixpanel,
  validateDomain,
  validateInt,
  validateMaxValueMemo,
  validateMinValueMemo
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import { get } from "lodash";
import { parseJson } from "../../../utils/jsonUtils";
const Xdr = () => {
  var _a, _b;
  const registerAction = useMixpanel("Xdr");
  const siteNames = getArrayDataContent((_a = useCrudSubscription(CrudTypes.GET, apiResources.siteNames)[0]) == null ? void 0 : _a.data);
  const gatewayNames = getArrayDataContent((_b = useCrudSubscription(CrudTypes.GET, apiResources.gatewayNames)[0]) == null ? void 0 : _b.data);
  return /* @__PURE__ */ jsxs(
    ConnectedFormPage,
    {
      resource: apiResources.settings,
      title: "tesseract.integration.pageTitle",
      subtitle: "tesseract.xdr.pageTitle",
      formatRequestData: (data) => ({ settings: {
        xdr: JSON.stringify(__spreadProps(__spreadValues({}, data), {
          sites: {
            all: data.sites.includes("__all__"),
            configurations: data.sites.includes("__all__") ? [] : data.sites
          },
          gateways: {
            all: data.gateways.includes("__all__"),
            configurations: data.gateways.includes("__all__") ? [] : data.gateways
          }
        }))
      } }),
      formatResourceData: (data) => {
        var _a2;
        const xdrSettings = parseJson((_a2 = data == null ? void 0 : data.settings) == null ? void 0 : _a2.xdr);
        return __spreadProps(__spreadValues({
          enabled: false,
          hostname: "",
          port: ""
        }, xdrSettings), {
          sites: get(xdrSettings, "sites.all", false) ? ["__all__"] : get(xdrSettings, "sites.configurations", []),
          gateways: get(xdrSettings, "gateways.all", false) ? ["__all__"] : get(xdrSettings, "gateways.configurations", [])
        });
      },
      params: { filter: { type: "virtualWan" } },
      onSubmitSuccess: () => {
        registerAction("Update");
      },
      children: [
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "enabled",
            label: "tesseract.xdr.enable"
          }
        ),
        /* @__PURE__ */ jsx(
          SelectArrayInput,
          {
            source: "sites",
            label: "tesseract.xdr.sites",
            description: "tesseract.xdr.descriptions.sites",
            choices: siteNames,
            optionValue: "uuid",
            options: { params: { filter: { modelSeries: "T" } } },
            disable: (value, data) => !(data == null ? void 0 : data.enabled),
            selectAllValue: "__all__",
            selectAll: true
          }
        ),
        /* @__PURE__ */ jsx(
          SelectArrayInput,
          {
            source: "gateways",
            label: "tesseract.xdr.gateways",
            description: "tesseract.xdr.descriptions.gateways",
            choices: gatewayNames,
            optionValue: "uuid",
            disable: (value, data) => !(data == null ? void 0 : data.enabled),
            selectAllValue: "__all__",
            selectAll: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "hostname",
            label: "tesseract.xdr.hostname",
            description: "tesseract.xdr.descriptions.hostname",
            validate: [validateDomain],
            disable: (value, data) => !(data == null ? void 0 : data.enabled),
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "port",
            label: "tesseract.xdr.port",
            description: "tesseract.xdr.descriptions.port",
            validate: [validateInt, validateMaxValueMemo(65535), validateMinValueMemo(1)],
            disable: (value, data) => !(data == null ? void 0 : data.enabled),
            isRequired: true
          }
        )
      ]
    }
  );
};
var Xdr_default = Xdr;
export {
  Xdr_default as default
};
