import { jsx } from "react/jsx-runtime";
import { IconButton } from "@barracuda-internal/bds-core";
import React from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = createStyles({
  buttonContainer: (props) => ({
    float: props.left ? "left" : "right",
    whiteSpace: "nowrap",
    zIndex: 15
  })
});
const useStyles = makeOverrideableStyles("ActionButtonsField", styles);
const ActionButtonsField = (props) => {
  const { data } = props;
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx("span", { className: classes.buttonContainer, children: React.Children.map(props.children, (child) => {
    if (!child) {
      return null;
    }
    const { onClick, disabled } = child.props;
    const isDisabled = typeof disabled === "function" ? disabled(data) : disabled;
    return onClick ? /* @__PURE__ */ jsx(
      IconButton,
      {
        size: "small",
        onClick: (event) => onClick(event, data),
        disabled: isDisabled,
        children: React.cloneElement(
          child,
          {
            data,
            onClick: void 0,
            disabled: isDisabled
          }
        )
      }
    ) : React.cloneElement(
      child,
      { data }
    );
  }) });
};
var ActionButtonsField_default = ActionButtonsField;
export {
  ActionButtonsField,
  ActionButtonsField_default as default,
  styles
};
