import { jsx, jsxs } from "react/jsx-runtime";
import { categoryIcons } from "../iconMapping";
import { get } from "lodash";
import { Grid, TextField } from "@barracuda-internal/bds-core";
import SingleSiteIcon from "@mui/icons-material/StoreMallDirectory";
import { GatewayIcon, SiteIcon } from "@cuda-react/icons";
import { Select, SelectArray } from "@cuda-react/core";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { getDestinationTypeChoices } from "./generateDestinationCriteriaSection";
import apiResources from "../../apiResources";
const useStyles = makeStyles((theme) => ({
  container: {
    width: 256
  },
  selectFilter: {
    marginTop: theme.spacing(1),
    width: "100%",
    maxWidth: 256
  },
  textFilter: {
    marginTop: theme.spacing(2),
    width: "100%",
    maxWidth: 256
  },
  typeSelect: {
    width: "100%",
    maxWidth: 256
  }
}));
const DestinationFilter = ({
  value,
  onChange,
  filterSources,
  table,
  categoryChoices,
  customCategories,
  className
}) => {
  const update = (field) => (eventOrValue) => onChange({
    type: get(value, "type", ""),
    [field]: eventOrValue.target ? eventOrValue.target.value : eventOrValue
  });
  const classes = useStyles();
  const [translate] = useTranslation();
  const destinationSelectChoices = getDestinationTypeChoices(filterSources);
  const filters = [];
  if (get(value, "type") === "domain") {
    filters.push(
      /* @__PURE__ */ jsx(
        TextField,
        {
          id: "cuda-filter-input-domain",
          label: translate("tesseract.security.tables.destinationDomain"),
          value: get(value, "domain", ""),
          onChange: update("domain"),
          className: classNames(className, classes.textFilter)
        },
        "domain"
      )
    );
  }
  if (get(value, "type") === "network") {
    filters.push(
      /* @__PURE__ */ jsx(
        TextField,
        {
          id: "cuda-filter-input-destinationNetwork",
          label: translate("tesseract.security.tables.destinationNetwork"),
          value: get(value, "destinationNetwork", ""),
          onChange: update("destinationNetwork"),
          className: classNames(className, classes.textFilter)
        },
        "destinationNetwork"
      )
    );
  }
  if (get(value, "type") === "application") {
    const source = table === "SslInspection" || table === "MalwareProtection" || table === "IpsSettings" ? "application" : "destinationApplication";
    filters.push(
      /* @__PURE__ */ jsx(
        SelectArray,
        {
          id: source,
          label: "tesseract.security.tables.destinationApplication",
          optionValue: "id",
          resource: apiResources.applications,
          filterKey: "applicationId",
          optionIcon: "categoryId",
          iconMap: categoryIcons,
          value: get(value, source),
          onChange: update(source),
          className: classNames(className, classes.selectFilter),
          options: { dropdownAutoWidth: true }
        },
        source
      )
    );
  }
  if (get(value, "type") === "category") {
    filters.push(
      /* @__PURE__ */ jsx(
        SelectArray,
        {
          id: "category",
          label: "tesseract.security.tables.destinationCategories",
          choices: categoryChoices,
          value: get(value, "category"),
          onChange: update("category"),
          className: classNames(className, classes.selectFilter)
        },
        "category"
      )
    );
  }
  if (get(value, "type") === "customCategory") {
    const source = table === "SslInspection" ? "destinationCustomCategory" : table === "WebFilter" ? "category" : "customCategory";
    filters.push(
      /* @__PURE__ */ jsx(
        SelectArray,
        {
          id: source,
          label: "tesseract.security.tables.destinationCustomCategories",
          optionValue: "id",
          choices: customCategories,
          value: get(value, source),
          onChange: update(source),
          className: classNames(className, classes.selectFilter)
        },
        source
      )
    );
  }
  if (get(value, "type") === "onPremGateway" && filterSources.includes("onPremGatewayNetworks")) {
    filters.push(
      /* @__PURE__ */ jsx(
        SelectArray,
        {
          id: "destinationOnPremGateway",
          label: "tesseract.security.tables.destinationOnpremGateway",
          optionValue: "uuid",
          resource: apiResources.gatewaysOnPremNetworks,
          selectAll: true,
          selectAllText: "tesseract.security.tables.allOnpremGateways",
          optionIcon: "uuid",
          icon: /* @__PURE__ */ jsx(GatewayIcon, {}),
          value: get(value, "destinationOnPremGateway"),
          onChange: update("destinationOnPremGateway"),
          className: classNames(className, classes.selectFilter)
        },
        "destinationOnPremGateway"
      )
    );
  }
  if (get(value, "type") === "site") {
    filters.push(
      /* @__PURE__ */ jsx(
        SelectArray,
        {
          id: "destinationSite",
          label: "tesseract.security.tables.destinationSite",
          optionValue: "uuid",
          resource: apiResources.siteNames,
          selectAll: true,
          selectAllText: "tesseract.security.tables.allSites",
          iconMap: { "*": /* @__PURE__ */ jsx(SiteIcon, {}) },
          optionIcon: "uuid",
          icon: /* @__PURE__ */ jsx(SingleSiteIcon, {}),
          value: get(value, "destinationSite"),
          onChange: update("destinationSite"),
          className: classNames(className, classes.selectFilter)
        },
        "destinationSite"
      )
    );
  }
  if (get(value, "type") === "ingressSite") {
    filters.push(
      /* @__PURE__ */ jsx(
        SelectArray,
        {
          id: "destinationSiteId",
          label: "tesseract.security.tables.destinationSite",
          optionValue: "id",
          resource: apiResources.sites,
          iconMap: { "*": /* @__PURE__ */ jsx(SiteIcon, {}) },
          optionIcon: "uuid",
          icon: /* @__PURE__ */ jsx(SingleSiteIcon, {}),
          value: get(value, "destinationSiteId"),
          onChange: update("destinationSiteId"),
          className: classNames(className, classes.selectFilter)
        },
        "destinationSiteId"
      )
    );
  }
  if (get(value, "type") === "onPremGateway" && !filterSources.includes("onPremGatewayNetworks")) {
    filters.push(
      /* @__PURE__ */ jsx(
        SelectArray,
        {
          id: "destinationGatewayId",
          label: "tesseract.security.tables.destinationGateway",
          optionValue: "id",
          resource: apiResources.gatewaysOnPrem,
          iconMap: { "*": /* @__PURE__ */ jsx(GatewayIcon, {}) },
          filterKey: "destinationGatewayId",
          value: get(value, "destinationGatewayId"),
          onChange: update("destinationGatewayId"),
          className: classNames(className, classes.selectFilter)
        },
        "destinationGatewayId"
      )
    );
  }
  return /* @__PURE__ */ jsxs(Grid, { container: true, direction: "column", className: classes.container, children: [
    /* @__PURE__ */ jsx(
      Select,
      {
        id: "cuda-filter-input-type",
        value: get(value, "type", ""),
        onChange: (eventOrValue) => onChange({ type: eventOrValue.target ? eventOrValue.target.value : eventOrValue }),
        label: translate("tesseract.security.tables.destinationType"),
        choices: destinationSelectChoices,
        classes: { select: classes.typeSelect, formControl: classes.typeSelect }
      }
    ),
    filters
  ] });
};
var DestinationFilter_default = DestinationFilter;
export {
  DestinationFilter_default as default
};
