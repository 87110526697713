const dataGenerator = () => ({
  total: 968,
  versions: {
    "1.2.0": 18,
    "2.2.0": 86,
    "4.0.3": 40,
    "1.0.2": 12,
    "1.0.8": 20,
    "1.0.1": 8,
    "1.0.5": 22,
    "3.0.1": 25,
    "1.1.1": 22,
    "8.1.1": 25,
    "11.1.1": 15,
    "1.4.3": 2,
    "1.1.2": 45,
    "1.4.2": 15,
    "1.11.2": 90,
    "1.2.1": 23,
    "1.123.1": 88,
    "12.0.1": 412
  }
});
var devicesVersion_default = dataGenerator;
export {
  devicesVersion_default as default
};
