var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import {
  ConnectedForm,
  DialogBody,
  getArrayDataContent,
  HiddenInput,
  SearchableSelectInput,
  SelectInput,
  TextInput,
  useCrudProps,
  useGlobalParam,
  useMixpanel,
  validateMaxLengthMemo,
  validateRegexMemo
} from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { formatRequestCriteria, formatResourceCriteria } from "../../../../../components/sourceAndDestination/formUtils";
import generateDestinationCriteriaSection from "../../../../../components/sourceAndDestination/generateDestinationCriteriaSection";
import { settingsIcons } from "../../../../../components/iconMapping";
import { useGeneratedSourceCriteriaSection } from "../../../../../hooks/sourceAndDestinationHooks";
import apiResources from "../../../../../apiResources";
import { get } from "lodash";
const useStyles = makeStyles({
  dialogContent: {},
  multiInputButtons: {
    width: 74,
    // @ts-ignore
    flexGrow: "0!important",
    flexShrink: 0,
    height: 50,
    marginLeft: 0,
    marginRight: 8
  },
  multiInputInputGroup: {
    display: "flex",
    alignItems: "flex-end",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      maxWidth: "calc(100% - 122px)"
    }
  },
  multiInputDivider: {
    width: "calc(100% - 64px)",
    maxWidth: "calc(100% - 64px) !important",
    marginBottom: -4
  },
  multiInputNetworkSelectChips: {
    marginLeft: "calc(-100% - 8px)",
    maxWidth: "calc(200% + 186px)",
    width: "calc(200% + 186px)"
  }
});
const hideInput = (key, type) => (value, data) => !get(data, key) || get(data, key) !== type;
const CreateEditApplicationControl = (props) => {
  var _b, _c;
  const _a = props, { create, defaultScope, id, type, onSuccess } = _a, dialogProps = __objRest(_a, ["create", "defaultScope", "id", "type", "onSuccess"]);
  const classes = useStyles(props);
  const isGateway = type === "gateway";
  const actionChoices = getArrayDataContent((_b = useCrudProps(apiResources.applicationControlActions)[0]) == null ? void 0 : _b.data);
  const icmpChoices = getArrayDataContent((_c = useCrudProps(apiResources.applicationControlIcmp)[0]) == null ? void 0 : _c.data);
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const registerAction = useMixpanel(isGateway ? "Gateway ACL" : "Site ACL", false);
  const selectAllValue = isGateway ? "allGateways" : "allSites";
  return /* @__PURE__ */ jsx(
    DialogBody,
    __spreadProps(__spreadValues({}, dialogProps), {
      form: true,
      title: `tesseract.security.applicationControl.settings.${create ? "create" : "edit"}.dialogTitle`,
      classes: { dialogContent: classes.dialogContent },
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          resource: apiResources.applicationControl,
          form: "applicationControl",
          create,
          params: create ? void 0 : { id },
          onSubmitSuccess: (response) => {
            var _a2, _b2;
            onSuccess == null ? void 0 : onSuccess();
            dialogProps.onClose();
            registerAction(create ? "Create" : "Update", {
              action: response.action,
              sourceType: (_a2 = response.source) == null ? void 0 : _a2.type,
              destinationType: (_b2 = response.destination) == null ? void 0 : _b2.type,
              scopeAll: response.scope === selectAllValue
            });
          },
          onCancel: dialogProps.onClose,
          formatRequestData: formatRequestCriteria(virtualWanId),
          formatResourceData: formatResourceCriteria,
          initialValues: create ? {
            type,
            scope: defaultScope || selectAllValue,
            source: { allSites: true },
            destination: { allSites: true },
            loggingEnabled: true
          } : void 0,
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsx(
              SearchableSelectInput,
              {
                source: "scope",
                label: "tesseract.security.applicationControl.settings.scope",
                description: "tesseract.security.applicationControl.settings.descriptions.scope",
                resource: isGateway ? apiResources.gatewayNames : apiResources.siteNames,
                optionValue: "uuid",
                options: !isGateway ? { params: { filter: { modelSeries: "T" } } } : void 0,
                selectAllValue,
                selectAllText: `tesseract.security.applicationControl.settings.${isGateway ? "allGateways" : "allSites"}`,
                selectAll: true,
                clearOnFocus: true,
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "name",
                label: "tesseract.security.applicationControl.settings.name",
                description: "tesseract.security.applicationControl.settings.descriptions.name",
                validate: [validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")],
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "description",
                label: "tesseract.security.applicationControl.settings.description",
                description: "tesseract.security.applicationControl.settings.descriptions.description",
                validate: [validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]
              }
            ),
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                source: "action",
                label: "tesseract.security.applicationControl.settings.action",
                description: "tesseract.security.applicationControl.settings.descriptions.action",
                iconMap: settingsIcons,
                choices: actionChoices,
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              HiddenInput,
              {
                source: "icmp",
                hiddenValue: "block",
                hide: hideInput("action", "block")
              }
            ),
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                source: "icmp",
                label: "tesseract.security.applicationControl.settings.icmp",
                description: "tesseract.security.applicationControl.settings.descriptions.icmp",
                iconMap: settingsIcons,
                choices: icmpChoices,
                disable: hideInput("action", "allow"),
                isRequired: true
              }
            ),
            useGeneratedSourceCriteriaSection([isGateway && "application", "network", "onPremGateway", "site", "userOrGroup"]),
            generateDestinationCriteriaSection(["network", "application", "onPremGatewayNetworks", "site"])
          ]
        }
      )
    })
  );
};
var CreateEditApplicationControl_default = CreateEditApplicationControl;
export {
  CreateEditApplicationControl_default as default
};
