var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ConnectedWizard, InputStep, ResultStep, SubmitStep, useCrudProps, useMixpanel } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../apiResources";
import EnrollSelectionStep from "./EnrollSelectionStep";
import EmailPreviewStep from "./EmailPreviewStep";
import ResolveSeatLimitStep from "./ResolveSeatLimitStep";
import EnrollSuccessStep from "./EnrollSuccessStep";
import { parseJson } from "../../../../utils/jsonUtils";
const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 700
  },
  disableStepper: {
    display: "none"
  }
}));
const CreateEnrollEmailDialog = (props) => {
  const { create, id, email, onClose, onSuccess } = props;
  const [translate] = useTranslation();
  const classes = useStyles();
  const [{ data: seatsData }, , fetchRemainingSeats] = useCrudProps(apiResources.zeroTrustUsersRemainingSeats);
  const remainingSeats = seatsData == null ? void 0 : seatsData.count;
  const [isEmailEnabled, setEmailEnabled] = useState(true);
  const [seatLimitExceeded, setSeatLimitExceeded] = useState(false);
  const [resolvedUsersChoices, setResolvedUsersChoices] = useState(void 0);
  const registerAction = useMixpanel(create ? "Endpoint Users Invite" : "Endpoint Users Resend");
  return /* @__PURE__ */ jsxs(
    ConnectedWizard,
    {
      resource: create ? apiResources.sendZeroTrustEnrollEmail : apiResources.resendZeroTrustEnrollEmail,
      update: !create,
      title: create ? "tesseract.endpoint.enrollEmailDialog.emailTitle" : "tesseract.endpoint.enrollEmailDialog.resendEmailTitle",
      onSubmitSuccess: () => {
        onSuccess == null ? void 0 : onSuccess();
        registerAction("Success");
        fetchRemainingSeats();
      },
      onClose,
      initialValues: !create ? {
        users: [email],
        subject: translate("tesseract.endpoint.enrollEmailDialog.zeroTrustSubject"),
        enrollmentEmailEnabled: true,
        id
      } : {
        enrollmentEmailEnabled: true,
        subject: translate("tesseract.endpoint.enrollEmailDialog.zeroTrustSubject")
      },
      formatRequestData: (data) => __spreadProps(__spreadValues({}, data), {
        users: seatLimitExceeded ? data == null ? void 0 : data.resolvedUsersDualList : data == null ? void 0 : data.users,
        groups: seatLimitExceeded ? null : data == null ? void 0 : data.groups
      }),
      stepperProps: {
        classes: {
          root: classes.disableStepper,
          divider: classes.disableStepper
        }
      },
      dialogBodyProps: { classes: { dialogContent: classes.dialog } },
      onChange: (data) => setEmailEnabled(data == null ? void 0 : data.enrollmentEmailEnabled),
      validate: (data) => {
        var _a, _b;
        if (create && !((_a = data == null ? void 0 : data.users) == null ? void 0 : _a.length) && !((_b = data == null ? void 0 : data.groups) == null ? void 0 : _b.length)) {
          return {
            users: translate("tesseract.endpoint.enrollEmailDialog.validate.userGroupRequired"),
            groups: translate("tesseract.endpoint.enrollEmailDialog.validate.userGroupRequired")
          };
        }
        return {};
      },
      formatError: (errors) => {
        setSeatLimitExceeded(errors == null ? void 0 : errors.resolvedUsers);
        setResolvedUsersChoices((errors == null ? void 0 : errors.resolvedUsers) && parseJson(errors == null ? void 0 : errors.resolvedUsers));
        return errors;
      },
      onStepChange: () => {
        setSeatLimitExceeded(false);
      },
      children: [
        isEmailEnabled ? /* @__PURE__ */ jsx(InputStep, { children: /* @__PURE__ */ jsx(
          EnrollSelectionStep,
          {
            create,
            remainingSeats
          }
        ) }) : !seatLimitExceeded ? /* @__PURE__ */ jsx(SubmitStep, { primaryLabel: "tesseract.endpoint.enrollEmailDialog.enroll", children: /* @__PURE__ */ jsx(
          EnrollSelectionStep,
          {
            create,
            remainingSeats
          }
        ) }) : null,
        !seatLimitExceeded && isEmailEnabled ? /* @__PURE__ */ jsx(
          SubmitStep,
          {
            primaryLabel: "tesseract.endpoint.enrollEmailDialog.send",
            children: /* @__PURE__ */ jsx(EmailPreviewStep, {})
          }
        ) : null,
        seatLimitExceeded && resolvedUsersChoices ? /* @__PURE__ */ jsx(
          SubmitStep,
          {
            primaryLabel: isEmailEnabled ? "tesseract.endpoint.enrollEmailDialog.send" : "tesseract.endpoint.enrollEmailDialog.enroll",
            secondaryButtonOnClick: isEmailEnabled ? void 0 : () => setSeatLimitExceeded(false),
            secondaryButtonLabel: isEmailEnabled ? void 0 : "cuda.buttons.back",
            children: /* @__PURE__ */ jsx(
              ResolveSeatLimitStep,
              {
                remainingSeats,
                resolvedUsersChoices
              }
            )
          }
        ) : null,
        /* @__PURE__ */ jsx(ResultStep, { onClose, children: /* @__PURE__ */ jsx(EnrollSuccessStep, { create }) })
      ]
    }
  );
};
var CreateEnrollEmailDialog_default = CreateEnrollEmailDialog;
export {
  CreateEnrollEmailDialog_default as default,
  useStyles
};
