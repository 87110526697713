var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useWatch } from "react-hook-form";
import StepContent from "../StepContent/StepContent";
import WizardToolbar from "../WizardToolbar/WizardToolbar";
import FormErrorReporter from "../../forms/FormErrorReporter";
const ResultStep = (props) => {
  const _a = props, {
    active,
    back,
    children,
    form,
    formErrorReporterValue,
    formErrorReporterSetError,
    onClose,
    preventBackNavigation,
    primaryLabel = "cuda.buttons.finish",
    secondaryButtonOnClick,
    result,
    secondaryButtonLabel,
    title,
    toolbarDisabled,
    secondaryColor
  } = _a, contentProps = __objRest(_a, [
    "active",
    "back",
    "children",
    "form",
    "formErrorReporterValue",
    "formErrorReporterSetError",
    "onClose",
    "preventBackNavigation",
    "primaryLabel",
    "secondaryButtonOnClick",
    "result",
    "secondaryButtonLabel",
    "title",
    "toolbarDisabled",
    "secondaryColor"
  ]);
  const data = useWatch();
  return /* @__PURE__ */ jsxs("div", { style: active ? void 0 : { display: "none" }, children: [
    /* @__PURE__ */ jsx(
      FormErrorReporter,
      {
        value: formErrorReporterValue,
        setError: formErrorReporterSetError,
        children: /* @__PURE__ */ jsx(
          StepContent,
          __spreadProps(__spreadValues({
            childProps: { data, result },
            title
          }, contentProps), {
            children
          })
        )
      }
    ),
    /* @__PURE__ */ jsx(
      WizardToolbar,
      {
        back: !preventBackNavigation && back || void 0,
        primaryLabel,
        primaryOnClick: onClose,
        secondaryLabel: secondaryButtonLabel,
        secondaryOnClick: secondaryButtonOnClick ? () => secondaryButtonOnClick(data, result) : void 0,
        secondaryColor,
        toolbarDisabled
      }
    )
  ] });
};
ResultStep.defaultProps = {
  preventBackNavigation: true,
  hideErrors: true,
  hideWizardStepper: true
};
var ResultStep_default = ResultStep;
export {
  ResultStep,
  ResultStep_default as default
};
