var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { get } from "lodash";
const formatIncomingCriteria = (criteria) => {
  const type = get(criteria, "type") || null;
  if (type === "site" && criteria.applianceId) {
    return {
      type: "ingressSite",
      wan: criteria.wan,
      port: criteria.port,
      applianceId: criteria.applianceId,
      address: criteria.address
    };
  } else if (type === "category") {
    return { type: criteria.type, categories: criteria.categories };
  } else if (type === "domain") {
    return { type: criteria.type, domains: criteria.domains };
  } else if (type === "application") {
    return { type: criteria.type, applications: criteria.applications, allSites: true };
  } else if (type === "network") {
    return { type: criteria.type, networks: criteria.networks, allSites: true };
  } else if (type === "site") {
    return {
      type: criteria.type,
      allSites: criteria.allSites,
      sites: (criteria.sites || []).map((site) => __spreadProps(__spreadValues({}, site), {
        networks: site.networks && site.networks.length > 0 ? site.networks : ["*"]
      }))
    };
  } else if (type === "onPremGateway" && !criteria.applianceId) {
    return {
      type: criteria.type,
      allGateways: criteria.allGateways,
      gateways: (criteria.gateways || []).map((gateway) => __spreadProps(__spreadValues({}, gateway), {
        networks: gateway.networks && gateway.networks.length > 0 ? gateway.networks : ["*"]
      }))
    };
  } else if (type === "userOrGroup") {
    return { type: criteria.type, users: criteria.users, groups: criteria.groups, allSites: true };
  } else {
    return criteria;
  }
};
const formatOutgoingCriteria = (criteria) => {
  switch (get(criteria, "type")) {
    case "category":
      return { type: criteria.type, categories: criteria.categories };
    case "domain":
      return { type: criteria.type, domains: criteria.domains };
    case "application":
      return { type: criteria.type, applications: criteria.applications };
    case "network":
      return { type: criteria.type, networks: criteria.networks };
    case "site":
      return {
        type: criteria.type,
        allSites: criteria.allSites,
        sites: (criteria.sites || []).map((site) => __spreadProps(__spreadValues({}, site), {
          networks: site.networks[0] === "*" ? [] : site.networks
        }))
      };
    case "onPremGateway":
      if (!criteria.applianceId) {
        return {
          type: criteria.type,
          allGateways: criteria.allGateways,
          gateways: (criteria.gateways || []).map((gateway) => __spreadProps(__spreadValues({}, gateway), {
            networks: gateway.networks[0] === "*" ? [] : gateway.networks
          }))
        };
      }
      return criteria;
    case "ingressSite":
      return {
        type: "site",
        wan: criteria.wan,
        port: criteria.port,
        address: criteria.address,
        applianceId: criteria.applianceId
      };
    case "userOrGroup":
      return { type: criteria.type, users: criteria.users, groups: criteria.groups };
    default:
      return criteria;
  }
};
const formatRequestCriteria = (virtualWanId) => (_a) => {
  var _b = _a, { source, destination } = _b, data = __objRest(_b, ["source", "destination"]);
  var _a2;
  return __spreadProps(__spreadValues({}, data), {
    source: formatOutgoingCriteria(source),
    destination: formatOutgoingCriteria(destination),
    icmp: (_a2 = data.icmp) != null ? _a2 : data.action,
    virtualWanId: data.virtualWanId || virtualWanId
  });
};
const formatResourceCriteria = (_a) => {
  var _b = _a, { source, destination } = _b, data = __objRest(_b, ["source", "destination"]);
  return __spreadProps(__spreadValues({}, data), {
    source: formatIncomingCriteria(source),
    destination: formatIncomingCriteria(destination)
  });
};
export {
  formatRequestCriteria,
  formatResourceCriteria
};
