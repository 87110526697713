var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { BooleanInput, FormSection } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { get } from "lodash";
import DhcpNetworksTableInput from "../../inputs/DhcpNetworksTableInput";
import DhcpLeasesReservationInput from "../../inputs/DhcpLeasesReservationInput";
import DhcpReservationsTableInput, { getReservationsFormProps } from "../../inputs/DhcpReservationsTableInput";
import {
  getNetworkId,
  useReservationFormValidation,
  useSiteInputValidations
} from "../../../../../hooks/createEditSiteHooks";
import { useWatch } from "react-hook-form";
const useStyles = makeStyles({
  dhcpMacOnlyLabel: {
    width: 256
  }
});
const DhcpTab = ({ applianceType, id }) => {
  const classes = useStyles();
  const lans = useWatch({ name: "lans" }) || [];
  const activeLans = lans.map((lan) => __spreadProps(__spreadValues({}, lan), { networkId: getNetworkId(lan) }));
  const reservedIps = activeLans.flatMap(
    (lan) => get(lan, "dhcp.reservations", []).map((reservation) => getNetworkId(lan) + ":" + get(reservation, "ipAddress"))
  );
  const reservableLans = activeLans.filter((lan) => get(lan, "dhcp.firstIp") && get(lan, "dhcp.lastIp"));
  const inputValidations = useSiteInputValidations();
  const reservationFormValidation = useReservationFormValidation();
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(FormSection, { title: "tesseract.appliances.settings.dhcp.networksSectionTitle", children: /* @__PURE__ */ jsx(DhcpNetworksTableInput, {}) }),
    /* @__PURE__ */ jsx(FormSection, { title: "tesseract.appliances.settings.dhcp.leasesSectionTitle", children: /* @__PURE__ */ jsx(
      DhcpLeasesReservationInput,
      __spreadProps(__spreadValues({
        applianceType,
        source: "lans",
        id,
        lans: activeLans
      }, getReservationsFormProps({
        reservableLans,
        inputValidations,
        reservationFormValidation
      })), {
        disabled: reservableLans.length < 1,
        disableReserveButton: (networkId, ip) => reservedIps.includes(networkId + ":" + ip)
      })
    ) }),
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.appliances.settings.dhcp.reservationsSectionTitle", children: [
      /* @__PURE__ */ jsx(
        BooleanInput,
        {
          source: "enableDhcpForKnownMacsOnly",
          label: "tesseract.appliances.settings.dhcp.blockUnknownMac",
          description: "tesseract.appliances.settings.dhcp.descriptions.blockUnknownMac",
          inputLabelProps: {
            classes: { inputLabel: classes.dhcpMacOnlyLabel }
          }
        }
      ),
      /* @__PURE__ */ jsx(
        DhcpReservationsTableInput,
        {
          activeLans,
          reservableLans,
          reservationFormValidation
        }
      )
    ] })
  ] });
};
var DhcpTab_default = DhcpTab;
export {
  DhcpTab,
  DhcpTab_default as default
};
