var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { CardSizes } from "../../../typesAndConstants";
import { createStyles, makeStyles } from "@mui/styles";
import { Divider, Grid } from "@barracuda-internal/bds-core";
import { DialogBody, Form, Input, SelectInput } from "@cuda-react/core";
import { SizeSelect } from "../SizeSelect";
import { sortBy } from "lodash";
const styles = (theme) => createStyles({
  previewWindow: {
    backgroundColor: "#F8F8F8",
    overflowY: "hidden",
    height: 388,
    padding: theme.spacing(2)
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  inputRoot: {
    width: "100%"
  },
  inputLabel: {
    width: 140
  },
  select: {
    width: 380
  },
  toggle: {
    width: 386
  }
});
const useStyles = makeStyles(styles);
const PreviewDialogContent = (props) => {
  var _a, _b;
  const { cards, onAdd, onClose, forceSmall, selectedCard } = props;
  const classes = useStyles(props);
  const cardsArray = sortBy(Object.keys(cards).map((cardId) => __spreadValues({ key: cardId }, cards[cardId])), (card) => card.key);
  const [preview, setPreview] = useState({ card: (_a = cardsArray == null ? void 0 : cardsArray[0]) == null ? void 0 : _a.key, size: CardSizes.small });
  return /* @__PURE__ */ jsx(
    DialogBody,
    {
      title: selectedCard ? "stratosReporting.previewDialog.editTitle" : "stratosReporting.previewDialog.title",
      form: true,
      onClose,
      children: /* @__PURE__ */ jsxs(
        Form,
        {
          onChange: (data) => setPreview(data),
          save: (values) => {
            onAdd == null ? void 0 : onAdd(values.card, values.size);
            onClose == null ? void 0 : onClose();
            return Promise.resolve({});
          },
          initialValues: selectedCard ? {
            card: selectedCard.componentId,
            size: selectedCard.size
          } : void 0,
          onCancel: onClose,
          formButtonToolbarProps: {
            label: `stratosReporting.previewDialog.${selectedCard ? "ok" : "add"}`
          },
          children: [
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                source: "card",
                choices: cardsArray,
                label: "stratosReporting.previewDialog.card",
                inputLabelProps: {
                  classes: { root: classes.inputRoot, inputLabel: classes.inputLabel }
                },
                inputProps: {
                  classes: { select: classes.select }
                },
                optionCategory: ((_b = cardsArray[0]) == null ? void 0 : _b.type) ? "type" : void 0,
                sortByDisplayName: true
              }
            ),
            /* @__PURE__ */ jsx(
              Input,
              {
                source: "size",
                label: "stratosReporting.previewDialog.size",
                component: SizeSelect,
                inputLabelProps: {
                  classes: { root: classes.inputRoot, inputLabel: classes.inputLabel }
                },
                inputProps: {
                  classes: { toggleGroup: classes.toggle },
                  forceSmall
                }
              }
            ),
            /* @__PURE__ */ jsx(Divider, { className: classes.divider }),
            /* @__PURE__ */ jsx(Grid, { container: true, justifyContent: "center", alignItems: "center", className: classes.previewWindow, children: (preview == null ? void 0 : preview.card) && cards[preview == null ? void 0 : preview.card] && (preview == null ? void 0 : preview.size) && React.createElement(
              cards[preview == null ? void 0 : preview.card].component,
              { size: preview.size, preview: true }
            ) })
          ]
        }
      )
    }
  );
};
export {
  PreviewDialogContent
};
