import { jsx, jsxs } from "react/jsx-runtime";
import {
  CustomField,
  DateField,
  getArrayDataContent,
  SelectedChoiceField,
  TablePage,
  TextField,
  useCrudProps,
  EmptyPageContent
} from "@cuda-react/core";
import { GetStartedBackgroundGraphic, SubscriptionsGraphic } from "@cuda-react/icons";
import apiResources from "../../../apiResources";
import { useTranslation } from "react-i18next";
const SubscriptionTable = () => {
  var _a;
  const pamProductsData = (_a = useCrudProps(apiResources.subscriptionsPamProducts)[0]) == null ? void 0 : _a.data;
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      authenticated: true,
      resource: apiResources.subscriptionsPam,
      title: "tesseract.subscription.pam.pageTitle",
      subtitle: "tesseract.subscription.pam.pageSubTitle",
      minCellWidth: 144,
      tableName: "PamSubscriptionsTable",
      resetOnGlobalParamChange: true,
      resizable: true,
      reorderable: true,
      editColumns: true,
      noDataPage: /* @__PURE__ */ jsx(
        EmptyPageContent,
        {
          background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
          icon: /* @__PURE__ */ jsx(SubscriptionsGraphic, {}),
          iconHeader: "tesseract.subscription.pam.table.noData.iconHeader",
          textHeader: "tesseract.subscription.pam.table.noData.textHeader",
          text: "tesseract.subscription.pam.table.noData.text"
        }
      ),
      children: [
        /* @__PURE__ */ jsx(
          SelectedChoiceField,
          {
            source: "product",
            label: "tesseract.subscription.pam.table.subscription",
            choices: getArrayDataContent(pamProductsData),
            optionValue: "key"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "serial",
            label: "tesseract.subscription.pam.table.serial",
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "amount",
            label: "tesseract.subscription.pam.table.amount",
            render: (value, data) => translate(`tesseract.subscription.pam.table.${data.usagePlan === "consumption" ? "msp" : "value"}`, { context: data == null ? void 0 : data.product, value })
          }
        ),
        /* @__PURE__ */ jsx(
          DateField,
          {
            source: "start",
            label: "tesseract.subscription.pam.table.startDate"
          }
        ),
        /* @__PURE__ */ jsx(
          DateField,
          {
            source: "expiry",
            label: "tesseract.subscription.pam.table.expirationDate"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "information",
            label: "tesseract.subscription.pam.table.information"
          }
        )
      ]
    }
  );
};
var SubscriptionTable_default = SubscriptionTable;
export {
  SubscriptionTable_default as default
};
