var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { get, isEqual } from "lodash";
import { clearLinkedSeries } from "./clearLinkedSeries";
import { handlePointClick } from "./handlePointClick";
const handleLinkedPointClick = (chart, setTooltip, translate, theme) => (event) => {
  var _a;
  const clickedPointSource = get(event, "target.point.source");
  const originalPoint = clickedPointSource && ((_a = chart.current) == null ? void 0 : _a.series.filter((series) => get(series, "userOptions.originalData")).flatMap((series) => get(series, "userOptions.originalData")).find((point) => point && point.pointData && isEqual(
    __spreadProps(__spreadValues({}, point.pointData), { linkedSeriesParent: void 0, series: void 0, linkedSeriesSources: void 0 }),
    __spreadProps(__spreadValues({}, clickedPointSource), { linkedSeriesParent: void 0, series: void 0, linkedSeriesSources: void 0 })
  )));
  if (originalPoint) {
    return handlePointClick(chart, originalPoint, setTooltip, translate, theme);
  } else {
    clearLinkedSeries(chart, setTooltip);
    return false;
  }
};
export {
  handleLinkedPointClick
};
