var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import {
  ConnectedForm,
  CrudTypes,
  DialogBody,
  FormSection,
  SearchableSelectInput,
  SelectInput,
  TextInput,
  useCrudSubscription,
  useGlobalParam,
  useMixpanel,
  validateMaxLengthMemo,
  validateMaxValueMemo,
  validateMinValueMemo,
  validateRegexMemo
} from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { formatRequestCriteria, formatResourceCriteria } from "../../../../../components/sourceAndDestination/formUtils";
import { useGeneratedSourceCriteriaSection } from "../../../../../hooks/sourceAndDestinationHooks";
import apiResources from "../../../../../apiResources";
import generateDestinationCriteriaSection from "../../../../../components/sourceAndDestination/generateDestinationCriteriaSection";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({});
const CreateEditIngressRule = (props) => {
  var _b, _c, _d, _e, _f;
  const _a = props, { create, id, onSuccess, defaultScope, type } = _a, dialogProps = __objRest(_a, ["create", "id", "onSuccess", "defaultScope", "type"]);
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const [currentApplianceType, setCurrentApplianceType] = useState(false);
  const [currentApplianceId, setCurrentApplianceId] = useState(false);
  const wanApplianceChoices = (_c = (_b = useCrudSubscription(CrudTypes.GET, currentApplianceType && currentApplianceId && (currentApplianceType.includes("ingressSite") ? apiResources.supportedSiteWans : apiResources.supportedGatewayWans), { filter: { id: currentApplianceId } })[0]) == null ? void 0 : _b.data) == null ? void 0 : _c.content;
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const registerAction = useMixpanel("Ingress NAT Rules", false);
  const validatePatPort = [validateMinValueMemo(1), validateMaxValueMemo(65e3)];
  const [selectedWanKey, setSelectedWanKey] = useState(void 0);
  const selectedWan = (_e = (_d = wanApplianceChoices == null ? void 0 : wanApplianceChoices[0]) == null ? void 0 : _d.wans) == null ? void 0 : _e.find((wan) => wan.key === selectedWanKey && wan.address);
  const wanIpChoices = selectedWan ? [
    { key: selectedWan == null ? void 0 : selectedWan.address, name: selectedWan == null ? void 0 : selectedWan.address, type: translate("tesseract.integration.ipSecV2.dialog.sourceDestSection.source.primaryAddress") },
    ...((selectedWan == null ? void 0 : selectedWan.addresses) || []).map((ip) => ({ key: ip, name: ip, type: translate("tesseract.integration.ipSecV2.dialog.sourceDestSection.source.additionalAddresses") }))
  ] : [];
  return /* @__PURE__ */ jsx(
    DialogBody,
    __spreadProps(__spreadValues({}, dialogProps), {
      form: true,
      title: `tesseract.security.ingressNatRules.settings.${create ? "create" : "edit"}.dialogTitle`,
      classes: { dialogContent: classes.dialogContent },
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          resource: apiResources.ingressNatRules,
          create,
          params: create ? void 0 : { id },
          onSubmitSuccess: (response) => {
            var _a2;
            onSuccess == null ? void 0 : onSuccess();
            dialogProps.onClose();
            registerAction(create ? "Create" : "Update", {
              action: response.action,
              sourceType: (_a2 = response.source) == null ? void 0 : _a2.type
            });
          },
          onCancel: dialogProps.onClose,
          onChange: (newValues) => {
            var _a2, _b2, _c2, _d2, _e2, _f2, _g, _h;
            if (((_a2 = newValues.destination) == null ? void 0 : _a2.type) && ((_b2 = newValues.destination) == null ? void 0 : _b2.type) !== currentApplianceType) {
              setCurrentApplianceType((_c2 = newValues.destination) == null ? void 0 : _c2.type);
              ((_d2 = newValues.destination) == null ? void 0 : _d2.applianceId) !== currentApplianceId ? setCurrentApplianceId((_e2 = newValues.destination) == null ? void 0 : _e2.applianceId) : setCurrentApplianceId(false);
            } else {
              setCurrentApplianceId((_f2 = newValues.destination) == null ? void 0 : _f2.applianceId);
            }
            if (((_g = newValues.destination) == null ? void 0 : _g.wan) !== selectedWanKey) {
              setSelectedWanKey((_h = newValues.destination) == null ? void 0 : _h.wan);
            }
          },
          formatRequestData: formatRequestCriteria(virtualWanId),
          formatResourceData: formatResourceCriteria,
          initialValues: create ? {
            destination: { applianceId: defaultScope, type },
            loggingEnabled: true
          } : void 0,
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "name",
                label: "tesseract.security.ingressNatRules.settings.name",
                description: "tesseract.security.ingressNatRules.settings.descriptions.name",
                validate: [validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")],
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "description",
                label: "tesseract.security.ingressNatRules.settings.description",
                description: "tesseract.security.ingressNatRules.settings.descriptions.description",
                validate: [validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]
              }
            ),
            useGeneratedSourceCriteriaSection(["internet", "networks"]),
            generateDestinationCriteriaSection(["onPremGateway", "ingressSite"]),
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                source: "destination.wan",
                label: "tesseract.security.ingressNatRules.settings.wan",
                description: "tesseract.security.ingressNatRules.settings.descriptions.wan",
                choices: wanApplianceChoices == null ? void 0 : wanApplianceChoices[0].wans,
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                label: "tesseract.security.ingressNatRules.settings.IPAddress",
                source: "destination.address",
                choices: wanIpChoices,
                optionCategory: ((_f = wanIpChoices[0]) == null ? void 0 : _f.type) ? "type" : void 0
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                label: "PAT Public Port",
                description: "tesseract.security.ingressNatRules.settings.descriptions.patPublicPort",
                source: "destination.port",
                type: "integer",
                validate: validatePatPort,
                options: {
                  type: "number",
                  InputProps: {
                    inputProps: {
                      min: 1,
                      step: 1
                    }
                  }
                }
              }
            ),
            /* @__PURE__ */ jsx(
              FormSection,
              {
                title: "tesseract.security.targetCriteria.section",
                collapsable: true,
                children: /* @__PURE__ */ jsx(
                  SearchableSelectInput,
                  {
                    source: "target",
                    label: "tesseract.security.ingressNatRules.settings.targetApplicationResource",
                    description: "tesseract.security.ingressNatRules.settings.descriptions.target",
                    resource: apiResources.validTargets,
                    clearOnFocus: true,
                    isRequired: true
                  }
                )
              }
            )
          ]
        }
      )
    })
  );
};
var CreateEditIngressRule_default = CreateEditIngressRule;
export {
  CreateEditIngressRule_default as default
};
