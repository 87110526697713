import { jsx, jsxs } from "react/jsx-runtime";
import { Button, Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { authLogout } from "../../../actions";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    // @ts-ignore Added by Cuda-react. Default (undefined), is fine
    backgroundColor: theme.palette.background.appbar,
    backgroundImage: "url('https://auth.barracudanetworks.com/img/bg_product-login.jpg')",
    backgroundSize: "cover",
    color: theme.palette.primary.contrastText
  },
  unauthorized: {
    fontSize: 32,
    color: theme.palette.primary.contrastText
  },
  unauthorizedMore: {
    paddingTop: theme.spacing(4),
    fontSize: 16,
    color: theme.palette.primary.contrastText
  },
  logout: {
    marginTop: theme.spacing(4)
  }
});
const useStyles = makeOverrideableStyles("BccUnauthorizedPage", styles);
const BccUnauthorizedPage = (props) => {
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const dispatch = useDispatch();
  return /* @__PURE__ */ jsxs("div", { className: classes.root, id: "login-page", children: [
    /* @__PURE__ */ jsx(Typography, { variant: "h1", className: classes.unauthorized, children: translate("cuda.auth.unauthorized") }),
    /* @__PURE__ */ jsx(Typography, { variant: "h4", className: classes.unauthorizedMore, children: translate("cuda.auth.unauthorizedMore") }),
    /* @__PURE__ */ jsx(Button, { color: "primary", size: "small", onClick: () => dispatch(authLogout()), variant: "contained", className: classes.logout, children: translate("cuda.auth.logout") })
  ] });
};
var BccUnauthorizedPage_default = BccUnauthorizedPage;
export {
  BccUnauthorizedPage,
  BccUnauthorizedPage_default as default,
  styles
};
