var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Checkbox, List, ListItem, ListItemText, Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import { Trans } from "react-i18next";
import { Input } from "@cuda-react/core/lib/components/inputs/Input/Input";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2, 2, 2, 8),
    "& li": {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  categoryLabel: {
    color: theme.palette.text.secondary,
    lineHeight: "19px",
    textAlign: "left",
    padding: 0,
    width: 202
  },
  checkedStatus: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    width: theme.spacing(2)
  }
}));
const SimpleBooleanChecklist = ({ categoryChoices, disabled, value, onChange, onBlur }) => {
  const classes = useStyles();
  const updateCategory = (itemKey, checked, value2, onChange2, onBlur2) => {
    let newValue = [...value2].filter((item) => item !== itemKey);
    if (checked) {
      newValue.push(itemKey);
    }
    onChange2(newValue);
    onBlur2(newValue);
  };
  const renderCategories = () => categoryChoices.map(
    (category) => /* @__PURE__ */ jsx(React.Fragment, { children: /* @__PURE__ */ jsxs(ListItem, { children: [
      /* @__PURE__ */ jsx(ListItemText, { className: classes.categoryLabel, children: /* @__PURE__ */ jsx(Trans, { i18nKey: category.name }) }),
      /* @__PURE__ */ jsx(
        Checkbox,
        {
          disabled,
          checked: value == null ? void 0 : value.includes(category.key),
          onChange: (event) => updateCategory(category.key, event.target.checked, value, onChange, onBlur),
          color: "primary",
          id: "boolean-input-" + category.key
        }
      ),
      /* @__PURE__ */ jsx("div", { className: classes.checkedStatus, children: (value == null ? void 0 : value.includes(category.key)) ? /* @__PURE__ */ jsx(Typography, { children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.webMonitorPolicies.monitored" }) }) : null })
    ] }) }, category.key)
  );
  return /* @__PURE__ */ jsx(List, { className: classes.root, id: "simple-boolean-checklist", children: renderCategories() });
};
const SimpleBooleanCheckListInput = (props) => /* @__PURE__ */ jsx(Input, __spreadValues({ component: SimpleBooleanChecklist, noLabel: true }, props));
var SimpleBooleanChecklistInput_default = SimpleBooleanCheckListInput;
export {
  SimpleBooleanChecklist,
  SimpleBooleanChecklistInput_default as default
};
