import {
  BANNER_NOTIFICATION,
  CrudTypes,
  getDataContent,
  notificationHide,
  notificationShowBanner,
  useCrudSubscription,
  useDeepCompareEffect,
  useGlobalParam,
  usePrevious,
  WARNING_NOTIFICATION
} from "@cuda-react/core";
import { useDispatch } from "react-redux";
import { DAY_AS_MILLISECONDS, daysBetweenAbs } from "../utils/dateTime";
import apiResources from "../apiResources";
const getDaysUntilSubscriptionExpires = (expiryDate) => daysBetweenAbs(/* @__PURE__ */ new Date(), new Date(expiryDate));
const subscriptionExpiresSoon = (daysUntilExpiry, isDemoMode) => daysUntilExpiry ? daysUntilExpiry < 15 && !isDemoMode : false;
const getDaysUntilIsDeprecated = (deprecatedDate) => daysBetweenAbs(/* @__PURE__ */ new Date(), new Date(deprecatedDate));
const deprecatedExpiresSoon = (daysUntilIsDeprecated) => daysUntilIsDeprecated !== false && daysUntilIsDeprecated < 15;
const getNotification = (numberOfDays, type) => {
  let notification;
  let timeframe;
  if (numberOfDays && numberOfDays < 1) {
    timeframe = "Today";
  } else if (numberOfDays && numberOfDays < 2) {
    timeframe = "OneDay";
  } else {
    timeframe = "Soon";
  }
  notification = notificationShowBanner(
    "tesseract.statusBanner." + type + "Expires" + timeframe,
    WARNING_NOTIFICATION,
    true,
    false,
    {
      days: typeof numberOfDays === "number" ? Math.floor(numberOfDays) : void 0,
      linkStart: '<a target="_blank" rel="noopener noreferrer" href="https://portal.azure.com/#blade/HubsExtension/BrowseResourceBlade/resourceType/Microsoft.SaaS%2Fresources">',
      linkEnd: "</a>"
    }
  );
  return notification;
};
var useSubscriptionsBannerNotification_default = () => {
  const [subscriptionStatus] = useCrudSubscription(CrudTypes.GET, apiResources.subscriptions, {}, {
    pollInterval: DAY_AS_MILLISECONDS
  });
  const [mode] = useGlobalParam("userData.mode");
  const subscriptionData = getDataContent(subscriptionStatus);
  const daysUntilSubscriptionExpires = (subscriptionData == null ? void 0 : subscriptionData.expiry) && getDaysUntilSubscriptionExpires(subscriptionData.expiry) !== null ? getDaysUntilSubscriptionExpires(subscriptionData.expiry) : false;
  const daysUntilIsDeprecated = subscriptionData && !subscriptionData.deprecated && getDaysUntilIsDeprecated(subscriptionData.deprecatedDatetime) !== null ? getDaysUntilIsDeprecated(subscriptionData.deprecatedDatetime) : false;
  const dispatch = useDispatch();
  let notification;
  if (deprecatedExpiresSoon(daysUntilIsDeprecated)) {
    notification = getNotification(daysUntilIsDeprecated, "deprecated");
  } else if (subscriptionExpiresSoon(daysUntilSubscriptionExpires, mode === "demo")) {
    notification = getNotification(daysUntilSubscriptionExpires, "subscription");
  }
  const previousNotification = usePrevious(notification);
  useDeepCompareEffect(() => {
    if (previousNotification) {
      dispatch(notificationHide(BANNER_NOTIFICATION, previousNotification.notification.content));
    }
    if (notification) {
      dispatch(notification);
    }
  }, [notification]);
  return null;
};
export {
  useSubscriptionsBannerNotification_default as default
};
