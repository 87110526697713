import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const IngressNatGraphic = ({ className }) => {
  var _a, _b, _c;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-graphic"),
      id: "cuda-graphic-ingressnat",
      viewBox: "0 0 96 96",
      children: /* @__PURE__ */ jsx("g", { id: "ArtBoard11", children: /* @__PURE__ */ jsxs("g", { id: "Ebene-1", children: [
        /* @__PURE__ */ jsx("path", { style: { fill: (_a = theme.palette.general) == null ? void 0 : _a.white, strokeWidth: 0 }, d: "M68,59.3h-3.4v6.9h-6.9v3.4h6.9v6.9h3.4v-6.9h6.9v-3.4h-6.9v-6.9Z" }),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 },
            d: "M20.9,71.3c-2.6,0-4.6-2.1-4.6-4.6s2.1-4.6,4.6-4.6,4.6,2.1,4.6,4.6-2.1,4.6-4.6,4.6M20.9,47.6c-2.6,0-4.6-2.1-4.6-4.6s2.1-4.6,4.6-4.6,4.6,2.1,4.6,4.6-2.1,4.6-4.6,4.6M20.9,23.9c-2.6,0-4.6-2.1-4.6-4.6s2.1-4.6,4.6-4.6,4.6,2.1,4.6,4.6-2.1,4.6-4.6,4.6M56.7,29.9l-3.5,3.5,7,7h-16.1v-.4c-1-8.9-6.4-16.5-14.4-20.5h-.2c0-.1,0-.4,0-.4-.1-4.7-3.8-8.3-8.4-8.3s-8.4,3.8-8.4,8.4,3.8,8.4,8.4,8.4,4.9-1.2,6.6-3.2l.2-.3.3.2c5.9,3.2,9.9,9,10.8,15.5v.5c0,0-9.9,0-9.9,0v-.3c-1.3-3.4-4.5-5.6-8-5.6s-8.4,3.8-8.4,8.4,3.8,8.4,8.4,8.4,6.7-2.3,7.9-5.6v-.3h10.1v.5c-1,6.5-5,12.3-10.9,15.5l-.3.2-.2-.3c-1.7-2-4.1-3.2-6.6-3.2-4.7,0-8.4,3.8-8.4,8.4s3.8,8.4,8.4,8.4,8.3-3.6,8.4-8.3v-.3s.2-.1.2-.1c8-4,13.4-11.7,14.4-20.5v-.4h16.2l-7,7h0c3.2-2.7,7.3-4.5,11.8-4.8l4.8-4.8-13.1-13.1Z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_c = theme.palette.success) == null ? void 0 : _c.main, strokeWidth: 0 },
            d: "M66.2,50.6c-9.5,0-17.2,7.7-17.2,17.2s7.7,17.2,17.2,17.2,17.2-7.7,17.2-17.2-7.7-17.2-17.2-17.2ZM74.9,69.6h-6.9v6.9h-3.4v-6.9h-6.9v-3.4h6.9v-6.9h3.4v6.9h6.9v3.4Z"
          }
        )
      ] }) })
    }
  );
};
var IngressNatGraphic_default = IngressNatGraphic;
export {
  IngressNatGraphic,
  IngressNatGraphic_default as default
};
