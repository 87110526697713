var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { IconButton, Tooltip, Typography } from "@barracuda-internal/bds-core";
import get from "lodash/get";
import React from "react";
import { AuditStatusIconMap, BooleanIcon, NotificationStatusIconMap, StatusIcon } from "@cuda-react/icons";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useTranslation } from "react-i18next";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  tooltip: __spreadProps(__spreadValues({}, theme.typography.body2), {
    // @ts-ignore Added by Cuda-react. Default (undefined), is fine
    color: theme.palette.text.tooltip
  }),
  iconButton: (props) => ({
    cursor: props.onClick ? "pointer" : "default",
    "&:hover": {
      backgroundColor: props.onClick ? void 0 : "transparent"
    }
  }),
  text: (props) => {
    const value = get(props.data, props.textSource || "");
    return {
      color: props.getStatusColorFromTheme ? props.getStatusColorFromTheme(value, theme) : void 0,
      display: "inline",
      verticalAlign: "middle",
      fontSize: theme.typography.pxToRem(14)
    };
  }
});
const useStyles = makeOverrideableStyles("StatusIcon", styles);
const getIcon = (props) => {
  const { iconSource, source, data, isAudit, isNotification, icon, isBoolean } = props;
  let { iconMap } = props;
  const trueSource = iconSource || source || "";
  const value = get(data, trueSource);
  if (isBoolean) {
    return /* @__PURE__ */ jsx(BooleanIcon, { status: value });
  }
  if (isAudit) {
    iconMap = iconMap || AuditStatusIconMap;
  }
  if (isNotification) {
    iconMap = iconMap || NotificationStatusIconMap;
  }
  const iconToRender = typeof icon === "function" ? icon(value, props.data) : icon;
  return /* @__PURE__ */ jsx(
    StatusIcon,
    {
      status: value,
      icon: iconToRender,
      iconMap
    }
  );
};
const getValue = (translate, prefix, specificSource, source, data, item) => {
  const trueSource = specificSource || source || "";
  const value = get(data, trueSource);
  let composedValue;
  if (specificSource) {
    composedValue = value;
  } else {
    composedValue = typeof item === "function" ? item == null ? void 0 : item(value, data) : item;
  }
  if (composedValue) {
    if (prefix) {
      composedValue = prefix + composedValue;
    }
  }
  return translate(composedValue);
};
const renderText = (props, classes, translate) => {
  const { text, textSource, textPrefix, source, data } = props;
  let textToRender = "";
  if (text || textSource) {
    textToRender = getValue(translate, textPrefix, textSource, source, data, text);
  }
  return textToRender ? /* @__PURE__ */ jsx(Typography, { className: classes.text, variant: "body2", children: textToRender }) : null;
};
const renderTooltip = (iconButtonToRender, props, classes, translate) => {
  const { tooltip, tooltipSource, tooltipPrefix, source, data, tooltipPosition = "top-end" } = props;
  let tooltipText = "";
  if (tooltip || tooltipSource) {
    tooltipText = getValue(translate, tooltipPrefix, tooltipSource, source, data, tooltip);
  }
  return tooltipText ? /* @__PURE__ */ jsx(
    Tooltip,
    {
      title: tooltipText,
      placement: tooltipPosition,
      classes: { tooltip: classes.tooltip },
      children: /* @__PURE__ */ jsx("span", { children: iconButtonToRender })
    }
  ) : iconButtonToRender;
};
const StatusIconField = (props) => {
  const { onClick, hide } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const iconToRender = getIcon(props);
  const textToRender = renderText(props, classes, translate);
  const iconButtonToRender = /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      IconButton,
      {
        size: "small",
        onClick: onClick ? (event) => onClick(event, props) : void 0,
        className: classes.iconButton,
        disableTouchRipple: !onClick,
        children: iconToRender
      }
    ),
    textToRender
  ] });
  if (hide == null ? void 0 : hide(props)) {
    return /* @__PURE__ */ jsx("div", {});
  }
  return renderTooltip(iconButtonToRender, props, classes, translate);
};
var StatusIconField_default = StatusIconField;
export {
  StatusIconField,
  StatusIconField_default as default,
  styles
};
