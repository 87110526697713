import { jsx, jsxs } from "react/jsx-runtime";
import { Card as BdsCard, CardContent, CardHeader, Grid, Typography } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import { CardSizeOptions, CardSizes } from "../../../typesAndConstants";
import { WidgetErrorIcon } from "@cuda-react/icons";
import FilterIcon from "@mui/icons-material/FilterAlt";
const previewScales = {
  [CardSizes.small]: "scale(0.9) translateY(-8%)",
  [CardSizes.medium]: "scale(0.75) translateY(-20%)",
  [CardSizes.large]: "scale(0.5) translateY(-50%)"
};
const styles = (theme) => createStyles({
  root: {
    margin: 0,
    textAlign: "left",
    display: "inline-block",
    verticalAlign: "top",
    maxWidth: "100%",
    transform: ({ preview, size }) => preview ? previewScales[size] : void 0,
    width: ({ size }) => {
      var _a;
      return (_a = CardSizeOptions[size]) == null ? void 0 : _a.width;
    },
    height: ({ size }) => {
      var _a;
      return (_a = CardSizeOptions[size]) == null ? void 0 : _a.height;
    },
    border: "unset",
    boxShadow: "none",
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: "solid"
  },
  cardHeader: {
    textAlign: "left",
    alignItems: "flex-start",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 2, 0.25, 2),
    overflowY: "visible"
  },
  titleTypography: {
    fontSize: 16,
    fontWeight: "bold",
    overflowY: "visible",
    color: theme.palette.reporting.titleColor,
    "& *": {
      fontWeight: "bold"
    },
    minHeight: 28
  },
  cardContent: {
    minWidth: `100%`,
    padding: 0,
    "&:last-child": {
      paddingBottom: 0
    },
    overflowX: "hidden",
    overflowY: "auto",
    gridRowStart: 2,
    gridColumnEnd: 5,
    gridColumnStart: 1,
    height: ({ title }) => title ? "calc(100% - 50px)" : "100%"
  },
  noDataContainer: {
    height: ({ size }) => {
      var _a;
      return `calc(${(_a = CardSizeOptions[size]) == null ? void 0 : _a.height}px - ${theme.spacing(10)})`;
    },
    width: "100%"
  },
  noDataIcon: {
    width: 64,
    height: 64
  },
  noDataMessage: {
    textAlign: "center",
    color: theme.palette.reporting.fontColor
  }
});
const useStyles = makeStyles(styles);
const BaseCard = (props) => {
  const { children, id = "cuda-generic-card", className, title, noData, activeFilter } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(BdsCard, { className: classNames(classes.root, className), id, children: [
    title && /* @__PURE__ */ jsx(
      CardHeader,
      {
        action: activeFilter ? /* @__PURE__ */ jsx(FilterIcon, { color: "primary", sx: { marginRight: 0.5 } }) : void 0,
        title: typeof title === "string" ? translate(title) : title,
        className: classes.cardHeader,
        titleTypographyProps: { className: classes.titleTypography }
      }
    ),
    /* @__PURE__ */ jsx(CardContent, { className: classes.cardContent, children: noData ? /* @__PURE__ */ jsxs(Grid, { container: true, justifyContent: "center", alignContent: "center", className: classes.noDataContainer, children: [
      /* @__PURE__ */ jsx(Grid, { xs: 12, style: { textAlign: "center" }, children: /* @__PURE__ */ jsx(WidgetErrorIcon, { className: classes.noDataIcon }) }),
      /* @__PURE__ */ jsx(Grid, { xs: 12, children: /* @__PURE__ */ jsx(Typography, { variant: "h6", className: classes.noDataMessage, children: translate("stratosReporting.noData.message") }) })
    ] }) : children })
  ] });
};
export {
  BaseCard
};
