import { jsx, jsxs } from "react/jsx-runtime";
import ForwardedDomains from "./cards/ForwardedDomains";
import { BasicPage, useMixpanel, usePreview } from "@cuda-react/core";
import FirmwareUpdates from "./cards/updates/FirmwareUpdates";
import SecurityUpdates from "./cards/updates/SecurityUpdates";
import { makeStyles } from "@mui/styles";
import SdwanConnectorNetwork from "./cards/SdwanConnectorNetwork";
import DcAgent from "./cards/DcAgent";
import DhcpServer from "./cards/DhcpServer";
import ReportCreatorAcl from "./cards/ReportCreatorAcl";
import SIPProxy from "./cards/SIPProxy";
const useStyles = makeStyles((theme) => ({
  content: {
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(3)
    }
  }
}));
const NetworkSettings = () => {
  const classes = useStyles();
  useMixpanel("Network Settings");
  const sipProxy = usePreview("sip");
  return /* @__PURE__ */ jsxs(
    BasicPage,
    {
      title: "tesseract.network.pageTitle",
      subtitle: "tesseract.network.settings",
      classes: { content: classes.content },
      children: [
        /* @__PURE__ */ jsx(FirmwareUpdates, {}),
        /* @__PURE__ */ jsx(SecurityUpdates, {}),
        /* @__PURE__ */ jsx(ForwardedDomains, {}),
        /* @__PURE__ */ jsx(ReportCreatorAcl, {}),
        /* @__PURE__ */ jsx(SdwanConnectorNetwork, {}),
        /* @__PURE__ */ jsx(DcAgent, {}),
        /* @__PURE__ */ jsx(DhcpServer, {}),
        sipProxy ? /* @__PURE__ */ jsx(SIPProxy, {}) : null
      ]
    }
  );
};
var NetworkSettings_default = NetworkSettings;
export {
  NetworkSettings,
  NetworkSettings_default as default
};
