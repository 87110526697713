import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const LiveLogOneWayInactiveIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-access-live-log-one-way-inactive",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
          d: "M37.4,47.4l-3.5-3.5,11.4-11.4H5.2v-5h40.1l-11.4-11.4,3.5-3.5,17.4,17.4-17.4,17.4Z"
        }
      )
    }
  );
};
var LiveLogOneWayInactiveIcon_default = LiveLogOneWayInactiveIcon;
export {
  LiveLogOneWayInactiveIcon_default as default
};
