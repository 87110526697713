import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { Button, CircularProgress, Paper, Typography } from "@barracuda-internal/bds-core";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { PageFirst, PageLast, PageNext, PagePrevious } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { useTranslation } from "react-i18next";
import { get, union } from "lodash";
import classNames from "classnames";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useDeepCompareEffect } from "../../../hooks";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  dualListInput: {
    textAlign: "left",
    display: "inline-block",
    paddingLeft: "1rem",
    height: "250px",
    maxWidth: "100%"
  },
  errorMessage: {
    color: theme.palette.error.main,
    position: "absolute",
    bottom: -20,
    fontSize: 12
  },
  additionalInfo: {
    position: "absolute",
    bottom: -20,
    fontSize: 12
  },
  additionalInfoWithError: {
    position: "absolute",
    bottom: -40,
    fontSize: 12
  },
  additionalInfoInRedWithError: {
    color: theme.palette.error.main,
    position: "absolute",
    bottom: -40,
    fontSize: 12
  },
  errorPaper: {
    // @ts-ignore This works.. not sure why typescript doesnt like it.
    boxShadow: [
      theme.palette.error.main + " 0px 1px 6px",
      theme.palette.error.main + " 0px 1px 4px"
    ]
  },
  disabled: {
    // @ts-ignore Added by Cuda-react. Default (undefined), is fine
    borderColor: theme.palette.disabled,
    // @ts-ignore Added by Cuda-react. Default (undefined), is fine
    color: theme.palette.disabled
  },
  dualList: {
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "nowrap",
    width: "100%",
    height: "calc(100% - " + theme.spacing(3) + ")",
    position: "relative"
  },
  listContainer: {
    margin: theme.spacing(0.25),
    paddingTop: 4,
    height: "calc(100% - 4px)",
    flex: "1 1 auto",
    overflowY: "scroll",
    overflowX: "auto",
    width: "35rem"
  },
  actionContainer: {
    flex: "1 1 auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    height: "100%",
    margin: theme.spacing(1),
    maxWidth: "90px"
  },
  actionButton: {
    minWidth: 50,
    maxWidth: 80,
    width: "100%",
    color: theme.palette.primary.contrastText
  },
  table: {
    padding: 0
  },
  tableRow: {
    border: 0,
    height: "auto",
    cursor: "default",
    "&$selected": {
      backgroundColor: theme.palette.primary.main,
      "& td": {
        color: theme.palette.primary.contrastText
      }
    },
    "&$selected:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  },
  tableCell: {
    height: "auto",
    padding: theme.spacing(0.25),
    border: 0
  },
  section: {
    paddingLeft: theme.spacing(1),
    fontWeight: "bold"
  },
  item: {
    paddingLeft: theme.spacing(3)
  },
  spinnerBlock: {
    height: 30,
    width: "100%",
    margin: 0
  },
  selected: {}
});
const useStyles = makeOverrideableStyles("DualList", styles);
const choicesToRows = (props) => {
  const leftRows = [];
  const rightRows = [];
  const { choices = [], value = [], optionValue = "id", optionText = "name", optionBlockData = "data", optionBlockValue = "value" } = props;
  const selected = Array.isArray(value) ? value : [value];
  choices.forEach((block) => {
    const blockData = get(block, optionBlockData);
    const blockValue = get(block, optionBlockValue);
    if (blockData.some((item) => !selected.includes(get(item, optionValue)))) {
      leftRows.push({ name: typeof optionText === "function" ? optionText(blockValue) : get(blockValue, optionText) });
    }
    if (blockData.some((item) => selected.includes(get(item, optionValue)))) {
      rightRows.push({ name: typeof optionText === "function" ? optionText(blockValue) : get(blockValue, optionText) });
    }
    blockData.forEach((item) => {
      const name = typeof optionText === "function" ? optionText(item) : get(item, optionText);
      const value2 = get(item, optionValue);
      if (!selected.includes(value2)) {
        leftRows.push({ name, value: value2 });
      } else {
        rightRows.push({ name, value: value2 });
      }
    });
  });
  return { leftRows, rightRows };
};
const DualList = (props) => {
  const {
    translateChoice = true,
    disabled,
    error,
    multiSelectable = true,
    hasSpinner,
    hideAdditionalInfoOnError,
    loading,
    id,
    disableLeft,
    disableRight,
    value = [],
    onChange,
    initialValues,
    additionalInfo,
    maxSelectable,
    onBlur
  } = props;
  const valueAsArray = Array.isArray(value) ? value : [value];
  const { leftRows, rightRows } = choicesToRows(props);
  const [leftSelectedRows, setLeftSelectedRows] = useState([]);
  const [rightSelectedRows, setRightSelectedRows] = useState([]);
  const handleRowClick = (row, leftState) => {
    if (!row.value) {
      return;
    }
    const selected = leftState ? leftSelectedRows : rightSelectedRows;
    const selectedIndex = selected.indexOf(row.name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    leftState ? setLeftSelectedRows(newSelected) : setRightSelectedRows(newSelected);
  };
  const isSelected = (name, leftState) => {
    const selected = leftState ? leftSelectedRows : rightSelectedRows;
    return selected.indexOf(name) !== -1;
  };
  const addAll = () => {
    const { leftRows: leftRows2 } = choicesToRows(props);
    const newSelection = valueAsArray.concat(leftRows2.filter((item) => !!item.value).map((item) => item.value));
    onChange == null ? void 0 : onChange(newSelection);
    onBlur == null ? void 0 : onBlur();
  };
  const addToSelection = () => {
    const selected = valueAsArray;
    const { leftRows: leftRows2 } = choicesToRows(props);
    const newSelection = selected.slice(0);
    leftRows2.forEach((row) => {
      if (row.value && leftSelectedRows.includes(row.name)) {
        newSelection.push(row.value);
      }
    });
    onChange == null ? void 0 : onChange(newSelection);
    onBlur == null ? void 0 : onBlur();
  };
  const addOneToSelection = (currentValue) => {
    const newSelection = union([], valueAsArray, [currentValue]);
    onChange == null ? void 0 : onChange(newSelection);
    onBlur == null ? void 0 : onBlur();
  };
  const removeOneFromSelection = (currentValue) => {
    const newSelection = valueAsArray.filter((val) => val !== currentValue);
    onChange == null ? void 0 : onChange(newSelection);
    onBlur == null ? void 0 : onBlur();
  };
  const removeFromSelection = () => {
    const { rightRows: rightRows2 } = choicesToRows(props);
    const removeItems = [];
    rightRows2.forEach((row) => {
      if (row.value && rightSelectedRows.includes(row.name)) {
        removeItems.push(row.value);
      }
    });
    const newSelection = valueAsArray.filter((item) => !removeItems.includes(item));
    onChange == null ? void 0 : onChange(newSelection);
    onBlur == null ? void 0 : onBlur();
  };
  useDeepCompareEffect(() => {
    setLeftSelectedRows([]);
    setRightSelectedRows([]);
  }, [value]);
  useDeepCompareEffect(() => {
    const timer = setTimeout(() => {
      initialValues && (onChange == null ? void 0 : onChange(initialValues));
    }, 50);
    return () => clearTimeout(timer);
  }, [initialValues]);
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const additionalInfoClass = (error2, maxSelectable2, currentCount) => {
    if (maxSelectable2) {
      if (!error2) {
        if (currentCount > maxSelectable2) {
          return classes.errorMessage;
        } else {
          return classes.additionalInfo;
        }
      } else {
        if (currentCount > maxSelectable2) {
          return classes.additionalInfoInRedWithError;
        } else {
          return classes.additionalInfoWithError;
        }
      }
    } else {
      if (!error2) {
        return classes.additionalInfo;
      } else {
        return classes.additionalInfoWithError;
      }
    }
  };
  return /* @__PURE__ */ jsx("div", { className: classes.dualListInput, id: id && "dual-list-input-" + id, children: /* @__PURE__ */ jsxs(Typography, { component: "div", className: classes.dualList, children: [
    /* @__PURE__ */ jsx(Paper, { className: classNames(classes.listContainer, error ? classes.errorPaper : void 0), children: /* @__PURE__ */ jsx(Table, { className: classes.table, children: /* @__PURE__ */ jsx(TableBody, { children: leftRows.map((row, index) => /* @__PURE__ */ jsx(
      TableRow,
      {
        className: classes.tableRow,
        classes: { selected: classes.selected },
        onClick: () => handleRowClick(row, true),
        selected: isSelected(row.name, true),
        children: /* @__PURE__ */ jsx(
          TableCell,
          {
            className: classNames(
              classes.tableCell,
              !row.value ? classes.section : classes.item,
              disabled || disableRight ? classes.disabled : void 0
            ),
            onDoubleClick: row.value ? () => addOneToSelection(row.value) : void 0,
            children: translateChoice ? translate(row.name) : row.name
          }
        )
      },
      index
    )) }) }) }),
    /* @__PURE__ */ jsxs("div", { className: classes.actionContainer, children: [
      multiSelectable && /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          color: "primary",
          size: "small",
          className: classes.actionButton,
          onClick: () => addAll(),
          disabled: !!(disabled || disableRight),
          children: /* @__PURE__ */ jsx(PageLast, { id: "cuda-dual-list-add-all" })
        }
      ),
      /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          color: "primary",
          size: "small",
          className: classes.actionButton,
          onClick: () => addToSelection(),
          disabled: !!(disabled || disableRight),
          children: /* @__PURE__ */ jsx(PageNext, { id: "cuda-dual-list-add-selected" })
        }
      ),
      hasSpinner && /* @__PURE__ */ jsx("div", { className: classes.spinnerBlock, children: loading && /* @__PURE__ */ jsx(CircularProgress, { size: 30, thickness: 2 }) }),
      /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          color: "primary",
          size: "small",
          className: classes.actionButton,
          onClick: () => removeFromSelection(),
          disabled: !!(disabled || disableLeft),
          children: /* @__PURE__ */ jsx(PagePrevious, { id: "cuda-dual-list-remove-selected" })
        }
      ),
      multiSelectable && /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          color: "primary",
          size: "small",
          className: classes.actionButton,
          onClick: () => {
            onChange == null ? void 0 : onChange([]);
            onBlur == null ? void 0 : onBlur();
          },
          disabled: !!(disabled || disableLeft),
          children: /* @__PURE__ */ jsx(PageFirst, { id: "cuda-dual-list-remove-all" })
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(Paper, { className: classNames(classes.listContainer, error ? classes.errorPaper : void 0), children: [
      /* @__PURE__ */ jsx(Table, { className: classes.table, children: /* @__PURE__ */ jsx(TableBody, { children: rightRows.map((row, index) => /* @__PURE__ */ jsx(
        TableRow,
        {
          className: classes.tableRow,
          classes: { selected: classes.selected },
          onClick: () => handleRowClick(row, false),
          selected: isSelected(row.name, false),
          children: /* @__PURE__ */ jsx(
            TableCell,
            {
              className: classNames(
                classes.tableCell,
                !row.value ? classes.section : classes.item,
                disabled || disableLeft ? classes.disabled : void 0
              ),
              onDoubleClick: row.value ? () => removeOneFromSelection(row.value) : void 0,
              children: translateChoice ? translate(row.name) : row.name
            }
          )
        },
        index
      )) }) }),
      error && /* @__PURE__ */ jsx("div", { className: classes.errorMessage, children: error }),
      !(hideAdditionalInfoOnError && error) && additionalInfo && /* @__PURE__ */ jsx("div", { className: additionalInfoClass(error, maxSelectable, valueAsArray.length), children: translate(additionalInfo, { maxCount: maxSelectable, currentCount: valueAsArray.length }) })
    ] })
  ] }) });
};
var DualList_default = DualList;
export {
  DualList,
  DualList_default as default
};
