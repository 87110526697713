var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { Button, Tooltip } from "@barracuda-internal/bds-core";
import { Download } from "@barracuda-internal/bds-core/dist/Icons/Core";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertRESTRequestToHTTP, CrudTypes } from "../../../clients";
import { getDataContent } from "../../../utils";
import { useCrudFetch, useGlobalParam } from "../../../hooks";
import { IconButton } from "@mui/material";
import ConditionalWrapper from "../../functional/ConditionalWrapper/ConditionalWrapper";
import { get, merge } from "lodash";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  downloadButton: {
    minWidth: "50px !important"
  },
  downloadIcon: {
    color: (props) => props.disabled ? theme.palette.text.disabled : void 0
  },
  tooltip: __spreadValues({}, theme.typography.body2)
});
const useStyles = makeOverrideableStyles("DownloadButton", styles);
const convertStringToCrudType = (stringCrudType) => {
  switch (stringCrudType) {
    case "PUT":
      return CrudTypes.UPDATE;
    case "POST":
      return CrudTypes.CREATE;
    case "GET":
      return CrudTypes.GET;
    case "DELETE":
      return CrudTypes.DELETE;
    default:
      return CrudTypes.GET;
  }
};
const DownloadButton = (props) => {
  const { resource: originalResource, filename, filedata, filetype, formatData, params, quietErrors, label, iconButton, tooltipText, disabled, disabledTooltipText, directDownload, requestMethod } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const [download, setDownload] = useState();
  const [globalParams] = useGlobalParam();
  const resolvedParams = typeof params === "function" ? params(props) : params;
  const resource = typeof originalResource === "function" ? originalResource(props) : originalResource;
  const crudType = requestMethod ? convertStringToCrudType(requestMethod) : void 0;
  const { url: resolvedUrl } = directDownload ? convertRESTRequestToHTTP(crudType || CrudTypes.GET, resource, merge({}, params, globalParams)) : { url: void 0 };
  const [data, loading, fetch] = useCrudFetch(crudType || CrudTypes.GET, resource, resolvedParams, { quietErrors });
  const downloadData = getDataContent(data) || filedata;
  const getFileInfo = (propToGet, type) => typeof propToGet === "function" ? propToGet(downloadData, props) : type === "name" ? translate(propToGet || resource) : propToGet;
  const fileName = getFileInfo(filename, "name");
  const fileType = getFileInfo(filetype, "typeFile");
  useEffect(() => {
    if (!loading && downloadData && download && !get(data, "error")) {
      fileDownload(formatData ? formatData(downloadData, props) : downloadData, fileName + "." + fileType);
      setDownload(false);
    }
  }, [loading, download]);
  return /* @__PURE__ */ jsx(
    ConditionalWrapper,
    {
      condition: !!tooltipText || !!(disabledTooltipText && disabled),
      wrapper: (children) => /* @__PURE__ */ jsx(
        Tooltip,
        {
          title: translate(disabled ? disabledTooltipText : tooltipText),
          placement: "top",
          classes: { tooltip: classes.tooltip },
          children: /* @__PURE__ */ jsx("span", { children })
        }
      ),
      children: iconButton ? /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          onClick: () => {
            resource && fetch();
            setDownload(true);
          },
          disabled,
          children: /* @__PURE__ */ jsx(Download, { className: classes.downloadIcon })
        }
      ) : /* @__PURE__ */ jsx(
        Button,
        {
          onClick: !directDownload ? () => {
            resource && fetch();
            setDownload(true);
          } : void 0,
          href: resolvedUrl,
          download: !directDownload ? void 0 : fileName + "." + fileType,
          variant: "contained",
          size: "small",
          startIcon: /* @__PURE__ */ jsx(Download, {}),
          className: classes.downloadButton,
          color: props.color || "secondary",
          disabled,
          children: translate(label || "cuda.buttons.downloadCsv")
        }
      )
    }
  );
};
DownloadButton.defaultProps = {
  disabled: false,
  filetype: "csv"
};
var DownloadButton_default = DownloadButton;
export {
  DownloadButton,
  DownloadButton_default as default,
  styles
};
