var en_default = {
  translation: {
    stratosReporting: {
      resourceStatisticsCard: {
        overview: "Total"
      },
      domainCard: {
        title: "Domains"
      },
      dualTopBarBaseCard: {
        title: "Domains",
        overview: "Total",
        totalAllowedSessions: "Allowed Sessions ",
        totalBlockedSessions: "Blocked Sessions ",
        allowed: "Allowed",
        blocked: "Blocked"
      },
      topUrlCard: {
        title: "URL Categories"
      },
      malwareCard: {
        title: "Advanced Threat Protection",
        hits: "{{formattedCount}} Hits",
        hits_one: "{{formattedCount}} Hit",
        hits_other: "{{formattedCount}} Hits"
      },
      ipsIncidentCard: {
        title: "IPS Incidents",
        sessions: "{{sessions}} Hits",
        sessions_one: "{{sessions}} Hit",
        sessions_other: "{{sessions}} Hits"
      },
      affectedUsers: {
        title: "Affected Users"
      },
      sdwanStatusCard: {
        title: "SD WAN Status",
        overviewTitle: "Total",
        healthy: "Healthy",
        degraded: "Degraded",
        down: "Down",
        tableTitle: "SD WAN Status Appliance Top 10",
        name: "Name",
        location: "Location",
        type: "Type"
      },
      totalChart: {
        total: "Total"
      },
      sdwanBandwidthCard: {
        tableTitle: "SD-WAN Bandwidth Top 5",
        title: "SD-WAN Bandwidth Summary"
      },
      sdwanBandwidthChartCard: {
        title: "SD WAN Bandwidth",
        topTunnels: "Top {{length}} Tunnels"
      },
      osStatusCard: {
        title: "Operating Systems",
        others: "Others",
        name: "Name",
        location: "Location",
        type: "Type",
        ip: "IP",
        linux: "Linux",
        mac: "Mac OSX",
        windows: "Windows",
        other: "Other",
        android: "Android",
        ios: "iOS"
      },
      ZTNAResources: {
        title: "Resources",
        others: "Others",
        total: "Total Available",
        custom: "Custom",
        inUse: "In Use",
        tableName: "Access Policies",
        table: {
          name: "Name",
          description: "Description",
          users: "Users",
          groups: "Groups",
          resources: "Resources",
          devicePosture: "Device Posture"
        }
      },
      ZTNAAgentInUse: {
        title: "Agent OS in Use",
        total: "Total",
        windows: "Win",
        macos: "macOs",
        android: "Android",
        ios: "iOS",
        linux: "Linux",
        tableName: "Top OS in Use",
        devices: "{{value}} Device",
        devices_plural: "{{value}} Devices"
      },
      ZTNADeviceLocation: {
        title: "Device Map",
        devices: "Devices",
        noData: "No device found.",
        user: "User",
        brand: "Device Brand",
        model: "Device Model",
        hardwareModel: "Hardware Model",
        operatingSystem: "Operating System",
        operatingSystemVersion: "OS Version",
        agentVersion: "Agent Version",
        enrollmentDate: "Enrollment Date",
        country: "Country",
        noCountry: "No Location"
      },
      ZTNAUserGeoLocation: {
        title: "User Geo Location",
        nonRoutableOrIP: "Non-routable or Private IP Addresses",
        devices: "{{value}} Device",
        devices_plural: "{{value}} Devices"
      },
      applianceMapCard: {
        title: "Appliance Map"
      },
      geoCard: {
        sourceTopCountries: {
          title: "Top Geo Source Countries"
        },
        destinationTopCountries: {
          title: "Top Geo Destination Countries"
        }
      },
      baseBarCard: {
        total: "out of {{total}}"
      },
      topApplicationCard: {
        title: "Top Applications",
        topApplications: "Top {{length}} Applications"
      },
      sdwanSummaryCard: {
        title: "SDWAN Summary",
        topAppliances: "Top {{length}} Appliances",
        inbound: "inbound",
        outbound: "outbound"
      },
      topAllowedCategoriesSessions: {
        title: "Top Allowed App Categories <0>(per Session)</0>",
        menuEntry: "Top Allowed App Categories (per Session)",
        footerKey: "Top {{length}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topAllowedCategoriesTraffic: {
        title: "Top Allowed App Categories <0>(per Traffic)</0>",
        menuEntry: "Top Allowed App Categories (per Traffic)",
        footerKey: "Top {{length}}"
      },
      blockedApplicationCategories: {
        title: "Top Blocked App Categories",
        footerKey: "Top {{length}}"
      },
      allowedApplicationsSessions: {
        title: "Top Allowed Applications <0>(per Session)</0>",
        menuEntry: "Top Allowed Applications (per Session)",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions",
        footerKey: "Top {{length}}"
      },
      allowedApplicationsTraffic: {
        title: "Top Allowed Applications <0>(per Traffic)</0>",
        menuEntry: "Top Allowed Applications (per Traffic)",
        footerKey: "Top {{length}}"
      },
      blockedApplications: {
        title: "Top Blocked Applications",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions",
        footerKey: "Top {{length}}"
      },
      applicationRisk: {
        mediumHigh: "Medium -\nHigh",
        medium: "Medium",
        lowMedium: "Low - Medium",
        low: "Low",
        high: "High",
        title: "Application Risk",
        risk: "Risk"
      },
      highRiskApplicationsCard: {
        title: "High Risk Applications",
        top: "Top {{length}}"
      },
      topWebRequest: {
        title: "Web Requests",
        tableTitle: "Top Web Requests",
        total: "Total",
        allowed: "allowed",
        blocked: "blocked",
        type: "Type",
        location: "Location",
        name: "Name"
      },
      storageCard: {
        title: "Storage Overview",
        use: "<0>{{spaceUsed}}GB</0> used out of {{totalSpace}}GB",
        retention: "Max. Retention appr. 3 days",
        name: "Name",
        lastChange: "Last Change",
        fileSize: "File Size",
        storageLow: "Storage space running out!",
        upgradeStorageNow: "Upgrade Storage now!",
        tableTitle: "Storage usage Top Appliances"
      },
      topBlockedUserCard: {
        title: "Top Blocked Users",
        topUsers: "Top {{length}} Users",
        total: "out of {{total}}",
        hits: "{{formattedCount}} Hits",
        hits_one: "{{formattedCount}} Hit",
        hits_other: "{{formattedCount}} Hits"
      },
      topBlockedUrlCategoriesCard: {
        title: "Top Blocked URL Categories",
        topUrlCategories: "Top {{length}} Blocked URL Categories",
        total: "out of {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      applicationCategories: {
        title: "App Categories"
      },
      topApplications: {
        title: "Applications"
      },
      topAllowedUrlCategoriesCard: {
        titlePerSession: "Top Allowed URL Categories <0>(per Session)</0>",
        titlePerTraffic: "Top Allowed URL Categories <0>(per Traffic)</0>",
        menuEntryPerSession: "Top Allowed URL Categories (per Session)",
        menuEntryPerTraffic: "Top Allowed URL Categories (per Traffic)",
        topAllowedUrlCategories: "Top {{length}} Allowed URL Categories",
        total: "out of {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topAllowedDomainsCard: {
        titlePerSession: "Top Allowed Domains <0>(per Session)</0>",
        titlePerTraffic: "Top Allowed Domains <0>(per Traffic)</0>",
        menuEntryPerSession: "Top Allowed Domains (per Session)",
        menuEntryPerTraffic: "Top Allowed Domains (per Traffic)",
        topAllowedDomains: "Top {{length}} Allowed Domains",
        total: "out of {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topBlockedDomainsCard: {
        title: "Top Blocked Domains",
        topBlockedDomains: "Top {{length}} Blocked Domains",
        total: "out of {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topUsersCard: {
        title: "Top Users",
        topUsers: "Top {{length}} Users",
        total: "out of {{total}}"
      },
      userStatusCard: {
        title: "Users",
        total: "Total",
        enrolled: "Enrolled",
        pending: "Pending",
        tableTitle: "Top Users (by Device Count)",
        name: "Name",
        email: "Email",
        deviceCount: "Device Count",
        enrollmentCompleted: {
          label: "Enrollment completed",
          yes: "Enrollment completed",
          no: "Enrollment pending"
        }
      },
      userSummaryCard: {
        title: "Summary",
        fullName: "Full Name",
        email: "Email",
        deviceCount: "Device Count",
        enrollmentCompleted: "Enrollment Completed"
      },
      userDevicesCard: {
        title: "Devices",
        table: {
          userFullName: "Full Name",
          userEmail: "Email",
          brand: "Device Brand",
          hardwareModel: "Device Model",
          operatingSystem: "Operating System",
          operatingSystemVersion: "OS Version",
          enrollmentDate: "Enrollment Date"
        }
      },
      accessDevicesAgentVersionCard: {
        title: "Agent Version in Use",
        total: "Total",
        version: "Version {{version}}",
        older: "Older",
        tableTitle: "Top Versions in Use",
        devices: "{{value}} Device",
        devices_plural: "{{value}} Devices"
      },
      sdwanHeatMap: {
        title: "SD WAN Heat Map"
      },
      legendFooter: {
        online: "online",
        offline: "offline",
        degraded: "degraded"
      },
      applianceStatus: {
        title: "Appliance Status",
        topUsers: "Top {{length}} Users",
        total: "out of {{total}}"
      },
      previewDialog: {
        addWidgetButton: "Add Widget",
        addWidgetCard: "Click to add widget!",
        title: "Add Widget",
        card: "Widget",
        size: "Size",
        cancel: "Cancel",
        add: "Add",
        ok: "OK",
        small: "Small",
        medium: "Medium",
        large: "Large",
        editTitle: "Edit Widget"
      },
      reportTable: {
        title: "All Reports",
        type: "Type",
        name: "Name",
        period: "Time Period",
        devices: "Devices",
        schedule: "Schedule",
        owner: "Owner"
      },
      reportDialog: {
        create: {
          title: "Create Report",
          button: "Create"
        },
        edit: {
          title: "Edit Report",
          button: "Edit"
        },
        reportData: {
          label: "Report Data",
          application: {
            name: "Application"
          },
          protocol: {
            name: "Protocol"
          },
          applicationRule: {
            name: "Application Rule"
          },
          contentType: {
            name: "Content Type"
          },
          outputInterface: {
            name: "Output Interface"
          },
          destination: {
            name: "Destination"
          }
        },
        datasource: {
          label: "Datasource",
          activity: {
            name: "Activity"
          },
          threat: {
            name: "Threat"
          },
          webFilter: {
            name: "Webfilter"
          },
          sdwanTunnels: {
            name: "SD WAN Tunnels"
          },
          sdwanTunnelsAppProtocol: {
            name: "SD WAN Tunnels Applications and Protocols"
          }
        },
        output: {
          label: "Output",
          outputFormat: {
            label: "Output Format"
          },
          deliveryOptions: {
            label: "Delivery Options",
            email: "Email",
            fileDownload: "File Download"
          },
          recipients: {
            label: "Recipients"
          },
          sizeLimit: {
            label: "Size Limit"
          },
          frequency: {
            label: "Frequency",
            once: "Once",
            weekly: "Every Week",
            monthly: "Every Month"
          },
          status: {
            label: "Status",
            enabled: "Enabled",
            disabled: "Disabled"
          }
        },
        advanced: {
          label: "Advanced",
          destination: {
            label: "Destination",
            domain: "Domain",
            category: "Category"
          },
          ipAnonymize: {
            label: "IP Anonymize"
          },
          serialNumberBucketing: {
            label: "Serial Number Bucketing"
          },
          domains: {
            label: "Domains"
          },
          exludeTimeframe: {
            label: "Exclude Timeframe"
          },
          action: {
            label: "Action"
          },
          chartType: {
            label: "Chart Type",
            horizontalBar: "Horizontal Bar",
            verticalBar: "Vertical Bar",
            pieChart: "Pie Chart"
          },
          ddLimitLevels: {
            label: "Drill-Down Limit Levels"
          }
        },
        result: {
          label: "Result Step"
        },
        success: "Successfully created"
      },
      userEnrollment: {
        title: "User Enrollment",
        enrolledUsers: "Users",
        enrolledDevices: "Devices",
        pendingEnrollment: "Pending",
        name: "Name",
        location: "Location",
        device: "Device"
      },
      licensing: {
        title: "Licenses",
        bandwidth: "Bandwidth",
        bandwidthUsageMedium: "{{remain}} Gbit/s left (out of {{total}} Gbit/s)",
        bandwidthUsageLarge: "{{used}} used (out of {{total}} GB/s)",
        bandwidthExpire: "Expires in {{daysLeft}} days",
        users: "Users",
        usersUsageMedium: "{{remain}} Users left (out of {{total}} Users)",
        usersUsageLarge: "{{used}} Users used( out of {{total}} Users)",
        usersExpire: "{{remain}} Users left",
        storage: "Storage",
        storageUsageMedium: "{{remain}} GB left (out of {{total}} GB)",
        storageUsageLarge: "{{used}} GB/s used (out of {{total}} GB/s)",
        storageRetention: "Max. Retention: appr {{remain}} days",
        storageExpire: "Expires in {{daysLeft}} days",
        appliances: "Appliances",
        appliancesUsageMedium: "{{remain}} days left (out of {{total}} days)",
        appliancesUsageLarge: "{{used}} days used (out of {{total}} days)",
        appliancesExpire: "Expires in {{daysLeft}} days",
        percentage: "{{percentage}}"
      },
      dashboardPage: {
        done: "Done",
        cancel: "Cancel",
        editDashboard: "Edit Dashboard",
        emptyMessage: "This Dashboard does not hold any widgets for now.",
        addMessage: "Add widgets by clicking the Add button.",
        lastDay: "Last 24 hours",
        lastWeek: "Last week",
        lastMonth: "Last month",
        configureDashboard: "Configure this Dashboard!",
        addDashboard: "Create Dashboard",
        name: "Name",
        template: "Template",
        reset: "Reset Dashboard",
        resetToTemplate: "Reset to template",
        resetToTemplateDescription: "Reset your dashboard back to the original widgets and layout of the chosen template",
        resetToDefault: "Do you want to reset the dashboard to its default state?",
        rename: "Rename Dashboard",
        delete: "Delete Dashboard",
        options: "Options",
        confirmDeletion: "Do you really want to delete the '{{name}}' dashboard?",
        actions: "Options",
        add: "Add Dashboard",
        validateName: "can only contain alpha-numeric characters or spaces.",
        changeDashboardMessage: "Are you sure you want to change dashboards without saving your changes?",
        uniqueNameError: "must be unique",
        maxNumberOfDashboardReached: "Cannot create more dashboards, maximum {{maxDashboardsAmount}} reached"
      },
      noData: {
        message: "No data for this widget!"
      },
      barGrid: {
        noData: "No matching entries found!"
      },
      categories: {
        ztna: "Zero Trust Access",
        security: "Security",
        webFilter: "Web Filter"
      },
      filterTooltip: "Click to create filter."
    }
  }
};
export {
  en_default as default
};
