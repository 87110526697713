import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { CustomField, TablePage, TextField, useMixpanel, DeleteDialog } from "@cuda-react/core";
import { useTranslation } from "react-i18next";
import apiResources from "../../../apiResources";
import useDemoApi from "../../../hooks/useDemoApi";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import { CreateEditGroup } from "./components/CreateEditGroup";
import { capitalize } from "lodash";
import { DeleteContent } from "../components/DeleteContent";
const Groups = () => {
  const [translate] = useTranslation();
  const groupsApi = useDemoApi(apiResources.identityGroups, apiResources.demoGroups);
  const registerAction = useMixpanel("Groups");
  const tableRefreshRef = useRef(null);
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      authenticated: true,
      pageMode: "paginate",
      title: "tesseract.identity.pageTitle",
      subtitle: "tesseract.identity.groups.title",
      resource: groupsApi,
      noDataMessage: "tesseract.identity.groups.noDataMessage",
      tableName: "tesseract.identity.groups.tableName",
      sortable: true,
      refreshRef: tableRefreshRef,
      actions: [
        /* @__PURE__ */ jsx(
          CreateEditButtonDialog,
          {
            create: true,
            component: CreateEditGroup,
            type: "trustedRoot",
            label: "tesseract.identity.groups.create",
            onSuccess: () => {
              var _a;
              registerAction("Create");
              (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
            }
          },
          "create"
        )
      ],
      children: [
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "displayName",
            label: "tesseract.identity.groups.name",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.identity.groups.description",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "type",
            label: "tesseract.identity.groups.type",
            render: (type) => capitalize(type),
            filter: "select",
            filterProps: {
              choices: [{ key: "local", name: "Local" }, { key: "external", name: "External" }]
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            width: 142,
            source: "id",
            alwaysVisible: true,
            render: (id, group) => {
              if (group.type === "local") {
                return /* @__PURE__ */ jsxs(Fragment, { children: [
                  /* @__PURE__ */ jsx(
                    CreateEditButtonDialog,
                    {
                      component: ({ onClose, onSuccess }) => /* @__PURE__ */ jsx(CreateEditGroup, { id, onClose, onSuccess }),
                      label: "tesseract.identity.groups.edit",
                      onSuccess: () => {
                        var _a;
                        registerAction("edit");
                        (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                      }
                    },
                    "edit"
                  ),
                  /* @__PURE__ */ jsx(
                    DeleteDialog,
                    {
                      data: group,
                      resource: apiResources.identityGroups,
                      title: "tesseract.identity.groups.delete.title",
                      message: /* @__PURE__ */ jsx(DeleteContent, { children: /* @__PURE__ */ jsx("p", { children: translate("tesseract.identity.groups.delete.body") }) }),
                      onSuccess: () => {
                        var _a;
                        registerAction("deleted");
                        (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                      }
                    }
                  )
                ] });
              }
            }
          }
        )
      ]
    }
  );
};
export {
  Groups
};
