import { jsx, jsxs } from "react/jsx-runtime";
import ApplicationControl from "./tabs/ApplicationControl/ApplicationControl";
import IngressRule from "./tabs/Ingress/IngressRule";
import { Route, Switch } from "react-router";
const NetworkAccess = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(Route, { path: "/policies/network/gateway", children: /* @__PURE__ */ jsx(ApplicationControl, { type: "gateway" }, "gateway") }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/network/site", children: /* @__PURE__ */ jsx(ApplicationControl, { type: "site" }, "site") }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/network/ingress", children: /* @__PURE__ */ jsx(IngressRule, {}) })
] });
var NetworkAccess_default = NetworkAccess;
export {
  NetworkAccess_default as default
};
