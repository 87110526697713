import { jsx, jsxs } from "react/jsx-runtime";
import { Card, IconButton } from "@barracuda-internal/bds-core";
import ListCardContent from "../ListCardContent/ListCardContent";
import { createStyles } from "@mui/styles";
import { Close } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { makeOverrideableStyles } from "@cuda-react/theme";
const styles = (theme) => createStyles({
  root: {
    border: theme.palette.divider + " solid 1px"
  },
  listCardTable: {
    marginTop: theme.spacing(2)
  },
  listCardTableCell: {
    paddingBottom: 4,
    paddingTop: 4,
    width: "auto",
    maxWidth: 160,
    lineBreak: "anywhere"
  },
  listCardTableRow: {
    height: "auto"
  },
  listCardTableLabelCell: {
    paddingLeft: theme.spacing(1)
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 0
  }
});
const useStyles = makeOverrideableStyles("LocationsCardContentTooltip", styles);
const LocationsCardContentTooltip = (props) => {
  const { children, data, onClose } = props;
  const classes = useStyles(props);
  return children ? /* @__PURE__ */ jsxs(Card, { className: classes.root, elevation: 3, children: [
    /* @__PURE__ */ jsx(
      IconButton,
      {
        size: "small",
        "aria-label": "close",
        disableRipple: true,
        disableTouchRipple: true,
        className: classes.closeIcon,
        onClick: () => onClose == null ? void 0 : onClose(),
        children: /* @__PURE__ */ jsx(Close, { id: "cuda-icon-close" })
      }
    ),
    /* @__PURE__ */ jsx(
      ListCardContent,
      {
        classes: {
          table: classes.listCardTable,
          tableCells: classes.listCardTableCell,
          tableRow: classes.listCardTableRow,
          tableLabelCell: classes.listCardTableLabelCell
        },
        data,
        children
      }
    )
  ] }) : null;
};
var LocationsCardContentTooltip_default = LocationsCardContentTooltip;
export {
  LocationsCardContentTooltip,
  LocationsCardContentTooltip_default as default,
  styles
};
