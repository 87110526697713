import { jsx } from "react/jsx-runtime";
import { ButtonDialog } from "@cuda-react/core";
import UpdateDialogContent from "./UpdateDialogContent";
const CreateUpdateDialog = ({ disabled, appliances, onSuccess, availableUpdates, disabledMessage }) => /* @__PURE__ */ jsx(
  ButtonDialog,
  {
    buttonText: "tesseract.settings.tabs.updates.firmware.create.buttonText",
    disabled,
    disabledMessage,
    children: /* @__PURE__ */ jsx(
      UpdateDialogContent,
      {
        appliances,
        title: "tesseract.settings.tabs.updates.firmware.create.dialogTitle",
        onSuccess,
        availableUpdates
      }
    )
  }
);
var CreateUpdateDialog_default = CreateUpdateDialog;
export {
  CreateUpdateDialog_default as default
};
