var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { createElement } from "react";
import { createStyles } from "@mui/styles";
import {
  createFilterOptions,
  InputAdornment
} from "@mui/material";
import {
  FormHelperText,
  IconButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  TextField,
  Typography
} from "@barracuda-internal/bds-core";
import { Close, Search, SortDescending } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { useAutocomplete } from "@mui/lab";
import classNames from "classnames";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeepCompareEffect } from "../../../hooks";
import { formatErrorMessage } from "../../../utils";
import { makeOverrideableStyles } from "@cuda-react/theme";
const styles = (theme) => createStyles({
  autocomplete: {
    width: "100%"
  },
  autocompleteInput: {
    flexGrow: 1,
    "& input": {
      overflow: "hidden !important",
      textOverflow: "ellipsis",
      paddingRight: (props) => props.clearOnFocus ? "36px" : "64px"
    }
  },
  chip: {
    margin: "0.1rem"
  },
  chipContainer: {
    maxWidth: 256
  },
  endAdornment: {
    position: "absolute",
    right: 8,
    top: "calc(50% - 14px)"
  },
  clearIndicator: {
    marginRight: -2,
    padding: 4,
    color: theme.palette.action.active
  },
  clearIndicatorHidden: {
    visibility: "hidden"
  },
  popupIndicator: {
    padding: 2,
    marginRight: -2,
    color: theme.palette.action.active
  },
  popupIndicatorOpen: {
    transform: "rotate(180deg)"
  },
  popper: {
    zIndex: theme.zIndex.modal,
    "& ul": {
      maxHeight: "80vh",
      overflowY: "auto"
    },
    width: (props) => props.dropdownAutoWidth ? "auto !important" : void 0,
    minWidth: (props) => props.dropdownAutoWidth ? 256 : void 0,
    maxWidth: (props) => props.dropdownAutoWidth ? "80vw" : void 0
  },
  paper: __spreadProps(__spreadValues({}, theme.typography.body1), {
    overflow: "hidden",
    margin: 0,
    "& > ul": {
      maxHeight: "40vh",
      overflow: "auto"
    }
  }),
  listbox: {
    listStyle: "none",
    margin: 0,
    padding: "8px 0px",
    position: "relative"
  },
  showAll: {
    padding: "2px 8px 0",
    marginBottom: "-4px",
    boxShadow: "none",
    transition: "none"
  },
  showAllLink: {
    color: theme.palette.primary.main,
    cursor: "pointer"
  },
  option: {
    minHeight: 48,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    paddingTop: 6,
    boxSizing: "border-box",
    outline: "0",
    WebkitTapHighlightColor: "transparent",
    paddingBottom: 6,
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.up("sm")]: {
      minHeight: "auto"
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.action.selected
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover
    },
    "&:active": {
      backgroundColor: theme.palette.action.selected
    },
    '&[aria-disabled="true"]': {
      opacity: theme.palette.action.disabledOpacity,
      pointerEvents: "none"
    }
  },
  listItemIcon: {
    display: "inline-flex",
    minWidth: "unset",
    marginLeft: -theme.spacing(1),
    marginRight: -theme.spacing(3),
    verticalAlign: "middle"
  },
  inset: {
    paddingLeft: theme.spacing(4)
  },
  listItemText: {
    display: "inline-flex",
    margin: 0,
    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  error: {
    marginBottom: theme.spacing(-1),
    marginLeft: theme.spacing(1)
  }
});
const useStyles = makeOverrideableStyles("AutocompleteSearch", styles);
const AutocompleteSearch = (props) => {
  const _a = props, {
    choices,
    clearOnFocus,
    disabled,
    error,
    icon,
    iconMap = {},
    id,
    label,
    maxOptionsVisible,
    noOptionsText,
    noSearchIcon,
    onChange,
    optionIcon,
    optionSubText,
    optionText,
    placeholder
  } = _a, otherProps = __objRest(_a, [
    "choices",
    "clearOnFocus",
    "disabled",
    "error",
    "icon",
    "iconMap",
    "id",
    "label",
    "maxOptionsVisible",
    "noOptionsText",
    "noSearchIcon",
    "onChange",
    "optionIcon",
    "optionSubText",
    "optionText",
    "placeholder"
  ]);
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const getChoiceName = (choice) => choice && get(choice, optionText) || "";
  const getChoiceSubText = (choice) => optionSubText && choice && get(choice, optionSubText) || "";
  const onChangeEvent = (event, choice) => onChange(choice);
  const [showAll, setShowAll] = useState(false);
  const {
    dirty,
    getRootProps,
    getInputProps,
    getClearProps,
    getPopupIndicatorProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    popupOpen,
    anchorEl,
    setAnchorEl,
    inputValue
  } = useAutocomplete(__spreadValues({
    autoHighlight: true,
    // @ts-ignore It claims "filterProps" doesn't exist, which it does...
    filterProps: createFilterOptions({
      stringify: getChoiceName
    }),
    onChange: onChangeEvent,
    noOptionsText: translate(noOptionsText),
    options: choices || [],
    disabled,
    getOptionLabel: getChoiceName
  }, otherProps));
  useDeepCompareEffect(() => {
    setShowAll(false);
  }, [popupOpen, groupedOptions]);
  return /* @__PURE__ */ jsxs("div", __spreadProps(__spreadValues({ className: classes.autocomplete }, getRootProps()), { children: [
    /* @__PURE__ */ jsx(
      TextField,
      {
        inputProps: __spreadProps(__spreadValues({}, getInputProps()), { id }),
        label: label && translate(label),
        placeholder: placeholder && translate(placeholder) || void 0,
        className: classes.autocompleteInput,
        variant: "outlined",
        fullWidth: true,
        error: !!error,
        disabled,
        onClick: (event) => {
          var _a2, _b;
          if (clearOnFocus) {
            (_b = (_a2 = getClearProps()).onClick) == null ? void 0 : _b.call(_a2, event);
          }
        },
        InputProps: {
          ref: setAnchorEl,
          startAdornment: !noSearchIcon && /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: /* @__PURE__ */ jsx(Search, {}) }) || void 0,
          endAdornment: /* @__PURE__ */ jsxs("div", { className: classes.endAdornment, children: [
            !clearOnFocus && /* @__PURE__ */ jsx(
              IconButton,
              __spreadProps(__spreadValues({
                size: "small"
              }, getClearProps()), {
                className: classNames(classes.clearIndicator, !(dirty && inputValue) && classes.clearIndicatorHidden),
                disabled,
                children: /* @__PURE__ */ jsx(Close, { fontSize: "small" })
              })
            ),
            /* @__PURE__ */ jsx(
              IconButton,
              __spreadProps(__spreadValues({
                size: "small"
              }, getPopupIndicatorProps()), {
                className: classNames(classes.popupIndicator, popupOpen && classes.popupIndicatorOpen),
                disabled,
                children: /* @__PURE__ */ jsx(SortDescending, {})
              })
            )
          ] })
        },
        autoComplete: "off"
      }
    ),
    popupOpen && anchorEl ? /* @__PURE__ */ jsx(
      Popper,
      {
        className: classes.popper,
        style: {
          width: anchorEl ? anchorEl.clientWidth : void 0
        },
        role: "presentation",
        anchorEl,
        open: true,
        children: /* @__PURE__ */ jsx(Paper, { className: classes.paper, children: groupedOptions.length > 0 ? /* @__PURE__ */ jsxs(
          "ul",
          __spreadProps(__spreadValues({
            className: classes.listbox
          }, getListboxProps()), {
            children: [
              groupedOptions.slice(0, showAll ? void 0 : maxOptionsVisible).map((choice, index) => {
                const choiceName = getChoiceName(choice);
                const subListText = optionSubText && getChoiceSubText(choice);
                const listIcon = iconMap && optionIcon && iconMap[get(choice, optionIcon)] || icon;
                return /* @__PURE__ */ createElement(
                  "li",
                  __spreadProps(__spreadValues({}, getOptionProps({ choice, index })), {
                    className: classes.option,
                    key: index
                  }),
                  listIcon && /* @__PURE__ */ jsx(ListItemIcon, { className: classes.listItemIcon, children: listIcon }),
                  typeof choiceName === "string" ? /* @__PURE__ */ jsx(
                    ListItemText,
                    {
                      primary: translate(choiceName),
                      secondary: optionSubText && translate(subListText),
                      className: classes.listItemText,
                      classes: { inset: classes.inset },
                      inset: !!listIcon
                    }
                  ) : choiceName
                );
              }),
              groupedOptions.length > maxOptionsVisible && !showAll && /* @__PURE__ */ jsx(Paper, { className: classes.showAll, children: /* @__PURE__ */ jsxs(Typography, { variant: "caption", children: [
                translate("cuda.table.autocomplete.showing", {
                  current: maxOptionsVisible,
                  total: groupedOptions.length
                }),
                /* @__PURE__ */ jsx(
                  "a",
                  {
                    onClick: (event) => {
                      var _a2;
                      setShowAll(true);
                      (_a2 = event == null ? void 0 : event.preventDefault) == null ? void 0 : _a2.call(event);
                    },
                    className: classes.showAllLink,
                    children: translate("cuda.table.autocomplete.showAll")
                  }
                )
              ] }) }) || null
            ]
          })
        ) : null })
      }
    ) : null,
    error ? /* @__PURE__ */ jsx(FormHelperText, { error: true, className: classes.error, children: formatErrorMessage(error) }) : null
  ] }));
};
AutocompleteSearch.defaultProps = {
  choices: [],
  iconMap: {},
  noOptionsText: "cuda.select.noOptions",
  onChange: () => {
  },
  optionText: "name",
  maxOptionsVisible: 10,
  noSearchIcon: false,
  placeholder: "cuda.table.autocomplete.search"
};
var AutocompleteSearch_default = AutocompleteSearch;
export {
  AutocompleteSearch,
  AutocompleteSearch_default as default,
  styles
};
