import { jsx } from "react/jsx-runtime";
import {
  Card,
  generateQueryString,
  getArrayDataContent,
  ResourceStatisticsCardContent,
  useCrudProps
} from "@cuda-react/core";
import { useStatisticsCardDetailsAndTotal } from "../../../../hooks/useStatisticsCardDetailsAndTotal";
import apiResources from "../../../../apiResources";
const SiteStatusCard = ({ type, details }) => {
  var _a;
  const siteStatusChoices = (_a = useCrudProps(type === "gateways" ? apiResources.siteStates : void 0)[0]) == null ? void 0 : _a.data;
  const [siteStatusDetails, siteStatusTotal] = useStatisticsCardDetailsAndTotal(
    getArrayDataContent(siteStatusChoices),
    (status) => `/infrastructure/sites${generateQueryString({
      upstream: [+details.id],
      connectionStatus: [status.key]
    })}`
  );
  return type === "gateways" ? /* @__PURE__ */ jsx(
    Card,
    {
      id: "ConnectedSitesCard",
      title: "tesseract.appliances.dashboard.siteStatus.tabTitle",
      children: /* @__PURE__ */ jsx(
        ResourceStatisticsCardContent,
        {
          overviewValue: siteStatusTotal,
          details: siteStatusDetails,
          resource: apiResources.siteStatus,
          params: { filter: { upstream: details.id, modelSeries: "T" } }
        }
      )
    }
  ) : null;
};
var SiteStatusCard_default = SiteStatusCard;
export {
  SiteStatusCard_default as default
};
