var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import {
  Card,
  CrudTypes,
  CustomField,
  getArrayDataContent,
  LinkField,
  LocationsCardContent,
  SelectedChoiceField,
  TextField,
  useCrudProps,
  useCrudSubscription,
  useGlobalParam
} from "@cuda-react/core";
import { getAzureHubLink, getAzureResourceLink } from "../../../../utils/azureUtils";
import apiResources from "../../../../apiResources";
import { useTheme } from "@mui/material";
const TunnelLocationsCard = ({ details, type }) => {
  var _a, _b;
  const isStandaloneSite = type === "sites" && !details.gateway;
  const isDemo = useGlobalParam("userData.mode")[0] === "demo";
  const isIoT = (details == null ? void 0 : details.modelSeries) === "S";
  const theme = useTheme();
  const regionChoices = (_a = useCrudProps(apiResources.gatewayRegions)[0]) == null ? void 0 : _a.data;
  const scaleUnitChoices = (_b = useCrudProps(apiResources.gatewayScaleUnits)[0]) == null ? void 0 : _b.data;
  const gatewayLocationsData = getArrayDataContent(useCrudSubscription(
    CrudTypes.GET,
    type !== "sites" ? apiResources.gatewayTunnelLocation : void 0,
    { id: details.id }
  )[0].data).map((gateway) => __spreadProps(__spreadValues({}, gateway), {
    tseries: gateway.sites.filter((site) => (site == null ? void 0 : site.modelSeries) === "T"),
    iot: gateway.sites.filter((site) => (site == null ? void 0 : site.modelSeries) === "S")
  }));
  const siteCardContent = details.name && (isStandaloneSite ? /* @__PURE__ */ jsx(
    LocationsCardContent,
    {
      params: { id: details.id },
      resource: apiResources.standaloneSiteLocation,
      series: [{
        label: "tesseract.appliances.dashboard.tunnelLocations.standaloneSites.label",
        color: theme.palette.general.graphite
      }]
    }
  ) : /* @__PURE__ */ jsx(
    LocationsCardContent,
    {
      params: { id: details.id },
      resource: apiResources.siteTunnelLocations,
      series: [
        {
          label: "tesseract.appliances.dashboard.tunnelLocations.gateways.label",
          color: theme.palette.general.purple
        },
        {
          label: isIoT ? "tesseract.appliances.dashboard.tunnelLocations.iot.label" : "tesseract.appliances.dashboard.tunnelLocations.sites.label",
          color: isIoT ? theme.palette.general.turquoise : theme.palette.general.blue,
          source: "sites"
        }
      ]
    }
  ));
  const cardContent = type === "sites" ? siteCardContent : /* @__PURE__ */ jsx(
    LocationsCardContent,
    {
      data: gatewayLocationsData,
      series: [
        {
          label: "tesseract.appliances.dashboard.tunnelLocations.gateways.label",
          color: theme.palette.general.purple,
          zIndex: 4,
          linkedSeries: {
            color: theme.palette.general.darkBlue,
            zIndex: 3,
            selectedZIndex: 5
          },
          details: {
            resource: apiResources.gateways,
            optionValue: "id",
            fields: [
              /* @__PURE__ */ jsx(
                LinkField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.gateways.fields.name",
                  source: "name",
                  link: getAzureResourceLink("applicationId", isDemo)
                },
                "name"
              ),
              /* @__PURE__ */ jsx(
                CustomField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.gateways.fields.sites",
                  source: "tseries",
                  render: (sites, data) => /* @__PURE__ */ jsx(
                    LinkField,
                    {
                      defaultText: (sites == null ? void 0 : sites.length) || sites,
                      link: `/infrastructure/sites?filter={"gateway":[${data.id}]}`
                    }
                  )
                },
                "tseries"
              ),
              /* @__PURE__ */ jsx(
                CustomField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.gateways.fields.iot",
                  source: "iot",
                  render: (sites, data) => /* @__PURE__ */ jsx(
                    LinkField,
                    {
                      defaultText: (sites == null ? void 0 : sites.length) || sites,
                      link: `/infrastructure/iot?filter={"gateway":[${data.id}]}`
                    }
                  )
                },
                "iot"
              ),
              /* @__PURE__ */ jsx(
                LinkField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.gateways.fields.vwan",
                  source: "virtualWan.name",
                  link: getAzureResourceLink("virtualWan.resourceId", isDemo)
                },
                "vwanName"
              ),
              /* @__PURE__ */ jsx(
                LinkField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.gateways.fields.hub",
                  source: "hubName",
                  link: getAzureHubLink("hubId", isDemo)
                },
                "hubName"
              ),
              /* @__PURE__ */ jsx(
                SelectedChoiceField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.gateways.fields.region",
                  source: "region",
                  choices: getArrayDataContent(regionChoices)
                },
                "region"
              ),
              /* @__PURE__ */ jsx(
                SelectedChoiceField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.gateways.fields.size",
                  source: "scaleUnit",
                  choices: getArrayDataContent(scaleUnitChoices)
                },
                "scaleUnit"
              )
            ]
          }
        },
        {
          label: "tesseract.sites.pageTitle",
          color: theme.palette.general.blue,
          source: "tseries",
          zIndex: 1,
          linkedSeries: {
            color: theme.palette.general.magenta,
            zIndex: 6,
            selectedZIndex: 2
          },
          details: {
            resource: apiResources.sites,
            optionValue: "id",
            fields: [
              /* @__PURE__ */ jsx(
                LinkField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.sites.fields.name",
                  source: "name",
                  link: (data) => `/infrastructure/sites/${data.id}`
                },
                "name"
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.sites.fields.location",
                  source: "summary"
                },
                "summary"
              ),
              /* @__PURE__ */ jsx(
                LinkField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.sites.fields.gateway",
                  source: "gatewayName",
                  link: (data) => `/infrastructure/gateways?filter={"name":"${data.gatewayName}"}`
                },
                "gatewayName"
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.sites.fields.providers",
                  source: "wanNetworks.length"
                },
                "wans"
              )
            ]
          }
        },
        {
          label: "tesseract.network.iot.menuTitle",
          color: theme.palette.general.turquoise,
          source: "iot",
          zIndex: 1,
          linkedSeries: {
            color: theme.palette.general.magenta,
            zIndex: 6,
            selectedZIndex: 2
          },
          details: {
            resource: apiResources.sites,
            optionValue: "id",
            fields: [
              /* @__PURE__ */ jsx(
                LinkField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.sites.fields.name",
                  source: "name",
                  link: (data) => `/infrastructure/iot/${data.id}`
                },
                "name"
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.sites.fields.location",
                  source: "summary"
                },
                "summary"
              ),
              /* @__PURE__ */ jsx(
                LinkField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.sites.fields.gateway",
                  source: "gatewayName",
                  link: (data) => `/infrastructure/gateways?filter={"name":"${data.gatewayName}"}`
                },
                "gatewayName"
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  label: "tesseract.appliances.dashboard.tunnelLocations.sites.fields.providers",
                  source: "wanNetworks.length"
                },
                "wans"
              )
            ]
          }
        }
      ]
    }
  );
  return /* @__PURE__ */ jsx(
    Card,
    {
      id: "TunnelLocationsCard",
      title: isStandaloneSite ? "tesseract.appliances.dashboard.tunnelLocations.standaloneSites.title" : "tesseract.appliances.dashboard.tunnelLocations.title",
      children: cardContent
    }
  );
};
var TunnelLocationsCard_default = TunnelLocationsCard;
export {
  TunnelLocationsCard_default as default
};
