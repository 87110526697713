var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { IconButton, TextField } from "@barracuda-internal/bds-core";
import { Hide, Show } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { useState } from "react";
import { formatErrorMessage } from "../../../utils";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { InputAdornment } from "@mui/material";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  disabledShowHide: {
    color: theme.palette.text.disabled
  },
  enabledShowHide: {
    color: theme.palette.common.black
  },
  passwordInputVisibility: {
    position: "relative",
    top: 0,
    left: 4,
    verticalAlign: "top",
    padding: 0,
    margin: 0,
    height: "auto",
    width: "auto",
    transition: "none",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  root: {
    width: 256
  }
});
const useStyles = makeOverrideableStyles("Password", styles);
const Password = (props) => {
  const {
    clearOnFocus,
    disabled,
    error,
    id,
    onBlur,
    onChange,
    onFocus,
    options = {},
    value = "",
    isVisible,
    viewable
  } = props;
  const classes = useStyles();
  const [visible, setVisible] = useState(isVisible);
  const [prevValue, setPrevValue] = useState(null);
  return /* @__PURE__ */ jsx(
    TextField,
    __spreadProps(__spreadValues({
      value,
      onBlur: (eventOrValue) => {
        let value2 = eventOrValue;
        if (clearOnFocus && !value2 && prevValue) {
          value2 = prevValue || "";
          setPrevValue(null);
        }
        onBlur == null ? void 0 : onBlur(value2);
      },
      onFocus: (event) => {
        if (clearOnFocus) {
          setPrevValue(value);
          onChange == null ? void 0 : onChange("");
        }
        onFocus == null ? void 0 : onFocus(event);
      },
      onChange: (value2) => {
        onChange == null ? void 0 : onChange(value2);
      },
      type: visible ? "text" : "password",
      helperText: formatErrorMessage(error),
      error: !!error
    }, options), {
      disabled,
      id: id && "text-input-" + id,
      className: classes.root,
      variant: "outlined",
      InputProps: {
        endAdornment: viewable && /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            className: classes.passwordInputVisibility,
            onClick: () => setVisible(!visible),
            disabled,
            children: visible ? /* @__PURE__ */ jsx(
              Hide,
              {
                className: disabled ? classes.disabledShowHide : classes.enabledShowHide
              }
            ) : /* @__PURE__ */ jsx(
              Show,
              {
                className: disabled ? classes.disabledShowHide : classes.enabledShowHide
              }
            )
          }
        ) }) || void 0
      }
    })
  );
};
var Password_default = Password;
export {
  Password,
  Password_default as default
};
