var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { CardSizes } from "../../../typesAndConstants";
import { useTranslation } from "react-i18next";
import { BasicPage, ButtonDialog } from "@cuda-react/core";
import { Button, Divider, Grid as MuiGrid, Typography } from "@barracuda-internal/bds-core";
import { AddWidgetButton } from "../AddWidgetButton";
import Grid from "../Grid";
import { createStyles, makeStyles } from "@mui/styles";
import { ExpandMore } from "@mui/icons-material";
import ResetDashboardDialog from "../ResetDashboardDialog";
const styles = (theme) => createStyles({
  topBorder: {
    borderTop: `${theme.palette.divider} solid 1px`,
    marginTop: -1
  },
  titleBar: {
    margin: 0,
    width: "100%"
  },
  emptyNotice: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(3),
    marginTop: theme.spacing(15)
  },
  contentDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  dashboardRoot: {
    marginTop: theme.spacing(1)
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main
  },
  dashboardTitle: {
    display: "inline-flex",
    alignItems: "center"
  },
  optionsMenuButton: {
    marginLeft: "auto"
  },
  buttonBar: {
    width: "100%",
    margin: theme.spacing(1, 3, 0, 1),
    paddingTop: theme.spacing(1)
  },
  doneButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  cancelButton: {
    marginLeft: theme.spacing(1)
  },
  editButton: {
    marginLeft: "auto"
  },
  editButtonOneDashboard: {
    marginLeft: theme.spacing(1)
  },
  actions: {
    width: "100%"
  },
  action: {
    width: "100%",
    margin: theme.spacing(0, 1, 0, 0)
  }
});
const useStyles = makeStyles(styles);
const DashboardContent = (props) => {
  var _a;
  const {
    editMode,
    activeDashboard,
    filters,
    setEditMode,
    availableCards,
    onChange,
    cardEditBuffer,
    setCardEditBuffer,
    additionalProps,
    templates,
    disabled,
    oneTabDashboard,
    addUpdateDashboard
  } = props;
  const [translate] = useTranslation();
  const classes = useStyles(props);
  const activeCards = Array.isArray(activeDashboard == null ? void 0 : activeDashboard.cards) ? (_a = activeDashboard == null ? void 0 : activeDashboard.cards) == null ? void 0 : _a.filter((card) => card.componentId in availableCards) : [];
  const usedComponentIds = (editMode ? cardEditBuffer : activeCards).map((card) => card.componentId);
  const filteredAvailableCards = Object.keys(availableCards).filter((id) => !usedComponentIds.includes(id)).reduce((cards, id) => __spreadProps(__spreadValues({}, cards), { [id]: availableCards[id] }), {});
  const displayedCards = editMode ? cardEditBuffer : activeCards;
  const { filterButtons, activeFilters } = filters || {};
  const addCard = (selectedCardId, selectedSize) => {
    let cardBuffer = [...cardEditBuffer];
    if (!editMode) {
      setEditMode(true);
      cardBuffer = [...activeCards];
    }
    if (selectedCardId && availableCards[selectedCardId] && selectedSize) {
      const newCard = {
        componentId: selectedCardId,
        size: selectedSize
      };
      cardBuffer.push(newCard);
    }
    setCardEditBuffer(cardBuffer);
  };
  const doneButton = /* @__PURE__ */ jsx(
    Button,
    {
      variant: "contained",
      color: "primary",
      size: "small",
      onClick: () => {
        onChange(cardEditBuffer);
        setEditMode(false);
      },
      className: classes.doneButton,
      children: translate("stratosReporting.dashboardPage.done")
    }
  );
  const cancelButton = /* @__PURE__ */ jsx(
    Button,
    {
      variant: "contained",
      color: "secondary",
      size: "small",
      onClick: () => {
        setEditMode(false);
      },
      className: classes.cancelButton,
      children: translate("stratosReporting.dashboardPage.cancel")
    }
  );
  const addButton = /* @__PURE__ */ jsx(
    AddWidgetButton,
    {
      cards: filteredAvailableCards,
      onAdd: addCard
    },
    activeDashboard == null ? void 0 : activeDashboard.name
  );
  const editButton = /* @__PURE__ */ jsx(
    Button,
    {
      variant: "contained",
      color: "primary",
      size: "small",
      className: oneTabDashboard ? classes.editButtonOneDashboard : classes.editButton,
      onClick: () => {
        setCardEditBuffer(activeCards);
        setEditMode(true);
      },
      children: translate("stratosReporting.dashboardPage.editDashboard")
    }
  );
  const resetMenu = /* @__PURE__ */ jsx(
    ButtonDialog,
    {
      buttonEndIcon: /* @__PURE__ */ jsx(ExpandMore, {}),
      buttonProps: {
        className: classes.optionsMenuButton,
        dashboardMenu: true,
        iconButton: false,
        color: "secondary",
        id: "open-dashboard-menu"
      },
      buttonText: "stratosReporting.dashboardPage.options",
      disabled,
      useMenu: true,
      children: /* @__PURE__ */ jsx(
        ResetDashboardDialog,
        {
          label: "stratosReporting.dashboardPage.reset",
          dashboard: activeDashboard,
          addUpdateDashboard,
          templates,
          oneTabDashboard
        },
        "reset-expand-menu-item"
      )
    },
    "menu"
  );
  const editPageActions = /* @__PURE__ */ jsxs(
    MuiGrid,
    {
      container: true,
      spacing: 1,
      className: classes.buttonBar,
      direction: "row-reverse",
      alignItems: "center",
      wrap: "wrap-reverse",
      children: [
        doneButton,
        cancelButton,
        addButton
      ]
    }
  );
  const pageActions = /* @__PURE__ */ jsxs(
    MuiGrid,
    {
      container: true,
      spacing: 1,
      className: classes.buttonBar,
      direction: "row-reverse",
      alignItems: "center",
      wrap: "wrap-reverse",
      children: [
        editButton,
        oneTabDashboard ? resetMenu : null,
        filterButtons
      ]
    }
  );
  return /* @__PURE__ */ jsxs(
    BasicPage,
    {
      actions: editMode ? editPageActions : pageActions,
      classes: {
        root: !oneTabDashboard ? classes.topBorder : void 0,
        titleBar: classes.titleBar,
        actions: editMode ? void 0 : classes.actions,
        action: editMode ? void 0 : classes.action
      },
      children: [
        /* @__PURE__ */ jsx(Divider, { className: classes.contentDivider }),
        /* @__PURE__ */ jsx(MuiGrid, { direction: "row", container: true, children: activeFilters }),
        /* @__PURE__ */ jsx("div", { className: classes.dashboardRoot, children: displayedCards.length < 1 ? /* @__PURE__ */ jsxs("div", { className: classes.emptyNotice, children: [
          /* @__PURE__ */ jsx(Typography, { variant: "h5", children: translate("stratosReporting.dashboardPage.configureDashboard") }),
          /* @__PURE__ */ jsxs(Typography, { variant: "body1", align: "center", children: [
            translate("stratosReporting.dashboardPage.emptyMessage"),
            /* @__PURE__ */ jsx("br", {}),
            translate("stratosReporting.dashboardPage.addMessage")
          ] }),
          /* @__PURE__ */ jsx(
            AddWidgetButton,
            {
              cards: filteredAvailableCards,
              onAdd: addCard
            }
          )
        ] }) : /* @__PURE__ */ jsx(
          Grid,
          {
            cardComponents: displayedCards.map((card) => {
              var _a2;
              return {
                componentId: card == null ? void 0 : card.componentId,
                component: (_a2 = availableCards[card == null ? void 0 : card.componentId]) == null ? void 0 : _a2.component,
                props: {
                  size: (card == null ? void 0 : card.size) || CardSizes.small,
                  additionalProps
                }
              };
            }),
            onChange: (cards) => {
              const mappedCards = (cards || []).map(
                (card) => ({ componentId: card.componentId, size: card.props.size || CardSizes.small })
              );
              setCardEditBuffer(mappedCards);
            },
            editMode,
            cards: filteredAvailableCards,
            allCards: availableCards
          }
        ) })
      ]
    }
  );
};
export {
  DashboardContent
};
