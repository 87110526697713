import { jsx, jsxs } from "react/jsx-runtime";
import SerialEditInput from "../../inputs/SerialEditInput";
import React from "react";
import { getArrayDataContent, ReadOnlyInput, SelectInput, TextField, TextInput, useCrudProps } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { useSiteInputValidations } from "../../../../../hooks/createEditSiteHooks";
import { SELECT_EMPTY_VALUE } from "../../EditTVTContent";
import apiResources from "../../../../../apiResources";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  textField: {
    lineHeight: "19px"
  }
});
const BasicTab = ({ applianceType, id, noAddingSerials, series }) => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const inputValidations = useSiteInputValidations();
  const model = useWatch({ name: "model" });
  const gatewayResource = applianceType === "gateway" ? apiResources.gatewaysOnPremUpstream : apiResources.gatewayNames;
  const gatewayParams = applianceType === "gateway" ? { filter: { id } } : {};
  const [gatewayData, gatewaysLoading] = useCrudProps(gatewayResource, gatewayParams);
  const gatewayNames = getArrayDataContent(gatewayData);
  const gatewayChoices = gatewaysLoading ? [] : series === "S" ? gatewayNames : [
    {
      key: SELECT_EMPTY_VALUE,
      name: "tesseract.appliances.dialog.basic.noGateway"
    },
    ...gatewayNames
  ];
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "name",
        label: "tesseract.appliances.settings.basic.name",
        description: translate("tesseract.appliances.settings.basic.descriptions.name", { context: applianceType }),
        isRequired: true,
        validate: inputValidations.validateSiteName
      }
    ),
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: applianceType === "gateway" ? "upstream" : "gateway",
        label: applianceType === "gateway" ? "tesseract.appliances.dialog.basic.upstream_gateway" : "tesseract.appliances.dialog.basic.gateway",
        description: translate(applianceType === "gateway" ? "tesseract.appliances.dialog.basic.descriptions.upstream" : "tesseract.appliances.dialog.basic.descriptions.gateway", { context: applianceType }),
        choices: gatewayChoices
      }
    ),
    /* @__PURE__ */ jsx(
      ReadOnlyInput,
      {
        source: "model",
        label: "tesseract.appliances.settings.basic.model",
        description: translate("tesseract.appliances.settings.basic.descriptions.model", { context: applianceType }),
        field: TextField,
        options: { classes: { text: classes.textField } }
      }
    ),
    /* @__PURE__ */ jsx(
      SerialEditInput,
      {
        source: "serials",
        label: "tesseract.appliances.settings.basic.serials",
        description: translate("tesseract.appliances.settings.basic.descriptions.serials", { context: applianceType }),
        id,
        model,
        applianceType,
        validate: inputValidations.validateSerials,
        isRequired: true,
        noAdd: noAddingSerials,
        series
      }
    )
  ] });
};
var BasicTab_default = BasicTab;
export {
  BasicTab,
  BasicTab_default as default
};
