import { jsx } from "react/jsx-runtime";
import { Link } from "@barracuda-internal/bds-core";
import get from "lodash/get";
import { useHistory } from "react-router-dom";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    color: theme.palette.primary.main
  },
  link: {
    cursor: "pointer",
    fontSize: theme.typography.pxToRem(14)
  }
});
const useStyles = makeOverrideableStyles("LinkField", styles);
const LinkField = (props) => {
  const { data, source = "", link, defaultText } = props;
  const classes = useStyles(props);
  const linkTarget = link && (typeof link === "string" ? link : link(data));
  const history = useHistory();
  const isExternal = linkTarget && linkTarget.startsWith("http");
  return /* @__PURE__ */ jsx("span", { className: classes.root, children: linkTarget && /* @__PURE__ */ jsx(
    Link,
    {
      className: classes.link,
      href: isExternal ? linkTarget : void 0,
      target: isExternal ? "_blank" : void 0,
      rel: isExternal ? "noopener" : void 0,
      onClick: isExternal ? void 0 : () => history.push(linkTarget),
      children: get(data, source, defaultText)
    }
  ) || get(data, source, defaultText) });
};
var LinkField_default = LinkField;
export {
  LinkField,
  LinkField_default as default,
  styles
};
