import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Divider, Grid, Typography } from "@barracuda-internal/bds-core";
import { Trans, useTranslation } from "react-i18next";
const styles = createStyles({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 16,
    minHeight: 500,
    width: "60%",
    minWidth: 800,
    padding: "16px 16px"
  },
  background: {
    width: "60%",
    minWidth: 800,
    margin: "auto",
    marginTop: 32,
    marginBottom: -580,
    opacity: 0.2,
    textAlign: "right",
    "& > *": {
      height: 512,
      width: 512
    }
  },
  icon: {
    textAlign: "center",
    "& > *": {
      height: 128,
      width: 128
    }
  },
  actions: {
    textAlign: "center",
    zIndex: 5
  },
  divider: {
    width: 200,
    margin: "auto"
  },
  iconHeader: {
    textAlign: "center"
  },
  textHeader: {
    fontWeight: "bold",
    textAlign: "center"
  },
  text: {
    textAlign: "center"
  },
  mainContainer: {
    justifyContent: "center"
  },
  stepsContainer: {
    textAlign: "center",
    justifyContent: "center",
    color: "#2478BC",
    margin: "2em"
  },
  circleStep: {
    border: "0.3em solid #2478BC",
    borderRadius: "100%",
    height: "3em",
    width: "3em"
  },
  textStep: {
    marginTop: "0.35em",
    fontSize: "1.5em",
    fontWeight: "bold",
    textAlign: "center"
  },
  customActions: {
    marginTop: "1em",
    textAlign: "center",
    zIndex: 5
  }
});
const useStyles = makeStyles(styles);
const EmptyPageContent = (props) => {
  const {
    background,
    classes: _ignore2,
    customActions,
    iconHeader,
    textHeader,
    icon,
    text,
    actions,
    actionSteps,
    textFooter
  } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    background && /* @__PURE__ */ jsx("div", { className: classes.background, children: background }),
    /* @__PURE__ */ jsxs(Grid, { container: true, className: classes.root, justifyContent: "center", alignContent: "center", spacing: 3, children: [
      icon && /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.icon, children: icon }),
      iconHeader && /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.iconHeader, children: /* @__PURE__ */ jsx(Typography, { component: "span", variant: "h5", className: classes.iconHeader, children: translate(iconHeader) }) }),
      actions && /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.actions, children: actions }),
      /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, children: /* @__PURE__ */ jsx(Divider, { className: classes.divider }) }),
      /* @__PURE__ */ jsxs(Grid, { item: true, xs: 12, container: true, spacing: 0, children: [
        textHeader && /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.textHeader, children: /* @__PURE__ */ jsx(Typography, { component: "span", className: classes.textHeader, children: translate(textHeader) }) }),
        text && /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.text, children: /* @__PURE__ */ jsx(Typography, { component: "span", className: classes.text, children: /* @__PURE__ */ jsx(Trans, { i18nKey: text, children: /* @__PURE__ */ jsx("br", {}) }) }) }),
        /* @__PURE__ */ jsx(Grid, { container: true, spacing: 2, className: classes.mainContainer, children: actionSteps && Array.isArray(actionSteps) && actionSteps.map((step, index) => /* @__PURE__ */ jsxs(Grid, { item: true, xs: 2, container: true, className: classes.stepsContainer, children: [
          /* @__PURE__ */ jsx(Typography, { component: "span", className: classes.circleStep, children: /* @__PURE__ */ jsx("p", { className: classes.textStep, children: translate(step == null ? void 0 : step.title) }) }),
          /* @__PURE__ */ jsx(Trans, { i18nKey: step == null ? void 0 : step.text, children: /* @__PURE__ */ jsx("br", {}) })
        ] }, index)) }),
        customActions && /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.customActions, children: customActions }),
        textFooter && /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.text, children: /* @__PURE__ */ jsx(Typography, { component: "span", className: classes.text, children: /* @__PURE__ */ jsx(Trans, { i18nKey: textFooter, children: /* @__PURE__ */ jsx("br", {}) }) }) })
      ] })
    ] })
  ] });
};
var EmptyPageContent_default = EmptyPageContent;
export {
  EmptyPageContent_default as default
};
