import { jsx, jsxs } from "react/jsx-runtime";
import {
  Card,
  ChipArrayField,
  ConnectedForm,
  TableInput,
  TextArrayInput,
  TextField,
  TextInput,
  useMixpanel,
  validateArrayMemo,
  validateDomain,
  validateIpv4
} from "@cuda-react/core";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../apiResources";
import { makeStyles } from "@mui/styles";
import { parseJson } from "../../../../utils/jsonUtils";
const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(-6)
  }
}));
const ForwardedDomains = () => {
  const [translate] = useTranslation();
  const [existingDomains, setExistingDomains] = useState([]);
  const classes = useStyles();
  const registerAction = useMixpanel("Network Settings Forwarded Domains", false);
  return /* @__PURE__ */ jsx(Card, { title: "tesseract.settings.tabs.forwardedDomains.title", children: /* @__PURE__ */ jsx(
    ConnectedForm,
    {
      resource: apiResources.settings,
      formatRequestData: (data) => ({
        settings: {
          dns_forwarding_zone: JSON.stringify(get(data, "settings.dns_forwarding_zone", {}))
        }
      }),
      formatResourceData: (data) => ({
        settings: {
          dns_forwarding_zone: parseJson(get(data, "settings.dns_forwarding_zone"))
        }
      }),
      params: { filter: { type: "virtualWan" } },
      onChange: (newValues) => {
        setExistingDomains(get(newValues, "settings.dns_forwarding_zone.zones", []).map((zone) => zone.domain));
      },
      classes: { form: classes.form },
      onSubmitSuccess: () => {
        registerAction("Update");
      },
      children: /* @__PURE__ */ jsxs(
        TableInput,
        {
          source: "settings.dns_forwarding_zone.zones",
          optionValue: "domain",
          formValidate: (values, initialValues) => {
            const domain = get(values, "domain");
            if (domain && domain !== get(initialValues, "domain") && existingDomains.includes(domain)) {
              return { domain: translate("tesseract.settings.tabs.forwardedDomains.duplicateName") };
            }
          },
          labels: {
            addTitle: "tesseract.settings.tabs.forwardedDomains.addDomain",
            addButtonText: "tesseract.settings.tabs.forwardedDomains.addDomain",
            editTitle: "tesseract.settings.tabs.forwardedDomains.editDomain",
            deleteTitle: "tesseract.settings.tabs.forwardedDomains.deleteDomain"
          },
          children: [
            /* @__PURE__ */ jsx(
              TextField,
              {
                source: "domain",
                label: "tesseract.settings.tabs.forwardedDomains.domain",
                inputComponent: TextInput,
                inputProps: { isRequired: true, validate: [validateDomain] },
                width: 296
              }
            ),
            /* @__PURE__ */ jsx(
              ChipArrayField,
              {
                source: "forwarders",
                label: "tesseract.settings.tabs.forwardedDomains.forwarders",
                inputComponent: TextArrayInput,
                inputProps: { validate: [validateArrayMemo(validateIpv4)], isRequired: true }
              }
            )
          ]
        }
      )
    }
  ) });
};
var ForwardedDomains_default = ForwardedDomains;
export {
  ForwardedDomains_default as default
};
