import { jsx, jsxs } from "react/jsx-runtime";
import apiResources from "../../../../apiResources";
import { CardSizes, ResourceStatisticsCard, useFetchStatisticsCardDetailsAndTotal } from "@stratos/reporting";
import { useTheme } from "@mui/material";
import { getStatusColorFromTheme } from "../../../../utils/themeUtils";
import { generateQueryString, LinkField, StatusIconField, TextField } from "@cuda-react/core";
import { siteGatewayStatusIcons } from "../../../../components/iconMapping";
const previewData = (theme) => [
  [
    {
      "title": "tesseract.dashboard.cards.vpn.status.online",
      "value": 1,
      "color": getStatusColorFromTheme("online", theme)
    },
    {
      "title": "tesseract.dashboard.cards.vpn.status.pending",
      "value": 2,
      "color": getStatusColorFromTheme("pending", theme)
    },
    {
      "title": "tesseract.dashboard.cards.vpn.status.connecting",
      "value": 3,
      "color": getStatusColorFromTheme("connecting", theme)
    },
    {
      "title": "tesseract.dashboard.cards.vpn.status.degraded",
      "value": 4,
      "color": getStatusColorFromTheme("degraded", theme)
    },
    {
      "title": "tesseract.dashboard.cards.vpn.status.offline",
      "value": 2,
      "color": getStatusColorFromTheme("offline", theme)
    }
  ],
  12
];
const filterDegraded = (details) => !(details.link || details.title).includes("degraded");
const TunnelStatusCard = (props) => {
  const { size, preview } = props;
  const theme = useTheme();
  const apiResult = useFetchStatisticsCardDetailsAndTotal(
    {
      url: preview ? void 0 : apiResources.VPNTunnelStatus
    },
    {
      url: preview ? void 0 : apiResources.siteStates
    },
    {
      getKeyColor: (key) => getStatusColorFromTheme(key, theme),
      linkCreator: (data) => `/#/infrastructure/sites${generateQueryString({ connectionStatus: [data.key] })}#/infrastructure/sites`
    }
  );
  const [details, total] = preview ? previewData(theme) : apiResult;
  return /* @__PURE__ */ jsxs(
    ResourceStatisticsCard,
    {
      overViewData: size === CardSizes.small && details ? details.filter(filterDegraded) : details,
      size,
      preview,
      overViewValue: total,
      overviewTitle: "tesseract.dashboard.cards.vpn.status.tabTitle",
      tableTitle: "tesseract.dashboard.cards.vpn.offline.tabTitle",
      noDataMessage: "tesseract.dashboard.cards.vpn.offline.noData",
      children: [
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "gateway.status",
            label: "tesseract.dashboard.cards.vpn.offline.gatewayStatus",
            iconMap: siteGatewayStatusIcons,
            width: 96
          }
        ),
        /* @__PURE__ */ jsx(
          LinkField,
          {
            label: "tesseract.dashboard.cards.vpn.offline.gatewayName",
            source: "gateway.name",
            link: (data) => `/infrastructure/gateways${generateQueryString({
              name: [data.gateway.name],
              connectionStatus: [data.gateway.status]
            })}`
          }
        ),
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "status",
            label: "tesseract.dashboard.cards.vpn.offline.siteStatus",
            iconMap: siteGatewayStatusIcons,
            width: 96
          }
        ),
        /* @__PURE__ */ jsx(
          LinkField,
          {
            label: "tesseract.dashboard.cards.vpn.offline.siteName",
            source: "name",
            link: (data) => {
              var _a;
              return `/infrastructure/${((_a = data.model) == null ? void 0 : _a.startsWith("F")) ? "iot" : "sites"}/${data.id}`;
            }
          }
        ),
        /* @__PURE__ */ jsx(TextField, { label: "tesseract.dashboard.cards.vpn.offline.siteLocation", source: "summary" })
      ]
    }
  );
};
var TunnelStatusCard_default = TunnelStatusCard;
export {
  TunnelStatusCard,
  TunnelStatusCard_default as default
};
