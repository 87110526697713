import { jsx } from "react/jsx-runtime";
import { get, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authCheck } from "../../../actions";
const Authenticated = ({ children, authParams }) => {
  const authData = useSelector((state) => get(state, "GlobalParamsReducer.userData"));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authCheck(authParams));
  }, []);
  return /* @__PURE__ */ jsx(React.Fragment, { children: !isEmpty(authData) ? children : null });
};
var Authenticated_default = Authenticated;
export {
  Authenticated,
  Authenticated_default as default
};
