import { jsx, jsxs } from "react/jsx-runtime";
import MalwareProtection from "./tabs/MalwareProtection/MalwareProtection";
import IPS from "./tabs/IPS/IPS";
import SslInterception from "./tabs/SslInterception/SslInterception";
import { Route, Switch } from "react-router";
const Security = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(Route, { path: "/policies/security/malware", children: /* @__PURE__ */ jsx(MalwareProtection, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/security/ssl", children: /* @__PURE__ */ jsx(SslInterception, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/security/ips", children: /* @__PURE__ */ jsx(IPS, {}) })
] });
var Security_default = Security;
export {
  Security_default as default
};
