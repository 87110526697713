import { jsx, jsxs } from "react/jsx-runtime";
import { Route, Switch } from "react-router";
import FirewallsTable from "./firewalls/FirewallsTable";
import AzureMonitor from "./settings/AzureMonitor";
import Xdr from "./xdr/Xdr";
import IpsecV2 from "./ipsecv2/Ipsecv2";
const Integration = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(Route, { path: "/integration/firewalls", children: /* @__PURE__ */ jsx(FirewallsTable, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/integration/settings", children: /* @__PURE__ */ jsx(AzureMonitor, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/integration/xdr", children: /* @__PURE__ */ jsx(Xdr, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/integration/ipsecV2", children: /* @__PURE__ */ jsx(IpsecV2, {}) })
] });
var Integration_default = Integration;
export {
  Integration,
  Integration_default as default
};
