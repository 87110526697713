var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import {
  ActionButtonsField,
  CustomField,
  DeleteDialog,
  DisableableButton,
  EmptyPageContent,
  getArrayDataContent,
  getDataContent,
  StatusIconField,
  TablePage,
  TextField,
  useCrudProps,
  useMixpanel,
  usePreview
} from "@cuda-react/core";
import CreateEnrollEmailDialog from "./enroll/CreateEnrollEmailDialog";
import apiResources from "../../../apiResources";
import { SuccessIcon } from "../../../components/iconMapping";
import { Forward, Help } from "@barracuda-internal/bds-core/dist/Icons/Core";
import UsersMenu from "./UsersMenu";
import { BooleanIcon, GetStartedBackgroundGraphic, UsersGraphic } from "@cuda-react/icons";
import { Dialog, Skeleton } from "@barracuda-internal/bds-core";
import { push } from "react-router-redux";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import useDemoApi from "../../../hooks/useDemoApi";
const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.error.main
  }
}));
const UsersTable = () => {
  const classes = useStyles();
  const tableRefreshRef = useRef(null);
  const [translate] = useTranslation();
  const registerAction = useMixpanel("Endpoint Users");
  const directoriesApi = useDemoApi(apiResources.directories, apiResources.demoDirectories);
  const identityApi = useDemoApi(apiResources.identityProvider, apiResources.demoIdentityProviders);
  const hasUserDirectory = getArrayDataContent(useCrudProps(directoriesApi)[0].data).length > 0;
  const hasIdentityProvider = getArrayDataContent(useCrudProps(identityApi)[0].data).length > 0;
  const [actionVisible, setActionVisible] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const dispatch = useDispatch();
  const optionsChoices = [{ key: "true", name: translate("tesseract.users.table.on") }, { key: "false", name: translate("tesseract.users.table.off") }];
  const enrollmentUrl = getDataContent(useCrudProps(apiResources.enrollmentUrl)[0].data);
  const [refreshRate, setRefreshRate] = useState(void 0);
  const tpmEnforcement = usePreview("tpm");
  const handleCreateSuccess = () => {
    var _a;
    (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
    setRefreshRate(5e3);
    setTimeout(() => setRefreshRate(void 0), 11e3);
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      TablePage,
      {
        titleActions: actionVisible && /* @__PURE__ */ jsx(
          DisableableButton,
          {
            buttonText: "tesseract.users.dialog.buttonText",
            disabled: !hasUserDirectory || !hasIdentityProvider,
            disabledMessage: "tesseract.users.dialog.disabledButton",
            onClick: () => setCreateOpen(true)
          }
        ),
        params: (params) => {
          var _a, _b, _c;
          return __spreadProps(__spreadValues({}, params), {
            sort: {
              order: (_a = params == null ? void 0 : params.sort) == null ? void 0 : _a.order,
              field: ((_b = params == null ? void 0 : params.sort) == null ? void 0 : _b.field) === "enrolled" ? "deviceCount" : (_c = params == null ? void 0 : params.sort) == null ? void 0 : _c.field
            }
          });
        },
        authenticated: true,
        title: "tesseract.endpoint.pageTitle",
        subtitle: "tesseract.endpoint.users",
        resource: apiResources.zeroTrustUsers,
        minCellWidth: 90,
        resetOnGlobalParamChange: true,
        refreshRef: tableRefreshRef,
        exportResource: { resource: apiResources.zeroTrustUsersExport, filename: "users" },
        onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table"),
        pollInterval: refreshRate,
        noDataPage: /* @__PURE__ */ jsx(
          EmptyPageContent,
          {
            background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
            icon: /* @__PURE__ */ jsx(UsersGraphic, {}),
            iconHeader: "tesseract.users.noData.iconHeader",
            textHeader: "tesseract.users.noData.textHeader",
            text: "tesseract.users.noData.text",
            actions: /* @__PURE__ */ jsx(
              DisableableButton,
              {
                buttonText: "tesseract.users.noData.labelButton",
                disabled: !hasUserDirectory || !hasIdentityProvider,
                disabledMessage: "tesseract.users.dialog.disabledButton",
                onClick: () => setCreateOpen(true)
              }
            ),
            actionSteps: [{
              title: "tesseract.users.noData.firstTitle",
              text: "tesseract.users.noData.firstStep"
            }, { title: "tesseract.users.noData.secondTitle", text: "tesseract.users.noData.secondStep" }],
            textFooter: "tesseract.users.noData.textFooter"
          }
        ),
        resizable: true,
        editColumns: true,
        tableName: "Users-Table",
        pageMode: "paginate",
        children: [
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "fullName",
              label: "tesseract.users.table.fullName",
              filter: "text",
              sortable: true,
              width: 90,
              render: (value) => value ? value : /* @__PURE__ */ jsx(Skeleton, { style: { width: 90, height: 30 } })
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "email",
              label: "tesseract.users.table.email",
              filter: "text",
              width: 120,
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            StatusIconField,
            {
              source: "optionsOverride",
              label: "tesseract.users.table.optionsOverride",
              icon: /* @__PURE__ */ jsx(BooleanIcon, {}),
              width: 90,
              filter: "select",
              filterProps: {
                choices: optionsChoices
              },
              columnProps: {
                field: "optionsOverride"
              },
              sortable: true,
              hidden: true
            }
          ),
          /* @__PURE__ */ jsx(
            StatusIconField,
            {
              source: "tamperProof",
              label: "tesseract.users.table.tamperProof",
              icon: /* @__PURE__ */ jsx(BooleanIcon, {}),
              width: 90,
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            StatusIconField,
            {
              source: "devicePreConnect",
              label: "tesseract.users.table.devicePreConnect",
              icon: /* @__PURE__ */ jsx(BooleanIcon, {}),
              width: 110,
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            StatusIconField,
            {
              source: "webFilter",
              label: "tesseract.users.table.webFiltering",
              icon: /* @__PURE__ */ jsx(BooleanIcon, {}),
              width: 90,
              sortable: true
            }
          ),
          tpmEnforcement ? /* @__PURE__ */ jsx(
            StatusIconField,
            {
              source: "tpmEnforcement",
              label: "tesseract.users.table.tpmEnforcement",
              icon: /* @__PURE__ */ jsx(BooleanIcon, {}),
              width: 90,
              sortable: true
            }
          ) : null,
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "deviceCount",
              label: "tesseract.users.table.deviceCount",
              render: (value, data) => {
                const text = `${value} (${data.deviceLimit})`;
                return data && /* @__PURE__ */ jsx(
                  TextField,
                  {
                    source: "text",
                    data: { data, text },
                    classes: (data == null ? void 0 : data.deviceLimit) < value ? classes : void 0
                  }
                );
              },
              width: 90,
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "enrolled",
              label: "tesseract.users.table.enrollmentCompleted.label",
              render: (value, data) => data && ((data == null ? void 0 : data.deviceCount) > 0 ? /* @__PURE__ */ jsx(StatusIconField, { icon: /* @__PURE__ */ jsx(SuccessIcon, {}), tooltip: "tesseract.users.table.enrollmentCompleted.yes" }) : /* @__PURE__ */ jsx(StatusIconField, { icon: /* @__PURE__ */ jsx(Help, {}), tooltip: "tesseract.users.table.enrollmentCompleted.no" })),
              width: 120,
              sortable: true
            }
          ),
          /* @__PURE__ */ jsxs(
            ActionButtonsField,
            {
              source: "id",
              width: 120,
              alwaysVisible: true,
              children: [
                /* @__PURE__ */ jsx(UsersMenu, { enrollmentUrl }),
                /* @__PURE__ */ jsx(
                  DeleteDialog,
                  {
                    resource: apiResources.zeroTrustUsers,
                    title: "tesseract.users.delete.title",
                    message: "tesseract.users.delete.body",
                    onSuccess: () => {
                      var _a;
                      registerAction("Unenroll User");
                      (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                    }
                  }
                ),
                /* @__PURE__ */ jsx(
                  Forward,
                  {
                    id: "user-details-icon",
                    onClick: (event, data) => {
                      dispatch(push("/ztna/users/" + data.id));
                    }
                  }
                )
              ]
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Dialog,
      {
        open: createOpen,
        maxWidth: false,
        disableEnforceFocus: true,
        onClose: () => setCreateOpen(false),
        children: /* @__PURE__ */ jsx(
          CreateEnrollEmailDialog,
          {
            create: true,
            onSuccess: handleCreateSuccess,
            onClose: () => setCreateOpen(false)
          }
        )
      }
    )
  ] });
};
var UsersTable_default = UsersTable;
export {
  UsersTable_default as default
};
