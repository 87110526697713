import { get, merge } from "lodash";
import { zoomToFit } from "./zoomToFit";
import { getLinkedPoints } from "./getLinkedPoints";
import { drawLinkedLines } from "./drawLinkedLines";
import { highlightSourcePoint } from "./highlightSourcePoint";
import { drawLinkedPoints } from "./drawLinkedPoints";
const handlePointClick = (chart, clickedPoint, setTooltip, translate, theme) => {
  var _a;
  const point = merge({}, clickedPoint);
  setTooltip({ element: null, point });
  highlightSourcePoint(chart, get(point, "pointData.series", {}), point, setTooltip, translate, theme);
  const linkedPoints = getLinkedPoints(chart, clickedPoint);
  if (linkedPoints.length) {
    drawLinkedPoints(chart, get(point, "pointData.series", {}), linkedPoints, setTooltip, translate, theme);
    drawLinkedLines(chart, theme, false);
    zoomToFit(chart, [point, ...linkedPoints]);
  }
  (_a = chart.current) == null ? void 0 : _a.redraw();
  return false;
};
export {
  handlePointClick
};
