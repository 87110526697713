var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { SvgIcon, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RiskLevels } from "../../../typesAndConstants";
const useStyles = makeStyles({
  icon: {
    width: 267,
    height: 186,
    fill: "none"
  }
});
const riskRotations = {
  low: -100,
  lowMedium: -50,
  medium: 0,
  mediumHigh: 50,
  high: 100
};
const RiskGaugeIcon = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const riskLevel = props.riskLevel || RiskLevels.low;
  const riskColor = theme.palette.risk[riskLevel];
  const riskRotation = riskRotations[riskLevel];
  const gradientId = useRef(`gauge_gradient_${Math.random() * 1e3}`);
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    __spreadProps(__spreadValues({
      className: classes.icon,
      viewBox: "0 0 267 186"
    }, props), {
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            d: "M 15.8659,177.871 C 10.6943,164.127 7.87354,149.232 7.87354,133.686 7.87354,64.2851 64.2199,7.93872 133.621,7.93872 c 69.4,0 125.746,56.34638 125.746,125.74728 0,15.53 -2.82,30.41 -7.976,44.146",
            stroke: `url(#${gradientId.current})`,
            strokeWidth: "15",
            strokeMiterlimit: "1.5",
            strokeLinecap: "round",
            strokeLinejoin: "round"
          }
        ),
        /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs(
          "radialGradient",
          {
            id: gradientId.current,
            gradientTransform: "translate(133.5,130)",
            cx: "0",
            cy: "-124",
            fr: "20",
            gradientUnits: "userSpaceOnUse",
            children: [
              /* @__PURE__ */ jsx("stop", { stopColor: riskColor, children: /* @__PURE__ */ jsx(
                "animate",
                {
                  attributeName: "stop-color",
                  values: Object.values(RiskLevels).slice(0, Object.values(RiskLevels).findIndex((level) => riskLevel === level) + 1).map((level) => theme.palette.risk[level]).join("; "),
                  dur: "1000ms"
                }
              ) }),
              /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#EDEDED" }),
              /* @__PURE__ */ jsx(
                "animateTransform",
                {
                  attributeName: "gradientTransform",
                  attributeType: "XML",
                  type: "rotate",
                  values: `-110;${riskRotation}`,
                  dur: "1000ms",
                  additive: "sum",
                  fill: "freeze"
                }
              )
            ]
          }
        ) }),
        /* @__PURE__ */ jsx("g", { transform: `translate(133.5,130)`, style: { fill: "none" }, children: /* @__PURE__ */ jsxs(
          "path",
          {
            d: "M 0,-124 0,-94",
            stroke: riskColor,
            strokeWidth: "6.25012",
            strokeMiterlimit: "2",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            children: [
              /* @__PURE__ */ jsx(
                "animate",
                {
                  attributeName: "stroke",
                  values: Object.values(RiskLevels).slice(0, Object.values(RiskLevels).findIndex((level) => riskLevel === level) + 1).map((level) => theme.palette.risk[level]).join("; "),
                  dur: "1000ms"
                }
              ),
              /* @__PURE__ */ jsx(
                "animateTransform",
                {
                  attributeName: "transform",
                  attributeType: "XML",
                  type: "rotate",
                  from: "-110 0 0",
                  to: `${riskRotation} 0 0`,
                  dur: "1000ms",
                  additive: "sum",
                  fill: "freeze"
                }
              )
            ]
          }
        ) })
      ]
    })
  );
};
export {
  RiskGaugeIcon
};
