import { jsx } from "react/jsx-runtime";
import React from "react";
import { useDeepCompareEffect } from "../../../hooks";
import { isEqual } from "lodash";
const Hidden = ({ hiddenValue, onChange, value }) => {
  useDeepCompareEffect(() => {
    if (hiddenValue && !isEqual(hiddenValue, value)) {
      onChange == null ? void 0 : onChange(hiddenValue);
    }
  }, [hiddenValue, onChange, value]);
  return /* @__PURE__ */ jsx(React.Fragment, {});
};
var Hidden_default = Hidden;
export {
  Hidden,
  Hidden_default as default
};
