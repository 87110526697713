import { jsx, jsxs } from "react/jsx-runtime";
import { Button, CircularProgress, IconButton, TextField, Typography } from "@barracuda-internal/bds-core";
import { InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Assignment } from "@barracuda-internal/bds-core/dist/Icons/Core";
import {
  CrudTypes,
  DialogBody,
  getDataContent,
  Toolbar,
  useCrudFetch,
  useGlobalParam,
  useMixpanel
} from "@cuda-react/core";
import hexGenerator from "hex-generator";
import React, { useEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import apiResources from "../../../../apiResources";
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    maxWidth: 400,
    padding: theme.spacing(2)
  },
  inputRoot: {
    width: "calc(100% - " + theme.spacing(8) + ")",
    margin: theme.spacing(2, 4, 1),
    "& .MuiOutlinedInput-multiline": {
      height: "inherit"
    }
  },
  completeMessage: {
    margin: theme.spacing(1)
  }
}));
const CreateGatewayContent = ({ onClose, title }) => {
  useMixpanel("Gateways", "Create Gateway Token");
  const [translate] = useTranslation();
  const classes = useStyles();
  const demo = useGlobalParam("userData.mode")[0] === "demo";
  const [data, , fetchToken] = useCrudFetch(CrudTypes.CREATE, apiResources.registerGateway);
  const token = demo ? React.useMemo(() => hexGenerator(128), []) : (getDataContent(data) || {}).otp;
  const textAreaRef = useRef(null);
  useEffect(() => {
    demo || fetchToken();
  }, []);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsxs(DialogBody, { classes: { dialogContent: classes.dialogContent }, title, onClose, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.completeMessage, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.gateways.dialog.result.message", children: /* @__PURE__ */ jsx(
        "a",
        {
          href: "https://portal.azure.com/#create/barracudanetworks.barracuda_cloudgenwan_gatewayvirtualwan_gateway_saas",
          rel: "noreferrer noopener",
          target: "_blank"
        }
      ) }) }),
      /* @__PURE__ */ jsx(
        TextField,
        {
          id: "gateway-otp",
          className: classes.inputRoot,
          InputProps: {
            endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: token ? /* @__PURE__ */ jsx(IconButton, { onClick: () => {
              var _a, _b;
              (_a = textAreaRef.current) == null ? void 0 : _a.select();
              document.execCommand("copy");
              (_b = textAreaRef.current) == null ? void 0 : _b.setSelectionRange(0, 0);
            }, children: /* @__PURE__ */ jsx(Assignment, {}) }) : /* @__PURE__ */ jsx(CircularProgress, { size: 24 }) })
          },
          fullWidth: true,
          inputRef: textAreaRef,
          label: translate("tesseract.gateways.dialog.result.managedAppText"),
          value: token || "",
          disabled: !token,
          variant: "outlined"
        }
      ),
      /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.completeMessage, children: translate("tesseract.gateways.dialog.result.managedAppOtp") })
    ] }),
    /* @__PURE__ */ jsx(Toolbar, { children: /* @__PURE__ */ jsx(Button, { variant: "contained", color: "primary", onClick: onClose, children: translate("cuda.notification.ok") }) })
  ] });
};
var CreateGatewayContent_default = CreateGatewayContent;
export {
  CreateGatewayContent,
  CreateGatewayContent_default as default,
  useStyles
};
