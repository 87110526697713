const parseJson = (jsonText, defaultValue = {}) => {
  try {
    return JSON.parse(jsonText);
  } catch (e) {
    return defaultValue;
  }
};
export {
  parseJson
};
