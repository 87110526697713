import { jsx, jsxs } from "react/jsx-runtime";
import { createStyles, makeStyles } from "@mui/styles";
import { CardSizes } from "../../../typesAndConstants";
import { useTranslation } from "react-i18next";
import { Grid, Skeleton, Typography } from "@barracuda-internal/bds-core";
const styles = (theme) => createStyles({
  root: {
    paddingTop: ({ size }) => theme.spacing(size === CardSizes.small ? 8 : 1)
  },
  icon: {
    textAlign: "center",
    padding: ({ size }) => size === CardSizes.large ? theme.spacing(1.5, 2, 0, 0) : 0,
    color: theme.palette.reporting.fontColor
  },
  total: {
    textAlign: "center",
    fontSize: 30,
    fontWeight: "lighter",
    color: theme.palette.reporting.fontColor
  },
  totalValue: {
    color: theme.palette.error.main,
    fontWeight: "bold"
  },
  skeleton: {
    width: 48,
    display: "inline-block"
  }
});
const useStyles = makeStyles(styles);
const TotalChart = (props) => {
  const { total, icon, loading, className, size } = props;
  const [translate] = useTranslation();
  const classes = useStyles(props);
  return /* @__PURE__ */ jsxs(Grid, { container: true, className: classes.root, alignItems: "center", justifyContent: "center", children: [
    /* @__PURE__ */ jsx(Grid, { item: true, xs: size === CardSizes.large ? void 0 : 12, className: classes.icon, children: icon }),
    /* @__PURE__ */ jsx(Grid, { item: true, xs: size === CardSizes.large ? void 0 : 12, children: /* @__PURE__ */ jsxs(Typography, { variant: "h2", className: classes.total, children: [
      /* @__PURE__ */ jsxs("span", { children: [
        translate("stratosReporting.totalChart.total"),
        " "
      ] }),
      loading ? /* @__PURE__ */ jsx(Skeleton, { className: classes.skeleton }) : /* @__PURE__ */ jsxs("span", { className: classes.totalValue, children: [
        total,
        " "
      ] })
    ] }) })
  ] });
};
export {
  TotalChart
};
