import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { MultiInput, TextInput, validateDomain, validateMaxLengthMemo, validateRegexMemo } from "@cuda-react/core";
import { useSiteInputValidations } from "../../../../../hooks/createEditSiteHooks";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  multiInputMainColumnStyle: {
    paddingBottom: 18,
    paddingLeft: 16,
    paddingRight: 16
  },
  topBarColumnStyle: {
    marginTop: 0
  }
});
const SdwanConnectorServicesStep = ({ create }) => {
  const classes = useStyles();
  const inputValidations = useSiteInputValidations();
  return /* @__PURE__ */ jsx(React.Fragment, { children: /* @__PURE__ */ jsxs(
    MultiInput,
    {
      maxInputs: 250,
      source: "resources",
      classes: !create ? classes : void 0,
      columnStyle: true,
      columnStyleItemLabelSource: "serviceName",
      columnStyleItemLabel: "tesseract.network.sdwanConnector.dialog.servers.itemName",
      columnStyleTitleLabel: "tesseract.network.sdwanConnector.dialog.servers.serviceName",
      children: [
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "serviceName",
            label: "tesseract.network.sdwanConnector.dialog.servers.serviceName",
            description: "tesseract.network.sdwanConnector.dialog.description.servers.serviceName",
            isRequired: true,
            validate: [validateMaxLengthMemo(31), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "hostname",
            label: "tesseract.network.sdwanConnector.dialog.servers.hostname",
            description: "tesseract.network.sdwanConnector.dialog.description.servers.hostname",
            isRequired: true,
            validate: [validateDomain]
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "internalIp",
            label: "tesseract.network.sdwanConnector.dialog.servers.internalIp",
            description: "tesseract.network.sdwanConnector.dialog.description.servers.internalIp",
            validate: inputValidations.validateIp,
            isRequired: true
          }
        )
      ]
    }
  ) });
};
var SdwanConnectorServicesStep_default = SdwanConnectorServicesStep;
export {
  SdwanConnectorServicesStep_default as default
};
