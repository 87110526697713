import { Fragment, jsx } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
;
const emptyPlaceholder = "\u2014";
const UserDirectorySyncResultField = ({ directory }) => {
  if (!directory.lastSuccessfulSync) {
    return /* @__PURE__ */ jsx(Fragment, { children: emptyPlaceholder });
  }
  const [translate] = useTranslation();
  const users = translate("tesseract.identity.userDirectories.users", { count: directory.totalUsers });
  const groups = translate("tesseract.identity.userDirectories.groups", { count: directory.totalGroups });
  const synced = translate("tesseract.identity.userDirectories.syncStatus", {
    users,
    groups
  });
  return /* @__PURE__ */ jsx(Fragment, { children: synced });
};
export {
  UserDirectorySyncResultField,
  emptyPlaceholder
};
