var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useCallback, useRef } from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = createStyles({
  root: {
    display: "flex"
  },
  inputRoot: {
    width: "auto",
    marginRight: 0
  },
  firstInput: {
    paddingRight: 8
  },
  secondInput: {
    paddingLeft: 0
  }
});
const useStyles = makeOverrideableStyles("Double", styles);
const Double = (props) => {
  const _a = props, {
    error,
    firstInputProps,
    id,
    inputComponent,
    onBlur,
    onChange,
    onFocus,
    secondInputProps,
    value,
    inputProps
  } = _a, otherProps = __objRest(_a, [
    "error",
    "firstInputProps",
    "id",
    "inputComponent",
    "onBlur",
    "onChange",
    "onFocus",
    "secondInputProps",
    "value",
    "inputProps"
  ]);
  const classes = useStyles(props);
  const currentValue = useRef(["", ""]);
  currentValue.current = Array.isArray(value) ? value : ["", ""];
  const childOnChange = useCallback((inputIndex) => (eventOrValue) => {
    const value2 = eventOrValue.target ? eventOrValue.target.value : eventOrValue;
    const newValue = [
      inputIndex === 0 ? value2 : currentValue.current[0],
      inputIndex === 1 ? value2 : currentValue.current[1]
    ];
    currentValue.current = newValue;
    onChange && onChange(newValue);
  }, [value]);
  const ComponentToRender = inputComponent;
  return /* @__PURE__ */ jsxs("div", { className: classes.root, children: [
    /* @__PURE__ */ jsx("div", { className: classes.firstInput, children: React.createElement(ComponentToRender, __spreadValues(__spreadValues(__spreadValues({
      id: id + "[0]",
      value: currentValue.current[0],
      onChange: childOnChange(0),
      onBlur: () => onBlur && onBlur(),
      onFocus: () => onFocus && onFocus(),
      error: Array.isArray(error) ? error[0] ? error[0] : void 0 : error
    }, firstInputProps), inputProps), otherProps)) }),
    /* @__PURE__ */ jsx("div", { className: classes.secondInput, children: React.createElement(ComponentToRender, __spreadValues(__spreadValues(__spreadValues({
      id: id + "[1]",
      value: currentValue.current[1],
      onChange: childOnChange(1),
      onBlur: () => onBlur && onBlur(),
      onFocus: () => onFocus && onFocus(),
      error: Array.isArray(error) ? error[1] ? error[1] : void 0 : error
    }, secondInputProps), inputProps), otherProps)) })
  ] });
};
var Double_default = Double;
export {
  Double,
  Double_default as default
};
