import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const WidgetErrorIcon = ({ className }) => {
  var _a, _b, _c;
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-widget-error",
      viewBox: "0 0 60 60",
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_a = theme.palette.error) == null ? void 0 : _a.main, strokeWidth: 0 },
            d: "M47.4,12.6c-9.6-9.6-25.1-9.6-34.7,0-9.6,9.6-9.6,25.1,0,34.7,9.6,9.6,25.1,9.6,34.7,0,9.6-9.6,9.6-25.1,0-34.7ZM16.1,43.9c-7.7-7.7-7.7-20.1,0-27.8,7.7-7.7,20.1-7.7,27.8,0,7.7,7.7,7.7,20.1,0,27.8-7.7,7.7-20.1,7.7-27.8,0Z"
          }
        ),
        /* @__PURE__ */ jsxs("g", { children: [
          /* @__PURE__ */ jsx("rect", { style: { fill: (_b = theme.palette.error) == null ? void 0 : _b.main, strokeWidth: 0 }, x: "27.5", y: "37.4", width: "4.9", height: "4.9" }),
          /* @__PURE__ */ jsx("rect", { style: { fill: (_c = theme.palette.error) == null ? void 0 : _c.main, strokeWidth: 0 }, x: "27.5", y: "17.7", width: "4.9", height: "14.7" })
        ] })
      ]
    }
  );
};
var WidgetErrorIcon_default = WidgetErrorIcon;
export {
  WidgetErrorIcon,
  WidgetErrorIcon_default as default
};
