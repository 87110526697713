import { jsx, jsxs } from "react/jsx-runtime";
import {
  CrudTypes,
  getDataContent,
  NoResourcePage,
  Tab,
  TabbedPage,
  useCrudSubscription
} from "@cuda-react/core";
import PropTypes from "prop-types";
import apiResources from "../../../../apiResources";
import UserSettings from "./UserSettings";
import UserDashboard from "./UserDashboard";
const UserDetails = ({ match }) => {
  var _a;
  const id = (_a = match == null ? void 0 : match.params) == null ? void 0 : _a.id;
  const [userDetailsData, loading] = useCrudSubscription(CrudTypes.GET, apiResources.zeroTrustUsers, { id });
  const userDetails = getDataContent(userDetailsData, {});
  const userNotFound = !!(userDetailsData == null ? void 0 : userDetailsData.error) || !id;
  return !userNotFound ? /* @__PURE__ */ jsxs(
    TabbedPage,
    {
      defaultTab: 0,
      title: "tesseract.endpoint.pageTitle",
      subtitle: [
        { text: "tesseract.endpoint.users", path: "/ztna/users" },
        (userDetails == null ? void 0 : userDetails.fullName) || " "
      ],
      children: [
        /* @__PURE__ */ jsx(
          Tab,
          {
            value: "#usersdashboard",
            label: "tesseract.endpoint.ztnaUsers.dashboardTabTitle",
            children: /* @__PURE__ */ jsx(
              UserDashboard,
              {
                details: { id, loading }
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          Tab,
          {
            value: "#usersettings",
            label: "tesseract.endpoint.ztnaUsers.settingsTabTitle",
            children: /* @__PURE__ */ jsx(
              UserSettings,
              {
                details: { id, loading }
              }
            )
          }
        )
      ]
    }
  ) : /* @__PURE__ */ jsx(NoResourcePage, { content: "tesseract.endpoint.ztnaUsers.userNotFound" });
};
UserDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  })
};
var UserDetails_default = UserDetails;
export {
  UserDetails_default as default
};
