import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import {
  FormSection,
  getArrayDataContent,
  PasswordConfirmInput,
  SelectInput,
  TextInput,
  useCrudProps
} from "@cuda-react/core";
import { useWatch } from "react-hook-form";
import { useSiteInputValidations } from "../../../../hooks/createEditSiteHooks";
import apiResources from "../../../../apiResources";
const SCLanTableInputForm = ({ portsData }) => {
  var _a;
  const inputValidations = useSiteInputValidations();
  const wifiEncryptionMode = getArrayDataContent((_a = useCrudProps(apiResources.wifiEncryptionMode)[0]) == null ? void 0 : _a.data);
  const port = useWatch({ name: "port" });
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "name",
        label: "tesseract.sites.dialog.lans.name",
        description: "tesseract.sites.dialog.lans.descriptions.name",
        isRequired: true,
        validate: inputValidations.validateName
      }
    ),
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: "port",
        label: "tesseract.sites.dialog.lans.port",
        description: "tesseract.sites.dialog.lans.descriptions.port",
        choices: portsData,
        optionValue: "port",
        optionText: "description"
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "address",
        label: "tesseract.sites.dialog.lans.address",
        description: "tesseract.sites.dialog.lans.descriptions.address",
        validate: inputValidations.validateIp,
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "netmask",
        label: "tesseract.sites.dialog.lans.netmask",
        description: "tesseract.sites.dialog.lans.descriptions.netmask",
        validate: inputValidations.validateNetmask,
        isRequired: true
      }
    ),
    port === "wifi" && /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.sites.dialog.lans.wifiSectionTitle", children: [
      /* @__PURE__ */ jsx(
        TextInput,
        {
          source: "ssid",
          label: "tesseract.sites.dialog.lans.wifi.ssid",
          description: "tesseract.sites.dialog.lans.descriptions.wifi.ssid",
          validate: inputValidations.validateWifiSsid,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          source: "mode",
          label: "tesseract.sites.dialog.lans.wifi.securityMode",
          description: "tesseract.sites.dialog.lans.descriptions.wifi.securityMode",
          choices: wifiEncryptionMode,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        PasswordConfirmInput,
        {
          source: "passphrase",
          label: "tesseract.sites.dialog.lans.wifi.passphrase",
          confirmLabel: "tesseract.sites.dialog.lans.wifi.passphraseConfirm",
          description: "tesseract.sites.dialog.lans.descriptions.wifi.passphrase",
          validate: inputValidations.validateWifiPassphrase,
          isRequired: true
        }
      )
    ] })
  ] });
};
var SCLanTableInputForm_default = SCLanTableInputForm;
export {
  SCLanTableInputForm_default as default
};
