import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { BooleanInput, FormSection, getArrayDataContent, SelectInput, TextInput, useCrudProps } from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../apiResources";
import { useSiteInputValidations } from "../../../../hooks/createEditSiteHooks";
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    fontSize: 16
  },
  completeMessage: {
    margin: theme.spacing(3)
  }
}));
const NetworkInput = () => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const dpdAction = getArrayDataContent(useCrudProps(apiResources.phasesDpdActions)[0]);
  const inputValidations = useSiteInputValidations();
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.completeMessage, children: translate("tesseract.integration.ipSecV2.dialog.networkSection.descriptionMessage") }),
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.title", children: [
      /* @__PURE__ */ jsx(
        BooleanInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.oneTunnelSubnet",
          description: "tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.descriptions.oneTunnelSubnet",
          source: "subnetPairs",
          hide: (value, data) => data == null ? void 0 : data.bgp
        }
      ),
      /* @__PURE__ */ jsx(
        BooleanInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.universalTrafficSelectors",
          description: "tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.descriptions.universalTrafficSelectors",
          source: "universalTrafficSelectors",
          hide: (value, data) => data == null ? void 0 : data.bgp
        }
      ),
      /* @__PURE__ */ jsx(
        BooleanInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.forceUdpEncapsulation",
          description: "tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.descriptions.forceUdpEncapsulation",
          source: "udpEncapsulation"
        }
      ),
      /* @__PURE__ */ jsx(
        BooleanInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.ikeReauthentication",
          description: "tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.descriptions.ikeReauthentication",
          source: "reauthentication"
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.integration.ipSecV2.dialog.networkSection.deadPeerDetection.title", children: [
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.networkSection.deadPeerDetection.actionDetected",
          description: "tesseract.integration.ipSecV2.dialog.networkSection.deadPeerDetection.descriptions.actionDetected",
          source: "dpd.action",
          choices: dpdAction && dpdAction
        }
      ),
      /* @__PURE__ */ jsx(
        TextInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.networkSection.deadPeerDetection.delay",
          description: "tesseract.integration.ipSecV2.dialog.networkSection.deadPeerDetection.descriptions.delay",
          source: "dpd.delay",
          inputProps: { type: "number" },
          validate: inputValidations.validateTunnelLifetime,
          disable: (value, data) => {
            var _a;
            return !(((_a = data == null ? void 0 : data.dpd) == null ? void 0 : _a.action) !== "none");
          }
        }
      )
    ] })
  ] });
};
var NetworkInput_default = NetworkInput;
export {
  NetworkInput,
  NetworkInput_default as default
};
