import { jsx, jsxs } from "react/jsx-runtime";
import { makeStyles } from "@mui/styles";
import {
  ActionButtonsField,
  ChipArrayField,
  DeleteDialog,
  getArrayDataContent,
  StatusIconField,
  TableAction,
  TablePage,
  TextField,
  useCrudProps,
  useMixpanel
} from "@cuda-react/core";
import { flatMap } from "lodash";
import { useRef } from "react";
import AddNotificationDialog from "./AddNotificationDialog";
import EditNotificationDialog from "./EditNotificationDialog";
import apiResources from "../../../apiResources";
import { Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
const styles = (theme) => ({
  table: {
    "&:title": {
      minWidth: "260px"
    },
    "&:tbody:td": {
      overflow: "visible !important"
    },
    "&:th:first-child": {
      width: "50px !important",
      textAlign: "center !important"
    },
    "&:tr:td:first-child, &:tr:th:last-child": {
      textAlign: "center !important"
    },
    "&:td.column-id": {
      width: "120px"
    }
  },
  rowActions: {
    width: "120px"
  },
  name: {
    width: "20%"
  },
  message: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1)
  }
});
const useStyles = makeStyles(styles);
const NotificationList = () => {
  var _a, _b;
  const registerAction = useMixpanel("Notifications");
  const notificationStatuses = (_a = useCrudProps(apiResources.auditStatuses, {}, {}, true)[0]) == null ? void 0 : _a.data;
  const notificationEvents = (_b = useCrudProps(apiResources.auditEntriesEvents, {}, {}, true)[0]) == null ? void 0 : _b.data;
  const notificationEventsReformatted = flatMap(getArrayDataContent(notificationEvents), (group) => group.events);
  const tableRefreshRef = useRef(null);
  const classes = useStyles();
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      titleActions: /* @__PURE__ */ jsx(
        AddNotificationDialog,
        {
          onSuccess: () => {
            var _a2;
            registerAction("Create");
            (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
          }
        },
        "add"
      ),
      exportResource: { resource: apiResources.notificationExport, filename: "notification" },
      resource: apiResources.notification,
      title: "tesseract.logs.pageTitle",
      subtitle: "tesseract.logs.notifications",
      defaultSort: { field: "name", dir: "asc" },
      minCellWidth: 160,
      refreshRef: tableRefreshRef,
      actions: /* @__PURE__ */ jsx(TableAction, { left: true, children: /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.message, children: translate("tesseract.logs.notificationsMessage") }) }),
      children: [
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            isNotification: true,
            source: "status",
            tooltipSource: "statusName",
            label: "tesseract.settings.tabs.notification.labels.status",
            width: 128,
            filter: "selectarray",
            filterProps: {
              choices: getArrayDataContent(notificationStatuses)
            },
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.settings.tabs.notification.labels.name",
            cellClassName: classes.name,
            filter: "text",
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "eventNames",
            label: "tesseract.settings.tabs.notification.labels.events",
            maxChips: 7,
            filter: "selectarray",
            filterProps: {
              choices: notificationEventsReformatted
            },
            columnProps: {
              field: "event"
            }
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "administrators",
            label: "tesseract.settings.tabs.notification.labels.administrators",
            maxChips: 2,
            filter: "text",
            columnProps: {
              field: "administrator"
            }
          }
        ),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            cellClassName: classes.rowActions,
            label: "tesseract.settings.tabs.notification.labels.actions",
            width: 120,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(
                EditNotificationDialog,
                {
                  id: (data) => data.id,
                  onSuccess: () => {
                    var _a2;
                    registerAction("Update");
                    (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                  }
                }
              ),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource: apiResources.notification,
                  title: "tesseract.settings.tabs.notification.delete",
                  message: "tesseract.settings.tabs.notification.action.deleteNotification",
                  onSuccess: () => {
                    var _a2;
                    registerAction("Delete");
                    (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                  }
                }
              )
            ]
          }
        )
      ]
    }
  );
};
var NotificationList_default = NotificationList;
export {
  NotificationList,
  NotificationList_default as default,
  styles
};
