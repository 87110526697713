import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { createStyles } from "@mui/styles";
import { get } from "lodash";
import { useCrudProps } from "../../../hooks";
import Text from "../TextInput/Text";
import File from "../FileInput/File";
import classNames from "classnames";
import { DialogBody } from "../../dialog";
import IconPreview from "./IconPreview";
import { Button, Grid, Typography } from "@barracuda-internal/bds-core";
import Toolbar from "../../layout/Toolbar/Toolbar";
import { Trans, useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { Boolean, InputLabel } from "../index";
const styles = (theme) => createStyles({
  search: {
    width: `${(50 + parseInt(theme.spacing(1))) * 5}px`,
    marginLeft: theme.spacing(1)
  },
  iconSelector: {
    width: `${(50 + parseInt(theme.spacing(1))) * 5}px`,
    height: "auto",
    flexBasis: `${(44 + parseInt(theme.spacing(1))) * 4}px`,
    flexGrow: 0,
    flexShrink: 0,
    margin: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap"
  },
  iconContainer: {
    margin: theme.spacing(0.5),
    height: 44,
    width: 44
  },
  iconContainerDisabled: {
    margin: theme.spacing(0.5),
    height: 44,
    width: 44,
    opacity: 0.5
  },
  iconPreview: {
    border: "solid 2px rgba(0,0,0,0)",
    "&:hover": {
      borderColor: "red",
      borderRadius: 8
    }
  },
  iconSelected: {
    borderColor: "blue",
    borderRadius: 8
  },
  previewIcon: {
    marginLeft: theme.spacing(1)
  },
  previewIconDisabled: {
    marginLeft: theme.spacing(1),
    opacity: 0.5
  },
  iconSelectorText: {
    margin: theme.spacing(2),
    color: "#646464"
  },
  searchContainer: {
    display: "flex",
    alignItems: "flex-start",
    margin: theme.spacing(0, 4, 1, 4),
    justifyContent: "center",
    borderBottom: "1px solid #DDDDDD"
  },
  searchContainerInner: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column"
  },
  searchText: {
    margin: theme.spacing(1, 1, 0, 2),
    color: "#676767"
  },
  customInput: {
    width: "100%"
  },
  customInputHelp: {
    display: "none !important"
  },
  customInputLabel: {
    width: "150px !important"
  },
  fileSelector: {
    display: "inline-flex"
  }
});
const useStyles = makeOverrideableStyles("IconBrowser", styles);
const getIcons = (iconResponse) => {
  const icons = Object.values(get(iconResponse, "categories", {})).flatMap((subicons) => subicons);
  return [...icons, ...get(iconResponse, "uncategorized", [])];
};
const IconBrowser = (props) => {
  var _a, _b;
  const [selectedIcon, setSelectedIcon] = useState({ filename: "", data: "" });
  const [search, setSearch] = useState("");
  const [isEnabled, setIsEnabled] = useState(false);
  const iconData = useCrudProps("https://api.iconify.design/collection", { filter: { prefix: "logos" } })[0].data;
  const icons = getIcons(iconData).filter((icon) => icon.includes(search)).slice(0, 15);
  const classes = useStyles(props);
  const [translate] = useTranslation();
  useEffect(() => {
    var _a2, _b2, _c, _d;
    setSelectedIcon(props.value || {});
    setIsEnabled(!!((_a2 = props.value) == null ? void 0 : _a2.filename) && !((_d = (_c = (_b2 = props.value) == null ? void 0 : _b2.filename) == null ? void 0 : _c.startsWith) == null ? void 0 : _d.call(_c, "//ICON::")));
  }, [props.value]);
  return /* @__PURE__ */ jsxs(DialogBody, { title: "Choose Icon", onClose: props.onClose, children: [
    /* @__PURE__ */ jsx(
      Typography,
      {
        variant: "subtitle1",
        className: classes.iconSelectorText,
        children: /* @__PURE__ */ jsx(Trans, { i18nKey: "cuda.inputs.IconBrowser.iconSelectorText", children: /* @__PURE__ */ jsx("br", {}) })
      }
    ),
    /* @__PURE__ */ jsx(
      InputLabel,
      {
        label: "cuda.inputs.IconBrowser.searchText",
        classes: { root: classes.customInput, inputHelp: classes.customInputHelp, inputLabel: classes.customInputLabel },
        children: /* @__PURE__ */ jsxs("div", { className: classes.searchContainerInner, children: [
          /* @__PURE__ */ jsx(
            Text,
            {
              value: search,
              onChange: (event) => {
                var _a2;
                return setSearch(((_a2 = event == null ? void 0 : event.target) == null ? void 0 : _a2.value) || "");
              },
              id: "search",
              placeholder: "cuda.inputs.IconBrowser.placeholderText",
              classes: { textField: classes.search },
              disabled: isEnabled
            }
          ),
          /* @__PURE__ */ jsx("div", { className: classes.iconSelector, children: (icons == null ? void 0 : icons.length) > 0 ? icons.map((icon) => /* @__PURE__ */ jsx(
            Grid,
            {
              item: true,
              onClick: isEnabled ? void 0 : () => {
                if (selectedIcon === `//ICON::${icon}.svg`) {
                  setSelectedIcon({});
                } else {
                  fetch(`https://api.iconify.design/logos/${icon}.svg?width=40&height=40`).then((response) => response.text()).then((svgData) => {
                    setSelectedIcon({ filename: `//ICON::${icon}.svg`, data: btoa(svgData) });
                  }).catch(() => {
                  });
                }
              },
              className: isEnabled ? classes.iconContainerDisabled : classes.iconContainer,
              children: /* @__PURE__ */ jsx(
                "img",
                {
                  src: `https://api.iconify.design/logos/${icon}.svg?width=40&height=40`,
                  alt: icon,
                  title: icon,
                  className: classNames(classes.iconPreview, `//ICON::${icon}.svg` === selectedIcon.filename && classes.iconSelected)
                }
              )
            },
            icon
          )) : /* @__PURE__ */ jsx(Grid, { item: true, children: translate("cuda.inputs.IconBrowser.noIcons") }) })
        ] })
      }
    ),
    /* @__PURE__ */ jsx(
      InputLabel,
      {
        label: "cuda.inputs.IconBrowser.useCustomText",
        classes: { root: classes.customInput, inputHelp: classes.customInputHelp, inputLabel: classes.customInputLabel },
        children: /* @__PURE__ */ jsx(Boolean, { id: "", value: isEnabled, onChange: (value) => setIsEnabled(!!value) })
      }
    ),
    /* @__PURE__ */ jsx(
      InputLabel,
      {
        label: "cuda.inputs.IconBrowser.customApplicationText",
        classes: { root: classes.customInput, inputHelp: classes.customInputHelp, inputLabel: classes.customInputLabel },
        children: /* @__PURE__ */ jsxs("div", { className: classes.fileSelector, children: [
          /* @__PURE__ */ jsx(
            File,
            {
              value: ((_b = (_a = selectedIcon == null ? void 0 : selectedIcon.filename) == null ? void 0 : _a.startsWith) == null ? void 0 : _b.call(_a, "//ICON::")) ? {} : selectedIcon,
              onChange: setSelectedIcon,
              id: props.id,
              showDelete: true,
              disabled: !isEnabled
            }
          ),
          /* @__PURE__ */ jsx(
            IconPreview,
            {
              icon: selectedIcon.data,
              filename: selectedIcon.filename,
              supportedFiles: props.supportedFiles,
              name: props.name,
              size: 40,
              classes: {
                initialsIcon: isEnabled ? classes.previewIcon : classes.previewIconDisabled,
                iconImage: isEnabled ? classes.previewIcon : classes.previewIconDisabled
              }
            }
          )
        ] })
      }
    ),
    /* @__PURE__ */ jsxs(Toolbar, { children: [
      /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          color: "primary",
          size: "small",
          onClick: () => {
            var _a2, _b2;
            (_a2 = props.onChange) == null ? void 0 : _a2.call(props, selectedIcon);
            (_b2 = props.onClose) == null ? void 0 : _b2.call(props);
          },
          children: translate("cuda.action.confirm")
        }
      ),
      /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          color: "secondary",
          size: "small",
          onClick: props.onClose,
          children: translate("cuda.action.cancel")
        }
      )
    ] })
  ] });
};
var IconBrowser_default = IconBrowser;
export {
  IconBrowser_default as default
};
