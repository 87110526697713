import { jsx } from "react/jsx-runtime";
import { CardSizeOptions } from "../../../typesAndConstants";
import apiResources from "../../../apiResources";
import { TableCardContent, TextField } from "@cuda-react/core";
import { BaseCard } from "../../baseCards";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  cardContent: {
    height: "calc(100% - 50px)",
    overflowY: "hidden"
  }
});
const UserDevicesCard = (props) => {
  var _a;
  const { size } = props;
  const classes = useStyles(props);
  const userDeviceTableColumns = [
    /* @__PURE__ */ jsx(TextField, { source: "brand", label: "stratosReporting.userDevicesCard.table.brand" }, "brand"),
    /* @__PURE__ */ jsx(TextField, { source: "hardwareModel", label: "stratosReporting.userDevicesCard.table.hardwareModel" }, "hardwareModel"),
    /* @__PURE__ */ jsx(TextField, { source: "enrollmentDate", label: "stratosReporting.userDevicesCard.table.enrollmentDate" }, "enrollmentDate"),
    /* @__PURE__ */ jsx(TextField, { source: "operatingSystem", label: "stratosReporting.userDevicesCard.table.operatingSystem", hidden: true }, "operatingSystem"),
    /* @__PURE__ */ jsx(TextField, { source: "operatingSystemVersion", label: "stratosReporting.userDevicesCard.table.operatingSystemVersion", hidden: true }, "operatingSystemVersion")
  ];
  const userDeviceData = userDeviceTableColumns;
  return /* @__PURE__ */ jsx(BaseCard, { title: "stratosReporting.userDevicesCard.title", size, classes: { cardContent: classes.cardContent }, children: /* @__PURE__ */ jsx(
    TableCardContent,
    {
      resource: apiResources.userDevices,
      pollInterval: 3e5,
      params: { filter: { userId: (_a = props == null ? void 0 : props.additionalProps) == null ? void 0 : _a.id } },
      maxHeight: CardSizeOptions[size].height - 50,
      children: userDeviceData
    }
  ) });
};
export {
  UserDevicesCard
};
