import { parse, stringify } from "query-string";
const parseQueryString = (queryString) => {
  const query = parse(queryString);
  if (query.filter && typeof query.filter === "string") {
    query.filter = JSON.parse(query.filter);
  }
  return query;
};
const generateQueryString = (filter = {}, sortField, sortOrder, pageNumber, resultsPerPage) => {
  const reformatQuery = {
    filter: filter && Object.keys(filter).length > 0 && JSON.stringify(filter) || void 0,
    sort: sortField,
    dir: sortOrder,
    pageStart: pageNumber,
    perPage: resultsPerPage
  };
  return `?${stringify(reformatQuery)}`;
};
export {
  generateQueryString,
  parseQueryString
};
