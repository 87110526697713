import { jsx, jsxs } from "react/jsx-runtime";
import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material";
const AppCategoriesIcon = (props) => {
  var _a, _b;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(SvgIcon, { viewBox: "2 2 21 21", style: { width: (_a = props.size) != null ? _a : 50, height: (_b = props.size) != null ? _b : 50 }, children: /* @__PURE__ */ jsxs("g", { transform: "matrix(1,0,0,1,-5.42152,-5.995)", children: [
    /* @__PURE__ */ jsx("rect", { x: "5.422", y: "5.995", width: "24.026", height: "24.026", style: { fill: "none" } }),
    /* @__PURE__ */ jsx("g", { transform: "matrix(1,0,0,1,7.30857,9.30195)", children: /* @__PURE__ */ jsx(
      "path",
      {
        d: "M7.296,2.706L9.296,4.706L18.126,4.706L18.126,14.706L2.126,14.706L2.126,2.706L7.296,2.706ZM8.126,0.706L2.126,0.706C1.026,0.706 0.136,1.606 0.136,2.706L0.126,14.706C0.126,15.806 1.026,16.706 2.126,16.706L18.126,16.706C19.226,16.706 20.126,15.806 20.126,14.706L20.126,4.706C20.126,3.606 19.226,2.706 18.126,2.706L10.126,2.706L8.126,0.706Z",
        style: { fill: theme.palette.reporting.fontColor, fillRule: "nonzero" }
      }
    ) })
  ] }) });
};
export {
  AppCategoriesIcon
};
