const dataGenerator = () => ({
  total: 597,
  countries: {
    "AU": 51,
    "AT": 32,
    "DE": 13,
    "NL": 4,
    "GB": 65,
    "US": 5,
    "SK": 95,
    "CZ": 190,
    "HU": 79,
    "TR": 35,
    "ZZ": 28
  }
});
var ztnaUserCountries_default = dataGenerator;
export {
  ztnaUserCountries_default as default
};
