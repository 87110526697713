var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { ConnectedWizard, InputStep, ResultStep, SubmitStep, useGlobalParam } from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import BasicPolicyInputs, { defaultPolicy } from "./BasicPolicyInputs";
import DeviceAttributesInputs, { defaultDeviceAttributes, validateDeviceAttributes } from "./DeviceAttributesInputs";
import { formatError, formatRequestData } from "./FormattingMethods";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../../apiResources";
import { get } from "lodash";
const useStyles = makeStyles({
  dialogContent: {
    width: "auto"
  },
  stepContent: {
    maxHeight: "calc(90vh - 195px)",
    overflowY: "auto",
    width: 730
  }
});
const CreatePolicy = ({ onClose, onSuccess }) => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const [securityPosture, setSecurityPosture] = useState();
  return /* @__PURE__ */ jsxs(
    ConnectedWizard,
    {
      title: "tesseract.endpoint.remoteAccessPolicies.create",
      resource: apiResources.zeroTrustPolicies,
      formatRequestData: (data) => __spreadProps(__spreadValues({}, formatRequestData(data)), {
        virtualWanId
      }),
      initialValues: __spreadValues(__spreadValues({}, defaultPolicy), defaultDeviceAttributes),
      formatError,
      onClose,
      onSubmitSuccess: onSuccess,
      dialogBodyProps: { classes: { dialogContent: classes.dialogContent } },
      onChange: (values) => {
        setSecurityPosture(get(values, "accessCriteria.securityPosture"));
      },
      validate: validateDeviceAttributes(translate),
      create: true,
      children: [
        /* @__PURE__ */ jsx(
          InputStep,
          {
            label: "tesseract.endpoint.remoteAccessPolicies.policy",
            submit: securityPosture === "disable",
            classes: { root: classes.stepContent },
            children: /* @__PURE__ */ jsx(BasicPolicyInputs, {})
          }
        ),
        securityPosture !== "disable" ? /* @__PURE__ */ jsx(
          SubmitStep,
          {
            label: "tesseract.endpoint.remoteAccessPolicies.deviceAttributes",
            classes: { root: classes.stepContent },
            children: /* @__PURE__ */ jsx(DeviceAttributesInputs, {})
          }
        ) : null,
        /* @__PURE__ */ jsx(
          ResultStep,
          {
            label: "tesseract.endpoint.remoteAccessPolicies.success",
            onClose,
            disableDirectNavigation: true,
            children: /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.endpoint.remoteAccessPolicies.successMessage") })
          }
        )
      ]
    }
  );
};
var CreatePolicy_default = CreatePolicy;
export {
  CreatePolicy,
  CreatePolicy_default as default
};
