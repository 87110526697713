import { jsx, jsxs } from "react/jsx-runtime";
import { Button, ClickAwayListener, Collapse, Grid, IconButton, Menu, MenuItem } from "@barracuda-internal/bds-core";
import { Close, Filter } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { isEmpty, sortBy } from "lodash";
import React, { useCallback, useState } from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useTranslation } from "react-i18next";
import { useDeepCompareCallback } from "../../../hooks";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  buttonBar: {
    width: "100%",
    margin: theme.spacing(1, 3, 0, 1)
  },
  filterButton: {
    right: theme.spacing(1)
  },
  list: {},
  leftRightSeperator: {
    marginLeft: "auto"
  },
  filterBar: {
    position: "relative",
    display: "block",
    marginLeft: "auto",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  filterContainer: {
    width: "auto",
    marginLeft: theme.spacing(1)
  },
  filterField: {
    marginTop: theme.spacing(1),
    verticalAlign: "bottom",
    width: 256
  },
  filterFieldInput: {
    backgroundColor: theme.palette.background.paper
  },
  filterRemoveIcon: {
    marginBottom: 2
  },
  buttonLabel: {
    whiteSpace: "nowrap"
  },
  actionFullWidth: {
    width: "100%"
  }
});
const useStyles = makeOverrideableStyles("TableActionsAndFilters", styles);
const TableActionsAndFilters = (props) => {
  const { onChange, filters, filterValues, actions } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const [filterListOpen, setFilterListOpen] = useState(null);
  const activeFilters = Object.keys(filterValues || {});
  const filterArray = filters && sortBy(
    filters.filter((filter) => !!filter && "props" in filter),
    (filter) => filter.props.label && translate(filter.props.label)
  );
  const handleChange = useDeepCompareCallback((eventOrValue, source) => {
    const value = eventOrValue && eventOrValue.target ? eventOrValue.target.value : eventOrValue;
    const newValues = Object.assign({}, filterValues, { [source]: isEmpty(value) ? void 0 : value });
    onChange == null ? void 0 : onChange(newValues);
  }, [filterValues]);
  const addFilter = useDeepCompareCallback((source) => {
    handleChange("", source);
    setFilterListOpen(null);
  }, [filterValues]);
  const removeFilter = useDeepCompareCallback((source) => {
    const newValues = Object.assign({}, filterValues);
    delete newValues[source];
    onChange == null ? void 0 : onChange(newValues);
  }, [filterValues]);
  const clearFilters = useCallback(() => {
    onChange == null ? void 0 : onChange({});
  }, []);
  const renderFilter = (targetChild) => /* @__PURE__ */ jsxs(Grid, { item: true, container: true, alignItems: "flex-end", className: classes.filterContainer, children: [
    /* @__PURE__ */ jsx(Grid, { item: true, children: React.cloneElement(targetChild, {
      id: "cuda-filter-input-" + targetChild.props.source.replace(/[^a-zA-Z0-9]/g, "-"),
      onChange: (eventOrValue) => handleChange(eventOrValue, targetChild.props.source),
      value: (filterValues == null ? void 0 : filterValues[targetChild.props.source]) || "",
      label: targetChild.props.label && translate(targetChild.props.label),
      variant: "outlined",
      className: classes.filterField,
      InputProps: { classes: { root: classes.filterFieldInput } }
    }) }),
    /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(
      IconButton,
      {
        size: "small",
        className: classes.filterRemoveIcon,
        onClick: () => removeFilter(targetChild.props.source),
        children: /* @__PURE__ */ jsx(Close, {})
      }
    ) })
  ] }, targetChild.props.source);
  const showFilters = Object.keys(activeFilters || {}).length > 0;
  const arrayActions = actions && (Array.isArray(actions) ? actions : [actions]);
  const leftActions = arrayActions && arrayActions.filter((action) => {
    var _a;
    return action && ((_a = action == null ? void 0 : action.props) == null ? void 0 : _a.left);
  });
  const rightActions = arrayActions && arrayActions.filter((action) => {
    var _a;
    return action && !((_a = action == null ? void 0 : action.props) == null ? void 0 : _a.left);
  });
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsxs(
      Grid,
      {
        container: true,
        spacing: 1,
        className: classes.buttonBar,
        direction: "row-reverse",
        alignItems: "center",
        wrap: "wrap-reverse",
        children: [
          rightActions && rightActions.map((action, index) => {
            var _a;
            return /* @__PURE__ */ jsx(Grid, { item: true, className: ((_a = action == null ? void 0 : action.props) == null ? void 0 : _a.fullWidth) ? classes.actionFullWidth : void 0, children: action }, index);
          }) || null,
          filterArray && filterArray.length > 0 && /* @__PURE__ */ jsxs(React.Fragment, { children: [
            /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(ClickAwayListener, { onClickAway: () => setFilterListOpen(null), children: /* @__PURE__ */ jsxs(React.Fragment, { children: [
              /* @__PURE__ */ jsx(
                Button,
                {
                  className: classes.filterButton,
                  startIcon: /* @__PURE__ */ jsx(Filter, {}),
                  color: "secondary",
                  variant: "contained",
                  size: "small",
                  onClick: (event) => setFilterListOpen(event.currentTarget),
                  disabled: filterArray.length === activeFilters.length,
                  children: translate("cuda.buttons.filters.add")
                }
              ),
              /* @__PURE__ */ jsx(
                Menu,
                {
                  className: classes.list,
                  anchorEl: filterListOpen,
                  open: !!filterListOpen,
                  onClose: () => setFilterListOpen(null),
                  children: filterArray.filter((filter) => !activeFilters.includes(filter.props.source)).map((filter) => /* @__PURE__ */ jsx(
                    MenuItem,
                    {
                      onClick: () => addFilter(filter.props.source),
                      children: filter.props.label && translate(filter.props.label)
                    },
                    filter.props.source
                  ))
                }
              )
            ] }) }) }),
            showFilters ? /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(
              Button,
              {
                onClick: () => clearFilters(),
                startIcon: /* @__PURE__ */ jsx(Close, {}),
                color: "secondary",
                variant: "contained",
                size: "small",
                className: classes.filterButton,
                classes: { root: classes.buttonLabel },
                children: translate("cuda.buttons.filters.clear")
              }
            ) }) : null
          ] }),
          /* @__PURE__ */ jsx("div", { className: classes.leftRightSeperator }),
          leftActions && leftActions.map((action, index) => {
            var _a;
            return /* @__PURE__ */ jsx(Grid, { item: true, className: ((_a = action == null ? void 0 : action.props) == null ? void 0 : _a.fullWidth) ? classes.actionFullWidth : void 0, children: action }, index);
          }) || null
        ]
      }
    ),
    filterArray && /* @__PURE__ */ jsx(
      Collapse,
      {
        className: classes.filterBar,
        in: showFilters,
        children: /* @__PURE__ */ jsx(Grid, { container: true, alignItems: "flex-end", justifyContent: "flex-end", spacing: 1, children: filterArray.filter((filter) => activeFilters.includes(filter.props.source)).map(renderFilter) })
      }
    )
  ] });
};
var TableActionsAndFilters_default = TableActionsAndFilters;
export {
  TableActionsAndFilters_default as default
};
