var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { getArrayDataContent, useCrudProps } from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import { useTranslation } from "react-i18next";
const useGatewaysSites = () => {
  var _a, _b;
  const sites = getArrayDataContent((_a = useCrudProps(apiResources.ipsecApplicableSitesWans, { filter: { modelSeries: "T" } })[0]) == null ? void 0 : _a.data).filter(({ wans }) => (wans == null ? void 0 : wans.length) > 0);
  const gateways = getArrayDataContent((_b = useCrudProps(apiResources.ipsecApplicableGatewaysWans, { filter: { type: "on-prem" } })[0]) == null ? void 0 : _b.data).map((gateway) => {
    var _a2;
    return __spreadProps(__spreadValues({}, gateway), {
      cloud: !(((_a2 = gateway == null ? void 0 : gateway.wans) == null ? void 0 : _a2.length) > 0)
    });
  });
  const [translate] = useTranslation();
  const validate = (data) => {
    var _a2;
    const cloudSelected = (_a2 = gateways.find(({ uuid }) => {
      var _a3;
      return ((_a3 = data == null ? void 0 : data.local) == null ? void 0 : _a3.uuid) === uuid;
    })) == null ? void 0 : _a2.cloud;
    if (cloudSelected && (data == null ? void 0 : data.initiate)) {
      return {
        initiate: translate("tesseract.integration.ipSecV2.cloudCantInitiate"),
        local: {
          uuid: translate("tesseract.integration.ipSecV2.cloudCantInitiate")
        }
      };
    }
    return {};
  };
  return { gateways, sites, validate };
};
export {
  useGatewaysSites
};
