import { jsx, jsxs } from "react/jsx-runtime";
import { Button, Typography } from "@barracuda-internal/bds-core";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCrudFetch, useDeepCompareEffect } from "../../../hooks";
import { CrudTypes } from "../../../clients";
import DialogBody from "../DialogBody/DialogBody";
import Toolbar from "../../layout/Toolbar/Toolbar";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = createStyles({
  body: {
    textAlign: "center",
    padding: 16,
    maxWidth: 600
  }
});
const useStyles = makeOverrideableStyles("ActionDialog", styles);
const ActionDialogContent = (props) => {
  const { title = "cuda.action.title", data, resource, params, crudType = CrudTypes.ACTION, message = "cuda.action.message", onClose, onConfirm, onSuccess } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [actionData, actionLoading, performAction] = useCrudFetch(crudType, resource, typeof params === "function" ? params(data) : params);
  useDeepCompareEffect(() => {
    if (submitted && !actionLoading && actionData && actionData.data !== void 0 && !actionData.error) {
      onClose == null ? void 0 : onClose();
      onSuccess == null ? void 0 : onSuccess();
    }
  }, [actionLoading, actionData]);
  const handleConfirm = () => {
    resource && performAction();
    setSubmitted(true);
    if (onConfirm == null ? void 0 : onConfirm()) {
      onSuccess == null ? void 0 : onSuccess();
      onClose == null ? void 0 : onClose();
    }
  };
  let renderedMessage = typeof message === "function" ? message(data) : message;
  if (typeof renderedMessage === "string") {
    renderedMessage = /* @__PURE__ */ jsx(Typography, { variant: "body1", children: translate(renderedMessage) });
  }
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      DialogBody,
      {
        title: translate(typeof title === "function" ? title(data) : title),
        children: /* @__PURE__ */ jsx(Container, { className: classes.body, children: renderedMessage })
      }
    ),
    /* @__PURE__ */ jsxs(Toolbar, { children: [
      /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          color: "primary",
          size: "small",
          startIcon: actionLoading ? /* @__PURE__ */ jsx(CircularProgress, { id: "cuda-loading-spinner", size: 16, thickness: 2 }) : void 0,
          disabled: actionLoading,
          onClick: handleConfirm,
          children: translate("cuda.action.confirm")
        }
      ),
      /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          color: "secondary",
          size: "small",
          disabled: actionLoading,
          onClick: onClose,
          children: translate("cuda.action.cancel")
        }
      )
    ] })
  ] });
};
var ActionDialogContent_default = ActionDialogContent;
export {
  ActionDialogContent,
  ActionDialogContent_default as default
};
