var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import React from "react";
import { Critical, Success, Warning } from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import { get } from "lodash";
import { Help } from "../";
const StatusIcon = (_a) => {
  var _b = _a, { icon, iconMap, status } = _b, props = __objRest(_b, ["icon", "iconMap", "status"]);
  if (icon) {
    return React.cloneElement(icon, __spreadProps(__spreadValues({}, props), { status }));
  }
  return React.cloneElement(get(iconMap, status, iconMap == null ? void 0 : iconMap["default"]) || /* @__PURE__ */ jsx(Help, {}), __spreadValues({}, props));
};
StatusIcon.defaultProps = {
  iconMap: {
    //@ts-ignore it complains about using boolean as an index, but this seems to work fine (and is needed)
    [true]: /* @__PURE__ */ jsx(Success, { id: "cuda-icon-okay", sx: { color: "success.main" } }),
    ok: /* @__PURE__ */ jsx(Success, { id: "cuda-icon-okay", sx: { color: "success.main" } }),
    warning: /* @__PURE__ */ jsx(Warning, { sx: { color: "warning.main" } }),
    //@ts-ignore it complains about using boolean as an index, but this seems to work fine (and is needed)
    [false]: /* @__PURE__ */ jsx(Critical, { sx: { color: "error.main" } }),
    error: /* @__PURE__ */ jsx(Critical, { sx: { color: "error.main" } }),
    errorWarning: /* @__PURE__ */ jsx(Warning, { sx: { color: "error.main" } }),
    //@ts-ignore it complains about sx TODO: fix this (or remove the sx line)
    default: /* @__PURE__ */ jsx(Help, { sx: { color: "neutral.main" } })
  }
};
var StatusIcon_default = StatusIcon;
export {
  StatusIcon,
  StatusIcon_default as default
};
