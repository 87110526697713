import { jsx, jsxs } from "react/jsx-runtime";
import {
  BasicPage,
  CrudTypes,
  FormSection,
  getDataContent,
  Tab,
  Tabs,
  useCrudSubscription,
  useMixpanel
} from "@cuda-react/core";
import SslInterceptionCertificates from "./certificates/SslInterceptionCertificates";
import SslInterceptionCAs from "./certificates/SslInterceptionCAs";
import SslInterceptionExplicit from "./explicit/SslInterceptionExplicit";
import { Paper } from "@barracuda-internal/bds-core";
import apiResources from "../../../../../apiResources";
const SslInterception = () => {
  useMixpanel("SSL Inspection");
  const [enabledData, enabledLoading, refreshEnabled] = useCrudSubscription(CrudTypes.GET, apiResources.sslInspectionEnabled);
  const enabled = getDataContent(enabledData.data, false);
  return /* @__PURE__ */ jsx(
    BasicPage,
    {
      title: "tesseract.securityAndAccess.pageTitle",
      subtitle: ["tesseract.securityAndAccess.security", "tesseract.securityAndAccess.ssl"],
      authenticated: true,
      children: /* @__PURE__ */ jsx(Paper, { children: /* @__PURE__ */ jsxs(Tabs, { children: [
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.security.sslInterception.settings.explicitTabTitle", children: /* @__PURE__ */ jsx(SslInterceptionExplicit, { enabled, enabledLoading, refreshEnabled }) }),
        /* @__PURE__ */ jsxs(Tab, { label: "tesseract.security.sslInterception.settings.tabTitle", children: [
          /* @__PURE__ */ jsx(FormSection, { title: "tesseract.security.sslInterception.settings.sslCertificates", children: /* @__PURE__ */ jsx(SslInterceptionCertificates, { enabled }) }),
          /* @__PURE__ */ jsx(FormSection, { title: "tesseract.security.sslInterception.settings.sslCertificateAuthorities", children: /* @__PURE__ */ jsx(SslInterceptionCAs, {}) })
        ] })
      ] }) })
    }
  );
};
var SslInterception_default = SslInterception;
export {
  SslInterception_default as default
};
