var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { generateQueryString, parseQueryString, useDeepCompareEffect, usePersistedState } from "@cuda-react/core";
import { timeframes } from "./useFilterOptions";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { get, unset } from "lodash";
var useFilterState_default = (presetAppliance) => {
  const [applianceId, setApplianceId] = usePersistedState("dashboardSelectedAppliance", (presetAppliance == null ? void 0 : presetAppliance.key) || "_NONE_");
  const [timeframe, setTimeframe] = usePersistedState("dashboardSelectedTimeframe", timeframes[0].key);
  const [otherFilters, setOtherFilters] = useState({});
  const { location, replace } = useHistory();
  useEffect(() => {
    try {
      const initialFilters = get(parseQueryString(location.search), "filter", {});
      const _a = initialFilters, { applianceId: applianceId2, timeframe: timeframe2 } = _a, otherFilters2 = __objRest(_a, ["applianceId", "timeframe"]);
      applianceId2 && setApplianceId(applianceId2);
      timeframe2 && setTimeframe(timeframe2);
      Object.keys(otherFilters2).length > 0 && setOtherFilters(otherFilters2);
    } catch (e) {
    }
  }, []);
  useDeepCompareEffect(() => {
    const search = generateQueryString(__spreadValues({
      applianceId,
      timeframe
    }, otherFilters));
    if (search !== location.search) {
      replace(__spreadProps(__spreadValues({}, location), {
        search
      }));
    }
  }, [applianceId, timeframe, otherFilters]);
  const updateFilter = (filterValue, filterId) => {
    if (filterId === "applianceId") {
      setApplianceId(filterValue);
    } else if (filterId === "timeframe") {
      setTimeframe(filterValue);
    } else {
      setOtherFilters((state) => __spreadProps(__spreadValues({}, state), {
        [filterId]: filterValue
      }));
    }
  };
  const removeFilter = (filterId, filterValue) => {
    setOtherFilters((state) => {
      const newState = __spreadValues({}, state);
      unset(newState, filterId);
      return newState;
    });
  };
  const clearAllFilters = () => {
    setOtherFilters({});
  };
  return {
    activeFilters: __spreadValues({ applianceId, timeframe }, otherFilters),
    updateFilter,
    removeFilter,
    clearAllFilters
  };
};
export {
  useFilterState_default as default
};
