var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { get } from "lodash";
const getLinkedPoints = (chart, point) => {
  var _a;
  if (get(point.pointData, "linkedSeriesParent")) {
    return [__spreadProps(__spreadValues({}, (_a = chart.current) == null ? void 0 : _a.fromLatLonToPoint({
      lat: get(point.pointData, "linkedSeriesParent.latitude"),
      lon: get(point.pointData, "linkedSeriesParent.longitude")
    })), {
      source: get(point.pointData, "linkedSeriesParent")
    })];
  }
  if (get(point.pointData, "linkedSeriesSources")) {
    const linkedPointData = get(point.pointData, "linkedSeriesSources").flatMap((source) => get(point.pointData, source)).filter((val) => val);
    return linkedPointData.map((point2) => {
      var _a2;
      return __spreadProps(__spreadValues({}, (_a2 = chart.current) == null ? void 0 : _a2.fromLatLonToPoint({
        lat: point2.latitude,
        lon: point2.longitude
      })), {
        source: point2
      });
    });
  }
  return [];
};
export {
  getLinkedPoints
};
