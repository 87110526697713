var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { MenuButton } from "@cuda-react/core";
import PropTypes from "prop-types";
import { Dialog } from "@barracuda-internal/bds-core";
import RestartIpsecTunnel from "./RestartIpsecTunnel";
const Ipsecv2TableMenu = (props) => {
  const { data, onSuccess } = props;
  const [restartDialogOpen, setRestartDialogOpen] = useState(false);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      MenuButton,
      {
        anchorOrigin: { horizontal: "left", vertical: "center" },
        transformOrigin: { horizontal: "left", vertical: "center" },
        menuItems: [
          {
            label: "tesseract.integration.ipSecV2.table.ellipsisMenu.restart",
            onClick: () => setRestartDialogOpen(true)
          }
        ],
        iconButton: true
      }
    ),
    /* @__PURE__ */ jsx(Dialog, { open: restartDialogOpen, maxWidth: false, onClose: () => setRestartDialogOpen(false), children: /* @__PURE__ */ jsx(
      RestartIpsecTunnel,
      __spreadProps(__spreadValues({}, props), {
        resourceName: data.name,
        resourceId: data.id,
        onSuccess,
        onClose: () => setRestartDialogOpen(false)
      })
    ) })
  ] });
};
Ipsecv2TableMenu.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })
};
var Ipsecv2TableMenu_default = Ipsecv2TableMenu;
export {
  Ipsecv2TableMenu_default as default
};
