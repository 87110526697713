var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useCallback, useState } from "react";
import {
  ConnectedWizard,
  InputStep,
  MultiStep,
  ResultStep,
  SubmitStep,
  useGlobalParam,
  useMixpanel
} from "@cuda-react/core";
import { get, set } from "lodash";
import { useTranslation } from "react-i18next";
import { formatSCErrors, validateNames, validateNetworks } from "../../../hooks/createEditSiteHooks";
import BasicStep from "./steps/common/BasicStep";
import SerialStep from "./steps/common/SerialStep";
import WanStep from "./steps/sc/WanStep";
import LanStep from "./steps/sc/LanStep";
import AdvancedStep from "./steps/common/AdvancedStep";
import SiteSubmitStep from "./steps/common/SiteSubmitStep";
import SiteResultStep from "./steps/sc/SiteResultStep";
import { makeStyles } from "@mui/styles";
import SelectWanFallbackStep from "./steps/sc/SelectWanFallbackStep";
import SelectLanLengthStep from "./steps/sc/SelectLanLengthStep";
import apiResources from "../../../apiResources";
const useStyles = makeStyles((theme) => ({
  root: {},
  dialogContent: {
    width: 850
  },
  standardInputStep: {
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(8)
  },
  submitStep: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8)
  }
}));
const initialValues = {
  wans: [{ port: "p4", name: "ISP-Uplink-1" }, { name: "ISP-Uplink-2" }],
  wanTotal: "1",
  lanTotal: "1",
  wanFailover: false
};
const CreateSCContent = ({ onClose, onSuccess }) => {
  const registerAction = useMixpanel("Sites Create Site", "Start", { type: "SC" });
  const [translate] = useTranslation();
  const classes = useStyles();
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const [{ wanTotal = 2, lanTotal = 1 }, setFormValues] = useState(initialValues);
  const onChangeHandler = useCallback((newValues) => {
    const wanTotal2 = parseInt(get(newValues, "wanTotal", 2)) || 2;
    const lanTotal2 = parseInt(get(newValues, "lanTotal", 1)) || 1;
    setFormValues({
      wanTotal: wanTotal2 > 0 ? wanTotal2 : 1,
      lanTotal: lanTotal2 > 0 ? lanTotal2 : 1
    });
  }, []);
  const onSuccessHandler = useCallback(
    (response) => {
      registerAction("Success", {
        model: response.model,
        type: "SC",
        wansLength: response.wans.length,
        lansLength: response.lans.length,
        isHa: response.serials.length > 1
      });
      onSuccess == null ? void 0 : onSuccess(response);
    },
    []
  );
  const validationHandler = useCallback((values = {}) => {
    const errors = {};
    const totalWans = parseInt(get(values, "wanTotal")) || 1;
    const totalLans = parseInt(get(values, "lanTotal")) || 1;
    const usedNetworks = validateNetworks(values, "wans", totalWans, translate, errors, void 0);
    if (errors.wans) {
      errors.wans = errors.wans.map((error) => ({ mode: error.mode || "tesseract.sites.dialog.validation.duplicateSCwan" }));
    }
    validateNetworks(values, "lans", totalLans, translate, errors, usedNetworks);
    const usedNetworkNames = validateNames(values, "wans", totalWans, translate, errors);
    validateNames(values, "lans", totalLans, translate, errors, usedNetworkNames);
    validateNames(values, "routes", void 0, translate, errors);
    values.secondaryDns && !values.primaryDns && set(errors, "secondaryDns", translate("tesseract.appliances.dialog.validation.secondaryDns"));
    return errors;
  }, []);
  const formatRequestHandler = useCallback(
    (data) => Object.assign({}, data, {
      serials: data.serials.map((appliance) => appliance.serial),
      wans: data.wans.slice(0, data.wanTotal).map((wan) => __spreadProps(__spreadValues({}, wan), {
        virtualLanId: wan.virtualLanId ? parseInt(wan.virtualLanId) : null,
        port: wan.mode !== "wwan" ? wan.port : void 0
      })),
      wifi: __spreadValues({ enabled: true }, data.lans.find((lan) => lan.port === "wifi") || { enabled: false }),
      lans: data.lans.slice(0, data.lanTotal).filter((lan) => lan.port !== "wifi").map((lan) => __spreadProps(__spreadValues({}, lan), { virtualLanId: lan.virtualLanId ? parseInt(lan.virtualLanId) : null })),
      virtualWan: virtualWanId
    }),
    []
  );
  return /* @__PURE__ */ jsxs(
    ConnectedWizard,
    {
      title: "tesseract.sites.dialog.create.sc.dialogTitle",
      dialogBodyProps: { classes: { dialogContent: classes.dialogContent } },
      resource: apiResources.siteScConfiguration,
      formatRequestData: formatRequestHandler,
      validate: validationHandler,
      initialValues,
      onChange: onChangeHandler,
      formatError: formatSCErrors,
      onClose,
      onSubmitSuccess: onSuccessHandler,
      children: [
        /* @__PURE__ */ jsx(InputStep, { label: "tesseract.appliances.dialog.basic.sectionTitle", children: /* @__PURE__ */ jsx(BasicStep, { enforceGateway: true, applianceType: "site" }) }),
        /* @__PURE__ */ jsx(InputStep, { label: translate("tesseract.appliances.dialog.serials.sectionTitle", { context: "site" }), children: /* @__PURE__ */ jsx(SerialStep, { mainMessage: "tesseract.appliances.dialog.serials.SCSiteMessage", series: "S" }) }),
        /* @__PURE__ */ jsx(MultiStep, { label: "tesseract.appliances.dialog.wans.sectionTitle", children: [
          /* @__PURE__ */ jsx(InputStep, { children: /* @__PURE__ */ jsx(SelectWanFallbackStep, {}) }, "mainInput"),
          ...Array(Math.min(wanTotal, 16)).fill(null).map((value, index) => /* @__PURE__ */ jsx(InputStep, { classes: { content: classes.standardInputStep }, children: /* @__PURE__ */ jsx(WanStep, { stepIndex: index }) }, index))
        ] }),
        /* @__PURE__ */ jsx(MultiStep, { label: "tesseract.appliances.dialog.lans.sectionTitle", children: [
          /* @__PURE__ */ jsx(InputStep, { children: /* @__PURE__ */ jsx(SelectLanLengthStep, {}) }, "mainInput"),
          ...Array(Math.min(lanTotal, 16)).fill(null).map((value, index) => /* @__PURE__ */ jsx(InputStep, { classes: { content: classes.standardInputStep }, children: /* @__PURE__ */ jsx(LanStep, { stepIndex: index }) }, index))
        ] }),
        /* @__PURE__ */ jsx(InputStep, { label: "tesseract.appliances.dialog.advanced.sectionTitle", children: /* @__PURE__ */ jsx(AdvancedStep, { series: "S", applianceType: "site" }) }),
        /* @__PURE__ */ jsx(SubmitStep, { label: "tesseract.appliances.dialog.steps.submit", children: /* @__PURE__ */ jsx(SiteSubmitStep, { applianceType: "site" }) }),
        /* @__PURE__ */ jsx(ResultStep, { label: "tesseract.appliances.dialog.steps.result", onClose, children: /* @__PURE__ */ jsx(SiteResultStep, {}) })
      ]
    }
  );
};
var CreateSCContent_default = CreateSCContent;
export {
  CreateSCContent_default as default
};
