var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { useDeepCompareMemo } from "@cuda-react/core";
import { useState } from "react";
import { CardSizes, GridSizeOptions } from "../typesAndConstants";
const SizeToRows = {
  [CardSizes.small]: 1,
  [CardSizes.medium]: 2,
  [CardSizes.large]: 2
};
const SizeToColumns = {
  [CardSizes.small]: 1,
  [CardSizes.medium]: 1,
  [CardSizes.large]: 2
};
const positionConflictsX = (itemA, itemB) => !(itemA.x + itemA.w <= itemB.x || itemB.x + itemB.w <= itemA.x);
const positionConflictsY = (itemA, itemB) => !(itemA.y + itemA.h <= itemB.y || itemB.y + itemB.h <= itemA.y);
const positionConflicts = (itemA, itemB) => positionConflictsX(itemA, itemB) && positionConflictsY(itemA, itemB);
const positionConflictsInLayout = (item, layout) => layout.some((existingItem) => positionConflicts(item, existingItem));
const getFiller = (xPosition, yPosition, draggedItem, forceSmall, widget) => {
  const fillerItem = {
    id: `gapFiller.${xPosition}.${yPosition}`,
    x: xPosition,
    y: yPosition,
    w: 1,
    h: 1,
    forceSmall
  };
  const hasInsert = draggedItem && positionConflicts(draggedItem, fillerItem);
  return __spreadProps(__spreadValues({}, fillerItem), {
    hasInsert,
    addWidget: widget
  });
};
const getNewPosition = (location, deltaX, deltaY, columns) => {
  const xIndexOffset = Math.ceil((deltaX + GridSizeOptions.columnMargin) / (GridSizeOptions.columnWidth + GridSizeOptions.columnMargin)) - 1;
  const yIndexOffset = Math.ceil((deltaY + GridSizeOptions.columnMargin) / (GridSizeOptions.rowHeight + GridSizeOptions.columnMargin)) - 1;
  const newX = location.x + xIndexOffset > columns ? columns : location.x + xIndexOffset < 0 ? 0 : location.x + xIndexOffset;
  const newY = location.y + yIndexOffset < 0 ? 0 : location.y + yIndexOffset;
  return __spreadProps(__spreadValues({}, location), { x: newX, y: newY });
};
const useEditableGridLayout = (cardComponents, onChange, editMode, cards, width) => {
  const maxColumnsForWidth = Math.floor((width + GridSizeOptions.columnMargin) / (GridSizeOptions.columnWidth + GridSizeOptions.columnMargin));
  const columns = maxColumnsForWidth >= 1 ? maxColumnsForWidth : 1;
  const forceSmall = columns <= 1;
  const [draggedLayoutItem, setDraggedLayoutItem] = useState(void 0);
  const onDragFinishHandler = (layoutItem, layout2) => (event, {
    x: deltaX,
    y: deltaY
  }) => {
    const draggedLocation = getNewPosition(layoutItem, deltaX, deltaY, columns);
    if (!positionConflicts(draggedLocation, layoutItem)) {
      const removeDragged = layout2.filter((item) => item.id !== layoutItem.id);
      const targetIndex = removeDragged.findIndex((item) => positionConflicts(draggedLocation, item));
      const newCardComponents = [
        ...removeDragged.slice(0, targetIndex >= 0 ? targetIndex : removeDragged.length),
        layoutItem,
        ...removeDragged.slice(targetIndex >= 0 ? targetIndex : removeDragged.length)
      ].map((item) => item.card).filter((card) => !!card);
      onChange == null ? void 0 : onChange(newCardComponents);
    }
    setDraggedLayoutItem(void 0);
  };
  const onAddHandler = (layoutItem, layout2) => (selectedCardId, selectedSize) => {
    if (selectedCardId && cards[selectedCardId] && selectedSize) {
      const targetIndex = layout2.findIndex((item) => layoutItem.id === item.id);
      const newCardComponents = [
        ...layout2.slice(0, targetIndex >= 0 ? targetIndex : layout2.length),
        {
          card: {
            componentId: selectedCardId,
            component: cards[selectedCardId].component,
            props: { size: selectedSize }
          }
        },
        ...layout2.slice(targetIndex >= 0 ? targetIndex : layout2.length)
      ].map((item) => item.card).filter((card) => !!card);
      onChange == null ? void 0 : onChange(newCardComponents);
    }
  };
  const layout = useDeepCompareMemo(() => cardComponents.reduce((layout2, card, index) => {
    const size = forceSmall ? CardSizes.small : card.props.size || CardSizes.small;
    const lastLayoutItem = layout2[layout2.length - 1] || { x: -1, y: 0, w: 1, h: 1, id: null };
    const layoutItem = {
      x: lastLayoutItem.x + lastLayoutItem.w,
      y: lastLayoutItem.y,
      w: SizeToRows[size],
      h: SizeToColumns[size],
      id: card.componentId,
      forceSmall,
      card
    };
    const newLayout = [...layout2];
    while (positionConflictsInLayout(layoutItem, newLayout) || layoutItem.x + layoutItem.w > columns) {
      let xPosition = layoutItem.x + 1;
      let yPosition = layoutItem.y;
      if (layoutItem.x + layoutItem.w > columns) {
        xPosition = 0;
        yPosition += 1;
      }
      if (!positionConflictsInLayout({
        x: layoutItem.x,
        y: layoutItem.y,
        w: 1,
        h: 1
      }, newLayout) && layoutItem.x + 1 <= columns) {
        newLayout.push(getFiller(layoutItem.x, layoutItem.y, draggedLayoutItem, forceSmall, editMode));
      }
      layoutItem.x = xPosition;
      layoutItem.y = yPosition;
    }
    const hasInsert = draggedLayoutItem && positionConflicts(draggedLayoutItem, layoutItem);
    const isMoving = (draggedLayoutItem == null ? void 0 : draggedLayoutItem.id) === layoutItem.id;
    newLayout.push(__spreadProps(__spreadValues({}, layoutItem), {
      hasInsert,
      isMoving,
      onDragEvent: (event, { x: deltaX, y: deltaY }) => {
        const draggedLocation = getNewPosition(layoutItem, deltaX, deltaY, columns);
        setDraggedLayoutItem(__spreadProps(__spreadValues({}, draggedLocation), { w: 1, h: 1 }));
      }
    }));
    if (index + 1 === cardComponents.length && editMode) {
      const widgetLocation = { x: layoutItem.x + layoutItem.w, y: layoutItem.y, w: 1, h: 1 };
      while (positionConflictsInLayout(widgetLocation, newLayout) || widgetLocation.x + widgetLocation.w > columns) {
        widgetLocation.x += 1;
        if (widgetLocation.x + widgetLocation.w > columns) {
          widgetLocation.x = 0;
          widgetLocation.y += 1;
        }
      }
      newLayout.push(getFiller(widgetLocation.x, widgetLocation.y, draggedLayoutItem, forceSmall, true));
    }
    if (index + 1 === cardComponents.length) {
      while (newLayout[newLayout.length - 1].x + newLayout[newLayout.length - 1].w < columns && newLayout[newLayout.length - 1].y === 0) {
        newLayout.push(getFiller(newLayout[newLayout.length - 1].x + newLayout[newLayout.length - 1].w, 0, draggedLayoutItem, forceSmall, false));
      }
    }
    return newLayout;
  }, []), [columns, draggedLayoutItem, cardComponents, editMode, forceSmall]);
  if (draggedLayoutItem && !layout.some(({ hasInsert }) => hasInsert)) {
    layout[layout.length - 1].hasInsert = true;
  }
  return layout.map((layoutItem) => __spreadProps(__spreadValues({}, layoutItem), {
    onDragFinish: onDragFinishHandler(layoutItem, layout),
    onAdd: onAddHandler(layoutItem, layout)
  }));
};
var useEditableGridLayout_default = useEditableGridLayout;
export {
  useEditableGridLayout_default as default
};
