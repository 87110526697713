import { jsx, jsxs } from "react/jsx-runtime";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  ActionButtonsField,
  ChipArrayField,
  CrudTypes,
  DeleteDialog,
  EmptyPageContent,
  getArrayDataContent,
  SelectField,
  TablePage,
  TextField,
  useCrudFetch,
  useCrudProps,
  useMixpanel,
  useTableChoices
} from "@cuda-react/core";
import { useRef, useState } from "react";
import { categoryIcons, fallbackIcons, priorityIcons } from "../../../../components/iconMapping";
import CreateExplicitPolicyDialog from "../dialogs/CreateExplicitPolicyDialog";
import EditExplicitPolicyDialog from "../dialogs/EditExplicitPolicyDialog";
import apiResources from "../../../../apiResources";
import { GetStartedBackgroundGraphic, OverrideGraphic } from "@cuda-react/icons";
const ExplicitPolicies = () => {
  var _a, _b, _c, _d;
  const registerAction = useMixpanel("SDWAN Explicit");
  const resource = apiResources.sdwanExplicitPolicies;
  const tableRefreshRef = useRef();
  const [actionVisible, setActionVisible] = useState(false);
  const [, , updateOrder] = useCrudFetch(CrudTypes.CREATE, resource);
  const handleClick = (id, direction, data) => {
    updateOrder({ id, changeOrder: direction, data }).then(tableRefreshRef.current);
  };
  const priorityData = (_a = useCrudProps(apiResources.priority, {}, { cache: true })[0]) == null ? void 0 : _a.data;
  const actionData = (_b = useCrudProps(apiResources.action, {}, { cache: true })[0]) == null ? void 0 : _b.data;
  const fallbackData = (_c = useCrudProps(apiResources.fallback, {}, { cache: true })[0]) == null ? void 0 : _c.data;
  const loadBalancingData = (_d = useCrudProps(apiResources.loadBalancing, {}, { cache: true })[0]) == null ? void 0 : _d.data;
  const forwardErrorCorrectionData = [
    {
      "key": "true",
      "name": "tesseract.policies.dialogs.explicit.action.forwardErrorCorrection.options.on"
    },
    {
      "key": "false",
      "name": "tesseract.policies.dialogs.explicit.action.forwardErrorCorrection.options.off"
    }
  ];
  const [renderApplication, getApplicationIconId] = useTableChoices(apiResources.applications, {
    filterId: "applicationId",
    optionValue: "id"
  }, "categoryId");
  const onSuccess = (field) => (response) => {
    var _a2, _b2, _c2;
    (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
    registerAction("Update", {
      applications: (_b2 = response.applications) == null ? void 0 : _b2.length,
      sources: (_c2 = response.sources) == null ? void 0 : _c2.length,
      field
    });
  };
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      title: "tesseract.securityAndAccess.pageTitle",
      subtitle: ["tesseract.securityAndAccess.sdwan", "tesseract.securityAndAccess.explicit"],
      resource,
      titleActions: actionVisible && /* @__PURE__ */ jsx(
        CreateExplicitPolicyDialog,
        {
          resource,
          onSuccess: tableRefreshRef.current
        }
      ),
      minCellWidth: 96,
      compact: true,
      refreshRef: tableRefreshRef,
      onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table"),
      noDataPage: /* @__PURE__ */ jsx(
        EmptyPageContent,
        {
          background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
          icon: /* @__PURE__ */ jsx(OverrideGraphic, {}),
          iconHeader: "tesseract.policies.explicit.noData.iconHeader",
          text: "tesseract.policies.explicit.noData.text",
          actions: /* @__PURE__ */ jsx(
            CreateExplicitPolicyDialog,
            {
              label: "tesseract.policies.explicit.noData.labelButton",
              resource,
              onSuccess: tableRefreshRef.current
            }
          )
        }
      ),
      children: [
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            label: "tesseract.policies.explicit.table.order",
            width: 96,
            source: "id",
            left: true,
            children: [
              /* @__PURE__ */ jsx(TextField, { source: "order" }),
              /* @__PURE__ */ jsx(
                ArrowUpwardIcon,
                {
                  onClick: (event, data) => handleClick(data.id, "increase", data),
                  disabled: (data) => data.index <= 0,
                  id: "cuda-icon-up"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowDownwardIcon,
                {
                  onClick: (event, data) => handleClick(data.id, "decrease", data),
                  disabled: (data) => data.index >= data.total - 1,
                  id: "cuda-icon-down"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(TextField, { source: "name", label: "tesseract.policies.explicit.table.name" }),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            label: "tesseract.policies.explicit.table.apps",
            source: "applications",
            render: renderApplication,
            iconMap: categoryIcons,
            optionIcon: getApplicationIconId
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            label: "tesseract.policies.explicit.table.sources",
            source: "sources"
          }
        ),
        /* @__PURE__ */ jsx(
          SelectField,
          {
            source: "priority",
            label: "tesseract.policies.explicit.table.priority",
            resource,
            id: (data) => data.id,
            choices: getArrayDataContent(priorityData),
            iconMap: priorityIcons,
            width: 142,
            columnProps: {
              width: 156
            },
            onSuccess: onSuccess("priority")
          }
        ),
        /* @__PURE__ */ jsx(
          SelectField,
          {
            source: "action",
            label: "tesseract.policies.explicit.table.action",
            resource,
            id: (data) => data.id,
            choices: getArrayDataContent(actionData),
            width: 144,
            columnProps: {
              width: 168
            },
            onSuccess: onSuccess("action")
          }
        ),
        /* @__PURE__ */ jsx(
          SelectField,
          {
            source: "fallback",
            label: "tesseract.policies.explicit.table.fallback",
            resource,
            id: (data) => data.id,
            choices: getArrayDataContent(fallbackData),
            iconMap: fallbackIcons,
            width: 124,
            columnProps: {
              width: 148
            },
            onSuccess: onSuccess("fallback")
          }
        ),
        /* @__PURE__ */ jsx(
          SelectField,
          {
            source: "loadBalancing",
            label: "tesseract.policies.sdwan.table.loadBalancing",
            resource,
            id: (data) => data.id,
            choices: getArrayDataContent(loadBalancingData),
            width: 120,
            columnProps: {
              width: 144
            },
            onSuccess: onSuccess("loadBalancing")
          }
        ),
        /* @__PURE__ */ jsx(
          SelectField,
          {
            source: "forwardErrorCorrection",
            label: "tesseract.policies.dialogs.explicit.action.forwardErrorCorrection.label",
            resource,
            id: (data) => data.id,
            choices: forwardErrorCorrectionData,
            width: 120,
            columnProps: {
              width: 144
            },
            onSuccess: onSuccess("forwardErrorCorrection")
          }
        ),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            width: 96,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(EditExplicitPolicyDialog, { id: (data) => data.id, onSuccess: tableRefreshRef.current }),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource,
                  title: "tesseract.sdwanExplicitPolicies.delete.title",
                  message: "tesseract.sdwanExplicitPolicies.delete.body",
                  onSuccess: () => {
                    var _a2;
                    registerAction("Delete");
                    (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                  }
                }
              )
            ]
          }
        )
      ]
    }
  );
};
var ExplicitPolicies_default = ExplicitPolicies;
export {
  ExplicitPolicies,
  ExplicitPolicies_default as default
};
