import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const EventFailedIcon = ({ className }) => {
  var _a, _b, _c;
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-event-failed",
      viewBox: "0 0 60 60",
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
            d: "M49.7,5.4H10.3c-2.7,0-4.9,2.2-4.9,4.9v44.2l9.8-9.8h34.4c2.7,0,4.9-2.2,4.9-4.9V10.3c0-2.7-2.2-4.9-4.9-4.9ZM49.7,39.8H15.3l-4.9,4.9V10.3h39.3v29.5Z"
          }
        ),
        /* @__PURE__ */ jsxs("g", { children: [
          /* @__PURE__ */ jsx("rect", { style: { fill: (_b = theme.palette.error) == null ? void 0 : _b.main, strokeWidth: 0 }, x: "28.1", y: "31.2", width: "3.8", height: "3.8" }),
          /* @__PURE__ */ jsx("rect", { style: { fill: (_c = theme.palette.error) == null ? void 0 : _c.main, strokeWidth: 0 }, x: "28.1", y: "15.8", width: "3.8", height: "11.5" })
        ] })
      ]
    }
  );
};
var EventFailedIcon_default = EventFailedIcon;
export {
  EventFailedIcon_default as default
};
