import { jsx, jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { MenuButton } from "@cuda-react/core";
import { Dialog } from "@barracuda-internal/bds-core";
import CreateSdwanConnectorTokenContent from "./createEdit/CreateSdwanConnectorTokenContent";
const SdwanConnectorTableMenu = ({ data }) => {
  const [tokenDialogOpen, setTokenDialogOpen] = useState(false);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      MenuButton,
      {
        anchorOrigin: { horizontal: "left", vertical: "top" },
        transformOrigin: { horizontal: "left", vertical: "top" },
        menuItems: [
          {
            label: "tesseract.network.sdwanConnector.table.ellipsisMenu.token",
            onClick: () => setTokenDialogOpen(true)
          },
          {
            label: "tesseract.network.sdwanConnector.table.ellipsisMenu.downloadWindows",
            onClick: () => {
              const downloadLink = window.open("https://d.barracudanetworks.com/SDWC/SDWANConnector_1.0.0-2_x64.exe", "_blank", "noopener,noreferrer");
              if (downloadLink)
                downloadLink.opener = null;
            }
          },
          {
            label: "tesseract.network.sdwanConnector.table.ellipsisMenu.downloadLinux",
            onClick: () => {
              const downloadLink = window.open("https://d.barracudanetworks.com/SDWC/SDWANConnector_1.0.0-2_Linux.zip", "_blank", "noopener,noreferrer");
              if (downloadLink)
                downloadLink.opener = null;
            }
          }
        ],
        iconButton: true
      }
    ),
    /* @__PURE__ */ jsx(Dialog, { open: tokenDialogOpen, maxWidth: false, onClose: () => setTokenDialogOpen(false), children: /* @__PURE__ */ jsx(
      CreateSdwanConnectorTokenContent,
      {
        id: data == null ? void 0 : data.id,
        sec: data == null ? void 0 : data.name,
        onClose: () => setTokenDialogOpen(false)
      }
    ) })
  ] });
};
var SdwanConnectorTableMenu_default = SdwanConnectorTableMenu;
export {
  SdwanConnectorTableMenu_default as default
};
