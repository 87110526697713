import { jsx, jsxs } from "react/jsx-runtime";
import { Route, Switch } from "react-router";
import { IdentitySettings } from "./identitySettings/IdentitySettings";
import { Users } from "./users/Users";
import { Groups } from "./groups/Groups";
const Identity = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(Route, { path: "/identity/users", children: /* @__PURE__ */ jsx(Users, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/identity/groups", children: /* @__PURE__ */ jsx(Groups, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/identity/settings", children: /* @__PURE__ */ jsx(IdentitySettings, {}) })
] });
export {
  Identity
};
