import { useCallback, useState } from "react";
var useListenerManager_default = () => {
  const [listeners, setListeners] = useState([]);
  const register = useCallback((id) => {
    setListeners((currentListeners) => [...currentListeners, id]);
  }, []);
  const unregister = useCallback((id) => {
    setListeners((currentListeners) => currentListeners.filter((listener) => listener !== id));
  }, []);
  return { register, unregister, listeners };
};
export {
  useListenerManager_default as default
};
