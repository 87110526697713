import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const SecurityIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-security",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
          d: "M30,3.2L8.1,13v14.6c0,13.5,9.3,26.1,21.9,29.2,12.6-3.1,21.9-15.7,21.9-29.2v-14.6L30,3.2ZM30,30h17c-1.3,10-8,19-17,21.8v-21.7H13v-13.9l17-7.6v21.4Z"
        }
      )
    }
  );
};
var SecurityIcon_default = SecurityIcon;
export {
  SecurityIcon,
  SecurityIcon_default as default
};
