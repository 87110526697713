var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import ButtonDialog from "../../dialog/ButtonDialog/ButtonDialog";
import { Edit } from "@barracuda-internal/bds-core/dist/Icons/Core";
import TableInputAddEditForm from "./TableInputAddEditForm";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  iconDisabled: {
    color: theme.palette.text.disabled
  }
});
const useStyles = makeOverrideableStyles("TableInputAddEditDialog", styles);
const TableInputAddEditDialog = (props) => {
  const _a = props, { children, data, disabled, buttonText, edit, initialErrors } = _a, formProps = __objRest(_a, ["children", "data", "disabled", "buttonText", "edit", "initialErrors"]);
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx(
    ButtonDialog,
    {
      buttonIcon: edit ? /* @__PURE__ */ jsx(Edit, { id: "edit-dialog-icon", className: disabled ? classes.iconDisabled : void 0 }) : void 0,
      buttonText: edit ? void 0 : buttonText,
      disabled,
      children: /* @__PURE__ */ jsx(
        TableInputAddEditForm,
        __spreadProps(__spreadValues({}, formProps), {
          initialValue: data,
          initialErrors: data && initialErrors,
          children
        })
      )
    }
  );
};
var TableInputAddEditDialog_default = TableInputAddEditDialog;
export {
  TableInputAddEditDialog,
  TableInputAddEditDialog_default as default
};
