var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { Switch, Typography } from "@barracuda-internal/bds-core";
import { useEffect } from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
const styles = (theme) => createStyles({
  root: {
    display: "inline-block"
  },
  switch: {
    marginLeft: -8
  },
  toggleEnabledText: {
    fontSize: 12,
    lineHeight: "20px",
    display: "inline-block",
    padding: theme.spacing(1.25, 0)
  },
  toggleDisabledText: {
    fontSize: 12,
    lineHeight: "20px",
    display: "inline-block",
    padding: theme.spacing(1.25, 0)
  }
});
const useStyles = makeOverrideableStyles("Boolean", styles);
const Boolean = (props) => {
  const { onChange, onBlur, id, useStrings, value = false, options, toggleEnabledText, toggleDisabledText } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const setValue = (value2) => {
    if (useStrings) {
      onChange == null ? void 0 : onChange(value2 ? "true" : "false");
    } else {
      onChange == null ? void 0 : onChange(value2);
    }
  };
  useEffect(() => {
    if (useStrings && value !== "true" && value !== "false") {
      setValue(value);
      onBlur == null ? void 0 : onBlur();
    } else if (!useStrings && value !== true && value !== false) {
      setValue(value === "true");
      onBlur == null ? void 0 : onBlur();
    }
  }, [value]);
  const checked = useStrings ? value === "true" : !!value;
  return /* @__PURE__ */ jsxs("div", { className: classes.root, children: [
    /* @__PURE__ */ jsx(
      Switch,
      __spreadValues({
        checked,
        onChange: (event, value2) => setValue(value2),
        id: id && "boolean-input-" + id,
        color: "primary",
        className: classes.switch
      }, options)
    ),
    toggleEnabledText && checked ? /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.toggleEnabledText, children: translate(toggleEnabledText) }) : null,
    toggleDisabledText && !checked ? /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.toggleDisabledText, children: translate(toggleDisabledText) }) : null
  ] });
};
var Boolean_default = Boolean;
export {
  Boolean,
  Boolean_default as default,
  useStyles
};
