import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import { ActionButtonsField, ConnectedTable, DateField, DeleteDialog, TextField, useMixpanel } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import CreateEditButtonDialog from "../../../../../../components/CreateEditButtonDialog";
import CreateEditSslCertificates from "./CreateEditSslCertificates";
import ExpiryStatus from "./ExpiryStatus";
import apiResources from "../../../../../../apiResources";
const styles = (theme) => ({
  cardActions: {
    paddingRight: theme.spacing(2),
    width: "calc(100% - " + theme.spacing(2) + ")"
  },
  actions: {
    paddingRight: 0,
    "&> span": {
      position: "relative",
      left: "5%"
    }
  }
});
const useStyles = makeStyles(styles);
const SslInterceptionCAs = () => {
  const registerAction = useMixpanel("SSL CA", false);
  const classes = useStyles();
  const tableRefreshRef = useRef();
  return /* @__PURE__ */ jsx("div", { id: "ssl-certificate-authorities", children: /* @__PURE__ */ jsxs(
    ConnectedTable,
    {
      resource: apiResources.sslCertificateAuthorities,
      actions: [
        /* @__PURE__ */ jsx(
          CreateEditButtonDialog,
          {
            create: true,
            component: CreateEditSslCertificates,
            type: "trustedRoot",
            label: "tesseract.security.sslInterception.settings.certificates.create.label",
            onSuccess: () => {
              var _a;
              registerAction("Create");
              (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
            }
          },
          "create"
        )
      ],
      classes: { cardActions: classes.cardActions },
      params: { filter: { type: "trustedRoot" } },
      minCellWidth: 128,
      refreshRef: tableRefreshRef,
      flat: true,
      children: [
        /* @__PURE__ */ jsx(
          ExpiryStatus,
          {
            source: "end",
            label: "tesseract.security.sslInterception.settings.status",
            width: 96
          }
        ),
        /* @__PURE__ */ jsx(TextField, { source: "name", label: "tesseract.security.sslInterception.settings.name" }),
        /* @__PURE__ */ jsx(TextField, { source: "description", label: "tesseract.security.sslInterception.settings.description" }),
        /* @__PURE__ */ jsx(TextField, { source: "issuer", label: "tesseract.security.sslInterception.settings.issuer" }),
        /* @__PURE__ */ jsx(DateField, { source: "start", label: "tesseract.security.sslInterception.settings.startDate" }),
        /* @__PURE__ */ jsx(DateField, { source: "end", label: "tesseract.security.sslInterception.settings.endDate" }),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            cellClassName: classes.actions,
            source: "id",
            width: 120,
            children: [
              /* @__PURE__ */ jsx(
                CreateEditButtonDialog,
                {
                  component: CreateEditSslCertificates,
                  type: "trustedRoot",
                  onSuccess: () => {
                    var _a;
                    registerAction("Update");
                    (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                  }
                }
              ),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource: apiResources.sslCertificateAuthorities,
                  title: "tesseract.security.sslInterception.settings.certificates.delete.title",
                  message: "tesseract.security.sslInterception.settings.certificates.delete.body",
                  onSuccess: () => {
                    var _a;
                    registerAction("Delete");
                    (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                  }
                }
              )
            ]
          }
        )
      ]
    }
  ) });
};
var SslInterceptionCAs_default = SslInterceptionCAs;
export {
  SslInterceptionCAs,
  SslInterceptionCAs_default as default,
  styles
};
