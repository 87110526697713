import { jsx, jsxs } from "react/jsx-runtime";
import Gateways from "./gateways/Gateways";
import { Route, Switch } from "react-router";
import Sites from "./sites/Sites";
import VmActivation from "./vmActivation/VmActivation";
import NetworkSettings from "./settings/NetworkSettings";
import IoT from "./sites/IoT";
import SdwanConnector from "./sdwanConnector/SdwanConnector";
const Network = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(Route, { path: "/infrastructure/gateways", children: /* @__PURE__ */ jsx(Gateways, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/infrastructure/sites", children: /* @__PURE__ */ jsx(Sites, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/infrastructure/iot", children: /* @__PURE__ */ jsx(IoT, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/infrastructure/connectors", children: /* @__PURE__ */ jsx(SdwanConnector, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/infrastructure/vmactivation", children: /* @__PURE__ */ jsx(VmActivation, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/infrastructure/settings", children: /* @__PURE__ */ jsx(NetworkSettings, {}) })
] });
var Network_default = Network;
export {
  Network,
  Network_default as default
};
