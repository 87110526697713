import { jsx } from "react/jsx-runtime";
import { BaseBarCard } from "../../baseCards";
import { colorFunctionError, numberFormatter } from "../../../utils";
import { CardSizes } from "../../../typesAndConstants";
import { useTranslation } from "react-i18next";
import { useReportingData } from "../../../contexts";
import { sortBy } from "lodash";
import { UrlCategoriesIcon } from "@cuda-react/icons";
import { useTheme } from "@mui/material";
const TopBlockedUrlCategoriesCard = (props) => {
  const { preview, size } = props;
  const [translate] = useTranslation();
  const { data, loading, addFilter } = useReportingData("TopBlockedUrlCategoriesCard", preview);
  const { blockedUrlCategoryDataPoints = [] } = data || {};
  const maxEntry = Math.max(...blockedUrlCategoryDataPoints.map((category) => category.numberOfSessions));
  const theme = useTheme();
  const entries = sortBy(blockedUrlCategoryDataPoints, "numberOfSessions").reverse().map((category) => ({
    title: category.label,
    value: size === CardSizes.small ? numberFormatter(category.numberOfSessions) : translate("stratosReporting.topBlockedUrlCategoriesCard.sessions", {
      count: category.numberOfSessions,
      formattedCount: numberFormatter(category.numberOfSessions)
    }),
    icon: /* @__PURE__ */ jsx(UrlCategoriesIcon, { size: 20 }),
    barProgress: 100 / maxEntry * category.numberOfSessions,
    color: colorFunctionError(100 / maxEntry * category.numberOfSessions, theme),
    onClick: category.label && addFilter ? () => addFilter(category.id, "urlCategoryDataPoints") : void 0,
    tooltipMessage: category.label && addFilter ? "stratosReporting.filterTooltip" : void 0
  }));
  return /* @__PURE__ */ jsx(
    BaseBarCard,
    {
      entries,
      title: "stratosReporting.topBlockedUrlCategoriesCard.title",
      size,
      preview,
      loading: loading && !data.blockedUrlCategoryDataPoints,
      noData: !loading && !data.blockedUrlCategoryDataPoints,
      activeFilter: true
    }
  );
};
export {
  TopBlockedUrlCategoriesCard
};
