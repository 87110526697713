var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { ProfileAvatar } from "@barracuda-internal/bds-core";
import { useGlobalParam } from "../../../hooks";
const UserAvatar = (props) => {
  const email = useGlobalParam("userData.currentUser")[0] || "Anonymous";
  const [name] = useGlobalParam("userData.currentUserName");
  const initials = name ? name.split(" ").reduce((initial, nameSegment) => initial + nameSegment[0].toUpperCase(), "") : email[0].toUpperCase();
  return /* @__PURE__ */ jsx(ProfileAvatar, __spreadProps(__spreadValues({}, props), { children: initials }));
};
var UserAvatar_default = UserAvatar;
export {
  UserAvatar,
  UserAvatar_default as default
};
