var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { isEmpty } from "lodash";
import { useRef, useState } from "react";
import { formatErrorMessage, getArrayDataContent } from "../../../utils";
import { CircularProgress, IconButton, TextField } from "@barracuda-internal/bds-core";
import { InputAdornment } from "@mui/material";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useCrudProps, useDeepCompareEffect } from "../../../hooks";
import { Assignment, Sync } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { useTranslation } from "react-i18next";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  textField: {
    width: 256
  },
  endAdornment: {
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  syncDisabled: {
    opacity: (props) => props.disabled ? 0.4 : 1
  }
});
const useStyles = makeOverrideableStyles("AutoSelect", styles);
const AutoSelect = (props) => {
  var _a;
  const {
    allowManualInput,
    allowShuffle,
    allowEmpty,
    resource,
    params,
    disabled,
    formatChoices,
    defaultChoice,
    choices,
    id,
    error,
    value = "",
    onChange,
    onBlur,
    options = {},
    generatedLabel = "cuda.inputs.autoSelect.generated"
  } = props;
  const classes = useStyles(props);
  const [generated, setGenerated] = useState(false);
  const [translate] = useTranslation();
  const initialValue = useRef(value);
  const textFieldRef = useRef(null);
  const resourceChoices = getArrayDataContent((_a = useCrudProps(resource, params)[0]) == null ? void 0 : _a.data);
  const resolvedChoices = [...choices || [], ...resourceChoices];
  const formattedChoices = formatChoices ? formatChoices(resolvedChoices, value, initialValue.current) : resolvedChoices;
  const filteredChoices = formattedChoices.filter((choice) => choice !== value);
  const valueIsFromChoices = formattedChoices.length > filteredChoices.length;
  useDeepCompareEffect(() => {
    if (!isEmpty(formattedChoices) && !allowEmpty && (allowManualInput ? !value : !valueIsFromChoices)) {
      setGenerated(true);
      onChange == null ? void 0 : onChange(defaultChoice || formattedChoices[0]);
      onBlur == null ? void 0 : onBlur();
    }
  }, [formattedChoices, value, onChange]);
  return /* @__PURE__ */ jsx(
    TextField,
    __spreadProps(__spreadValues({
      className: classes.textField,
      value: (allowManualInput || valueIsFromChoices) && value || "",
      onChange: allowManualInput ? (eventOrValue) => {
        setGenerated(false);
        onChange && onChange(eventOrValue);
        onBlur && onBlur();
      } : () => {
      }
    }, options), {
      error: !!error,
      disabled,
      helperText: error ? formatErrorMessage(error) : void 0,
      id: "autoselect-input-" + id,
      inputRef: textFieldRef,
      InputProps: {
        endAdornment: /* @__PURE__ */ jsxs(InputAdornment, { position: "end", className: classes.endAdornment, children: [
          generated ? translate(generatedLabel) : null,
          allowShuffle ? /* @__PURE__ */ jsx(
            IconButton,
            {
              size: "small",
              id: "regenerate-value-button",
              className: classes.regenerateButton,
              onClick: () => {
                setGenerated(true);
                onChange && onChange(filteredChoices[Math.floor(Math.random() * filteredChoices.length)]);
                onBlur && onBlur();
              },
              disabled: disabled || filteredChoices.length < 1,
              children: /* @__PURE__ */ jsx(Sync, { className: disabled ? classes.syncDisabled : "" })
            }
          ) : void 0,
          valueIsFromChoices || allowManualInput || allowEmpty ? /* @__PURE__ */ jsx(
            IconButton,
            {
              size: "small",
              onClick: () => {
                if (textFieldRef.current) {
                  textFieldRef.current.select();
                  document.execCommand("copy");
                  textFieldRef.current.setSelectionRange(0, 0);
                }
              },
              children: /* @__PURE__ */ jsx(Assignment, { className: disabled ? classes.syncDisabled : "" })
            }
          ) : /* @__PURE__ */ jsx(CircularProgress, { size: 24 })
        ] })
      }
    })
  );
};
var AutoSelect_default = AutoSelect;
export {
  AutoSelect,
  AutoSelect_default as default
};
