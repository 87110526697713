import { CrudTypes, getDataContent, useCrudSubscription } from "@cuda-react/core";
var useCardFetch_default = ({
  url,
  params,
  options,
  formatData
}) => {
  const [response, loading, refresh] = useCrudSubscription(CrudTypes.GET, url, params, options);
  const formattedData = formatData ? formatData(response.data) : getDataContent(response.data);
  return {
    data: formattedData,
    loading,
    refresh,
    error: response.error
  };
};
export {
  useCardFetch_default as default
};
