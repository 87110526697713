import { jsx, jsxs } from "react/jsx-runtime";
import {
  DialogBody,
  Form,
  SelectInput,
  TextInput,
  validateMaxLengthMemo,
  validateRegexMemo
} from "@cuda-react/core";
const AddDashboardDialog = ({
  addUpdateDashboard,
  onClose,
  templates
}) => /* @__PURE__ */ jsx(
  DialogBody,
  {
    title: "stratosReporting.dashboardPage.addDashboard",
    onClose,
    form: true,
    children: /* @__PURE__ */ jsxs(
      Form,
      {
        initialValues: { name: "", cards: [] },
        save: (newDashboard) => addUpdateDashboard(newDashboard).then((result) => {
          onClose == null ? void 0 : onClose();
          return result;
        }),
        children: [
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "name",
              label: "stratosReporting.dashboardPage.name",
              isRequired: true,
              validate: [validateMaxLengthMemo(32), validateRegexMemo(/^[a-zA-Z0-9 ]+$/, "stratosReporting.dashboardPage.validateName")]
            }
          ),
          (templates == null ? void 0 : templates.length) ? /* @__PURE__ */ jsx(
            SelectInput,
            {
              source: "template",
              label: "stratosReporting.dashboardPage.template",
              choices: templates,
              optionValue: "name"
            }
          ) : null
        ]
      }
    )
  }
);
export {
  AddDashboardDialog
};
