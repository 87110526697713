var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { get } from "lodash";
import { createStyles, makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import { usei18nCountries } from "@stratos/reporting/lib/utils/countries";
import { getArrayDataContent, SearchableSelect, Select, Text, useCrudProps } from "@cuda-react/core";
import apiResources from "../../apiResources";
import React, { useState } from "react";
import {
  Button,
  ClickAwayListener,
  Collapse,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@barracuda-internal/bds-core";
import { Close, Filter } from "@barracuda-internal/bds-core/dist/Icons/Core";
import FilterIcon from "@mui/icons-material/FilterAlt";
const timeframes = [
  { key: "1", name: "tesseract.dashboard.filters.timeframes.lastDay", unit: "hours", duration: "24" },
  { key: "7", name: "tesseract.dashboard.filters.timeframes.lastWeek", unit: "days", duration: "7" },
  { key: "28", name: "tesseract.dashboard.filters.timeframes.lastMonth", unit: "weeks", duration: "4" },
  { key: "90", name: "tesseract.dashboard.filters.timeframes.lastQuarter", unit: "months", duration: "3" }
];
const getStaticChoiceFromFilter = (filterId, filterValues, optionValue = "key") => {
  const filterValue = get(filterValues, filterId);
  if ((filterValue == null ? void 0 : filterValue.key) && (filterValue == null ? void 0 : filterValue.name)) {
    return [{ [optionValue]: filterValue == null ? void 0 : filterValue.key, name: filterValue == null ? void 0 : filterValue.name }];
  }
  return [];
};
const useStyles = makeStyles((theme) => createStyles({
  filterContainer: {
    marginBottom: theme.spacing(1),
    "& > div": {
      marginTop: 0,
      paddingTop: 0
    },
    "& .MuiInputBase-root": {
      background: "none"
    }
  },
  filterContainerTimeAndAppliance: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& > div": {
      marginTop: 0,
      paddingTop: 0
    }
  },
  filterField: {
    verticalAlign: "bottom",
    width: 256
  },
  filterRemoveIcon: {
    marginBottom: 2
  },
  clearFilterButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  filterBar: {
    position: "relative",
    display: "block",
    marginRight: "auto",
    marginLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  buttonLabel: {
    whiteSpace: "nowrap"
  },
  filterButtons: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  list: {},
  filterMessage: {
    alignContent: "center",
    padding: "0px 8px",
    textWrap: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexShrink: 1
  }
}));
var useFilterOptions_default = (activeFilters, updateFilter, removeFilter, clearAllFilters, appliances, disabled) => {
  var _a, _b;
  const [translate] = useTranslation();
  const riskLevelChoices = ["0", "1", "2", "3", "4"].map((riskLevel) => ({
    id: riskLevel,
    name: translate("tesseract.dashboard.filters.riskLevelChoice", { context: riskLevel })
  }));
  const getCountryNameByCode = usei18nCountries();
  const geoLocations = getArrayDataContent((_a = useCrudProps(apiResources.geoLocations, {}, { cache: true })[0]) == null ? void 0 : _a.data).map((countryCode) => ({
    name: getCountryNameByCode(countryCode),
    key: countryCode
  }));
  const sdwanCategories = getArrayDataContent(useCrudProps(apiResources.sdwanAppCategories)[0]);
  const visibleFilters = Object.keys(activeFilters);
  const [filterListOpen, setFilterListOpen] = useState(null);
  const classes = useStyles();
  const isTimeFrameOrApplianceFilter = (source) => source === "applianceId" || source === "timeframe";
  const renderFilter = (targetFilter) => {
    var _b2;
    const _a2 = targetFilter, { source, component } = _a2, targetFilterProps = __objRest(_a2, ["source", "component"]);
    const value = ((_b2 = activeFilters == null ? void 0 : activeFilters[source]) == null ? void 0 : _b2.key) || (activeFilters == null ? void 0 : activeFilters[source]) || "";
    return /* @__PURE__ */ jsxs(Grid, { className: isTimeFrameOrApplianceFilter(source) ? classes.filterContainerTimeAndAppliance : classes.filterContainer, children: [
      React.createElement(component, __spreadValues({
        id: "cuda-filter-input-" + source.replace(/[^a-zA-Z0-9]/g, "-"),
        onChange: (eventOrValue) => {
          const value2 = (eventOrValue == null ? void 0 : eventOrValue.target) ? eventOrValue.target.value : eventOrValue;
          updateFilter(value2, source);
        },
        value,
        variant: "outlined",
        className: classes.filterField,
        disabled
      }, targetFilterProps)),
      !isTimeFrameOrApplianceFilter(source) ? /* @__PURE__ */ jsx(
        IconButton,
        {
          size: "small",
          className: classes.filterRemoveIcon,
          onClick: () => removeFilter(source),
          children: /* @__PURE__ */ jsx(Close, {})
        }
      ) : null
    ] });
  };
  const filters = [
    {
      component: Select,
      source: "timeframe",
      label: "tesseract.dashboard.filters.timeframe",
      choices: timeframes
    },
    {
      component: Select,
      source: "applianceId",
      label: "tesseract.dashboard.filters.appliance",
      choices: appliances,
      disabled: disabled || appliances.length < 1 || ((_b = appliances[0]) == null ? void 0 : _b.key) === "_NONE_"
    },
    {
      component: SearchableSelect,
      source: "applicationDataPoints",
      label: "tesseract.dashboard.filters.application",
      options: {
        label: "tesseract.dashboard.filters.application"
      },
      staticChoices: getStaticChoiceFromFilter("applicationDataPoints", activeFilters, "id"),
      resource: apiResources.applications,
      optionValue: "id",
      filterKey: "applicationId",
      clearOnFocus: true
    },
    {
      component: Select,
      source: "sdwanCategoryDataPoints",
      label: "tesseract.dashboard.filters.sdwanCategory",
      choices: sdwanCategories
    },
    {
      component: Text,
      source: "domainDataPoints",
      label: "tesseract.dashboard.filters.destinationDomain"
    },
    {
      component: SearchableSelect,
      source: "geoDestinationDataPoints",
      label: "tesseract.dashboard.filters.geoDestination",
      options: {
        label: "tesseract.dashboard.filters.geoDestination"
      },
      choices: geoLocations,
      clearOnFocus: true
    },
    {
      component: SearchableSelect,
      source: "geoSourceDataPoints",
      label: "tesseract.dashboard.filters.geoSource",
      options: {
        label: "tesseract.dashboard.filters.geoSource"
      },
      choices: geoLocations,
      clearOnFocus: true
    },
    {
      component: SearchableSelect,
      source: "ipsDataPoints",
      label: "tesseract.dashboard.filters.ipsEvent",
      options: {
        label: "tesseract.dashboard.filters.ipsEvent"
      },
      staticChoices: getStaticChoiceFromFilter("ipsDataPoints", activeFilters, "id"),
      resource: apiResources.ipsExploits,
      optionValue: "id",
      clearOnFocus: true
    },
    {
      component: Select,
      source: "atdDataPoints",
      label: "tesseract.dashboard.filters.malwareFile",
      choices: getStaticChoiceFromFilter("atdDataPoints", activeFilters),
      hidden: true
    },
    {
      component: Select,
      source: "riskDataPoints",
      label: "tesseract.dashboard.filters.riskLevel",
      optionValue: "id",
      choices: riskLevelChoices
    },
    // TODO: Removing URL Category searchable select input for now until the API is fixed, just using hidden select input.
    // {
    //     component: SearchableSelect,
    //     source: "urlCategoryDataPoints",
    //     label: "tesseract.dashboard.filters.urlCategory",
    //     options: {
    //         label: "tesseract.dashboard.filters.urlCategory"
    //     },
    //     resource: apiResources.urlFilterCategories,
    //     hidden: true
    // },
    {
      component: Select,
      source: "urlCategoryDataPoints",
      label: "tesseract.dashboard.filters.urlCategory",
      choices: getStaticChoiceFromFilter("urlCategoryDataPoints", activeFilters),
      hidden: true
    }
    // TODO: Removing this filter completely until it is fixed on the platform
    // {
    //     component: SearchableSelect,
    //     source: "userDataPoints",
    //     label: "tesseract.dashboard.filters.user",
    //     staticChoices: [{"id": "#", "name": "Not set"}],
    //     options: {
    //         label: "tesseract.dashboard.filters.user",
    //     },
    //     resource: apiResources.users,
    //     optionValue: "id"
    // },
  ];
  const filterButtons = /* @__PURE__ */ jsx(React.Fragment, { children: /* @__PURE__ */ jsxs(Grid, { className: classes.filterButtons, children: [
    /* @__PURE__ */ jsx(Typography, { className: classes.filterMessage, children: /* @__PURE__ */ jsx(Trans, { i18nKey: `tesseract.dashboard.filters.${disabled ? "disabledFilters" : "activeFilters"}`, children: /* @__PURE__ */ jsx(FilterIcon, { color: "primary", sx: { margin: "-6px -2px -6px -2px" } }) }) }),
    visibleFilters.length > 2 ? /* @__PURE__ */ jsx(
      Button,
      {
        onClick: () => clearAllFilters(),
        startIcon: /* @__PURE__ */ jsx(Close, {}),
        color: "secondary",
        variant: "contained",
        size: "small",
        className: classes.clearFilterButton,
        classes: { root: classes.buttonLabel },
        children: translate("tesseract.dashboard.filters.resetFilter")
      }
    ) : null,
    /* @__PURE__ */ jsx(ClickAwayListener, { onClickAway: () => setFilterListOpen(null), children: /* @__PURE__ */ jsxs(React.Fragment, { children: [
      /* @__PURE__ */ jsx(
        Button,
        {
          startIcon: /* @__PURE__ */ jsx(Filter, {}),
          color: "secondary",
          variant: "contained",
          size: "small",
          onClick: (event) => setFilterListOpen(event.currentTarget),
          disabled: disabled || visibleFilters.length >= filters.length,
          children: translate("tesseract.dashboard.filters.addFilter")
        }
      ),
      /* @__PURE__ */ jsx(
        Menu,
        {
          className: classes.list,
          anchorEl: filterListOpen,
          open: !!filterListOpen,
          onClose: () => setFilterListOpen(null),
          children: filters.filter((filter) => !visibleFilters.includes(filter.source) && !filter.hidden).map((filter) => /* @__PURE__ */ jsx(
            MenuItem,
            {
              onClick: () => updateFilter("", filter.source),
              children: filter.label && translate(filter.label)
            },
            filter.source
          ))
        }
      )
    ] }) })
  ] }) });
  const activeFilterInputs = /* @__PURE__ */ jsx(
    Collapse,
    {
      className: classes.filterBar,
      in: true,
      children: /* @__PURE__ */ jsxs(Grid, { container: true, spacing: 1, children: [
        filters.filter((filter) => visibleFilters.includes(filter.source)).map(renderFilter),
        visibleFilters.length > 2 || disabled ? null : /* @__PURE__ */ jsx(Typography, { className: classes.filterMessage, sx: { marginTop: -1 }, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.dashboard.filters.addFilterMessage" }) })
      ] })
    }
  );
  return { filterButtons, activeFilterInputs };
};
export {
  useFilterOptions_default as default,
  timeframes
};
