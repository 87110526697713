import { jsx, jsxs } from "react/jsx-runtime";
import { Popper } from "@barracuda-internal/bds-core";
import { get } from "lodash";
import React from "react";
import { getDataContent } from "../../../utils";
import LocationsCardContentTooltip from "../LocationsCardContentTooltip/LocationsCardContentTooltip";
import { useLocationsMap } from "../../../hooks";
import PropTypes from "prop-types";
const LocationsCardContent = (props) => {
  const { tooltipAnchor, tooltip, setTooltip, pointResourceData, seriesDetails, mapRef } = useLocationsMap(props);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx("div", { className: "in-highchart", ref: mapRef }),
    /* @__PURE__ */ jsx(
      Popper,
      {
        anchorEl: tooltipAnchor,
        open: !!tooltipAnchor,
        placement: "right",
        children: /* @__PURE__ */ jsx(
          LocationsCardContentTooltip,
          {
            data: Object.assign(
              {},
              get(tooltip, "point.pointData"),
              getDataContent(pointResourceData)
            ),
            onClose: () => setTooltip(null),
            children: seriesDetails && seriesDetails.fields
          }
        )
      }
    )
  ] });
};
LocationsCardContent.propTypes = {
  /**
   * data to use, if you have not provided a target CRUD resource in either top level nor on any pointType
   */
  data: PropTypes.arrayOf(PropTypes.object),
  /**
   * disables the auto-zoom-to-fit action that is applied when data is loaded.
   */
  disableZoomOnLoad: PropTypes.bool,
  /**
   * enables the legend for the map.
   */
  legendEnabled: PropTypes.bool,
  /**
   * a [CRUD](/?path=/docs/key-concepts-crud) resource to fetch data from, via a CRUD subscription. Can also be supplied on a
   * per point-type basis, within the pointTypes prop.
   */
  resource: PropTypes.string,
  /**
   * [CRUD](/?path=/docs/key-concepts-crud) params to use when fetching data via a CRUD subscription.
   */
  params: PropTypes.object,
  /**
   * definition of how each map data series should be displayed, and how it can be extracted from the supplied data.
   *
   * @param {string} color the color of the points.
   * @param {object} details if provided, when a series is clicked on a tooltip will be displayed, with a ListCardContent content.
   * @param {node[]} details.fields array of children to pass to the ListCardContent. Each child will be cloned and passed "data", which is the data associated with this point.
   * @param {string} details.optionValue a "source" dot-path for a value to use as the "id" param when performing a CRUD for further data. Only used if details.resource is provided.
   * @param {string} details.resource a CRUD resource to fetch data from and merge with the data provided for the point.
   * @param {function} filter function passed to Array.filter() to filter the provided data, returning true only for those entries that belong to this series. The data associated with the point is available at ".pointData"
   * @param {string} label series label, which will be displayed in the legend (if enabled) .
   * @param {number} legendIndex an index to determine the order in which this series will appear in the legend. by default they will appear in the order defined by the pointTypes array.
   * @param {object} linkedSeries if set, whenever an entry in this series is clicked, links will be shown connected it to all its "linked" entries. Another series' entry is "linked" to the clicked target entry in this series if either: it's data was collected via a "source" property on the target entry; OR the target entry was collected via a "source" property on the other series' entry.
   * @param {string} linkedSeries.color the color to use when highlighting the linked entries.
   * @param {string} linkedSeries.label the label to use in the legend to describe the linked entries.
   * @param {number} linkedSeries.zIndex the zIndex to use for the highlighted linked entries.
   * @param {number} linkedSeries.selectedZIndex the zIndex to use for the highlighted clicked entry.
   * @param {string} resource a CRUD resource to fetch data from, via a CRUD subscription. If not set, and source is not set, the main resource or provided data will be used.
   * @param {string} source a "source" property from each of the main resource/data's entries to use to create this series. All matching values will be concatenated to create this series.
   * @param {number} zIndex the zIndex to use for this series data points
   */
  series: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      details: PropTypes.shape({
        fields: PropTypes.arrayOf(PropTypes.node),
        optionValue: PropTypes.string,
        resource: PropTypes.string
      }),
      filter: PropTypes.func,
      label: PropTypes.string,
      legendIndex: PropTypes.number,
      linkedSeries: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
          color: PropTypes.string,
          label: PropTypes.string
        })
      ]),
      resource: PropTypes.string,
      source: PropTypes.string,
      zIndex: PropTypes.number
    })
  ).isRequired,
  /**
   * A dot-path key to a properties in the returned data array that defines the overall status of the data. This status is then used to call the
   * updateState callback (if provided). The worst status found is always used.
   */
  statusAvatarAggregateSource: PropTypes.string,
  /**
   * A dot-path key to a property in the returned data that defines the overall status of the data. This status is then used to call the
   * updateState callback (if provided).
   */
  statusAvatarSource: PropTypes.string,
  /**
   * callback called whenever the global status changes. This is usually provided by TabbedCard.
   */
  updateState: PropTypes.func,
  /**
   * if false, content is not rendered (although CRUD data is still collected).
   *
   * this prop is passed down by [TabbedCard](/?path=/docs/core-components-cards-tabbedcard--tabbed-card) when tab is not currently selected.
   */
  visible: PropTypes.bool
};
LocationsCardContent.defaultProps = {
  visible: true
};
var LocationsCardContent_default = LocationsCardContent;
export {
  LocationsCardContent,
  LocationsCardContent_default as default
};
