import { maxBy, minBy } from "lodash";
const zoomToFit = (chart, pointsToFit, redraw = false) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  if (!pointsToFit || !pointsToFit.length) {
    return;
  }
  const getPointXY = (point) => {
    var _a2, _b2;
    return "x" in point ? point : (_b2 = (_a2 = chart.current) == null ? void 0 : _a2.fromLatLonToPoint) == null ? void 0 : _b2.call(_a2, point);
  };
  const parsedPointsToFit = pointsToFit.filter((point) => !!point).flatMap((point) => (point == null ? void 0 : point.clusteredData) ? point.clusteredData.filter((cluster) => !!cluster).map(getPointXY) : getPointXY(point));
  const paddingCoefficient = 0.2;
  const minWidth = 1e3;
  const minHeight = minWidth * (9 / 16);
  const xAxis = (_a = chart.current) == null ? void 0 : _a.xAxis;
  const yAxis = (_b = chart.current) == null ? void 0 : _b.yAxis;
  const minX = ((_c = minBy(parsedPointsToFit, (point) => point == null ? void 0 : point.x)) == null ? void 0 : _c.x) || ((_d = xAxis == null ? void 0 : xAxis[0]) == null ? void 0 : _d.min) || 0;
  const maxX = ((_e = maxBy(parsedPointsToFit, (point) => point == null ? void 0 : point.x)) == null ? void 0 : _e.x) || ((_f = xAxis == null ? void 0 : xAxis[0]) == null ? void 0 : _f.max) || minWidth;
  const minY = ((_g = minBy(parsedPointsToFit, (point) => point == null ? void 0 : point.y)) == null ? void 0 : _g.y) || ((_h = yAxis == null ? void 0 : yAxis[0]) == null ? void 0 : _h.min) || 0;
  const maxY = ((_i = maxBy(parsedPointsToFit, (point) => point == null ? void 0 : point.y)) == null ? void 0 : _i.y) || ((_j = yAxis == null ? void 0 : yAxis[0]) == null ? void 0 : _j.max) || minHeight;
  const xPadding = (maxX - minX) * paddingCoefficient;
  const yPadding = (maxY - minY) * paddingCoefficient;
  let leftBound = minX - xPadding;
  let rightBound = maxX + xPadding;
  let topBound = minY - yPadding;
  let bottomBound = maxY + yPadding;
  const xRange = rightBound - leftBound;
  const yRange = bottomBound - topBound;
  if (xRange <= minWidth) {
    const additionalPadding = (minWidth - xRange) / 2;
    leftBound = leftBound - additionalPadding;
    rightBound = rightBound + additionalPadding;
  }
  if (yRange <= minHeight) {
    const additionalPadding = (minHeight - yRange) / 2;
    topBound = topBound - additionalPadding;
    bottomBound = bottomBound + additionalPadding;
  }
  (_k = xAxis == null ? void 0 : xAxis[0]) == null ? void 0 : _k.setExtremes(leftBound, rightBound, false);
  (_l = yAxis == null ? void 0 : yAxis[0]) == null ? void 0 : _l.setExtremes(topBound, bottomBound, redraw);
};
export {
  zoomToFit
};
