var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { BooleanField, CustomField, getArrayDataContent, SelectedChoiceField, TableInput, TextField, useCrudProps } from "@cuda-react/core";
import { useNetworkFormValidation } from "../../../../hooks/createEditSiteHooks";
import SCWanTableInputForm from "./SCWanTableInputForm";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../apiResources";
const SCWanTableInput = () => {
  var _a;
  const [translate] = useTranslation();
  const networkFormValidation = useNetworkFormValidation();
  const wanTypesData = getArrayDataContent((_a = useCrudProps(apiResources.siteModes)[0]) == null ? void 0 : _a.data);
  const wanTypesChoices = wanTypesData.filter((obj) => obj.key !== "pppoe");
  const { setValue } = useFormContext();
  const [wans = [], firstWanMode, serial] = useWatch({ name: ["wans", "wans[0].mode", "serials[0]"] });
  const wansLength = wans.length;
  const fallbackDisabled = firstWanMode === "wwan";
  const noCreate = wansLength === 2 || fallbackDisabled;
  useEffect(() => {
    wansLength > 0 && setValue("wans[0].provider", "group_1");
    wansLength > 1 && setValue("wans[1].provider", "fallback");
  }, [wansLength]);
  return /* @__PURE__ */ jsxs(
    TableInput,
    {
      source: "wans",
      optionValue: "name",
      labels: {
        addTitle: "tesseract.appliances.settings.wan.addTitle",
        addButtonText: "tesseract.appliances.settings.wan.addSCButtonText",
        editTitle: "tesseract.appliances.settings.wan.editTitle",
        deleteTitle: "tesseract.appliances.settings.wan.deleteTitle",
        deleteMessage: "tesseract.appliances.settings.wan.deleteMessage"
      },
      formValidate: (...args) => {
        const errors = networkFormValidation(...args);
        if (errors == null ? void 0 : errors.port) {
          return __spreadProps(__spreadValues({}, errors), {
            mode: translate("tesseract.appliances.settings.wan.validateOnePort")
          });
        }
        return errors;
      },
      formatFormData: (newData) => __spreadProps(__spreadValues({}, newData), {
        port: newData.mode === "wwan" ? void 0 : newData.port
      }),
      minimumItems: 1,
      formChildren: /* @__PURE__ */ jsx(SCWanTableInputForm, { wanTypes: wanTypesData, wansLength, serial }),
      noCreate,
      children: [
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.appliances.settings.wan.name"
          }
        ),
        /* @__PURE__ */ jsx(
          SelectedChoiceField,
          {
            source: "mode",
            choices: wanTypesChoices,
            label: "tesseract.appliances.settings.wan.type"
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "provider",
            label: "tesseract.appliances.settings.wan.fallback",
            width: 154,
            render: (value) => value === "fallback" ? /* @__PURE__ */ jsx(
              BooleanField,
              {
                data: { value: true },
                source: "value"
              }
            ) : null
          }
        )
      ]
    }
  );
};
var SCWanTableInput_default = SCWanTableInput;
export {
  SCWanTableInput_default as default
};
