import { jsx, jsxs } from "react/jsx-runtime";
import { CircularProgress, Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    width: "calc(100% - 2rem)",
    height: "fit-content",
    padding: "0.5rem 1rem",
    textAlign: "center",
    display: "inline-block"
  },
  spinner: {
    padding: "0 0.5rem",
    display: "inline-block"
  },
  loadingMessage: {
    margin: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    textAlign: "center",
    fontSize: 20,
    display: "inline-block"
  }
});
const useStyles = makeOverrideableStyles("LoadingMessage", styles);
const LoadingMessage = (props) => {
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs("span", { className: classes.root, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.loadingMessage, children: translate("cuda.cards.loading") }),
    /* @__PURE__ */ jsx(CircularProgress, { size: 20, className: classes.spinner }, "spinner")
  ] });
};
var LoadingMessage_default = LoadingMessage;
export {
  LoadingMessage_default as default,
  styles
};
