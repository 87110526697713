var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { useEffect, useState } from "react";
import apiResources from "../../apiResources";
import { timeframes } from "./useFilterOptions";
import { CrudTypes, useCrudSubscription, useDeepCompareEffect } from "@cuda-react/core";
const pollIntervals = {
  long: 3e5,
  medium: 5e3,
  short: 2e3
};
var useReportingApiPolling_default = (activeFilters, disabled, appliances, appliancesLoading) => {
  var _b, _c;
  const [pollInterval, setPollInterval] = useState(pollIntervals.short);
  const [pollCount, setPollCount] = useState(0);
  const _a = activeFilters, { applianceId, timeframe } = _a, otherFilters = __objRest(_a, ["applianceId", "timeframe"]);
  let applianceApi;
  const applianceType = (_b = appliances.find((item) => item.key === applianceId)) == null ? void 0 : _b.type;
  if (applianceType === "site") {
    applianceApi = apiResources.siteStatistics;
  } else if (applianceType === "on-prem") {
    applianceApi = apiResources.onpremStatistics;
  } else
    applianceApi = apiResources.managedGatewayStatistics;
  const selectedTimeframe = timeframes.find((item) => item.key === timeframe) || timeframes[0];
  const validOtherFilters = Object.keys(otherFilters).reduce((result, key) => {
    const value = otherFilters[key];
    if (value !== void 0 && value !== null && value !== "" && !(Array.isArray(value) && value.length === 0) && Object.keys(value).length > 0) {
      if ((value == null ? void 0 : value.key) && (value == null ? void 0 : value.name)) {
        result[key] = value == null ? void 0 : value.key;
      } else {
        result[key] = value;
      }
    }
    return result;
  }, {});
  const filter = __spreadValues({
    unit: selectedTimeframe.unit,
    duration: selectedTimeframe.duration
  }, validOtherFilters);
  const [{ data }, loading, , clearState] = useCrudSubscription(
    CrudTypes.GET,
    applianceId && applianceId !== "_NONE_" && appliances.length > 0 && !disabled ? applianceApi : void 0,
    {
      id: applianceId,
      filter
    },
    { pollInterval }
  );
  useEffect(() => {
    if (!loading) {
      setPollCount((count) => count + 1);
    }
  }, [loading]);
  useDeepCompareEffect(() => {
    if (!loading && (data == null ? void 0 : data.instanceId) && pollCount >= 2 && pollInterval < pollIntervals.long) {
      setPollInterval(pollIntervals.long);
    } else if (pollCount > 5 && pollInterval < pollIntervals.medium) {
      setPollInterval(pollIntervals.medium);
    } else if (pollCount > 10 && pollInterval < pollIntervals.long) {
      setPollInterval(pollIntervals.long);
    }
  }, [loading, data, pollCount]);
  useDeepCompareEffect(() => {
    clearState();
    setPollInterval(pollIntervals.short);
    setPollCount(0);
  }, [applianceId, filter]);
  return {
    data: (data == null ? void 0 : data.instanceId) ? data : {},
    loading: appliancesLoading || loading || appliances.length > 0 && ((_c = appliances[0]) == null ? void 0 : _c.key) !== "_NONE_" && !(data == null ? void 0 : data.instanceId) && pollInterval === pollIntervals.short
  };
};
export {
  useReportingApiPolling_default as default,
  pollIntervals
};
