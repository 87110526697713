var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import React from "react";
const defaultAlternateConditionalWrapper = (children, props) => React.Children.map(
  children,
  (child) => child && React.cloneElement(child, props) || null
);
const ConditionalWrapper = (_a) => {
  var _b = _a, { alternateWrapper = defaultAlternateConditionalWrapper, condition, wrapper, children } = _b, props = __objRest(_b, ["alternateWrapper", "condition", "wrapper", "children"]);
  const conditionValue = typeof condition === "function" ? condition(props) : condition;
  return /* @__PURE__ */ jsx(React.Fragment, { children: conditionValue ? wrapper(children, props) : alternateWrapper(children, props) });
};
var ConditionalWrapper_default = ConditionalWrapper;
export {
  ConditionalWrapper,
  ConditionalWrapper_default as default,
  defaultAlternateConditionalWrapper
};
