import { jsx } from "react/jsx-runtime";
import { SvgIcon, useTheme } from "@mui/material";
import classNames from "classnames";
const Large = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-graphic"),
      id: "cuda-gridsize-large",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx("path", { style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 }, d: "M7.7,7.7v44.5h44.5V7.7H7.7Z" })
    }
  );
};
export {
  Large
};
