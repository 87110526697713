var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { BaseBarCard } from "../../baseCards";
import { colorFunctionError, numberFormatter } from "../../../utils";
import { useTranslation } from "react-i18next";
import { useReportingData } from "../../../contexts";
import { CardSizes } from "../../../typesAndConstants";
import { sortBy } from "lodash";
import { AppCategoriesIcon } from "../../icons";
import { useTheme } from "@mui/material";
const TopBlockedApplicationCategoriesCard = (props) => {
  const { preview, size } = props;
  const [translate] = useTranslation();
  const { data, loading, addFilter } = useReportingData("TopBlockedApplicationCategoriesCard", preview);
  const { blockedSdwanCategoryDataPoints = [] } = data || {};
  const maxEntry = Math.max(...blockedSdwanCategoryDataPoints.map((domainDataPoint) => domainDataPoint.numberOfSessions));
  const theme = useTheme();
  const entries = sortBy(blockedSdwanCategoryDataPoints, "numberOfSessions").reverse().map((domainDataPoint) => ({
    title: domainDataPoint.label,
    value: size === CardSizes.small ? numberFormatter(domainDataPoint.numberOfSessions) : translate("stratosReporting.topBlockedDomainsCard.sessions", { formattedCount: numberFormatter(domainDataPoint.numberOfSessions) }),
    icon: /* @__PURE__ */ jsx(AppCategoriesIcon, { size: 20 }),
    barProgress: 100 / maxEntry * domainDataPoint.numberOfSessions,
    color: colorFunctionError(100 / maxEntry * domainDataPoint.numberOfSessions, theme),
    onClick: addFilter ? () => addFilter(domainDataPoint.id, "sdwanCategoryDataPoints") : void 0,
    tooltipMessage: addFilter ? "stratosReporting.filterTooltip" : void 0
  }));
  return /* @__PURE__ */ jsx(
    BaseBarCard,
    __spreadProps(__spreadValues({}, props), {
      entries,
      title: "stratosReporting.blockedApplicationCategories.title",
      loading: loading && !data.blockedSdwanCategoryDataPoints,
      noData: !loading && !data.blockedSdwanCategoryDataPoints,
      activeFilter: true
    })
  );
};
export {
  TopBlockedApplicationCategoriesCard
};
