import { jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@barracuda-internal/bds-core";
import { get } from "lodash";
import {
  BooleanInput,
  CustomField,
  SelectArrayInput,
  SelectInput,
  StatusIconField,
  TableInput,
  TextField,
  TextInput
} from "@cuda-react/core";
import { BooleanIcon } from "@cuda-react/icons";
import { useTranslation } from "react-i18next";
import {
  getNetworkNameFromPorts,
  useNetworkFormValidation,
  useSiteInputValidations
} from "../../../../hooks/createEditSiteHooks";
import { useWatch } from "react-hook-form";
const LanTableInput = ({ tableRowDimmed, portsData }) => {
  const [serials = [], wans = []] = useWatch({ name: ["serials", "wans"] });
  const [translate] = useTranslation();
  const inputValidations = useSiteInputValidations();
  const networkFormValidation = useNetworkFormValidation();
  const getNetworkName = getNetworkNameFromPorts(portsData);
  const fullWanTypeData = [
    {
      mode: "default",
      name: "tesseract.appliances.dialog.lans.bridgeType.default"
    },
    {
      mode: "bridge",
      name: "tesseract.appliances.dialog.lans.bridgeType.name"
    }
  ];
  const hasWanBridge = wans.some((wan) => wan.mode === "bridge");
  return /* @__PURE__ */ jsxs(
    TableInput,
    {
      source: "lans",
      optionValue: "name",
      labels: {
        addTitle: "tesseract.appliances.settings.lan.addTitle",
        addButtonText: "tesseract.appliances.settings.lan.addButtonText",
        editTitle: "tesseract.appliances.settings.lan.editTitle",
        deleteTitle: "tesseract.appliances.settings.lan.deleteTitle",
        deleteMessage: /* @__PURE__ */ jsxs(Typography, { children: [
          /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.appliances.settings.lan.deleteMessage1") }) }),
          /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.appliances.settings.lan.deleteMessage2") }) }),
          /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.appliances.settings.lan.deleteMessage3") }) }),
          /* @__PURE__ */ jsx("p", { children: /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.appliances.settings.lan.deleteMessage4") }) })
        ] })
      },
      formValidate: networkFormValidation,
      rowDimmed: tableRowDimmed,
      minimumItems: hasWanBridge ? 0 : 1,
      defaultFormValues: { dhcp: { type: "disabled", defaultLeaseTime: "86400", maxLeaseTime: "86400" } },
      minCellWidth: 128,
      children: [
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.appliances.settings.lan.name",
            inputComponent: TextInput,
            inputProps: {
              validate: inputValidations.validateName,
              description: "tesseract.appliances.settings.lan.descriptions.name",
              isRequired: true
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "mode",
            label: "tesseract.appliances.dialog.lans.type",
            inputComponent: SelectInput,
            inputProps: {
              description: "tesseract.appliances.dialog.lans.descriptions.type",
              choices: fullWanTypeData,
              optionValue: "mode",
              optionText: "name"
            },
            render: () => {
            },
            hidden: true
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "lanPorts",
            label: "tesseract.appliances.dialog.lans.bridgeType.lanPorts",
            inputComponent: SelectArrayInput,
            inputProps: {
              description: "tesseract.appliances.dialog.lans.bridgeType.description.lanPorts",
              choices: portsData,
              optionValue: "port",
              optionText: "description",
              hide: (value, data) => get(data, "mode") !== "bridge"
            },
            render: () => {
            },
            hidden: true
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "address",
            label: "tesseract.appliances.dialog.lans.bridgeType.address",
            inputComponent: TextInput,
            inputProps: {
              description: "tesseract.appliances.dialog.lans.bridgeType.description.address",
              validate: inputValidations.validatePrivateIp,
              hide: (value, data) => get(data, "mode") !== "bridge",
              isRequired: true
            },
            render: () => {
            },
            hidden: true
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "netmask",
            label: "tesseract.appliances.dialog.lans.bridgeType.netmask",
            inputComponent: TextInput,
            inputProps: {
              description: "tesseract.appliances.dialog.lans.bridgeType.description.netmask",
              validate: inputValidations.validateNetmask,
              hide: (value, data) => get(data, "mode") !== "bridge",
              isRequired: true
            },
            render: () => {
            },
            hidden: true
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "port",
            label: "tesseract.appliances.settings.lan.port",
            inputComponent: SelectInput,
            inputProps: {
              description: "tesseract.appliances.settings.lan.descriptions.port",
              choices: portsData,
              optionValue: "port",
              optionText: "description",
              hide: (value, data) => get(data, "mode") === "bridge"
            },
            render: (value, data) => getNetworkName(data)
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "virtualLanId",
            label: "tesseract.appliances.settings.wan.virtualLanId",
            inputComponent: TextInput,
            inputProps: {
              description: "tesseract.appliances.settings.wan.descriptions.virtualLanId",
              validate: inputValidations.validateVlanId,
              hide: (value, data) => get(data, "mode") === "bridge"
            },
            hidden: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "address",
            label: "tesseract.appliances.settings.lan.address",
            inputComponent: TextInput,
            inputProps: {
              validate: inputValidations.validatePrivateIp,
              description: "tesseract.appliances.settings.lan.descriptions.address",
              isRequired: true,
              hide: (value, data) => get(data, "mode") === "bridge"
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "netmask",
            label: "tesseract.appliances.settings.lan.netmask",
            inputComponent: TextInput,
            inputProps: {
              validate: inputValidations.validateNetmask,
              description: "tesseract.appliances.settings.lan.descriptions.netmask",
              isRequired: true,
              hide: (value, data) => get(data, "mode") === "bridge"
            }
          }
        ),
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "failover",
            label: "tesseract.appliances.settings.lan.failover",
            inputComponent: BooleanInput,
            inputProps: {
              description: "tesseract.appliances.settings.lan.descriptions.failover",
              disabled: !serials || serials.length < 2,
              hide: (value, data) => get(data, "mode") === "bridge"
            },
            icon: /* @__PURE__ */ jsx(BooleanIcon, {}),
            hidden: !serials || serials.length < 2,
            getStatusColorFromTheme: (status, theme) => status ? theme.palette.success.main : theme.palette.info.main
          }
        )
      ]
    }
  );
};
var LanTableInput_default = LanTableInput;
export {
  LanTableInput_default as default
};
