import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import { useTheme } from "@mui/material";
const AlertIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-alert",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.error) == null ? void 0 : _a.main, strokeWidth: 0 },
          d: "M30,5.5c-13.5,0-24.5,11-24.5,24.5s11,24.5,24.5,24.5,24.5-11,24.5-24.5S43.5,5.5,30,5.5ZM32.5,42.2h-4.9v-4.9h4.9v4.9ZM32.5,32.4h-4.9v-14.7h4.9v14.7Z"
        }
      )
    }
  );
};
var AlertIcon_default = AlertIcon;
export {
  AlertIcon,
  AlertIcon_default as default
};
