import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import {
  ChipArrayField,
  getArrayDataContent,
  SelectedChoiceField,
  TextField,
  useCrudProps,
  WizardSummary
} from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../../apiResources";
import { SELECT_EMPTY_VALUE } from "../../../../../utils/constants";
const useStyles = makeStyles((theme) => ({
  haMessage: {
    color: theme.palette.warning.main
  },
  summaryChipArray: {
    maxWidth: 220
  }
}));
const PromoteSubmitStep = ({ choices, data }) => {
  var _a;
  const [translate] = useTranslation();
  const classes = useStyles();
  const gatewaysData = getArrayDataContent((_a = useCrudProps(apiResources.gatewayNames)[0]) == null ? void 0 : _a.data);
  const gatewayChoices = [
    {
      key: SELECT_EMPTY_VALUE,
      name: "tesseract.appliances.dialog.submit.noGateway"
    },
    ...gatewaysData
  ];
  const selectedSite = choices == null ? void 0 : choices.find((site) => site.id === (data == null ? void 0 : data.siteId));
  const siteIsHa = selectedSite && selectedSite.serials && selectedSite.serials.length > 1;
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(WizardSummary, { columns: [{ xs: 12, sm: 5 }, { xs: 12, sm: 7 }], data: selectedSite, children: [
      /* @__PURE__ */ jsx(
        TextField,
        {
          column: 0,
          source: "name",
          label: "tesseract.appliances.dialog.submit.name"
        }
      ),
      /* @__PURE__ */ jsx(
        TextField,
        {
          column: 0,
          source: "model",
          label: "tesseract.appliances.dialog.submit.model"
        }
      ),
      /* @__PURE__ */ jsx(
        ChipArrayField,
        {
          column: 0,
          source: "serials",
          label: "tesseract.appliances.dialog.submit.serial"
        }
      ),
      /* @__PURE__ */ jsx(
        SelectedChoiceField,
        {
          source: "gateway",
          label: "tesseract.appliances.dialog.submit.gateway",
          column: 1,
          choices: gatewayChoices
        }
      ),
      /* @__PURE__ */ jsx(
        ChipArrayField,
        {
          source: "wans",
          label: "tesseract.appliances.dialog.submit.wan",
          render: (wan) => wan && Object.keys(wan),
          column: 1,
          classes: { root: classes.summaryChipArray }
        }
      )
    ] }),
    !siteIsHa ? /* @__PURE__ */ jsx(Typography, { className: classes.haMessage, children: translate("tesseract.appliances.dialog.submit.haWarning") }) : null
  ] });
};
var PromoteSubmitStep_default = PromoteSubmitStep;
export {
  PromoteSubmitStep_default as default
};
