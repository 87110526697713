var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { ListItemIcon, ListItemText, MenuItem, Select } from "@barracuda-internal/bds-core";
import { isEmpty } from "lodash";
import get from "lodash/get";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrudTypes } from "../../../clients";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useCrudFetch } from "../../../hooks";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  select: {
    width: (props) => props.width ? props.width : 200,
    "& fieldset": {
      borderWidth: 0
    },
    "&:hover,:focus": {
      "& fieldset": {
        borderWidth: 1
      }
    },
    marginLeft: -8
  },
  selectInput: {
    overflow: "visible"
  },
  listText: {
    display: "inline-flex",
    margin: 0,
    color: theme.palette.common.black
  },
  listItemIcon: {
    minWidth: 32,
    verticalAlign: "middle"
    // marginTop: -2
  },
  inset: {
    paddingLeft: 32
  }
});
const useStyles = makeOverrideableStyles("SelectField", styles);
const SelectField = (props) => {
  const { data = {}, resource, source = "", id, defaultChoice, choices, optionValue = "key", optionText = "name", disabled, icon, iconMap = {}, onSuccess } = props;
  const classes = useStyles(props);
  const dataValue = get(data, source);
  const [translate] = useTranslation();
  const [option, setOption] = useState(dataValue || defaultChoice || get(choices == null ? void 0 : choices[0], optionValue));
  const requestResourceId = typeof id === "function" ? id(data) : id;
  const [, , performFetch] = useCrudFetch(CrudTypes.UPDATE, resource);
  const handleChange = (event) => {
    performFetch(
      {
        id: requestResourceId,
        data: __spreadProps(__spreadValues({}, data), {
          [source]: event.target.value
        })
      }
    ).then((response) => onSuccess == null ? void 0 : onSuccess(response == null ? void 0 : response.data));
    setOption(event.target.value);
  };
  useEffect(() => {
    setOption(dataValue);
  }, [dataValue, requestResourceId]);
  return choices && choices.length > 0 && /* @__PURE__ */ jsx(
    Select,
    {
      value: option,
      onChange: handleChange,
      inputProps: { className: classes.selectInput },
      className: classes.select,
      disabled,
      children: choices.map((choice) => {
        const choiceName = get(choice, optionText, "");
        const listIcon = iconMap[get(choice, optionValue)] || icon;
        const choiceValue = get(choice, optionValue);
        return /* @__PURE__ */ jsxs(MenuItem, { value: choiceValue, children: [
          listIcon && /* @__PURE__ */ jsx(ListItemIcon, { className: classes.listItemIcon, children: listIcon }),
          typeof choiceName === "string" ? /* @__PURE__ */ jsx(
            ListItemText,
            {
              primary: translate(choiceName),
              className: classes.listText,
              classes: { inset: classes.inset },
              inset: !isEmpty(iconMap) && !listIcon
            }
          ) : choiceName
        ] }, choiceValue);
      })
    }
  ) || null;
};
var SelectField_default = SelectField;
export {
  SelectField,
  SelectField_default as default,
  styles
};
