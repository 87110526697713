var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import {
  ActionButtonsField,
  ChipArrayField,
  CrudTypes,
  CustomField,
  DeleteDialog,
  DisableableButton,
  EmptyPageContent,
  getArrayDataContent,
  StatusIconField,
  TablePage,
  TextField,
  useCrudSubscription,
  useMixpanel
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import { CircularProgress, Dialog } from "@barracuda-internal/bds-core";
import CreateRule from "./createEdit/CreateRule";
import EditRule from "./createEdit/EditRule";
import { BooleanIcon, GetStartedBackgroundGraphic, IPsecConnectionGraphic } from "@cuda-react/icons";
import IpsecLocalPeerFilter from "./IpsecLocalPeerFilter";
import Ipsecv2TableMenu from "./Ipsecv2TableMenu";
const IpsecV2 = (props) => {
  var _a, _b;
  const registerAction = useMixpanel("IpsecV2");
  const ipSecTableRefreshRef = useRef(null);
  const [actionVisible, setActionVisible] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const ipsecSiteWans = getArrayDataContent((_a = useCrudSubscription(CrudTypes.GET, apiResources.ipsecApplicableSitesWans)[0]) == null ? void 0 : _a.data);
  const ipsecGatewayWans = getArrayDataContent((_b = useCrudSubscription(CrudTypes.GET, apiResources.ipsecApplicableGatewaysWans)[0]) == null ? void 0 : _b.data);
  const generatePeerData = (uuid, data) => {
    var _a2;
    const appliancesListType = ((_a2 = data == null ? void 0 : data.local) == null ? void 0 : _a2.type) === "gateway" ? ipsecGatewayWans : ipsecSiteWans;
    const appliance = Array.isArray(appliancesListType) ? appliancesListType.find((peer) => (peer == null ? void 0 : peer.uuid) === uuid) : appliancesListType;
    const wan = appliance == null ? void 0 : appliance.wans.find((peerWan) => {
      var _a3;
      return (peerWan == null ? void 0 : peerWan.key) === ((_a3 = data == null ? void 0 : data.local) == null ? void 0 : _a3.wan);
    });
    return appliance ? (appliance == null ? void 0 : appliance.name) + (wan ? ` (${wan == null ? void 0 : wan.name})` : "") : /* @__PURE__ */ jsx(CircularProgress, {});
  };
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      TablePage,
      __spreadProps(__spreadValues({
        titleActions: actionVisible && /* @__PURE__ */ jsx(
          CreateEditButtonDialog,
          {
            create: true,
            component: CreateRule,
            label: "tesseract.integration.ipSecV2.createButton",
            onClick: () => setCreateOpen(true),
            onSuccess: () => {
              var _a2;
              return (_a2 = ipSecTableRefreshRef.current) == null ? void 0 : _a2.call(ipSecTableRefreshRef);
            }
          }
        ),
        authenticated: true,
        resource: apiResources.ipsecV2,
        title: "tesseract.integration.pageTitle",
        subtitle: "tesseract.integration.ipSecV2.pageTitle",
        minCellWidth: 90,
        tableName: "IPsecVPN",
        resetOnGlobalParamChange: true,
        refreshRef: ipSecTableRefreshRef,
        resizable: true,
        reorderable: true,
        editColumns: true,
        params: (params) => {
          var _a2, _b2, _c, _d;
          return __spreadProps(__spreadValues({}, params), {
            filter: (params == null ? void 0 : params.filter) ? __spreadProps(__spreadValues({}, params.filter), {
              "localConfigurationUuid": (_b2 = (_a2 = params == null ? void 0 : params.filter) == null ? void 0 : _a2["local.uuid"]) == null ? void 0 : _b2.uuid,
              "localConfigurationType": (_d = (_c = params == null ? void 0 : params.filter) == null ? void 0 : _c["local.uuid"]) == null ? void 0 : _d["peer-type"]
            }) : void 0
          });
        },
        noDataPage: /* @__PURE__ */ jsx(
          EmptyPageContent,
          {
            background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
            icon: /* @__PURE__ */ jsx(IPsecConnectionGraphic, {}),
            iconHeader: "tesseract.integration.ipSecV2.noData.iconHeader",
            textHeader: "tesseract.integration.ipSecV2.noData.textHeader",
            text: "tesseract.integration.ipSecV2.noData.text",
            actions: /* @__PURE__ */ jsx(
              DisableableButton,
              {
                buttonText: "tesseract.integration.ipSecV2.noData.buttonText",
                onClick: () => setCreateOpen(true)
              }
            )
          }
        ),
        onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table")
      }, props), {
        children: [
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "name",
              label: "tesseract.integration.ipSecV2.table.name",
              filter: "text",
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "description",
              label: "tesseract.integration.ipSecV2.table.description",
              hideByDefault: true
            }
          ),
          /* @__PURE__ */ jsx(
            StatusIconField,
            {
              source: "enabled",
              label: "tesseract.integration.ipSecV2.table.enabled",
              filter: "select",
              filterProps: {
                choices: [
                  {
                    key: "true",
                    name: "tesseract.integration.ipSecV2.table.filters.enabled.enabled"
                  },
                  {
                    key: "false",
                    name: "tesseract.integration.ipSecV2.table.filters.enabled.disabled"
                  }
                ]
              },
              icon: /* @__PURE__ */ jsx(BooleanIcon, {}),
              width: 90
            }
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "local.uuid",
              label: "tesseract.integration.ipSecV2.table.siteWanInterface",
              render: (value, data) => generatePeerData(value, data),
              filter: "custom",
              filterProps: {
                component: IpsecLocalPeerFilter,
                ipsecSiteWans,
                ipsecGatewayWans
              }
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "remotes",
              label: "tesseract.integration.ipSecV2.table.gateway",
              render: (value) => value == null ? void 0 : value.gateway
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "type",
              label: "tesseract.integration.ipSecV2.table.type",
              defaultText: "IPSec IKEv2",
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "local.networks",
              label: "tesseract.integration.ipSecV2.table.localNetworks",
              render: (value) => value,
              hideByDefault: true
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "local",
              label: "tesseract.integration.ipSecV2.table.localBgp",
              render: (value) => value.bgpAddress ? value.bgpAddress + " - " + value.asn : [],
              hideByDefault: true
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "remotes[0].gateway",
              label: "tesseract.integration.ipSecV2.table.remoteNetworks",
              render: (value, data) => {
                var _a2;
                return (_a2 = data == null ? void 0 : data.remotes) == null ? void 0 : _a2.map((remote) => remote.networks ? remote.networks : []);
              },
              hideByDefault: true
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "remotes[0]",
              label: "tesseract.integration.ipSecV2.table.remoteBgp",
              render: (value, data) => data == null ? void 0 : data.remotes.map((remote) => remote.bgpAddress ? remote.bgpAddress + " - " + remote.asn : []),
              hideByDefault: true
            }
          ),
          /* @__PURE__ */ jsxs(
            ActionButtonsField,
            {
              width: 120,
              source: "id",
              alwaysVisible: true,
              children: [
                /* @__PURE__ */ jsx(
                  Ipsecv2TableMenu,
                  {
                    onSuccess: () => {
                      registerAction("Restart Tunnel");
                      ipSecTableRefreshRef.current;
                    }
                  }
                ),
                /* @__PURE__ */ jsx(
                  CreateEditButtonDialog,
                  {
                    component: EditRule,
                    onSuccess: () => {
                      registerAction("Update");
                      ipSecTableRefreshRef.current;
                    }
                  }
                ),
                /* @__PURE__ */ jsx(
                  DeleteDialog,
                  {
                    resource: apiResources.ipsecV2,
                    title: "tesseract.integration.ipSecV2.dialog.delete.title",
                    message: "tesseract.integration.ipSecV2.dialog.delete.body",
                    onSuccess: () => {
                      var _a2;
                      registerAction("IPSec Tunnel Delete");
                      (_a2 = ipSecTableRefreshRef.current) == null ? void 0 : _a2.call(ipSecTableRefreshRef);
                    }
                  }
                )
              ]
            }
          )
        ]
      })
    ),
    /* @__PURE__ */ jsx(
      Dialog,
      {
        open: createOpen,
        maxWidth: false,
        disableEnforceFocus: true,
        children: /* @__PURE__ */ jsx(
          CreateRule,
          {
            onSuccess: () => {
              var _a2;
              registerAction("Create");
              (_a2 = ipSecTableRefreshRef.current) == null ? void 0 : _a2.call(ipSecTableRefreshRef);
            },
            onClose: () => setCreateOpen(false)
          }
        )
      }
    )
  ] });
};
var Ipsecv2_default = IpsecV2;
export {
  Ipsecv2_default as default
};
