import { jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@barracuda-internal/bds-core";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {},
  content: {
    padding: theme.spacing(2)
  },
  stepTitle: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    fontSize: 18,
    fontWeight: 500
  }
});
const useStyles = makeOverrideableStyles("StepContent", styles);
const StepContent = (props) => {
  const { children, childProps, title } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs("div", { className: classes.root, children: [
    title && /* @__PURE__ */ jsx(Typography, { className: classes.stepTitle, children: translate(title) }),
    /* @__PURE__ */ jsx("div", { className: classes.content, children: childProps && React.Children.map(children, (child) => child && React.cloneElement(child, childProps)) || children })
  ] });
};
var StepContent_default = StepContent;
export {
  StepContent,
  StepContent_default as default
};
