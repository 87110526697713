var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { makeStyles } from "@mui/styles";
import {
  ActionButtonsField,
  ChipArrayField,
  CrudTypes,
  DeleteDialog,
  DisableableButton,
  EmptyPageContent,
  formatDateTime,
  getArrayDataContent,
  getDataContent,
  LinkField,
  SelectedChoiceField,
  StatusIconField,
  TablePage,
  TextField,
  useCrudProps,
  useCrudSubscription,
  useGlobalParam,
  useMixpanel
} from "@cuda-react/core";
import { Forward } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import { useHistory } from "react-router";
import apiResources from "../../../apiResources";
import { getAzureResourceLink } from "../../../utils/azureUtils";
import { AzureIcon, GetStartedBackgroundGraphic, IotGraphic, PrivateEdgeIcon } from "@cuda-react/icons";
import { PRIVATE_CLOUD_FILTER_KEY } from "../gateways/GatewayTable";
import CreateSCContent from "../../../components/appliances/create/CreateSCContent";
import { Dialog } from "@barracuda-internal/bds-core";
const useStyles = makeStyles((theme) => ({
  statusCell: {
    width: 32
  },
  actionsCell: {
    width: 64
  },
  maxWidthCell: {
    maxWidth: 128
  },
  gatewayIcon: {
    top: theme.spacing(0.75),
    position: "relative",
    paddingRight: theme.spacing(1)
  }
}));
const IotTable = (props) => {
  var _a, _b;
  const registerAction = useMixpanel("IoT");
  const [gatewaysData, loading] = useCrudSubscription(CrudTypes.GET, apiResources.gatewayNames);
  const gatewayChoices = [
    {
      key: -1,
      name: "tesseract.sites.table.noGateway"
    },
    ...getArrayDataContent(gatewaysData)
  ];
  const statusChoices = (_a = useCrudProps(apiResources.siteStates)[0]) == null ? void 0 : _a.data;
  const [pointToSite] = useCrudSubscription(CrudTypes.GET, apiResources.pointToSite, {}, { crudOptions: { quietErrors: true } });
  const pointToSiteEnabled = get(getDataContent(pointToSite), "enabled", false);
  const modelsData = (_b = useCrudProps(apiResources.allModelNames, {}, {}, true)[0]) == null ? void 0 : _b.data;
  const modelChoices = getArrayDataContent(modelsData).filter((model) => model.startsWith("FSC")).map((modelName) => ({ key: modelName.replace("_", "."), name: modelName.replace("_", ".") }));
  const [cloudVwanChoices] = useCrudSubscription(CrudTypes.GET, apiResources.cloudVwans);
  const noGateways = getArrayDataContent(gatewaysData).length === 0;
  const [translate] = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const isDemo = useGlobalParam("userData.mode")[0] === "demo";
  const tableRefreshRef = useRef(null);
  const [actionVisible, setActionVisible] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      TablePage,
      __spreadProps(__spreadValues({
        titleActions: actionVisible && /* @__PURE__ */ jsx(
          DisableableButton,
          {
            buttonText: "tesseract.sites.dialog.create.sc.createSC",
            disabledMessage: "tesseract.sites.dialog.create.sc.menuItemDisabled",
            disabled: noGateways || loading,
            onClick: () => setCreateOpen(true)
          }
        ),
        authenticated: true,
        resource: apiResources.sites,
        title: "tesseract.network.pageTitle",
        subtitle: "tesseract.network.iot.menuTitle"
      }, props), {
        noDataMessage: "tesseract.sites.table.noIot",
        minCellWidth: 160,
        tableName: "IoT",
        resizable: true,
        reorderable: true,
        editColumns: true,
        resetOnGlobalParamChange: true,
        refreshRef: tableRefreshRef,
        noDataPage: /* @__PURE__ */ jsx(
          EmptyPageContent,
          {
            background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
            icon: /* @__PURE__ */ jsx(IotGraphic, {}),
            iconHeader: "tesseract.network.iot.noData.iconHeader",
            textHeader: "tesseract.network.iot.noData.textHeader",
            text: "tesseract.network.iot.noData.text",
            actions: /* @__PURE__ */ jsx(
              DisableableButton,
              {
                buttonText: "tesseract.sites.dialog.create.sc.createSC",
                disabledMessage: "tesseract.sites.dialog.create.sc.menuItemDisabled",
                disabled: noGateways || loading,
                onClick: () => setCreateOpen(true)
              }
            )
          }
        ),
        onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table"),
        params: { filter: { modelSeries: "S" } },
        children: [
          /* @__PURE__ */ jsx(
            StatusIconField,
            {
              source: "aggregateState",
              cellClassName: classes.statusCell,
              width: 96,
              alwaysVisible: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "name",
              label: "tesseract.sites.table.name",
              filter: "text",
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "serials",
              label: "tesseract.sites.table.serial",
              cellClassName: classes.maxWidthCell,
              filter: "text",
              columnProps: {
                field: "serial"
              }
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "model",
              label: "tesseract.sites.table.model",
              cellClassName: classes.maxWidthCell,
              filter: "selectarray",
              filterProps: {
                choices: modelChoices
              },
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "gatewayName",
              label: "tesseract.sites.table.gateway",
              cellClassName: classes.maxWidthCell,
              filter: "selectarray",
              filterProps: {
                id: "gateway",
                source: "upstream",
                choices: gatewayChoices
              },
              columnProps: {
                field: "gateway"
              },
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            SelectedChoiceField,
            {
              source: "cloudVirtualWan",
              label: "tesseract.sites.table.cloudVirtualWan",
              choices: getArrayDataContent(cloudVwanChoices),
              optionValue: "id",
              cellClassName: classes.maxWidthCell,
              render: (value, data, choice) => {
                const hasCloudVwan = !!(data == null ? void 0 : data.cloudVirtualWan);
                let text = hasCloudVwan && (choice == null ? void 0 : choice.name) || "";
                text = text + ((choice == null ? void 0 : choice.description) ? ` (${choice == null ? void 0 : choice.description})` : "");
                return hasCloudVwan ? /* @__PURE__ */ jsxs(Fragment, { children: [
                  /* @__PURE__ */ jsx(
                    AzureIcon,
                    {
                      classes: { icon: classes.gatewayIcon }
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    LinkField,
                    {
                      source: "text",
                      data: { choice, text },
                      link: getAzureResourceLink("choice.resourceId", isDemo)
                    }
                  )
                ] }) : /* @__PURE__ */ jsxs(Fragment, { children: [
                  /* @__PURE__ */ jsx(
                    PrivateEdgeIcon,
                    {
                      classes: { icon: classes.gatewayIcon }
                    }
                  ),
                  translate("tesseract.gateways.table.privateCloud")
                ] });
              },
              filter: "select",
              filterProps: {
                source: "cloudVirtualWanId",
                optionValue: "id",
                choices: [
                  { id: PRIVATE_CLOUD_FILTER_KEY, name: translate("tesseract.gateways.table.privateCloud") },
                  ...getArrayDataContent(cloudVwanChoices).map((choice) => __spreadProps(__spreadValues({}, choice), { id: choice.id + "" }))
                ]
              },
              columnProps: {
                field: "cloudVirtualWanName"
              }
            }
          ),
          /* @__PURE__ */ jsx(
            SelectedChoiceField,
            {
              source: "connectionStatus.aggregateConnectionState",
              label: "tesseract.sites.table.status",
              choices: getArrayDataContent(statusChoices),
              cellClassName: classes.maxWidthCell,
              filter: "selectarray",
              filterProps: {
                id: "connection-state",
                choices: getArrayDataContent(statusChoices)
              },
              columnProps: {
                field: "connectionStatus"
              },
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "lanNetworks",
              label: "tesseract.sites.table.lan"
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "wans",
              render: (value) => Object.entries(value || {}).map(([name, network]) => `${name} (${network === "dynamic" || network === "wwan" ? translate("tesseract.sites.table." + network) : network})`),
              label: "tesseract.sites.table.wan"
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "licenseStatus.licenseStates",
              render: (val) => formatDateTime(val == null ? void 0 : val.expiryDate),
              label: "tesseract.sites.table.licenseExpiration",
              columnProps: {
                field: "licenseExpiration"
              },
              hideByDefault: true
            }
          ),
          /* @__PURE__ */ jsxs(
            ActionButtonsField,
            {
              source: "id",
              width: 128,
              alwaysVisible: true,
              children: [
                /* @__PURE__ */ jsx(
                  DeleteDialog,
                  {
                    resource: apiResources.sites,
                    title: "tesseract.sites.delete.title",
                    message: "tesseract.sites.delete.body",
                    onSuccess: () => {
                      var _a2;
                      registerAction("Delete Site");
                      (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                    }
                  }
                ),
                /* @__PURE__ */ jsx(
                  Forward,
                  {
                    id: "site-dashboard-icon",
                    onClick: (event, data) => history.push("/infrastructure/iot/" + data.id)
                  }
                )
              ]
            }
          )
        ]
      })
    ),
    /* @__PURE__ */ jsx(
      Dialog,
      {
        open: createOpen,
        maxWidth: false,
        onClose: () => setCreateOpen(false),
        disableEnforceFocus: true,
        children: /* @__PURE__ */ jsx(
          CreateSCContent,
          {
            onSuccess: () => {
              var _a2;
              return (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
            },
            onClose: () => setCreateOpen(false)
          }
        )
      }
    )
  ] });
};
var IotTable_default = IotTable;
export {
  IotTable,
  IotTable_default as default,
  useStyles
};
