import { jsx } from "react/jsx-runtime";
import { Edit } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { ButtonDialog } from "@cuda-react/core";
import UpdateDialogContent from "./UpdateDialogContent";
const EditUpdateDialog = ({ data, appliances, onSuccess, availableUpdates }) => /* @__PURE__ */ jsx(ButtonDialog, { buttonIcon: /* @__PURE__ */ jsx(Edit, { id: "update-window-edit-icon" }), children: /* @__PURE__ */ jsx(
  UpdateDialogContent,
  {
    data,
    appliances,
    title: "tesseract.settings.tabs.updates.firmware.edit.dialogTitle",
    onSuccess,
    availableUpdates
  }
) });
var EditUpdateDialog_default = EditUpdateDialog;
export {
  EditUpdateDialog_default as default
};
