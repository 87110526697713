var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { getArrayDataContent, useCrudProps } from "@cuda-react/core";
import { useStatisticsCardDetailsAndTotal } from "./useStatisticsCardDetailsAndTotal";
import { useCardFetch } from "./index";
const formatResourceData = (states, overviewData, linkCreator, getKeyColor) => {
  const [formattedStates, totalFunc] = useStatisticsCardDetailsAndTotal(
    getArrayDataContent(states),
    linkCreator,
    getKeyColor
  );
  const staticData = formattedStates.map((status) => __spreadProps(__spreadValues({}, status), {
    value: overviewData == null ? void 0 : overviewData[status.value]
  }));
  const total = totalFunc(overviewData);
  return [staticData, total];
};
const useFetchStatisticsCardDetailsAndTotal = (cardFetchParams, crudProps, formatters) => {
  var _a, _b;
  const { data: overviewData } = useCardFetch(cardFetchParams);
  const states = (_b = (_a = useCrudProps(crudProps == null ? void 0 : crudProps.url, crudProps == null ? void 0 : crudProps.params, crudProps == null ? void 0 : crudProps.options)) == null ? void 0 : _a[0]) == null ? void 0 : _b.data;
  const [staticData, total] = formatResourceData(states, overviewData, formatters == null ? void 0 : formatters.linkCreator, formatters == null ? void 0 : formatters.getKeyColor);
  return [overviewData ? staticData : void 0, overviewData ? total : void 0];
};
export {
  formatResourceData,
  useFetchStatisticsCardDetailsAndTotal
};
