import { jsx } from "react/jsx-runtime";
import { ButtonDialog } from "@cuda-react/core";
import { PreviewDialogContent } from "../PreviewDialogContent";
const AddWidgetButton = ({ cards, onAdd, forceSmall }) => /* @__PURE__ */ jsx(
  ButtonDialog,
  {
    buttonText: "stratosReporting.previewDialog.addWidgetButton",
    buttonProps: { color: "secondary" },
    stopPropagation: true,
    disabled: Object.values(cards).length < 1,
    children: /* @__PURE__ */ jsx(
      PreviewDialogContent,
      {
        onAdd,
        cards,
        forceSmall
      }
    )
  }
);
export {
  AddWidgetButton
};
