import { jsx, jsxs } from "react/jsx-runtime";
import { BooleanInput, ConnectedFormPage, FormSection } from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import { Typography } from "@barracuda-internal/bds-core";
import { Trans } from "react-i18next";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  helpText: {
    padding: theme.spacing(1, 1, 4, 3),
    color: theme.palette.text.secondary
  }
}));
const WebFilterSettings = () => {
  const classes = useStyles();
  return /* @__PURE__ */ jsx(
    ConnectedFormPage,
    {
      resource: apiResources.settings,
      title: "tesseract.securityAndAccess.pageTitle",
      subtitle: ["tesseract.securityAndAccess.webFilter", "tesseract.securityAndAccess.webFilterSettings"],
      formatRequestData: (data) => ({
        settings: {
          safe_search: data.safe_search.toString(),
          no_push_notifications_for_ads: data.silent_ad_blocking.toString()
        }
      }),
      formatResourceData: (data) => {
        var _a, _b;
        const safe_search = ((_a = data == null ? void 0 : data.settings) == null ? void 0 : _a.safe_search) === "true";
        const silent_ad_blocking = ((_b = data == null ? void 0 : data.settings) == null ? void 0 : _b.no_push_notifications_for_ads) === "true";
        return { safe_search, silent_ad_blocking };
      },
      params: { filter: { type: "virtualWan" } },
      canReset: true,
      children: /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.webFilterSettings.title", newStyle: true, hideBorder: true, children: [
        /* @__PURE__ */ jsx(Typography, { className: classes.helpText, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.webFilterSettings.helpText", children: /* @__PURE__ */ jsx("br", {}) }) }),
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "safe_search",
            label: "tesseract.webFilterSettings.safeSearch",
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "silent_ad_blocking",
            label: "tesseract.webFilterSettings.silentAdBlocking",
            newStyle: true
          }
        )
      ] })
    }
  );
};
var WebFilterSettings_default = WebFilterSettings;
export {
  WebFilterSettings_default as default
};
