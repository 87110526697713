import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import { useTheme } from "@mui/material";
const AuditLogIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-audit-log",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.lighter, strokeWidth: 0 },
          d: "M45.1,4.8H14.9c-2.8,0-5,2.3-5,5v40.3c0,2.8,2.3,5,5,5h30.3c2.8,0,5-2.3,5-5V9.8c0-2.8-2.3-5-5-5ZM22.4,9.8h5v12.6l-2.5-1.9-2.5,1.9v-12.6ZM45.1,50.2H14.9V9.8h2.5v22.7l7.6-5.7,7.6,5.7V9.8h12.6v40.3Z"
        }
      )
    }
  );
};
var AuditLogIcon_default = AuditLogIcon;
export {
  AuditLogIcon,
  AuditLogIcon_default as default
};
