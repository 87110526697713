import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { createStyles, makeStyles } from "@mui/styles";
import classNames from "classnames";
const styles = createStyles({
  icon: {},
  primaryFill: {
    fill: "#9253A1"
  },
  secondaryFill: {
    fill: "#582C5F"
  },
  whiteFill: {
    fill: "#FFFFFF"
  }
});
const useStyles = makeStyles(styles);
const BarracudaIcon = ({ className }) => {
  const classes = useStyles();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, classes.icon, "cuda-react-icon"),
      id: "cuda-icon-barracuda",
      viewBox: "0 0 24 24",
      children: /* @__PURE__ */ jsxs("g", { children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            className: classes.primaryFill,
            d: "M0.4,0.1h23.3c0.2,0,0.3,0.1,0.3,0.3v19.5c0,1.1-0.4,2.1-1.2,2.9c-0.8,0.8-1.8,1.2-2.9,1.2H0.4\n		                c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2V0.4C0.1,0.2,0.2,0.1,0.4,0.1z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            className: classes.secondaryFill,
            d: "M1.3,1.3h21.3v18.4c0,1.6-1.3,2.9-2.9,2.9H1.3V1.3z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            className: classes.whiteFill,
            d: "M8.1,17.1h-3c0-4.9,1.3-7.9,1.3-7.9C6,14.4,8.1,17.1,8.1,17.1z M20.1,5.6c0,0-9.4,3.8-11.2,11.5h7.9\n		                C16.9,17.1,11.2,15.6,20.1,5.6z"
          }
        )
      ] })
    }
  );
};
var BarracudaIcon_default = BarracudaIcon;
export {
  BarracudaIcon,
  BarracudaIcon_default as default,
  useStyles
};
