var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import React from "react";
import ButtonDialog from "../ButtonDialog/ButtonDialog";
import { makeOverrideableStyles } from "@cuda-react/theme";
import ActionDialogContent from "../ActionDialogContent/ActionDialogContent";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  iconDisabledPrimaryFill: {
    "& .cuda-react-icon-primary-fill": {
      fill: theme.palette.text.disabled
    }
  }
});
const useStyles = makeOverrideableStyles("ActionDialog", styles);
const ActionDialog = (props) => {
  const _a = props, {
    buttonIcon,
    buttonText,
    disable,
    disabled,
    disabledMessage,
    buttonProps = {},
    classes: _ignore
  } = _a, contentProps = __objRest(_a, [
    "buttonIcon",
    "buttonText",
    "disable",
    "disabled",
    "disabledMessage",
    "buttonProps",
    "classes"
  ]);
  const classes = useStyles(props);
  const isDisabled = disabled || !!disable && disable(contentProps.data);
  return /* @__PURE__ */ jsx(
    ButtonDialog,
    __spreadProps(__spreadValues({
      buttonIcon: buttonIcon && React.cloneElement(
        buttonIcon,
        { className: isDisabled ? classes.iconDisabledPrimaryFill : void 0 }
      ),
      buttonText,
      disabled: isDisabled,
      disabledMessage
    }, buttonProps), {
      children: /* @__PURE__ */ jsx(ActionDialogContent, __spreadValues({}, contentProps))
    })
  );
};
var ActionDialog_default = ActionDialog;
export {
  ActionDialog_default as default
};
