import { jsx, jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { AddUserDirectoryButton } from "./AddUserDirectoryButton";
const i18nScope = "tesseract.identity.userDirectories";
const UserDirectoriesNoData = ({ onSelect }) => {
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(
    Box,
    {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "300px",
      children: [
        /* @__PURE__ */ jsx(Typography, { children: translate(`${i18nScope}.noDataMessage`) }),
        /* @__PURE__ */ jsx(Typography, { mt: 1, mb: 2, children: translate(`${i18nScope}.clickBelow`) }),
        /* @__PURE__ */ jsx(AddUserDirectoryButton, { onSelect })
      ]
    }
  );
};
export {
  UserDirectoriesNoData
};
