import { jsx, jsxs } from "react/jsx-runtime";
import {
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select as BdsSelect,
  SelectWrapper
} from "@barracuda-internal/bds-core";
import { get, isEmpty, merge, set } from "lodash";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { formatErrorMessage } from "../../../utils";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { Info } from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import { createStyles } from "@mui/styles";
const CUDA_SELECT_EMPTY_VALUE = "CUDA_SELECT_EMPTY_VALUE";
const styles = (theme) => createStyles({
  formControl: {
    width: 256
  },
  listItemIcon: {
    display: "inline-flex",
    minWidth: "unset",
    marginRight: theme.spacing(1),
    verticalAlign: "middle",
    opacity: (props) => props.disabled ? 0.4 : 1
  },
  inset: {
    paddingLeft: 32
  },
  listItemText: {
    display: "inline-flex",
    margin: 0,
    verticalAlign: "middle",
    maxWidth: "100%",
    "& span": {
      color: "currentColor"
    }
  },
  listItemTextIndented: {
    display: "inline-flex",
    margin: 0,
    verticalAlign: "middle",
    maxWidth: "100%",
    "& span": {
      color: "currentColor"
    },
    textIndent: "10px"
  },
  listItemCategory: {
    display: "inline-flex",
    margin: 0,
    verticalAlign: "middle",
    maxWidth: "100%",
    "& span": {
      color: "currentColor"
    }
  },
  listItemTextTypography: {
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  listItemCategoryTextTypography: {
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "bold",
    color: theme.palette.text.secondary
  },
  select: {
    width: 256
  },
  selectInput: {
    overflow: "visible"
  },
  currentSelection: {
    "& span": {
      color: "blue"
    }
  }
});
const useStyles = makeOverrideableStyles("Select", styles);
const Select = (props) => {
  const {
    allowEmpty = false,
    choices = [],
    emptyLabel,
    error,
    disabled,
    icon,
    iconMap = {},
    filterChoices,
    id,
    label,
    optionCategory = "",
    optionHelp,
    optionText = "name",
    optionValue = "key",
    onBlur,
    defaultChoice,
    onChange,
    onFocus,
    value = "",
    sortByDisplayName
  } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const emptyOption = {};
  set(emptyOption, optionValue, CUDA_SELECT_EMPTY_VALUE);
  typeof optionText === "string" && set(emptyOption, optionText, emptyLabel || "cuda.inputs.select.empty");
  const allChoices = allowEmpty ? [...choices, emptyOption] : choices;
  const initialValue = useRef(value);
  const filteredChoices = filterChoices ? allChoices.filter((choice) => filterChoices(choice, value, initialValue.current)) : allChoices;
  const categories = optionCategory !== "" ? [...new Set(filteredChoices.map((choice) => get(choice, optionCategory)))] : void 0;
  const getDisplayName = (choice) => {
    let displayName = "";
    if (React.isValidElement(optionText)) {
      displayName = React.cloneElement(optionText, { record: choice });
    } else if (typeof optionText === "function") {
      displayName = optionText(choice);
    } else if (typeof optionText === "string") {
      displayName = get(choice, optionText);
    }
    return typeof displayName === "string" ? translate(displayName) : displayName;
  };
  if (sortByDisplayName) {
    filteredChoices.sort((choiceA, choiceB) => {
      const nameA = getDisplayName(choiceA);
      const nameB = getDisplayName(choiceB);
      if (typeof nameA === "string" && typeof nameB === "string") {
        if (nameA > nameB) {
          return 1;
        }
        return nameB > nameA ? -1 : 0;
      }
      return 0;
    });
    categories == null ? void 0 : categories.sort((choiceA, choiceB) => {
      const nameA = translate(choiceA);
      const nameB = translate(choiceB);
      if (nameA > nameB) {
        return 1;
      } else {
        return nameB > nameA ? -1 : 0;
      }
    });
  }
  useEffect(() => {
    if (!isEmpty(filteredChoices) && !allowEmpty && !filteredChoices.find((choice) => get(choice, optionValue) === value)) {
      const firstChoice = get(categories ? filteredChoices.find((choice) => get(choice, optionCategory) === categories[0]) : filteredChoices[0], optionValue);
      onChange == null ? void 0 : onChange(defaultChoice || firstChoice);
    }
  }, [choices, allowEmpty, value, onChange]);
  const getCurrentChoice = () => value && allChoices.find((choice) => get(choice, optionValue) === value) || {};
  const renderSelectableMenuItem = (choice) => {
    const displayName = getDisplayName(choice);
    const listIcon = iconMap[get(choice, optionValue)] || icon;
    return /* @__PURE__ */ jsxs(
      MenuItem,
      {
        value: get(choice, optionValue),
        children: [
          listIcon && /* @__PURE__ */ jsx(ListItemIcon, { className: classes.listItemIcon, children: listIcon }),
          typeof displayName === "string" ? /* @__PURE__ */ jsx(
            ListItemText,
            {
              primary: displayName,
              className: categories ? classes.listItemTextIndented : classes.listItemText,
              classes: { inset: classes.inset },
              inset: !isEmpty(iconMap) && !listIcon,
              primaryTypographyProps: { className: classes.listItemTextTypography }
            }
          ) : displayName
        ]
      },
      get(choice, optionValue)
    );
  };
  const renderCategory = (category) => /* @__PURE__ */ jsx(
    MenuItem,
    {
      value: category,
      children: /* @__PURE__ */ jsx(
        ListItemText,
        {
          primary: translate(category),
          className: classes.listItemCategory,
          primaryTypographyProps: { className: classes.listItemCategoryTextTypography }
        }
      )
    },
    category.split(".").pop()
  );
  const handleChange = (event) => {
    var _a;
    const newValue = (_a = event == null ? void 0 : event.target) == null ? void 0 : _a.value;
    if (categories && categories.includes(newValue)) {
      return null;
    }
    if (allowEmpty && newValue === get(emptyOption, optionValue)) {
      onChange == null ? void 0 : onChange(merge({}, event, { target: { value: "" } }));
    } else {
      onChange == null ? void 0 : onChange(event);
    }
    onBlur == null ? void 0 : onBlur();
  };
  return filteredChoices && filteredChoices.length > 0 ? /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsxs(SelectWrapper, { size: "small", className: classes.formControl, children: [
      label && /* @__PURE__ */ jsx(
        InputLabel,
        {
          id: "select-label-id-" + id,
          className: classes.inputLabel,
          children: translate(label)
        }
      ),
      /* @__PURE__ */ jsx(
        BdsSelect,
        {
          value: allowEmpty ? value || get(emptyOption, optionValue) : value,
          onChange: handleChange,
          onBlur,
          onFocus,
          inputProps: { className: classes.selectInput },
          id: "select-input-" + id,
          labelId: "select-label-id-" + id,
          label: label && translate(label),
          className: classes.select,
          fullWidth: true,
          disabled,
          error: !!error,
          variant: "outlined",
          children: categories ? categories.map((category) => {
            let choicesFilteredByCategory = filteredChoices.filter((choice) => get(choice, optionCategory) === category);
            return [renderCategory(category), choicesFilteredByCategory.map((choice) => renderSelectableMenuItem(choice))];
          }) : filteredChoices.map((choice) => renderSelectableMenuItem(choice))
        }
      ),
      error ? /* @__PURE__ */ jsx(FormHelperText, { error: true, children: formatErrorMessage(error) }) : null
    ] }),
    optionHelp && getCurrentChoice()[optionHelp] && /* @__PURE__ */ jsx("div", { className: classes.inputHelp, children: /* @__PURE__ */ jsx(Info, { tooltip: getCurrentChoice()[optionHelp], tooltipPosition: "top-left" }) })
  ] }) : null;
};
var Select_default = Select;
export {
  CUDA_SELECT_EMPTY_VALUE,
  Select,
  Select_default as default
};
