import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import { useTheme } from "@mui/material";
const CustomAppsIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-custom-apps",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.lighter, strokeWidth: 0 },
          d: "M14.2,25l4.8,2c.6-1.2,1.2-2.3,1.8-3.3.6-1.1,1.3-2.1,2-3.2l-3.5-.7-5.2,5.2ZM22.9,30.1l7,7c1.7-.7,3.6-1.7,5.5-3,2-1.4,3.8-2.9,5.5-4.6,2.9-2.9,5.1-6.1,6.7-9.6,1.6-3.5,2.3-6.7,2.1-9.7-3-.2-6.2.5-9.7,2.1-3.5,1.6-6.7,3.9-9.6,6.7-1.7,1.7-3.3,3.6-4.6,5.5-1.4,2-2.4,3.8-3,5.5ZM33.9,26.1c-.9-.9-1.4-2.1-1.4-3.5s.5-2.5,1.4-3.5,2.1-1.4,3.5-1.4,2.6.5,3.5,1.4,1.4,2.1,1.4,3.5-.5,2.5-1.4,3.5-2.1,1.4-3.5,1.4-2.6-.5-3.5-1.4ZM35.1,45.9l5.2-5.2-.7-3.5c-1.1.7-2.1,1.4-3.2,2s-2.2,1.2-3.3,1.8l2,4.9ZM54.4,5.6c.8,5,.3,9.8-1.4,14.5-1.7,4.7-4.8,9.2-9,13.5l1.2,6.1c.2.8.1,1.6-.1,2.4s-.7,1.5-1.2,2l-10.4,10.4-5.2-12.1-10.5-10.5-12.1-5.2,10.3-10.4c.6-.6,1.3-1,2.1-1.2s1.6-.3,2.4-.1l6.1,1.2c4.3-4.3,8.8-7.3,13.4-9.1,4.7-1.8,9.5-2.3,14.5-1.5ZM9.9,39.6c1.4-1.4,3.2-2.2,5.3-2.2,2.1,0,3.8.7,5.3,2.1,1.4,1.4,2.1,3.2,2.1,5.3,0,2.1-.7,3.8-2.2,5.3-1,1-2.7,1.9-5.1,2.7-2.4.7-5.7,1.4-10,2,.6-4.2,1.2-7.6,2-10,.7-2.4,1.6-4.1,2.7-5.1ZM13.4,43.1c-.4.4-.8,1.2-1.2,2.2-.4,1.1-.7,2.2-.9,3.3,1.1-.2,2.2-.4,3.3-.8s1.8-.8,2.2-1.2c.5-.5.8-1.1.8-1.8s-.2-1.3-.7-1.8-1.1-.7-1.8-.7-1.3.3-1.8.8Z"
        }
      )
    }
  );
};
var CustomAppsIcon_default = CustomAppsIcon;
export {
  CustomAppsIcon,
  CustomAppsIcon_default as default
};
