import { jsx, jsxs } from "react/jsx-runtime";
import {
  ActionDialogContent,
  ButtonDialog,
  CrudTypes,
  getDataContent,
  useCrudFetch,
  useCrudSubscription,
  useDeepCompareEffect
} from "@cuda-react/core";
import { FirewallIcon, VpnIcon } from "@cuda-react/icons";
import { useState } from "react";
import { Trans } from "react-i18next";
import { Typography } from "@barracuda-internal/bds-core";
import { DropDownArrow } from "@barracuda-internal/bds-core/dist/Icons/Controls";
import { Refresh } from "@barracuda-internal/bds-core/dist/Icons/Core";
import apiResources from "../../../../apiResources";
const RestartSiteDialog = ({ id, serials }) => {
  const [restartEnabled, setRestartEnabled] = useState(false);
  const [restartAvailableSerial1, loadingAvailable1, refreshSerial1] = useCrudSubscription(
    CrudTypes.GET,
    serials && serials.length && apiResources.restartAvailable,
    serials && { siteId: id, id: serials[0] },
    { pollInterval: restartEnabled ? 3e5 : 3e4 }
  );
  const [restartAvailableSerial2, loadingAvailable2, refreshSerial2] = useCrudSubscription(
    CrudTypes.GET,
    serials && serials.length > 1 && apiResources.restartAvailable,
    serials && { siteId: id, id: serials[1] },
    { pollInterval: restartEnabled ? 3e5 : 3e4 }
  );
  const restartAvailable = [getDataContent(restartAvailableSerial1.data), getDataContent(restartAvailableSerial2.data)];
  useDeepCompareEffect(() => {
    setRestartEnabled(Boolean(restartAvailable[0] || restartAvailable[1]));
  }, [restartAvailable]);
  const [, boxLoading, requestRestartBox] = useCrudFetch(CrudTypes.CREATE, apiResources.restartBox, { id });
  const [, vpnLoading, requestRestartVpn] = useCrudFetch(CrudTypes.CREATE, apiResources.restartVpn, { id });
  const [, firmwareLoading, requestRestartFirmware] = useCrudFetch(CrudTypes.CREATE, apiResources.restartFirmware, { id });
  const restartAll = (requestFunction) => () => {
    serials.forEach((serial, index) => {
      if (restartAvailable[index]) {
        requestFunction({ serial }).then(index === 0 ? refreshSerial1 : refreshSerial2);
      }
    });
    return true;
  };
  const renderMessage = (i18nKey) => /* @__PURE__ */ jsx(Typography, { children: /* @__PURE__ */ jsx(Trans, { i18nKey, count: serials && serials.length, children: /* @__PURE__ */ jsx("br", {}) }) });
  const restartAvailableDataLoading = loadingAvailable1 || loadingAvailable2;
  const loading = boxLoading || vpnLoading || firmwareLoading;
  const canRestart = restartEnabled && !restartAvailableDataLoading && !loading;
  return /* @__PURE__ */ jsxs(
    ButtonDialog,
    {
      useMenu: true,
      buttonIcon: /* @__PURE__ */ jsx(DropDownArrow, {}),
      buttonText: "tesseract.appliances.restart.buttonLabel",
      disabled: !canRestart,
      children: [
        /* @__PURE__ */ jsx(
          ActionDialogContent,
          {
            icon: /* @__PURE__ */ jsx(Refresh, {}),
            label: "tesseract.appliances.restart.box.menuChoice",
            message: renderMessage("tesseract.appliances.restart.box.confirmMessage"),
            onConfirm: restartAll(requestRestartBox),
            title: "tesseract.appliances.restart.box.dialogTitle"
          }
        ),
        /* @__PURE__ */ jsx(
          ActionDialogContent,
          {
            icon: /* @__PURE__ */ jsx(VpnIcon, {}),
            label: "tesseract.appliances.restart.vpn.menuChoice",
            message: renderMessage("tesseract.appliances.restart.vpn.confirmMessage"),
            onConfirm: restartAll(requestRestartVpn),
            title: "tesseract.appliances.restart.vpn.dialogTitle"
          }
        ),
        /* @__PURE__ */ jsx(
          ActionDialogContent,
          {
            icon: /* @__PURE__ */ jsx(FirewallIcon, {}),
            label: "tesseract.appliances.restart.firmware.menuChoice",
            message: renderMessage("tesseract.appliances.restart.firmware.confirmMessage"),
            onConfirm: restartAll(requestRestartFirmware),
            title: "tesseract.appliances.restart.firmware.dialogTitle"
          }
        )
      ]
    }
  );
};
var RestartSiteDialog_default = RestartSiteDialog;
export {
  RestartSiteDialog_default as default
};
