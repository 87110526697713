var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { ActionButtonsField, ButtonDialog, ConnectedTable, DialogBody, Form, Input, TextField } from "@cuda-react/core";
import { get } from "lodash";
import { makeStyles } from "@mui/styles";
import { getNetworkId } from "../../../../hooks/createEditSiteHooks";
import apiResources from "../../../../apiResources";
const FormContent = ({ formatInput, formatOutput, formChildren, formValidate, initialValues, value, onChange, onBlur, onClose }) => {
  const onSubmit = (values) => {
    const existingValues = formatInput(value || []);
    const newValues = [...existingValues, values];
    const outputValues = formatOutput(newValues, value || []);
    onChange(outputValues);
    onBlur(outputValues);
    onClose && onClose();
    return Promise.resolve(values);
  };
  return /* @__PURE__ */ jsx(
    DialogBody,
    {
      form: true,
      title: "tesseract.appliances.settings.dhcp.addReservationTitle",
      children: /* @__PURE__ */ jsx(
        Form,
        {
          validate: formValidate,
          initialValues,
          onCancel: onClose,
          save: onSubmit,
          formButtonToolbarProps: {
            label: "cuda.state.ok"
          },
          children: formChildren
        }
      )
    }
  );
};
const FormDialog = (_a) => {
  var _b = _a, { disabled, disableReserveButton, data, lans } = _b, props = __objRest(_b, ["disabled", "disableReserveButton", "data", "lans"]);
  const [initialValues, setInitialValues] = React.useState({});
  const networkId = getNetworkId(lans && lans.find((lan) => get(lan, "id") === get(data, "lanConfigurationId")) || {});
  return /* @__PURE__ */ jsx(
    ButtonDialog,
    {
      buttonText: "tesseract.appliances.settings.dhcp.leases.reserve",
      disabled: disabled || !!disableReserveButton && disableReserveButton(networkId, get(data, "ip")),
      onOpen: () => setInitialValues({
        networkId,
        ipAddress: get(data, "ip"),
        mac: get(data, "mac")
      }),
      children: /* @__PURE__ */ jsx(FormContent, __spreadProps(__spreadValues({}, props), { initialValues }))
    }
  );
};
const styles = (theme) => ({
  flat: {
    margin: theme.spacing(2)
  }
});
const useStyles = makeStyles(styles);
const DhcpLeasesReservation = (_c) => {
  var _d = _c, { id, applianceType } = _d, props = __objRest(_d, ["id", "applianceType"]);
  const classes = useStyles();
  return /* @__PURE__ */ jsx("div", { id: "table-input-dhcp-leases", children: /* @__PURE__ */ jsxs(
    ConnectedTable,
    {
      resource: applianceType === "site" ? apiResources.configurationDhcpLeases : apiResources.configurationOnPremDhcpLeases,
      params: { id },
      noRouter: true,
      flat: true,
      classes,
      pollInterval: 6e4,
      noDataMessage: "tesseract.appliances.settings.dhcp.leases.noLeases",
      minCellWidth: 164,
      children: [
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "subnetName",
            label: "tesseract.appliances.settings.dhcp.leases.port",
            filter: "text",
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "mac",
            label: "tesseract.appliances.settings.dhcp.leases.mac",
            filter: "text",
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "ip",
            label: "tesseract.appliances.settings.dhcp.leases.ip",
            filter: "text",
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(ActionButtonsField, { source: "id", children: /* @__PURE__ */ jsx(FormDialog, __spreadValues({}, props)) })
      ]
    }
  ) });
};
const DhcpLeasesReservationInput = (props) => /* @__PURE__ */ jsx(Input, __spreadProps(__spreadValues({}, props), { component: DhcpLeasesReservation }));
var DhcpLeasesReservationInput_default = DhcpLeasesReservationInput;
export {
  DhcpLeasesReservation,
  DhcpLeasesReservationInput,
  FormContent,
  FormDialog,
  DhcpLeasesReservationInput_default as default,
  styles
};
