import { Fragment, jsx } from "react/jsx-runtime";
import { Small } from "./Small";
import { Medium } from "./Medium";
import { Large } from "./Large";
import { None } from "./None";
var GridSizes = /* @__PURE__ */ ((GridSizes2) => {
  GridSizes2["small"] = "small";
  GridSizes2["medium"] = "medium";
  GridSizes2["large"] = "large";
  return GridSizes2;
})(GridSizes || {});
const SizedIcons = {
  ["small" /* small */]: /* @__PURE__ */ jsx(Small, {}),
  ["medium" /* medium */]: /* @__PURE__ */ jsx(Medium, {}),
  ["large" /* large */]: /* @__PURE__ */ jsx(Large, {}),
  ["None"]: /* @__PURE__ */ jsx(None, {})
};
const GridSizeIcon = ({ size }) => /* @__PURE__ */ jsx(Fragment, { children: SizedIcons[size] });
var GridSizeIcon_default = GridSizeIcon;
export {
  GridSizeIcon,
  GridSizes,
  GridSizeIcon_default as default
};
