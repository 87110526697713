var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { CardActions, FormHelperText, IconButton } from "@barracuda-internal/bds-core";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { get, isArray, isString, merge } from "lodash";
import React from "react";
import DeleteDialog from "../../dialog/DeleteDialog/DeleteDialog";
import ActionButtonsField from "../../fields/ActionButtonsField/ActionButtonsField";
import TableActionsAndFilters from "../../table/TableActionsAndFilters/TableActionsAndFilters";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import TableInputAddEditDialog from "./TableInputAddEditDialog";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    margin: 16,
    marginTop: 8,
    "& th": {
      textTransform: "uppercase"
    }
  },
  cardActions: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    flexDirection: "column"
  },
  table: {
    "& .k-grid-content": {
      overflowY: "auto"
    },
    "& table": {
      minWidth: (props) => React.Children.toArray(props.children).filter((child) => !!child).reduce(
        (total, child) => total + (child && !get(child, "props.hidden") ? get(child, "props.columnProps.width", get(child, "props.width", props.minCellWidth)) : 0),
        0
      ) + (props.orderable ? 96 : 0) + (!props.noEdit || !props.noDelete ? 96 : 0)
    }
  },
  tableError: {
    marginLeft: theme.spacing(1)
  },
  tableContainerError: {
    border: "solid 1px " + theme.palette.error.main,
    borderRadius: 4,
    marginTop: -20,
    marginLeft: -4,
    paddingTop: 20,
    paddingLeft: 4,
    width: "calc(100% + 8px)",
    display: "inline-flex"
  },
  orderCell: {
    width: 60,
    paddingRight: 8,
    paddingLeft: "8px !important"
  },
  cellError: {
    border: "solid 1px " + theme.palette.error.main + "!important"
  },
  rowError: {
    borderTop: "solid 1px " + theme.palette.error.main + "!important",
    borderBottom: "solid 1px " + theme.palette.error.main + "!important",
    "&:first-child": {
      borderLeft: "solid 1px " + theme.palette.error.main + "!important"
    },
    "&:last-child": {
      borderRight: "solid 1px " + theme.palette.error.main + "!important"
    }
  },
  dimmedRow: {
    opacity: 0.5,
    backgroundColor: theme.palette.action.disabledBackground + "!important"
  },
  actionButtons: {
    padding: 2
  }
});
const useStyles = makeOverrideableStyles("Table", styles);
const EditableTable = (props) => {
  const {
    addButtonProps,
    children,
    defaultFormValues,
    deleteButtonProps,
    disabled,
    editButtonProps,
    formChildren,
    formatError,
    formatFormData,
    formatInput,
    formatOutput,
    formValidate,
    id,
    error,
    onChange,
    onBlur,
    labels = {
      addTitle: "cuda.buttons.add",
      addButtonText: "cuda.buttons.add",
      editTitle: "cuda.buttons.edit"
    },
    minimumItems,
    noCreate,
    noDelete,
    noEdit,
    optionValue = "index",
    orderable,
    orderColumnLabel,
    rowDimmed,
    value = "",
    tabbed,
    formProps
  } = props;
  const classes = useStyles(props);
  const inputValue = value || [];
  const data = formatInput ? formatInput(inputValue) : inputValue;
  const currentError = formatError ? formatError(error, inputValue) : error;
  const errorArray = isArray(currentError) && currentError || [];
  const errorString = isString(currentError) && currentError;
  const moveRow = (index, movement) => {
    const newIndex = index + movement;
    if (newIndex >= 0 && newIndex < data.length) {
      const newData = data.filter((row, rowIndex) => rowIndex !== index);
      newData.splice(newIndex, 0, data[index]);
      const newValue = formatOutput ? formatOutput(newData, inputValue) : newData;
      onChange && onChange(newValue);
      onBlur && onBlur(newValue);
    }
  };
  const childrenForForm = formChildren || React.Children.map(children, (child) => {
    if (child && child.props.inputComponent) {
      const _a = child.props, { inputComponent: InputComponent, inputProps } = _a, childProps = __objRest(_a, ["inputComponent", "inputProps"]);
      return React.createElement(InputComponent, __spreadValues(__spreadValues({ key: childProps.source }, childProps), inputProps || {}));
    }
    return null;
  });
  const childArray = React.Children.toArray(children).filter((child) => !!child && !get(child, "props.hidden"));
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs("div", { className: classes.root, id: id ? "table-input-" + id : void 0, children: [
    /* @__PURE__ */ jsx(CardActions, { className: classes.cardActions, disableSpacing: true, children: /* @__PURE__ */ jsx(
      TableActionsAndFilters,
      {
        actions: !noCreate ? [
          /* @__PURE__ */ jsx(
            TableInputAddEditDialog,
            __spreadProps(__spreadValues({}, merge(
              {
                title: labels == null ? void 0 : labels.addTitle,
                buttonText: labels == null ? void 0 : labels.addButtonText,
                onSubmit: (newData) => {
                  const newValue = formatOutput ? formatOutput([...data, newData], inputValue) : [...data, newData];
                  onChange && onChange(newValue);
                  onBlur && onBlur(newValue);
                },
                formValidate,
                formatFormData,
                tabbed,
                data: defaultFormValues,
                disabled,
                formProps
              },
              addButtonProps
            )), {
              children: childrenForForm
            }),
            "add"
          )
        ] : void 0
      }
    ) }),
    /* @__PURE__ */ jsxs(
      Grid,
      {
        data,
        className: classNames(classes.table, errorString ? classes.tableContainerError : void 0),
        rowRender: (row, rowProps) => React.cloneElement(row, {
          className: classNames(
            row.props && row.props.className,
            // @ts-ignore This isn't on GridRowProps, but can be provided by our own props.
            rowProps.className,
            rowDimmed && rowDimmed(rowProps.dataItem) && classes.dimmedRow
          )
        }),
        cellRender: (cell, { className, columnIndex, dataIndex, dataItem }) => {
          let child;
          let error2;
          const childIndex = (columnIndex || 0) - (orderable ? 1 : 0);
          const rowHasError = errorArray && errorArray[dataIndex] && (typeof errorArray[dataIndex] === "string" || !childArray.some(({ props: { source } }) => get(errorArray[dataIndex], source)));
          if (orderable && columnIndex === 0) {
            child = /* @__PURE__ */ jsxs(ActionButtonsField, { left: true, cellClassName: classes.orderCell, label: orderColumnLabel, children: [
              /* @__PURE__ */ jsx(
                IconButton,
                {
                  size: "small",
                  onClick: () => moveRow(dataIndex, -1),
                  disabled: disabled || dataIndex < 1,
                  className: classes.actionButtons,
                  children: /* @__PURE__ */ jsx(
                    ArrowUpwardIcon,
                    {
                      id: "cuda-icon-up"
                    }
                  )
                }
              ),
              /* @__PURE__ */ jsx(
                IconButton,
                {
                  size: "small",
                  onClick: () => moveRow(dataIndex, 1),
                  disabled: disabled || dataIndex >= data.length - 1,
                  className: classes.actionButtons,
                  children: /* @__PURE__ */ jsx(
                    ArrowDownwardIcon,
                    {
                      id: "cuda-icon-down"
                    }
                  )
                }
              )
            ] });
          } else if (childIndex === childArray.length) {
            child = /* @__PURE__ */ jsxs(ActionButtonsField, { children: [
              !noEdit && /* @__PURE__ */ jsx(
                TableInputAddEditDialog,
                __spreadProps(__spreadValues({}, merge(
                  {
                    onSubmit: (value2, initialValue) => {
                      const newData = data.map((existingItem, index) => {
                        if (get(__spreadValues({ index }, existingItem), optionValue) === get(initialValue, optionValue)) {
                          return value2;
                        }
                        return existingItem;
                      });
                      const newValue = formatOutput ? formatOutput(newData, inputValue) : newData;
                      onChange && onChange(newValue);
                      onBlur && onBlur(newValue);
                    },
                    formValidate,
                    formatFormData,
                    tabbed,
                    initialErrors: errorArray && errorArray[dataIndex],
                    title: (labels == null ? void 0 : labels.editTitle) || "",
                    edit: true,
                    disabled,
                    formProps,
                    rowIndex: dataIndex
                  },
                  editButtonProps
                )), {
                  children: childrenForForm
                })
              ) || null,
              !noDelete && /* @__PURE__ */ jsx(
                DeleteDialog,
                __spreadValues({}, merge(
                  {
                    onDelete: (params) => {
                      const newData = data.filter((value2, index) => get(__spreadValues({ index }, value2), optionValue) !== params.id);
                      const newValue = formatOutput ? formatOutput(newData, inputValue) : newData;
                      onChange && onChange(newValue);
                      onBlur && onBlur(newValue);
                    },
                    optionValue,
                    message: labels == null ? void 0 : labels.deleteMessage,
                    title: labels == null ? void 0 : labels.deleteTitle,
                    disabled: disabled || minimumItems && data && data.length <= minimumItems
                  },
                  deleteButtonProps
                ))
              ) || null
            ] });
          } else {
            child = childArray[childIndex];
            error2 = get(errorArray, `[${dataIndex}].${get(child, "props.source")}`);
          }
          return /* @__PURE__ */ jsx("td", { className: classNames(className, error2 && classes.cellError, rowHasError && classes.rowError), children: child && React.cloneElement(child, {
            data: dataItem,
            index: dataIndex,
            total: data.length,
            disabled
          }) || null });
        },
        children: [
          /* @__PURE__ */ jsx(GridNoRecords, { children: translate((labels == null ? void 0 : labels.noDataMessage) || "cuda.inputs.table.noDataMessage") }),
          orderable ? /* @__PURE__ */ jsx(
            GridColumn,
            {
              field: "order",
              title: orderColumnLabel && translate(orderColumnLabel) || " ",
              width: 96
            }
          ) : null,
          childArray.map((child, childIndex) => /* @__PURE__ */ jsx(
            GridColumn,
            __spreadValues({
              field: child.props.source,
              title: child.props.label && translate(child.props.label) || " ",
              className: child.props.cellClassName,
              headerClassName: child.props.cellClassName,
              width: child.props.width
            }, child.props.columnProps),
            child.props.source || childIndex
          )),
          (!noEdit || !noDelete) && /* @__PURE__ */ jsx(
            GridColumn,
            {
              field: "actions",
              title: " ",
              width: 96
            }
          ) || null
        ]
      }
    ),
    errorString && /* @__PURE__ */ jsx(FormHelperText, { error: true, className: classes.tableError, children: errorString }),
    errorArray.filter((row) => !!row && typeof row === "string").map((errorText) => /* @__PURE__ */ jsx(FormHelperText, { error: true, className: classes.tableError, children: errorText }, errorText))
  ] });
};
EditableTable.defaultProps = {
  minCellWidth: 128
};
var EditableTable_default = EditableTable;
export {
  EditableTable,
  EditableTable_default as default
};
