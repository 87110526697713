import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const WidgetAddIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-widget-add",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.success) == null ? void 0 : _a.main, strokeWidth: 0 },
          d: "M42.3,32.5v-4.9s-9.8,0-9.8,0v-9.8h-4.9v9.8h-9.8v4.9h9.8v9.8h4.9v-9.8h9.8ZM54.5,30c0-13.5-11-24.5-24.5-24.5-13.5,0-24.5,11-24.5,24.5,0,13.5,11,24.5,24.5,24.5,13.5,0,24.5-11,24.5-24.5ZM10.4,30c0-10.8,8.8-19.6,19.6-19.6s19.6,8.8,19.6,19.6c0,10.8-8.8,19.6-19.6,19.6-10.8,0-19.6-8.8-19.6-19.6Z"
        }
      )
    }
  );
};
var WidgetAddIcon_default = WidgetAddIcon;
export {
  WidgetAddIcon,
  WidgetAddIcon_default as default
};
