import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { CardSizeOptions, CardSizes } from "../../../typesAndConstants";
import generatePreviewData from "../../../utils/previewDataGenerators/devicesVersion";
import { useCardFetch } from "../../../hooks";
import StatisticsOverview from "../../statistics/StatisticsOverview";
import StatisticsDetail from "../../statistics/StatisticsDetail";
import BaseCard from "../../baseCards/BaseCard";
import { Divider, Grid, Typography } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { BarGrid } from "../../statistics/BarGrid";
import { useTranslation } from "react-i18next";
import { colorFunction, compareSemanticVersions, getStatusColorByIndex, groupBySemanticVersions } from "../../../utils";
import apiResources from "../../../apiResources";
import classNames from "classnames";
const styles = (theme) => createStyles({
  dividerCards: {
    width: "95%"
  },
  appliancesContainer: {
    maxHeight: 300,
    minHeight: 300
  },
  hideBoxShadow: {
    boxShadow: "none",
    borderWidth: 0
  },
  cardContent: {
    height: "calc(100% - 50px)",
    overflowY: "hidden"
  },
  titleBar: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.reporting.fontColor,
    padding: "16px 16px 8px"
  },
  barGrid: {
    maxHeight: 300,
    minHeight: 300,
    overflowY: "scroll"
  },
  noDataContainer: {
    height: ({ size }) => `calc(${CardSizeOptions[size].height}px - ${theme.spacing(50)})`,
    width: "100%"
  }
});
const useStyles = makeStyles(styles);
const defaultAmountByCardSize = {
  small: 3,
  medium: 5,
  large: 5
};
const AccessDevicesAgentVersionCard = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const [translate] = useTranslation();
  const { preview, size } = props;
  const { data = {} } = useCardFetch({ url: !preview ? apiResources.agentVersions : void 0 });
  const content = preview ? generatePreviewData() : data;
  const { total, versions } = content;
  const overviewValue = total ? total : 0;
  const valueOrderedVersions = (versions ? Object.keys(versions) : []).sort((versionA, versionB) => versions[versionA] - versions[versionB]).reverse();
  const valueOrderedVersionsLength = valueOrderedVersions.length;
  let groupedByVersion = {};
  if (valueOrderedVersionsLength > defaultAmountByCardSize[size]) {
    groupedByVersion = groupBySemanticVersions(versions, "minor");
    if (groupedByVersion && Object.keys(groupedByVersion).length > defaultAmountByCardSize[size]) {
      groupedByVersion = groupBySemanticVersions(versions, "major");
      if (groupedByVersion && Object.keys(groupedByVersion).length > defaultAmountByCardSize[size]) {
        const keys = Object.keys(groupedByVersion).sort(compareSemanticVersions).reverse();
        const droppedValues = {};
        let older = 0;
        keys.forEach((key, index) => {
          if (index < defaultAmountByCardSize[size] - 1) {
            droppedValues[key] = groupedByVersion[key];
          } else {
            older += groupedByVersion[key];
          }
        });
        droppedValues["stratosReporting.accessDevicesAgentVersionCard.older"] = older;
        groupedByVersion = droppedValues;
      }
    }
  } else {
    valueOrderedVersions.forEach((vers) => groupedByVersion[vers] = versions[vers]);
  }
  const orderedKeys = Object.keys(groupedByVersion).sort(compareSemanticVersions).reverse();
  const overviewData = orderedKeys.length > 0 ? orderedKeys.map((key, index) => ({
    title: orderedKeys[index],
    value: groupedByVersion[orderedKeys[index]],
    color: getStatusColorByIndex(index, theme)
  })) : [];
  const highestValue = versions && versions[valueOrderedVersions[0]];
  const barChartData = valueOrderedVersions.map((version) => ({
    title: translate("stratosReporting.accessDevicesAgentVersionCard.version", { version }),
    value: translate("stratosReporting.accessDevicesAgentVersionCard.devices", { value: versions[version], context: versions[version] === 1 ? void 0 : "plural" }),
    color: colorFunction(100 / highestValue * versions[version], theme),
    barProgress: 100 * versions[version] / highestValue
  }));
  return /* @__PURE__ */ jsxs(BaseCard, { title: "stratosReporting.accessDevicesAgentVersionCard.title", size, preview, classes: { cardContent: classes.cardContent }, children: [
    /* @__PURE__ */ jsx(
      StatisticsOverview,
      {
        overviewTitle: "stratosReporting.resourceStatisticsCard.overview",
        overviewValue,
        children: Array.isArray(overviewData) && overviewData.map((data2) => /* @__PURE__ */ jsx(
          StatisticsDetail,
          {
            title: data2.title,
            value: data2 == null ? void 0 : data2.value,
            color: data2 == null ? void 0 : data2.color
          },
          data2.title
        ))
      }
    ),
    size === CardSizes.large && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Grid, { container: true, direction: "column", alignItems: "center", children: /* @__PURE__ */ jsx(Divider, { className: classes.dividerCards }) }),
      /* @__PURE__ */ jsx(Typography, { className: classes.titleBar, children: translate("stratosReporting.accessDevicesAgentVersionCard.tableTitle") }),
      /* @__PURE__ */ jsx(
        BarGrid,
        {
          size: CardSizes.large,
          entries: barChartData,
          className: classNames(classes.barGrid, classes.noDataContainer)
        }
      )
    ] })
  ] });
};
export {
  AccessDevicesAgentVersionCard
};
