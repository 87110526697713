import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const SubscriptionsGraphic = ({ className }) => {
  var _a, _b, _c;
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-graphic"),
      id: "cuda-graphic-subscriptions",
      viewBox: "0 0 96 96",
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
            d: "M54.1,34.6l.2-3.4c0-.8-.5-1.6-1.3-1.8l-3.3-.9-1.9-2.8c-.5-.7-1.3-1-2.1-.7l-3.2,1.2-3.2-1.2c-.8-.3-1.7,0-2.1.7l-1.9,2.8-3.3.9c-.8.2-1.3,1-1.3,1.8l.2,3.4-2.1,2.6c-.5.7-.5,1.6,0,2.2l2.1,2.7-.2,3.4c0,.8.5,1.6,1.3,1.8l3.3.9,1.9,2.8c.3.5.9.8,1.5.8s.4,0,.6-.1l3.2-1.2,3.2,1.2c.8.3,1.7,0,2.1-.7l1.9-2.8,3.3-.9c.8-.2,1.3-1,1.3-1.8l-.2-3.4,2.1-2.7c.5-.7.5-1.6,0-2.2l-2.1-2.6ZM50.9,40.5c-.3.3-.4.8-.4,1.2v2.7c.1,0-2.4.7-2.4.7-.4.1-.8.4-1,.7l-1.5,2.2-2.5-.9c-.4-.2-.9-.2-1.3,0l-2.5.9-1.5-2.2c-.2-.4-.6-.6-1-.7l-2.6-.7v-2.7c.2-.4,0-.9-.3-1.2l-1.7-2.1,1.7-2.1c.3-.3.4-.8.4-1.2v-2.7c-.1,0,2.4-.7,2.4-.7.4-.1.8-.4,1-.7l1.5-2.2,2.5.9c.4.2.9.2,1.3,0l2.5-.9,1.5,2.2c.2.4.6.6,1,.7l2.6.7v2.7c-.2.4,0,.9.3,1.2l1.7,2.1-1.7,2.1Z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 },
            d: "M46.3,72.6l-2.3-1.4c-.8-.5-1.9-.5-2.7,0l-8.4,5.4,1-11.4c.5.1.9.1,1.4,0l7-2.7,4.1,1.6c.4-1.6,1-3.2,1.8-4.6l-5-1.9c-.6-.2-1.2-.2-1.8,0l-6.1,2.3-3.5-5.4c-.3-.5-.8-.9-1.4-1l-6.3-1.7.3-6.5c0-.6-.2-1.2-.6-1.7l-4.1-5.1,4.1-5.1c.4-.5.6-1.1.6-1.7l-.3-6.5,6.3-1.7c.6-.2,1.1-.5,1.4-1l3.5-5.4,6.1,2.3c.6.2,1.2.2,1.8,0l6.1-2.3,3.5,5.4c.3.5.8.9,1.4,1l6.3,1.7-.3,6.5c0,.6.2,1.2.6,1.7l4.1,5.1-4.1,5.1c-.4.5-.6,1.1-.6,1.7l.2,4.6c1.6-.4,3.2-.7,4.9-.7l-.2-3.2,4.7-5.9c.7-.9.7-2.2,0-3.1l-4.7-5.9.4-7.5c0-1.2-.7-2.2-1.8-2.5l-7.3-2-4.1-6.3c-.6-1-1.9-1.4-3-1l-7,2.7-7-2.7c-1.1-.4-2.3,0-3,1l-4.1,6.3-7.3,2c-1.1.3-1.9,1.4-1.8,2.5l.4,7.5-4.7,5.9c-.7.9-.7,2.2,0,3.1l4.7,5.9-.4,7.5c0,1.2.7,2.2,1.8,2.5l7.3,2,1.2,1.8-1.9,21.5c0,.9.4,1.8,1.2,2.3.8.5,1.8.5,2.6,0l11.3-7.3,7.3,4.6c-1.8-2.4-3.1-5.2-3.7-8.2Z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_c = theme.palette.success) == null ? void 0 : _c.main, strokeWidth: 0 },
            d: "M65.8,51.9c-9.4,0-17,7.6-17,17s7.6,17,17,17,17-7.6,17-17-7.6-17-17-17ZM74.3,70.5h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"
          }
        )
      ]
    }
  );
};
var SubscriptionsGraphic_default = SubscriptionsGraphic;
export {
  SubscriptionsGraphic,
  SubscriptionsGraphic_default as default
};
