import { jsx, jsxs } from "react/jsx-runtime";
import React, { useRef, useState } from "react";
import {
  INFO_NOTIFICATION,
  MenuButton,
  TOAST_NOTIFICATION,
  useMixpanel,
  useNotifications
} from "@cuda-react/core";
import CreateEnrollEmailDialog from "./enroll/CreateEnrollEmailDialog";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
import { Dialog } from "@barracuda-internal/bds-core";
const UsersMenu = ({ data, enrollmentUrl }) => {
  const registerAction = useMixpanel("Endpoint Users", false);
  const [showCopyLinkNotification] = useNotifications(TOAST_NOTIFICATION, "tesseract.users.table.enrollmentLinkCopied", INFO_NOTIFICATION, { duration: 5e3 });
  const [resendOpen, setResendOpen] = useState(false);
  const copyOnClickRef = useRef(null);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      MenuButton,
      {
        anchorOrigin: { horizontal: "left", vertical: "top" },
        transformOrigin: { horizontal: "left", vertical: "top" },
        menuItems: [
          {
            label: "tesseract.users.table.resendInvite",
            onClick: () => setResendOpen(true)
          },
          {
            label: "tesseract.users.table.enrollmentLink",
            onClick: () => {
              var _a;
              registerAction("Copy Link");
              (_a = copyOnClickRef == null ? void 0 : copyOnClickRef.current) == null ? void 0 : _a.call(copyOnClickRef);
              showCopyLinkNotification();
            }
          }
        ],
        iconButton: true
      }
    ),
    /* @__PURE__ */ jsx(Dialog, { open: resendOpen, maxWidth: false, onClose: () => setResendOpen(false), children: /* @__PURE__ */ jsx(CreateEnrollEmailDialog, { id: data == null ? void 0 : data.id, email: data == null ? void 0 : data.email, onClose: () => setResendOpen(false) }) }),
    /* @__PURE__ */ jsx(
      CopyToClipboardButton,
      {
        value: `${document.location.origin}/zerotrust/enroll/?uuid=${data == null ? void 0 : data.uuid}&endpoint=https://${enrollmentUrl}`,
        onClickRef: copyOnClickRef,
        hideButton: true
      }
    )
  ] });
};
var UsersMenu_default = UsersMenu;
export {
  UsersMenu_default as default
};
