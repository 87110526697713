var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { LoadingMessage, useGlobalParam } from "@cuda-react/core";
import { UserDirectoryBCCForm } from "./UserDirectoryBCCForm";
import { UserDirectoryEntraIDForm } from "./UserDirectoryEntraIDForm";
import { UserDirectoryLdapForm } from "./UserDirectoryLdapForm";
import { UserDirectoryOktaForm } from "./UserDirectoryOktaForm";
import { UserDirectoryGoogleForm } from "./UserDirectoryGoogleForm";
const UserDirectoryForm = (props) => {
  var _a, _b, _c, _d;
  const account = (_d = (_c = (_b = (_a = useGlobalParam()) == null ? void 0 : _a[0]) == null ? void 0 : _b.pageTitle) == null ? void 0 : _c.account) != null ? _d : null;
  const accountName = useMemo(() => account == null ? void 0 : account.join(" - "), [account]);
  if (!account || !accountName) {
    return /* @__PURE__ */ jsx(LoadingMessage, {});
  }
  switch (props.directory.type) {
    case "bcc":
      return /* @__PURE__ */ jsx(UserDirectoryBCCForm, __spreadProps(__spreadValues({}, props), { directory: __spreadProps(__spreadValues({}, props.directory), { accountName }) }));
    case "entraid":
      return /* @__PURE__ */ jsx(UserDirectoryEntraIDForm, __spreadProps(__spreadValues({}, props), { directory: __spreadProps(__spreadValues({}, props.directory), { accountName }) }));
    case "ldap":
      return /* @__PURE__ */ jsx(UserDirectoryLdapForm, __spreadProps(__spreadValues({}, props), { directory: __spreadProps(__spreadValues({}, props.directory), { accountName }) }));
    case "okta":
      return /* @__PURE__ */ jsx(UserDirectoryOktaForm, __spreadProps(__spreadValues({}, props), { directory: __spreadProps(__spreadValues({}, props.directory), { accountName }) }));
    case "google":
      return /* @__PURE__ */ jsx(UserDirectoryGoogleForm, __spreadProps(__spreadValues({}, props), { directory: __spreadProps(__spreadValues({}, props.directory), { accountName }) }));
    default:
      return null;
  }
};
export {
  UserDirectoryForm
};
