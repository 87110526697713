var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { ResourceStatisticsCard, useFetchStatisticsCardDetailsAndTotal } from "@stratos/reporting";
import apiResources from "../../../../apiResources";
import { getStatusColorFromTheme } from "../../../../utils/themeUtils";
import { useTheme } from "@mui/material";
import { ActionButtonsField, ChipArrayField, generateQueryString, StatusIconField, TextField } from "@cuda-react/core";
import { Forward } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { useHistory } from "react-router";
const previewData = (theme) => [
  [
    {
      "title": "tesseract.dashboard.cards.iotStatusCard.healthy",
      "value": 3,
      "color": getStatusColorFromTheme("online", theme)
    },
    {
      "title": "tesseract.dashboard.cards.iotStatusCard.pending",
      "value": 0,
      "color": getStatusColorFromTheme("pending", theme)
    },
    {
      "title": "tesseract.dashboard.cards.iotStatusCard.connecting",
      "value": 4,
      "color": getStatusColorFromTheme("connecting", theme)
    },
    {
      "title": "tesseract.dashboard.cards.iotStatusCard.degraded",
      "value": 5,
      "color": getStatusColorFromTheme("degraded", theme)
    },
    {
      "title": "tesseract.dashboard.cards.iotStatusCard.offline",
      "value": 4,
      "color": getStatusColorFromTheme("offline", theme)
    }
  ],
  16
];
const filterDegraded = (details) => !(details.link || details.title).includes("degraded");
const IotStatusCard = (props) => {
  const { preview } = props;
  const theme = useTheme();
  const apiResourceData = useFetchStatisticsCardDetailsAndTotal(
    {
      url: preview ? void 0 : apiResources.siteStatus,
      params: { filter: { modelSeries: "S" } }
    },
    {
      url: apiResources.siteStates
    },
    {
      linkCreator: (status) => `/#/infrastructure/iot${generateQueryString({ connectionStatus: [status.key] })}`,
      getKeyColor: (key) => getStatusColorFromTheme(key, theme)
    }
  );
  const [staticData, total] = preview ? previewData(theme) : apiResourceData;
  const history = useHistory();
  return /* @__PURE__ */ jsxs(
    ResourceStatisticsCard,
    __spreadProps(__spreadValues({}, props), {
      overViewData: staticData ? staticData.filter(filterDegraded) : staticData,
      overViewValue: total,
      overviewTitle: "tesseract.dashboard.cards.status.iot.tabTitle",
      tableTitle: "tesseract.network.iot.menuTitle",
      children: [
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "aggregateState",
            width: 80
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.sites.table.name",
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "serials",
            label: "tesseract.sites.table.serial",
            columnProps: {
              // @ts-ignore
              id: "serials",
              field: "serial"
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "gatewayName",
            label: "tesseract.sites.table.gateway",
            columnProps: {
              field: "gateway"
            },
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          ActionButtonsField,
          {
            source: "id",
            width: 128,
            children: /* @__PURE__ */ jsx(
              Forward,
              {
                id: "site-dashboard-icon",
                onClick: (event, data) => history.push("/infrastructure/iot/" + data.id)
              }
            )
          }
        )
      ]
    })
  );
};
var IotStatusCard_default = IotStatusCard;
export {
  IotStatusCard_default as default
};
