import { all, call } from "redux-saga/effects";
import AuthSagas from "./AuthSagas";
import RedirectSaga from "./RedirectSaga";
var sagas_default = (authClient) => function* rootSaga() {
  yield all([
    call(AuthSagas(authClient)),
    call(RedirectSaga)
  ]);
};
export {
  AuthSagas,
  RedirectSaga,
  sagas_default as default
};
