var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import {
  CrudTypes,
  getArrayDataContent,
  getDataContent,
  NoResourcePage,
  Tab,
  TabbedPage,
  useCrudProps,
  useCrudSubscription,
  useGlobalParam
} from "@cuda-react/core";
import ApplianceAuditLog from "../appliances/logs/ApplianceAuditLog";
import HistoryLog from "../appliances/logs/HistoryLog";
import LiveLog from "../appliances/logs/LiveLog";
import ApplianceDashboard from "../appliances/ApplianceDashboard";
import apiResources from "../../../apiResources";
import EditTVTContent from "../../../components/appliances/edit/EditTVTContent";
import EditCloudGatewayContent from "../../../components/gateways/edit/EditCloudGatewayContent";
import EditManagedGatewayContent from "../../../components/gateways/edit/EditManagedGatewayContent";
const GatewayDetails = ({ match }) => {
  var _a, _b;
  const id = match.params.id;
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const [gatewayDetailsData, loading] = useCrudSubscription(CrudTypes.GET, apiResources.gateways, { id });
  const gatewayDetails = getDataContent(gatewayDetailsData, {});
  const gatewayVirtualWanId = (_a = gatewayDetails == null ? void 0 : gatewayDetails.virtualWan) == null ? void 0 : _a.id;
  const gatewayNotFound = !!(gatewayDetailsData == null ? void 0 : gatewayDetailsData.error) || gatewayVirtualWanId && virtualWanId !== gatewayVirtualWanId;
  const gatewayType = gatewayDetails == null ? void 0 : gatewayDetails.gatewayType;
  const configStatus = getArrayDataContent((_b = useCrudProps(id && gatewayType === "on-prem" ? apiResources.configurationOnPremStatus : void 0, { id })[0]) == null ? void 0 : _b.data);
  const activeAppliance = configStatus.find((appliance) => appliance.activeHA) || configStatus[0];
  const activeSerial = activeAppliance && activeAppliance.instanceId;
  return !gatewayNotFound ? /* @__PURE__ */ jsxs(
    TabbedPage,
    {
      title: "tesseract.network.pageTitle",
      subtitle: [
        { text: "tesseract.network.gateways", path: "/infrastructure/gateways" },
        gatewayDetailsData && gatewayDetailsData.data && gatewayDetailsData.data.name || " "
      ],
      defaultTab: 0,
      children: [
        /* @__PURE__ */ jsx(
          Tab,
          {
            value: "#gatewaydashboard",
            label: "tesseract.gateways.dashboard.tabTitle",
            children: /* @__PURE__ */ jsx(
              ApplianceDashboard,
              {
                details: __spreadProps(__spreadValues({ id, activeSerial }, gatewayDetails), { loading }),
                type: "gateways"
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          Tab,
          {
            value: "#settings",
            label: "tesseract.gateways.settings.tabTitle",
            //This check ensures our crud data has loaded, without it the cloud settings page is shown while we wait for data to load
            children: gatewayType ? gatewayType === "on-prem" ? /* @__PURE__ */ jsx(EditTVTContent, { id, type: "gateway", details: gatewayDetails }) : gatewayType === "managed" ? /* @__PURE__ */ jsx(EditManagedGatewayContent, { id, details: gatewayDetails }) : /* @__PURE__ */ jsx(EditCloudGatewayContent, { id, details: gatewayDetails }) : null
          }
        ),
        /* @__PURE__ */ jsx(
          Tab,
          {
            value: "#connectionHistoryLog",
            label: "tesseract.gateways.connectionHistory.tabTitle",
            children: /* @__PURE__ */ jsx(
              HistoryLog,
              {
                id,
                serial: activeSerial,
                messagesType: "gateways",
                tableName: "gatewayHistory",
                resource: gatewayType && (gatewayType === "on-prem" ? apiResources.gatewayOnPremHistory : apiResources.gatewayCloudHistory),
                details: gatewayDetails
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          Tab,
          {
            value: "#liveConnectionLog",
            label: "tesseract.gateways.liveLog.tabTitle",
            children: /* @__PURE__ */ jsx(
              LiveLog,
              {
                id,
                serial: activeSerial,
                messagesType: "gateways",
                tableName: "gatewayLive",
                resource: gatewayType && (gatewayType === "on-prem" ? apiResources.gatewayOnPremLive : apiResources.gatewayCloudLive),
                details: gatewayDetails
              }
            )
          }
        ),
        /* @__PURE__ */ jsx(
          Tab,
          {
            value: "#auditLog",
            label: "tesseract.gateways.auditLog.tabTitle",
            children: /* @__PURE__ */ jsx(
              ApplianceAuditLog,
              {
                id,
                messagesType: "gateways",
                resource: apiResources.gatewayAuditLog,
                details: gatewayDetails
              }
            )
          }
        )
      ]
    }
  ) : /* @__PURE__ */ jsx(NoResourcePage, { content: "tesseract.gateways.gatewayNotFound" });
};
var GatewayDetails_default = GatewayDetails;
export {
  GatewayDetails_default as default
};
