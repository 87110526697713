var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { useDeepCompareMemo } from "../UtilHooks";
import { Collapse, IconButton } from "@barracuda-internal/bds-core";
import { GridColumn } from "@progress/kendo-react-grid";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
import { ListCardContent } from "../../components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { get } from "lodash";
const styles = (theme) => createStyles({
  expandRow: {
    backgroundColor: theme.palette.background.default
  },
  expandCell: {
    padding: "10px 0 !important"
  },
  detailCellClosed: {
    "&.k-detail-cell": {
      transition: "padding linear 250ms",
      padding: "0 8px",
      borderBottom: 0
    }
  },
  details: {
    padding: "0 !important",
    boxShadow: "none !important",
    backgroundColor: "transparent !important"
  },
  expandIcon: {
    transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms"
  },
  expandedIcon: {
    transform: "rotate(180deg)"
  },
  expandColumnHeader: {
    "& a.k-link": {
      cursor: "default"
    }
  }
});
const useStyles = makeOverrideableStyles("DataTableExpandableRows", styles);
const useDataTableExpandableRows = (data, children, props) => {
  const [expandedItems, setExpandedItems] = useState([]);
  const { expandableFields, rowId = "" } = props;
  const classes = useStyles(props);
  const memoData = useDeepCompareMemo(() => expandableFields ? data.map((item, index) => __spreadProps(__spreadValues({}, item), {
    isExpanded: expandedItems.includes(get(item, rowId, index))
  })) : data, [!!expandableFields, data, expandedItems]);
  const cellRender = useCallback((cell, cellProps, additionalRender) => {
    var _a;
    if (cellProps.field === "isExpanded" && expandableFields) {
      return React.cloneElement(cell, {
        className: classNames(cellProps.className, classes.expandCell),
        children: /* @__PURE__ */ jsx(
          IconButton,
          {
            size: "small",
            onClick: () => setExpandedItems((entries) => {
              const id = get(cellProps.dataItem, rowId, cellProps.dataIndex);
              if (entries.includes(id)) {
                return entries.filter((entry) => entry !== id);
              }
              return [...entries, id];
            }),
            children: /* @__PURE__ */ jsx(
              ExpandMoreIcon,
              {
                className: classNames(((_a = cellProps == null ? void 0 : cellProps.dataItem) == null ? void 0 : _a.isExpanded) && classes.expandedIcon, classes.expandIcon)
              }
            )
          }
        )
      });
    }
    return additionalRender ? additionalRender(cell, cellProps) : cell;
  }, [!expandableFields]);
  const rowRender = useCallback((row, rowProps) => {
    var _a;
    const rowExpanded = (_a = rowProps == null ? void 0 : rowProps.dataItem) == null ? void 0 : _a.isExpanded;
    return expandableFields ? [
      row,
      /* @__PURE__ */ jsx(
        "tr",
        {
          className: classNames(classes.expandRow, "k-detail-row"),
          children: /* @__PURE__ */ jsx(
            "td",
            {
              className: classNames(!rowExpanded && classes.detailCellClosed, "k-detail-cell"),
              colSpan: children.length + 1,
              children: /* @__PURE__ */ jsx(Collapse, { in: rowExpanded, className: classes.details, children: /* @__PURE__ */ jsx(ListCardContent, { data: rowProps.dataItem, children: expandableFields }) })
            }
          )
        },
        "details-" + get(rowProps == null ? void 0 : rowProps.dataItem, rowId, rowProps == null ? void 0 : rowProps.dataIndex)
      )
    ] : row;
  }, [expandableFields]);
  return {
    data: memoData,
    cellRender,
    rowRender,
    children: expandableFields ? [
      ...children,
      /* @__PURE__ */ jsx(
        GridColumn,
        {
          headerClassName: classes.expandColumnHeader,
          field: "isExpanded",
          title: " ",
          width: 64,
          sortable: false
        },
        "expanded"
      )
    ] : children
  };
};
export {
  useDataTableExpandableRows
};
