var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import {
  ActionButtonsField,
  ChipArrayField,
  DeleteDialog,
  EmptyPageContent,
  getArrayDataContent,
  ListField,
  SelectedChoiceField,
  SelectField,
  TablePage,
  TextField,
  useCrudProps,
  useMixpanel
} from "@cuda-react/core";
import { AppsResourcesGraphic, CustomAppsIcon, GetStartedBackgroundGraphic } from "@cuda-react/icons";
import { useRef, useState } from "react";
import { categoryIcons } from "../../../components/iconMapping";
import CreateCustomAppDialog from "./dialogs/CreateCustomAppDialog";
import EditCustomAppDialog from "./dialogs/EditCustomAppDialog";
import { makeStyles } from "@mui/styles";
import { get } from "lodash";
import CustomAppDestinationFilter from "./CustomAppDestinationFilter";
import apiResources from "../../../apiResources";
const useStyles = makeStyles({
  destinations: {
    width: "30%"
  }
});
const CustomApps = () => {
  var _a, _b, _c;
  const registerAction = useMixpanel("Apps");
  const choicesData = (_a = useCrudProps(apiResources.sdwanPolicies)[0]) == null ? void 0 : _a.data;
  const protocolData = (_b = useCrudProps(apiResources.protocol, {}, { cache: true })[0]) == null ? void 0 : _b.data;
  const typeData = (_c = useCrudProps(apiResources.customAppTypes, {}, { cache: true })[0]) == null ? void 0 : _c.data;
  const classes = useStyles();
  const tableRefreshRef = useRef();
  const [actionVisible, setActionVisible] = useState(false);
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      resource: apiResources.customApps,
      title: "tesseract.securityAndAccess.pageTitle",
      subtitle: "tesseract.securityAndAccess.apps",
      titleActions: actionVisible && /* @__PURE__ */ jsx(
        CreateCustomAppDialog,
        {
          resource: apiResources.customApps,
          onSuccess: tableRefreshRef.current
        }
      ),
      params: (data) => __spreadProps(__spreadValues({}, data), {
        filter: __spreadProps(__spreadValues(__spreadValues({}, get(data, "filter", {})), get(data, "filter.destinations", {})), {
          destinations: void 0,
          internal: 0
        })
      }),
      minCellWidth: 224,
      authenticated: true,
      resetOnGlobalParamChange: true,
      refreshRef: tableRefreshRef,
      onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table"),
      noDataPage: /* @__PURE__ */ jsx(
        EmptyPageContent,
        {
          background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
          icon: /* @__PURE__ */ jsx(AppsResourcesGraphic, {}),
          iconHeader: "tesseract.security.applicationControl.noData.appResource.iconHeader",
          textHeader: "tesseract.security.applicationControl.noData.appResource.textHeader",
          text: "tesseract.security.applicationControl.noData.appResource.text",
          actions: /* @__PURE__ */ jsx(
            CreateCustomAppDialog,
            {
              create: true,
              label: "tesseract.security.applicationControl.noData.appResource.labelButton",
              onSuccess: tableRefreshRef.current
            }
          )
        }
      ),
      children: [
        /* @__PURE__ */ jsx(
          ListField,
          {
            source: "name",
            label: "tesseract.customApps.table.name",
            icon: /* @__PURE__ */ jsx(CustomAppsIcon, {}),
            filter: "text",
            cellClassName: classes.name,
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.customApps.table.description",
            filter: "text",
            cellClassName: classes.description,
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          SelectedChoiceField,
          {
            source: "type",
            label: "tesseract.customApps.table.type",
            choices: getArrayDataContent(typeData),
            filter: "selectarray",
            filterProps: {
              choices: getArrayDataContent(typeData)
            },
            sortable: true,
            width: 120
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "destinations",
            render: (value) => {
              const lastPort = value.lastPort && value.lastPort !== value.firstPort ? "-" + value.lastPort : "";
              const portRange = value.firstPort ? ":" + value.firstPort + lastPort : "";
              const protocolName = value.protocol && value.protocol !== "any" && (getArrayDataContent(protocolData).find((protocol) => protocol.key === value.protocol) || {}).name;
              return value.destination + portRange + (protocolName ? " (" + protocolName + ")" : "");
            },
            label: "tesseract.customApps.table.destination",
            filter: "custom",
            filterProps: {
              component: CustomAppDestinationFilter,
              choices: getArrayDataContent(protocolData)
            },
            cellClassName: classes.destinations,
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          SelectField,
          {
            source: "categoryId",
            label: "tesseract.customApps.table.category",
            resource: apiResources.customApps,
            id: (data) => data.id,
            choices: getArrayDataContent(choicesData),
            optionValue: "category",
            iconMap: categoryIcons,
            filter: "selectarray",
            filterProps: {
              choices: getArrayDataContent(choicesData),
              optionValue: "category",
              iconMap: categoryIcons
            },
            columnProps: {
              width: 250
            },
            sortable: true,
            onSuccess: tableRefreshRef.current
          }
        ),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            width: 128,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(EditCustomAppDialog, { id: (data) => data.id, onSuccess: tableRefreshRef.current }),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource: apiResources.customApps,
                  title: "tesseract.customApps.delete.title",
                  message: "tesseract.customApps.delete.body",
                  onSuccess: () => {
                    var _a2;
                    (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                    registerAction("Delete");
                  }
                }
              )
            ]
          }
        )
      ]
    }
  );
};
var CustomApps_default = CustomApps;
export {
  CustomApps_default as default
};
