import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { BaseCard } from "../../baseCards";
import { CardSizeOptions, CardSizes } from "../../../typesAndConstants";
import { PreviewDialogContent } from "../PreviewDialogContent";
import { createStyles, makeStyles } from "@mui/styles";
import { Button, Dialog, Grid, Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { WidgetAddIcon } from "@cuda-react/icons";
const styles = (theme) => createStyles({
  card: {
    boxShadow: "none",
    backgroundColor: "#E9E9E9",
    transition: "transform 0.3s",
    "&:hover $addIcon": {
      transform: "scale(1.2)"
    },
    "&:hover $labelText": {
      transform: "scale(1.2)"
    }
  },
  button: {
    width: CardSizeOptions.small.width,
    height: `calc(${CardSizeOptions.small.height}px - ${theme.spacing(0.5)})`,
    "&:disabled": {
      opacity: 0.5
    }
  },
  icon: {
    alignFont: "center"
  },
  addIcon: {
    width: 64,
    height: 64,
    fill: "#5f5f5f",
    transition: "transform 0.3s"
  },
  label: {},
  labelText: {
    textAlign: "center",
    color: theme.palette.reporting.fontColor,
    alignFont: "center",
    transition: "transform 0.3s"
  },
  dialog: {},
  dialogPaper: {
    minWidth: "fit-content"
  }
});
const useStyles = makeStyles(styles);
const AddWidgetCard = (props) => {
  const { cards, onAdd, forceSmall } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const [open, setOpen] = useState(false);
  return /* @__PURE__ */ jsxs(BaseCard, { size: CardSizes.small, className: classes.card, children: [
    /* @__PURE__ */ jsx(
      Button,
      {
        size: "small",
        className: classes.button,
        onClick: () => setOpen(true),
        disabled: Object.values(cards).length < 1,
        children: /* @__PURE__ */ jsxs(Grid, { container: true, justifyContent: "center", direction: "column", spacing: 1, children: [
          /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.icon, children: /* @__PURE__ */ jsx(WidgetAddIcon, { className: classes.addIcon }) }),
          /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.label, children: /* @__PURE__ */ jsx(Typography, { variant: "h6", className: classes.labelText, children: translate("stratosReporting.previewDialog.addWidgetCard") }) })
        ] })
      }
    ),
    /* @__PURE__ */ jsx(
      Dialog,
      {
        open,
        className: classes.dialog,
        maxWidth: false,
        classes: { paper: classes.dialogPaper },
        disableEnforceFocus: true,
        children: /* @__PURE__ */ jsx(
          PreviewDialogContent,
          {
            onClose: () => setOpen(false),
            onAdd,
            cards,
            forceSmall
          }
        )
      }
    )
  ] });
};
export {
  AddWidgetCard
};
