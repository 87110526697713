var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import {
  ConnectedForm,
  DialogBody,
  getArrayDataContent,
  IconSelectorInput,
  SearchableSelectInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  useCrudProps,
  useGlobalParam,
  validateMaxLengthMemo
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import { get, sortBy } from "lodash";
import { categoryIcons } from "../../../components/iconMapping";
import { User, UserGroup } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import useDemoApi from "../../../hooks/useDemoApi";
const useStyles = makeStyles((theme) => ({
  headerText: {
    margin: theme.spacing(2),
    color: "#646464"
  }
}));
const hideInput = (key, type) => (value, data) => !get(data, key) || get(data, key) !== type;
const CreateEditApplication = ({ onClose, onSuccess, create, id }) => {
  const [appName, setAppName] = useState("");
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const [zeroTrustTenant] = useCrudProps(apiResources.zeroTrustSettings);
  const directoriesApi = useDemoApi(apiResources.directories, apiResources.demoDirectories);
  const hasUserDirectory = getArrayDataContent(useCrudProps(directoriesApi)[0].data).length > 0;
  const imageFileTypes = getArrayDataContent(useCrudProps(apiResources.customResponseImageTypes)[0]);
  const visibilityChoices = sortBy(getArrayDataContent(useCrudProps(apiResources.visibility)[0]), "name");
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx(
    DialogBody,
    {
      title: create ? "tesseract.endpoint.appCatalog.create.title" : "tesseract.endpoint.appCatalog.edit.title",
      onClose,
      form: true,
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          resource: apiResources.appCatalog,
          formatRequestData: (data) => {
            var _a, _b;
            return __spreadProps(__spreadValues({}, data), {
              icon: ((_a = data == null ? void 0 : data.icon) == null ? void 0 : _a.data) || null,
              iconFilename: ((_b = data == null ? void 0 : data.icon) == null ? void 0 : _b.filename) || null,
              virtualWanId
            });
          },
          formatResourceData: (data) => __spreadProps(__spreadValues({}, data), {
            icon: { data: data == null ? void 0 : data.icon, filename: data == null ? void 0 : data.iconFilename }
          }),
          onChange: (data) => {
            setAppName(get(data, "name", ""));
          },
          validate: (data) => {
            var _a, _b;
            if ((data == null ? void 0 : data.visibility) === "usersAndGroups" && !((_a = data == null ? void 0 : data.users) == null ? void 0 : _a.length) && !((_b = data == null ? void 0 : data.groups) == null ? void 0 : _b.length)) {
              return {
                users: translate("tesseract.endpoint.appCatalog.validate.userGroupRequired"),
                groups: translate("tesseract.endpoint.appCatalog.validate.userGroupRequired")
              };
            }
            return {};
          },
          params: create ? void 0 : { id },
          onSubmitSuccess: () => {
            onSuccess();
            onClose();
          },
          onCancel: onClose,
          create,
          children: [
            /* @__PURE__ */ jsx(
              TextInput,
              {
                label: "tesseract.endpoint.appCatalog.create.displayName",
                description: "tesseract.endpoint.appCatalog.create.descriptions.name",
                source: "name",
                validate: [validateMaxLengthMemo(64)],
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              IconSelectorInput,
              {
                label: "tesseract.endpoint.appCatalog.create.displayIcon",
                description: "tesseract.endpoint.appCatalog.create.descriptions.icon",
                source: "icon",
                supportedFiles: imageFileTypes,
                name: appName
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                label: "tesseract.endpoint.appCatalog.launchURL",
                description: "tesseract.endpoint.appCatalog.create.descriptions.launchUrl",
                source: "url",
                validate: [validateMaxLengthMemo(255)],
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                label: "tesseract.endpoint.appCatalog.visibility",
                description: "tesseract.endpoint.appCatalog.create.descriptions.visibility",
                source: "visibility",
                choices: visibilityChoices
              }
            ),
            /* @__PURE__ */ jsx(
              SearchableSelectInput,
              {
                label: "tesseract.endpoint.appCatalog.create.associatedApplication",
                description: "tesseract.endpoint.appCatalog.create.descriptions.application",
                source: "app",
                resource: apiResources.zeroTrustApplicationsZtnaBased,
                optionValue: "id",
                filterKey: "applicationId",
                options: {
                  dropdownAutoWidth: true
                },
                iconMap: categoryIcons,
                optionIcon: "categoryId",
                hide: hideInput("visibility", "ztnaBased"),
                clearOnFocus: true,
                isRequired: true
              }
            ),
            !(zeroTrustTenant == null ? void 0 : zeroTrustTenant.data) || hasUserDirectory ? /* @__PURE__ */ jsxs(Fragment, { children: [
              /* @__PURE__ */ jsx(
                SelectArrayInput,
                {
                  label: "tesseract.endpoint.appCatalog.create.users",
                  description: "tesseract.endpoint.appCatalog.create.descriptions.users",
                  source: "users",
                  resource: hasUserDirectory ? apiResources.users : void 0,
                  disabled: !hasUserDirectory,
                  optionValue: "name",
                  options: { dropdownAutoWidth: true },
                  hide: hideInput("visibility", "usersAndGroups"),
                  icon: /* @__PURE__ */ jsx(User, {})
                }
              ),
              /* @__PURE__ */ jsx(
                SelectArrayInput,
                {
                  label: "tesseract.endpoint.appCatalog.create.groups",
                  description: "tesseract.endpoint.appCatalog.create.descriptions.groups",
                  source: "groups",
                  resource: hasUserDirectory ? apiResources.groups : void 0,
                  disabled: !hasUserDirectory,
                  optionValue: "id",
                  options: {
                    dropdownAutoWidth: true
                  },
                  hide: hideInput("visibility", "usersAndGroups"),
                  icon: /* @__PURE__ */ jsx(UserGroup, {})
                }
              )
            ] }) : /* @__PURE__ */ jsxs(Fragment, { children: [
              /* @__PURE__ */ jsx(
                SelectArrayInput,
                {
                  label: "tesseract.endpoint.appCatalog.create.users",
                  description: "tesseract.endpoint.appCatalog.create.descriptions.users",
                  source: "users",
                  resource: hasUserDirectory ? apiResources.users : void 0,
                  disabled: !hasUserDirectory,
                  error: translate("tesseract.endpoint.appCatalog.validate.tenantIdRequired"),
                  optionValue: "name",
                  options: { dropdownAutoWidth: true },
                  hide: hideInput("visibility", "usersAndGroups"),
                  icon: /* @__PURE__ */ jsx(User, {})
                }
              ),
              /* @__PURE__ */ jsx(
                SelectArrayInput,
                {
                  label: "tesseract.endpoint.appCatalog.create.groups",
                  description: "tesseract.endpoint.appCatalog.create.descriptions.groups",
                  source: "groups",
                  resource: hasUserDirectory ? apiResources.groups : void 0,
                  disabled: !hasUserDirectory,
                  error: translate("tesseract.endpoint.appCatalog.validate.tenantIdRequired"),
                  optionValue: "id",
                  options: { dropdownAutoWidth: true },
                  hide: hideInput("visibility", "usersAndGroups"),
                  icon: /* @__PURE__ */ jsx(UserGroup, {})
                }
              )
            ] })
          ]
        }
      )
    }
  );
};
var CreateEditApplication_default = CreateEditApplication;
export {
  CreateEditApplication,
  CreateEditApplication_default as default
};
