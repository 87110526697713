import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const VoipIcon = ({ className }) => {
  var _a, _b, _c, _d;
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-voip",
      viewBox: "0 0 60 60",
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
            d: "M39.6,11.7c4.4,0,8.3,1.8,11.2,4.7l2.6-2.6c-3.5-3.5-8.4-5.7-13.8-5.7s-10.3,2.2-13.8,5.7l2.6,2.6c2.9-2.9,6.8-4.6,11.2-4.6Z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 },
            d: "M39.6,15.4c-3.4,0-6.4,1.4-8.6,3.6l2.6,2.6c1.5-1.5,3.7-2.5,6-2.5s4.5,1,6,2.5l2.6-2.6c-2.2-2.2-5.2-3.6-8.6-3.6Z"
          }
        ),
        /* @__PURE__ */ jsx("path", { style: { fill: (_c = theme.palette.icons) == null ? void 0 : _c.graphics, strokeWidth: 0 }, d: "M39.6,22.7c-1.3,0-2.6.5-3.4,1.4l3.4,3.4,3.4-3.4c-.9-.9-2.1-1.4-3.4-1.4Z" }),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_d = theme.palette.icons) == null ? void 0 : _d.graphics, strokeWidth: 0 },
            d: "M48.6,37l-8.9-1.8c-1.2-.2-2,.5-2.2.7l-6.1,6.1c-6.1-3.5-11.1-8.5-14.6-14.6l6.1-6.1c.6-.6.8-1.4.7-2.2l-1.8-9c-.2-1.1-1.2-1.9-2.4-1.9h-10.1c-1.4,0-2.5,1.1-2.4,2.5.4,7,2.6,13.6,5.9,19.4,3.9,6.7,9.4,12.2,16,16,5.8,3.3,12.4,5.5,19.4,5.9,1.3,0,2.5-1,2.5-2.4v-10.1c0-1.2-.8-2.2-1.9-2.4ZM11.8,12.9h5.4l1.1,5.7-4,4c-1.3-3.2-2.2-6.4-2.6-9.7ZM45.7,46.8c-3.3-.4-6.6-1.3-9.7-2.6l4-4,5.7,1.1v5.4Z"
          }
        )
      ]
    }
  );
};
var VoipIcon_default = VoipIcon;
export {
  VoipIcon,
  VoipIcon_default as default
};
