var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { get, merge } from "lodash";
import { parse } from "query-string";
import { useHistory } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { CrudTypes } from "../clients";
import {
  convertFromSimpleFilters,
  convertToSimpleFilters,
  generateQueryString,
  getArrayDataContent,
  getDataContent
} from "../utils";
import { useDeepCompareEffect, useDeepCompareMemo, useDeepCompareMemoize, usePrevious } from "./UtilHooks";
import { useGlobalParam } from "./GlobalParamsHooks";
import { useCrudSubscription } from "./CrudHooks";
import { useInfiniteScroll } from "./TableScrollHooks";
import TableFooterRow from "../components/table/TableFooterRow/TableFooterRow";
import { Skeleton } from "@barracuda-internal/bds-core";
import { createStyles, makeStyles } from "@mui/styles";
const useConnectedTable = (props) => {
  var _a;
  const {
    pageSizes,
    noRouter,
    defaultFilter,
    defaultSort,
    defaultItemsPerPage = 40,
    resetOnGlobalParamChange,
    pageMode,
    pageSizeAll = 1e3,
    params,
    resource,
    formatData,
    pollInterval
  } = props;
  const { location, replace } = useHistory();
  const initialStates = noRouter ? {} : parse(location.search);
  let initialFilter;
  try {
    initialFilter = get(initialStates, "filter") && convertFromSimpleFilters(JSON.parse(get(initialStates, "filter")));
  } catch (err) {
  }
  const [pageState, setPageState] = useState(pageMode === "paginate" ? {
    skip: get(initialStates, "pageStart") && parseInt(get(initialStates, "pageStart")) || 0,
    take: get(initialStates, "perPage") && parseInt(get(initialStates, "perPage")) || defaultItemsPerPage
  } : {
    // make sure to ignore any uri params regarding pagination when not in use
    skip: 0,
    take: defaultItemsPerPage
  });
  const [sortState, setSortState] = useState([{
    field: get(initialStates, "sort") || get(defaultSort, "field") || void 0,
    dir: get(initialStates, "dir") || get(defaultSort, "dir") || void 0
  }]);
  const [filterState, setFilterState] = useState(
    initialFilter || defaultFilter && convertFromSimpleFilters(defaultFilter) || {}
  );
  const crudParams = useDeepCompareMemo(() => ({
    sort: {
      field: get(sortState, "[0].field"),
      order: get(sortState, "[0].dir", "").toUpperCase()
    },
    filter: convertToSimpleFilters(filterState),
    pagination: pageMode === "all" ? {
      page: 1,
      perPage: pageSizeAll
    } : {
      page: pageState.skip && pageState.take && pageState.skip / pageState.take + 1 || 1,
      perPage: pageState.take
    }
  }), [pageState, sortState, filterState, pageMode]);
  useDeepCompareEffect(() => {
    if (noRouter) {
      return;
    }
    const search = generateQueryString(
      convertToSimpleFilters(filterState),
      get(sortState, "[0].field"),
      get(sortState, "[0].dir"),
      pageMode === "paginate" ? pageState.skip : void 0,
      pageMode === "paginate" ? pageState.take : void 0
    );
    if (search !== location.search) {
      replace(__spreadProps(__spreadValues({}, location), {
        search
      }));
    }
  }, [pageState, sortState, filterState]);
  useDeepCompareEffect(() => {
    setPageState(__spreadProps(__spreadValues({}, pageState), { take: defaultItemsPerPage }));
  }, [defaultItemsPerPage], false);
  const { filter: globalFilter, sort: globalSort, pagination: globalPagination } = useGlobalParam()[0] || {};
  useDeepCompareEffect(() => {
    if (resetOnGlobalParamChange) {
      setPageState({
        skip: 0,
        take: defaultItemsPerPage
      });
      setSortState([{
        field: get(defaultSort, "field", ""),
        dir: get(defaultSort, "dir", void 0)
      }]);
      setFilterState(defaultFilter && convertFromSimpleFilters(defaultFilter) || {});
    }
  }, [globalFilter, globalSort, globalPagination], false);
  const requestParams = typeof params === "function" ? params(crudParams) : merge({}, crudParams, params);
  const [resourceData, loading, fetch] = useCrudSubscription(
    CrudTypes.GET,
    resource,
    requestParams,
    {
      pollInterval,
      crudOptions: { quietErrors: true },
      debounceWait: 250
    }
  );
  const refresh = () => fetch();
  const total = resourceData && ((_a = resourceData.data) == null ? void 0 : _a.total) || 0;
  const returnedData = resourceData && !resourceData.error ? resourceData : { data: [] };
  let formattedData = (formatData ? formatData(getDataContent(returnedData)) : getArrayDataContent(returnedData)).map((item) => __spreadProps(__spreadValues({}, item), {
    total
  }));
  const previousLoading = usePrevious(loading);
  useEffect(() => {
    if (previousLoading && !loading && formattedData.length === 0 && pageMode === "paginate" && pageState.skip > 0) {
      setPageState(__spreadProps(__spreadValues({}, pageState), { skip: 0 }));
    }
  }, [loading, pageState.take]);
  const canLoadMoreRows = pageState.take < total;
  const loadMoreRows = () => {
    setPageState((currentVal) => ({ take: currentVal.take + defaultItemsPerPage, skip: 0 }));
  };
  const infiniteScrollTableProps = useInfiniteScroll({ canLoadMoreRows, pageMode, loading, loadMoreRows });
  if (loading || canLoadMoreRows && pageMode === "dynamic") {
    formattedData = [...formattedData, { "cuda-connected-table-footer": "loading" }];
  } else if (!canLoadMoreRows && pageMode === "dynamic" && formattedData.length > 0) {
    formattedData = [...formattedData, { "cuda-connected-table-footer": "end" }];
  }
  const rowRender = useCallback(
    (row, rowProps) => {
      var _a2;
      return rowProps.dataItem["cuda-connected-table-footer"] ? /* @__PURE__ */ jsx(TableFooterRow, { colSpan: (_a2 = rowProps.children) == null ? void 0 : _a2.length, state: rowProps.dataItem["cuda-connected-table-footer"] }) : row;
    },
    []
  );
  const tableDataMemo = useDeepCompareMemoize(formattedData);
  return {
    dataTableProps: __spreadValues(__spreadProps(__spreadValues(__spreadValues(__spreadValues({}, useDeepCompareMemoize(__spreadValues({
      pageable: pageMode === "paginate" && total ? {
        buttonCount: 5,
        info: true,
        pageSizes: pageSizes || false,
        previousNext: true
      } : false,
      onPageChange: useCallback(
        (event) => {
          var _a2;
          (_a2 = event.nativeEvent) == null ? void 0 : _a2.preventDefault();
          setPageState(event.page);
        },
        []
      ),
      total
    }, pageState))), useDeepCompareMemoize({
      sortable: true,
      onSortChange: useCallback((event) => setSortState(event.sort), []),
      sort: sortState
    })), useDeepCompareMemoize({
      onFilterChange: useCallback((event) => setFilterState(event.filter), []),
      filter: filterState
    })), {
      data: tableDataMemo,
      rowRender
    }), infiniteScrollTableProps),
    pageState,
    setPageState,
    sortState,
    setSortState,
    filterState,
    setFilterState,
    crudParams,
    requestParams,
    total,
    refresh
  };
};
const styles = createStyles({
  skeleton: {
    width: "100%",
    height: 450,
    marginTop: -110
  }
});
const useStyles = makeStyles(styles);
const useNoDataPage = (tableData, noDataPage, filterActive, onPageStatusChange) => {
  var _a;
  const [pageStatus, setPageStatus] = useState(noDataPage ? "initializing" : "table");
  const classes = useStyles({});
  useEffect(() => {
    onPageStatusChange == null ? void 0 : onPageStatusChange(pageStatus);
  }, [pageStatus]);
  useEffect(() => {
    var _a2, _b;
    if (filterActive && pageStatus !== "table") {
      setPageStatus("table");
    } else if (pageStatus === "initializing" && ((_a2 = tableData[0]) == null ? void 0 : _a2["cuda-connected-table-footer"]) === "loading") {
      setPageStatus("loading");
    } else if (pageStatus === "loading" && tableData.length === 0) {
      setPageStatus("noDataPage");
    } else if (tableData.length > 0 && ((_b = tableData[0]) == null ? void 0 : _b["cuda-connected-table-footer"]) !== "loading") {
      setPageStatus("table");
    }
  }, [pageStatus, tableData.length, (_a = tableData[0]) == null ? void 0 : _a["cuda-connected-table-footer"], filterActive]);
  if (["initializing", "loading"].includes(pageStatus)) {
    return /* @__PURE__ */ jsx(Skeleton, { className: classes.skeleton });
  }
  return pageStatus === "noDataPage" ? noDataPage : null;
};
export {
  useConnectedTable,
  useNoDataPage
};
