import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography } from "@barracuda-internal/bds-core";
import {
  getArrayDataContent,
  HiddenInput,
  PasswordInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  useCrudProps,
  validateMaxLength
} from "@cuda-react/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useSiteInputValidations } from "../../../../../hooks/createEditSiteHooks";
import { get } from "lodash";
import { useWatch } from "react-hook-form";
import apiResources from "../../../../../apiResources";
const useStyles = makeStyles((theme) => ({
  message: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
const WanStep = ({ applianceType, series, stepIndex = 0 }) => {
  var _a, _b, _c;
  const serials = useWatch({ name: "serials" }) || [];
  const portsData = getArrayDataContent((_a = useCrudProps(apiResources.modelPorts, { id: get(serials, "[0].model") })[0]) == null ? void 0 : _a.data);
  const wanTypesData = getArrayDataContent((_b = useCrudProps(apiResources.siteModes)[0]) == null ? void 0 : _b.data);
  const providersData = getArrayDataContent((_c = useCrudProps(apiResources.siteProviders)[0]) == null ? void 0 : _c.data);
  const [translate] = useTranslation();
  const classes = useStyles();
  const inputValidations = useSiteInputValidations();
  const getFullSource = (source) => "wans[" + stepIndex + "]." + source;
  const isOnPremGateway = applianceType === "gateway" && series === "T";
  const isTseriesSite = applianceType === "site" && series === "T";
  const fullWanTypeData = isTseriesSite && wanTypesData.length ? [...wanTypesData, {
    key: "bridge",
    name: "tesseract.appliances.dialog.wans.bridgeType.name"
  }] : wanTypesData;
  const forceStaticWan = isOnPremGateway && stepIndex === 0;
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: translate("tesseract.appliances.dialog.wans.setup", { step: stepIndex + 1 }) }),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("name"),
        label: "tesseract.appliances.dialog.wans.name",
        description: "tesseract.appliances.dialog.wans.descriptions.name",
        isRequired: true,
        validate: inputValidations.validateName
      }
    ),
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: getFullSource("mode"),
        label: "tesseract.appliances.dialog.wans.type",
        description: "tesseract.appliances.dialog.wans.descriptions.type",
        choices: fullWanTypeData,
        disabled: forceStaticWan,
        validate: (value, allValues) => {
          const isGatewaySet = (allValues == null ? void 0 : allValues.gateway) !== "__SELECT_EMPTY_VALUE";
          if (value === "bridge" && isGatewaySet) {
            return translate("tesseract.appliances.dialog.wans.validation.mode");
          } else {
            return void 0;
          }
        }
      }
    ),
    forceStaticWan ? /* @__PURE__ */ jsx(
      HiddenInput,
      {
        source: getFullSource("mode"),
        hiddenValue: "static"
      }
    ) : null,
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: getFullSource("wanPort"),
        label: "tesseract.appliances.dialog.wans.bridgeType.wanPort",
        description: "tesseract.appliances.dialog.wans.bridgeType.descriptions.wanPort",
        choices: portsData,
        optionValue: "port",
        optionText: "description",
        hide: (value, data) => get(data, getFullSource("mode")) !== "bridge"
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("address"),
        label: "tesseract.appliances.dialog.wans.bridgeType.address",
        description: "tesseract.appliances.dialog.wans.bridgeType.descriptions.address",
        validate: inputValidations.validatePrivateIp,
        hide: (value, data) => get(data, getFullSource("mode")) !== "bridge",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("netmask"),
        label: "tesseract.appliances.dialog.wans.bridgeType.netmask",
        description: "tesseract.appliances.dialog.wans.bridgeType.descriptions.netmask",
        validate: inputValidations.validateNetmask,
        hide: (value, data) => get(data, getFullSource("mode")) !== "bridge",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("gateway"),
        label: "tesseract.appliances.dialog.wans.bridgeType.gateway",
        description: "tesseract.appliances.dialog.wans.bridgeType.descriptions.gateway",
        validate: inputValidations.validatePrivateIp,
        hide: (value, data) => get(data, getFullSource("mode")) !== "bridge",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      SelectArrayInput,
      {
        source: getFullSource("lanPorts"),
        label: "tesseract.appliances.dialog.wans.bridgeType.lanPorts",
        description: "tesseract.appliances.dialog.wans.bridgeType.descriptions.lanPorts",
        choices: portsData,
        optionValue: "port",
        optionText: "description",
        hide: (value, data) => get(data, getFullSource("mode")) !== "bridge",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: getFullSource("port"),
        label: "tesseract.appliances.dialog.wans.port",
        description: "tesseract.appliances.dialog.wans.descriptions.port",
        choices: portsData,
        optionValue: "port",
        optionText: "description",
        hide: (value, data) => get(data, getFullSource("mode")) === "wwan" || get(data, getFullSource("mode")) === "bridge"
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        label: "tesseract.appliances.dialog.wans.virtualLanId",
        source: getFullSource("virtualLanId"),
        description: "tesseract.appliances.dialog.wans.descriptions.virtualLanId",
        validate: inputValidations.validateVlanId,
        hide: (value, data) => get(data, getFullSource("mode")) === "wwan" || get(data, getFullSource("mode")) === "bridge"
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("userName"),
        label: "tesseract.appliances.dialog.wans.pppoeType.name",
        description: "tesseract.appliances.dialog.wans.pppoeType.description.name",
        validate: validateMaxLength(255),
        hide: (value, data) => get(data, getFullSource("mode")) !== "pppoe"
      }
    ),
    /* @__PURE__ */ jsx(
      PasswordInput,
      {
        source: getFullSource("password"),
        label: "tesseract.appliances.dialog.wans.pppoeType.password",
        description: "tesseract.appliances.dialog.wans.pppoeType.description.password",
        validate: inputValidations.validatePassword,
        hide: (value, data) => get(data, getFullSource("mode")) !== "pppoe"
      }
    ),
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: getFullSource("provider"),
        label: "tesseract.appliances.dialog.wans.providerPinning",
        description: "tesseract.appliances.dialog.wans.descriptions.providerPinning",
        choices: providersData
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("address"),
        label: "tesseract.appliances.dialog.wans.address",
        description: "tesseract.appliances.dialog.wans.descriptions.address",
        validate: inputValidations.validateIp,
        hide: (value, data) => get(data, getFullSource("mode")) !== "static" || get(data, getFullSource("mode")) === "bridge",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("netmask"),
        label: "tesseract.appliances.dialog.wans.netmask",
        description: "tesseract.appliances.dialog.wans.descriptions.netmask",
        validate: inputValidations.validateNetmask,
        hide: (value, data) => get(data, getFullSource("mode")) !== "static",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("gateway"),
        label: "tesseract.appliances.dialog.wans.gateway",
        description: "tesseract.appliances.dialog.wans.descriptions.gateway",
        validate: inputValidations.validateIp,
        hide: (value, data) => get(data, getFullSource("mode")) !== "static",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("pins[0].pin"),
        label: translate("tesseract.appliances.dialog.wans.pin", { serial: get(serials, "[0].serial", "") }),
        description: "tesseract.appliances.dialog.wans.descriptions.pin",
        validate: inputValidations.validatePin,
        hide: (value, data) => get(data, getFullSource("mode")) !== "wwan"
      }
    ),
    /* @__PURE__ */ jsx(
      HiddenInput,
      {
        source: getFullSource("pins[0].serial"),
        hiddenValue: get(serials, "[0].serial", "")
      }
    ),
    serials.length > 1 ? /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("pins[1].pin"),
        label: translate("tesseract.appliances.dialog.wans.pin", { serial: get(serials, "[1].serial", "") }),
        description: "tesseract.appliances.dialog.wans.descriptions.pin",
        validate: inputValidations.validatePin,
        hide: (value, data) => get(data, getFullSource("mode")) !== "wwan"
      }
    ) : null,
    serials.length > 1 ? /* @__PURE__ */ jsx(
      HiddenInput,
      {
        source: getFullSource("pins[1].serial"),
        hiddenValue: get(serials, "[1].serial", "")
      }
    ) : null
  ] });
};
var WanStep_default = WanStep;
export {
  WanStep_default as default
};
