import { jsx, jsxs } from "react/jsx-runtime";
import { Divider, Grid, Skeleton, Typography } from "@barracuda-internal/bds-core";
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { CardSizeOptions } from "../../../typesAndConstants";
const styles = (theme) => createStyles({
  container: {
    minHeight: `calc(${CardSizeOptions.small.height}px - ${theme.spacing(6)})`
  },
  overviewContainer: {
    margin: theme.spacing(4, 0, 0)
  },
  overviewText: {
    marginRight: theme.spacing(1),
    fontSize: 30,
    color: theme.palette.reporting.fontColor,
    fontWeight: 300
  },
  overviewValue: {
    color: theme.palette.general.graphite,
    fontWeight: 700,
    fontSize: 30
  },
  dividerRow: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
    width: "calc(100% - 80px)"
  },
  divider: {
    width: "100%"
  },
  detailsRow: {
    width: "100%",
    display: "flex",
    height: 112,
    alignItems: "center",
    flexBasis: "unset"
  },
  detailsContainer: {
    maxHeight: 112,
    flexWrap: "nowrap"
  }
});
const useStyles = makeStyles(styles);
const StatisticsOverview = (props) => {
  const { children, overviewTitle, overviewValue } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(Grid, { container: true, direction: "column", alignItems: "center", className: classes.container, children: [
    /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, children: /* @__PURE__ */ jsxs(Grid, { container: true, alignItems: "center", className: classes.overviewContainer, children: [
      /* @__PURE__ */ jsx(Typography, { variant: "h4", className: classes.overviewText, children: translate(overviewTitle) }),
      overviewValue !== void 0 ? /* @__PURE__ */ jsx(Typography, { variant: "h4", className: classes.overviewValue, children: overviewValue }) : /* @__PURE__ */ jsx(Skeleton, { variant: "rounded", width: 28, height: 34 })
    ] }) }),
    /* @__PURE__ */ jsxs(React.Fragment, { children: [
      /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.dividerRow, children: /* @__PURE__ */ jsx(Divider, { className: classes.divider }) }),
      children ? /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.detailsRow, children: /* @__PURE__ */ jsx(Grid, { container: true, className: classes.detailsContainer, alignItems: "flex-end", justifyContent: "space-evenly", children }) }) : /* @__PURE__ */ jsx(Grid, { container: true, alignItems: "flex-end", justifyContent: "center", className: classes.detailsContainer, children: /* @__PURE__ */ jsx(Grid, { item: true, xs: 11, children: /* @__PURE__ */ jsx(Skeleton, { variant: "rounded", height: 102 }) }) })
    ] })
  ] });
};
export {
  StatisticsOverview
};
