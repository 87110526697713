import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography } from "@barracuda-internal/bds-core";
import { getArrayDataContent, SelectInput, TextInput, useCrudProps } from "@cuda-react/core";
import { useSiteInputValidations } from "../../../../../hooks/createEditSiteHooks";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useWatch } from "react-hook-form";
import apiResources from "../../../../../apiResources";
import { get } from "lodash";
const useStyles = makeStyles((theme) => ({
  message: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
const LanStep = ({ stepIndex = 0 }) => {
  var _a, _b;
  const getFullSource = (source) => "lans[" + stepIndex + "]." + source;
  const model = useWatch({ name: "serials.0.model" });
  const portsData = getArrayDataContent((_a = useCrudProps(apiResources.modelPorts, { id: model })[0]) == null ? void 0 : _a.data);
  const inputValidations = useSiteInputValidations();
  const [translate] = useTranslation();
  const classes = useStyles();
  const dhcpTypeChoices = getArrayDataContent((_b = useCrudProps(apiResources.siteConfigurationTypes)[0]) == null ? void 0 : _b.data);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: translate("tesseract.appliances.dialog.lans.setup", { step: stepIndex + 1 }) }),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("name"),
        label: "tesseract.appliances.dialog.lans.name",
        description: "tesseract.appliances.dialog.lans.descriptions.name",
        isRequired: true,
        validate: inputValidations.validateName
      }
    ),
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: getFullSource("port"),
        label: "tesseract.appliances.dialog.lans.port",
        description: "tesseract.appliances.dialog.lans.descriptions.port",
        choices: portsData,
        optionValue: "port",
        optionText: "description"
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        label: "tesseract.appliances.dialog.lans.virtualLanId",
        source: getFullSource("virtualLanId"),
        description: "tesseract.appliances.dialog.lans.descriptions.virtualLanId",
        validate: inputValidations.validateVlanId
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("address"),
        label: "tesseract.appliances.dialog.lans.address",
        description: "tesseract.appliances.dialog.lans.descriptions.address",
        validate: inputValidations.validatePrivateIp,
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("netmask"),
        label: "tesseract.appliances.dialog.lans.netmask",
        description: "tesseract.appliances.dialog.lans.descriptions.netmask",
        validate: inputValidations.validateNetmask,
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: getFullSource("dhcp.type"),
        label: "tesseract.appliances.dialog.lans.dhcpRelayType",
        description: "tesseract.appliances.dialog.lans.descriptions.dhcpRelayType",
        choices: dhcpTypeChoices,
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("dhcp.description"),
        label: "tesseract.appliances.dialog.lans.dhcpDescription",
        description: "tesseract.appliances.dialog.lans.descriptions.dhcpDescription",
        hide: (value, data) => get(data, getFullSource("dhcp.type")) !== "dhcpService"
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("dhcp.firstIp"),
        label: "tesseract.appliances.dialog.lans.dhcpFirstIp",
        description: "tesseract.appliances.dialog.lans.descriptions.dhcpFirstIp",
        validate: inputValidations.validateIp,
        hide: (value, data) => get(data, getFullSource("dhcp.type")) !== "dhcpService",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("dhcp.lastIp"),
        label: "tesseract.appliances.dialog.lans.dhcpLastIp",
        description: "tesseract.appliances.dialog.lans.descriptions.dhcpLastIp",
        validate: inputValidations.validateIp,
        hide: (value, data) => get(data, getFullSource("dhcp.type")) !== "dhcpService",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("dhcp.domain"),
        label: "tesseract.appliances.dialog.lans.dhcpDomain",
        description: "tesseract.appliances.dialog.lans.descriptions.dhcpDomain",
        hide: (value, data) => get(data, getFullSource("dhcp.type")) !== "dhcpService"
      }
    )
  ] });
};
var LanStep_default = LanStep;
export {
  LanStep,
  LanStep_default as default
};
