import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const LiveLogTwoWayIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-access-live-log-two-way",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "polygon",
        {
          style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
          points: "42.1 12.5 38.6 16 50 27.5 10.5 27.5 10 27.5 21.5 16 17.9 12.5 .4 30 17.9 47.5 21.4 44 10 32.5 10.5 32.5 50 32.5 38.5 44 42.1 47.5 59.6 30 42.1 12.5"
        }
      )
    }
  );
};
var LiveLogTwoWayIcon_default = LiveLogTwoWayIcon;
export {
  LiveLogTwoWayIcon_default as default
};
