import { jsx, jsxs } from "react/jsx-runtime";
import { Card, StatusIconField, TableCardContent, TextField } from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import { get } from "lodash";
import { getVpnTunnelStatusIcon } from "../../../../components/iconMapping";
const flattenVPNTunnels = (content) => content.flatMap((instance) => instance.tunnels);
const VPNTunnelsCard = ({ type, details, gatewayType }) => {
  const isSc = get(details, "modelSeries") === "S" || !get(details, "modelSeries");
  const apiResourcesLink = type === "sites" ? apiResources.configurationSiteVPNTunnels : gatewayType === "on-prem" ? apiResources.configurationOnPremVPNTunnels : apiResources.configurationGatewayVPNTunnels;
  return type === "sites" && isSc ? null : /* @__PURE__ */ jsx(
    Card,
    {
      id: "VPNTunnelsCard",
      title: "tesseract.appliances.dashboard.details.vpnTunnels.title",
      children: /* @__PURE__ */ jsxs(
        TableCardContent,
        {
          resource: apiResourcesLink,
          pollInterval: 3e5,
          params: { id: details.id },
          formatData: (data) => Array.isArray(data) ? flattenVPNTunnels(data) : [],
          children: [
            /* @__PURE__ */ jsx(
              StatusIconField,
              {
                label: "tesseract.appliances.dashboard.details.vpnTunnels.status",
                source: "status",
                icon: getVpnTunnelStatusIcon,
                textSource: "status",
                textPrefix: "tesseract.appliances.dashboard.details.vpnTunnels.state."
              }
            ),
            /* @__PURE__ */ jsx(
              TextField,
              {
                label: "tesseract.appliances.dashboard.details.vpnTunnels.name",
                source: "name"
              }
            ),
            /* @__PURE__ */ jsx(
              TextField,
              {
                label: "tesseract.appliances.dashboard.details.vpnTunnels.peer",
                source: "peer"
              }
            ),
            /* @__PURE__ */ jsx(
              TextField,
              {
                label: "tesseract.appliances.dashboard.details.vpnTunnels.local",
                source: "local"
              }
            ),
            /* @__PURE__ */ jsx(
              TextField,
              {
                label: "tesseract.appliances.dashboard.details.vpnTunnels.type",
                source: "type"
              }
            )
          ]
        }
      )
    }
  );
};
var VPNTunnelsCard_default = VPNTunnelsCard;
export {
  VPNTunnelsCard_default as default
};
