import { jsx, jsxs } from "react/jsx-runtime";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@barracuda-internal/bds-core";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
const ErrorDialog = ({ directory, open, onClose }) => {
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(Dialog, { open, scroll: "paper", fullWidth: true, children: [
    /* @__PURE__ */ jsxs(DialogTitle, { display: "flex", justifyContent: "space-between", children: [
      /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.identity.userDirectories.syncErrors") }),
      /* @__PURE__ */ jsx(IconButton, { onClick: onClose, children: /* @__PURE__ */ jsx(Close, {}) })
    ] }),
    /* @__PURE__ */ jsx(DialogContent, { children: directory.syncErrors.map((error, index) => /* @__PURE__ */ jsx(Typography, { mb: 2, children: error }, index)) })
  ] });
};
export {
  ErrorDialog
};
