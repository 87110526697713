import { jsx } from "react/jsx-runtime";
import { useCrudFetch } from "./CrudHooks";
import { CrudTypes } from "../clients";
import { useCallback, useEffect, useRef } from "react";
import { debounce, get } from "lodash";
import { CircularProgress } from "@barracuda-internal/bds-core";
import { getArrayDataContent } from "../utils";
import { useGlobalParam } from "./GlobalParamsHooks";
const useTableChoices = (url, options, optionIcon) => {
  const knownIds = useRef([]);
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  useEffect(() => {
    knownIds.current = [];
  }, [virtualWanId]);
  const [data, loading, performFetch] = useCrudFetch(CrudTypes.GET, url, options == null ? void 0 : options.crudParams, options == null ? void 0 : options.crudOptions);
  const debounceFetch = debounce(() => performFetch(
    {
      filter: { [(options == null ? void 0 : options.filterId) || "id"]: knownIds.current },
      pagination: {
        page: 1,
        perPage: 2e3
      }
    }
  ), (options == null ? void 0 : options.debounceWait) || 50);
  const choiceArray = [...getArrayDataContent(data.data), ...(options == null ? void 0 : options.staticChoices) || []];
  const renderText = useCallback((value, data2, ...args) => {
    const id = (options == null ? void 0 : options.source) ? get(data2, options.source) : value;
    const choice = choiceArray.find((item) => get(item, (options == null ? void 0 : options.optionValue) || "key") === id);
    const choiceNeedsLoading = !choice && !knownIds.current.includes(id);
    if (choiceNeedsLoading) {
      knownIds.current.push(id);
      debounceFetch();
    }
    return (options == null ? void 0 : options.render) ? options.render(choice, choiceNeedsLoading || loading, value, data2, ...args) : get(choice, (options == null ? void 0 : options.optionText) || "name", choiceNeedsLoading || loading ? /* @__PURE__ */ jsx(CircularProgress, { size: 14 }) : null);
  }, [choiceArray, loading]);
  const getIconId = useCallback((value, data2) => {
    const id = (options == null ? void 0 : options.source) ? get(data2, options.source) : value;
    const choice = choiceArray.find((item) => get(item, (options == null ? void 0 : options.optionValue) || "key") === id);
    return get(choice, optionIcon || "");
  }, [optionIcon, choiceArray]);
  return optionIcon ? [renderText, getIconId] : renderText;
};
export {
  useTableChoices
};
