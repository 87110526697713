import { jsx, jsxs } from "react/jsx-runtime";
import { ConnectedForm, DialogBody, TextInput } from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";
import { IdentityProviderSAMLForm } from "./IdentityProviderSAMLForm";
import { RedirectUriInfo } from "./RedirectUriInfo";
const i18nScope = "tesseract.identity.identityProviders.add";
const IdentityProviderForm = ({
  onClose,
  create,
  identityProvider,
  id
}) => {
  if (identityProvider.type === "saml") {
    return /* @__PURE__ */ jsx(
      IdentityProviderSAMLForm,
      {
        onSubmit: () => onClose(true),
        onCancel: () => onClose(false),
        create,
        identityProvider,
        id
      }
    );
  }
  return /* @__PURE__ */ jsx(
    DialogBody,
    {
      title: create ? `tesseract.identity.identityProviders.createIdentityProvider` : `tesseract.identity.identityProviders.editIdentityProvider`,
      onClose: () => onClose(false),
      form: true,
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          create,
          resource: apiResources.identityProvider,
          params: create ? void 0 : { id },
          onSubmitSuccess: () => onClose(true),
          onCancel: () => onClose(false),
          initialValues: identityProvider,
          children: [
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "displayName",
                label: `${i18nScope}.options.displayName`,
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "options.tenantId",
                label: `${i18nScope}.options.tenantId`,
                hide: (value, idp) => (idp == null ? void 0 : idp.type) !== "entraid",
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "options.discoveryEndpoint",
                label: `${i18nScope}.options.discoveryURL`,
                hide: (value, idp) => (idp == null ? void 0 : idp.type) !== "oidc",
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "options.clientId",
                label: `${i18nScope}.options.clientId`,
                hide: (value, idp) => (idp == null ? void 0 : idp.type) !== "oidc",
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "options.clientSecret",
                label: `${i18nScope}.options.clientSecret`,
                hide: (value, idp) => (idp == null ? void 0 : idp.type) !== "oidc",
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "options.scope",
                label: `${i18nScope}.options.scope`,
                hide: (value, idp) => (idp == null ? void 0 : idp.type) !== "oidc",
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "options.googleWorkspaceDomain",
                label: `${i18nScope}.options.googleWorkspaceDomain`,
                hide: (value, idp) => (idp == null ? void 0 : idp.type) !== "google",
                isRequired: true
              }
            ),
            (identityProvider == null ? void 0 : identityProvider.type) === "oidc" ? /* @__PURE__ */ jsx(RedirectUriInfo, {}) : null
          ]
        }
      )
    }
  );
};
export {
  IdentityProviderForm
};
