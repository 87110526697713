import { jsx } from "react/jsx-runtime";
import { ButtonDialog } from "@cuda-react/core";
import EnableSupportContent from "./EnableSupportContent";
const EnableSupportDialog = ({ applianceType, uuid, name, gatewayType, applicationId }) => /* @__PURE__ */ jsx(ButtonDialog, { buttonText: "tesseract.appliances.support.buttonText", children: /* @__PURE__ */ jsx(
  EnableSupportContent,
  {
    applianceType,
    uuid,
    name,
    gatewayType,
    applicationId
  }
) });
var EnableSupportDialog_default = EnableSupportDialog;
export {
  EnableSupportDialog_default as default
};
