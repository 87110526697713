import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const OverrideGraphic = ({ className }) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-graphic"),
      id: "cuda-graphic-override",
      viewBox: "0 0 96 96",
      children: /* @__PURE__ */ jsx("g", { id: "ArtBoard11", children: /* @__PURE__ */ jsxs("g", { id: "Ebene-1", children: [
        /* @__PURE__ */ jsx("rect", { style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 }, x: "7.7", y: "41.4", width: "5.4", height: "5.4" }),
        /* @__PURE__ */ jsx("rect", { style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 }, x: "20.4", y: "66.8", width: "5.4", height: "5.4" }),
        /* @__PURE__ */ jsx("path", { style: { fill: (_c = theme.palette.icons) == null ? void 0 : _c.graphics, strokeWidth: 0 }, d: "M7.8,33.6h5.3v-5.3c-2.8.4-4.9,2.6-5.3,5.3" }),
        /* @__PURE__ */ jsx("rect", { style: { fill: (_d = theme.palette.icons) == null ? void 0 : _d.graphics, strokeWidth: 0 }, x: "7.7", y: "54.1", width: "5.4", height: "5.4" }),
        /* @__PURE__ */ jsx("path", { style: { fill: (_e = theme.palette.icons) == null ? void 0 : _e.graphics, strokeWidth: 0 }, d: "M45.9,66.8v5.3c2.8-.4,4.9-2.6,5.3-5.3h-5.3Z" }),
        /* @__PURE__ */ jsx("rect", { style: { fill: (_f = theme.palette.icons) == null ? void 0 : _f.graphics, strokeWidth: 0 }, x: "33.1", y: "66.8", width: "5.4", height: "5.4" }),
        /* @__PURE__ */ jsx("path", { style: { fill: (_g = theme.palette.icons) == null ? void 0 : _g.graphics, strokeWidth: 0 }, d: "M13.1,72.2v-5.3h-5.3c.4,2.8,2.6,4.9,5.3,5.3" }),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_h = theme.palette.icons) == null ? void 0 : _h.graphics, strokeWidth: 0 },
            d: "M53.8,22.9c-.2-.3-.7-.3-1,0l-2,2,4.2,4.2,2-2c.1-.1.2-.3.2-.5s0-.3-.2-.5l-3.3-3.3Z"
          }
        ),
        /* @__PURE__ */ jsx("path", { style: { fill: (_i = theme.palette.icons) == null ? void 0 : _i.graphics, strokeWidth: 0 }, d: "M48.3,27.4l-14.7,14.7v4.2h4.2l14.7-14.7-4.2-4.2Z" }),
        /* @__PURE__ */ jsx("path", { style: { fill: (_j = theme.palette.general) == null ? void 0 : _j.white, strokeWidth: 0 }, d: "M72.9,58.3h-3.4v6.8h-6.8v3.4h6.8v6.9h3.4v-6.9h6.8v-3.4h-6.8v-6.8Z" }),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_k = theme.palette.icons) == null ? void 0 : _k.graphics, strokeWidth: 0 },
            d: "M57.5,52.3h-29.9V17.4h34.6v31.6c1.7-.9,3.5-1.5,5.4-1.8v-28.8c0-3.5-2.8-6.3-6.3-6.3H28.6c-3.5,0-6.3,2.8-6.3,6.3v33c0,3.5,2.8,6.3,6.3,6.3h24.9c1-2,2.4-3.9,4.1-5.4"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_l = theme.palette.success) == null ? void 0 : _l.main, strokeWidth: 0 },
            d: "M71.2,49.7c-9.5,0-17.1,7.7-17.1,17.1s7.7,17.1,17.1,17.1,17.1-7.7,17.1-17.1-7.7-17.1-17.1-17.1ZM79.7,68.6h-6.8v6.9h-3.4v-6.9h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"
          }
        )
      ] }) })
    }
  );
};
var OverrideGraphic_default = OverrideGraphic;
export {
  OverrideGraphic,
  OverrideGraphic_default as default
};
