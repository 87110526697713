import { get } from "lodash";
const progressFunction = (x1) => {
  const exp = 5.0326 - x1 * 0.54;
  return Math.exp(exp) + 3;
};
const statusColors = [
  "palette.general.blue",
  "palette.general.yellow",
  "palette.general.purple",
  "palette.general.magenta",
  "palette.general.turquoise",
  "palette.general.darkBlue",
  "palette.general.magenta"
];
const getStatusColorByIndex = (index, theme) => get(theme, statusColors[index % statusColors.length] || statusColors[0], theme.palette.primary.main);
const colorFunction = (percentage, theme) => getGradientFromHexColors(get(theme, "palette.neutral.main", theme.palette.primary.main), get(theme, "palette.neutral.light", theme.palette.primary.light), percentage / 100);
const colorFunctionSuccess = (percentage, theme) => getGradientFromHexColors(theme.palette.success.main, theme.palette.success.light, percentage / 100);
const colorFunctionError = (percentage, theme) => getGradientFromHexColors(theme.palette.error.main, theme.palette.error.light, percentage / 100);
const compareSemanticVersions = (firstStr, secondStr) => {
  const firstStringSplitted = firstStr.split(".");
  const secondStringSplitted = secondStr.split(".");
  const minStringsLength = Math.min(firstStringSplitted.length, secondStringSplitted.length);
  for (let index = 0; index < minStringsLength; index++) {
    const firstStringCompValue = +firstStringSplitted[index] || 0;
    const secondStringCompValue = +secondStringSplitted[index] || 0;
    if (firstStringCompValue !== secondStringCompValue) {
      return firstStringCompValue > secondStringCompValue ? 1 : -1;
    }
  }
  return firstStringSplitted.length - secondStringSplitted.length;
};
const groupBySemanticVersions = (object, deepness) => {
  const sortObject = {};
  const versions = Object.keys(object);
  versions.forEach((version) => {
    const splittedString = version.split(".");
    let newValue;
    if (deepness === "minor") {
      newValue = splittedString[0] + "." + splittedString[1] + ".X";
    } else {
      newValue = splittedString[0] + ".X.X";
    }
    newValue in sortObject ? sortObject[newValue] += object[version] : sortObject[newValue] = object[version];
  });
  return sortObject;
};
const colorToHex = (color) => color.reduce((hexCode, colorPart) => {
  const part = colorPart.toString(16);
  return hexCode + (part.length === 1 ? "0" : "") + part;
}, "#");
const colorToRgba = (color) => `rgba(${color[0]},${color[1]},${color[2]},${color[3]})`;
const hexToColor = (color) => {
  const hex = color.startsWith("#") ? color.substring(1) : color;
  return hex.length === 3 ? [
    parseInt(hex[0] + hex[0], 16),
    parseInt(hex[1] + hex[1], 16),
    parseInt(hex[2] + hex[2], 16),
    1
  ] : [
    parseInt(hex.substring(0, 2), 16),
    parseInt(hex.substring(2, 4), 16),
    parseInt(hex.substring(4, 6), 16),
    1
  ];
};
const rgbToColor = (color) => {
  const rgbMatch = color.match(/rgba?\((\d+),\s?(\d+),\s?(\d+)(?:,\s?(\d+))?\)/);
  return [
    parseInt((rgbMatch == null ? void 0 : rgbMatch[1]) || "0"),
    parseInt((rgbMatch == null ? void 0 : rgbMatch[2]) || "0"),
    parseInt((rgbMatch == null ? void 0 : rgbMatch[3]) || "0"),
    parseFloat((rgbMatch == null ? void 0 : rgbMatch[4]) || "1")
  ];
};
const stringToColor = (color) => {
  if (color.startsWith("rgb")) {
    return rgbToColor(color);
  }
  return hexToColor(color);
};
const getGradientFromHexColors = (firstColor, secondColor, ratio) => {
  const firstColorRgba = stringToColor(firstColor);
  const secondColorRgba = stringToColor(secondColor);
  const red = Math.ceil(firstColorRgba[0] * ratio + secondColorRgba[0] * (1 - ratio));
  const green = Math.ceil(firstColorRgba[1] * ratio + secondColorRgba[1] * (1 - ratio));
  const blue = Math.ceil(firstColorRgba[2] * ratio + secondColorRgba[2] * (1 - ratio));
  const alpha = firstColorRgba[3] * ratio + secondColorRgba[3] * (1 - ratio);
  return colorToRgba([red, green, blue, alpha]);
};
export {
  colorFunction,
  colorFunctionError,
  colorFunctionSuccess,
  compareSemanticVersions,
  getGradientFromHexColors,
  getStatusColorByIndex,
  groupBySemanticVersions,
  progressFunction
};
