import { jsx, jsxs } from "react/jsx-runtime";
import { categoryIcons } from "../iconMapping";
import { get } from "lodash";
import {
  BooleanInput,
  FormSection,
  SearchableSelectInput,
  SelectArrayInput,
  SelectInput,
  SelectPairArrayInput,
  TextArrayInput
} from "@cuda-react/core";
import apiResources from "../../apiResources";
const hideInput = (key, type) => (value, data) => !get(data, key) || get(data, key) !== type;
const destinationTypeChoices = [
  { key: "application", name: "tesseract.security.destinationCriteria.application" },
  { key: "category", name: "tesseract.security.destinationCriteria.category" },
  { key: "customCategory", name: "tesseract.security.destinationCriteria.customCategory" },
  { key: "domain", name: "tesseract.security.destinationCriteria.domain" },
  { key: "network", name: "tesseract.security.destinationCriteria.network" },
  { key: "site", name: "tesseract.security.destinationCriteria.site" },
  { key: "onPremGateway", name: "tesseract.security.destinationCriteria.onPremGateway" },
  { key: "ingressSite", name: "tesseract.security.destinationCriteria.ingressSite" }
];
const getDestinationTypeChoices = (types) => destinationTypeChoices.filter(
  (choice) => types.includes(choice.key) || choice.key === "onPremGateway" && types.includes("onPremGatewayNetworks")
);
var generateDestinationCriteriaSection_default = (destinationTypes, categoryChoices = []) => {
  const destinationSelectChoices = getDestinationTypeChoices(destinationTypes);
  return (
    // @ts-ignore check if 'collapsable' is actually supported...
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.security.destinationCriteria.section", collapsable: true, children: [
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          source: "destination.type",
          label: "tesseract.security.destinationCriteria.type",
          description: "tesseract.security.destinationCriteria.description",
          choices: destinationSelectChoices,
          isRequired: true
        }
      ),
      destinationTypes.includes("application") ? /* @__PURE__ */ jsx(
        SelectArrayInput,
        {
          source: "destination.applications",
          label: "tesseract.security.destinationCriteria.application",
          description: "tesseract.security.destinationCriteria.applicationDescription",
          resource: apiResources.applications,
          filterKey: "applicationId",
          iconMap: categoryIcons,
          optionIcon: "categoryId",
          optionValue: "id",
          hide: hideInput("destination.type", "application"),
          options: { dropdownAutoWidth: true },
          isRequired: true
        }
      ) : null,
      destinationTypes.includes("category") ? /* @__PURE__ */ jsx(
        SelectArrayInput,
        {
          source: "destination.categories",
          label: "Category",
          description: "tesseract.security.urlFiltering.exceptions.descriptions.categories",
          choices: categoryChoices,
          hide: hideInput("destination.type", "category"),
          isRequired: true
        }
      ) : null,
      destinationTypes.includes("customCategory") ? /* @__PURE__ */ jsx(
        SelectArrayInput,
        {
          source: "destination.customCategories",
          label: "tesseract.security.urlFiltering.exceptions.customCategories",
          description: "tesseract.security.urlFiltering.exceptions.descriptions.customCategories",
          resource: apiResources.customCategories,
          optionValue: "id",
          hide: hideInput("destination.type", "customCategory"),
          isRequired: true
        }
      ) : null,
      destinationTypes.includes("domain") ? /* @__PURE__ */ jsx(
        TextArrayInput,
        {
          source: "destination.domains",
          label: "tesseract.security.urlFiltering.exceptions.domain",
          description: "tesseract.security.urlFiltering.exceptions.descriptions.domains",
          hide: hideInput("destination.type", "domain"),
          isRequired: true
        }
      ) : null,
      destinationTypes.includes("network") ? /* @__PURE__ */ jsx(
        TextArrayInput,
        {
          source: "destination.networks",
          label: "tesseract.security.destinationCriteria.network",
          description: "tesseract.security.destinationCriteria.networkDescription",
          hide: hideInput("destination.type", "network"),
          isRequired: true
        }
      ) : null,
      destinationTypes.includes("onPremGateway") && /* @__PURE__ */ jsx(
        SearchableSelectInput,
        {
          source: "destination.applianceId",
          label: "tesseract.security.destinationCriteria.onPremGateway",
          description: "tesseract.security.destinationCriteria.gatewayDescription",
          resource: apiResources.gateways,
          options: { params: { filter: { type: "on-prem" } } },
          optionValue: "id",
          hide: hideInput("destination.type", "onPremGateway"),
          clearOnFocus: true,
          isRequired: true
        }
      ) || null,
      destinationTypes.includes("onPremGatewayNetworks") ? /* @__PURE__ */ jsx(
        BooleanInput,
        {
          source: "destination.allGateways",
          label: "tesseract.security.destinationCriteria.allOnpremGateways",
          hide: hideInput("destination.type", "onPremGateway")
        }
      ) : null,
      destinationTypes.includes("onPremGatewayNetworks") ? /* @__PURE__ */ jsx(
        SelectPairArrayInput,
        {
          source: "destination.gateways",
          label: "tesseract.security.destinationCriteria.onpremGateway",
          description: "tesseract.security.destinationCriteria.onpremGatewayDescription",
          primarySource: "id",
          primaryLabel: "tesseract.security.destinationCriteria.onpremGateway",
          primaryOptionValue: "uuid",
          resource: apiResources.gatewaysOnPremNetworks,
          addTitle: "tesseract.security.destinationCriteria.addOnpremGateway",
          editTitle: "tesseract.security.destinationCriteria.editOnpremGateway",
          secondarySource: "networks",
          secondaryChoices: "networks",
          secondaryOptionValue: "id",
          secondarySelectAll: true,
          secondarySelectAllText: "tesseract.security.destinationCriteria.allNetworks",
          secondaryLabel: "tesseract.security.destinationCriteria.gatewayNetwork",
          hide: (value, data) => hideInput("destination.type", "onPremGateway")(value, data) || get(data, "destination.allGateways"),
          isRequired: true
        }
      ) : null,
      destinationTypes.includes("site") ? /* @__PURE__ */ jsx(
        BooleanInput,
        {
          source: "destination.allSites",
          label: "tesseract.security.destinationCriteria.allSites",
          hide: hideInput("destination.type", "site")
        }
      ) : null,
      destinationTypes.includes("site") ? /* @__PURE__ */ jsx(
        SelectPairArrayInput,
        {
          source: "destination.sites",
          label: "tesseract.security.destinationCriteria.site",
          description: "tesseract.security.destinationCriteria.siteDescription",
          primarySource: "id",
          primaryLabel: "tesseract.security.destinationCriteria.site",
          primaryOptionValue: "uuid",
          resource: apiResources.siteNetworks,
          addTitle: "tesseract.security.destinationCriteria.addSite",
          editTitle: "tesseract.security.destinationCriteria.editSite",
          secondarySource: "networks",
          secondaryChoices: "networks",
          secondaryOptionValue: "id",
          secondarySelectAll: true,
          secondarySelectAllText: "tesseract.security.destinationCriteria.allNetworks",
          secondaryLabel: "tesseract.security.destinationCriteria.siteNetwork",
          hide: (value, data) => hideInput("destination.type", "site")(value, data) || get(data, "destination.allSites"),
          isRequired: true
        }
      ) : null,
      destinationTypes.includes("ingressSite") ? /* @__PURE__ */ jsx(
        SearchableSelectInput,
        {
          source: "destination.applianceId",
          label: "tesseract.security.destinationCriteria.site",
          description: "tesseract.security.destinationCriteria.siteDescription",
          optionValue: "id",
          filterKey: "site",
          resource: apiResources.sites,
          options: { params: { filter: { modelSeries: "T" } } },
          hide: hideInput("destination.type", "ingressSite"),
          clearOnFocus: true,
          isRequired: true
        }
      ) : null
    ] })
  );
};
export {
  generateDestinationCriteriaSection_default as default,
  getDestinationTypeChoices
};
