var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { BooleanInput, ConnectedForm, useGlobalParam, useMixpanel } from "@cuda-react/core";
import apiResources from "../../../apiResources";
const EditCloudGatewayContent = ({ id, details: { model, gatewayType } }) => {
  const registerAction = useMixpanel(
    "Gateways Settings",
    !!model,
    { model: model || "", type: gatewayType || "" }
  );
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  return /* @__PURE__ */ jsxs(
    ConnectedForm,
    {
      resource: apiResources.cloudGateways,
      id,
      topToolbar: true,
      formatRequestData: (data) => __spreadProps(__spreadValues({}, data), {
        id,
        virtualWanId
      }),
      onSubmitSuccess: (response) => {
        registerAction("Update", {
          model: response.model,
          type: "cloud"
        });
      },
      children: [
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "securePeeredNetworks",
            label: "tesseract.gateways.settings.labels.secureInternetTraffic",
            description: "tesseract.gateways.settings.descriptions.secureInternetTraffic"
          }
        ),
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "disableSyslogStreaming",
            label: "tesseract.syslog.syslogOverride",
            description: "tesseract.syslog.descriptions.syslogOverride_gateway"
          }
        )
      ]
    }
  );
};
var EditCloudGatewayContent_default = EditCloudGatewayContent;
export {
  EditCloudGatewayContent_default as default
};
