var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { get, uniqBy } from "lodash";
import {
  BooleanCheckListInput,
  BooleanInput,
  ConnectedFormPage,
  DateField,
  FileInput,
  getArrayDataContent,
  ReadOnlyInput,
  SelectInput,
  TextField,
  TextInput,
  useCrudProps,
  useMixpanel,
  validateInt,
  validateMaxValueMemo,
  validateMinValueMemo
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import { parseJson } from "../../../utils/jsonUtils";
const Syslog = () => {
  var _a, _b;
  const registerAction = useMixpanel("Syslog");
  const protocols = getArrayDataContent(useCrudProps(apiResources.syslogProtocols)[0]);
  const securityProtocols = getArrayDataContent(useCrudProps(apiResources.syslogSecurityProtocols)[0]);
  const logs = (_b = (_a = useCrudProps(apiResources.syslogLogs)[0]) == null ? void 0 : _a.data) == null ? void 0 : _b.content;
  const groupChoices = uniqBy(logs == null ? void 0 : logs.map((obj) => ({ key: obj.category, name: obj.category })), "key");
  const categoryChoices = logs == null ? void 0 : logs.map((obj) => __spreadProps(__spreadValues({}, obj), { group: obj.category }));
  const disableInput = (key, type) => (value, data) => !get(data, key) || get(data, key) !== type || !get(data, "enabled");
  const defaultValues = {
    enabled: false,
    port: "514",
    protocol: "udp",
    securityProtocol: "tls_v1_2",
    certificate: {},
    logs: categoryChoices == null ? void 0 : categoryChoices.filter((obj) => obj.group === "SECURITY").map((obj) => obj.key)
  };
  return /* @__PURE__ */ jsxs(
    ConnectedFormPage,
    {
      resource: apiResources.settings,
      title: "tesseract.logs.pageTitle",
      subtitle: "tesseract.syslog.pageTitle",
      onSubmitSuccess: () => {
        registerAction("Update");
      },
      formatResourceData: (data) => {
        var _a2;
        const parsedData = parseJson(((_a2 = data == null ? void 0 : data.settings) == null ? void 0 : _a2.syslog_streaming) || "{}");
        if (!parsedData.enabled) {
          return defaultValues;
        }
        return __spreadValues(__spreadValues({}, defaultValues), parsedData);
      },
      formatRequestData: (data) => ({
        settings: {
          syslog_streaming: JSON.stringify(__spreadProps(__spreadValues({}, data), {
            certificate: get(data, "protocol") === "tcpTls" ? data.certificate.data : void 0,
            securityProtocol: get(data, "protocol") === "tcpTls" ? data.securityProtocol : void 0
          }))
        }
      }),
      params: { filter: { type: "virtualWan" } },
      formatError: (error) => error.settings.syslog_streaming,
      clearOnSuccess: true,
      children: [
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "enabled",
            label: "tesseract.syslog.enable"
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "server",
            label: "tesseract.syslog.server",
            description: "tesseract.syslog.descriptions.server",
            disable: (value, data) => !(data == null ? void 0 : data.enabled),
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          SelectInput,
          {
            source: "protocol",
            label: "tesseract.syslog.protocol",
            description: "tesseract.syslog.descriptions.protocol",
            choices: protocols,
            disable: (value, data) => !(data == null ? void 0 : data.enabled),
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "port",
            label: "tesseract.syslog.port",
            description: "tesseract.syslog.descriptions.port",
            validate: [validateInt, validateMaxValueMemo(65535), validateMinValueMemo(1)],
            disable: (value, data) => !(data == null ? void 0 : data.enabled),
            isRequired: true
          }
        ),
        /* @__PURE__ */ jsx(
          FileInput,
          {
            source: "certificate",
            label: "tesseract.syslog.uploadSslCert",
            description: "tesseract.syslog.descriptions.uploadSslCert",
            disable: disableInput("protocol", "tcpTls"),
            readType: "string"
          }
        ),
        /* @__PURE__ */ jsx(
          ReadOnlyInput,
          {
            source: "certificate_cname",
            label: "tesseract.syslog.certificateCName",
            field: TextField,
            hide: (value) => !value,
            disable: disableInput("protocol", "tcpTls")
          }
        ),
        /* @__PURE__ */ jsx(
          ReadOnlyInput,
          {
            source: "certificate_enddate",
            label: "tesseract.syslog.certificateExpiryDate",
            field: DateField,
            hide: (value) => !value,
            disable: disableInput("protocol", "tcpTls")
          }
        ),
        /* @__PURE__ */ jsx(
          SelectInput,
          {
            source: "securityProtocol",
            label: "tesseract.syslog.securityProtocol",
            description: "tesseract.syslog.descriptions.securityProtocol",
            choices: securityProtocols,
            disable: disableInput("protocol", "tcpTls")
          }
        ),
        /* @__PURE__ */ jsx(
          BooleanCheckListInput,
          {
            source: "logs",
            title: "tesseract.syslog.logs",
            label: " ",
            categoryChoices: categoryChoices != null ? categoryChoices : [],
            groupChoices: groupChoices != null ? groupChoices : [],
            disable: (value, data) => !(data == null ? void 0 : data.enabled),
            isSyslog: true
          }
        )
      ]
    }
  );
};
var Syslog_default = Syslog;
export {
  Syslog_default as default
};
