import { jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { OneTrustContext } from "../../../hooks";
const getInitialActiveGroups = () => ((window == null ? void 0 : window.OptanonActiveGroups) || "").split(",").filter((group) => !!group);
const OneTrustProvider = ({ children }) => {
  var _a, _b;
  const [enabled, setEnabled] = useState(!!window.Optanon);
  const [completed, setCompleted] = useState(!!((_b = (_a = window.Optanon) == null ? void 0 : _a.IsAlertBoxClosedAndValid) == null ? void 0 : _b.call(_a)));
  const [allowedGroups, setAllowedGroups] = useState(getInitialActiveGroups());
  const reopenPopup = useCallback(() => {
    var _a2, _b2;
    (_b2 = (_a2 = window.Optanon) == null ? void 0 : _a2.ToggleInfoDisplay) == null ? void 0 : _b2.call(_a2);
  }, []);
  const onConsentChange = (event) => {
    setCompleted(true);
    setAllowedGroups(event.detail);
  };
  const setupAttempts = useRef(0);
  const rejectAllCookie = document.cookie.includes("ONETRUST_REJECT_ALL=true");
  const initialSetup = () => {
    if (window.Optanon) {
      setEnabled(true);
      setCompleted(window.Optanon.IsAlertBoxClosedAndValid());
      setAllowedGroups(getInitialActiveGroups());
      window.Optanon.OnConsentChanged(onConsentChange);
      if (rejectAllCookie) {
        window.Optanon.RejectAll();
      }
    } else if (setupAttempts.current++ < 3) {
      setTimeout(initialSetup, 5e3);
    }
  };
  useEffect(() => {
    initialSetup();
  }, []);
  return /* @__PURE__ */ jsx(OneTrustContext.Provider, { value: { enabled, completed, allowedGroups, reopenPopup }, children });
};
var OneTrustProvider_default = OneTrustProvider;
export {
  OneTrustProvider,
  OneTrustProvider_default as default
};
