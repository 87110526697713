var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { Success } from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import OffIcon from "./OffIcon";
const BooleanIcon = (_a) => {
  var _b = _a, { status } = _b, props = __objRest(_b, ["status"]);
  return status ? /* @__PURE__ */ jsx(Success, __spreadValues({ id: "cuda-icon-on", sx: { color: "success.main" } }, props)) : /* @__PURE__ */ jsx(OffIcon, __spreadValues({}, props));
};
BooleanIcon.propTypes = {
  status: PropTypes.bool
};
var BooleanIcon_default = BooleanIcon;
export {
  BooleanIcon_default as default
};
