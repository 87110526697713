import { jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@barracuda-internal/bds-core";
import { Trans, useTranslation } from "react-i18next";
import { DualListInput } from "@cuda-react/core";
import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  message: {
    margin: theme.spacing(1, 2, 2)
  }
}));
const ResolveSeatLimitStep = ({ remainingSeats, resolvedUsersChoices }) => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const validations = useMemo(() => ({
    validateResolvedUsers: (value) => value && value.length > remainingSeats ? translate("tesseract.endpoint.enrollEmailDialog.validate.seatLimit", { maxCount: remainingSeats, currentCount: value.length }) : void 0
  }), [remainingSeats]);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { className: classes.message, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.endpoint.enrollEmailDialog.resolvedUsers", values: { seats: remainingSeats } }) }),
    /* @__PURE__ */ jsx(
      DualListInput,
      {
        source: "resolvedUsersDualList",
        choices: [{ data: resolvedUsersChoices.map((item) => ({ id: item, name: item })), value: { id: "", name: "" } }],
        inputLabelProps: {
          fullWidth: true
        },
        initialValues: resolvedUsersChoices,
        maxSelectable: remainingSeats,
        additionalInfo: "tesseract.endpoint.enrollEmailDialog.validate.seatLimit",
        validate: validations.validateResolvedUsers,
        hideAdditionalInfoOnError: true
      }
    )
  ] });
};
var ResolveSeatLimitStep_default = ResolveSeatLimitStep;
export {
  ResolveSeatLimitStep,
  ResolveSeatLimitStep_default as default,
  useStyles
};
