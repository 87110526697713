import { jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import { MixpanelContext, MixpanelPrefixContext, useGlobalParam, useOneTrust } from "../../../hooks";
const AnalyticsProvider = ({ children, mixpanelConfig: { token, options = {}, identifier } = {} }) => {
  const [mixpanelPrefix, setMixpanelPrefix] = useState();
  const [hasInitialized, setHasInitialized] = useState(false);
  const [hasIdentified, setHasIdentified] = useState(false);
  const [globalData] = useGlobalParam();
  const userIdentifier = globalData && (identifier == null ? void 0 : identifier(globalData));
  const disabled = document.cookie.includes("DISABLE_MIXPANEL=true");
  const { allowedGroups } = useOneTrust();
  const optedIn = allowedGroups.includes("C0005");
  useEffect(() => {
    if (token && !disabled && optedIn) {
      mixpanel.init(
        token,
        options
      );
      setHasInitialized(true);
    }
  }, [token, disabled, optedIn]);
  useEffect(() => {
    if (hasInitialized) {
      if (!optedIn) {
        mixpanel.opt_out_tracking();
      } else {
        mixpanel.opt_in_tracking();
      }
    }
  }, [optedIn, hasInitialized]);
  useEffect(() => {
    hasInitialized && userIdentifier && mixpanel.identify(userIdentifier);
    (userIdentifier || userIdentifier === null) && setHasIdentified(true);
  }, [hasInitialized, userIdentifier]);
  return /* @__PURE__ */ jsx(MixpanelContext.Provider, { value: hasInitialized && hasIdentified && mixpanel, children: /* @__PURE__ */ jsx(MixpanelPrefixContext.Provider, { value: [mixpanelPrefix, setMixpanelPrefix], children }) });
};
var AnalyticsProvider_default = AnalyticsProvider;
export {
  AnalyticsProvider_default as default
};
