import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const AppCatalogGraphic = ({ className }) => {
  var _a, _b, _c, _d, _e;
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-graphic"),
      id: "cuda-graphic-appcatalog",
      viewBox: "0 0 90 90",
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 },
            d: "M47.1,65.6c0-.8,0-1.6.2-2.4h-30.1V18.7h44.5v27.7c1.7-.5,3.5-.7,5.3-.7v-26.2c0-3.4-2.8-6.1-6.1-6.1H18c-3.4,0-6.1,2.8-6.1,6.1v42.9c0,3.4,2.8,6.1,6.1,6.1h29.3c-.1-1-.2-1.9-.2-2.9Z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 },
            d: "M51.4,53.1h-6.5v-6.6h6.6v6.4c1-1.2,2.2-2.3,3.5-3.3v-6.6h-13.5v13.5h7.7c.6-1.2,1.4-2.4,2.2-3.5Z"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: theme.palette.success.main, strokeWidth: 0 },
            d: "M67,48.5c-9.4,0-17.1,7.6-17.1,17.1s7.6,17.1,17.1,17.1,17.1-7.6,17.1-17.1-7.6-17.1-17.1-17.1ZM75.6,67.3h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"
          }
        ),
        /* @__PURE__ */ jsx("path", { style: { fill: (_c = theme.palette.icons) == null ? void 0 : _c.graphics, strokeWidth: 0 }, d: "M23.8,38.7h13.5v-13.5h-13.5v13.5ZM33.8,28.7v6.6h-6.6v-6.6h6.6Z" }),
        /* @__PURE__ */ jsx("path", { style: { fill: (_d = theme.palette.icons) == null ? void 0 : _d.graphics, strokeWidth: 0 }, d: "M41.5,25.3v13.5h13.5v-13.5h-13.5ZM45,35.3v-6.6h6.6v6.6h-6.6Z" }),
        /* @__PURE__ */ jsx("path", { style: { fill: (_e = theme.palette.icons) == null ? void 0 : _e.graphics, strokeWidth: 0 }, d: "M23.8,56.6h13.5v-13.5h-13.5v13.5ZM33.8,46.5v6.6h-6.6v-6.6h6.6Z" })
      ]
    }
  );
};
var AppCatalogGraphic_default = AppCatalogGraphic;
export {
  AppCatalogGraphic,
  AppCatalogGraphic_default as default
};
