import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography } from "@barracuda-internal/bds-core";
import { Trans, useTranslation } from "react-i18next";
import { DateField, SelectSerialInput, TextField } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import apiResources from "../../../../../apiResources";
const useStyles = makeStyles((theme) => ({
  serialNoteText: {
    marginTop: theme.spacing(1)
  }
}));
const filterToSelectedModel = (selectedAppliances) => selectedAppliances[0] && { model: selectedAppliances[0].model } || {};
const SerialStep = ({ allowHa, mainMessage, series }) => {
  const [translate] = useTranslation();
  const classes = useStyles();
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { children: translate(mainMessage) }),
    allowHa && /* @__PURE__ */ jsx(Typography, { className: classes.serialNoteText, variant: "body1", children: /* @__PURE__ */ jsxs(Trans, { i18nKey: "tesseract.appliances.dialog.serials.haMessage", children: [
      "Translate text ",
      /* @__PURE__ */ jsx("strong", { children: "Bold translate text" }),
      " Translate text"
    ] }) }),
    /* @__PURE__ */ jsxs(
      SelectSerialInput,
      {
        claimResource: apiResources.claimAppliance,
        dynamicFilter: allowHa ? filterToSelectedModel : void 0,
        filter: { series },
        expandSectionButtonText: "tesseract.appliances.dialog.serials.addAppliance",
        linkingCodeInputLabel: "tesseract.appliances.dialog.serials.linkingCode",
        maxSelectable: allowHa ? 2 : 1,
        searchField: "serial",
        source: "serials",
        tableResource: apiResources.serials,
        isRequired: true,
        children: [
          /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dialog.serials.table.serial", source: "serial", sortable: true }),
          /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dialog.serials.table.model", source: "model", sortable: true }),
          /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dialog.serials.table.location", source: "location.summary" }),
          /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dialog.serials.table.orderId", source: "orderId" }),
          /* @__PURE__ */ jsx(DateField, { label: "tesseract.appliances.dialog.serials.table.orderDate", source: "ordered", dateOnly: true })
        ]
      }
    )
  ] });
};
var SerialStep_default = SerialStep;
export {
  SerialStep,
  SerialStep_default as default
};
