import { jsx } from "react/jsx-runtime";
import { Breadcrumbs, Link, Typography } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { Link as RouterLink } from "react-router-dom";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    margin: 0
  },
  title: {
    fontWeight: 300,
    fontSize: 18,
    display: "inline-block",
    color: theme.palette.common.black,
    lineHeight: 1
  },
  link: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  }
});
const useStyles = makeOverrideableStyles("BreadcrumbsTitle", styles);
const BreadcrumbsTitle = (props) => {
  const { title, subtitle, className } = props;
  const [translate] = useTranslation();
  const classes = useStyles(props);
  const titleArray = [title, ...!Array.isArray(subtitle) ? [subtitle] : subtitle];
  return /* @__PURE__ */ jsx(
    "div",
    {
      className: classNames(classes.root, className),
      id: "cuda-react-page-title",
      children: /* @__PURE__ */ jsx(Breadcrumbs, { separator: "\u203A", children: titleArray.map((title2, index) => {
        if (!title2) {
          return null;
        }
        return typeof title2 === "string" ? /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "h6",
            component: "div",
            className: classes.title,
            children: translate(title2)
          },
          index
        ) : /* @__PURE__ */ jsx(
          Link,
          {
            component: RouterLink,
            underline: "none",
            to: title2.path,
            children: /* @__PURE__ */ jsx(
              Typography,
              {
                variant: "h6",
                component: "div",
                className: classNames(classes.title, classes.link),
                children: translate(title2.text)
              }
            )
          },
          index
        );
      }) })
    }
  );
};
var BreadcrumbsTitle_default = BreadcrumbsTitle;
export {
  BreadcrumbsTitle,
  BreadcrumbsTitle_default as default,
  styles
};
