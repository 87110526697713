import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography } from "@barracuda-internal/bds-core";
import { TextField, WizardSummary } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  summaryName: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 200
  }
}));
const SdwanConnectorSubmitStep = ({ data }) => {
  const [translate] = useTranslation();
  const classes = useStyles();
  const connector = data == null ? void 0 : data.name;
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.network.sdwanConnector.dialog.summary.name", { connector }) }),
    /* @__PURE__ */ jsxs(WizardSummary, { columnCount: 1, columns: [{ xs: 10, md: 10 }], data, stratosSummaryStyle: true, children: [
      /* @__PURE__ */ jsx(
        TextField,
        {
          source: "name",
          label: "tesseract.network.sdwanConnector.dialog.summary.connectorName",
          column: 0,
          className: classes.summaryName,
          borderBottom: true
        }
      ),
      data == null ? void 0 : data.resources.map((destinationsData, index, resourcesArray) => [
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "serviceName",
            label: "tesseract.network.sdwanConnector.dialog.summary.serviceName",
            className: classes.summaryName,
            defaultText: destinationsData.serviceName
          },
          "serviceName"
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "hostname",
            label: "tesseract.network.sdwanConnector.dialog.summary.hostname",
            column: 0,
            className: classes.summaryName,
            defaultText: destinationsData.hostname,
            borderBottom: resourcesArray.length - 1 !== index
          },
          "hostname"
        )
      ])
    ] })
  ] });
};
var SdwanConnectorSubmitStep_default = SdwanConnectorSubmitStep;
export {
  SdwanConnectorSubmitStep_default as default
};
