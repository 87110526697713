import { jsx } from "react/jsx-runtime";
import { SvgIcon, useTheme } from "@mui/material";
const None = () => {
  const theme = useTheme();
  return /* @__PURE__ */ jsx(SvgIcon, { children: /* @__PURE__ */ jsx(
    "path",
    {
      d: "M0.169922 0.899414V8.89941H8.16992V0.899414H0.169922ZM6.16992 6.89941H2.16992V2.89941H6.16992V6.89941ZM0.169922 10.8994V18.8994H8.16992V10.8994H0.169922ZM6.16992 16.8994H2.16992V12.8994H6.16992V16.8994ZM10.1699 0.899414V8.89941H18.1699V0.899414H10.1699ZM16.1699 6.89941H12.1699V2.89941H16.1699V6.89941ZM10.1699 10.8994V18.8994H18.1699V10.8994H10.1699ZM16.1699 16.8994H12.1699V12.8994H16.1699V16.8994Z",
      fill: theme.palette.reporting.fontColor
    }
  ) });
};
export {
  None
};
