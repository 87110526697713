var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import {
  AutoSelectInput,
  BooleanInput,
  CustomField,
  DoubleInput,
  getArrayDataContent,
  HiddenInput,
  Input,
  PasswordInput,
  Select,
  SelectArrayInput,
  SelectedChoiceField,
  SelectInput,
  StatusIconField,
  Tab,
  TableInput,
  Text,
  TextArrayInput,
  TextField,
  TextInput,
  useCrudProps,
  validateInt,
  validateMaxLengthMemo,
  validateMaxValueMemo,
  validateMinValueMemo
} from "@cuda-react/core";
import { BooleanIcon } from "@cuda-react/icons";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import {
  getNetworkNameFromPorts,
  useNetworkFormValidation,
  useSiteInputValidations
} from "../../../../../hooks/createEditSiteHooks";
import { useWatch } from "react-hook-form";
import apiResources from "../../../../../apiResources";
import { SELECT_EMPTY_VALUE } from "../../EditSCContent";
import ExpressRouteHeader from "../ExpressRouteHeader";
import { get } from "lodash";
import WanEditWarning from "./WanEditWarning";
const useStyles = makeStyles({
  doubleInput: {
    width: 124
  },
  selectDoubleInput: {
    width: 132,
    "&> div": {
      width: 124
    },
    "&> div > div": {
      width: 124
    }
  }
});
const DEFAULT_MTU = "1452";
const WanTableInput = ({ applianceType, serials, tableRowDimmed, portsData, series }) => {
  var _a, _b, _c;
  const [translate] = useTranslation();
  const networkFormValidation = useNetworkFormValidation();
  const classes = useStyles();
  const inputValidations = useSiteInputValidations();
  const providersData = getArrayDataContent((_a = useCrudProps(apiResources.siteProviders, {}, {}, true)[0]) == null ? void 0 : _a.data);
  const getNetworkName = getNetworkNameFromPorts(portsData);
  const healthCheckModes = getArrayDataContent((_b = useCrudProps(apiResources.siteHealthCheckModes, {}, {}, true)[0]) == null ? void 0 : _b.data);
  const wanTypesData = getArrayDataContent((_c = useCrudProps(apiResources.siteModes, {}, {}, true)[0]) == null ? void 0 : _c.data);
  const gateway = useWatch({ name: "gateway" }) || [];
  const expressRouteObject = {
    key: "expressRoute",
    name: "tesseract.appliances.settings.wan.expressRoute"
  };
  const bridgingObject = {
    key: "bridge",
    name: "tesseract.appliances.settings.wan.bridge"
  };
  const expressRouteFilteredFormChoices = gateway === SELECT_EMPTY_VALUE || applianceType === "gateway" ? wanTypesData : [...wanTypesData, expressRouteObject];
  const wanTypeFormChoices = applianceType === "site" && series === "T" ? [...expressRouteFilteredFormChoices, bridgingObject] : expressRouteFilteredFormChoices;
  const filteredWanTypeFormChoices = wanTypeFormChoices;
  const [currentMode, setCurrentMode] = useState(null);
  return /* @__PURE__ */ jsxs(
    TableInput,
    {
      source: "wans",
      optionValue: "name",
      labels: {
        addTitle: "tesseract.appliances.settings.wan.addTitle",
        addButtonText: "tesseract.appliances.settings.wan.addButtonText",
        editTitle: "tesseract.appliances.settings.wan.editTitle",
        deleteTitle: "tesseract.appliances.settings.wan.deleteTitle",
        deleteMessage: "tesseract.appliances.settings.wan.deleteMessage"
      },
      deleteButtonProps: { multiline: true },
      formValidate: networkFormValidation,
      formatFormData: (newData) => __spreadProps(__spreadValues({}, newData), {
        failover: newData.mode === "static" ? newData.failover : void 0,
        port: newData.mode === "wwan" || newData.mode === "bridge" ? void 0 : newData.port
      }),
      rowDimmed: tableRowDimmed,
      minimumItems: 1,
      defaultFormValues: { doubleNetmask: ["", ""], doubleAddress: ["", ""], mtu: DEFAULT_MTU },
      tabbed: true,
      formProps: { onChange: (data) => setCurrentMode(data.mode) },
      formChildren: [
        /* @__PURE__ */ jsxs(Tab, { label: "tesseract.appliances.settings.wan.basic", children: [
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "name",
              label: "tesseract.appliances.settings.wan.name",
              description: "tesseract.appliances.settings.wan.descriptions.name",
              validate: inputValidations.validateName,
              isRequired: true
            },
            "name"
          ),
          /* @__PURE__ */ jsx(
            SelectInput,
            {
              source: "mode",
              choices: filteredWanTypeFormChoices,
              label: "tesseract.appliances.settings.wan.type",
              description: "tesseract.appliances.settings.wan.descriptions.type",
              validate: (value) => {
                if (value === "bridge" && gateway !== "__SELECT_EMPTY_VALUE") {
                  return translate("tesseract.appliances.dialog.wans.validation.mode");
                } else {
                  return void 0;
                }
              }
            },
            "mode"
          ),
          /* @__PURE__ */ jsx(
            SelectInput,
            {
              source: "wanPort",
              label: "tesseract.appliances.dialog.wans.bridgeType.wanPort",
              description: "tesseract.appliances.dialog.wans.bridgeType.descriptions.wanPort",
              choices: portsData,
              optionValue: "port",
              optionText: "description",
              hide: (value, data) => (data == null ? void 0 : data.mode) !== "bridge"
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "address",
              label: "tesseract.appliances.dialog.wans.bridgeType.address",
              description: "tesseract.appliances.dialog.wans.bridgeType.descriptions.address",
              validate: inputValidations.validatePrivateIp,
              hide: (value, data) => (data == null ? void 0 : data.mode) !== "bridge",
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "netmask",
              label: "tesseract.appliances.dialog.wans.bridgeType.netmask",
              description: "tesseract.appliances.dialog.wans.bridgeType.descriptions.netmask",
              validate: inputValidations.validateNetmask,
              hide: (value, data) => (data == null ? void 0 : data.mode) !== "bridge",
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "gateway",
              label: "tesseract.appliances.dialog.wans.bridgeType.gateway",
              description: "tesseract.appliances.dialog.wans.bridgeType.descriptions.gateway",
              validate: inputValidations.validatePrivateIp,
              hide: (value, data) => (data == null ? void 0 : data.mode) !== "bridge",
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            SelectArrayInput,
            {
              source: "lanPorts",
              label: "tesseract.appliances.dialog.wans.bridgeType.lanPorts",
              description: "tesseract.appliances.dialog.wans.bridgeType.descriptions.lanPorts",
              choices: portsData,
              optionValue: "port",
              optionText: "description",
              hide: (value, data) => (data == null ? void 0 : data.mode) !== "bridge",
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            SelectInput,
            {
              source: "port",
              label: "tesseract.appliances.settings.wan.port",
              description: "tesseract.appliances.settings.wan.descriptions.port",
              choices: portsData,
              optionValue: "port",
              optionText: "description",
              hide: (value, data) => data && ["wwan", "expressRoute", "bridge"].includes(data.mode) || false
            },
            "port"
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "virtualLanId",
              label: "tesseract.appliances.settings.wan.virtualLanId",
              description: "tesseract.appliances.settings.wan.descriptions.virtualLanId",
              validate: inputValidations.validateVlanId,
              hide: (value, data) => data && ["wwan", "expressRoute", "bridge"].includes(data.mode) || false
            },
            "virtualLanId"
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "address",
              label: "tesseract.appliances.settings.wan.address",
              hide: (value, data) => !(data && data.mode === "static"),
              description: "tesseract.appliances.settings.wan.descriptions.address",
              validate: inputValidations.validateStaticAddress,
              isRequired: true
            },
            "address"
          ),
          /* @__PURE__ */ jsx(
            TextArrayInput,
            {
              source: "addresses",
              label: "tesseract.appliances.settings.wan.additionalAddresses",
              hide: (value, data) => !(data && data.mode === "static"),
              description: "tesseract.appliances.settings.wan.descriptions.additionalAddresses",
              validate: inputValidations.validateIpArray
            },
            "addresses"
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "netmask",
              label: "tesseract.appliances.settings.wan.netmask",
              hide: (value, data) => !(data && data.mode === "static"),
              description: "tesseract.appliances.settings.wan.descriptions.netmask",
              validate: inputValidations.validateNetmask,
              isRequired: true
            },
            "netmask"
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "gateway",
              label: "tesseract.appliances.settings.wan.gateway",
              description: "tesseract.appliances.settings.wan.descriptions.gateway",
              hide: (value, data) => !(data && data.mode === "static"),
              validate: inputValidations.validateIp,
              isRequired: true
            },
            "gateway"
          ),
          /* @__PURE__ */ jsx(
            Input,
            {
              source: "primarySecondary",
              component: ExpressRouteHeader,
              noLabel: true,
              hide: (value, data) => data && data.mode !== "expressRoute" || false
            },
            "primarySecondary"
          ),
          /* @__PURE__ */ jsx(
            DoubleInput,
            {
              source: "doublePort",
              label: "tesseract.appliances.settings.wan.doublePort",
              description: "tesseract.appliances.settings.wan.descriptions.doublePort",
              inputComponent: Select,
              inputProps: { inputProps: {
                choices: portsData,
                optionValue: "port",
                optionText: "description"
              } },
              hide: (value, data) => data && data.mode !== "expressRoute" || false,
              inputClasses: {
                firstInput: classes.selectDoubleInput,
                secondInput: classes.selectDoubleInput
              },
              isRequired: true
            },
            "doublePort"
          ),
          /* @__PURE__ */ jsx(
            DoubleInput,
            {
              source: "doubleVirtualLanId",
              label: "tesseract.appliances.settings.wan.doubleVirtualLanId",
              description: "tesseract.appliances.settings.wan.descriptions.doubleVirtualLanId",
              inputComponent: Text,
              hide: (value, data) => data && data.mode !== "expressRoute" || false,
              inputProps: { inputProps: {
                classes: {
                  textField: classes.doubleInput
                }
              } },
              validate: inputValidations.validateVlanIdArray
            },
            "doubleVirtualLanId"
          ),
          /* @__PURE__ */ jsx(
            DoubleInput,
            {
              source: "doubleAddress",
              label: "tesseract.appliances.settings.wan.doubleAddress",
              description: "tesseract.appliances.settings.wan.descriptions.doubleAddress",
              inputComponent: Text,
              hide: (value, data) => data && data.mode !== "expressRoute" || false,
              validate: inputValidations.validateRequiredPrivateIpArray,
              inputProps: { inputProps: {
                classes: {
                  textField: classes.doubleInput
                }
              } },
              isRequired: true
            },
            "doubleAddress"
          ),
          /* @__PURE__ */ jsx(
            DoubleInput,
            {
              source: "doubleNetmask",
              label: "tesseract.appliances.settings.wan.doubleNetmask",
              description: "tesseract.appliances.settings.wan.descriptions.doubleNetmask",
              inputComponent: Text,
              hide: (value, data) => data && data.mode !== "expressRoute" || false,
              inputProps: { inputProps: {
                classes: {
                  textField: classes.doubleInput
                }
              } },
              validate: inputValidations.validateRequiredNetmaskArray,
              isRequired: true,
              disabled: true
            },
            "doubleNetmask"
          ),
          /* @__PURE__ */ jsx(
            HiddenInput,
            {
              source: "doubleNetmask",
              hiddenValue: ["30", "30"],
              hide: (value, data) => data && data.mode !== "expressRoute" || false
            },
            "doubleNetmaskHardcoded"
          ),
          /* @__PURE__ */ jsx(
            SelectInput,
            {
              source: "provider",
              choices: providersData,
              label: "tesseract.appliances.settings.wan.providerPinning",
              description: "tesseract.appliances.settings.wan.descriptions.providerPinning"
            },
            "provider"
          ),
          /* @__PURE__ */ jsx(
            BooleanInput,
            {
              source: "failover",
              label: "tesseract.appliances.settings.wan.failover",
              hide: (value, data) => !(data && data.mode === "static"),
              description: "tesseract.appliances.settings.wan.descriptions.failover",
              disabled: !serials || serials.length < 2
            },
            "failover"
          ),
          /* @__PURE__ */ jsx(
            HiddenInput,
            {
              source: "pins[0].serial",
              hide: (value, data) => !(data && data.mode === "wwan"),
              hiddenValue: serials[0]
            },
            "pins[0].serial"
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "pins[0].pin",
              label: translate("tesseract.appliances.settings.wan.pin", { serial: serials[0] }),
              hide: (value, data) => !(data && data.mode === "wwan"),
              description: "tesseract.appliances.settings.wan.descriptions.pin",
              validate: inputValidations.validatePin
            },
            "pins[0].pin"
          ),
          serials.length > 1 ? /* @__PURE__ */ jsx(
            HiddenInput,
            {
              source: "pins[1].serial",
              hiddenValue: serials[1],
              hide: (value, data) => !(data && data.mode === "wwan")
            },
            "pins[1].serial"
          ) : null,
          serials.length > 1 ? /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "pins[1].pin",
              label: translate("tesseract.appliances.settings.wan.pin", { serial: serials[1] }),
              hide: (value, data) => !(data && data.mode === "wwan"),
              description: "tesseract.appliances.settings.wan.descriptions.pin",
              validate: inputValidations.validatePin
            },
            "pins[1].pin"
          ) : null,
          /* @__PURE__ */ jsx(
            AutoSelectInput,
            {
              source: "asn",
              label: "tesseract.appliances.settings.wan.asn",
              description: "tesseract.appliances.settings.wan.descriptions.asn",
              resource: apiResources.availableAsns,
              hide: (value, data) => data && data.mode !== "expressRoute" || false,
              validate: inputValidations.validateAsn,
              allowShuffle: true,
              allowManualInput: true,
              isRequired: true
            },
            "asn"
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "transferNetwork",
              label: "tesseract.appliances.settings.wan.virtualTransferNetwork",
              description: "tesseract.appliances.settings.wan.descriptions.virtualTransferNetwork",
              hide: (value, data) => data && data.mode !== "expressRoute" || false,
              validate: inputValidations.validatePrivateIp,
              isRequired: true,
              options: { InputProps: { endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: "/28" }) } }
            },
            "transferNetwork"
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "username",
              label: "tesseract.appliances.settings.wan.userName",
              description: "tesseract.appliances.settings.wan.descriptions.userNamePassword",
              hide: (value, data) => data && data.mode !== "pppoe" || false,
              validate: validateMaxLengthMemo(255)
            },
            "userName"
          ),
          /* @__PURE__ */ jsx(
            PasswordInput,
            {
              source: "password",
              label: "tesseract.appliances.settings.wan.password",
              description: "tesseract.appliances.settings.wan.descriptions.userNamePassword",
              hide: (value, data) => data && data.mode !== "pppoe" || false,
              validate: validateMaxLengthMemo(255)
            }
          ),
          /* @__PURE__ */ jsx(WanEditWarning, {})
        ] }, "basic"),
        /* @__PURE__ */ jsxs(
          Tab,
          {
            label: "tesseract.appliances.settings.wan.advanced",
            disabled: !["static", "dynamic", "pppoe"].includes(currentMode || ""),
            children: [
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "mtu",
                  label: "tesseract.appliances.settings.wan.mtu",
                  description: "tesseract.appliances.settings.wan.descriptions.mtu",
                  validate: [validateInt, validateMaxValueMemo(1492), validateMinValueMemo(68)],
                  hideInput: currentMode !== "pppoe"
                }
              ),
              /* @__PURE__ */ jsx(
                BooleanInput,
                {
                  source: "healthCheck.enabled",
                  label: "tesseract.appliances.settings.wan.healthCheck.enabled",
                  description: "tesseract.appliances.settings.wan.descriptions.healthCheck.enabled"
                }
              ),
              /* @__PURE__ */ jsx(
                SelectInput,
                {
                  source: "healthCheck.mode",
                  label: "tesseract.appliances.settings.wan.healthCheck.mode",
                  description: "tesseract.appliances.settings.wan.descriptions.healthCheck.mode",
                  choices: healthCheckModes,
                  isRequired: true,
                  disable: (value, data) => !get(data, "healthCheck.enabled") || get(data, "mode") !== "pppoe"
                }
              ),
              currentMode !== "pppoe" ? /* @__PURE__ */ jsx(
                HiddenInput,
                {
                  source: "healthCheck.mode",
                  hiddenValue: "icmp"
                }
              ) : null,
              /* @__PURE__ */ jsx(
                TextArrayInput,
                {
                  source: "healthCheck.targets",
                  label: "tesseract.appliances.settings.wan.healthCheck.targets",
                  description: "tesseract.appliances.settings.wan.descriptions.healthCheck.targets",
                  disable: (value, data) => !get(data, "healthCheck.enabled"),
                  validate: inputValidations.validateIpArray,
                  hide: (value, data) => {
                    var _a2;
                    return ((_a2 = data == null ? void 0 : data.healthCheck) == null ? void 0 : _a2.mode) !== "icmp";
                  },
                  isRequired: true
                }
              )
            ]
          },
          "advanced"
        )
      ],
      children: [
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.appliances.settings.wan.name"
          }
        ),
        /* @__PURE__ */ jsx(
          SelectedChoiceField,
          {
            source: "mode",
            choices: filteredWanTypeFormChoices,
            label: "tesseract.appliances.settings.wan.type"
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "port",
            label: "tesseract.appliances.settings.wan.port",
            width: 104,
            render: (value, data) => getNetworkName(data)
          }
        ),
        /* @__PURE__ */ jsx(
          SelectedChoiceField,
          {
            source: "provider",
            choices: providersData,
            label: "tesseract.appliances.settings.wan.providerPinning",
            width: 164
          }
        ),
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "failover",
            label: "tesseract.appliances.settings.wan.failover",
            icon: /* @__PURE__ */ jsx(BooleanIcon, {}),
            hidden: !serials || serials.length < 2,
            getStatusColorFromTheme: (status, theme) => status ? theme.palette.success.main : theme.palette.info.main
          }
        )
      ]
    }
  );
};
var WanTableInput_default = WanTableInput;
export {
  DEFAULT_MTU,
  WanTableInput_default as default
};
