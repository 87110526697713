var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { all, call } from "@redux-saga/core/effects";
import { createHashHistory } from "history";
import "isomorphic-fetch";
import { merge } from "lodash";
import { Provider } from "react-redux";
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import bccRedirectAuthClient from "../../clients/bccRedirectAuthClient";
import * as reducers from "../../reducers";
import CudaSagas from "../../sagas";
import { CudaTheme } from "@cuda-react/theme";
import reduxThunk from "redux-thunk";
import { CssBaseline } from "@barracuda-internal/bds-core";
import "@progress/kendo-theme-material/dist/all.css";
import AnalyticsProvider from "../functional/AnalyticsProvider/AnalyticsProvider";
import { OneTrustProvider } from "../functional/OneTrustProvider/OneTrustProvider";
import { Router } from "react-router";
import PreviewProvider from "../functional/PreviewProvider/PreviewProvider";
import { loadState, persistStore } from "../../reducers/LocalStoragePersist/localStoragePersist";
import { I18nextProvider } from "../../i18n";
const CudaReactProvider = ({
  customTheme,
  customMessages,
  children,
  customReducers,
  customSagas,
  history,
  initialState,
  bccAuthentication,
  authClient: customAuthClient,
  mixpanelConfig
}) => {
  const allReducers = combineReducers(__spreadValues(__spreadValues({}, reducers), customReducers));
  const sagaMiddleware = createSagaMiddleware();
  const routerHistory = history || createHashHistory();
  const composeEnhancers = process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
  const store = createStore(
    allReducers,
    merge({}, initialState, loadState()),
    composeEnhancers(
      //@ts-ignore not sure what this is suddenly complaining for...
      applyMiddleware(sagaMiddleware, routerMiddleware(routerHistory), reduxThunk)
    )
  );
  persistStore(store, ["GlobalParamsReducer", "AuthReducer", "PersonalisedTablesReducer"]);
  const authClient = customAuthClient || (bccAuthentication ? bccRedirectAuthClient : null);
  function* mergedSagas() {
    yield all([
      call(CudaSagas(authClient)),
      customSagas && call(customSagas)
    ]);
  }
  sagaMiddleware.run(mergedSagas);
  const mergedTheme = createTheme(merge({}, CudaTheme, customTheme));
  return /* @__PURE__ */ jsx(Provider, { store, children: /* @__PURE__ */ jsx(OneTrustProvider, { children: /* @__PURE__ */ jsx(AnalyticsProvider, { mixpanelConfig, children: /* @__PURE__ */ jsx(StyledEngineProvider, { injectFirst: true, children: /* @__PURE__ */ jsxs(ThemeProvider, { theme: mergedTheme, children: [
    /* @__PURE__ */ jsx(CssBaseline, {}),
    /* @__PURE__ */ jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, children: /* @__PURE__ */ jsx(I18nextProvider, { messages: customMessages, children: /* @__PURE__ */ jsx(Router, { history: routerHistory, children: /* @__PURE__ */ jsx(PreviewProvider, { children }) }) }) })
  ] }) }) }) }) });
};
CudaReactProvider.defaultProps = {
  initialState: {}
};
var CudaReactProvider_default = CudaReactProvider;
export {
  CudaReactProvider_default as default
};
