import { jsx, jsxs } from "react/jsx-runtime";
import { ButtonDialog } from "@cuda-react/core";
import CustomAppContent from "./CustomAppContent";
const CreateCustomAppDialog = ({ onSuccess }) => /* @__PURE__ */ jsxs(ButtonDialog, { buttonText: "tesseract.customApps.dialogs.create.buttonText", useMenu: true, children: [
  /* @__PURE__ */ jsx(
    CustomAppContent,
    {
      create: true,
      type: "network",
      title: "tesseract.customApps.dialogs.create.dialogTitleNetwork",
      label: "tesseract.customApps.dialogs.create.menuLabelNetwork",
      onSuccess
    }
  ),
  /* @__PURE__ */ jsx(
    CustomAppContent,
    {
      create: true,
      type: "web",
      title: "tesseract.customApps.dialogs.create.dialogTitleWeb",
      label: "tesseract.customApps.dialogs.create.menuLabelWeb",
      onSuccess
    }
  )
] });
var CreateCustomAppDialog_default = CreateCustomAppDialog;
export {
  CreateCustomAppDialog_default as default
};
