import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import {
  ActionButtonsField,
  Boolean,
  ChipArrayField,
  ConnectedTable,
  CrudTypes,
  DeleteDialog,
  getArrayDataContent,
  getDataContent,
  InputLabel,
  PropGateway,
  Select,
  StatusIconField,
  TextField,
  useCrudFetch,
  useCrudProps,
  useCrudSubscription,
  useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { makeStyles } from "@mui/styles";
import { TextField as BdsTextField } from "@barracuda-internal/bds-core";
import CreateEditButtonDialog from "../../../../../../components/CreateEditButtonDialog";
import CreateEditSslRules from "./CreateEditSslRules";
import SourceFilter from "../../../../../../components/sourceAndDestination/SourceFilter";
import DestinationFilter from "../../../../../../components/sourceAndDestination/DestinationFilter";
import { useSourceDestinationField } from "../../../../../../hooks/sourceAndDestinationHooks";
import {
  formatSourceDestinationFilters,
  formatUserGroupInTableData
} from "../../../../../../components/sourceAndDestination/tableUtils";
import { sslExplicitIcons } from "../../../../../../components/iconMapping";
import apiResources from "../../../../../../apiResources";
const styles = (theme) => ({
  cardActions: {
    paddingRight: theme.spacing(2),
    width: "calc(100% - " + theme.spacing(2) + ")"
  },
  orderCell: {
    minWidth: 70
  },
  actionCell: {
    minWidth: 70,
    paddingLeft: 0
  },
  actions: {
    paddingRight: 0,
    "&> span": {
      position: "relative",
      left: "5%"
    }
  },
  buttons: {
    paddingLeft: theme.spacing(2)
  }
});
const useStyles = makeStyles(styles);
const SslInterceptionExplicit = ({
  enabled,
  enabledLoading,
  refreshEnabled
}) => {
  var _a, _b, _c;
  const registerAction = useMixpanel("SSL", false);
  const tableRefreshRef = useRef();
  const [, enabledUpdating, updateEnabled] = useCrudFetch(CrudTypes.UPDATE, apiResources.sslInspectionEnabled);
  const loadingEnabled = typeof enabled !== "boolean" || enabledLoading || enabledUpdating;
  const handleUpdateEnabled = (newValue) => {
    if (!loadingEnabled && newValue !== enabled) {
      registerAction("Rule Default Update", { enabled: newValue });
      return updateEnabled({ enabled: newValue ? "enable" : "disable" }).then(refreshEnabled);
    }
  };
  const actionChoices = getArrayDataContent((_a = useCrudProps(apiResources.sslExplicitActions, {}, { cache: true })[0]) == null ? void 0 : _a.data);
  const categoryChoices = getArrayDataContent((_b = useCrudProps(apiResources.urlFilterCategories, {}, { cache: true })[0]) == null ? void 0 : _b.data);
  const customCategories = getArrayDataContent((_c = useCrudProps(apiResources.customCategories)[0]) == null ? void 0 : _c.data);
  const [defaultActionData, defaultActionLoading, refreshDefaultAction] = useCrudSubscription(CrudTypes.GET, apiResources.sslExplicitDefault);
  const [, defaultActionUpdating, updateDefaultAction] = useCrudFetch(CrudTypes.UPDATE, apiResources.sslExplicitDefault);
  const defaultAction = getDataContent(defaultActionData);
  const defaultLoading = typeof defaultAction !== "string" || defaultActionLoading || defaultActionUpdating;
  const handleUpdateDefaultAction = (action) => {
    if (action && !defaultLoading && action !== defaultAction) {
      registerAction("Rule Default Update", { action });
      return updateDefaultAction({ action }).then(refreshDefaultAction);
    }
  };
  const [, , callSslInterception] = useCrudFetch(CrudTypes.CREATE, apiResources.sslExplicit);
  const handleOrderClick = (id, direction, data) => callSslInterception({
    id,
    changeOrder: direction,
    data
  }).then(tableRefreshRef.current);
  const generateChipArrayProps = useSourceDestinationField(categoryChoices);
  const classes = useStyles();
  return /* @__PURE__ */ jsxs(
    ConnectedTable,
    {
      resource: apiResources.sslExplicit,
      exportResource: { resource: apiResources.sslExplicitExport, filename: "sslExplicit" },
      actions: [
        /* @__PURE__ */ jsx(
          InputLabel,
          {
            label: "tesseract.security.sslInterception.settings.defaultAction",
            minimised: true,
            left: true,
            children: /* @__PURE__ */ jsx(
              Select,
              {
                id: "defaultAction",
                source: "defaultAction",
                value: !defaultLoading ? defaultAction : "",
                onChange: (eventOrValue) => handleUpdateDefaultAction(eventOrValue.target ? eventOrValue.target.value : eventOrValue),
                onBlur: () => {
                },
                disabled: !!defaultLoading,
                choices: actionChoices
              },
              "defaultAction"
            )
          },
          "defaultAction"
        ),
        /* @__PURE__ */ jsx(
          InputLabel,
          {
            label: "tesseract.security.sslInterception.settings.inputs.enabled",
            minimised: true,
            left: true,
            children: /* @__PURE__ */ jsx(
              Boolean,
              {
                id: "enabled",
                onChange: handleUpdateEnabled,
                options: { disabled: !!loadingEnabled },
                value: typeof enabled !== "boolean" ? false : enabled
              }
            )
          },
          "enabled"
        ),
        /* @__PURE__ */ jsx(
          CreateEditButtonDialog,
          {
            create: true,
            component: CreateEditSslRules,
            onSuccess: tableRefreshRef.current
          },
          "create"
        )
      ],
      filters: [
        /* @__PURE__ */ jsx(
          BdsTextField,
          {
            label: "tesseract.security.sslInterception.settings.name",
            source: "name"
          },
          "name"
        ),
        /* @__PURE__ */ jsx(
          BdsTextField,
          {
            label: "tesseract.security.sslInterception.settings.description",
            source: "description"
          },
          "description"
        ),
        /* @__PURE__ */ jsx(
          Select,
          {
            label: "tesseract.security.sslInterception.settings.action",
            source: "action",
            id: "action",
            choices: actionChoices
          },
          "action"
        )
      ],
      params: formatSourceDestinationFilters,
      classes: { cardActions: classes.cardActions },
      formatData: formatUserGroupInTableData,
      minCellWidth: 128,
      refreshRef: tableRefreshRef,
      flat: true,
      children: [
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            left: true,
            label: "tesseract.security.sslInterception.settings.order",
            cellClassName: classes.orderCell,
            source: "id",
            width: 96,
            children: [
              /* @__PURE__ */ jsx(
                TextField,
                {
                  source: "order"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowUpwardIcon,
                {
                  onClick: (event, data) => handleOrderClick(data.id, "increase", data),
                  disabled: (data) => data.index <= 0,
                  id: "cuda-icon-up"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowDownwardIcon,
                {
                  onClick: (event, data) => handleOrderClick(data.id, "decrease", data),
                  disabled: (data) => data.index >= data.total - 1,
                  id: "cuda-icon-down"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.security.sslInterception.settings.name",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.security.sslInterception.settings.description",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "action",
            text: (action) => (actionChoices.find((item) => item.key === action) || {}).name,
            label: "tesseract.security.sslInterception.settings.action",
            iconMap: sslExplicitIcons,
            cellClassName: classes.actionCell,
            filter: "select",
            filterProps: {
              choices: actionChoices
            }
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "source",
            label: "tesseract.security.sslInterception.settings.source",
            editProps: generateChipArrayProps("source"),
            filter: "custom",
            filterProps: {
              component: SourceFilter,
              filterSources: ["network", "site", "userOrGroup"]
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "source" })
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "destination",
            label: "tesseract.security.sslInterception.settings.destination",
            editProps: generateChipArrayProps("destination"),
            filter: "custom",
            filterProps: {
              component: DestinationFilter,
              filterSources: ["application", "network", "site", "domain", "category", "customCategory"],
              table: "SslInspection",
              categoryChoices,
              customCategories
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "destination" })
          }
        ),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            source: "id",
            width: 96,
            children: [
              /* @__PURE__ */ jsx(CreateEditButtonDialog, { component: CreateEditSslRules, onSuccess: tableRefreshRef.current }),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource: apiResources.sslExplicit,
                  title: "tesseract.security.sslInterception.settings.delete.title",
                  message: "tesseract.security.sslInterception.settings.delete.body",
                  onSuccess: () => {
                    var _a2;
                    registerAction("Rule Delete");
                    (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                  }
                }
              )
            ]
          }
        )
      ]
    }
  );
};
var SslInterceptionExplicit_default = SslInterceptionExplicit;
export {
  SslInterceptionExplicit,
  SslInterceptionExplicit_default as default,
  styles
};
