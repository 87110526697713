import { jsx, jsxs } from "react/jsx-runtime";
import { Button, Grid, Typography } from "@barracuda-internal/bds-core";
import { createStyles, makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
const styles = (theme) => createStyles({
  detailLinkButton: {
    textTransform: "none",
    padding: theme.spacing(1),
    flexGrow: 1
  },
  detailContainer: {
    padding: theme.spacing(1)
  },
  detailsText: {
    color: theme.palette.reporting.fontColor,
    margin: theme.spacing(0, 0.5),
    fontSize: 14,
    textAlign: "center"
  },
  detailsValue: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
    fontSize: 24,
    textAlign: "center"
  }
});
const useStyles = makeStyles(styles);
const StatisticsDetail = (props) => {
  const { id, link, title, value, color, icon } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx(
    Grid,
    {
      item: true,
      id,
      href: link ? link : void 0,
      component: link ? Button : "div",
      className: link ? classes.detailLinkButton : classes.detailContainer,
      children: /* @__PURE__ */ jsxs(Grid, { container: true, direction: "column", alignItems: "center", children: [
        /* @__PURE__ */ jsxs(Grid, { container: true, alignItems: "center", direction: "column", children: [
          icon,
          /* @__PURE__ */ jsx(Typography, { className: classes.detailsText, children: translate(title) })
        ] }),
        /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(
          Typography,
          {
            variant: "h5",
            className: classes.detailsValue,
            style: color ? { color } : void 0,
            children: value
          }
        ) })
      ] })
    }
  );
};
export {
  StatisticsDetail
};
