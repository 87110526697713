import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const WorkspaceIcon = ({ className }) => {
  var _a, _b, _c;
  const theme = useTheme();
  return /* @__PURE__ */ jsxs(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-workspace",
      viewBox: "0 0 60 60",
      children: [
        /* @__PURE__ */ jsx("path", { style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, strokeWidth: 0 }, d: "M44.8,20.3v-5h-26.3c-1.8,0-3.3,1.5-3.3,3.3v26.3h5v-24.5h24.5Z" }),
        /* @__PURE__ */ jsx("path", { style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 }, d: "M36.5,11.9v-5H10.2c-1.8,0-3.3,1.5-3.3,3.3v26.3h5V11.9h24.5Z" }),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_c = theme.palette.icons) == null ? void 0 : _c.graphics, strokeWidth: 0 },
            d: "M49.8,23.5h-23c-1.8,0-3.3,1.5-3.3,3.3v23c0,1.8,1.5,3.3,3.3,3.3h23c1.8,0,3.3-1.5,3.3-3.3v-23c0-1.8-1.5-3.3-3.3-3.3ZM48,48.1h-19.5v-19.5h19.5v19.5Z"
          }
        )
      ]
    }
  );
};
var WorkspaceIcon_default = WorkspaceIcon;
export {
  WorkspaceIcon,
  WorkspaceIcon_default as default
};
