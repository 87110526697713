import { jsx, jsxs } from "react/jsx-runtime";
import { createStyles } from "@mui/styles";
import { ButtonDialog } from "../../dialog";
import IconPreview from "./IconPreview";
import IconBrowser from "./IconBrowser";
import { makeOverrideableStyles } from "@cuda-react/theme";
const styles = (theme) => createStyles({
  container: {
    display: "inline-flex"
  },
  button: {
    marginLeft: theme.spacing(1)
  }
});
const useStyles = makeOverrideableStyles("IconSelector", styles);
const IconSelector = (props) => {
  var _a, _b;
  const classes = useStyles(props);
  return /* @__PURE__ */ jsxs("div", { className: classes.container, children: [
    /* @__PURE__ */ jsx(
      IconPreview,
      {
        icon: (_a = props.value) == null ? void 0 : _a.data,
        filename: (_b = props.value) == null ? void 0 : _b.filename,
        supportedFiles: props.supportedFiles || [{ fileExtensions: ["svg"], mimeType: "image/svg+xml" }],
        name: props.name
      }
    ),
    /* @__PURE__ */ jsx(
      ButtonDialog,
      {
        buttonText: "Choose Icon",
        buttonClasses: { button: classes.button },
        buttonProps: {
          id: "icon-input-" + props.id
        },
        disabled: props.disabled,
        children: /* @__PURE__ */ jsx(
          IconBrowser,
          {
            value: props.value,
            onChange: props.onChange,
            supportedFiles: props.supportedFiles,
            id: "icon-upload",
            name: props.name
          }
        )
      }
    )
  ] });
};
var IconSelector_default = IconSelector;
export {
  IconSelector_default as default
};
