import { jsx, jsxs } from "react/jsx-runtime";
import { createStyles, makeStyles } from "@mui/styles";
import { Critical, Success } from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import { CardSizes } from "../../../../typesAndConstants";
import { formatDateTime } from "@cuda-react/core";
import { Grid, Skeleton } from "@barracuda-internal/bds-core";
const styles = (theme) => createStyles({
  entryRow: {
    marginTop: ({ size }) => theme.spacing(size === CardSizes.large ? 2 : 0.5),
    cursor: ({ onClick }) => onClick ? "pointer" : void 0
  },
  rowIcon: {
    width: 24,
    height: 24,
    margin: theme.spacing(0.75, 2, 0.75, 0),
    flexShrink: 0,
    fontSize: 14
  },
  rowText: {
    flexGrow: 1,
    fontSize: 14
  },
  rowTitle: {
    flexGrow: 1,
    fontSize: 14,
    fontWeight: 500
  },
  rowDescription: {
    fontSize: 14,
    fontWeight: "lighter"
  },
  fillGreen: {
    color: theme.palette.success.main
  },
  fillRed: {
    color: theme.palette.error.main
  },
  dateTime: {
    width: 200,
    flexShrink: 0,
    marginLeft: theme.spacing(1),
    alignSelf: "flex-start",
    fontSize: 14,
    fontWeight: "lighter"
  },
  skeleton: {
    height: 24
  }
});
const useStyles = makeStyles(styles);
const RecentEvent = (props) => {
  const { loading, eventName, description, created, status, size, onClick } = props;
  const classes = useStyles(props);
  return /* @__PURE__ */ jsxs(
    Grid,
    {
      container: true,
      direction: "row",
      justifyContent: "space-between",
      alignItems: "center",
      wrap: "nowrap",
      className: classes.entryRow,
      onClick,
      children: [
        /* @__PURE__ */ jsxs(Grid, { item: true, className: classes.rowIcon, children: [
          loading && /* @__PURE__ */ jsx(Skeleton, { className: classes.skeleton }),
          !loading && status === "successful" && /* @__PURE__ */ jsx(Success, { id: "sdwan-ui-ok-status-icon", className: classes.fillGreen }),
          !loading && status !== "successful" && /* @__PURE__ */ jsx(Critical, { id: "sdwan-ui-failed-status-icon", className: classes.fillRed })
        ] }),
        /* @__PURE__ */ jsxs(Grid, { item: true, container: true, className: classes.rowText, children: [
          /* @__PURE__ */ jsxs(Grid, { item: true, xs: 12, container: true, wrap: "nowrap", children: [
            /* @__PURE__ */ jsx(Grid, { item: true, className: classes.rowTitle, children: loading ? /* @__PURE__ */ jsx(Skeleton, { className: classes.skeleton }) : /* @__PURE__ */ jsx("strong", { children: eventName }) }),
            size !== CardSizes.small && /* @__PURE__ */ jsx(Grid, { item: true, className: classes.dateTime, children: loading ? /* @__PURE__ */ jsx(Skeleton, { className: classes.skeleton }) : formatDateTime(created) })
          ] }),
          size === CardSizes.large && /* @__PURE__ */ jsx(Grid, { item: true, xs: 12, className: classes.rowDescription, children: loading ? /* @__PURE__ */ jsx(Skeleton, { className: classes.skeleton }) : description })
        ] })
      ]
    }
  );
};
export {
  RecentEvent
};
