import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography } from "@barracuda-internal/bds-core";
import { getArrayDataContent, HiddenInput, SelectInput, TextInput, useCrudProps } from "@cuda-react/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { get } from "lodash";
import { useSiteInputValidations } from "../../../../../hooks/createEditSiteHooks";
import { useWatch } from "react-hook-form";
import apiResources from "../../../../../apiResources";
const useStyles = makeStyles((theme) => ({
  message: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
const WanStep = ({ stepIndex = 0 }) => {
  var _a;
  const getFullSource = (source) => "wans[" + stepIndex + "]." + source;
  const [serials = [], wanTotal] = useWatch({ name: ["serials", "wanTotal"] });
  const failover = wanTotal + "" === "2";
  const wanTypesData = getArrayDataContent((_a = useCrudProps(apiResources.siteModes)[0]) == null ? void 0 : _a.data);
  const [translate] = useTranslation();
  const classes = useStyles();
  const inputValidations = useSiteInputValidations();
  const filteredTypes = failover ? wanTypesData.filter((type) => stepIndex === 0 ? type.key !== "wwan" && type.key !== "pppoe" : type.key === "wwan") : wanTypesData.filter((type) => type.key !== "pppoe");
  const forcedWwan = filteredTypes.length === 1;
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: translate(stepIndex === 1 ? "tesseract.sites.dialog.wans.setupSCFailover" : "tesseract.sites.dialog.wans.setupSCPrimary") }),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("name"),
        label: "tesseract.sites.dialog.wans.name",
        description: "tesseract.sites.dialog.wans.descriptions.name",
        isRequired: true,
        validate: inputValidations.validateName
      }
    ),
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: getFullSource("mode"),
        label: "tesseract.sites.dialog.wans.type",
        description: "tesseract.sites.dialog.wans.descriptions.type",
        choices: filteredTypes,
        disabled: forcedWwan
      }
    ),
    /* @__PURE__ */ jsx(
      HiddenInput,
      {
        source: getFullSource("port"),
        hiddenValue: "wan"
      }
    ),
    forcedWwan && /* @__PURE__ */ jsx(
      HiddenInput,
      {
        source: getFullSource("mode"),
        hiddenValue: "wwan"
      }
    ),
    /* @__PURE__ */ jsx(
      HiddenInput,
      {
        source: getFullSource("provider"),
        hiddenValue: stepIndex > 0 ? "fallback" : "group_1"
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("address"),
        label: "tesseract.sites.dialog.wans.address",
        description: "tesseract.sites.dialog.wans.descriptions.address",
        validate: inputValidations.validateIp,
        hide: (value, data) => get(data, getFullSource("mode")) !== "static",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("netmask"),
        label: "tesseract.sites.dialog.wans.netmask",
        description: "tesseract.sites.dialog.wans.descriptions.netmask",
        validate: inputValidations.validateNetmask,
        hide: (value, data) => get(data, getFullSource("mode")) !== "static",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("gateway"),
        label: "tesseract.sites.dialog.wans.gateway",
        description: "tesseract.sites.dialog.wans.descriptions.gateway",
        validate: inputValidations.validateIp,
        hide: (value, data) => get(data, getFullSource("mode")) !== "static",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: getFullSource("pin.pin"),
        label: translate("tesseract.sites.dialog.wans.pin", { serial: get(serials, "[0].serial", "") }),
        description: "tesseract.sites.dialog.wans.descriptions.pin",
        validate: inputValidations.validatePin,
        hide: (value, data) => get(data, getFullSource("mode")) !== "wwan"
      }
    ),
    /* @__PURE__ */ jsx(
      HiddenInput,
      {
        source: getFullSource("pin.serial"),
        hiddenValue: get(serials, "[0].serial", "")
      }
    )
  ] });
};
var WanStep_default = WanStep;
export {
  WanStep,
  WanStep_default as default
};
