import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { FormSection, getArrayDataContent, useCrudProps } from "@cuda-react/core";
import SCLanTableInput from "../../inputs/SCLanTableInput";
import RoutesTableInput from "../../inputs/RoutesTableInput";
import apiResources from "../../../../../apiResources";
const LansTab = ({ id }) => {
  var _a;
  const portsData = getArrayDataContent((_a = useCrudProps(apiResources.sitePorts, { id })[0]) == null ? void 0 : _a.data);
  const availablePorts = portsData.filter((port) => port.port && !port.port.includes("wan"));
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(FormSection, { title: "tesseract.sites.settings.lan.sectionTitle", children: /* @__PURE__ */ jsx(SCLanTableInput, { portsData: availablePorts }) }),
    /* @__PURE__ */ jsx(FormSection, { title: "tesseract.sites.settings.route.sectionTitle", children: /* @__PURE__ */ jsx(RoutesTableInput, {}) })
  ] });
};
var LansTab_default = LansTab;
export {
  LansTab_default as default
};
