import { jsx, jsxs } from "react/jsx-runtime";
import {
  BooleanInput,
  ConnectedFormPage,
  FormSection,
  getArrayDataContent,
  PasswordConfirmInput,
  PasswordInput,
  SelectInput,
  TextInput,
  useCrudProps,
  useMixpanel,
  validateRegexMemo
} from "@cuda-react/core";
import { get } from "lodash";
import apiResources from "../../../apiResources";
import { Divider, Typography } from "@barracuda-internal/bds-core";
import { Trans } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { parseJson } from "../../../utils/jsonUtils";
import { useSiteInputValidations } from "../../../hooks/createEditSiteHooks";
const useStyles = makeStyles((theme) => ({
  skeleton: {
    height: 250
  },
  introductionText: {
    padding: theme.spacing(0, 2, 0, 4),
    color: theme.palette.text.secondary
  },
  sectionDescriptionText: {
    padding: theme.spacing(0, 2, 2, 4),
    color: theme.palette.text.secondary
  },
  subSectionDescriptionText: {
    padding: theme.spacing(0, 2, 2, 4),
    color: theme.palette.text.secondary
  }
}));
const AzureMonitor = () => {
  var _a;
  const inputValidations = useSiteInputValidations();
  const registerAction = useMixpanel("Log Analytics");
  const regionChoices = getArrayDataContent((_a = useCrudProps(apiResources.azureMonitorRegions)[0]) == null ? void 0 : _a.data);
  const validateUuid = validateRegexMemo(/^([a-fA-F0-9]{8}-([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12})?$/, "tesseract.validation.uuid");
  const classes = useStyles();
  return /* @__PURE__ */ jsx(
    ConnectedFormPage,
    {
      title: "tesseract.integration.pageTitle",
      subtitle: "tesseract.integration.azureMonitor",
      resource: apiResources.settings,
      formatRequestData: (data) => {
        var _a2, _b, _c, _d, _e, _f;
        return {
          settings: {
            oms_workspace: JSON.stringify(((_b = (_a2 = data == null ? void 0 : data.settings) == null ? void 0 : _a2.oms_workspace) == null ? void 0 : _b.enabled) ? (_c = data == null ? void 0 : data.settings) == null ? void 0 : _c.oms_workspace : { enabled: false }),
            azure_monitor: JSON.stringify(((_e = (_d = data == null ? void 0 : data.settings) == null ? void 0 : _d.azure_monitor) == null ? void 0 : _e.enabled) ? (_f = data == null ? void 0 : data.settings) == null ? void 0 : _f.azure_monitor : { enabled: false })
          }
        };
      },
      formatResourceData: (data) => {
        var _a2, _b;
        const azureMonitor = parseJson(((_a2 = data == null ? void 0 : data.settings) == null ? void 0 : _a2.azure_monitor) || '{"enabled":false}');
        return {
          settings: {
            oms_workspace: parseJson(((_b = data == null ? void 0 : data.settings) == null ? void 0 : _b.oms_workspace) || '{"enabled":false,"id":"","key":""}'),
            azure_monitor: (azureMonitor == null ? void 0 : azureMonitor.enabled) ? azureMonitor : {
              enabled: false,
              clientId: "",
              clientSecret: "",
              tenantId: "",
              subscriptionId: "",
              resourceGroup: "",
              region: "australiacentral",
              correlationId: ""
            }
          }
        };
      },
      params: { filter: { type: "virtualWan" } },
      onSubmitSuccess: (response) => {
        registerAction("Update", { oms_enabled: parseJson(response.settings.oms_workspace).enabled, azure_monitor_enabled: parseJson(response.settings.azure_monitor).enabled });
      },
      children: /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.integration.azureMonitor", newStyle: true, hideBorder: true, children: [
        /* @__PURE__ */ jsx(Typography, { className: classes.introductionText, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.settings.tabs.oms.introduction" }) }),
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "settings.azure_monitor.enabled",
            label: "tesseract.settings.tabs.oms.azureMonitorAgent",
            toggleEnabledText: "tesseract.settings.tabs.oms.enabled",
            toggleDisabledText: "tesseract.settings.tabs.oms.disabled",
            additionalInfoLabel: "tesseract.settings.tabs.oms.descriptions.azureMonitorAgent",
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "settings.azure_monitor.clientId",
            label: "tesseract.settings.tabs.oms.clientId",
            disable: (value, allValues) => !get(allValues, "settings.azure_monitor.enabled"),
            validate: validateUuid,
            isRequired: true,
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          PasswordInput,
          {
            source: "settings.azure_monitor.clientSecret",
            label: "tesseract.settings.tabs.oms.clientSecret",
            isRequired: true,
            disable: (value, allValues) => !get(allValues, "settings.azure_monitor.enabled"),
            validate: inputValidations.validatePassword,
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "settings.azure_monitor.tenantId",
            label: "tesseract.settings.tabs.oms.tenantId",
            disable: (value, allValues) => !get(allValues, "settings.azure_monitor.enabled"),
            validate: validateUuid,
            isRequired: true,
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "settings.azure_monitor.subscriptionId",
            label: "tesseract.settings.tabs.oms.subscriptionId",
            disable: (value, allValues) => !get(allValues, "settings.azure_monitor.enabled"),
            validate: validateUuid,
            isRequired: true,
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "settings.azure_monitor.resourceGroup",
            label: "tesseract.settings.tabs.oms.resourceGroup",
            disable: (value, allValues) => !get(allValues, "settings.azure_monitor.enabled"),
            isRequired: true,
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          SelectInput,
          {
            source: "settings.azure_monitor.region",
            label: "tesseract.settings.tabs.oms.region",
            choices: regionChoices,
            disable: (value, allValues) => !get(allValues, "settings.azure_monitor.enabled"),
            isRequired: true,
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "settings.azure_monitor.correlationId",
            label: "tesseract.settings.tabs.oms.correlationId",
            disable: (value, allValues) => !get(allValues, "settings.azure_monitor.enabled"),
            validate: validateUuid,
            isRequired: true,
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(Divider, { sx: { marginTop: 1, marginBottom: 1 } }),
        /* @__PURE__ */ jsx(
          BooleanInput,
          {
            source: "settings.oms_workspace.enabled",
            label: "tesseract.settings.tabs.oms.oms",
            additionalInfoLabel: "tesseract.settings.tabs.oms.descriptions.oms",
            toggleEnabledText: "tesseract.settings.tabs.oms.enabled",
            toggleDisabledText: "tesseract.settings.tabs.oms.disabled",
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "settings.oms_workspace.id",
            label: "tesseract.settings.tabs.oms.id",
            disable: (value, allValues) => !get(allValues, "settings.oms_workspace.enabled"),
            isRequired: true,
            newStyle: true
          }
        ),
        /* @__PURE__ */ jsx(
          PasswordConfirmInput,
          {
            label: "tesseract.settings.tabs.oms.key",
            source: "settings.oms_workspace.key",
            disable: (value, allValues) => !get(allValues, "settings.oms_workspace.enabled"),
            validate: [],
            noConfirm: true,
            isRequired: true,
            newStyle: true
          }
        )
      ] })
    }
  );
};
var AzureMonitor_default = AzureMonitor;
export {
  AzureMonitor,
  AzureMonitor_default as default
};
