var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import {
  BooleanCheckListInput,
  BooleanInput,
  ConnectedForm,
  DialogBody,
  getArrayDataContent,
  SelectArrayInput,
  SelectInput,
  TextArrayInput,
  TextInput,
  useCrudProps,
  useGlobalParam,
  useMixpanel,
  validateMaxLengthMemo,
  validateRegexMemo
} from "@cuda-react/core";
import { settingsIcons } from "../../../../../../components/iconMapping";
import apiResources from "../../../../../../apiResources";
import { get } from "lodash";
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 840
  },
  inputRoot: {
    marginLeft: theme.spacing(8),
    width: "calc(100% - " + theme.spacing(16) + ")"
  },
  lastInput: {
    marginBottom: theme.spacing(2)
  }
}));
const hideInput = (key, type) => (value, data) => !get(data, key) || get(data, key) !== type;
const CreateEditUrlFiltering = (_a) => {
  var _b = _a, { create, id, onSuccess } = _b, props = __objRest(_b, ["create", "id", "onSuccess"]);
  var _a2, _b2, _c, _d;
  const registerAction = useMixpanel("Web Filter Rule", false);
  const actionChoices = getArrayDataContent((_a2 = useCrudProps(apiResources.urlFilterActions, {}, { cache: true })[0]) == null ? void 0 : _a2.data);
  const typeChoices = getArrayDataContent((_b2 = useCrudProps(apiResources.urlFilterTypes, {}, { cache: true })[0]) == null ? void 0 : _b2.data);
  const categoryChoices = getArrayDataContent((_c = useCrudProps(apiResources.urlFilterCategories, {}, { cache: true })[0]) == null ? void 0 : _c.data);
  const groupChoices = getArrayDataContent((_d = useCrudProps(apiResources.urlFilterGroups)[0]) == null ? void 0 : _d.data);
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const [translate] = useTranslation();
  const classes = useStyles();
  return /* @__PURE__ */ jsx(
    DialogBody,
    __spreadProps(__spreadValues({}, props), {
      form: true,
      title: create ? "tesseract.security.urlFiltering.settings.create.dialogTitle" : "tesseract.security.urlFiltering.settings.edit.dialogTitle",
      classes: { dialogContent: classes.dialogContent },
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          resource: apiResources.urlFilterRules,
          form: "urlFilterRules",
          create,
          params: create ? void 0 : { id },
          onSubmitSuccess: (response) => {
            var _a3, _b3, _c2;
            registerAction(create ? "Create" : "Update", {
              action: response.action,
              type: response.type,
              length: ((_a3 = response.categories) == null ? void 0 : _a3.length) || ((_b3 = response.rules) == null ? void 0 : _b3.length)
            });
            onSuccess == null ? void 0 : onSuccess();
            (_c2 = props.onClose) == null ? void 0 : _c2.call(props);
          },
          onCancel: props.onClose,
          formatRequestData: (_e) => {
            var _f = _e, { categories, rules, customCategories } = _f, data = __objRest(_f, ["categories", "rules", "customCategories"]);
            return __spreadProps(__spreadValues({}, data), {
              rules: data.type === "category" ? categories : data.type === "customCategory" ? customCategories : rules,
              virtualWanId: create ? virtualWanId : data.virtualWanId,
              appliesTo: create ? "Everyone" : data.appliesTo
            });
          },
          formatResourceData: (_g) => {
            var _h = _g, { rules } = _h, data = __objRest(_h, ["rules"]);
            return __spreadProps(__spreadValues({}, data), {
              categories: data.type === "category" ? rules : [],
              rules: data.type === "domain" ? rules : [],
              customCategories: data.type === "customCategory" ? rules.map((customCategoriesId) => parseInt(customCategoriesId)) : []
            });
          },
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "name",
                label: "tesseract.security.urlFiltering.settings.name",
                description: "tesseract.security.urlFiltering.settings.descriptions.name",
                inputProps: { classes: { root: classes.inputRoot } },
                validate: [validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")],
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "description",
                label: "tesseract.security.urlFiltering.settings.description",
                description: "tesseract.security.urlFiltering.settings.descriptions.description",
                validate: [validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")],
                inputProps: { classes: { root: classes.inputRoot } }
              }
            ),
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                source: "action",
                label: "tesseract.security.urlFiltering.settings.action",
                description: "tesseract.security.urlFiltering.settings.descriptions.action",
                iconMap: settingsIcons,
                choices: actionChoices,
                inputProps: { classes: { root: classes.inputRoot } },
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              BooleanInput,
              {
                source: "silent",
                label: "tesseract.security.urlFiltering.settings.silent",
                description: "tesseract.security.urlFiltering.settings.descriptions.silent",
                disable: hideInput("action", "block")
              }
            ),
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                source: "type",
                label: "tesseract.security.urlFiltering.settings.type",
                description: "tesseract.security.urlFiltering.settings.descriptions.type",
                choices: typeChoices,
                isRequired: true,
                inputProps: { classes: { root: classes.inputRoot } }
              }
            ),
            /* @__PURE__ */ jsx(
              BooleanCheckListInput,
              {
                source: "categories",
                label: " ",
                categoryChoices,
                groupChoices,
                hide: (value, data) => data && data.type !== "category",
                validate: [(value) => !value || value.length === 0 ? translate("tesseract.security.urlFiltering.settings.validation.emptyCategories") : void 0]
              }
            ),
            /* @__PURE__ */ jsx(
              SelectArrayInput,
              {
                source: "customCategories",
                label: "tesseract.security.urlFiltering.settings.customCategories",
                description: "tesseract.security.urlFiltering.settings.descriptions.customCategories",
                resource: apiResources.customCategories,
                optionValue: "id",
                hide: (value, data) => data && data.type !== "customCategory",
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextArrayInput,
              {
                source: "rules",
                label: "tesseract.security.urlFiltering.settings.domains",
                description: "tesseract.security.urlFiltering.settings.descriptions.domains",
                hide: (value, data) => data && data.type !== "domain",
                isRequired: true,
                inputProps: { classes: { root: classes.inputRoot + " " + classes.lastInput } }
              }
            )
          ]
        }
      )
    })
  );
};
var CreateEditUrlFiltering_default = CreateEditUrlFiltering;
export {
  CreateEditUrlFiltering,
  CreateEditUrlFiltering_default as default,
  useStyles
};
