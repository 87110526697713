import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import {
  BooleanInput,
  getArrayDataContent,
  PasswordConfirmInput,
  SelectInput,
  TextInput,
  useCrudProps,
  usePreview
} from "@cuda-react/core";
import { useSiteInputValidations } from "../../../../../hooks/createEditSiteHooks";
import apiResources from "../../../../../apiResources";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import CreateEditButtonDialog from "../../../../CreateEditButtonDialog";
import DownloadBootstrapConfigDialog from "./DownloadBootstrapConfigDialog";
const useStyles = makeStyles((theme) => ({
  bootstrapConfigButton: {
    margin: theme.spacing(2, 2, 2, 4)
  }
}));
const AdvancedSettingsTab = ({ applianceType, series, serials, gatewayType }) => {
  var _a;
  const timezonesData = getArrayDataContent((_a = useCrudProps(apiResources.siteTimezones)[0]) == null ? void 0 : _a.data);
  const inputValidations = useSiteInputValidations();
  const [translate] = useTranslation();
  const classes = useStyles();
  const isSc = series === "S";
  const isBackhaulLan = usePreview("backhaullan");
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      PasswordConfirmInput,
      {
        source: "password",
        label: "tesseract.appliances.settings.advanced.rootPassword",
        description: translate("tesseract.appliances.settings.advanced.descriptions.rootPassword", { context: applianceType }),
        confirmLabel: "tesseract.appliances.settings.advanced.rootPasswordConfirmation",
        validate: inputValidations.validatePassword,
        autocomplete: "new-password"
      }
    ),
    !isSc ? /* @__PURE__ */ jsx(
      SelectInput,
      {
        choices: timezonesData,
        source: "timezone",
        label: "tesseract.appliances.settings.advanced.updateWindowTZ",
        description: translate("tesseract.appliances.settings.advanced.descriptions.updateWindowTZ", { context: applianceType }),
        optionValue: "key",
        isRequired: true
      }
    ) : null,
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "primaryDns",
        label: "tesseract.appliances.settings.advanced.primaryDns",
        description: "tesseract.appliances.settings.advanced.descriptions.primaryDns",
        validate: inputValidations.validateIp
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "secondaryDns",
        label: "tesseract.appliances.settings.advanced.secondaryDns",
        description: "tesseract.appliances.settings.advanced.descriptions.secondaryDns",
        validate: inputValidations.validateIp,
        disable: (value, data) => !(data && (data.primaryDns || value))
      }
    ),
    isBackhaulLan && applianceType === "gateway" && gatewayType === "on-prem" ? /* @__PURE__ */ jsx(
      BooleanInput,
      {
        source: "secureOnPremNetworks",
        label: "tesseract.appliances.settings.advanced.secureOnPremNetwork",
        description: translate("tesseract.appliances.settings.advanced.descriptions.secureOnPremNetwork")
      }
    ) : null,
    !isSc ? /* @__PURE__ */ jsx(
      BooleanInput,
      {
        source: "disableSecurityPolicy",
        label: "tesseract.appliances.settings.advanced.disableSecurityPolicy",
        description: translate("tesseract.appliances.settings.advanced.descriptions.disableSecurityPolicy", { context: applianceType })
      }
    ) : null,
    !isSc ? /* @__PURE__ */ jsx(
      BooleanInput,
      {
        source: "disableIntrusionPrevention",
        label: "tesseract.appliances.settings.advanced.disableIpsPolicy",
        description: translate("tesseract.appliances.settings.advanced.descriptions.disableIpsPolicy", { context: applianceType }),
        disable: (value, data) => data && data.disableSecurityPolicy
      }
    ) : null,
    !isSc ? /* @__PURE__ */ jsx(
      BooleanInput,
      {
        source: "disableOMS",
        label: "tesseract.appliances.settings.advanced.disableOMS",
        description: translate("tesseract.appliances.settings.advanced.descriptions.disableOMS", { context: applianceType })
      }
    ) : null,
    !isSc ? /* @__PURE__ */ jsx(
      BooleanInput,
      {
        source: "disableSyslogStreaming",
        label: "tesseract.syslog.syslogOverride",
        description: translate("tesseract.syslog.descriptions.syslogOverride", { context: applianceType })
      }
    ) : null,
    applianceType === "site" || gatewayType === "on-prem" ? /* @__PURE__ */ jsx("div", { className: classes.bootstrapConfigButton, children: /* @__PURE__ */ jsx(
      CreateEditButtonDialog,
      {
        create: true,
        label: "tesseract.sites.settings.advanced.downloadBootstrapConfigDialog.buttonLabel",
        component: DownloadBootstrapConfigDialog,
        applianceType,
        serials,
        isSc
      }
    ) }) : null
  ] });
};
var AdvancedSettingsTab_default = AdvancedSettingsTab;
export {
  AdvancedSettingsTab,
  AdvancedSettingsTab_default as default,
  useStyles
};
