var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { ConnectedForm, Tab, useMixpanel } from "@cuda-react/core";
import { get, set } from "lodash";
import { formatSCErrors, validateNames, validateNetworks } from "../../../hooks/createEditSiteHooks";
import { useTranslation } from "react-i18next";
import BasicTab from "./tabs/common/BasicTab";
import WansTab from "./tabs/sc/WansTab";
import LansTab from "./tabs/sc/LansTab";
import DhcpTab from "./tabs/sc/DhcpTab";
import AdvancedSettingsTab from "./tabs/common/AdvancedSettingsTab";
import apiResources from "../../../apiResources";
const SELECT_EMPTY_VALUE = "__SELECT_EMPTY_VALUE";
const EditSiteContent = (props) => {
  const { id, details: { model, modelSeries, serials } } = props;
  const [translate] = useTranslation();
  const registerAction = useMixpanel(
    "Sites Settings",
    !!model,
    { model: model || "", type: modelSeries || "" }
  );
  return /* @__PURE__ */ jsxs(
    ConnectedForm,
    {
      resource: apiResources.siteScConfiguration,
      id,
      canReset: true,
      tabbed: true,
      topToolbar: true,
      validate: (values = {}) => {
        const errors = {};
        const usedNetworks = validateNetworks(values, "wans", void 0, translate, errors);
        validateNetworks(values, "lans", void 0, translate, errors, usedNetworks);
        const usedNetworkNames = validateNames(values, "wans", void 0, translate, errors);
        validateNames(values, "lans", void 0, translate, errors, usedNetworkNames);
        validateNames(values, "routes", void 0, translate, errors);
        values.secondaryDns && !values.primaryDns && set(errors, "secondaryDns", translate("tesseract.appliances.dialog.validation.secondaryDns"));
        return errors;
      },
      formatResourceData: (data) => __spreadProps(__spreadValues({}, data), {
        wans: get(data, "wans", []).sort((wanA) => wanA.provider === "fallback" ? 1 : -1),
        lans: get(data, "wifi.enabled") ? [
          ...get(data, "lans", []),
          get(data, "wifi")
        ] : get(data, "lans", [])
      }),
      formatRequestData: (data) => __spreadProps(__spreadValues({}, data), {
        lans: get(data, "lans", []).filter((lan) => lan.port !== "wifi"),
        wifi: __spreadValues({ enabled: true }, get(data, "lans", []).find((lan) => lan.port === "wifi") || { enabled: false })
      }),
      formatError: formatSCErrors,
      onSubmitSuccess: (response) => {
        registerAction("Update", {
          model: response.model,
          type: "SC",
          wansLength: response.wans.length,
          lansLength: response.lans.length,
          isHa: response.serials.length > 1
        });
      },
      children: [
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.sites.settings.basic.tabTitle", children: /* @__PURE__ */ jsx(BasicTab, { id, noAddingSerials: true, series: "S", applianceType: "site" }) }),
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.sites.settings.wan.tabTitle", children: /* @__PURE__ */ jsx(WansTab, { id }) }),
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.sites.settings.lan.tabTitle", children: /* @__PURE__ */ jsx(LansTab, { id }) }),
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.sites.settings.dhcp.tabTitle", children: /* @__PURE__ */ jsx(DhcpTab, {}) }),
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.sites.settings.advanced.tabTitle", children: /* @__PURE__ */ jsx(AdvancedSettingsTab, { series: "S", applianceType: "site", serials }) })
      ]
    }
  );
};
var EditSCContent_default = EditSiteContent;
export {
  SELECT_EMPTY_VALUE,
  EditSCContent_default as default
};
