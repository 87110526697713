var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { CardActions, Paper } from "@barracuda-internal/bds-core";
import { get, isEmpty } from "lodash";
import React, { useCallback } from "react";
import DownloadButton from "../../buttons/DownloadButton/DownloadButton";
import TableActionsAndFilters from "../TableActionsAndFilters/TableActionsAndFilters";
import { useTranslation } from "react-i18next";
import {
  useConnectedTable,
  useDataTableExpandableRows,
  useDataTableFieldColumns,
  useDataTableGroups,
  useDataTablePersonalisedColumns,
  useDataTableRowDetails,
  useDataTableRowHighlighting,
  useDataTableSelect,
  useNoDataPage
} from "../../../hooks";
import { DateTime, Select, SelectArray, Text } from "../../inputs";
import { makeOverrideableStyles } from "@cuda-react/theme";
import Table from "../Table/Table";
import { convertFromSimpleFilters, convertToSimpleFilters } from "../../../utils";
import classNames from "classnames";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import { createStyles } from "@mui/styles";
import TableFilter from "../TableActionsAndFilters/TableFilter";
import ConditionalWrapper from "../../functional/ConditionalWrapper/ConditionalWrapper";
const styles = (theme) => createStyles({
  cardActions: (props) => !props.flat ? {
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    width: "100%",
    flexDirection: "column"
  } : {
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection: "column"
  },
  buttonBarWithTitle: {
    marginTop: theme.spacing(-8.5),
    marginBottom: theme.spacing(-1)
  },
  filterBarWithTitle: {
    marginTop: theme.spacing(3.5)
  },
  dataTable: {
    "& .k-grid-content": {
      overflowY: (props) => props.displayVerticalScroll ? void 0 : "visible"
    },
    "& .k-grid-header": {
      padding: (props) => props.displayVerticalScroll ? void 0 : "0 !important"
    },
    "& table": {
      minWidth: (props) => !props.resizable && !props.editColumns ? React.Children.toArray(props.children).reduce((total, child) => {
        const childWidth = get(child, "props.columnProps.width", get(child, "props.width", props.minCellWidth)) || 0;
        return total + childWidth;
      }, 0) : "100%"
    }
  },
  flat: {}
});
const useStyles = makeOverrideableStyles("ConnectedTable", styles);
const renderFilter = (filter, props, CustomFilter) => {
  switch (filter) {
    case "selectarray":
      return /* @__PURE__ */ jsx(TableFilter, __spreadProps(__spreadValues({}, props), { component: SelectArray }));
    case "select":
      return /* @__PURE__ */ jsx(TableFilter, __spreadProps(__spreadValues({}, props), { component: Select }));
    case "datetime":
      return /* @__PURE__ */ jsx(TableFilter, __spreadProps(__spreadValues({}, props), { component: DateTime }));
    case "custom":
      return /* @__PURE__ */ jsx(TableFilter, __spreadProps(__spreadValues({}, props), { component: CustomFilter }));
    default:
      return /* @__PURE__ */ jsx(TableFilter, __spreadProps(__spreadValues({}, props), { component: Text }));
  }
};
const generateUniqueKey = (child, index) => {
  const key = "" + child.key;
  return key.startsWith(".$") ? key.replace(".$", "") : child.props.source || index;
};
const ConnectedTable = (props) => {
  const _a = props, {
    actions,
    children,
    disablePageSizes,
    exportProps,
    exportResource,
    hasTitle,
    noDataMessage,
    noDataPage,
    onPageStatusChange,
    noDataWithFilterMessage,
    rowDetails,
    refreshRef,
    tableActionsAndFiltersProps,
    paper
  } = _a, dataTableProps = __objRest(_a, [
    "actions",
    "children",
    "disablePageSizes",
    "exportProps",
    "exportResource",
    "hasTitle",
    "noDataMessage",
    "noDataPage",
    "onPageStatusChange",
    "noDataWithFilterMessage",
    "rowDetails",
    "refreshRef",
    "tableActionsAndFiltersProps",
    "paper"
  ]);
  delete dataTableProps.classes;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const childArray = React.Children.toArray(children).filter(Boolean);
  const dataTableFieldColumnsProps = useDataTableFieldColumns(childArray, dataTableProps);
  const {
    dataTableProps: connectedTableProps,
    filterState,
    setFilterState,
    crudParams,
    requestParams,
    refresh
  } = useConnectedTable(__spreadProps(__spreadValues({}, dataTableProps), { pageSizes: disablePageSizes ? void 0 : props.pageSizes }));
  if (refreshRef) {
    refreshRef.current = refresh;
  }
  const _b = useDataTablePersonalisedColumns(
    dataTableFieldColumnsProps.children,
    // minCellWidth is set in defaultProps, so it will never be undefined when passed into useDataTablePersonalisedColumns
    __spreadProps(__spreadValues({}, dataTableProps), { minCellWidth: dataTableProps.minCellWidth })
  ), {
    children: personalisedColumnChildren,
    actions: personalisedColumnActions
  } = _b, dataTablePersonalisedColumnsProps = __objRest(_b, [
    "children",
    "actions"
  ]);
  const dataTableGroupProps = useDataTableGroups(connectedTableProps.data, dataTableProps);
  const [dataTableSelectProps, selectColumn] = useDataTableSelect(dataTableGroupProps.data, dataTableProps);
  const dataTableRowHighlightingProps = useDataTableRowHighlighting(dataTableProps);
  const [dataTableRowDetailsProps, rowDetailsDialog] = useDataTableRowDetails(__spreadValues({ rowDetails }, dataTableProps));
  const _c = useDataTableExpandableRows(
    dataTableSelectProps.data,
    [selectColumn, ...personalisedColumnChildren].filter((item) => Boolean(item)),
    dataTableProps
  ), {
    children: dataTableExpandableRowChildren
  } = _c, dataTableExpandableRowProps = __objRest(_c, [
    "children"
  ]);
  const downloadButton = exportResource && /* @__PURE__ */ jsx(
    DownloadButton,
    __spreadValues({
      resource: exportResource.resource || props.resource || "",
      directDownload: (exportResource == null ? void 0 : exportResource.directDownload) || void 0,
      filename: exportResource.filename,
      filetype: "csv",
      params: requestParams
    }, exportProps || {}),
    "download"
  ) || void 0;
  const updatedActions = Array.isArray(actions) ? [...actions] : actions ? [actions] : [];
  downloadButton && updatedActions.unshift(downloadButton);
  personalisedColumnActions && updatedActions.push(personalisedColumnActions);
  const filters = childArray.filter((child) => child.props.filter).map((child, index) => renderFilter(
    child.props.filter,
    __spreadValues({
      key: generateUniqueKey(child, index),
      source: get(child.props, "columnProps.field", child.props.source),
      id: get(child.props, "columnProps.field", child.props.source),
      label: get(child.props, "filterProps.label", child.props.label)
    }, get(child.props, "filterProps", {})),
    get(child.props, "filterProps.component")
  ));
  const tableActions = updatedActions.length > 0 || filters.length > 0 ? /* @__PURE__ */ jsx(CardActions, { className: classes.cardActions, disableSpacing: true, children: /* @__PURE__ */ jsx(
    TableActionsAndFilters,
    __spreadValues({
      filters,
      filterValues: convertToSimpleFilters(filterState),
      onChange: (filterValue) => setFilterState(convertFromSimpleFilters(filterValue)),
      actions: updatedActions
    }, tableActionsAndFiltersProps)
  ) }) : void 0;
  const cellRender = useCallback((cell, cellProps) => dataTableGroupProps.cellRender(
    cell,
    cellProps,
    (cell2, cellProps2) => dataTableSelectProps.cellRender(
      cell2,
      cellProps2,
      (cell3, cellProps3) => dataTableExpandableRowProps.cellRender(
        cell3,
        cellProps3,
        dataTableFieldColumnsProps.cellRender
      )
    )
  ), [dataTableGroupProps.cellRender, dataTableSelectProps.cellRender, dataTableFieldColumnsProps.cellRender, dataTableExpandableRowProps.cellRender]);
  const headerCellRender = useCallback((cell, cellProps) => dataTableSelectProps.headerCellRender(
    cell,
    cellProps,
    dataTablePersonalisedColumnsProps.headerCellRender
  ), [dataTableSelectProps.headerCellRender, dataTablePersonalisedColumnsProps.headerCellRender]);
  const rowRender = useCallback((row, rowProps) => dataTableExpandableRowProps.rowRender(
    connectedTableProps.rowRender(
      dataTableRowDetailsProps.rowRender(
        dataTableRowHighlightingProps.rowRender(row, rowProps),
        rowProps
      ),
      rowProps
    ),
    rowProps
  ), [dataTableRowDetailsProps.rowRender, dataTableRowHighlightingProps.rowRender, dataTableExpandableRowProps.rowRender]);
  const filterActive = !isEmpty(get(crudParams, "filter"));
  const alternativePage = useNoDataPage(connectedTableProps.data, noDataPage, filterActive, onPageStatusChange);
  return /* @__PURE__ */ jsxs(ErrorBoundary, { children: [
    alternativePage || /* @__PURE__ */ jsx(ConditionalWrapper, { condition: paper, wrapper: (children2) => /* @__PURE__ */ jsx(Paper, { children: children2 }), children: /* @__PURE__ */ jsx(
      Table,
      __spreadProps(__spreadValues(__spreadProps(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues({}, connectedTableProps), dataTableGroupProps), dataTablePersonalisedColumnsProps), dataTableSelectProps), dataTableExpandableRowProps), {
        cellRender,
        headerCellRender,
        rowRender,
        className: classNames(classes.dataTable, props.flat && classes.flat),
        noRecordsMessage: filterActive ? typeof noDataWithFilterMessage !== "string" ? noDataWithFilterMessage : translate(noDataWithFilterMessage) : typeof noDataMessage !== "string" ? noDataMessage : translate(noDataMessage),
        tableActions
      }), dataTableProps), {
        fitToScreen: true,
        children: dataTableExpandableRowChildren
      })
    ) }),
    rowDetailsDialog
  ] });
};
ConnectedTable.defaultProps = {
  addFilterLabel: "cuda.table.addFilter",
  defaultFilter: {},
  defaultItemsPerPage: 40,
  defaultSort: {},
  minCellWidth: 128,
  noDataMessage: "cuda.table.noData",
  noDataWithFilterMessage: "cuda.table.noDataWithFilter",
  pageSizeAll: 1e3,
  pageSizes: [5, 10, 20, 40, 80],
  pageMode: "all",
  pollInterval: 3e4
};
var ConnectedTable_default = ConnectedTable;
export {
  ConnectedTable,
  ConnectedTable_default as default
};
