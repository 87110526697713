var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import {
  BooleanCheckListInput,
  ConnectedForm,
  DialogBody,
  getArrayDataContent,
  TextArrayInput,
  TextInput,
  useCrudProps,
  useGlobalParam,
  useMixpanel,
  validateMaxLengthMemo,
  validateRegexMemo
} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";
import { Divider } from "@barracuda-internal/bds-core";
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 840
  },
  divider: {
    margin: theme.spacing(1, 4, 1, 4)
  },
  domainContainer: {
    margin: theme.spacing(1, 0, 1, 0),
    flex: "1 1 auto",
    overflowY: "auto",
    display: "flex",
    flexDirection: "row"
  }
}));
const CreateEditCustomCategory = (props) => {
  var _a, _b;
  const create = props.create;
  const id = props.id;
  const onSuccess = props.onSuccess;
  const registerAction = useMixpanel("Custom Category Rule", false);
  const categoryChoices = getArrayDataContent((_a = useCrudProps(apiResources.urlFilterCategories, {}, {}, true)[0]) == null ? void 0 : _a.data);
  const groupChoices = getArrayDataContent((_b = useCrudProps(apiResources.urlFilterGroups)[0]) == null ? void 0 : _b.data);
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const [translate] = useTranslation();
  const classes = useStyles();
  return /* @__PURE__ */ jsx(
    DialogBody,
    __spreadProps(__spreadValues({}, props), {
      form: true,
      title: create ? "tesseract.customCategories.dialog.create.dialogTitle" : "tesseract.customCategories.dialog.edit.dialogTitle",
      classes: { dialogContent: classes.dialogContent },
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          resource: apiResources.customCategories,
          create,
          params: create ? void 0 : { id },
          onSubmitSuccess: (response) => {
            var _a2, _b2, _c;
            registerAction(create ? "Create" : "Update", {
              length: ((_a2 = response.categories) == null ? void 0 : _a2.length) || ((_b2 = response.rules) == null ? void 0 : _b2.length)
            });
            onSuccess == null ? void 0 : onSuccess();
            (_c = props.onClose) == null ? void 0 : _c.call(props);
          },
          onCancel: props.onClose,
          formatRequestData: (_c) => {
            var _d = _c, { categories, rules } = _d, data = __objRest(_d, ["categories", "rules"]);
            return __spreadProps(__spreadValues({}, data), {
              categories,
              virtualWanId: create ? virtualWanId : data.virtualWanId
            });
          },
          formatResourceData: (data) => __spreadProps(__spreadValues({}, data), {
            categories: data.categories || []
          }),
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "name",
                label: "tesseract.customCategories.dialog.name",
                description: "tesseract.customCategories.dialog.descriptions.name",
                validate: [validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")],
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "description",
                label: "tesseract.customCategories.dialog.description",
                description: "tesseract.customCategories.dialog.descriptions.description",
                validate: [validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]
              }
            ),
            /* @__PURE__ */ jsx(Divider, { className: classes.divider }),
            /* @__PURE__ */ jsx("div", { className: classes.domainContainer, children: /* @__PURE__ */ jsx(
              TextArrayInput,
              {
                source: "domains",
                label: "tesseract.customCategories.dialog.includedDomains",
                description: "tesseract.customCategories.dialog.descriptions.includedDomains"
              }
            ) }),
            /* @__PURE__ */ jsx(
              BooleanCheckListInput,
              {
                source: "categories",
                label: " ",
                categoryChoices,
                groupChoices,
                validate: (value, data) => {
                  var _a2, _b2;
                  return ((_a2 = data == null ? void 0 : data.categories) == null ? void 0 : _a2.length) > 0 || ((_b2 = data == null ? void 0 : data.domains) == null ? void 0 : _b2.length) > 0 ? void 0 : translate("tesseract.customCategories.dialog.validation.emptyCategories");
                }
              }
            )
          ]
        }
      )
    })
  );
};
CreateEditCustomCategory.propTypes = {
  create: PropTypes.bool,
  id: PropTypes.number,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  title: PropTypes.string
};
var CreateEditCustomCategory_default = CreateEditCustomCategory;
export {
  CreateEditCustomCategory,
  CreateEditCustomCategory_default as default,
  useStyles
};
