import { jsx, jsxs } from "react/jsx-runtime";
import { ConnectedForm, DialogBody, Tab } from "@cuda-react/core";
import BasicPolicyInputs from "./BasicPolicyInputs";
import DeviceAttributesInputs, { validateDeviceAttributes } from "./DeviceAttributesInputs";
import { formatError, formatRequestData, formatResourceData } from "./FormattingMethods";
import { makeStyles } from "@mui/styles";
import apiResources from "../../../../../apiResources";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  dialogContent: {
    width: 680
  }
});
const EditPolicy = ({ onClose, onSuccess, id }) => {
  const classes = useStyles();
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx(
    DialogBody,
    {
      title: "tesseract.endpoint.remoteAccessPolicies.edit",
      onClose,
      classes,
      form: true,
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          tabbed: true,
          resource: apiResources.zeroTrustPolicies,
          onSubmitSuccess: () => {
            onSuccess == null ? void 0 : onSuccess();
            onClose == null ? void 0 : onClose();
          },
          params: { id },
          onCancel: onClose,
          formatRequestData,
          formatResourceData,
          formatError,
          validate: validateDeviceAttributes(translate),
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsx(Tab, { label: "tesseract.endpoint.remoteAccessPolicies.policy", children: /* @__PURE__ */ jsx(BasicPolicyInputs, {}) }),
            /* @__PURE__ */ jsx(Tab, { label: "tesseract.endpoint.remoteAccessPolicies.deviceAttributes", children: /* @__PURE__ */ jsx(DeviceAttributesInputs, {}) })
          ]
        }
      )
    }
  );
};
var EditPolicy_default = EditPolicy;
export {
  EditPolicy,
  EditPolicy_default as default
};
