import { jsx, jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import {
  ActionButtonsField,
  ChipArrayField,
  CrudTypes,
  DeleteDialog,
  EmptyPageContent,
  PropGateway,
  TablePage,
  TextField,
  useCrudFetch,
  useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import CreateEditIngressRule from "./CreateEditIngressRule";
import { useSourceDestinationField } from "../../../../../hooks/sourceAndDestinationHooks";
import SourceFilter from "../../../../../components/sourceAndDestination/SourceFilter";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import { get, merge } from "lodash";
import apiResources from "../../../../../apiResources";
import {
  formatGatewaySiteGroupInTableData,
  formatSourceDestinationFilters
} from "../../../../../components/sourceAndDestination/tableUtils";
import GroupingField from "../GroupingField";
import { GetStartedBackgroundGraphic, IngressNatGraphic } from "@cuda-react/icons";
const IngressRule = () => {
  const tableRefreshRef = useRef();
  const [, , callIngressRuleControl] = useCrudFetch(CrudTypes.CREATE, apiResources.ingressNatRulesUpdateOrder, {});
  const handleOrderClick = (id, direction, data) => callIngressRuleControl({
    id,
    changeOrder: direction,
    data
  }).then(tableRefreshRef.current);
  const generateChipArrayProps = useSourceDestinationField();
  const registerAction = useMixpanel("Ingress NAT Rules");
  const [actionVisible, setActionVisible] = useState(false);
  const renderGatewayGroupName = (applianceId, rowData) => {
    const groupByType = rowData.items[0].type === "gateway" ? "onPremGatewayName" : "siteName";
    return generateChipArrayProps("scope")({ data: { scope: { type: groupByType } } }).render;
  };
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      title: "tesseract.securityAndAccess.pageTitle",
      subtitle: ["tesseract.securityAndAccess.network", "tesseract.securityAndAccess.ingressNatRules"],
      resource: apiResources.ingressNatRules,
      exportResource: { resource: apiResources.ingressNatRulesExport, filename: "ingressNatRules" },
      minCellWidth: 128,
      refreshRef: tableRefreshRef,
      titleActions: actionVisible && /* @__PURE__ */ jsx(
        CreateEditButtonDialog,
        {
          create: true,
          createEditComponent: CreateEditIngressRule,
          component: CreateEditIngressRule,
          onSuccess: tableRefreshRef.current
        },
        "create"
      ),
      groupBy: "destination.applianceId",
      groupField: /* @__PURE__ */ jsx(
        GroupingField,
        {
          render: (applianceId, data) => renderGatewayGroupName(applianceId, data)(applianceId, data),
          createEditComponent: CreateEditIngressRule,
          dataIconType: "destination.type"
        }
      ),
      defaultItemsPerPage: 5e3,
      formatData: formatGatewaySiteGroupInTableData,
      onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table"),
      params: (data) => merge({}, formatSourceDestinationFilters(data)),
      authenticated: true,
      noDataPage: /* @__PURE__ */ jsx(
        EmptyPageContent,
        {
          background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
          icon: /* @__PURE__ */ jsx(IngressNatGraphic, {}),
          iconHeader: "tesseract.security.ingressNatRules.noData.iconHeader",
          textHeader: "tesseract.security.ingressNatRules.noData.textHeader",
          text: "tesseract.security.ingressNatRules.noData.text",
          actions: /* @__PURE__ */ jsx(
            CreateEditButtonDialog,
            {
              label: "tesseract.security.ingressNatRules.noData.labelButton",
              create: true,
              createEditComponent: CreateEditIngressRule,
              component: CreateEditIngressRule,
              onSuccess: tableRefreshRef.current
            },
            "create"
          )
        }
      ),
      children: [
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            left: true,
            label: "tesseract.security.ingressNatRules.settings.order",
            width: 96,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(
                TextField,
                {
                  source: "order"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowUpwardIcon,
                {
                  onClick: (event, data) => handleOrderClick(data.id, "increase", data),
                  disabled: (data) => get(data, "order", 0) < 2,
                  id: "cuda-icon-up"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowDownwardIcon,
                {
                  onClick: (event, data) => handleOrderClick(data.id, "decrease", data),
                  disabled: (data) => get(data, "groupTotal", 0) - get(data, "order", 0) < 1,
                  id: "cuda-icon-down"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.security.ingressNatRules.settings.name",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.security.ingressNatRules.settings.description",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "source",
            label: "tesseract.security.ingressNatRules.settings.source",
            editProps: generateChipArrayProps("source"),
            filter: "custom",
            filterProps: {
              component: SourceFilter,
              filterSources: ["internet", "networks"]
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "source" })
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "destination",
            label: "tesseract.security.ingressNatRules.settings.destination",
            editProps: generateChipArrayProps("destination"),
            filter: "custom",
            filterProps: {
              component: DestinationFilter,
              filterSources: ["onPremGateway", "ingressSite"]
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "destination" })
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "target",
            label: "tesseract.security.ingressNatRules.settings.target",
            editProps: generateChipArrayProps("target"),
            filter: "selectarray",
            filterProps: {
              resource: apiResources.validTargets
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "source" })
          }
        ),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            width: 96,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(CreateEditButtonDialog, { component: CreateEditIngressRule, onSuccess: tableRefreshRef.current }),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource: apiResources.ingressNatRules,
                  title: "tesseract.security.ingressNatRules.settings.delete.title",
                  message: "tesseract.security.ingressNatRules.settings.delete.body",
                  onSuccess: () => {
                    var _a;
                    (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                    registerAction("Delete");
                  }
                }
              )
            ]
          }
        )
      ]
    }
  );
};
IngressRule.propTypes = {};
var IngressRule_default = IngressRule;
export {
  IngressRule_default as default
};
