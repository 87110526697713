import { jsx, jsxs } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import { Link, Typography } from "@barracuda-internal/bds-core";
import { Trans, useTranslation } from "react-i18next";
import { CustomField } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  siteCreated: {
    textAlign: "center",
    marginBottom: theme.spacing(2)
  },
  message: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  serial: {
    textAlign: "center",
    paddingTop: 12
  },
  warning: {
    color: theme.palette.warning.main
  }
}));
const SiteResultStep = ({ data }) => {
  const classes = useStyles();
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx(
    CustomField,
    {
      source: "serials",
      data,
      render: (value) => {
        const valueAsArray = value && (Array.isArray(value) ? value : [value]);
        return /* @__PURE__ */ jsxs(Grid, { container: true, direction: "column", children: [
          /* @__PURE__ */ jsxs(Grid, { item: true, children: [
            /* @__PURE__ */ jsx(Typography, { className: classes.siteCreated, variant: "h5", children: translate("tesseract.sites.dialog.result.siteCreated") }),
            /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: /* @__PURE__ */ jsx(
              Trans,
              {
                i18nKey: "tesseract.sites.dialog.result.connectPortSc",
                count: valueAsArray.length,
                children: /* @__PURE__ */ jsx("strong", {})
              }
            ) }),
            /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.sites.dialog.result.campus", children: /* @__PURE__ */ jsx(
              Link,
              {
                href: "https://campus.barracuda.com/doc/98224805/",
                target: "_blank",
                rel: "noopener"
              }
            ) }) })
          ] }),
          /* @__PURE__ */ jsx(Grid, { item: true, children: valueAsArray.map((appliance) => /* @__PURE__ */ jsx(Typography, { variant: "h6", className: classes.serial, children: translate("tesseract.sites.dialog.result.serial", { serial: appliance.serial }) }, appliance.serial)) })
        ] });
      }
    }
  );
};
var SiteResultStep_default = SiteResultStep;
export {
  SiteResultStep,
  SiteResultStep_default as default
};
