import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { getArrayDataContent, SelectInput, TextInput, useCrudProps } from "@cuda-react/core";
import { useSiteInputValidations } from "../../../../../hooks/createEditSiteHooks";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../../apiResources";
const AdvancedStep = ({ applianceType, series }) => {
  var _a;
  const inputValidations = useSiteInputValidations();
  const timezonesData = getArrayDataContent((_a = useCrudProps(apiResources.siteTimezones)[0]) == null ? void 0 : _a.data);
  const [translate] = useTranslation();
  const isSc = series === "S";
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        choices: timezonesData,
        defaultChoice: isSc ? "UTC" : void 0,
        source: "timezone",
        label: "tesseract.appliances.dialog.advanced.updateWindowTZ",
        description: translate("tesseract.appliances.dialog.advanced.descriptions.updateWindowTZ", { context: applianceType }),
        hideInput: isSc,
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "primaryDns",
        label: "tesseract.appliances.dialog.advanced.primaryDns",
        description: "tesseract.appliances.dialog.advanced.descriptions.primaryDns",
        validate: inputValidations.validateIp
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "secondaryDns",
        label: "tesseract.appliances.dialog.advanced.secondaryDns",
        description: "tesseract.appliances.dialog.advanced.descriptions.secondaryDns",
        validate: inputValidations.validateIp,
        disable: (value, data) => !(data && (data.primaryDns || value))
      }
    )
  ] });
};
var AdvancedStep_default = AdvancedStep;
export {
  AdvancedStep_default as default
};
