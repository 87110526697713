var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import AutoIcon from "@mui/icons-material/Build";
import BusinessIcon from "@mui/icons-material/Business";
import {
  AlertIcon,
  AllowIcon,
  BlockIcon,
  DoNotScanIcon,
  Help as HelpPendingIcon,
  LiveLogClosingConnectionIcon,
  LiveLogConnectionEstablishedIcon,
  LiveLogConnectionNotEstablishedIcon,
  LiveLogOneWayActiveIcon,
  LiveLogOneWayInactiveIcon,
  LiveLogTwoWayIcon,
  NetworkBulkIcon,
  NetworkServicesIcon,
  Office365Icon,
  OffIcon,
  OverrideIcon,
  PriorityHighIcon,
  PriorityLowIcon,
  PriorityMediumIcon,
  PriorityRealtimeIcon,
  SaasIcon,
  ScanIcon,
  VoipIcon,
  VpnIcon,
  WarnIcon,
  WebTrafficIcon
} from "@cuda-react/icons";
import { Description, Forward, Help } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { Critical, Success, Warning } from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import { makeStyles } from "@mui/styles";
import { get } from "lodash";
const useStyles = makeStyles((theme) => ({
  fillGreen: {
    color: theme.palette.success.main
  },
  fillRed: {
    color: theme.palette.error.main
  },
  fillOrange: {
    color: theme.palette.warning.main
  }
}));
const BusinessIconResized = () => (
  // @ts-ignore not sure why this fails, as this prop does work...
  /* @__PURE__ */ jsx(BusinessIcon, { size: 24 })
);
const AutoIconResized = () => (
  // @ts-ignore not sure why this fails, as this prop does work...
  /* @__PURE__ */ jsx(AutoIcon, { size: 24 })
);
const categoryIcons = {
  office365: /* @__PURE__ */ jsx(Office365Icon, {}),
  saasAndBusiness: /* @__PURE__ */ jsx(SaasIcon, {}),
  voicevideo: /* @__PURE__ */ jsx(VoipIcon, {}),
  remoteAccess: /* @__PURE__ */ jsx(BusinessIconResized, {}),
  networkservices: /* @__PURE__ */ jsx(NetworkServicesIcon, {}),
  networkbulk: /* @__PURE__ */ jsx(NetworkBulkIcon, {}),
  webtraffic: /* @__PURE__ */ jsx(WebTrafficIcon, {})
};
const priorityIcons = {
  realtime: /* @__PURE__ */ jsx(PriorityRealtimeIcon, {}),
  high: /* @__PURE__ */ jsx(PriorityHighIcon, {}),
  medium: /* @__PURE__ */ jsx(PriorityMediumIcon, {}),
  low: /* @__PURE__ */ jsx(PriorityLowIcon, {})
};
const SuccessIcon = () => {
  const classes = useStyles();
  return /* @__PURE__ */ jsx(Success, { id: "sdwan-ui-ok-status-icon", className: classes.fillGreen });
};
const WarningIcon = () => {
  const classes = useStyles();
  return /* @__PURE__ */ jsx(Warning, { id: "sdwan-ui-warning-status-icon", className: classes.fillOrange });
};
const ErrorIcon = () => {
  const classes = useStyles();
  return /* @__PURE__ */ jsx(Critical, { id: "sdwan-ui-error-status-icon", className: classes.fillRed });
};
const fallbackIcons = {
  allow: /* @__PURE__ */ jsx(AllowIcon, {}),
  drop: /* @__PURE__ */ jsx(BlockIcon, {})
};
const settingsIcons = {
  allow: /* @__PURE__ */ jsx(AllowIcon, {}),
  block: /* @__PURE__ */ jsx(BlockIcon, {}),
  warn: /* @__PURE__ */ jsx(WarnIcon, {}),
  alert: /* @__PURE__ */ jsx(AlertIcon, {}),
  override: /* @__PURE__ */ jsx(OverrideIcon, {})
};
const ipsIcons = {
  doNotScan: /* @__PURE__ */ jsx(AllowIcon, {}),
  enforce: /* @__PURE__ */ jsx(BlockIcon, {}),
  ignore: /* @__PURE__ */ jsx(AllowIcon, {}),
  log: /* @__PURE__ */ jsx(Description, {})
};
const sslExplicitIcons = {
  doNotInspect: /* @__PURE__ */ jsx(AllowIcon, {}),
  inspect: /* @__PURE__ */ jsx(BlockIcon, {})
};
const scanIcons = {
  doNotScan: /* @__PURE__ */ jsx(DoNotScanIcon, {}),
  scan: /* @__PURE__ */ jsx(ScanIcon, {})
};
const trafficTypeIcons = {
  vpn: /* @__PURE__ */ jsx(VpnIcon, {}),
  direct: /* @__PURE__ */ jsx(Forward, {}),
  auto: /* @__PURE__ */ jsx(AutoIconResized, {})
};
const siteGatewayStatusIcons = {
  online: /* @__PURE__ */ jsx(SuccessIcon, {}),
  offline: /* @__PURE__ */ jsx(ErrorIcon, {}),
  connecting: /* @__PURE__ */ jsx(WarningIcon, {}),
  pending: /* @__PURE__ */ jsx(HelpPendingIcon, {}),
  degraded: /* @__PURE__ */ jsx(WarningIcon, {})
};
const applianceConfigurationStatusIcons = {
  completed: /* @__PURE__ */ jsx(SuccessIcon, {}),
  connecting: /* @__PURE__ */ jsx(WarningIcon, {}),
  pending: /* @__PURE__ */ jsx(HelpPendingIcon, {}),
  failed: /* @__PURE__ */ jsx(ErrorIcon, {})
};
const logsIconMap = {
  ok: /* @__PURE__ */ jsx(SuccessIcon, {}),
  error: /* @__PURE__ */ jsx(ErrorIcon, {}),
  warning: /* @__PURE__ */ jsx(WarningIcon, {}),
  unknown: /* @__PURE__ */ jsx(Help, {})
};
const logsTrafficStatesIconMap = {
  initiate: /* @__PURE__ */ jsx(LiveLogOneWayActiveIcon, {}),
  fail: /* @__PURE__ */ jsx(LiveLogOneWayInactiveIcon, {}),
  sync: /* @__PURE__ */ jsx(LiveLogTwoWayIcon, {}),
  established: /* @__PURE__ */ jsx(LiveLogConnectionEstablishedIcon, {}),
  httpDeny: /* @__PURE__ */ jsx(LiveLogConnectionNotEstablishedIcon, {}),
  closing: /* @__PURE__ */ jsx(LiveLogClosingConnectionIcon, {})
};
const siteGatewayApplianceStatusIcons = __spreadValues(__spreadValues({}, siteGatewayStatusIcons), applianceConfigurationStatusIcons);
const getSiteGatewayStatusIcons = (value) => get(siteGatewayStatusIcons, value || "", siteGatewayStatusIcons.pending);
const getSiteGatewayApplianceStatusIcon = (value) => get(siteGatewayApplianceStatusIcons, value || "", siteGatewayApplianceStatusIcons.pending);
const hotfixUpdateStatusIcons = {
  ok: /* @__PURE__ */ jsx(SuccessIcon, {}),
  warning: /* @__PURE__ */ jsx(Help, {}),
  unknown: /* @__PURE__ */ jsx(Help, {}),
  error: /* @__PURE__ */ jsx(ErrorIcon, {})
};
const vpnTunnelStatusIcons = {
  up: /* @__PURE__ */ jsx(SuccessIcon, {}),
  down: /* @__PURE__ */ jsx(ErrorIcon, {}),
  inactive: /* @__PURE__ */ jsx(OffIcon, {}),
  fail: /* @__PURE__ */ jsx(ErrorIcon, {}),
  standby: /* @__PURE__ */ jsx(WarningIcon, {}),
  "down (passive)": /* @__PURE__ */ jsx(WarningIcon, {}),
  "down (fail)": /* @__PURE__ */ jsx(ErrorIcon, {}),
  "down (standby)": /* @__PURE__ */ jsx(WarningIcon, {}),
  "down (disabled)": /* @__PURE__ */ jsx(OffIcon, {}),
  pending: /* @__PURE__ */ jsx(HelpPendingIcon, {})
};
const dhcpStatusIcons = {
  disabled: /* @__PURE__ */ jsx(OffIcon, {}),
  dhcpService: /* @__PURE__ */ jsx(SuccessIcon, {}),
  dhcpRelay: /* @__PURE__ */ jsx(SuccessIcon, {})
};
const getVpnTunnelStatusIcon = (state) => {
  var _a;
  return get(vpnTunnelStatusIcons, ((_a = state == null ? void 0 : state.toLowerCase) == null ? void 0 : _a.call(state)) || "", vpnTunnelStatusIcons.pending);
};
export {
  AutoIconResized,
  BusinessIconResized,
  ErrorIcon,
  SuccessIcon,
  WarningIcon,
  applianceConfigurationStatusIcons,
  categoryIcons,
  dhcpStatusIcons,
  fallbackIcons,
  getSiteGatewayApplianceStatusIcon,
  getSiteGatewayStatusIcons,
  getVpnTunnelStatusIcon,
  hotfixUpdateStatusIcons,
  ipsIcons,
  logsIconMap,
  logsTrafficStatesIconMap,
  priorityIcons,
  scanIcons,
  settingsIcons,
  siteGatewayApplianceStatusIcons,
  siteGatewayStatusIcons,
  sslExplicitIcons,
  trafficTypeIcons,
  vpnTunnelStatusIcons
};
