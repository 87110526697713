import { getStatusColorFromTheme } from "../utils/themeUtils";
import { get } from "lodash";
import { useTheme } from "@mui/material";
import { useMemo } from "react";
const useStatisticsCardDetailsAndTotal = (statuses, linkCreator) => {
  const theme = useTheme();
  const [siteStatusDetails, siteStatusTotal] = useMemo(() => [
    statuses.map((status) => ({
      text: status.name,
      value: status.key,
      color: getStatusColorFromTheme(status.key, theme),
      link: linkCreator ? linkCreator(status) : void 0
    })),
    (data) => statuses.reduce((total, status) => total + get(data, status.key, 0), 0)
  ], [statuses, theme]);
  return [siteStatusDetails, siteStatusTotal];
};
export {
  useStatisticsCardDetailsAndTotal
};
