import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { HiddenInput, SelectInput, TextInput } from "@cuda-react/core";
import { useSiteInputValidations } from "../../../../hooks/createEditSiteHooks";
import { get } from "lodash";
const SCWanTableInputForm = ({ rowIndex, wanTypes, wansLength, serial }) => {
  const wirelessTypes = wanTypes.filter((type) => get(type, "key") === "wwan");
  const wiredTypes = wanTypes.filter((type) => get(type, "key") !== "wwan");
  const inputValidations = useSiteInputValidations();
  const forcedWwan = rowIndex === void 0;
  let modeChoices = wanTypes;
  if (forcedWwan) {
    modeChoices = wirelessTypes;
  } else if (wansLength === 2) {
    modeChoices = rowIndex === 1 ? wirelessTypes : wiredTypes;
  }
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "name",
        label: "tesseract.sites.settings.wan.name",
        description: "tesseract.sites.settings.wan.descriptions.name",
        validate: inputValidations.validateName,
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: "mode",
        label: "tesseract.sites.settings.wan.type",
        description: "tesseract.sites.settings.wan.descriptions.type",
        disabled: forcedWwan,
        choices: modeChoices
      }
    ),
    forcedWwan ? /* @__PURE__ */ jsx(
      HiddenInput,
      {
        source: "mode",
        hiddenValue: "wwan"
      }
    ) : null,
    /* @__PURE__ */ jsx(
      HiddenInput,
      {
        source: "port",
        hiddenValue: "wan"
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "address",
        label: "tesseract.sites.settings.wan.address",
        hide: (value, data) => !(data && data.mode === "static"),
        description: "tesseract.sites.settings.wan.descriptions.address",
        validate: inputValidations.validateStaticAddress,
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "netmask",
        label: "tesseract.sites.settings.wan.netmask",
        hide: (value, data) => !(data && data.mode === "static"),
        description: "tesseract.sites.settings.wan.descriptions.netmask",
        validate: inputValidations.validateNetmask,
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "gateway",
        label: "tesseract.sites.settings.wan.gateway",
        description: "tesseract.sites.settings.wan.descriptions.gateway",
        hide: (value, data) => !(data && data.mode === "static"),
        validate: inputValidations.validateIp,
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      HiddenInput,
      {
        source: "pin.serial",
        hiddenValue: serial
      }
    ),
    ",",
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "pin.pin",
        label: "tesseract.sites.settings.wan.scPin",
        hide: (value, data) => !(data && data.mode === "wwan"),
        description: "tesseract.sites.settings.wan.descriptions.pin",
        validate: inputValidations.validatePin
      }
    )
  ] });
};
var SCWanTableInputForm_default = SCWanTableInputForm;
export {
  SCWanTableInputForm_default as default
};
