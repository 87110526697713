import { jsx, jsxs } from "react/jsx-runtime";
import {
  CustomField,
  DateField,
  getArrayDataContent,
  StatusIconField,
  TableAction,
  TablePage,
  TextField,
  useCrudProps,
  useMixpanel
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import { Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
const createdRanges = [
  { key: "1", name: "tesseract.audit.filter.created.days1" },
  { key: "3", name: "tesseract.audit.filter.created.days3" },
  { key: "7", name: "tesseract.audit.filter.created.weeks1" },
  { key: "14", name: "tesseract.audit.filter.created.weeks2" },
  { key: "30", name: "tesseract.audit.filter.created.months1" },
  { key: "60", name: "tesseract.audit.filter.created.months2" },
  { key: "90", name: "tesseract.audit.filter.created.months3" },
  { key: "180", name: "tesseract.audit.filter.created.months6" },
  { key: "270", name: "tesseract.audit.filter.created.months9" },
  { key: "365", name: "tesseract.audit.filter.created.years1" }
];
const useStyles = makeStyles((theme) => ({
  message: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(1)
  }
}));
const AuditLog = () => {
  var _a, _b, _c;
  useMixpanel("Audit");
  const auditStatuses = (_a = useCrudProps(apiResources.auditStatuses, {}, {}, true)[0]) == null ? void 0 : _a.data;
  const auditTypes = (_b = useCrudProps(apiResources.auditTypes, {}, {}, true)[0]) == null ? void 0 : _b.data;
  const auditEvents = (_c = useCrudProps(apiResources.auditEvents, {}, {}, true)[0]) == null ? void 0 : _c.data;
  const [translate] = useTranslation();
  const classes = useStyles();
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      authenticated: true,
      exportResource: { resource: apiResources.auditEntriesExport, filename: "auditEntries" },
      resource: apiResources.auditEntries,
      title: "tesseract.logs.pageTitle",
      subtitle: "tesseract.logs.audit",
      minCellWidth: 140,
      pageMode: "paginate",
      sortable: true,
      actions: /* @__PURE__ */ jsx(TableAction, { left: true, children: /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.message, children: translate("tesseract.logs.auditLog.message") }) }),
      children: [
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            isAudit: true,
            source: "status",
            label: "tesseract.audit.labels.status",
            width: 96,
            filter: "selectarray",
            filterProps: {
              choices: getArrayDataContent(auditStatuses)
            }
          }
        ),
        /* @__PURE__ */ jsx(
          DateField,
          {
            source: "created",
            label: "tesseract.audit.labels.created",
            dateOnly: false,
            width: 160,
            filter: "select",
            filterProps: {
              choices: createdRanges
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "type",
            label: "tesseract.audit.labels.type",
            render: (value, record) => record.typeName,
            width: 160,
            filter: "selectarray",
            filterProps: {
              choices: getArrayDataContent(auditTypes)
            }
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "event",
            label: "tesseract.audit.labels.event",
            render: (value, record) => record.eventName,
            width: 160,
            filter: "selectarray",
            filterProps: {
              choices: getArrayDataContent(auditEvents)
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "serial",
            label: "tesseract.audit.labels.serial",
            width: 150,
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.audit.labels.description",
            sortable: false
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "remoteAddress",
            label: "tesseract.audit.labels.remoteAddress",
            width: 142,
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "createdUsername",
            label: "tesseract.audit.labels.createdUsername",
            width: 200,
            filter: "text"
          }
        )
      ]
    }
  );
};
var AuditLog_default = AuditLog;
export {
  createdRanges,
  AuditLog_default as default
};
