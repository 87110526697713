import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const LiveLogOneWayActiveIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-graphic"),
      id: "cuda-icon-access-live-log-one-way-active",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.neutral) == null ? void 0 : _a.main, strokeWidth: 0 },
          d: "M37.4,47.4l-3.5-3.5,11.4-11.4H5.2v-5h40.1l-11.4-11.4,3.5-3.5,17.4,17.4-17.4,17.4Z"
        }
      )
    }
  );
};
var LiveLogOneWayActiveIcon_default = LiveLogOneWayActiveIcon;
export {
  LiveLogOneWayActiveIcon_default as default
};
