var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { ConnectedForm, DialogBody, Tab } from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import GeneralIpSecTunnelInput from "./GeneralIpSecTunnelInput";
import SourceDestInput from "./SourceDestInput";
import PhasesInput from "./PhasesInput";
import NetworkInput from "./NetworkInput";
import { useGatewaysSites } from "./useGatewaysSites";
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: 730,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));
const EditRule = (props) => {
  const classes = useStyles();
  const { id, onClose, onSuccess } = props;
  const { gateways, sites, validate } = useGatewaysSites();
  return /* @__PURE__ */ jsx(
    DialogBody,
    {
      title: "tesseract.integration.ipSecV2.edit",
      onClose,
      classes,
      form: true,
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          tabbed: true,
          resource: apiResources.ipsecV2,
          onSubmitSuccess: () => {
            onSuccess == null ? void 0 : onSuccess();
            onClose == null ? void 0 : onClose();
          },
          formatRequestData: (data) => {
            var _a, _b, _c, _d, _e, _f;
            return __spreadProps(__spreadValues({}, data), {
              local: __spreadProps(__spreadValues({}, (data == null ? void 0 : data.local) || {}), {
                wan: gateways.some(({ uuid, cloud }) => {
                  var _a2;
                  return uuid === ((_a2 = data == null ? void 0 : data.local) == null ? void 0 : _a2.uuid) && cloud;
                }) ? "" : (_a = data == null ? void 0 : data.local) == null ? void 0 : _a.wan
              }),
              remotes: (data == null ? void 0 : data.bgp) ? [...data.remotes] : [data.remotes[0]],
              dpd: {
                delay: ((_b = data == null ? void 0 : data.dpd) == null ? void 0 : _b.action) === "none" ? "30" : (_c = data == null ? void 0 : data.dpd) == null ? void 0 : _c.delay,
                action: (_d = data == null ? void 0 : data.dpd) == null ? void 0 : _d.action
              },
              phase2: __spreadProps(__spreadValues({}, (data == null ? void 0 : data.phase2) || {}), {
                traffic: ((_e = data == null ? void 0 : data.phase2) == null ? void 0 : _e.trafficEnabled) ? (_f = data == null ? void 0 : data.phase2) == null ? void 0 : _f.traffic : null
              }),
              subnetPairs: (data == null ? void 0 : data.bgp) ? false : data == null ? void 0 : data.subnetPairs,
              universalTrafficSelectors: (data == null ? void 0 : data.bgp) ? true : data == null ? void 0 : data.universalTrafficSelectors
            });
          },
          formatResourceData: (data) => {
            var _a, _b;
            return __spreadProps(__spreadValues({}, data), {
              phase2: __spreadProps(__spreadValues({}, (data == null ? void 0 : data.phase2) || {}), {
                trafficEnabled: !!((_a = data == null ? void 0 : data.phase2) == null ? void 0 : _a.traffic),
                traffic: ((_b = data == null ? void 0 : data.phase2) == null ? void 0 : _b.traffic) || ""
              })
            });
          },
          params: { id },
          onCancel: onClose,
          validate,
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsx(Tab, { label: "tesseract.integration.ipSecV2.general", children: /* @__PURE__ */ jsx(GeneralIpSecTunnelInput, {}) }),
            /* @__PURE__ */ jsx(Tab, { label: "tesseract.integration.ipSecV2.sourceDest", children: /* @__PURE__ */ jsx(SourceDestInput, { gateways, sites }) }),
            /* @__PURE__ */ jsx(Tab, { label: "tesseract.integration.ipSecV2.phases", children: /* @__PURE__ */ jsx(PhasesInput, {}) }),
            /* @__PURE__ */ jsx(Tab, { label: "tesseract.integration.ipSecV2.network", children: /* @__PURE__ */ jsx(NetworkInput, {}) })
          ]
        }
      )
    }
  );
};
EditRule.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  id: PropTypes.number
};
var EditRule_default = EditRule;
export {
  EditRule,
  EditRule_default as default,
  useStyles
};
