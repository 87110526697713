import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { CardSizeOptions, CardSizes } from "../../../typesAndConstants";
import { useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Divider, Grid, Typography } from "@barracuda-internal/bds-core";
import BaseCard from "../../../components/baseCards/BaseCard";
import StatisticsOverview from "../../statistics/StatisticsOverview";
import StatisticsDetail from "../../statistics/StatisticsDetail";
import { colorFunction, getStatusColorByIndex } from "../../../utils";
import apiResources from "../../../apiResources";
import generatePreviewData from "../../../utils/previewDataGenerators/ztnaAgentOperatingSystems";
import { useCardFetch } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { BarGrid } from "../../statistics/BarGrid";
import classNames from "classnames";
const styles = (theme) => createStyles({
  dividerCards: {
    width: "95%"
  },
  cardContent: {
    height: "calc(100% - 50px)",
    overflowY: "hidden"
  },
  titleBar: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.reporting.fontColor,
    padding: "16px 16px 8px"
  },
  barGrid: {
    maxHeight: 300,
    minHeight: 300,
    overflowY: "scroll"
  },
  noDataContainer: {
    height: ({ size }) => `calc(${CardSizeOptions[size].height}px - ${theme.spacing(50)})`,
    width: "100%"
  }
});
const useStyles = makeStyles(styles);
const ZTNAAgentInUseCard = (props) => {
  const { preview, size } = props;
  const [translate] = useTranslation();
  const { data = {} } = useCardFetch({ url: !preview ? apiResources.agentOperatingSystems : void 0 });
  const { total, operatingSystems } = (preview ? generatePreviewData() : data) || {};
  const totalValue = total ? total : 0;
  const classes = useStyles(props);
  const theme = useTheme();
  const operatingSystemsKeys = (operatingSystems ? Object.keys(operatingSystems) : []).sort((osA, osB) => operatingSystems[osA] - operatingSystems[osB]).reverse();
  const colorsOsObj = {};
  const highestValue = operatingSystems && operatingSystems[operatingSystemsKeys[0]];
  const entries = operatingSystemsKeys.map((osKey, index) => {
    let osColor;
    if (operatingSystems[osKey] in colorsOsObj) {
      osColor = colorsOsObj[operatingSystems[osKey]];
    } else {
      osColor = colorFunction(100 / highestValue * operatingSystems[osKey], theme);
      colorsOsObj[operatingSystems[osKey]] = osColor;
    }
    return {
      title: osKey,
      value: translate("stratosReporting.ZTNAAgentInUse.devices", { value: operatingSystems[osKey], context: operatingSystems[osKey] === 1 ? void 0 : "plural" }),
      count: operatingSystems[osKey],
      barProgress: 100 / highestValue * operatingSystems[osKey],
      color: osColor,
      statusColor: getStatusColorByIndex(index, theme)
    };
  });
  const overviewData = entries.slice(0, size === "small" ? 3 : 5);
  return /* @__PURE__ */ jsxs(BaseCard, { title: "stratosReporting.ZTNAAgentInUse.title", size, preview, classes: { cardContent: classes.cardContent }, children: [
    /* @__PURE__ */ jsx(
      StatisticsOverview,
      {
        overviewTitle: "stratosReporting.ZTNAAgentInUse.total",
        overviewValue: totalValue,
        children: Array.isArray(overviewData) && overviewData.map((data2) => /* @__PURE__ */ jsx(
          StatisticsDetail,
          {
            title: data2.title,
            value: data2 == null ? void 0 : data2.count,
            color: data2 == null ? void 0 : data2.statusColor
          },
          data2.title
        ))
      }
    ),
    size === CardSizes.large && /* @__PURE__ */ jsxs(Fragment, { children: [
      /* @__PURE__ */ jsx(Grid, { container: true, direction: "column", alignItems: "center", children: /* @__PURE__ */ jsx(Divider, { className: classes.dividerCards }) }),
      /* @__PURE__ */ jsx(Typography, { className: classes.titleBar, children: translate("stratosReporting.ZTNAAgentInUse.tableName") }),
      /* @__PURE__ */ jsx(
        BarGrid,
        {
          size: CardSizes.large,
          entries,
          className: classNames(classes.barGrid, classes.noDataContainer)
        }
      )
    ] })
  ] });
};
export {
  ZTNAAgentInUseCard
};
