import { jsx } from "react/jsx-runtime";
import { ButtonDialog } from "@cuda-react/core";
import { Edit } from "@barracuda-internal/bds-core/dist/Icons/Core";
import CustomAppContent from "./CustomAppContent";
const EditCustomAppDialog = ({ id, data, onSuccess }) => {
  const appId = data && typeof id === "function" ? id(data) : id;
  return /* @__PURE__ */ jsx(ButtonDialog, { buttonIcon: /* @__PURE__ */ jsx(Edit, { id: "edit-rule-icon" }), children: /* @__PURE__ */ jsx(
    CustomAppContent,
    {
      title: "tesseract.customApps.dialogs.edit.dialogTitle",
      id: appId,
      onSuccess
    }
  ) });
};
var EditCustomAppDialog_default = EditCustomAppDialog;
export {
  EditCustomAppDialog_default as default
};
