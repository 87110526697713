var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { ResourceStatisticsCard, useFetchStatisticsCardDetailsAndTotal } from "@stratos/reporting";
import apiResources from "../../../../apiResources";
import { getStatusColorFromTheme } from "../../../../utils/themeUtils";
import { useTheme } from "@mui/material";
const previewData = (theme) => [
  [
    {
      "title": "tesseract.dashboard.cards.status.applianceConfiguration.completed",
      "value": 10,
      "color": getStatusColorFromTheme("completed", theme)
    },
    {
      "title": "tesseract.dashboard.cards.status.applianceConfiguration.connecting",
      "value": 10,
      "color": getStatusColorFromTheme("connecting", theme)
    },
    {
      "title": "tesseract.dashboard.cards.status.applianceConfiguration.failed",
      "value": 3,
      "color": getStatusColorFromTheme("failed", theme)
    },
    {
      "title": "tesseract.dashboard.cards.status.applianceConfiguration.pending",
      "value": 3,
      "color": getStatusColorFromTheme("pending", theme)
    }
  ],
  26
];
const ApplianceStatusCard = (props) => {
  const theme = useTheme();
  const { preview } = props;
  const apiResourceData = useFetchStatisticsCardDetailsAndTotal(
    { url: preview ? void 0 : apiResources.applianceConfiguration },
    { url: apiResources.applianceStates },
    {
      getKeyColor: (key) => getStatusColorFromTheme(key, theme)
    }
  );
  const [details, total] = preview ? previewData(theme) : apiResourceData;
  return /* @__PURE__ */ jsx(
    ResourceStatisticsCard,
    __spreadProps(__spreadValues({}, props), {
      overViewData: details,
      overViewValue: total,
      overviewTitle: "tesseract.dashboard.cards.status.applianceConfiguration.tabTitle"
    })
  );
};
var ApplianceStatusCard_default = ApplianceStatusCard;
export {
  ApplianceStatusCard_default as default
};
