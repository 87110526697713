import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const OverrideIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-override",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.error) == null ? void 0 : _a.main, strokeWidth: 0 },
          d: "M30,5.5c-13.5,0-24.5,11-24.5,24.5s11,24.5,24.5,24.5,24.5-11,24.5-24.5S43.5,5.5,30,5.5ZM41,36.6l-3.5,3.5-7.1-7.1-7.1,7.1-3.5-3.5,7.1-7.1-7.1-7.1,3.5-3.5,7.1,7.1,7.1-7.1,3.5,3.5-7.1,7.1,7.1,7.1Z"
        }
      )
    }
  );
};
var OverrideIcon_default = OverrideIcon;
export {
  OverrideIcon,
  OverrideIcon_default as default
};
