var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import {
  CrudTypes,
  getArrayDataContent,
  LinkField,
  LocationsCardContent,
  TextField,
  useCrudSubscription
} from "@cuda-react/core";
import { BaseCard, CardSizeOptions, CardSizes } from "@stratos/reporting";
import apiResources from "../../../../apiResources";
import ActiveTunnelsTable from "./ActiveTunnelsTable/ActiveTunnelsTable";
import { useGatewayDetails } from "./useGatewayDetails";
import demoData from "./demoData";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  cardContent: {
    height: "calc(100% - 52px)",
    overflowY: "hidden",
    "& .in-highchart": {
      height: `calc(${CardSizeOptions.small.height}px - ${theme.spacing(6)})`,
      overflow: "show",
      width: "100%"
    }
  },
  title: {
    margin: theme.spacing(1, 1, 1, 2.5),
    fontWeight: "bold",
    color: "#4E4D4D"
  }
}));
const sitesDetailFieldsArray = () => [
  /* @__PURE__ */ jsx(
    LinkField,
    {
      label: "tesseract.dashboard.cards.vpn.locations.sites.fields.name",
      source: "name",
      link: (data) => `/infrastructure/sites/${data.id}`
    },
    "name"
  ),
  /* @__PURE__ */ jsx(
    TextField,
    {
      label: "tesseract.dashboard.cards.vpn.locations.sites.fields.location",
      source: "summary"
    },
    "summary"
  ),
  /* @__PURE__ */ jsx(
    LinkField,
    {
      label: "tesseract.dashboard.cards.vpn.locations.sites.fields.gateway",
      source: "gatewayName",
      link: (data) => `/infrastructure/gateways?filter={"name":"${data.gatewayName}"}`
    },
    "gatewayName"
  ),
  /* @__PURE__ */ jsx(
    TextField,
    {
      label: "tesseract.dashboard.cards.vpn.locations.sites.fields.providers",
      source: "wanNetworks.length"
    },
    "wans"
  )
];
const iotDetailFieldsArray = () => [
  /* @__PURE__ */ jsx(
    LinkField,
    {
      label: "tesseract.dashboard.cards.vpn.locations.iot.fields.name",
      source: "name",
      link: (data) => `/infrastructure/iot/${data.id}`
    },
    "name"
  ),
  /* @__PURE__ */ jsx(
    TextField,
    {
      label: "tesseract.dashboard.cards.vpn.locations.iot.fields.location",
      source: "summary"
    },
    "summary"
  ),
  /* @__PURE__ */ jsx(
    LinkField,
    {
      label: "tesseract.dashboard.cards.vpn.locations.iot.fields.gateway",
      source: "gatewayName",
      link: (data) => `/infrastructure/gateways?filter={"name":"${data.gatewayName}"}`
    },
    "gatewayName"
  ),
  /* @__PURE__ */ jsx(
    TextField,
    {
      label: "tesseract.dashboard.cards.vpn.locations.iot.fields.providers",
      source: "wanNetworks.length"
    },
    "wans"
  )
];
const standaloneSitesFieldsArray = () => [
  /* @__PURE__ */ jsx(
    LinkField,
    {
      label: "tesseract.dashboard.cards.vpn.locations.sites.fields.name",
      source: "name",
      link: (data) => `/sites/${data.id}`
    },
    "name"
  ),
  /* @__PURE__ */ jsx(
    TextField,
    {
      label: "tesseract.dashboard.cards.vpn.locations.sites.fields.location",
      source: "summary"
    },
    "summary"
  ),
  /* @__PURE__ */ jsx(
    TextField,
    {
      label: "tesseract.dashboard.cards.vpn.locations.sites.fields.providers",
      source: "wanNetworks.length"
    },
    "wans"
  )
];
const ActiveTunnelsCard = (props) => {
  var _a;
  const classes = useStyles(props);
  const theme = useTheme();
  const gatewayLocations = getArrayDataContent(useCrudSubscription(
    CrudTypes.GET,
    props.preview ? void 0 : apiResources.VPNTunnelLocations
  )[0].data).map((gateway) => __spreadProps(__spreadValues({}, gateway), {
    tseries: gateway.sites.filter((site) => (site == null ? void 0 : site.modelSeries) === "T"),
    iot: gateway.sites.filter((site) => (site == null ? void 0 : site.modelSeries) === "S")
  }));
  const standaloneSitesAllLocations = getArrayDataContent((_a = useCrudSubscription(
    CrudTypes.GET,
    props.preview ? void 0 : apiResources.standaloneSitesAllLocations
  )[0]) == null ? void 0 : _a.data);
  return /* @__PURE__ */ jsxs(
    BaseCard,
    __spreadProps(__spreadValues({
      id: "VPNStatusCard",
      title: "tesseract.dashboard.cards.vpn.locations.tabTitle",
      classes: { cardContent: classes.cardContent }
    }, props), {
      children: [
        /* @__PURE__ */ jsx(
          LocationsCardContent,
          {
            data: props.preview ? demoData : gatewayLocations,
            series: [
              {
                label: "tesseract.dashboard.cards.vpn.locations.gateways.label",
                color: theme.palette.general.purple,
                zIndex: 4,
                linkedSeries: {
                  color: theme.palette.general.darkBlue,
                  label: "tesseract.dashboard.cards.vpn.locations.gateways.linkedSites",
                  zIndex: 3,
                  selectedZIndex: 5
                },
                details: {
                  resource: apiResources.gateways,
                  optionValue: "id",
                  fields: useGatewayDetails()
                }
              },
              {
                label: "tesseract.dashboard.cards.vpn.locations.sites.label",
                color: theme.palette.general.blue,
                source: "tseries",
                zIndex: 1,
                linkedSeries: {
                  color: theme.palette.general.magenta,
                  label: "tesseract.dashboard.cards.vpn.locations.sites.linkedHubs",
                  zIndex: 6,
                  selectedZIndex: 2
                },
                details: {
                  resource: apiResources.sites,
                  optionValue: "id",
                  fields: sitesDetailFieldsArray()
                }
              },
              {
                label: "tesseract.dashboard.cards.vpn.locations.iot.label",
                color: theme.palette.general.turquoise,
                source: "iot",
                zIndex: 1,
                linkedSeries: {
                  color: theme.palette.general.magenta,
                  label: "tesseract.dashboard.cards.vpn.locations.iot.linkedHubs",
                  zIndex: 6,
                  selectedZIndex: 2
                },
                details: {
                  resource: apiResources.sites,
                  optionValue: "id",
                  fields: iotDetailFieldsArray()
                }
              },
              {
                label: "tesseract.dashboard.cards.vpn.locations.standaloneSites.label",
                color: theme.palette.general.graphite,
                data: standaloneSitesAllLocations,
                zIndex: 1,
                details: {
                  resource: apiResources.sites,
                  optionValue: "id",
                  fields: standaloneSitesFieldsArray()
                }
              }
            ],
            chartOverrides: {
              chart: {
                height: CardSizeOptions.small.height - 48,
                width: CardSizeOptions[props.size].width - 2,
                spacing: [2, 2, 2, 2]
              },
              legend: {
                floating: true,
                backgroundColor: "rgba(255,255,255,0.4)",
                shadow: {
                  color: "rgba(255,255,255, 0.4)",
                  opacity: 0.2,
                  width: 16
                },
                borderRadius: 4
              }
            }
          }
        ),
        props.size === CardSizes.large && /* @__PURE__ */ jsx(ActiveTunnelsTable, { preview: props.preview })
      ]
    })
  );
};
var ActiveTunnelsCard_default = ActiveTunnelsCard;
export {
  ActiveTunnelsCard,
  ActiveTunnelsCard_default as default
};
