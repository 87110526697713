import { jsx } from "react/jsx-runtime";
import { BaseBarCard } from "../../../baseCards";
import { colorFunction, numberFormatter } from "../../../../utils";
import { useReportingData } from "../../../../contexts";
import { sortBy } from "lodash";
import { NetworkIcon, StratosSupportIcon } from "@cuda-react/icons";
import Flag from "react-world-flags";
import { usei18nCountries } from "../../../../utils/countries";
import { useTheme } from "@mui/material";
const GeoDestinationTopCountries = (props) => {
  const { preview, size } = props;
  const { data, loading, addFilter } = useReportingData("GeoDestinationTopCountries", preview);
  const { geoDestinationDataPoints = [] } = data || {};
  const getCountryNameByCode = usei18nCountries();
  const theme = useTheme();
  const maxValue = Math.max(...geoDestinationDataPoints.map((country) => country.numberOfSessions));
  const entries = sortBy(geoDestinationDataPoints, "numberOfSessions").reverse().map((country) => ({
    title: getCountryNameByCode(country.id),
    value: numberFormatter(country.numberOfSessions),
    barProgress: 100 / maxValue * country.numberOfSessions,
    icon: country.id ? country.id === "ZZ" ? /* @__PURE__ */ jsx(NetworkIcon, {}) : /* @__PURE__ */ jsx(
      Flag,
      {
        code: country.id,
        fallback: /* @__PURE__ */ jsx(StratosSupportIcon, {}),
        style: {
          width: 40,
          height: 20,
          borderRadius: "50%",
          marginRight: 0,
          marginLeft: -6,
          overflow: "hidden",
          objectFit: "cover",
          transform: "scaleX(0.50)",
          flexShrink: 0
        }
      }
    ) : /* @__PURE__ */ jsx(StratosSupportIcon, {}),
    color: colorFunction(100 / maxValue * country.numberOfSessions, theme),
    onClick: addFilter && country.id !== "" ? () => addFilter(country.id, "geoDestinationDataPoints") : void 0,
    tooltipMessage: addFilter && country.id !== "" ? "stratosReporting.filterTooltip" : void 0
  }));
  return /* @__PURE__ */ jsx(
    BaseBarCard,
    {
      entries,
      title: "stratosReporting.geoCard.destinationTopCountries.title",
      size,
      preview,
      loading: loading && !data.geoDestinationDataPoints,
      noData: !loading && !data.geoDestinationDataPoints,
      activeFilter: true
    }
  );
};
export {
  GeoDestinationTopCountries
};
