import { jsx, jsxs } from "react/jsx-runtime";
import { Route, Switch } from "react-router";
import AuditLog from "./auditLog/AuditLog";
import NotificationList from "./notifications/NotificationList";
import Syslog from "./syslog/Syslog";
const LogsAndReporting = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(Route, { path: "/logs/audit", children: /* @__PURE__ */ jsx(AuditLog, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/logs/notifications", children: /* @__PURE__ */ jsx(NotificationList, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/logs/syslog", children: /* @__PURE__ */ jsx(Syslog, {}) })
] });
var LogsAndReporting_default = LogsAndReporting;
export {
  LogsAndReporting,
  LogsAndReporting_default as default
};
