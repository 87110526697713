import { useGlobalParam } from "@cuda-react/core";
var useDemoApi_default = (mainApi, demoApi) => {
  const userData = useGlobalParam("userData")[0];
  if (!userData) {
    return void 0;
  }
  return userData.mode === "demo" ? demoApi : mainApi;
};
export {
  useDemoApi_default as default
};
