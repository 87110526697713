var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { StepIcon as MuiStepIcon } from "@mui/material";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    // "&$active:not($error)": {
    //     color: theme.palette.primary.dark
    // }
  },
  active: {},
  error: {}
});
const useStyles = makeOverrideableStyles("StepIcon", styles);
const StepIcon = (props) => {
  const _a = props, { icon, subStep, classes: _classOverride } = _a, stepIconProps = __objRest(_a, ["icon", "subStep", "classes"]);
  const classes = useStyles(props);
  const iconValue = subStep ? icon - 1 : icon;
  return /* @__PURE__ */ jsx(MuiStepIcon, __spreadProps(__spreadValues({}, stepIconProps), { classes, icon: iconValue > 0 ? iconValue : "" }));
};
var StepIcon_default = StepIcon;
export {
  StepIcon,
  StepIcon_default as default
};
