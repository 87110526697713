import { jsx, jsxs } from "react/jsx-runtime";
import { generateQueryString, LinkField, StatusIconField, TextField } from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import { siteGatewayStatusIcons } from "../../../../../components/iconMapping";
import { StatisticsTable } from "@stratos/reporting";
const ActiveTunnelsTable = ({ preview }) => /* @__PURE__ */ jsxs(
  StatisticsTable,
  {
    tableTitle: "tesseract.dashboard.cards.vpn.offline.tabTitle",
    resource: preview ? void 0 : apiResources.VPNTunnelOffline,
    noDataMessage: "tesseract.dashboard.cards.vpn.offline.noData",
    children: [
      /* @__PURE__ */ jsx(
        StatusIconField,
        {
          source: "gateway.status",
          label: "tesseract.dashboard.cards.vpn.offline.gatewayStatus",
          iconMap: siteGatewayStatusIcons,
          width: 96
        }
      ),
      /* @__PURE__ */ jsx(
        LinkField,
        {
          label: "tesseract.dashboard.cards.vpn.offline.gatewayName",
          source: "gateway.name",
          link: (data) => `/infrastructure/gateways${generateQueryString({
            name: [data.gateway.name],
            connectionStatus: [data.gateway.status]
          })}`
        }
      ),
      /* @__PURE__ */ jsx(
        StatusIconField,
        {
          source: "status",
          label: "tesseract.dashboard.cards.vpn.offline.siteStatus",
          iconMap: siteGatewayStatusIcons,
          width: 96
        }
      ),
      /* @__PURE__ */ jsx(
        LinkField,
        {
          label: "tesseract.dashboard.cards.vpn.offline.siteName",
          source: "name",
          link: (data) => {
            var _a;
            return `/infrastructure/${((_a = data.model) == null ? void 0 : _a.startsWith("F")) ? "iot" : "sites"}/${data.id}`;
          }
        }
      ),
      /* @__PURE__ */ jsx(TextField, { label: "tesseract.dashboard.cards.vpn.offline.siteLocation", source: "summary" })
    ]
  }
);
var ActiveTunnelsTable_default = ActiveTunnelsTable;
export {
  ActiveTunnelsTable,
  ActiveTunnelsTable_default as default
};
