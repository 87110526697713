import { jsx, jsxs } from "react/jsx-runtime";
import { Route, Switch } from "react-router";
import CustomApps from "./customApps/CustomApps";
import Endpoint from "./endpoint/Endpoint";
import Security from "./security/Security";
import Sdwan from "./sdwan/Sdwan";
import NetworkAccess from "./networkAccess/NetworkAccess";
import WebFilter from "./webFilter/WebFilter";
import Settings from "./settings/Settings";
const SecurityAndAccess = () => /* @__PURE__ */ jsxs(Switch, { children: [
  /* @__PURE__ */ jsx(Route, { path: "/policies/apps", children: /* @__PURE__ */ jsx(CustomApps, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/network", children: /* @__PURE__ */ jsx(NetworkAccess, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/sdwan", children: /* @__PURE__ */ jsx(Sdwan, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/webfilter", children: /* @__PURE__ */ jsx(WebFilter, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/security", children: /* @__PURE__ */ jsx(Security, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/ztna", children: /* @__PURE__ */ jsx(Endpoint, {}) }),
  /* @__PURE__ */ jsx(Route, { path: "/policies/settings", children: /* @__PURE__ */ jsx(Settings, {}) })
] });
var SecurityAndAccess_default = SecurityAndAccess;
export {
  SecurityAndAccess,
  SecurityAndAccess_default as default
};
