import { jsx } from "react/jsx-runtime";
import { IntercomProvider } from "react-use-intercom";
import useUpdatesBannerNotification from "./hooks/useUpdatesBannerNotification";
import useSubscriptionsBannerNotification from "./hooks/useSubscriptionsBannerNotification";
import useLicensesBannerNotification from "./hooks/useLicensesBannerNotification";
const GlobalStatusController = () => {
  useUpdatesBannerNotification();
  useSubscriptionsBannerNotification();
  useLicensesBannerNotification();
  const intercomDisabled = document.cookie.includes("DISABLE_INTERCOM=true");
  return intercomDisabled ? null : (
    // @ts-ignore
    /* @__PURE__ */ jsx(
      IntercomProvider,
      {
        appId: "idva4pea",
        autoBoot: true,
        autoBootProps: { verticalPadding: 61 }
      }
    )
  );
};
var GlobalStatusController_default = GlobalStatusController;
export {
  GlobalStatusController_default as default
};
