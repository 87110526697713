var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useTranslation } from "react-i18next";
import { concat, isEqual, omit, without } from "lodash";
import { ButtonDialog, DialogBody } from "../../dialog";
import { Toolbar } from "../../layout";
import { Button, Checkbox, List, ListItem, ListItemIcon, ListItemText } from "@barracuda-internal/bds-core";
import { useDeepCompareEffect } from "../../../hooks";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  listContainer: {
    display: "flex",
    flexDirection: "row"
  },
  selectAllText: {
    fontWeight: theme.typography.fontWeightBold
  }
});
const useStyles = makeOverrideableStyles("ColumnSelectorDialog", styles);
const ColumnSelectorDialogContent = (props) => {
  const { columns, defaultColumns, onClose, onSave } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const [selectedColumnFields, setSelectedColumnFields] = useState([]);
  const selectableColumns = columns.filter((column) => !column.alwaysVisible);
  const someSelected = selectedColumnFields.length > 0 && selectedColumnFields.length < selectableColumns.length;
  const allSelected = !someSelected && selectedColumnFields.length === selectableColumns.length;
  const getFieldsFromColumns = (columnsToTransform) => columnsToTransform.filter((column) => column.show && !column.alwaysVisible).map((column) => column.field);
  useDeepCompareEffect(() => {
    setSelectedColumnFields(getFieldsFromColumns(selectableColumns));
  }, [selectableColumns]);
  const handleReset = () => {
    onSave(defaultColumns);
    onClose == null ? void 0 : onClose();
  };
  const handleSave = () => {
    onSave(columns.map((column) => __spreadProps(__spreadValues({}, column), {
      show: column.alwaysVisible || selectedColumnFields.includes(column.field)
    })));
    onClose == null ? void 0 : onClose();
  };
  const toggleColumn = (column) => {
    setSelectedColumnFields(
      (currentFields) => selectedColumnFields.includes(column.field) ? without(currentFields, column.field) : concat(currentFields, column.field)
    );
  };
  const toggleAll = () => {
    if (!allSelected) {
      setSelectedColumnFields(selectableColumns.map((column) => column.field));
    } else {
      setSelectedColumnFields([]);
    }
  };
  const renderItem = (column) => /* @__PURE__ */ jsxs(
    ListItem,
    {
      onClick: () => toggleColumn(column),
      dense: true,
      button: true,
      children: [
        /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(
          Checkbox,
          {
            edge: "start",
            checked: selectedColumnFields.includes(column.field),
            tabIndex: -1,
            disableRipple: true
          }
        ) }),
        /* @__PURE__ */ jsx(ListItemText, { primary: column.title })
      ]
    },
    column.field
  );
  const simplifyColumn = (col) => omit(col, "alwaysVisible");
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsxs(DialogBody, { title: "cuda.table.columnSelector.dialogTitle", onClose, children: [
      /* @__PURE__ */ jsxs(ListItem, { onClick: toggleAll, dense: true, button: true, children: [
        /* @__PURE__ */ jsx(ListItemIcon, { children: /* @__PURE__ */ jsx(
          Checkbox,
          {
            edge: "start",
            checked: allSelected,
            indeterminate: someSelected,
            disableRipple: true
          }
        ) }),
        /* @__PURE__ */ jsx(ListItemText, { primary: "Select All", primaryTypographyProps: { className: classes.selectAllText } })
      ] }),
      /* @__PURE__ */ jsxs("div", { className: classes.listContainer, children: [
        /* @__PURE__ */ jsx(List, { children: selectableColumns.filter((column, index) => index % 2 === 0).map(renderItem) }),
        /* @__PURE__ */ jsx(List, { children: selectableColumns.filter((column, index) => index % 2 === 1).map(renderItem) })
      ] })
    ] }),
    /* @__PURE__ */ jsxs(Toolbar, { children: [
      /* @__PURE__ */ jsx(
        Button,
        {
          onClick: handleSave,
          disabled: selectedColumnFields.length <= 0,
          variant: "contained",
          color: "primary",
          size: "small",
          children: translate("cuda.buttons.save")
        }
      ),
      /* @__PURE__ */ jsx(
        Button,
        {
          onClick: onClose,
          variant: "contained",
          color: "secondary",
          size: "small",
          children: translate("cuda.buttons.cancel")
        }
      ),
      /* @__PURE__ */ jsx(
        Button,
        {
          onClick: handleReset,
          disabled: isEqual(columns.map(simplifyColumn), defaultColumns.map(simplifyColumn)),
          variant: "contained",
          color: "primary",
          size: "small",
          left: true,
          children: translate("cuda.buttons.reset")
        }
      )
    ] })
  ] });
};
const ColumnSelectorDialog = (props) => /* @__PURE__ */ jsx(ButtonDialog, { buttonText: "cuda.table.columnSelector.buttonLabel", children: /* @__PURE__ */ jsx(ColumnSelectorDialogContent, __spreadValues({}, props)) });
var ColumnSelectorDialog_default = ColumnSelectorDialog;
export {
  ColumnSelectorDialogContent,
  ColumnSelectorDialog_default as default
};
