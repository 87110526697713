import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography } from "@barracuda-internal/bds-core";
import {
  ChipArrayField,
  getArrayDataContent,
  SelectedChoiceField,
  TextField,
  useCrudProps,
  WizardSummary
} from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import apiResources from "../../../../../apiResources";
import { useFormState } from "react-hook-form";
import { SELECT_EMPTY_VALUE } from "../../../../../utils/constants";
const useStyles = makeStyles((theme) => ({
  summaryName: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 200
  },
  summaryChipArray: {
    maxWidth: 220
  },
  submitError: {
    paddingLeft: 0,
    paddingTop: theme.spacing(1)
  }
}));
const renderWanLanName = (type) => (value, data) => {
  if (value) {
    const matchingWanLan = get(data, type + "s", []).findIndex((wanLan) => wanLan.mode === value.mode && wanLan.port === value.port && wanLan.virtualLanId === value.virtualLanId && wanLan.name === value.name);
    if (matchingWanLan >= 0 && matchingWanLan < get(data, type + "Total", 0)) {
      return value.name;
    }
  }
  return [];
};
const SiteSubmitStep = ({ applianceType, data, series }) => {
  var _a, _b, _c, _d;
  const gatewayResource = applianceType === "gateway" ? apiResources.gatewaysOnPremUpstream : apiResources.gatewayNames;
  const gatewaysData = getArrayDataContent((_a = useCrudProps(gatewayResource)[0]) == null ? void 0 : _a.data);
  const gatewayChoices = [
    {
      key: SELECT_EMPTY_VALUE,
      name: "tesseract.appliances.dialog.submit.noGateway"
    },
    ...gatewaysData
  ];
  const timezonesData = getArrayDataContent((_b = useCrudProps(apiResources.siteTimezones)[0]) == null ? void 0 : _b.data);
  const [translate] = useTranslation();
  const classes = useStyles();
  const isSc = series === "S";
  const { errors } = useFormState();
  const lanErrors = (_c = errors == null ? void 0 : errors.lans) == null ? void 0 : _c.message;
  const wanErrors = (_d = errors == null ? void 0 : errors.wans) == null ? void 0 : _d.message;
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.appliances.dialog.submit.message", { context: applianceType }) }),
    /* @__PURE__ */ jsxs(WizardSummary, { columns: [{ xs: 12, sm: 5 }, { xs: 12, sm: 7 }], data, children: [
      /* @__PURE__ */ jsx(
        TextField,
        {
          source: "name",
          label: "tesseract.appliances.dialog.submit.name",
          column: 0,
          className: classes.summaryName
        }
      ),
      /* @__PURE__ */ jsx(
        ChipArrayField,
        {
          source: "serials",
          render: (val) => val && val.serial,
          label: "tesseract.appliances.dialog.submit.serial",
          column: 0,
          classes: { root: classes.summaryChipArray }
        }
      ),
      /* @__PURE__ */ jsx(
        SelectedChoiceField,
        {
          source: applianceType === "gateway" ? "upstream" : "gateway",
          label: applianceType === "gateway" ? "tesseract.appliances.dialog.submit.upstream_gateway" : "tesseract.appliances.dialog.submit.gateway",
          column: 0,
          choices: gatewayChoices
        }
      ),
      !isSc ? /* @__PURE__ */ jsx(
        SelectedChoiceField,
        {
          source: "timezone",
          label: "tesseract.appliances.dialog.submit.timezone",
          column: 0,
          choices: timezonesData
        }
      ) : null,
      /* @__PURE__ */ jsx(
        ChipArrayField,
        {
          source: "wans",
          render: renderWanLanName("wan"),
          label: "tesseract.appliances.dialog.submit.wan",
          column: 1,
          classes: { root: classes.summaryChipArray }
        }
      ),
      /* @__PURE__ */ jsx(
        ChipArrayField,
        {
          source: "lans",
          render: renderWanLanName("lan"),
          label: "tesseract.appliances.dialog.submit.lan",
          column: 1,
          classes: { root: classes.summaryChipArray }
        }
      ),
      /* @__PURE__ */ jsx(TextField, { source: "primaryDns", label: "tesseract.appliances.dialog.submit.primaryDns", column: 1 }),
      /* @__PURE__ */ jsx(TextField, { source: "secondaryDns", label: "tesseract.appliances.dialog.submit.secondaryDns", column: 1 })
    ] }),
    lanErrors ? /* @__PURE__ */ jsx(Typography, { color: "error", children: lanErrors }) : null,
    wanErrors ? /* @__PURE__ */ jsx(Typography, { color: "error", children: wanErrors }) : null
  ] });
};
var SiteSubmitStep_default = SiteSubmitStep;
export {
  SiteSubmitStep_default as default
};
