var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { Button, IconButton, ListItemIcon, ListItemText, Tooltip } from "@barracuda-internal/bds-core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { ConditionalWrapper } from "../../functional";
import { createStyles } from "@mui/styles";
import { ExpandMore, Settings } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
const styles = (theme) => createStyles({
  button: {},
  menuEntry: {},
  labelWithIcon: {
    paddingLeft: theme.spacing(1)
  },
  tooltip: __spreadValues({}, theme.typography.body2)
});
const useStyles = makeOverrideableStyles("MenuButton", styles);
const MenuButton = (props) => {
  const _a = props, { disabled, label, menuItems, icon, anchorOrigin, transformOrigin, iconButton, dashboardMenu } = _a, buttonProps = __objRest(_a, ["disabled", "label", "menuItems", "icon", "anchorOrigin", "transformOrigin", "iconButton", "dashboardMenu"]);
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    iconButton ? /* @__PURE__ */ jsx(
      IconButton,
      __spreadProps(__spreadValues({
        size: "small",
        disabled,
        onClick: (event) => setAnchorEl(event.currentTarget)
      }, buttonProps), {
        children: !dashboardMenu ? /* @__PURE__ */ jsx(MoreVertIcon, { id: "cuda-icon-menu" }) : /* @__PURE__ */ jsxs(React.Fragment, { children: [
          /* @__PURE__ */ jsx(ExpandMore, {}),
          /* @__PURE__ */ jsx(Settings, {})
        ] })
      })
    ) : /* @__PURE__ */ jsx(
      Button,
      __spreadProps(__spreadValues({
        className: classes.button,
        disabled,
        variant: "contained",
        color: "primary",
        size: "small",
        onClick: (event) => setAnchorEl(event.currentTarget),
        startIcon: icon
      }, buttonProps), {
        children: label ? translate(label) : null
      })
    ),
    /* @__PURE__ */ jsx(
      Menu,
      {
        anchorEl,
        open: Boolean(anchorEl),
        onClose: () => setAnchorEl(null),
        anchorOrigin,
        transformOrigin,
        children: menuItems && menuItems.map((menuItem, index) => /* @__PURE__ */ jsx("div", { children: /* @__PURE__ */ jsx(
          ConditionalWrapper,
          {
            condition: menuItem.disabledTooltipText && menuItem.disabled,
            wrapper: (children) => /* @__PURE__ */ jsx(
              Tooltip,
              {
                title: translate(menuItem.disabledTooltipText),
                placement: "left",
                classes: { tooltip: classes.tooltip },
                children
              }
            ),
            children: /* @__PURE__ */ jsxs(
              MenuItem,
              {
                onClick: () => {
                  if (menuItem.disabled) {
                    return;
                  }
                  setAnchorEl(null);
                  menuItem.onClick && menuItem.onClick();
                },
                className: classes.menuEntry,
                selected: menuItem.selected,
                disabled: menuItem.disabled,
                children: [
                  menuItem.icon ? /* @__PURE__ */ jsx(ListItemIcon, { children: menuItem.icon }) : null,
                  /* @__PURE__ */ jsx(
                    ListItemText,
                    {
                      className: menuItem.icon && classes.labelWithIcon || void 0,
                      primary: translate(menuItem.label),
                      secondary: translate(menuItem.subLabel || "")
                    }
                  )
                ]
              }
            )
          },
          menuItem.label + index
        ) }, menuItem.label + index)) || null
      }
    )
  ] });
};
MenuButton.propTypes = {};
MenuButton.defaultProps = {
  anchorOrigin: {
    horizontal: "left",
    vertical: "bottom"
  },
  transformOrigin: {
    horizontal: "left",
    vertical: "top"
  }
};
var MenuButton_default = MenuButton;
export {
  MenuButton,
  MenuButton_default as default,
  styles
};
