var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { getArrayDataContent, getDataContent } from "../utils";
import { get } from "lodash";
import { authChangeAccount } from "../actions";
import { useDispatch } from "react-redux";
import { useCrudFetch } from "./CrudHooks";
import { useGlobalParam } from "./GlobalParamsHooks";
import { CrudTypes } from "../clients";
import { useEffect } from "react";
const sortByName = (itemA, itemB) => {
  var _a, _b;
  const itemAName = ((_a = itemA == null ? void 0 : itemA.name) == null ? void 0 : _a.toLowerCase()) || "";
  const itemBName = ((_b = itemB == null ? void 0 : itemB.name) == null ? void 0 : _b.toLowerCase()) || "";
  if (itemBName > itemAName) {
    return -1;
  }
  return itemAName > itemBName ? 1 : 0;
};
const useAccounts = (listAccountsResource, userDataResource, updateInPlace) => {
  const [currentAccountId] = useGlobalParam("userData.currentAccount");
  const [userDataResponse, , fetchUserData] = useCrudFetch(CrudTypes.GET, userDataResource);
  const userData = getDataContent(userDataResponse);
  const [accountListResponse, , fetchAccountList] = useCrudFetch(CrudTypes.GET, listAccountsResource);
  const accountListData = getArrayDataContent(accountListResponse);
  const availableAccounts = (accountListData.length ? accountListData : (userData == null ? void 0 : userData.allAccounts) || []).map((account) => __spreadProps(__spreadValues({}, account), {
    virtualWans: (account == null ? void 0 : account.virtualWans) && account.virtualWans.sort(sortByName)
  })).sort(sortByName);
  const dispatch = useDispatch();
  const currentAccount = availableAccounts.find((account) => account.id === currentAccountId) || get(userData, "currentAccount", {});
  const swapAccount = (account) => dispatch(authChangeAccount(account.id, updateInPlace));
  const defaultAccount = get(userData, "defaultAccount", {});
  const refreshAccounts = () => {
    fetchAccountList();
    fetchUserData();
  };
  useEffect(() => {
    refreshAccounts();
  }, [currentAccountId]);
  return [availableAccounts, currentAccount, defaultAccount, swapAccount, refreshAccounts];
};
export {
  useAccounts
};
