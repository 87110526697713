import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import {
  Card,
  ChipArrayField,
  CustomField,
  DateField,
  getArrayDataContent,
  LinkField,
  ListCardContent,
  SelectedChoiceField,
  StatusIconField,
  TableCardContent,
  TextField,
  useCrudProps,
  useGlobalParam
} from "@cuda-react/core";
import { useTranslation } from "react-i18next";
import { StatusIcon } from "@cuda-react/icons";
import { makeStyles } from "@mui/styles";
import { getAzureHubLink, getAzureResourceLink } from "../../../../utils/azureUtils";
import apiResources from "../../../../apiResources";
import { siteGatewayStatusIcons } from "../../../../components/iconMapping";
const useStyles = makeStyles((theme) => ({
  serialIconSpan: {
    lineHeight: 3,
    marginLeft: -7,
    "& svg": {
      position: "relative",
      top: 7,
      marginRight: theme.spacing(1)
    }
  }
}));
const GatewaySummaryCard = ({ details }) => {
  var _a, _b, _c;
  const classes = useStyles();
  const [translate] = useTranslation();
  const isDemo = useGlobalParam("userData.mode")[0] === "demo";
  const regionChoices = getArrayDataContent((_a = useCrudProps(apiResources.gatewayRegions)[0]) == null ? void 0 : _a.data);
  const isOnPremGateway = details.gatewayType === "on-prem";
  const isManagedGateway = details.gatewayType === "managed";
  const licenseStateChoices = getArrayDataContent((_b = useCrudProps(isOnPremGateway ? apiResources.licensesStates : void 0)[0]) == null ? void 0 : _b.data);
  const licenseTypeChoices = getArrayDataContent((_c = useCrudProps(isOnPremGateway ? apiResources.licensesTypes : void 0)[0]) == null ? void 0 : _c.data);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      Card,
      {
        id: "GatewaySummaryCard",
        title: "tesseract.appliances.dashboard.summary.status.tabTitle",
        children: /* @__PURE__ */ jsxs(ListCardContent, { data: details, children: [
          isOnPremGateway ? /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dashboard.summary.siteStatus.model", source: "model" }) : null,
          /* @__PURE__ */ jsx(
            LinkField,
            {
              source: "hubName",
              label: "tesseract.appliances.dashboard.summary.status.hub",
              link: getAzureHubLink("hubId", isDemo),
              filter: "text",
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            LinkField,
            {
              source: "cloudVirtualWan.name",
              label: "tesseract.appliances.dashboard.summary.status.vwan",
              link: getAzureResourceLink("cloudVirtualWan.resourceId", isDemo),
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            SelectedChoiceField,
            {
              source: "region",
              label: "tesseract.appliances.dashboard.summary.status.region",
              choices: getArrayDataContent(regionChoices),
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              label: isOnPremGateway ? "tesseract.appliances.dashboard.summary.gateway.serial" : isManagedGateway ? "tesseract.appliances.dashboard.summary.gateway.publicIP" : "tesseract.appliances.dashboard.summary.gateway.publicIPs",
              source: "connectionStatus.connectionStates",
              render: (val) => /* @__PURE__ */ jsxs("span", { className: classes.serialIconSpan, children: [
                /* @__PURE__ */ jsx(
                  StatusIcon,
                  {
                    iconMap: siteGatewayStatusIcons,
                    status: val.state
                  }
                ),
                val.id || translate("tesseract.appliances.dashboard.summary.gateway.instanceNotFound")
              ] })
            }
          )
        ] })
      }
    ),
    isOnPremGateway && /* @__PURE__ */ jsx(
      Card,
      {
        id: "GatewayLicencesCard",
        title: "tesseract.appliances.dashboard.summary.licenses.title",
        children: /* @__PURE__ */ jsxs(
          TableCardContent,
          {
            resource: details.id ? apiResources.licensesGateways : void 0,
            params: { id: details.id },
            formatData: (data) => (data == null ? void 0 : data.licenseStates) || [],
            minCellWidth: 64,
            children: [
              /* @__PURE__ */ jsx(
                StatusIconField,
                {
                  label: "tesseract.appliances.dashboard.summary.licenses.status",
                  source: "state"
                }
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  label: "tesseract.appliances.dashboard.summary.licenses.serial",
                  source: "instanceId"
                }
              ),
              /* @__PURE__ */ jsx(
                SelectedChoiceField,
                {
                  label: "tesseract.appliances.dashboard.summary.licenses.state",
                  source: "state",
                  choices: licenseStateChoices
                },
                "textState"
              ),
              /* @__PURE__ */ jsx(
                SelectedChoiceField,
                {
                  source: "type",
                  label: "tesseract.appliances.dashboard.summary.licenses.type",
                  choices: licenseTypeChoices
                }
              ),
              /* @__PURE__ */ jsx(
                CustomField,
                {
                  source: "expiryDate",
                  label: "tesseract.appliances.dashboard.summary.licenses.expirationDate",
                  render: (expiryDate, data) => !(data.type === "payg" && data.state === "ok") ? /* @__PURE__ */ jsx(DateField, { data, source: "expiryDate" }) : /* @__PURE__ */ jsx(
                    TextField,
                    {
                      source: "data",
                      data: { data: translate("tesseract.appliances.dashboard.summary.licenses.noData") }
                    }
                  )
                }
              )
            ]
          }
        )
      }
    )
  ] });
};
var GatewaySummaryCard_default = GatewaySummaryCard;
export {
  GatewaySummaryCard_default as default
};
