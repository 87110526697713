import { jsx, jsxs } from "react/jsx-runtime";
import { Children } from "react";
import { makeStyles } from "@mui/styles";
import { Divider, Grid } from "@barracuda-internal/bds-core";
import { CardSizeOptions } from "../../../typesAndConstants";
const useStyles = makeStyles((theme) => ({
  divider: {
    height: CardSizeOptions.medium.height - 68,
    margin: (CardSizeOptions.medium.width - 2 * CardSizeOptions.small.width) / 2
  },
  content: {
    flexGrow: 1,
    width: CardSizeOptions.small.width
  }
}));
const DualContainer = (props) => {
  const { children } = props;
  const classes = useStyles(props);
  const childArray = Children.toArray(children);
  return /* @__PURE__ */ jsxs(Grid, { container: true, wrap: "nowrap", justifyContent: "center", children: [
    /* @__PURE__ */ jsx(Grid, { item: true, className: classes.content, children: childArray[0] }),
    /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(Divider, { orientation: "vertical", variant: "middle", className: classes.divider }) }),
    /* @__PURE__ */ jsx(Grid, { item: true, className: classes.content, children: childArray[1] })
  ] });
};
var DualContainer_default = DualContainer;
export {
  DualContainer,
  DualContainer_default as default
};
