var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { createStyles, makeStyles } from "@mui/styles";
import { Grid, Tooltip, Typography } from "@barracuda-internal/bds-core";
import { OverviewCardRow } from "../OverviewCardRow";
import { CardSizeOptions, CardSizes } from "../../../typesAndConstants";
import { BorderLinearProgress } from "../BorderLinearProgress";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { WidgetInfoIcon } from "@cuda-react/icons";
const styles = (theme) => createStyles({
  caption: {
    fontSize: 14,
    fontWeight: 300
  },
  container: {
    maxHeight: ({ size }) => `calc(${CardSizeOptions[size].height}px - 68px)`,
    minHeight: ({ size }) => `calc(${CardSizeOptions[size].height}px - 68px)`,
    padding: theme.spacing(1, 1, 1)
  },
  contentColumn: {
    width: "100%",
    maxWidth: "100%"
  },
  value: {
    width: ({ size }) => size === CardSizes.small ? 60 : 110,
    paddingRight: theme.spacing(1),
    textAlign: "right",
    "& > *": {
      fontSize: 14,
      fontWeight: "lighter",
      color: theme.palette.reporting.fontColor
    }
  },
  bar: {
    flexGrow: 1
  },
  row: {
    padding: theme.spacing(0, 1),
    margin: theme.spacing(0.5, 0)
  },
  rowClick: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(217, 217, 217, 0.5)",
      // #D9D9D9 at 0.5 opacity
      borderRadius: theme.spacing(1)
    }
  },
  title: {
    minWidth: ({ size }) => size === CardSizes.small ? 210 : 350,
    maxWidth: ({ size }) => size === CardSizes.small ? 210 : 350,
    color: theme.palette.reporting.fontColor,
    "&> p": {
      fontSize: 14
    }
  },
  noDataContainer: {
    height: ({ size }) => `calc(${CardSizeOptions[size].height}px - 56px)`,
    width: "100%"
  },
  noDataIcon: {
    width: 64,
    height: 64
  },
  noDataMessage: {
    textAlign: "center",
    color: theme.palette.reporting.fontColor
  }
});
const useStyles = makeStyles(styles);
const BarGrid = (props) => {
  const { size, entries, className, loading } = props;
  const [translate] = useTranslation();
  const classes = useStyles(props);
  return !loading && entries.length === 0 ? /* @__PURE__ */ jsxs(Grid, { container: true, justifyContent: "center", alignContent: "center", className: classNames(classes.noDataContainer, className), children: [
    /* @__PURE__ */ jsx(Grid, { xs: 12, style: { textAlign: "center" }, children: /* @__PURE__ */ jsx(WidgetInfoIcon, { className: classes.noDataIcon }) }),
    /* @__PURE__ */ jsx(Grid, { xs: 12, children: /* @__PURE__ */ jsx(Typography, { variant: "h6", className: classes.noDataMessage, children: translate("stratosReporting.barGrid.noData") }) })
  ] }) : /* @__PURE__ */ jsxs(Grid, { container: true, className: classNames(classes.container, className), alignContent: "flex-start", children: [
    loading && new Array(size === CardSizes.large ? 12 : 5).fill(null).map((empty, index) => /* @__PURE__ */ jsx(
      Grid,
      {
        item: true,
        xs: 12,
        container: true,
        wrap: "nowrap",
        alignItems: "center",
        className: classes.row,
        children: /* @__PURE__ */ jsx(
          OverviewCardRow,
          {
            title: "",
            size,
            classes: { titleColumn: classes.title },
            noContainer: true,
            noContentContainer: true,
            loading: true
          }
        )
      },
      index
    )),
    !loading && entries.length > 0 && entries.slice(0, size === CardSizes.large ? entries.length : 5).map((entry, key) => /* @__PURE__ */ jsx(
      Tooltip,
      {
        title: entry.tooltipMessage ? translate(entry.tooltipMessage) : void 0,
        placement: "top",
        arrow: true,
        disableInteractive: true,
        sx: (theme) => __spreadValues({}, theme.typography.body2),
        children: /* @__PURE__ */ jsx(
          Grid,
          {
            item: true,
            xs: 12,
            container: true,
            wrap: "nowrap",
            alignItems: "center",
            className: classes.row + (entry.onClick ? " " + classes.rowClick : ""),
            onClick: entry.onClick,
            children: /* @__PURE__ */ jsx(
              OverviewCardRow,
              {
                size,
                title: entry.title,
                icon: entry.icon,
                classes: { titleColumn: classes.title },
                onClick: entry.onClick,
                noContainer: true,
                noContentContainer: true,
                children: /* @__PURE__ */ jsxs(Grid, { container: true, alignItems: "center", wrap: "nowrap", justifyContent: "flex-end", spacing: 1, children: [
                  /* @__PURE__ */ jsx(Grid, { item: true, className: classes.value, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", noWrap: true, children: entry.value }) }),
                  size === CardSizes.small || !entry.smallText ? null : /* @__PURE__ */ jsx(Grid, { item: true, children: /* @__PURE__ */ jsx(Typography, { variant: "caption", children: entry.smallText ? `(${entry.smallText})` : "" }) }),
                  entry.barProgress !== void 0 ? /* @__PURE__ */ jsx(Grid, { item: true, className: classes.bar, children: /* @__PURE__ */ jsx(
                    BorderLinearProgress,
                    {
                      variant: "determinate",
                      barColor: (entry == null ? void 0 : entry.color) || "#1a90ff",
                      value: entry.barProgress
                    }
                  ) }) : null
                ] })
              }
            )
          }
        )
      },
      key
    ))
  ] });
};
export {
  BarGrid
};
