import { jsx, jsxs } from "react/jsx-runtime";
import { ButtonDialog, Tabs } from "@cuda-react/core";
import AddDashboardDialog from "../AddDashboardDialog";
import RenameDashboardDialog from "../RenameDashboardDialog";
import ResetDashboardDialog from "../ResetDashboardDialog";
import { createStyles, makeStyles } from "@mui/styles";
import { ExpandMore } from "@mui/icons-material";
import DeleteDialogContent from "@cuda-react/core/lib/components/dialog/DeleteDialogContent/DeleteDialogContent";
const styles = (theme) => createStyles({
  container: {
    width: "100%"
  },
  optionsMenuButton: {
    marginLeft: theme.spacing(1)
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  singleDashboardActions: {
    display: "flex",
    width: "100%",
    justifyContent: "right"
  }
});
const useStyles = makeStyles(styles);
const DashboardMenu = (props) => {
  const {
    children,
    onChange,
    activeDashboard,
    addUpdateDashboard,
    deleteDashboard,
    templates,
    disabled,
    maxDashboardsReached,
    maxDashboardsReachedMessage
  } = props;
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx("div", { className: classes.container, children: /* @__PURE__ */ jsx(
    Tabs,
    {
      onChange,
      activeTab: activeDashboard == null ? void 0 : activeDashboard.name,
      actions: [
        /* @__PURE__ */ jsxs(
          ButtonDialog,
          {
            buttonEndIcon: /* @__PURE__ */ jsx(ExpandMore, {}),
            buttonProps: {
              className: classes.optionsMenuButton,
              dashboardMenu: true,
              iconButton: false,
              color: "secondary",
              id: "open-dashboard-menu"
            },
            buttonText: "stratosReporting.dashboardPage.options",
            disabled,
            useMenu: true,
            children: [
              /* @__PURE__ */ jsx(
                DeleteDialogContent,
                {
                  title: "stratosReporting.dashboardPage.delete",
                  message: "stratosReporting.dashboardPage.confirmDeletion",
                  optionText: "name",
                  label: "stratosReporting.dashboardPage.delete",
                  data: activeDashboard,
                  onDelete: () => deleteDashboard(activeDashboard)
                }
              ),
              /* @__PURE__ */ jsx(
                RenameDashboardDialog,
                {
                  label: "stratosReporting.dashboardPage.rename",
                  dashboard: activeDashboard,
                  addUpdateDashboard
                }
              ),
              /* @__PURE__ */ jsx(
                ResetDashboardDialog,
                {
                  label: "stratosReporting.dashboardPage.reset",
                  dashboard: activeDashboard,
                  addUpdateDashboard,
                  templates
                }
              )
            ]
          },
          "menu"
        ),
        /* @__PURE__ */ jsx(
          ButtonDialog,
          {
            buttonText: "stratosReporting.dashboardPage.add",
            disabled: disabled || maxDashboardsReached,
            disabledMessage: maxDashboardsReachedMessage,
            buttonClasses: { button: classes.addButton },
            children: /* @__PURE__ */ jsx(AddDashboardDialog, { addUpdateDashboard, templates })
          },
          "add"
        )
      ],
      condensedMenu: true,
      children
    }
  ) });
};
var DashboardMenu_default = DashboardMenu;
export {
  DashboardMenu,
  DashboardMenu_default as default
};
