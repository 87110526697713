import { jsx, jsxs } from "react/jsx-runtime";
import { BasicPage } from "@cuda-react/core";
import { IdentityProviders } from "./identityProviders/IdentityProviders";
import { UserDirectories } from "./userDirectories/UserDirectories";
const IdentitySettings = () => /* @__PURE__ */ jsxs(
  BasicPage,
  {
    title: "tesseract.identity.pageTitle",
    subtitle: "tesseract.identity.pageSubtitle",
    children: [
      /* @__PURE__ */ jsx(IdentityProviders, {}),
      /* @__PURE__ */ jsx(UserDirectories, {})
    ]
  }
);
var IdentitySettings_default = IdentitySettings;
export {
  IdentitySettings,
  IdentitySettings_default as default
};
