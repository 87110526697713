import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import {
  ActionButtonsField,
  ChipArrayField,
  ConnectedTable,
  CrudTypes,
  DeleteDialog,
  getArrayDataContent,
  PropGateway,
  StatusIconField,
  TextField,
  useCrudFetch,
  useCrudProps,
  useMixpanel,
  useTableChoices
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { makeStyles } from "@mui/styles";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import CreateEditIpsRules from "./CreateEditIpsRules";
import SourceFilter from "../../../../../components/sourceAndDestination/SourceFilter";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import { formatSourceDestinationFilters } from "../../../../../components/sourceAndDestination/tableUtils";
import { ipsIcons } from "../../../../../components/iconMapping";
import { useSourceDestinationField } from "../../../../../hooks/sourceAndDestinationHooks";
import apiResources from "../../../../../apiResources";
const useStyles = makeStyles((theme) => ({
  cardActions: {
    paddingRight: theme.spacing(2),
    width: "calc(100% - " + theme.spacing(2) + ")"
  },
  orderCell: {
    minWidth: 70
  },
  actionCell: {
    minWidth: 70,
    paddingLeft: 0
  },
  exploitList: {
    width: "512px !important"
  }
}));
const IPSFalsePositives = () => {
  var _a;
  const registerAction = useMixpanel("IPS False Positive", false);
  const classes = useStyles();
  const tableRefreshRef = useRef();
  const actionChoices = getArrayDataContent((_a = useCrudProps(apiResources.ipsActions)[0]) == null ? void 0 : _a.data);
  const [, , callIpsUpdate] = useCrudFetch(CrudTypes.CREATE, apiResources.ips, {});
  const handleOrderClickIps = (id, direction, data) => callIpsUpdate({ id, changeOrder: direction, data }).then(tableRefreshRef.current);
  const generateChipArrayPropsIps = useSourceDestinationField();
  const renderExploitChoice = useTableChoices(apiResources.ipsExploits, { optionValue: "id" });
  return /* @__PURE__ */ jsxs(
    ConnectedTable,
    {
      resource: apiResources.ips,
      exportResource: { resource: apiResources.ipsExport, filename: "ips" },
      actions: [
        /* @__PURE__ */ jsx(
          CreateEditButtonDialog,
          {
            create: true,
            component: CreateEditIpsRules,
            onSuccess: tableRefreshRef.current
          },
          "create"
        )
      ],
      params: formatSourceDestinationFilters,
      classes: { cardActions: classes.cardActions },
      minCellWidth: 128,
      flat: true,
      refreshRef: tableRefreshRef,
      children: [
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            left: true,
            label: "tesseract.security.ips.settings.order",
            cellClassName: classes.orderCell,
            width: 96,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(
                TextField,
                {
                  source: "order"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowUpwardIcon,
                {
                  onClick: (event, data) => handleOrderClickIps(data.id, "increase", data),
                  disabled: (data) => data.index <= 0,
                  id: "cuda-icon-up"
                }
              ),
              /* @__PURE__ */ jsx(
                ArrowDownwardIcon,
                {
                  onClick: (event, data) => handleOrderClickIps(data.id, "decrease", data),
                  disabled: (data) => data.index >= data.total - 1,
                  id: "cuda-icon-down"
                }
              )
            ]
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.security.ips.settings.name",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "description",
            label: "tesseract.security.ips.settings.description",
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "exploits",
            label: "tesseract.security.ips.settings.exploits",
            optionValue: "id",
            render: renderExploitChoice,
            filter: "selectarray",
            filterProps: {
              resource: apiResources.ipsExploits,
              optionValue: "id",
              options: {
                classes: {
                  popper: classes.exploitList
                },
                optionText: "name"
              }
            }
          }
        ),
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "action",
            text: (action) => (actionChoices.find((item) => item.key === action) || {}).name,
            label: "tesseract.security.ips.settings.action",
            iconMap: ipsIcons,
            cellClassName: classes.actionCell,
            filter: "select",
            filterProps: {
              choices: actionChoices
            },
            width: 200
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "source",
            label: "tesseract.security.ips.settings.source",
            editProps: generateChipArrayPropsIps("source"),
            filter: "custom",
            filterProps: {
              component: SourceFilter,
              filterSources: ["network", "site"]
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "source" })
          }
        ),
        /* @__PURE__ */ jsx(
          PropGateway,
          {
            source: "destination",
            label: "tesseract.security.ips.settings.destination",
            editProps: generateChipArrayPropsIps("destination"),
            filter: "custom",
            filterProps: {
              component: DestinationFilter,
              filterSources: ["network", "site"]
            },
            children: /* @__PURE__ */ jsx(ChipArrayField, { source: "destination" })
          }
        ),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            width: 96,
            source: "id",
            children: [
              /* @__PURE__ */ jsx(CreateEditButtonDialog, { component: CreateEditIpsRules, onSuccess: tableRefreshRef.current }),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource: apiResources.ips,
                  title: "tesseract.security.ips.settings.delete.title",
                  message: "tesseract.security.ips.settings.delete.body",
                  onSuccess: () => {
                    var _a2;
                    registerAction("Delete");
                    (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                  }
                }
              )
            ]
          }
        )
      ]
    }
  );
};
var IPSFalsePositives_default = IPSFalsePositives;
export {
  IPSFalsePositives,
  IPSFalsePositives_default as default,
  useStyles
};
