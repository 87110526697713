import { jsx, jsxs } from "react/jsx-runtime";
import { ConnectedWizard, ResultStep, SubmitStep, TextInput } from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";
import { makeStyles } from "@mui/styles";
import { IdentityProviderSAMLSetup } from "../IdentityProviderSAMLSetup";
const i18nScope = "tesseract.identity.identityProviders.add";
const useStyles = makeStyles((theme) => ({
  textarea: {
    "& > div": {
      height: "auto",
      minHeight: theme.spacing(20),
      alignItems: "flex-start"
    }
  },
  narrowLabel: {
    width: 210
  }
}));
const ShowSAMLData = ({ result }) => {
  if (!(result == null ? void 0 : result.data.saml)) {
    return null;
  }
  return /* @__PURE__ */ jsx(IdentityProviderSAMLSetup, { identityProvider: result.data });
};
const IdentityProviderSAMLForm = ({
  onSubmit,
  onCancel,
  create,
  identityProvider,
  id
}) => {
  const classes = useStyles();
  return /* @__PURE__ */ jsxs(
    ConnectedWizard,
    {
      title: `tesseract.identity.identityProviders.createIdentityProvider`,
      resource: apiResources.identityProvider,
      onClose: onCancel,
      initialValues: identityProvider,
      children: [
        /* @__PURE__ */ jsxs(SubmitStep, { label: `${i18nScope}.createStep`, children: [
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "displayName",
              label: `${i18nScope}.options.displayName`,
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              source: "options.signInEndpoint",
              label: `${i18nScope}.options.signInEndpoint`,
              isRequired: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextInput,
            {
              isRequired: true,
              source: "options.signingCert",
              label: `${i18nScope}.options.signingCert`,
              inputClasses: {
                textField: classes.textarea
              },
              inputLabelProps: {
                classes: {
                  inputLabel: classes.narrowLabel
                }
              },
              options: {
                multiline: true
              }
            }
          )
        ] }),
        /* @__PURE__ */ jsx(
          ResultStep,
          {
            label: `${i18nScope}.setupStep`,
            onClose: onSubmit,
            children: /* @__PURE__ */ jsx(ShowSAMLData, {})
          }
        )
      ]
    }
  );
};
export {
  IdentityProviderSAMLForm
};
