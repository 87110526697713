import { jsx } from "react/jsx-runtime";
import { BaseBarCard } from "../../baseCards";
import { bytesFormatter, colorFunction } from "../../../utils";
import { useReportingData } from "../../../contexts";
import { UsersIcon } from "../../icons";
import { useTheme } from "@mui/material";
const TopUsersCard = (props) => {
  const { preview, size } = props;
  const { data, loading, addFilter } = useReportingData("TopUsersCard", preview);
  const { userDataPoints = [] } = data || {};
  const theme = useTheme();
  const maxValue = Math.max(...userDataPoints.map((user) => user.numberOfBytes));
  const entries = userDataPoints.map((user) => ({
    title: user.label,
    value: bytesFormatter(user.numberOfBytes),
    barProgress: 100 / maxValue * user.numberOfBytes,
    color: colorFunction(100 / maxValue * user.numberOfBytes, theme),
    icon: /* @__PURE__ */ jsx(UsersIcon, { size: 20 })
    //Removing the filter completely until it is fixed on the platform
    // onClick: addFilter ? () => addFilter(user.id, "userDataPoints") : undefined,
    // tooltipMessage: addFilter ? "stratosReporting.filterTooltip" : undefined
  }));
  return /* @__PURE__ */ jsx(
    BaseBarCard,
    {
      entries,
      title: "stratosReporting.topUsersCard.title",
      size,
      preview,
      loading: loading && !data.userDataPoints,
      noData: !loading && !data.userDataPoints,
      activeFilter: true
    }
  );
};
export {
  TopUsersCard
};
