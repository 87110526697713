import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { getArrayDataContent, PasswordConfirmInput, SelectInput, TextInput, useCrudProps } from "@cuda-react/core";
import { useSiteInputValidations } from "../../../../../hooks/createEditSiteHooks";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../../apiResources";
import { SELECT_EMPTY_VALUE } from "../../../../../utils/constants";
const BasicStep = ({ applianceType, enforceGateway }) => {
  var _a;
  const gatewayResource = applianceType === "gateway" ? apiResources.gatewaysOnPremUpstream : apiResources.gatewayNames;
  const gatewaysData = getArrayDataContent((_a = useCrudProps(gatewayResource)[0]) == null ? void 0 : _a.data);
  const gatewayChoices = enforceGateway ? gatewaysData : [
    {
      key: SELECT_EMPTY_VALUE,
      name: "tesseract.appliances.dialog.basic.noGateway"
    },
    ...gatewaysData
  ];
  const inputValidations = useSiteInputValidations();
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "name",
        label: "tesseract.appliances.dialog.basic.name",
        description: translate("tesseract.appliances.dialog.basic.descriptions.name", { context: applianceType }),
        isRequired: true,
        validate: inputValidations.validateSiteName
      }
    ),
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: applianceType === "gateway" ? "upstream" : "gateway",
        label: applianceType === "gateway" ? "tesseract.appliances.dialog.basic.upstream_gateway" : "tesseract.appliances.dialog.basic.gateway",
        description: translate(applianceType === "gateway" ? "tesseract.appliances.dialog.basic.descriptions.upstream" : "tesseract.appliances.dialog.basic.descriptions.gateway", { context: applianceType }),
        choices: gatewayChoices
      }
    ),
    /* @__PURE__ */ jsx(
      PasswordConfirmInput,
      {
        source: "password",
        label: "tesseract.appliances.dialog.basic.rootPassword",
        description: translate("tesseract.appliances.dialog.basic.descriptions.rootPassword", { context: applianceType }),
        confirmLabel: "tesseract.appliances.dialog.basic.rootPasswordConfirmation",
        isRequired: true,
        viewable: true,
        validate: inputValidations.validatePassword
      }
    )
  ] });
};
var BasicStep_default = BasicStep;
export {
  BasicStep_default as default
};
