import { jsx } from "react/jsx-runtime";
import { useFormState, useWatch } from "react-hook-form";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  message: {
    padding: theme.spacing(2, 12.25, 2, 4.75)
  }
}));
const WanEditWarning = () => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const { defaultValues, dirtyFields } = useFormState();
  const mode = useWatch({ name: "mode" }) || [];
  const addresses = useWatch({ name: "addresses" }) || [];
  return (
    /* the first condition checks that the form has been opened in edit mode */
    (defaultValues == null ? void 0 : defaultValues.id) && !["wwan", "expressRoute", "bridge"].includes(defaultValues == null ? void 0 : defaultValues.mode) && (dirtyFields == null ? void 0 : dirtyFields.mode) && ["wwan", "expressRoute", "bridge"].includes(mode) ? /* @__PURE__ */ jsx(Typography, { className: classes.message, children: translate("tesseract.appliances.settings.wan.warning") }) : (defaultValues == null ? void 0 : defaultValues.id) && (dirtyFields == null ? void 0 : dirtyFields.addresses) && !(defaultValues == null ? void 0 : defaultValues.addresses.every((address) => addresses.includes(address))) ? /* @__PURE__ */ jsx(Typography, { className: classes.message, children: translate("tesseract.appliances.settings.wan.addressWarning") }) : null
  );
};
var WanEditWarning_default = WanEditWarning;
export {
  WanEditWarning_default as default,
  useStyles
};
