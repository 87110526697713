var fr_default = {
  translation: {
    tesseract: {
      productName: "Barracuda SecureEdge",
      dashboard: {
        menuTitle: "TABLEAU DE BORD",
        pageTitle: "Tableau de bord",
        emptyTemplate: "Vide",
        cards: {
          gateway: {
            cardTitle: "Statut d\u2019Edge Service",
            online: "En ligne",
            pending: "En cours",
            degraded: "D\xE9grad\xE9",
            offline: "Hors ligne"
          },
          iotStatusCard: {
            healthy: "En ligne",
            pending: "En cours",
            connecting: "Connexion en cours",
            degraded: "D\xE9grad\xE9",
            offline: "Hors ligne",
            name: "Nom",
            gateway: "Passerelle",
            aggregateState: "Statut",
            type: "Mod\xE8le"
          },
          status: {
            site: {
              tabTitle: "Statut de site"
            },
            iot: {
              tabTitle: "Statut IoT"
            },
            applianceConfiguration: {
              tabTitle: "Statut de configuration d'appareil",
              completed: "Termin\xE9",
              connecting: "Connexion en cours",
              failed: "\xC9chou\xE9",
              pending: "En cours"
            }
          },
          vpn: {
            locations: {
              tabTitle: "Emplacement",
              gateways: {
                label: "Edge Services",
                linkedSites: "Sites/IoT connect\xE9s",
                fields: {
                  name: "Nom",
                  sites: "Sites connect\xE9s",
                  iot: "IoT connect\xE9",
                  vwan: "Espace de travail",
                  hub: "Hub",
                  region: "R\xE9gion",
                  size: "Taille"
                }
              },
              sites: {
                label: "Sites",
                linkedHubs: "Edge Service connect\xE9",
                fields: {
                  name: "Nom",
                  location: "Emplacement",
                  gateway: "Edge Service",
                  providers: "Nombre de fournisseurs"
                }
              },
              iot: {
                label: "IoT",
                linkedHubs: "Edge Service connect\xE9",
                fields: {
                  name: "Nom",
                  location: "Emplacement",
                  gateway: "Edge Service",
                  providers: "Nombre de fournisseurs"
                }
              },
              standaloneSites: {
                label: "Sites autonomes"
              }
            },
            status: {
              tabTitle: "Statut des tunnels",
              ok: "OK",
              warning: "Avertissement",
              error: "Erreur",
              online: "En ligne",
              pending: "En cours",
              connecting: "Connexion en cours",
              degraded: "D\xE9grad\xE9",
              offline: "Hors ligne"
            },
            offline: {
              tabTitle: "Tunnels hors ligne",
              gatewayName: "Nom d\u2019Edge Service",
              gatewayStatus: "Statut d\u2019Edge Service",
              siteName: "Nom de site / IoT",
              siteStatus: "Statut de site / IoT",
              siteLocation: "Emplacement de site / IoT",
              noData: "Aucun tunnel hors ligne trouv\xE9."
            }
          },
          recentEvents: {
            adminTabTitle: "\xC9v\xE9nements administratifs r\xE9cents",
            systemTabTitle: "\xC9v\xE9nements syst\xE8me r\xE9cents",
            latest: "Derniers {{length}}"
          },
          categories: {
            status: "Statut"
          }
        },
        status: "Statut",
        security: "S\xE9curit\xE9",
        webFilter: "Filtre Web",
        filters: {
          timeframe: "\xC9ch\xE9ancier",
          appliance: "Service Edge/Site",
          noAppliances: "Aucun",
          timeframes: {
            lastDay: "Derni\xE8res 24 heures",
            lastMonth: "Dernier mois",
            lastWeek: "Derni\xE8re semaine",
            lastQuarter: "3 derniers mois"
          }
        },
        ztnaDashboard: "Acc\xE8s Zero Trust",
        ztnaUserDashboard: "Tableau de bord d'utilisateur"
      },
      network: {
        pageTitle: "Infrastructure",
        vmActivation: "Activation de VM",
        vmActivationMessage: "Enregistrez vos informations de facturation pour l'activation de VM automatique dans tous les espaces de travail.",
        gateways: "Edge Services",
        sites: "Sites",
        iot: {
          menuTitle: "IoT",
          noData: {
            iconHeader: "Commencez par ajouter un dispositif IoT !",
            labelButton: "Ajouter un dispositif IoT",
            textHeader: "Un dispositif IoT : c'est quoi ?",
            text: "Un dispositif IoT d\xE9signe un emplacement de l'Internet des objets qui est connect\xE9 (et prot\xE9g\xE9) par un appareil Barracuda Secure Connector. Les appareils Secure Connector peuvent \xEAtre d\xE9ploy\xE9s comme un appareil physique ou virtuel \u2013 selon la m\xE9thode id\xE9ale pour vos besoins."
          }
        },
        settings: "Param\xE8tres",
        firewalls: "CloudGen Firewalls",
        sdwanConnector: {
          menuTitle: "CONNECTEUR",
          pageTitle: "Connecteurs",
          table: {
            status: "Statut",
            name: "Nom",
            gateway: "Edge Service",
            internalResources: "Ressources internes",
            provisioningDate: "Date d'approvisionnement",
            servers: "Serveurs",
            ellipsisMenu: {
              downloadLinux: "T\xE9l\xE9charger l'agent Linux",
              downloadWindows: "T\xE9l\xE9charger l'agent Windows",
              token: "G\xE9n\xE9rer un jeton d'enregistrement"
            },
            hostnames: "Noms d'h\xF4te"
          },
          delete: {
            body: "\xCAtes-vous certain de vouloir supprimer ce connecteur ?",
            title: "Supprimer connecteur {{name}}"
          },
          dialog: {
            add: "Ajouter",
            menuItemDisabled: "Le connecteur n\xE9cessite un service Edge",
            basic: {
              name: "Nom",
              sectionTitle: "Informations basiques",
              tabTitle: "Basique"
            },
            create: {
              dialogTitle: "Ajouter connecteur"
            },
            edit: {
              dialogTitle: "Modifier connecteur"
            },
            poolNetwork: {
              sectionTitle: "IP achemin\xE9e",
              descriptionMessage: "Avant de pouvoir ajouter votre premier connecteur, vous devez d\xE9finir une plage de r\xE9seau client. Le r\xE9seau client sert \xE0 assigner des IP \xE0 des ressources derri\xE8re les connecteurs.",
              network: "R\xE9seau client"
            },
            general: {
              sectionTitle: "G\xE9n\xE9ral",
              name: "Nom",
              description: "Description",
              appliance: "Edge Service"
            },
            servers: {
              sectionTitle: "Serveurs",
              serviceName: "Serveurs",
              itemName: "Serveur",
              hostname: "Nom d'h\xF4te",
              internalIp: "IP interne",
              category: "Cat\xE9gorie"
            },
            summary: {
              sectionTitle: "Synth\xE8se",
              name: "Synth\xE8se pour connecteur {{connector}}",
              connectorName: "Nom de connecteur",
              serviceName: "Nom de service",
              hostname: "Nom d'h\xF4te"
            },
            completed: {
              sectionTitle: "Cr\xE9ation de connecteur \xAB\xA0{{connector}}\xA0\xBB r\xE9ussie",
              sectionTitleNextSteps: "\xC9tapes suivantes",
              sectionMessage: "Proc\xE9dez comme suit pour commencer \xE0 l'utiliser",
              sectionSteps: "T\xE9l\xE9chargez et installez le connecteur",
              sectionSteps2: "Fournissez au connecteur le jeton OTP :",
              sectionSteps3: "V\xE9rifiez que la connexion est \xE9tablie",
              downloadButtonLinux: "T\xE9l\xE9chargement pour Linux",
              downloadButtonWindows: "T\xE9l\xE9chargement pour Windows"
            },
            description: {
              poolNetwork: {
                network: "La plage de r\xE9seau client dans une notation CIDR"
              },
              general: {
                sectionTitle: "G\xE9n\xE9ral",
                name: "Un nom unique pour votre connecteur.",
                description: "Une br\xE8ve description du connecteur.",
                appliance: "S\xE9lectionnez les appareils Edge Service \xE0 configurer."
              },
              servers: {
                sectionTitle: "Serveurs",
                serviceName: "Un nom unique pour votre serveur.",
                hostname: "Le nom d'h\xF4te li\xE9 au serveur.",
                internalIp: "L'IP interne li\xE9e au serveur.",
                category: "Cat\xE9gorie"
              }
            },
            token: {
              dialogTitle: "G\xE9n\xE9rer un jeton d'enregistrement",
              message: 'G\xE9n\xE9rer un nouveau jeton pour "{{sec}}"',
              subLabel: "Jeton de connecteur"
            }
          },
          noData: {
            iconHeader: "Commencez par ajouter un connecteur !",
            labelButton: "Ajouter un connecteur",
            textHeader: "Un connecteur : c'est quoi ?",
            text: "La cr\xE9ation de services sp\xE9cifiques disponibles pour votre personnel peut s'av\xE9rer un d\xE9fi.<0 /> With a connector, you can easily connect Windows services and servers right to your network and make them available to your workforce without any need for a dedicated site device."
          }
        },
        customCategories: "Cat\xE9gories personnalis\xE9es"
      },
      endpoint: {
        pageTitle: "Zero Trust Access",
        devices: "Dispositifs",
        settings: "Param\xE8tres",
        zeroTrustAccess: "Zero Trust Access",
        zeroTrustSettingsTitle: "Param\xE8tres",
        pointToSite: "Point \xE0 site",
        users: "Utilisateurs inscrits",
        appCatalog: {
          pageTitle: "Catalogue d'applications",
          textHeader: "Un catalogue d'applications : c'est quoi ?",
          text: "Avec le catalogue d'applications, vous d\xE9finissez les applications qui s'affichent dans l'app SecureEdge Access Agent en raccourcis.<0 />Cette fonctionnalit\xE9 de raccourci est valide pour les applications externes mais aussi internes.<br/><br/>Cette section sera bient\xF4t disponible.",
          startText: "Avec le catalogue d'applications, vous d\xE9finissez les applications qui s'affichent dans l'app SecureEdge Access Agent en raccourcis.<0 />Cette fonctionnalit\xE9 de raccourci est valide pour les applications externes mais aussi internes.",
          startTextHeader: "Commencez par ajouter une application !",
          buttonText: "Ajouter application",
          iconText: "Ic\xF4ne",
          name: "Nom",
          application: "Application",
          launchURL: "URL de lancement",
          visibility: "Visibilit\xE9",
          create: {
            title: "Ajouter application",
            displayName: "Afficher nom",
            displayIcon: "Afficher ic\xF4ne",
            associatedApplication: "Application associ\xE9e",
            users: "Utilisateurs",
            groups: "Groupes",
            descriptions: {
              icon: "T\xE9l\xE9chargez l'ic\xF4ne d'application.",
              iconInfo: "Les formats de fichier JPEG, PNG, GIF et SVG sont pris en charge.",
              name: "Un nom unique pour l'application.",
              application: "Choisissez l'application associ\xE9e",
              launchUrl: "Fournissez l'URL de lancement",
              visibility: "Choisissez la visibilit\xE9 du lien",
              users: "S\xE9lectionnez les utilisateurs qui devraient avoir acc\xE8s aux apps s\xE9lectionn\xE9es. Si aucun utilisateur ou groupe n'est s\xE9lectionn\xE9, les apps s\xE9lectionn\xE9es sont accessibles \xE0 tous.",
              groups: "S\xE9lectionnez les groupes qui devraient avoir acc\xE8s aux apps s\xE9lectionn\xE9es. Si aucun utilisateur ou groupe n'est s\xE9lectionn\xE9, les apps s\xE9lectionn\xE9es sont accessibles \xE0 tous."
            }
          },
          edit: {
            title: "Modifier application"
          },
          delete: {
            body: "\xCAtes-vous certain de vouloir supprimer cette application ?",
            title: "Supprimer {{name}}"
          },
          validate: {
            userGroupRequired: "au moins un utilisateur ou un groupe est obligatoire",
            tenantIdRequired: "Utilisateurs et groupes indisponibles. Vous devez d'abord ajouter un R\xE9pertoire d'utilisateurs dans la page Identit\xE9 > Param\xE8tres."
          }
        },
        enrollEmailDialog: {
          addEmail: "Ajouter un utilisateur ou e-mail",
          body: "Corps",
          customMessage: "Message personnalis\xE9",
          defaultContent: 'Salut ! \n\nYou have been added by your organisation to Barracuda SecureEdge User Connectivity. \n\nTo gain access, follow these instructions: \n\n1. Go to {{link}}\n2. Download and install the Barracuda VPN client.\n3. Click "Enroll now" to complete the process.\n\nYou are now able to connect to Barracuda SecureEdge from your Windows or Mac OS clients.',
          defaultSubject: "Vous venez d'\xEAtre ajout\xE9 \xE0 Barracuda SecureEdge",
          zeroTrustSubject: "Votre invitation d'inscription pour votre agent Barracuda SecureEdge Access Agent",
          emailTitle: "Inscrire des utilisateurs",
          resendEmailTitle: "Renvoyer des invitations d'inscription",
          noHtml: "les caract\xE8res sp\xE9ciaux suivants sont interdits : <>",
          recipients: "\xC0",
          users: "Utilisateurs",
          send: "Envoyer",
          subject: "Objet",
          enrollMessage: "S\xE9lectionnez les utilisateurs ou groupes \xE0 inscrire pour SecureEdge Access. L'utilisateur devra s'identifier au premier lancement de l'agent. Vous pouvez suivre cette progression dans la page Acc\xE8s > Utilisateurs.<0>Il vous reste {{count}} si\xE8ge.</0>",
          sendMessage: "Le message suivant sera envoy\xE9 aux utilisateurs et groupes s\xE9lectionn\xE9s. \xCAtes-vous certain de vouloir envoyer votre invitation ?",
          successHeader_plural: "Votre invitation d'inscription est en cours d'envoi \xE0 {{count}} utilisateurs !",
          successHeader: "Votre invitation d'inscription est en cours d'envoi \xE0 {{count}} utilisateur !",
          successMessage_plural: "Vous pouvez v\xE9rifier le statut d'inscription de chaque utilisateur dans le tableau Utilisateurs.",
          successMessage: "Vous pouvez v\xE9rifier le statut d'inscription de l'utilisateur dans le tableau Utilisateurs.",
          emailTemplate: {
            hello: "Salut {{username}} !",
            message: "Vous \xEAtes invit\xE9 \xE0 inscrire votre Barracuda SecureEdge Access Agent. Cet agent s\xE9curise votre acc\xE8s aux ressources d'entreprise et prot\xE8ge vos dispositifs des cybermenaces.",
            clickStart: 'Veuillez cliquer "Start" to start the self-enrollment process.',
            start: "D\xE9marrer",
            copyLink: "Vous pouvez aussi copier le lien suivant directement dans votre SecureEdge Access Agent pour vous inscrire.",
            exampleLink: "https://se.barracudanetworks.com/zerotrust/enroll/?uuid=22fc1190-8d5a-4b8e-803f-fd8819408305&endpoint=https://se.barracudanetworks.com"
          },
          addUsers: "Ajouter des utilisateurs",
          addGroups: "Ajouter des groupes",
          userNotificationSectionTitle: "Notification d'utilisateur (Option)",
          enrollmentEmail: {
            label: "E-mail d'inscription",
            enabled: "Oui - inclut le lien de t\xE9l\xE9chargement d'agent",
            disabled: "Non - installation silencieuse"
          },
          groups: "Groupes",
          enroll: "Inscription",
          enrollMessageOld: "S\xE9lectionnez les utilisateurs ou groupes \xE0 inscrire pour SecureEdge Access. L'utilisateur devra s'identifier au premier lancement de l'agent. Vous pouvez suivre l'avancement dans la page Acc\xE8s > Utilisateurs.",
          enrollMessage_plural: "S\xE9lectionnez les utilisateurs ou groupes \xE0 inscrire pour SecureEdge Access. L'utilisateur devra s'identifier au premier lancement de l'agent. Vous pouvez suivre cette progression dans la page Acc\xE8s > Utilisateurs.<0>Il vous reste {{count}} si\xE8ges.</0>",
          resolvedUsers: "Les utilisateurs/groupes s\xE9lectionn\xE9s repr\xE9sentent plus d'utilisateurs que les si\xE8ges restants. Veuillez utiliser le menu \xE0 la suite pour retirer les utilisateurs que vous ne voulez pas inscrire maintenant ou revenir en arri\xE8re pour s\xE9lectionner des utilisateurs/groupes diff\xE9rents.",
          successHeaderOld: "Votre invitation d'inscription est en cours d'envoi \xE0 '{{users}}' !",
          successHeaderOld_plural: "Votre invitation d'inscription est en cours d'envoi \xE0 {{count}} utilisateurs !",
          noEmailSuccessHeader: "Inscription de {{count}} utilisateur !",
          noEmailSuccessHeader_plural: "Inscription de {{count}} utilisateurs !",
          validate: {
            userGroupRequired: "au moins un utilisateur ou un groupe est obligatoire",
            userRequired: "au moins un utilisateur est obligatoire",
            seatLimit: "Inscrire {{currentCount}} utilisateurs ({{maxCount}} si\xE8ges disponibles)"
          },
          reenrollMessage: "Renvoyez l'e-mail d'invitation d'inscription \xE0 l'utilisateur s\xE9lectionn\xE9.",
          noSeats: "il ne reste plus de si\xE8ges.",
          seats: "Il vous reste {{count}} si\xE8ge.",
          seats_plural: "Il vous reste {{count}} si\xE8ges.",
          unSuccessHeaderAllUsersAlreadyEnrolled: "L'utilisateur s\xE9lectionn\xE9 est d\xE9j\xE0 inscrit.",
          unSuccessHeaderAllUsersAlreadyEnrolled_plural: "Tous les utilisateurs s\xE9lectionn\xE9s sont d\xE9j\xE0 inscrits.",
          alreadyEnrolledUsersUnsuccessful_emailplural: "Aucun e-mail ne sera envoy\xE9.",
          alreadyEnrolledUsers: "{{totalCount}} des utilisateurs s\xE9lectionn\xE9s est d\xE9j\xE0 inscrit.",
          alreadyEnrolledUsers_workspace: "{{totalCount}} des utilisateurs s\xE9lectionn\xE9s est d\xE9j\xE0 inscrit ({{unenrollableCount}} sur un compte ou dans un espace de travail diff\xE9rent).",
          alreadyEnrolledUsers_email: "{{totalCount}} des utilisateurs s\xE9lectionn\xE9s est d\xE9j\xE0 inscrit. D\xE8s lors, aucun e-mail ne sera envoy\xE9 \xE0 cet utilisateur.",
          alreadyEnrolledUsers_emailworkspace: "{{totalCount}} des utilisateurs s\xE9lectionn\xE9s est d\xE9j\xE0 inscrit ({{unenrollableCount}} sur un compte ou dans un espace de travail diff\xE9rent). D\xE8s lors, aucun e-mail ne sera envoy\xE9.",
          alreadyEnrolledUsers_plural: "{{totalCount}} des utilisateurs s\xE9lectionn\xE9s sont d\xE9j\xE0 inscrits.",
          alreadyEnrolledUsers_workspaceplural: "{{totalCount}} des utilisateurs s\xE9lectionn\xE9s sont d\xE9j\xE0 inscrits ({{unenrollableCount}} sur un compte ou dans un espace de travail diff\xE9rent).",
          alreadyEnrolledUsers_emailplural: "{{totalCount}} des utilisateurs s\xE9lectionn\xE9s sont d\xE9j\xE0 inscrits. D\xE8s lors, aucun e-mail ne sera envoy\xE9 \xE0 ces utilisateurs.",
          alreadyEnrolledUsers_emailworkspaceplural: "{{totalCount}} des utilisateurs s\xE9lectionn\xE9s sont d\xE9j\xE0 inscrits ({{unenrollableCount}} sur un compte ou dans un espace de travail diff\xE9rent). D\xE8s lors, aucun e-mail ne sera envoy\xE9."
        },
        remoteAccessPolicies: {
          delete: {
            title: "Supprimer strat\xE9gie Acc\xE8s Zero Trust {{name}}",
            body: "\xCAtes-vous certain de vouloir supprimer cette strat\xE9gie Acc\xE8s Zero Trust ?"
          },
          order: "Ordre",
          name: "Nom",
          description: "Description",
          groupsGrantedAccess: "Acc\xE8s octroy\xE9 aux groupes",
          resources: "Ressources",
          devicePosture: "Posture de s\xE9curit\xE9",
          usersGrantedAccess: "Acc\xE8s octroy\xE9 aux utilisateurs",
          zeroTrustAccess: "Zero Trust Access",
          policy: "Strat\xE9gie",
          create: "Cr\xE9er strat\xE9gie Acc\xE8s Zero Trust",
          edit: "Modifier strat\xE9gie Acc\xE8s Zero Trust",
          success: "Succ\xE8s",
          successMessage: "La nouvelle strat\xE9gie Acc\xE8s Zero Trust a \xE9t\xE9 correctement cr\xE9\xE9e.",
          createButton: "Ajouter une strat\xE9gie",
          deviceAttributes: "Posture de s\xE9curit\xE9",
          resourcesSection: {
            title: "Ressources",
            internalResource: "Ressources internes (apps personnalis\xE9es)",
            publicResource: "Point final public (SaaS)",
            resourceTypeLabel: "Type de ressource",
            resourceTypeDescription: `S\xE9lectionnez "Interne" pour les ressources uniquement accessibles en interne via un point d'entr\xE9e et "Public" pour les ressources accessibles publiquement.`,
            resourcesLabel: "Ressources"
          },
          applications: "Applications",
          accessCriteriaSection: "Crit\xE8res d'acc\xE8s",
          users: "Utilisateurs",
          groups: "Groupes",
          securityInspection: "Inspection de s\xE9curit\xE9",
          screenLock: "Verrou d'\xE9cran activ\xE9",
          firewall: "Pare-feu activ\xE9",
          jailbroken: "Bloquer les dispositifs d\xE9brid\xE9s",
          encryption: "Faire respecter le cryptage de disque",
          appUpdates: "Mises \xE0 jour de Barracuda SecureEdge Access Agent",
          minimumVersion: "Version minimum",
          platform: "Plateforme",
          osUpdates: "Mises \xE0 jour d'OS",
          antivirus: "Antivirus activ\xE9",
          descriptions: {
            name: "Un nom unique pour la strat\xE9gie.",
            description: "Une br\xE8ve description de la strat\xE9gie.",
            resourceType: "Configurez soit les applications personnalis\xE9es soit les applications de tiers externes.",
            applications: "Les applications concern\xE9es par cette strat\xE9gie.",
            users: "S\xE9lectionnez les utilisateurs qui devraient avoir acc\xE8s aux apps s\xE9lectionn\xE9es. Si aucun utilisateur ou groupe n'est s\xE9lectionn\xE9, les apps s\xE9lectionn\xE9es sont accessibles \xE0 tous.",
            groups: "S\xE9lectionnez les groupes qui devraient avoir acc\xE8s aux apps s\xE9lectionn\xE9es. Si aucun utilisateur ou groupe n'est s\xE9lectionn\xE9, les apps s\xE9lectionn\xE9es sont accessibles \xE0 tous.",
            securityPosture: "Choisissez si les attributs des dispositifs configur\xE9s doivent \xEAtre appliqu\xE9s ou si les manquements doivent seulement \xEAtre consign\xE9s.",
            securityInspection: "Si activ\xE9, le trafic vers les applications choisies est achemin\xE9 via le tunnel pour assurer une inspection de s\xE9curit\xE9 approfondie et les strat\xE9gies de point d'entr\xE9e seront appliqu\xE9es. Si d\xE9sactiv\xE9, le trafic est achemin\xE9 normalement via Internet.",
            screenLock: "Les utilisateurs doivent r\xE9gler un verrou d'\xE9cran sur leurs dispositifs. Plateformes compatibles : Android, iOS.",
            firewall: "Les utilisateurs doivent activer et configurer un pare-feu sur leurs dispositifs. Plateformes compatibles : mac OS, Windows.",
            jailbroken: "\xC9vitez que les dispositifs compromis n'acc\xE8dent aux ressources. Plateformes compatibles : Android, iOS.",
            encryption: "Les utilisateurs doivent d\xE9finir un cryptage de disque. Plateformes compatibles : Android, iOS, mac OS, Windows.",
            appUpdates: "Les utilisateurs doivent mettre \xE0 jour Barracuda SecureEdge Access Agent sur la version la plus r\xE9cente. Plateformes compatibles : Android, iOS, Linux, mac OS, Windows.",
            osUpdates: "Les utilisateurs doivent mettre \xE0 jour le syst\xE8me d'exploitation vers la version minimum. Plateformes compatibles : Android, iOS, mac OS, Windows.",
            antivirus: "Les utilisateurs doivent avoir configur\xE9 un service antivirus sur leur dispositif. Plateformes : Windows."
          },
          validatePlatform: "OS d\xE9j\xE0 s\xE9lectionn\xE9",
          validateDeviceCriteria: "au moins une fonctionnalit\xE9 de s\xE9curit\xE9 doit \xEAtre activ\xE9e",
          lastMileOptimization: "Optimisation du dernier kilom\xE8tre ",
          noData: {
            iconHeader: "Commencez par ajouter une strat\xE9gie d'Acc\xE8s Zero Trust !",
            labelButton: "Ajouter une strat\xE9gie d'Acc\xE8s Zero Trust",
            textHeader: "Une strat\xE9gie d'Acc\xE8s Zero Trust : c'est quoi ?",
            text: "Dans cette interface, vous d\xE9finissez les exigences envers les dispositifs d'utilisateur pour pouvoir acc\xE9der au r\xE9seau.<0 /> For example, you can require devices to have the latest OS updates applied and onboard security enabled, or you can even block jailbroken devices."
          }
        },
        pointsOfEntry: {
          title: "Points d'entr\xE9e",
          table: {
            name: "Nom",
            type: "Type",
            size: "Taille",
            location: "Emplacement",
            publicIp: "IP publiques",
            noData: "Aucun point d'entr\xE9e n'a \xE9t\xE9 configur\xE9. Au moins un point d'entr\xE9e doit \xEAtre configur\xE9 pour utiliser Zero Trust Access."
          },
          createButton: "Ajouter des points d'entr\xE9e",
          create: {
            title: "Ajouter des points d'entr\xE9e",
            configurationType: "Type",
            site: "Site",
            gateway: "Edge Service",
            firewall: "Pare-feu",
            ipAddress: "IP publique",
            sites: "Sites",
            gateways: "Edge Services",
            firewalls: "Pare-feux",
            descriptions: {
              sites: "Sites \xE0 ajouter comme points d'entr\xE9e.",
              gateways: "Edge Services \xE0 ajouter comme points d'entr\xE9e.",
              firewalls: "Pare-feux \xE0 ajouter comme points d'entr\xE9e."
            },
            validateSelectOne: "Au moins un pare-feu, site ou Edge Service doit \xEAtre s\xE9lectionn\xE9."
          },
          edit: {
            title: "Modifier un point d'entr\xE9e",
            name: "Nom",
            ipAddress: "IP publiques",
            descriptions: {
              ipAddress: "Les adresses IP publiques via lesquelles ce pare-feu est accessible."
            }
          },
          delete: {
            title: "Supprimer le point d'entr\xE9e {{name}}",
            body: "\xCAtes-vous certain de vouloir supprimer ce point d'entr\xE9e ?"
          },
          noData: {
            iconHeader: "Commencez par ajouter un point d'entr\xE9e !",
            labelButton: "Ajouter un point d'entr\xE9e",
            textHeader: "Un point d'entr\xE9e : c'est quoi ?",
            text: "Un point d'entr\xE9e (PoE - Point of Entry) agit comme un point de connexion pour vos SecureEdge Access Agents.<0 /> Les agents choisissent automatiquement le PoE disponible le plus proche pour optimiser la qualit\xE9 de service."
          }
        },
        zeroTrustSettings: {
          agentWebFiltering: "Filtrage Web d'agent",
          dnsSuffix: "Suffixe DNS",
          downloadCertificateHelpText: "T\xE9l\xE9chargez et d\xE9ployez ce certificat racine pour tous les clients afin d'assurer des op\xE9rations parall\xE8les avec les configurations client \xE0 site CloudGen Firewall et SecureEdge Access Agent.",
          downloadCertificateLabel: "T\xE9l\xE9charger un certificat",
          networkSectionTitle: "Configuration r\xE9seau Access Agent",
          clientNetworkDescription: "Le r\xE9seau client sert \xE0 assigner des IP aux clients qui se connectent via SecureEdge Access Agent. Il est divis\xE9 en pools qui sont ensuite distribu\xE9s sur chaque point d'entr\xE9e.",
          manualConfigurationEnabled: "Configuration manuelle",
          clientNetworkRange: "R\xE9seau client",
          clientNetworkSize: "Ce r\xE9seau client offre au moins {{connections}} connexions client par point d'entr\xE9e.",
          clientNetworkPoolSize: "Masque de bits de pool",
          validatePoolSize: "doit \xEAtre inf\xE9rieur au r\xE9seau client",
          enforce: "Appliquer",
          disable: "D\xE9sactiver",
          globalZtnaSettings: "R\xE9glages d'acc\xE8s"
        },
        ztnaUsers: {
          dashboardTabTitle: "Tableau de bord",
          settingsTabTitle: "Param\xE8tres",
          userNotFound: "Impossible de trouver l'utilisateur"
        }
      },
      securityAndAccess: {
        pageTitle: "Strat\xE9gie de s\xE9curit\xE9",
        apps: "Applications et ressources",
        network: "ACL de r\xE9seau",
        gateway: "ACL de Edge Service",
        site: "ACL de site",
        sdwan: "SD-WAN",
        categories: "Cat\xE9gories d'application",
        explicit: "Cat\xE9gories de priorit\xE9",
        security: "S\xE9curit\xE9",
        malware: "Protection contre les menaces avanc\xE9es",
        ssl: "Inspection SSL",
        ips: "IPS",
        ingressNatRules: "NAT d'entr\xE9e",
        urlFilter: "Strat\xE9gies",
        webFilter: "Filtre Web",
        webFilterSettings: "Param\xE8tres",
        webMonitorPolicies: "Surveillance Web",
        settings: "Param\xE8tres",
        customResponse: "Messages de r\xE9ponse"
      },
      appliances: {
        dashboard: {
          deployment: {
            status: {
              title: "Statut de d\xE9ploiement {{serial}}",
              status: "Statut",
              action: "Action",
              message: "Message",
              registered: "Enregistr\xE9",
              lastSeen: "Vu en dernier",
              completed: "Termin\xE9",
              localIp: "IP locale",
              remoteIp: "IP publique"
            },
            events: {
              title: "\xC9v\xE9nements de d\xE9ploiements",
              state: "\xC9tat",
              status: "Statut",
              created: "Date",
              siteDeploying: "D\xE9ploiement en cours"
            },
            location: {
              title: "Emplacement de d\xE9ploiement"
            }
          },
          details: {
            servicesCardTitle: "Services",
            interfaces: {
              title: "Interfaces",
              status: "Statut",
              name: "Nom",
              speed: "Vitesse",
              duplex: "Duplex",
              errors: "Erreurs",
              state: {
                ok: "OK",
                warning: "Avertissement",
                error: "Erreur",
                unknown: "Inconnu",
                loading: " "
              }
            },
            boxResources: {
              title: "Ressources",
              cpuLoad: "CPU",
              cpuLoadError: "Une erreur a \xE9t\xE9 d\xE9tect\xE9e dans la CPU. Veuillez red\xE9marrer le dispositif. Si cette erreur persiste, veuillez contacter le support Barracuda.",
              memory: "M\xE9moire",
              diskInformation: "Disque dur",
              diskInformationError: "Une erreur a \xE9t\xE9 d\xE9tect\xE9e dans l'une des partitions de disque. Veuillez red\xE9marrer le dispositif. Si cette erreur persiste, veuillez contacter Barracuda Support."
            },
            vpnTunnels: {
              title: "Tunnels VPN",
              instanceId: "Num\xE9ro de s\xE9rie d'instance",
              status: "Statut",
              name: "Nom",
              peer: "Pair",
              local: "Local",
              type: "Type",
              state: {
                UP: "Actif",
                DOWN: "Arr\xEAt\xE9",
                INACTIVE: "Inactif",
                FAIL: "Arr\xEAt\xE9 - \xC9chec",
                STANDBY: "Arr\xEAt\xE9 - Veille",
                "DOWN (PASSIVE)": "Arr\xEAt\xE9 - Passif",
                "DOWN (FAIL)": "Arr\xEAt\xE9 - \xC9chou\xE9",
                "DOWN (STANDBY)": "Arr\xEAt\xE9 - Veille",
                "DOWN (DISABLED)": "D\xE9sactiv\xE9",
                PENDING: "En cours"
              }
            }
          },
          firmware: {
            details: {
              tabTitle: "Micrologiciel",
              version: "Version",
              hotfixes: "Correctifs install\xE9s",
              window: "Mise \xE0 jour suivante",
              nextUpdate: "Entre {{from}} et {{to}}"
            },
            hotfixes: {
              tabTitle: "Correctifs",
              status: "Statut",
              name: "Nom"
            },
            updates: {
              tabTitle: "Mises \xE0 jour de configuration",
              lastUpdate: "Derni\xE8re mise \xE0 jour",
              lastSeen: "Vu en dernier",
              remoteIp: "IP distante"
            },
            state: {
              error: "Erreur",
              ok: "OK",
              unknown: "Inconnu",
              warning: "En cours",
              loading: " "
            }
          },
          siteStatus: {
            tabTitle: "Sites connect\xE9s"
          },
          iotStatus: {
            tabTitle: "IoT connect\xE9"
          },
          summary: {
            siteStatus: {
              title: "Statut",
              model: "Mod\xE8le",
              serials: "Num\xE9ro(s) de s\xE9rie",
              serial: "Num\xE9ro de s\xE9rie",
              release: "Version"
            },
            licenses: {
              title: "Licences",
              type: "Type",
              state: "\xC9tat",
              status: "Statut",
              expirationDate: "Date d'expiration",
              serial: "Num\xE9ro de s\xE9rie",
              noData: "S/O"
            },
            gateway: {
              tabTitle: "Edge Service",
              noGateway: "Aucun Edge Service n'est configur\xE9 pour ce site",
              name: "Nom",
              region: "R\xE9gion",
              hubName: "Hub",
              vwan: "Cloud VWAN",
              size: "Taille",
              publicIP: "IP publique",
              publicIPs: "IP publique(s)",
              serial: "Num\xE9ro(s) de s\xE9rie",
              instanceNotFound: "Impossible de trouver l'instance"
            },
            status: {
              tabTitle: "Statut",
              hub: "Hub",
              region: "R\xE9gion",
              vwan: "Cloud VWAN",
              connectedSites: "Sites connect\xE9s"
            }
          },
          tunnelLocations: {
            title: "Emplacement",
            gateways: {
              label: "Edge Services",
              fields: {
                name: "Nom",
                sites: "Sites connect\xE9s",
                iot: "IoT connect\xE9",
                vwan: "Espace de travail",
                hub: "Hub",
                region: "R\xE9gion",
                size: "Taille"
              }
            },
            iot: {
              label: "IoT"
            },
            sites: {
              label: "Sites",
              fields: {
                name: "Nom",
                location: "Emplacement",
                gateway: "Edge Service",
                providers: "Nombre de fournisseurs"
              }
            },
            standaloneSites: {
              title: "Emplacement",
              label: "Sites autonomes"
            }
          }
        },
        dialog: {
          createType: {
            sectionTitle: "Type d\u2019Edge Service",
            new: "Nouvel Edge Service de z\xE9ro",
            promote: "Promouvoir un site existant en tant qu\u2019Edge Service"
          },
          siteSelection: {
            sectionTitle: "S\xE9lection de site",
            mainMessage: "S\xE9lectionnez le site que vous souhaitez promouvoir vers un service Private Edge.",
            secondaryMessage: "Note\xA0: Toutes les strat\xE9gies associ\xE9es (ex. ACL, filtres d'URL, etc...) seront supprim\xE9es en cas de promotion.",
            haWarning: "Ce site n'est pas HA. Il est recommand\xE9 d'utiliser HA pour les services Private Edge.",
            site: "Site"
          },
          basic: {
            gateway: "Edge Service",
            upstream_gateway: "Edge Service amont",
            noGateway: "(Aucun)",
            name: "Nom",
            sectionTitle: "Informations basiques",
            tabTitle: "Basique",
            rootPassword: "Mot de passe racine",
            rootPasswordConfirmation: "Confirmer le mot de passe racine",
            descriptions: {
              gateway_site: "Le Barracuda SecureEdge Edge Service auquel vous voulez que ce site se connecte.",
              gateway_gateway: "Le Barracuda SecureEdge Edge Service auquel vous voulez que ce Edge Service se connecte.",
              name_site: "Un nom unique pour votre site.",
              name_gateway: "Un nom unique pour votre Edge Service.",
              rootPassword_site: "Le mot de passe utilisateur racine pour le site.",
              rootPassword_gateway: "Le mot de passe utilisateur racine pour l\u2019Edge Service.",
              upstream_gateway: "L\u2019Edge Service amont de Barracuda SecureEdge auquel vous voulez que cet Edge Service se connecte."
            }
          },
          serials: {
            gatewayMessage: "S\xE9lectionnez les appareils Edge Service \xE0 configurer. Nous recommandons la s\xE9lection de deux appareils pour cr\xE9er un cluster HA (High Availability - Haute disponibilit\xE9).",
            TVTSiteMessage: "S\xE9lectionnez les appareils de site \xE0 configurer. Vous pouvez s\xE9lectionner un seul appareil ou deux appareils pour cr\xE9er un cluster HA.",
            SCSiteMessage: "S\xE9lectionnez l'appareil Secure Connector \xE0 configurer.",
            haMessage: "<1>Note :</1> Les deux appareils d'un cluster HA doivent avoir le m\xEAme mod\xE8le et la m\xEAme version de micrologiciel",
            sectionTitle_site: "Appareils de site",
            sectionTitle_gateway: "Appareils Edge Service",
            addAppliance: "Ajoutez un appareil avec son num\xE9ro de s\xE9rie et son code de liaison /jeton de licence",
            linkingCode: "Code/Jeton",
            table: {
              serial: "Num\xE9ro de s\xE9rie",
              model: "Mod\xE8le",
              location: "Emplacement",
              orderId: "ID d'ordre",
              orderDate: "Date d'ordre"
            }
          },
          wans: {
            sectionTitle: "WAN",
            message: "Votre dispositif offre un total de {{portsAvailable}} ports disponibles pour une d\xE9signation WAN et LAN.",
            message2_site: "Combien de connexions WAN voulez-vous configurer pour ce site ?",
            message2_gateway: "Combien de connexions WAN voulez-vous configurer pour cet Edge Service ?",
            size: "Nombre de WAN",
            setup: "Configurer un lien WAN {{step}}",
            name: "Nom",
            port: "Port",
            virtualLanId: "ID de LAN virtuel",
            type: "Type",
            providerPinning: "Sp\xE9cification de fournisseur",
            address: "Adresse IP",
            netmask: "Masque r\xE9seau",
            gateway: "Passerelle",
            pin: "PIN de SIM pour {{serial}}",
            invalidPinFormat: "Le PIN de la SIM doit comporter 4 chiffres",
            SCmessage: "Votre dispositif a un seul port WAN \xE0 configurer.",
            SCmessage_failover: "Votre dispositif peut avoir jusqu'\xE0 2 connexions WAN (une primaire et une de basculement).",
            SCconfigureFailover: "Voulez-vous configurer un basculement pour ce site ?",
            failover: "Basculement WAN",
            setupSC: "Configurer un lien WAN primaire",
            setupSC_0: "Configurer un lien WAN primaire",
            setupSC_1: "Configurer un lien WAN de basculement",
            descriptions: {
              name: "Un nom unique pour ce lien WAN.",
              port: "Le port du dispositif \xE0 utiliser pour ce lien.",
              virtualLanId: "ID LAN virtuel en option pour r\xE9seau (utilis\xE9 en cas de connexion \xE0 un commutateur compatible LAN virtuel).",
              type: "Le type de r\xE9seau \xE0 utiliser pour ce lien.",
              providerPinning: "Le groupe de fournisseurs sur lequel \xE9pingler ce lien. Les groupes de fournisseurs sont utilis\xE9s par les strat\xE9gies SD-WAN pour d\xE9finir le comportement et le d\xE9bit du trafic.",
              address: "L'adresse IP \xE0 assigner au port.",
              netmask: "Le suffixe de masque r\xE9seau CIDR de r\xE9seau pour ce lien.",
              gateway: "L'adresse IP de la passerelle associ\xE9e \xE0 ce lien.",
              pin: "Le PIN de la carte SIM install\xE9e dans ce dispositif."
            },
            bridgeType: {
              name: "Pont",
              address: "Adresse IP",
              gateway: "Passerelle",
              lanPorts: "Ports LAN",
              netmask: "Masque r\xE9seau",
              wanPort: "Port WAN",
              descriptions: {
                address: "L'adresse IP \xE0 assigner au port.",
                gateway: "L'adresse IP de la passerelle associ\xE9e \xE0 ce lien.",
                lanPorts: "Le port LAN \xE0 utiliser",
                netmask: "Le suffixe de masque r\xE9seau CIDR de r\xE9seau",
                wanPort: "Le port WAN \xE0 utiliser"
              }
            },
            pppoeType: {
              name: "Nom d'utilisateur",
              password: "Mot de passe",
              description: {
                name: "Un nom unique pour ce PPPoE.",
                password: "Mot de passe unique"
              }
            },
            validation: {
              mode: "ne peut pas \xEAtre un pont si un Edge Service est s\xE9lectionn\xE9"
            }
          },
          lans: {
            sectionTitle: "LAN",
            message: "Votre dispositif offre un total de {{portsAvailable}} ports disponibles pour une d\xE9signation WAN et LAN. Vous avez allou\xE9 {{wans}} au WAN.",
            message2_site: "Combien de connexions LAN voulez-vous configurer pour ce site ?",
            message2_gateway: "Combien de connexions LAN voulez-vous configurer pour cet Edge Service ?",
            size: "Nombre de LAN",
            setup: "Configurer un lien WAN {{step}}",
            name: "Nom",
            port: "Port",
            virtualLanId: "ID de LAN virtuel",
            address: "Adresse IP",
            netmask: "Masque r\xE9seau",
            dhcpEnable: "Serveur DHCP",
            dhcpDescription: "Description",
            dhcpFirstIp: "Premi\xE8re adresse IP",
            dhcpLastIp: "Derni\xE8re adresse IP",
            dhcpDomain: "Domaine DHCP",
            descriptions: {
              dhcpEnable: "Active le serveur DHCP pour ce lien LAN.",
              name: "Un nom unique pour ce lien LAN.",
              port: "Le port du dispositif \xE0 utiliser pour ce lien.",
              virtualLanId: "ID LAN virtuel en option pour r\xE9seau (utilis\xE9 en cas de connexion \xE0 un commutateur compatible LAN virtuel).",
              address: "L'adresse IP \xE0 assigner au port.",
              netmask: "Le suffixe de masque r\xE9seau CIDR de r\xE9seau \xE0 utiliser pour le r\xE9seau cr\xE9\xE9.",
              dhcpDescription: "Description personnalis\xE9e pour ce r\xE9seau DHCP",
              dhcpFirstIp: "Premi\xE8re adresse IP allouable dans ce r\xE9seau DHCP.",
              dhcpLastIp: "Derni\xE8re adresse IP allouable dans ce r\xE9seau DHCP.",
              dhcpDomain: "Nom de domaine pour ce service DHCP.",
              wifi: {
                ssid: "Le SSID du r\xE9seau Wi-Fi.",
                securityMode: "Le mode de s\xE9curit\xE9 Wi-Fi \xE0 utiliser.",
                passphrase: "La phrase secr\xE8te pour le r\xE9seau."
              },
              type: "Le type d'interface. Elle peut \xEAtre configur\xE9e par d\xE9faut comme une interface LAN ou un Pont."
            },
            type: "Type",
            bridgeType: {
              default: "Port simple",
              name: "Pont",
              address: "Adresse IP",
              lanPorts: "Ports",
              netmask: "Masque r\xE9seau",
              description: {
                address: "L'adresse IP \xE0 assigner au port.",
                lanPorts: "Les ports LAN \xE0 utiliser",
                netmask: "Le suffixe de masque r\xE9seau CIDR de r\xE9seau"
              }
            },
            dhcpRelayType: "DHCP",
            validation: {
              dhcpRelayType: "ne peut pas \xEAtre activ\xE9 si un SecureEdge est d\xE9fini"
            }
          },
          advanced: {
            sectionTitle: "Param\xE8tres avanc\xE9s",
            updateWindowTZ: "Fuseau horaire de fen\xEAtre de mise \xE0 jour",
            primaryDns: "DNS primaire",
            secondaryDns: "DNS secondaire",
            descriptions: {
              updateWindowTZ_site: "Le fuseau horaire pour ce site, utilis\xE9 pour d\xE9terminer sa fen\xEAtre de mise \xE0 jour.",
              updateWindowTZ_gateway: "Le fuseau horaire pour l\u2019Edge Service, utilis\xE9 pour d\xE9terminer sa fen\xEAtre de mise \xE0 jour.",
              primaryDns: "D\xE9finissez manuellement l'adresse du DNS primaire.",
              secondaryDns: "D\xE9finissez manuellement l'adresse du DNS secondaire."
            }
          },
          submit: {
            message_site: "Veuillez examiner les d\xE9tails de votre site et cliquer sur Enregistrer",
            message_gateway: "Veuillez examiner les d\xE9tails de votre Edge Service et cliquer sur Enregistrer",
            name: "Nom",
            model: "Mod\xE8le",
            description: "Description",
            serial: "Num\xE9ro de s\xE9rie",
            gateway: "Edge Service",
            noGateway: "(Aucun)",
            upstream_gateway: "Edge Service amont",
            timezone: "Fuseau horaire",
            status: "Statut de connexion",
            peeringAddress: "Adresse d'appairage",
            wan: "WAN",
            lan: "LAN",
            primaryDns: "DNS primaire",
            secondaryDns: "DNS secondaire",
            haWarning: "Ce site n'est pas HA. Il est recommand\xE9 d'utiliser HA pour les services Private Edge."
          },
          result: {
            success_site: "Le site a bien \xE9t\xE9 cr\xE9\xE9",
            success_gateway: "L\u2019Edge Service a bien \xE9t\xE9 cr\xE9\xE9",
            connectPort: "Assurez-vous de brancher le port <0>{{port}}</0> de l'appareil suivant dans une connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur l'appareil.",
            connectPort_plural: "Assurez-vous de brancher le port <0>{{port}}</0> des appareils suivants dans une connexion Internet compatible DHCP pour autoriser la configuration automatique de vos appareils. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur les appareils.",
            HAMessage: "Connectez le port <0>P1</0> des deux appareils directement avec un c\xE2ble de r\xE9seau. La synchronisation HA d\xE9marre automatiquement.",
            campus: "Pour en savoir plus, voir <0>Barracuda Campus</0>",
            serial: "Num\xE9ro de s\xE9rie : {{serial}}",
            nonstandardPort: "Avertissement : Cette configuration utilise un port non-standard : <0>{{ports}}</0>. Assurez-vous qu'il est activ\xE9 sur votre appareil virtuel.",
            nonstandardPort_plural: "Avertissement : Cette configuration utilise des ports non-standard : <0>{{ports}}</0>. Assurez-vous qu'ils sont activ\xE9s sur votre appareil virtuel."
          },
          promoteResult: {
            primaryMessage: "Le site est correctement promu vers l\u2019Edge Service.",
            secondaryMessage: "Vous pouvez maintenant commencer \xE0 y joindre des sites nouveaux ou existants."
          },
          steps: {
            result: "Termin\xE9",
            submit: "Synth\xE8se"
          },
          types: {
            dynamic: "Dynamique (DHCP)",
            configure_on_branch: "Configur\xE9 sur branche"
          },
          validation: {
            duplicateNetwork: "un r\xE9seau correspondant \xE0 ce port/LAN virtuel a d\xE9j\xE0 \xE9t\xE9 configur\xE9",
            duplicateWwan: "un seul WWAN peut \xEAtre configur\xE9",
            duplicateExpressRoute: "un seul itin\xE9raire express peut \xEAtre configur\xE9",
            duplicateName: "nom d\xE9j\xE0 utilis\xE9 pour une autre connexion",
            secondaryDns: "ne doit pas \xEAtre d\xE9fini en l'absence de DNS primaire",
            availableAsn: "cette valeur ASN est indisponible",
            publicIp: "l'adresse IP doit appartenir \xE0 la plage publique",
            noLans: "doit avoir au moins un LAN",
            exceededPPoE: "quatre PPPoE WAN au maximum peuvent \xEAtre configur\xE9s",
            oneInlineBridge: "un seul pont WAN peut \xEAtre configur\xE9"
          }
        },
        settings: {
          tabTitle: "Param\xE8tres",
          basic: {
            tabTitle: "Informations basiques",
            name: "Nom",
            model: "Mod\xE8le",
            gateway: "Edge Service",
            serials: "Num\xE9ro(s) de s\xE9rie",
            descriptions: {
              gateway_site: "Le Barracuda SecureEdge Edge Service auquel vous voulez que ce site se connecte.",
              gateway_gateway: "Le Barracuda SecureEdge Edge Service auquel vous voulez que ce Edge Service se connecte.",
              name_site: "Un nom unique pour votre site.",
              name_gateway: "Un nom unique pour votre Edge Service.",
              model_site: "Le mod\xE8le de dispositif pour ce site.",
              model_gateway: "Le mod\xE8le de dispositif pour cet Edge Service.",
              serials_site: "Le ou les num\xE9ros de s\xE9ries de dispositif pour ce site.",
              serials_gateway: "Le ou les num\xE9ros de s\xE9rie de dispositif pour cet Edge Service."
            },
            serialEdit: {
              table: {
                status: "Statut",
                serial: "Num\xE9ro de s\xE9rie",
                add: "Ajouter second"
              },
              addTitle: "Ajouter un num\xE9ro de s\xE9rie",
              editTitle: "\xC9changer un num\xE9ro de s\xE9rie",
              deleteTitle: "Retirer un num\xE9ro de s\xE9rie",
              edit_site: "S\xE9lectionnez \xE0 la suite le num\xE9ro de s\xE9rie de l'appareil de remplacement que vous voulez joindre \xE0 ce site. Les appareils peuvent \xEAtre remplac\xE9s uniquement par un autre du m\xEAme mod\xE8le.",
              edit_gateway: "S\xE9lectionnez le num\xE9ro de s\xE9rie de l'appareil de remplacement que vous voulez joindre \xE0 cet Edge Service \xE0 la suite. Les appareils peuvent \xEAtre remplac\xE9s uniquement par un autre du m\xEAme mod\xE8le.",
              edit: "S\xE9lectionnez \xE0 la suite le num\xE9ro de s\xE9rie de l'appareil de remplacement que vous voulez joindre \xE0 ce site. Les appareils peuvent \xEAtre remplac\xE9s uniquement par un autre du m\xEAme mod\xE8le.",
              add_site: "Le fait de joindre un second appareil \xE0 votre site active la fonctionnalit\xE9 HA. Veuillez s\xE9lectionner \xE0 la suite le num\xE9ro de s\xE9rie de votre appareil.",
              add_gateway: "Le fait de joindre un second appareil \xE0 votre Edge Service active la fonctionnalit\xE9 HA. Veuillez s\xE9lectionner \xE0 la suite le num\xE9ro de s\xE9rie de votre appareil.",
              add: "Le fait de joindre un second appareil \xE0 votre site active la fonctionnalit\xE9 HA. Veuillez s\xE9lectionner \xE0 la suite le num\xE9ro de s\xE9rie de votre appareil.",
              delete1_site: "\xCAtes-vous certain de vouloir retirer le num\xE9ro de s\xE9rie {{serial}} de ce site ?",
              delete1_gateway: "\xCAtes-vous certain de vouloir retirer le num\xE9ro de s\xE9rie {{serial}} de cet Edge Service ?",
              delete1: "\xCAtes-vous certain de vouloir retirer le num\xE9ro de s\xE9rie {{serial}} de ce site ?",
              delete2: "Vous allez retirer la fonctionnalit\xE9 HA et d\xE9sactiver l'appareil associ\xE9.",
              save: "Les modifications seront prises en compte apr\xE8s l'enregistrement.",
              saveDelete: "Apr\xE8s l'enregistrement vous pouvez d\xE9connecter l'appareil.",
              saveAdd: "Apr\xE8s l'enregistrement, vous pouvez connecter le nouvel appareil en commen\xE7ant par connecter le port <0>P4</0> de l'appareil dans la connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur l'appareil.",
              saveSCAdd: "Apr\xE8s l'enregistrement, vous pouvez connecter le nouvel appareil en connectant le port WAN \xE0 une connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil.",
              saveEdit: "Apr\xE8s l'enregistrement, vous pouvez d\xE9connecter l'ancien appareil. Connectez alors le nouvel appareil en commen\xE7ant par connecter le port <0>P4</0> de l'appareil dans la connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur l'appareil.",
              saveSCEdit: "Apr\xE8s l'enregistrement, vous pouvez d\xE9connecter l'ancien appareil. Connectez ensuite le nouvel appareil en connectant le port WAN \xE0 une connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil.",
              saveHA: "En outre, connectez le port <0>P1</0> du nouvel appareil et de l'ancien directement avec un c\xE2ble de r\xE9seau. La synchronisation HA d\xE9marre automatiquement.",
              saveEditSecondary: "L'ancien appareil a \xE9t\xE9 configur\xE9 pour HA. Avant de pouvoir l'utiliser \xE0 nouveau, vous devez cr\xE9er une nouvelle image.",
              saveDeleteSecondary: "Cet appareil a \xE9t\xE9 configur\xE9 pour HA. Avant de pouvoir l'utiliser \xE0 nouveau, vous devez cr\xE9er une nouvelle image.",
              saveDeletePrimary_site: "L'autre appareil joint \xE0 ce site a \xE9t\xE9 configur\xE9 pour HA. Vous devez cr\xE9er une nouvelle image apr\xE8s l'enregistrement pour qu'il se reconfigure comme un appareil non-HA. En d'autres termes, attendez-vous \xE0 un temps d'arr\xEAt de votre site jusqu'\xE0 ce que ce processus soit termin\xE9.",
              saveDeletePrimary_gateway: "L'autre appareil joint \xE0 cet Edge Service a \xE9t\xE9 configur\xE9 pour HA. Vous devez cr\xE9er une nouvelle image apr\xE8s l'enregistrement pour qu'il se reconfigure comme un appareil non-HA. En d'autres termes, attendez-vous \xE0 un temps d'arr\xEAt de votre Edge Service jusqu'\xE0 ce que ce processus soit termin\xE9.",
              saveDeletePrimary: "L'autre appareil joint \xE0 ce site a \xE9t\xE9 configur\xE9 pour HA. Vous devez cr\xE9er une nouvelle image apr\xE8s l'enregistrement pour qu'il se reconfigure comme un appareil non-HA. En d'autres termes, attendez-vous \xE0 un temps d'arr\xEAt de votre site jusqu'\xE0 ce que ce processus soit termin\xE9."
            }
          },
          wan: {
            tabTitle: "WAN",
            addTitle: "Ajouter une nouvelle interface WAN",
            addButtonText: "Ajouter une interface WAN",
            addSCButtonText: "Ajouter une solution de repli WAN",
            editTitle: "Modifier une interface WAN",
            deleteTitle: "Supprimer une interface WAN",
            deleteMessage: "\xCAtes-vous certain de vouloir supprimer cette interface WAN ?<0/>Toutes les connexions et strat\xE9gies associ\xE9es avec ce WAN (comme les tunnels IPsec) sont automatiquement retir\xE9es.<0/>La suppression deviendra permanente uniquement apr\xE8s l'enregistrement.",
            name: "Nom",
            port: "Port",
            virtualLanId: "ID de LAN virtuel",
            type: "Type",
            providerPinning: "Sp\xE9cification de fournisseur",
            fallback: "Solution de repli",
            address: "Adresse IP",
            netmask: "Masque r\xE9seau",
            gateway: "Passerelle",
            pin: "PIN de SIM pour {{serial}}",
            scPin: "PIN de SIM",
            invalidPinFormat: "Le PIN de la SIM doit comporter 4 chiffres",
            portWarning: "Les rang\xE9es gris\xE9es ci-dessus indiquent que le port s\xE9lectionn\xE9 n'a pas \xE9t\xE9 activ\xE9 sur l'appareil.",
            failover: "Basculement HA",
            network: "R\xE9seau",
            doublePort: "Port",
            doubleVirtualLanId: "ID de LAN virtuel",
            doubleAddress: "Adresse IP",
            doubleNetmask: "Masque r\xE9seau",
            virtualTransferNetwork: "R\xE9seau de transfert virtuel",
            asn: "ASN itin\xE9raire express",
            expressRoute: "Itin\xE9raire express",
            primary: "Primaire",
            secondary: "Secondaire",
            validatePublicIp: "doit \xEAtre une IP publique pour la connectivit\xE9 utilisateur",
            validateOnePort: "vous pouvez uniquement configurer un port WAN dynamique/statique et un port WWAN",
            descriptions: {
              name: "Un nom unique pour ce lien WAN.",
              port: "Le port du dispositif \xE0 utiliser pour ce lien.",
              virtualLanId: "ID LAN virtuel en option pour r\xE9seau (utilis\xE9 en cas de connexion \xE0 un commutateur compatible LAN virtuel).",
              type: "Le type de r\xE9seau \xE0 utiliser pour ce lien.",
              providerPinning: "Le groupe de fournisseurs sur lequel \xE9pingler ce lien. Les groupes de fournisseurs sont utilis\xE9s par les strat\xE9gies SD-WAN pour d\xE9finir le comportement et le d\xE9bit du trafic.",
              address: "L'adresse IP \xE0 assigner au port.",
              netmask: "Le suffixe de masque r\xE9seau CIDR de r\xE9seau pour ce lien.",
              gateway: "L'adresse IP de la passerelle associ\xE9e \xE0 ce lien.",
              pin: "Le PIN de la carte SIM install\xE9e dans ce dispositif.",
              failover: "En cas d'activation, le port est surveill\xE9 et un \xE9v\xE9nement de basculement HA est ex\xE9cut\xE9 en cas de perte de la connexion. Disponible uniquement si HA est activ\xE9e.",
              doublePort: "Les ports de dispositif \xE0 utiliser pour les liens primaires et secondaires.",
              doubleVirtualLanId: "ID LAN virtuel en option pour les liens primaire et secondaire (utilis\xE9 en cas de connexion \xE0 un commutateur compatible LAN virtuel).",
              doubleAddress: "L'adresse IP \xE0 assigner aux liens primaire et secondaire. Il doit s'agir de la premi\xE8re IP utilisable des plages configur\xE9es dans Azure pour chaque connexion (ex. si 172.16.0.4/30 est configur\xE9e dans Azure, alors renseignez l'adresse IP 172.16.0.5).",
              doubleNetmask: "Le suffixe de masque r\xE9seau CIDR du r\xE9seau pour les liens primaires et secondaires.",
              asn: "Un ASN BGP autog\xE9n\xE9r\xE9 pour ce lien. Il n'y aura pas de conflit avec d'autres ASN g\xE9r\xE9s par SecureEdge.",
              virtualTransferNetwork: "R\xE9seau utilis\xE9 pour r\xE9server les IP des interfaces de transfert virtuel.",
              healthCheck: {
                enabled: "En cas d'activation, l'interface v\xE9rifie p\xE9riodiquement sa sant\xE9.",
                mode: "Le mode de Contr\xF4le de sant\xE9 que vous voulez utiliser.",
                targets: "Adresses IP \xE0 cibler avec le contr\xF4le de sant\xE9 ICMP."
              },
              userNamePassword: "Doit comporter moins de 256 caract\xE8res",
              mtu: "Doit \xEAtre un nombre inf\xE9rieur ou \xE9gal \xE0 1492.",
              additionalAddresses: "Adresses IP additionnelles \xE0 assigner au port."
            },
            bridge: "Pont",
            advanced: "Avanc\xE9",
            basic: "Basique",
            userName: "Nom d'utilisateur",
            password: "Mot de passe",
            mtu: "Taille MTU",
            healthCheck: {
              enabled: "Activer Contr\xF4le de sant\xE9",
              mode: "Contr\xF4le de sant\xE9",
              targets: "Cible de contr\xF4le de sant\xE9"
            },
            warning: "Le changement de type de WAN entra\xEEne la suppression des r\xE8gles NAT d'entr\xE9e ou des tunnels IPSec associ\xE9s.",
            additionalAddresses: "Adresses IP additionnelles",
            addressWarning: "Avertissement : si l'une quelconque des adresses IP WAN WAN que vous avez supprim\xE9es \xE9tait utilis\xE9e dans un tunnel IPsec ou pour une r\xE8gle NAT d'entr\xE9e, l'enregistrement de ces modifications va entra\xEEner la suppression des r\xE8gles associ\xE9es."
          },
          lan: {
            tabTitle: "LAN",
            sectionTitle: "Interfaces LAN",
            addTitle: "Ajouter une nouvelle interface LAN",
            addButtonText: "Ajouter une interface LAN",
            editTitle: "Modifier une interface LAN",
            deleteTitle: "Supprimer une interface LAN",
            deleteMessage1: "\xCAtes-vous certain de vouloir supprimer cette interface LAN ? L'effet sera le suivant :",
            deleteMessage2: "Toutes les r\xE9servations et la configuration DHCP correspondantes seront retir\xE9es.",
            deleteMessage3: "Toutes les r\xE8gles de s\xE9curit\xE9 utilisant cette interface LAN seront mises \xE0 jour ou retir\xE9es.",
            deleteMessage4: "La suppression deviendra permanente uniquement apr\xE8s l'enregistrement.",
            name: "Nom",
            port: "Port",
            virtualLanId: "ID de LAN virtuel",
            address: "Adresse IP",
            netmask: "Masque r\xE9seau",
            dhcpEnable: "Serveur DHCP",
            dhcpFirst: "Premi\xE8re adresse IP",
            dhcpLast: "Derni\xE8re adresse IP",
            dhcpDomain: "Domaine DHCP",
            dhcpRange: "Plage DHCP",
            portWarning: "Les rang\xE9es gris\xE9es ci-dessus indiquent que le port s\xE9lectionn\xE9 n'a pas \xE9t\xE9 activ\xE9 sur l'appareil.",
            failover: "Basculement HA",
            descriptions: {
              name: "Un nom unique pour ce lien LAN.",
              port: "Le port du dispositif \xE0 utiliser pour ce lien.",
              virtualLanId: "ID LAN virtuel en option pour r\xE9seau (utilis\xE9 en cas de connexion \xE0 un commutateur compatible LAN virtuel).",
              address: "L'adresse IP \xE0 assigner au port.",
              netmask: "Le suffixe de masque r\xE9seau CIDR de r\xE9seau \xE0 utiliser pour le r\xE9seau cr\xE9\xE9.",
              dhcpEnable: "Activez le serveur DHCP sur le dispositif pour ce lien.",
              dhcpFirst: "La premi\xE8re IP disponible pour l'assignation DHCP. Elle doit appartenir au r\xE9seau d\xE9fini par le masque r\xE9seau et l'IP de port.",
              dhcpLast: "La derni\xE8re IP disponible pour l'assignation DHCP. Elle doit appartenir au r\xE9seau d\xE9fini par le masque r\xE9seau et l'IP de port.",
              dhcpDomain: "Le nom de domaine \xE0 assigner au serveur DHCP.",
              failover: "En cas d'activation, le port est surveill\xE9 et un \xE9v\xE9nement de basculement HA est ex\xE9cut\xE9 en cas de perte de la connexion. Disponible uniquement si HA est activ\xE9e."
            },
            wifi: {
              validateSsid: "doit uniquement contenir des caract\xE8res alphanum\xE9riques ou '-'"
            }
          },
          route: {
            tabTitle: "Itin\xE9raire",
            sectionTitle: "Itin\xE9raires statiques",
            addTitle: "Ajouter un nouvel itin\xE9raire statique",
            addButtonText: "Ajouter un itin\xE9raire statique",
            editTitle: "Modifier un itin\xE9raire statique",
            deleteTitle: "Supprimer un itin\xE9raire statique",
            deleteMessage: "\xCAtes-vous certain de vouloir supprimer cet itin\xE9raire statique ? La suppression deviendra permanente uniquement apr\xE8s l'enregistrement.",
            name: "Nom",
            address: "R\xE9seau cible",
            netmask: "Masque r\xE9seau",
            gateway: "Passerelle",
            descriptions: {
              name: "Un nom unique pour cet itin\xE9raire.",
              address: "L'adresse r\xE9seau cible pour cet itin\xE9raire.",
              netmask: "Le suffixe de masque r\xE9seau CIDR du r\xE9seau cible.",
              gateway: "La passerelle \xE0 utiliser pour cet itin\xE9raire."
            }
          },
          dhcp: {
            tabTitle: "DHCP",
            networksSectionTitle: "R\xE9seaux DHCP",
            leasesSectionTitle: "Baux DHCP",
            reservationsSectionTitle: "R\xE9servations DHCP",
            dhcpEnable: "Activer un service DHCP",
            addNetworkTitle: "Ajouter un nouveau r\xE9seau DHCP",
            addNetworkButtonText: "Ajouter un r\xE9seau",
            editNetworkTitle: "Modifier un r\xE9seau DHCP",
            deleteNetworkTitle: "Supprimer un r\xE9seau DHCP",
            deleteNetworkMessage: "\xCAtes-vous certain de vouloir supprimer ce r\xE9seau DHCP ? La suppression deviendra permanente uniquement apr\xE8s l'enregistrement.",
            networks: {
              networkSectionTitle: "R\xE9seau",
              basicSectionTitle: "Basique",
              basicTabTitle: "Basique",
              advancedSectionTitle: "Avanc\xE9",
              advancedTabTitle: "Avanc\xE9",
              enable: "Statut DHCP",
              port: "Nom LAN",
              description: "Description",
              firstIp: "Premi\xE8re adresse IP",
              lastIp: "Derni\xE8re adresse IP",
              domain: "Nom de domaine",
              dnsServers: "Serveurs DNS",
              bootFileName: "Nom du fichier de d\xE9marrage",
              defaultLeaseTime: "Dur\xE9e du bail par d\xE9faut",
              maxLeaseTime: "Dur\xE9e du bail maximum",
              winsServers: "Serveurs WINS",
              tftpHostname: "Nom d'h\xF4te TFTP",
              tftpIpAddress: "Adresse IP TFTP",
              vendorOptions: "Options de fournisseur",
              validateMaxLeaseTime: "ne doit pas \xEAtre inf\xE9rieure \xE0 la dur\xE9e du bail par d\xE9faut",
              dhcpRelayType: "DHCP",
              disabled: "D\xE9sactiv\xE9",
              dhcpRelay: "Relais DHCP",
              dhcpService: "Service HCP"
            },
            blockUnknownMac: "Autoriser uniquement les adresses MAC connues",
            addReservationTitle: "Ajouter une nouvelle r\xE9servation DHCP",
            addReservationButtonText: "Ajouter une r\xE9servation",
            editReservationTitle: "Modifier une r\xE9servation DHCP",
            deleteReservationTitle: "Supprimer une r\xE9servation DHCP",
            deleteReservationMessage: "\xCAtes-vous certain de vouloir supprimer cette r\xE9servation DHCP ? La suppression deviendra permanente uniquement apr\xE8s l'enregistrement.",
            leases: {
              reservationSectionTitle: "Baux",
              port: "Nom LAN",
              ip: "Adresse IP",
              mac: "Adresse MAC",
              reserve: "IP r\xE9serv\xE9e",
              noLeases: "Aucun bail actuellement actif. L'affichage des nouveaux baux peut prendre environ 2 minutes."
            },
            reservations: {
              reservationSectionTitle: "R\xE9servation",
              name: "Nom",
              description: "Description",
              port: "Nom LAN",
              ip: "Adresse IP",
              mac: "Adresse MAC",
              clientId: "ID client",
              hostname: "Nom d'h\xF4te",
              validateMac: "doit \xEAtre une adresse MAC valide",
              validateHostname: "doit uniquement contenir des caract\xE8res alphanum\xE9riques, des \xAB\xA0-\xA0\xBB ou \xAB\xA0\xA0\xBB",
              duplicate: "doit \xEAtre unique dans le LAN",
              duplicateName: "doit \xEAtre unique"
            },
            descriptions: {
              dhcpEnable_site: "Activez le prestataire de services DHCP pour ce site.",
              dhcpEnable_gateway: "Activez le prestataire de services DHCP pour cet Edge Service.",
              blockUnknownMac: "En cas d'activation, seules les adresses MAC list\xE9es dans le tableau suivant peuvent utiliser le service DHCP.",
              networks: {
                enable: "Activez le serveur DHCP sur le dispositif pour ce lien.",
                description: "Indiquez une description optionnelle pour cette configuration de serveur DHCP.",
                port: "L'interface LAN sur laquelle ce r\xE9seau DHCP doit \xEAtre configur\xE9.",
                firstIp: "La premi\xE8re IP disponible pour l'assignation DHCP. Elle doit appartenir au r\xE9seau d\xE9fini par le masque r\xE9seau et l'IP de port.",
                lastIp: "La derni\xE8re IP disponible pour l'assignation DHCP. Elle doit appartenir au r\xE9seau d\xE9fini par le masque r\xE9seau et l'IP de port.",
                domain: "En option, d\xE9finissez un nom de domaine \xE0 assigner au serveur DHCP.",
                dnsServers: "En option, d\xE9finissez une liste de serveurs DNS \xE0 utiliser.",
                bootFileName: "En option, d\xE9finissez le nom d'un fichier de d\xE9marrage initial qui est charg\xE9 par un client. Il devrait \xEAtre identifiable pour un protocole de transfert de fichier quelconque que le client pourrait utiliser afin de charger le fichier.",
                defaultLeaseTime: "La longueur par d\xE9faut en secondes \xE0 assigner \xE0 un bail.",
                maxLeaseTime: "La longueur maximum en secondes \xE0 assigner \xE0 un bail.",
                winsServers: "En option, ajoutez une ou plusieurs adresses IP de serveurs WINS.",
                tftpHostname: "En option, d\xE9finissez un nom d'h\xF4te d'un serveur TFTP.",
                tftpIpAddress: "En option, d\xE9finissez l'adresse IP d'un serveur TFTP.",
                vendorOptions: "En option, d\xE9finissez une ou plusieurs options de fournisseur s\xE9par\xE9es par \xAB\xA0;\xA0\xBB."
              },
              reservations: {
                name: "Un nom servant \xE0 identifier cette r\xE9servation.",
                description: "Description optionnelle pour cette r\xE9servation.",
                port: "L'interface LAN sur laquelle effectuer cette r\xE9servation.",
                ip: "L'IP \xE0 r\xE9server pour le client.",
                mac: "L'adresse MAC pour identifier le client.",
                clientId: "L'identificateur client qui identifie le client h\xF4te en cas de demande d'adresse IP.",
                hostname: "Nom d'h\xF4te \xE0 assigner \xE0 cette IP."
              },
              dhcpRelayType: "Choisissez le type DHCP."
            },
            validation: {
              dhcpEnable: "ne peut pas \xEAtre activ\xE9 quand un pont WAN est configur\xE9"
            }
          },
          advanced: {
            tabTitle: "Param\xE8tres avanc\xE9s",
            primaryDns: "DNS primaire",
            secondaryDns: "DNS secondaire",
            rootPassword: "Mot de passe racine",
            rootPasswordConfirmation: "Confirmer le mot de passe racine",
            updateWindowTZ: "Fuseau horaire de fen\xEAtre de mise \xE0 jour",
            disableOMS: "D\xE9sactiver Log Analytics",
            disableSecurityPolicy: "D\xE9sactiver S\xE9curit\xE9",
            disableIpsPolicy: "D\xE9sactiver IPS",
            omsNote: "Vous ignorez ainsi les param\xE8tres de Log Analytics par d\xE9faut.",
            descriptions: {
              updateWindowTZ_site: "Le fuseau horaire pour ce site, utilis\xE9 pour d\xE9terminer sa fen\xEAtre de mise \xE0 jour.",
              updateWindowTZ_gateway: "Le fuseau horaire pour l\u2019Edge Service, utilis\xE9 pour d\xE9terminer sa fen\xEAtre de mise \xE0 jour.",
              primaryDns: "D\xE9finissez manuellement l'adresse du DNS primaire.",
              secondaryDns: "D\xE9finissez manuellement l'adresse du DNS secondaire.",
              rootPassword_site: "Le mot de passe utilisateur racine pour le site.",
              rootPassword_gateway: "Le mot de passe utilisateur racine pour l\u2019Edge Service.",
              disableOMS_site: "D\xE9sactivez les flux de journaux vers Azure Log Analytics pour ce site.",
              disableOMS_gateway: "D\xE9sactivez les flux de journaux vers Azure Log Analytics pour cet Edge Service.",
              disableSecurityPolicy_site: "D\xE9sactivez toutes les strat\xE9gies de s\xE9curit\xE9 pour ce site.",
              disableSecurityPolicy_gateway: "D\xE9sactivez toutes les strat\xE9gies de s\xE9curit\xE9 pour cet Edge Service.",
              disableIpsPolicy_site: "D\xE9sactivez la strat\xE9gie IPS pour ce site.",
              disableIpsPolicy_gateway: "D\xE9sactivez la strat\xE9gie IPS pour cet Edge Service."
            }
          },
          confirmPortNotActivated: "Certains des ports s\xE9lectionn\xE9s ne sont pas actuellement activ\xE9s pour ce dispositif.",
          confirmPortNotActivated_site: "Certains des ports s\xE9lectionn\xE9s ne sont pas actuellement activ\xE9s pour ce site.",
          confirmPortNotActivated_gateway: "Certains des ports s\xE9lectionn\xE9s ne sont pas actuellement activ\xE9s pour cet Edge Service.",
          confirmPointOfEntryBroken: "Ce dispositif est configur\xE9 comme un point d'entr\xE9e et n\xE9cessite un WAN avec une IP publique statique. L'enregistrement sans IP publique statique configur\xE9e va retirer la fonctionnalit\xE9 de point d'entr\xE9e.",
          confirmPointOfEntryBroken_site: "Ce site est configur\xE9 comme un point d'entr\xE9e et n\xE9cessite un WAN avec une IP publique statique. L'enregistrement sans IP publique statique configur\xE9e va retirer la fonctionnalit\xE9 de point d'entr\xE9e.",
          confirmPointOfEntryBroken_gateway: "Cet Edge Service est configur\xE9 comme un point d'entr\xE9e et n\xE9cessite un WAN avec une IP publique statique. L'enregistrement sans IP publique statique configur\xE9e va retirer la fonctionnalit\xE9 de point d'entr\xE9e.",
          confirm: "\xCAtes-vous certain de vouloir proc\xE9der ?"
        },
        support: {
          buttonText: "Acc\xE8s au support",
          dialogTitle: "Acc\xE8s au support",
          message_site: "L'ACL de support et la cl\xE9 SSH fournies ici b\xE9n\xE9ficient d'un acc\xE8s SSH pendant 24 heures.",
          message_gateway: "L'ACL de support et la cl\xE9 SSH fournies ici b\xE9n\xE9ficient d'un acc\xE8s SSH \xE0 cet Edge Service pendant 24 heures.",
          networks: "ACL de support",
          publicKey: "Cl\xE9 publique SSH",
          success_site: "Acc\xE8s au support activ\xE9 pour le site \xAB\xA0{{name}}\xA0\xBB pendant 24 heures.",
          success_gateway: "Acc\xE8s au support activ\xE9 pour l\u2019Edge Service \xAB\xA0{{name}}\xA0\xBB pendant 24 heures.",
          description: {
            networks_site: "Liste s\xE9par\xE9e par des virgules des r\xE9seaux devant b\xE9n\xE9ficier d'un acc\xE8s SSH \xE0 votre site.",
            networks_gateway: "Liste s\xE9par\xE9e par des virgules des r\xE9seaux devant b\xE9n\xE9ficier d'un acc\xE8s SSH \xE0 votre Edge Service.",
            publicKey_site: "La cl\xE9 publique OpenSSH pour acc\xE9der \xE0 votre site.",
            publicKey_gateway: "La cl\xE9 publique OpenSSH pour acc\xE9der \xE0 votre Edge Service."
          },
          cloudGatewayMessage: "Pour acc\xE9der \xE0 un Service Edge pour un WAN virtuel, vous devez aussi activer l'Acc\xE8s au support sur l'<0>Application g\xE9r\xE9e via Azure</0>."
        },
        restart: {
          buttonLabel: "Red\xE9marrer",
          box: {
            menuChoice: "Red\xE9marrer un site",
            dialogTitle: "Red\xE9marrer un site",
            confirmMessage: "Cliquez sur CONFIRMER red\xE9marrera ce site. 5 minutes peuvent \xEAtre n\xE9cessaires. <0/><0/>\xCAtes-vous certain de vouloir proc\xE9der ?",
            confirmMessage_plural: "Cliquez sur CONFIRMER red\xE9marrera les deux appareils de votre paire HA. 5 minutes peuvent \xEAtre n\xE9cessaires. <0/> Seuls les appareils d\xE9clar\xE9s comme \xE9tant en ligne seront affect\xE9s. <0/><0/>\xCAtes-vous certain de vouloir proc\xE9der ?"
          },
          vpn: {
            menuChoice: "Red\xE9marrer un service VPN",
            dialogTitle: "Red\xE9marrer un service VPN",
            confirmMessage: "Cliquez sur CONFIRMER red\xE9marrera le service VPN pour cet appareil. 5 minutes peuvent \xEAtre n\xE9cessaires. <0/><0/>\xCAtes-vous certain de vouloir proc\xE9der ?",
            confirmMessage_plural: "Cliquez sur CONFIRMER red\xE9marrera le service VPN des deux appareils de votre paire HA. 5 minutes peuvent \xEAtre n\xE9cessaires. <0/> Seuls les appareils d\xE9clar\xE9s comme \xE9tant en ligne seront affect\xE9s. <0/><0/>\xCAtes-vous certain de vouloir proc\xE9der ?"
          },
          firmware: {
            menuChoice: "Red\xE9marrage de micrologiciel",
            dialogTitle: "Red\xE9marrer un micrologiciel de pare-feu",
            confirmMessage: "Cliquez sur CONFIRMER red\xE9marrera le micrologiciel pour cet appareil. 5 minutes peuvent \xEAtre n\xE9cessaires. <0/><0/>\xCAtes-vous certain de vouloir proc\xE9der ?",
            confirmMessage_plural: "Cliquez sur CONFIRMER red\xE9marrera le micrologiciel des deux appareils de votre paire HA. 5 minutes peuvent \xEAtre n\xE9cessaires. <0/> Seuls les appareils d\xE9clar\xE9s comme \xE9tant en ligne seront affect\xE9s. <0/><0/>\xCAtes-vous certain de vouloir proc\xE9der ?"
          }
        }
      },
      security: {
        securityEnabled: "S\xE9curit\xE9 activ\xE9e",
        menuTitle: "S\xC9CURIT\xC9",
        pageTitle: "S\xE9curit\xE9",
        comingSoon: "Prochainement",
        protection: {
          tabTitle: "Protection contre les maliciels",
          settings: {
            tabTitle: "Param\xE8tres",
            protection: "Protection contre les maliciels",
            name: "Nom",
            action: "Action",
            description: "Description",
            order: "Ordre",
            source: "Source",
            destination: "Destination",
            defaultAction: "Action par d\xE9faut",
            inputs: {
              enabled: "Activ\xE9"
            },
            descriptions: {
              name: "Un nom unique pour votre r\xE8gle.",
              description: "Une br\xE8ve description de votre r\xE8gle.",
              action: "L'action \xE0 prendre pour le trafic correspondant \xE0 cette strat\xE9gie."
            },
            create: {
              buttonText: "Ajouter une r\xE8gle",
              dialogTitle: "Ajouter une nouvelle r\xE8gle"
            },
            edit: {
              dialogTitle: "Modifier une r\xE8gle"
            },
            delete: {
              title: "Supprimer une r\xE8gle",
              body: "\xCAtes-vous certain de vouloir supprimer cette r\xE8gle ?"
            }
          }
        },
        addRuleButtonText: "Ajouter une r\xE8gle",
        sourceCriteria: {
          any: "Tous",
          section: "Crit\xE8res de source",
          type: "Type",
          site: "Site",
          internet: "Internet",
          siteNetwork: "R\xE9seaux",
          source: "Source",
          application: "Application r\xE9seau personnalis\xE9e",
          applications: "Applications r\xE9seau personnalis\xE9es",
          allSites: "Tous sites",
          allNetworks: "Tous r\xE9seaux",
          addSite: "Ajouter un site",
          editSite: "Modifier un site",
          network: "IP/R\xE9seau",
          userOrGroup: "Utilisateur/Groupe",
          users: "Utilisateurs",
          groups: "Groupes",
          userOrGroupRequired: "au moins un utilisateur ou un groupe est obligatoire",
          userOrGroupDisabled: "Aucun R\xE9pertoire d'utilisateurs configur\xE9. Voir 'Identit\xE9 > Param\xE8tres' pour ajouter un R\xE9pertoire d'utilisateurs.",
          userOrGroupDisabledShort: "Aucun R\xE9pertoire d'utilisateurs configur\xE9.",
          description: "S\xE9lectionnez votre type de source.",
          applicationDescription: "S\xE9lectionnez votre application source.",
          networkDescription: "Ins\xE9rez votre r\xE9seau source.",
          siteDescription: "S\xE9lectionnez votre site source.",
          userDescription: "S\xE9lectionnez votre ou vos utilisateurs sources.",
          groupDescription: "S\xE9lectionnez votre ou vos groupes sources.",
          gatewayNetwork: "R\xE9seaux",
          allOnpremGateways: "Tous les services Private Edge",
          addOnpremGateway: "Ajouter un service Private Edge",
          editOnpremGateway: "Modifier un service Private Edge",
          onpremGateway: "Service Private Edge",
          onpremGatewayDescription: "S\xE9lectionnez votre service Private Edge source"
        },
        destinationCriteria: {
          section: "Crit\xE8res de destination",
          type: "Type",
          destination: "Destination",
          site: "Site",
          siteNetwork: "R\xE9seaux",
          allSites: "Tous sites",
          allNetworks: "Tous r\xE9seaux",
          addSite: "Ajouter un site",
          editSite: "Modifier un site",
          network: "IP/R\xE9seau",
          application: "Application",
          domain: "Domaine",
          category: "Cat\xE9gorie d'URL",
          onPremGateway: "Service Private Edge",
          description: "S\xE9lectionnez votre type de destination.",
          applicationDescription: "S\xE9lectionnez votre application de destination.",
          siteDescription: "S\xE9lectionnez votre site de destination.",
          gatewayDescription: "S\xE9lectionnez votre service Private Edge de destination.",
          networkDescription: "S\xE9lectionnez votre r\xE9seau de destination.",
          gateway: "Edge Service",
          ingressSite: "Site",
          customCategory: "Cat\xE9gories personnalis\xE9es",
          gatewayNetwork: "R\xE9seaux",
          onpremGateway: "Service Private Edge",
          allOnpremGateways: "Tous les services Private Edge",
          addOnpremGateway: "Ajouter un service Private Edge",
          editOnpremGateway: "Modifier un service Private Edge",
          onpremGatewayDescription: "S\xE9lectionnez votre service Private Edge de destination."
        },
        targetCriteria: {
          section: "Crit\xE8res de cible"
        },
        tables: {
          allSites: "Tous sites",
          allGateways: "Tous les Edge Services",
          source: "Source",
          sourceNetwork: "IP/R\xE9seau",
          sourceApplication: "Application r\xE9seau personnalis\xE9e",
          sourceSite: "Site",
          sourceType: "Type de source",
          destination: "Destination",
          destinationSite: "Site",
          destinationApplication: "Application",
          destinationCategories: "Cat\xE9gorie d'URL",
          destinationNetwork: "IP/R\xE9seau",
          destinationDomain: "Domaine",
          destinationType: "Type de destination",
          destinationGateway: "Edge Service de destination",
          destinationWan: "WAN de destination",
          user: "Utilisateur",
          group: "Groupe",
          destinationCustomCategories: "Cat\xE9gorie personnalis\xE9e",
          allOnpremGateways: "Tous les services Private Edge",
          sourceOnpremGateway: "Service Private Edge",
          destinationOnpremGateway: "Service Private Edge"
        },
        applicationControl: {
          siteTabTitle: "ACL de site",
          gatewayTabTitle: "ACL de Edge Service",
          settings: {
            tabTitle: "Param\xE8tres",
            siteApplicationControl: "ACL de site",
            gatewayApplicationControl: "ACL de Edge Service",
            order: "Ordre",
            name: "Nom",
            action: "Action",
            description: "Description",
            defaultAction: "Action par d\xE9faut",
            source: "Source",
            destination: "Destination",
            scope: "Port\xE9e",
            allSites: "Tous sites",
            allGateways: "Tous les Edge Services",
            descriptions: {
              name: "Un nom unique pour votre r\xE8gle.",
              description: "Une br\xE8ve description de votre r\xE8gle.",
              action: "L'action \xE0 prendre (bloquer ou autoriser) pour le trafic correspondant \xE0 cette strat\xE9gie.",
              scope: "Le ou les sites auxquels appliquer cette r\xE8gle.",
              icmp: "Autorisez les dispositifs \xE0 communiquer via ICMP."
            },
            create: {
              buttonText: "Ajouter une r\xE8gle",
              dialogTitle: "Ajouter une nouvelle r\xE8gle"
            },
            edit: {
              dialogTitle: "Modifier une r\xE8gle"
            },
            delete: {
              title: "Supprimer une r\xE8gle",
              body: "\xCAtes-vous certain de vouloir supprimer cette r\xE8gle ?"
            },
            icmp: "ICMP"
          },
          noData: {
            edgeServiceAcl: {
              iconHeader: "Commencez par ajouter une ACL d\u2019Edge Service !",
              labelButton: "Ajouter une ACL d\u2019Edge Service",
              textHeader: "Une ACL d\u2019Edge Service : c'est quoi ?",
              text: "Pour des motifs de s\xE9curit\xE9, vous souhaitez souvent restreindre l'acc\xE8s \xE0 votre ou vos services.<0 /> This Access Control List lets you configure who is allowed to access the service(s) and how"
            },
            siteAcl: {
              iconHeader: "Commencez par ajouter une ACL de site !",
              labelButton: "Ajouter une ACL de site",
              textHeader: "Une ACL de site : c'est quoi ?",
              text: "Pour des motifs de s\xE9curit\xE9, vous souhaitez souvent restreindre l'acc\xE8s \xE0 votre ou vos sites.<0 /> This Access Control List lets you configure who is allowed to access the site(s) and how"
            },
            appResource: {
              iconHeader: "Commencez par ajouter des Apps et Ressources !",
              labelButton: "Ajouter une application personnalis\xE9e",
              textHeader: "Une application personnalis\xE9e : c'est quoi ?",
              text: "Pour un environnement SD-WAN en exploitation, votre configuration doit conna\xEEtre les applications en usage.<0 /> Bien qu'un grand nombre d'applications soient prises en charge d\xE8s le d\xE9part, certaines peuvent ne pas former partie de l'offre initiale.<0 />Vous pouvez ajouter des applications r\xE9seau et Web qui ne sont pas prises en charge ici."
            }
          }
        },
        urlFiltering: {
          tabTitle: "Filtrage URL",
          settings: {
            tabTitle: "Param\xE8tres",
            order: "Ordre",
            name: "Nom",
            description: "Description",
            appliesTo: "S'applique \xE0",
            action: "Action",
            type: "Type",
            rule: "Destination",
            domains: "Domaine",
            categories: "Cat\xE9gorie d'URL",
            defaultAction: "Action par d\xE9faut",
            destinationCriteria: "Crit\xE8res de destination",
            descriptions: {
              name: "Un nom unique pour votre r\xE8gle.",
              description: "Une br\xE8ve description de votre r\xE8gle.",
              action: "L'action \xE0 prendre (bloquer ou autoriser) pour le trafic correspondant \xE0 cette strat\xE9gie.",
              type: "Fondez cette politique sur la correspondance avec le nom de domaine ou la cat\xE9gorie d'URL.",
              domains: "Les domaines utilis\xE9s pour correspondre \xE0 cette strat\xE9gie.",
              silent: "Bloquer la r\xE8gle silencieusement.",
              customCategories: "Les cat\xE9gories personnalis\xE9es utilis\xE9es pour correspondre \xE0 cette strat\xE9gie."
            },
            create: {
              buttonText: "Ajouter une r\xE8gle",
              dialogTitle: "Ajouter une nouvelle r\xE8gle"
            },
            edit: {
              dialogTitle: "Modifier une r\xE8gle"
            },
            validation: {
              emptyCategories: "doit avoir au moins une cat\xE9gorie s\xE9lectionn\xE9e."
            },
            delete: {
              title: "Supprimer une r\xE8gle",
              body: "\xCAtes-vous certain de vouloir supprimer cette r\xE8gle ?"
            },
            silent: "Silencieux",
            customCategories: "Cat\xE9gories personnalis\xE9es",
            destinationDomains: "Domaines ({{domains.0}}, +{{additional}})",
            destinationDomains_1: "Domaines ({{domains.0}})",
            destinationCategories: "Cat\xE9gories ({{activeCategories.0.name}}, +{{additional}})",
            destinationCategories_1: "Cat\xE9gories ({{activeCategories.0.name}})",
            destinationCustomCategories: "Cat\xE9gories personnalis\xE9es ({{activeCategories.0.name}}, +{{additional}})",
            destinationCustomCategories_1: "Cat\xE9gories personnalis\xE9es ({{activeCategories.0.name}})"
          },
          exceptions: {
            tabTitle: "Explicite",
            order: "Ordre",
            name: "Nom",
            description: "Description",
            action: "Action",
            type: "Type",
            rule: "R\xE8gle",
            domain: "Domaine",
            category: "Cat\xE9gorie d'URL",
            domains: "Nom d'h\xF4te de destination",
            categories: "Cat\xE9gorie d'URL",
            source: "Source",
            sourceNetwork: "IP/R\xE9seau source",
            sourceSite: "Site source",
            allSites: "Tous sites",
            allNetworks: "Tous r\xE9seaux",
            sourceSiteNetwork: "R\xE9seaux",
            sourceType: "Type",
            network: "IP/R\xE9seau",
            site: "Site",
            sourceSites: "Site",
            addSite: "Ajouter un site",
            editSite: "Modifier un site",
            destinations: "Destination",
            destination: "IP/R\xE9seau de destination",
            destinationType: "Type",
            destinationCategories: "Cat\xE9gorie d'URL de destination",
            destinationDomains: "Domaine de destination",
            sectionTitle: "Crit\xE8res",
            sourceCriteria: "Crit\xE8res de source",
            destinationCriteria: "Crit\xE8res de destination",
            descriptions: {
              name: "Un nom unique pour cette strat\xE9gie explicite.",
              description: "Une br\xE8ve description de cette strat\xE9gie.",
              action: "L'action \xE0 prendre (bloquer ou autoriser) pour le trafic correspondant \xE0 cette strat\xE9gie.",
              categories: "Les cat\xE9gories utilis\xE9es pour correspondre \xE0 cette strat\xE9gie.",
              domains: "Les domaines utilis\xE9s pour correspondre \xE0 cette strat\xE9gie.",
              customCategories: "Les cat\xE9gories personnalis\xE9es utilis\xE9es pour correspondre \xE0 cette strat\xE9gie.",
              silent: "Bloquer la r\xE8gle silencieusement."
            },
            validation: {
              sourceDest: "doit fournir au moins une source ou destination"
            },
            create: {
              buttonText: "Ajouter",
              dialogTitle: "Ajouter Explicite"
            },
            edit: {
              dialogTitle: "Modifier Explicite"
            },
            delete: {
              title: "Supprimer Explicite",
              body: "\xCAtes-vous certain de vouloir supprimer cette exception ?"
            },
            silent: "Silencieux",
            customCategories: "Cat\xE9gories personnalis\xE9es"
          }
        },
        urlCategories: {
          tabTitle: "Cat\xE9gories d'URL",
          settings: {
            tabTitle: "Param\xE8tres",
            urlCategories: "Cat\xE9gories d'URL",
            description: "Cocher une super cat\xE9gorie d'URL ou une cat\xE9gorie d'URL pour la bloquer"
          },
          blocklists: {
            tabTitle: "Autoriser/Bloquer listes",
            description: "D\xE9finir les URL, IP ou r\xE9seaux \xE0 bloquer /autoriser en permanence",
            inputs: {
              blacklist: "Toujours bloquer",
              whitelist: "Toujours autoriser"
            }
          },
          exceptions: {
            tabTitle: "Explicite",
            categories: "Super cat\xE9gories",
            subCategories: "Cat\xE9gories",
            mode: "Autoriser/Bloquer",
            allow: "Autoriser",
            block: "Bloquer",
            users: "Utilisateurs",
            groups: "Groupes",
            source: "IP/R\xE9seau source",
            time: "Temps",
            validateCategory: "Au moins une super cat\xE9gorie d'URL ou une cat\xE9gorie d'URL doit \xEAtre fournie",
            validateSource: "Au moins un utilisateur, une source ou un groupe doit \xEAtre fourni",
            delete: {
              title: "Supprimer Explicite",
              body: "\xCAtes-vous certain de vouloir supprimer cette exception ? La suppression deviendra permanente uniquement apr\xE8s l'enregistrement."
            },
            create: {
              title: "Ajouter nouvelle Explicite",
              button: "Ajouter"
            },
            edit: {
              title: "Modifier Explicite"
            }
          }
        },
        ips: {
          tabTitle: "IPS",
          settings: {
            tabTitle: "Param\xE8tres",
            falsePositivesTabTitle: "Faux positifs",
            ips: "Pr\xE9vention d'intrusion",
            name: "Nom",
            action: "Action",
            description: "Description",
            block: "Bloquer",
            mode: "Mode",
            log: "Journal",
            order: "Ordre",
            source: "Source",
            exploits: "Exploit",
            destination: "Destination",
            defaultAction: "Action par d\xE9faut",
            descriptions: {
              name: "Un nom unique pour votre r\xE8gle.",
              description: "Une br\xE8ve description de votre r\xE8gle.",
              action: "L'action \xE0 prendre pour le trafic correspondant \xE0 cette strat\xE9gie."
            },
            inputs: {
              enabled: "Activ\xE9"
            },
            create: {
              buttonText: "Ajouter une r\xE8gle",
              dialogTitle: "Ajouter une nouvelle r\xE8gle"
            },
            edit: {
              dialogTitle: "Modifier une r\xE8gle"
            },
            delete: {
              title: "Supprimer une r\xE8gle",
              body: "\xCAtes-vous certain de vouloir supprimer cette r\xE8gle ?"
            }
          }
        },
        sslInterception: {
          tabTitle: "Inspection SSL",
          settings: {
            tabTitle: "Certificats",
            sslInterception: "Inspection SSL",
            name: "Nom",
            action: "Action",
            description: "Description",
            order: "Ordre",
            source: "Source",
            destination: "Destination",
            defaultAction: "Action par d\xE9faut",
            explicitTabTitle: "Param\xE8tres",
            status: "Statut",
            issuer: "\xC9metteur",
            startDate: "Date de d\xE9but",
            endDate: "Date de fin",
            makeActive: "Rendre actif",
            active: "Actif",
            activeCertificate: "Certificat actif",
            makeCertificateActive: "Rendre actif",
            sslCertificates: "Certificats d'inspection SSL",
            sslCertificateAuthorities: "Autorit\xE9 de certification de confiance",
            uploadP12: "T\xE9l\xE9charger PKCS12",
            uploadPEM: "T\xE9l\xE9charger PEM ou CRT",
            passphrase: "Phrase secr\xE8te",
            downloadCertificate: "T\xE9l\xE9charger un certificat de client",
            certificates: {
              create: {
                label: "Ajouter un certificat",
                dialogTitle: "T\xE9l\xE9charger un nouveau certificat"
              },
              edit: {
                dialogTitle: "Modifier des d\xE9tails de certificat"
              },
              delete: {
                title: "Supprimer un certificat",
                body: "\xCAtes-vous certain de vouloir supprimer ce certificat ?",
                disabledMessage: "Impossible de supprimer le certificat actif."
              }
            },
            descriptions: {
              name: "Un nom unique pour votre r\xE8gle.",
              certificateName: "Un nom unique pour votre certificat.",
              description: "Une br\xE8ve description de votre r\xE8gle.",
              certificateDescription: "Une br\xE8ve description de votre certificat.",
              action: "L'action \xE0 prendre pour le trafic correspondant \xE0 cette strat\xE9gie.",
              uploadP12: "T\xE9l\xE9chargez votre certificat PKS12 pour. Il doit contenir une cl\xE9 priv\xE9e.",
              uploadPEM: "T\xE9l\xE9chargez un certificat PEM ou CRT.",
              passphrase: "La phrase secr\xE8te de d\xE9verrouillage du certificat PKS12 (si n\xE9cessaire)."
            },
            inputs: {
              enabled: "Activ\xE9"
            },
            create: {
              buttonText: "Ajouter une r\xE8gle",
              dialogTitle: "Ajouter une nouvelle r\xE8gle"
            },
            edit: {
              dialogTitle: "Modifier une r\xE8gle"
            },
            delete: {
              title: "Supprimer une r\xE8gle",
              body: "\xCAtes-vous certain de vouloir supprimer cette r\xE8gle ?"
            }
          }
        },
        ingressNatRules: {
          settings: {
            order: "Ordre",
            name: "Nom",
            target: "Cible",
            description: "Description",
            source: "Source",
            destination: "Destination",
            gateway: "Edge Service",
            wan: "WAN",
            targetApplicationResource: "Application/Ressource",
            descriptions: {
              name: "Un nom unique pour votre r\xE8gle.",
              description: "Une br\xE8ve description de votre r\xE8gle.",
              target: "La cible du trafic correspondant \xE0 cette r\xE8gle.",
              wan: "S\xE9lectionnez votre interface WAN en fonction de l'IP publique souhait\xE9e.",
              patPublicPort: "Votre port public PAT de destination. Ce champ est obligatoire si la cible est une application r\xE9seau sans port. Si aucun port n'est fourni, alors le port configur\xE9 de l'application cible (ou le premier port de la plage de ports configur\xE9s) est utilis\xE9."
            },
            create: {
              buttonText: "Ajouter une r\xE8gle",
              dialogTitle: "Ajouter une nouvelle r\xE8gle"
            },
            edit: {
              dialogTitle: "Modifier une r\xE8gle"
            },
            delete: {
              title: "Supprimer une r\xE8gle",
              body: "\xCAtes-vous certain de vouloir supprimer cette r\xE8gle ?"
            },
            IPAddress: "Adresse"
          },
          noData: {
            iconHeader: "Commencez par ajouter une r\xE8gle de NAT d'entr\xE9e !",
            labelButton: "Ajouter une r\xE8gle de NAT d'entr\xE9e",
            textHeader: "Un NAT d'entr\xE9e : c'est quoi ?",
            text: "Si vous souhaitez rendre des ressources de r\xE9seau interne disponibles pour le public, utilisez cette section pour d\xE9finir les param\xE8tres de ces connexions.<0 /><0 />Please note that the internal service needs to run on a static public IP address."
          }
        }
      },
      customApps: {
        menuTitle: "APPS PERSONNALIS\xC9ES",
        pageTitle: "Applications personnalis\xE9es",
        table: {
          name: "Nom",
          description: "Description",
          protocol: "Protocole",
          destination: "Destinations",
          category: "Cat\xE9gorie",
          type: "Type"
        },
        dialogs: {
          create: {
            buttonText: "Nouvelle application personnalis\xE9e",
            dialogTitleWeb: "Nouvelle application Web personnalis\xE9e",
            dialogTitleNetwork: "Nouvelle application r\xE9seau personnalis\xE9e",
            menuLabelWeb: "Nouvelle application Web personnalis\xE9e",
            menuLabelNetwork: "Nouvelle application r\xE9seau personnalis\xE9e"
          },
          edit: {
            dialogTitle: "Modifier une application personnalis\xE9e"
          },
          general: {
            sectionTitle: "G\xE9n\xE9ral",
            name: "Nom",
            description: "Description",
            descriptions: {
              name: "Un nom unique pour cette application personnalis\xE9e.",
              description: "Une br\xE8ve description de cette application personnalis\xE9e."
            }
          },
          endpoints: {
            protocol: "Protocole",
            sectionTitle: "Points finaux d'application",
            destination: "Destination",
            port: "Port",
            firstPort: "Premier Port",
            lastPort: "Dernier Port",
            descriptions: {
              protocol: "Le protocole utilis\xE9 par cette application personnalis\xE9e",
              destination: "L'IP, le domaine ou le r\xE9seau de destination auquel cette application personnalis\xE9e envoie le trafic.",
              webAppDestination: "Le nom d'h\xF4te de destination auquel cette application personnalis\xE9e envoie le trafic.",
              port: "Le port utilis\xE9 par cette application personnalis\xE9e",
              firstPort: "FACULTATIF : Le premier port de la plage de ports utilis\xE9 par cette application personnalis\xE9e.",
              lastPort: "FACULTATIF : Le dernier port de la plage de ports utilis\xE9 par cette application personnalis\xE9e."
            }
          },
          category: "Cat\xE9gorie",
          descriptions: {
            category: "La cat\xE9gorie \xE0 laquelle assigner cette application personnalis\xE9e."
          }
        },
        delete: {
          title: "Supprimer {{name}}",
          body: "\xCAtes-vous certain de vouloir supprimer cette application personnalis\xE9e ?"
        }
      },
      sites: {
        auditLog: {
          tabTitle: "Journal d'audit",
          pageTitle: "Journal d'audit"
        },
        connectionHistory: {
          tabTitle: "Connexions r\xE9centes",
          pageTitle: "Connexions r\xE9centes",
          waitingForConnections: "Connexion au site. L'\xE9tablissement de la connexion peut prendre environ 2 minutes. Merci de patienter...",
          emptyData: "Aucune connexion r\xE9cente...",
          pauseRequestsButton: "Pause",
          resumeRequestsButton: "Reprendre"
        },
        dashboard: {
          tabTitle: "Tableau de bord",
          statusCardTitle: "Statut",
          servicesCardTitle: "Services",
          licensesCardTitle: "Licences",
          locationsCardTitle: "Emplacement",
          gateway: {
            tabTitle: "Edge Service",
            name: "Nom",
            region: "R\xE9gion",
            hubName: "Hub",
            vwan: "VWAN",
            size: "Taille"
          },
          siteStatus: {
            title: "Information",
            model: "Mod\xE8le",
            licenses: "Licences",
            serials: "Num\xE9ro(s) de s\xE9rie",
            uptime: "Actif depuis",
            time: "Heure actuelle",
            release: "Version",
            hostname: "Nom d'h\xF4te"
          },
          licenses: {
            type: "Type",
            state: "\xC9tat",
            status: "Statut",
            expirationDate: "Date d'expiration",
            serial: "Num\xE9ro de s\xE9rie",
            noData: "S/O"
          },
          boxResources: {
            title: "Ressources",
            cpuLoad: "CPU",
            cpuLoadError: "Une erreur a \xE9t\xE9 d\xE9tect\xE9e dans la CPU. Veuillez red\xE9marrer le dispositif. Si cette erreur persiste, veuillez contacter le support.",
            memory: "M\xE9moire",
            diskInformation: "Disque dur",
            diskInformationError: "Une erreur a \xE9t\xE9 d\xE9tect\xE9e dans l'une des partitions de disque. Veuillez red\xE9marrer le dispositif. Si cette erreur persiste, veuillez contacter le support."
          },
          interfaces: {
            title: "Interfaces",
            status: "Statut",
            name: "Nom",
            speed: "Vitesse",
            duplex: "Duplex",
            errors: "Erreurs",
            state: {
              ok: "OK",
              warning: "Avertissement",
              error: "Erreur",
              unknown: "Inconnu",
              loading: " "
            }
          },
          deploymentEvents: {
            title: "\xC9v\xE9nements de d\xE9ploiements",
            state: "\xC9tat",
            status: "Statut",
            created: "Date"
          },
          deploymentStatus: {
            title: "Statut de d\xE9ploiement {{serial}}",
            status: "Statut",
            action: "Action",
            message: "Message",
            registered: "Enregistr\xE9",
            lastSeen: "Vu en dernier",
            completed: "Termin\xE9",
            localIp: "IP locale",
            remoteIp: "IP publique",
            siteDeploying: "D\xE9ploiement en cours"
          },
          deploymentLocation: {
            title: "Emplacement de d\xE9ploiement"
          },
          firmware: {
            tabTitle: "Micrologiciel",
            version: "Version",
            hotfixes: "Correctifs install\xE9s",
            window: "Mise \xE0 jour suivante",
            nextUpdate: "Entre {{from}} et {{to}}"
          },
          hotfixes: {
            tabTitle: "Correctifs",
            status: "Statut",
            name: "Nom",
            state: {
              error: "Erreur",
              ok: "OK",
              unknown: "Inconnu",
              warning: "En cours",
              loading: " "
            }
          },
          updates: {
            tabTitle: "Mises \xE0 jour de configuration",
            lastUpdate: "Derni\xE8re mise \xE0 jour",
            lastSeen: "Vu en dernier",
            remoteIp: "IP distante"
          },
          restart: {
            buttonLabel: "Red\xE9marrer",
            box: {
              menuChoice: "Red\xE9marrer un site",
              dialogTitle: "Red\xE9marrer un site",
              confirmMessage: "Cliquez sur CONFIRMER red\xE9marrera ce site. 5 minutes peuvent \xEAtre n\xE9cessaires. <0/><0/>\xCAtes-vous certain de vouloir proc\xE9der ?",
              confirmMessage_plural: "Cliquez sur CONFIRMER red\xE9marrera les deux appareils de votre paire HA. 5 minutes peuvent \xEAtre n\xE9cessaires. <0/> Seuls les appareils d\xE9clar\xE9s comme \xE9tant en ligne seront affect\xE9s. <0/><0/>\xCAtes-vous certain de vouloir proc\xE9der ?"
            },
            vpn: {
              menuChoice: "Red\xE9marrer un service VPN",
              dialogTitle: "Red\xE9marrer un service VPN",
              confirmMessage: "Cliquez sur CONFIRMER red\xE9marrera le service VPN pour cet appareil. 5 minutes peuvent \xEAtre n\xE9cessaires. <0/><0/>\xCAtes-vous certain de vouloir proc\xE9der ?",
              confirmMessage_plural: "Cliquez sur CONFIRMER red\xE9marrera le service VPN des deux appareils de votre paire HA. 5 minutes peuvent \xEAtre n\xE9cessaires. <0/> Seuls les appareils d\xE9clar\xE9s comme \xE9tant en ligne seront affect\xE9s. <0/><0/>\xCAtes-vous certain de vouloir proc\xE9der ?"
            },
            firmware: {
              menuChoice: "Red\xE9marrage de micrologiciel",
              dialogTitle: "Red\xE9marrer un micrologiciel de pare-feu",
              confirmMessage: "Cliquez sur CONFIRMER red\xE9marrera le micrologiciel pour cet appareil. 5 minutes peuvent \xEAtre n\xE9cessaires. <0/><0/>\xCAtes-vous certain de vouloir proc\xE9der ?",
              confirmMessage_plural: "Cliquez sur CONFIRMER red\xE9marrera le micrologiciel des deux appareils de votre paire HA. 5 minutes peuvent \xEAtre n\xE9cessaires. <0/> Seuls les appareils d\xE9clar\xE9s comme \xE9tant en ligne seront affect\xE9s. <0/><0/>\xCAtes-vous certain de vouloir proc\xE9der ?"
            }
          }
        },
        delete: {
          body: "\xCAtes-vous certain de vouloir supprimer ce site ?",
          title: "Supprimer site {{name}}"
        },
        dialog: {
          create: {
            buttonText: "Nouveau site",
            sc: {
              createSC: "Nouveau dispositif IoT",
              dialogTitle: "Nouveau dispositif IoT",
              menuItemDisabled: "Les dispositifs IoT n\xE9cessitent un Edge Service"
            },
            tvt: {
              createTVT: "Nouveau dispositif T/VT",
              dialogTitle: "Nouveau site"
            }
          },
          wans: {
            sectionTitle: "WAN",
            message: "Votre dispositif offre un total de {{portsAvailable}} ports disponibles pour une d\xE9signation WAN et LAN.",
            message2_site: "Combien de connexions WAN voulez-vous configurer pour ce site ?",
            message2_gateway: "Combien de connexions WAN voulez-vous configurer pour cet Edge Service ?",
            size: "Nombre de WAN",
            setup: "Configurer un lien WAN {{step}}",
            name: "Nom",
            port: "Port",
            virtualLanId: "ID de LAN virtuel",
            type: "Type",
            providerPinning: "Sp\xE9cification de fournisseur",
            address: "Adresse IP",
            netmask: "Masque r\xE9seau",
            gateway: "Passerelle",
            pin: "PIN de SIM pour {{serial}}",
            invalidPinFormat: "Le PIN de la SIM doit comporter 4 chiffres",
            SCmessage: "Votre dispositif a un seul port WAN \xE0 configurer.",
            SCmessage_failover: "Votre dispositif peut avoir jusqu'\xE0 2 connexions WAN (une primaire et une de basculement).",
            SCconfigureFailover: "Voulez-vous configurer un basculement pour ce site ?",
            failover: "Basculement WAN",
            setupSCPrimary: "Configurer un lien WAN primaire",
            setupSCFailover: "Configurer un lien WAN de basculement",
            descriptions: {
              name: "Un nom unique pour ce lien WAN.",
              port: "Le port du dispositif \xE0 utiliser pour ce lien.",
              virtualLanId: "ID LAN virtuel en option pour r\xE9seau (utilis\xE9 en cas de connexion \xE0 un commutateur compatible LAN virtuel).",
              type: "Le type de r\xE9seau \xE0 utiliser pour ce lien.",
              providerPinning: "Le groupe de fournisseurs sur lequel \xE9pingler ce lien. Les groupes de fournisseurs sont utilis\xE9s par les strat\xE9gies SD-WAN pour d\xE9finir le comportement et le d\xE9bit du trafic.",
              address: "L'adresse IP \xE0 assigner au port.",
              netmask: "Le suffixe de masque r\xE9seau CIDR de r\xE9seau pour ce lien.",
              gateway: "L'adresse IP de la passerelle associ\xE9e \xE0 ce lien.",
              pin: "Le PIN de la carte SIM install\xE9e dans ce dispositif."
            }
          },
          lans: {
            sectionTitle: "LAN",
            message: "Votre dispositif offre un total de {{portsAvailable}} ports disponibles pour un usage WAN et LAN. Vous avez allou\xE9 {{wans}} ports au WAN.",
            SCmessage: "Votre dispositif dispose d'un total de {{portsAvailable}} ports LAN.",
            message2: "Combien de connexions LAN voulez-vous configurer pour ce site ?",
            size: "Nombre de LAN",
            setup: "Configurer un lien WAN {{step}}",
            name: "Nom",
            port: "Port",
            virtualLanId: "ID de LAN virtuel",
            address: "Adresse IP",
            netmask: "Masque r\xE9seau",
            dhcpEnable: "Serveur DHCP",
            dhcpDescription: "Description",
            dhcpFirstIp: "Premi\xE8re adresse IP",
            dhcpLastIp: "Derni\xE8re adresse IP",
            dhcpDomain: "Domaine DHCP",
            dhcpSectionTitle: "Configuration DHCP",
            wifiSectionTitle: "Configuration Wi-Fi",
            wifi: {
              ssid: "SSID",
              securityMode: "Mode de s\xE9curit\xE9",
              passphrase: "Phrase secr\xE8te",
              passphraseConfirm: "Confirmer la phrase secr\xE8te"
            },
            descriptions: {
              dhcpEnable: "Active le serveur DHCP pour ce lien LAN.",
              name: "Un nom unique pour ce lien LAN.",
              port: "Le port du dispositif \xE0 utiliser pour ce lien.",
              virtualLanId: "ID LAN virtuel en option pour r\xE9seau (utilis\xE9 en cas de connexion \xE0 un commutateur compatible LAN virtuel).",
              address: "L'adresse IP \xE0 assigner au port.",
              netmask: "Le suffixe de masque r\xE9seau CIDR de r\xE9seau \xE0 utiliser pour le r\xE9seau cr\xE9\xE9.",
              dhcpDescription: "Description personnalis\xE9e pour ce r\xE9seau DHCP",
              dhcpFirstIp: "Premi\xE8re adresse IP allouable dans ce r\xE9seau DHCP.",
              dhcpLastIp: "Derni\xE8re adresse IP allouable dans ce r\xE9seau DHCP.",
              dhcpDomain: "Nom de domaine pour ce service DHCP.",
              wifi: {
                ssid: "Le SSID du r\xE9seau Wi-Fi.",
                securityMode: "Le mode de s\xE9curit\xE9 Wi-Fi \xE0 utiliser.",
                passphrase: "La phrase secr\xE8te pour le r\xE9seau."
              }
            }
          },
          advanced: {
            sectionTitle: "Param\xE8tres avanc\xE9s",
            updateWindowTZ: "Fuseau horaire de fen\xEAtre de mise \xE0 jour",
            primaryDns: "DNS primaire",
            secondaryDns: "DNS secondaire",
            descriptions: {
              updateWindowTZ: "Le fuseau horaire pour ce site, utilis\xE9 pour d\xE9terminer sa fen\xEAtre de mise \xE0 jour.",
              primaryDns: "D\xE9finissez manuellement l'adresse du DNS primaire.",
              secondaryDns: "D\xE9finissez manuellement l'adresse du DNS secondaire."
            }
          },
          submit: {
            message: "Veuillez examiner les d\xE9tails de votre site et cliquer sur Enregistrer",
            name: "Nom",
            description: "Description",
            serial: "Num\xE9ro de s\xE9rie",
            gateway: "Edge Service",
            noGateway: "(Aucun)",
            timezone: "Fuseau horaire",
            status: "Statut de connexion",
            peeringAddress: "Adresse d'appairage",
            wan: "WAN",
            lan: "LAN",
            primaryDns: "DNS primaire",
            secondaryDns: "DNS secondaire"
          },
          result: {
            siteCreated: "Le site a bien \xE9t\xE9 cr\xE9\xE9",
            connectPort: "Assurez-vous de brancher le port <0>{{port}}</0> de l'appareil suivant dans une connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur l'appareil.",
            connectPortSc: "Assurez-vous de brancher le port WAN de l'appareil ci-dessous dans une connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil.",
            connectPort_plural: "Assurez-vous de brancher le port <0>{{port}}</0> des appareils suivants dans une connexion Internet compatible DHCP pour autoriser la configuration automatique de vos appareils. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur les appareils.",
            HAMessage: "Connectez le port <0>P1</0> des deux appareils directement avec un c\xE2ble de r\xE9seau. La synchronisation HA d\xE9marre automatiquement.",
            campus: "Pour en savoir plus, voir <0>Barracuda Campus</0>",
            serial: "Num\xE9ro de s\xE9rie : {{serial}}",
            nonstandardPort: "Avertissement : Cette configuration utilise un port non-standard : <0>{{ports}}</0>. Assurez-vous qu'il est activ\xE9 sur votre appareil virtuel.",
            nonstandardPort_plural: "Avertissement : Cette configuration utilise des ports non-standard : <0>{{ports}}</0>. Assurez-vous qu'ils sont activ\xE9s sur votre appareil virtuel."
          },
          steps: {
            result: "Termin\xE9",
            submit: "Synth\xE8se"
          },
          types: {
            dynamic: "Dynamique (DHCP)",
            configure_on_branch: "Configur\xE9 sur branche"
          },
          validation: {
            duplicateNetwork: "un r\xE9seau correspondant \xE0 ce port/LAN virtuel a d\xE9j\xE0 \xE9t\xE9 configur\xE9",
            duplicateWwan: "un seul WWAN peut \xEAtre configur\xE9",
            duplicateSCwan: "un seul WAN dynamique ou statique peut \xEAtre configur\xE9",
            duplicateExpressRoute: "un seul itin\xE9raire express peut \xEAtre configur\xE9",
            duplicateName: "nom d\xE9j\xE0 utilis\xE9 pour une autre connexion",
            secondaryDns: "ne doit pas \xEAtre d\xE9fini en l'absence de DNS primaire",
            availableAsn: "cette valeur ASN est indisponible"
          }
        },
        liveLog: {
          tabTitle: "Connexions actives",
          pageTitle: "Connexions actives",
          waitingForConnections: "Connexion au site. L'\xE9tablissement de la connexion peut prendre environ 2 minutes. Merci de patienter...",
          emptyData: "Aucune connexion actuellement active...",
          pauseRequestsButton: "Pause",
          resumeRequestsButton: "Reprendre"
        },
        menuTitle: "SITES",
        pageTitle: "Sites",
        settings: {
          tabTitle: "Param\xE8tres",
          basic: {
            tabTitle: "Informations basiques",
            name: "Nom",
            model: "Mod\xE8le",
            gateway: "Edge Service",
            serials: "Num\xE9ro(s) de s\xE9rie",
            descriptions: {
              gateway: "Le service Barracuda SecureEdge Edge auquel ce site est connect\xE9.",
              name: "Un nom unique pour votre site.",
              model: "Le mod\xE8le de dispositif pour ce site.",
              serials: "Le ou les num\xE9ros de s\xE9ries de dispositif pour ce site."
            },
            serialEdit: {
              table: {
                status: "Statut",
                serial: "Num\xE9ro de s\xE9rie",
                add: "Ajouter second"
              },
              addTitle: "Ajouter un num\xE9ro de s\xE9rie",
              editTitle: "\xC9changer un num\xE9ro de s\xE9rie",
              deleteTitle: "Retirer un num\xE9ro de s\xE9rie",
              edit: "S\xE9lectionnez \xE0 la suite le num\xE9ro de s\xE9rie de l'appareil de remplacement que vous voulez joindre \xE0 ce site. Les appareils peuvent \xEAtre remplac\xE9s uniquement par un autre du m\xEAme mod\xE8le.",
              add: "Le fait de joindre un second appareil \xE0 votre site active la fonctionnalit\xE9 HA. Veuillez s\xE9lectionner \xE0 la suite le num\xE9ro de s\xE9rie de vos appareils.",
              delete1: "\xCAtes-vous certain de vouloir retirer le num\xE9ro de s\xE9rie {{serial}} de ce site ?",
              delete2: "Vous allez retirer la fonctionnalit\xE9 HA et d\xE9sactiver l'appareil associ\xE9.",
              save: "Les modifications seront prises en compte apr\xE8s l'enregistrement.",
              saveDelete: "Apr\xE8s l'enregistrement vous pouvez d\xE9connecter l'appareil.",
              saveAdd: "Apr\xE8s l'enregistrement, vous pouvez connecter le nouvel appareil en commen\xE7ant par connecter le port <0>P4</0> de l'appareil dans la connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur l'appareil.",
              saveEdit: "Apr\xE8s l'enregistrement, vous pouvez d\xE9connecter l'ancien appareil. Connectez alors le nouvel appareil en commen\xE7ant par connecter le port <0>P4</0> de l'appareil dans la connexion Internet compatible DHCP pour autoriser la configuration automatique de votre appareil. Si vous souhaitez utiliser un autre port, vous pouvez le configurer directement sur l'appareil.",
              saveHA: "En outre, connectez le port <0>P1</0> du nouvel appareil et de l'ancien directement avec un c\xE2ble de r\xE9seau. La synchronisation HA d\xE9marre automatiquement.",
              saveEditSecondary: "L'ancien appareil a \xE9t\xE9 configur\xE9 pour HA. Avant de pouvoir l'utiliser \xE0 nouveau, vous devez cr\xE9er une nouvelle image.",
              saveDeleteSecondary: "Cet appareil a \xE9t\xE9 configur\xE9 pour HA. Avant de pouvoir l'utiliser \xE0 nouveau, vous devez cr\xE9er une nouvelle image.",
              saveDeletePrimary: "L'autre appareil joint \xE0 ce site a \xE9t\xE9 configur\xE9 pour HA. Vous devez cr\xE9er une nouvelle image apr\xE8s l'enregistrement pour qu'il se reconfigure comme un appareil non-HA. En d'autres termes, attendez-vous \xE0 un temps d'arr\xEAt de votre site jusqu'\xE0 ce que ce processus soit termin\xE9."
            }
          },
          wan: {
            tabTitle: "WAN",
            addTitle: "Ajouter une nouvelle interface WAN",
            addButtonText: "Ajouter une interface WAN",
            editTitle: "Modifier une interface WAN",
            deleteTitle: "Supprimer une interface WAN",
            deleteMessage: "\xCAtes-vous certain de vouloir supprimer cette interface WAN ? <0> La suppression deviendra permanente uniquement apr\xE8s l'enregistrement.",
            name: "Nom",
            port: "Port",
            virtualLanId: "ID de LAN virtuel",
            type: "Type",
            providerPinning: "Sp\xE9cification de fournisseur",
            address: "Adresse IP",
            netmask: "Masque r\xE9seau",
            gateway: "Passerelle",
            pin: "PIN de SIM pour {{serial}}",
            scPin: "PIN de SIM",
            invalidPinFormat: "Le PIN de la SIM doit comporter 4 chiffres",
            portWarning: "Les rang\xE9es gris\xE9es ci-dessus indiquent que le port s\xE9lectionn\xE9 n'a pas \xE9t\xE9 activ\xE9 sur l'appareil.",
            failover: "Basculement HA",
            network: "R\xE9seau",
            doublePort: "Port",
            doubleVirtualLanId: "ID de LAN virtuel",
            doubleAddress: "Adresse IP",
            doubleNetmask: "Masque r\xE9seau",
            virtualTransferNetwork: "R\xE9seau de transfert virtuel",
            asn: "ASN itin\xE9raire express",
            expressRoute: "Itin\xE9raire express",
            primary: "Primaire",
            secondary: "Secondaire",
            validatePublicIp: "doit \xEAtre une IP publique pour la connectivit\xE9 utilisateur",
            descriptions: {
              name: "Un nom unique pour ce lien WAN.",
              port: "Le port du dispositif \xE0 utiliser pour ce lien.",
              virtualLanId: "ID LAN virtuel en option pour r\xE9seau (utilis\xE9 en cas de connexion \xE0 un commutateur compatible LAN virtuel).",
              type: "Le type de r\xE9seau \xE0 utiliser pour ce lien.",
              providerPinning: "Le groupe de fournisseurs sur lequel \xE9pingler ce lien. Les groupes de fournisseurs sont utilis\xE9s par les strat\xE9gies SD-WAN pour d\xE9finir le comportement et le d\xE9bit du trafic.",
              address: "L'adresse IP \xE0 assigner au port.",
              netmask: "Le suffixe de masque r\xE9seau CIDR de r\xE9seau pour ce lien.",
              gateway: "L'adresse IP de la passerelle associ\xE9e \xE0 ce lien.",
              pin: "Le PIN de la carte SIM install\xE9e dans ce dispositif.",
              failover: "En cas d'activation, le port est surveill\xE9 et un \xE9v\xE9nement de basculement HA est ex\xE9cut\xE9 en cas de perte de la connexion. Disponible uniquement sur un site avec HA activ\xE9e",
              doublePort: "Les ports de dispositif \xE0 utiliser pour les liens primaires et secondaires.",
              doubleVirtualLanId: "ID LAN virtuel en option pour les liens primaire et secondaire (utilis\xE9 en cas de connexion \xE0 un commutateur compatible LAN virtuel).",
              doubleAddress: "L'adresse IP \xE0 assigner aux liens primaire et secondaire. Il doit s'agir de la premi\xE8re IP utilisable des plages configur\xE9es dans Azure pour chaque connexion (ex. si 172.16.0.4/30 est configur\xE9e dans Azure, alors renseignez l'adresse IP 172.16.0.5).",
              doubleNetmask: "Le suffixe de masque r\xE9seau CIDR du r\xE9seau pour les liens primaires et secondaires.",
              asn: "Un ASN BGP autog\xE9n\xE9r\xE9 pour ce lien. Il ne sera pas en conflit avec d'autres ASN g\xE9r\xE9s au sein du service Cloud Gen WAN.",
              virtualTransferNetwork: "R\xE9seau utilis\xE9 pour r\xE9server les IP des interfaces de transfert virtuel."
            }
          },
          lan: {
            tabTitle: "LAN",
            sectionTitle: "Interfaces LAN",
            addTitle: "Ajouter une nouvelle interface LAN",
            addButtonText: "Ajouter une interface LAN",
            editTitle: "Modifier une interface LAN",
            deleteTitle: "Supprimer une interface LAN",
            deleteMessage1: "\xCAtes-vous certain de vouloir supprimer cette interface LAN ? L'effet sera le suivant :",
            deleteMessage2: "Toutes les r\xE9servations et la configuration DHCP correspondantes seront retir\xE9es.",
            deleteMessage3: "Toutes les r\xE8gles de s\xE9curit\xE9 utilisant cette interface LAN seront mises \xE0 jour ou retir\xE9es.",
            deleteMessage4: "La suppression deviendra permanente uniquement apr\xE8s l'enregistrement.",
            name: "Nom",
            port: "Port",
            virtualLanId: "ID de LAN virtuel",
            address: "Adresse IP",
            netmask: "Masque r\xE9seau",
            dhcpEnable: "Serveur DHCP",
            dhcpFirst: "Premi\xE8re adresse IP",
            dhcpLast: "Derni\xE8re adresse IP",
            dhcpDomain: "Domaine DHCP",
            dhcpRange: "Plage DHCP",
            portWarning: "Les rang\xE9es gris\xE9es ci-dessus indiquent que le port s\xE9lectionn\xE9 n'a pas \xE9t\xE9 activ\xE9 sur l'appareil.",
            failover: "Basculement HA",
            descriptions: {
              name: "Un nom unique pour ce lien LAN.",
              port: "Le port du dispositif \xE0 utiliser pour ce lien.",
              virtualLanId: "ID LAN virtuel en option pour r\xE9seau (utilis\xE9 en cas de connexion \xE0 un commutateur compatible LAN virtuel).",
              address: "L'adresse IP \xE0 assigner au port.",
              netmask: "Le suffixe de masque r\xE9seau CIDR de r\xE9seau \xE0 utiliser pour le r\xE9seau cr\xE9\xE9.",
              dhcpEnable: "Activez le serveur DHCP sur le dispositif pour ce lien.",
              dhcpFirst: "La premi\xE8re IP disponible pour l'assignation DHCP. Elle doit appartenir au r\xE9seau d\xE9fini par le masque r\xE9seau et l'IP de port.",
              dhcpLast: "La derni\xE8re IP disponible pour l'assignation DHCP. Elle doit appartenir au r\xE9seau d\xE9fini par le masque r\xE9seau et l'IP de port.",
              dhcpDomain: "Le nom de domaine \xE0 assigner au serveur DHCP.",
              failover: "En cas d'activation, le port est surveill\xE9 et un \xE9v\xE9nement de basculement HA est ex\xE9cut\xE9 en cas de perte de la connexion. Disponible uniquement sur un site avec HA activ\xE9e"
            }
          },
          route: {
            tabTitle: "Itin\xE9raire",
            sectionTitle: "Itin\xE9raires statiques",
            addTitle: "Ajouter un nouvel itin\xE9raire statique",
            addButtonText: "Ajouter un itin\xE9raire statique",
            editTitle: "Modifier un itin\xE9raire statique",
            deleteTitle: "Supprimer un itin\xE9raire statique",
            deleteMessage: "\xCAtes-vous certain de vouloir supprimer cet itin\xE9raire statique ? La suppression deviendra permanente uniquement apr\xE8s l'enregistrement.",
            name: "Nom",
            address: "R\xE9seau cible",
            netmask: "Masque r\xE9seau",
            gateway: "Passerelle",
            descriptions: {
              name: "Un nom unique pour cet itin\xE9raire.",
              address: "L'adresse r\xE9seau cible pour cet itin\xE9raire.",
              netmask: "Le suffixe de masque r\xE9seau CIDR du r\xE9seau cible.",
              gateway: "La passerelle \xE0 utiliser pour cet itin\xE9raire."
            }
          },
          dhcp: {
            tabTitle: "DHCP",
            networksSectionTitle: "R\xE9seaux DHCP",
            leasesSectionTitle: "Baux DHCP",
            reservationsSectionTitle: "R\xE9servations DHCP",
            dhcpEnable: "Activer un service DHCP",
            addNetworkTitle: "Ajouter un nouveau r\xE9seau DHCP",
            addNetworkButtonText: "Ajouter un r\xE9seau",
            editNetworkTitle: "Modifier un r\xE9seau DHCP",
            deleteNetworkTitle: "Supprimer un r\xE9seau DHCP",
            deleteNetworkMessage: "\xCAtes-vous certain de vouloir supprimer ce r\xE9seau DHCP ? La suppression deviendra permanente uniquement apr\xE8s l'enregistrement.",
            networks: {
              networkSectionTitle: "R\xE9seau",
              basicSectionTitle: "Basique",
              basicTabTitle: "Basique",
              advancedSectionTitle: "Avanc\xE9",
              advancedTabTitle: "Avanc\xE9",
              enable: "Plage DHCP active",
              port: "Nom LAN",
              description: "Description",
              firstIp: "Premi\xE8re adresse IP",
              lastIp: "Derni\xE8re adresse IP",
              domain: "Nom de domaine",
              dnsServers: "Serveurs DNS",
              bootFileName: "Nom du fichier de d\xE9marrage",
              defaultLeaseTime: "Dur\xE9e du bail par d\xE9faut",
              maxLeaseTime: "Dur\xE9e du bail maximum",
              winsServers: "Serveurs WINS",
              tftpHostname: "Nom d'h\xF4te TFTP",
              tftpIpAddress: "Adresse IP TFTP",
              vendorOptions: "Options de fournisseur",
              validateMaxLeaseTime: "ne doit pas \xEAtre inf\xE9rieure \xE0 la dur\xE9e du bail par d\xE9faut"
            },
            blockUnknownMac: "Autoriser uniquement les adresses MAC connues",
            addReservationTitle: "Ajouter une nouvelle r\xE9servation DHCP",
            addReservationButtonText: "Ajouter une r\xE9servation",
            editReservationTitle: "Modifier une r\xE9servation DHCP",
            deleteReservationTitle: "Supprimer une r\xE9servation DHCP",
            deleteReservationMessage: "\xCAtes-vous certain de vouloir supprimer cette r\xE9servation DHCP ? La suppression deviendra permanente uniquement apr\xE8s l'enregistrement.",
            leases: {
              reservationSectionTitle: "Baux",
              port: "Nom LAN",
              ip: "Adresse IP",
              mac: "Adresse MAC",
              reserve: "IP r\xE9serv\xE9e",
              noLeases: "Aucun bail actuellement actif. L'affichage des nouveaux baux peut prendre environ 2 minutes."
            },
            reservations: {
              reservationSectionTitle: "R\xE9servation",
              name: "Nom",
              description: "Description",
              port: "Nom LAN",
              ip: "Adresse IP",
              mac: "Adresse MAC",
              clientId: "ID client",
              hostname: "Nom d'h\xF4te",
              validateMac: "doit \xEAtre une adresse MAC valide",
              validateHostname: "doit uniquement contenir des caract\xE8res alphanum\xE9riques, des \xAB\xA0-\xA0\xBB ou \xAB\xA0\xA0\xBB",
              duplicate: "doit \xEAtre unique dans le LAN",
              duplicateName: "doit \xEAtre unique"
            },
            descriptions: {
              dhcpEnable: "Activez le prestataire de services DHCP pour ce site.",
              blockUnknownMac: "En cas d'activation, seules les adresses MAC list\xE9es dans le tableau suivant peuvent utiliser le service DHCP.",
              networks: {
                enable: "Activez le serveur DHCP sur le dispositif pour ce lien.",
                description: "Indiquez une description optionnelle pour cette configuration de serveur DHCP.",
                port: "L'interface LAN sur laquelle ce r\xE9seau DHCP doit \xEAtre configur\xE9.",
                firstIp: "La premi\xE8re IP disponible pour l'assignation DHCP. Elle doit appartenir au r\xE9seau d\xE9fini par le masque r\xE9seau et l'IP de port.",
                lastIp: "La derni\xE8re IP disponible pour l'assignation DHCP. Elle doit appartenir au r\xE9seau d\xE9fini par le masque r\xE9seau et l'IP de port.",
                domain: "En option, d\xE9finissez un nom de domaine \xE0 assigner au serveur DHCP.",
                dnsServers: "En option, d\xE9finissez une liste de serveurs DNS \xE0 utiliser.",
                bootFileName: "En option, d\xE9finissez le nom d'un fichier de d\xE9marrage initial qui est charg\xE9 par un client. Il devrait \xEAtre identifiable pour un protocole de transfert de fichier quelconque que le client pourrait utiliser afin de charger le fichier.",
                defaultLeaseTime: "La longueur par d\xE9faut en secondes \xE0 assigner \xE0 un bail.",
                maxLeaseTime: "La longueur maximum en secondes \xE0 assigner \xE0 un bail.",
                winsServers: "En option, ajoutez une ou plusieurs adresses IP de serveurs WINS.",
                tftpHostname: "En option, d\xE9finissez un nom d'h\xF4te d'un serveur TFTP.",
                tftpIpAddress: "En option, d\xE9finissez l'adresse IP d'un serveur TFTP.",
                vendorOptions: "En option, d\xE9finissez une ou plusieurs options de fournisseur s\xE9par\xE9es par \xAB\xA0;\xA0\xBB."
              },
              reservations: {
                name: "Un nom servant \xE0 identifier cette r\xE9servation.",
                description: "Description optionnelle pour cette r\xE9servation.",
                port: "L'interface LAN sur laquelle effectuer cette r\xE9servation.",
                ip: "L'IP \xE0 r\xE9server pour le client.",
                mac: "L'adresse MAC pour identifier le client.",
                clientId: "L'identificateur client qui identifie le client h\xF4te en cas de demande d'adresse IP.",
                hostname: "Nom d'h\xF4te \xE0 assigner \xE0 cette IP."
              }
            }
          },
          advanced: {
            tabTitle: "Param\xE8tres avanc\xE9s",
            primaryDns: "DNS primaire",
            secondaryDns: "DNS secondaire",
            rootPassword: "Mot de passe racine",
            rootPasswordConfirmation: "Confirmer le mot de passe racine",
            updateWindowTZ: "Fuseau horaire de fen\xEAtre de mise \xE0 jour",
            disableOMS: "D\xE9sactiver Log Analytics",
            disableSecurityPolicy: "D\xE9sactiver S\xE9curit\xE9",
            disableIpsPolicy: "D\xE9sactiver IPS",
            omsNote: "Vous ignorez ainsi les param\xE8tres de Log Analytics par d\xE9faut.",
            descriptions: {
              updateWindowTZ: "Le fuseau horaire pour ce site, utilis\xE9 pour d\xE9terminer sa fen\xEAtre de mise \xE0 jour.",
              primaryDns: "D\xE9finissez manuellement l'adresse du DNS primaire.",
              secondaryDns: "D\xE9finissez manuellement l'adresse du DNS secondaire.",
              rootPassword: "Le mot de passe utilisateur racine pour le site.",
              disableOMS: "D\xE9sactivez les flux de journaux vers Azure Log Analytics pour ce site.",
              disableSecurityPolicy: "D\xE9sactivez toutes les strat\xE9gies de s\xE9curit\xE9 pour ce site.",
              disableIpsPolicy: "D\xE9sactivez la strat\xE9gie IPS pour ce site."
            },
            downloadBootstrapConfigDialog: {
              button: "bouton",
              buttons: "boutons",
              buttonLabel: "Configuration d'amor\xE7age",
              title: "T\xE9l\xE9charger une configuration d'amor\xE7age",
              dialogMessage: `Les appareils doivent automatiquement se connecter au SecureEdge et t\xE9l\xE9charger leur configuration (dans la mesure o\xF9 ils b\xE9n\xE9ficient d'une connexion Internet compatible DHCP).

If an internet connection cannot be easily provided, you can configure the appliance using the "Barracuda Firewall Install" tool and a USB flash drive. See <0>here</0> for documentation on this tool.

Use the {{button}} below to download the configuration files. These files should be provided as "CGW Configurations" during the Barracuda Firewall Install setup.`,
              dialogMessageSc: "Les appareils doivent automatiquement se connecter au SecureEdge et t\xE9l\xE9charger leur configuration (dans la mesure o\xF9 ils b\xE9n\xE9ficient d'une connexion Internet compatible DHCP).\n\nUse the {{button}} below to download the configuration file.\nThis file should be copied to the root directory of a USB Flash drive.\nIf the USB storage is present during boot the configuration will be applied.",
              downloadButtonLabel: "T\xE9l\xE9charger",
              serial: "Num\xE9ro de s\xE9rie {{serial}}"
            }
          },
          confirm: "Certains des ports s\xE9lectionn\xE9s ne sont pas actuellement activ\xE9s pour ce site. \xCAtes-vous certain de vouloir proc\xE9der ?"
        },
        table: {
          name: "Nom",
          description: "Description",
          serial: "Num\xE9ro de s\xE9rie",
          model: "Mod\xE8le",
          gateway: "Edge Service",
          cloudVirtualWan: "Cloud VWAN",
          privateCloud: "Aucun",
          status: "Statut de connexion",
          peeringAddress: "Adresse d'appairage",
          asn: "Num\xE9ro AS",
          lan: "LAN",
          wan: "WAN",
          linkAddresses: "Adresses",
          noSites: "Aucun site trouv\xE9.",
          noIot: "Aucun dispositif IoT trouv\xE9.",
          dynamic: "Dynamique",
          wwan: "WWAN",
          vpnSite: "T\xE9l\xE9chargez la configuration VPN du client.",
          vpnSiteDisabled: "La fonctionnalit\xE9 de la connectivit\xE9 utilisateur doit \xEAtre activ\xE9e et un WAN statique doit \xEAtre allou\xE9 pour que la connectivit\xE9 utilisateur puisse ex\xE9cuter cette action.",
          noGateway: "(Aucun)",
          licenseType: "Type de licence",
          licenseExpiration: "Expiration de licence",
          bridge: "Pont"
        },
        siteNotFound: "Impossible de trouver le site que vous recherchez.",
        noData: {
          iconHeader: "Commencez par ajouter un site !",
          textHeader: "Un site : c'est quoi ?",
          text: "Un site indique un endroit connect\xE9 (et prot\xE9g\xE9) par un dispositif de site.<0 />A site device can be deployed as a hardware or virtual appliance - whatever best meets your needs.<0 />Adding a site device to your network setup is a simple and straight-forward process.<0 /><0 />Don\u2019t have a site yet?",
          demoLicenseLink: "<0>Obtenir une licence de d\xE9mo</0>"
        }
      },
      gateways: {
        menuTitle: "EDGE SERVICES",
        pageTitle: "Services Edge",
        noData: {
          iconHeader: "Commencez par ajouter un Edge Service !",
          textHeader: "Un Edge Service : c'est quoi ?",
          text: "Un Edge Service agit comme un point de connexion pour vos sites et vos agents d'acc\xE8s \xE0 distance.<0 /> Les sites et les agents choisissent automatiquement l\u2019Edge Service disponible le plus proche pour optimiser la qualit\xE9 de service."
        },
        table: {
          asn: "Num\xE9ro AS",
          name: "Nom",
          type: "Type",
          upstream: "Amont",
          region: "Emplacement",
          instances: "Instances",
          size: "Taille",
          HA: "(HA)",
          upstream_gateway: "Edge Service amont",
          scaleUnit: "Unit\xE9 d'\xE9chelle",
          sites: "Sites",
          status: "Statut de connexion",
          vpnGateway: "T\xE9l\xE9chargez la configuration VPN du client.",
          vpnGatewayDisabled: "La fonctionnalit\xE9 de la connectivit\xE9 utilisateur doit \xEAtre activ\xE9e pour effectuer cette action.",
          privateCloud: "Service Private Edge",
          managedCloud: "Edge Service",
          noGateway: "(Aucun)",
          mbit: "Mo",
          filters: {
            sizeSelector: "Type de taille",
            scaleUnit: "Unit\xE9 d'\xE9chelle",
            model: "Mod\xE8le",
            bandwidth: "Bande passante"
          }
        },
        dashboard: {
          locationsCardTitle: "Emplacement",
          tabTitle: "Tableau de bord",
          connectedSites: {
            tabTitle: "Sites connect\xE9s"
          },
          firmware: {
            tabTitle: "Micrologiciel",
            version: "Version",
            hotfixes: "Correctifs install\xE9s",
            window: "Mise \xE0 jour suivante",
            nextUpdate: "Entre {{from}} et {{to}}"
          }
        },
        liveLog: {
          tabTitle: "Connexions actives",
          pageTitle: "Connexions actives",
          waitingForConnections: "Connexion \xE0 l\u2019Edge Service. L'\xE9tablissement de la connexion peut prendre environ 2 minutes. Merci de patienter...",
          emptyData: "Aucune connexion actuellement active...",
          pauseRequestsButton: "Pause",
          resumeRequestsButton: "Reprendre"
        },
        connectionHistory: {
          pageTitle: "Connexions r\xE9centes",
          tabTitle: "Connexions r\xE9centes",
          waitingForConnections: "Connexion \xE0 l\u2019Edge Service. L'\xE9tablissement de la connexion peut prendre environ 2 minutes. Merci de patienter...",
          emptyData: "Aucune connexion r\xE9cente...",
          pauseRequestsButton: "Pause",
          resumeRequestsButton: "Reprendre"
        },
        delete: {
          body: "\xCAtes-vous certain de vouloir supprimer cet Edge Service ?",
          title: "Supprimer l\u2019Edge Service {{name}}",
          forbidden: "Pour supprimer cet Edge Service, veuillez supprimer d'abord tous les sites et Edge Services qui lui sont attach\xE9s."
        },
        dialog: {
          basic: {
            name: "Nom",
            sectionTitle: "Informations basiques",
            tabTitle: "Basique"
          },
          create: {
            buttonText: "Nouvel Edge Service",
            cloud: {
              menuLabel: "Edge Service pour WAN virtuel",
              dialogTitle: "G\xE9n\xE9rer un jeton d\u2019Edge Service"
            },
            onPrem: {
              menuLabel: "Service Private Edge",
              dialogTitle: "Nouveau service Private Edge",
              serials: {
                sectionTitle: "Appareils service Private Edge"
              }
            },
            managed: {
              menuLabel: "Edge Service",
              dialogTitle: "Nouvel Edge Service",
              name: "Nom",
              region: "R\xE9gion",
              bandwidth: "Bande passante",
              bandwidthAvailable: "{{availableBandwidth}} Mbps disponibles",
              invalidBandwidth: "Bande passante s\xE9lectionnable maxi. : {{availableBandwidth}} Mo. R\xE9duisez la s\xE9lection de bande passante ou achetez une capacit\xE9 additionnelle pour cr\xE9er ce Service Edge."
            }
          },
          edit: {
            dialogTitle: "Modifier un Edge Service"
          },
          result: {
            managedAppText: "OTP d'app g\xE9r\xE9",
            message: "Veuillez copier le jeton suivant et le coller dans votre d\xE9ploiement <0>Edge Service pour WAN virtuel</0>.",
            managedAppOtp: "Ce jeton est uniquement valide pour un seul d\xE9ploiement et expirera dans 30 minutes."
          },
          steps: {
            result: "Termin\xE9",
            submit: "Configurer"
          },
          validation: {
            duplicateName: "ne doit pas \xEAtre un nom d\u2019Edge Service en doublon"
          }
        },
        settings: {
          tabTitle: "Param\xE8tres",
          labels: {
            secureInternetTraffic: "S\xE9curiser le Trafic Internet des VNET"
          },
          descriptions: {
            secureInternetTraffic: "Activez l'inspection du trafic Internet depuis ce r\xE9seau virtuel avec cet Edge Service."
          },
          editBandwidthWarning: "Le changement de bande passante entra\xEEne l'indisponibilit\xE9 temporaire de l\u2019Edge Service qui doit red\xE9marrer.",
          managed: {
            bandwidth: {
              invalidBandwidth: "Bande passante s\xE9lectionnable maxi. : {{availableBandwidth}} Mo. R\xE9duisez la s\xE9lection de bande passante ou achetez une capacit\xE9 additionnelle pour mettre \xE0 jour ce Service Edge."
            }
          }
        },
        auditLog: {
          tabTitle: "Journal d'audit",
          pageTitle: "Journal d'audit"
        },
        gatewayNotFound: "Impossible de trouver l\u2019Edge Service que vous recherchez."
      },
      logs: {
        pageTitle: "Journaux et reporting",
        audit: "Journal d'audit",
        notifications: "Notifications",
        notificationsMessage: "Configurez les notifications pour les \xE9v\xE9nements syst\xE8mes et administratifs de tous les espaces de travail.",
        auditLog: {
          message: "Journal d'audit indiquant les \xE9v\xE9nements syst\xE8mes et administratifs de tous les espaces de travail.",
          labels: {
            status: "Statut",
            created: "Cr\xE9\xE9",
            type: "Type",
            event: "Nom",
            serial: "Num\xE9ro de s\xE9rie",
            description: "Description",
            remoteAddress: "IP publique",
            createdUsername: "Administrateur"
          }
        },
        connectionHistory: {
          detailsTitle: "D\xE9tails de connexion",
          labels: {
            application: "Application",
            count: "Nombre",
            destinationIP: "IP de destination",
            destinationPort: "Port de destination",
            information: "Information",
            ipProtocol: "Protocole IP",
            lastTime: "Derni\xE8re fois",
            nextHop: "Prochain saut",
            sourceIP: "IP source",
            sourceMac: "MAC source",
            state: "\xC9tat",
            urlCategory: "Cat\xE9gorie d'URL",
            user: "Utilisateur"
          }
        },
        liveLog: {
          detailsTitle: "D\xE9tails de connexion",
          labels: {
            agentId: "ID d'agent",
            appName: "Contexte d'application",
            application: "Application",
            connectionTrafficState: "\xC9tat de connexion",
            destinationBytesDescription: "Octets sortants",
            destinationIp: "IP de destination",
            destinationInterface: "Interface de destination",
            destinationPort: "Port de destination",
            idleTime: "Inactivit\xE9 depuis",
            ipProtocol: "Protocole IP",
            protocol: "Protocole",
            protocolId: "ID de protocole",
            serial: "Num\xE9ro de s\xE9rie",
            sourceBytesDescription: "Octets entrants",
            sourceIp: "IP source",
            sourcePort: "Port source",
            sourceNat: "NAT source",
            speedDescription: "Vitesse",
            startTime: "Heure de d\xE9but",
            state: "\xC9tat",
            target: "Cible",
            type: "Type",
            totalBytesDescription: "Octets totaux",
            urlCategories: "Cat\xE9gories d'URL",
            user: "Utilisateur",
            vpnRoute: "Itin\xE9raire VPN"
          },
          connectionState: {
            initiate: "Trafic unidirectionnel actif",
            fail: "Trafic unidirectionnel inactif",
            sync: "Trafic bidirectionnel",
            closing: "Fermeture de connexion",
            established: "Connexion \xE9tablie",
            httpDeny: "Connexion non \xE9tablie"
          }
        }
      },
      policies: {
        menuTitle: "SD-WAN",
        sdwan: {
          tabTitle: "Cat\xE9gories d'application",
          search: "Rechercher des applications",
          table: {
            category: "Cat\xE9gorie",
            apps: "Applications",
            customApps: "Applications personnalis\xE9es",
            priority: "Priorit\xE9",
            action: "Action",
            fallback: "Solution de repli",
            loadBalancing: "\xC9quilibrage de charge",
            reset: "R\xE9initialiser"
          }
        },
        explicit: {
          delete: {
            title: "Supprimer {{name}}",
            body: "\xCAtes-vous certain de vouloir supprimer cette strat\xE9gie ?"
          },
          tabTitle: "Cat\xE9gories de priorit\xE9",
          table: {
            order: "Ordre",
            name: "Nom",
            apps: "Applications",
            sources: "Source",
            user: "Utilisateur",
            traffic: "Type de trafic",
            priority: "Priorit\xE9",
            action: "Action",
            fallback: "Solution de repli",
            loadBalancing: "\xC9quilibrage de charge"
          },
          noData: {
            iconHeader: "Commencez par ajouter une priorit\xE9 de strat\xE9gie SD-WAN ici !",
            labelButton: "Ajouter une strat\xE9gie de priorit\xE9",
            text: "Votre configuration SecureEdge offre un ensemble commun de strat\xE9gies SD-WAN pr\xEAtes \xE0 l'emploi. <0 />But if your setup requires a different SD-WAN profile for certain applications, you can define your custom policies here."
          }
        },
        dialogs: {
          explicit: {
            create: {
              buttonText: "Ajouter une strat\xE9gie",
              dialogTitle: "Nouvelle strat\xE9gie"
            },
            edit: {
              dialogTitle: "Modifier une strat\xE9gie"
            },
            general: {
              sectionTitle: "G\xE9n\xE9ral",
              name: "Nom",
              description: "Description",
              descriptions: {
                name: "Un nom unique pour cette strat\xE9gie.",
                description: "Une br\xE8ve description de cette strat\xE9gie."
              }
            },
            criteria: {
              sectionTitle: "Crit\xE8res",
              applications: "Applications",
              sources: "IP/R\xE9seau source",
              trafficType: "Type de trafic",
              descriptions: {
                applications: "Applications sp\xE9cifiques auxquelles appliquer cette strat\xE9gie. Au moins une application ou source doit \xEAtre fournie.",
                sources: "IP/R\xE9seau sources sp\xE9cifiques auxquels appliquer cette strat\xE9gie. Au moins une application ou source doit \xEAtre fournie.",
                trafficType: "En option, d\xE9finissez le type de trafic sp\xE9cifique auquel appliquer cette politique."
              }
            },
            action: {
              sectionTitle: "Action",
              priority: "Priorit\xE9",
              action: "Action",
              fallback: "Solution de repli",
              loadBalancing: "\xC9quilibrage de charge",
              forwardErrorCorrection: {
                label: "Optimisation du dernier kilom\xE8tre",
                options: {
                  on: "Activ\xE9",
                  off: "D\xE9sactiv\xE9"
                }
              },
              descriptions: {
                priority: "Le niveau de priorit\xE9 \xE0 appliquer au trafic correspondant \xE0 cette strat\xE9gie.",
                action: "L'action \xE0 prendre sur le trafic correspondant \xE0 cette strat\xE9gie.",
                fallback: "Le comportement de solution de repli \xE0 appliquer au trafic correspondant \xE0 cette strat\xE9gie.",
                loadBalancing: "Le comportement d'\xE9quilibrage de charge \xE0 appliquer au trafic correspondant \xE0 cette strat\xE9gie.",
                forwardErrorCorrection: "Active l'optimisation du dernier kilom\xE8tre pour le trafic correspondant \xE0 cette strat\xE9gie. Les r\xE9seaux avec des pertes peuvent \xEAtre corrig\xE9s mais la consommation de bande passante par les clients augmente."
              }
            },
            validation: {
              required: "une application ou source est n\xE9cessaire"
            }
          }
        }
      },
      sdwanExplicitPolicies: {
        delete: {
          title: "Supprimer la strat\xE9gie {{name}}",
          body: "\xCAtes-vous certain de vouloir supprimer cette strat\xE9gie ?"
        }
      },
      audit: {
        menuTitle: "JOURNAL D'AUDIT",
        pageTitle: "Journal d'audit",
        labels: {
          created: "Cr\xE9\xE9",
          type: "Type",
          event: "Nom",
          serial: "Num\xE9ro de s\xE9rie",
          description: "Description",
          status: "Statut",
          createdUsername: "Administrateur",
          remoteAddress: "IP publique"
        },
        filter: {
          created: {
            days1: "1 jour",
            days3: "3 jours",
            weeks1: "1 semaine",
            weeks2: "2 semaines",
            months1: "1 mois",
            months2: "2 mois",
            months3: "3 mois",
            months6: "6 mois",
            months9: "9 mois",
            years1: "1 an"
          }
        }
      },
      settings: {
        menuTitle: "PARAM\xC8TRES",
        pageTitle: "Param\xE8tres",
        tabs: {
          workspaces: {
            title: "Espaces de travail",
            pageTitle: "G\xE9rer des espaces de travail",
            fields: {
              name: "Nom",
              description: "Description",
              associations: "Sites / Edge Services"
            },
            dialog: {
              create: {
                buttonLabel: "Nouvel espace de travail",
                dialogTitle: "Nouvel espace de travail"
              },
              edit: "Modifier un espace de travail"
            },
            delete: {
              body: "\xCAtes-vous certain de vouloir supprimer cet espace de travail ?",
              title: "Supprimer {{name}}",
              disabled: {
                notEmpty: "Les espaces de travail ne peuvent pas \xEAtre supprim\xE9s si un site ou un Edge Service leur est encore associ\xE9.",
                lastWorkspace: "Vous devez avoir au moins un espace de travail."
              }
            },
            none: "Aucun espace de travail disponible"
          },
          workspaceSettings: {
            title: "Param\xE8tres d'espace de travail"
          },
          updates: {
            title: "Fen\xEAtres de mise \xE0 jour",
            firmware: {
              title: "Fen\xEAtres de mise \xE0 jour de micrologiciel",
              description: "Les fen\xEAtres de mise \xE0 jour du micrologiciel doivent durer au moins 3 heures et \xEAtre ex\xE9cut\xE9es dans le fuseau horaire local de l'appareil.",
              noWindowsMessage: "Aucune fen\xEAtre de mise \xE0 jour d\xE9finie",
              noUpdatesMessage: "Aucune mise \xE0 jour disponible \xE0 installer.",
              noAppliancesMessage: "Aucun appareil ne n\xE9cessite actuellement une mise \xE0 jour.",
              default: {
                label: "Fen\xEAtre de mise \xE0 jour par d\xE9faut :",
                to: "\xE0",
                from: "de",
                setting: "{{from}} <0>\xE0</0> {{to}}"
              },
              create: {
                buttonText: "Nouvelle fen\xEAtre de mise \xE0 jour",
                dialogTitle: "Ajouter une nouvelle fen\xEAtre de mise \xE0 jour"
              },
              edit: {
                dialogTitle: "Modifier une fen\xEAtre de mise \xE0 jour"
              },
              delete: {
                title: "Supprimer une fen\xEAtre de mise \xE0 jour",
                message: "Une fois supprim\xE9e, le site ou l\u2019Edge Service associ\xE9 \xE0 cette fen\xEAtre de mise \xE0 jour utilisera la fen\xEAtre de mise \xE0 jour par d\xE9faut."
              },
              fields: {
                appliance: "Site / Edge Service",
                applianceDescription: "Edge Service ou site autonome \xE0 mettre \xE0 jour durant la fen\xEAtre de mise \xE0 jour",
                from: "De",
                fromDescription: "D\xE9but de la p\xE9riode de temps pendant laquelle les mises \xE0 jour du micrologiciel peuvent \xEAtre automatiquement install\xE9es. La fen\xEAtre horaire est relative au fuseau horaire local automatiquement d\xE9tect\xE9 de chaque Edge Service ou site autonome.",
                to: "\xC0",
                toDescription: "Fin de la p\xE9riode de temps pendant laquelle les mises \xE0 jour du micrologiciel peuvent \xEAtre automatiquement install\xE9es. La fen\xEAtre horaire est relative au fuseau horaire local automatiquement d\xE9tect\xE9 de chaque Edge Service ou site autonome.",
                windowLength: "Vous avez ainsi une fen\xEAtre de mise \xE0 jour du micrologiciel de {{duration}}."
              },
              updateNow: {
                buttonLabel: "Installer maintenant",
                disabledMessage: "Aucune mise \xE0 jour disponible.",
                dialogTitle: "Installer des mises \xE0 jour maintenant",
                dialogMessage: "La mise \xE0 jour va maintenant supprimer toutes les fen\xEAtres de mise \xE0 jour programm\xE9es et mettre \xE0 jour imm\xE9diatement tous les Edge Services et sites autonomes. Les sites avec un Edge Service d\xE9buteront leur mise \xE0 jour une fois leur Edge Service mis \xE0 jour. Un temps d'arr\xEAt est possible."
              },
              validation: {
                insufficientWindow: "La fen\xEAtre de mise \xE0 jour doit durer au moins 3 heures"
              },
              editDefault: {
                dialogTitle: "Modifier une fen\xEAtre de mise \xE0 jour par d\xE9faut"
              }
            },
            security: {
              title: "Fen\xEAtre de mise \xE0 jour de s\xE9curit\xE9",
              window: "Fen\xEAtre de mise \xE0 jour de correctif",
              windowDescription: "La p\xE9riode de temps pendant laquelle les mises \xE0 jour de correctif peuvent \xEAtre automatiquement install\xE9es. La fen\xEAtre horaire est relative au fuseau horaire local d\xE9tect\xE9 automatiquement pour chaque Edge Service ou site. Le fuseau horaire peut \xEAtre ignor\xE9 dans la configuration du site."
            },
            hotfixUpdateWindow: "Fen\xEAtre de mise \xE0 jour de correctif",
            validation: {
              invalidStartTime: "heure de d\xE9but invalide",
              invalidEndTime: "heure de fin invalide"
            }
          },
          oms: {
            title: "Azure Monitor",
            enabled: "Activ\xE9",
            id: "ID d'espace de travail",
            key: "Cl\xE9 d'espace de travail",
            descriptions: {
              oms: "Activez uniquement si vous utilisez Microsoft Operations Management Suite (OMS). C'est en voie d'obsolescence.",
              azureMonitorAgent: "Le nouvel Azure Monitor Agent est l'agent privil\xE9gi\xE9 pour les Edge Services de sites et priv\xE9s.<0 />(Pour la prise en charge de Log Analytics sur Edge Services et Edge Services for Azure, veuillez utiliser la solution OMS \xE0 la suite)."
            },
            clientId: "ID client",
            clientSecret: "Secret de client",
            correlationId: "ID de corr\xE9lation",
            region: "R\xE9gion",
            resourceGroup: "Groupe de ressources",
            subscriptionId: "ID d'abonnement",
            tenantId: "ID de client",
            oms: "Azure Log Analytics (OMS)",
            azureMonitorAgent: "Azure Monitor Agent",
            disabled: "D\xE9sactiv\xE9",
            introduction: "Gr\xE2ce \xE0 Azure Monitor, vous recherchez, analysez et visualisez les donn\xE9es de machine g\xE9n\xE9r\xE9es par vos syst\xE8mes informatiques et votre infrastructure technologique pour en savoir plus sur votre r\xE9seau. Dirigez les journaux vers votre compte Azure Monitor pour approfondir le traitement des donn\xE9es de journal de votre configuration."
          },
          global: {
            title: "Param\xE8tres globaux"
          },
          forwardedDomains: {
            title: "Domaines transf\xE9r\xE9s",
            domains: "Domaines",
            descriptions: {
              domains: "Domaines DNS \xE0 transf\xE9rer vers Azure pour r\xE9solution"
            },
            domain: "Domaine",
            forwarders: "Serveur",
            button: "Ajouter",
            duplicateName: "nom de domaine d\xE9j\xE0 utilis\xE9",
            addDomain: "Ajouter un domaine",
            editDomain: "Modifier un domaine",
            deleteDomain: "Supprimer un domaine"
          },
          vmActivation: {
            title: "Activation de VM",
            firstName: "Pr\xE9nom",
            lastName: "Nom",
            companyName: "Nom de soci\xE9t\xE9",
            phoneNumber: "Num\xE9ro de t\xE9l\xE9phone",
            address: "Ligne d'adresse 1",
            address2: "Ligne d'adresse 2",
            city: "Ville",
            country: "Pays",
            emailAddress: "Adresse e-mail",
            state: "\xC9tat",
            zip: "Code postal",
            descriptions: {
              firstName: "Votre pr\xE9nom.",
              lastName: "Votre nom.",
              companyName: "Le nom de votre soci\xE9t\xE9.",
              phoneNumber: "Le num\xE9ro de t\xE9l\xE9phone de votre soci\xE9t\xE9.",
              address: "La premi\xE8re ligne d'adresse de votre soci\xE9t\xE9.",
              address2: "La seconde ligne d'adresse de votre soci\xE9t\xE9 (le cas \xE9ch\xE9ant).",
              city: "La ville o\xF9 se trouve votre bureau.",
              country: "Le pays o\xF9 se trouve votre bureau.",
              emailAddress: "L'adresse e-mail de votre soci\xE9t\xE9.",
              state: "L'\xE9tat ou le comt\xE9 o\xF9 se trouve votre bureau.",
              zip: "Le code postal de votre bureau."
            }
          },
          notification: {
            title: "Notifications",
            delete: "Supprimer une notification ",
            labels: {
              name: "Nom",
              events: "\xC9v\xE9nements",
              status: "Statut",
              administrators: "Administrateurs",
              actions: "Actions"
            },
            descriptions: {
              name: "Un nom unique pour cette notification.",
              events: "Les \xE9v\xE9nements pour lesquels envoyer des notifications.",
              status: "Les types de statut pour lesquels envoyer des notifications.",
              administrators: "Les adresses e-mail d'administrateurs auxquelles envoyer des notifications.",
              actions: "Les actions pour lesquelles envoyer des notifications."
            },
            components: {
              button: {
                add: "Ajouter",
                edit: "Modifier",
                delete: "Supprimer",
                save: "Enregistrer",
                cancel: "Annuler"
              },
              title: {
                editNotification: "Modifier une notification",
                createNotification: "Cr\xE9er une notification"
              }
            },
            action: {
              deleteNotification: "\xCAtes-vous certain de vouloir supprimer cette notification ?"
            }
          },
          sdwanConnectorNetwork: {
            title: "Connecteur",
            subMenu: "Plage de r\xE9seau client",
            description: "Le r\xE9seau client sert \xE0 assigner des IP \xE0 des ressources derri\xE8re les connecteurs."
          },
          reportCreatorAcl: {
            title: "Report Creator",
            subMenu: "R\xE9seaux autoris\xE9s",
            description: "Saisissez une liste des r\xE9seaux et adresses IP priv\xE9s o\xF9 l'outil Report Creator va rechercher des dispositifs de site et des Services Edge."
          },
          dcAgent: {
            title: "Agent DC",
            description: "Saisissez l'adresse IP de l'Agent DC que vous avez configur\xE9.",
            ipAddress: "Adresse IP"
          },
          dhcpServer: {
            title: "Serveur de relais DHCP",
            description: "Saisissez l'adresse IP du serveur DHCP que vous voulez utiliser.",
            ipAddress: "Adresse IP"
          }
        }
      },
      statusBanner: {
        subscriptionExpiresSoon: "Votre abonnement va expirer dans {{days}} jours",
        subscriptionExpiresOneDay: "Votre abonnement va expirer dans 1 jour",
        subscriptionExpiresToday: "Votre abonnement expire dans moins de 1 jour",
        deprecatedExpiresSoon: "Le plan d'abonnement Azure que vous utilisez n'est plus pris en charge. Ce service va expirer dans {{days}} jours si vous ne le remplacez pas par un nouveau plan. Veuillez changer votre plan {{linkStart}}ici{{linkEnd}}.",
        deprecatedExpiresOneDay: "Le plan d'abonnement Azure que vous utilisez n'est plus pris en charge. Ce service va expirer dans 1 jour si vous ne le remplacez pas par un nouveau plan. Veuillez changer votre plan {{linkStart}}ici{{linkEnd}}",
        deprecatedExpiresToday: "Le plan d'abonnement Azure que vous utilisez n'est plus pris en charge. Ce service va expirer dans moins d'un jour si vous ne le remplacez pas par un nouveau plan. Veuillez changer votre plan {{linkStart}}ici{{linkEnd}}",
        updateAvailable: "Une nouvelle mise \xE0 jour du micrologiciel est disponible ! Planifiez une fen\xEAtre de maintenance avant {{date}} pour d\xE9marrer le processus de mise \xE0 jour.",
        pamBandwidthCapacity: "Votre bande passante de service Edge actuelle d\xE9passe votre quantit\xE9 licenci\xE9e. Veuillez contacter le service commercial pour obtenir plus de bande passante."
      },
      states: {
        pending: "En cours",
        completed: "Termin\xE9",
        online: "En ligne",
        offline: "Hors ligne",
        failed: "\xC9chou\xE9",
        error: "Erreur",
        degraded: "D\xE9grad\xE9",
        connecting: "Connexion en cours",
        unknown: "Inconnu",
        loading: " "
      },
      validation: {
        alphaNumericDash: "doit uniquement contenir des chiffres, des lettres et des tirets",
        alphaNumericDashNoHaSuffix: "ne doit pas contenir le suffixe \xAB -ha \xBB",
        alphaNumericDashSpace: "doit uniquement contenir des chiffres, des lettres, des tirets et des espaces",
        alphaNumericDotsDash: "doit uniquement contenir des chiffres, des lettres, des points et des tirets",
        alphaNumericDotsColonsDash: "doit uniquement contenir des chiffres, des lettres, des points, des deux points et des tirets",
        customKeyword: "doit contenir uniquement un maximum de 128 chiffres, lettres, tirets, points, apostrophes ou espaces",
        uuid: "doit \xEAtre un UUID/GUID valide"
      },
      footer: {
        serial: "  | Num\xE9ro de s\xE9rie d'abonnement : {{serial}}"
      },
      users: {
        table: {
          fullName: "Nom complet",
          email: "E-mail",
          deviceCount: "Nombre d'appareils",
          enrollmentCompleted: {
            label: "Inscription termin\xE9e",
            yes: "Inscription termin\xE9e",
            no: "Inscription en cours"
          },
          enrollmentLink: "Copier un lien d'inscription",
          enrollmentLinkCopied: "Lien d'inscription copi\xE9 dans le presse-papiers",
          resendInvite: "Renvoyer une invitation",
          tamperProof: "Inviolable",
          devicePreConnect: "Connexion pr\xE9alable de dispositif",
          webFiltering: "Filtrage Web",
          optionsOverride: "Priorit\xE9 utilisateur",
          on: "Activ\xE9",
          off: "D\xE9sactiv\xE9"
        },
        delete: {
          title: "D\xE9sinscrire l'utilisateur",
          body: "\xCAtes-vous certain de vouloir retirer cet utilisateur ?"
        },
        dialog: {
          buttonText: "Inscrire des utilisateurs",
          disabledButton: "Vous devez d'abord configurer un R\xE9pertoire d'utilisateurs et un Prestataire d'identit\xE9 dans la page Identit\xE9 > Param\xE8tres."
        },
        noData: {
          iconHeader: "Commencez par ajouter des utilisateurs !",
          labelButton: "Ajouter et inviter des utilisateurs",
          textHeader: "Pourquoi les utilisateurs sont-ils si importants pour SecureEdge Access ?",
          text: "SecureEdge Access contr\xF4le l'acc\xE8s et les privil\xE8ges des utilisateurs sur votre r\xE9seau. C'est donc ici que vous d\xE9terminez qui peut acc\xE9der et d\xE9buter le processus d'inscription des SecureEdge Access Agents.",
          firstTitle: "1",
          firstStep: "Envoyer une invitation d'inscription",
          secondTitle: "2",
          secondStep: "Demandez \xE0 votre utilisateur de t\xE9l\xE9charger, installer et proc\xE9der lui-m\xEAme \xE0 l'inscription de l'agent.",
          textFooter: "Fini. \xC7a marche !"
        },
        settings: {
          global: "Priorit\xE9 utilisateur",
          introduction: "Ignorez les r\xE9glages Global ZTNA pour cet utilisateur.",
          tamperProof: "Inviolable",
          devicePreConnect: "Connexion pr\xE9alable de dispositif",
          deviceLimit: "Limite de dispositif utilisateur",
          webFiltering: "Filtrage Web",
          enabled: "activ\xE9",
          disabled: "d\xE9sactiv\xE9",
          overrideEnabled: "utilisant les r\xE9glages sp\xE9cifi\xE9s \xE0 la suite",
          overrideDisabled: "utilisant les r\xE9glages Global ZTNA",
          descriptions: {
            tamperProof: "Emp\xEAche l'utilisateur de d\xE9sactiver SecureEdge Access Agent. Pour les dispositifs exploit\xE9s sous iOS, iPadOS ou Android, une solution MDM est obligatoire.",
            devicePreConnect: "Assure la gestion des dispositifs utilisateurs exploit\xE9s sous Windows sans que l'utilisateur soit connect\xE9.",
            webFiltering: "Force la rev\xE9rification de tout le trafic Web selon la strat\xE9gie de filtrage Web d\xE9finie.",
            deviceLimit: "dispositifs par utilisateur"
          }
        }
      },
      devices: {
        table: {
          userName: "Utilisateur",
          email: "E-mail",
          brand: "Marque de dispositif",
          model: "Mod\xE8le de dispositif",
          hardwareModel: "Mod\xE8le de mat\xE9riel",
          os: "Syst\xE8me d'exploitation",
          osFlavour: "Nuance OS",
          osVersion: "Version OS",
          enrollmentDate: "Date d'inscription"
        },
        delete: {
          body: "\xCAtes-vous certain de vouloir supprimer ce dispositif ?",
          title: "Supprimer un dispositif"
        },
        noData: {
          iconHeader: "Aucun dispositif inscrit trouv\xE9 !",
          action: "Ajouter et inviter des utilisateurs",
          textHeader: "Un dispositif inscrit : c'est quoi ?",
          text: "D\xE8s que vous avez invit\xE9 un utilisateur \xE0 d\xE9buter le processus d'auto-inscription et qu'il a termin\xE9 les \xE9tapes d'int\xE9gration, tous les dispositifs pour cet utilisateur sur lesquels des SecureEdge Access Agents sont install\xE9s sont list\xE9s ici."
        }
      },
      firewalls: {
        table: {
          name: "Nom",
          serials: "Num\xE9ro de s\xE9rie",
          isCcManaged: "G\xE9r\xE9 par CC",
          model: "Mod\xE8le"
        },
        delete: {
          title: "D\xE9connecter le CloudGen Firewall  {{name}}",
          body: "\xCAtes-vous certain de vouloir d\xE9connecter ce CloudGen Firewall du SecureEdge Service ?",
          forbidden: "Vous ne pouvez pas supprimer les int\xE9grations CloudGen Firewall si elles sont configur\xE9es comme point final."
        },
        dialog: {
          create: {
            buttonText: "Jeton d'enregistrement",
            dialogTitle: "G\xE9n\xE9rer un jeton d'enregistrement"
          },
          result: {
            message: "Veuillez copier le jeton suivant et le coller dans votre <0>formulaire d'activation de Firewall Admin</0>.",
            firewallText: "Jeton d'enregistrement de pare-feu",
            firewallOtp: "Ce jeton est uniquement valide pour un seul pare-feu et expirera dans 30 minutes."
          }
        },
        noData: {
          iconHeader: "Organisez un d\xE9ploiement CloudGen Firewall !",
          labelButton: "Obtenez un jeton d'enregistrement",
          textHeader: "Vous voulez ajouter un CloudGen Firewall \xE0 votre configuration ?",
          text: "Si vous avez d\xE9j\xE0 un Barracuda CloudGen Firewall en activit\xE9, vous pouvez aussi demander ce d\xE9ploiement pour un point d'entr\xE9e pour vos Access Agents. La demande d'un d\xE9ploiement CloudGen Firewall se limite \xE0 copier le jeton d'enregistrement dans la console de gestion pour CloudGen Firewall.<0 /><0 />Notez simplement qu'apr\xE8s la demande du CloudGen Firewall, le composant d'acc\xE8s \xE0 distance n'est plus g\xE9r\xE9 par l'outil Firewall Admin."
        }
      },
      integration: {
        pageTitle: "Int\xE9gration",
        firewalls: "CloudGen Firewall",
        ipSecV2: {
          create: "Cr\xE9er Tunnel IPsec",
          createButton: "Ajouter Tunnel IPsec",
          edit: "Modifier Tunnel IPsec",
          general: "G\xE9n\xE9ral",
          network: "R\xE9seau",
          phases: "Phases",
          sourceDest: "Source/Destination",
          success: "Succ\xE8s",
          successMessage: "Nouveau Tunnel IPSec correctement cr\xE9\xE9",
          pageTitle: "VPN IPsec",
          table: {
            status: "Statut",
            name: "Nom",
            description: "Description",
            enabled: "Activ\xE9",
            gateway: "Passerelle distante",
            localNetworks: "R\xE9seaux locaux",
            localBgp: "BGP local",
            remoteNetworks: "R\xE9seaux distants",
            remoteBgp: "BGP distant",
            type: "Type",
            siteWanInterface: "Pair SecureEdge",
            peerIp: "IP Pair",
            duration: "Dur\xE9e",
            encryption: "Cryptage",
            filters: {
              enabled: {
                enabled: "Activ\xE9",
                disabled: "D\xE9sactiv\xE9"
              },
              secureEdgePeer: {
                peerType: "Type de pair",
                edgeService: "Service Edge",
                site: "Site"
              }
            },
            ellipsisMenu: {
              restart: "Red\xE9marrer le Tunnel IPSec"
            }
          },
          cloudCantInitiate: "seuls les services Edge priv\xE9s et les sites peuvent \xEAtre r\xE9gl\xE9s pour initier des tunnels",
          dialog: {
            create: {
              buttonText: "Ajouter tunnel"
            },
            delete: {
              body: "\xCAtes-vous certain de vouloir supprimer ce Tunnel IPsec ?",
              title: "Supprimer Tunnel IPsec {{name}}"
            },
            restart: {
              ok: "Ok",
              cancel: "Annuler",
              body: "\xCAtes-vous certain de vouloir supprimer ce Tunnel IPsec ?",
              title: "Red\xE9marrer Tunnel IPSec {{name}}",
              message: "Merci de patienter...le tunnel red\xE9marre"
            },
            generalSection: {
              descriptionMessage: "Pour cr\xE9er un nouveau tunnel, d\xE9finissez les param\xE8tres suivants pour le configurer.",
              tunnelStatus: "Activer",
              general: {
                title: "Informations g\xE9n\xE9rales",
                name: "Nom",
                description: "Description",
                initiatesTunnel: "Initier tunnel",
                restartSa: "Red\xE9marrer SA",
                descriptions: {
                  title: "Description d'informations g\xE9n\xE9rales",
                  name: "Un nom unique pour ce Tunnel IPsec.",
                  description: "Description optionnelle du tunnel.",
                  tunnelStatus: "La d\xE9sactivation d\xE9sactive temporairement le tunnel.",
                  initiatesTunnel: "Activez pour que le dispositif SecureEdge initie le Tunnel IPsec."
                }
              },
              authentication: {
                title: "Authentification",
                authentication: "Authentification",
                sharedSecret: "Secret partag\xE9",
                serverCert: "Certificat de serveur",
                caCert: "Certificat CA",
                x509Condition: "Condition X509",
                exploit509: "Exploitation 509",
                descriptions: {
                  authentication: "La m\xE9thode que le Tunnel IPsec devrait utiliser pour l'authentification.",
                  sharedSecret: "La cl\xE9 pr\xE9partag\xE9e \xE0 utiliser pour l'authentification.",
                  serverCert: "Description de Certificat de serveur",
                  caCert: "Description de Certificat CA",
                  x509Condition: "Description de condition X509",
                  exploit509: "Description d'Exploitation 509"
                }
              }
            },
            sourceDestSection: {
              descriptionMessage: "Configurez la source et la destination du tunnel que vous voulez ajouter.",
              enableBgp: "Activer BGP",
              descriptions: {
                enableBgp: "Activer BGP"
              },
              source: {
                title: "Source",
                type: "Type",
                peer: "Pair",
                ipAddresses: "Passerelles paires",
                initiatesTunnel: "Initie",
                wanInterface: "Interface WAN",
                localId: "ID local",
                networkAddresses: "Adresses r\xE9seau",
                networkAddressBgp: "Adresse d'appairage",
                asnBgp: "ASN",
                descriptions: {
                  ipAddresses: "Les adresses IP publiques du service Edge s\xE9lectionn\xE9. Obligatoire pour la configuration de votre dispositif distant.",
                  localId: "ID IPsec du c\xF4t\xE9 local du tunnel.",
                  networkAddresses: "Les r\xE9seaux atteignables du c\xF4t\xE9 local du tunnel.",
                  networkAddressBgp: "L'adresse IP utilis\xE9e pour la communication BGP du c\xF4t\xE9 local.",
                  asnBgp: "Le Num\xE9ro de syst\xE8me autonome du c\xF4t\xE9 local",
                  enableBgp: "Activez pour que les r\xE9seaux atteignables sur les sites locaux et distants soient \xE9chang\xE9s dynamiquement via BGP."
                },
                sourceTypeData: {
                  gateway: "Service Edge",
                  site: "Site"
                },
                localWans: "Adresse",
                primaryAddress: "Adresse primaire",
                additionalAddresses: "Adresses additionnelles"
              },
              destination: {
                title: "Destination",
                remoteGatewayName: "Nom",
                remoteGateway: "Passerelle distante",
                remoteId: "ID distant",
                networkAddresses: "Adresses r\xE9seau",
                networkAddressBgp: "Adresse d'appairage",
                asnBgp: "ASN",
                multiInputTitle: "Passerelle distante",
                multiInputTitleLabel: "Destinations",
                descriptions: {
                  remoteId: "ID IPsec du c\xF4t\xE9 distant du tunnel.",
                  networkAddresses: "Les r\xE9seaux atteignables du c\xF4t\xE9 distant du tunnel.",
                  networkAddressBgp: "L'adresse IP utilis\xE9e pour la communication BGP du c\xF4t\xE9 distant.",
                  asnBgp: "Le Num\xE9ro de syst\xE8me autonome du c\xF4t\xE9 distant",
                  remoteGateway: "L'IP ou FQDN du c\xF4t\xE9 distant du tunnel. Si le c\xF4t\xE9 local n'initie pas le tunnel, le param\xE8tre peut \xEAtre r\xE9gl\xE9 sur 0.0.0.0/0."
                }
              }
            },
            phasesSection: {
              descriptionMessage: "Le tunnel offre 2 phases. Configurez vos param\xE8tres de s\xE9curit\xE9 pr\xE9f\xE9r\xE9s pour chacune de ces phases.",
              phase1: {
                title: "Phase 1",
                encryption: "Cryptage",
                hash: "Hachage",
                dhGroup: "Groupe DH",
                proposalHandling: "Traitement de proposition",
                lifetime: "Dur\xE9e de vie",
                descriptions: {
                  proposalHandling: "En cas de r\xE9glage sur strict uniquement, l'algorithme de hachage et cryptage et le groupe Diffie Hellman peuvent \xEAtre utilis\xE9s. Autrement, en cas de r\xE9glage sur n\xE9gocier, l'initiateur peut s\xE9lectionner un jeu diff\xE9rent d'algorithmes et de Groupe DH.",
                  lifetime: "Le nombre de secondes avant que l'IPsec (SA) ne soit recrypt\xE9."
                }
              },
              phase2: {
                title: "Phase 2",
                lifetime: "Dur\xE9e de vie",
                trafficVolume: "Volume de trafic activ\xE9",
                trafficVolumeKb: "Volume de trafic Ko",
                encryption: "Cryptage",
                hash: "Hachage",
                dhGroup: "Groupe DH",
                proposalHandling: "Traitement de proposition",
                descriptions: {
                  proposalHandling: "En cas de r\xE9glage sur strict uniquement, l'algorithme de hachage et cryptage et le groupe Diffie Hellman peuvent \xEAtre utilis\xE9s. Autrement, en cas de r\xE9glage sur n\xE9gocier, l'initiateur peut s\xE9lectionner un jeu diff\xE9rent d'algorithmes et de Groupe DH.",
                  lifetime: "Le nombre de secondes avant que l'IPsec (SA) ne soit recrypt\xE9.",
                  trafficVolume: "En cas d'activation, le recryptage peut \xEAtre d\xE9clench\xE9 sur la base du volume de trafic.",
                  trafficVolumeKb: "Le nombre de Ko avant que le SA ne soit recrypt\xE9."
                }
              }
            },
            networkSection: {
              descriptionMessage: "Configurez les param\xE8tres de r\xE9seau. Ce sont des options avanc\xE9es, sans \xEAtre obligatoire pour un tunnel g\xE9n\xE9ral.",
              networkSettings: {
                title: "Param\xE8tres de r\xE9seau",
                oneTunnelSubnet: "Un tunnel VPN par paire de sous-r\xE9seau",
                universalTrafficSelectors: "S\xE9lecteurs de trafic universels",
                forceUdpEncapsulation: "Encapsulation UDP forc\xE9e",
                ikeReauthentication: "R\xE9authentification IKE",
                descriptions: {
                  oneTunnelSubnet: "Cr\xE9ez un SA par paire de r\xE9seaux local et distant.",
                  universalTrafficSelectors: "L'activation autorise tout le trafic via le tunnel.",
                  forceUdpEncapsulation: "Utilisez le port UDP 4500 pour l'enveloppe de tunnel IPsec m\xEAme si aucun m\xE9canisme NAT n'est d\xE9tect\xE9.",
                  ikeReauthentication: "Authentification chaque fois que le SA est recrypt\xE9."
                }
              },
              deadPeerDetection: {
                title: "D\xE9tection de pair mort",
                actionDetected: "Action en cas de d\xE9tection",
                delay: "D\xE9lai",
                descriptions: {
                  actionDetected: "Action quand le c\xF4t\xE9 distant ne r\xE9pond pas aux messages DPD. L'effacement annule le tunnel et un red\xE9marrage est tent\xE9 pour r\xE9tablir le tunnel sans que le DPD ne soit d\xE9sactiv\xE9.",
                  delay: "D\xE9lai en secondes apr\xE8s lequel le message de maintien du DPD actif est envoy\xE9 si aucun autre trafic n'est re\xE7u."
                }
              }
            }
          },
          noData: {
            buttonText: "Ajouter une connexion IPsec",
            iconHeader: "Commencez par ajouter une connexion IPsec !",
            labelButton: "Ajouter une connexion IPsec",
            textHeader: "Pourquoi int\xE9grer une connexion IPsec ?",
            text: "Les d\xE9ploiements SecureEdge utilisent le VPN Barracuda TINA propri\xE9taire pour une connectivit\xE9 s\xE9curis\xE9e et fiable. Consultez cette page de configuration pour connecter des d\xE9ploiements de tiers capables d'utiliser IPsec IKEv2. D\xE8s que la configuration est termin\xE9e, vous pouvez v\xE9rifier le statut de votre connexion yourIPsec VPN sur cette page."
          }
        },
        azureMonitor: "Azure Monitor"
      },
      copy: {
        label: "Copier dans le presse-papiers"
      },
      subscription: {
        pam: {
          menuTitle: "Abonnements",
          pageTitle: "Profil",
          pageSubTitle: "Abonnements",
          table: {
            subscription: "Abonnement",
            serial: "Num\xE9ro de s\xE9rie de licence",
            amount: "Quantit\xE9",
            startDate: "Date de d\xE9but",
            expirationDate: "Date d'expiration",
            noData: {
              iconHeader: "Commencez par ajouter un abonnement !",
              textHeader: "Comment obtenir une licence d'abonnement ?",
              text: "Les abonnements donnent acc\xE8s \xE0 des fonctionnalit\xE9s additionnelles dans votre d\xE9ploiement SecureEdge.<0/> Par exemple, si vous voulez ajouter des si\xE8ges SecureEdge Access pour votre personnel \xE0 distance, utilisez simplement cette interface pour ajouter le nombre n\xE9cessaire de si\xE8ges \xE0 votre d\xE9ploiement.<0/> Pour obtenir le meilleur abonnement pour vos besoins, contactez simplement Barracuda Sales (barracuda.com/contact-sales)."
            },
            information: "Information",
            value: "{{value}}",
            value_34: "{{value}} Utilisateurs",
            value_24: "{{value}} Mbps",
            msp: "MSP factur\xE9s"
          }
        }
      },
      customCategories: {
        table: {
          name: "Nom",
          description: "Description",
          details: "D\xE9tails",
          detailCategories: "Cat\xE9gories ({{activeCategories.0.name}}, +{{additional}}) ",
          detailCategories_1: "Cat\xE9gories ({{activeCategories.0.name}}) ",
          detailDomains: "Domaines inclus ({{domains.0}}, +{{additionalDomains}})",
          detailDomains_1: "Domaine inclus ({{domains.0}})",
          domainsExcluded: "Domaines exclus",
          domainsIncluded: "Domaines inclus",
          noData: "Aucune r\xE8gle de cat\xE9gorie personnalis\xE9e trouv\xE9e."
        },
        dialog: {
          create: {
            buttonText: "Ajouter cat\xE9gorie personnalis\xE9e",
            dialogTitle: "Nouvelle cat\xE9gorie personnalis\xE9e"
          },
          edit: {
            dialogTitle: "Modifier cat\xE9gorie personnalis\xE9e"
          },
          name: "Nom",
          description: "Description",
          details: "D\xE9tails",
          includedDomains: "Domaines inclus",
          excludedDomains: "Domaines exclus",
          descriptions: {
            name: "Un nom unique pour cette r\xE8gle.",
            description: "Une br\xE8ve description de cette r\xE8gle.",
            includedDomains: "La liste des domaines affect\xE9s par cette r\xE8gle.",
            excludedDomains: "La liste des domaines non-affect\xE9s par cette r\xE8gle."
          },
          validation: {
            emptyCategories: "doit fournir au moins un domaine ou cat\xE9gorie"
          },
          delete: {
            title: "Supprimer {{name}}",
            body: "\xCAtes-vous certain de vouloir supprimer cette r\xE8gle ? Toutes les r\xE8gles de s\xE9curit\xE9 utilisant cette cat\xE9gorie personnalis\xE9e seront mises \xE0 jour ou retir\xE9es."
          }
        }
      },
      syslog: {
        pageTitle: "Streaming Syslog",
        certificateCName: "Nom commun de certificat",
        certificateExpiryDate: "Date d'expiration de certificat",
        enable: "Activer Syslog",
        logs: "Journaux",
        port: "Port",
        protocol: "Protocole",
        securityProtocol: "Protocole de s\xE9curit\xE9",
        server: "Serveur Syslog",
        syslogOverride: "D\xE9sactiver le streaming Syslog",
        uploadSslCert: "T\xE9l\xE9charger Certificat SSL",
        descriptions: {
          port: "Le port cible de votre serveur Syslog.",
          protocol: "Le protocole de transfert de donn\xE9es \xE0 utiliser pour le streaming Syslog.",
          uploadSslCert: "Le certificat public de votre serveur Syslog.",
          logs: "Les journaux d'appareil \xE0 diffuser en streaming.",
          server: "Le nom d'h\xF4te ou IP de votre serveur Syslog.",
          securityProtocol: "Le protocole de s\xE9curit\xE9 \xE0 utiliser pour le streaming Syslog.",
          syslogOverride_site: "D\xE9sactivez le streaming Syslog pour ce site.",
          syslogOverride_gateway: "D\xE9sactivez le streaming Syslog pour ce service Edge."
        }
      },
      xdr: {
        pageTitle: "Barracuda XDR",
        enable: "Activer streaming",
        sites: "Sites",
        gateways: "Services Edge",
        hostname: "Nom d'h\xF4te",
        port: "Port",
        descriptions: {
          sites: "Les sites qui devraient diffuser des journaux vers Barracuda XDR.",
          gateways: "Les services Edge qui devraient diffuser des journaux vers Barracuda XDR.",
          hostname: "Le nom d'h\xF4te Barracuda XDR vers lequel diffuser le streaming.",
          port: "Le port Barracuda XDR vers lequel diffuser le streaming."
        }
      },
      webFilterSettings: {
        safeSearch: "SafeSearch",
        silentAdBlocking: "Blocage de publicit\xE9 silencieux",
        descriptions: {
          safeSearch: `Applique le mode "SafeSearch" aux moteurs de recherche Internet. Exige l'activation et la configuration d'une inspection SSL de s\xE9curit\xE9 pour appliquer SafeSearch.`,
          silentAdBlocking: `Bloque en silence la cat\xE9gorie d'URL "Publicit\xE9s ou banni\xE8res".`
        }
      },
      webMonitorPolicies: {
        webConfigHelpText: "Activez ou d\xE9sactivez la surveillance des recherches Web de vos utilisateurs pour certains mots cl\xE9s. En cas d'activation, vous pouvez diffuser en streaming les correspondances de mot cl\xE9 (toutes recueillies dans le Web Monitor Alert Log qui forme partie de la collection Service Logs) vers le serveur Syslog de votre choix pour traitement ult\xE9rieur.<0/> Par esprit pratique, s\xE9lectionnez parmi les cat\xE9gories de mots cl\xE9s pr\xE9d\xE9finis ou ajoutez des mots cl\xE9s personnalis\xE9s correspondant explicitement \xE0 vos exigences.",
        enabled: "Activer la surveillance Web",
        webMonitoringTitle: "Cat\xE9gories de mot cl\xE9",
        customKeywordTitle: "Jeu de mots cl\xE9s personnalis\xE9s",
        customKeywordHelpText: 'Saisissez les mots cl\xE9s personnalis\xE9s dans le champ de texte et cliquez sur le bouton "+" pour les ajouter \xE0 la liste de surveillance.',
        customKeywords: "Mots cl\xE9s personnalis\xE9s",
        monitored: "surveill\xE9"
      },
      customResponse: {
        setup: "Configurer une page de r\xE9ponse personnalis\xE9e",
        setupHelpText: "Voici comment configurer votre page de r\xE9ponse personnalis\xE9e. Une fois cette page de r\xE9ponse enregistr\xE9e, elle est affich\xE9e pour tous vos utilisateurs actifs.",
        barracudaBranding: "Marquage Barracuda",
        customLogo: "Logo personnalis\xE9",
        customText: "Texte personnalis\xE9",
        showBarracudaBranding: "afficher marquage",
        descriptions: {
          customText: "Affichage sous le logo personnalis\xE9.<0/> Laissez-le vide pour n'utiliser aucun texte personnalis\xE9.",
          customLogo: "Affichage au sommet. Les formats de fichier JPEG, PNG, GIF et SVG sont pris en charge."
        }
      },
      identity: {
        menuTitle: "IDENTIT\xC9",
        pageTitle: "Identit\xE9",
        pageSubtitle: "Param\xE8tres",
        identityProviders: {
          title: "Prestataires d'identit\xE9",
          addNotification: "Prestataire d'identit\xE9 correctement ajout\xE9s",
          noDataMessage: "Il semble que vous ne disposiez pas encore d'un Prestataire d'identit\xE9 configur\xE9.",
          clickBelow: "Cliquez sur le bouton \xE0 la suite pour en ajouter un.",
          createIdentityProvider: "Ajouter Prestataire d'identit\xE9",
          saml: {
            informationNeeded: "Informations n\xE9cessaires pour votre Prestataire d'identit\xE9",
            entityId: "ID d'entit\xE9",
            acsURL: "URL ACS"
          },
          add: {
            title: "Ajouter Prestataire d'identit\xE9",
            createStep: "Param\xE8tres d'identification SAML",
            setupStep: "Configurer SAML",
            button: "Ajouter",
            buttonDisabled: "Vous ne pouvez avoir qu'un seul Prestataire d'identit\xE9 \xE0 la fois.",
            types: {
              email: "E-mail",
              entraid: "Microsoft Entra ID",
              bcc: "Barracuda Cloud Control",
              google: "Google Workspace",
              oidc: "OpenID Connect",
              saml: "SAML 2.0"
            },
            options: {
              provider: "Prestataire",
              displayName: "Nom d'affichage",
              tenantId: "ID Tenant",
              clientId: "ID Client",
              clientSecret: "Secret de client",
              discoveryURL: "URL d\xE9couverte",
              scope: "Port\xE9e",
              googleWorkspaceDomain: "Domaine Google Workspace",
              signInEndpoint: "Point final d'authentification",
              signingCert: "Certificat de signature"
            },
            redirectUri: "URI de redirection"
          },
          table: {
            name: "Nom",
            type: "Type",
            status: "Statut",
            noData: "Aucun Prestataire d'identit\xE9 trouv\xE9."
          },
          authorizationStatusField: {
            needs_consent: "Autorisation n\xE9cessaire",
            active: "Actif",
            error: "Erreur"
          },
          menu: {
            test: "Tester Prestataire d'identit\xE9",
            setupSaml: "Configurer SAML"
          },
          delete: {
            title: "Retirer Prestataire d'identit\xE9",
            body: "\xCAtes-vous certain de vouloir retirer ce Prestataire d'identit\xE9 ?"
          }
        },
        userDirectories: {
          title: "Identit\xE9",
          subtitle: "R\xE9pertoires d'utilisateurs",
          tableName: "R\xE9pertoires d'utilisateurs",
          noDataMessage: "Il semble que vous ne disposiez pas encore d'un R\xE9pertoire d'utilisateurs configur\xE9.",
          clickBelow: "Cliquez sur le bouton \xE0 la suite pour en ajouter un.",
          createDirectory: "Ajouter R\xE9pertoire d'utilisateurs",
          createDirectoryDialogTitle: "Ajouter R\xE9pertoire d'utilisateurs",
          editDirectory: "Modifier R\xE9pertoire d'utilisateurs",
          displayName: "Nom",
          type: "Type",
          status: "Statut",
          statusField: {
            running: "En cours",
            cancelled: "Annul\xE9",
            errored: "En erreur",
            completed: "Termin\xE9",
            pending: "En attente",
            unknown: "Inconnu",
            needs_consent: "Autorisation n\xE9cessaire"
          },
          sync: "Sync",
          syncResult: "R\xE9sultat de synchronisation",
          syncedNotification: "Synchronisation de R\xE9pertoire d'utilisateurs d\xE9marr\xE9e",
          syncErrors: "Erreurs de synchronisation",
          users_one: "{{count}} Utilisateur",
          users_other: "{{count}} Utilisateurs",
          groups_one: "{{count}} Groupe",
          groups_other: "{{count}} Groupes",
          syncStatus: "{{users}}, {{groups}}, Synchronis\xE9",
          lastSuccessfulSync: "Derni\xE8re synchronisation",
          lastSyncAt: "Derni\xE8re synchronisation le {{date}}",
          neverSynced: "Jamais synchronis\xE9",
          delete: {
            body: "\xCAtes-vous certain de vouloir supprimer ce R\xE9pertoire d'utilisateurs ?",
            body2: "Si vous modifiez ou supprimez le R\xE9pertoire d'utilisateurs pour votre compte, les strat\xE9gies de s\xE9curit\xE9 et d'acc\xE8s existantes pourraient ne plus fonctionner comme pr\xE9vu. Veuillez les examiner et les corriger, si n\xE9cessaire. Les utilisateurs d\xE9j\xE0 inscrits auront toujours acc\xE8s. Veuillez utiliser la page Utilisateurs inscrits pour g\xE9rer ceux \xE0 supprimer.",
            title: "Supprimer R\xE9pertoire d'utilisateurs {{name}}"
          },
          form: {
            title: "R\xE9pertoire",
            displayName: "Nom d'affichage",
            tenantId: "ID Tenant",
            type: {
              label: "Type de r\xE9pertoire",
              bcc: "Barracuda Cloud Control",
              entraid: "Microsoft Entra ID",
              okta: "Okta",
              ldap: "LDAP",
              google: "Google Workspace"
            },
            ldap: {
              steps: {
                userDirectory: "Infos de R\xE9pertoire d'utilisateurs",
                auth: "Authentification",
                tls: "Param\xE8tres TLS"
              },
              host: {
                label: "H\xF4te",
                placeholder: "ex. : ldap.barracuda.com"
              },
              port: {
                label: "Port",
                placeholder: "ex. : 389"
              },
              profile: {
                label: "Profil",
                placeholder: "S\xE9lectionner profil",
                ad: "Annonce",
                custom: "Personnalis\xE9"
              },
              userSearchBase: {
                label: "Base de recherche d'utilisateur",
                placeholder: "ex. : dc=mydomain.com,dc=com"
              },
              groupSearchBase: {
                label: "Base de recherche de groupe",
                placeholder: "ex. : dc=mydomain.com,dc=com"
              },
              authenticationMethod: {
                label: "M\xE9thode d'authentification",
                placeholder: "S\xE9lectionner m\xE9thode d'authentification",
                anon: "Anon",
                simple: "Simple",
                saslExternal: "SASL External",
                saslKerberos: "SASL Kerberos",
                ntlm: "NTLM"
              },
              username: "Nom d'utilisateur",
              password: "Mot de passe",
              saslCredentials: "Identifiants SASL",
              useTls: {
                tlsSettings: "Param\xE8tres TLS",
                none: "Aucun",
                tls: "TLS",
                startTls: "StartTLS",
                encryptionMethod: "M\xE9thode de cryptage",
                ldapSni: {
                  label: "Nom d'h\xF4te SNI",
                  placeholder: "ex. : example.org"
                },
                ldapPrivkey: {
                  label: "Cl\xE9 priv\xE9e",
                  placeholder: "Collez votre cl\xE9 priv\xE9e ici"
                },
                ldapPrivkeyPassword: "Mot de passe",
                ldapPubkey: {
                  label: "Cl\xE9 publique",
                  placeholder: "Collez votre cl\xE9 publique ici"
                },
                ldapCacerts: {
                  label: "Certificats CA",
                  placeholder: "Collez vos certificats CA ici"
                },
                ldapCheckCerts: {
                  label: "V\xE9rifier certificat",
                  description: "V\xE9rifiez si les certificats de serveur re\xE7us sont fiables"
                },
                ldapCheckHostname: {
                  label: "V\xE9rifier nom d'h\xF4te",
                  description: "Valider nom d'h\xF4te du certificat"
                },
                ldapCertAdditionalNames: {
                  label: "Noms additionnels de certificat",
                  placeholder: "ex. : example.org,company.com,dc1.example.org"
                }
              }
            },
            okta: {
              domainname: "Nom de domaine Okta",
              authToken: "Jeton d'authentification Okta"
            },
            google: {
              googleWorkspaceDomain: "Domaine Google Workspace"
            },
            bcc: {
              environment: "Environnement"
            }
          }
        },
        users: {
          title: "Utilisateurs",
          subtitle: "Utilisateurs",
          tableName: "Utilisateurs",
          name: "Nom",
          email: "E-mail",
          noDataMessage: "Aucun utilisateur trouv\xE9.",
          nameOrEmail: "Utilisateur"
        },
        groups: {
          title: "Groupes",
          subtitle: "Groupes",
          tableName: "Groupes",
          name: "Nom",
          email: "E-mail",
          noDataMessage: "Aucun groupe trouv\xE9."
        }
      }
    }
  }
};
export {
  fr_default as default
};
