var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import { useDeepCompareMemo, useDeepCompareMemoize } from "../UtilHooks";
import { GridColumn } from "@progress/kendo-react-grid";
const useDataTableSelect = (data, props) => {
  const { isSelected, onSelect, onSelectAll, selectAllState } = props;
  const cellRender = useCallback((cell, cellProps, additionalRender) => {
    const { field } = cellProps;
    if (field === "_isSelected") {
      return cell;
    }
    return additionalRender ? additionalRender(cell, cellProps) : cell;
  }, []);
  const headerCellRender = useCallback((cell, cellProps, additionalRender) => {
    const { field } = cellProps;
    if (field === "_isSelected" && !onSelectAll) {
      return null;
    }
    return additionalRender ? additionalRender(cell, cellProps) : cell;
  }, [!onSelectAll]);
  const onSelectionChange = useCallback((event) => {
    onSelect == null ? void 0 : onSelect(event.dataItem);
  }, [onSelect]);
  const onHeaderSelectionChange = useCallback(() => onSelectAll == null ? void 0 : onSelectAll(data), [data, onSelectAll]);
  const memoizedData = useDeepCompareMemoize(isSelected ? data.map((row) => __spreadProps(__spreadValues({}, row), {
    _isSelected: isSelected(row)
  })) : data);
  const dataTableColumn = useDeepCompareMemo(() => isSelected ? /* @__PURE__ */ jsx(
    GridColumn,
    {
      field: "_isSelected",
      width: 64,
      headerSelectionValue: selectAllState == null ? void 0 : selectAllState(data)
    },
    "_isSelected"
  ) : null, [data, isSelected]);
  return [
    {
      headerCellRender,
      onSelectionChange: onSelect ? onSelectionChange : void 0,
      onHeaderSelectionChange: onSelectAll ? onHeaderSelectionChange : void 0,
      selectedField: isSelected ? "_isSelected" : void 0,
      data: memoizedData,
      cellRender
    },
    dataTableColumn
  ];
};
export {
  useDataTableSelect
};
