var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import SupportIcon from "@mui/icons-material/Support";
import LogoutIcon from "@mui/icons-material/Logout";
import LanguageIcon from "@mui/icons-material/Language";
import { authLogout } from "../../../actions";
import { useGlobalParam } from "../../../hooks";
import { useTranslation } from "react-i18next";
import { Check, ExpandLess, OpenInNew } from "@mui/icons-material";
import DialogBody from "../../dialog/DialogBody/DialogBody";
import Toolbar from "../../layout/Toolbar/Toolbar";
import { Button, Container, Dialog, ProfileMenu, Typography } from "@barracuda-internal/bds-core";
import { createStyles } from "@mui/styles";
import { makeOverrideableStyles } from "@cuda-react/theme";
import UserAvatar from "../UserAvatar/UserAvatar";
import classNames from "classnames";
import { useI18next } from "../../../i18n";
import UserIcon from "@barracuda-internal/bds-core/dist/Icons/Core/User";
const styles = createStyles({
  body: {
    textAlign: "center",
    padding: 16,
    maxWidth: 600
  },
  userIcon: {},
  formControl: {
    width: 240,
    marginTop: -2
  },
  select: {
    width: 240
  },
  expandIcon: {
    transition: "transform 0.25s ease-in-out"
  },
  expandIconExpanded: {
    transform: "rotate(180deg)"
  }
});
const useStyles = makeOverrideableStyles("LogoutMenu", styles);
const translateMenuItems = (menuItems, translate) => menuItems && menuItems.map((menuItem) => __spreadProps(__spreadValues({}, menuItem), {
  name: translate(menuItem.name)
}));
const LogoutMenu = (props) => {
  const { additionalChoices = [], showSupportAccess, supportAccessEnabled, onSupportAccessAccept } = props;
  const classes = useStyles(props);
  const [userData] = useGlobalParam("userData");
  const email = (userData == null ? void 0 : userData.currentUser) || "Anonymous";
  const name = userData == null ? void 0 : userData.currentUserName;
  const portalUrl = (userData == null ? void 0 : userData.portalUrl) || "login.barracudanetworks.com";
  const [translate, i18n] = useTranslation();
  const dispatch = useDispatch();
  const [openConsent, setOpenConsent] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const accessTitle = supportAccessEnabled ? "cuda.supportAccess.title.disable" : "cuda.supportAccess.title.enable";
  const accessBody = supportAccessEnabled ? translate("cuda.supportAccess.body.disable") : translate("cuda.supportAccess.body.enable");
  const { activeLanguage, availableLanguages, setActiveLanguage } = useI18next();
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      ProfileMenu,
      {
        icon: /* @__PURE__ */ jsx(UserAvatar, {}),
        name,
        email,
        menuItems: translateMenuItems([
          {
            name: "cuda.manageProfile",
            onClick: () => {
              window.open(portalUrl);
            },
            icon: /* @__PURE__ */ jsx(UserIcon, {}),
            endIcon: /* @__PURE__ */ jsx(OpenInNew, {})
          },
          ...showSupportAccess ? [
            {
              name: accessTitle,
              icon: /* @__PURE__ */ jsx(SupportIcon, {}),
              onClick: () => setOpenConsent(true)
            }
          ] : [],
          ...additionalChoices,
          {
            icon: /* @__PURE__ */ jsx(LanguageIcon, { id: "cuda-language-select" }),
            name: `cuda.languages.${activeLanguage}`,
            onClick: () => setLanguageOpen(!languageOpen),
            endIcon: /* @__PURE__ */ jsx(
              ExpandLess,
              {
                className: classNames(
                  classes.expandIcon,
                  languageOpen && classes.expandIconExpanded
                )
              }
            )
          },
          ...languageOpen ? availableLanguages.map((language) => ({
            icon: /* @__PURE__ */ jsx("div", { style: { width: 64 } }),
            name: `cuda.languages.${language}`,
            onClick: () => setActiveLanguage == null ? void 0 : setActiveLanguage(language),
            endIcon: language === activeLanguage ? /* @__PURE__ */ jsx(Check, {}) : void 0
          })) : [],
          {
            name: "cuda.auth.signOut",
            icon: /* @__PURE__ */ jsx(LogoutIcon, { id: "cuda-user-logout" }),
            onClick: () => dispatch(authLogout())
          }
        ], translate)
      }
    ),
    showSupportAccess && /* @__PURE__ */ jsxs(
      Dialog,
      {
        open: openConsent,
        maxWidth: false,
        onClose: () => setOpenConsent(false),
        children: [
          /* @__PURE__ */ jsx(DialogBody, { title: accessTitle, children: /* @__PURE__ */ jsx(Container, { className: classes.body, children: /* @__PURE__ */ jsx(Typography, { variant: "body1", children: accessBody }) }) }),
          /* @__PURE__ */ jsxs(Toolbar, { children: [
            /* @__PURE__ */ jsx(
              Button,
              {
                variant: "contained",
                color: "primary",
                onClick: () => {
                  onSupportAccessAccept == null ? void 0 : onSupportAccessAccept(!supportAccessEnabled);
                  setOpenConsent(false);
                },
                size: "small",
                children: translate("cuda.action.confirm")
              }
            ),
            /* @__PURE__ */ jsx(
              Button,
              {
                variant: "contained",
                color: "secondary",
                size: "small",
                onClick: () => setOpenConsent(false),
                children: translate("cuda.action.cancel")
              }
            )
          ] })
        ]
      }
    )
  ] });
};
LogoutMenu.defaultProps = {
  additionalChoices: [],
  supportAccessEnabled: false
};
var LogoutMenu_default = LogoutMenu;
export {
  LogoutMenu,
  LogoutMenu_default as default
};
