var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { Typography } from "@barracuda-internal/bds-core";
import React from "react";
import { useTranslation } from "react-i18next";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    paddingBottom: theme.spacing(1.2)
  },
  title: {
    padding: theme.spacing(1.5, 3, 1.5),
    marginBottom: theme.spacing(1),
    textTransform: "uppercase",
    fontSize: 16,
    color: theme.palette.text.secondary
  },
  titleNewStyle: {
    padding: theme.spacing(1.5, 3, 0, 1.5),
    marginBottom: theme.spacing(1),
    fontSize: 20,
    color: "#4E4D4D"
  },
  borderNewStyle: {
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    borderWidth: "0 0 1px",
    margin: theme.spacing(1.5, 2, 1.5, 2)
  },
  subtitle: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1)
  },
  titleWithoutBorder: {
    padding: theme.spacing(1.5, 3.5, 0, 1.5),
    marginBottom: theme.spacing(1),
    fontSize: 20,
    color: "#4E4D4D"
  }
});
const useStyles = makeOverrideableStyles("FormSection", styles);
const FormSection = (props) => {
  const _a = props, { title, children, subtitle, newStyle, hideBorder } = _a, childProps = __objRest(_a, ["title", "children", "subtitle", "newStyle", "hideBorder"]);
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return !newStyle ? /* @__PURE__ */ jsxs("div", { className: classes.root, children: [
    title ? /* @__PURE__ */ jsx(Typography, { variant: "h6", className: classes.title, children: translate(title) }) : null,
    subtitle ? /* @__PURE__ */ jsx(Typography, { variant: "subtitle1", className: classes.subtitle, children: translate(subtitle) }) : null,
    React.Children.map(children, (child) => React.isValidElement(child) ? React.cloneElement(child, childProps) : child)
  ] }) : /* @__PURE__ */ jsxs("div", { className: classes.root, children: [
    title ? /* @__PURE__ */ jsx("div", { className: !hideBorder ? classes.borderNewStyle : classes.titleWithoutBorder, children: /* @__PURE__ */ jsx(Typography, { variant: "h6", className: classes.titleNewStyle, children: translate(title) }) }) : null,
    React.Children.map(children, (child) => React.isValidElement(child) ? React.cloneElement(child, childProps) : child)
  ] });
};
var FormSection_default = FormSection;
export {
  FormSection,
  FormSection_default as default
};
