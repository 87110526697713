import { jsx } from "react/jsx-runtime";
import React from "react";
import { FormSection } from "@cuda-react/core";
import DhcpNetworksTableInput from "../../inputs/DhcpNetworksTableInput";
const DhcpTab = () => /* @__PURE__ */ jsx(React.Fragment, { children: /* @__PURE__ */ jsx(FormSection, { title: "tesseract.sites.settings.dhcp.networksSectionTitle", children: /* @__PURE__ */ jsx(DhcpNetworksTableInput, { series: "S" }) }) });
var DhcpTab_default = DhcpTab;
export {
  DhcpTab,
  DhcpTab_default as default
};
