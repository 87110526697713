import { jsx } from "react/jsx-runtime";
import React from "react";
import { TextInput, validateCIDR } from "@cuda-react/core";
const SdwanConnectorPoolNetworkStep = () => /* @__PURE__ */ jsx(React.Fragment, { children: /* @__PURE__ */ jsx(
  TextInput,
  {
    source: "clientNetworkConfiguration.range",
    label: "tesseract.network.sdwanConnector.dialog.poolNetwork.network",
    description: "tesseract.network.sdwanConnector.dialog.description.poolNetwork.network",
    isRequired: true,
    validate: validateCIDR
  }
) });
SdwanConnectorPoolNetworkStep.propTypes = {};
var SdwanConnectorPoolNetworkStep_default = SdwanConnectorPoolNetworkStep;
export {
  SdwanConnectorPoolNetworkStep_default as default
};
