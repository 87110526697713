var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { makeOverrideableStyles } from "@cuda-react/theme";
import { set } from "lodash";
import React from "react";
import { createStyles } from "@mui/styles";
const styles = createStyles({
  readOnly: {
    display: "inline-block",
    padding: "10px 0"
  }
});
const useStyles = makeOverrideableStyles("ReadOnlyInput", styles);
const ReadOnly = (props) => {
  const {
    options,
    source = "",
    field: FieldToRender,
    value
  } = props;
  const classes = useStyles(props);
  return React.createElement(FieldToRender, __spreadValues({
    source,
    data: set({}, source, value),
    className: classes.readOnly
  }, options));
};
var ReadOnly_default = ReadOnly;
export {
  ReadOnly_default as default
};
