import { jsx, jsxs } from "react/jsx-runtime";
import { List, ListItem, ListItemIcon, ListItemText } from "@barracuda-internal/bds-core";
import { isEmpty } from "lodash";
import get from "lodash/get";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { useTranslation } from "react-i18next";
import { createStyles } from "@mui/styles";
const styles = createStyles({
  listItem: {
    paddingTop: 2,
    paddingBottom: 2
  },
  listItemText: {
    margin: 0
  },
  listItemIcon: {
    minWidth: 32,
    verticalAlign: "middle"
  },
  inset: {
    paddingLeft: 32
  }
});
const useStyles = makeOverrideableStyles("ListField", styles);
const ListField = (props) => {
  const { source = "", data = {}, icon, iconMap = {}, render } = props;
  const [translate] = useTranslation();
  const classes = useStyles(props);
  let fieldValues = get(data, source) || [];
  if (!Array.isArray(fieldValues)) {
    fieldValues = [fieldValues];
  }
  return /* @__PURE__ */ jsx(List, { dense: true, disablePadding: true, children: fieldValues.map((row, index) => {
    const iconToDisplay = iconMap[row] || icon;
    return /* @__PURE__ */ jsxs(ListItem, { disableGutters: true, className: classes.listItem, children: [
      iconToDisplay && /* @__PURE__ */ jsx(ListItemIcon, { className: classes.listItemIcon, children: iconToDisplay }),
      /* @__PURE__ */ jsx(
        ListItemText,
        {
          primary: render ? render(row) : translate(row),
          primaryTypographyProps: { variant: "body1" },
          className: classes.listItemText,
          classes: { inset: classes.inset },
          inset: !isEmpty(iconMap) && !iconToDisplay
        }
      )
    ] }, index);
  }) });
};
var ListField_default = ListField;
export {
  ListField,
  ListField_default as default,
  styles
};
