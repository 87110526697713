import { jsx, jsxs } from "react/jsx-runtime";
import SvgIcon from "@mui/material/SvgIcon";
import { useTheme } from "@mui/material";
const AppsIcon = (props) => {
  var _a, _b;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(SvgIcon, { viewBox: "2 2 21 21", style: { width: (_a = props.size) != null ? _a : 50, height: (_b = props.size) != null ? _b : 50 }, children: /* @__PURE__ */ jsxs("g", { transform: "matrix(1,0,0,1,-5.42152,-5.995)", children: [
    /* @__PURE__ */ jsx("rect", { x: "5.422", y: "5.995", width: "24.026", height: "24.026", style: { fill: "none" } }),
    /* @__PURE__ */ jsx("g", { transform: "matrix(1,0,0,1,7.86524,8.10862)", children: /* @__PURE__ */ jsx(
      "path",
      {
        d: "M0.569,0.899L0.569,8.899L8.569,8.899L8.569,0.899L0.569,0.899ZM6.569,6.899L2.569,6.899L2.569,2.899L6.569,2.899L6.569,6.899ZM0.569,10.899L0.569,18.899L8.569,18.899L8.569,10.899L0.569,10.899ZM6.569,16.899L2.569,16.899L2.569,12.899L6.569,12.899L6.569,16.899ZM10.569,0.899L10.569,8.899L18.569,8.899L18.569,0.899L10.569,0.899ZM16.569,6.899L12.569,6.899L12.569,2.899L16.569,2.899L16.569,6.899ZM10.569,10.899L10.569,18.899L18.569,18.899L18.569,10.899L10.569,10.899ZM16.569,16.899L12.569,16.899L12.569,12.899L16.569,12.899L16.569,16.899Z",
        style: { fill: theme.palette.reporting.fontColor, fillRule: "nonzero" }
      }
    ) })
  ] }) });
};
export {
  AppsIcon
};
