import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import apiResources from "../../../apiResources";
import {
  ActionButtonsField,
  ChipArrayField,
  ConditionalWrapper,
  DeleteDialog,
  DisableableButton,
  EmptyPageContent,
  getArrayDataContent,
  SelectedChoiceField,
  TablePage,
  TextField,
  useCrudProps,
  useMixpanel
} from "@cuda-react/core";
import { get } from "lodash";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import AddPointOfEntryContent from "./AddPointOfEntryContent";
import EditPointOfEntryContent from "./EditPointOfEntryContent";
import { GetStartedBackgroundGraphic, PointOfEntryGraphic } from "@cuda-react/icons";
import { Dialog } from "@barracuda-internal/bds-core";
const PointsOfEntry = () => {
  var _a;
  const configurationTypes = (_a = useCrudProps(apiResources.pointsOfEntryConfigurationTypes)[0]) == null ? void 0 : _a.data;
  const registerAction = useMixpanel("Points Of Entry");
  const tableRefreshRef = useRef(null);
  const [actionVisible, setActionVisible] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      TablePage,
      {
        resource: apiResources.pointsOfEntry,
        title: "tesseract.endpoint.pageTitle",
        subtitle: "tesseract.endpoint.pointsOfEntry.title",
        tableName: "EndpointPointsOfEntryTable",
        resetOnGlobalParamChange: true,
        resizable: true,
        reorderable: true,
        editColumns: true,
        refreshRef: tableRefreshRef,
        exportResource: { resource: apiResources.pointsOfEntryExport, filename: "pointsOfEntry" },
        titleActions: actionVisible && /* @__PURE__ */ jsx(
          DisableableButton,
          {
            buttonText: "tesseract.endpoint.pointsOfEntry.createButton",
            onClick: () => setCreateOpen(true)
          }
        ),
        onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table"),
        noDataPage: /* @__PURE__ */ jsx(
          EmptyPageContent,
          {
            background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
            icon: /* @__PURE__ */ jsx(PointOfEntryGraphic, {}),
            iconHeader: "tesseract.endpoint.pointsOfEntry.noData.iconHeader",
            textHeader: "tesseract.endpoint.pointsOfEntry.noData.textHeader",
            text: "tesseract.endpoint.pointsOfEntry.noData.text",
            actions: /* @__PURE__ */ jsx(
              DisableableButton,
              {
                buttonText: "tesseract.endpoint.pointsOfEntry.noData.labelButton",
                onClick: () => setCreateOpen(true)
              }
            )
          }
        ),
        children: [
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "configuration.name",
              label: "tesseract.endpoint.pointsOfEntry.table.name",
              filter: "text",
              filterProps: {
                source: "name"
              },
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            SelectedChoiceField,
            {
              source: "configurationType",
              label: "tesseract.endpoint.pointsOfEntry.table.type",
              filter: "select",
              filterProps: {
                choices: getArrayDataContent(configurationTypes),
                source: "type"
              },
              choices: getArrayDataContent(configurationTypes),
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "ipAddresses",
              label: "tesseract.endpoint.pointsOfEntry.table.publicIp"
            }
          ),
          /* @__PURE__ */ jsx(TextField, { source: "configuration.sizeName", label: "tesseract.endpoint.pointsOfEntry.table.size" }),
          /* @__PURE__ */ jsx(TextField, { source: "configuration.locationName", label: "tesseract.endpoint.pointsOfEntry.table.location" }),
          /* @__PURE__ */ jsxs(
            ActionButtonsField,
            {
              source: "id",
              width: 96,
              alwaysVisible: true,
              children: [
                /* @__PURE__ */ jsx(
                  ConditionalWrapper,
                  {
                    wrapper: () => null,
                    condition: (props) => get(props, "data.configurationType") !== "cgf",
                    children: /* @__PURE__ */ jsx(
                      CreateEditButtonDialog,
                      {
                        component: EditPointOfEntryContent,
                        onSuccess: () => {
                          var _a2;
                          registerAction("Update");
                          (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                        }
                      }
                    )
                  }
                ),
                /* @__PURE__ */ jsx(
                  DeleteDialog,
                  {
                    resource: apiResources.pointsOfEntry,
                    title: "tesseract.endpoint.pointsOfEntry.delete.title",
                    message: "tesseract.endpoint.pointsOfEntry.delete.body",
                    onSuccess: () => {
                      var _a2;
                      registerAction("Delete");
                      (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                    }
                  }
                )
              ]
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Dialog,
      {
        open: createOpen,
        maxWidth: false,
        disableEnforceFocus: true,
        onClose: () => setCreateOpen(false),
        children: /* @__PURE__ */ jsx(
          AddPointOfEntryContent,
          {
            onClose: () => setCreateOpen(false),
            onSuccess: () => {
              var _a2;
              registerAction("Create");
              (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
            }
          }
        )
      }
    )
  ] });
};
var PointsOfEntry_default = PointsOfEntry;
export {
  PointsOfEntry_default as default
};
