var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { Input } from "../Input/Input";
import { createStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
const styles = (theme) => createStyles({
  root: {
    width: "100%",
    margin: theme.spacing(1, 0, 1, 0),
    borderWidth: 1,
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    borderColor: theme.palette.divider
  },
  rootSyslog: {
    margin: theme.spacing(2.5, 5, 2, 10),
    borderWidth: 1,
    borderColor: theme.palette.divider
  },
  accordion: {
    transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderColor: theme.palette.divider,
    textTransform: theme.typography.button.textTransform,
    boxShadow: "none",
    "&:before": {
      display: "none"
    }
  },
  accordionSummary: {
    flexDirection: "row-reverse",
    padding: theme.spacing(0, 0, 0, 2),
    margin: theme.spacing(0, 0, 0, -2)
  },
  accordionDetails: {
    padding: theme.spacing(0, 4, 2)
  },
  leftHeading: {
    padding: theme.spacing(1, 0, 0, 2),
    float: "left",
    display: "inline-block",
    marginBottom: theme.spacing(1),
    fontSize: 20,
    color: "#4E4D4D"
  },
  rightHeading: {
    padding: theme.spacing(1, 2, 0, 0),
    float: "right",
    display: "inline-block"
  },
  listContainer: {
    width: "100%",
    display: "flex"
  },
  listContainerError: {
    border: "solid 1px " + theme.palette.error.main,
    borderRadius: 4
  },
  list: {
    margin: theme.spacing(0, 1),
    width: "100%"
  },
  categoryList: {
    padding: theme.spacing(0, 0, 0, 2)
  },
  category: {
    padding: theme.spacing(0, 0, 0, 2)
  },
  subCategory: {
    transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    display: "contents"
  },
  listItemIcon: {
    minWidth: 24,
    margin: theme.spacing(0, 1, 0, 0)
  },
  checkbox: {
    padding: 0
  },
  error: {
    marginLeft: theme.spacing(1)
  },
  listItemText: {
    textTransform: "capitalize"
  },
  chip: {
    margin: theme.spacing(0, 1, 1, 0),
    padding: theme.spacing(-1, 0, 1, 0),
    textTransform: "capitalize"
  },
  categoryTitle: {
    display: "inline-block",
    margin: theme.spacing(0, 1, 0, 0),
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "capitalize"
    },
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    lineHeight: "19px"
  },
  checkCount: {
    display: "inline-block",
    fontSize: 12,
    textTransform: "none"
  }
});
const useStyles = makeOverrideableStyles("BooleanCheckListInput", styles);
const updateItem = (itemKey, checked, value, onChange, onBlur) => {
  let newValue = [...value].filter((item) => item !== itemKey);
  if (checked) {
    newValue.push(itemKey);
  }
  onChange(newValue);
  onBlur(newValue);
};
const updateCategory = (category, checked, value, onChange, onBlur) => {
  const categoryItems = category.subCategories;
  let newValue = [...value];
  (categoryItems || []).forEach(({ key: itemKey }) => {
    newValue = newValue.filter((item) => item !== itemKey);
    if (checked) {
      newValue.push(itemKey);
    }
  });
  onChange(newValue);
  onBlur(newValue);
};
const alphabeticalNameSort = (objectA, objectB) => {
  const nameA = objectA == null ? void 0 : objectA.name;
  const nameB = objectB == null ? void 0 : objectB.name;
  if (nameA > nameB) {
    return 1;
  }
  return nameB > nameA ? -1 : 0;
};
const categoryCheckedState = (category, inputValue) => {
  const categoryItems = category.subCategories;
  const allItems = (categoryItems || []).length;
  let minOneChecked = false;
  let allChecked = true;
  let checkedItems = 0;
  (categoryItems || []).forEach(({ key: itemKey }) => {
    const itemChecked = inputValue && inputValue.includes(itemKey);
    if (itemChecked) {
      checkedItems++;
    }
    minOneChecked = minOneChecked || itemChecked;
    allChecked = allChecked && itemChecked;
  });
  return { checked: minOneChecked, partialChecked: minOneChecked && !allChecked, checkedItems, allItems };
};
const BooleanCheckList = (props) => {
  const { disabled, id, onChange, onBlur, value, error, categoryChoices, groupChoices, title, isSyslog } = props;
  const [translate] = useTranslation();
  const classes = useStyles();
  const categoryGroupMap = categoryChoices.reduce((groups, category) => __spreadProps(__spreadValues({}, groups), {
    [category.group]: [...groups[category.group] || [], category].sort(alphabeticalNameSort)
  }), {});
  const categories = groupChoices.map((group) => __spreadProps(__spreadValues({}, group), {
    subCategories: categoryGroupMap[group.key] || void 0
  })).sort(alphabeticalNameSort);
  const renderCategory = (categories2) => categories2.map((category, index) => {
    const { checked, partialChecked, checkedItems, allItems } = categoryCheckedState(category, value);
    return /* @__PURE__ */ jsx(React.Fragment, { children: /* @__PURE__ */ jsxs(
      Accordion,
      {
        className: classes.accordion,
        id: "boolean-checklist-category-expander",
        children: [
          /* @__PURE__ */ jsx(
            AccordionSummary,
            {
              expandIcon: /* @__PURE__ */ jsx(ExpandMoreIcon, {}),
              className: classes.accordionSummary,
              children: /* @__PURE__ */ jsxs(
                ListItem,
                {
                  className: classes.category,
                  children: [
                    /* @__PURE__ */ jsxs(ListItemText, { className: classes.listItemText, children: [
                      /* @__PURE__ */ jsx(
                        Typography,
                        {
                          variant: "body1",
                          className: classes.categoryTitle,
                          id: "boolean-checklist-category-title",
                          children: translate(category.name)
                        }
                      ),
                      /* @__PURE__ */ jsx(Typography, { className: classes.checkCount, children: translate("cuda.inputs.booleanCheckListInput.selectedItemsStatus", {
                        checkedItems,
                        allItems
                      }) })
                    ] }),
                    /* @__PURE__ */ jsx(ListItemIcon, { className: classes.listItemIcon, children: /* @__PURE__ */ jsx(
                      Checkbox,
                      {
                        className: classes.checkbox,
                        disabled,
                        indeterminate: !!partialChecked,
                        checked: !!checked && !partialChecked,
                        color: "primary",
                        onClick: (event) => {
                          event.stopPropagation();
                          updateCategory(category, event.target.checked, value, onChange, onBlur);
                        }
                      }
                    ) })
                  ]
                }
              )
            }
          ),
          /* @__PURE__ */ jsx(AccordionDetails, { className: classes.accordionDetails, children: /* @__PURE__ */ jsx(List, { className: classes.categoryList, children: (category.subCategories || []).map((item, subIndex) => {
            const itemChecked = value && value.includes(item.key);
            return /* @__PURE__ */ jsx(
              ListItem,
              {
                className: classes.subCategory,
                children: /* @__PURE__ */ jsx(
                  Chip,
                  {
                    label: translate(item.name),
                    className: classes.chip,
                    onDelete: () => updateItem(item.key, !itemChecked, value, onChange, onBlur),
                    onClick: () => updateItem(item.key, !itemChecked, value, onChange, onBlur),
                    variant: !!itemChecked ? "filled" : "outlined",
                    deleteIcon: !!itemChecked ? /* @__PURE__ */ jsx(CloseIcon, { id: "boolean-checklist-remove-icon" }) : /* @__PURE__ */ jsx(AddIcon, { id: "boolean-checklist-add-icon" }),
                    color: "primary",
                    disabled,
                    id: "boolean-checklist-item"
                  }
                )
              },
              "list-item-" + index + "-" + subIndex
            );
          }) }) })
        ]
      }
    ) }, "list-category-" + index);
  });
  return /* @__PURE__ */ jsxs("div", { className: isSyslog ? classes.rootSyslog : classes.root, id: id && "boolean-checklist-input-" + id, children: [
    /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.leftHeading, children: translate(title != null ? title : "cuda.inputs.booleanCheckListInput.categories") }),
    /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.rightHeading, children: translate("cuda.inputs.booleanCheckListInput.selectAll") }),
    /* @__PURE__ */ jsx("div", { className: classes.listContainer + (error ? " " + classes.listContainerError : ""), children: /* @__PURE__ */ jsx(List, { className: classes.list, children: renderCategory(categories) }) }),
    error && /* @__PURE__ */ jsx(FormHelperText, { error: true, className: classes.error, children: error }) || null
  ] });
};
const BooleanCheckListInput = (props) => /* @__PURE__ */ jsx(
  Input,
  __spreadProps(__spreadValues({}, props), {
    showErrorOnDisabled: false,
    component: BooleanCheckList,
    noLabel: props.isSyslog,
    inputLabelProps: { fullWidth: true, autoWidth: true },
    displayError: false
  })
);
var BooleanCheckListInput_default = BooleanCheckListInput;
export {
  BooleanCheckList,
  BooleanCheckListInput,
  BooleanCheckListInput_default as default,
  useStyles
};
