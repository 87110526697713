var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { BaseBarCard } from "../../baseCards";
import { colorFunctionSuccess, numberFormatter } from "../../../utils";
import { Trans, useTranslation } from "react-i18next";
import { useReportingData } from "../../../contexts";
import { CardSizes } from "../../../typesAndConstants";
import { sortBy } from "lodash";
import { Typography } from "@barracuda-internal/bds-core";
import { AppCategoriesIcon } from "../../icons";
import { useTheme } from "@mui/material";
const TopAllowedCategoriesSessionsCard = (props) => {
  const { preview, size } = props;
  const [translate] = useTranslation();
  const { data, loading, addFilter } = useReportingData("TopAllowedCategoriesSessions", preview);
  const { sdwanCategoryDataPoints = [] } = data || {};
  const theme = useTheme();
  const maxValue = Math.max(...sdwanCategoryDataPoints.map((category) => category.numberOfSessions));
  const entries = sortBy(sdwanCategoryDataPoints, "numberOfSessions").reverse().map((category) => ({
    title: category.label,
    value: size === CardSizes.small ? numberFormatter(category.numberOfSessions) : translate("stratosReporting.topAllowedCategoriesSessions.sessions", {
      count: category.numberOfSessions,
      formattedCount: numberFormatter(category.numberOfSessions)
    }),
    barProgress: 100 / maxValue * category.numberOfSessions,
    color: colorFunctionSuccess(100 / maxValue * category.numberOfSessions, theme),
    // TODO: This icon can come from our set of icons for each category... as per the SDWAN page
    icon: /* @__PURE__ */ jsx(AppCategoriesIcon, { size: 20 }),
    onClick: category.label && addFilter ? () => addFilter(category.id, "sdwanCategoryDataPoints") : void 0,
    tooltipMessage: category.label && addFilter ? "stratosReporting.filterTooltip" : void 0
  }));
  return /* @__PURE__ */ jsx(
    BaseBarCard,
    __spreadProps(__spreadValues({}, props), {
      entries,
      title: /* @__PURE__ */ jsx(Trans, { i18nKey: "stratosReporting.topAllowedCategoriesSessions.title", children: /* @__PURE__ */ jsx(Typography, { variant: "body1", component: "span" }) }),
      loading: loading && !data.sdwanCategoryDataPoints,
      noData: !loading && !data.sdwanCategoryDataPoints,
      activeFilter: true
    })
  );
};
export {
  TopAllowedCategoriesSessionsCard
};
