import { jsx, jsxs } from "react/jsx-runtime";
import get from "lodash/get";
import { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import { Typography } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import { createStyles } from "@mui/styles";
import { makeOverrideableStyles } from "@cuda-react/theme";
const styles = (theme) => createStyles({
  root: {
    display: "flex"
  },
  percentage: {
    display: "inline-block",
    marginRight: theme.spacing(0.5),
    width: 28,
    paddingTop: 4,
    fontSize: 14,
    height: 16,
    lineHeight: "15px",
    color: theme.palette.text.secondary
  },
  chartContainer: {
    display: "inline-block",
    width: "calc(100% - " + (28 + parseInt(theme.spacing(0.5))) + "px)",
    height: 24
  },
  chart: {
    overflow: "visible !important",
    "& > svg": {
      overflow: "visible !important"
    }
  }
});
const useStyles = makeOverrideableStyles("PercentageBarChartField", styles);
const barChartConfig = (classes, theme, chartData, props) => ({
  chart: {
    type: "bar",
    height: 24,
    margin: [1, 3, 3, 2],
    borderWidth: 1,
    borderColor: theme.palette.divider,
    className: classes.chart
  },
  // @ts-ignore this is a valid option according to the docs.
  title: false,
  xAxis: {
    visible: false
  },
  // @ts-ignore these are valid options according to the docs.
  yAxis: {
    min: 0,
    max: 100,
    labels: false,
    title: false
  },
  // @ts-ignore this is a valid option according to the docs.
  legend: false,
  // @ts-ignore this is a valid option according to the docs.
  credits: false,
  series: [
    {
      type: "bar",
      groupPadding: 0,
      pointPadding: 0,
      minPointLength: 3,
      name: props.label ? props.label : props.source,
      data: chartData
    }
  ],
  tooltip: {
    outside: true,
    shared: true,
    formatter: function() {
      if (props.tooltip || props.tooltipSource) {
        return props.tooltipSource ? get(props.data, props.tooltipSource) : props.tooltip;
      }
      if (this.points && this.points.length > 0 && this.points[0].series) {
        return this.points[0].series.name + ": " + this.y + "%";
      }
      return "";
    }
  }
});
const defaultColorScheme = (val, data, theme, invertColors) => {
  let color;
  if (val < 75) {
    color = invertColors ? theme.palette.error.main : theme.palette.success.main;
  } else if (val >= 75 && val < 90) {
    color = theme.palette.warning.main;
  } else {
    color = invertColors ? theme.palette.success.main : theme.palette.error.main;
  }
  return color;
};
const PercentageBarChartField = (props) => {
  const { data, invertColors, label, source = "", tooltip, tooltipSource, customColorScheme } = props;
  const chart = useRef();
  const divRef = useRef(null);
  const classes = useStyles(props);
  const theme = useTheme();
  const val = get(data, source) || 0;
  const color = !customColorScheme ? defaultColorScheme(val, data, theme, invertColors) : customColorScheme(val, data, theme);
  const chartData = [
    {
      name: label ? label : source,
      // eslint-disable-next-line id-length
      x: 1,
      // eslint-disable-next-line id-length
      y: val,
      color
    }
  ];
  const config = barChartConfig(classes, theme, chartData, props);
  useEffect(() => {
    if (chart.current) {
      chart.current.series[0].update(config.series[0], false);
      chart.current.update(config, false);
      chart.current.redraw();
    } else if (divRef.current) {
      chart.current = new Highcharts["Chart"](divRef.current, config);
    }
  });
  return /* @__PURE__ */ jsxs("div", { className: classes.root, children: [
    /* @__PURE__ */ jsx(Typography, { className: classes.percentage, component: "div", children: val + "%" }),
    /* @__PURE__ */ jsx("div", { className: classes.chartContainer, ref: divRef })
  ] });
};
var PercentageBarChartField_default = PercentageBarChartField;
export {
  PercentageBarChartField,
  PercentageBarChartField_default as default
};
