var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import "isomorphic-fetch";
import React from "react";
import { Route, Switch } from "react-router";
import BccRedirectLoginPage from "../authentication/BccRedirectLoginPage/BccRedirectLoginPage";
import BccUnauthorizedPage from "../authentication/BccUnauthorizedPage/BccUnauthorizedPage";
import AppLayout from "../layout/AppLayout/AppLayout";
import LogoutMenu from "../authentication/LogoutMenu/LogoutMenu";
import UnsupportedPage from "../pages/UnsupportedPage/UnsupportedPage";
import { browserIsIE } from "../../utils";
import "@progress/kendo-theme-material/dist/all.css";
import { LanguageFullReset } from "../../i18n";
const CudaReactLayout = ({
  title,
  additionalNavbarOptions,
  children,
  footerItems,
  nonRouteChildren,
  customLoginPage,
  customLogoutMenu,
  customUnauthorizedPage,
  listAccountsResource,
  navigation = [],
  fullPageRoutes,
  requiresAuthentication
}) => {
  const logout = requiresAuthentication ? customLogoutMenu || /* @__PURE__ */ jsx(LogoutMenu, {}) : null;
  const loginPage = requiresAuthentication ? customLoginPage || BccRedirectLoginPage : null;
  const unsupported = browserIsIE();
  return /* @__PURE__ */ jsx(LanguageFullReset, { children: unsupported ? /* @__PURE__ */ jsx(UnsupportedPage, {}) : (
    //@ts-ignore Switch complains it can only have one child, seems to work fine with multiple...
    /* @__PURE__ */ jsxs(Switch, { children: [
      loginPage && /* @__PURE__ */ jsx(Route, { path: "/login", exact: true, component: loginPage }),
      requiresAuthentication && /* @__PURE__ */ jsx(Route, { path: "/unauthorized", exact: true, children: customUnauthorizedPage ? React.createElement(customUnauthorizedPage, { listAccountsResource }) : /* @__PURE__ */ jsx(BccUnauthorizedPage, {}) }),
      fullPageRoutes || null,
      /* @__PURE__ */ jsx(
        Route,
        {
          path: "/",
          render: (routeProps) => /* @__PURE__ */ jsxs(React.Fragment, { children: [
            nonRouteChildren || null,
            /* @__PURE__ */ jsx(
              AppLayout,
              __spreadProps(__spreadValues({
                logout,
                additionalOptions: additionalNavbarOptions,
                footerItems,
                navigation,
                title
              }, routeProps), {
                children
              })
            )
          ] })
        }
      )
    ] })
  ) });
};
var CudaReactLayout_default = CudaReactLayout;
export {
  CudaReactLayout_default as default
};
