import { jsx } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const WarnIcon = ({ className }) => {
  var _a;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-icon"),
      id: "cuda-icon-warn",
      viewBox: "0 0 60 60",
      children: /* @__PURE__ */ jsx(
        "path",
        {
          style: { fill: (_a = theme.palette.warning) == null ? void 0 : _a.main, strokeWidth: 0 },
          d: "M30,6.8L3.1,53.2h53.7L30,6.8ZM32.4,45.9h-4.9v-4.9h4.9v4.9ZM27.6,36.1v-9.8h4.9v9.8h-4.9Z"
        }
      )
    }
  );
};
var WarnIcon_default = WarnIcon;
export {
  WarnIcon,
  WarnIcon_default as default
};
