import { jsx, jsxs } from "react/jsx-runtime";
import { InputAdornment } from "@mui/material";
import {
  ClickAwayListener,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  TextField,
  Typography
} from "@barracuda-internal/bds-core";
import { Close, Search, SortDescending } from "@barracuda-internal/bds-core/dist/Icons/Core";
import classNames from "classnames";
import { get, isEqual, merge } from "lodash";
import React, { useEffect, useState } from "react";
import { useCrudProps, useDeepCompareEffect } from "../../../hooks";
import { formatErrorMessage, getArrayDataContent } from "../../../utils";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    width: "100%",
    "& .MuiInputBase-root": {
      paddingRight: "4px",
      backgroundColor: theme.palette.background.paper
    }
  },
  textField: {
    "& input": {
      textOverflow: "ellipsis"
    }
  },
  popupIndicator: {
    transition: `transform ${theme.transitions.duration.standard}ms`
  },
  popupIndicatorOpen: {
    transform: "rotate(180deg)"
  },
  popper: {
    zIndex: theme.zIndex.modal,
    width: (props) => props.dropdownAutoWidth ? "auto !important" : void 0,
    minWidth: (props) => props.dropdownAutoWidth ? 256 : void 0,
    maxWidth: (props) => props.dropdownAutoWidth ? "80vw" : void 0
  },
  popperContainer: {
    overflow: "hidden"
  },
  popperList: {
    maxHeight: "40vh",
    overflow: "auto",
    paddingBottom: 0
  },
  showing: {
    marginLeft: theme.spacing(1),
    lineHeight: "1.5rem"
  },
  showMoreLink: {
    color: theme.palette.primary.main,
    cursor: "pointer"
  },
  noData: {
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    textAlign: "center"
  },
  error: {
    marginBottom: theme.spacing(-1),
    marginLeft: theme.spacing(1)
  },
  listItemText: {
    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  },
  listItemSubText: {
    margin: 0
  }
});
const useStyles = makeOverrideableStyles("ConnectedAutocompleteSearch", styles);
const ConnectedAutocompleteSearch = (props) => {
  var _a;
  const {
    addEntry,
    clearOnFocus,
    disabled,
    error,
    filterChoices,
    icon,
    iconMap,
    id,
    label,
    maxOptionsVisible = 10,
    noDataMessage = "cuda.table.noData",
    noSearchIcon,
    onChange,
    optionIcon,
    optionText = "name",
    optionSubText,
    placeholder = "cuda.table.autocomplete.search",
    resource,
    params,
    showMoreText = "cuda.table.autocomplete.showMore",
    staticChoices,
    totalText = "cuda.table.autocomplete.showing",
    value = ""
  } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [desiredOptionsVisible, setDesiredOptionsVisible] = useState(maxOptionsVisible);
  const [pageSize, setPageSize] = useState(maxOptionsVisible);
  const popupOpen = !!anchorEl;
  const query = {
    filter: {
      [optionText]: searchQuery || ""
    },
    pagination: {
      page: 1,
      perPage: pageSize - (staticChoices && staticChoices.length || 0)
    }
  };
  const choices = (_a = useCrudProps(
    resource,
    merge({}, query, params),
    void 0,
    false,
    500
  )[0]) == null ? void 0 : _a.data;
  const fullLength = get(choices, "total", 0) + (staticChoices && staticChoices.length || 0);
  const choicesArray = [...staticChoices || [], ...getArrayDataContent(choices)];
  const filteredChoicesArray = filterChoices ? choicesArray.filter(filterChoices) : choicesArray;
  useDeepCompareEffect(() => {
    setDesiredOptionsVisible(maxOptionsVisible);
  }, [value]);
  useEffect(() => {
    if (anchorEl === null) {
      setDesiredOptionsVisible(maxOptionsVisible);
    }
  }, [anchorEl]);
  useDeepCompareEffect(() => {
    const minimumExtraRequired = desiredOptionsVisible - filteredChoicesArray.length;
    const dataHasLoaded = !!fullLength;
    const pageSizeNotExceededTotal = pageSize < fullLength;
    const incrementPageSize = Math.ceil(minimumExtraRequired / maxOptionsVisible) * maxOptionsVisible;
    if (minimumExtraRequired > 0 && dataHasLoaded && pageSizeNotExceededTotal) {
      setPageSize((current) => current + incrementPageSize);
    }
  }, [choicesArray.length, filteredChoicesArray.length, desiredOptionsVisible]);
  return /* @__PURE__ */ jsx(ClickAwayListener, { onClickAway: () => {
    if (searchQuery !== null && searchQuery !== get(value, optionText)) {
      onChange == null ? void 0 : onChange(null);
      setSearchQuery(null);
    }
    if (clearOnFocus) {
      setSearchQuery(null);
    }
    setAnchorEl(null);
  }, children: /* @__PURE__ */ jsxs("div", { className: classes.root, children: [
    /* @__PURE__ */ jsx(
      TextField,
      {
        fullWidth: true,
        className: classes.textField,
        id,
        disabled,
        onChange: (event) => {
          setSearchQuery(event && event.target && event.target.value);
          setDesiredOptionsVisible(maxOptionsVisible);
          if (!popupOpen) {
            setAnchorEl(event && event.currentTarget && event.currentTarget.parentNode);
          }
        },
        onClick: (event) => {
          if (clearOnFocus) {
            onChange == null ? void 0 : onChange(null);
            setSearchQuery("");
          } else {
            event.target.select();
            setSearchQuery(get(value, optionText, ""));
          }
          !disabled && setAnchorEl(popupOpen ? null : event && event.currentTarget);
        },
        label: label && translate(label),
        placeholder: placeholder && translate(placeholder) || void 0,
        value: (anchorEl ? searchQuery : get(value, optionText)) || "",
        variant: "outlined",
        InputProps: {
          startAdornment: !noSearchIcon && /* @__PURE__ */ jsx(InputAdornment, { position: "start", children: /* @__PURE__ */ jsx(Search, {}) }) || void 0,
          endAdornment: /* @__PURE__ */ jsxs(InputAdornment, { position: "end", children: [
            value && !clearOnFocus && /* @__PURE__ */ jsx(
              IconButton,
              {
                size: "small",
                onClick: (event) => {
                  onChange == null ? void 0 : onChange(null);
                  setAnchorEl(null);
                  setSearchQuery(null);
                  event.stopPropagation();
                },
                disabled,
                children: /* @__PURE__ */ jsx(Close, { fontSize: "small" })
              }
            ),
            /* @__PURE__ */ jsx(
              IconButton,
              {
                size: "small",
                className: classNames(classes.popupIndicator, popupOpen && classes.popupIndicatorOpen),
                disabled,
                children: /* @__PURE__ */ jsx(SortDescending, {})
              }
            )
          ] })
        },
        autoComplete: "off"
      }
    ),
    /* @__PURE__ */ jsx(
      Popper,
      {
        className: classes.popper,
        style: {
          width: anchorEl ? anchorEl.clientWidth : void 0
        },
        role: "presentation",
        anchorEl,
        open: popupOpen,
        children: /* @__PURE__ */ jsx(Paper, { className: classes.popperContainer, children: filteredChoicesArray.length > 0 || addEntry ? /* @__PURE__ */ jsxs(React.Fragment, { children: [
          /* @__PURE__ */ jsxs(List, { dense: true, className: classes.popperList, children: [
            filteredChoicesArray.slice(0, desiredOptionsVisible).map((choice) => {
              const listText = translate(get(choice, optionText));
              const subListText = optionSubText && translate(get(choice, optionSubText));
              const listIcon = iconMap && optionIcon && iconMap[get(choice, optionIcon)] || icon;
              return /* @__PURE__ */ jsxs(
                ListItem,
                {
                  button: true,
                  onClick: () => {
                    onChange == null ? void 0 : onChange(choice);
                    setSearchQuery(clearOnFocus ? null : get(choice, optionText));
                    setAnchorEl(null);
                  },
                  selected: isEqual(choice, value),
                  children: [
                    listIcon && /* @__PURE__ */ jsx(ListItemIcon, { children: listIcon }),
                    /* @__PURE__ */ jsx(
                      ListItemText,
                      {
                        className: classes.listItemText + (subListText ? " " + classes.listItemSubText : ""),
                        inset: iconMap && !listIcon,
                        primary: listText,
                        secondary: subListText
                      }
                    )
                  ]
                },
                listText
              );
            }),
            addEntry && searchQuery ? /* @__PURE__ */ jsxs(
              ListItem,
              {
                button: true,
                onClick: () => {
                  const newChoice = addEntry(searchQuery);
                  onChange == null ? void 0 : onChange(newChoice);
                  setSearchQuery(clearOnFocus ? null : get(newChoice, optionText));
                  setAnchorEl(null);
                },
                children: [
                  icon && /* @__PURE__ */ jsx(ListItemIcon, { children: icon }),
                  /* @__PURE__ */ jsx(
                    ListItemText,
                    {
                      className: classes.listItemText,
                      inset: iconMap && !icon,
                      primary: translate("cuda.table.autocomplete.addEntry", { value: searchQuery })
                    }
                  )
                ]
              },
              "add-entry"
            ) : null
          ] }),
          choices ? /* @__PURE__ */ jsxs(Typography, { variant: "caption", className: classes.showing, children: [
            translate(totalText, {
              current: filteredChoicesArray.slice(0, desiredOptionsVisible).length,
              total: fullLength
            }),
            desiredOptionsVisible < fullLength && filteredChoicesArray.length >= desiredOptionsVisible && /* @__PURE__ */ jsx(
              "a",
              {
                onClick: () => setDesiredOptionsVisible(desiredOptionsVisible + maxOptionsVisible),
                className: classes.showMoreLink,
                children: translate(showMoreText)
              }
            )
          ] }) : null
        ] }) : /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.noData, children: translate(noDataMessage) }) })
      }
    ),
    error ? /* @__PURE__ */ jsx(
      FormHelperText,
      {
        error: true,
        className: classes.error,
        children: formatErrorMessage(error)
      }
    ) : null
  ] }) });
};
var ConnectedAutocompleteSearch_default = ConnectedAutocompleteSearch;
export {
  ConnectedAutocompleteSearch,
  ConnectedAutocompleteSearch_default as default,
  styles
};
