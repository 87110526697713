import { jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect } from "react";
import generatePreviewData from "../../utils/previewDataGenerators/reportingData";
const ReportingDataContext = createContext({ loading: true });
const ReportingDataProvider = ({ children, data, loading, register, unregister, addFilter }) => /* @__PURE__ */ jsx(ReportingDataContext.Provider, { value: { data, loading, register, unregister, addFilter }, children });
const useReportingData = (id, preview) => {
  const { data, loading, register, unregister, addFilter } = useContext(ReportingDataContext);
  useEffect(() => {
    !preview && (register == null ? void 0 : register(id));
    return () => {
      !preview && (unregister == null ? void 0 : unregister(id));
    };
  }, [preview, id]);
  return {
    data: preview ? generatePreviewData() : data,
    loading,
    addFilter: preview ? void 0 : addFilter
  };
};
export {
  ReportingDataContext,
  ReportingDataProvider,
  useReportingData
};
