var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx } from "react/jsx-runtime";
import { useAccounts } from "../../../hooks";
import { useTranslation } from "react-i18next";
import Select from "../../inputs/Select/Select";
import { overrideableStyles } from "@cuda-react/theme";
import { createStyles, makeStyles } from "@mui/styles";
const styles = createStyles({
  root: {
    width: 192
  },
  select: {
    width: 192,
    backgroundColor: (props) => props.transparent ? "rgba(0,0,0,0) !important" : void 0,
    "& > div": {
      backgroundColor: (props) => props.transparent ? "rgba(0,0,0,0) !important" : void 0
    }
  }
});
const useStyles = makeStyles(overrideableStyles("ChangeAccountButton", styles));
const ChangeAccountButton = (props) => {
  const { listAccountsResource, onAccountChange, selectProps, userDataResource } = props;
  const [availableAccounts, currentAccount, defaultAccount, swapAccount] = useAccounts(listAccountsResource, userDataResource);
  const [translate] = useTranslation();
  const classes = useStyles(props);
  return availableAccounts.length > 1 ? /* @__PURE__ */ jsx(
    Select,
    __spreadValues({
      label: translate("cuda.auth.switchAccount"),
      id: "select-account",
      value: currentAccount.id,
      choices: availableAccounts.map((account) => ({
        id: account.id,
        label: account.name,
        subLabel: account.id === defaultAccount.id ? "cuda.auth.defaultAccount" : void 0
      })),
      onChange: (event) => {
        const selectedAccount = availableAccounts.find((account) => {
          var _a;
          return account.id === ((_a = event == null ? void 0 : event.target) == null ? void 0 : _a.value);
        });
        selectedAccount && onAccountChange && onAccountChange(selectedAccount);
        selectedAccount && swapAccount(selectedAccount);
      },
      optionValue: "id",
      optionText: "label",
      classes: {
        formControl: classes.root,
        select: classes.select
      }
    }, selectProps)
  ) : null;
};
var ChangeAccountButton_default = ChangeAccountButton;
export {
  ChangeAccountButton,
  ChangeAccountButton_default as default
};
