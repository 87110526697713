var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { get } from "lodash";
import { User, UserGroup } from "@barracuda-internal/bds-core/dist/Icons/Core";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from "react-i18next";
import {
  BooleanInput,
  FormSection,
  getDataContent,
  Input,
  SelectArrayInput,
  SelectInput,
  SelectPairArrayInput,
  TextArrayInput,
  useCrudProps,
  useTableChoices
} from "@cuda-react/core";
import SingleSiteIcon from "@mui/icons-material/StoreMallDirectory";
import { categoryIcons } from "../components/iconMapping";
import { CustomAppsIcon, GatewayIcon, SiteIcon } from "@cuda-react/icons";
import { CircularProgress } from "@barracuda-internal/bds-core";
import apiResources from "../apiResources";
const sourceTypeChoices = [
  { key: "application", name: "tesseract.security.sourceCriteria.application" },
  { key: "internet", name: "tesseract.security.sourceCriteria.internet" },
  { key: "network", name: "tesseract.security.sourceCriteria.network" },
  { key: "networks", name: "tesseract.security.sourceCriteria.network" },
  { key: "onPremGateway", name: "tesseract.security.sourceCriteria.onpremGateway" },
  { key: "site", name: "tesseract.security.sourceCriteria.site" },
  { key: "userOrGroup", name: "tesseract.security.sourceCriteria.userOrGroup" }
];
const getSourceTypeChoices = (types) => sourceTypeChoices.filter((choice) => types.includes(choice.key));
const hideInput = (key, type) => (value, data) => !get(data, key) || get(data, key) !== type;
const UserGroupDisabledMessage = () => {
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsx(FormHelperText, { error: true, children: translate("tesseract.security.sourceCriteria.userOrGroupDisabled") });
};
const useGeneratedSourceCriteriaSection = (sourceTypes) => {
  var _a;
  const sourceSelectChoices = getSourceTypeChoices(sourceTypes);
  const userOrGroupEnabled = (_a = useCrudProps(sourceTypes.includes("userOrGroup") && apiResources.userOrGroupEnabled || void 0)[0]) == null ? void 0 : _a.data;
  const userOrGroupDisabled = getDataContent(userOrGroupEnabled) !== true;
  return /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.security.sourceCriteria.section", children: [
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: "source.type",
        description: "tesseract.security.sourceCriteria.description",
        label: "tesseract.security.sourceCriteria.type",
        choices: sourceSelectChoices,
        isRequired: true
      }
    ),
    sourceTypes.includes("application") && /* @__PURE__ */ jsx(
      SelectArrayInput,
      {
        source: "source.applications",
        label: "tesseract.security.sourceCriteria.applications",
        description: "tesseract.security.sourceCriteria.applicationDescription",
        resource: apiResources.customApps,
        optionValue: "applicationId",
        options: {
          params: { filter: { type: "network" } },
          dropdownAutoWidth: true
        },
        hide: hideInput("source.type", "application"),
        icon: /* @__PURE__ */ jsx(CustomAppsIcon, {}),
        isRequired: true
      }
    ) || null,
    sourceTypes.includes("network") && /* @__PURE__ */ jsx(
      TextArrayInput,
      {
        source: "source.networks",
        label: "tesseract.security.sourceCriteria.network",
        description: "tesseract.security.sourceCriteria.networkDescription",
        hide: hideInput("source.type", "network"),
        isRequired: true
      }
    ) || null,
    sourceTypes.includes("networks") && /* @__PURE__ */ jsx(
      TextArrayInput,
      {
        source: "source.networks",
        label: "tesseract.security.sourceCriteria.network",
        description: "tesseract.security.sourceCriteria.networkDescription",
        hide: hideInput("source.type", "networks"),
        isRequired: true
      }
    ) || null,
    sourceTypes.includes("onPremGateway") && /* @__PURE__ */ jsx(
      BooleanInput,
      {
        source: "source.allGateways",
        label: "tesseract.security.sourceCriteria.allOnpremGateways",
        hide: hideInput("source.type", "onPremGateway")
      }
    ) || null,
    sourceTypes.includes("onPremGateway") && /* @__PURE__ */ jsx(
      SelectPairArrayInput,
      {
        source: "source.gateways",
        label: "tesseract.security.sourceCriteria.onpremGateway",
        description: "tesseract.security.sourceCriteria.onpremGatewayDescription",
        primarySource: "id",
        primaryLabel: "tesseract.security.sourceCriteria.onpremGateway",
        primaryOptionValue: "uuid",
        resource: apiResources.gatewaysOnPremNetworks,
        addTitle: "tesseract.security.sourceCriteria.addOnpremGateway",
        editTitle: "tesseract.security.sourceCriteria.editOnpremGateway",
        secondarySource: "networks",
        secondaryChoices: "networks",
        secondaryOptions: {
          options: {
            maxOptionsVisible: 2
          }
        },
        secondaryOptionValue: "id",
        secondarySelectAll: true,
        secondarySelectAllText: "tesseract.security.sourceCriteria.allNetworks",
        secondaryLabel: "tesseract.security.sourceCriteria.gatewayNetwork",
        hide: (value, data) => hideInput("source.type", "onPremGateway")(value, data) || get(data, "source.allGateways"),
        isRequired: true
      }
    ) || null,
    sourceTypes.includes("site") && /* @__PURE__ */ jsx(
      BooleanInput,
      {
        source: "source.allSites",
        label: "tesseract.security.sourceCriteria.allSites",
        hide: hideInput("source.type", "site")
      }
    ) || null,
    sourceTypes.includes("site") && /* @__PURE__ */ jsx(
      SelectPairArrayInput,
      {
        source: "source.sites",
        label: "tesseract.security.sourceCriteria.site",
        description: "tesseract.security.sourceCriteria.siteDescription",
        primarySource: "id",
        primaryLabel: "tesseract.security.sourceCriteria.site",
        primaryOptionValue: "uuid",
        resource: apiResources.siteNetworks,
        addTitle: "tesseract.security.sourceCriteria.addSite",
        editTitle: "tesseract.security.sourceCriteria.editSite",
        secondarySource: "networks",
        secondaryChoices: "networks",
        secondaryOptions: {
          options: {
            maxOptionsVisible: 2
          }
        },
        secondaryOptionValue: "id",
        secondarySelectAll: true,
        secondarySelectAllText: "tesseract.security.sourceCriteria.allNetworks",
        secondaryLabel: "tesseract.security.sourceCriteria.siteNetwork",
        hide: (value, data) => hideInput("source.type", "site")(value, data) || get(data, "source.allSites"),
        isRequired: true
      }
    ) || null,
    sourceTypes.includes("userOrGroup") && /* @__PURE__ */ jsx(
      SelectArrayInput,
      {
        source: "source.users",
        label: "tesseract.security.sourceCriteria.users",
        description: "tesseract.security.sourceCriteria.userDescription",
        resource: apiResources.users,
        optionValue: "name",
        require: (value, data) => !(get(data, "source.groups") || []).length,
        hide: hideInput("source.type", "userOrGroup"),
        options: { disabled: userOrGroupDisabled, dropdownAutoWidth: true },
        icon: /* @__PURE__ */ jsx(User, {})
      }
    ),
    sourceTypes.includes("userOrGroup") && /* @__PURE__ */ jsx(
      SelectArrayInput,
      {
        source: "source.groups",
        label: "tesseract.security.sourceCriteria.groups",
        description: "tesseract.security.sourceCriteria.groupDescription",
        resource: apiResources.groups,
        optionValue: "id",
        require: (value, data) => !(get(data, "source.users") || []).length,
        hide: hideInput("source.type", "userOrGroup"),
        options: { disabled: userOrGroupDisabled, dropdownAutoWidth: true },
        icon: /* @__PURE__ */ jsx(UserGroup, {})
      }
    ),
    sourceTypes.includes("userOrGroup") && userOrGroupDisabled && /* @__PURE__ */ jsx(
      Input,
      {
        source: "userOrGroupDisabled",
        minimised: true,
        inputLabelProps: { fullWidth: true },
        component: UserGroupDisabledMessage,
        hide: hideInput("source.type", "userOrGroup")
      }
    )
  ] });
};
const generateChipArrayProps = (renderers, categoryChoices) => (target) => (chipArrayProps) => {
  const {
    renderSiteNetwork,
    renderGatewayNetwork,
    renderApplication,
    getApplicationIconId,
    renderCustomCategory,
    renderGroup,
    renderGateway,
    renderGatewayName,
    renderGatewayWan,
    renderSiteName,
    renderSiteWan,
    renderTargetApplication,
    getTargetApplicationIconId,
    renderUser
  } = renderers;
  const type = get(chipArrayProps, `data.${target}.type`) || "network";
  if (type === "site" && get(chipArrayProps, `data.${target}.allSites`)) {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `${target}.allSites`,
      choices: [{ key: true, name: "tesseract.security.tables.allSites" }],
      icon: /* @__PURE__ */ jsx(SiteIcon, {})
    });
  }
  if (type === "site" && get(chipArrayProps, `data.${target}.wan`)) {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `destination.applianceId`,
      render: renderSiteWan,
      icon: /* @__PURE__ */ jsx(SiteIcon, {})
    });
  }
  if (type === "site") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `${target}.sites`,
      render: renderSiteNetwork,
      optionValue: "uuid",
      sourceValue: "id",
      icon: /* @__PURE__ */ jsx(SingleSiteIcon, {})
    });
  }
  if (type === "onPremGateway" && get(chipArrayProps, `data.${target}.allGateways`)) {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `${target}.allGateways`,
      choices: [{ key: true, name: "tesseract.security.tables.allOnpremGateways" }],
      icon: /* @__PURE__ */ jsx(GatewayIcon, {})
    });
  }
  if (type === "onPremGateway" && get(chipArrayProps, `data.${target}.allGateways`) === false && get(chipArrayProps, `data.${target}.gateways`)) {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `${target}.gateways`,
      render: renderGatewayNetwork,
      optionValue: "uuid",
      sourceValue: "id",
      icon: /* @__PURE__ */ jsx(GatewayIcon, {})
    });
  }
  if (type === "application") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `${target}.applications`,
      render: renderApplication,
      optionValue: "id",
      iconMap: categoryIcons,
      optionIcon: getApplicationIconId
    });
  }
  if (target === "target") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `target`,
      render: renderTargetApplication,
      optionValue: "id",
      iconMap: categoryIcons,
      optionIcon: getTargetApplicationIconId
    });
  }
  if (type === "category") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `${target}.categories`,
      choices: categoryChoices || []
    });
  }
  if (type === "customCategory") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `destination.customCategories`,
      render: renderCustomCategory
    });
  }
  if (type === "userOrGroup") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `${target}.userOrGroups`,
      optionValue: "name",
      iconMap: {
        user: /* @__PURE__ */ jsx(User, {}),
        group: /* @__PURE__ */ jsx(UserGroup, {})
      },
      optionIcon: "icon",
      render: (value, ...args) => get(value, "id") ? renderGroup(get(value, "id"), ...args) : renderUser(get(value, "name"), ...args)
    });
  }
  if (type === "gateway") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `${target}.${type}s`,
      render: renderGateway,
      optionValue: "uuid",
      icon: /* @__PURE__ */ jsx(GatewayIcon, {})
    });
  }
  if (type === "onPremGateway") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `destination.applianceId`,
      render: renderGatewayWan,
      icon: /* @__PURE__ */ jsx(GatewayIcon, {})
    });
  }
  if (type === "onPremGatewayName") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `destination.applianceId`,
      render: renderGatewayName,
      icon: /* @__PURE__ */ jsx(GatewayIcon, {})
    });
  }
  if (type === "siteName") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `destination.applianceId`,
      render: renderSiteName,
      icon: /* @__PURE__ */ jsx(SiteIcon, {})
    });
  }
  if (type === "any") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `${target}.type`,
      choices: [
        { key: "any", name: "Any" }
      ]
    });
  }
  if (type === "networks") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `${target}.${type}`
    });
  }
  if (type === "internet") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `${target}.type`,
      choices: [
        { key: "internet", name: "Internet" }
      ]
    });
  }
  return __spreadProps(__spreadValues({}, chipArrayProps), {
    source: `${target}.${type}s`
  });
};
const generateGenericChipArrayProps = (renderers) => (target) => (chipArrayProps) => {
  const { renderGateway } = renderers;
  const type = get(chipArrayProps, `data.${target}`) || null;
  if (type === "gateway") {
    return __spreadProps(__spreadValues({}, chipArrayProps), {
      source: `configurationUuid`,
      render: renderGateway,
      icon: /* @__PURE__ */ jsx(GatewayIcon, {})
    });
  }
  return __spreadValues({}, chipArrayProps);
};
const useSourceDestinationField = (categoryChoices = []) => {
  const renderSiteNetwork = useTableChoices(apiResources.siteNetworks, {
    optionValue: "uuid",
    filterId: "uuid",
    staticChoices: [{ uuid: "allSites", name: "tesseract.security.applicationControl.settings.allSites" }],
    render: (choice, loading, value, rowData, sourceData) => {
      const selectedNetworks = get(sourceData, "networks") || [];
      const choiceNetworks = get(choice, "networks") || [];
      let renderContent = choice == null ? void 0 : choice.name;
      if (selectedNetworks.length) {
        renderContent = (choice == null ? void 0 : choice.name) && selectedNetworks.map((network) => {
          const networkName = (choiceNetworks.find((item) => item.id === network) || {}).name;
          return networkName ? choice.name + ": " + networkName : null;
        }).filter((item) => !!item);
      }
      return renderContent ? renderContent : loading ? /* @__PURE__ */ jsx(CircularProgress, { size: 14 }) : null;
    }
  });
  const [renderApplication, getApplicationIconId] = useTableChoices(apiResources.applications, {
    optionValue: "id",
    filterId: "applicationId"
  }, "categoryId");
  const renderGroup = useTableChoices(apiResources.groups, { optionValue: "id" });
  const renderGateway = useTableChoices(apiResources.gateways, {
    optionValue: "uuid",
    filterId: "uuid",
    staticChoices: [{ uuid: "allGateways", name: "tesseract.security.applicationControl.settings.allGateways" }]
  });
  const renderGatewayNetwork = useTableChoices(apiResources.gatewaysOnPremNetworks, {
    optionValue: "uuid",
    filterId: "uuid",
    staticChoices: [{ uuid: "allGateways", name: "tesseract.security.applicationControl.settings.allGateways" }],
    render: (choice, loading, value, rowData, sourceData) => {
      const selectedNetworks = get(sourceData, "networks") || [];
      const choiceNetworks = get(choice, "networks") || [];
      let renderContent = choice == null ? void 0 : choice.name;
      if (selectedNetworks.length) {
        renderContent = (choice == null ? void 0 : choice.name) && selectedNetworks.map((network) => {
          const networkName = (choiceNetworks.find((item) => item.id === network) || {}).name;
          return networkName ? choice.name + ": " + networkName : null;
        }).filter((item) => !!item);
      }
      return renderContent ? renderContent : loading ? /* @__PURE__ */ jsx(CircularProgress, { size: 14 }) : null;
    }
  });
  const renderGatewayWan = useTableChoices(apiResources.gatewaysOnPrem, {
    optionValue: "id",
    filterId: "id",
    staticChoices: [{ uuid: "allGateways", name: "tesseract.security.applicationControl.settings.allGateways" }],
    // @ts-ignore Definition in cuda-react is incorrect, needs updating
    render: (choice, loading, value, rowData) => {
      const rowWanId = get(rowData, "destination.wan") || null;
      const choiceWans = get(choice, "wans") || [];
      let renderContent = "";
      if (rowWanId) {
        const wanElement = choiceWans && choiceWans.find((wan) => wan.id === rowWanId);
        renderContent = (wanElement == null ? void 0 : wanElement.name) && (rowData.destination.port ? wanElement.name + ": " + rowData.destination.port : wanElement.name);
      }
      return renderContent ? renderContent : loading ? /* @__PURE__ */ jsx(CircularProgress, { size: 14 }) : null;
    }
  });
  const renderSiteWan = useTableChoices(apiResources.siteConfiguration, {
    optionValue: "id",
    // @ts-ignore Definition in cuda-react is incorrect, needs updating
    render: (choice, loading, value, rowData) => {
      const rowWanId = get(rowData, "destination.wan");
      const choiceWans = get(choice, "wans");
      let renderContent = "";
      if (rowWanId) {
        const wanElement = choiceWans == null ? void 0 : choiceWans.find((wan) => wan.id === rowWanId);
        renderContent = (wanElement == null ? void 0 : wanElement.name) && (rowData.destination.port ? wanElement.name + ": " + rowData.destination.port : wanElement.name);
      }
      return renderContent ? renderContent : loading ? /* @__PURE__ */ jsx(CircularProgress, { size: 14 }) : null;
    }
  });
  const renderGatewayName = useTableChoices(apiResources.gatewaysOnPrem, {
    optionValue: "id",
    filterId: "id"
  });
  const renderSiteName = useTableChoices(apiResources.sites, {
    optionValue: "id",
    filterId: "id"
  });
  const [renderTargetApplication, getTargetApplicationIconId] = useTableChoices(apiResources.validTargets, { optionValue: "key" }, "categoryId");
  const renderCustomCategory = useTableChoices(apiResources.customCategories, {
    optionValue: "id",
    filterId: "id"
  });
  const renderUser = useTableChoices(apiResources.users, { optionValue: "id" });
  return generateChipArrayProps({
    renderSiteNetwork,
    renderGatewayNetwork,
    renderApplication,
    getApplicationIconId,
    renderCustomCategory,
    renderGroup,
    renderGateway,
    renderGatewayName,
    renderGatewayWan,
    renderSiteName,
    renderSiteWan,
    renderTargetApplication,
    getTargetApplicationIconId,
    renderUser
  }, categoryChoices);
};
const useGenericField = (categoryChoices = []) => {
  const renderGateway = useTableChoices(apiResources.gateways, {
    optionValue: "uuid",
    filterId: "uuid",
    staticChoices: [{ uuid: "allGateways", name: "tesseract.security.applicationControl.settings.allGateways" }]
  });
  return generateGenericChipArrayProps({ renderGateway });
};
export {
  UserGroupDisabledMessage,
  getSourceTypeChoices,
  useGeneratedSourceCriteriaSection,
  useGenericField,
  useSourceDestinationField
};
