import { jsx } from "react/jsx-runtime";
import { ButtonDialog } from "@cuda-react/core";
import NotificationContent from "./NotificationContent";
const AddNotificationDialog = ({ onSuccess }) => /* @__PURE__ */ jsx(ButtonDialog, { buttonText: "tesseract.settings.tabs.notification.components.button.add", children: /* @__PURE__ */ jsx(
  NotificationContent,
  {
    create: true,
    title: "tesseract.settings.tabs.notification.components.title.createNotification",
    onSuccess
  }
) });
var AddNotificationDialog_default = AddNotificationDialog;
export {
  AddNotificationDialog_default as default
};
