var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { CrudTypes, getArrayDataContent, useCrudSubscription, useDeepCompareEffect } from "@cuda-react/core";
import apiResources from "../../apiResources";
import { useState } from "react";
import { sortBy } from "lodash";
var useAppliances_default = (presetAppliance) => {
  const [{ data: siteData }, sitesLoading] = useCrudSubscription(
    CrudTypes.GET,
    !presetAppliance ? apiResources.siteNames : void 0,
    { filter: { modelSeries: "T" } }
  );
  const [{ data: onPremGatewayData }, onPremGatewaysLoading] = useCrudSubscription(
    CrudTypes.GET,
    !presetAppliance ? apiResources.gatewayNames : void 0,
    { filter: { type: "on-prem" } }
  );
  const [{ data: managedGatewayData }, managedGatewaysLoading] = useCrudSubscription(
    CrudTypes.GET,
    !presetAppliance ? apiResources.gatewayNames : void 0,
    { filter: { type: "managed" } }
  );
  const [appliances, setAppliances] = useState([]);
  const [neverLoaded, setNeverLoaded] = useState(!presetAppliance);
  useDeepCompareEffect(() => {
    if (!sitesLoading && !onPremGatewaysLoading && !managedGatewaysLoading && !neverLoaded) {
      const applianceItems = sortBy([
        ...getArrayDataContent(siteData).map((item) => __spreadProps(__spreadValues({}, item), { type: "site" })),
        ...getArrayDataContent(onPremGatewayData).map((item) => __spreadProps(__spreadValues({}, item), { type: "on-prem" })),
        ...getArrayDataContent(managedGatewayData).map((item) => __spreadProps(__spreadValues({}, item), { type: "managed" }))
      ], "name");
      setAppliances(applianceItems.length > 0 ? applianceItems : [{
        key: "_NONE_",
        name: "tesseract.dashboard.filters.noAppliances"
      }]);
    } else if ((sitesLoading || onPremGatewaysLoading || managedGatewaysLoading) && neverLoaded) {
      setNeverLoaded(false);
    }
  }, [sitesLoading, onPremGatewaysLoading, managedGatewaysLoading, neverLoaded]);
  return {
    appliances: presetAppliance ? [presetAppliance] : appliances,
    appliancesLoading: neverLoaded || sitesLoading || onPremGatewaysLoading || managedGatewaysLoading
  };
};
export {
  useAppliances_default as default
};
