import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import {
  BooleanInput,
  FormSection,
  getArrayDataContent,
  PasswordInput,
  SelectInput,
  TextInput,
  useCrudProps,
  validateMaxLengthMemo
} from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useSiteInputValidations } from "../../../../hooks/createEditSiteHooks";
import apiResources from "../../../../apiResources";
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    fontSize: 16
  },
  completeMessage: {
    margin: theme.spacing(3)
  },
  boolean: {
    maxWidth: 256
  }
}));
const GeneralIpSecTunnelInput = () => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const inputValidations = useSiteInputValidations();
  const authenticationTypes = getArrayDataContent(useCrudProps(apiResources.authenticationType)[0]);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.completeMessage, children: translate("tesseract.integration.ipSecV2.dialog.generalSection.descriptionMessage") }),
    /* @__PURE__ */ jsx(
      BooleanInput,
      {
        source: "enabled",
        label: "tesseract.integration.ipSecV2.dialog.generalSection.tunnelStatus",
        description: "tesseract.integration.ipSecV2.dialog.generalSection.general.descriptions.tunnelStatus"
      }
    ),
    /* @__PURE__ */ jsx(
      BooleanInput,
      {
        source: "initiate",
        label: "tesseract.integration.ipSecV2.dialog.sourceDestSection.source.initiatesTunnel",
        description: "tesseract.integration.ipSecV2.dialog.generalSection.general.descriptions.initiatesTunnel",
        classes: { field: classes.boolean }
      }
    ),
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.integration.ipSecV2.dialog.generalSection.general.title", children: [
      /* @__PURE__ */ jsx(
        TextInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.generalSection.general.name",
          description: "tesseract.integration.ipSecV2.dialog.generalSection.general.descriptions.name",
          source: "name",
          validate: inputValidations.validateName,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        TextInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.generalSection.general.description",
          description: "tesseract.integration.ipSecV2.dialog.generalSection.general.descriptions.description",
          source: "description",
          validate: [validateMaxLengthMemo(255)]
        }
      )
    ] }),
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.integration.ipSecV2.dialog.generalSection.authentication.title", children: [
      /* @__PURE__ */ jsx(
        SelectInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.generalSection.authentication.authentication",
          description: "tesseract.integration.ipSecV2.dialog.generalSection.authentication.descriptions.authentication",
          source: "authentication.method",
          choices: authenticationTypes,
          disabled: true,
          isRequired: true
        }
      ),
      /* @__PURE__ */ jsx(
        PasswordInput,
        {
          label: "tesseract.integration.ipSecV2.dialog.generalSection.authentication.sharedSecret",
          description: "tesseract.integration.ipSecV2.dialog.generalSection.authentication.descriptions.sharedSecret",
          source: "authentication.psk.secret",
          validate: inputValidations.validatePassword,
          hide: (values, data) => {
            var _a;
            return ((_a = data == null ? void 0 : data.authentication) == null ? void 0 : _a.method) !== "pre-shared-key";
          },
          isRequired: true
        }
      )
    ] })
  ] });
};
var GeneralIpSecTunnelInput_default = GeneralIpSecTunnelInput;
export {
  GeneralIpSecTunnelInput,
  GeneralIpSecTunnelInput_default as default
};
