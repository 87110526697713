var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import React from "react";
import { ButtonDialog } from "@cuda-react/core";
import { Edit } from "@barracuda-internal/bds-core/dist/Icons/Core";
const CreateEditButtonDialog = (_a) => {
  var _b = _a, {
    create,
    data,
    label,
    component: Component,
    onSuccess,
    disabled,
    disabledMessage
  } = _b, props = __objRest(_b, [
    "create",
    "data",
    "label",
    "component",
    "onSuccess",
    "disabled",
    "disabledMessage"
  ]);
  const internalDisabled = typeof disabled === "function" ? disabled(data != null ? data : {}) : disabled;
  return /* @__PURE__ */ jsx(
    ButtonDialog,
    {
      buttonText: create ? label : void 0,
      buttonIcon: create ? void 0 : /* @__PURE__ */ jsx(Edit, { id: "edit-rule-icon" }),
      disabled: internalDisabled,
      disabledMessage,
      stopPropagation: true,
      children: React.createElement(Component, __spreadValues({ create, id: data == null ? void 0 : data.id, onSuccess }, props))
    }
  );
};
CreateEditButtonDialog.defaultProps = {
  label: "tesseract.security.addRuleButtonText"
};
var CreateEditButtonDialog_default = CreateEditButtonDialog;
export {
  CreateEditButtonDialog_default as default
};
