var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx } from "react/jsx-runtime";
import { makeOverrideableStyles } from "@cuda-react/theme";
import Columns from "../../layout/Columns/Columns";
import BasicPage from "../BasicPage/BasicPage";
const useStyles = makeOverrideableStyles("Dashboard", (theme) => ({
  dashboard: {
    width: `calc(100% + ${theme.spacing(2)})`,
    margin: theme.spacing(-0.5, -1, 0)
  }
}));
const Dashboard = (_a) => {
  var _b = _a, { children, columns } = _b, props = __objRest(_b, ["children", "columns"]);
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx(BasicPage, __spreadProps(__spreadValues({}, props), { children: /* @__PURE__ */ jsx(Columns, { columns, className: classes.dashboard, children }) }));
};
var Dashboard_default = Dashboard;
export {
  Dashboard,
  Dashboard_default as default
};
