var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx } from "react/jsx-runtime";
import { useReportingData } from "../../../contexts";
import { sortBy } from "lodash";
import { RiskCard } from "../../baseCards";
import { bytesFormatter, getRiskColor, getRiskLevel } from "../../../utils";
import { useTheme } from "@mui/material";
const ApplicationRiskCard = (props) => {
  const { preview } = props;
  const { data, loading, addFilter } = useReportingData("ApplicationRiskCard", preview);
  const { riskDataPoints = [] } = data || {};
  const theme = useTheme();
  const traffic = riskDataPoints.reduce(
    ({ total, riskTotal }, riskDataPoint) => ({
      total: total + riskDataPoint.numberOfBytes,
      riskTotal: riskTotal + riskDataPoint.numberOfBytes * parseInt(riskDataPoint.id)
    }),
    { total: 0, riskTotal: 0 }
  );
  const riskLevel = getRiskLevel(traffic.riskTotal / traffic.total);
  const entries = sortBy(riskDataPoints, "id").map((riskDataPoint) => ({
    title: `stratosReporting.applicationRisk.${getRiskLevel(riskDataPoint.id) || "low"}`,
    value: bytesFormatter(riskDataPoint.numberOfBytes),
    barProgress: 100 / traffic.total * riskDataPoint.numberOfBytes,
    color: getRiskColor(riskDataPoint.id, theme),
    onClick: addFilter ? () => addFilter(riskDataPoint.id, "riskDataPoints") : void 0,
    tooltipMessage: addFilter ? "stratosReporting.filterTooltip" : void 0
  }));
  return /* @__PURE__ */ jsx(RiskCard, __spreadProps(__spreadValues({}, props), { riskLevel, entries, loading, activeFilter: true }));
};
export {
  ApplicationRiskCard
};
