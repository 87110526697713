import { jsx, jsxs } from "react/jsx-runtime";
import {
  FormSection,
  getArrayDataContent,
  SelectInput,
  StatusIconField,
  Tab,
  TableInput,
  TextArrayInput,
  TextField,
  TextInput,
  useCrudProps
} from "@cuda-react/core";
import { get } from "lodash";
import { useSiteInputValidations } from "../../../../hooks/createEditSiteHooks";
import { useWatch } from "react-hook-form";
import apiResources from "../../../../apiResources";
import { useTranslation } from "react-i18next";
import { dhcpStatusIcons } from "../../../iconMapping";
const DhcpNetworksTableInput = ({ series }) => {
  var _a;
  const [translate] = useTranslation();
  const inputValidations = useSiteInputValidations();
  const wans = useWatch({ name: "wans" });
  const isBridgeConfigured = wans && (wans == null ? void 0 : wans.filter((wan) => wan.mode === "bridge").length) > 0;
  const isSc = series === "S";
  const dhcpTypeChoices = getArrayDataContent((_a = useCrudProps(apiResources.siteConfigurationTypes)[0]) == null ? void 0 : _a.data);
  const filteredDhcpTypeChoices = isSc ? dhcpTypeChoices.filter((choice) => choice.key !== "dhcpRelay") : dhcpTypeChoices;
  return /* @__PURE__ */ jsxs(
    TableInput,
    {
      source: "lans",
      id: "dhcp-networks",
      optionValue: "name",
      labels: {
        addTitle: "tesseract.appliances.settings.dhcp.addNetworkTitle",
        addButtonText: "tesseract.appliances.settings.dhcp.addNetworkButtonText",
        editTitle: "tesseract.appliances.settings.dhcp.editNetworkTitle",
        deleteTitle: "tesseract.appliances.settings.dhcp.deleteNetworkTitle",
        deleteMessage: "tesseract.appliances.settings.dhcp.deleteNetworkMessage"
      },
      minCellWidth: 164,
      noCreate: true,
      noDelete: true,
      defaultFormValues: { dhcp: { type: "dhcpService", defaultLeaseTime: "86400", maxLeaseTime: "86400" } },
      formChildren: [
        /* @__PURE__ */ jsxs(Tab, { label: "tesseract.appliances.settings.dhcp.networks.basicTabTitle", children: [
          /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.appliances.settings.dhcp.networks.networkSectionTitle", children: [
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                source: "dhcp.type",
                label: "tesseract.appliances.settings.dhcp.networks.dhcpRelayType",
                description: "tesseract.appliances.settings.dhcp.descriptions.dhcpRelayType",
                choices: filteredDhcpTypeChoices,
                isRequired: true,
                validate: (value, data) => {
                  if (get(data, "dhcp.type") !== "disabled" && isBridgeConfigured) {
                    return translate("tesseract.appliances.settings.dhcp.validation.dhcpEnable");
                  } else {
                    return void 0;
                  }
                }
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "name",
                label: "tesseract.appliances.settings.dhcp.networks.port",
                description: "tesseract.appliances.settings.dhcp.descriptions.networks.port",
                require: (value, data) => isSc ? get(data, "dhcp.type") === "dhcpService" : get(data, "dhcp.type") === "dhcpService",
                disable: (value, data) => isSc ? get(data, "dhcp.type") === "disabled" : get(data, "dhcp.type") !== "dhcpService"
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "dhcp.description",
                label: "tesseract.appliances.settings.dhcp.networks.description",
                description: "tesseract.appliances.settings.dhcp.descriptions.networks.description"
              }
            )
          ] }),
          /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.appliances.settings.dhcp.networks.basicSectionTitle", children: [
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "dhcp.firstIp",
                label: "tesseract.appliances.settings.dhcp.networks.firstIp",
                description: "tesseract.appliances.settings.dhcp.descriptions.networks.firstIp",
                validate: inputValidations.validateIp,
                require: (value, data) => isSc ? get(data, "dhcp.type") === "dhcpService" : get(data, "dhcp.type") === "dhcpService",
                disable: (value, data) => isSc ? get(data, "dhcp.type") === "disabled" : get(data, "dhcp.type") !== "dhcpService"
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "dhcp.lastIp",
                label: "tesseract.appliances.settings.dhcp.networks.lastIp",
                description: "tesseract.appliances.settings.dhcp.descriptions.networks.lastIp",
                validate: inputValidations.validateIp,
                require: (value, data) => isSc ? get(data, "dhcp.type") === "dhcpService" : get(data, "dhcp.type") === "dhcpService",
                disable: (value, data) => isSc ? get(data, "dhcp.type") === "disabled" : get(data, "dhcp.type") !== "dhcpService"
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "dhcp.domain",
                label: "tesseract.appliances.settings.dhcp.networks.domain",
                description: "tesseract.appliances.settings.dhcp.descriptions.networks.domain",
                disable: (value, data) => isSc ? get(data, "dhcp.type") === "disabled" : get(data, "dhcp.type") !== "dhcpService"
              }
            )
          ] })
        ] }, "basic"),
        !isSc ? /* @__PURE__ */ jsx(
          Tab,
          {
            label: "tesseract.appliances.settings.dhcp.networks.advancedTabTitle",
            children: /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.appliances.settings.dhcp.networks.advancedSectionTitle", children: [
              /* @__PURE__ */ jsx(
                TextArrayInput,
                {
                  source: "dhcp.dnsServers",
                  label: "tesseract.appliances.settings.dhcp.networks.dnsServers",
                  description: "tesseract.appliances.settings.dhcp.descriptions.networks.dnsServers",
                  validate: inputValidations.validateIpArray,
                  disable: (value, data) => get(data, "dhcp.type") !== "dhcpService"
                }
              ),
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "dhcp.bootFileName",
                  label: "tesseract.appliances.settings.dhcp.networks.bootFileName",
                  description: "tesseract.appliances.settings.dhcp.descriptions.networks.bootFileName",
                  disable: (value, data) => get(data, "dhcp.type") !== "dhcpService"
                }
              ),
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "dhcp.defaultLeaseTime",
                  label: "tesseract.appliances.settings.dhcp.networks.defaultLeaseTime",
                  description: "tesseract.appliances.settings.dhcp.descriptions.networks.defaultLeaseTime",
                  validate: inputValidations.validateDefaultLeaseTime,
                  require: (value, data) => get(data, "dhcp.type") === "dhcpService",
                  disable: (value, data) => get(data, "dhcp.type") !== "dhcpService"
                }
              ),
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "dhcp.maxLeaseTime",
                  label: "tesseract.appliances.settings.dhcp.networks.maxLeaseTime",
                  description: "tesseract.appliances.settings.dhcp.descriptions.networks.maxLeaseTime",
                  validate: inputValidations.validateMaxLeaseTime,
                  require: (value, data) => get(data, "dhcp.type") === "dhcpService",
                  disable: (value, data) => get(data, "dhcp.type") !== "dhcpService"
                }
              ),
              /* @__PURE__ */ jsx(
                TextArrayInput,
                {
                  source: "dhcp.winsServers",
                  label: "tesseract.appliances.settings.dhcp.networks.winsServers",
                  description: "tesseract.appliances.settings.dhcp.descriptions.networks.winsServers",
                  validate: inputValidations.validateIpArray,
                  disable: (value, data) => get(data, "dhcp.type") !== "dhcpService"
                }
              ),
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "dhcp.tftpHostname",
                  label: "tesseract.appliances.settings.dhcp.networks.tftpHostname",
                  description: "tesseract.appliances.settings.dhcp.descriptions.networks.tftpHostname",
                  disable: (value, data) => get(data, "dhcp.type") !== "dhcpService"
                }
              ),
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "dhcp.tftpIpAddress",
                  label: "tesseract.appliances.settings.dhcp.networks.tftpIpAddress",
                  description: "tesseract.appliances.settings.dhcp.descriptions.networks.tftpIpAddress",
                  validate: inputValidations.validateIp,
                  disable: (value, data) => get(data, "dhcp.type") !== "dhcpService"
                }
              ),
              /* @__PURE__ */ jsx(
                TextInput,
                {
                  source: "dhcp.vendorOptions",
                  label: "tesseract.appliances.settings.dhcp.networks.vendorOptions",
                  description: "tesseract.appliances.settings.dhcp.descriptions.networks.vendorOptions",
                  disable: (value, data) => get(data, "dhcp.type") !== "dhcpService"
                }
              )
            ] })
          },
          "advanced"
        ) : null
      ],
      tabbed: true,
      children: [
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "dhcp.type",
            label: "tesseract.appliances.settings.dhcp.networks.enable",
            width: 192,
            iconMap: dhcpStatusIcons,
            text: (status) => translate("tesseract.appliances.settings.dhcp.networks." + status)
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "name",
            label: "tesseract.appliances.settings.dhcp.networks.port"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "dhcp.firstIp",
            label: "tesseract.appliances.settings.dhcp.networks.firstIp"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "dhcp.lastIp",
            label: "tesseract.appliances.settings.dhcp.networks.lastIp"
          }
        )
      ]
    }
  );
};
var DhcpNetworksTableInput_default = DhcpNetworksTableInput;
export {
  DhcpNetworksTableInput_default as default
};
