var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { useRef } from "react";
import {
  ActionButtonsField,
  ConnectedTable,
  CrudTypes,
  DateField,
  DeleteDialog,
  DownloadButton,
  TextField,
  useCrudFetch,
  useCrudSubscription,
  useMixpanel
} from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { Button } from "@barracuda-internal/bds-core";
import CreateEditButtonDialog from "../../../../../../components/CreateEditButtonDialog";
import CreateEditSslCertificates from "./CreateEditSslCertificates";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import ExpiryStatus from "./ExpiryStatus";
import apiResources from "../../../../../../apiResources";
const styles = (theme) => ({
  cardActions: {
    paddingRight: theme.spacing(2),
    width: "calc(100% - " + theme.spacing(2) + ")"
  },
  orderCell: {
    minWidth: 70
  },
  actionCell: {
    minWidth: 70,
    paddingLeft: 0
  },
  actions: {
    paddingRight: 0,
    "&> span": {
      position: "relative",
      left: "5%"
    }
  },
  buttons: {
    paddingLeft: theme.spacing(2),
    minWidth: 124
  }
});
const useStyles = makeStyles(styles);
const MakeActiveButton = (_a) => {
  var _b = _a, { activeId, data, onActivate } = _b, props = __objRest(_b, ["activeId", "data", "onActivate"]);
  if (!activeId || !get(data, "id")) {
    return null;
  }
  const [translate] = useTranslation();
  return get(data, "id") !== activeId ? /* @__PURE__ */ jsx(Button, __spreadProps(__spreadValues({ variant: "contained", onClick: () => onActivate(data), color: "primary" }, props), { children: translate("tesseract.security.sslInterception.settings.makeCertificateActive") })) : translate("tesseract.security.sslInterception.settings.activeCertificate");
};
const WrappedMakeActiveButton = MakeActiveButton;
const SslInterceptionCertificates = ({ enabled }) => {
  const registerAction = useMixpanel("SSL Certificate", false);
  const tableRefreshRef = useRef();
  const [activeCertificate, , activeCertificateRefresh] = useCrudSubscription(CrudTypes.GET, apiResources.sslCertificatesActive);
  const [, , updateActiveCertificate] = useCrudFetch(CrudTypes.UPDATE, apiResources.sslCertificatesActive, {});
  const handleActiveCall = (data) => updateActiveCertificate({ id: data.id }).then(activeCertificateRefresh);
  const activeCertificateId = get(activeCertificate, "data.content");
  const isDisabled = typeof enabled === "boolean" && !enabled;
  const classes = useStyles();
  return /* @__PURE__ */ jsx("div", { id: "ssl-certificates", children: /* @__PURE__ */ jsxs(
    ConnectedTable,
    {
      resource: apiResources.sslCertificates,
      actions: [
        /* @__PURE__ */ jsx(
          CreateEditButtonDialog,
          {
            create: true,
            component: CreateEditSslCertificates,
            type: "interception",
            label: "tesseract.security.sslInterception.settings.certificates.create.label",
            onSuccess: () => {
              var _a;
              registerAction("Create");
              (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
            }
          },
          "create"
        )
      ],
      classes: { cardActions: classes.cardActions },
      params: { filter: { type: "interception" } },
      minCellWidth: 128,
      refreshRef: tableRefreshRef,
      flat: true,
      children: [
        /* @__PURE__ */ jsx(
          ExpiryStatus,
          {
            source: "end",
            label: "tesseract.security.sslInterception.settings.status",
            width: 96
          }
        ),
        /* @__PURE__ */ jsx(TextField, { source: "name", label: "tesseract.security.sslInterception.settings.name" }),
        /* @__PURE__ */ jsx(TextField, { source: "description", label: "tesseract.security.sslInterception.settings.description" }),
        /* @__PURE__ */ jsx(TextField, { source: "issuer", label: "tesseract.security.sslInterception.settings.issuer" }),
        /* @__PURE__ */ jsx(DateField, { source: "start", label: "tesseract.security.sslInterception.settings.startDate" }),
        /* @__PURE__ */ jsx(DateField, { source: "end", label: "tesseract.security.sslInterception.settings.endDate" }),
        /* @__PURE__ */ jsx(
          WrappedMakeActiveButton,
          {
            source: "active",
            activeId: activeCertificateId,
            label: "tesseract.security.sslInterception.settings.active",
            className: classes.buttons,
            onActivate: (...args) => {
              registerAction("Active");
              handleActiveCall(...args);
            }
          }
        ),
        /* @__PURE__ */ jsxs(
          ActionButtonsField,
          {
            cellClassName: classes.actions,
            source: "id",
            width: 120,
            children: [
              /* @__PURE__ */ jsx(
                DownloadButton,
                {
                  resource: apiResources.sslCertificatesDownload,
                  params: (props) => ({ id: get(props, "data.id") }),
                  filename: (downloadData, props) => get(props, "data.name") || "activeSslInspectionCertificate",
                  filetype: "pem",
                  tooltipText: "tesseract.security.sslInterception.settings.downloadCertificate",
                  iconButton: true
                }
              ),
              /* @__PURE__ */ jsx(
                CreateEditButtonDialog,
                {
                  component: CreateEditSslCertificates,
                  resource: apiResources.sslCertificates,
                  type: "interception",
                  onSuccess: () => {
                    var _a;
                    registerAction("Update");
                    (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                  }
                }
              ),
              /* @__PURE__ */ jsx(
                DeleteDialog,
                {
                  resource: apiResources.sslCertificates,
                  title: "tesseract.security.sslInterception.settings.certificates.delete.title",
                  message: "tesseract.security.sslInterception.settings.certificates.delete.body",
                  disabledMessage: "tesseract.security.sslInterception.settings.certificates.delete.disabledMessage",
                  disable: (data) => !isDisabled && (!get(data, "id") || !activeCertificateId || get(data, "id") === activeCertificateId),
                  onSuccess: () => {
                    var _a;
                    registerAction("Delete");
                    (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                  }
                }
              )
            ]
          }
        )
      ]
    }
  ) });
};
var SslInterceptionCertificates_default = SslInterceptionCertificates;
export {
  MakeActiveButton,
  SslInterceptionCertificates,
  WrappedMakeActiveButton,
  SslInterceptionCertificates_default as default,
  styles
};
