var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { Grid, TextField } from "@barracuda-internal/bds-core";
import { get } from "lodash";
import SingleSiteIcon from "@mui/icons-material/StoreMallDirectory";
import { User, UserGroup } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { getDataContent, Select, SelectArray, useCrudProps } from "@cuda-react/core";
import { CustomAppsIcon, GatewayIcon, SiteIcon } from "@cuda-react/icons";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { getSourceTypeChoices } from "../../hooks/sourceAndDestinationHooks";
import apiResources from "../../apiResources";
const useStyles = makeStyles((theme) => ({
  container: {
    width: 256
  },
  selectFilter: {
    marginTop: theme.spacing(1),
    width: "100%",
    maxWidth: 256
  },
  textFilter: {
    marginTop: theme.spacing(2),
    width: "100%",
    maxWidth: 256
  },
  typeSelect: {
    width: "100%",
    maxWidth: 256
  },
  disabled: {
    paddingBottom: theme.spacing(2)
  }
}));
const SourceFilter = ({ className, value, onChange, filterSources }) => {
  var _a;
  const userOrGroupEnabled = (_a = useCrudProps(filterSources.includes("userOrGroup") && apiResources.userOrGroupEnabled)[0]) == null ? void 0 : _a.data;
  const userOrGroupDisabled = getDataContent(userOrGroupEnabled) !== true;
  const update = (field) => (eventOrValue) => onChange(__spreadProps(__spreadValues({}, value || {}), {
    [field]: eventOrValue.target ? eventOrValue.target.value : eventOrValue
  }));
  const classes = useStyles();
  const [translate] = useTranslation();
  const sourceSelectChoices = getSourceTypeChoices(filterSources);
  const filters = [];
  if (get(value, "type") === "application") {
    filters.push(
      /* @__PURE__ */ jsx(
        SelectArray,
        {
          id: "sourceApplications",
          label: "tesseract.security.tables.sourceApplication",
          optionValue: "applicationId",
          resource: apiResources.customApps,
          options: { params: { filter: { type: "network" } } },
          icon: /* @__PURE__ */ jsx(CustomAppsIcon, {}),
          value: get(value, "sourceApplication"),
          onChange: update("sourceApplication"),
          className: classNames(className, classes.selectFilter)
        },
        "application"
      )
    );
  }
  if (get(value, "type") === "network") {
    filters.push(
      /* @__PURE__ */ jsx(
        TextField,
        {
          id: "cuda-filter-input-sourceNetwork",
          label: translate("tesseract.security.tables.sourceNetwork"),
          value: get(value, "sourceNetwork", ""),
          onChange: update("sourceNetwork"),
          variant: "outlined",
          className: classNames(className, classes.textFilter)
        },
        "sourceNetwork"
      )
    );
  }
  if (get(value, "type") === "networks") {
    filters.push(
      /* @__PURE__ */ jsx(
        TextField,
        {
          id: "cuda-filter-input-sourceNetwork",
          label: translate("tesseract.security.tables.sourceNetwork"),
          value: get(value, "sourceIngressNatNetwork", ""),
          onChange: update("sourceIngressNatNetwork"),
          variant: "outlined",
          className: classNames(className, classes.textFilter)
        },
        "sourceIngressNatNetwork"
      )
    );
  }
  if (get(value, "type") === "onPremGateway") {
    filters.push(
      /* @__PURE__ */ jsx(
        SelectArray,
        {
          id: "sourceOnPremGateway",
          label: "tesseract.security.tables.sourceOnpremGateway",
          optionValue: "uuid",
          resource: apiResources.gatewaysOnPremNetworks,
          selectAll: true,
          selectAllText: "tesseract.security.tables.allOnpremGateways",
          optionIcon: "uuid",
          icon: /* @__PURE__ */ jsx(GatewayIcon, {}),
          value: get(value, "sourceOnPremGateway"),
          onChange: update("sourceOnPremGateway"),
          className: classNames(className, classes.selectFilter)
        },
        "sourceOnPremGateway"
      )
    );
  }
  if (get(value, "type") === "site") {
    filters.push(
      /* @__PURE__ */ jsx(
        SelectArray,
        {
          id: "sourceSite",
          label: "tesseract.security.tables.sourceSite",
          optionValue: "uuid",
          resource: apiResources.siteNames,
          selectAll: true,
          selectAllText: "tesseract.security.tables.allSites",
          iconMap: { "*": /* @__PURE__ */ jsx(SiteIcon, {}) },
          optionIcon: "uuid",
          icon: /* @__PURE__ */ jsx(SingleSiteIcon, {}),
          value: get(value, "sourceSite"),
          onChange: update("sourceSite"),
          className: classNames(className, classes.selectFilter)
        },
        "sourceSite"
      )
    );
  }
  if (get(value, "type") === "userOrGroup") {
    filters.push(
      /* @__PURE__ */ jsx(
        SelectArray,
        {
          id: "user",
          label: "tesseract.security.tables.user",
          optionValue: "name",
          resource: apiResources.users,
          icon: /* @__PURE__ */ jsx(User, {}),
          value: get(value, "user"),
          onChange: update("user"),
          className: classNames(className, classes.selectFilter),
          disabled: userOrGroupDisabled
        },
        "user"
      )
    );
    filters.push(
      /* @__PURE__ */ jsx(
        SelectArray,
        {
          id: "group",
          label: "tesseract.security.tables.group",
          optionValue: "name",
          resource: apiResources.groups,
          icon: /* @__PURE__ */ jsx(UserGroup, {}),
          value: get(value, "group"),
          onChange: update("group"),
          className: classNames(className, classes.selectFilter, userOrGroupDisabled && classes.disabled),
          disabled: userOrGroupDisabled,
          error: userOrGroupDisabled ? translate("tesseract.security.sourceCriteria.userOrGroupDisabledShort") : void 0
        },
        "group"
      )
    );
  }
  return /* @__PURE__ */ jsxs(Grid, { container: true, direction: "column", className: classes.container, children: [
    /* @__PURE__ */ jsx(
      Select,
      {
        id: "cuda-filter-input-type",
        value: get(value, "type", ""),
        onChange: (eventOrValue) => onChange({ type: eventOrValue.target ? eventOrValue.target.value : eventOrValue }),
        label: translate("tesseract.security.tables.sourceType"),
        choices: sourceSelectChoices,
        classes: { select: classes.typeSelect, formControl: classes.typeSelect }
      }
    ),
    filters
  ] });
};
var SourceFilter_default = SourceFilter;
export {
  SourceFilter_default as default
};
