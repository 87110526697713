var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { ResourceStatisticsCard, useFetchStatisticsCardDetailsAndTotal } from "@stratos/reporting";
import apiResources from "../../../../apiResources";
import { getStatusColorFromTheme } from "../../../../utils/themeUtils";
import { useTheme } from "@mui/material";
import {
  ActionButtonsField,
  generateQueryString,
  getArrayDataContent,
  LinkField,
  SelectedChoiceField,
  StatusIconField,
  TextField,
  useCrudProps,
  useGlobalParam
} from "@cuda-react/core";
import { getAzureResourceLink } from "../../../../utils/azureUtils";
import { Forward } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { push } from "react-router-redux";
import { useDispatch } from "react-redux";
const previewData = (theme) => [
  [
    {
      "title": "tesseract.dashboard.cards.gateway.online",
      "value": 9,
      "color": getStatusColorFromTheme("online", theme)
    },
    {
      "title": "tesseract.dashboard.cards.gateway.pending",
      "value": 0,
      "color": getStatusColorFromTheme("pending", theme)
    },
    {
      "title": "tesseract.dashboard.cards.gateway.degraded",
      "value": 0,
      "color": getStatusColorFromTheme("degraded", theme)
    },
    {
      "title": "tesseract.dashboard.cards.gateway.offline",
      "value": 1,
      "color": getStatusColorFromTheme("offline", theme)
    }
  ],
  16
];
const GatewayStatusCard = (props) => {
  var _a;
  const { preview } = props;
  const theme = useTheme();
  const apiResourceData = useFetchStatisticsCardDetailsAndTotal(
    {
      url: preview ? void 0 : apiResources.gatewayStatus
    },
    {
      url: apiResources.gatewayStates
    },
    {
      linkCreator: (status) => `/#/infrastructure/gateways${generateQueryString({ connectionStatus: [status.key] })}`,
      getKeyColor: (key) => getStatusColorFromTheme(key, theme)
    }
  );
  const [staticData, total] = preview ? previewData(theme) : apiResourceData;
  const isDemo = useGlobalParam("userData.mode")[0] === "demo";
  const dispatch = useDispatch();
  const regionChoices = (_a = useCrudProps(apiResources.gatewayRegions)[0]) == null ? void 0 : _a.data;
  return /* @__PURE__ */ jsxs(
    ResourceStatisticsCard,
    __spreadProps(__spreadValues({}, props), {
      overViewData: staticData,
      overViewValue: total,
      overviewTitle: "tesseract.dashboard.cards.gateway.cardTitle",
      tableTitle: "tesseract.network.gateways",
      children: [
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "aggregateState",
            width: 80
          }
        ),
        /* @__PURE__ */ jsx(
          LinkField,
          {
            source: "name",
            label: "tesseract.gateways.table.name",
            link: getAzureResourceLink("applicationId", isDemo),
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          SelectedChoiceField,
          {
            source: "region",
            label: "tesseract.gateways.table.region",
            choices: getArrayDataContent(regionChoices),
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "sites",
            label: "tesseract.gateways.table.sites",
            width: 104,
            sortable: true
          }
        ),
        /* @__PURE__ */ jsx(ActionButtonsField, { width: 64, source: "id", alwaysVisible: true, children: /* @__PURE__ */ jsx(
          Forward,
          {
            id: "gateway-dashboard-icon",
            onClick: (event, data) => {
              dispatch(push("/infrastructure/gateways/" + data.id));
            }
          }
        ) })
      ]
    })
  );
};
var GatewayStatusCard_default = GatewayStatusCard;
export {
  GatewayStatusCard_default as default
};
