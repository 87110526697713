import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import {
  getArrayDataContent,
  SelectInput,
  TextInput,
  useCrudProps,
  validateMaxLengthMemo,
  validateRegexMemo
} from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
const SdwanConnectorGeneralStep = () => {
  const [{ data: gatewaysData }] = useCrudProps(apiResources.gatewayNames);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "name",
        label: "tesseract.network.sdwanConnector.dialog.general.name",
        description: "tesseract.network.sdwanConnector.dialog.description.general.name",
        isRequired: true,
        validate: [validateMaxLengthMemo(32), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        source: "description",
        label: "tesseract.network.sdwanConnector.dialog.general.description",
        description: "tesseract.network.sdwanConnector.dialog.description.general.description",
        validate: [validateMaxLengthMemo(255)]
      }
    ),
    /* @__PURE__ */ jsx(
      SelectInput,
      {
        source: "configurationUuid",
        label: "tesseract.network.sdwanConnector.dialog.general.appliance",
        description: "tesseract.network.sdwanConnector.dialog.description.general.appliance",
        optionValue: "uuid",
        choices: getArrayDataContent(gatewaysData),
        isRequired: true
      }
    )
  ] });
};
SdwanConnectorGeneralStep.propTypes = {};
var SdwanConnectorGeneralStep_default = SdwanConnectorGeneralStep;
export {
  SdwanConnectorGeneralStep_default as default
};
