import { jsx, jsxs } from "react/jsx-runtime";
import { BasicPage, getArrayDataContent, Tab, Tabs, useCrudProps, useMixpanel } from "@cuda-react/core";
import { Paper } from "@barracuda-internal/bds-core";
import apiResources from "../../../../../apiResources";
import SettingsTable from "./SettingsTable";
import ExplicitTable from "./ExplicitTable";
import { sortBy } from "lodash";
const UrlFilters = () => {
  var _a, _b;
  useMixpanel("Web Filter");
  const actionChoices = getArrayDataContent((_a = useCrudProps(apiResources.urlFilterActions, {}, {}, true)[0]) == null ? void 0 : _a.data);
  const categoryChoices = sortBy(getArrayDataContent((_b = useCrudProps(apiResources.urlFilterCategories, {}, {}, true)[0]) == null ? void 0 : _b.data), "key");
  return /* @__PURE__ */ jsx(
    BasicPage,
    {
      title: "tesseract.securityAndAccess.pageTitle",
      subtitle: ["tesseract.securityAndAccess.webFilter", "tesseract.securityAndAccess.urlFilter"],
      authenticated: true,
      children: /* @__PURE__ */ jsx(Paper, { children: /* @__PURE__ */ jsxs(Tabs, { children: [
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.security.urlFiltering.settings.tabTitle", children: /* @__PURE__ */ jsx(
          SettingsTable,
          {
            actionChoices,
            categoryChoices
          }
        ) }),
        /* @__PURE__ */ jsx(Tab, { label: "tesseract.security.urlFiltering.exceptions.tabTitle", children: /* @__PURE__ */ jsx(
          ExplicitTable,
          {
            actionChoices,
            categoryChoices
          }
        ) })
      ] }) })
    }
  );
};
var UrlFilters_default = UrlFilters;
export {
  UrlFilters,
  UrlFilters_default as default
};
