import { jsx } from "react/jsx-runtime";
import {
  Card,
  generateQueryString,
  getArrayDataContent,
  ResourceStatisticsCardContent,
  useCrudProps
} from "@cuda-react/core";
import { useStatisticsCardDetailsAndTotal } from "../../../../hooks/useStatisticsCardDetailsAndTotal";
import apiResources from "../../../../apiResources";
const IoTStatusCard = ({ type, details }) => {
  var _a;
  const siteStatusChoices = (_a = useCrudProps(type === "gateways" ? apiResources.siteStates : void 0)[0]) == null ? void 0 : _a.data;
  const [iotStatusDetails, iotStatusTotal] = useStatisticsCardDetailsAndTotal(
    getArrayDataContent(siteStatusChoices),
    (status) => `/infrastructure/iot${generateQueryString({
      gateway: [+details.id],
      connectionStatus: [status.key]
    })}`
  );
  return type === "gateways" ? /* @__PURE__ */ jsx(
    Card,
    {
      id: "ConnectedIoTCard",
      title: "tesseract.appliances.dashboard.iotStatus.tabTitle",
      children: /* @__PURE__ */ jsx(
        ResourceStatisticsCardContent,
        {
          overviewValue: iotStatusTotal,
          details: iotStatusDetails,
          resource: apiResources.siteStatus,
          params: { filter: { upstream: details.id, modelSeries: "S" } }
        }
      )
    }
  ) : null;
};
var IoTStatusCard_default = IoTStatusCard;
export {
  IoTStatusCard_default as default
};
