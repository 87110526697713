import { jsx, jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { get, isArray } from "lodash";
import { useTranslation } from "react-i18next";
import { Typography } from "@barracuda-internal/bds-core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    lineHeight: "24px",
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14)
  },
  icon: {
    top: theme.spacing(0.75),
    position: "relative",
    paddingRight: theme.spacing(1)
  }
});
const useStyles = makeOverrideableStyles("SelectedChoiceField", styles);
const SelectedChoiceField = (props) => {
  const { choices, className, data, icon, iconMap, optionText = "name", optionValue = "key", optionIcon = "", source = "", render } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const dataValue = get(data, source);
  const choiceObject = isArray(choices) && choices.find((val) => get(val, optionValue) === dataValue) || {};
  const choiceText = get(choiceObject, optionText, null);
  const renderedIcon = get(iconMap, get(choiceObject, optionIcon), icon);
  const renderedText = render ? render(dataValue, data, choiceObject) : choiceText;
  return /* @__PURE__ */ jsxs(Typography, { variant: "body2", component: "span", className: classNames(className, classes.root), children: [
    renderedIcon ? /* @__PURE__ */ jsx("span", { className: classes.icon, children: renderedIcon }) : null,
    renderedText && (typeof renderedText === "string" ? translate(renderedText) : renderedText)
  ] });
};
var SelectedChoiceField_default = SelectedChoiceField;
export {
  SelectedChoiceField,
  SelectedChoiceField_default as default,
  styles
};
