import { jsx, jsxs } from "react/jsx-runtime";
import { ResourceStatisticsCard } from "../../baseCards";
import generatePreviewData from "../../../utils/previewDataGenerators/ztnaResources";
import apiResources from "../../../apiResources";
import { useCardFetch } from "../../../hooks";
import { ChipArrayField, getArrayDataContent, TextField, useCrudProps, useTableChoices } from "@cuda-react/core";
import { useTheme } from "@mui/material";
const ZTNAResourcesCard = (props) => {
  var _a;
  const { preview, size } = props;
  const { data = {} } = useCardFetch({ url: !preview ? apiResources.ZTNAResources : void 0 });
  const { total, custom, inUse } = (preview ? generatePreviewData() : data) || {};
  const renderGroups = useTableChoices(apiResources.groups, { optionValue: "id", filterId: "id" });
  const renderApplication = useTableChoices(apiResources.applications, { optionValue: "id", filterId: "applicationId" });
  const renderUsers = useTableChoices(apiResources.users, { optionValue: "id", filterId: "id" });
  const securityPostureChoices = (_a = useCrudProps(apiResources.zeroTrustPoliciesSecurityPosture)[0]) == null ? void 0 : _a.data;
  const theme = useTheme();
  const overviewData = [
    { title: "stratosReporting.ZTNAResources.total", value: total || 0, color: theme.palette.general.blue },
    { title: "stratosReporting.ZTNAResources.custom", value: custom || 0, color: theme.palette.general.turquoise }
  ];
  const totalValue = inUse ? inUse : 0;
  return /* @__PURE__ */ jsxs(
    ResourceStatisticsCard,
    {
      overViewValue: totalValue,
      overviewTitle: "stratosReporting.ZTNAResources.title",
      customOverviewTitle: "stratosReporting.ZTNAResources.inUse",
      overViewData: overviewData,
      tableTitle: "stratosReporting.ZTNAResources.tableName",
      size,
      preview,
      resource: apiResources.zeroTrustPolicies,
      children: [
        /* @__PURE__ */ jsx(TextField, { source: "name", label: "stratosReporting.ZTNAResources.table.name" }),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "accessCriteria.users",
            label: "stratosReporting.ZTNAResources.table.users",
            render: renderUsers
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "accessCriteria.groups",
            label: "stratosReporting.ZTNAResources.table.groups",
            render: renderGroups
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "resourceCriteria.applications",
            label: "stratosReporting.ZTNAResources.table.resources",
            render: renderApplication
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "accessCriteria.securityPosture",
            label: "stratosReporting.ZTNAResources.table.devicePosture",
            choices: getArrayDataContent(securityPostureChoices)
          }
        )
      ]
    }
  );
};
export {
  ZTNAResourcesCard
};
