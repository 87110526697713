var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { CrudTypes } from "../../clients/crudClient";
import { getArrayDataContent } from "../../utils/jsonUtils";
import { useCrudProps, useCrudSubscription } from "../CrudHooks";
import { useDeepCompareEffect } from "../UtilHooks";
import Highcharts from "highcharts";
import HighchartsMap from "highcharts/modules/map";
import markerClusters from "highcharts/modules/marker-clusters";
import { useEffect, useRef, useState } from "react";
import proj4 from "proj4";
import { get, merge } from "lodash";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStatusAvatar } from "../CardHooks";
import { parseData } from "./parseData";
import { generateOptions } from "./generateOptions";
import { zoomToFit } from "./zoomToFit";
import { clearLinkedSeries } from "./clearLinkedSeries";
markerClusters(Highcharts);
HighchartsMap(Highcharts);
const useLocationsMap = ({
  series,
  resource,
  data,
  disableZoomOnLoad,
  params,
  statusAvatarSource,
  statusAvatarAggregateSource,
  updateState,
  legendEnabled = true,
  visible = true,
  noRandom,
  chartOverrides
}) => {
  var _a;
  const mapRef = useRef(null);
  const chart = useRef();
  const tooltipRef = useRef(null);
  const theme = useTheme();
  const [translate, { options: { resources }, language }] = useTranslation();
  const [tooltipAnchor, setTooltipAnchor] = useState(null);
  const setTooltip = (tooltip) => {
    if (!tooltip) {
      setTooltipAnchor(null);
      tooltipRef.current = null;
    } else {
      tooltip.element !== void 0 && setTooltipAnchor(tooltip.element);
      tooltipRef.current = merge({}, tooltipRef.current, tooltip);
    }
  };
  const [crudData, loading] = useCrudSubscription(CrudTypes.GET, resource, params);
  const resolvedData = resource ? { data: crudData == null ? void 0 : crudData.data } : { data };
  const selectedPointType = get(tooltipRef.current, "point.pointData.series");
  const seriesDetails = selectedPointType && selectedPointType.details;
  const pointResourceId = get(tooltipRef.current, `point.pointData.${seriesDetails && seriesDetails.optionValue}`);
  const pointResourceData = (_a = useCrudProps(
    seriesDetails && seriesDetails.resource,
    pointResourceId && { id: pointResourceId }
  )[0]) == null ? void 0 : _a.data;
  useStatusAvatar(
    resolvedData,
    getArrayDataContent(resolvedData == null ? void 0 : resolvedData.data).flatMap((value) => value.data),
    loading,
    statusAvatarSource,
    statusAvatarAggregateSource,
    updateState
  );
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.proj4 = window.proj4 || proj4;
    }
    return () => {
      chart.current && chart.current.destroy();
    };
  }, []);
  useDeepCompareEffect(() => {
    if (visible) {
      const filteredDataPoints = parseData(series || [], resolvedData, translate, noRandom);
      if (chart.current) {
        clearLinkedSeries(chart, setTooltip, false);
        if (!disableZoomOnLoad) {
          chart.current.xAxis && chart.current.xAxis[0].setExtremes(null, null, false);
          chart.current.yAxis && chart.current.yAxis[0].setExtremes(null, null, false);
        }
        filteredDataPoints.forEach((dataPoint, index) => {
          var _a2, _b;
          (_b = (_a2 = chart.current) == null ? void 0 : _a2.series[index + 1]) == null ? void 0 : _b.update(
            __spreadProps(__spreadValues({}, dataPoint), {
              name: dataPoint.label,
              type: "mappoint",
              data: dataPoint.data,
              originalData: dataPoint.data
            }),
            true
          );
        });
        chartOverrides && chart.current.update(chartOverrides, true);
        chart.current.reflow();
        if (!disableZoomOnLoad) {
          const allPoints = chart.current.series.filter((series2) => series2.userOptions && series2.userOptions.type === "mappoint").flatMap((series2) => series2.points);
          allPoints && zoomToFit(chart, allPoints, true);
        }
      } else {
        const options = generateOptions(
          chart,
          theme,
          legendEnabled,
          filteredDataPoints,
          setTooltip,
          tooltipRef,
          translate,
          chartOverrides
        );
        chart.current = new Highcharts["Map"](mapRef.current, options);
      }
    }
  }, [series, crudData, visible, resources, language, chartOverrides]);
  return { tooltipAnchor, tooltip: tooltipRef.current, setTooltip, pointResourceData, seriesDetails, mapRef };
};
export {
  useLocationsMap
};
