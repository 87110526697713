import { jsx } from "react/jsx-runtime";
import { DialogBody, LoadingMessage, useCrudProps } from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import { UserDirectoryForm } from "./UserDirectoryForm/UserDirectoryForm";
const EditUserDirectoryWrapper = ({ id, onClose, onSuccess }) => {
  const [{ data: directory }] = useCrudProps(apiResources.directories, { id });
  if (!directory) {
    return /* @__PURE__ */ jsx(LoadingMessage, {});
  }
  return /* @__PURE__ */ jsx(DialogBody, { title: "tesseract.identity.userDirectories.editDirectory", onClose, form: true, children: /* @__PURE__ */ jsx(
    UserDirectoryForm,
    {
      create: false,
      id,
      directory,
      onClose: (refresh) => {
        if (refresh) {
          onSuccess();
        }
        onClose();
      }
    }
  ) });
};
export {
  EditUserDirectoryWrapper
};
