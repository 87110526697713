var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  Container,
  Dialog,
  FormControl,
  FormHelperText,
  IconButton,
  Typography
} from "@barracuda-internal/bds-core";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import {
  CrudTypes,
  DateField,
  DialogBody,
  formatErrorMessage,
  getArrayDataContent,
  getDataContent,
  Input,
  SelectSerial,
  TextField,
  Toolbar,
  useCrudSubscription
} from "@cuda-react/core";
import { StatusIcon } from "@cuda-react/icons";
import { Add, Delete, Edit } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { get } from "lodash";
import { siteGatewayStatusIcons } from "../../../iconMapping";
import apiResources from "../../../../apiResources";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&> *": {
      marginBottom: theme.spacing(1)
    }
  },
  spaced: {
    "&> *": {
      marginRight: theme.spacing(1)
    }
  },
  spacedIcons: {
    "&> *": {
      marginRight: theme.spacing(1)
    },
    "& svg": {
      position: "relative",
      top: theme.spacing(0.75)
    }
  },
  serialTable: {
    width: "auto",
    "& td": {
      padding: theme.spacing(1, 2)
    },
    "& th": {
      paddingBottom: theme.spacing(0.5),
      color: theme.palette.text.secondary
    }
  },
  addCell: {
    textAlign: "center",
    borderBottom: "0"
  },
  cancel: {
    marginRight: 12
  },
  dialog: {
    width: 850
  },
  dialogDelete: {
    width: "auto",
    maxWidth: 700
  },
  dialogBody: {
    padding: 16
  },
  dialogPaper: {
    minWidth: "fit-content"
  },
  typographyBold: {
    fontWeight: "bold"
  }
}));
const SerialEdit = ({
  applianceType,
  value,
  onChange,
  onBlur,
  disabled,
  model,
  id,
  error,
  noAdd,
  series
}) => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const [dialog, setDialog] = useState();
  const siteStatus = getDataContent(useCrudSubscription(
    CrudTypes.GET,
    applianceType === "site" ? apiResources.siteDashboardStatus : apiResources.gateways,
    { id },
    { pollInterval: 3e4 }
  )[0], {});
  const connectionStatus = get(siteStatus, "connectionStatus.connectionStates", []);
  const siteHA = getArrayDataContent(useCrudSubscription(
    CrudTypes.GET,
    applianceType === "site" ? apiResources.siteHA : apiResources.gatewayHA,
    { id },
    { pollInterval: 3e4 }
  )[0]);
  const isSecondary = (serial) => value && value.length > 1 && (siteHA.find((item) => item.serial === serial) || {}).primary === false;
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsxs(FormControl, { error: !!error, children: [
      Array.isArray(value) ? /* @__PURE__ */ jsxs(Table, { className: classes.serialTable, id: "table-input-serial", children: [
        /* @__PURE__ */ jsx(TableHead, { children: /* @__PURE__ */ jsxs(TableRow, { children: [
          /* @__PURE__ */ jsx(TableCell, { children: translate("tesseract.appliances.settings.basic.serialEdit.table.status") }),
          /* @__PURE__ */ jsx(TableCell, { children: translate("tesseract.appliances.settings.basic.serialEdit.table.serial") }),
          /* @__PURE__ */ jsx(TableCell, {})
        ] }) }),
        /* @__PURE__ */ jsxs(TableBody, { children: [
          value.map((serial) => /* @__PURE__ */ jsxs(TableRow, { children: [
            /* @__PURE__ */ jsx(TableCell, { children: /* @__PURE__ */ jsx(
              StatusIcon,
              {
                iconMap: siteGatewayStatusIcons,
                status: (connectionStatus.find((instance) => instance.id === serial) || {}).state || ""
              }
            ) }),
            /* @__PURE__ */ jsx(TableCell, { children: serial }),
            /* @__PURE__ */ jsxs(TableCell, { children: [
              /* @__PURE__ */ jsx(
                IconButton,
                {
                  onClick: () => setDialog({ mode: "edit", target: serial }),
                  disabled,
                  id: "edit-serial-icon",
                  children: /* @__PURE__ */ jsx(Edit, {})
                }
              ),
              value.length > 1 ? /* @__PURE__ */ jsx(
                IconButton,
                {
                  onClick: () => setDialog({ mode: "delete", target: serial }),
                  disabled,
                  id: "delete-serial-icon",
                  children: /* @__PURE__ */ jsx(Delete, {})
                }
              ) : null
            ] })
          ] }, serial)),
          value.length < 2 && !noAdd ? /* @__PURE__ */ jsx(TableRow, { children: /* @__PURE__ */ jsx(TableCell, { colSpan: 5, className: classes.addCell, children: /* @__PURE__ */ jsx(
            Button,
            {
              onClick: () => setDialog({ mode: "add" }),
              startIcon: /* @__PURE__ */ jsx(Add, {}),
              disabled,
              children: translate("tesseract.appliances.settings.basic.serialEdit.table.add")
            }
          ) }) }) : null
        ] })
      ] }) : null,
      error && /* @__PURE__ */ jsx(FormHelperText, { children: formatErrorMessage(error) })
    ] }),
    /* @__PURE__ */ jsxs(
      Dialog,
      {
        open: !!dialog,
        classes: { paper: classes.dialogPaper },
        children: [
          /* @__PURE__ */ jsx(
            DialogBody,
            {
              onClose: () => setDialog(null),
              title: (dialog == null ? void 0 : dialog.mode) === "edit" ? "tesseract.appliances.settings.basic.serialEdit.editTitle" : (dialog == null ? void 0 : dialog.mode) === "add" ? "tesseract.appliances.settings.basic.serialEdit.addTitle" : "tesseract.appliances.settings.basic.serialEdit.deleteTitle",
              classes: { dialogContent: dialog && dialog.mode === "delete" ? classes.dialogDelete : classes.dialog },
              children: /* @__PURE__ */ jsx(Container, { className: classes.dialogBody, children: dialog && (dialog.mode === "add" || dialog.mode === "edit") ? /* @__PURE__ */ jsxs(React.Fragment, { children: [
                /* @__PURE__ */ jsx(Typography, { children: translate(
                  (dialog == null ? void 0 : dialog.mode) === "edit" ? "tesseract.appliances.settings.basic.serialEdit.edit" : "tesseract.appliances.settings.basic.serialEdit.add",
                  { context: applianceType }
                ) }),
                /* @__PURE__ */ jsxs(
                  SelectSerial,
                  {
                    id: "serial",
                    value: dialog && dialog.serial || "",
                    onChange: (value2) => {
                      setDialog(__spreadProps(__spreadValues({}, dialog), { serial: value2 }));
                    },
                    onBlur: () => {
                    },
                    claimResource: apiResources.claimAppliance,
                    filter: { model, series },
                    expandSectionButtonText: "tesseract.appliances.dialog.serials.addAppliance",
                    linkingCodeInputLabel: "tesseract.appliances.dialog.serials.linkingCode",
                    maxSelectable: 1,
                    searchField: "serial",
                    tableResource: apiResources.serials,
                    children: [
                      /* @__PURE__ */ jsx(
                        TextField,
                        {
                          label: "tesseract.appliances.dialog.serials.table.serial",
                          source: "serial",
                          sortable: true
                        }
                      ),
                      /* @__PURE__ */ jsx(TextField, { label: "tesseract.appliances.dialog.serials.table.model", source: "model" }),
                      /* @__PURE__ */ jsx(
                        TextField,
                        {
                          label: "tesseract.appliances.dialog.serials.table.location",
                          source: "location.summary"
                        }
                      ),
                      /* @__PURE__ */ jsx(
                        TextField,
                        {
                          label: "tesseract.appliances.dialog.serials.table.orderId",
                          source: "orderId"
                        }
                      ),
                      /* @__PURE__ */ jsx(
                        DateField,
                        {
                          label: "tesseract.appliances.dialog.serials.table.orderDate",
                          source: "ordered",
                          dateOnly: true
                        }
                      )
                    ]
                  }
                ),
                /* @__PURE__ */ jsx("br", {}),
                /* @__PURE__ */ jsx(
                  Typography,
                  {
                    className: classes.typographyBold,
                    children: translate("tesseract.appliances.settings.basic.serialEdit.save")
                  }
                ),
                /* @__PURE__ */ jsx("br", {}),
                /* @__PURE__ */ jsx(Typography, { children: series === "S" ? /* @__PURE__ */ jsx(
                  Trans,
                  {
                    i18nKey: dialog && dialog.mode === "add" ? "tesseract.appliances.settings.basic.serialEdit.saveSCAdd" : "tesseract.appliances.settings.basic.serialEdit.saveSCEdit",
                    children: /* @__PURE__ */ jsx("b", {})
                  }
                ) : /* @__PURE__ */ jsx(
                  Trans,
                  {
                    i18nKey: dialog && dialog.mode === "add" ? "tesseract.appliances.settings.basic.serialEdit.saveAdd" : "tesseract.appliances.settings.basic.serialEdit.saveEdit",
                    children: /* @__PURE__ */ jsx("b", {})
                  }
                ) }),
                /* @__PURE__ */ jsx("br", {}),
                dialog && (dialog.mode === "add" || dialog.mode === "edit" && Array.isArray(value) && value.length > 1) && /* @__PURE__ */ jsx(Typography, { children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.appliances.settings.basic.serialEdit.saveHA", children: /* @__PURE__ */ jsx("b", {}) }) }),
                dialog.mode === "edit" && isSecondary(dialog && dialog.target) && /* @__PURE__ */ jsxs(React.Fragment, { children: [
                  /* @__PURE__ */ jsx("br", {}),
                  /* @__PURE__ */ jsx("b", { children: /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.appliances.settings.basic.serialEdit.saveEditSecondary") }) })
                ] })
              ] }) : /* @__PURE__ */ jsxs(React.Fragment, { children: [
                /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.appliances.settings.basic.serialEdit.delete1", {
                  serial: (dialog == null ? void 0 : dialog.target) || "",
                  context: applianceType
                }) }),
                /* @__PURE__ */ jsx("br", {}),
                /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.appliances.settings.basic.serialEdit.delete2") }),
                /* @__PURE__ */ jsx("br", {}),
                /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.appliances.settings.basic.serialEdit.saveDelete") }),
                value && value.length > 1 && /* @__PURE__ */ jsxs(React.Fragment, { children: [
                  /* @__PURE__ */ jsx("br", {}),
                  /* @__PURE__ */ jsx(Typography, { children: /* @__PURE__ */ jsx("b", { children: isSecondary(dialog == null ? void 0 : dialog.target) ? translate("tesseract.appliances.settings.basic.serialEdit.saveDeleteSecondary") : translate("tesseract.appliances.settings.basic.serialEdit.saveDeletePrimary", { context: applianceType }) }) })
                ] })
              ] }) })
            }
          ),
          /* @__PURE__ */ jsxs(Toolbar, { children: [
            /* @__PURE__ */ jsx(
              Button,
              {
                variant: "contained",
                color: "primary",
                onClick: () => {
                  const newValue = value.filter((existingSerial) => existingSerial !== dialog.target);
                  dialog.mode !== "delete" && newValue.push(get(dialog.serial, "[0].serial"));
                  onChange(newValue);
                  onBlur(newValue);
                  setDialog(null);
                },
                disabled: dialog && dialog.mode !== "delete" && (!dialog.serial || dialog.serial.length === 0),
                children: translate("cuda.delete.ok")
              }
            ),
            /* @__PURE__ */ jsx(
              Button,
              {
                className: classes.cancel,
                variant: "contained",
                color: "secondary",
                onClick: () => {
                  onBlur(value);
                  setDialog(null);
                },
                children: translate("cuda.delete.cancel")
              }
            )
          ] })
        ]
      }
    )
  ] });
};
const SerialEditInput = (props) => /* @__PURE__ */ jsx(Input, __spreadProps(__spreadValues({}, props), { component: SerialEdit }));
var SerialEditInput_default = SerialEditInput;
export {
  SerialEdit,
  SerialEditInput_default as default
};
