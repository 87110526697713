var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { get, set } from "lodash";
import { getArrayDataContent } from "@cuda-react/core";
const setFilterFromValue = (filter, filterKey, sourceKey, params) => {
  const value = get(params, sourceKey);
  if (value) {
    set(filter, filterKey, value);
  }
};
const formatSourceDestinationFilters = (params) => {
  const filter = {};
  setFilterFromValue(filter, "sourceType", "filter.source.type", params);
  setFilterFromValue(filter, "sourceNetwork", "filter.source.sourceNetwork", params);
  setFilterFromValue(filter, "sourceNetwork", "filter.source.sourceIngressNatNetwork", params);
  setFilterFromValue(filter, "sourceSite", "filter.source.sourceSite", params);
  setFilterFromValue(filter, "sourceSite", "filter.source.sourceOnPremGateway", params);
  setFilterFromValue(filter, "sourceApplication", "filter.source.sourceApplication", params);
  setFilterFromValue(filter, "user", "filter.source.user", params);
  setFilterFromValue(filter, "group", "filter.source.group", params);
  setFilterFromValue(filter, "application", "filter.destination.application", params);
  setFilterFromValue(filter, "destinationType", "filter.destination.type", params);
  setFilterFromValue(filter, "destinationSite", "filter.destination.destinationSite", params);
  setFilterFromValue(filter, "destinationSite", "filter.destination.destinationOnPremGateway", params);
  setFilterFromValue(filter, "destinationApplication", "filter.destination.destinationApplication", params);
  setFilterFromValue(filter, "destinationNetwork", "filter.destination.destinationNetwork", params);
  setFilterFromValue(filter, "domain", "filter.destination.domain", params);
  setFilterFromValue(filter, "category", "filter.destination.category", params);
  setFilterFromValue(filter, "destinationCustomCategory", "filter.destination.destinationCustomCategory", params);
  setFilterFromValue(filter, "destinationGatewayId", "filter.destination.destinationGatewayId", params);
  setFilterFromValue(filter, "destinationSiteId", "filter.destination.destinationSiteId", params);
  setFilterFromValue(filter, "customCategory", "filter.rules.customCategory", params);
  if (get(params, "filter.destination.type") === "ingressSite") {
    set(filter, "destinationType", "site");
  }
  return __spreadProps(__spreadValues({}, params), {
    filter: __spreadProps(__spreadValues(__spreadValues({}, params.filter || {}), filter), {
      destination: void 0,
      source: void 0
    })
  });
};
const formatUserGroupInTableData = (rows) => getArrayDataContent(rows).map((row) => {
  const users = get(row, "source.users", []).map((user) => ({ name: user, icon: "user" }));
  const groups = get(row, "source.groups", []).map((group) => ({ id: group, icon: "group" }));
  return __spreadProps(__spreadValues({}, row), {
    source: __spreadProps(__spreadValues({}, get(row, "source", {})), {
      userOrGroups: users.concat(groups)
    })
  });
});
const formatGatewaySiteGroupInTableData = (rows) => getArrayDataContent(rows).map((row) => {
  const type = get(row, "destination.type", []);
  const applianceType = type === "onPremGateway" ? "gateway" : "site";
  return __spreadProps(__spreadValues({}, row), {
    type: applianceType
  });
});
export {
  formatGatewaySiteGroupInTableData,
  formatSourceDestinationFilters,
  formatUserGroupInTableData
};
