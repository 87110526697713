import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import { useTheme } from "@mui/material";
const AppsResourcesGraphic = ({ className }) => {
  var _a, _b, _c, _d;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-graphic"),
      id: "cuda-graphic-appsresources",
      viewBox: "0 0 96 96",
      children: /* @__PURE__ */ jsx("g", { id: "ArtBoard11", children: /* @__PURE__ */ jsxs("g", { id: "Ebene-1", children: [
        /* @__PURE__ */ jsx("path", { style: { fill: (_a = theme.palette.icons) == null ? void 0 : _a.graphics, fillRule: "nonzero", strokeWidth: 0 }, d: "M9.1,36.5h23.2V13.3H9.1v23.2ZM14,18.3h13.2v13.2h-13.2v-13.2Z" }),
        /* @__PURE__ */ jsx("path", { style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, fillRule: "nonzero", strokeWidth: 0 }, d: "M41.3,36.5h23.2V13.3h-23.2v23.2ZM46.2,18.3h13.2v13.2h-13.2v-13.2Z" }),
        /* @__PURE__ */ jsx("path", { style: { fill: (_c = theme.palette.icons) == null ? void 0 : _c.graphics, fillRule: "nonzero", strokeWidth: 0 }, d: "M9.1,68.8h23.2v-23.2H9.1v23.2ZM14,50.6h13.2v13.2h-13.2v-13.2Z" }),
        /* @__PURE__ */ jsx("path", { style: { fill: theme.palette.common.white, fillRule: "nonzero", strokeWidth: 0 }, d: "M71.6,57.1h-3.4v6.8h-6.8v3.4h6.8v6.8h3.4v-6.8h6.8v-3.4h-6.8v-6.8Z" }),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_d = theme.palette.icons) == null ? void 0 : _d.graphics, fillRule: "nonzero", strokeWidth: 0 },
            d: "M50,65.7c0-.6,0-1.2,0-1.8h-3.9v-13.2h10.7c2.2-1.9,4.7-3.3,7.5-4.1v-.9h-23.2v23.2h9c-.2-1-.3-2.1-.3-3.2"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: theme.palette.success.main, fillRule: "nonzero", strokeWidth: 0 },
            d: "M69.9,48.6c-9.4,0-17,7.6-17,17s7.6,17,17,17,17-7.6,17-17-7.6-17-17-17ZM78.4,67.4h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"
          }
        )
      ] }) })
    }
  );
};
var AppsResourcesGraphic_default = AppsResourcesGraphic;
export {
  AppsResourcesGraphic,
  AppsResourcesGraphic_default as default
};
