var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { formatErrorMessage } from "../../../utils";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { Button, FormControl, FormHelperText, Grid, IconButton } from "@barracuda-internal/bds-core";
import classNames from "classnames";
import { createStyles } from "@mui/styles";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { Close } from "@barracuda-internal/bds-core/dist/Icons/Core";
const styles = (theme) => createStyles({
  fieldSet: {
    width: 256
  },
  fileInput: {
    display: "none"
  },
  button: {
    width: "inherit",
    height: 40
  },
  fileUploadButton: {
    width: "auto",
    flexGrow: 0,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  fileUploadDelete: {
    width: 28,
    flexGrow: 0,
    flexShrink: 0
  },
  fileUploadLabel: {
    width: "auto",
    flexGrow: 1,
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  fileUploadContainer: {
    width: "100%",
    height: 40,
    border: "1px solid " + theme.palette.divider,
    borderRadius: 4,
    "&> *": {
      display: "inline-block"
    }
  },
  error: {
    borderColor: theme.palette.error.main
  },
  fileUploadButtonContainer: {
    display: "inline-block",
    paddingRight: theme.spacing(1)
  },
  imageWrapper: {
    height: 40,
    width: 40,
    position: "absolute",
    right: `calc(-40px - ${theme.spacing(1)})`,
    borderRadius: 4,
    border: `1px solid ${theme.palette.divider}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "center"
  }
});
const useStyles = makeOverrideableStyles("File", styles);
const File = (props) => {
  const {
    accept,
    value,
    onChange,
    onBlur,
    error,
    id,
    options = {},
    disabled,
    showDelete,
    imagePreview,
    readType = "base64",
    buttonLabel = "cuda.inputs.file.buttonLabel"
  } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const [uploadError, setUploadError] = useState(false);
  const unsupportedMessage = !window.FileReader ? translate("cuda.inputs.file.browserUnsupported") : void 0;
  const errorMessage = unsupportedMessage || uploadError || error;
  return /* @__PURE__ */ jsxs(FormControl, { component: "fieldset", className: classes.fieldSet, error: !!errorMessage, children: [
    /* @__PURE__ */ jsx(
      "input",
      {
        type: "file",
        id: id && "file-input-" + id,
        disabled: disabled || !!unsupportedMessage,
        accept,
        className: classes.fileInput,
        value: !(value == null ? void 0 : value.filename) || !(value == null ? void 0 : value.data) ? "" : void 0,
        onChange: (event) => {
          setUploadError(false);
          const file = get(event, "target.files[0]", get(event, "target.dataTransfer.files[0]"));
          if (file) {
            const reader = new window.FileReader();
            reader.onload = (onloadEvent) => {
              var _a;
              const result = (_a = onloadEvent.target) == null ? void 0 : _a.result;
              if (result) {
                onChange == null ? void 0 : onChange({
                  filename: file.name,
                  data: readType === "base64" ? result.split(";")[1].split(",")[1] : result
                });
                onBlur == null ? void 0 : onBlur();
              }
            };
            reader.onerror = (error2) => {
              setUploadError(get(error2, "target.error.message") || translate("cuda.inputs.file.uploadError"));
              onChange == null ? void 0 : onChange({});
              onBlur == null ? void 0 : onBlur();
            };
            if (readType === "string") {
              reader.readAsBinaryString(file);
            } else {
              reader.readAsDataURL(file);
            }
          } else {
            onChange == null ? void 0 : onChange({});
            onBlur == null ? void 0 : onBlur();
          }
        }
      }
    ),
    /* @__PURE__ */ jsxs(
      Grid,
      {
        container: true,
        wrap: "nowrap",
        alignItems: "center",
        className: classNames(classes.fileUploadContainer, errorMessage && classes.error),
        children: [
          /* @__PURE__ */ jsx(Grid, { item: true, className: classes.fileUploadButtonContainer, children: /* @__PURE__ */ jsx("label", { htmlFor: id && "file-input-" + id, className: classes.fileUploadButton, children: /* @__PURE__ */ jsx(
            Button,
            __spreadProps(__spreadValues({
              variant: "contained",
              color: "primary",
              size: "small",
              component: "div",
              className: classes.button,
              disabled: disabled || !!unsupportedMessage
            }, options), {
              children: translate(buttonLabel)
            })
          ) }) }),
          /* @__PURE__ */ jsx(Grid, { item: true, className: classes.fileUploadLabel, children: get(value, "filename", " ") }),
          (value == null ? void 0 : value.data) && showDelete && /* @__PURE__ */ jsx(Grid, { item: true, className: classes.fileUploadDelete, children: /* @__PURE__ */ jsx(
            IconButton,
            {
              size: "small",
              onClick: (event) => {
                onChange == null ? void 0 : onChange({});
              },
              disabled,
              children: /* @__PURE__ */ jsx(Close, { fontSize: "small" })
            }
          ) }),
          imagePreview && /* @__PURE__ */ jsx(Grid, { item: true, className: classes.imageWrapper, children: imagePreview(value) })
        ]
      }
    ),
    errorMessage && /* @__PURE__ */ jsx(FormHelperText, { children: formatErrorMessage(errorMessage) })
  ] });
};
var File_default = File;
export {
  File,
  File_default as default
};
