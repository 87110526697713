import { jsx, jsxs } from "react/jsx-runtime";
import { SvgIcon } from "@barracuda-internal/bds-core";
import { useTheme } from "@mui/material";
import classNames from "classnames";
const IpsGraphic = ({ className }) => {
  var _a, _b, _c;
  const theme = useTheme();
  return /* @__PURE__ */ jsx(
    SvgIcon,
    {
      className: classNames(className, "cuda-react-graphic"),
      id: "cuda-graphic-ips",
      viewBox: "0 0 96 96",
      children: /* @__PURE__ */ jsx("g", { id: "ArtBoard11", children: /* @__PURE__ */ jsxs("g", { id: "Ebene-1", children: [
        /* @__PURE__ */ jsx("path", { style: { fill: (_a = theme.palette.general) == null ? void 0 : _a.white, strokeWidth: 0 }, d: "M65.3,58.4h-3.4v6.8h-6.8v3.4h6.8v6.8h3.4v-6.8h6.8v-3.4h-6.8v-6.8Z" }),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_b = theme.palette.icons) == null ? void 0 : _b.graphics, strokeWidth: 0 },
            d: "M43.7,66.9c0-.4,0-.9,0-1.3-1.3.7-2.7,1.3-4.1,1.8h-.2c0,0-.1,0-.1,0v-26.5h-19c0-.5,0-1,0-1.5v-15h0s19-7.2,19-7.2v23.7h19.3c-.1,2.4-.5,4.8-1.2,7,1.7-.6,3.5-.9,5.3-1,.6-2.5.9-5,.9-7.6v-18.4l-24.2-9.1-24.2,9.1v18.4c0,15.4,10.4,29.6,24.2,33.2,1.6-.4,3.2-1,4.7-1.7-.3-1.3-.4-2.7-.4-4"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            style: { fill: (_c = theme.palette.success) == null ? void 0 : _c.main, strokeWidth: 0 },
            d: "M63.6,49.9c-9.4,0-17.1,7.6-17.1,17.1s7.6,17.1,17.1,17.1,17.1-7.6,17.1-17.1-7.6-17.1-17.1-17.1ZM72.2,68.7h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"
          }
        )
      ] }) })
    }
  );
};
var IpsGraphic_default = IpsGraphic;
export {
  IpsGraphic,
  IpsGraphic_default as default
};
