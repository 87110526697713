var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import {
  BooleanInput,
  ConnectedFormPage,
  FormSection,
  getArrayDataContent,
  PropGateway,
  TextArrayInput,
  useCrudProps,
  useMixpanel,
  validateArray,
  validateMaxLengthMemo,
  validateRegexMemo
} from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import { Trans } from "react-i18next";
import { makeStyles } from "@mui/styles";
import apiResources from "../../../../apiResources";
import SimpleBooleanCheckListInput from "./SimpleBooleanChecklistInput";
import { parseJson } from "../../../../utils/jsonUtils";
const useStyles = makeStyles((theme) => ({
  helpText: {
    padding: theme.spacing(0, 2, 2, 4)
  }
}));
const WebMonitorPolicies = () => {
  const registerAction = useMixpanel("Web filter configuration");
  const classes = useStyles();
  const categoryChoices = getArrayDataContent(useCrudProps(apiResources.webMonitorPolicies)[0]);
  return /* @__PURE__ */ jsxs(
    ConnectedFormPage,
    {
      resource: apiResources.settings,
      title: "tesseract.securityAndAccess.pageTitle",
      subtitle: ["tesseract.securityAndAccess.webFilter", "tesseract.securityAndAccess.webMonitorPolicies"],
      formatRequestData: (data) => ({
        settings: {
          web_monitor_policies: JSON.stringify(data)
        }
      }),
      formatResourceData: (data) => {
        var _a;
        const webMonitorPolicies = parseJson(((_a = data == null ? void 0 : data.settings) == null ? void 0 : _a.web_monitor_policies) || "{}");
        return __spreadValues({
          enabled: false,
          categories: [],
          custom_keywords: []
        }, webMonitorPolicies);
      },
      params: { filter: { type: "virtualWan" } },
      onSubmitSuccess: () => {
        registerAction("Update");
      },
      showSeparateTitle: true,
      children: [
        /* @__PURE__ */ jsxs(FormSection, { children: [
          /* @__PURE__ */ jsx(PropGateway, { children: /* @__PURE__ */ jsx(Typography, { className: classes.helpText, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.webMonitorPolicies.webConfigHelpText", children: /* @__PURE__ */ jsx("br", {}) }) }) }),
          /* @__PURE__ */ jsx(
            BooleanInput,
            {
              source: "enabled",
              label: "tesseract.webMonitorPolicies.enabled"
            }
          )
        ] }),
        /* @__PURE__ */ jsx(FormSection, { title: "tesseract.webMonitorPolicies.webMonitoringTitle", newStyle: true, children: /* @__PURE__ */ jsx(
          SimpleBooleanCheckListInput,
          {
            source: "categories",
            label: "",
            categoryChoices,
            disable: (value, data) => !(data == null ? void 0 : data.enabled)
          }
        ) }),
        /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.webMonitorPolicies.customKeywordTitle", newStyle: true, children: [
          /* @__PURE__ */ jsx(PropGateway, { children: /* @__PURE__ */ jsx(Typography, { className: classes.helpText, children: /* @__PURE__ */ jsx(Trans, { i18nKey: "tesseract.webMonitorPolicies.customKeywordHelpText" }) }) }),
          /* @__PURE__ */ jsx(
            TextArrayInput,
            {
              source: "custom_keywords",
              label: "tesseract.webMonitorPolicies.customKeywords",
              disable: (value, data) => !(data == null ? void 0 : data.enabled),
              validate: [validateArray([validateMaxLengthMemo(128), validateRegexMemo(/^[a-zA-Z0-9](([a-zA-Z0-9.' -]{0,126})[a-zA-Z0-9])?$/, "tesseract.validation.customKeyword")])]
            }
          )
        ] })
      ]
    }
  );
};
var WebMonitorPolicies_default = WebMonitorPolicies;
export {
  WebMonitorPolicies_default as default
};
