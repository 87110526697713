import { jsx, jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
import { Button, CircularProgress, Container, Typography } from "@barracuda-internal/bds-core";
import React from "react";
import { CrudTypes, DialogBody, getDataContent, Toolbar, useCrudFetch, useCrudSubscription } from "@cuda-react/core";
import apiResources from "../../../apiResources";
const styles = createStyles({
  body: {
    textAlign: "center",
    padding: 16
  }
});
const useStylesContent = makeOverrideableStyles("RestartDialogContent", styles);
const RestartIpsecTunnel = (props) => {
  const { resourceName, onSuccess, onClose, resourceId } = props;
  const classes = useStylesContent(props);
  const [translate] = useTranslation();
  const [, restarting, performRestart] = useCrudFetch(CrudTypes.CREATE, apiResources.restartIpsecV2Tunnel, { id: resourceId });
  const canRestart = Boolean(getDataContent(useCrudSubscription(
    CrudTypes.GET,
    apiResources.restartIpsecV2TunnelAvailable,
    { id: resourceId },
    { pollInterval: 3e4 }
  )[0].data));
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      DialogBody,
      {
        title: translate("tesseract.integration.ipSecV2.dialog.restart.title", { name: resourceName }),
        children: /* @__PURE__ */ jsx(Container, { className: classes.body, children: /* @__PURE__ */ jsx(Typography, { variant: "body1", children: !canRestart ? translate("tesseract.integration.ipSecV2.dialog.restart.message") : translate("tesseract.integration.ipSecV2.dialog.restart.body") }) })
      }
    ),
    /* @__PURE__ */ jsxs(Toolbar, { children: [
      /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          color: "primary",
          disabled: !canRestart,
          startIcon: !canRestart ? /* @__PURE__ */ jsx(CircularProgress, { id: "cuda-loading-spinner", size: 16, thickness: 2 }) : void 0,
          onClick: () => performRestart({ id: resourceId }).then((result) => {
            if (!result.error) {
              onSuccess == null ? void 0 : onSuccess();
              onClose == null ? void 0 : onClose();
            } else {
              onClose == null ? void 0 : onClose();
            }
          }),
          children: translate("tesseract.integration.ipSecV2.dialog.restart.ok")
        }
      ),
      /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          color: "secondary",
          onClick: onClose,
          children: translate("tesseract.integration.ipSecV2.dialog.restart.cancel")
        }
      )
    ] })
  ] });
};
RestartIpsecTunnel.defaultProps = {};
var RestartIpsecTunnel_default = RestartIpsecTunnel;
export {
  RestartIpsecTunnel,
  RestartIpsecTunnel_default as default
};
