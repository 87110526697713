import { jsx, jsxs } from "react/jsx-runtime";
import apiResources from "../../../apiResources";
import { ConnectedForm, DialogBody, ReadOnlyInput, TextArrayInput, TextField } from "@cuda-react/core";
const EditPointOfEntryContent = ({ onClose, onSuccess, id }) => /* @__PURE__ */ jsx(
  DialogBody,
  {
    title: "tesseract.endpoint.pointsOfEntry.edit.title",
    onClose,
    form: true,
    children: /* @__PURE__ */ jsxs(
      ConnectedForm,
      {
        resource: apiResources.pointsOfEntry,
        onSubmitSuccess: () => {
          onSuccess == null ? void 0 : onSuccess();
          onClose == null ? void 0 : onClose();
        },
        params: { id },
        onCancel: onClose,
        allowDirtyNavigation: true,
        children: [
          /* @__PURE__ */ jsx(
            ReadOnlyInput,
            {
              source: "configuration.name",
              label: "tesseract.endpoint.pointsOfEntry.edit.name",
              field: TextField
            }
          ),
          /* @__PURE__ */ jsx(
            TextArrayInput,
            {
              source: "ipAddresses",
              label: "tesseract.endpoint.pointsOfEntry.edit.ipAddress",
              description: "tesseract.endpoint.pointsOfEntry.edit.descriptions.ipAddress"
            }
          )
        ]
      }
    )
  }
);
var EditPointOfEntryContent_default = EditPointOfEntryContent;
export {
  EditPointOfEntryContent_default as default
};
