import { jsx } from "react/jsx-runtime";
import get from "lodash/get";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    lineHeight: "24px",
    color: theme.palette.common.black,
    display: "inline-flex",
    verticalAlign: "middle",
    fontSize: theme.typography.pxToRem(14)
  }
});
const useStyles = makeOverrideableStyles("BooleanField", styles);
const CustomField = (props) => {
  const { source = "", data, render } = props;
  const classes = useStyles(props);
  const renderedValue = render(get(data || {}, source), data);
  return renderedValue !== null ? /* @__PURE__ */ jsx("div", { id: "custom-field-" + source.replace(/[.]/g, "-"), className: classes.root, children: renderedValue }) : null;
};
var CustomField_default = CustomField;
export {
  CustomField,
  CustomField_default as default,
  styles
};
