import { jsx } from "react/jsx-runtime";
import { RadioButtonGroupInput } from "@cuda-react/core";
const CreateTypeStep = () => /* @__PURE__ */ jsx(
  RadioButtonGroupInput,
  {
    source: "createMode",
    choices: [
      { key: "new", name: "tesseract.appliances.dialog.createType.new" },
      { key: "promote", name: "tesseract.appliances.dialog.createType.promote" }
    ]
  }
);
var CreateTypeStep_default = CreateTypeStep;
export {
  CreateTypeStep_default as default
};
