var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ConditionalWrapper from "../../functional/ConditionalWrapper/ConditionalWrapper";
import { List, ListItem, ListItemText, Tooltip } from "@barracuda-internal/bds-core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { get } from "lodash";
import { useHistory, useLocation } from "react-router";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  sideTabs: {
    width: "100%",
    display: "grid",
    gridGap: 0,
    gridTemplateColumns: "228px calc(100% - 228px)",
    transition: "all 1s"
  },
  sideBar: {
    gridColumn: 1,
    gridRow: 1,
    width: 220,
    padding: "0 8px 8px 0",
    transition: "all 1s",
    zIndex: 0
  },
  sideBarButton: {
    margin: 4,
    borderRadius: 7,
    padding: "6px 5px",
    width: "calc(100% - 8px)",
    display: "flex",
    alignItems: "center"
  },
  listItemText: {
    fontSize: 14,
    height: 18,
    lineHeight: "18px",
    marginRight: "auto"
  },
  sideBarButtonText: {
    fontSize: 14,
    height: 18,
    lineHeight: "18px",
    marginRight: "auto"
  },
  menuItemActive: {
    padding: theme.spacing(1, 2, 1, 1),
    borderRadius: 4,
    "&:hover": {
      backgroundColor: (
        // @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
        theme.palette.primary.background || theme.palette.primary.border
      )
    },
    "&:focus": {
      backgroundColor: (
        // @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
        theme.palette.primary.background || theme.palette.primary.border
      )
    },
    backgroundColor: (
      // @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
      theme.palette.primary.background || theme.palette.primary.border
    ),
    color: theme.palette.primary.dark
  },
  menuItemInactive: {
    padding: theme.spacing(1, 2, 1, 1),
    borderRadius: 4,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: (
        // @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
        theme.palette.primary.background || theme.palette.primary.border
      )
    },
    "&:focus": {
      backgroundColor: (
        // @ts-ignore TODO: is this the right way to get this tokenized? this is a new value not used by mui theme
        theme.palette.primary.background || theme.palette.primary.border
      )
    }
  },
  menuItemDisabled: {
    color: theme.palette.text.disabled
  },
  tabContent: {},
  tooltipPopper: {
    left: "-238px !important"
  },
  tooltip: __spreadValues({}, theme.typography.body2)
});
const useStyles = makeOverrideableStyles("SideTabs", styles);
const SideTabs = (props) => {
  const { children, defaultTab } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  const location = useLocation();
  const { push } = useHistory();
  const childArray = React.Children.toArray(children).filter((child) => child);
  const currentTabIndex = location.hash ? childArray.findIndex((child, index) => `#${child.props.value || index}` === location.hash) : defaultTab;
  const activeValue = get(childArray[currentTabIndex], "props.value", currentTabIndex);
  useEffect(() => {
    if (currentTabIndex !== defaultTab && (!childArray[currentTabIndex] || childArray[currentTabIndex].props.disabled || childArray[currentTabIndex].props.hidden)) {
      push(__spreadProps(__spreadValues({}, location), {
        hash: `#${get(childArray[defaultTab], "props.value", defaultTab)}`
      }));
    }
  }, [location.hash, currentTabIndex]);
  return /* @__PURE__ */ jsxs("div", { className: classes.sideTabs, children: [
    /* @__PURE__ */ jsx(List, { className: classes.sideBar, id: "cuda-react-sidebar", children: childArray.map((child, index) => {
      const childProps = child && child.props || {};
      const tabIsActive = !childProps.disabled && index === currentTabIndex;
      const tabIsInactive = !childProps.disabled && index !== currentTabIndex;
      return /* @__PURE__ */ jsx(
        ConditionalWrapper,
        {
          condition: !!(childProps.disabledMessage && childProps.disabled),
          wrapper: (children2) => /* @__PURE__ */ jsx(
            Tooltip,
            {
              title: translate(childProps.disabledMessage),
              placement: "right-start",
              classes: { popper: classes.tooltipPopper, tooltip: classes.tooltip },
              children: /* @__PURE__ */ jsx("span", { children: children2 })
            }
          ),
          children: /* @__PURE__ */ jsx(
            ListItem,
            {
              button: true,
              component: Link,
              to: `#${childProps.value || index}`,
              disabled: childProps.disabled,
              className: classNames(
                classes.sideBarButton,
                childProps.disabled && classes.menuItemDisabled,
                tabIsActive && classes.menuItemActive,
                tabIsInactive && classes.menuItemInactive
              ),
              children: /* @__PURE__ */ jsx(
                ListItemText,
                {
                  className: classes.listItemText,
                  primary: translate(childProps.label),
                  primaryTypographyProps: {
                    variant: "body2",
                    className: classes.sideBarButtonText
                  }
                }
              )
            }
          )
        },
        index
      );
    }) }),
    /* @__PURE__ */ jsx("div", { className: classes.tabContent, children: childArray.map((tab, index) => React.cloneElement(
      tab,
      {
        key: tab.props.value || index,
        value: tab.props.value || index,
        activeValue
      }
    )) })
  ] });
};
SideTabs.defaultProps = {
  defaultTab: 0
};
var SideTabs_default = SideTabs;
export {
  SideTabs_default as default
};
