var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { FormSection } from "@cuda-react/core";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Typography } from "@barracuda-internal/bds-core";
import LanTableInput from "../../inputs/LanTableInput";
import RoutesTableInput from "../../inputs/RoutesTableInput";
import { getNetworkId } from "../../../../../hooks/createEditSiteHooks";
import { useWatch } from "react-hook-form";
const useStyles = makeStyles((theme) => ({
  tableWarning: {
    color: theme.palette.warning.main,
    marginLeft: theme.spacing(2)
  }
}));
const LansTab = ({ portsData }) => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const lans = useWatch({ name: "lans" }) || [];
  const activeLans = lans.map((lan) => __spreadProps(__spreadValues({}, lan), {
    networkId: getNetworkId(lan)
  }));
  const tableRowDimmed = (row) => portsData && !portsData.some((applianceInterface) => applianceInterface.port === row.port && applianceInterface.available);
  const lanHasPortDisabled = activeLans.some(tableRowDimmed);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.appliances.settings.lan.sectionTitle", children: [
      /* @__PURE__ */ jsx(LanTableInput, { tableRowDimmed, portsData }),
      lanHasPortDisabled && /* @__PURE__ */ jsx(Typography, { className: classes.tableWarning, children: translate("tesseract.appliances.settings.lan.portWarning") })
    ] }),
    /* @__PURE__ */ jsx(FormSection, { title: "tesseract.appliances.settings.route.sectionTitle", children: /* @__PURE__ */ jsx(RoutesTableInput, {}) })
  ] });
};
var LansTab_default = LansTab;
export {
  LansTab_default as default
};
