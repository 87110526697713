var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { jsx, jsxs } from "react/jsx-runtime";
import { CardSizeOptions, CardSizes } from "../../../typesAndConstants";
import { BaseCard } from "../../baseCards";
import generatePreviewData from "../../../utils/previewDataGenerators/zeroTrustDevices";
import apiResources from "../../../apiResources";
import { useCardFetch } from "../../../hooks";
import { CustomField, DateField, LocationsCardContent, TextField } from "@cuda-react/core";
import { StatisticsTable } from "../../statistics";
import { makeStyles } from "@mui/styles";
import { usei18nCountries } from "../../../utils/countries";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  cardContent: {
    height: "calc(100% - 52px)",
    overflowY: "hidden",
    "& .in-highchart": {
      height: `calc(${CardSizeOptions.small.height}px - ${theme.spacing(6)})`,
      overflow: "show",
      width: "100%"
    }
  }
}));
const ZTNADeviceLocationCard = (props) => {
  var _a, _b;
  const { preview, size } = props;
  const { data = [] } = useCardFetch(
    {
      url: !preview ? apiResources.userDevices : void 0,
      params: { filter: { userId: (_a = props == null ? void 0 : props.additionalProps) == null ? void 0 : _a.id } }
    }
  );
  const content = (preview ? generatePreviewData() : data) || [];
  const classes = useStyles(props);
  const getNameByCountryCode = usei18nCountries();
  const theme = useTheme();
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(
    BaseCard,
    {
      title: "stratosReporting.ZTNADeviceLocation.title",
      size,
      preview,
      classes: { cardContent: classes.cardContent },
      children: [
        /* @__PURE__ */ jsx(
          LocationsCardContent,
          {
            series: [
              {
                label: "stratosReporting.ZTNADeviceLocation.devices",
                color: theme.palette.general.graphite,
                zIndex: 1,
                details: {
                  fields: [
                    /* @__PURE__ */ jsx(
                      TextField,
                      {
                        source: "userFullName",
                        label: "stratosReporting.ZTNADeviceLocation.user"
                      },
                      "userFullName"
                    ),
                    /* @__PURE__ */ jsx(
                      TextField,
                      {
                        source: "brand",
                        label: "stratosReporting.ZTNADeviceLocation.brand"
                      },
                      "brand"
                    ),
                    /* @__PURE__ */ jsx(
                      TextField,
                      {
                        source: "hardwareModel",
                        label: "stratosReporting.ZTNADeviceLocation.hardwareModel"
                      },
                      "hardwareModel"
                    ),
                    /* @__PURE__ */ jsx(
                      TextField,
                      {
                        source: "operatingSystem",
                        label: "stratosReporting.ZTNADeviceLocation.operatingSystem"
                      },
                      "operatingSystem"
                    ),
                    /* @__PURE__ */ jsx(
                      TextField,
                      {
                        source: "operatingSystemVersion",
                        label: "stratosReporting.ZTNADeviceLocation.operatingSystemVersion"
                      },
                      "operatingSystemVersion"
                    ),
                    /* @__PURE__ */ jsx(
                      TextField,
                      {
                        source: "agentVersion",
                        label: "stratosReporting.ZTNADeviceLocation.agentVersion"
                      },
                      "agentVersion"
                    ),
                    /* @__PURE__ */ jsx(
                      CustomField,
                      {
                        source: "countryCode",
                        label: "stratosReporting.ZTNADeviceLocation.country",
                        render: getNameByCountryCode
                      },
                      "location.countryCode"
                    ),
                    /* @__PURE__ */ jsx(
                      DateField,
                      {
                        source: "enrollmentDate",
                        label: "stratosReporting.ZTNADeviceLocation.enrollmentDate"
                      },
                      "enrollmentDate"
                    )
                  ]
                },
                data: content.map((item) => __spreadValues(__spreadValues({}, item), item.location))
              }
            ],
            chartOverrides: {
              chart: {
                height: CardSizeOptions.small.height - 48,
                width: CardSizeOptions[props.size].width - 2,
                spacing: [2, 2, 2, 2]
              },
              legend: {
                floating: true,
                backgroundColor: "rgba(255,255,255,0.4)",
                shadow: {
                  color: "rgba(255,255,255, 0.4)",
                  opacity: 0.2,
                  width: 16
                },
                borderRadius: 4
              }
            }
          }
        ),
        props.size === CardSizes.large && /* @__PURE__ */ jsxs(
          StatisticsTable,
          {
            tableTitle: "stratosReporting.ZTNADeviceLocation.devices",
            noDataMessage: "stratosReporting.ZTNADeviceLocation.noData",
            resource: apiResources.userDevices,
            params: { filter: { userId: (_b = props == null ? void 0 : props.additionalProps) == null ? void 0 : _b.id } },
            children: [
              /* @__PURE__ */ jsx(
                TextField,
                {
                  source: "userFullName",
                  label: "stratosReporting.ZTNADeviceLocation.user"
                }
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  source: "brand",
                  label: "stratosReporting.ZTNADeviceLocation.brand"
                }
              ),
              /* @__PURE__ */ jsx(
                TextField,
                {
                  source: "operatingSystem",
                  label: "stratosReporting.ZTNADeviceLocation.operatingSystem"
                }
              ),
              /* @__PURE__ */ jsx(
                CustomField,
                {
                  source: "location.countryCode",
                  label: "stratosReporting.ZTNADeviceLocation.country",
                  render: (value) => getNameByCountryCode(value) !== translate("stratosReporting.ZTNAUserGeoLocation.nonRoutableOrIP") ? getNameByCountryCode(value) : translate("stratosReporting.ZTNADeviceLocation.noCountry")
                }
              ),
              /* @__PURE__ */ jsx(
                DateField,
                {
                  source: "enrollmentDate",
                  label: "stratosReporting.ZTNADeviceLocation.enrollmentDate"
                }
              )
            ]
          }
        )
      ]
    }
  );
};
export {
  ZTNADeviceLocationCard
};
