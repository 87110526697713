import { jsx, jsxs } from "react/jsx-runtime";
import {
  ChipArrayField,
  CrudTypes,
  CustomField,
  DateField,
  getArrayDataContent,
  getDataContent,
  StatusIconField,
  TablePage,
  TextField,
  useCrudProps,
  useCrudSubscription,
  useMixpanel
} from "@cuda-react/core";
import { memo, useEffect, useState } from "react";
import { logsIconMap } from "../../../../components/iconMapping";
import apiResources from "../../../../apiResources";
import { isString } from "lodash";
const createdRanges = [
  { key: "1", name: "tesseract.audit.filter.created.days1" },
  { key: "3", name: "tesseract.audit.filter.created.days3" },
  { key: "7", name: "tesseract.audit.filter.created.weeks1" },
  { key: "14", name: "tesseract.audit.filter.created.weeks2" },
  { key: "30", name: "tesseract.audit.filter.created.months1" },
  { key: "60", name: "tesseract.audit.filter.created.months2" },
  { key: "90", name: "tesseract.audit.filter.created.months3" },
  { key: "180", name: "tesseract.audit.filter.created.months6" },
  { key: "270", name: "tesseract.audit.filter.created.months9" },
  { key: "365", name: "tesseract.audit.filter.created.years1" }
];
const HistoryLog = (props) => {
  var _a;
  const { id, messagesType, resource, serial, tableName, details: { model, gatewayType, modelSeries } } = props;
  const [pollInterval, setPollInterval] = useState(5e3);
  const connectionHistoryStates = getArrayDataContent((_a = useCrudProps(
    gatewayType === "on-prem" ? apiResources.connectionOnPremHistoryStates : apiResources.connectionHistoryStates,
    {},
    {},
    true
  )[0]) == null ? void 0 : _a.data);
  const detailsLoaded = id && (resource === apiResources.gatewayCloudHistory || serial);
  let historyUpdatedUrl = apiResources.siteConnectionHistoryUpdated;
  if (gatewayType === "on-prem")
    historyUpdatedUrl = apiResources.gatewayOnPremHistoryUpdated;
  else if (resource === apiResources.gatewayCloudHistory)
    historyUpdatedUrl = apiResources.gatewayCloudHistoryUpdated;
  const historyUpdated = getDataContent(useCrudSubscription(
    CrudTypes.GET,
    historyUpdatedUrl,
    { id },
    {
      pollInterval,
      crudOptions: { quietErrors: true },
      debounceWait: 500
    }
  )[0]);
  const emptyDataMessage = `tesseract.${messagesType}.connectionHistory.${(historyUpdated == null ? void 0 : historyUpdated.content) === null ? "waitingForConnections" : "emptyData"}`;
  useMixpanel(
    gatewayType ? "Gateways History" : "Sites History",
    !!model,
    { model: model || "", type: gatewayType || modelSeries || "" }
  );
  useEffect(() => {
    if (isString(historyUpdated))
      setPollInterval(6e5);
  }, [historyUpdated]);
  return /* @__PURE__ */ jsxs(
    TablePage,
    {
      authenticated: true,
      tableName,
      resource: detailsLoaded ? resource : void 0,
      params: { id, serial },
      pageMode: "paginate",
      defaultSort: { field: "lastTime", dir: "desc" },
      noDataMessage: emptyDataMessage,
      resizable: true,
      reorderable: true,
      editColumns: true,
      sortable: true,
      compact: true,
      noStaleData: true,
      hasTitle: true,
      rowDetails: {
        title: "tesseract.logs.connectionHistory.detailsTitle",
        fields: [
          /* @__PURE__ */ jsx(
            StatusIconField,
            {
              source: "state",
              label: "tesseract.logs.connectionHistory.labels.state",
              iconMap: logsIconMap,
              column: 0
            },
            "state"
          ),
          /* @__PURE__ */ jsx(
            DateField,
            {
              source: "lastTime",
              label: "tesseract.logs.connectionHistory.labels.lastTime",
              dateOnly: false,
              column: 0
            },
            "lastTime"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "count",
              label: "tesseract.logs.connectionHistory.labels.count",
              column: 0
            },
            "count"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "ipProtocol",
              label: "tesseract.logs.connectionHistory.labels.ipProtocol",
              column: 0
            },
            "ipProtocol"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "sourceIp",
              label: "tesseract.logs.connectionHistory.labels.sourceIP",
              column: 0
            },
            "sourceIp"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "sourceMac",
              label: "tesseract.logs.connectionHistory.labels.sourceMac",
              column: 0
            },
            "sourceMac"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "destinationIp",
              label: "tesseract.logs.connectionHistory.labels.destinationIP",
              column: 0
            },
            "destinationIp"
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "destinationPort",
              label: "tesseract.logs.connectionHistory.labels.destinationPort",
              render: (value) => value || ""
            },
            "destinationPort"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "nextHop",
              label: "tesseract.logs.connectionHistory.labels.nextHop"
            },
            "nextHop"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "user",
              label: "tesseract.logs.connectionHistory.labels.user"
            },
            "user"
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "urlCategory",
              label: "tesseract.logs.connectionHistory.labels.urlCategory"
            },
            "urlCategory"
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "application",
              label: "tesseract.logs.connectionHistory.labels.application"
            },
            "application"
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "information",
              label: "tesseract.logs.connectionHistory.labels.information"
            },
            "information"
          )
        ]
      },
      children: [
        /* @__PURE__ */ jsx(
          StatusIconField,
          {
            source: "state",
            label: "tesseract.logs.connectionHistory.labels.state",
            width: 96,
            iconMap: logsIconMap,
            filter: "select",
            filterProps: {
              choices: connectionHistoryStates
            },
            alwaysVisible: true
          }
        ),
        /* @__PURE__ */ jsx(
          DateField,
          {
            source: "lastTime",
            label: "tesseract.logs.connectionHistory.labels.lastTime",
            dateOnly: false,
            width: 164,
            filter: "select",
            filterProps: {
              choices: createdRanges
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "count",
            label: "tesseract.logs.connectionHistory.labels.count",
            filter: "text",
            filterProps: {
              type: "number"
            },
            width: 96,
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "ipProtocol",
            label: "tesseract.logs.connectionHistory.labels.ipProtocol",
            width: 150,
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "sourceIp",
            label: "tesseract.logs.connectionHistory.labels.sourceIP",
            width: 150,
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "sourceMac",
            label: "tesseract.logs.connectionHistory.labels.sourceMac",
            width: 180,
            filter: "text",
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "destinationIp",
            label: "tesseract.logs.connectionHistory.labels.destinationIP",
            width: 150,
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          CustomField,
          {
            source: "destinationPort",
            label: "tesseract.logs.connectionHistory.labels.destinationPort",
            width: 150,
            render: (value) => value || "",
            filter: "text",
            filterProps: {
              type: "integer"
            }
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "nextHop",
            label: "tesseract.logs.connectionHistory.labels.nextHop",
            width: 150,
            filter: "text",
            hideByDefault: true
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "user",
            label: "tesseract.logs.connectionHistory.labels.user",
            width: 100,
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "urlCategory",
            label: "tesseract.logs.connectionHistory.labels.urlCategory",
            filter: "text",
            width: 140
          }
        ),
        /* @__PURE__ */ jsx(
          ChipArrayField,
          {
            source: "application",
            label: "tesseract.logs.connectionHistory.labels.application",
            width: 130,
            filter: "text"
          }
        ),
        /* @__PURE__ */ jsx(
          TextField,
          {
            source: "information",
            label: "tesseract.logs.connectionHistory.labels.information",
            width: 140,
            filter: "text"
          }
        )
      ]
    }
  );
};
var HistoryLog_default = memo(HistoryLog);
export {
  HistoryLog,
  createdRanges,
  HistoryLog_default as default
};
