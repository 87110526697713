var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { makeStyles } from "@mui/styles";
import CreateEditButtonDialog from "../../../../components/CreateEditButtonDialog";
import { SelectedChoiceField } from "@cuda-react/core";
import SingleSiteIcon from "@mui/icons-material/StoreMallDirectory";
import { GatewayIcon, SiteIcon } from "@cuda-react/icons";
import { get } from "lodash";
const useStyles = makeStyles({
  root: {
    display: "inline-flex",
    width: "calc(100% - 24px)",
    justifyContent: "space-between"
  }
});
const GroupingField = (props) => {
  const { source, data, type, createEditComponent, dataIconType, onSuccess } = props;
  const classes = useStyles(props);
  const iconType = dataIconType ? get(data, `items[0]${dataIconType}`) : type;
  const isGateway = iconType === "gateway" || iconType === "onPremGateway";
  const value = get(data, source);
  return /* @__PURE__ */ jsxs("div", { className: classes.root, children: [
    /* @__PURE__ */ jsx(
      SelectedChoiceField,
      __spreadProps(__spreadValues({}, props), {
        optionIcon: "uuid",
        iconMap: isGateway ? void 0 : { allSites: /* @__PURE__ */ jsx(SiteIcon, {}) },
        icon: isGateway ? /* @__PURE__ */ jsx(GatewayIcon, {}) : /* @__PURE__ */ jsx(SingleSiteIcon, {})
      })
    ),
    /* @__PURE__ */ jsx(
      CreateEditButtonDialog,
      {
        create: true,
        component: createEditComponent,
        defaultScope: value,
        type: iconType,
        onSuccess
      }
    )
  ] });
};
var GroupingField_default = GroupingField;
export {
  GroupingField,
  GroupingField_default as default
};
