import { jsx, jsxs } from "react/jsx-runtime";
import {
  Card,
  ConnectedForm,
  FormSection,
  PropGateway,
  TextInput,
  useGlobalParam,
  validatePrivateIpv4CIDR
} from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import apiResources from "../../../../apiResources";
import { useTranslation } from "react-i18next";
import { formatRequestCriteria } from "../../../../components/sourceAndDestination/formUtils";
const useStyles = makeStyles((theme) => ({
  message: {
    padding: theme.spacing(0, 2, 2, 4)
  }
}));
const SdwanConnectorNetwork = () => {
  const [translate] = useTranslation();
  const classes = useStyles();
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  return /* @__PURE__ */ jsx(Card, { title: "tesseract.settings.tabs.sdwanConnectorNetwork.title", children: /* @__PURE__ */ jsx(
    ConnectedForm,
    {
      formatRequestData: formatRequestCriteria(virtualWanId),
      resource: apiResources.sdwanConnectorNetwork,
      children: /* @__PURE__ */ jsxs(FormSection, { title: "tesseract.settings.tabs.sdwanConnectorNetwork.subMenu", children: [
        /* @__PURE__ */ jsx(PropGateway, { children: /* @__PURE__ */ jsx(Typography, { className: classes.message, children: translate("tesseract.settings.tabs.sdwanConnectorNetwork.description") }) }),
        /* @__PURE__ */ jsx(
          TextInput,
          {
            source: "clientNetworkConfiguration.range",
            label: "tesseract.network.sdwanConnector.dialog.poolNetwork.network",
            description: "tesseract.network.sdwanConnector.dialog.description.poolNetwork.network",
            isRequired: true,
            validate: validatePrivateIpv4CIDR
          }
        )
      ] })
    }
  ) });
};
var SdwanConnectorNetwork_default = SdwanConnectorNetwork;
export {
  SdwanConnectorNetwork,
  SdwanConnectorNetwork_default as default,
  useStyles
};
