var ja_default = {
  translation: {
    stratosReporting: {
      resourceStatisticsCard: {
        overview: "\u5408\u8A08"
      },
      sdwanStatusCard: {
        title: "SD WAN \u30B9\u30C6\u30FC\u30BF\u30B9",
        overviewTitle: "\u5408\u8A08",
        healthy: "\u5065\u5168",
        degraded: "\u30C0\u30A6\u30F3\u30B0\u30EC\u30FC\u30C9\u6E08\u307F",
        down: "\u505C\u6B62\u4E2D",
        tableTitle: "SD WAN \u30B9\u30C6\u30FC\u30BF\u30B9 \u6A5F\u5668\u4E0A\u4F4D10",
        name: "\u540D\u524D",
        location: "\u5834\u6240",
        type: "\u30BF\u30A4\u30D7"
      },
      sdwanBandwidthCard: {
        tableTitle: "SD-WAN \u5E2F\u57DF\u5E45 \u4E0A\u4F4D 5\u9805\u76EE",
        title: "SD-WAN \u5E2F\u57DF\u5E45\u8981\u7D04"
      },
      sdwanBandwidthChartCard: {
        title: "SD WAN \u5E2F\u57DF\u5E45",
        topTunnels: "\u4E0A\u4F4D {{length}} \u672C\u306E\u30C8\u30F3\u30CD\u30EB"
      },
      osStatusCard: {
        title: "\u30AA\u30DA\u30EC\u30FC\u30C6\u30A3\u30F3\u30B0\u30B7\u30B9\u30C6\u30E0",
        others: "\u305D\u306E\u4ED6",
        name: "\u540D\u524D",
        location: "\u5834\u6240",
        type: "\u30BF\u30A4\u30D7",
        ip: "IP",
        linux: "Linux",
        mac: "Mac OSX",
        windows: "Windows",
        other: "\u305D\u306E\u4ED6",
        android: "Android",
        ios: "iOS"
      },
      applianceMapCard: {
        title: "\u6A5F\u5668\u30DE\u30C3\u30D7"
      },
      baseBarCard: {
        total: "\u5408\u8A08{{total}}\u4EF6\u306E\u3046\u3061"
      },
      topApplicationCard: {
        title: "\u4E0A\u4F4D\u306E\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
        topApplications: "\u4E0A\u4F4D{{length}}\u672C\u306E\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3"
      },
      sdwanSummaryCard: {
        title: "SDWAN\u8981\u7D04",
        topAppliances: "\u4E0A\u4F4D{{length}}\u500B\u306E\u6A5F\u5668",
        inbound: "\u7740\u4FE1",
        outbound: "\u9001\u4FE1"
      },
      topWebRequest: {
        title: "\u30A6\u30A7\u30D6\u30EA\u30AF\u30A8\u30B9\u30C8",
        tableTitle: "\u4E0A\u4F4D\u306E\u30A6\u30A7\u30D6\u30EA\u30AF\u30A8\u30B9\u30C8",
        total: "\u5408\u8A08",
        allowed: "\u8A31\u53EF\u6E08",
        blocked: "\u30D6\u30ED\u30C3\u30AF\u6E08",
        type: "\u30BF\u30A4\u30D7",
        location: "\u5834\u6240",
        name: "\u540D\u524D"
      },
      storageCard: {
        title: "\u30B9\u30C8\u30EC\u30FC\u30B8\u6982\u8981",
        use: "{{totalSpace}}GB\u4E2D<0>{{spaceUsed}}GB</0>\u4F7F\u7528\u4E2D",
        retention: "\u6700\u5927\u4FDD\u6301\u7D043\u65E5",
        name: "\u540D\u524D",
        lastChange: "\u524D\u56DE\u306E\u5909\u66F4",
        fileSize: "\u30D5\u30A1\u30A4\u30EB\u30B5\u30A4\u30BA",
        storageLow: "\u8A18\u61B6\u9818\u57DF\u304C\u50C5\u304B\u306B\u306A\u308A\u307E\u3057\u305F\uFF01",
        upgradeStorageNow: "\u4ECA\u3059\u3050\u30B9\u30C8\u30EC\u30FC\u30B8\u3092\u30B0\u30EC\u30FC\u30C9\u30A2\u30C3\u30D7\u3057\u307E\u3057\u3087\u3046\uFF01",
        tableTitle: "\u30B9\u30C8\u30EC\u30FC\u30B8\u306E\u4F7F\u7528\u5EA6\u304C\u9AD8\u3044\u4E0A\u4F4D\u6A5F\u5668"
      },
      topBlockedUserCard: {
        title: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u963B\u6B62\u3055\u308C\u305F\u30E6\u30FC\u30B6\u30FC",
        topUsers: "\u4E0A\u4F4D{{length}}\u4EBA\u306E\u30E6\u30FC\u30B6\u30FC",
        total: "\u5408\u8A08{{total}}\u4EF6\u306E\u3046\u3061",
        hits: "{{formattedCount}} \u30D2\u30C3\u30C8",
        hits_one: "{{formattedCount}} \u30D2\u30C3\u30C8",
        hits_other: "{{formattedCount}} \u30D2\u30C3\u30C8"
      },
      sdwanHeatMap: {
        title: "SD WAN\u30D2\u30FC\u30C8\u30DE\u30C3\u30D7"
      },
      legendFooter: {
        online: "\u30AA\u30F3\u30E9\u30A4\u30F3",
        offline: "\u30AA\u30D5\u30E9\u30A4\u30F3",
        degraded: "\u30C0\u30A6\u30F3\u30B0\u30EC\u30FC\u30C9\u6E08\u307F"
      },
      applianceStatus: {
        title: "\u6A5F\u5668\u30B9\u30C6\u30FC\u30BF\u30B9",
        topUsers: "\u4E0A\u4F4D{{length}}\u4EBA\u306E\u30E6\u30FC\u30B6\u30FC",
        total: "\u5408\u8A08{{total}}\u4EF6\u306E\u3046\u3061"
      },
      previewDialog: {
        addWidgetButton: "\u30A6\u30A3\u30B8\u30A7\u30C3\u30C8\u3092\u8FFD\u52A0",
        title: "\u30A6\u30A3\u30B8\u30A7\u30C3\u30C8\u3092\u8FFD\u52A0",
        card: "\u30A6\u30A3\u30B8\u30A7\u30C3\u30C8",
        size: "\u30B5\u30A4\u30BA",
        cancel: "\u30AD\u30E3\u30F3\u30BB\u30EB",
        add: "\u8FFD\u52A0",
        small: "\u5C0F",
        medium: "\u4E2D",
        large: "\u5927",
        addWidgetCard: "\u30AF\u30EA\u30C3\u30AF\u3057\u3066\u30A6\u30A3\u30B8\u30A7\u30C3\u30C8\u3092\u8FFD\u52A0\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
        ok: "OK",
        editTitle: "\u30A6\u30A3\u30B8\u30A7\u30C3\u30C8\u3092\u7DE8\u96C6"
      },
      reportTable: {
        title: "\u3059\u3079\u3066\u306E\u30EC\u30DD\u30FC\u30C8",
        type: "\u30BF\u30A4\u30D7",
        name: "\u540D\u524D",
        period: "\u671F\u9593",
        devices: "\u88C5\u7F6E",
        schedule: "\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB",
        owner: "\u30AA\u30FC\u30CA\u30FC"
      },
      reportDialog: {
        create: {
          title: "\u30EC\u30DD\u30FC\u30C8\u3092\u4F5C\u6210",
          button: "\u4F5C\u6210"
        },
        edit: {
          title: "\u30EC\u30DD\u30FC\u30C8\u3092\u7DE8\u96C6",
          button: "\u7DE8\u96C6"
        },
        reportData: {
          label: "\u30EC\u30DD\u30FC\u30C8\u30C7\u30FC\u30BF",
          application: {
            name: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3"
          },
          protocol: {
            name: "\u30D7\u30ED\u30C8\u30B3\u30EB"
          },
          applicationRule: {
            name: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u30EB\u30FC\u30EB"
          },
          contentType: {
            name: "\u30B3\u30F3\u30C6\u30F3\u30C4\u30BF\u30A4\u30D7"
          },
          outputInterface: {
            name: "\u51FA\u529B\u30A4\u30F3\u30BF\u30FC\u30D5\u30A7\u30FC\u30B9"
          },
          destination: {
            name: "\u9001\u308A\u5148"
          }
        },
        datasource: {
          label: "\u30C7\u30FC\u30BF\u30BD\u30FC\u30B9",
          activity: {
            name: "\u52D5\u4F5C"
          },
          threat: {
            name: "\u8105\u5A01"
          },
          webFilter: {
            name: "\u30A6\u30A7\u30D6\u30D5\u30A3\u30EB\u30BF\u30FC"
          },
          sdwanTunnels: {
            name: "SD WAN \u30C8\u30F3\u30CD\u30EB"
          },
          sdwanTunnelsAppProtocol: {
            name: "SD WAN \u30C8\u30F3\u30CD\u30EB\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u3068\u30D7\u30ED\u30C8\u30B3\u30EB"
          }
        },
        output: {
          label: "\u51FA\u529B",
          outputFormat: {
            label: "\u51FA\u529B\u30D5\u30A9\u30FC\u30DE\u30C3\u30C8"
          },
          deliveryOptions: {
            label: "\u30C7\u30EA\u30D0\u30EA\u30FC\u30AA\u30D7\u30B7\u30E7\u30F3",
            email: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
            fileDownload: "\u30D5\u30A1\u30A4\u30EB\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9"
          },
          recipients: {
            label: "\u53D7\u4FE1\u8005"
          },
          sizeLimit: {
            label: "\u30B5\u30A4\u30BA\u9650\u5EA6"
          },
          frequency: {
            label: "\u983B\u5EA6",
            once: "\u4E00\u56DE",
            weekly: "\u6BCE\u9031",
            monthly: "\u6BCE\u6708"
          },
          status: {
            label: "\u30B9\u30C6\u30FC\u30BF\u30B9",
            enabled: "\u6709\u52B9",
            disabled: "\u7121\u52B9"
          }
        },
        advanced: {
          label: "\u8A73\u7D30\u8A2D\u5B9A",
          destination: {
            label: "\u9001\u308A\u5148",
            domain: "\u30C9\u30E1\u30A4\u30F3",
            category: "\u30AB\u30C6\u30B4\u30EA\u30FC"
          },
          ipAnonymize: {
            label: "IP\u533F\u540D\u5316"
          },
          serialNumberBucketing: {
            label: "\u30B7\u30EA\u30A2\u30EB\u756A\u53F7\u30D0\u30B1\u30C3\u30C8\u4F5C\u6210"
          },
          domains: {
            label: "\u30C9\u30E1\u30A4\u30F3"
          },
          exludeTimeframe: {
            label: "\u9664\u5916\u6642\u9593\u67A0"
          },
          action: {
            label: "\u64CD\u4F5C"
          },
          chartType: {
            label: "\u30C1\u30E3\u30FC\u30C8\u30BF\u30A4\u30D7",
            horizontalBar: "\u6A2A\u30D0\u30FC",
            verticalBar: "\u7E26\u30D0\u30FC",
            pieChart: "\u5186\u30B0\u30E9\u30D5"
          },
          ddLimitLevels: {
            label: "\u30C9\u30EA\u30EB\u30C0\u30A6\u30F3\u9650\u5EA6\u306E\u30EC\u30D9\u30EB"
          }
        },
        result: {
          label: "\u7D50\u679C\u30B9\u30C6\u30C3\u30D7"
        },
        success: "\u6B63\u5E38\u306B\u4F5C\u6210\u3055\u308C\u307E\u3057\u305F"
      },
      userEnrollment: {
        title: "\u30E6\u30FC\u30B6\u30FC\u767B\u9332",
        enrolledUsers: "\u30E6\u30FC\u30B6\u30FC",
        enrolledDevices: "\u88C5\u7F6E",
        pendingEnrollment: "\u4FDD\u7559\u4E2D",
        name: "\u540D\u524D",
        location: "\u5834\u6240",
        device: "\u88C5\u7F6E"
      },
      licensing: {
        title: "\u30E9\u30A4\u30BB\u30F3\u30B9",
        bandwidth: "\u5E2F\u57DF\u5E45",
        bandwidthUsageMedium: "{{remain}} \u3042\u3068Gbit/s (\u5408\u8A08{{total}} Gbit/s\u306E\u5185)",
        bandwidthUsageLarge: "{{used}}\u4F7F\u7528 (\u5408\u8A08{{total}} GB/s\u306E\u5185)",
        bandwidthExpire: "{{daysLeft}}\u65E5\u5F8C\u306B\u6E80\u4E86",
        users: "\u30E6\u30FC\u30B6\u30FC",
        usersUsageMedium: "{{remain}} \u6B8B\u308A\u30E6\u30FC\u30B6\u30FC(\u5408\u8A08\u30E6\u30FC\u30B6\u30FC {{total}}\u4EBA\u306E\u5185)",
        usersUsageLarge: "{{used}}\u4F7F\u7528\u30E6\u30FC\u30B6\u30FC(\u5408\u8A08\u30E6\u30FC\u30B6\u30FC {{total}} \u4EBA\u306E\u5185)",
        usersExpire: "{{remain}} \u6B8B\u308A\u30E6\u30FC\u30B6\u30FC",
        storage: "\u30B9\u30C8\u30EC\u30FC\u30B8",
        storageUsageMedium: "{{remain}} \u6B8B\u308AGB (\u5408\u8A08{{total}} GB\u306E\u5185)",
        storageUsageLarge: "{{used}} GB/s\u4F7F\u7528(\u5408\u8A08{{total}} GB/s\u306E\u5185)",
        storageRetention: "\u6700\u5927\u4FDD\u6301\u7D04{{remain}}\u65E5\u9593",
        storageExpire: "{{daysLeft}}\u65E5\u5F8C\u306B\u6E80\u4E86",
        appliances: "\u6A5F\u5668",
        appliancesUsageMedium: "\u3042\u3068{{remain}}\u65E5(\u5408\u8A08{{total}}\u65E5\u306E\u5185)",
        appliancesUsageLarge: "{{used}}\u65E5\u4F7F\u7528(\u5408\u8A08{{total}}\u65E5\u306E\u5185)",
        appliancesExpire: "{{daysLeft}}\u65E5\u5F8C\u306B\u6E80\u4E86",
        percentage: "{{percentage}}"
      },
      dashboardPage: {
        done: "\u5B8C\u4E86",
        cancel: "\u30AD\u30E3\u30F3\u30BB\u30EB",
        editDashboard: "Dashboard\u3092\u7DE8\u96C6",
        emptyMessage: "\u3053\u306EDashboard\u306F\u5F53\u9762\u30A6\u30A3\u30B8\u30A7\u30C3\u30C8\u304C\u3042\u308A\u307E\u305B\u3093\u3002",
        addMessage: "\u300C\u8FFD\u52A0\u300D\u30DC\u30BF\u30F3\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u3066\u30A6\u30A3\u30B8\u30A7\u30C3\u30C8\u3092\u8FFD\u52A0\u3057\u3066\u304F\u3060\u3055\u3044\u3002",
        lastDay: "\u904E\u53BB24\u6642\u9593",
        lastWeek: "\u5148\u9031",
        lastMonth: "\u5148\u6708",
        configureDashboard: "\u3053\u306EDashboard\u3092\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\uFF01",
        addDashboard: "Dashboard\u306E\u4F5C\u6210",
        name: "\u540D\u524D",
        template: "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C8",
        reset: "Dashboard\u3092\u30EA\u30BB\u30C3\u30C8",
        rename: "Dashboard\u306E\u540D\u524D\u3092\u5909\u66F4\u3059\u308B",
        delete: "Dashboard\u3092\u524A\u9664",
        confirmDeletion: "\u300C{{name}}\u300DDashboard\u3092\u672C\u5F53\u306B\u524A\u9664\u3057\u3066\u3082\u3088\u3044\u306E\u3067\u3059\u304B\u3002",
        actions: "\u30AA\u30D7\u30B7\u30E7\u30F3",
        add: "Dashboard\u3092\u8FFD\u52A0",
        resetToTemplate: "\u30C6\u30F3\u30D7\u30EC\u30FC\u30C8\u306B\u30EA\u30BB\u30C3\u30C8",
        validateName: "\u82F1\u6570\u5B57\u304B\u7A7A\u767D\u3057\u304B\u542B\u3081\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093\u3002",
        changeDashboardMessage: "\u5909\u66F4\u3092\u4FDD\u5B58\u305B\u305ADashboard\u3092\u5909\u66F4\u3057\u3066\u3082\u3088\u3044\u306E\u3067\u3059\u304B\u3002",
        uniqueNameError: "\u4E00\u610F\u3067\u306A\u3051\u308C\u3070\u306A\u308A\u307E\u305B\u3093",
        resetToDefault: "Dashboard\u3092\u30C7\u30D5\u30A9\u30EB\u30C8\u72B6\u614B\u306B\u30EA\u30BB\u30C3\u30C8\u3057\u307E\u3059\u304B",
        resetToTemplateDescription: "Dashboard\u3092\u9078\u629E\u3055\u308C\u305F\u30C6\u30F3\u30D7\u30EC\u30FC\u30C8\u306E\u5143\u306E\u30A6\u30A3\u30B8\u30A7\u30C3\u30C8\u3068\u30EC\u30A4\u30A2\u30A6\u30C8\u306B\u30EA\u30BB\u30C3\u30C8\u3057\u3066\u304F\u3060\u3055\u3044",
        options: "\u30AA\u30D7\u30B7\u30E7\u30F3",
        maxNumberOfDashboardReached: "Dashboard\u3092\u3053\u308C\u4EE5\u4E0A\u4F5C\u6210\u3067\u304D\u307E\u305B\u3093\u3002\u6700\u5927{{maxDashboardsAmount}}\u500B\u306B\u9054\u3057\u307E\u3057\u305F"
      },
      domainCard: {
        title: "\u30C9\u30E1\u30A4\u30F3"
      },
      dualTopBarBaseCard: {
        title: "\u30C9\u30E1\u30A4\u30F3",
        overview: "\u5408\u8A08",
        totalAllowedSessions: "\u8A31\u53EF\u3055\u308C\u3066\u3044\u308BSessions ",
        totalBlockedSessions: "\u963B\u6B62\u3055\u308C\u305FSessions ",
        allowed: "\u8A31\u53EF\u6E08",
        blocked: "\u30D6\u30ED\u30C3\u30AF\u6E08"
      },
      topUrlCard: {
        title: "URL\u30AB\u30C6\u30B4\u30EA\u30FC"
      },
      malwareCard: {
        title: "Advanced Threat Protection",
        hits: "{{formattedCount}} \u30D2\u30C3\u30C8",
        hits_one: "{{formattedCount}} \u30D2\u30C3\u30C8",
        hits_other: "{{formattedCount}} \u30D2\u30C3\u30C8"
      },
      ipsIncidentCard: {
        title: "IPS\u306E\u30A4\u30F3\u30B7\u30C7\u30F3\u30C8",
        sessions: "{{sessions}} \u30D2\u30C3\u30C8",
        sessions_one: "{{sessions}} \u30D2\u30C3\u30C8",
        sessions_other: "{{sessions}} \u30D2\u30C3\u30C8"
      },
      affectedUsers: {
        title: "\u5F71\u97FF\u3092\u53D7\u3051\u308B\u30E6\u30FC\u30B6\u30FC"
      },
      totalChart: {
        total: "\u5408\u8A08"
      },
      geoCard: {
        sourceTopCountries: {
          title: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u30B8\u30AA\u30FB\u30BD\u30FC\u30B9\u8AF8\u56FD"
        },
        destinationTopCountries: {
          title: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u30B8\u30AA\u76EE\u7684\u5730\u8AF8\u56FD"
        }
      },
      topAllowedCategoriesSessions: {
        title: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30A2\u30D7\u30EA\u30AB\u30C6\u30B4\u30EA\u30FC<0>(Session\u5F53\u305F\u308A)</0>",
        menuEntry: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30A2\u30D7\u30EA\u30AB\u30C6\u30B4\u30EA\u30FC(Session\u5F53\u305F\u308A)",
        footerKey: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9 {{length}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topAllowedCategoriesTraffic: {
        title: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30A2\u30D7\u30EA\u30AB\u30C6\u30B4\u30EA\u30FC<0>(Traffic\u5F53\u305F\u308A)</0>",
        menuEntry: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30A2\u30D7\u30EA\u30AB\u30C6\u30B4\u30EA\u30FC(Traffic\u5F53\u305F\u308A)",
        footerKey: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9 {{length}}"
      },
      blockedApplicationCategories: {
        title: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u963B\u6B62\u3055\u308C\u305F\u30A2\u30D7\u30EA\u30AB\u30C6\u30B4\u30EA\u30FC",
        footerKey: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9 {{length}}"
      },
      allowedApplicationsSessions: {
        title: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3<0>(Session\u5F53\u305F\u308A)</0>",
        menuEntry: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3(Session\u5F53\u305F\u308A)",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions",
        footerKey: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9 {{length}}"
      },
      allowedApplicationsTraffic: {
        title: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3<0>(Traffic\u5F53\u305F\u308A)</0>",
        menuEntry: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3(Traffic\u5F53\u305F\u308A)",
        footerKey: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9 {{length}}"
      },
      blockedApplications: {
        title: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u963B\u6B62\u3055\u308C\u305F\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions",
        footerKey: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9 {{length}}"
      },
      applicationRisk: {
        mediumHigh: "\u4E2D -\n\u9AD8\u3044",
        medium: "\u4E2D",
        lowMedium: "\u4F4E - \u4E2D",
        low: "\u30ED\u30FC",
        high: "\u9AD8\u3044",
        title: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3\u306E\u30EA\u30B9\u30AF",
        risk: "\u30EA\u30B9\u30AF"
      },
      highRiskApplicationsCard: {
        title: "\u30CF\u30A4\u30EA\u30B9\u30AF\u306E\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3",
        top: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9 {{length}}"
      },
      topBlockedUrlCategoriesCard: {
        title: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u963B\u6B62\u3055\u308C\u305FURL\u30AB\u30C6\u30B4\u30EA\u30FC",
        topUrlCategories: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9{{length}}\u963B\u6B62\u3055\u308C\u305FURL\u30AB\u30C6\u30B4\u30EA\u30FC",
        total: "\u4EE5\u5916 {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      applicationCategories: {
        title: "\u30A2\u30D7\u30EA\u30FB\u30AB\u30C6\u30B4\u30EA\u30FC"
      },
      topApplications: {
        title: "\u30A2\u30D7\u30EA\u30B1\u30FC\u30B7\u30E7\u30F3"
      },
      topAllowedUrlCategoriesCard: {
        titlePerSession: "\u6700\u591A\u8A31\u53EF\u3055\u308C\u3066\u3044\u308BURL\u30AB\u30C6\u30B4\u30EA\u30FC<0>(Session\u5F53\u305F\u308A)</0>",
        titlePerTraffic: "\u6700\u591A\u8A31\u53EF\u3055\u308C\u3066\u3044\u308BURL\u30AB\u30C6\u30B4\u30EA\u30FC<0>(Traffic\u5F53\u305F\u308A)</0>",
        menuEntryPerSession: "\u6700\u591A\u8A31\u53EF\u3055\u308C\u3066\u3044\u308BURL\u30AB\u30C6\u30B4\u30EA\u30FC(Session\u5F53\u305F\u308A)",
        menuEntryPerTraffic: "\u6700\u591A\u8A31\u53EF\u3055\u308C\u3066\u3044\u308BURL\u30AB\u30C6\u30B4\u30EA\u30FC(Traffic\u5F53\u305F\u308A)",
        topAllowedUrlCategories: "\u6700\u591A{{length}}\u8A31\u53EF\u3055\u308C\u3066\u3044\u308BURL\u30AB\u30C6\u30B4\u30EA\u30FC",
        total: "\u4EE5\u5916 {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topAllowedDomainsCard: {
        titlePerSession: "\u6700\u591A\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30C9\u30E1\u30A4\u30F3<0>(Session\u5F53\u305F\u308A)</0>",
        titlePerTraffic: "\u6700\u591A\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30C9\u30E1\u30A4\u30F3<0>(Traffic\u5F53\u305F\u308A)</0>",
        menuEntryPerSession: "\u6700\u591A\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30C9\u30E1\u30A4\u30F3(Session\u5F53\u305F\u308A)",
        menuEntryPerTraffic: "\u6700\u591A\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30C9\u30E1\u30A4\u30F3(Traffic\u5F53\u305F\u308A)",
        topAllowedDomains: "\u6700\u591A{{length}}\u8A31\u53EF\u3055\u308C\u3066\u3044\u308B\u30C9\u30E1\u30A4\u30F3",
        total: "\u4EE5\u5916 {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topBlockedDomainsCard: {
        title: "\u6700\u591A\u963B\u6B62\u3055\u308C\u305F\u30C9\u30E1\u30A4\u30F3",
        topBlockedDomains: "\u6700\u591A{{length}}\u963B\u6B62\u3055\u308C\u305F\u30C9\u30E1\u30A4\u30F3",
        total: "\u4EE5\u5916 {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topUsersCard: {
        title: "\u6700\u591A\u30A2\u30AF\u30BB\u30B9\u30E6\u30FC\u30B6\u30FC",
        topUsers: "\u6700\u591A{{length}}\u306E\u30E6\u30FC\u30B6\u30FC",
        total: "\u4EE5\u5916 {{total}}"
      },
      noData: {
        message: "\u3053\u306E\u30A6\u30A3\u30B8\u30A7\u30C3\u30C8\u306E\u305F\u3081\u306E\u30C7\u30FC\u30BF\u304C\u3042\u308A\u307E\u305B\u3093\uFF01"
      },
      barGrid: {
        noData: "\u30DE\u30C3\u30C1\u3057\u305F\u30A8\u30F3\u30C8\u30EA\u30FC\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\uFF01"
      },
      ZTNAResources: {
        title: "\u30EA\u30BD\u30FC\u30B9",
        others: "\u305D\u306E\u4ED6",
        total: "\u5408\u8A08\u5229\u7528\u53EF\u80FD\u30B5\u30A4\u30BA",
        custom: "\u30AB\u30B9\u30BF\u30E0",
        inUse: "\u4F7F\u7528\u4E2D",
        tableName: "\u30A2\u30AF\u30BB\u30B9\u30DD\u30EA\u30B7\u30FC",
        table: {
          name: "\u540D\u524D",
          description: "\u5185\u5BB9",
          users: "\u30E6\u30FC\u30B6\u30FC",
          groups: "\u30B0\u30EB\u30FC\u30D7",
          resources: "\u30EA\u30BD\u30FC\u30B9",
          devicePosture: "\u88C5\u7F6E\u30DD\u30B9\u30C1\u30E3\u30FC"
        }
      },
      ZTNAAgentInUse: {
        title: "\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8OS\u304C\u4F7F\u7528\u4E2D",
        total: "\u5408\u8A08",
        windows: "Win",
        macos: "macOs",
        android: "Android",
        ios: "iOS",
        linux: "Linux",
        tableName: "\u6700\u591A\u4F7F\u7528\u4E2DOS",
        devices: "{{value}} \u88C5\u7F6E",
        devices_plural: "{{value}} \u88C5\u7F6E"
      },
      ZTNADeviceLocation: {
        title: "\u30C7\u30D0\u30A4\u30B9\u30DE\u30C3\u30D7",
        devices: "\u88C5\u7F6E",
        noData: "\u30C7\u30D0\u30A4\u30B9\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002",
        user: "\u30E6\u30FC\u30B6\u30FC",
        brand: "\u88C5\u7F6E\u306E\u30D6\u30E9\u30F3\u30C9",
        model: "\u88C5\u7F6E\u30E2\u30C7\u30EB",
        hardwareModel: "\u30CF\u30FC\u30C9\u30A6\u30A7\u30A2\u30E2\u30C7\u30EB",
        operatingSystem: "\u30AA\u30DA\u30EC\u30FC\u30C6\u30A3\u30F3\u30B0\u30B7\u30B9\u30C6\u30E0",
        operatingSystemVersion: "OS\u30D0\u30FC\u30B8\u30E7\u30F3",
        agentVersion: "\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u30D0\u30FC\u30B8\u30E7\u30F3",
        enrollmentDate: "\u767B\u9332\u65E5",
        country: "\u56FD",
        noCountry: "\u5834\u6240\u7121\u3057"
      },
      ZTNAUserGeoLocation: {
        title: "\u30E6\u30FC\u30B6\u30FC\u6240\u5728\u5730",
        nonRoutableOrIP: "\u30EB\u30FC\u30C6\u30A3\u30F3\u30B0\u4E0D\u53EF\u307E\u305F\u306F\u30D7\u30E9\u30A4\u30D9\u30FC\u30C8IP\u30A2\u30C9\u30EC\u30B9",
        devices: "{{value}} \u88C5\u7F6E",
        devices_plural: "{{value}} \u88C5\u7F6E"
      },
      userStatusCard: {
        title: "\u30E6\u30FC\u30B6\u30FC",
        total: "\u5408\u8A08",
        enrolled: "\u767B\u9332\u6E08",
        pending: "\u4FDD\u7559\u4E2D",
        tableTitle: "\u6700\u591A\u30E6\u30FC\u30B6\u30FC\uFF08\u30C7\u30D0\u30A4\u30B9\u53F0\u6570\u5225\uFF09",
        name: "\u540D\u524D",
        email: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
        deviceCount: "\u30C7\u30D0\u30A4\u30B9\u6570",
        enrollmentCompleted: {
          label: "\u767B\u9332\u5B8C\u4E86",
          yes: "\u767B\u9332\u5B8C\u4E86",
          no: "\u767B\u9332\u4FDD\u7559\u4E2D"
        }
      },
      userSummaryCard: {
        title: "\u8981\u7D04",
        fullName: "\u30D5\u30EB\u30CD\u30FC\u30E0",
        email: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
        deviceCount: "\u30C7\u30D0\u30A4\u30B9\u6570",
        enrollmentCompleted: "\u767B\u9332\u5B8C\u4E86"
      },
      userDevicesCard: {
        title: "\u88C5\u7F6E",
        table: {
          userFullName: "\u30D5\u30EB\u30CD\u30FC\u30E0",
          userEmail: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9",
          brand: "\u88C5\u7F6E\u306E\u30D6\u30E9\u30F3\u30C9",
          hardwareModel: "\u88C5\u7F6E\u30E2\u30C7\u30EB",
          operatingSystem: "\u30AA\u30DA\u30EC\u30FC\u30C6\u30A3\u30F3\u30B0\u30B7\u30B9\u30C6\u30E0",
          operatingSystemVersion: "OS\u30D0\u30FC\u30B8\u30E7\u30F3",
          enrollmentDate: "\u767B\u9332\u65E5"
        }
      },
      accessDevicesAgentVersionCard: {
        title: "\u4F7F\u7528\u4E2D\u306E\u30A8\u30FC\u30B8\u30A7\u30F3\u30C8\u30D0\u30FC\u30B8\u30E7\u30F3",
        total: "\u5408\u8A08",
        version: "\u30D0\u30FC\u30B8\u30E7\u30F3 {{version}}",
        older: "\u53E4\u3044\u65B9",
        tableTitle: "\u6700\u591A\u4F7F\u7528\u4E2D\u30D0\u30FC\u30B8\u30E7\u30F3",
        devices: "{{value}} \u88C5\u7F6E",
        devices_plural: "{{value}} \u88C5\u7F6E"
      },
      categories: {
        ztna: "\u30BC\u30ED\u30C8\u30E9\u30B9\u30C8\u30A2\u30AF\u30BB\u30B9 (Zero Trust Access)",
        security: "\u30BB\u30AD\u30E5\u30EA\u30C6\u30A3",
        webFilter: "\u30A6\u30A7\u30D6\u30D5\u30A3\u30EB\u30BF\u30FC"
      }
    }
  }
};
export {
  ja_default as default
};
