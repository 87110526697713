import { useContext } from "react";
import { PreviewContext } from "../components/functional/PreviewProvider/PreviewProvider";
const usePreview = (previewId) => {
  const activePreviews = useContext(PreviewContext);
  if (activePreviews.includes("all")) {
    return true;
  }
  return activePreviews.includes(previewId);
};
export {
  usePreview
};
