var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { get, omit } from "lodash";
const formatRequestData = (data) => __spreadProps(__spreadValues({}, data), {
  resourceCriteria: __spreadProps(__spreadValues({}, omit(get(data, "resourceCriteria", {}), ["publicApps", "internalApps"])), {
    type: get(data, "resourceCriteria.type"),
    applications: get(data, get(data, "resourceCriteria.type") === "public" ? "resourceCriteria.publicApps" : "resourceCriteria.internalApps", [])
  }),
  deviceCriteria: __spreadProps(__spreadValues({}, omit(get(data, "deviceCriteria", {}), "osMinimumVersions")), {
    minimumClientVersion: get(data, "deviceCriteria.appUpdates") ? get(data, "deviceCriteria.appMinimumVersion", "") : null,
    minimumOperatingSystemVersions: (get(data, "deviceCriteria.osUpdates") && get(data, "deviceCriteria.osMinimumVersions") || []).reduce((versions, { platform, version }) => __spreadProps(__spreadValues({}, versions), { [platform]: version }), {})
  })
});
const formatResourceData = (data) => __spreadProps(__spreadValues({}, data), {
  resourceCriteria: __spreadProps(__spreadValues({}, get(data, "resourceCriteria", {})), {
    publicApps: get(data, "resourceCriteria.type") === "public" ? get(data, "resourceCriteria.applications") : [],
    internalApps: get(data, "resourceCriteria.type") === "internal" ? get(data, "resourceCriteria.applications") : []
  }),
  deviceCriteria: __spreadProps(__spreadValues({}, get(data, "deviceCriteria", {})), {
    appUpdates: !!get(data, "deviceCriteria.minimumClientVersion") || false,
    appMinimumVersion: get(data, "deviceCriteria.minimumClientVersion") || "",
    osUpdates: Object.values(get(data, "deviceCriteria.minimumOperatingSystemVersions", {})).some((value) => !!value),
    osMinimumVersions: Object.keys(get(data, "deviceCriteria.minimumOperatingSystemVersions", {})).map((operatingSystem) => ({ platform: operatingSystem, version: get(data, "deviceCriteria.minimumOperatingSystemVersions." + operatingSystem, "") })).filter(({ version }) => !!version)
  })
});
const formatError = (error, data) => __spreadProps(__spreadValues({}, error), {
  resourceCriteria: __spreadProps(__spreadValues({}, get(error, "resourceCriteria", {})), {
    publicApps: get(data, "resourceCriteria.type") === "public" ? get(error, "resourceCriteria.applications") : [],
    internalApps: get(data, "resourceCriteria.type") === "internal" ? get(error, "resourceCriteria.applications") : []
  }),
  deviceCriteria: __spreadProps(__spreadValues({}, get(error, "deviceCriteria", {})), {
    minimumOperatingSystemVersions: void 0,
    osMinimumVersions: get(error, "deviceCriteria.minimumOperatingSystemVersions") && Object.keys(get(error, "deviceCriteria.minimumOperatingSystemVersions", {})).map((platform) => ({
      platform,
      inputIndex: get(data, "deviceCriteria.osMinimumVersions", []).findIndex(({ platform: searchPlatform }) => searchPlatform === platform)
    })).sort(({ inputIndex: inputIndexA }, { inputIndex: inputIndexB }) => inputIndexA - inputIndexB).map(({ platform }) => ({ version: get(error, `deviceCriteria.minimumOperatingSystemVersions.${platform}`) }))
  })
});
export {
  formatError,
  formatRequestData,
  formatResourceData
};
