import { jsx, jsxs } from "react/jsx-runtime";
import { makeOverrideableStyles } from "@cuda-react/theme";
import Authenticated from "../../authentication/Authenticated/Authenticated";
import { Button, Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    width: "calc(100% - 32px)",
    margin: 16
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: "50vh",
    height: "100%"
  },
  subtitle: {
    paddingBottom: theme.spacing(3),
    fontSize: 30,
    fontWeight: 300
  },
  content: {
    paddingBottom: theme.spacing(3),
    fontSize: 20,
    fontWeight: 300
  }
});
const useStyles = makeOverrideableStyles("NoResourcePage", styles);
const NoResourcePage = (props) => {
  const { button, content, subtitle, to } = props;
  const [translate] = useTranslation();
  const { push } = useHistory();
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx("div", { className: classes.root, id: "cuda-react-page", children: /* @__PURE__ */ jsx(Authenticated, { children: /* @__PURE__ */ jsxs("div", { className: classes.container, children: [
    subtitle && /* @__PURE__ */ jsx(Typography, { className: classes.subtitle, children: translate(subtitle) }),
    /* @__PURE__ */ jsx(Typography, { className: classes.content, children: translate(content) }),
    button && /* @__PURE__ */ jsx(
      Button,
      {
        color: "primary",
        onClick: () => push(to ? to : "/"),
        variant: "contained",
        size: "small",
        children: translate(button)
      }
    )
  ] }) }) });
};
NoResourcePage.defaultProps = {
  content: "cuda.resourceNotFound.content",
  subtitle: "cuda.resourceNotFound.subtitle",
  button: "cuda.buttons.return"
};
var NoResourcePage_default = NoResourcePage;
export {
  NoResourcePage,
  NoResourcePage_default as default,
  styles
};
