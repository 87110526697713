import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BANNER_NOTIFICATION,
  DIALOG_NOTIFICATION,
  notificationHide,
  notificationShowBanner,
  notificationShowDialog,
  notificationShowToast,
  TOAST_NOTIFICATION
} from "../actions";
import { useDeepCompareMemo } from "./UtilHooks";
const getValueOrDefault = (value, defaultValue) => value !== void 0 ? value : defaultValue;
const useNotifications = (defaultType, defaultContent, defaultLevel, { dismissible, spinner, duration, translateParams } = {}) => {
  const dispatch = useDispatch();
  return useDeepCompareMemo(() => [
    (type, content, level, options = {}) => {
      if (getValueOrDefault(type, defaultType) === BANNER_NOTIFICATION) {
        dispatch(notificationShowBanner(
          getValueOrDefault(content, defaultContent),
          getValueOrDefault(level, defaultLevel),
          getValueOrDefault(options.dismissible, dismissible),
          getValueOrDefault(options.spinner, spinner),
          getValueOrDefault(options.translateParams, translateParams)
        ));
      } else if (getValueOrDefault(type, defaultType) === DIALOG_NOTIFICATION) {
        dispatch(notificationShowDialog(
          getValueOrDefault(content, defaultContent),
          getValueOrDefault(options.translateParams, translateParams)
        ));
      } else {
        dispatch(notificationShowToast(
          getValueOrDefault(content, defaultContent),
          getValueOrDefault(level, defaultLevel),
          getValueOrDefault(options.duration, duration),
          getValueOrDefault(options.translateParams, translateParams)
        ));
      }
    },
    (type, content) => dispatch(notificationHide(getValueOrDefault(type, defaultType) || TOAST_NOTIFICATION, getValueOrDefault(content, defaultContent)))
  ], [defaultContent, defaultType, defaultLevel, dismissible, spinner, duration, translateParams]);
};
const usePageBanner = (content, level, dismissible, spinner, translateParams) => {
  const [show, hide] = useNotifications(BANNER_NOTIFICATION, content, level, { dismissible, spinner, translateParams });
  useEffect(() => {
    show();
    return () => hide();
  });
};
export {
  useNotifications,
  usePageBanner
};
