import { jsx, jsxs } from "react/jsx-runtime";
import { Button, CircularProgress } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import Toolbar from "../../layout/Toolbar/Toolbar";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = createStyles({
  primary: {},
  secondary: {},
  back: {}
});
const useStyles = makeOverrideableStyles("WizardToolbar", styles);
const WizardToolbar = (props) => {
  const { back, primaryOnClick, toolbarDisabled, primaryLabel, secondaryOnClick, secondaryLabel, secondaryColor = "primary" } = props;
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(Toolbar, { children: [
    /* @__PURE__ */ jsx(
      Button,
      {
        onClick: primaryOnClick,
        disabled: toolbarDisabled,
        startIcon: toolbarDisabled ? /* @__PURE__ */ jsx(CircularProgress, { id: "cuda-loading-spinner", size: 16, thickness: 2 }) : void 0,
        variant: "contained",
        color: "primary",
        size: "small",
        className: classes.primary,
        children: primaryLabel && translate(primaryLabel)
      }
    ),
    secondaryLabel && secondaryOnClick ? /* @__PURE__ */ jsx(
      Button,
      {
        onClick: secondaryOnClick,
        disabled: toolbarDisabled,
        variant: "contained",
        size: "small",
        color: secondaryColor,
        className: classes.secondary,
        children: translate(secondaryLabel)
      }
    ) : null,
    back ? /* @__PURE__ */ jsx(
      Button,
      {
        onClick: back,
        disabled: toolbarDisabled,
        variant: "contained",
        color: "secondary",
        size: "small",
        className: classes.back,
        children: translate("cuda.buttons.back")
      }
    ) : null
  ] });
};
WizardToolbar.defaultProps = {
  primaryLabel: "cuda.buttons.next"
};
var WizardToolbar_default = WizardToolbar;
export {
  WizardToolbar,
  WizardToolbar_default as default
};
