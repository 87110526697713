import { jsx, jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { CircularProgress, Typography } from "@barracuda-internal/bds-core";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    // @ts-ignore Added by Cuda-react. Default (undefined), is fine
    backgroundColor: theme.palette.background.appbar,
    backgroundImage: "url('https://auth.barracudanetworks.com/img/bg_product-login.jpg')",
    backgroundSize: "cover",
    color: theme.palette.text.primary
  },
  authCheck: {
    fontSize: 32,
    color: theme.palette.primary.contrastText,
    marginBottom: theme.spacing(2)
  },
  text: {
    color: theme.palette.text.primary
  },
  title: {
    "& *": {
      color: theme.palette.primary.contrastText
    },
    backgroundColor: theme.palette.primary.main,
    fontSize: 32,
    fontWeight: 400
  },
  dialogMessage: {
    padding: "17px 7px 20px 7px",
    margin: "0 23px !important"
  },
  actions: {
    // @ts-ignore Added by Cuda-react. Default (undefined), is fine
    backgroundColor: theme.palette.background.navbar
  },
  dialog: {}
});
const useStyles = makeOverrideableStyles("AuthenticatingPage", styles);
const AuthenticatingPage = (props) => {
  const classes = useStyles(props);
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs("div", { className: classes.root, id: "login-page", children: [
    /* @__PURE__ */ jsx(CircularProgress, { size: 56, className: classes.authCheck }),
    /* @__PURE__ */ jsx(Typography, { variant: "h1", className: classes.authCheck, children: translate("cuda.auth.checking") })
  ] });
};
var AuthenticatingPage_default = AuthenticatingPage;
export {
  AuthenticatingPage,
  AuthenticatingPage_default as default,
  styles
};
