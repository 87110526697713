import { jsx, jsxs } from "react/jsx-runtime";
import { Button, CircularProgress, TextField, Typography } from "@barracuda-internal/bds-core";
import { InputAdornment } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  CrudTypes,
  DialogBody,
  getDataContent,
  Toolbar,
  useCrudFetch,
  useGlobalParam,
  useMixpanel
} from "@cuda-react/core";
import hexGenerator from "hex-generator";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import apiResources from "../../../../apiResources";
import CopyToClipboardButton from "../../../../components/CopyToClipboardButton";
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    maxWidth: 400,
    padding: theme.spacing(2)
  },
  inputRoot: {
    width: "calc(100% - " + theme.spacing(8) + ")",
    margin: theme.spacing(2, 4, 1),
    "& .MuiOutlinedInput-multiline": {
      height: "inherit"
    }
  },
  completeMessage: {
    margin: theme.spacing(1)
  }
}));
const CreateSdwanConnectorTokenContent = ({ id, sec, onClose }) => {
  useMixpanel("SD-WAN Connectors", "Create SD-WAN Connector Token");
  const [translate] = useTranslation();
  const classes = useStyles();
  const [currentAccount] = useGlobalParam("userData.currentAccount");
  const demo = useGlobalParam("userData.mode")[0] === "demo";
  const [data, , fetchToken] = useCrudFetch(CrudTypes.CREATE, apiResources.sdwanConnectorRegisterToken, { id, portalId: currentAccount });
  const token = React.useMemo(() => demo ? hexGenerator(128) : (getDataContent(data) || {}).otp, [demo, data]);
  useEffect(() => {
    demo || fetchToken();
  }, []);
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsxs(
      DialogBody,
      {
        classes: { dialogContent: classes.dialogContent },
        title: "tesseract.network.sdwanConnector.dialog.token.dialogTitle",
        onClose,
        children: [
          /* @__PURE__ */ jsx(Typography, { variant: "body1", className: classes.completeMessage, children: translate("tesseract.network.sdwanConnector.dialog.token.message", { sec }) }),
          /* @__PURE__ */ jsx(
            TextField,
            {
              id: "sec-otp",
              className: classes.inputRoot,
              InputProps: {
                endAdornment: /* @__PURE__ */ jsx(InputAdornment, { position: "end", children: token ? /* @__PURE__ */ jsx(
                  CopyToClipboardButton,
                  {
                    value: token || "",
                    iconOnly: true
                  }
                ) : /* @__PURE__ */ jsx(CircularProgress, { size: 24 }) })
              },
              fullWidth: true,
              label: translate("tesseract.network.sdwanConnector.dialog.token.subLabel"),
              value: token || "",
              disabled: !token,
              variant: "outlined"
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(Toolbar, { children: /* @__PURE__ */ jsx(Button, { variant: "contained", color: "primary", onClick: onClose, children: translate("cuda.notification.ok") }) })
  ] });
};
var CreateSdwanConnectorTokenContent_default = CreateSdwanConnectorTokenContent;
export {
  CreateSdwanConnectorTokenContent,
  CreateSdwanConnectorTokenContent_default as default,
  useStyles
};
