import { jsx, jsxs } from "react/jsx-runtime";
import { Typography } from "@barracuda-internal/bds-core";
import { useTranslation } from "react-i18next";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { createStyles } from "@mui/styles";
const styles = (theme) => createStyles({
  root: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary
  },
  title: {
    fontSize: 12,
    display: "inline-block",
    color: theme.palette.text.disabled
  }
});
const useStyles = makeOverrideableStyles("AccountTitle", styles);
const AccountTitle = (props) => {
  const { title } = props;
  const [translate] = useTranslation();
  const classes = useStyles(props);
  return /* @__PURE__ */ jsx(
    "div",
    {
      className: classes.root,
      id: "cuda-react-account-title",
      children: (!Array.isArray(title) ? [title] : title).map((text, index) => /* @__PURE__ */ jsxs(
        Typography,
        {
          component: "div",
          className: classes.title,
          children: [
            index > 0 ? "/" : "",
            translate(text)
          ]
        },
        index
      ))
    }
  );
};
var AccountTitle_default = AccountTitle;
export {
  AccountTitle,
  AccountTitle_default as default,
  styles
};
