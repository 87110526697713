import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography } from "@barracuda-internal/bds-core";
import { BooleanInput, HiddenInput, TextInput } from "@cuda-react/core";
import { useSiteInputValidations } from "../../../../../hooks/createEditSiteHooks";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useWatch } from "react-hook-form";
const useStyles = makeStyles((theme) => ({
  message: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
const SelectWanFallbackStep = () => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const inputValidations = useSiteInputValidations();
  const wanFailover = useWatch({ name: "wanFailover" });
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: translate("tesseract.sites.dialog.wans.SCmessage", { context: "failover" }) }),
    /* @__PURE__ */ jsx(Typography, { className: classes.message, variant: "body1", children: translate("tesseract.sites.dialog.wans.SCconfigureFailover") }),
    /* @__PURE__ */ jsx(
      BooleanInput,
      {
        label: "tesseract.sites.dialog.wans.failover",
        source: "wanFailover",
        isRequired: true
      }
    ),
    /* @__PURE__ */ jsx(
      HiddenInput,
      {
        source: "wanTotal",
        hiddenValue: wanFailover ? "2" : "1"
      }
    ),
    /* @__PURE__ */ jsx(
      TextInput,
      {
        label: "tesseract.sites.dialog.wans.size",
        source: "wanTotal",
        isRequired: true,
        disabled: true,
        validate: inputValidations.validateSCWansLength,
        type: "integer",
        options: {
          type: "number",
          InputProps: {
            inputProps: {
              min: 1,
              max: 2,
              step: 1
            }
          }
        }
      }
    )
  ] });
};
var SelectWanFallbackStep_default = SelectWanFallbackStep;
export {
  SelectWanFallbackStep,
  SelectWanFallbackStep_default as default
};
