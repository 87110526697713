import { jsx, jsxs } from "react/jsx-runtime";
import { createStyles } from "@mui/styles";
import { makeOverrideableStyles } from "@cuda-react/theme";
import { Trans, useTranslation } from "react-i18next";
import { get } from "lodash";
import { useCrudFetch } from "../../../hooks";
import { CrudTypes } from "../../../clients";
import React from "react";
import DialogBody from "../DialogBody/DialogBody";
import Container from "@mui/material/Container";
import { Button, Typography } from "@barracuda-internal/bds-core";
import Toolbar from "../../layout/Toolbar/Toolbar";
import CircularProgress from "@mui/material/CircularProgress";
const styles = createStyles({
  body: {
    textAlign: "center",
    padding: 16
  },
  multiLineBody: {
    textAlign: "left",
    padding: 16
  }
});
const useStylesContent = makeOverrideableStyles("DeleteDialogContent", styles);
const DeleteDialogContent = (props) => {
  const { optionValue, optionText, data, resource, title, message, onClose, onDelete, onSuccess, dontCloseOnError, multiline } = props;
  const classes = useStylesContent(props);
  const [translate] = useTranslation();
  const resourceId = data && get(data, optionValue);
  const resourceName = data && get(data, optionText);
  const customDelete = onDelete ? (params) => {
    onDelete(params);
    onSuccess && onSuccess();
    onClose == null ? void 0 : onClose();
  } : void 0;
  const [, deleting, performDelete] = useCrudFetch(CrudTypes.DELETE, resource, { id: resourceId });
  return /* @__PURE__ */ jsxs(React.Fragment, { children: [
    /* @__PURE__ */ jsx(
      DialogBody,
      {
        title: translate(title, { name: resourceName }),
        children: /* @__PURE__ */ jsx(Container, { className: multiline ? classes.multiLineBody : classes.body, children: multiline ? /* @__PURE__ */ jsx(Typography, { variant: "body1", children: typeof message === "string" ? /* @__PURE__ */ jsx(Trans, { i18nKey: message, values: { name: resourceName }, children: /* @__PURE__ */ jsx("br", {}) }) : message }) : /* @__PURE__ */ jsx(Typography, { variant: "body1", children: typeof message === "string" ? translate(message, { name: resourceName }) : message }) })
      }
    ),
    /* @__PURE__ */ jsxs(Toolbar, { children: [
      /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          color: "primary",
          size: "small",
          startIcon: deleting ? /* @__PURE__ */ jsx(CircularProgress, { id: "cuda-loading-spinner", size: 16, thickness: 2 }) : void 0,
          disabled: deleting,
          onClick: () => {
            if (customDelete) {
              customDelete({ id: resourceId });
            } else {
              return performDelete({ id: resourceId }).then((result) => {
                if (!result.error) {
                  onSuccess && onSuccess(result, data);
                  onClose == null ? void 0 : onClose();
                } else if (!dontCloseOnError) {
                  onClose == null ? void 0 : onClose();
                }
              });
            }
          },
          children: translate("cuda.delete.ok")
        }
      ),
      /* @__PURE__ */ jsx(
        Button,
        {
          variant: "contained",
          color: "secondary",
          size: "small",
          disabled: deleting,
          onClick: onClose,
          children: translate("cuda.delete.cancel")
        }
      )
    ] })
  ] });
};
var DeleteDialogContent_default = DeleteDialogContent;
export {
  DeleteDialogContent_default as default
};
