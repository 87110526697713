import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import {
  ActionButtonsField,
  ChipArrayField,
  CrudTypes,
  DateField,
  DeleteDialog,
  DisableableButton,
  EmptyPageContent,
  getArrayDataContent,
  PropGateway,
  StatusIconField,
  TablePage,
  TextField,
  useCrudSubscription,
  useMixpanel
} from "@cuda-react/core";
import { ConnectorGraphic, GetStartedBackgroundGraphic } from "@cuda-react/icons";
import apiResources from "../../../apiResources";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import EditSdwanConnector from "./createEdit/EditSdwanConnector";
import { siteGatewayStatusIcons } from "../../../components/iconMapping";
import { useGenericField } from "../../../hooks/sourceAndDestinationHooks";
import SdwanConnectorTableMenu from "./SdwanConnectorTableMenu";
import CreateSdwanConnector from "./createEdit/CreateSdwanConnector";
import { Dialog } from "@barracuda-internal/bds-core";
const SdwanConnector = () => {
  const tableRefreshRef = useRef(null);
  const registerAction = useMixpanel("SD-WAN Connector");
  const generateChipArrayProps = useGenericField();
  const [gatewaysData, loading] = useCrudSubscription(
    CrudTypes.GET,
    apiResources.gatewayNames,
    {},
    { pollInterval: 3e4 }
  );
  const noGateways = getArrayDataContent(gatewaysData).length === 0;
  const [actionVisible, setActionVisible] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      TablePage,
      {
        title: "tesseract.network.pageTitle",
        subtitle: "tesseract.network.sdwanConnector.pageTitle",
        titleActions: actionVisible && /* @__PURE__ */ jsx(
          DisableableButton,
          {
            buttonText: "tesseract.network.sdwanConnector.dialog.add",
            disabledMessage: "tesseract.network.sdwanConnector.dialog.menuItemDisabled",
            disabled: noGateways || loading,
            onClick: () => setCreateOpen(true)
          }
        ),
        authenticated: true,
        resource: apiResources.sdwanConnector,
        tableName: "SdWanConnectorTable",
        minCellWidth: 128,
        defaultItemsPerPage: 5e3,
        flat: true,
        refreshRef: tableRefreshRef,
        resizable: true,
        reorderable: true,
        editColumns: true,
        onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table"),
        noDataPage: /* @__PURE__ */ jsx(
          EmptyPageContent,
          {
            background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
            icon: /* @__PURE__ */ jsx(ConnectorGraphic, {}),
            iconHeader: "tesseract.network.sdwanConnector.noData.iconHeader",
            textHeader: "tesseract.network.sdwanConnector.noData.textHeader",
            text: "tesseract.network.sdwanConnector.noData.text",
            actions: /* @__PURE__ */ jsx(
              DisableableButton,
              {
                buttonText: "tesseract.network.sdwanConnector.noData.labelButton",
                disabledMessage: "tesseract.network.sdwanConnector.dialog.menuItemDisabled",
                disabled: noGateways || loading,
                onClick: () => setCreateOpen(true)
              }
            )
          }
        ),
        children: [
          /* @__PURE__ */ jsx(
            StatusIconField,
            {
              isNotification: true,
              source: "connectionStatus.aggregateConnectionState",
              iconMap: siteGatewayStatusIcons,
              tooltipSource: "statusName",
              label: "tesseract.network.sdwanConnector.table.status",
              width: 128,
              filter: "selectarray",
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "name",
              label: "tesseract.network.sdwanConnector.table.name",
              filter: "text",
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            PropGateway,
            {
              source: "configurationUuid",
              label: "tesseract.network.sdwanConnector.table.gateway",
              editProps: generateChipArrayProps("configurationType"),
              sortable: true,
              children: /* @__PURE__ */ jsx(ChipArrayField, {})
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "resources",
              render: (value) => value == null ? void 0 : value.serviceName,
              label: "tesseract.customApps.table.destination",
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            ChipArrayField,
            {
              source: "resources",
              render: (value) => value == null ? void 0 : value.hostname,
              label: "tesseract.network.sdwanConnector.table.hostnames",
              columnProps: {
                field: "hostnames"
              }
            }
          ),
          /* @__PURE__ */ jsx(
            DateField,
            {
              source: "created",
              label: "tesseract.network.sdwanConnector.table.provisioningDate",
              sortable: true
            }
          ),
          /* @__PURE__ */ jsxs(
            ActionButtonsField,
            {
              width: 142,
              source: "id",
              alwaysVisible: true,
              children: [
                /* @__PURE__ */ jsx(SdwanConnectorTableMenu, {}),
                /* @__PURE__ */ jsx(CreateEditButtonDialog, { component: EditSdwanConnector, onSuccess: tableRefreshRef.current || void 0 }),
                /* @__PURE__ */ jsx(
                  DeleteDialog,
                  {
                    resource: apiResources.sdwanConnector,
                    title: "tesseract.network.sdwanConnector.delete.title",
                    message: "tesseract.network.sdwanConnector.delete.body",
                    onSuccess: () => {
                      var _a;
                      (_a = tableRefreshRef.current) == null ? void 0 : _a.call(tableRefreshRef);
                      registerAction("Delete SecureEdge Connector");
                    }
                  }
                )
              ]
            }
          )
        ]
      }
    ),
    /* @__PURE__ */ jsx(
      Dialog,
      {
        open: createOpen,
        maxWidth: false,
        disableEnforceFocus: true,
        onClose: () => setCreateOpen(false),
        children: /* @__PURE__ */ jsx(
          CreateSdwanConnector,
          {
            onSuccess: tableRefreshRef.current,
            onClose: () => {
              setCreateOpen(false);
            }
          }
        )
      }
    )
  ] });
};
var SdwanConnector_default = SdwanConnector;
export {
  SdwanConnector,
  SdwanConnector_default as default
};
