var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import React from "react";
import {
  BooleanInput,
  ConnectedForm,
  DialogBody,
  getArrayDataContent,
  SelectInput,
  TextInput,
  useCrudProps,
  useGlobalParam,
  useMixpanel,
  validateMaxLengthMemo,
  validateRegexMemo
} from "@cuda-react/core";
import { get, isEqual } from "lodash";
import { useGeneratedSourceCriteriaSection } from "../../../../../../hooks/sourceAndDestinationHooks";
import generateDestinationCriteriaSection from "../../../../../../components/sourceAndDestination/generateDestinationCriteriaSection";
import {
  formatRequestCriteria,
  formatResourceCriteria
} from "../../../../../../components/sourceAndDestination/formUtils";
import { settingsIcons } from "../../../../../../components/iconMapping";
import apiResources from "../../../../../../apiResources";
const hideInput = (key, type) => (value, data) => !get(data, key) || get(data, key) !== type;
const CreateEditUrlExceptions = (_a) => {
  var _b = _a, { create, id, onSuccess } = _b, props = __objRest(_b, ["create", "id", "onSuccess"]);
  var _a2, _b2;
  const registerAction = useMixpanel("Web Filter Explicit", false);
  const actionChoices = getArrayDataContent((_a2 = useCrudProps(apiResources.urlFilterActions, {}, {}, true)[0]) == null ? void 0 : _a2.data);
  const categoryChoices = getArrayDataContent((_b2 = useCrudProps(apiResources.urlFilterCategories, {}, {}, true)[0]) == null ? void 0 : _b2.data);
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  return /* @__PURE__ */ jsx(
    DialogBody,
    __spreadProps(__spreadValues({}, props), {
      form: true,
      title: create ? "tesseract.security.urlFiltering.exceptions.create.dialogTitle" : "tesseract.security.urlFiltering.exceptions.edit.dialogTitle",
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          resource: apiResources.urlExceptions,
          form: "urlExceptions",
          create,
          params: create ? void 0 : { id },
          onSubmitSuccess: (response) => {
            var _a3, _b3;
            registerAction(create ? "Create" : "Update", {
              action: response.action,
              sourceType: (_a3 = response.source) == null ? void 0 : _a3.type,
              destinationType: (_b3 = response.destination) == null ? void 0 : _b3.type
            });
            onSuccess == null ? void 0 : onSuccess();
            props.onClose();
          },
          onCancel: props.onClose,
          formatRequestData: formatRequestCriteria(virtualWanId),
          formatResourceData: formatResourceCriteria,
          initialValues: create ? { source: { allSites: true }, destination: { allSites: true } } : void 0,
          allowDirtyNavigation: true,
          children: [
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "name",
                label: "tesseract.security.urlFiltering.exceptions.name",
                description: "tesseract.security.urlFiltering.exceptions.descriptions.name",
                validate: [validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")],
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "description",
                label: "tesseract.security.urlFiltering.exceptions.description",
                description: "tesseract.security.urlFiltering.exceptions.descriptions.description",
                validate: [validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]
              }
            ),
            /* @__PURE__ */ jsx(
              SelectInput,
              {
                source: "action",
                label: "tesseract.security.urlFiltering.exceptions.action",
                description: "tesseract.security.urlFiltering.exceptions.descriptions.action",
                icons: settingsIcons,
                iconMap: settingsIcons,
                choices: actionChoices,
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              BooleanInput,
              {
                source: "silent",
                label: "tesseract.security.urlFiltering.exceptions.silent",
                description: "tesseract.security.urlFiltering.exceptions.descriptions.silent",
                disable: hideInput("action", "block")
              }
            ),
            useGeneratedSourceCriteriaSection(["network", "site", "userOrGroup"]),
            generateDestinationCriteriaSection(["category", "domain", "customCategory"], categoryChoices)
          ]
        }
      )
    })
  );
};
const propsAreEqual = (val1, val2) => isEqual(val1.id, val2.id) && isEqual(val1.create, val2.create);
var CreateEditUrlExceptions_default = React.memo(CreateEditUrlExceptions, propsAreEqual);
export {
  CreateEditUrlExceptions,
  CreateEditUrlExceptions_default as default,
  propsAreEqual
};
