var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { jsx, jsxs } from "react/jsx-runtime";
import { ConnectedWizard, InputStep, ResultStep, SubmitStep, useGlobalParam } from "@cuda-react/core";
import { Typography } from "@barracuda-internal/bds-core";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import apiResources from "../../../../apiResources";
import PhasesInput from "./PhasesInput";
import NetworkInput from "./NetworkInput";
import SourceDestInput from "./SourceDestInput";
import GeneralIpSecTunnelInput from "./GeneralIpSecTunnelInput";
import { useGatewaysSites } from "./useGatewaysSites";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: 730,
    backgroundColor: "white"
  }
}));
const CreateRule = (props) => {
  const classes = useStyles();
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const { gateways, sites, validate } = useGatewaysSites();
  const [translate] = useTranslation();
  return /* @__PURE__ */ jsxs(
    ConnectedWizard,
    {
      title: "tesseract.integration.ipSecV2.create",
      resource: apiResources.ipsecV2,
      dialogBodyProps: { classes: { dialogContent: classes.dialogContent } },
      formatRequestData: (data) => {
        var _a, _b, _c, _d, _e, _f;
        return __spreadProps(__spreadValues({}, data), {
          virtualWanId,
          local: __spreadProps(__spreadValues({}, (data == null ? void 0 : data.local) || {}), {
            wan: gateways.some(({ uuid, cloud }) => {
              var _a2;
              return uuid === ((_a2 = data == null ? void 0 : data.local) == null ? void 0 : _a2.uuid) && cloud;
            }) ? "" : (_a = data == null ? void 0 : data.local) == null ? void 0 : _a.wan
          }),
          remotes: (data == null ? void 0 : data.bgp) ? [...data.remotes] : [data.remotes[0]],
          dpd: {
            delay: ((_b = data == null ? void 0 : data.dpd) == null ? void 0 : _b.action) === "none" ? "30" : (_c = data == null ? void 0 : data.dpd) == null ? void 0 : _c.delay,
            action: (_d = data == null ? void 0 : data.dpd) == null ? void 0 : _d.action
          },
          phase2: __spreadProps(__spreadValues({}, (data == null ? void 0 : data.phase2) || {}), {
            traffic: ((_e = data == null ? void 0 : data.phase2) == null ? void 0 : _e.trafficEnabled) ? (_f = data == null ? void 0 : data.phase2) == null ? void 0 : _f.traffic : null
          }),
          subnetPairs: (data == null ? void 0 : data.bgp) ? false : data == null ? void 0 : data.subnetPairs,
          universalTrafficSelectors: (data == null ? void 0 : data.bgp) ? true : data == null ? void 0 : data.universalTrafficSelectors
        });
      },
      initialValues: {
        enabled: true,
        bgp: false,
        initiate: true,
        phase1: {
          encryptionMethod: "aes256",
          hashMethod: "sha256",
          dhGroup: "group2",
          proposalHandling: "strict",
          lifetime: 28800
        },
        phase2: {
          encryptionMethod: "aes256",
          hashMethod: "sha256",
          dhGroup: "group2",
          proposalHandling: "strict",
          lifetime: 3600,
          trafficEnabled: false
        },
        reauthentication: true,
        dpd: {
          action: "restart",
          delay: "30"
        },
        subnetPairs: false,
        universalTrafficSelectors: false,
        udpEncapsulation: false
      },
      onClose: props.onClose,
      onSubmitSuccess: props.onSuccess,
      validate,
      children: [
        /* @__PURE__ */ jsx(
          InputStep,
          {
            label: "tesseract.integration.ipSecV2.general",
            children: /* @__PURE__ */ jsx(GeneralIpSecTunnelInput, {})
          }
        ),
        /* @__PURE__ */ jsx(
          InputStep,
          {
            label: "tesseract.integration.ipSecV2.sourceDest",
            children: /* @__PURE__ */ jsx(SourceDestInput, { sites, gateways })
          }
        ),
        /* @__PURE__ */ jsx(
          InputStep,
          {
            label: "tesseract.integration.ipSecV2.phases",
            children: /* @__PURE__ */ jsx(PhasesInput, {})
          }
        ),
        /* @__PURE__ */ jsx(
          SubmitStep,
          {
            label: "tesseract.integration.ipSecV2.network",
            children: /* @__PURE__ */ jsx(NetworkInput, {})
          }
        ),
        /* @__PURE__ */ jsx(ResultStep, { onClose: props.onClose, label: "tesseract.integration.ipSecV2.success", children: /* @__PURE__ */ jsx(Typography, { children: translate("tesseract.integration.ipSecV2.successMessage") }) })
      ]
    }
  );
};
CreateRule.propTypes = {
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
};
var CreateRule_default = CreateRule;
export {
  CreateRule,
  CreateRule_default as default,
  useStyles
};
