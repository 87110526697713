var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import {
  ActionButtonsField,
  CrudTypes,
  CustomField,
  DeleteDialog,
  DisableableButton,
  EmptyPageContent,
  getArrayDataContent,
  getDataContent,
  LinkField,
  PropGateway,
  SelectedChoiceField,
  StatusIconField,
  TablePage,
  TextField,
  useCrudProps,
  useCrudSubscription,
  useGlobalParam,
  useMixpanel
} from "@cuda-react/core";
import {
  AzureIcon,
  EdgeServiceIcon,
  GetStartedBackgroundGraphic,
  PointOfPresenceGraphic,
  PrivateEdgeIcon
} from "@cuda-react/icons";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CreateGatewayDialog from "./dialogs/CreateGatewayDialog";
import { getAzureHubLink, getAzureResourceLink } from "../../../utils/azureUtils";
import { get } from "lodash";
import { makeStyles } from "@mui/styles";
import { Forward } from "@barracuda-internal/bds-core/dist/Icons/Core";
import { push } from "react-router-redux";
import apiResources from "../../../apiResources";
import { useTranslation } from "react-i18next";
import SizeFilter, { SIZE_FILTER_CHOICE_KEY } from "../../../components/gateways/filters/SizeFilter";
const useStyles = makeStyles((theme) => ({
  scaleUnitCell: {
    width: "10%"
  },
  gatewayIcon: {
    height: "1.2rem",
    width: "1.2rem",
    marginBottom: theme.spacing(-0.5),
    paddingRight: theme.spacing(1),
    position: "relative"
  }
}));
const PRIVATE_CLOUD_FILTER_KEY = "private-cloud";
const MANAGED_CLOUD_FILTER_KEY = "managed";
const GatewayTable = (props) => {
  var _a, _b, _c;
  const registerAction = useMixpanel("Gateways");
  const classes = useStyles();
  const [translate] = useTranslation();
  const statusChoices = (_a = useCrudProps(apiResources.gatewayStates)[0]) == null ? void 0 : _a.data;
  const regionChoices = (_b = useCrudProps(apiResources.gatewayRegions)[0]) == null ? void 0 : _b.data;
  const scaleUnitChoices = (_c = useCrudProps(apiResources.gatewayScaleUnits)[0]) == null ? void 0 : _c.data;
  const bandwidthChoices = getArrayDataContent(useCrudProps(apiResources.managedGatewaysBandwidths)[0]);
  const [cloudVwanChoices] = useCrudSubscription(CrudTypes.GET, apiResources.cloudVwans);
  const isDemo = useGlobalParam("userData.mode")[0] === "demo";
  const [pointToSite] = useCrudSubscription(CrudTypes.GET, apiResources.pointToSite, {}, { crudOptions: { quietErrors: true } });
  const pointToSiteEnabled = get(getDataContent(pointToSite), "enabled", false);
  const dispatch = useDispatch();
  const tableRefreshRef = useRef(null);
  const [virtualWanId] = useGlobalParam("filter.virtualWanId");
  const [regionsList, , refreshRegionsList] = useCrudProps(apiResources.managedGatewaysRegions, { virtualWanId });
  const isRegionAvailable = getArrayDataContent(regionsList).length > 0;
  const [actionVisible, setActionVisible] = useState(false);
  const [createMenu, setCreateMenu] = useState(null);
  const [subscriptionSerial] = useGlobalParam("userData.subscription.serial");
  const getBandwidth = (model) => {
    var _a2;
    return (_a2 = bandwidthChoices.find((item) => item.key === model)) == null ? void 0 : _a2.name;
  };
  const renderSize = (scaleUnit, data) => {
    const type = get(data, "gatewayType");
    if (type === "cloud") {
      return /* @__PURE__ */ jsx(
        SelectedChoiceField,
        {
          data,
          source: "scaleUnit",
          choices: getArrayDataContent(scaleUnitChoices)
        }
      );
    }
    if (type === "managed") {
      return getBandwidth(get(data, "model"));
    }
    return get(data, "model") + (get(data, "serial", "").includes(",") ? ` ${translate("tesseract.gateways.table.HA")}` : "");
  };
  const getResource = (data) => {
    const type = get(data, "gatewayType");
    if (type === "cloud") {
      return apiResources.gateways;
    }
    if (type === "managed") {
      return apiResources.managedGateways;
    }
    return apiResources.gatewaysOnPrem;
  };
  const createButton = /* @__PURE__ */ jsx(
    DisableableButton,
    {
      buttonText: "tesseract.gateways.dialog.create.buttonText",
      menuItems: [
        {
          label: "tesseract.gateways.dialog.create.managed.menuLabel",
          onClick: () => setCreateMenu("managed"),
          disabled: !isRegionAvailable
        },
        {
          label: "tesseract.gateways.dialog.create.cloud.menuLabel",
          onClick: () => setCreateMenu("cloud"),
          disabled: !subscriptionSerial
        },
        {
          label: "tesseract.gateways.dialog.create.onPrem.menuLabel",
          onClick: () => setCreateMenu("on-prem")
        }
      ]
    }
  );
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsxs(
      TablePage,
      __spreadProps(__spreadValues({
        titleActions: actionVisible && createButton,
        authenticated: true,
        resource: apiResources.gateways,
        title: "tesseract.network.pageTitle",
        subtitle: "tesseract.network.gateways",
        minCellWidth: 88,
        tableName: "Gateways",
        resetOnGlobalParamChange: true,
        refreshRef: tableRefreshRef,
        resizable: true,
        reorderable: true,
        editColumns: true,
        noDataPage: /* @__PURE__ */ jsx(
          EmptyPageContent,
          {
            background: /* @__PURE__ */ jsx(GetStartedBackgroundGraphic, {}),
            icon: /* @__PURE__ */ jsx(PointOfPresenceGraphic, {}),
            iconHeader: "tesseract.gateways.noData.iconHeader",
            textHeader: "tesseract.gateways.noData.textHeader",
            text: "tesseract.gateways.noData.text",
            actions: createButton
          }
        ),
        onPageStatusChange: (pageStatus) => setActionVisible(pageStatus === "table"),
        params: (params) => {
          var _a2, _b2, _c2, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
          return __spreadProps(__spreadValues({}, params), {
            filter: __spreadProps(__spreadValues({}, params.filter), {
              cloudVirtualWanId: ((_a2 = params.filter) == null ? void 0 : _a2.cloudVirtualWanId) === PRIVATE_CLOUD_FILTER_KEY || ((_b2 = params.filter) == null ? void 0 : _b2.cloudVirtualWanId) === MANAGED_CLOUD_FILTER_KEY ? void 0 : (_c2 = params.filter) == null ? void 0 : _c2.cloudVirtualWanId,
              type: ((_d = params.filter) == null ? void 0 : _d.cloudVirtualWanId) === PRIVATE_CLOUD_FILTER_KEY ? "on-prem" : ((_e = params.filter) == null ? void 0 : _e.cloudVirtualWanId) === MANAGED_CLOUD_FILTER_KEY ? "managed" : void 0,
              size: void 0,
              scaleUnit: ((_g = (_f = params.filter) == null ? void 0 : _f.size) == null ? void 0 : _g[SIZE_FILTER_CHOICE_KEY]) === "scaleUnit" ? (_h = params.filter) == null ? void 0 : _h.size.scaleUnit : void 0,
              model: ((_j = (_i = params.filter) == null ? void 0 : _i.size) == null ? void 0 : _j[SIZE_FILTER_CHOICE_KEY]) === "model" ? (_k = params.filter) == null ? void 0 : _k.size.model : ((_m = (_l = params.filter) == null ? void 0 : _l.size) == null ? void 0 : _m[SIZE_FILTER_CHOICE_KEY]) === "bandwidth" ? (_n = params.filter) == null ? void 0 : _n.size.bandwidth : void 0
            })
          });
        }
      }, props), {
        children: [
          /* @__PURE__ */ jsx(
            StatusIconField,
            {
              source: "aggregateState",
              alwaysVisible: true,
              width: 88
            }
          ),
          /* @__PURE__ */ jsx(
            LinkField,
            {
              source: "name",
              label: "tesseract.gateways.table.name",
              link: getAzureResourceLink("applicationId", isDemo),
              filter: "text",
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            SelectedChoiceField,
            {
              source: "cloudVirtualWan.id",
              label: "tesseract.gateways.table.type",
              optionValue: "id",
              render: (value, data) => {
                var _a2, _b2, _c2, _d;
                const hasCloudVwan = !!((_a2 = data == null ? void 0 : data.cloudVirtualWan) == null ? void 0 : _a2.id);
                let text = hasCloudVwan && ((_b2 = data == null ? void 0 : data.cloudVirtualWan) == null ? void 0 : _b2.name) || "";
                text = text + (((_c2 = data == null ? void 0 : data.cloudVirtualWan) == null ? void 0 : _c2.description) ? ` (${(_d = data == null ? void 0 : data.cloudVirtualWan) == null ? void 0 : _d.description})` : "");
                const isOnPrem = get(data, "gatewayType") === "on-prem";
                return hasCloudVwan ? /* @__PURE__ */ jsxs(Fragment, { children: [
                  /* @__PURE__ */ jsx(AzureIcon, { classes: { icon: classes.gatewayIcon } }),
                  /* @__PURE__ */ jsx(
                    LinkField,
                    {
                      source: "text",
                      data: { data, text },
                      link: getAzureResourceLink("data.cloudVirtualWan.resourceId", isDemo)
                    }
                  )
                ] }) : /* @__PURE__ */ jsxs(Fragment, { children: [
                  isOnPrem ? (
                    //@ts-ignore this is bad typescript on cuda-react end
                    /* @__PURE__ */ jsx(PrivateEdgeIcon, { classes: { icon: classes.gatewayIcon } })
                  ) : (
                    //@ts-ignore this is bad typescript on cuda-react end
                    /* @__PURE__ */ jsx(EdgeServiceIcon, { classes: { icon: classes.gatewayIcon } })
                  ),
                  translate(`tesseract.gateways.table.${isOnPrem ? "privateCloud" : "managedCloud"}`)
                ] });
              },
              filter: "select",
              filterProps: {
                source: "cloudVirtualWanId",
                optionValue: "id",
                choices: [
                  { id: PRIVATE_CLOUD_FILTER_KEY, name: translate("tesseract.gateways.table.privateCloud") },
                  { id: MANAGED_CLOUD_FILTER_KEY, name: translate("tesseract.gateways.table.managedCloud") },
                  ...getArrayDataContent(cloudVwanChoices).map((choice) => __spreadProps(__spreadValues({}, choice), { id: choice.id + "" }))
                ]
              },
              columnProps: {
                field: "cloudVirtualWanName"
              }
            }
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "hubName",
              label: "tesseract.gateways.table.upstream",
              render: (name, data) => get(data, "gatewayType") === "cloud" ? /* @__PURE__ */ jsx(LinkField, { data, source: "hubName", link: getAzureHubLink("hubId", isDemo) }) : /* @__PURE__ */ jsx(
                TextField,
                {
                  data,
                  source: "upstreamName",
                  defaultText: translate("tesseract.gateways.table.noGateway")
                }
              ),
              filter: "text",
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            SelectedChoiceField,
            {
              source: "region",
              label: "tesseract.gateways.table.region",
              choices: getArrayDataContent(regionChoices),
              filter: "selectarray",
              filterProps: {
                choices: getArrayDataContent(regionChoices)
              },
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            SelectedChoiceField,
            {
              source: "connectionStatus.aggregateConnectionState",
              label: "tesseract.gateways.table.status",
              choices: getArrayDataContent(statusChoices),
              filter: "selectarray",
              filterProps: {
                choices: getArrayDataContent(statusChoices)
              },
              columnProps: {
                field: "connectionStatus"
              },
              sortable: true
            }
          ),
          /* @__PURE__ */ jsx(
            CustomField,
            {
              source: "scaleUnit",
              label: "tesseract.gateways.table.size",
              cellClassName: classes.scaleUnitCell,
              render: renderSize,
              filter: "custom",
              filterProps: {
                component: SizeFilter,
                source: "size",
                scaleUnitChoices: getArrayDataContent(scaleUnitChoices),
                bandwidthChoices
              }
            }
          ),
          /* @__PURE__ */ jsx(
            TextField,
            {
              source: "sites",
              label: "tesseract.gateways.table.sites",
              width: 104,
              sortable: true
            }
          ),
          /* @__PURE__ */ jsxs(ActionButtonsField, { width: 128, minResizableWidth: 128, source: "id", alwaysVisible: true, children: [
            /* @__PURE__ */ jsx(PropGateway, { editProps: ({ data }) => ({
              data,
              resource: getResource(data)
            }), children: /* @__PURE__ */ jsx(
              DeleteDialog,
              {
                disable: (data) => ((data == null ? void 0 : data.sites) || (data == null ? void 0 : data.gateways) || (data == null ? void 0 : data.secureEdgeConnectors)) > 0,
                title: "tesseract.gateways.delete.title",
                message: "tesseract.gateways.delete.body",
                disabledMessage: "tesseract.gateways.delete.forbidden",
                onSuccess: () => {
                  var _a2;
                  registerAction("Delete Gateway");
                  (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
                  refreshRegionsList();
                }
              }
            ) }),
            /* @__PURE__ */ jsx(
              Forward,
              {
                id: "gateway-dashboard-icon",
                onClick: (event, data) => {
                  dispatch(push("/infrastructure/gateways/" + data.id));
                }
              }
            )
          ] })
        ]
      })
    ),
    /* @__PURE__ */ jsx(
      CreateGatewayDialog,
      {
        currentDialog: createMenu,
        onSuccess: () => {
          var _a2;
          refreshRegionsList();
          (_a2 = tableRefreshRef.current) == null ? void 0 : _a2.call(tableRefreshRef);
        },
        onClose: () => setCreateMenu(null)
      }
    )
  ] });
};
var GatewayTable_default = GatewayTable;
export {
  MANAGED_CLOUD_FILTER_KEY,
  PRIVATE_CLOUD_FILTER_KEY,
  GatewayTable_default as default
};
