import { Fragment, jsx, jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { DialogBody, MenuButton, useCrudProps, useGlobalParam } from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import { Box, Dialog } from "@barracuda-internal/bds-core";
import { IdentityProviderSAMLSetup } from "./IdentityProviderSAMLSetup";
const IdentityProviderMenu = ({ data: identityProvider }) => {
  var _a;
  const isDemo = useGlobalParam("userData.mode")[0] === "demo";
  const testURL = (_a = useCrudProps((identityProvider == null ? void 0 : identityProvider.id) && !isDemo ? apiResources.identityProviderTestURL : void 0, { id: identityProvider == null ? void 0 : identityProvider.id })[0].data) == null ? void 0 : _a.testURL;
  const [showSAMLSetup, setShowSAMLSetup] = useState(false);
  const menuItems = useMemo(() => {
    const items = [{
      label: "tesseract.identity.identityProviders.menu.test",
      onClick: () => window.open(testURL, "_blank"),
      disabled: isDemo
    }];
    if ((identityProvider == null ? void 0 : identityProvider.type) === "saml") {
      items.push({
        label: "tesseract.identity.identityProviders.menu.setupSaml",
        onClick: () => {
          setShowSAMLSetup(true);
          return null;
        },
        disabled: isDemo
      });
    }
    return items;
  }, [identityProvider, setShowSAMLSetup, testURL, isDemo]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    /* @__PURE__ */ jsx(
      MenuButton,
      {
        anchorOrigin: { horizontal: "left", vertical: "bottom" },
        transformOrigin: { horizontal: "left", vertical: "top" },
        menuItems,
        iconButton: true
      }
    ),
    (identityProvider == null ? void 0 : identityProvider.type) === "saml" ? /* @__PURE__ */ jsx(Dialog, { open: showSAMLSetup, children: /* @__PURE__ */ jsx(DialogBody, { title: "tesseract.identity.identityProviders.menu.setupSaml", onClose: () => setShowSAMLSetup(false), children: /* @__PURE__ */ jsx(Box, { p: 3, children: /* @__PURE__ */ jsx(IdentityProviderSAMLSetup, { identityProvider }) }) }) }) : null
  ] });
};
export {
  IdentityProviderMenu
};
