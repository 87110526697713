var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { jsx, jsxs } from "react/jsx-runtime";
import {
  ConnectedForm,
  DialogBody,
  TextInput,
  SelectArrayInput
} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
const CreateEditUser = (_a) => {
  var _b = _a, { create, id, onSuccess } = _b, props = __objRest(_b, ["create", "id", "onSuccess"]);
  return /* @__PURE__ */ jsx(
    DialogBody,
    __spreadProps(__spreadValues({}, props), {
      form: true,
      title: create ? "tesseract.identity.users.create" : "tesseract.identity.users.edit",
      children: /* @__PURE__ */ jsxs(
        ConnectedForm,
        {
          resource: apiResources.identityUsers,
          create,
          params: create ? void 0 : { id },
          onSubmitSuccess: () => {
            var _a2;
            onSuccess && onSuccess();
            (_a2 = props.onClose) == null ? void 0 : _a2.call(props);
          },
          formatResourceData: (data) => {
            var _a2, _b2;
            if (!data) {
              return;
            }
            return __spreadProps(__spreadValues({}, data), {
              groups: (_b2 = (_a2 = data == null ? void 0 : data.groups) == null ? void 0 : _a2.map((group) => group.id)) != null ? _b2 : []
            });
          },
          onCancel: props.onClose,
          formatRequestData: (data) => {
            const newData = __spreadProps(__spreadValues({}, data), {
              groupIds: data.groups
            });
            delete newData.groups;
            return newData;
          },
          children: [
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "name",
                label: "tesseract.identity.users.name",
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              TextInput,
              {
                source: "email",
                label: "tesseract.identity.users.email",
                isRequired: true
              }
            ),
            /* @__PURE__ */ jsx(
              SelectArrayInput,
              {
                resource: apiResources.identityGroups,
                source: "groups",
                optionValue: "id",
                label: "tesseract.identity.users.groups"
              }
            )
          ]
        }
      )
    })
  );
};
export {
  CreateEditUser
};
